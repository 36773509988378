import {
  IJoinedMission,
  EPurchaseOrderStatus,
} from '@freelancelabs/teoreme-commons';
import { Roles as RolesTypes } from '@freelancelabs/inside-commons/dist/lib/models/enum/role.enum';
import { queryClient } from '@commons';
import { Link, Flex, Box, Text, Button, BlocInformation } from 'components/ui';
import React, { useState } from 'react';
import { create } from 'react-modal-promise';
import { ModalFrame, ModalProps } from '../ModalFrame';
import { UserSelect } from 'components/selects/UserSelect';
import { CloseIcon } from 'components/ui/icons';
import { useCreateOrUpdateMission } from 'hooks/missionHooks';
type Props = ModalProps & {
  beforeValidation?: () => void;
  mission?: IJoinedMission;
};

export const UpdateMissionManagerModal = ({
  onResolve,
  isOpen,
  mission,
}: Props) => {
  const [contactSelected, setContactSelected] = React.useState<
    string | undefined
  >(mission?.accountManager?.cognitoUserId);
  const [createOrUpdate] = useCreateOrUpdateMission();
  const [loading, setLoading] = useState(false);
  const onSubmit = async () => {
    setLoading(true);

    try {
      await createOrUpdate(mission?.reference, {
        //@ts-ignore
        accountManager: contactSelected,
      });
      queryClient.refetchQueries({ queryKey: [mission?.reference] });
      onResolve(true);
    } catch (e) {}

    setLoading(false);
  };
  const purchaseOrderDone = mission?.provider?.purchaseOrders?.find(bdc => {
    if (
      bdc?.status === EPurchaseOrderStatus.DONE ||
      bdc?.status === EPurchaseOrderStatus.SIGNED_IMPORT
    ) {
      return true;
    }
    return false;
  });
  const purchaseOrderWaiting = mission?.provider?.purchaseOrders?.find(bdc => {
    if (
      bdc?.status !== EPurchaseOrderStatus.DONE &&
      bdc?.status !== EPurchaseOrderStatus.SIGNED_IMPORT
    ) {
      return true;
    }
    return false;
  });
  return (
    <ModalFrame
      isOpen={isOpen}
      onClose={() => onResolve(false)}
      width={470}
      closeIcon
    >
      <Box>
        <Text variant="h2">Modifier le responsable de la mission</Text>
        {!purchaseOrderDone && purchaseOrderWaiting && (
          <BlocInformation mt={20}>
            {`Attention, le bon de commande fournisseur ${purchaseOrderWaiting?.reference} est en attente de signature sur cette mission. Vous devez renvoyer le bon de commande fournisseur si vous souhaitez que le nouveau responsable de mission en soit le signataire.`}
          </BlocInformation>
        )}
        <Box width={'375px'} mt={20}>
          <UserSelect
            placeholder="Responsable de la mission"
            role={RolesTypes.ACCOUNT_MANAGER}
            onChange={value => setContactSelected(value)}
            value={contactSelected}
            excludeDeactivatedUser={true}
          />
        </Box>
        <Flex mt={20} width={'250px'} justifyContent="space-between">
          <Button
            isLoading={loading}
            isDisabled={!contactSelected}
            onClick={() => onSubmit()}
            type="button"
          >
            Enregistrer
          </Button>
          <Link
            mt={20}
            iconLeft={<CloseIcon />}
            onClick={() => onResolve(false)}
          >
            Annuler
          </Link>
        </Flex>
      </Box>
    </ModalFrame>
  );
};

export const showUpdateMissionManagerModal = create<Props, boolean>(
  UpdateMissionManagerModal
);
