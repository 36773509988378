import {
  formatAllObject,
  IUserLocal,
  IUserMinimal,
  transformText,
  validateFormPhoneFormatFR,
  checkFormEmail,
} from '@commons';
import { ConfirmNavigationModal } from 'components/modals/ConfirmNavigationModal';
import {
  Box,
  FormControl,
  FormInfo,
  Input,
  Radio,
  Row,
  PhoneInputControlled,
} from 'components/ui';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';

type FormValues = Partial<Omit<IUserLocal, 'civility'>> & {
  civility: string;
};
type ContactFormProps = {
  defaultValues?: any; //Partial<IUserMinimal>;
  onSubmit?: (user: Partial<IUserMinimal>) => any | void;
  // we could just check if we have a cognitoUserId in out defaultValues - but let's be clear.
  isNew?: boolean;
  contactRole?: 'PROVIDER_CONTACT' | 'CUSTOMER';
  onboardingStep: any;
};

export const OnBoardingContactForm = ({
  defaultValues,
  onSubmit: onSubmitParent,
  isNew,
  contactRole,
  onboardingStep,
}: ContactFormProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isDirty, isSubmitSuccessful },
    reset,
  } = useForm<FormValues>({
    defaultValues: {
      ...defaultValues,
      civility: String(defaultValues.civility),
    },
  });

  // !!!  if user ( defaultValue) exist do not delete data !!!!
  const requiredFields = contactRole === 'CUSTOMER';
  const userExistCheck =
    defaultValues !== undefined && Object.keys(defaultValues).length > 1;
  const onSubmit = async (formValues: FormValues) => {
    setLoading(true);
    // transform form values as requested
    const values = {
      ...defaultValues,
      ...formValues,
      firstName: transformText(formValues.firstName, 'capitalize').trim(),
      lastName: transformText(formValues.lastName, 'uppercase').trim(),
      businessUnit: transformText(
        formValues.businessUnit,
        'capitalize-first'
      ).trim(),
      jobTitle: transformText(formValues.jobTitle, 'capitalize-first').trim(),
      civility: parseInt(formValues.civility || '1', 10),
    };
    if (!errors?.phone || !errors?.phone.message) {
      onSubmitParent && (await onSubmitParent(formatAllObject(values)));
    }
    setLoading(false);

    // reset dirty values
    formValues.firstName = transformText(
      formValues.firstName,
      'capitalize'
    ).trim();
    formValues.lastName = transformText(
      formValues.lastName,
      'uppercase'
    ).trim();
    formValues.businessUnit = transformText(
      formValues.businessUnit,
      'capitalize-first'
    ).trim();
    formValues.jobTitle = transformText(
      formValues.jobTitle,
      'capitalize-first'
    ).trim();
    formValues.civility = formValues.civility || '1';
    reset(formValues);
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <ConfirmNavigationModal active={isDirty && !isSubmitSuccessful} />
      <Box mb={20}>
        <Row spacing={20}>
          <FormControl label="Email">
            <>
              <Input
                isDisabled
                isFullWidth
                {...register('email', {
                  required: false,
                  validate: value => checkFormEmail(value as string),
                })}
              />
              {!!errors?.email && (
                <FormInfo variantColor="error">Email incorrect</FormInfo>
              )}
            </>
          </FormControl>
          <FormControl label="Autre adresse email">
            <>
              <Input
                isDisabled
                isFullWidth
                {...register('alternateEmail', {
                  required: false,
                  validate: value => checkFormEmail(value as string),
                })}
              />
              {!!errors?.alternateEmail && (
                <FormInfo variantColor="error">Email incorrect</FormInfo>
              )}
            </>
          </FormControl>
        </Row>
      </Box>
      <Box>
        <FormControl
          required={requiredFields}
          label={'Civilité'}
          errorMessage={errors?.civility?.message}
        >
          <>
            <Radio
              disabled
              {...register('civility', {
                required:
                  requiredFields || (userExistCheck && defaultValues?.civility)
                    ? 'Ce champ est requis'
                    : '',
              })}
              value="1"
            >
              Monsieur
            </Radio>
            <Radio
              disabled
              {...register('civility', {
                required:
                  requiredFields || (userExistCheck && defaultValues?.civility)
                    ? 'Ce champ est requis'
                    : '',
              })}
              value="2"
            >
              Madame
            </Radio>
          </>
        </FormControl>
      </Box>
      <Row spacing={20}>
        <FormControl
          required={requiredFields}
          label="Prénom"
          errorMessage={errors?.firstName?.message}
        >
          <Input
            isFullWidth
            isDisabled
            {...register('firstName', {
              required:
                requiredFields || (userExistCheck && defaultValues?.firstName)
                  ? 'Ce champ est requis'
                  : '',
            })}
            onChange={e => {
              // @ts-ignore
              e.target.value = e.target.value.trimStart();
            }}
          />
        </FormControl>
        <FormControl
          required={requiredFields}
          label="Nom"
          errorMessage={errors?.lastName?.message}
        >
          <Input
            isFullWidth
            isDisabled
            {...register('lastName', {
              required:
                requiredFields || (userExistCheck && defaultValues?.lastName)
                  ? 'Ce champ est requis'
                  : '',
            })}
            onChange={e => {
              // @ts-ignore
              e.target.value = e.target.value.trimStart();
            }}
          />
        </FormControl>
      </Row>

      <Row spacing={20}>
        <FormControl
          required={requiredFields}
          label="Téléphone"
          errorMessage={errors?.phone?.message}
        >
          <PhoneInputControlled
            control={control}
            disabled
            isFullWidth
            name="phone"
            rules={{
              validate: (value: string) => {
                return validateFormPhoneFormatFR(value);
              },
              required:
                requiredFields || (userExistCheck && defaultValues?.phone)
                  ? 'Ce champ est requis'
                  : '',
            }}
          />
        </FormControl>
        {contactRole === 'PROVIDER_CONTACT' ? (
          <FormControl
            required={requiredFields}
            label="Fonction"
            errorMessage={errors?.jobTitle?.message}
          >
            <Input
              isFullWidth
              isDisabled
              {...register('jobTitle', {
                required:
                  requiredFields || (userExistCheck && defaultValues?.jobTitle)
                    ? 'Ce champ est requis'
                    : '',
              })}
              onChange={e => {
                // @ts-ignore
                e.target.value = e.target.value.trimStart();
              }}
            />
          </FormControl>
        ) : (
          <Box />
        )}
      </Row>

      <Row spacing={20}>
        {contactRole === 'CUSTOMER' && (
          <FormControl
            required={requiredFields}
            label="Fonction"
            errorMessage={errors?.jobTitle?.message}
          >
            <Input
              isFullWidth
              isDisabled
              {...register('jobTitle', {
                required:
                  requiredFields || (userExistCheck && defaultValues?.jobTitle)
                    ? 'Ce champ est requis'
                    : '',
              })}
              onChange={e => {
                // @ts-ignore
                e.target.value = e.target.value.trimStart();
              }}
            />
          </FormControl>
        )}
        {contactRole === 'CUSTOMER' && (
          <FormControl
            required={requiredFields}
            label="Business Unit"
            errorMessage={errors?.businessUnit?.message}
          >
            <Input
              isFullWidth
              isDisabled
              {...register('businessUnit', {
                required:
                  requiredFields ||
                  (userExistCheck && defaultValues?.businessUnit)
                    ? 'Ce champ est requis'
                    : '',
              })}
              onChange={e => {
                // @ts-ignore
                e.target.value = e.target.value.trimStart();
              }}
            />
          </FormControl>
        )}
      </Row>
    </form>
  );
};
