import { useEffect, useState } from 'react';

// Hook
export function useDebounce(value: any, delay: number) {
  //search api do not accept () ? * +
  const valueClean =
    typeof value === 'string'
      ? value
          ?.replaceAll('(', '')
          ?.replaceAll(')', '')
          ?.replaceAll('?', '')
          ?.replaceAll('+', '')
          ?.replaceAll('*', '')
      : value;
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(valueClean);

  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(valueClean);
      }, delay);

      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler);
      };
    },
    [valueClean, delay] // Only re-call effect if value or delay changes
  );

  return debouncedValue;
}
