import {
  IContactSendEmailWithAttachmentParams,
  EFileType,
} from '@freelancelabs/teoreme-commons';
import { sendEmail, uploadFile } from '@commons';
import {
  Box,
  FormControl,
  Input,
  Link,
  Text,
  FileInput,
  StaticSelectControlled,
  TextAreaControlled,
} from 'components/ui';
import { AddIcon } from 'components/ui/icons';
import { useShowMessage } from 'hooks/useShowMessage';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { SendEmailModal } from 'components/modals/SendEmailContactModal';
type FormValues = any;
type SendEmailFormProps = {
  forRole: 'CUSTOMER' | 'PROVIDER';
  contact: any;
  onSubmit?: (formValues: FormValues) => any | void;
};

export const SendEmailForm: React.FC<
  React.PropsWithChildren<SendEmailFormProps>
> = ({ onSubmit: onSubmitParent, contact, forRole }) => {
  const history = useHistory();
  const {
    handleSubmit,
    control,
    register,
    watch,
    setValue,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {
      reason: forRole === 'PROVIDER' ? '1' : '2',
    },
  });
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState<File | false>(false);
  const showMessage = useShowMessage();
  const onSubmit = async (formValue: FormValues) => {
    setLoading(true);
    const submitData: IContactSendEmailWithAttachmentParams = {
      options: {
        cc: [],
        attachments: [],
        to: contact?.email,
        subject: formValue.subject,
        template: 'contact_provider_contact',
        templateVars: {
          content: formValue.message
            .replaceAll(
              'votre espace Connecteed',
              `<a href="https://connecteed.freelance.com/login">votre espace Connecteed<a/>`
            )
            .replaceAll('\n', '<br/>'),
        },
      },
    };
    if (file) {
      const uploadResponse = await uploadFile({
        file,
        fileType: EFileType.TEMPORARY,
        establishment: '',
        actionType: 'upload',
      });

      if (!uploadResponse) return;

      const attachment = {
        path: uploadResponse.fileLocation,
        filename: uploadResponse.fileName,
      };
      //@ts-ignore
      submitData.options.attachments.push(attachment);
    }
    try {
      await sendEmail(submitData);
      showMessage('success', 'Email envoyé');
    } catch (e) {
      //
      setLoading(false);
    }
    setLoading(false);
    history.goBack();
  };
  const reason = watch('reason');
  React.useEffect(() => {
    if (reason === '1') {
      setValue('subject', `Une nouvelle mission chez [Client]`);
      setValue(
        'message',
        `Bonjour,
      Dans le cadre de votre prochaine mission chez [Client], nous vous invitons à vous connecter  votre espace Connecteed et à ajouter ou mettre à jour si besoin les informations suivantes :

      - Informations concernant votre établissement

      - Informations concernant le ou les intervenant(s)

      Ces informations sont nécessaires au démarrage rapide de votre prochaine mission.`
      );
    }
    if (reason === '2') {
      setValue('message', '');
      setValue('subject', '');
    }
  }, [reason]);
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <SendEmailModal
        isLoading={loading}
        isEditable
        onClose={() => history.goBack()}
        onReject={() => history.goBack()}
        title={'Envoyer un email'}
      >
        <Box mt={40} p={40}>
          <Text variant="h3">1. Email du contact</Text>
          <Text mt={20} mb={40}>
            {contact?.email}
          </Text>
          <Box width={580} mt={40}>
            <Text variant="h3">2. Sélectionner un motif</Text>
            <FormControl label="">
              <StaticSelectControlled
                control={control}
                name="reason"
                bcWhite
                options={
                  forRole === 'PROVIDER'
                    ? [
                        {
                          label: 'Nouvelle mission',
                          value: '1',
                        },

                        { label: 'Aucun motif', value: '2' },
                      ]
                    : [{ label: 'Aucun motif', value: '2' }]
                }
                placeholder="Motif"
              />
            </FormControl>
          </Box>
          <Box mt={40} width={580}>
            <Text mb={20} variant="h3">
              3. Message
            </Text>
            <FormControl label="Objet">
              <Input
                isFullWidth
                {...register('subject', { required: 'Ce champs est requis' })}
              />
            </FormControl>
          </Box>
          <Box width={580}>
            <FormControl
              label="Message"
              errorMessage={
                errors?.message?.type === 'minLength'
                  ? 'Supérieur à 0'
                  : errors?.message?.type === 'maxLength'
                    ? 'Maximum 500 caractères'
                    : // @ts-ignore
                      errors?.message?.message
              }
            >
              <TextAreaControlled
                rules={{
                  required: 'Ce champ est requis',
                  minLength: 1,
                  maxLength: 500,
                }}
                control={control}
                name="message"
                defaultValue=""
                minRows={8}
              />
            </FormControl>
          </Box>
          <Box width={580}>
            <FileInput
              mb={20}
              label="Pièce jointe"
              onSelect={files => setFile(files[0])}
              accept=".pdf"
              subLabel="Format accepté : PDF"
            >
              <Link iconLeft={<AddIcon />}>
                {file ? (file as File).name : 'Importer une pièce jointe'}
              </Link>
            </FileInput>
          </Box>
        </Box>
      </SendEmailModal>
    </form>
  );
};
