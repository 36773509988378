import {
  calculateContractorRateFromMarginAndClientRate,
  calculateClientRateFromMarginAndContractorRate,
  getMarginOrMarkup,
  round,
} from '@commons';
import { EMarginType } from '@freelancelabs/teoreme-commons';
import {
  Flex,
  Link,
  Box,
  FormControl,
  Radio,
  Text,
  CheckSwitch,
  Input,
  Row,
  TextAreaControlled,
} from 'components/ui';
import { EuroIcon, PercentIcon, RefreshIcon } from 'components/ui/icons';
import { useDebounce } from 'hooks/useDebounce';
import React, { useEffect, useState } from 'react';

type FormValues = {
  marginType: EMarginType;
  isEnabled: boolean;
  margin: number;
  shouldJoinAttachment: boolean;
  saleBudget?: number | undefined;
  purchaseBudget?: number | undefined;
  consumedSaleBudget?: number | undefined;
  consumedPurchaseBudget?: number | undefined;
};

type ConfigureExpensesPartialFormProps = {
  form: FormValues & any;
  isDisabled: boolean;
  expensesConfig?: {
    marginType?: EMarginType;
    isEnabled?: boolean;
    margin?: number;
    shouldJoinAttachment?: boolean;
    saleBudget?: number | undefined;
    purchaseBudget?: number | undefined;
    consumedSaleBudget?: number | undefined;
    consumedPurchaseBudget?: number | undefined;
  };
  // onSubmit: (formValues: FormValues) => void;
  // onClose?: () => void;
};

export const ConfigureExpensesPartialForm = ({
  expensesConfig,
  isDisabled,
  form: {
    setError,
    clearErrors,
    formState: { errors, isDirty },
    register,
    watch,
    control,
    setValue,
    getValues,
  },
}: ConfigureExpensesPartialFormProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [fieldBlur, setFieldBlur] = useState<
    | 'expensesConfig.marginType'
    | 'expensesConfig.margin'
    | 'expensesConfig.saleBudget'
    | 'expensesConfig.purchaseBudget'
    | undefined
  >();
  const [activeBudget, setActiveBudget] = useState(
    expensesConfig?.saleBudget ? true : false
  );

  const [expensesEnabled, SetExpensesEnabled] = useState(
    expensesConfig?.isEnabled
  );
  const debounceTime = 0;

  const marginType = useDebounce(
    watch('expensesConfig.marginType'),
    debounceTime
  );
  const marginTypeLabel =
    marginType === EMarginType.MARGIN ? 'Marque' : 'Marge';

  const purchaseBudget = useDebounce(
    watch('expensesConfig.purchaseBudget'),
    debounceTime
  );
  const consumedPurchaseBudget = expensesConfig?.consumedPurchaseBudget;
  const saleBudget = useDebounce(
    watch('expensesConfig.saleBudget'),
    debounceTime
  );
  const margin = useDebounce(watch('expensesConfig.margin'), debounceTime);

  const [roundedValues, setRoundedValues] = useState({
    margin: expensesConfig?.margin ? round(expensesConfig?.margin) : undefined,
    saleBudget: expensesConfig?.saleBudget
      ? round(expensesConfig?.saleBudget)
      : undefined,
    purchaseBudget: expensesConfig?.purchaseBudget
      ? round(expensesConfig?.purchaseBudget)
      : undefined,
  });

  const consumedSaleBudget = expensesConfig?.consumedSaleBudget;
  const isOutOfRangePurchageBudget =
    purchaseBudget &&
    Number(purchaseBudget) > 0 &&
    Number(purchaseBudget) <= (consumedPurchaseBudget || 0);
  const isOutOfRangeSaleBudget =
    saleBudget &&
    Number(saleBudget) > 0 &&
    Number(saleBudget) <= (consumedSaleBudget || 0);

  const [fieldsForCalculation, setFieldsForCalculation] = React.useState<{
    margin: boolean;
    purchaseBudget: boolean;
    saleBudget: boolean;
  }>({ margin: false, purchaseBudget: false, saleBudget: false });

  const getCalculationFields = () => {
    setFieldsForCalculation({
      margin: isNotEmpty(margin),
      purchaseBudget: isNotEmpty(purchaseBudget),
      saleBudget: isNotEmpty(saleBudget),
    });
  };

  const isNotEmpty = (value: string | number | null | undefined): boolean => {
    if (value === '') return false;
    if (value === undefined) return false;
    if (value === null) return false;
    // if (value === 0) return false;
    if (Number.isNaN(value)) return false;

    return true;
  };

  const calculateBudgetAndMargin = (
    field:
      | 'expensesConfig.marginType'
      | 'expensesConfig.saleBudget'
      | 'expensesConfig.purchaseBudget'
      | 'expensesConfig.margin',
    value?: number | string
  ) => {
    const results: any = {
      marginType: marginType,
      margin: margin ?? 0,
      marginRounded: isNotEmpty(margin) ? round(Number(margin)) : '',
      saleBudget: saleBudget ?? 0,
      saleBudgetRounded: isNotEmpty(saleBudget) ? round(saleBudget) : '',
      purchaseBudget: purchaseBudget ?? 0,
      purchaseBudgetRounded: isNotEmpty(purchaseBudget)
        ? round(purchaseBudget)
        : '',
    };

    /*Si modification du “taux de marque” →
        recalcule automatique du ““tarif jour/montant payé par le client”
        (pas de modification du tarif jour/montant perçu par l’intervenant)
    */

    if (field === 'expensesConfig.margin') {
      if (isNotEmpty(value)) {
        results.margin = isNotEmpty(value) ? Number(value) : undefined;
        if (isNotEmpty(purchaseBudget) && fieldsForCalculation.purchaseBudget) {
          results.purchaseBudget = Number(purchaseBudget);
          results.saleBudget = calculateClientRateFromMarginAndContractorRate(
            Number(purchaseBudget),
            Number(value), //margin
            marginType
            //true //round
          );
        } else if (isNotEmpty(saleBudget) && fieldsForCalculation.saleBudget) {
          results.saleBudget = Number(saleBudget);
          results.purchaseBudget =
            calculateContractorRateFromMarginAndClientRate(
              Number(saleBudget), // saleBudget
              Number(value),
              marginType
              //true //round
            );
        }
      } else {
        // empty value margin
        if (isNotEmpty(saleBudget)) {
          results.purchaseBudget = Number(saleBudget);
        } else if (isNotEmpty(purchaseBudget)) {
          results.saleBudget = Number(purchaseBudget);
        }
        results.margin = undefined;
      }
    }
    /*Si modification du “taux de marque” →
     * recalcule automatique du ““tarif jour/montant payé par le client”
     *   (pas de modification du tarif jour/montant perçu par l’intervenant)
     */
    if (field === 'expensesConfig.marginType' && isNotEmpty(value)) {
      if (isNotEmpty(margin)) {
        results.margin = Number(margin);
        if (isNotEmpty(purchaseBudget) && fieldsForCalculation.purchaseBudget) {
          results.purchaseBudget = Number(purchaseBudget);
          results.saleBudget = calculateClientRateFromMarginAndContractorRate(
            Number(purchaseBudget),
            Number(margin), //margin
            value as EMarginType
            //true //round
          );
        } else if (isNotEmpty(saleBudget) && fieldsForCalculation.saleBudget) {
          results.saleBudget = Number(saleBudget);
          results.purchaseBudget =
            calculateContractorRateFromMarginAndClientRate(
              Number(saleBudget), // saleBudget
              Number(margin),
              value as EMarginType
              //true //round
            );
        }
      } else {
        if (
          isNotEmpty(saleBudget) &&
          isNotEmpty(purchaseBudget) &&
          fieldsForCalculation.saleBudget &&
          fieldsForCalculation.purchaseBudget
        ) {
          results.saleBudget = Number(saleBudget);
          results.purchaseBudget = Number(purchaseBudget);
          results.margin = getMarginOrMarkup(
            Number(saleBudget),
            Number(purchaseBudget),
            value as EMarginType
          );
        }
      }
    }
    /*Si modification du “tarif jour/montant perçu par l’intervenant”  →
        recalcule automatique du “tarif jour/montant payé par le client”
        (pas de modification du taux de marque)*/
    if (field === 'expensesConfig.purchaseBudget') {
      if (!isNotEmpty(value)) {
        results.purchaseBudget = '';
      } else {
        results.purchaseBudget = Number(value);
        if (isNotEmpty(margin) && fieldsForCalculation.margin) {
          results.margin = Number(margin);
          results.saleBudget = calculateClientRateFromMarginAndContractorRate(
            Number(value), // purchaseBudget
            Number(margin),
            marginType
            //true //round
          );
        } else if (isNotEmpty(saleBudget) && fieldsForCalculation.saleBudget) {
          results.saleBudget = Number(saleBudget);
          results.margin = getMarginOrMarkup(
            Number(saleBudget),
            Number(value),
            marginType
          );
        }
      }
    }
    /* Si modification du “tarif jour/montant payé par le client” →
      recalcule automatique du “tarif jour/montant perçu par l’intervenant”
      (pas de modification du taux de marque)
      */
    if (field === 'expensesConfig.saleBudget') {
      if (!isNotEmpty(value)) {
        results.saleBudget = '';
      } else {
        results.saleBudget = Number(value);
        if (isNotEmpty(margin) && fieldsForCalculation.margin) {
          results.margin = Number(margin);
          results.purchaseBudget =
            calculateContractorRateFromMarginAndClientRate(
              Number(value), // saleBudget
              Number(margin),
              marginType
              //true //round
            );
        } else if (
          isNotEmpty(purchaseBudget) &&
          fieldsForCalculation.purchaseBudget
        ) {
          results.purchaseBudget = Number(purchaseBudget);
          results.margin = getMarginOrMarkup(
            Number(value),
            Number(purchaseBudget),
            marginType
          );
        }
      }
    }

    setRoundedValues({
      margin: margin ? round(Number(results?.margin)) : undefined,
      saleBudget: results?.saleBudget
        ? round(Number(results?.saleBudget))
        : undefined,
      purchaseBudget: results?.purchaseBudget
        ? round(Number(results?.purchaseBudget))
        : undefined,
    });

    setValue(
      'expensesConfig.margin',
      isNotEmpty(results.margin) ? round(results.margin) : ''
    );
    setValue(
      'expensesConfig.saleBudget',
      isNotEmpty(results.saleBudget) ? round(results?.saleBudget) : ''
    );
    setValue(
      'expensesConfig.purchaseBudget',
      isNotEmpty(results.purchaseBudget) ? round(results.purchaseBudget) : ''
    );
    setFieldBlur(undefined);

    // Due to the fact that we have a Partial Form
    // we are adding not rounded data to form
    // because only rounded data from input are sent to submit function
    // and we need to send NOT rounded data to the api

    register('expensesConfigRaw');
    setValue('expensesConfigRaw', results);
  };
  useEffect(() => {
    if (!expensesConfig?.saleBudget && activeBudget === true) {
      setActiveBudget(false);
    }
  }, [expensesConfig]);
  return (
    <Box>
      <Flex mb={30}>
        <CheckSwitch
          id="isEnabled"
          {...register('expensesConfig.isEnabled')}
          onClick={(e: any) => {
            const isEnabled = e?.target?.checked;
            SetExpensesEnabled(isEnabled);
            if (!isEnabled) {
              setRoundedValues({
                margin: 0,
                saleBudget: 0,
                purchaseBudget: 0,
              });
              setValue('expensesConfig.margin', 0);
              setValue('expensesConfig.saleBudget', 0);
              setValue('expensesConfig.purchaseBudget', 0);
              setValue('expensesConfigRaw', {
                margin: 0,
                saleBudget: 0,
                purchaseBudget: 0,
              });
            }
          }}
          checked={expensesEnabled}
          isDisabled={isDisabled}
        >
          <Text>Activer les frais</Text>
        </CheckSwitch>
      </Flex>
      {expensesEnabled && (
        <>
          <Flex mb={20}>
            <CheckSwitch
              id="activeBudget"
              checked={activeBudget}
              isDisabled={isDisabled}
              onClick={(e: any) => {
                const isActive = e?.target?.checked;
                setActiveBudget(isActive);
                if (!isActive) {
                  setValue('expensesConfig.saleBudget', '');
                  setValue('expensesConfig.purchaseBudget', '');
                  setValue('expensesConfigRaw', {
                    margin: expensesConfig?.margin,
                    saleBudget: 0,
                    purchaseBudget: 0,
                  });

                  setRoundedValues({
                    ...roundedValues,
                    saleBudget: 0,
                    purchaseBudget: 0,
                  });
                }
              }}
            >
              <Text>Définir un budget</Text>
            </CheckSwitch>
          </Flex>

          <Flex>
            <CheckSwitch
              id="shouldJoinAttachment"
              {...register('expensesConfig.shouldJoinAttachment')}
              isDisabled={isDisabled}
            >
              <Text>Justificatif requis</Text>
            </CheckSwitch>
          </Flex>
          <Box mt={20}>
            <FormControl
              label="Type de taux"
              required
              errorMessage={errors?.expensesConfig?.marginType?.message}
            >
              <>
                <Radio
                  {...register('expensesConfig.marginType', {
                    required: 'Ce champ est requis',
                    isDisabled: isDisabled,
                  })}
                  // Taux de marque
                  value={EMarginType.MARGIN}
                  isDisabled={isDisabled}
                  onChange={(e: any) => {
                    setValue('marginType', EMarginType.MARGIN);
                    calculateBudgetAndMargin(
                      'expensesConfig.marginType',
                      EMarginType.MARGIN
                    );
                  }}
                >
                  Taux de Marque
                </Radio>
                <Radio
                  {...register('expensesConfig.marginType', {
                    required: 'Ce champ est requis',
                    isDisabled: isDisabled,
                  })}
                  // Taux de marge
                  value={EMarginType.MARKUP}
                  isDisabled={isDisabled}
                  onChange={(e: any) => {
                    setValue('expensesConfig.marginType', EMarginType.MARKUP);
                    calculateBudgetAndMargin(
                      'expensesConfig.marginType',
                      EMarginType.MARKUP
                    );
                  }}
                >
                  Taux de Marge
                </Radio>
              </>
            </FormControl>
          </Box>
          <Link
            mb={20}
            iconLeft={<RefreshIcon />}
            isDisabled={isDisabled}
            onClick={() => {
              //@ts-ignore
              setValue('expensesConfig.margin', '');
              //@ts-ignore
              setValue('expensesConfig.saleBudget', '');
              //@ts-ignore
              setValue('expensesConfig.purchaseBudget', '');
              setRoundedValues({
                //@ts-ignore
                margin: '',
                //@ts-ignore
                saleBudget: '',
                //@ts-ignore
                purchaseBudget: '',
              });
            }}
          >
            Effacer les montants
          </Link>

          <Flex justifyContent={'space-between'} flexWrap="wrap">
            <Box width={1 / 3}>
              <FormControl
                label={`Taux de ${marginTypeLabel}`}
                errorMessage={errors?.expensesConfig?.margin?.message}
              >
                <Input
                  {...register('expensesConfig.margin', {
                    validate: (value: number) =>
                      value >= 100 || value < 0
                        ? 'La valeur doit être comprise entre 0 et 99%'
                        : undefined,
                  })}
                  step="0.001"
                  type="number"
                  onSelect={() => getCalculationFields()}
                  isDisabled={isDisabled}
                  onChange={(e: any) => {
                    setValue('expensesConfig.margin', e?.target?.value);
                    calculateBudgetAndMargin(
                      'expensesConfig.margin',
                      e?.target?.value
                    );
                  }}
                  isFullWidth
                  value={roundedValues?.margin}
                  pr={20}
                  icon={<PercentIcon style={{ paddingRight: 20 }} />}
                />
              </FormControl>
            </Box>
            <Box width={2 / 3}>
              <Row hidden={activeBudget === false} spacing={20}>
                <FormControl
                  label={`Montant HT enveloppe client`}
                  errorMessage={errors?.expensesConfig?.saleBudget?.message}
                  warningMessage={
                    isOutOfRangeSaleBudget
                      ? `Attention le montant est inférieur aux montants déclarés ${consumedSaleBudget}€`
                      : undefined
                  }
                >
                  <Input
                    step="0.001"
                    isFullWidth
                    type="number"
                    icon={<EuroIcon />}
                    onBlur={() => setFieldBlur('expensesConfig.saleBudget')}
                    {...register('expensesConfig.saleBudget', {
                      validate: (value: any) => {
                        if (activeBudget && value <= 0) {
                          return 'Veuillez définir un budget';
                        }
                      },
                    })}
                    onSelect={() => getCalculationFields()}
                    isDisabled={isDisabled}
                    onChange={(e: any) => {
                      setValue('expensesConfig.saleBudget', e?.target?.value);
                      // if (isNotEmpty(e?.target?.value)) {
                      calculateBudgetAndMargin(
                        'expensesConfig.saleBudget',
                        e?.target?.value
                      );
                      // }
                    }}
                    value={
                      roundedValues?.saleBudget === 0
                        ? ''
                        : roundedValues?.saleBudget
                    }
                  />
                </FormControl>
                <FormControl
                  label={`Montant HT enveloppe fournisseur`}
                  errorMessage={errors?.expensesConfig?.purchaseBudget?.message}
                  warningMessage={
                    isOutOfRangePurchageBudget
                      ? `Attention le montant est inférieur aux montants déclarés ${consumedPurchaseBudget}€`
                      : undefined
                  }
                >
                  <Input
                    step="0.001"
                    {...(register('expensesConfig.purchaseBudget'),
                    {
                      validate: (value: any) => {
                        if (activeBudget && value <= 0) {
                          return 'Veuillez définir un budget';
                        }
                      },
                    })}
                    onBlur={() => setFieldBlur('expensesConfig.purchaseBudget')}
                    isDisabled={isDisabled}
                    onSelect={() => getCalculationFields()}
                    onChange={(e: any) => {
                      setValue(
                        'expensesConfig.purchaseBudget',
                        e?.target?.value
                      );
                      calculateBudgetAndMargin(
                        'expensesConfig.purchaseBudget',
                        e?.target?.value
                      );
                    }}
                    value={
                      roundedValues?.purchaseBudget === 0
                        ? ''
                        : roundedValues?.purchaseBudget
                    }
                    isFullWidth
                    type="number"
                    icon={<EuroIcon />}
                  />
                </FormControl>
              </Row>
            </Box>
          </Flex>
          <FormControl
            mt={isOutOfRangePurchageBudget || isOutOfRangeSaleBudget ? 20 : 0}
            label="Description enveloppe frais"
          >
            <TextAreaControlled
              minRows={3}
              name="expensesConfig.description"
              control={control}
              isDisabled={isDisabled}
            />
          </FormControl>
        </>
      )}
    </Box>
  );
};
