export const getDefaultInvoiceAdress = (establishment: any) => {
  const customerEstablishment = establishment?.customer;
  const establishmentAddress = customerEstablishment?.address;

  const invoiceAdresses = establishment?.customer?.invoiceAddress;
  const defaultAddress = invoiceAdresses?.find(
    (e: any) => e.default && !e.locked
  );

  return defaultAddress || establishmentAddress;
};
