import {
  EBillingType,
  EMissionStatus,
  EMissionStructure,
  EMissionType,
} from '@freelancelabs/teoreme-commons';
import { Roles as RolesTypes } from '@freelancelabs/inside-commons/dist/lib/models/enum/role.enum';
import {
  Flex,
  Box,
  FormLabel,
  DatePickerRange,
  Input,
  Link,
  Row,
} from 'components/ui';
import {
  PURCHASE_ORDER_CLIENT_STATUS_SELECT,
  DEFAULT_MISSION_DISPLAYCOL,
} from '@commons';
import * as React from 'react';
import { useParams } from 'react-router-dom';
import { UserSelect } from 'components/selects/UserSelect';
import { StaticSelect } from 'components/selects/StaticSelect';
import { useListMissionStore } from 'store/mission';
import { ChevronRightIcon, EuroIcon, RefreshIcon } from 'components/ui/icons';
import { Theme } from 'styles';
import { ContractorSelect } from 'components/selects/ContractorSelect';
import { RadioSelectEstablishments } from 'components/selects/RadioSelectEstablishments';
import { StaticMultiSelect } from 'components/selects/StaticMultiSelect';
const row = 1 / 1;
export const MissionsFilersList = () => {
  const { filter: filterParams } = useParams<{
    filter: 'all' | 'validated' | 'draft' | 'aborted' | 'terminated';
  }>();
  const {
    initialSate,
    searchQuery,
    startDate,
    endDate,
    managerSelected,
    commercialSelected,
    statusSelect,
    statusBDCCustomerSelect,
    typeSelected,
    structureSelected,
    //clientSelected,
    contactClientSelected,
    //providerSelected,
    multiProviderSelected,
    multiClientSelected,
    contractorSelected,
    billingTypeSelect,
    isMandateMode,
    isDirectPayment,
    minCustomerBudget,
    maxCustomerBudget,
    missionType,
    displayCol,
    limit,
    updateListMissionStore,
    resetListMissionStore,
  } = useListMissionStore();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [displayDate, setDisplayDate] = React.useState<
    'createdAt' | 'validatedAt' | 'abortedAt' | 'endAt' | 'startAt'
  >('startAt');

  React.useEffect(() => {
    if (filterParams === 'all') {
      setDisplayDate('startAt');
      updateListMissionStore({
        //@ts-ignore
        order: 'desc',
        initialSate,
        sortedBy: 'createdAt',
        page: 0,
      });
    }
    if (filterParams === 'validated') {
      setDisplayDate('startAt');
      updateListMissionStore({
        //@ts-ignore
        order: 'desc',
        initialSate,
        sortedBy: 'validatedAt',
        page: 0,
      });
    }
    if (filterParams === 'aborted') {
      setDisplayDate('startAt');
      updateListMissionStore({
        //@ts-ignore
        order: 'desc',
        initialSate,
        sortedBy: 'abortedAt',
        page: 0,
      });
    }
    if (filterParams === 'draft') {
      setDisplayDate('startAt');
      updateListMissionStore({
        //@ts-ignore
        order: 'asc',
        initialSate,
        sortedBy: 'createdAt',
        page: 0,
      });
    }
    if (filterParams === 'terminated') {
      setDisplayDate('startAt');
      updateListMissionStore({
        //@ts-ignore
        order: 'asc',
        initialSate,
        sortedBy: 'endAt',
        page: 0,
      });
    }
  }, [filterParams, setDisplayDate]);

  // const sortString = (order === 'desc' ? '-' : '') + sortedBy;
  // const debouncedFilterQuery = useDebounce(searchQuery, 500);

  // const onSortedChange = React.useCallback(
  //   (sortBy: string, order: 'asc' | 'desc'): void => {
  //     //@ts-ignore
  //     updateListMissionStore({ sortBy, order });
  //   },
  //   []
  // );

  React.useEffect(() => {
    updateListMissionStore({ page: 0, initialSate });
  }, [filterParams, typeSelected, managerSelected]);
  return (
    <Box>
      <Flex
        display="inline-flex"
        justifyContent="flex-start"
        flexWrap={'wrap'}
        // ml={20}
        // mr={20}
        // mb={10}
      >
        <Box width={row} pr={'5px'} pt={'5px'}>
          <FormLabel>Colonnes à afficher</FormLabel>
          <StaticMultiSelect
            onChange={value => updateListMissionStore({ displayCol: value })}
            options={DEFAULT_MISSION_DISPLAYCOL}
            values={displayCol}
          />
        </Box>
        <Box width={row} pr={'5px'} pt={'5px'}>
          <FormLabel>RECHERCHER</FormLabel>
          <Input
            isFullWidth
            type="search"
            onChange={e => {
              //@ts-ignore
              updateListMissionStore({ searchQuery: e.target.value });
            }}
            value={searchQuery}
          />
        </Box>
        <Box width={row} pr={'5px'} pt={'5px'}>
          <FormLabel>Statuts</FormLabel>
          <StaticSelect
            isDisabled={filterParams !== 'all'}
            placeholder="Tous les statuts"
            options={[
              {
                label: 'Tous les statuts',
                value: 'N/A',
                key: 'N/A',
              },
              {
                label: 'Brouillons',
                value: EMissionStatus.DRAFT,
                key: EMissionStatus.DRAFT,
              },
              {
                label: 'Validées',
                value: EMissionStatus.VALIDATED,
                key: EMissionStatus.VALIDATED,
              },
              {
                label: 'Terminées',
                value: EMissionStatus.TERMINATED,
                key: EMissionStatus.TERMINATED,
              },
              {
                label: 'Annulées',
                value: EMissionStatus.ABORTED,
                key: EMissionStatus.ABORTED,
              },
              // {
              //   label: 'Validées',
              //   value: EMissionStatus.ARCHIVED,
              //   key: EMissionStatus.ARCHIVED,
              // },
            ]}
            onChange={value =>
              updateListMissionStore({ statusSelect: value as string })
            }
            value={statusSelect}
          />
        </Box>
        <Box width={row} pr={'5px'} pt={'5px'}>
          <FormLabel>Mission / avenant</FormLabel>
          <StaticSelect
            placeholder="Type de mission"
            options={[
              {
                label: 'Tous les types',
                value: 'N/A',
                key: 'N/A',
              },
              {
                label: 'Mission',
                value: 'Mission',
                key: 'Mission',
              },
              {
                label: 'Avenant',
                value: 'Amendment',
                key: 'Amendment',
              },
            ]}
            onChange={value =>
              updateListMissionStore({ typeSelected: value as string })
            }
            value={typeSelected}
          />
        </Box>
        <Box width={row} pr={'5px'} pt={'5px'}>
          <FormLabel>Type de mission</FormLabel>
          <StaticSelect
            placeholder="Type de mission"
            options={[
              {
                label: 'Tous les types',
                value: 'N/A',
                key: 'N/A',
              },
              {
                label: 'RM',
                value: EMissionType.RESOURCE_MANAGEMENT,
                key: EMissionType.RESOURCE_MANAGEMENT,
              },
              {
                label: 'TA',
                value: EMissionType.CLASSIC,
                key: EMissionType.CLASSIC,
              },
            ]}
            onChange={value =>
              updateListMissionStore({ missionType: value as string })
            }
            value={missionType}
          />
        </Box>
        <Box width={row} pr={'5px'} pt={'5px'}>
          <FormLabel>Type de tarification</FormLabel>
          <StaticSelect
            placeholder="Type de tarification"
            options={[
              {
                label: 'Tous les types',
                value: 'N/A',
                key: 'N/A',
              },
              {
                label: 'Temps passé',
                value: EBillingType.DAY,
                key: EBillingType.DAY,
              },
              {
                label: 'Forfait',
                value: EBillingType.FLAT_RATE,
                key: EBillingType.FLAT_RATE,
              },
            ]}
            onChange={value =>
              updateListMissionStore({
                billingTypeSelect: value as string,
              })
            }
            value={billingTypeSelect}
          />
        </Box>

        <Box width={row} pr={'5px'} pt={'5px'}>
          <FormLabel>Statut BDC client</FormLabel>
          <StaticSelect
            placeholder="Tous les Chargés de comptes"
            options={PURCHASE_ORDER_CLIENT_STATUS_SELECT}
            onChange={value =>
              updateListMissionStore({
                statusBDCCustomerSelect: value as string,
              })
            }
            value={statusBDCCustomerSelect}
          />
        </Box>
        <Box width={row} pr={'5px'} pt={'5px'}>
          <FormLabel>Chargés de comptes</FormLabel>
          <UserSelect
            placeholder="Tous les Chargés de comptes"
            role={RolesTypes.ACCOUNT_MANAGER}
            onChange={value =>
              updateListMissionStore({ managerSelected: value })
            }
            onChangeCompleteObject={value => {
              updateListMissionStore({
                managerSelectedCompleteObject: value,
              });
            }}
            value={managerSelected}
          />
        </Box>
        <Box width={row} pr={'5px'} pt={'5px'}>
          <FormLabel>Commercial interne</FormLabel>
          <UserSelect
            name="internalCommercial"
            role={'INTERNAL_COMMERCIAL'}
            onChange={value =>
              updateListMissionStore({ commercialSelected: value })
            }
            value={commercialSelected}
          />
        </Box>
        <Box width={row} pt={'5px'}>
          <FormLabel>Date de début</FormLabel>
          <DatePickerRange
            startDate={startDate}
            endDate={endDate}
            isClearable={true}
            setStartDate={value => {
              updateListMissionStore({ startDate: value, page: 0 });
            }}
            setEndDate={value => {
              updateListMissionStore({ endDate: value, page: 0 });
            }}
          />
        </Box>
        <Box width={row} pr={'5px'} pt={'5px'}>
          <FormLabel>Structure</FormLabel>
          <StaticSelect
            placeholder="Toutes les structure"
            options={[
              {
                label: 'Toutes les structures',
                value: 'N/A',
                key: 'N/A',
              },
              {
                label: 'FCOM',
                value: EMissionStructure.FCOM,
                key: EMissionStructure.FCOM,
              },
              {
                label: 'FONE',
                value: EMissionStructure.FONE,
                key: EMissionStructure.FONE,
              },
              {
                label: 'INOPS',
                value: EMissionStructure.INOPS,
                key: EMissionStructure.INOPS,
              },
              {
                label: 'COWORKEES',
                value: EMissionStructure.COWORKEES,
                key: EMissionStructure.COWORKEES,
              },
            ]}
            onChange={value =>
              updateListMissionStore({ structureSelected: value as string })
            }
            value={structureSelected}
          />
        </Box>
        <Box width={row} pr={'5px'} pt={'5px'}>
          <FormLabel>Mission avec Mandat</FormLabel>
          <StaticSelect
            placeholder=""
            isClearable={true}
            options={[
              {
                label: 'Oui',
                value: 1,
                key: 'isMandateMode_1',
              },
              {
                label: 'Non',
                value: 0,
                key: 'isMandateMode_0',
              },
            ]}
            onChange={value =>
              updateListMissionStore({
                isMandateMode: value as 0 | 1 | undefined,
              })
            }
            value={isMandateMode}
          />
        </Box>
        <Box width={row} pr={'5px'} pt={'5px'}>
          <FormLabel>Mission au paiement direct</FormLabel>
          <StaticSelect
            placeholder=""
            isClearable={true}
            options={[
              {
                label: 'Oui',
                value: 1,
                key: 'isDirectPayment_1',
              },
              {
                label: 'Non',
                value: 0,
                key: 'isDirectPayment_0',
              },
            ]}
            onChange={value =>
              updateListMissionStore({
                isDirectPayment: value as 0 | 1 | undefined,
              })
            }
            value={isDirectPayment}
          />
        </Box>
        <Box width={row} pr={'5px'} pt={'5px'}>
          <FormLabel>Établissement Clients</FormLabel>
          <RadioSelectEstablishments
            filter={{ customer: { $exists: true } }}
            referenceValue="uuid"
            placeholder="Tous les clients"
            onChange={value =>
              updateListMissionStore({
                multiClientSelected: value,
              })
            }
            values={multiClientSelected}
          />
        </Box>
        <Box width={row} pr={'5px'} pt={'5px'}>
          <FormLabel>Contact client</FormLabel>
          <UserSelect
            //filter={}
            //referenceValue="uuid"
            placeholder="Tous les contacts client"
            role="CUSTOMER"
            onChange={value =>
              updateListMissionStore({ contactClientSelected: value as string })
            }
            value={contactClientSelected}
          />
        </Box>
        <Box width={row} pt={'5px'} mb={'5px'}>
          <FormLabel mb={'10px'}>Montant de la commande client HT</FormLabel>
          <Row spacing={5}>
            <Flex alignItems={'center'}>
              <Input
                icon={<EuroIcon />}
                isFullWidth
                type="number"
                onChange={e => {
                  //@ts-ignore
                  updateListMissionStore({ minCustomerBudget: e.target.value });
                }}
                value={minCustomerBudget}
              />
              <ChevronRightIcon
                color={Theme.colors.primary.default}
                height={20}
                width={20}
                style={{ marginLeft: '5px' }}
              />
            </Flex>
            <Input
              icon={<EuroIcon />}
              isFullWidth
              type="number"
              onChange={e => {
                //@ts-ignore
                updateListMissionStore({ maxCustomerBudget: e.target.value });
              }}
              value={maxCustomerBudget}
            />
          </Row>
        </Box>
        <Box width={row} pr={'5px'} pt={'5px'}>
          <FormLabel>Établissements Fournisseurs</FormLabel>
          <RadioSelectEstablishments
            filter={{ 'provider.manager': { $exists: true } }}
            referenceValue="uuid"
            placeholder="tous les fournisseurs"
            onChange={value =>
              updateListMissionStore({
                multiProviderSelected: value,
              })
            }
            values={multiProviderSelected}
          />
        </Box>
        <Box width={row} pr={'5px'} pt={'5px'}>
          <FormLabel>Intervenant</FormLabel>
          <ContractorSelect
            placeholder="Tous les intervenants"
            onChange={value =>
              updateListMissionStore({ contractorSelected: value as string })
            }
            value={contractorSelected}
          />
        </Box>

        <Box width={row} pr={'5px'} pt={'5px'} mb={10}>
          <FormLabel>NB. LIGNES AFFICHÉES</FormLabel>
          <StaticSelect
            isClearable={false}
            placeholder=" "
            options={[
              {
                label: '20',
                value: 20,
                key: 20,
              },
              {
                label: '50',
                value: 50,
                key: 50,
              },
              {
                label: '100',
                value: 100,
                key: 100,
              },
            ]}
            onChange={value =>
              updateListMissionStore({ limit: value as number })
            }
            value={limit}
          />
        </Box>
        <Box>
          <Flex
            justifyContent={'center'}
            alignItems={'center'}
            flexWrap="wrap"
            alignContent="center"
            width={1 / 1}
            height={'100%'}
          >
            <Link
              isDisabled={initialSate}
              iconLeft={
                <RefreshIcon
                  fill={initialSate ? Theme?.colors?.grey?.default : undefined}
                />
              }
              mt={'5px'}
              onClick={() => (!initialSate ? resetListMissionStore({}) : false)}
            >
              Réinitialiser
            </Link>
          </Flex>
        </Box>
      </Flex>
    </Box>
  );
};
