import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Missions } from './Missions';
import { PurchaseOrders } from './PurchaseOrders';

export const Delivery = () => {
  return (
    <Switch>
      <Route exact path="/delivery">
        <Redirect to="/delivery/missions" />
      </Route>
      <Route path="/delivery/missions">
        <Missions />
      </Route>
      <Route path="/delivery/purchase-orders">
        <PurchaseOrders />
      </Route>
    </Switch>
  );
};
