import { IContractorCreateParams } from '@freelancelabs/teoreme-commons';
import {
  CONTRACTOR_SOCIAL_STATUS_SELECT,
  validateFormPhoneFormatFR,
  checkFormEmail,
} from '@commons';
import { BlocInformation } from 'components/ui';
import {
  Flex,
  Box,
  Button,
  FormControl,
  Input,
  Radio,
  Row,
  Text,
  PhoneInputControlled,
  DatePickerControlled,
  StaticSelectControlled,
} from 'components/ui';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { differenceInYears } from 'date-fns';

type FormValues = IContractorCreateParams & any;
type AddContractorFormProps = {
  onSubmit?: (formValues: FormValues) => any | void;
  email: string;
  isDisabled?: boolean;
};

export const CreateContractorWizard: React.FC<
  React.PropsWithChildren<AddContractorFormProps>
> = ({ onSubmit: onSubmitParent, email, isDisabled }) => {
  const [loading, setLoading] = useState(false);
  const {
    handleSubmit,
    control,
    register,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: { email: email },
  });

  const onSubmit = async (data: FormValues) => {
    setLoading(true);
    if (onSubmitParent) {
      onSubmitParent(data);
    }

    setLoading(false);
  };

  return (
    <Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box mb={20}>
          <FormControl
            label="Civilité"
            required
            errorMessage={errors?.contractor?.civility?.message}
          >
            <Flex>
              <Radio
                isDisabled={isDisabled}
                data-cy="contractorCivility-1"
                {...register('contractor.civility', {
                  required: 'Ce champ est requis',
                })}
                value="1"
              >
                Monsieur
              </Radio>
              <Radio
                isDisabled={isDisabled}
                data-cy="contractorCivility-2"
                {...register('contractor.civility', {
                  required: 'Ce champ est requis',
                })}
                value="2"
              >
                Madame
              </Radio>
            </Flex>
          </FormControl>
        </Box>
        <Row spacing={20}>
          <FormControl
            label="Prénom"
            required
            errorMessage={errors?.contractor?.firstName?.message}
          >
            <Input
              isDisabled={isDisabled}
              data-cy="contractorFirstName"
              isFullWidth
              {...register('contractor.firstName', {
                required: 'Ce champ est requis',
              })}
            />
          </FormControl>

          <FormControl
            label="Nom"
            required
            errorMessage={errors?.contractor?.lastName?.message}
          >
            <Input
              isDisabled={isDisabled}
              data-cy="contractoLastName"
              isFullWidth
              {...register('contractor.lastName', {
                required: 'Ce champ est requis',
              })}
            />
          </FormControl>
        </Row>
        <Row spacing={20}>
          <FormControl
            label="Date de naissance"
            required
            errorMessage={errors?.contractor?.birthday?.message}
          >
            <DatePickerControlled
              isDisabled={isDisabled}
              disabled={isDisabled}
              control={control}
              rules={{
                required: 'Ce champs est requis',
                validate: (value: any) => {
                  let age = differenceInYears(new Date(), value);
                  if (age < 18) {
                    return "L'intervenant doit etre majeur";
                  }
                  if (age >= 99) {
                    return 'Ne peut dépasser 99';
                  }
                  return undefined;
                },
              }}
              name="contractor.birthday"
            />
          </FormControl>
          <FormControl label="Téléphone" errorMessage={errors?.phone?.message}>
            <PhoneInputControlled
              isDisabled={isDisabled}
              control={control}
              rules={{
                validate: (value: string) => {
                  return validateFormPhoneFormatFR(value);
                },
              }}
              isFullWidth
              name="phone"
            />
          </FormControl>
        </Row>
        <Row spacing={20}>
          <Box>
            <FormControl
              required
              label="Email"
              errorMessage={errors?.email?.message}
            >
              <Input
                isFullWidth
                isDisabled
                {...register('email', {
                  required: 'Ce champ est requis',
                })}
                name="email"
              />
            </FormControl>
          </Box>
          <></>
        </Row>
        <Text mb={20} variant="h3">
          Informations spécifiques à l’établissement
        </Text>

        <Box>
          <Box>
            <Row spacing={20}>
              <FormControl label="Fonction">
                <Input
                  isDisabled={isDisabled}
                  data-cy="contractorJobTitle"
                  isFullWidth
                  {...register('contractor.jobTitle')}
                />
              </FormControl>
              <FormControl
                label="Téléphone lié à l'établissement"
                errorMessage={errors?.contractor?.phone?.message}
              >
                <PhoneInputControlled
                  isDisabled={isDisabled}
                  control={control}
                  rules={{
                    validate: (value: string) => {
                      return validateFormPhoneFormatFR(value);
                    },
                  }}
                  isFullWidth
                  name="contractor.phone"
                />
              </FormControl>
            </Row>
            <Row spacing={20}>
              <FormControl
                label="Email secondaire"
                errorMessage={errors?.contractor?.email?.message}
              >
                <Input
                  isDisabled={isDisabled}
                  data-cy="contractorEmail"
                  isFullWidth
                  {...register('contractor.email', {
                    validate: value => checkFormEmail(value as string),
                  })}
                  name="contractor.email"
                />
              </FormControl>
              <Box mt={20}>
                <BlocInformation>
                  <p>
                    Si besoin, précisez un second mail de l’intervenant pour
                    l'établissement fournisseur renseigné.
                  </p>
                </BlocInformation>
              </Box>
            </Row>
            <Box>
              <FormControl
                label="Statut social de l’intervenant au sein de l’établissement fournisseur"
                required
              >
                <StaticSelectControlled
                  isDisabled={isDisabled}
                  id="contractor-socialStatus-select"
                  control={control}
                  name="contractor.socialStatus"
                  required
                  options={CONTRACTOR_SOCIAL_STATUS_SELECT}
                  placeholder="Rechercher un statut"
                />
              </FormControl>
            </Box>
          </Box>
        </Box>
        <Box hidden={isDisabled}>
          <Button
            type="submit"
            isLoading={loading}
            isDisabled={loading}
            data-cy="submit-create-contactor"
          >
            Suivant
          </Button>
        </Box>
      </form>
    </Box>
  );
};
