import {
  calculateClientRateFromMarginAndContractorRate,
  calculateContractorRateFromMarginAndClientRate,
  getMarginOrMarkup,
  getWorkingDaysWithoutHolidays,
  round,
  useGetHolidays,
} from '@commons';
import {
  EBillingType,
  EMarginType,
  EMissionStatus,
  EMissionType,
  IBilling,
  IJoinedMission,
} from '@freelancelabs/teoreme-commons';
import {
  BlocInformation,
  Box,
  CheckBox,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Link,
  NumberFormInputControlled,
  Radio,
  Row,
  Text,
} from 'components/ui';
import {
  EuroIcon,
  ExclamationCircleIcon,
  PercentIcon,
  RefreshIcon,
} from 'components/ui/icons';
import { isMissionFieldEditable } from 'helpers';
import { useDebounce } from 'hooks/useDebounce';
import React, { useState } from 'react';
import { useTheme } from 'styled-components';

type FormValues = {
  billing: IBilling;
};
type BillingPartialFormProps = {
  mission: IJoinedMission;
  estimatedDays?: number;
  form: FormValues & any;
  forRole?: 'ALL' | 'CUSTOMER' | 'PROVIDER';
  from?: 'MISSION';
  isDisabled: boolean;
  setIgnoreNav?: () => void;
};

export const BillingPartialForm = ({
  mission,
  isDisabled,
  estimatedDays,
  setIgnoreNav,
  form: {
    setError,
    clearErrors,
    formState: { errors, isDirty },
    register,
    watch,
    control,
    setValue,
    getValues,
  },
  forRole = 'ALL',
  from,
}: BillingPartialFormProps) => {
  const boundMSDelay = 0;
  let billingType: string = watch('billing.type');
  const formEstimatedDays = watch('billing.estimatedDays');
  const { data: holidays } = useGetHolidays();
  const { startAt = new Date(), endAt = new Date() } = mission;
  const missionWorkingDaysWithoutHolidays = getWorkingDaysWithoutHolidays(
    startAt,
    endAt,
    holidays
  );
  const [billingChangeText, setBillingChangeText] = React.useState<
    string | false
  >(false);
  const clientRate = useDebounce(watch('billing.clientRate'), boundMSDelay);
  const contractorRate = useDebounce(
    watch('billing.contractorRate'),
    boundMSDelay
  );

  const margin = useDebounce(watch('billing.margin'), boundMSDelay);
  const [marginRounded, setMarginRounded] = useState<
    number | string | null | undefined
  >(!isNaN(margin) ? round(margin) : undefined);
  const marginType = watch('billing.marginType');
  const marginTypeLabel =
    marginType === EMarginType.MARGIN ? 'Marque' : 'Marge';
  const [fieldBlur, setFieldBlur] = useState<string | false>(false);
  if (
    billingType !== EBillingType.DAY &&
    billingType !== EBillingType.FLAT_RATE
  ) {
    billingType = EBillingType.DAY;
    setValue('billing.type', EBillingType.DAY);
  }
  if (!formEstimatedDays && estimatedDays && billingType === EBillingType.DAY) {
    setValue('billing.estimatedDays', missionWorkingDaysWithoutHolidays);
  }
  const theme = useTheme();
  const isContractor = watch('billing.isContractor');

  const [fieldsForCalculation, setFieldsForCalculation] = React.useState<{
    margin: boolean;
    contractorRate: boolean;
    clientRate: boolean;
  }>({ margin: false, contractorRate: false, clientRate: false });

  const isNotEmpty = (value: string | number | null | undefined): boolean => {
    if (value === '') return false;
    if (value === undefined) return false;
    if (value === null) return false;
    // if (value === 0) return false;
    if (Number.isNaN(value)) return false;

    return true;
  };

  const getCalculationFields = () => {
    setFieldsForCalculation({
      margin: isNotEmpty(margin),
      contractorRate: isNotEmpty(contractorRate),
      clientRate: isNotEmpty(clientRate),
    });
  };

  React.useEffect(() => {
    if (isNotEmpty(clientRate) && isNotEmpty(contractorRate)) {
      const resultMargin = getMarginOrMarkup(
        Number(clientRate),
        Number(contractorRate),
        marginType
      );
      setMarginRounded(round(resultMargin));
      setValue('billing.margin', resultMargin);
    }
  }, [marginType]);

  React.useEffect(() => {
    const missionClientRate = mission?.billing?.clientRate;
    const missionContractorRate = mission?.billing?.contractorRate;
    if (mission?.status === EMissionStatus?.VALIDATED) {
      if (
        Number(missionClientRate) !== Number(clientRate) ||
        Number(missionContractorRate) !== Number(contractorRate)
      ) {
        setBillingChangeText(
          'Attention la nouvelle tarification ne s’appliquera pas aux CRAs / Jalons et factures déja soumis ou déjà validés.'
        );
      } else {
        setBillingChangeText(false);
      }
    }
  }, [clientRate, contractorRate, clientRate, contractorRate, mission]);
  const getMarginWarning = (margin: number) => {
    if (margin !== undefined) {
      if (
        mission?.billingInformation?.missionType?.label ===
          EMissionType.RESOURCE_MANAGEMENT &&
        margin > 7
      ) {
        return 'Le taux saisi semble élevé pour une mission de RM.';
      }
      if (
        mission?.billingInformation?.missionType?.label ===
          EMissionType.CLASSIC &&
        margin < 7
      ) {
        return 'Le taux saisi semble bas pour une mission de TA.';
      }
    }

    return undefined;
  };

  /*Si modification du “tarif jour/montant perçu par l’intervenant”  →
    recalcule automatique du “tarif jour/montant payé par le client”
    (pas de modification du taux de marque)
   */
  const recalculateFieldsFromContractorRate = (
    newContractorRate: string | number | null | undefined
  ) => {
    setValue('billing.contractorRate', newContractorRate);

    if (
      isNotEmpty(newContractorRate) &&
      isNotEmpty(margin) &&
      fieldsForCalculation.margin
    ) {
      const clientResult = calculateClientRateFromMarginAndContractorRate(
        Number(newContractorRate),
        Number(margin),
        marginType,
        true //round
      );
      const notEqual =
        // eslint-disable-next-line eqeqeq
        Number(clientResult) != Number(clientRate);
      //@ts-ignore
      if (notEqual) {
        setValue('billing.clientRate', clientResult);
      }
    } else if (
      isNotEmpty(newContractorRate) &&
      isNotEmpty(clientRate) &&
      fieldsForCalculation.clientRate
    ) {
      const resultMargin = getMarginOrMarkup(
        Number(clientRate),
        Number(newContractorRate),
        marginType
      );
      setMarginRounded(round(resultMargin));
      setValue('billing.margin', resultMargin);
    }
  };

  // JIRA DOCUMENTATION :
  //fcomdev.atlassian.net/wiki/spaces/TRM/pages/289243158/OLD+Fiche+Mission+Informations+g+n+rales+Tarification+-+Consultation+modification+de+la+tarification+de+la+mission+par+le+charg+de+comptes

  /* Si modification du “tarif jour/montant payé par le client” →
    recalcule automatique du “tarif jour/montant perçu par l’intervenant”
    (pas de modification du taux de marque)
   */
  const recalculateFieldsFromClientRate = (
    newClientRate: string | number | null | undefined
  ) => {
    setValue('billing.clientRate', newClientRate);
    if (
      isNotEmpty(newClientRate) &&
      isNotEmpty(margin) &&
      fieldsForCalculation.margin
    ) {
      const contractorResult = calculateContractorRateFromMarginAndClientRate(
        Number(newClientRate),
        Number(margin),
        marginType,
        true //round
      );
      const notEqual =
        // eslint-disable-next-line eqeqeq
        Number(contractorResult) != Number(contractorRate);
      //@ts-ignore
      if (notEqual) {
        setValue('billing.contractorRate', contractorResult);
      }
    } else if (
      isNotEmpty(newClientRate) &&
      isNotEmpty(contractorRate) &&
      fieldsForCalculation.contractorRate
    ) {
      const resultMargin = getMarginOrMarkup(
        Number(newClientRate),
        Number(contractorRate),
        marginType
      );
      setMarginRounded(round(resultMargin));
      setValue('billing.margin', resultMargin);
    }
  };
  const onChangeClientRate = (e: any) => {
    const clientRateValue = isNotEmpty(e.target.value)
      ? Number(e.target.value)
      : undefined;
    setValue('billing.clientRate', clientRateValue);
    // if (isNotEmpty(contractorRate) && isNotEmpty(margin)) {
    recalculateFieldsFromClientRate(clientRateValue);
    // }
  };
  const onBlurClientRate = (e: any) => {
    const clientRateValue = e.target.value ? Number(e.target.value) : undefined;
    recalculateFieldsFromClientRate(clientRateValue);
  };

  const onChangeContractorRate = (e: any) => {
    const contractorRateValue = e.target.value
      ? Number(e.target.value)
      : undefined;
    setValue('billing.contractorRate', contractorRateValue);
    // if (isNotEmpty(clientRate) && isNotEmpty(margin)) {
    recalculateFieldsFromContractorRate(contractorRateValue);
    // }
  };
  const onBlurContractorRate = (e: any) => {
    const contractorRateValue = e.target.value
      ? Number(e.target.value)
      : undefined;
    recalculateFieldsFromContractorRate(contractorRateValue);
  };
  /* Si modification du “taux de marque” →
   recalcule automatique du ““tarif jour/montant payé par le client”
   (pas de modification du tarif jour/montant perçu par l’intervenant)
   */
  const recalculateFieldsFromMargin = (
    newMargin: string | number | null | undefined
  ) => {
    const marginValue = isNotEmpty(newMargin) ? Number(newMargin) : undefined;
    const marginValueRounded = isNotEmpty(newMargin)
      ? round(Number(newMargin))
      : '';
    const nbValue = isNotEmpty(marginValueRounded)
      ? Number(marginValueRounded)
      : 0;
    setMarginRounded(marginValueRounded);
    setValue('billing.margin', marginValue);

    if (marginType === EMarginType.MARGIN) {
      if (nbValue >= 100 || nbValue < 0) {
        setError('billing.margin', {
          message: 'La valeur doit être comprise entre 0% et 99%',
        });
        return;
      }
    }
    if (marginType === EMarginType.MARKUP) {
      if (nbValue < 0) {
        setError('billing.margin', {
          message: 'La valeur doit être supérieur à 0%',
        });
        return;
      }
    }
    clearErrors('billing.margin');

    if (
      isNotEmpty(marginValue) &&
      isNotEmpty(contractorRate) &&
      fieldsForCalculation.contractorRate
    ) {
      const clientResult = calculateClientRateFromMarginAndContractorRate(
        Number(contractorRate),
        Number(marginValue),
        marginType,
        true //round
      );
      const notEqual =
        // eslint-disable-next-line eqeqeq
        round(Number(clientResult)) != round(Number(clientRate));
      //@ts-ignore
      if (notEqual) {
        setValue('billing.clientRate', clientResult);
      }
    } else if (
      isNotEmpty(clientRate) &&
      isNotEmpty(marginValue) &&
      fieldsForCalculation.clientRate
    ) {
      const contractorResult = calculateContractorRateFromMarginAndClientRate(
        Number(clientRate),
        Number(marginValue),
        marginType,
        true //round
      );
      const notEqual =
        // eslint-disable-next-line eqeqeq
        Number(contractorResult) != Number(contractorRate);
      //@ts-ignore
      if (notEqual) {
        setValue('billing.contractorRate', contractorResult);
      }
    }
  };
  const onChangeMargin = (e: any) => {
    const marginValue = e.target.value;

    recalculateFieldsFromMargin(marginValue);
  };
  const typeDisable =
    !isMissionFieldEditable(mission, 'billing.type') || isDisabled;
  return (
    <>
      <Flex>
        {from !== 'MISSION' && (
          <Box mb={10} mr={5}>
            <FormControl
              label="Type de tarification"
              required
              errorMessage={errors?.billing?.type?.message}
            >
              <>
                <Radio
                  isDisabled={
                    !isMissionFieldEditable(mission, 'billing.type') ||
                    isDisabled
                  }
                  //disabled={mission?.status !== EMissionStatus.DRAFT}
                  {...register('billing.type', {
                    required: typeDisable ? undefined : 'Ce champ est requis',
                  })}
                  value={EBillingType.DAY}
                >
                  Temps passé
                </Radio>
                <Radio
                  isDisabled={
                    !isMissionFieldEditable(mission, 'billing.type') ||
                    isDisabled
                  }
                  //disabled={mission?.status !== EMissionStatus.DRAFT}
                  {...register('billing.type', {
                    required: typeDisable ? undefined : 'Ce champ est requis',
                  })}
                  value={EBillingType.FLAT_RATE}
                >
                  <Text fontSize={14}>Forfait</Text>
                </Radio>
              </>
            </FormControl>
          </Box>
        )}

        <Box mb={0}>
          <FormControl
            label="Type de taux"
            required
            errorMessage={errors?.billing?.marginType?.message}
          >
            {}
            <>
              <Radio
                disabled={
                  !isMissionFieldEditable(mission, 'billing.marginType') ||
                  isDisabled
                }
                {...register('billing.marginType', {
                  required: typeDisable ? undefined : 'Ce champ est requis',
                })}
                // Taux de marque
                value={EMarginType.MARGIN}
              >
                Taux de Marque
              </Radio>
              <Radio
                disabled={
                  !isMissionFieldEditable(mission, 'billing.marginType') ||
                  isDisabled
                }
                {...register('billing.marginType', {
                  required: typeDisable ? undefined : 'Ce champ est requis',
                })}
                // Taux de marge
                value={EMarginType.MARKUP}
              >
                Taux de Marge
              </Radio>
            </>
          </FormControl>
        </Box>
      </Flex>
      {billingType === EBillingType.DAY && (
        <>
          {estimatedDays !== 0 && (
            <Box mb={10}>
              <BlocInformation>
                <p data-cy="mission-estimatedDays">
                  Estimation du nombre de jours ouvrés : {estimatedDays} jours
                </p>
              </BlocInformation>
              <BlocInformation>
                <p data-cy="mission-estimatedDays">
                  Estimation du nombre de jours ouvrés sans les jours fériés:{' '}
                  {missionWorkingDaysWithoutHolidays} jours
                </p>
              </BlocInformation>
            </Box>
          )}
          {!isDisabled && (
            <Link
              mb={20}
              iconLeft={<RefreshIcon />}
              onClick={() => {
                setFieldBlur(false);
                setValue('billing.contractorRate', '');
                setValue('billing.contractorRate', '');
                setValue('billing.clientRate', '');
                setValue('billing.clientRate', '');
                setValue('billing.margin', '');
                setMarginRounded('');
              }}
            >
              Effacer les montants
            </Link>
          )}
          <Box>
            {forRole === 'ALL' && (
              <>
                <Row spacing={20}>
                  <FormControl
                    required
                    label="Nombre de jours estimé"
                    // @ts-ignore
                    errorMessage={
                      errors?.billing?.estimatedDays?.type === 'min'
                        ? 'Ne peut pas être inférieur à 1'
                        : // @ts-ignore
                          errors?.billing?.estimatedDays?.message
                    }
                  >
                    <NumberFormInputControlled
                      isDisabled={
                        !isMissionFieldEditable(
                          mission,
                          'billing.estimatedDays'
                        ) || isDisabled
                      }
                      control={control}
                      isFullWidth
                      name="billing.estimatedDays"
                      rules={{ required: 'Ce champ est requis', min: 0.1 }}
                    />
                  </FormControl>
                  <FormControl
                    required
                    label="Tarif jour payé par le client (HT)"
                    // @ts-ignore
                    errorMessage={
                      errors?.billing?.clientRate?.type === 'min'
                        ? 'Ne peut pas être inférieur à 1'
                        : // @ts-ignore
                          errors?.billing?.clientRate?.message
                    }
                  >
                    <NumberFormInputControlled
                      isDisabled={
                        !isMissionFieldEditable(
                          mission,
                          'billing.clientRate'
                        ) || isDisabled
                      }
                      control={control}
                      isFullWidth
                      onSelect={() => getCalculationFields()}
                      onChange={(e: any) => onChangeClientRate(e)}
                      // onBlur={(e: any) => onBlurClientRate(e)}
                      name="billing.clientRate"
                      data-cy="mission-clientRate-input"
                      rules={{
                        required: 'Ce champ est requis',
                        min: 1,
                        validate: (value: any) => {
                          if (value && getValues()['billing.contractorRate']) {
                            if (
                              getValues()['billing.contractorRate'] >
                              Number(value)
                            ) {
                              return "Le tarif jour payé par l'intervenant ne peut étre supérieur à celui du client.";
                            }
                          }
                          return undefined;
                        },
                      }}
                      icon={<EuroIcon />}
                    />
                  </FormControl>
                  <FormControl
                    required
                    label="Tarif jour perçu par l'intervenant (HT)"
                    // @ts-ignore
                    errorMessage={
                      errors?.billing?.contractorRate?.type === 'min'
                        ? 'Ne peut pas être inférieur à 1'
                        : // @ts-ignore
                          errors?.billing?.contractorRate?.message
                    }
                  >
                    <NumberFormInputControlled
                      isDisabled={
                        !isMissionFieldEditable(
                          mission,
                          'billing.contractorRate'
                        ) || isDisabled
                      }
                      control={control}
                      onSelect={() => getCalculationFields()}
                      onChange={(e: any) => onChangeContractorRate(e)}
                      onBlur={(e: any) => onBlurContractorRate(e)}
                      rules={{
                        validate: (value: any) => {
                          if (value && getValues()['billing.clientRate']) {
                            if (
                              getValues()['billing.clientRate'] < Number(value)
                            ) {
                              return "Le tarif jour payé par l'intervenant ne peut étre supérieur à celui du client.";
                            }
                          }
                          return undefined;
                        },
                        required: 'Ce champ est requis',
                        min: 1,
                      }}
                      isFullWidth
                      name="billing.contractorRate"
                      data-cy="mission-contractorRate-input"
                      icon={<EuroIcon />}
                    />
                  </FormControl>
                </Row>
                <FormControl
                  label={`Taux de ${marginTypeLabel}`}
                  errorMessage={errors?.billing?.margin?.message}
                  warningMessage={getMarginWarning(margin)}
                >
                  <Box>
                    <Input
                      icon={<PercentIcon />}
                      type="number"
                      maxDecimal={2}
                      step="0.01"
                      isDisabled={isDisabled}
                      // onBlur={() => setFieldBlur('margin')}
                      name="billing.margin.vue"
                      onSelect={() => getCalculationFields()}
                      onChange={(e: any) => onChangeMargin(e)}
                      //@ts-ignore
                      value={marginRounded}
                    />
                    {/* WARNING THIS FIELD USE REAL VALUE OF BILLING.MARGIN WITHOUT ROUNDING FOR HOOK-FORM DO NOT DELETE THIS*/}
                    <Box display={'none'}>
                      <NumberFormInputControlled
                        isDisabled={
                          !isMissionFieldEditable(mission, 'billing.margin') ||
                          isDisabled
                        }
                        defaultValue={round(margin)}
                        name="billing.margin"
                        onBlur={() => setFieldBlur('margin')}
                        //onSelect={() => setFieldBlur('margin')}
                        control={control}
                        onChange={(e: any) =>
                          setValue('billing.margin', e.target.value)
                        }
                        step="any"
                        // maxDecimal={2}

                        icon={<PercentIcon />}
                        rules={{
                          validate: (value: number) => {
                            if (marginType === EMarginType.MARGIN) {
                              if (Number(value) >= 100 || Number(value) < 0) {
                                return 'La valeur doit être comprise entre 0 et 99%';
                              } else {
                                return undefined;
                              }
                            }
                            if (marginType === EMarginType.MARKUP) {
                              if (Number(value) < 0) {
                                return 'La valeur doit être supérieur à 0%';
                              } else {
                                return undefined;
                              }
                            }
                          },
                        }}
                      />
                    </Box>
                  </Box>
                </FormControl>
              </>
            )}
            {forRole === 'PROVIDER' && (
              <>
                <Row spacing={20}>
                  <FormControl
                    required
                    label="Nombre de jours estimé"
                    // @ts-ignore
                    errorMessage={
                      errors?.billing?.estimatedDays?.type === 'min'
                        ? 'Ne peut pas être inférieur à 1'
                        : // @ts-ignore
                          errors?.billing?.estimatedDays?.message
                    }
                  >
                    <NumberFormInputControlled
                      isDisabled={
                        !isMissionFieldEditable(
                          mission,
                          'billing.estimatedDays'
                        ) || isDisabled
                      }
                      control={control}
                      isFullWidth
                      name="billing.estimatedDays"
                      rules={{ required: 'Ce champ est requis', min: 0.1 }}
                    />
                  </FormControl>
                  <FormControl
                    required
                    label="Tarif jour perçu par l'intervenant BDC (HT)"
                    // @ts-ignore
                    errorMessage={
                      errors?.billing?.contractorRate?.type === 'min'
                        ? 'Ne peut pas être inférieur à 1'
                        : // @ts-ignore
                          errors?.billing?.contractorRate?.message
                    }
                  >
                    <NumberFormInputControlled
                      isDisabled={
                        !isMissionFieldEditable(
                          mission,
                          'billing.contractorRate'
                        ) || isDisabled
                      }
                      control={control}
                      onSelect={() => getCalculationFields()}
                      onChange={(e: any) => onChangeContractorRate(e)}
                      onBlur={(e: any) => onBlurContractorRate(e)}
                      rules={{
                        required: 'Ce champ est requis',
                        min: 1,
                        validate: (value: any) => {
                          if (value && clientRate) {
                            if (clientRate < value) {
                              return `Le tarif jour payé par l'intervenant ne peut étre supérieur à celui du client ( ${clientRate} ).`;
                            }
                          }
                          return undefined;
                        },
                      }}
                      isFullWidth
                      name="billing.contractorRate"
                      icon={<EuroIcon />}
                    />
                  </FormControl>
                </Row>
                <FormControl
                  label={`Taux de ${marginTypeLabel}`}
                  errorMessage={errors?.billing?.margin?.message}
                  warningMessage={getMarginWarning(margin)}
                >
                  <Box>
                    <Input
                      isDisabled={isDisabled}
                      icon={<PercentIcon />}
                      type="number"
                      step="0.01"
                      onSelect={() => getCalculationFields()}
                      onChange={(e: any) => onChangeMargin(e)}
                      //@ts-ignore
                      value={isNotEmpty(marginRounded) ? marginRounded : ''}
                    />
                    {/* WARNING THIS FIELD USE REAL VALUE OF BILLING.MARGIN WITHOUT ROUNDING FOR HOOK-FORM DO NOT DELETE THIS*/}
                    <Box display={'none'}>
                      <NumberFormInputControlled
                        isDisabled={
                          !isMissionFieldEditable(mission, 'billing.margin') ||
                          isDisabled
                        }
                        defaultValue={round(margin)}
                        name="billing.margin"
                        onBlur={() => setFieldBlur('margin')}
                        //onSelect={() => setFieldBlur('margin')}
                        control={control}
                        onChange={(e: any) =>
                          setValue('billing.margin', e.target.value)
                        }
                        step="any"
                        icon={<PercentIcon />}
                        rules={{
                          validate: (value: number) => {
                            if (marginType === EMarginType.MARGIN) {
                              if (Number(value) >= 100 || Number(value) < 0) {
                                return 'La valeur doit être comprise entre 0 et 99%';
                              } else {
                                return undefined;
                              }
                            }
                            if (marginType === EMarginType.MARKUP) {
                              if (Number(value) < 0) {
                                return 'La valeur doit être supérieur à 0%';
                              } else {
                                return undefined;
                              }
                            }
                          },
                        }}
                      />
                    </Box>
                  </Box>
                </FormControl>
                {/* !!  This field is used to calculate the prices according to the Margin , do not delete !! */}
                <Box style={{ display: 'none' }}>
                  <NumberFormInputControlled
                    isDisabled={
                      !isMissionFieldEditable(mission, 'billing.clientRate') ||
                      isDisabled
                    }
                    control={control}
                    isFullWidth
                    name="billing.clientRate"
                    data-cy="mission-clientRate-input"
                    rules={{
                      required: 'Ce champ est requis',
                      min: 1,
                      validate: (value: any) => {
                        if (value && getValues()['billing.contractorRate']) {
                          if (
                            getValues()['billing.contractorRate'] >
                            Number(value)
                          ) {
                            return "Le tarif jour payé par l'intervenant ne peut étre supérieur à celui du client.";
                          }
                        }
                        return undefined;
                      },
                    }}
                    icon={<EuroIcon />}
                  />
                </Box>
              </>
            )}
            {forRole === 'CUSTOMER' && (
              <>
                <Row spacing={20}>
                  <FormControl
                    label="Nombre de jours estimé"
                    required
                    // @ts-ignore
                    errorMessage={
                      errors?.billing?.estimatedDays?.type === 'min'
                        ? 'Ne peut pas être inférieur à 1'
                        : // @ts-ignore
                          errors?.billing?.estimatedDays?.message
                    }
                  >
                    <NumberFormInputControlled
                      isDisabled={
                        !isMissionFieldEditable(
                          mission,
                          'billing.estimatedDays'
                        ) || isDisabled
                      }
                      control={control}
                      isFullWidth
                      name="billing.estimatedDays"
                      rules={{ required: 'Ce champ est requis', min: 0.1 }}
                    />
                  </FormControl>
                  <FormControl
                    required
                    label="Tarif jour payé par le client (HT)"
                    // @ts-ignore
                    errorMessage={
                      errors?.billing?.clientRate?.type === 'min'
                        ? 'Ne peut pas être inférieur à 1'
                        : // @ts-ignore
                          errors?.billing?.clientRate?.message
                    }
                  >
                    <NumberFormInputControlled
                      isDisabled={
                        !isMissionFieldEditable(
                          mission,
                          'billing.contractorRate'
                        ) || isDisabled
                      }
                      control={control}
                      onSelect={() => getCalculationFields()}
                      onChange={(e: any) => onChangeClientRate(e)}
                      onBlur={(e: any) => onBlurClientRate(e)}
                      rules={{
                        required: 'Ce champ est requis',
                        min: 1,
                        validate: (value: any) => {
                          if (value && getValues('billing.contractorRate')) {
                            if (getValues('billing.contractorRate') > value) {
                              return "Le montant perçu par l'intervenant ne peut étre supérieur à celui du client.";
                            }
                          }
                          return undefined;
                        },
                      }}
                      isFullWidth
                      name="billing.clientRate"
                      icon={<EuroIcon />}
                    />
                  </FormControl>
                </Row>
                <FormControl
                  label={`Taux de ${marginTypeLabel}`}
                  errorMessage={errors?.billing?.margin?.message}
                  warningMessage={getMarginWarning(margin)}
                >
                  <Box>
                    <Input
                      isDisabled={isDisabled}
                      icon={<PercentIcon />}
                      type="number"
                      step="0.01"
                      onSelect={() => getCalculationFields()}
                      onChange={(e: any) => onChangeMargin(e)}
                      //@ts-ignore
                      value={isNotEmpty(marginRounded) ? marginRounded : ''}
                    />
                    {/* WARNING THIS FIELD USE REAL VALUE OF BILLING.MARGIN WITHOUT ROUNDING FOR HOOK-FORM DO NOT DELETE THIS*/}
                    <Box display={'none'}>
                      <NumberFormInputControlled
                        isDisabled={
                          !isMissionFieldEditable(mission, 'billing.margin') ||
                          isDisabled
                        }
                        defaultValue={round(margin)}
                        name="billing.margin"
                        onBlur={() => setFieldBlur('margin')}
                        //onSelect={() => setFieldBlur('margin')}
                        control={control}
                        onChange={(e: any) =>
                          setValue('billing.margin', e.target.value)
                        }
                        step="any"
                        icon={<PercentIcon />}
                        rules={{
                          validate: (value: number) => {
                            if (marginType === EMarginType.MARGIN) {
                              if (Number(value) >= 100 || Number(value) < 0) {
                                return 'La valeur doit être comprise entre 0 et 99%';
                              } else {
                                return undefined;
                              }
                            }
                            if (marginType === EMarginType.MARKUP) {
                              if (Number(value) < 0) {
                                return 'La valeur doit être supérieur à 0%';
                              } else {
                                return undefined;
                              }
                            }
                          },
                        }}
                      />
                    </Box>
                  </Box>
                </FormControl>
                {/* !!  This field is used to calculate the prices according to the Margin , do not delete !! */}
                <Box style={{ display: 'none' }}>
                  <NumberFormInputControlled
                    isDisabled={
                      !isMissionFieldEditable(
                        mission,
                        'billing.contractorRate'
                      ) || isDisabled
                    }
                    control={control}
                    rules={{
                      required: 'Ce champ est requis',
                      min: 1,
                      validate: (value: any) => {
                        if (value && clientRate) {
                          if (clientRate < value) {
                            return `Le tarif jour payé par l'intervenant ne peut étre supérieur à celui du client ( ${clientRate} ).`;
                          }
                        }
                        return undefined;
                      },
                    }}
                    step="any"
                    isFullWidth
                    name="billing.contractorRate"
                    icon={<EuroIcon />}
                  />
                </Box>
              </>
            )}
          </Box>
        </>
      )}
      {billingType === EBillingType.FLAT_RATE && (
        <Box>
          {!isDisabled && (
            <Link
              mb={20}
              iconLeft={<RefreshIcon />}
              onClick={() => {
                setFieldBlur(false);
                setValue('billing.contractorRate', '');
                setValue('billing.contractorRate', '');
                setValue('billing.clientRate', '');
                setValue('billing.clientRate', '');
                setValue('billing.margin', '');
                setMarginRounded('');
              }}
            >
              Effacer les montants
            </Link>
          )}
          {forRole === 'ALL' && (
            <>
              <Row spacing={20}>
                <FormControl
                  required
                  label="Montant payé par le client (HT)"
                  // @ts-ignore
                  errorMessage={
                    errors?.billing?.clientRate?.type === 'min'
                      ? 'Ne peut pas être inférieur à 1'
                      : // @ts-ignore
                        errors?.billing?.clientRate?.message
                  }
                >
                  <NumberFormInputControlled
                    isDisabled={
                      !isMissionFieldEditable(mission, 'billing.clientRate') ||
                      isDisabled
                    }
                    control={control}
                    onSelect={() => getCalculationFields()}
                    onChange={(e: any) => onChangeClientRate(e)}
                    onBlur={(e: any) => onBlurClientRate(e)}
                    rules={{
                      required: 'Ce champ est requis',
                      min: 1,
                      validate: (value: any) => {
                        if (value && getValues()['billing.contractorRate']) {
                          if (getValues()['billing.contractorRate'] > value) {
                            return "Le montant perçu par l'intervenant ne peut étre supérieur à celui du client.";
                          }
                        }
                        if (
                          billingType === EBillingType.FLAT_RATE &&
                          value < (mission?.billing?.consumedSaleBudget || 0)
                        ) {
                          return `Le montant client ne peut étre inférieur au budget déjà consommé ${
                            mission?.billing?.consumedSaleBudget || 0
                          } €.`;
                        }
                        return undefined;
                      },
                    }}
                    isFullWidth
                    name="billing.clientRate"
                    icon={<EuroIcon />}
                  />
                </FormControl>
                <FormControl
                  required
                  label="Montant perçu par l'intervenant (HT)"
                  // @ts-ignore
                  errorMessage={
                    errors?.billing?.contractorRate?.type === 'min'
                      ? 'Ne peut pas être inférieur à 1'
                      : // @ts-ignore
                        errors?.billing?.contractorRate?.message
                  }
                >
                  <NumberFormInputControlled
                    isDisabled={
                      !isMissionFieldEditable(
                        mission,
                        'billing.contractorRate'
                      ) || isDisabled
                    }
                    control={control}
                    onSelect={() => getCalculationFields()}
                    onChange={(e: any) => onChangeContractorRate(e)}
                    onBlur={(e: any) => onBlurContractorRate(e)}
                    rules={{
                      validate: (value: any) => {
                        if (value && getValues()['billing.clientRate']) {
                          if (getValues()['billing.clientRate'] < value) {
                            return "Le montant perçu par l'intervenant ne peut étre supérieur à celui du client.";
                          }
                        }
                        if (
                          billingType === EBillingType.FLAT_RATE &&
                          value <
                            (mission?.billing?.consumedPurchaseBudget || 0)
                        ) {
                          return `Le montant fournisseur ne peut étre inférieur au budget déjà consommé ${
                            mission?.billing?.consumedPurchaseBudget || 0
                          } €.`;
                        }
                        return undefined;
                      },
                      required: 'Ce champ est requis',
                      min: 1,
                    }}
                    isFullWidth
                    name="billing.contractorRate"
                    icon={<EuroIcon />}
                  />
                </FormControl>
                <FormControl
                  label={`Taux de ${marginTypeLabel}`}
                  errorMessage={errors?.billing?.margin?.message}
                  warningMessage={getMarginWarning(margin)}
                >
                  <Box>
                    <Input
                      isDisabled={isDisabled}
                      icon={<PercentIcon />}
                      type="number"
                      step="0.01"
                      //@ts-ignore
                      value={isNotEmpty(marginRounded) ? marginRounded : ''}
                      onSelect={() => getCalculationFields()}
                      onChange={(e: any) => onChangeMargin(e)}
                    />
                    {/* WARNING THIS FIELD USE REAL VALUE OF BILLING.MARGIN WITHOUT ROUNDING FOR HOOK-FORM DO NOT DELETE THIS*/}
                    <Box display={'none'}>
                      <NumberFormInputControlled
                        isDisabled={
                          !isMissionFieldEditable(mission, 'billing.margin') ||
                          isDisabled
                        }
                        defaultValue={round(margin)}
                        name="billing.margin"
                        onBlur={() => setFieldBlur('margin')}
                        //onSelect={() => setFieldBlur('margin')}
                        control={control}
                        onChange={(e: any) =>
                          setValue('billing.margin', e.target.value)
                        }
                        step="any"
                        icon={<PercentIcon />}
                        rules={{
                          validate: (value: number) => {
                            if (marginType === EMarginType.MARGIN) {
                              if (Number(value) >= 100 || Number(value) < 0) {
                                return 'La valeur doit être comprise entre 0 et 99%';
                              } else {
                                return undefined;
                              }
                            }
                            if (marginType === EMarginType.MARKUP) {
                              if (Number(value) < 0) {
                                return 'La valeur doit être supérieur à 0%';
                              } else {
                                return undefined;
                              }
                            }
                          },
                        }}
                      />
                    </Box>
                  </Box>
                </FormControl>
              </Row>
            </>
          )}
          {forRole === 'PROVIDER' && (
            <Box>
              <FormControl
                required
                label="Montant perçu par l'intervenant (HT)"
                // @ts-ignore
                errorMessage={
                  errors?.billing?.contractorRate?.type === 'min'
                    ? 'Ne peut pas être inférieur à 1'
                    : // @ts-ignore
                      errors?.billing?.contractorRate?.message
                }
              >
                <NumberFormInputControlled
                  isDisabled={
                    !isMissionFieldEditable(
                      mission,
                      'billing.contractorRate'
                    ) || isDisabled
                  }
                  control={control}
                  onSelect={() => getCalculationFields()}
                  onChange={(e: any) => onChangeContractorRate(e)}
                  onBlur={(e: any) => onBlurContractorRate(e)}
                  rules={{
                    required: 'Ce champ est requis',
                    min: 1,
                    validate: (value: any) => {
                      if (value && clientRate) {
                        if (clientRate < value) {
                          return `Le tarif jour payé par l'intervenant ne peut étre supérieur à celui du client ( ${clientRate} ).`;
                        }
                      }
                      return undefined;
                    },
                  }}
                  name="billing.contractorRate"
                  icon={<EuroIcon />}
                />
              </FormControl>
            </Box>
          )}
          {forRole === 'CUSTOMER' && (
            <Box>
              <FormControl
                required
                label="Montant payé par le client (HT)"
                // @ts-ignore
                errorMessage={
                  errors?.billing?.clientRate?.type === 'min'
                    ? 'Ne peut pas être inférieur à 1'
                    : // @ts-ignore
                      errors?.billing?.clientRate?.message
                }
              >
                <NumberFormInputControlled
                  isDisabled={
                    !isMissionFieldEditable(mission, 'billing.clientRate') ||
                    isDisabled
                  }
                  control={control}
                  onSelect={() => getCalculationFields()}
                  onChange={(e: any) => onChangeClientRate(e)}
                  rules={{
                    required: 'Ce champ est requis',
                    min: 1,
                    validate: (value: any) => {
                      if (value && getValues()['billing.contractorRate']) {
                        if (getValues()['billing.contractorRate'] > value) {
                          return "Le montant perçu par l'intervenant ne peut étre supérieur à celui du client.";
                        }
                      }
                      if (
                        billingType === EBillingType.FLAT_RATE &&
                        value < (mission?.billing?.consumedSaleBudget || 0)
                      ) {
                        return `Le montant client ne peut étre inférieur au budget déjà consommé ${
                          mission?.billing?.consumedSaleBudget || 0
                        } €.`;
                      }
                      return undefined;
                    },
                  }}
                  name="billing.clientRate"
                  icon={<EuroIcon />}
                />
              </FormControl>
              <FormControl
                label={`Taux de ${marginTypeLabel}`}
                errorMessage={errors?.billing?.margin?.message}
                warningMessage={getMarginWarning(margin)}
              >
                <Box>
                  <Input
                    icon={<PercentIcon />}
                    type="number"
                    step="0.01"
                    onSelect={() => getCalculationFields()}
                    onChange={(e: any) => onChangeMargin(e)}
                    //@ts-ignore
                    value={isNotEmpty(marginRounded) ? marginRounded : ''}
                  />
                  {/* WARNING THIS FIELD USE REAL VALUE OF BILLING.MARGIN WITHOUT ROUNDING FOR HOOK-FORM DO NOT DELETE THIS*/}
                  <Box display={'none'}>
                    <NumberFormInputControlled
                      isDisabled={
                        !isMissionFieldEditable(mission, 'billing.margin') ||
                        isDisabled
                      }
                      defaultValue={round(margin)}
                      name="billing.margin"
                      onBlur={() => setFieldBlur('margin')}
                      //onSelect={() => setFieldBlur('margin')}
                      control={control}
                      onChange={(e: any) =>
                        setValue('billing.margin', e.target.value)
                      }
                      // maxDecimal={2}
                      // step={'0.01'}
                      icon={<PercentIcon />}
                      rules={{
                        validate: (value: number) => {
                          if (marginType === EMarginType.MARGIN) {
                            if (Number(value) >= 100 || Number(value) < 0) {
                              return 'La valeur doit être comprise entre 0 et 99%';
                            } else {
                              return undefined;
                            }
                          }
                          if (marginType === EMarginType.MARKUP) {
                            if (Number(value) < 0) {
                              return 'La valeur doit être supérieur à 0%';
                            } else {
                              return undefined;
                            }
                          }
                        },
                      }}
                    />
                  </Box>
                </Box>
              </FormControl>
            </Box>
          )}
        </Box>
      )}
      {forRole === 'CUSTOMER' && (
        <Box mb={20}>
          <CheckBox
            mb={10}
            key="billing.isContractor"
            id="billing.isContractor"
            {...register('billing.isContractor')}
          >
            <FormLabel>Afficher le montant perçu par l'intervenant</FormLabel>
          </CheckBox>
          {isContractor && billingType === EBillingType.FLAT_RATE && (
            <Box>
              <FormControl
                required
                label="Montant perçu par l'intervenant (HT)"
                // @ts-ignore
                errorMessage={
                  errors?.billing?.contractorRate?.type === 'min'
                    ? 'Ne peut pas être inférieur à 1'
                    : // @ts-ignore
                      errors?.billing?.contractorRate?.message
                }
              >
                <NumberFormInputControlled
                  isDisabled={
                    !isMissionFieldEditable(
                      mission,
                      'billing.contractorRate'
                    ) || isDisabled
                  }
                  control={control}
                  onSelect={() => getCalculationFields()}
                  onChange={(e: any) => onChangeContractorRate(e)}
                  onBlur={(e: any) => onBlurContractorRate(e)}
                  rules={{
                    required: 'Ce champ est requis',
                    min: 1,
                    validate: (value: any) => {
                      if (value && getValues()['billing.clientRate']) {
                        if (getValues()['billing.clientRate'] < value) {
                          return "Le montant perçu par l'intervenant ne peut étre supérieur à celui du client.";
                        }
                      }
                      if (
                        billingType === EBillingType.FLAT_RATE &&
                        value < (mission?.billing?.consumedPurchaseBudget || 0)
                      ) {
                        return `Le montant fournisseur ne peut étre inférieur au budget déjà consommé ${
                          mission?.billing?.consumedPurchaseBudget || 0
                        } €.`;
                      }
                      return undefined;
                    },
                  }}
                  name="billing.contractorRate"
                  icon={<EuroIcon />}
                />
              </FormControl>
            </Box>
          )}
          {isContractor && billingType === EBillingType.DAY && (
            <Box>
              <FormControl
                required
                label="TARIF JOUR PERÇU PAR L'INTERVENANT (HT)"
                // @ts-ignore
                errorMessage={
                  errors?.billing?.contractorRate?.type === 'min'
                    ? 'Supérieur à 0'
                    : // @ts-ignore
                      errors?.billing?.contractorRate?.message
                }
              >
                <NumberFormInputControlled
                  isDisabled={
                    !isMissionFieldEditable(
                      mission,
                      'billing.contractorRate'
                    ) || isDisabled
                  }
                  control={control}
                  onSelect={() => getCalculationFields()}
                  onChange={(e: any) => onChangeContractorRate(e)}
                  onBlur={(e: any) => onBlurContractorRate(e)}
                  rules={{
                    required: 'Ce champ est requis',
                    min: 1,
                    validate: (value: any) => {
                      if (value && getValues('billing.clientRate')) {
                        if (getValues('billing.clientRate') < value) {
                          return "Le montant perçu par l'intervenant ne peut étre supérieur à celui du client.";
                        }
                      }
                      return undefined;
                    },
                  }}
                  name="billing.contractorRate"
                  icon={<EuroIcon />}
                />
              </FormControl>
            </Box>
          )}
        </Box>
      )}
      {billingChangeText && (
        <Flex
          alignItems={'center'}
          border={`1px solid ${theme?.colors?.warning?.default}`}
          borderRadius={8}
          mt={'10px'}
          p={20}
        >
          <ExclamationCircleIcon fill={theme?.colors?.warning?.default} />
          <Text ml={'5px'} variant="p" color={theme?.colors?.warning?.default}>
            {billingChangeText}
          </Text>
        </Flex>
      )}
    </>
  );
};
