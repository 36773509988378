import * as React from 'react';

function DutyTimeIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 14 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2.7865 4.476C3.98181 3.5183 5.46836 2.99756 7 3C8.59375 3 10.0585 3.552 11.2135 4.476L12.3033 3.38625L13.3638 4.44675L12.274 5.5365C13.0673 6.52961 13.564 7.72652 13.7071 8.98949C13.8502 10.2525 13.6337 11.5302 13.0827 12.6756C12.5317 13.8209 11.6685 14.7875 10.5924 15.4639C9.51627 16.1404 8.27105 16.4993 7 16.4993C5.72896 16.4993 4.48374 16.1404 3.40765 15.4639C2.33156 14.7875 1.46832 13.8209 0.917295 12.6756C0.366266 11.5302 0.149832 10.2525 0.2929 8.98949C0.435969 7.72652 0.932726 6.52961 1.726 5.5365L0.636254 4.4475L1.69675 3.387L2.7865 4.47675V4.476ZM7 15C7.68944 15 8.37213 14.8642 9.00909 14.6004C9.64605 14.3365 10.2248 13.9498 10.7123 13.4623C11.1998 12.9748 11.5865 12.396 11.8504 11.7591C12.1142 11.1221 12.25 10.4394 12.25 9.75C12.25 9.06056 12.1142 8.37787 11.8504 7.74091C11.5865 7.10395 11.1998 6.5252 10.7123 6.03769C10.2248 5.55018 9.64605 5.16347 9.00909 4.89963C8.37213 4.6358 7.68944 4.5 7 4.5C5.60762 4.5 4.27226 5.05312 3.28769 6.03769C2.30313 7.02226 1.75 8.35761 1.75 9.75C1.75 11.1424 2.30313 12.4777 3.28769 13.4623C4.27226 14.4469 5.60762 15 7 15ZM7.75 9H10L6.25 13.875V10.5H4L7.75 5.62125V9ZM4 0.75H10V2.25H4V0.75Z"
        fill={props?.fill || '#003CC2'}
      />
    </svg>
  );
}

export default DutyTimeIcon;
