import {
  getEstablishmentName,
  getFullName,
  getHumanDateMonthAndYear,
  getStandByDutiesTotalAmount,
  additionalActivityGenerate,
  useAdditionalActivityUpdateOne,
  additionalActivitySendFile,
  getAdditionalActivitiesTotalAmount,
  downloadFile,
  getAdditionActivityTextType,
} from '@commons';
import {
  IJoinedAdditionalActivity,
  IJoinedAdditionalActivityWithInvoice,
  IStandByDutyLine,
  EQuoteStatus,
  EadditionalActivityType,
} from '@freelancelabs/teoreme-commons';
import {
  Flex,
  Box,
  FormControl,
  CheckSwitch,
  Text,
  LabelField,
  Button,
} from 'components/ui';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { queryClient } from '@commons';
import { ConfirmNavigationModal } from '../ConfirmNavigationModal';
import { create } from 'react-modal-promise';
import { ModalFrame, ModalProps } from '../ModalFrame';
import { showDisplayPdfModal } from '../DisplayPdfModal';
import { showSelectAdditionalActivityFileTypeDialogModal } from './SelectAdditionalActivityFileTypeDialogModal';

type FormValues = {
  shouldDisplayProviderComment: boolean;
  shouldDisplayLineDetails: boolean;
  shouldDisplayAttachments: boolean;
};
type GenerateActivityQuoteModalProps = ModalProps & {
  additionalActivity:
    | IJoinedAdditionalActivity
    | IJoinedAdditionalActivityWithInvoice;
};

export const GenerateActivityQuoteModal = ({
  additionalActivity,
  onResolve,
  isOpen,
}: GenerateActivityQuoteModalProps) => {
  const [isLoading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    watch,
    formState: { isDirty, isSubmitSuccessful },
  } = useForm<FormValues>({
    defaultValues: {
      shouldDisplayProviderComment: false,
      shouldDisplayLineDetails: false,
      shouldDisplayAttachments: false,
    },
  });
  const shouldDisplayProviderComment = watch('shouldDisplayProviderComment');
  const shouldDisplayLineDetails = watch('shouldDisplayLineDetails');
  const shouldDisplayAttachments = watch('shouldDisplayAttachments');
  const { mutateAsync: updateAdditionalActivity } =
    useAdditionalActivityUpdateOne();
  const onDownloadFile = async (file: any) => {
    try {
      const data = await downloadFile(file.fileLocation);
      if (data) {
        const reader = new FileReader();
        //@ts-ignore
        reader.readAsDataURL(data.data); // converts the blob to base64 and calls onload
        reader.onload = () => {
          const a = document.createElement('a');
          a.href = (reader.result as string) || '';
          a.download = file.fileName;

          a.click();
        };
      }
    } catch (e) {
      console.log(e);
    }
  };
  const onSubmit = async (formValues: FormValues) => {
    setLoading(true);
    try {
      const quoteFile = await additionalActivityGenerate({
        fileType:
          additionalActivity?.type === EadditionalActivityType.STAND_BY_DUTY
            ? 'STAND_BY_DUTY_QUOTE'
            : 'EXPENSES_QUOTE',
        additionalActivity: {
          uuid: additionalActivity?.uuid as string,
          shouldDisplayProviderComment:
            formValues?.shouldDisplayProviderComment,
          shouldDisplayLineDetails: formValues?.shouldDisplayLineDetails,
          shouldDisplayAttachments: formValues?.shouldDisplayAttachments,
        },
      });
      showDisplayPdfModal({
        layer: 106,
        fileLocation: quoteFile?.fileLocation,
        fileName: quoteFile?.fileName,
        confirmLabel: 'Envoyer au client',
        afterDownloadAction: async () => {
          try {
            // SAVE
            await updateAdditionalActivity({
              uuid: additionalActivity?.uuid as string,
              incQuoteCounter: true,
            });
          } catch (e) {
            //
          }
        },
        onConfirm: async () => {
          try {
            //SEND CUSTOMER
            await additionalActivitySendFile({
              uuid: additionalActivity?.uuid as string,
              fileType:
                additionalActivity?.type ===
                EadditionalActivityType.STAND_BY_DUTY
                  ? 'STAND_BY_DUTY_QUOTE'
                  : 'EXPENSES_QUOTE',
              file: {
                fileLocation: quoteFile?.fileLocation,
                fileName: quoteFile?.fileName,
                //@ts-ignore
                eTag: '',
              },
            });
            queryClient?.refetchQueries({
              queryKey: [additionalActivity?.uuid],
            });
          } catch (e) {
            //
          }
          onResolve(true);
        },
        customButtonText: 'Enregistrer sans envoyer',
        customButtonAction: async () => {
          try {
            // SAVE
            await updateAdditionalActivity({
              uuid: additionalActivity?.uuid as string,
              incQuoteCounter: true,
              quote: {
                status: EQuoteStatus?.IMPORTED,
                file: {
                  fileLocation: quoteFile?.fileLocation,
                  fileName: quoteFile?.fileName,
                  eTag: 'eTag',
                },
              },
            });
            queryClient?.refetchQueries({
              queryKey: [additionalActivity?.uuid],
            });
            queryClient?.refetchQueries({
              queryKey: ['additionalActivities'],
              type: 'active',
            });
          } catch (e) {
            //
          }
          onResolve(true);
        },
        // If user choose to download, we show a Dialog modal
        downloadConfirm: async () => {
          let pdfOutput = false;
          let wordFile: any;
          let closedModal = false;

          await showSelectAdditionalActivityFileTypeDialogModal({
            layer: 107,
            title: 'Télécharger',
            downloadPdfFile: () => {
              pdfOutput = true;
            },
            closeModal: () => {
              closedModal = true;
            },
            downloadDocFile: async () => {
              if (!wordFile) {
                wordFile = await additionalActivityGenerate({
                  // @ts-ignore
                  outputType: 'DOCX',
                  fileType:
                    additionalActivity?.type ===
                    EadditionalActivityType.STAND_BY_DUTY
                      ? 'STAND_BY_DUTY_QUOTE'
                      : 'EXPENSES_QUOTE',
                  additionalActivity: {
                    uuid: additionalActivity?.uuid as string,
                    shouldDisplayProviderComment:
                      formValues?.shouldDisplayProviderComment,
                    shouldDisplayLineDetails:
                      formValues?.shouldDisplayLineDetails,
                    shouldDisplayAttachments:
                      formValues?.shouldDisplayAttachments,
                  },
                });
              }
              await onDownloadFile(wordFile);
            },
          });

          if (closedModal) return false; // stop update and pdf download process if modal closed
          // update counter in case of download
          queryClient?.refetchQueries({ queryKey: [additionalActivity?.uuid] });
          queryClient?.refetchQueries({
            queryKey: ['additionalActivities'],
            type: 'active',
          });

          // return true to use default pdf viewer component download of the pdf else return false we already ran download of the docx file
          // returning true will start downloading the pdf.
          return pdfOutput;
        },
      });
    } catch (e) {
      //
    }
    onResolve(true);
    setLoading(false);
  };
  const textType = getAdditionActivityTextType(additionalActivity);
  return (
    <ModalFrame
      isOpen={isOpen}
      onClose={() => onResolve(false)}
      width={900}
      layer={105}
      closeIcon
    >
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <Box width={1 / 1}>
          <Text mb={20} variant="h1">
            Génération d'un devis client {textType}
          </Text>
          <Flex>
            <Box width={8 / 12}>
              <ConfirmNavigationModal active={isDirty && !isSubmitSuccessful} />
              <Box>
                <Box width={1 / 1}>
                  <FormControl label="Inclure le commentaire fournisseur">
                    <CheckSwitch
                      id="shouldDisplayProviderComment"
                      mb={10}
                      {...register('shouldDisplayProviderComment')}
                    >
                      <Text variant="h3">
                        {shouldDisplayProviderComment ? 'Oui' : 'Non'}
                      </Text>
                    </CheckSwitch>
                  </FormControl>
                </Box>
                <Box width={1 / 1}>
                  <FormControl
                    label={`Inclure le détail des lignes ${textType}`}
                  >
                    <CheckSwitch
                      id="shouldDisplayLineDetails"
                      mb={10}
                      {...register('shouldDisplayLineDetails')}
                    >
                      <Text variant="h3">
                        {shouldDisplayLineDetails ? 'Oui' : 'Non'}
                      </Text>
                    </CheckSwitch>
                  </FormControl>
                </Box>
                <Box width={1 / 1}>
                  <FormControl label="Inclure dans le devis les PJ justificatives fournies par le fournisseur">
                    <CheckSwitch
                      id="shouldDisplayAttachments"
                      mb={10}
                      {...register('shouldDisplayAttachments')}
                    >
                      <Text variant="h3">
                        {shouldDisplayAttachments ? 'Oui' : 'Non'}
                      </Text>
                    </CheckSwitch>
                  </FormControl>
                </Box>
              </Box>
            </Box>
            <Box width={4 / 12}>
              <Box width={1 / 1} borderRadius={'5px'} backgroundColor="#e5f5ff">
                <Box p={10}>
                  <LabelField
                    label="Période"
                    value={getHumanDateMonthAndYear(
                      additionalActivity?.month as Date
                    )}
                    underline
                    mb={10}
                  />
                  <LabelField
                    label="Montant HT"
                    value={
                      additionalActivity?.type ===
                      EadditionalActivityType.STAND_BY_DUTY
                        ? `${getStandByDutiesTotalAmount(
                            additionalActivity?.standByDutyLines as IStandByDutyLine[],
                            'CUSTOMER'
                          )} €`
                        : `${getAdditionalActivitiesTotalAmount(
                            [additionalActivity],
                            additionalActivity?.type,
                            'CUSTOMER',
                            false,
                            additionalActivity?.customerMargin
                          )} €`
                    }
                    boldValue
                    underline
                    mb={10}
                  />
                  <LabelField
                    label="Référence de la mission"
                    value={additionalActivity?.mission?.displayReference}
                    linkValue={`/delivery/missions/${additionalActivity?.mission?.reference}/gescom-create`}
                    underline
                    mb={10}
                  />
                  <LabelField
                    label="Client"
                    value={getEstablishmentName(
                      additionalActivity?.estCustomer
                    )}
                    linkValue={`/clients/establishments/${additionalActivity?.estCustomer?.uuid}/information`}
                    underline
                    mb={10}
                  />
                  <LabelField
                    label="Fournisseur"
                    value={getEstablishmentName(
                      additionalActivity?.estProvider
                    )}
                    linkValue={`/providers/establishments/${additionalActivity?.estProvider?.uuid}/information`}
                    underline
                    mb={10}
                  />
                  <LabelField
                    label="Intervenant"
                    value={getFullName(additionalActivity?.contractor)}
                    linkValue={`/providers/contractors/${additionalActivity?.contractor?.uuid}`}
                    underline
                    mb={10}
                  />
                </Box>
              </Box>
            </Box>
          </Flex>
          <Button type="submit" isLoading={isLoading}>
            Générer le devis
          </Button>
        </Box>
      </form>
    </ModalFrame>
  );
};

export const showGenerateActivityQuoteModal =
  create<GenerateActivityQuoteModalProps>(GenerateActivityQuoteModal);
