import { getWorkingDays, missionUpdateOne, queryClient } from '@commons';
import {
  EBillingType,
  EMissionType,
  IAdditionalService,
  IAddress,
  IBilling,
  IBillingInformation,
  IJoinedMission,
} from '@freelancelabs/teoreme-commons';
import { Section } from 'components/Section';
import { ServiceHandler } from 'components/ServiceHandler';
import { ConfirmNavigationModal } from 'components/modals/ConfirmNavigationModal';
import { BlocInformation, Box, Button, Form } from 'components/ui';
import { BillingPartialForm } from 'forms/partials/BillingPartialForm';
import { ConfigureExpensesPartialForm } from 'forms/partials/ConfigureExpensesPartialForm';
//import { ConfigureExpensesForm } from 'forms/ConfigureExpensesForm';
import { useSetCrumbs } from 'hooks/breadCrumb';
import { useCreateOrUpdateMission, useGetMission } from 'hooks/missionHooks';
import { useShowMessage } from 'hooks/useShowMessage';
import React from 'react';
import { useForm } from 'react-hook-form';
import { StandByDutyTable } from './StandByDutyTable';
import { showDialogModal } from 'components/modals/DialogModal';
import { useHistory } from 'react-router-dom';

type FormValues = {
  shouldCreatePastCra?: boolean;
  startAt?: Date;
  endAt?: Date;
  title?: string;
  location?: IAddress;
  description?: string;
  billing: IBilling;
  billingInformation?: IBillingInformation;
  isRemote?: boolean;
  nbRemoteDaysPerWeek?: number;
  expensesConfig?: any;
};
type InformationProps = {
  isDisabled: boolean;
};
export const PricingMission = ({ isDisabled }: InformationProps) => {
  const history = useHistory();
  const [isLoading, setIsLoading] = React.useState(false);
  const { mission, reference } = useGetMission();
  const [ignoreNav, setIgnoreNav] = React.useState(false);
  const form = useForm<FormValues>({
    defaultValues: {
      billing: { type: EBillingType.DAY },
      ...mission,
      shouldCreatePastCra: mission?.shouldCreatePastCra,
    },
  });
  const {
    handleSubmit,
    formState: { isDirty, isSubmitSuccessful },
    setError,
    clearErrors,
    reset,
    watch,
  } = form;
  const [createOrUpdate] = useCreateOrUpdateMission();
  const showMessage = useShowMessage();
  const onSubmit = async (data: FormValues) => {
    // expensesConfigRaw is added only in ConfigureExpensesPartialForm and only used to send data to api
    // @ts-ignore
    const expenseConfigMargin = data?.expensesConfigRaw?.margin
      ? // @ts-ignore
        Number(data?.expensesConfigRaw?.margin)
      : 0;

    const expenseConfigPurchaseBudget =
      // @ts-ignore
      data?.expensesConfigRaw?.purchaseBudget
        ? // @ts-ignore
          Number(data?.expensesConfigRaw?.purchaseBudget)
        : 0;

    const expenseConfigSaleBudget =
      // @ts-ignore
      data?.expensesConfigRaw?.saleBudget
        ? // @ts-ignore
          Number(data?.expensesConfigRaw?.saleBudget)
        : 0;

    let expensesConfig;
    if (data?.expensesConfig) {
      expensesConfig = {
        isEnabled: data?.expensesConfig?.isEnabled,
        margin: expenseConfigMargin,
        marginType: data?.expensesConfig?.marginType,
        shouldJoinAttachment: data?.expensesConfig?.shouldJoinAttachment,
        saleBudget: expenseConfigSaleBudget,
        purchaseBudget: expenseConfigPurchaseBudget,
        description: data?.expensesConfig?.description,
      };
    }

    setIsLoading(true);
    setError('description', { message: 'Ce champs est requis' });
    clearErrors('description');
    setIgnoreNav(true);
    delete data.billingInformation;
    delete data.title;
    delete data.startAt;
    //@ts-ignore
    delete data.additionalServices;
    delete data.endAt;
    try {
      const response = await createOrUpdate(reference, {
        billing: {
          clientRate: data?.billing?.clientRate,
          contractorRate: data?.billing?.contractorRate,
          estimatedDays: data?.billing?.estimatedDays,
          margin: data?.billing?.margin ? data?.billing?.margin : 0,
          type: data?.billing?.type,
          marginType: data?.billing?.marginType,
        },
        billingInformation: data?.billingInformation,
        expensesConfig,
      });
      showMessage('success', 'Vos modifications ont bien été enregistrées.');
      if (data?.billing?.margin) {
        if (
          data?.billing?.margin > 7 &&
          mission?.billingInformation?.missionType?.label ===
            EMissionType?.RESOURCE_MANAGEMENT
        ) {
          await showDialogModal({
            width: 800,
            title: 'Incohérence type de mission/taux de marque',
            text: `Le taux de marque saisi semble élevé pour une mission de RM.`,
            confirmLabel: 'Corriger',
            cancelLabel: 'Continuer',
          }).then(async action => {
            if (action) {
              history?.push(
                `/delivery/missions/${mission?.reference}/gescom-create`
              );
            }
          });
        }
        if (
          data?.billing?.margin < 7 &&
          mission?.billingInformation?.missionType?.label ===
            EMissionType?.CLASSIC
        ) {
          await showDialogModal({
            width: 800,
            title: 'Incohérence type de mission/taux de marque',
            text: `Le taux de marque saisi semble bas pour une mission de TA.`,
            confirmLabel: 'Corriger',
            cancelLabel: 'Continuer',
          }).then(async action => {
            if (action) {
              history?.push(
                `/delivery/missions/${mission?.reference}/gescom-create`
              );
            }
          });
        }
      }

      reset(response);
    } catch (e) {
      /*showMessage(
          'error',
          'Vos modifications n’ont pas pu être prises en compte dans GESCOM et n’ont pas été enregistrées dans votre fiche mission. Merci de réessayer'
        );*/
    }
    setIsLoading(false);
  };

  const onUpdateServices = async (service: IAdditionalService | false) => {
    if (service && mission) {
      const submitData = {
        reference: mission.reference,
        mission: {
          additionalServices: {
            update: [],
            add: [],
            cancel: [],
          },
        },
      };

      if (service?.uuid) {
        submitData?.mission?.additionalServices.update.push(service as never);
      } else {
        submitData?.mission?.additionalServices.add.push(service as never);
      }
      try {
        await missionUpdateOne(submitData);
        queryClient.refetchQueries({ queryKey: [mission.reference] });
      } catch (e) {
        //
      }
      // createOrUpdate(reference, {
      //   additionalServices: services,
      // });
    }
  };

  useSetCrumbs(
    [],
    [
      { label: 'prestations', path: '/delivery' },
      { label: 'missions', path: '/delivery/missions' },
      {
        label: mission?.displayReference || 'nouvelle mission',
        path: `/delivery/missions/${reference}`,
      },
      {
        label: 'informations',
        path: `/delivery/missions/${reference}/information`,
      },
    ]
  );

  const [estimatedDays, setEstimatedDays] = React.useState(0);

  const startAt = watch('startAt');
  const endAt = watch('endAt');
  const billingType = watch('billing.type');
  React.useEffect(() => {
    if (startAt && endAt) {
      setEstimatedDays(getWorkingDays(startAt, endAt));
    }
  }, [startAt, endAt, setEstimatedDays]);

  const getTarificationTypeText = () => {
    switch (mission?.billing?.type) {
      case EBillingType.DAY:
        return 'Temps passé';
      case EBillingType.FLAT_RATE:
        return 'Forfait';
      default:
        return '';
    }
  };
  return (
    <>
      <Form
        onSubmit={handleSubmit(onSubmit)}
        mb={20}
        autoComplete="off"
        data-cy="mission-form"
      >
        <ConfirmNavigationModal
          active={isDirty && ignoreNav === false && !isSubmitSuccessful}
        />

        <Section
          variant="h2"
          title={`Tarification ${getTarificationTypeText()}`}
        >
          <BillingPartialForm
            mission={mission as IJoinedMission}
            form={form}
            forRole="ALL"
            estimatedDays={estimatedDays}
            isDisabled={isDisabled}
            setIgnoreNav={() => setIgnoreNav(true)}
            from="MISSION"
          />
        </Section>

        <Section mb={20} title="Paramétrage des frais">
          {mission?.customer?.establishment?.customer?.expensesConfig
            ?.isEnabled ? (
            <ConfigureExpensesPartialForm
              form={form}
              isDisabled={isDisabled}
              expensesConfig={mission?.expensesConfig}
            />
          ) : (
            <BlocInformation>
              {!mission?.customer
                ? "Pour activer les frais sur la mission veuillez ajouter l'établissement client."
                : "L'établissement client n'autorise pas la création des frais."}
            </BlocInformation>
          )}
        </Section>

        <Button
          mt={-10}
          type="submit"
          isDisabled={isDisabled}
          isLoading={isLoading}
          data-cy="mission-save"
        >
          Enregistrer
        </Button>
      </Form>
      {billingType === EBillingType.DAY && (
        <Box>
          {mission?.customer?.establishment?.customer?.standByDutyFormulas
            ?.length ? (
            <Section
              variant="h2"
              title="Types de prestation complémentaire"
              mt={50}
            >
              <StandByDutyTable />
            </Section>
          ) : (
            <Section
              variant="h2"
              title="Types de prestation complémentaire"
              mt={50}
            >
              <BlocInformation>
                Pour activer les prestation complémentaire sur la mission
                veuillez en créer au moins une sur l'établissement client.
              </BlocInformation>
            </Section>
          )}
        </Box>
      )}
      {mission?.customer?.billingOptions?.isDirectPayment === false && (
        <Section variant="h2" title="Services complémentaires" mt={50}>
          {mission?.provider?.establishment ? (
            <ServiceHandler
              mission={mission}
              isDisabled={isDisabled}
              forRole="ALL"
              value={mission?.additionalServices || []}
              onChange={onUpdateServices}
            />
          ) : (
            <BlocInformation>
              <p>
                Pour pouvoir ajouter des services complémentaires, merci de
                renseigner les informations du fournisseur.
              </p>
            </BlocInformation>
          )}
        </Section>
      )}
    </>
  );
};
