import React, { ComponentPropsWithoutRef } from 'react';
import { Select } from '../ui';

type StaticSelectProps = {
  id?: string;
  isDisabled?: boolean;
  value?: string | number;
  onChange?: (value: string | number) => any;
  options: { label: string; value: string | number; key?: string | number }[];
  bcWhite?: Boolean;
  isClearable?: boolean;
  isOptionDisabled?: (value: any) => any;
  isLoading?: boolean;
  components?: any;
  isFieldSet?: boolean;
} & Omit<ComponentPropsWithoutRef<'input'>, 'event' | 'onChange' | 'value'>;

export const StaticSelect = ({
  options,
  value,
  onChange,
  isDisabled = false,
  isClearable,
  id,
  bcWhite,
  placeholder,
  isOptionDisabled,
  isLoading,
  components,
  isFieldSet,
}: StaticSelectProps) => {
  return (
    <Select
      isLoading={isLoading}
      bcWhite={bcWhite}
      id={id}
      value={options?.find((option: any) => option?.value === value)}
      options={options}
      onChange={(option: any) => onChange?.(option?.value)}
      isDisabled={isDisabled}
      isClearable={isClearable !== undefined ? isClearable : true}
      placeholder={placeholder}
      isOptionDisabled={(option: any) => isOptionDisabled?.(option)}
      components={components}
      isFieldSet={isFieldSet}
    />
  );
};
