import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { CRADetails } from './Details';
import { CRAList } from './List';

export const CRA = () => {
  return (
    <Switch>
      <Route exact path="/orders/cra">
        <Redirect to="/orders/cra/awaiting" />
      </Route>
      <Route path="/orders/cra/:filter(all|awaiting|validated|to-be-validated|archived|by-customer)">
        <CRAList />
      </Route>
      <Route path="/orders/cra/:refCra">
        <CRADetails />
      </Route>
    </Switch>
  );
};
