import { Flex, FormControl, Input, Button } from 'components/ui';
import { useShowMessage } from 'hooks/useShowMessage';
import React, { useState } from 'react';
import { saleforceGetOpp } from '@commons';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
type FormValues = {
  externalId: string;
};
type CreateMissionFromSfOppFormProps = {
  onSubmit?: () => void;
};

export const CreateMissionFromSfOppForm: React.FC<
  React.PropsWithChildren<CreateMissionFromSfOppFormProps>
> = () => {
  const history = useHistory();
  const {
    handleSubmit,
    register,
    watch,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {},
  });
  const externalId = watch('externalId');
  const [loading, setLoading] = useState(false);
  const showMessage = useShowMessage();
  const onSubmit = async (formValue: FormValues) => {
    try {
      setLoading(true);
      const responseGetOpp = await saleforceGetOpp({ externalId });
      const missionRef = responseGetOpp?.mission?.reference;
      if (responseGetOpp?.created === false && missionRef) {
        showMessage(
          'error',
          `La mission ${responseGetOpp?.mission?.displayReference} qui correspond à cette opportunité a déjà été créée`
        );
      }
      if (responseGetOpp?.created && missionRef) {
        history.push(`/delivery/missions/${missionRef}`);
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };
  React.useEffect(() => {}, []);
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Flex alignItems={'center'}>
        <FormControl
          label="N° opportunité Salesforce"
          errorMessage={errors?.externalId?.message}
        >
          <Input
            width={300}
            {...register('externalId', { required: 'Ce champs est requis' })}
          />
        </FormControl>
        <Button
          variant="filled"
          isLoading={loading}
          type="submit"
          mb={10}
          ml={10}
          isDisabled={!externalId}
        >
          Créer
        </Button>
      </Flex>
    </form>
  );
};
