import {
  EBillingType,
  IAddress,
  IBilling,
  IBillingInformation,
} from '@freelancelabs/teoreme-commons';
import { Button, Form } from 'components/ui';
import { MissionPartialForm } from 'forms/partials/MissionPartialForm';
import { useSetCrumbs } from 'hooks/breadCrumb';
import { useCreateOrUpdateMission, useGetMission } from 'hooks/missionHooks';
import React from 'react';
import { useForm } from 'react-hook-form';
import { ConfirmNavigationModal } from 'components/modals/ConfirmNavigationModal';
import { useShowMessage } from 'hooks/useShowMessage';
import { getCountryData, isNotEmpty } from '@commons';

type FormValues = {
  shouldCreatePastCra?: boolean;
  startAt?: Date;
  endAt?: Date;
  title?: string;
  location?: IAddress & {
    brand?: string;
    businessUnit?: string;
  };
  description?: string;
  billing: IBilling;
  billingInformation?: IBillingInformation;
  isRemote?: boolean;
  nbRemoteDaysPerWeek?: number;
};
type InformationProps = {
  isDisabled: boolean;
};
export const Information = ({ isDisabled }: InformationProps) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const { mission, reference } = useGetMission();
  const missionStatus = mission?.status;
  const [ignoreNav, setIgnoreNav] = React.useState(false);
  const form = useForm<FormValues>({
    defaultValues: {
      billing: { type: EBillingType.DAY },
      ...mission,
      shouldCreatePastCra: mission?.shouldCreatePastCra,
    },
  });
  const {
    handleSubmit,
    formState: { isDirty, isSubmitSuccessful },
    setError,
    clearErrors,
    reset,
  } = form;
  const [createOrUpdate] = useCreateOrUpdateMission();
  const showMessage = useShowMessage();
  const onSubmit = async (data: FormValues) => {
    setIsLoading(true);
    if (!data.description || data.description.length === 0) {
      setError('description', { message: 'Ce champs est requis' });
    } else {
      clearErrors('description');
      setIgnoreNav(true);
      delete data.billingInformation;
      delete data.title;
      delete data.startAt;
      //@ts-ignore
      delete data.additionalServices;
      delete data.endAt;
      try {
        const locationCountryCode =
          data?.location?.country &&
          getCountryData('alpha2', {
            name: data?.location?.country as string,
          });
        const response = await createOrUpdate(reference, {
          title: data?.title,
          shouldCreatePastCra: data?.shouldCreatePastCra,
          description: data?.description,
          isRemote: data?.isRemote || false,
          nbRemoteDaysPerWeek: data?.isRemote
            ? Number(data?.nbRemoteDaysPerWeek)
            : undefined,
          billing: {
            clientRate: data?.billing?.clientRate,
            contractorRate: data?.billing?.contractorRate,
            estimatedDays: data?.billing?.estimatedDays,
            margin: data?.billing?.margin,
            type: data?.billing?.type,
            marginType: data?.billing?.marginType,
          },
          billingInformation: data?.billingInformation,
          location: {
            recipient: isNotEmpty(data?.location?.recipient?.trim())
              ? data?.location?.recipient
              : undefined,
            additional: isNotEmpty(data?.location?.additional?.trim())
              ? data?.location?.additional
              : undefined,
            street: isNotEmpty(data?.location?.street?.trim())
              ? data?.location?.street
              : undefined,
            city: isNotEmpty(data?.location?.city?.trim())
              ? data?.location?.city
              : undefined,
            postalCode: isNotEmpty(data?.location?.postalCode?.trim())
              ? data?.location?.postalCode
              : undefined,
            country: isNotEmpty(data?.location?.country?.trim())
              ? data?.location?.country
              : undefined,
            countryCode: locationCountryCode,
            businessUnit: isNotEmpty(data?.location?.businessUnit?.trim())
              ? data?.location?.businessUnit
              : undefined,
            brand: isNotEmpty(data?.location?.brand?.trim())
              ? data?.location?.brand
              : undefined,
          },
        });
        showMessage('success', 'Vos modifications ont bien été enregistrées.');
        reset(response);
      } catch (e) {
        /*showMessage(
          'error',
          'Vos modifications n’ont pas pu être prises en compte dans GESCOM et n’ont pas été enregistrées dans votre fiche mission. Merci de réessayer'
        );*/
      }
    }
    setIsLoading(false);
  };

  useSetCrumbs(
    [],
    [
      { label: 'prestations', path: '/delivery' },
      { label: 'missions', path: '/delivery/missions' },
      {
        label: mission?.displayReference || 'nouvelle mission',
        path: `/delivery/missions/${reference}`,
      },
      {
        label: 'informations',
        path: `/delivery/missions/${reference}/information`,
      },
    ]
  );

  return (
    <>
      <Form
        onSubmit={handleSubmit(onSubmit)}
        mb={20}
        autoComplete="off"
        data-cy="mission-form"
      >
        <ConfirmNavigationModal
          active={isDirty && ignoreNav === false && !isSubmitSuccessful}
        />

        <MissionPartialForm
          mission={mission}
          missionStatus={missionStatus}
          sage={mission?.sage}
          form={form}
          forRole="ALL"
          reference={mission?.reference}
          setIgnoreNav={() => setIgnoreNav(true)}
          isDisabled={isDisabled}
        />

        <Button
          mt={10}
          type="submit"
          isDisabled={isDisabled}
          isLoading={isLoading}
          data-cy="mission-save"
        >
          Enregistrer
        </Button>
      </Form>
    </>
  );
};
