import { userFindOne, checkAllStrcutureCreatedInGescom } from '@commons';
import { useEstablishmentFindOne, useEstablishmentUpdateOne } from '@commons';
import { EstablishmentStructuredCard } from 'components/cards/EstablishmentStructuredCard';
import { Box, Flex, Link, Text, Spinner } from 'components/ui';
import { useTheme } from 'styled-components';
import { AddIcon, EditIcon } from 'components/ui/icons';
import { getFullName, getTradeNameSafe } from '@commons';
import { useBreadCrumb } from 'hooks/breadCrumb';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { showAddEstablishmentGescomModal } from 'components/modals/AddEstablishmentGescomModal';
import { showUpdateEstaClientManager } from 'components/modals/UpdateEstaClientManager';
import { showUpdateEstaInternalCommercial } from 'components/modals/UpdateEstaInternalCommercial';
import { showUpdateMarginModal } from 'components/modals/clients/UpdateMarginModal';
import { queryClient } from '@commons';

export const ClientsEstablishmentsInformation = () => {
  const theme = useTheme();
  const { uuid } = useParams<{ uuid: string }>();
  const { data: establishment } = useEstablishmentFindOne(uuid);
  const { setCrumbs } = useBreadCrumb();
  const { mutateAsync: updateEstablishment } = useEstablishmentUpdateOne();
  const [fetchingUpdateManager, setFetchingUpdateManager] =
    React.useState<boolean>(false);
  const [
    fetchingUpdateInternalCommercial,
    setFetchingUpdateInternalCommercial,
  ] = React.useState<boolean>(false);
  const [accountManager, setAccountManager] = React.useState(
    establishment?.customer?.accountManager
  );
  const [internalCommercial, setInternalCommercial] = React.useState(
    establishment?.customer?.internalCommercial as any
  );
  useEffect(() => {
    setCrumbs([
      {
        label: 'clients',
        path: '/clients',
      },
      {
        label: 'établissements',
        path: `/clients/establishments`,
      },
      {
        label: establishment ? getTradeNameSafe(establishment) : '-',
        path: `/clients/establishments/${establishment?.uuid}`,
      },
      {
        label: 'informations',
        path: `/clients/establishments/${establishment?.uuid}/information`,
      },
    ]);

    const fetchAccountManager = async (uuid: string) => {
      const fetchedAccountManager = await userFindOne(uuid);
      setAccountManager(fetchedAccountManager);
    };
    const fetchInternalCommercial = async (uuid: string) => {
      const fetchedInternalCommercial = await userFindOne(uuid);
      setInternalCommercial(fetchedInternalCommercial);
    };

    if (typeof establishment?.customer?.accountManager === 'string') {
      fetchAccountManager(establishment?.customer?.accountManager);
    } else {
      setAccountManager(establishment?.customer?.accountManager);
    }
    if (typeof establishment?.customer?.internalCommercial === 'string') {
      fetchInternalCommercial(establishment?.customer?.internalCommercial);
    } else {
      setInternalCommercial(establishment?.customer?.internalCommercial);
    }
  }, [establishment]);

  // this cannot really happen, but make typescript easier
  if (!establishment) return null;
  const { sage } = establishment?.customer;
  const onUpdateManager = async (manager: string) => {
    try {
      setFetchingUpdateManager(true);
      const establishmentData = {
        uuid: uuid,
        establishment: {
          customer: {
            accountManager: manager,
          },
        },
      };
      await updateEstablishment(establishmentData as any);
      await queryClient.refetchQueries({ queryKey: [uuid] });
    } catch (err) {
      //console.log(err);
    }
    setFetchingUpdateManager(false);
  };
  const onUpdateInternalCommercial = async (internalCommercial: string) => {
    try {
      setFetchingUpdateInternalCommercial(true);
      const establishmentData = {
        uuid: uuid,
        establishment: {
          customer: {
            internalCommercial,
          },
        },
      };
      await updateEstablishment(establishmentData as any);
      await queryClient.refetchQueries({ queryKey: [uuid] });
    } catch (err) {
      //console.log(err);
    }
    setFetchingUpdateInternalCommercial(false);
  };
  const checkSageData = checkAllStrcutureCreatedInGescom(sage);
  return (
    <Box>
      <Box display={'inline-flex'}>
        <Text variant="h2" mb={20}>
          Identification de l'établissement
        </Text>
        {!checkSageData?.isReady ? (
          <Link
            ml={3}
            iconLeft={<EditIcon />}
            mt={'5px'}
            onClick={() =>
              showAddEstablishmentGescomModal({
                establishment: establishment as any,
              })
            }
          >
            {`Créer dans GESCOM (
            ${checkSageData?.missingStructure?.map(
              structure => `${structure} `
            )} )`}
          </Link>
        ) : (
          <></>
        )}
      </Box>
      <EstablishmentStructuredCard
        forRole={'CUSTOMER'}
        mb={20}
        isFullWidth
        establishment={establishment}
        checkSage={true}
      />
      <Box mb={20}>
        <Text variant="h2" mb={20}>
          Chargé de compte assigné
          <Link
            iconLeft={<EditIcon scale={20} />}
            ml={10}
            onClick={() =>
              showUpdateEstaClientManager({
                manager: accountManager?.cognitoUserId,
              }).then(manager => {
                if (manager) {
                  onUpdateManager(manager);
                }
              })
            }
          >
            Modifier
          </Link>
        </Text>

        {accountManager &&
          !fetchingUpdateManager &&
          getFullName(accountManager)}
        {fetchingUpdateManager && (
          <Spinner color={theme.colors.primary.default} />
        )}
      </Box>
      <Box mb={20}>
        <Text variant="h2" mb={20}>
          Commercial interne assigné
          <Link
            iconLeft={<EditIcon scale={20} />}
            ml={10}
            onClick={() =>
              showUpdateEstaInternalCommercial({
                internalCommercial: internalCommercial?.cognitoUserId,
              }).then((internalCommercial: any) => {
                if (internalCommercial) {
                  onUpdateInternalCommercial(internalCommercial);
                }
              })
            }
          >
            Modifier
          </Link>
        </Text>

        {internalCommercial &&
          !fetchingUpdateInternalCommercial &&
          getFullName(internalCommercial)}
        {fetchingUpdateInternalCommercial && (
          <Spinner color={theme.colors.primary.default} />
        )}
      </Box>
      <Text mb={10} mt={20} variant="h2">
        Taux de marque
        {establishment.customer?.margin ? (
          <Link
            iconLeft={<EditIcon scale={20} />}
            ml={10}
            onClick={() => showUpdateMarginModal({ establishment })}
          >
            Modifier
          </Link>
        ) : (
          <Flex
            alignItems="center"
            justifyContent="center"
            backgroundColor="primary.lighter"
            p={50}
            mb={20}
          >
            <Link
              onClick={() => showUpdateMarginModal({ establishment })}
              iconLeft={<AddIcon />}
            >
              Ajouter le taux de marque
            </Link>
          </Flex>
        )}
      </Text>
      {!!establishment.customer?.margin && (
        <Box>
          <Text variant="p">{establishment.customer?.margin} %</Text>

          <Text variant="p" mt={10}>
            Commentaire : {establishment.customer?.marginComment || 'N/A'}
          </Text>
        </Box>
      )}
    </Box>
  );
};
