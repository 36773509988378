import { queryClient, useAdminTaskManage, getErrorMessage } from '@commons';
import { ETaskManageAction, ITask } from '@freelancelabs/teoreme-commons';
import { Button, Flex, Box, Text } from 'components/ui';
import { getIdentifierByTaskType, taskTypeMapping } from 'helpers/business';
import { useShowMessage } from 'hooks/useShowMessage';
import React, { useState } from 'react';
import { create } from 'react-modal-promise';
import { ModalFrame, ModalProps } from '../ModalFrame';
type Props = ModalProps & {
  beforeValidation?: () => void;
  tasks: ITask[];
  action: ETaskManageAction;
};
export const ManageTaskModal = ({ action, tasks, onResolve }: Props) => {
  const { mutateAsync: taskManage } = useAdminTaskManage();
  const showMessage = useShowMessage();
  const [isLoading, setIsLoading] = useState(false);
  const getTextType = () => {
    switch (action) {
      case ETaskManageAction.RESOLVE:
        return 'résoudre';
      case ETaskManageAction.CANCEL:
        return 'annuler';
      case ETaskManageAction.RETRY:
        return 'relancer';
    }
  };
  const text = getTextType();
  const onSubmit = async () => {
    setIsLoading(true);

    try {
      const response = await taskManage({
        uuids: tasks?.map(t => t.uuid),
        action: action,
      });
      queryClient.refetchQueries({
        queryKey: ['admin-tasks-search'],
        type: 'active',
      });
      if (response?.hasError) {
        response?.errors?.forEach((err: any) =>
          showMessage('error', getErrorMessage(err?.errorCode))
        );
      } else {
        showMessage('success', 'Action réussi');
      }
    } catch (e) {
      setIsLoading(false);
    }
    setIsLoading(false);
  };
  return (
    <ModalFrame
      isOpen={true}
      onClose={() => onResolve(false)}
      width={700}
      closeIcon
    >
      <Box>
        <Text mb={20} variant="h1">
          Êtes-vous sûr de vouloir {text} les tâches suivantes ?
        </Text>
        <Box maxHeight={500} overflow="scroll">
          {tasks?.map(task => {
            const identifier = getIdentifierByTaskType(task);
            return (
              <Text mb={10}>
                {taskTypeMapping?.[task?.type]} : {identifier?.value}
              </Text>
            );
          })}
        </Box>
        <Flex mt={20} width={1 / 1}>
          <Button isLoading={isLoading} onClick={() => onSubmit()}>
            Résoudre
          </Button>
        </Flex>
      </Box>
    </ModalFrame>
  );
};

export const showManageTaskModal = create<Props, boolean>(ManageTaskModal);
