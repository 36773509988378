import { useContractorFindOne } from '@commons';
import { IJoinedContractor } from '@freelancelabs/teoreme-commons';
import { Box, SpinnerBox } from 'components/ui';
import { ValidateContractorForm } from 'forms/contractors/ValidateContractorForm';
import React from 'react';
import { useParams } from 'react-router-dom';

export const ValidateContractor = () => {
  const { id } = useParams<{ id: string }>();
  const { data: contractor, status } = useContractorFindOne(id);
  const loading = status === 'pending';
  return (
    <Box>
      {loading && <SpinnerBox />}
      {!loading && (
        <ValidateContractorForm
          isEditable={false}
          contractor={contractor as IJoinedContractor}
        />
      )}
    </Box>
  );
};
