import { NotFoundIcon } from 'components/ui/icons';
import { Box, Flex, Link, Text } from 'components/ui';
import * as React from 'react';
// type FormValues = {
//   email: string;
//   password: string;
//   confirmPassword: string;
// };
type PageNotFoundProps = {
  customText?: string;
  top?: number;
};
export const PageNotFound = ({ customText, top }: PageNotFoundProps) => {
  return (
    <Flex
      top={top ? top : 0}
      left={0}
      bottom={0}
      right={0}
      alignItems="center"
      justifyContent="center"
      position="fixed"
    >
      <Flex alignItems="center" justifyContent="center">
        <NotFoundIcon />
        <Box>
          <Text variant="h1">Oups !</Text>
          <Text variant="p">
            {customText
              ? customText
              : 'La page que vous cherchez semble introuvable.'}
          </Text>
          <Text variant="p">
            Vous avez besoin d’aide ? <Link>Contactez-nous</Link>
          </Text>
        </Box>
      </Flex>
    </Flex>
  );
};
export default PageNotFound;
