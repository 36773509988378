import { isEqual } from 'lodash';
import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';
type Crumb = {
  label: string;
  path: string;
};
export const BreadCrumbContext = createContext<{
  crumbs: Crumb[];
  setCrumbs: any;
}>({ crumbs: [], setCrumbs: null });

export const BreadCrumbProvider = ({ children }: { children: ReactNode }) => {
  const [crumbs, setCrumbsRaw] = useState<Crumb[]>([]);
  let title = '';
  if (Array.isArray(crumbs) && crumbs?.length > 0) {
    crumbs?.forEach(c => {
      title = title + c.label + ' ';
    });
  } else {
    title = 'Connecteed';
  }
  const capitalizeTitle = title?.[0]?.toUpperCase() + title?.slice(1);
  document.title = capitalizeTitle;
  const setCrumbs = (newCrumbs: Crumb[]) => {
    if (!isEqual(crumbs, newCrumbs)) setCrumbsRaw(newCrumbs);
  };

  return (
    <BreadCrumbContext.Provider value={{ crumbs, setCrumbs }}>
      {children}
    </BreadCrumbContext.Provider>
  );
};

export const useBreadCrumb = () => useContext(BreadCrumbContext);
export const useSetCrumbs = (variables: any[], crumbs: Crumb[]) => {
  const { setCrumbs } = useContext(BreadCrumbContext);
  useEffect(() => {
    setCrumbs(crumbs);
  }, variables);
};
