import { TopBar } from 'components/TopBar';
import { Text, Container, Flex, Box, SpinnerBox } from 'components/ui';
import { CardStats } from 'components/cards';
import { useBreadCrumb } from 'hooks/breadCrumb';
import {
  MissionIcon,
  TimeLapsIcon,
  EuroIcon,
  DutyTimeIcon,
  FeesIcon,
  MileStoneIcon,
  BankIcon,
} from 'components/ui/icons';
import LastNotifications from './LastNotifications';
import { LastTraces } from './LastHistory';
import { useTheme } from 'styled-components';
import { useToBeValidatedStats } from '@commons';

export const Home = () => {
  const { setCrumbs } = useBreadCrumb();
  const { data: stats, isFetching: isFetchingStats } = useToBeValidatedStats();
  setCrumbs([
    {
      label: `Tableau de bord`,
      path: '/dashboard',
    },
  ]);
  const theme = useTheme();
  const iconColor = theme.colors?.blue;
  const iconSize = 30;
  const cra = stats?.cra;
  const invoiceProvider = stats?.invoiceProvider;
  const invoiceCustomerProjected = {
    totalCountProjected: stats?.invoiceCustomer?.totalCountProjected,
    projected: stats?.invoiceCustomer?.projected,
  };
  const invoiceCustomerValidated = {
    validated: stats?.invoiceCustomer?.validated,
    totalCountValidated: stats?.invoiceCustomer?.totalCountValidated,
  };
  const ribUpdateRequests = stats?.ribUpdateRequests;
  const expenses = stats?.expense;
  const milestones = stats?.milestone;
  const standByDuties = stats?.standByDuty;
  const customerCreditNoteValidatedData =
    //@ts-ignore
    stats?.customerCreditNoteValidatedData;
  //@ts-ignore
  const mission = stats?.mission;
  const actionsWith = 1 / 5;
  return (
    <>
      <TopBar />
      <Container>
        <Box
          width={'100%'}
          display={'inline-flex'}
          justifyContent={'space-between'}
          flexWrap="wrap"
        >
          <Box width={'100%'}>
            <Text mt={20} mb={40} variant="h1">
              Tableau de bord
            </Text>
            {!isFetchingStats ? (
              <Box>
                <Text mb={20} variant="h3">
                  Mes actions
                </Text>
                <Flex
                  justifyContent={'center'}
                  alignItems={'center'}
                  flexWrap={'wrap'}
                  mb={20}
                >
                  <Box width={actionsWith}>
                    <CardStats
                      label={'Mes C.R.A à valider'}
                      value={`${cra?.toBeValidated} sur ${cra?.totalCountToBeValidated}`}
                      link="/orders/cra/to-be-validated"
                      icon={
                        <TimeLapsIcon
                          height={'1em'}
                          width={'1em'}
                          fontSize={iconSize}
                          fill={iconColor}
                        />
                      }
                    />
                  </Box>
                  <Box width={actionsWith}>
                    <CardStats
                      label={'Mes factures fournisseur à valider'}
                      value={`${invoiceProvider?.toBeValidated} sur ${invoiceProvider?.totalCountToBeValidated}`}
                      link="/orders/invoices/provider/time-spent/to-be-validated"
                      icon={<EuroIcon fontSize={iconSize} fill={iconColor} />}
                    />
                  </Box>
                  <Box width={actionsWith}>
                    <CardStats
                      label={'Mes factures client à créer'}
                      value={`${invoiceCustomerProjected?.projected} sur ${invoiceCustomerProjected?.totalCountProjected}`}
                      link="/orders/invoices/customer/time-spent/projected"
                      icon={<EuroIcon fontSize={iconSize} fill={iconColor} />}
                    />
                  </Box>
                  <Box width={actionsWith}>
                    <CardStats
                      label={'Mes prestations comp. à valider '}
                      link="/orders/additional-activities/to-be-validated"
                      value={`${standByDuties?.toBeValidated} sur ${standByDuties?.totalCountToBeValidated}`}
                      icon={
                        <DutyTimeIcon fontSize={iconSize} fill={iconColor} />
                      }
                    />
                  </Box>
                  <Box width={actionsWith}>
                    <CardStats
                      label={'Mes frais à valider '}
                      link="/orders/additional-activities/to-be-validated"
                      value={`${expenses?.toBeValidated} sur ${expenses?.totalCountToBeValidated}`}
                      icon={
                        <FeesIcon width={25} height={25} fill={iconColor} />
                      }
                    />
                  </Box>
                  <Box width={actionsWith}>
                    <CardStats
                      label={'Mes jalons à valider '}
                      link="/orders/milestones/to-be-validated"
                      value={`${milestones?.toBeValidated} sur ${milestones?.totalCountToBeValidated}`}
                      icon={<MileStoneIcon fontSize={25} fill={iconColor} />}
                    />
                  </Box>
                  <Box width={actionsWith}>
                    <CardStats
                      label={'Mes factures clients à envoyer '}
                      link="/orders/invoices/customer/time-spent/validated"
                      value={`${invoiceCustomerValidated?.validated} sur ${invoiceCustomerValidated?.totalCountValidated}`}
                      icon={<EuroIcon fontSize={iconSize} fill={iconColor} />}
                    />
                  </Box>
                  <Box width={actionsWith}>
                    <CardStats
                      label={'Mes avoirs clients à envoyer '}
                      link="/orders/invoices/customer/credit-note/validated"
                      value={`${customerCreditNoteValidatedData?.validated} sur ${customerCreditNoteValidatedData?.totalCountValidated}`}
                      icon={<EuroIcon fontSize={iconSize} fill={iconColor} />}
                    />
                  </Box>
                  {/* <Box width={actionsWith}>
                    <CardStats
                      label={'Mes DPAE à valider '}
                      link="/providers/dpae/to-be-validated"
                      value={`${dpae?.toBeValidated} sur ${dpae?.totalCountToBeValidated}`}
                      icon={
                        <DocumentIcon fontSize={iconSize} fill={iconColor} />
                      }
                    />
                  </Box> */}
                  <Box width={actionsWith}>
                    <CardStats
                      label={'Mes missions en brouillon'}
                      link="/delivery/missions/draft"
                      value={`${mission?.draft} sur ${mission?.totalCountDraft}`}
                      icon={
                        <MissionIcon fontSize={iconSize} fill={iconColor} />
                      }
                    />
                  </Box>
                  {/* <Box width={actionsWith}>
                    <CardStats
                      label={'Mes intervenants à valider '}
                      link="/providers/contractors/pending"
                      value={`${contractor?.toBeValidated} sur ${contractor?.totalCountToBeValidated}`}
                      icon={<UserIcon fontSize={iconSize} fill={iconColor} />}
                    />
                  </Box> */}
                  <Box width={actionsWith}>
                    <CardStats
                      label={'Coordonnées bancaires à valider'}
                      link="/providers/validate-bank-account/pending"
                      value={`${ribUpdateRequests?.toBeValidated} sur ${ribUpdateRequests?.totalCountToBeValidated}`}
                      icon={<BankIcon fontSize={iconSize} fill={iconColor} />}
                    />
                  </Box>
                </Flex>
              </Box>
            ) : (
              <SpinnerBox />
            )}
          </Box>
          <Flex width={'100%'}>
            <Box mt={40} width={'60%'}>
              {localStorage.token && <LastNotifications />}
            </Box>
            {/* backgroundColor={Theme?.colors?.lightGray}*/}
            <Box width={'38%'}>
              <Box mt={32}>{localStorage.token && <LastTraces />}</Box>
            </Box>
          </Flex>
        </Box>
      </Container>
    </>
  );
};
export default Home;
