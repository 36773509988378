/* eslint-disable react-hooks/rules-of-hooks */
import {
  ADDITIONAL_SERVICES_TYPE_FR,
  MAX_FILE_SIZE_ANNEXE_MB,
  displayMonth,
  GenerateInvoice,
  getServiceValues,
  getTradeNameSafe,
  useInvoiceUpdateOne,
  useUserFindMany,
  useUserFindOne,
  useDownloadFile,
  replaceSpecificCaractereInFileName,
  missionUpdateOne,
  isStandByDutyInvoice,
  isDeliveryNoteInvoice,
  isTimeSpentInvoice,
  round,
  additionalActivityFindOne,
  MAX_CUSTOMER_REFERENCE_FIELD_LENGTH,
  MAX_PAYMENT_DEADLINE_END_OF_MONTH,
  MAX_PAYMENT_DEADLINE_FIXED,
  isNotSameContacts,
  isMileStoneInvoice,
  getInvoiceTotalAmount,
  isDisableFONEDate,
  isCreditNoteInvoice,
  isAdditionalActivityInvoice,
  getOppositeInvoiceType,
  invoiceFindMany,
  getSructure,
} from '@commons';
import {
  EAdditionalServiceType,
  EInvoicePaymentLabel,
  EInvoiceSendMethod,
  EInvoiceStatus,
  EPriceUnit,
  ICra,
  IEstablishment,
  IInvoiceGetResponse,
  IInvoiceLine,
  EFileType,
  EInvoiceType,
  IJoinedInvoice,
  IJoinedAdditionalActivity,
  EEmailRecipientType,
  IEmailContact,
  EAdditionalServiceStatus,
  EBillingType,
  EMissionStructure,
  IIban,
} from '@freelancelabs/teoreme-commons';
import { showAddContactModal } from 'components/modals/AddContactModal';
import { showDialogModal } from 'components/modals/DialogModal';
import { ShowUpdateInvoiceLineText } from 'components/modals/invoices/UpdateInvoiceLineTextModal';
import { Section } from 'components/Section';
import { UserSelect } from 'components/selects/UserSelect';
import {
  ALink,
  BlocInformation,
  Box,
  Button,
  CustomToolTip,
  Flex,
  FormControl,
  Input,
  LabelField,
  Link,
  Radio,
  Row,
  SpinnerBox,
  StaticSelectControlled,
  Tab,
  Table,
  Text,
  TextAreaControlled,
  FormLabel,
  FileListControlled,
  CheckSwitch,
} from 'components/ui';
import { AddIcon, EditIcon, EyeIcon } from 'components/ui/icons';
import { useShowMessage } from 'hooks/useShowMessage';
import { electronicFormatIBAN } from 'ibantools';
//@ts-ignore
import jsonQ from 'jsonq';
import React, { useState } from 'react';
import { format as formatDate } from 'date-fns';
import { useForm } from 'react-hook-form';
import { queryClient } from '@commons';
import { useTheme } from 'styled-components';
import { AddEmailContactForm } from 'forms/AddEmailContactForm';

type FormValues = any;
type EditTimeSpentFormProps = {
  invoice?: IInvoiceGetResponse;
  onClose: () => void;
};
const OVERRIDE_PRESTATION_TYPE = 'PRESTATION';
const getPriceByUnit = (unit: EPriceUnit): string => {
  switch (unit) {
    case EPriceUnit.PERCENT_INVOICE:
      return '% du montant de la facture du payeur';

    case EPriceUnit.FLAT_PRICE:
    default:
      return `€`;

    case EPriceUnit.PRICE_PER_WORKING_DAY:
      return `Jour(s)`;

    case EPriceUnit.PRICE_PER_DAY:
      return `Jour(s)`;

    case EPriceUnit.PRICE_PER_MONTH:
      return `Mois`;

    case EPriceUnit.PRICE_PER_YEAR:
      return `Ans`;
    case EPriceUnit.ONE_TIME_PERCENT:
      return `% du montant de la facture en paiement unique`;
    case EPriceUnit.ONE_TIME_PRICE:
      return `€ en paiement unique`;
  }
};

/**
 * Resolve the description to use for the performance line of the invoice (depending on the type of the invoice)
 * @param invoice
 */
export const resolvePerformanceDescription = (
  invoice: IJoinedInvoice
): string => {
  const customInvoiceDesc =
    invoice?.invoiceIssue?.customDescription === ''
      ? ' '
      : invoice?.invoiceIssue?.customDescription;
  const missionCustomInvoiceDesc =
    invoice.mission?.customer?.customInvoicesDescription === ''
      ? ' '
      : invoice.mission?.customer?.customInvoicesDescription;

  return customInvoiceDesc || missionCustomInvoiceDesc || ' ';
};

const Actions = ({ item, onUpdate }: { item: any; onUpdate: any }) => {
  const Theme = useTheme();
  console.log('item', item);

  const [backGroundDescription, setBackGroundDescription] = useState(false);
  const [backGroundTitle, setBackGroundTitle] = useState(false);
  const [description, setDescription] = useState(item?.comment);
  const [comment, setComment] = useState(item?.description);
  const onUpdateTitle = (value: string) => {
    setComment(value);
    onUpdate({ ...item, comment: description, description: value });
  };
  const onUpdateDescription = (value: string, updateMission?: boolean) => {
    setDescription(value);
    onUpdate({ ...item, comment: value, description: comment, updateMission });
  };

  return (
    <Flex
      alignItems={'center'}
      justifyContent={'space-between'}
      flexWrap={'wrap'}
      width={1 / 1}
      cursor={item?.type !== OVERRIDE_PRESTATION_TYPE ? 'pointer' : 'initial'}
    >
      <Box
        width={1 / 1}
        mb={'5px'}
        backgroundColor={backGroundDescription ? 'lightBlue' : 'initial'}
        onMouseOver={() =>
          item?.type !== OVERRIDE_PRESTATION_TYPE
            ? setBackGroundDescription(true)
            : false
        }
        onMouseLeave={() =>
          item?.type !== OVERRIDE_PRESTATION_TYPE
            ? setBackGroundDescription(false)
            : false
        }
        onClick={() =>
          item?.type !== OVERRIDE_PRESTATION_TYPE
            ? ShowUpdateInvoiceLineText({
                text: comment,
                label: 'Titre',
              })?.then(
                //@ts-ignore
                value => (value ? onUpdateTitle(value) : false)
              )
            : false
        }
      >
        {item?.type !== OVERRIDE_PRESTATION_TYPE ? (
          <CustomToolTip
            text={'Cliquer pour modifier le titre'}
            id={item?.key + 'title'}
          >
            <Text fontSize={9}>{comment}</Text>
          </CustomToolTip>
        ) : (
          <Text fontSize={9}>{comment}</Text>
        )}
      </Box>
      <Box
        width={1 / 1}
        backgroundColor={backGroundTitle ? 'lightBlue' : 'initial'}
        onMouseOver={() => setBackGroundTitle(true)}
        onMouseLeave={() => setBackGroundTitle(false)}
        onClick={() =>
          item?.type === OVERRIDE_PRESTATION_TYPE
            ? ShowUpdateInvoiceLineText({
                text: description,
                label: 'Description',
                type: item?.type,
              })?.then(value =>
                value
                  ? //@ts-ignore
                    onUpdateDescription(value?.textValue, value?.updateMission)
                  : false
              )
            : ShowUpdateInvoiceLineText({
                text: description,
                label: 'Description',
              })?.then(value =>
                //@ts-ignore
                value ? onUpdateDescription(value as string) : false
              )
        }
      >
        <CustomToolTip
          text={'Cliquer pour modifier la description'}
          id={item?.key + '_description'}
        >
          <Flex width={1 / 1} cursor="pointer">
            <Text width={10 / 12} fontSize={9}>
              {description !== undefined
                ? description?.split('\n')?.map((text: string) => (
                    <>
                      {text}
                      <br />
                    </>
                  ))
                : 'N/A'}
            </Text>
            <Flex width={2 / 12} justifyContent="flex-end">
              <EditIcon fill={Theme?.colors?.blue} />
            </Flex>
          </Flex>
        </CustomToolTip>
      </Box>
    </Flex>
  );
};

const columns = (
  services: IInvoiceLine[],
  onUpdate: (service: IInvoiceLine, index?: number) => any,
  onDelete: (service: IInvoiceLine, index?: number) => any,
  cra: ICra,
  invoice: IJoinedInvoice,
  additionalActivity?: IJoinedAdditionalActivity
) => [
  {
    key: 'type',
    text: 'Nature des travaux effectués / prestation',
    render: (item: IInvoiceLine) => {
      //@ts-ignore
      if (item?.type === OVERRIDE_PRESTATION_TYPE) {
        return <Actions item={item} onUpdate={onUpdate} />;
      }
      if (item?.type === EAdditionalServiceType.FAST_CASH) {
        return (
          <Actions
            item={{
              ...item,
              // description: ADDITIONAL_SERVICES_TYPE_FR[item?.type].replace(
              //   '{deadline}',
              //   //@ts-ignore
              //   item?.data?.deadline
              // ),
            }}
            onUpdate={onUpdate}
          />
        );
      } else {
        return (
          <Actions
            item={{
              ...item,
              // @ts-ignore
              description: `${ADDITIONAL_SERVICES_TYPE_FR[item?.type] ?? ''} ${
                item?.description || ''
              }`,
            }}
            onUpdate={onUpdate}
          />
        );
      }
    },
  },
  {
    key: 'quantity',
    text: 'QTÉ',
    render: (item: any) => {
      //@ts-ignore
      if (item?.type === OVERRIDE_PRESTATION_TYPE) {
        if (isTimeSpentInvoice(invoice?.invoiceType)) {
          return <Text minWidth={'80px'}>{item?.nbWorkingDays}</Text>;
        }
        if (
          isStandByDutyInvoice(invoice?.invoiceType) ||
          isDeliveryNoteInvoice(invoice?.invoiceType)
        ) {
          return <Text minWidth={'80px'}>1</Text>;
        }
      } else {
        if (isTimeSpentInvoice(invoice?.invoiceType)) {
          if (item?.unit === EPriceUnit?.PRICE_PER_WORKING_DAY) {
            return <Text minWidth={'80px'}>{cra?.nbWorkingDays}</Text>;
          }
          return <Text minWidth={'80px'}>{item?.amount || 1}</Text>;
        }
        if (
          isStandByDutyInvoice(invoice?.invoiceType) ||
          isDeliveryNoteInvoice(invoice?.invoiceType)
        ) {
          return <Text minWidth={'80px'}>1</Text>;
        }
      }
      return <Text minWidth={'80px'}>{item?.amount || 1}</Text>;
    },
  },
  {
    key: 'unit',
    text: 'Unité',
    render: (item: any) => {
      //@ts-ignore
      const isPayedByClient = invoice?.mission?.additionalServices?.find(
        as =>
          !as?.paidByProvider &&
          as.type === EAdditionalServiceType.FAST_CASH &&
          as.status === EAdditionalServiceStatus.ACTIVE
      );

      if (item?.type === OVERRIDE_PRESTATION_TYPE) {
        if (isMileStoneInvoice(invoice?.invoiceType)) {
          return <Text minWidth={'80px'}> Forfait</Text>;
        }
        if (isTimeSpentInvoice(invoice?.invoiceType)) {
          return <Text minWidth={'80px'}> Jour(s)</Text>;
        }
        if (
          isStandByDutyInvoice(invoice?.invoiceType) ||
          isDeliveryNoteInvoice(invoice?.invoiceType)
        ) {
          return <Text minWidth={'80px'}>Forfait</Text>;
        }
      } else {
        return (
          <Text minWidth={'80px'}>
            {getPriceByUnit(item?.unit)?.replace(
              'payeur',
              isPayedByClient ? 'fournisseur' : 'payeur'
            )}
          </Text>
        );
      }
    },
  },
  {
    key: 'unitPrice',
    text: 'Prix unitaire',
    render: (item: any) => {
      //@ts-ignore
      if (item?.type === OVERRIDE_PRESTATION_TYPE) {
        if (isTimeSpentInvoice(invoice?.invoiceType)) {
          return (
            <Text minWidth={'80px'}>{item?.clientRate ?? item?.price} €</Text>
          );
        }
        if (isMileStoneInvoice(invoice?.invoiceType)) {
          return (
            <Text minWidth={'80px'}>{round(invoice?.clientRate || 0)} €</Text>
          );
        }
        if (
          isStandByDutyInvoice(invoice?.invoiceType) ||
          isDeliveryNoteInvoice(invoice?.invoiceType)
        ) {
          return (
            <Text minWidth={'80px'}>
              {round(invoice?.totalBeforeTaxes as number)} €
            </Text>
          );
        }
      } else {
        // fix TEOR-4190
        // const totalAmountHT =
        //   (cra?.nbWorkingDays || 0) * (cra?.contractorRate || 0);
        const totalAmountHT = getInvoiceTotalAmount(
          invoice,
          'CUSTOMER',
          additionalActivity
        );
        let price = item?.price || item?.amount;
        const data = getServiceValues(
          totalAmountHT,
          cra?.nbWorkingDays as number,
          item,
          invoice
        );
        const isPercent = item?.unit?.includes('PERCENT');
        if (isPercent) {
          price = data?.price;
        }
        return <Text minWidth={'80px'}>{price} €</Text>;
      }
    },
  },
  {
    key: 'price',
    text: 'Montant',

    render: (item: IInvoiceLine) => {
      //@ts-ignore
      if (item?.type === OVERRIDE_PRESTATION_TYPE) {
        if (isTimeSpentInvoice(invoice?.invoiceType)) {
          //@ts-ignore
          return <Text>{item?.nbWorkingDays * item?.clientRate} €</Text>;
        }
        if (isMileStoneInvoice(invoice?.invoiceType)) {
          return (
            <Text minWidth={'80px'}>{round(invoice?.clientRate || 0)} €</Text>
          );
        }
        if (
          isStandByDutyInvoice(invoice?.invoiceType) ||
          isDeliveryNoteInvoice(invoice?.invoiceType)
        ) {
          return (
            <Text minWidth={'80px'}>
              {round(invoice?.totalBeforeTaxes as number)} €
            </Text>
          );
        }
      } else {
        // const price = item?.price;
        // const unit = item?.unit;
        // const totalAmountHT =
        //   (cra?.nbWorkingDays || 0) * (cra?.contractorRate || 0);
        const totalAmountHT = getInvoiceTotalAmount(
          invoice,
          'CUSTOMER',
          additionalActivity
        );
        const data = getServiceValues(
          totalAmountHT,
          cra?.nbWorkingDays as number,
          item,
          invoice
        );
        return <Text minWidth={'80px'}>{data?.price} €</Text>;
      }
    },
  },
];

export const GenerateCustomerInvoiceForm = ({
  invoice,
  onClose,
}: EditTimeSpentFormProps) => {
  const showMessage = useShowMessage();
  const [fileLocation, setFileLocation] = useState<string>();
  const [file, setFile] = useState<string>();
  const [firstPreview, setFirstPreview] = useState(false);
  const [directPaymentItems, setDirectPaymentItems] = useState<any>([]);
  const [additionalInvoiceContacts, setAdditionalInvoiceContacts] = useState(
    invoice?.invoiceIssue?.contacts?.filter(
      c => c?.recipientType !== EEmailRecipientType?.TO
    ) || []
  );
  const [editAInvoiceContactEmail, setEditAInvoiceContactEmail] = useState<
    string | boolean
  >(false);
  const { mutateAsync: updateInvoice } = useInvoiceUpdateOne();
  const [additionalActivity, setAdditionalActivity] = useState<
    undefined | IJoinedAdditionalActivity
  >();
  const getInvoiceTo = () => {
    let invoiceTO = invoice?.invoiceIssue?.contacts?.find(
      c => c?.recipientType === EEmailRecipientType.TO
    );
    // if default contact dosen't have recipientType
    if (!invoiceTO) {
      if (invoice?.invoiceIssue?.contacts?.length === 1) {
        invoiceTO = {
          ...invoice?.invoiceIssue?.contacts[0],
          recipientType: EEmailRecipientType.TO,
        };
      }
    }
    return invoiceTO;
  };
  const invoiceTO = getInvoiceTo();
  const isMarginInvoice = invoice?.isMarginInvoice;
  const overridePrestationText = isMarginInvoice
    ? 'Marge Freelance pour son activité propre'
    : isTimeSpentInvoice(invoice?.invoiceType as EInvoiceType)
      ? `Prestation du mois ${displayMonth(invoice?.month as Date)}`
      : isStandByDutyInvoice(invoice?.invoiceType as EInvoiceType)
        ? `Prestation complémentaire/Interventions HNO du mois ${displayMonth(
            invoice?.month as Date
          )}`
        : isDeliveryNoteInvoice(invoice?.invoiceType as EInvoiceType)
          ? `Frais refacturables du mois ${displayMonth(invoice?.month as Date)}`
          : isMileStoneInvoice(invoice?.invoiceType as EInvoiceType)
            ? 'Montant forfaitaire'
            : 'undefined';
  let items =
    invoice?.invoiceLines?.map(u => ({
      key: u.uuid || String(new Date().getTime()),
      ...u,
    })) || [];

  const directPaymentColumns = () => [
    {
      key: 'workNature',
      text: 'Nature des travaux effectués Prestations',
      render: (item: any) => {
        if (item?.type === OVERRIDE_PRESTATION_TYPE) {
          return (
            <Actions
              item={{
                ...item,
                comment: invoice?.invoiceIssue?.customDescription,
                description: item?.workNature,
              }}
              onUpdate={onAdd}
            />
          );
        }
        return (
          <Text minWidth={'80px'} fontSize={9}>
            {item?.workNature}
          </Text>
        );
      },
    },
    {
      key: 'totalBeforeTaxes',
      text: 'Montant HT',
      render: (item: any) => {
        return <Text>{item?.totalBeforeTaxes}</Text>;
      },
    },
    {
      key: 'vatRate',
      text: 'Tva 20 (%)',
      render: (item: any) => {
        return <Text>{item?.vatRate}</Text>;
      },
    },
    {
      key: 'totalWithTaxes',
      text: 'Montant TTC',
      render: (item: any) => {
        return <Text>{item?.totalWithTaxes}</Text>;
      },
    },
  ];

  const getDefaultComment = () => {
    let commentCustomDescription;
    if (isTimeSpentInvoice(invoice?.invoiceType as EInvoiceType)) {
      commentCustomDescription =
        invoice?.invoiceIssue?.customDescription !== undefined
          ? invoice?.invoiceIssue?.customDescription
          : invoice?.mission?.customer?.customInvoicesDescription;
    }

    return commentCustomDescription;
  };
  items = [
    {
      // TODO DELETE THIS AFTER BACK  READY
      //@ts-ignore
      type: OVERRIDE_PRESTATION_TYPE,
      uuid: 'OVERRIDE_PRESTATION_TYPE',
      description: `${overridePrestationText}`,
      comment: getDefaultComment(),
      ...invoice?.cra,
      clientRate:
        !invoice?.isMarginInvoice && invoice?.mission?.isMandateMode
          ? invoice?.contractorRate
          : invoice?.clientRate,
    },
    ...items,
  ];
  const invoiceContact =
    invoice?.invoiceIssue?.contacts?.find(
      c => c?.recipientType === EEmailRecipientType.TO
    ) || invoice?.estCustomer?.customer?.invoiceContact;
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { data: selectedUser } = useUserFindMany(
    invoiceContact?.email
      ? {
          filterObject: {
            email: invoiceContact?.email,
          },
        }
      : undefined
  );
  const { data: missionCustomerContact } = useUserFindOne(
    invoice?.mission?.customer?.contact
  );
  const [contactSelected, setContactSelected] = useState(
    invoiceContact || selectedUser?.users?.[0] || missionCustomerContact
  );
  const [invoiceLinesUpdated, setInvoiceLinesUpdated] = useState(
    invoice?.invoiceLines
  );
  const [hasSetContact, setHasSetContact] = useState(false);

  const getDefaultIban = () => {
    if (invoice?.invoiceIssue?.iban) {
      return invoice?.invoiceIssue?.iban;
    }
    let matchIbanUuid = false;
    //@ts-ignore
    const ibans = invoice?.ibans;
    // const establishmentMatch = ibans?.forEach((IB: any) => {
    //   IB?.scopes?.forEach((scope: any) => {
    //     scope?.customers?.forEach((estaCustomer: any) => {
    //       if (estaCustomer === invoice?.estCustomer?.siren) {
    //         matchIbanUuid = IB?.uuid;
    //         return IB?.uuid;
    //       }
    //     });
    //   });
    // });
    if (!matchIbanUuid) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const primaryMatch = ibans?.forEach((IB: any) => {
        IB?.scopes?.forEach((scope: any) => {
          // TODO SEARCH BY STRCUTURE FCOM /FONE
          if (
            scope?.primary &&
            scope?.structure === invoice?.mission?.billingInformation?.structure
          ) {
            matchIbanUuid = IB?.uuid;
            return IB?.uuid;
          }
        });
      });
    }
    return matchIbanUuid;
  };

  const resolveDirectPaymentFileData = async (
    customerInvoice: IJoinedInvoice
  ) => {
    const currencyFormatter = new Intl.NumberFormat('fr-FR', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    const directPaymentSpecialTreatment: any = {};
    let originalProviderInvoice;
    let originalCustomerInvoice: any = customerInvoice;
    let multiplier = 1;
    if (customerInvoice.invoiceIssue?.isDirectPayment === true) {
      // if customer invoice is creditNote special treatement here
      if (isCreditNoteInvoice(customerInvoice.invoiceType)) {
        // redit note direct paiment treatment
        multiplier = -1;
        if (isAdditionalActivityInvoice(originalCustomerInvoice.invoiceType)) {
          const invoiceList = await invoiceFindMany({
            filterObject: {
              additionalActivity: originalCustomerInvoice.additionalActivity,
              invoiceType: getOppositeInvoiceType(
                originalCustomerInvoice.invoiceType
              ),
              mission: originalCustomerInvoice.mission,
              status: { $in: [EInvoiceStatus.CANCELLED] },
            },
          });
          originalProviderInvoice = invoiceList?.invoices[0];
        }
      } else {
        // normal invoice treatment
        if (isAdditionalActivityInvoice(customerInvoice.invoiceType)) {
          const invoiceList = await invoiceFindMany({
            filterObject: {
              additionalActivity: customerInvoice.additionalActivity,
              invoiceType: getOppositeInvoiceType(customerInvoice.invoiceType),
              mission: customerInvoice.mission.reference,
              status: { $in: [EInvoiceStatus.VALIDATED, EInvoiceStatus.PAID] },
            },
          });
          originalProviderInvoice = invoiceList?.invoices[0];
        } else {
          const invoiceList = await invoiceFindMany({
            filterObject: {
              month: customerInvoice.month,
              invoiceType: getOppositeInvoiceType(customerInvoice.invoiceType),
              mission: customerInvoice.mission.reference,
              status: { $in: [EInvoiceStatus.VALIDATED, EInvoiceStatus.PAID] },
            },
          });
          originalProviderInvoice = invoiceList?.invoices[0];
        }
      }
      // Direct payment is activated, special treatement for invoice';
      // 1st line
      const month = originalCustomerInvoice.month
        ? formatDate(originalCustomerInvoice.month, 'MM/yyyy')
        : '';
      const datePart = month.length > 0 ? `du mois de ${month}` : '';
      // const customInvoiceDesc = resolvePerformanceDescription(
      //   originalCustomerInvoice
      // );
      // const addDesc = customInvoiceDesc === ' ' ? '' : `\n ${customInvoiceDesc}`;
      const providerStructure =
        customerInvoice?.mission?.sage?.structure === EMissionStructure.FONE
          ? EMissionStructure.FCOM
          : customerInvoice?.mission?.sage?.structure || '';
      // @ts-ignore
      const hqAdress = getSructure(providerStructure ?? '');
      // directPaymentSpecialTreatment.firstLineDescription = `Prestation ${providerHqAddress.provHQBusinessName} (titulaire) ${datePart} ${addDesc}`
      directPaymentSpecialTreatment.firstLineDescription = `Prestation ${hqAdress.complete} (titulaire) ${datePart}`;
      // total de la marge de freelance en HT
      const totalHtProviderInvoice =
        originalProviderInvoice?.totalBeforeTaxes || 0;
      const totalHtCustomerInvoice = originalCustomerInvoice.totalBeforeTaxes;
      const margeFcom = totalHtCustomerInvoice - totalHtProviderInvoice;
      const totalMarginVat = margeFcom * 0.2;
      const totalMargeWithVat = margeFcom + totalMarginVat;

      directPaymentSpecialTreatment.firstLineAmountHT =
        currencyFormatter.format(margeFcom ?? 0 * multiplier);
      directPaymentSpecialTreatment.firstLineVatAmount =
        currencyFormatter.format(totalMarginVat ?? 0 * multiplier);
      directPaymentSpecialTreatment.firstLineTotalAmount =
        currencyFormatter.format(totalMargeWithVat ?? 0 * multiplier);
      // 2nd line
      if (originalProviderInvoice && !originalProviderInvoice?.isVat) {
        originalProviderInvoice.vatRate = 0;
      }

      const currentIban = invoice?.ibans?.find(
        (iban: any) => iban.uuid === getDefaultIban()
      );
      directPaymentSpecialTreatment.sndLine =
        originalProviderInvoice?.vatRate !== 20;
      directPaymentSpecialTreatment.sndLineDescription = `TVA collectée sur prestation réalisée en France à reverser à ${currentIban?.owner ?? 'FREELANCE.COM'}`;
      const providerVateRate = directPaymentSpecialTreatment.sndLine
        ? (originalProviderInvoice?.vatRate || 0) / 100
        : 0;
      const sndLineVatAmout = directPaymentSpecialTreatment.sndLine
        ? (originalProviderInvoice?.totalBeforeTaxes ?? 0) *
          (0.2 - providerVateRate)
        : 0;
      directPaymentSpecialTreatment.sndLineVatAmount = currencyFormatter.format(
        (directPaymentSpecialTreatment.sndLineVatAmount =
          sndLineVatAmout * multiplier)
      );
      directPaymentSpecialTreatment.sndLineTotalAmount =
        currencyFormatter.format(sndLineVatAmout * multiplier);
      // 3thr line
      const providerInvoiceRef =
        originalProviderInvoice?.providerInvoiceRef ?? '';
      const businessName =
        originalProviderInvoice?.estProvider?.businessName ?? '';
      let bu = businessName;
      directPaymentSpecialTreatment.thirdLineDescription =
        `Prestation ${bu} ` +
        `(sous traitant) Facture [${providerInvoiceRef}] ci-jointe `;
      // search for provider invoice linked to this customer invoice to get the refrendcxe
      directPaymentSpecialTreatment.thirdLineAmountHt =
        currencyFormatter.format(
          originalProviderInvoice?.totalBeforeTaxes ?? 0 * multiplier
        );
      directPaymentSpecialTreatment.thirdLineVatAmount =
        currencyFormatter.format(
          originalProviderInvoice?.vat ?? 0 * multiplier
        );
      directPaymentSpecialTreatment.thirdLineAmount = currencyFormatter.format(
        originalProviderInvoice?.totalWithTaxes ?? 0 * multiplier
      );
      // 5th line ( net total)
      const totalNetAmount = totalMargeWithVat + sndLineVatAmout;
      directPaymentSpecialTreatment.netTotalAmoutFCOM =
        currencyFormatter.format(totalNetAmount * multiplier);
    }

    return directPaymentSpecialTreatment;
  };

  const {
    setValue,
    setError,
    watch,
    control,
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm<FormValues>({
    mode: 'onChange',
    defaultValues: {
      attachments: invoice?.attachments ?? [],
      customerReference: invoice?.customerReference,
      paymentDeadline: invoice?.paymentDeadline,
      comment: invoice?.comment,
      invoiceIssue: {
        sendMethod:
          invoice?.invoiceIssue?.sendMethod || EInvoiceSendMethod?.EMAIL,
        paymentLabel:
          invoice?.invoiceIssue?.paymentLabel ||
          EInvoicePaymentLabel?.END_OF_MONTH,
        shouldJoinProofToInvoice:
          invoice?.invoiceIssue?.shouldJoinProofToInvoice,
        iban: getDefaultIban(),
        contacts: {
          email:
            invoiceTO?.email ||
            invoiceContact?.email ||
            missionCustomerContact?.email,
          lastName:
            invoiceTO?.lastName ||
            invoiceContact?.lastName ||
            missionCustomerContact?.lastName,
          firstName:
            invoiceTO?.firstName ||
            invoiceContact?.firstName ||
            missionCustomerContact?.firstName,
          civility:
            invoiceTO?.civility ||
            invoiceContact?.civility ||
            missionCustomerContact?.civility,
        },
      },
    },
  });
  const sendMethod = watch('invoiceIssue.sendMethod');
  const attachments = watch('attachments');
  const shouldJoinProofToInvoice = watch(
    'invoiceIssue.shouldJoinProofToInvoice'
  );
  const previousAttachments = invoice?.attachments ?? [];
  const shouldMergeProofWithInvoice = watch(
    'invoiceIssue.shouldJoinProofToInvoice'
  );
  const [formSelected, setFormSelected] = useState<
    'CUSTOMER' | 'ORDER' | 'BANK' | 'COMMENTS' | 'CRA' | 'PROOF'
  >('CUSTOMER');

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingSave, setIsLoadingSave] = useState(false);

  // const checkEmail = (value: string): string | undefined => {
  //   const regex = /[a-z0-9_\-\.]+@[a-z0-9_\-\.]+\.[a-z]+/i;
  //   if (regex.test(value)) {
  //     return undefined;
  //   } else {
  //     return 'Veuillez entrer une adresse e-mail valide';
  //   }
  // };

  const checkFormPaymentDeadline = (value: number): string | undefined => {
    const payLabel = getValues().invoiceIssue.paymentLabel;

    if (payLabel === EInvoicePaymentLabel.END_OF_MONTH) {
      if (value > MAX_PAYMENT_DEADLINE_END_OF_MONTH)
        return `Le délai d'échéance ne peut pas dépasser ${MAX_PAYMENT_DEADLINE_FIXED} jours à date d'émission de la facture ou ${MAX_PAYMENT_DEADLINE_END_OF_MONTH} jours fin de mois`;
    }
    if (payLabel === EInvoicePaymentLabel.FIXED) {
      if (value > MAX_PAYMENT_DEADLINE_FIXED)
        return `Le délai d'échéance ne peut pas dépasser ${MAX_PAYMENT_DEADLINE_FIXED} jours à date d'émission de la facture ou ${MAX_PAYMENT_DEADLINE_END_OF_MONTH} jours fin de mois`;
    }
    return undefined;
  };

  const isCustomerReferenceValid = (value: string): boolean => {
    return value.length <= MAX_CUSTOMER_REFERENCE_FIELD_LENGTH;
  };

  const checkFormCustomerReference = (value: string): string | undefined => {
    if (!isCustomerReferenceValid(value)) {
      return `Veuillez saisir moins de ${MAX_CUSTOMER_REFERENCE_FIELD_LENGTH} caractères`;
    }
    return undefined;
  };
  const invoiceIban = getDefaultIban();

  const isIbanDisabled = (ibanUuid: string | undefined) => {
    return invoice?.ibans?.some(ib => ib.uuid === ibanUuid && ib.disabled);
  };

  const IBAN_SELECT = invoice?.ibans
    ?.map(iban => {
      return {
        label: `NOM : ${iban?.displayName} | NOM DE LA BANQUE : ${iban?.bankName} | IBAN: ${electronicFormatIBAN(
          iban?.iban
        )}`,
        value: iban?.uuid,
        key: iban?.uuid,
        disabled: iban.disabled,
      };
    })
    .filter(iban => {
      if (iban.disabled && iban?.key !== `${invoiceIban}`) {
        return false;
      }
      return true;
    });

  const onAdd = (line: any) => {
    if (line?.type === OVERRIDE_PRESTATION_TYPE) {
      const buildLine = {
        type: OVERRIDE_PRESTATION_TYPE,
        uuid: OVERRIDE_PRESTATION_TYPE,
        comment: line?.comment,
        description: line?.description,
        updateMission: line?.updateMission,
      };
      const _invL = jsonQ.clone(invoiceLinesUpdated);
      const indexUpdated = _invL?.findIndex(
        (data: any) => data?.uuid === buildLine?.uuid
      );
      if (indexUpdated > -1) {
        // update line
        _invL[indexUpdated].comment = line?.comment;
        _invL[indexUpdated].description = line?.description;
      } else {
        // create line
        _invL?.push(buildLine);
      }
      setInvoiceLinesUpdated(_invL);
    } else {
      const _invL = jsonQ.clone(invoiceLinesUpdated);
      const indexUpdated = _invL?.findIndex(
        (data: any) => data?.uuid === line?.uuid
      );
      if (indexUpdated > -1) {
        _invL[indexUpdated].comment = line?.comment;
        _invL[indexUpdated].description = line?.description;
      }
      setInvoiceLinesUpdated(_invL);
    }
  };
  const onUpdateEmailContacts = (
    contact: {
      email: string;
      recipientType?: EEmailRecipientType;
    },
    oldContact?: {
      email: string;
      recipientType?: EEmailRecipientType;
    }
  ) => {
    const contacts = [...additionalInvoiceContacts];
    const indexUpdatedContact = contacts?.findIndex(
      (oldC: any) =>
        oldC?.email === oldContact?.email || oldC?.email === contact?.email
    );
    if (indexUpdatedContact !== -1) {
      const updateC = contacts[indexUpdatedContact];
      contacts[indexUpdatedContact] = { ...updateC, ...contact };
      setAdditionalInvoiceContacts(contacts);
    } else {
      contacts?.push(contact);
      setAdditionalInvoiceContacts(contacts);
    }
    setEditAInvoiceContactEmail(false);
  };
  const onDeleteEmailContacts = (email: string) => {
    let contacts = [...additionalInvoiceContacts];
    contacts = contacts?.filter((c: any) => c?.email !== email);
    setAdditionalInvoiceContacts(contacts);
  };
  const onDelete = () => {
    //
  };

  const onSubmit = async (formValues: FormValues) => {
    const ibanParam = formValues?.invoiceIssue?.iban;
    if (isIbanDisabled(ibanParam)) {
      setError(
        'invoiceIssue.iban',
        {
          type: 'manual',
          message:
            'l`IBAN sélectionné est marqué comme "Désactivé", vous devez le remplacer',
        },
        { shouldFocus: true }
      );
      showMessage(
        'error',
        'l`IBAN sélectionné est marqué comme "Désactivé", vous devez le remplacer'
      );
      return;
    }
    setIsLoading(true);
    const shouldMergeProofWithInvoice =
      formValues?.invoiceIssue?.shouldMergeProofWithInvoice;
    let fileParams = formValues?.attachments ?? [];
    if (attachments) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      fileParams = (attachments as any).map((file: any) => {
        const { eTag, fileLocation, fileName } = file;
        return { eTag, fileLocation, fileName };
      });
    }
    const lineDescIndex = invoiceLinesUpdated?.findIndex(
      //@ts-ignore
      il => il?.type === OVERRIDE_PRESTATION_TYPE
    );
    let customDescription;
    const updateDescPrestation = invoiceLinesUpdated?.[lineDescIndex as number];
    if (updateDescPrestation) {
      customDescription = updateDescPrestation?.comment;
      //@ts-ignore
      if (updateDescPrestation?.updateMission) {
        // update custom desc in mission
        try {
          await missionUpdateOne({
            reference: invoice?.mission?.reference as string,
            mission: {
              customer: {
                //@ts-ignore
                customInvoicesDescription: updateDescPrestation?.comment,
              },
            },
          });
        } catch (e) {
          //
        }
      }
      // delete line update desc for submit
      invoiceLinesUpdated?.splice(lineDescIndex as number, 1);
    }
    const defaultContactTo = getInvoiceTo();
    const defaultInvoiceContacts = invoice?.invoiceIssue?.contacts || [];
    let upsertInvoiceContacts = [];
    const removeInvoiceContacts = [];
    // delete contact
    defaultInvoiceContacts
      ?.filter(c => c?.recipientType !== EEmailRecipientType?.TO)
      ?.forEach((dic: IEmailContact) => {
        const isFind = additionalInvoiceContacts?.findIndex(
          (aic: IEmailContact) => aic?.email === dic?.email
        );
        if (isFind === -1) {
          removeInvoiceContacts?.push(dic?.email);
        }
      });

    // UPDATE TO
    if (defaultContactTo) {
      if (
        defaultContactTo?.email !== formValues?.invoiceIssue?.contacts?.email
      ) {
        removeInvoiceContacts.push(defaultContactTo?.email);
        if (formValues?.invoiceIssue?.contacts?.email) {
          upsertInvoiceContacts.push({
            ...formValues?.invoiceIssue?.contacts,
            recipientType: EEmailRecipientType?.TO,
          });
        }
      }
    } else {
      if (formValues?.invoiceIssue?.contacts?.email) {
        upsertInvoiceContacts.push({
          ...formValues?.invoiceIssue?.contacts,
          recipientType: EEmailRecipientType?.TO,
        });
      }
    }
    // UPDATE
    const otherContacts = isNotSameContacts(
      defaultInvoiceContacts?.filter(
        c => c.recipientType !== EEmailRecipientType?.TO
      ),
      additionalInvoiceContacts?.filter(
        c => c.recipientType !== EEmailRecipientType?.TO
      )
    );
    upsertInvoiceContacts = [...upsertInvoiceContacts, ...otherContacts];

    const submmitValues = {
      uuids: [invoice?.uuid as string],
      invoice: {
        comment: formValues?.comment,
        //attachments: fileParams,
        customerReference: formValues?.customerReference,
        paymentDeadline: Number(formValues?.paymentDeadline),
        invoiceIssue: {
          sendMethod: formValues?.invoiceIssue?.sendMethod,
          paymentLabel: formValues?.invoiceIssue?.paymentLabel,
          iban: formValues?.invoiceIssue?.iban,
          // contacts: [ ...formValues?.invoiceIssue?.contacts],
          upsertInvoiceContacts:
            upsertInvoiceContacts?.length > 0
              ? upsertInvoiceContacts
              : undefined,
          removeInvoiceContacts:
            removeInvoiceContacts?.length > 0
              ? removeInvoiceContacts
              : undefined,
          customDescription: customDescription,
          shouldJoinProofToInvoice:
            formValues?.invoiceIssue?.shouldJoinProofToInvoice,
          shouldMergeProofWithInvoice: shouldJoinProofToInvoice
            ? shouldMergeProofWithInvoice
            : false,
        },
        // eslint-disable-next-line array-callback-return
        invoiceLines: invoiceLinesUpdated?.map((il: any) => {
          if (il?.type !== OVERRIDE_PRESTATION_TYPE) {
            return {
              uuid: il?.uuid,
              comment: il?.comment,
              description: il?.description,
            };
          }
        }),
      },
    };

    try {
      //@ts-ignore
      const responseUpdateInvoice = await updateInvoice(submmitValues);
      if (
        responseUpdateInvoice?.errorUpdateArray &&
        responseUpdateInvoice?.errorUpdateArray?.length > 0
      ) {
        queryClient.invalidateQueries({ queryKey: [invoice?.uuid] });
        queryClient.refetchQueries({ queryKey: ['Invoices'], type: 'active' });
        showMessage('error', 'Une erreur est survenue');
        return;
      }
      queryClient.invalidateQueries({ queryKey: [invoice?.uuid] });
      queryClient.refetchQueries({ queryKey: ['Invoices'], type: 'active' });
      const generateInvoiceFile = await GenerateInvoice({
        invoice: invoice?.uuid,
      });
      setFileLocation(generateInvoiceFile?.fileLocation);
    } catch (e) {
      //
    }
    setIsLoading(false);
  };

  const onValidateInvoice = async () => {
    const ibanParam = invoice?.invoiceIssue?.iban;
    if (isIbanDisabled(ibanParam)) {
      setError('invoiceIssue.iban', {
        message:
          'l`IBAN sélectionné est marqué comme "Désactivé", vous devez le remplacer',
      });
      showMessage(
        'error',
        'l`IBAN sélectionné est marqué comme "Désactivé", vous devez le remplacer'
      );
      return;
    }
    setIsLoadingSave(true);

    const formValues = getValues();
    const shouldMergeProofWithInvoice =
      formValues?.invoiceIssue?.shouldMergeProofWithInvoice;
    const errorMessageCustomerRef = checkFormCustomerReference(
      formValues.customerReference
    );
    const errorMessagePaymentDeadline = checkFormPaymentDeadline(
      formValues.paymentDeadline
    );
    if (Number(formValues?.paymentDeadline) < 0) {
      setError(
        'paymentDeadline',
        { message: 'Veuillez saisir une valeur positive' },
        { shouldFocus: true }
      );
      setIsLoadingSave(false);
      return;
    }
    if (errorMessageCustomerRef || errorMessagePaymentDeadline) {
      if (errorMessageCustomerRef) {
        setError(
          'customerReference',
          { type: 'maxLength', message: errorMessageCustomerRef },
          { shouldFocus: true }
        );
      }
      if (errorMessagePaymentDeadline) {
        setError(
          'paymentDeadline',
          { message: errorMessagePaymentDeadline },
          { shouldFocus: true }
        );
      }
      setIsLoadingSave(false);
      return;
    }
    let fileParams = formValues?.attachments ?? [];
    if (attachments) {
      fileParams = (attachments as any).map((file: any) => {
        const { eTag, fileLocation, fileName } = file;
        return { eTag, fileLocation, fileName };
      });
    }
    const lineDescIndex = invoiceLinesUpdated?.findIndex(
      //@ts-ignore
      il => il?.type === OVERRIDE_PRESTATION_TYPE
    );
    let customDescription;
    const updateDescPrestation = invoiceLinesUpdated?.[lineDescIndex as number];
    if (updateDescPrestation) {
      customDescription = updateDescPrestation?.comment;
      //@ts-ignore
      if (updateDescPrestation?.updateMission) {
        // update custom desc in mission
        try {
          await missionUpdateOne({
            reference: invoice?.mission?.reference as string,
            mission: {
              customer: {
                //@ts-ignore
                customInvoicesDescription: updateDescPrestation?.comment,
              },
            },
          });
          // delete line update desc for submit
          invoiceLinesUpdated?.splice(lineDescIndex as number, 1);
        } catch (e) {
          //
        }
      }
    }

    const defaultContactTo = getInvoiceTo();
    const defaultInvoiceContacts = invoice?.invoiceIssue?.contacts || [];
    let upsertInvoiceContacts = [];
    const removeInvoiceContacts = [];
    // delete contact
    defaultInvoiceContacts
      ?.filter(c => c?.recipientType !== EEmailRecipientType?.TO)
      ?.forEach((dic: IEmailContact) => {
        const isFind = additionalInvoiceContacts?.findIndex(
          (aic: IEmailContact) => aic?.email === dic?.email
        );
        if (isFind === -1) {
          removeInvoiceContacts?.push(dic?.email);
        }
      });

    // UPDATE TO
    if (defaultContactTo) {
      if (
        defaultContactTo?.email !== formValues?.invoiceIssue?.contacts?.email
      ) {
        removeInvoiceContacts.push(defaultContactTo?.email);
        if (formValues?.invoiceIssue?.contacts?.email) {
          upsertInvoiceContacts.push({
            ...formValues?.invoiceIssue?.contacts,
            recipientType: EEmailRecipientType?.TO,
          });
        }
      }
    } else {
      if (formValues?.invoiceIssue?.contacts?.email) {
        upsertInvoiceContacts.push({
          ...formValues?.invoiceIssue?.contacts,
          recipientType: EEmailRecipientType?.TO,
        });
      }
    }
    // UPDATE
    const otherContacts = isNotSameContacts(
      defaultInvoiceContacts?.filter(
        c => c.recipientType !== EEmailRecipientType?.TO
      ),
      additionalInvoiceContacts?.filter(
        c => c.recipientType !== EEmailRecipientType?.TO
      )
    );
    upsertInvoiceContacts = [...upsertInvoiceContacts, ...otherContacts];
    const submmitValues = {
      uuids: [invoice?.uuid as string],
      invoice: {
        status: EInvoiceStatus?.VALIDATED,
        comment: formValues?.comment,
        attachments: fileParams,
        customerReference: formValues?.customerReference,
        paymentDeadline: Number(formValues?.paymentDeadline),
        invoiceIssue: {
          sendMethod: formValues?.invoiceIssue?.sendMethod,
          paymentLabel: formValues?.invoiceIssue?.paymentLabel,
          iban: formValues?.invoiceIssue?.iban,
          customDescription: customDescription,
          shouldJoinProofToInvoice:
            formValues?.invoiceIssue?.shouldJoinProofToInvoice,
          shouldMergeProofWithInvoice: shouldJoinProofToInvoice
            ? shouldMergeProofWithInvoice
            : false,
          upsertInvoiceContacts:
            upsertInvoiceContacts?.length > 0
              ? upsertInvoiceContacts
              : undefined,
          removeInvoiceContacts:
            removeInvoiceContacts?.length > 0
              ? removeInvoiceContacts
              : undefined,
        },
        invoiceLines: invoiceLinesUpdated?.map((il: any) => {
          return {
            uuid: il?.uuid,
            comment: il?.comment,
            description: il?.description,
          };
        }),
      },
    };
    try {
      //@ts-ignore
      const responseUpdateInvoice = await updateInvoice(submmitValues);
      if (
        responseUpdateInvoice?.errorUpdateArray &&
        responseUpdateInvoice?.errorUpdateArray?.length > 0
      ) {
        queryClient.refetchQueries({ queryKey: [invoice?.uuid] });
        queryClient.refetchQueries({ queryKey: ['Invoices'], type: 'active' });
        showMessage('error', 'Une erreur est survenue');
        setIsLoadingSave(false);
        return;
      }
      queryClient.refetchQueries({ queryKey: [invoice?.uuid] });
      queryClient.refetchQueries({ queryKey: ['Invoices'], type: 'active' });
      // const generateInvoiceFile = await GenerateInvoice({
      //   invoice: invoice?.uuid,
      // });

      // const fileLocation = generateInvoiceFile?.fileLocation;
      // setFileLocation(fileLocation);
    } catch (e) {
      setIsLoadingSave(false);
    }
    setIsLoadingSave(false);
  };
  const onSendCustomer = async () => {
    if (sendMethod === EInvoiceSendMethod?.EMAIL) {
      await showDialogModal({
        title: 'Êtes-vous sûr de vouloir envoyer la facture ? ',
        text: (
          <Text variant="b" color="red">
            L'envoi de la facture passera le statut de la facture à 'Envoyé au
            client' il ne sera plus possible de la modifier !
          </Text>
        ),
        confirmLabel: 'Envoyer',
        cancelLabel: 'Annuler',
        layer: 300,
      }).then(action => {
        if (action) {
          try {
            const responseUpdateInvoice = updateInvoice({
              uuids: [invoice?.uuid as string],
              invoice: { status: EInvoiceStatus?.SENT },
            });
            if (
              //@ts-ignore
              responseUpdateInvoice?.errorUpdateArray &&
              //@ts-ignore
              responseUpdateInvoice?.errorUpdateArray?.length > 0
            ) {
              queryClient.refetchQueries({ queryKey: [invoice?.uuid] });
              queryClient.refetchQueries({
                queryKey: ['Invoices'],
                type: 'active',
              });
              showMessage('error', 'Une erreur est survenue');
              return;
            }
            queryClient.refetchQueries({ queryKey: [invoice?.uuid] });
            queryClient.refetchQueries({
              queryKey: ['Invoices'],
              type: 'active',
            });
            onClose();
          } catch (e) {
            queryClient.refetchQueries({ queryKey: [invoice?.uuid] });
            queryClient.refetchQueries({
              queryKey: ['Invoices'],
              type: 'active',
            });
          }
        }
      });
    }
    if (sendMethod === EInvoiceSendMethod?.OTHER) {
      await showDialogModal({
        title: 'Télécharger',
        text: "Le téléchargement du document passera le statut de la facture à 'Envoyé au client'",
        confirmLabel: 'Télécharger',
        cancelLabel: 'Ne pas télécharger',
        layer: 208,

        // This dialog modal will show a loader if user aprove question, while the mission is updating
        //beforeValidation: () => update(),
      }).then(async action => {
        if (action === true) {
          try {
            const fileName = invoice?.invoiceFile?.fileName as string;
            const a = document.createElement('a');
            a.href = file || '';
            a.download = fileName.endsWith('.pdf')
              ? replaceSpecificCaractereInFileName(fileName)
              : replaceSpecificCaractereInFileName(fileName) + '.pdf';
            a.click();
            await updateInvoice({
              uuids: [invoice?.uuid as string],
              invoice: { status: EInvoiceStatus?.SENT },
            });
            queryClient.refetchQueries({ queryKey: [invoice?.uuid] });
            queryClient.refetchQueries({
              queryKey: ['Invoices'],
              type: 'active',
            });
            onClose();
          } catch (e) {}
        }
        return action;
      });
    }
  };
  const formsWidth = '1000px';
  React.useEffect(() => {
    if (isIbanDisabled(invoice?.invoiceIssue?.iban)) {
      setError(
        'invoiceIssue.iban',
        {
          type: 'manual',
          message:
            'l`IBAN sélectionné est marqué comme "Désactivé", vous devez le remplacer',
        },
        { shouldFocus: true }
      );
    }
    const contact = selectedUser?.users?.[0];
    if (contact && hasSetContact === false) {
      setHasSetContact(true);
      setContactSelected(contact);
      setValue('invoiceIssue.contacts.email', contact?.email);
      setValue('invoiceIssue.contacts.firstName', contact?.firstName);
      setValue('invoiceIssue.contacts.lastName', contact?.lastName);
      setValue('invoiceIssue.contacts.civility', contact?.civility);
    }
  }, [selectedUser, hasSetContact]);
  const getAdditionalActivity = async (uuid: string) => {
    if (uuid) {
      try {
        const aa = await additionalActivityFindOne({ uuid });
        setAdditionalActivity(aa);
      } catch (e) {
        //
      }
    }
  };
  const {
    status: statusFile,
    data,
    isFetching,
  } = useDownloadFile(fileLocation as string, {
    enabled: fileLocation ? true : false,
  });

  React.useEffect(() => {
    // use for download file
    if (data) {
      const reader = new FileReader();
      reader.readAsDataURL(data.data); // converts the blob to base64 and calls onload
      reader.onload = () => {
        setFile(reader.result as string);
      };
    }
  }, [data]);
  const onGenerateInvoice = async (onlyGenerate?: boolean) => {
    const formValues = getValues();

    const submmitValues = {
      uuids: [invoice?.uuid as string],
      invoice: {
        comment: formValues?.comment,
        customerReference: formValues?.customerReference,
        paymentDeadline: Number(formValues?.paymentDeadline),
        invoiceIssue: {
          sendMethod: formValues?.invoiceIssue?.sendMethod,
          paymentLabel: formValues?.invoiceIssue?.paymentLabel,
          iban: formValues?.invoiceIssue?.iban,
          //contacts: [formValues?.invoiceIssue?.contacts],
          shouldJoinProofToInvoice:
            formValues?.invoiceIssue?.shouldJoinProofToInvoice,
        },
        invoiceLines: invoiceLinesUpdated?.map((il: any) => {
          return {
            uuid: il?.uuid,
            comment: il?.comment,
            description: il?.description,
          };
        }),
      },
    };
    try {
      if (!onlyGenerate) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const responseUpdateInvoice = await updateInvoice(submmitValues);
        queryClient.refetchQueries({ queryKey: [invoice?.uuid] });
        queryClient.refetchQueries({ queryKey: ['Invoices'], type: 'active' });
      }

      const generateInvoiceFile = await GenerateInvoice({
        invoice: invoice?.uuid,
      });
      const fileLocation = generateInvoiceFile?.fileLocation;
      setFileLocation(fileLocation);
    } catch (e) {
      //
    }
  };

  React.useEffect(() => {
    if (invoice && !firstPreview) {
      onGenerateInvoice(true);
      setFirstPreview(true);
    }
    if (
      invoice?.status === EInvoiceStatus.VALIDATED &&
      invoice?.invoiceFile?.fileLocation
    ) {
      setFileLocation(invoice?.invoiceFile?.fileLocation);
    }
    if (invoice?.additionalActivity) {
      getAdditionalActivity(invoice?.additionalActivity);
    }
    const getDirectPaymentData = async () => {
      const directPaymentItems = await resolveDirectPaymentFileData(
        invoice as IJoinedInvoice
      );
      const paymentDirectLines = [];
      if (directPaymentItems?.firstLineTotalAmount !== '0,00')
        paymentDirectLines.push({
          workNature: directPaymentItems?.firstLineDescription,
          totalBeforeTaxes: directPaymentItems?.firstLineAmountHT,
          vatRate: directPaymentItems?.firstLineVatAmount,
          totalWithTaxes: directPaymentItems?.firstLineTotalAmount,
          type: OVERRIDE_PRESTATION_TYPE,
        });
      if (directPaymentItems?.sndLineTotalAmount !== '0,00')
        paymentDirectLines.push({
          workNature: directPaymentItems?.sndLineDescription,
          totalBeforeTaxes: directPaymentItems?.sndLine,
          vatRate: directPaymentItems?.sndLineVatAmount,
          totalWithTaxes: directPaymentItems?.sndLineTotalAmount,
        });
      if (directPaymentItems?.thirdLineAmount !== '0,00')
        paymentDirectLines.push({
          workNature: directPaymentItems?.thirdLineDescription,
          totalBeforeTaxes: directPaymentItems?.thirdLineAmountHt,
          vatRate: directPaymentItems?.thirdLineVatAmount,
          totalWithTaxes: directPaymentItems?.thirdLineAmount,
        });
      setDirectPaymentItems(paymentDirectLines);
    };
    if (invoice?.invoiceIssue?.isDirectPayment) {
      getDirectPaymentData();
    }
  }, [invoice, firstPreview]);

  const formDisabled = invoice?.status !== EInvoiceStatus?.PROJECTED;
  const displayBankTabsForFone = () => {
    if (
      invoice?.mission?.billingInformation?.structure === EMissionStructure.FONE
    ) {
      if (isDisableFONEDate()) {
        return false;
      }
    }
    return true;
  };
  return (
    <Flex width={1 / 1} minHeight={800}>
      <Box width={1 / 2}>
        <Flex display={'inline-flex'} flexWrap={'wrap'} width={1 / 1} mb={20}>
          <>
            <Tab
              onClick={() => setFormSelected('CUSTOMER')}
              isSelected={formSelected === 'CUSTOMER'}
              variant="secondary"
              fontSize={12}
            >
              Informations
            </Tab>
            {/* <Tab
              onClick={() => setFormSelected('CRA')}
              isSelected={formSelected === 'CRA'}
              variant="secondary"
            >
              CRA
            </Tab> */}
            <Tab
              onClick={() => setFormSelected('ORDER')}
              isSelected={formSelected === 'ORDER'}
              variant="secondary"
              fontSize={12}
            >
              Lignes de facturation
            </Tab>
            {displayBankTabsForFone() && (
              <Tab
                onClick={() => setFormSelected('BANK')}
                isSelected={formSelected === 'BANK'}
                variant="secondary"
                fontSize={12}
              >
                Coordonnées bancaire
              </Tab>
            )}
            <Tab
              onClick={() => setFormSelected('COMMENTS')}
              isSelected={formSelected === 'COMMENTS'}
              variant="secondary"
              fontSize={12}
            >
              Commentaires
            </Tab>
            <Tab
              onClick={() => setFormSelected('PROOF')}
              isSelected={formSelected === 'PROOF'}
              variant="secondary"
              fontSize={12}
            >
              Documents
            </Tab>
          </>
        </Flex>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Flex
            justifyContent={'center'}
            display={'inline-flex'}
            flexWrap={'wrap'}
            width={1 / 1}
            minHeight={350}
          >
            <Box width={formsWidth} hidden={formSelected !== 'CUSTOMER'}>
              <Box width={1 / 1} mb={10}>
                <Row spacing={20}>
                  <LabelField
                    label="Établissement client : "
                    value={getTradeNameSafe(
                      invoice?.estCustomer as IEstablishment
                    )}
                  />
                  <LabelField
                    label="Référence mission : "
                    value={invoice?.mission?.displayReference}
                  />
                </Row>
              </Box>
              <Box width={1 / 1} mb={10}>
                <Row spacing={20}>
                  {invoice?.month ? (
                    <LabelField
                      label="Tarification : "
                      value={
                        invoice?.billingType === EBillingType.DAY
                          ? 'Temps passé'
                          : 'Forfait'
                      }
                    />
                  ) : (
                    <></>
                  )}
                  <></>
                </Row>
                <Row spacing={20}>
                  {invoice?.month ? (
                    <LabelField
                      label="Mois concerné : "
                      value={displayMonth(invoice?.month as Date)}
                    />
                  ) : (
                    <LabelField
                      label="Tarification : "
                      value={
                        invoice?.billingType === EBillingType.DAY
                          ? 'Temps passé'
                          : 'Forfait'
                      }
                    />
                  )}

                  <LabelField
                    label="Structure : "
                    value={invoice?.mission?.billingInformation?.structure}
                  />
                </Row>
              </Box>
              <Box width={1 / 1} mb={20}>
                <LabelField
                  label="Description de la mission : "
                  value={
                    invoice?.mission?.customer?.customInvoicesDescription ||
                    'N/A'
                  }
                  underline
                />
              </Box>
              <FormControl
                label={'Mode de distribution'}
                errorMessage={errors?.invoiceIssue?.sendMethod?.message}
              >
                <>
                  <Radio
                    isDisabled={formDisabled}
                    {...register('invoiceIssue.sendMethod', {
                      required: 'Ce champ est requis',
                    })}
                    value={EInvoiceSendMethod?.EMAIL}
                  >
                    Email
                  </Radio>
                  <Radio
                    isDisabled={formDisabled}
                    {...register('invoiceIssue.sendMethod', {
                      required: 'Ce champ est requis',
                    })}
                    value={EInvoiceSendMethod?.OTHER}
                  >
                    Autre ( plateforme , fax etc .... )
                  </Radio>
                </>
              </FormControl>

              <Flex>
                <FormControl
                  width={1 / 3}
                  mr={20}
                  required
                  label="Référence client"
                  errorMessage={errors?.customerReference?.message}
                >
                  <Input
                    isDisabled={formDisabled}
                    isFullWidth
                    {...register('customerReference', {
                      validate: (value: any) =>
                        checkFormCustomerReference(value),
                    })}
                  />
                </FormControl>
                <Box width={2 / 3}>
                  <Row spacing={20}>
                    <FormControl
                      label="Délai de paiement"
                      required
                      errorMessage={
                        errors?.paymentDeadline?.type === 'min'
                          ? `Veuillez saisir une valeur positive`
                          : errors?.paymentDeadline?.message
                      }
                    >
                      <Input
                        isDisabled={formDisabled}
                        isFullWidth
                        type="number"
                        {...register('paymentDeadline', {
                          required: 'Ce champs est requis ',
                          min: 0,
                          validate: value => checkFormPaymentDeadline(value),
                        })}
                      />
                    </FormControl>
                    <FormControl
                      required
                      label="À compter de la"
                      errorMessage={errors?.invoiceIssue?.paymentLabel?.message}
                    >
                      <StaticSelectControlled
                        isDisabled={formDisabled}
                        isClearable={false}
                        control={control}
                        name="invoiceIssue.paymentLabel"
                        options={[
                          {
                            label: `Fin de mois`,
                            value: EInvoicePaymentLabel?.END_OF_MONTH,
                          },
                          {
                            label: `Date d'émission la facture`,
                            value: EInvoicePaymentLabel?.FIXED,
                          },
                        ]}
                        rules={{ required: 'Ce champ est requis' }}
                        placeholder=""
                      />
                    </FormControl>
                  </Row>
                </Box>
              </Flex>
              <Box hidden={sendMethod === EInvoiceSendMethod?.OTHER}>
                <FormControl
                  label={'Destinataire'}
                  required
                  errorMessage={errors?.invoiceIssue?.contacts?.message}
                >
                  <>
                    <UserSelect
                      isDisabled={formDisabled}
                      filter={{
                        cognitoUserId: {
                          $in: invoice?.estCustomer?.customer?.contacts,
                        },
                      }}
                      //@ts-ignore
                      disableCall={!contactSelected?.cognitoUserId}
                      disableRoleMatch
                      addedData={[
                        {
                          label: `${invoiceContact?.firstName} ${invoiceContact?.lastName} ${invoiceContact?.email}`,
                          value: `${invoiceContact?.email}`,
                          data: invoiceContact,
                        },
                      ]}
                      isClearable={false}
                      onChangeCompleteObject={(data: any) => {
                        setContactSelected(data);
                        setValue('invoiceIssue.contacts.email', data?.email);
                        setValue(
                          'invoiceIssue.contacts.firstName',
                          data?.firstName
                        );
                        setValue(
                          'invoiceIssue.contacts.lastName',
                          data?.lastName
                        );
                        setValue(
                          'invoiceIssue.contacts.civility',
                          data?.civility
                        );
                      }}
                      id="invoice-contact-generate"
                      placeholder={`Contacts client rattachés à l'établissement ${getTradeNameSafe(
                        invoice?.estCustomer as any
                      )}`}
                      role={'CUSTOMER'}
                      name="invoiceIssue.contacts"
                      value={
                        //@ts-ignore
                        contactSelected?.cognitoUserId || contactSelected
                      }
                    />
                    <Link
                      iconLeft={<AddIcon />}
                      onClick={() =>
                        showAddContactModal({
                          contactRole: 'CUSTOMER',
                        }).then(c => {
                          //@ts-ignore
                          c.cognitoUserId && setContactSelected(c);
                          setValue('invoiceIssue.contacts.email', c?.email);
                          setValue(
                            'invoiceIssue.contacts.firstName',
                            c?.firstName
                          );
                          setValue(
                            'invoiceIssue.contacts.lastName',
                            c?.lastName
                          );
                          setValue(
                            'invoiceIssue.contacts.civility',
                            c?.civility
                          );
                        })
                      }
                      mt={2}
                    >
                      Ajouter un contact client
                    </Link>
                  </>
                </FormControl>
                <Box mb={20} mt={20}>
                  <Text mb={20} variant="h3">
                    Ajouter des destinataires en cc ou cci
                  </Text>
                  {additionalInvoiceContacts &&
                    additionalInvoiceContacts?.map((contact: any) => (
                      <AddEmailContactForm
                        key={contact?.email}
                        onSubmit={newContact =>
                          onUpdateEmailContacts(newContact, contact)
                        }
                        contacts={additionalInvoiceContacts}
                        contact={contact}
                        readOnly={editAInvoiceContactEmail !== contact.email}
                        onEdit={() =>
                          setEditAInvoiceContactEmail(contact?.email)
                        }
                        onDelete={onDeleteEmailContacts}
                      />
                    ))}
                  <Box hidden={editAInvoiceContactEmail ? true : false}>
                    <Link onClick={() => setEditAInvoiceContactEmail(true)}>
                      Ajouter un destinataire
                    </Link>
                  </Box>
                  <Box hidden={editAInvoiceContactEmail !== true}>
                    {editAInvoiceContactEmail && (
                      <AddEmailContactForm
                        key={'NEW'}
                        contacts={additionalInvoiceContacts}
                        readOnly={false}
                        onSubmit={onUpdateEmailContacts}
                        onEdit={() => setEditAInvoiceContactEmail(false)}
                      />
                    )}
                  </Box>
                  {/* {!editAInvoiceContactEmail ? (
                    <Link onClick={() => setEditAInvoiceContactEmail(true)}>
                      Ajouter un destinataire
                    </Link>
                  ) : editAInvoiceContactEmail === true ? (
                    <AddEmailContactForm
                      key={'NEW'}
                      contacts={additionalInvoiceContacts}
                      readOnly={false}
                      onSubmit={onUpdateEmailContacts}
                      onEdit={() => setEditAInvoiceContactEmail(false)}
                    />
                  ) : (
                    <></>
                  )} */}
                </Box>
              </Box>
            </Box>
            <Box width={formsWidth} hidden={formSelected !== 'ORDER'}>
              <Section width={1 / 1} title="Lignes de facturation" variant="h3">
                <Box
                  maxHeight={500}
                  overflow={'scroll'}
                  onClick={async () => {
                    const res = await resolveDirectPaymentFileData(
                      invoice as IJoinedInvoice
                    );
                  }}
                >
                  {invoice?.invoiceIssue?.isDirectPayment &&
                  directPaymentItems?.length > 0 ? (
                    <Table
                      columns={directPaymentColumns()}
                      items={directPaymentItems}
                      total={directPaymentItems?.length}
                      perPage={2000}
                      isLoading={false}
                    />
                  ) : (
                    <Table
                      columns={columns(
                        items,
                        onAdd,
                        onDelete,
                        invoice?.cra as ICra,
                        invoice as IJoinedInvoice,
                        additionalActivity
                      )}
                      items={items}
                      total={items?.length}
                      perPage={2000}
                      isLoading={false}
                    />
                  )}
                </Box>
              </Section>
            </Box>
            <Box width={formsWidth} hidden={formSelected !== 'BANK'}>
              <FormControl
                required
                label="Coordonnées bancaire Freelance.com"
                errorMessage={errors?.invoiceIssue?.iban?.message}
              >
                <StaticSelectControlled
                  isDisabled={formDisabled}
                  control={control}
                  name="invoiceIssue.iban"
                  options={IBAN_SELECT}
                  isOptionDisabled={(option: any) => {
                    return option.disabled;
                  }}
                  rules={{ required: 'Ce champ est requis' }}
                  placeholder=""
                />
              </FormControl>
            </Box>
            <Box width={formsWidth} hidden={formSelected !== 'COMMENTS'}>
              <Section width={1 / 1} title="Commentaires interne" variant="h3">
                <BlocInformation mb={20}>
                  Le commentaire ne sera pas visible dans la facture.
                </BlocInformation>
                <FormControl label="Commentaire">
                  <TextAreaControlled
                    isDisabled={formDisabled}
                    control={control}
                    name="comment"
                    defaultValue=""
                    minRows={3}
                  />
                </FormControl>
              </Section>
            </Box>
            <Box width={formsWidth} hidden={formSelected !== 'PROOF'}>
              <Section mb={10} width={1 / 1} title="Mission" variant="h3">
                <Box ml={20}>
                  <ul>
                    {invoice?.mission?.customer?.purchaseOrder?.file
                      ?.fileLocation ? (
                      <li style={{ listStyle: 'none' }}>
                        <Link iconLeft={<EyeIcon />}>
                          <ALink
                            target={'_blank'}
                            href={`/file/${invoice?.mission?.customer?.purchaseOrder?.file?.fileLocation.replaceAll(
                              '/',
                              '§'
                            )}`}
                          >
                            Bon de commande client
                          </ALink>
                        </Link>
                      </li>
                    ) : (
                      'Aucun bon de commande client'
                    )}
                    {invoice?.mission?.customer?.quote?.file?.fileLocation ? (
                      <li style={{ listStyle: 'none' }}>
                        <Link iconLeft={<EyeIcon />}>
                          <ALink
                            target={'_blank'}
                            href={`/file/${invoice?.mission?.customer?.quote?.file?.fileLocation.replaceAll(
                              '/',
                              '§'
                            )}`}
                          >
                            Devis client
                          </ALink>
                        </Link>
                      </li>
                    ) : (
                      'Aucun devis'
                    )}
                  </ul>
                </Box>
              </Section>
              <Section mb={10} width={1 / 1} title="CRA" variant="h3">
                <Box ml={20}>
                  <ul>
                    {invoice?.cra?.proof?.fileLocation ? (
                      <li style={{ listStyle: 'none' }}>
                        <Link iconLeft={<EyeIcon />}>
                          <ALink
                            target={'_blank'}
                            href={`/file/${invoice?.cra?.proof?.fileLocation.replaceAll(
                              '/',
                              '§'
                            )}`}
                          >
                            jusiticatif CRA
                          </ALink>
                        </Link>
                      </li>
                    ) : (
                      'Aucun justificatif'
                    )}
                  </ul>
                </Box>
              </Section>
              <Box hidden={!additionalActivity}>
                <Section
                  mb={10}
                  width={1 / 1}
                  title="Jalons / Frais / Prestations complémentaires"
                  variant="h3"
                >
                  <Box
                    ml={20}
                    hidden={!additionalActivity?.quote?.file?.fileLocation}
                  >
                    <ul>
                      <li style={{ listStyle: 'none' }}>
                        <Link iconLeft={<EyeIcon />}>
                          <ALink
                            target={'_blank'}
                            href={`/file/${additionalActivity?.quote?.file?.fileLocation.replaceAll(
                              '/',
                              '§'
                            )}`}
                          >
                            Devis
                          </ALink>
                        </Link>
                      </li>
                    </ul>
                  </Box>
                  <Box
                    hidden={
                      additionalActivity?.quote?.file?.fileLocation
                        ? true
                        : false
                    }
                  >
                    <ul>
                      <li>Aucun devis</li>
                    </ul>
                  </Box>
                </Section>
              </Box>

              <Section
                mb={10}
                width={1 / 1}
                title="Fichiers joints"
                variant="h3"
              >
                <Box mb={40}>
                  <FormLabel style={{ fontWeight: 300 }}>
                    Format accepté : PDF
                  </FormLabel>
                  <FileListControlled
                    control={control}
                    name="attachments"
                    fileType={EFileType.TEMPORARY}
                    actionName={'Importer un document'}
                    accept=".pdf"
                    maxSize={MAX_FILE_SIZE_ANNEXE_MB}
                    maxFile={3}
                    previousAttachments={previousAttachments}
                  />
                </Box>

                {sendMethod === EInvoiceSendMethod.EMAIL && (
                  <FormControl
                    label={'Joindre les justificatifs à la facture client'}
                    errorMessage={
                      errors?.invoiceIssue?.shouldJoinProofToInvoice?.message
                    }
                    required
                  >
                    <CheckSwitch
                      id="shouldJoinProofToInvoice"
                      {...register('invoiceIssue.shouldJoinProofToInvoice')}
                      onChange={e => {
                        setValue(
                          'invoiceIssue.shouldJoinProofToInvoice',
                          e.target.checked
                        );
                        return e.target.checked;
                      }}
                    >
                      <Text variant="p">
                        {shouldJoinProofToInvoice
                          ? 'Joindre les justificatifs à la facture client'
                          : 'Ne pas Joindre les justificatifs à la facture client'}
                      </Text>
                    </CheckSwitch>
                  </FormControl>
                )}
                {shouldJoinProofToInvoice &&
                  sendMethod === EInvoiceSendMethod.EMAIL && (
                    <FormControl
                      required
                      label={'Fusionner les justificatifs avec la facture'}
                      errorMessage={
                        errors?.invoiceIssue?.shouldMergeProofWithInvoice
                          ?.message
                      }
                    >
                      <CheckSwitch
                        id="shouldMergeProofWithInvoice"
                        {...register(
                          'invoiceIssue.shouldMergeProofWithInvoice'
                        )}
                      >
                        <Text variant="p">
                          {shouldMergeProofWithInvoice
                            ? 'Fusionner les justificatifs avec la facture'
                            : ' Séparer les justificatifs et la facture'}
                        </Text>
                      </CheckSwitch>
                    </FormControl>
                  )}
              </Section>
            </Box>
          </Flex>
          <Flex alignItems={'center'} justifyContent={'flex-end'}>
            <Box hidden={invoice?.status !== EInvoiceStatus?.VALIDATED}>
              <Button
                variant="filled"
                type="button"
                onClick={() => onSendCustomer()}
                isLoading={isLoading}
                isDisabled={isLoadingSave}
                mr={10}
              >
                {sendMethod === EInvoiceSendMethod?.EMAIL
                  ? 'Envoyer au client'
                  : 'Télécharger'}
              </Button>
            </Box>
            {invoice?.status === EInvoiceStatus?.PROJECTED && (
              <>
                {' '}
                <Button
                  variant="ghost"
                  type="submit"
                  isLoading={isLoading}
                  isDisabled={isLoadingSave}
                  mr={10}
                >
                  Enregistrer
                </Button>
                <Button
                  type="button"
                  onClick={() => onValidateInvoice()}
                  isLoading={isLoadingSave}
                  isDisabled={isLoading}
                >
                  Valider
                </Button>
              </>
            )}
          </Flex>
        </form>
      </Box>
      <Box width={1 / 2} minHeight={900} p={10}>
        {fileLocation && !isFetching && statusFile !== 'success' && (
          <Flex
            width={1 / 1}
            flexWrap={'wrap'}
            display={'inline-flex'}
            alignItems={'center'}
            alignContent={'center'}
            justifyContent={'center'}
            height={'100%'}
          >
            <Text variant="b">Erreur lors du chargement du fichier </Text>
          </Flex>
        )}
        {(isFetching || !fileLocation) && (
          <Box height={900} backgroundColor="#484c4e">
            <SpinnerBox />
          </Box>
        )}
        {statusFile === 'success' && (
          <iframe
            //@ts-ignore
            src={data && (data as any)?.config?.url + '#toolbar=0'}
            frameBorder="0"
            title="facture"
            style={{ width: '100%', height: '100%' }}
          ></iframe>
        )}
      </Box>
    </Flex>
  );
};
