import * as React from 'react';

function SvgEuroIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        d="M13.01 20c1.827 0 3.335-.442 4.525-1.327 1.19-.884 2.012-2.092 2.465-3.624h-2.04a4.932 4.932 0 01-1.848 2.33c-.85.577-1.892.866-3.124.866-1.147 0-2.15-.317-3.007-.95-.857-.634-1.462-1.515-1.816-2.643h5.142v-.982H7.952a6.766 6.766 0 01-.085-1.15c0-.333.021-.654.064-.96h5.376v-.982H8.122c.312-1.198.907-2.134 1.785-2.81.878-.675 1.905-1.013 3.081-1.013 1.232 0 2.274.289 3.124.867a4.916 4.916 0 011.848 2.33H20c-.453-1.533-1.275-2.741-2.465-3.625C16.345 5.442 14.837 5 13.01 5c-1.175 0-2.248.23-3.219.69a6.423 6.423 0 00-2.412 1.953c-.637.842-1.076 1.82-1.317 2.935H4v.982h1.912c-.028.418-.042.738-.042.96 0 .39.021.774.064 1.15H4v.982h2.125c.382 1.63 1.19 2.928 2.422 3.896C9.78 19.516 11.267 20 13.01 20z"
      />
    </svg>
  );
}

export default SvgEuroIcon;
