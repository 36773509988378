import {
  formatDateAndTime,
  useTraceFindMany,
  getCivilityAllKeys,
} from '@commons';
import { ITracer } from '@freelancelabs/teoreme-commons';
import {
  ALink,
  Box,
  Flex,
  Itemize,
  Link,
  Separator,
  Text,
} from 'components/ui';
import { EyeIcon } from 'components/ui/icons';
import { useTranslationFormat } from 'hooks/useTranslateFormat';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { Theme } from 'styles';

type Item = Itemize<ITracer>;

export const LastTraces = () => {
  const history = useHistory();
  const t = useTranslationFormat();
  const { data: traces } = useTraceFindMany({
    filterObject: {},
    sort: '-createdAt',
    limit: 5,
    skip: 0,
  });

  const items: Item[] =
    //@ts-ignore
    traces?.traces?.map((trace: ITracer) => ({
      ...trace,
      key: trace.uuid || '',
    })) || [];

  return (
    <>
      <Box
        display={'inline-flex'}
        width={'100%'}
        justifyContent={'space-between'}
        mt={'8px'}
      >
        <Text variant="h3" pl={5}>
          Mon historique
        </Text>
        <Link
          iconLeft={<EyeIcon />}
          mt={1}
          onClick={() => history.push('/traces')}
        >
          Voir tout
        </Link>
      </Box>

      <Box ml={5} pl={5} backgroundColor={Theme?.colors?.lightGray}>
        <Flex
          justifyContent={'flex-start'}
          width={1 / 1}
          backgroundColor={Theme?.colors?.lightGray}
        >
          <Separator width={9 / 10} />
        </Flex>
        {items?.length === 0 ? (
          <Text ml={2} color={'primary.light'}>
            Aucune action enregistrée
          </Text>
        ) : (
          <Flex display="inline-flex" flexWrap={'wrap'} width={1 / 1}>
            {items?.map(trace => (
              <Flex
                display="inline-flex"
                justifyContent="flex-start"
                flexWrap={'wrap'}
                width={9 / 10}
              >
                <Flex
                  display="inline-flex"
                  justifyContent="flex-start"
                  flexWrap={'wrap'}
                  width={1 / 1}
                >
                  <Box width={4 / 5} mb={20}>
                    <Text fontWeight="bold" maxWidth={'100%'}>
                      {
                        t(`traces:${trace.reference}`, false, false, {
                          ...getCivilityAllKeys(
                            trace.parameters,
                            trace?.reference
                          ),
                        }).split('--')[0]
                      }
                    </Text>
                  </Box>
                  <Box width={1 / 5}>
                    <ALink
                      mt={'-5px'}
                      style={{ marginLeft: 'auto' }}
                      href={t(
                        `linkTraces:${trace?.reference}`,
                        false,
                        false,
                        trace?.parameters
                      )}
                    >
                      <Link iconLeft={<EyeIcon />}>Voir</Link>
                    </ALink>
                  </Box>
                  <Box mb={20}>
                    {t(`traces:${trace.reference}`, false, false, {
                      ...getCivilityAllKeys(trace.parameters, trace?.reference),
                    })
                      .split('--')[1]
                      ?.replaceAll('&amp;', '&')
                      ?.replaceAll('&quot;', '"')
                      ?.replaceAll('&#x2F;', '/')
                      ?.split('\n')
                      ?.map(text => (
                        <>
                          {text}
                          <br />
                        </>
                      ))}
                  </Box>
                  <Box width={1 / 1}>
                    <Text color={Theme.colors.lightBlue}>
                      {formatDateAndTime(trace.createdAt || '')}
                    </Text>
                  </Box>
                </Flex>
                <Separator />
              </Flex>
            ))}
          </Flex>
        )}
      </Box>
    </>
  );
};
export default LastTraces;
