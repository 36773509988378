import {
  getFullName,
  getTradeNameSafe,
  removeOffsetDate,
  useCreateAditionalActivity,
  useAdditionalActivityFindOne,
  useAdditionalActivityUpdateOne,
  useMissionFindOne,
  queryClient,
  getHumanDateMonthAndYear,
  ADDIONAL_FILES_NUMBER,
  getAdditionalActivitiesTotalAmount,
  formatDate,
  FR_STATUS,
  round,
  transformText,
  getAdditionActivityTextType,
} from '@commons';
import {
  EadditionalActivityStatus,
  IJoinedMission,
  EadditionalActivityType,
  IExpenseLine,
  IJoinedAdditionalActivity,
  EChangeOwnerType,
} from '@freelancelabs/teoreme-commons';
import { Header } from 'components/Header';
import TextInformation from 'components/TextInformation';
import { showDialogModal } from 'components/modals/DialogModal';
import {
  Tab,
  Box,
  Button,
  Flex,
  LabelField,
  Link,
  SpinnerBox,
  Text,
  TextArea,
  FormControl,
  Divider,
  Status,
  MenuItem,
  FileListControlled,
} from 'components/ui';
import { showValidateAdditionalActivityModal } from 'components/modals/activities/ValidateAdditionalActivityModal';
import { showRejectAdditionalActivityModal } from 'components/modals/RejectAdditionalActivityModal';
import { showImportBDCAdditionalActivityModal } from 'components/modals/activities/ImportBDCModal';
import { AddIcon, EuroIcon, EditIcon, DeleteIcon } from 'components/ui/icons';
import { useForm } from 'react-hook-form';
import { useShowMessage } from 'hooks/useShowMessage';
//@ts-ignore
import jsonQ from 'jsonq';
import React, { useState, useEffect } from 'react';
import { Theme } from 'styles';
import { AddExpensesLine } from './AddExpensesLine';
import { FileCard } from 'components/cards';
import AddBloc from 'components/AddBloc';
import { ActivityQuoteCard } from 'routes/Orders/ActivityQuoteCard';
import { HistoryList } from 'components/lists/HistoryList';
import { columns } from '../HistoryListData';
type CreateOrUpdateExpensesFormProps = {
  month?: Date;
  missionRef: string;
  method: 'CREATE' | 'UPDATE';
  uuid?: string;
  onResolve?: (params: any) => void;
};
type Line = IExpenseLine & {
  lock?: boolean;
};
const defaultLine = {
  lock: false,
};
export const CreateOrUpdateExpensesForm: React.FC<
  React.PropsWithChildren<CreateOrUpdateExpensesFormProps>
> = ({ month, missionRef, method, uuid, onResolve }) => {
  const [formSelected, setFormSelected] = useState<'INFORMATIONS' | 'HISTORY'>(
    'INFORMATIONS'
  );
  const [onCreate, setOnCreate] = useState(method === 'CREATE' ? true : false);
  const [onUpdateLine, setOnUpdateLine] = useState<string | false>(false);
  const { data: activity, isFetching: isFetchingAddtional } =
    useAdditionalActivityFindOne(uuid);
  const [providerComment, setProviderComment] = useState(
    activity?.providerComment
  );
  const expensesLines: Line[] = activity?.expenseLines || [];
  const [lines, setLines] = useState<Line[] | []>(
    method === 'UPDATE' ? expensesLines : []
  );
  const { data: mission, isFetching: isFetchingMission } =
    useMissionFindOne(missionRef);

  const showMessage = useShowMessage();

  const [loading, setLoading] = useState(false);

  const { mutateAsync: createAdditionalActivity } =
    useCreateAditionalActivity();
  const { mutateAsync: updateAdditionalActivity } =
    useAdditionalActivityUpdateOne();

  const {
    control,
    getValues,
    setError,
    formState: { errors },
  } = useForm<any>({
    defaultValues: {
      //@ts-ignore
      attachments: activity?.attachments,
    },
  });

  const existingAttachments = activity?.attachments;
  const isAttachmentsRequired = (mission: any) => {
    return mission?.expensesConfig?.shouldJoinAttachment;
  };

  const onSubmit = async (saveLater: boolean) => {
    const formValues = getValues();
    setError('attachments', { message: undefined });
    //@ts-ignores
    const attachments = formValues?.attachments;
    if (!attachments?.length) {
      if (isAttachmentsRequired(mission)) {
        setError('attachments', {
          message: 'Au moins un justificatif est requis',
        });
        return;
      }
    }

    const submitValues = {
      saveAndFinishLater: saveLater,
      missionReference: mission?.reference as string,
      type: EadditionalActivityType?.EXPENSE,
      month: month && removeOffsetDate(month),
      attachments,
      expenses: lines?.map(l => {
        return {
          ...l,
          uuid: typeof l?.uuid === 'number' ? undefined : l?.uuid,
          lock: undefined,
        };
      }) as IExpenseLine[],
    };
    try {
      setLoading(true);
      if (method === 'CREATE') {
        await createAdditionalActivity(submitValues);
        queryClient.refetchQueries({
          queryKey: ['additionalActivities'],
          type: 'active',
        });
        // queryClient.refetchQueries({ queryKey: [missionRef], { type: 'active' });
      }
      if (method === 'UPDATE') {
        const removeExpenseLines: string[] | [] = [];
        expensesLines?.forEach((expense: any) => {
          if (!lines?.find(l => l?.uuid === expense.uuid)) {
            removeExpenseLines?.push(expense.uuid as never);
          }
        });
        const addAttachments: any[] = [];
        const removeAttachments: any[] = [];
        const existingAttachmentsFileNames =
          existingAttachments?.map((f: any) => f.fileName) || [];

        const currentAttachmentsFileNames =
          attachments?.map((file: any) => file.fileName) || [];

        attachments?.forEach((sd: any) => {
          if (!existingAttachmentsFileNames?.includes(sd?.fileName)) {
            addAttachments?.push(sd);
          }
        });
        existingAttachments?.forEach((sd: any) => {
          if (!currentAttachmentsFileNames?.includes(sd?.fileName)) {
            removeAttachments?.push(sd);
          }
        });

        await updateAdditionalActivity({
          uuid: uuid,
          ...submitValues,
          removeExpenseLines,
          addAttachments,
          removeAttachments,
          status: EadditionalActivityStatus.TO_BE_SUBMITTED,
        });
      }
      queryClient.refetchQueries({
        queryKey: ['additionalActivities'],
        type: 'active',
      });
      // queryClient.refetchQueries({ queryKey: [missionRef], type: 'active' });
      queryClient.refetchQueries({ queryKey: ['ActivitiesReport'] });
      showMessage(
        'success',
        `Votre frais à été ${method === 'UPDATE' ? 'mise à jours' : 'créée'}`
      );
      queryClient.refetchQueries({
        queryKey: ['additionalActivities'],
        type: 'active',
      });
      if (onResolve && typeof onResolve === 'function') {
        onResolve(true);
      }
    } catch (e) {
      setLoading(false);
    }
    setLoading(false);
  };

  const handleDeleteLine = (line: Line) => {
    const updatedLines = jsonQ.clone(lines);
    const index = lines?.findIndex((l: Line) => l?.uuid === line?.uuid);
    updatedLines?.splice(index, 1);
    setOnUpdateLine(false);
    setLines(updatedLines);
    setOnCreate(false);
  };
  const handleCreateOrUpdateLine = (line: Line) => {
    const updatedLines = jsonQ.clone(lines);
    const index = lines?.findIndex((l: Line) => l?.uuid === line?.uuid);
    if (index !== -1) {
      updatedLines[index] = line;
    } else {
      updatedLines?.push(line);
    }

    setOnUpdateLine(false);
    setOnCreate(false);
    setLines(updatedLines);
  };
  const onDeletePo = async () => {
    const confirm = await showDialogModal({
      title: 'Êtes-vous sûr(e) de vouloir supprimer le BDC client?',
      text: 'Attention ce document sera définitivement supprimé de Connecteed.',
      confirmLabel: 'Confirmer',
      cancelLabel: 'Annuler',
    });

    if (!confirm) return;

    setLoading(true);
    await updateAdditionalActivity({
      uuid: uuid,
      purchaseOrder: {
        // @ts-ignore
        file: null,
      },
    });
    queryClient.refetchQueries({ queryKey: [uuid || ''] });
    setLoading(false);
  };
  useEffect(() => {
    if (method === 'UPDATE' && uuid) {
      if (activity && activity?.expenseLines?.length) {
        setLines(
          activity?.expenseLines?.map((expense: any) => ({
            ...expense,
            lock: true,
          }))
        );
      }
    }
  }, [activity, method, uuid]);
  const isEditable =
    !activity?.status ||
    activity?.status === EadditionalActivityStatus?.TO_BE_FILLED ||
    activity?.status === EadditionalActivityStatus?.TO_BE_SUBMITTED ||
    activity?.status === EadditionalActivityStatus?.REJECTED;

  const totalProviderAmount = () => {
    return getAdditionalActivitiesTotalAmount(
      [activity as IJoinedAdditionalActivity],
      EadditionalActivityType?.EXPENSE,
      'PROVIDER',
      false,
      activity?.customerMargin
    );
  };
  const totalCustomerAmount = () => {
    return getAdditionalActivitiesTotalAmount(
      [activity as IJoinedAdditionalActivity],
      EadditionalActivityType?.EXPENSE,
      'CUSTOMER',
      false,
      activity?.customerMargin
    );
  };
  const validateAdditionalActivity = async (additionalActivity: any) => {
    try {
      setLoading(true);
      const textType = getAdditionActivityTextType(additionalActivity);
      await updateAdditionalActivity({
        uuid: additionalActivity.uuid,
        status: EadditionalActivityStatus.VALIDATED,
        milestoneAmount: additionalActivity?.milestoneAmount,
        addAttachments: additionalActivity?.addAttachments,
        removeAttachments: additionalActivity?.removeAttachments,
      });
      showMessage(
        'success',
        `${transformText(textType, 'capitalize-first')} a bien été validé`
      );
      queryClient?.invalidateQueries({ queryKey: [additionalActivity.uuid] });
      queryClient.refetchQueries({
        queryKey: ['additionalActivities'],
        type: 'active',
      });
      queryClient?.refetchQueries({
        queryKey: ['ActivitiesReport'],
        type: 'active',
      });
    } catch (e) {
      //
      setLoading(false);
    }
    setLoading(false);
  };
  if (isFetchingAddtional || isFetchingMission) {
    return <SpinnerBox />;
  }
  if (method === 'CREATE' && !mission?.expensesConfig?.isEnabled) {
    return (
      <Box width={1 / 1}>
        <Header tabs={false} height={120}>
          <Text variant="h1">Déclarer des frais</Text>
        </Header>
        Vous ne pouvez pas créer de frais sur cette mission.
      </Box>
    );
  }
  const exepensesIsEnabled = mission?.expensesConfig?.isEnabled;
  const haveExpenseBudget =
    exepensesIsEnabled && mission?.expensesConfig?.purchaseBudget;
  if (method === 'CREATE' && !exepensesIsEnabled) {
    return (
      <Box width={1 / 1}>
        <Header tabs={false} height={120}>
          <Text variant="h1">Déclarer des frais</Text>
        </Header>
        Vous ne pouvez pas créer de frais sur cette mission.
      </Box>
    );
  }
  const expenseBudget = mission?.expensesConfig?.purchaseBudget || 0;
  const expenseConsumedBudget = mission?.expensesConfig?.consumedPurchaseBudget;
  let totalLines = 0;
  lines?.forEach(l => (totalLines += Number(l?.amount || 0)));
  const currentConsumedBudget =
    (method === 'CREATE' ? totalLines : 0) + (expenseConsumedBudget || 0);
  const isOutOffBudget = haveExpenseBudget
    ? currentConsumedBudget > (expenseBudget || 0)
    : false;
  const stepperPercentExpenses = (): { percent: string; color: string } => {
    let color = '';

    const percent = ((currentConsumedBudget || 0) / (expenseBudget || 0)) * 100;
    if (percent > 100) {
      color = Theme?.colors?.error?.default;
      return { percent: '100%', color };
    }
    if (percent >= 80) {
      color = Theme?.colors?.warning?.default;
    } else {
      color = Theme?.colors?.primary?.default;
    }
    return { percent: `${round(percent)}%`, color };
  };
  const stepperWidthExpense = stepperPercentExpenses()?.percent;
  const colorStepperExpense = stepperPercentExpenses()?.color;
  return (
    <Box width={1 / 1}>
      <Text variant="h1" mb={10}>
        {method === 'UPDATE' ? 'Modifier' : 'Déclarer'} des frais
      </Text>
      {method === 'UPDATE' && (
        <Flex mb={10}>
          <Tab
            onClick={() => setFormSelected('INFORMATIONS')}
            isSelected={formSelected === 'INFORMATIONS'}
            variant="secondary"
            fontSize={12}
          >
            Informations
          </Tab>

          <Tab
            onClick={() => setFormSelected('HISTORY')}
            isSelected={formSelected === 'HISTORY'}
            variant="secondary"
            fontSize={12}
          >
            Historique
          </Tab>
        </Flex>
      )}
      {formSelected === 'HISTORY' && (
        <Box>
          <HistoryList
            columns={columns}
            filterObject={{
              ownerId: uuid,
              ownerType: EChangeOwnerType?.ACTIVITY,
            }}
          />
        </Box>
      )}
      {formSelected === 'INFORMATIONS' && (
        <Box width={1 / 1}>
          {activity?.rejectReason &&
            activity?.status === EadditionalActivityStatus?.REJECTED && (
              <Box>
                <TextInformation variant="error" width={1 / 1} mb={10}>
                  {`Cette prestation complémentaire été refusé le ${new Date(
                    activity?.statusChangedAt as Date
                  ).toLocaleDateString()} pour cause de : ${activity?.rejectReason}`}
                </TextInformation>
              </Box>
            )}
          <Flex>
            <Box width={1 / 1}>
              <Flex
                height={'100px'}
                p={25}
                justifyContent="center"
                alignItems="center"
                alignContent="center"
                backgroundColor="#edf3ff"
                borderRadius="8px"
              >
                {month && (
                  <LabelField
                    underline
                    label="Période"
                    value={getHumanDateMonthAndYear(month)}
                  />
                )}
                <LabelField
                  underline
                  ml={5}
                  label="Référence mission"
                  value={mission?.displayReference}
                />
                <LabelField
                  underline
                  ml={5}
                  label="Client"
                  value={
                    mission &&
                    getTradeNameSafe(mission?.customer?.establishment)
                  }
                />
                <LabelField
                  underline
                  ml={5}
                  label="Intervenant"
                  value={getFullName(mission?.provider?.contractor)}
                />
              </Flex>
            </Box>
          </Flex>
          <Box>
            {haveExpenseBudget !== undefined && haveExpenseBudget !== 0 && (
              <LabelField
                label="Budget des frais consommé"
                value={
                  <Box mb={20}>
                    <Flex
                      width={1 / 1}
                      zIndex={100}
                      justifyContent="space-around"
                      borderBottom={'12px solid #eaeefc'}
                      borderRadius={'6px'}
                    >
                      <Box
                        position="absolute"
                        left={0}
                        bottom={-12}
                        //@ts-ignore
                        width={stepperWidthExpense}
                        borderRadius={'6px'}
                        borderBottom={`12px solid ${colorStepperExpense}`}
                      />
                      <Box
                        width={1 / 1}
                        position="absolute"
                        left={0}
                        bottom={-12}
                      >
                        <Text
                          textAlign={'center'}
                          color={
                            Number(stepperWidthExpense?.replace('%', '')) > 47
                              ? 'white'
                              : 'black'
                          }
                          fontSize={10}
                        >
                          {stepperWidthExpense}
                        </Text>
                      </Box>
                    </Flex>
                    <Text mt={20} width={1 / 1}>
                      {`Vous avez consommé ${
                        currentConsumedBudget || 0
                      } € de frais sur ${round(
                        expenseBudget
                      )} € prévus sur cette mission.`}
                    </Text>
                  </Box>
                }
                underline
                mb={10}
              />
            )}
          </Box>
          {lines
            ?.sort((a: Line, b: Line) => Number(b.lock) - Number(a.lock))
            ?.map((l: Line, index) => (
              <>
                <AddExpensesLine
                  key={`line_${uuid}`}
                  isEditable={isEditable}
                  readOnly={onUpdateLine === l?.uuid ? false : l?.lock}
                  line={l}
                  mission={mission as IJoinedMission}
                  onCreateOrUpdateLine={(line: Line) =>
                    handleCreateOrUpdateLine(line)
                  }
                  unLockToUpdate={(line: Line) =>
                    setOnUpdateLine(line?.uuid as string)
                  }
                  onDeleteLine={(line: Line) => handleDeleteLine(line)}
                />
                {index + 1 !== lines?.length && <Divider />}
              </>
            ))}
          {onCreate && (
            <>
              <AddExpensesLine
                key={`line_add`}
                readOnly={false}
                //@ts-ignore
                line={defaultLine}
                mission={mission as IJoinedMission}
                onCreateOrUpdateLine={(line: Line) =>
                  handleCreateOrUpdateLine(line)
                }
                onDeleteLine={(line: Line) => handleDeleteLine(line)}
              />
              <Divider />
            </>
          )}
          {!onCreate && isEditable && (
            <Box mt={20}>
              <Link
                onClick={() => setOnCreate(true)}
                iconLeft={<AddIcon fill={Theme?.colors?.primary?.default} />}
              >
                Ajouter un frais
              </Link>
            </Box>
          )}
          <Box mt={25}>
            <Text variant="b" textAlign={'left'}>
              Justificatifs
            </Text>
            <FormControl
              label=""
              errorMessage={errors?.attachments?.message}
              mt={-20}
            />

            <FileListControlled
              control={control}
              maxFile={lines.length + ADDIONAL_FILES_NUMBER}
              name="attachments"
              missionRef={mission?.reference}
              isDisabled={!isEditable}
              readOnly={!isEditable}
              actionName={'Importer un justificatif'}
              accept=".pdf"
              defaultValue={activity?.attachments as any}
              onChangeAction={(e: any) => {
                if (e?.length) setError('attachments', { message: undefined });
              }}
            />
          </Box>
          <Box width={12 / 12} mt={20}>
            <Box width={12 / 12}>
              <FormControl label="Commentaire envoyé">
                <TextArea
                  isDisabled={!isEditable}
                  minRows={3}
                  name={'providerComment'}
                  value={providerComment}
                  onChange={e => setProviderComment(e?.target?.value)}
                  placeholder={
                    isEditable
                      ? 'Ajouter un commentaire destiné aux équipes Freelance.com'
                      : 'N/A'
                  }
                />
              </FormControl>
            </Box>
          </Box>
          {method === 'UPDATE' && (
            <Box width={12 / 12}>
              <FormControl label="Devis client">
                <ActivityQuoteCard
                  additionalActivity={activity}
                  isDisabled={
                    activity?.status === EadditionalActivityStatus.VALIDATED
                  }
                />
              </FormControl>
              {/* <FormControl label="Devis client">
              {activity?.quote ? (
                <FileCard
                  layer={104}
                  fileName={activity?.quote?.file?.fileName || ''}
                  fileLocation={
                    (activity && activity.quote?.file.fileLocation) || ''
                  }
                  complement={
                    <Status variantColor="success">{`${
                      activity?.quote?.status
                        ? FR_STATUS[activity.quote?.status]
                        : ''
                    } le ${formatDate(
                      activity?.quote?.updatedAt ||
                        activity?.quote?.createdAt ||
                        ''
                    )}`}</Status>
                  }
                  actions={
                    <>
                      <MenuItem
                        onClick={() =>
                          showGenerateActivityQuoteModal({
                            additionalActivity:
                              activity as IJoinedAdditionalActivity,
                          })
                        }
                      >
                        <Link iconLeft={<SendIcon />}>
                          Génerer et envoyer un devis
                        </Link>
                      </MenuItem>
                    </>
                  }
                />
              ) : (
                <AddBloc>
                  <Flex>
                    <Link
                      iconLeft={<SendIcon />}
                      onClick={() =>
                        showGenerateActivityQuoteModal({
                          additionalActivity:
                            activity as IJoinedAdditionalActivity,
                        })
                      }
                    >
                      Génerer et envoyer le devis
                    </Link>
                  </Flex>
                </AddBloc>
              )}
            </FormControl> */}
              <FormControl label="BDC client">
                {activity?.purchaseOrder?.file ? (
                  <FileCard
                    isDisabled={
                      activity?.status === EadditionalActivityStatus.VALIDATED
                    }
                    layer={105}
                    fileName={
                      `${
                        activity?.purchaseOrder?.reference || 'BDC'
                      }_${activity?.mission.displayReference.replace(
                        / /g,
                        '_'
                      )}.pdf` || ''
                    }
                    fileLocation={
                      activity?.purchaseOrder?.file?.fileLocation ?? ''
                    }
                    complement={
                      <Status variantColor="success">{`${
                        activity?.purchaseOrder?.status
                          ? FR_STATUS[
                              activity.purchaseOrder
                                ?.status as keyof typeof FR_STATUS
                            ]
                          : ''
                      } le ${formatDate(
                        activity?.purchaseOrder?.updatedAt ||
                          activity?.purchaseOrder?.createdAt ||
                          ''
                      )}`}</Status>
                    }
                    actions={
                      <>
                        <MenuItem
                          onClick={() =>
                            showImportBDCAdditionalActivityModal({
                              additionalActivity:
                                activity as IJoinedAdditionalActivity,
                            })
                          }
                        >
                          <Link iconLeft={<EditIcon />}>
                            Modifier le BDC client
                          </Link>
                        </MenuItem>
                        <MenuItem onClick={onDeletePo}>
                          <Link iconLeft={<DeleteIcon />}>
                            Supprimer le BDC client
                          </Link>
                        </MenuItem>
                      </>
                    }
                  />
                ) : (
                  <>
                    {activity?.status !==
                      EadditionalActivityStatus.VALIDATED && (
                      <AddBloc>
                        <Flex>
                          <Link
                            iconLeft={<AddIcon />}
                            onClick={() =>
                              showImportBDCAdditionalActivityModal({
                                additionalActivity:
                                  activity as IJoinedAdditionalActivity,
                              })
                            }
                          >
                            Importer un BDC client
                          </Link>
                        </Flex>
                      </AddBloc>
                    )}
                  </>
                )}
              </FormControl>
            </Box>
          )}
          {method === 'UPDATE' && (
            <Flex width={1 / 1} justifyContent="flex-end" mt={10}>
              <Box width={1 / 1}>
                <Text
                  p={10}
                  variant="h1"
                  color="#009933"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  backgroundColor="rgba(0, 153, 51, 0.1)"
                  fontWeight={500}
                >
                  Montant fournisseur total HT : {totalProviderAmount()}
                  <EuroIcon fill="#009933" />
                </Text>
              </Box>
              <Box width={1 / 1}>
                <Text
                  p={10}
                  variant="h1"
                  color="#009933"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  backgroundColor="rgba(0, 153, 51, 0.1)"
                  fontWeight={500}
                >
                  Montant client total HT : {totalCustomerAmount()}
                  <EuroIcon fill="#009933" />
                </Text>
              </Box>
            </Flex>
          )}
          <Flex mt={10} justifyContent="flex-end">
            {method === 'CREATE' && !activity && (
              <Flex>
                <Button
                  onClick={() => onSubmit(false)}
                  isLoading={loading}
                  isDisabled={lines?.length < 1 || isOutOffBudget}
                >
                  Créer
                </Button>
              </Flex>
            )}
            {activity?.status ===
              EadditionalActivityStatus?.TO_BE_VALIDATED && (
              <Flex>
                <Button
                  isLoading={loading}
                  isDisabled={lines?.length < 1}
                  variant="success"
                  ml={10}
                  onClick={async () => {
                    if (activity?.mission?.isMandateMode) {
                      await validateAdditionalActivity(activity);
                    } else {
                      showValidateAdditionalActivityModal({
                        additionalActivity: activity,
                      })?.then(action => {
                        if (action) {
                          if (onResolve) {
                            onResolve(true);
                          }
                        }
                      });
                    }
                  }}
                >
                  Valider
                </Button>
                <Button
                  isLoading={loading}
                  isDisabled={lines?.length < 1}
                  variant="ghostError"
                  ml={10}
                  onClick={async () => {
                    await showRejectAdditionalActivityModal({
                      additionalActivity: activity,
                    })?.then(action => {
                      if (action) {
                        queryClient.refetchQueries({
                          queryKey: ['additionalActivities'],
                          type: 'active',
                        });
                        queryClient?.refetchQueries({
                          queryKey: ['ActivitiesReport'],
                          type: 'active',
                        });

                        if (onResolve) {
                          onResolve(true);
                        }
                      }
                    });
                  }}
                >
                  Refuser
                </Button>
              </Flex>
            )}
          </Flex>
        </Box>
      )}
    </Box>
  );
};

CreateOrUpdateExpensesForm.displayName = 'CreateOrUpdateExpensesForm';

export default CreateOrUpdateExpensesForm;
