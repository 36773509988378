import {
  contractorUpdateOne,
  getFullName,
  transformText,
  useContractorFindOne,
  setBirthdayDateToZeroHours,
  validateFormPhoneFormatFR,
  checkAllStrcutureCreatedInGescomForContractor,
} from '@commons';
import { showAddContractorGescomModal } from 'components/modals/AddContractorGescomModal';
import { ConfirmNavigationModal } from 'components/modals/ConfirmNavigationModal';
import { Section } from 'components/Section';
import {
  Flex,
  Box,
  Button,
  FormControl,
  Input,
  Link,
  Radio,
  Row,
  Status,
  PhoneInputControlled,
  DatePickerControlled,
} from 'components/ui';
import { EditIcon } from 'components/ui/icons';
import { useSetCrumbs } from 'hooks/breadCrumb';
import React from 'react';
import { useForm } from 'react-hook-form';
import { queryClient } from '@commons';
import { useParams } from 'react-router-dom';
import { differenceInYears } from 'date-fns';

type FormValues = {
  civility: string | number;
  firstName: string;
  lastName: string;
  jobTitle: string;
  phone: string;
  birthday: Date;
};
export const ProvidersContractorsInformation = () => {
  const { id: uuid } = useParams<{ id: string }>();
  const { data: contractor } = useContractorFindOne(uuid);
  const [loading, setLoading] = React.useState(false);
  //@ts-ignore
  const date = new Date(contractor?.birthday);
  // const timeZone = 'Zulu';
  // const zonedDate = toZonedTime(date, timeZone);
  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors, isDirty, isSubmitSuccessful },
  } = useForm<FormValues>({
    defaultValues: {
      ...contractor,
      civility: contractor?.civility ? contractor?.civility.toString() : '',
      firstName: contractor?.firstName,
      lastName: contractor?.lastName,
      phone: contractor?.phone,
      jobTitle: contractor?.jobTitle,
      //@ts-ignore
      birthday: date,
    },
  });
  useSetCrumbs(
    [contractor],
    [
      {
        label: 'fournisseurs',
        path: '/providers',
      },
      {
        label: 'intervenants',
        path: '/providers/contractors',
      },
      {
        label: contractor ? getFullName(contractor) : '',
        path: '/providers/contractors/' + contractor?.uuid,
      },
    ]
  );

  const onSubmit = async (data: FormValues) => {
    if (!contractor) return;
    setLoading(true);
    const formatedDate = setBirthdayDateToZeroHours(data?.birthday);
    await contractorUpdateOne({
      uuid,
      contractor: {
        //@ts-ignore
        civility: data?.civility,
        firstName: transformText(data.firstName, 'capitalize').trim(),
        lastName: transformText(data.lastName, 'uppercase').trim(),
        jobTitle: transformText(data.jobTitle, 'capitalize-first').trim(),
        phone: data.phone,
        birthday: formatedDate,
      },
    });

    // reset dirty values
    data.firstName = transformText(data.firstName, 'capitalize').trim();
    data.lastName = transformText(data.lastName, 'uppercase').trim();
    data.jobTitle = transformText(data.jobTitle, 'capitalize-first').trim();
    reset({ ...data, civility: data.civility });
    queryClient.refetchQueries({ queryKey: [contractor?.uuid] });
    setLoading(false);
  };

  const sage = (contractor as any)?.sage;
  const checkSageData = checkAllStrcutureCreatedInGescomForContractor(sage);
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <ConfirmNavigationModal active={isDirty && !isSubmitSuccessful} />

      <Section
        variant="h2"
        title={
          <>
            Informations générales
            {!checkSageData?.isReady ? (
              <Link
                ml={10}
                iconLeft={<EditIcon />}
                mt={'5px'}
                onClick={() =>
                  showAddContractorGescomModal({
                    contractor: contractor as any,
                  })
                }
              >
                {`Créer dans GESCOM (
            ${checkSageData?.missingStructure?.map(
              structure => `${structure} `
            )} )`}
              </Link>
            ) : (
              <></>
            )}
          </>
        }
      >
        <Flex>
          <Status
            mt={-20}
            variantColor={!checkSageData?.isReady ? 'warning' : 'success'}
          >
            {!checkSageData?.isReady
              ? `À créer dans GESCOM (${checkSageData?.missingStructure?.map(
                  strcuture => strcuture
                )})`
              : 'Créé dans GESCOM'}
          </Status>
        </Flex>
        {/* <Box mt={30} mb={20}>
          <Input
            isFullWidth
            value={contractor?.user?.email || ''}
            disabled
            isDisabled
          />
        </Box> */}
        <Box mt={40}>
          <FormControl
            required
            label={'Civilité'}
            errorMessage={errors?.civility?.message}
          >
            <>
              <Radio
                {...register('civility', { required: 'Ce champs est requis' })}
                value="1"
              >
                Monsieur
              </Radio>
              <Radio
                {...register('civility', { required: 'Ce champs est requis' })}
                value="2"
              >
                Madame
              </Radio>
            </>
          </FormControl>
        </Box>
        <Row spacing={20}>
          <FormControl
            required
            label="Prénom"
            errorMessage={errors?.firstName?.message}
          >
            <Input
              isFullWidth
              {...register('firstName', { required: 'Ce champs est requis' })}
              onChange={e => {
                // @ts-ignore
                e.target.value = e.target.value.trimStart();
              }}
            />
          </FormControl>

          <FormControl
            required
            label="Nom"
            errorMessage={errors?.lastName?.message}
          >
            <Input
              isFullWidth
              {...register('lastName', { required: 'Ce champs est requis' })}
              onChange={e => {
                // @ts-ignore
                e.target.value = e.target.value.trimStart();
              }}
            />
          </FormControl>
        </Row>
        <Row spacing={20}>
          <FormControl
            required
            label="Date de naissance"
            errorMessage={errors?.birthday?.message}
          >
            <DatePickerControlled
              control={control}
              name="birthday"
              valueName="selected"
              rules={{
                required: 'Ce champs est requis',
                validate: (value: any) => {
                  return differenceInYears(new Date(), value) < 18
                    ? "L'intervenant doit etre majeur"
                    : undefined;
                },
              }}
            />
          </FormControl>
          <FormControl
            label="Fonction"
            errorMessage={errors?.jobTitle?.message}
          >
            <Input
              isFullWidth
              {...register('jobTitle')}
              onChange={e => {
                // @ts-ignore
                e.target.value = e.target.value.trimStart();
              }}
            />
          </FormControl>
        </Row>
        <Row spacing={20}>
          <FormControl label="Téléphone" errorMessage={errors?.phone?.message}>
            <PhoneInputControlled
              rules={{
                validate: (value: string) => {
                  return validateFormPhoneFormatFR(value);
                },
              }}
              control={control}
              isFullWidth
              name="phone"
            />
          </FormControl>
          <></>
        </Row>
        <Button isLoading={loading}>Enregistrer</Button>
      </Section>
    </form>
  );
};
