import {
  IChangeHistory,
  ECraStatus,
  IChangeTypeCra,
  ERejectCraReason,
} from '@freelancelabs/teoreme-commons';
import { cutLongText, getFullName, getHumanDate } from '@commons';
import { getStatusText } from 'helpers';
import { Link, Box, Text, Status, CustomToolTip } from 'components/ui';
import { showDisplayPdfModal } from 'components/modals/DisplayPdfModal';
import { EyeIcon } from 'components/ui/icons';
export const columns = () => [
  {
    key: 'changedAt',
    text: 'DATE',
    render: (item: IChangeHistory) =>
      getHumanDate(item?.changedAt as Date, 'dd/MM/yyyy à HH:mm'),
  },
  {
    key: 'user',
    text: 'UTILISATEUR',
    render: (item: IChangeHistory) =>
      item?.changedBy ? getFullName(item.changedBy as any) : 'N/A',
  },
  {
    key: 'state',
    text: 'STATUT',
    render: (item: IChangeHistory) => {
      if (item?.to?.state) {
        const data = getStatusText(item?.to?.state as ECraStatus);
        return (
          <Box>
            <Status variantColor={data.variantColor}>{data?.statusText}</Status>
          </Box>
        );
      }
      return 'N/A';
    },
  },
  {
    key: 'comment',
    text: 'COMMENTAIRE',
    render: (item: IChangeHistory) => {
      const to = item?.to as IChangeTypeCra;
      if (to?.accountManagerComment) {
        return (
          <CustomToolTip id={item?.uuid} text={to.accountManagerComment}>
            {cutLongText(to.accountManagerComment, 20)}
          </CustomToolTip>
        );
      }
      return 'N/A';
    },
  },
  {
    key: 'rejectReason',
    text: 'MOTIF',
    render: (item: IChangeHistory) => {
      const to = item?.to as IChangeTypeCra;
      if (to?.rejectReason === ERejectCraReason?.NON_COMPLIANT_PROOF) {
        return <Text>Justificatif non conforme</Text>;
      } else {
        if (to.rejectMetaData) {
          return (
            <CustomToolTip id={item?.uuid} text={to.rejectMetaData}>
              {cutLongText(to.rejectMetaData, 20)}
            </CustomToolTip>
          );
        }
        return 'N/A';
      }
    },
  },
  {
    key: 'proof',
    text: 'JUSTIFICATIF',
    render: (item: IChangeHistory) => {
      if (item?.to?.proof) {
        return (
          <Link
            iconLeft={<EyeIcon />}
            onClick={() =>
              showDisplayPdfModal({
                fileLocation: item?.to?.proof?.fileLocation || '',
                fileName: `${item?.to?.proof?.fileName}.pdf`,
                layer: 200,
              })
            }
          >
            Voir le fichier
          </Link>
        );
      }
      return 'N/A';
    },
  },
];
