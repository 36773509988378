import React from 'react';
import DatePickerElement, {
  ReactDatePickerProps,
  registerLocale,
} from 'react-datepicker';
import { fr } from 'date-fns/locale';
import 'react-datepicker/dist/react-datepicker.css';
import styled from 'styled-components';
import { Input } from './Input';
import { CalendarIcon } from './icons';
const DatePickerElementWrapper = styled.div`
  .react-datepicker-wrapper {
    width: 100%;
  }
`;

export const DatePicker = (
  props: ReactDatePickerProps & {
    disabled?: boolean;
    actionOnSelect?: (date: Date) => void;
  }
) => {
  registerLocale('fr', fr);
  const [localDate, setLocalDate] = React.useState<Date | null>(
    props?.value ? (props?.value as unknown as Date) : null
  );
  React.useEffect(() => {
    if (props.value === null) {
      setLocalDate(null);
    }
  }, [props.value]);
  return (
    <DatePickerElementWrapper>
      <DatePickerElement
        autoComplete="off"
        onSelect={date => {
          setLocalDate(date);
          if (props?.actionOnSelect) {
            props?.actionOnSelect(date);
          }
        }}
        selected={localDate}
        locale="fr"
        dateFormat="dd/MM/yyyy"
        showPopperArrow={false}
        showMonthYearPicker={props.showMonthYearPicker}
        customInput={
          <Input
            autoComplete="off"
            isDisabled={props.disabled}
            isFullWidth
            data-cy="datepicker"
            icon={<CalendarIcon />}
          />
        }
        {...props}
      />
    </DatePickerElementWrapper>
  );
};
