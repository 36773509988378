import React, { ComponentProps, Fragment } from 'react';
import { Box } from './Box';

type RowProps = {
  children: React.ReactNode[];
  spacing: number;
};
export const Row = ({
  children,
  spacing,
  ...props
}: RowProps & ComponentProps<typeof Box>) => {
  return (
    <Box display="flex" {...props}>
      {children.map(
        (child, index) =>
          child && (
            <Fragment key={index}>
              <Box flex={1}>{child}</Box>
              {index !== children.length - 1 && <Box width={spacing + 'px'} />}
            </Fragment>
          )
      )}
    </Box>
  );
};
