import {
  Button,
  Flex,
  FormControl,
  Text,
  Input,
  FormLabel,
  Box,
  Row,
  BlocInformation,
  PhoneInputControlled,
  StaticSelectControlled,
} from 'components/ui';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  IJoinedContractor,
  EContractorSocialStatus,
} from '@freelancelabs/teoreme-commons';
import {
  CONTRACTOR_SOCIAL_STATUS_SELECT,
  validateFormPhoneFormatFR,
  checkFormEmail,
} from '@commons';

type FormValues = {
  scopedData?: any; //IJoinedContractorScopedData;
};

type UpdateContractorScopedDataFormProps = {
  contractor?: IJoinedContractor;
  defaultValues?: FormValues;
  onSubmit?: any; // TODO MAKE TYPES
};

export const UpdateContractorScopedDataForm = ({
  contractor,
  defaultValues,
  onSubmit: onSubmitParent,
}: UpdateContractorScopedDataFormProps) => {
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isDirty },
  } = useForm<FormValues>({
    defaultValues: { ...defaultValues },
  });
  const onSubmit = async (formValues: FormValues) => {
    setLoading(true);
    const values: FormValues = {
      scopedData: {
        phone: formValues.scopedData?.phone,
        email: formValues.scopedData?.email,
        jobTitle: formValues.scopedData?.jobTitle,
        socialStatus: formValues.scopedData
          ?.socialStatus as EContractorSocialStatus,
      },
    };
    await onSubmitParent?.(values);
    setLoading(false);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Text variant="h2" mb={20}>
        Modifier les informations de cet intervenant pour cet établissement
      </Text>

      <Text mt={20} variant="h3">
        Informations de l'intervenant
      </Text>

      <Box mb={20} mt={20}>
        <BlocInformation>
          <p>
            {
              'A noter : si l’intervenant est rattaché à plusieurs établissements fournisseurs d’une même entreprise, toute modification du statut social (et/ou de la DPAE associée) de l’intervenant sera appliquée de manière automatique à l’ensemble des établissements de la même entreprise.'
            }
          </p>
        </BlocInformation>
      </Box>
      <FormLabel>Intervenant</FormLabel>
      <Text>
        {(contractor?.civility as any) === '2' ? 'Mme.' : 'M.'} &nbsp;
        {contractor?.lastName}&nbsp;
        {contractor?.firstName}&nbsp;
      </Text>

      <Box mt={20}>
        <Row spacing={20}>
          <FormControl
            label="Fonction liée à l'établissement"
            errorMessage={
              // @ts-ignore
              errors?.scopedData?.jobTitle?.message
            }
          >
            <Input
              isFullWidth
              {...register('scopedData.jobTitle')}
              onChange={e => {
                // @ts-ignore
                e.target.value = e.target.value.trimStart();
              }}
            />
          </FormControl>

          <FormControl
            label="Téléphone lié à l'établissement"
            errorMessage={
              // @ts-ignore
              errors?.scopedData?.phone?.message
            }
          >
            <PhoneInputControlled
              control={control}
              isFullWidth
              name="scopedData.phone"
              rules={{
                validate: (value: string) => {
                  return validateFormPhoneFormatFR(value);
                },
              }}
            />
          </FormControl>
        </Row>
      </Box>

      <FormControl
        label={'Email secondaire'}
        errorMessage={
          // @ts-ignore
          errors?.scopedData?.email?.message
        }
      >
        <Input
          isFullWidth
          {...register('scopedData.email', {
            required: false,
            validate: value => checkFormEmail(value as string),
          })}
          type="email"
        />
      </FormControl>
      <FormControl
        label="Statut social de l'intérvenant au sein de l'établissement fournisseur"
        required
      >
        <StaticSelectControlled
          control={control}
          options={CONTRACTOR_SOCIAL_STATUS_SELECT}
          name="scopedData.socialStatus"
          placeholder="Statut social"
        />
      </FormControl>

      <Flex>
        <Button type="submit" isLoading={loading} isDisabled={!isDirty}>
          Enregistrer
        </Button>
      </Flex>
    </form>
  );
};
