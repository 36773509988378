import * as React from 'react';
import { Container } from './styles';

type Props = {
  label?: string;
  value: boolean;
  onChange: (value: boolean) => void;
  disabled?: boolean;
};

export const Toggle: React.FC<React.PropsWithChildren<Props>> = props => {
  const { value, onChange, label, disabled } = props;

  const onClickEvent = React.useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
      if (!disabled) {
        onChange(event.target.checked);
      }
    },
    [disabled, onChange]
  );
  return (
    <Container>
      <label className="switch">
        <input
          disabled={disabled}
          type="checkbox"
          onChange={onClickEvent}
          checked={value}
        />
        <span className="slider round"></span>
      </label>
      <p>{label}</p>
    </Container>
  );
};

export default Toggle;
