import {
  EInvoiceStatus,
  IJoinedInvoice,
  IAdditionalActivity,
  EInvoiceType,
  EBillingType,
  EInvoiceDocumentType,
} from '@freelancelabs/teoreme-commons';
import {
  Flex,
  Box,
  Text,
  Status,
  Menu,
  MenuItem,
  ALink,
  Link,
} from 'components/ui';
import { useSetCrumbs } from 'hooks/breadCrumb';
import { useParams } from 'react-router-dom';
import { CustomToolTip } from 'components/ui';
import {
  useEstablishmentFindOne,
  getTradeNameSafe,
  getFullName,
  isTimeSpentInvoice,
  isStandByDutyInvoice,
  getEstablishmentName,
  INVOICE_PROVIDER_TYPES,
  isCreditNoteInvoice,
  isExpenseInvoice,
  isMileStoneInvoice,
} from '@commons';
import { InvoiceList } from 'components/lists/InvoiceList';
import { DotsIcon, EyeIcon, FastCashIcon } from 'components/ui/icons';
import { Theme } from 'styles';
import { useTranslationFormat } from 'hooks/useTranslateFormat';
import { showDisplayPdfModal } from 'components/modals/DisplayPdfModal';
import { useInvoiceGlobalListStore } from 'store';
import { InvoicesFilersList } from './InvoicesFilters';

export const Invoices = () => {
  const { id } = useParams<{ id: string }>();
  const { data: establishment } = useEstablishmentFindOne(id);
  useSetCrumbs(
    [establishment],
    [
      {
        label: 'fournisseurs',
        path: '/providers',
      },
      {
        label: 'établissements',
        path: '/providers/establishments',
      },
      {
        label: establishment ? getTradeNameSafe(establishment) : '-',
        path: '/providers/establishments/' + establishment?.uuid || '',
      },
      {
        label: 'Factures',
        path: `/providers/establishments/${establishment?.uuid || ''}/invoices`,
      },
    ]
  );
  const t = useTranslationFormat();
  const invoiceTypeText = (invoiceType: EInvoiceType) => {
    return t(`texts:INVOICETYPE_${invoiceType}`, 'capitalize-first');
  };
  const columns = () => [
    {
      key: 'sageInvoice',
      text: 'N° FACTURE',
      render: (item: IJoinedInvoice) => {
        if (item?.sage?.documentType === EInvoiceDocumentType.INVOICE) {
          return (
            <ALink
              href={`/orders/invoices/customer/time-spent/${item?.uuid}`}
              target="_blank"
            >
              {' '}
              <Text>{item?.sage?.invoiceId || 'N/A'}</Text>{' '}
            </ALink>
          );
        }
      },
    },
    {
      key: 'displayReference',

      text: 'REF.MISSION',
      render: (item: IJoinedInvoice) => {
        const deadline = item?.paymentDeadline as number;
        return (
          <>
            {item?.status === EInvoiceStatus.TO_BE_VALIDATED &&
            /*@ts-ignore*/
            item?.hasFastCash ? (
              <Box>
                <CustomToolTip
                  id={item.uuid}
                  text={
                    <Text color="white" fontSize={11}>
                      Fast cash {deadline} jours
                    </Text>
                  }
                >
                  <Box mr={20}>
                    <FastCashIcon
                      deaddline={deadline}
                      fill={
                        deadline > 10
                          ? Theme.colors.warning.default
                          : Theme.colors.error.default
                      }
                    />
                  </Box>
                </CustomToolTip>
              </Box>
            ) : (
              // <Box
              //   ml={
              //     item?.status === EInvoiceStatus.TO_BE_VALIDATED ? '40px' : 0
              //   }
              // >
              //   {' '}
              // </Box>
              <></>
            )}
            <CustomToolTip
              text={item?.mission?.description}
              id={item?.uuid + '_description'}
            >
              <Text>{item.mission.displayReference}</Text>
            </CustomToolTip>
          </>
        );
      },
    },
    {
      key: 'structure',
      text: 'STRU',
      render: (item: IJoinedInvoice) => item?.mission?.sage?.structure,
    },
    {
      key: 'month',
      text: 'MOIS',
      render: (item: IJoinedInvoice) => {
        if (
          isMileStoneInvoice(item?.invoiceType) ||
          item?.mission?.billing?.type === EBillingType.FLAT_RATE
        ) {
          return `${
            new Date(item?.createdAt as Date)?.getUTCMonth() + 1
          }/${new Date(item?.createdAt as Date)?.getUTCFullYear()}`;
        }

        return `${new Date(item?.month as Date)?.getUTCMonth() + 1}/${new Date(
          item?.month as Date
        )?.getUTCFullYear()}`;
      },
    },
    {
      key: 'invoiceType',
      text: 'TYPE DE PRESTATION',
      render: (item: IJoinedInvoice) => {
        const isMarginInvoice = item?.isMarginInvoice ? ' (marge)' : '';
        if (isTimeSpentInvoice(item.invoiceType))
          return 'Prestation standard' + isMarginInvoice;
        if (isStandByDutyInvoice(item.invoiceType))
          return 'Prestation complémentaire' + isMarginInvoice;
        if (isExpenseInvoice(item.invoiceType))
          return 'Frais' + isMarginInvoice;
        return invoiceTypeText(item.invoiceType);
      },
    },
    {
      key: 'invoiceTypeAvoir',
      text: 'TYPE DE FACTURE',
      render: (item: IJoinedInvoice) => {
        if (isCreditNoteInvoice(item.invoiceType)) {
          return 'Avoir';
        }
        return 'Facture';
      },
    },
    {
      key: 'statusChangedAt',
      text: 'REÇUE LE',
      render: (item: IJoinedInvoice) =>
        //@ts-ignore
        item?.statusChangedAt && item?.statusChangedAt.toLocaleDateString(),
    },
    {
      key: 'estCustomer',
      text: 'ÉTABLIS. CLIENT',
      render: (item: IJoinedInvoice) => getEstablishmentName(item.estCustomer),
    },
    {
      key: 'contractor',
      text: 'INTERVENANT',
      render: (item: IJoinedInvoice) =>
        //@ts-ignore
        getFullName(item?.contractor),
    },
    {
      key: 'totalBeforeTaxes',
      text: `Montant HT`,
      render: (item: IJoinedInvoice) =>
        `${item?.totalBeforeTaxes?.toFixed(2) || 'N/A'} €`,
    },
    {
      key: 'status',
      text: 'STATUT',
      render: (item: IJoinedInvoice) => {
        const status = item.status;
        const statusChangedAt = item?.statusChangedAt;
        let variantColor = 'grey';
        let text = '';
        switch (status) {
          case EInvoiceStatus.PROJECTED:
            variantColor = 'grey';
            text = 'Prévisionnelle';
            break;
          case EInvoiceStatus.TO_BE_FILLED:
            variantColor = 'grey';
            text = 'A saisir';
            break;
          case EInvoiceStatus.TO_BE_SUBMITTED:
            variantColor = 'grey';
            text = 'Non soumise';
            break;
          case EInvoiceStatus.TO_BE_VALIDATED:
            variantColor = 'warning';
            text = `Transmise ${
              statusChangedAt
                ? 'le : ' + statusChangedAt.toLocaleDateString()
                : ''
            }`;
            break;
          case EInvoiceStatus.VALIDATED:
            variantColor = 'success';
            text = `Validée  ${
              statusChangedAt
                ? 'le : ' + statusChangedAt.toLocaleDateString()
                : ''
            }`;
            break;
          case EInvoiceStatus.ARCHIVED:
            variantColor = 'grey';
            text = 'Archivée';
            break;
          case EInvoiceStatus.REJECTED:
            variantColor = 'error';
            text = `Refusée ${
              statusChangedAt
                ? 'le : ' + statusChangedAt.toLocaleDateString()
                : ''
            }`;
            break;
          case EInvoiceStatus.CANCELLED:
            variantColor = 'error';
            text = `Annulée ${
              statusChangedAt
                ? 'le : ' + statusChangedAt.toLocaleDateString()
                : ''
            }`;
            break;
          case EInvoiceStatus.PAID:
            variantColor = 'success';
            text = `Payée ${
              item?.sage?.paymentDetails?.paymentDate
                ? '- Virement émis le : ' +
                  item?.sage?.paymentDetails?.paymentDate?.toLocaleDateString()
                : ''
            }`;
            break;
          default:
            variantColor = 'grey';
        }

        return (
          <Flex>
            <Status
              //@ts-ignore
              variantColor={variantColor}
            >
              {text}
            </Status>
          </Flex>
        );
      },
    },
    {
      key: 'actions',
      text: 'ACTIONS',
      align: 'flex-end',
      render: (
        item: IJoinedInvoice & { additionalActivity?: IAdditionalActivity }
      ) => {
        const invoiceId = item?.uuid;
        const icon = <EyeIcon />;
        const invoiceFile = item?.invoiceFile;
        return (
          <Menu
            align="right"
            menuItems={
              <>
                <MenuItem>
                  <ALink
                    href={`/orders/invoices/provider/time-spent/${invoiceId}`}
                    params={{ invoicePathFrom: 'PROVIDER_ESTABLISHMENTS' }}
                  >
                    <Link iconLeft={icon}>Voir le formulaire</Link>
                  </ALink>
                </MenuItem>
                {invoiceFile && (
                  <MenuItem>
                    <Link
                      onClick={() =>
                        showDisplayPdfModal({
                          fileLocation: invoiceFile.fileLocation,
                          //@ts-ignore
                          fileName: invoiceFile.fileName,
                        })
                      }
                      iconLeft={icon}
                    >
                      Voir la facture
                    </Link>
                  </MenuItem>
                )}
              </>
            }
          >
            <DotsIcon fill={Theme?.colors?.blue} fontSize={20} />
          </Menu>
        );
      },
    },
  ];
  return (
    <Box maxWidth={'calc(1380px - 330px)'} overflowX="scroll">
      <Text variant="h2" mb={20}>
        Factures
      </Text>
      <Box width={1500} mb={50}>
        <InvoiceList
          filterComponents={
            <InvoicesFilersList hookStore={useInvoiceGlobalListStore} />
          }
          hookStore={useInvoiceGlobalListStore}
          columns={columns as any}
          filterObject={{
            estProvider: id,
            invoiceType: {
              $in: [
                ...INVOICE_PROVIDER_TYPES,
                EInvoiceType.PROVIDER_CREDIT_NOTE,
                EInvoiceType.PROVIDER_EXPENSE_CREDIT_NOTE,
                EInvoiceType.PROVIDER_STAND_BY_DUTY_CREDIT_NOTE,
              ],
            },
          }}
        />
      </Box>
    </Box>
  );
};
