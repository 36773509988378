import * as React from 'react';

function SvgLogoutIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
      <g fill="currentColor" fillRule="evenodd">
        <path d="M16 16l4-4-4-4zM10 13h6v-2h-6z" />
        <path d="M4 4v16h10.617v-2H6V6h8.617V4z" />
      </g>
    </svg>
  );
}

export default SvgLogoutIcon;
