import {
  EInvoiceStatus,
  EMissionStatus,
  IJoinedCraWithInvoice,
} from '@freelancelabs/teoreme-commons';
import { useState } from 'react';
import { getHumanDate, formatDate, FR_STATUS } from '@commons';
import {
  Container,
  Row,
  Box,
  Text,
  Link,
  LabelField,
  Divider,
} from 'components/ui';
import styled from 'styled-components';
import { Card } from '../ui/Card';
import { Theme } from 'styles';
import { EyeIcon, EyeSlashIcon } from 'components/ui/icons';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ContainerCard = styled(Card)`
  cursor: pointer;
  &:hover,
  &:focus {
    background-color: ${p => p.theme.colors.lightGray};
  }
`;
export type CardTypes = {
  cra: IJoinedCraWithInvoice;
  disabledEvidence?: true;
  displayInvoice?: boolean;
  displayGescom?: boolean;
  displayMission?: boolean;
  link?: string;
};
export const CardCra = ({
  cra,
  link,
  disabledEvidence,
  displayMission,
  displayGescom,
  displayInvoice,
  ...props
}: CardTypes) => {
  const [showMore, setShowMore] = useState(false);
  let bdlProvider = false;
  let bdlCustomer = false;
  if (cra?.invoices?.provider?.sage?.documentType) {
    bdlProvider = true;
  }
  if (cra?.invoices?.customer?.sage?.documentType) {
    bdlCustomer = true;
  }
  const invoiceCustomer = cra?.invoices?.customer;
  const invoiceProvider = cra?.invoices?.provider;
  return (
    <Container>
      <Box>
        {displayMission && showMore && (
          <Box>
            <Text variant="h3">Informations de la mission</Text>
            <Row p={10} spacing={20}>
              <LabelField
                value={cra?.mission?.displayReference}
                label="Référence mission"
                linkValue={`/delivery/missions/${cra?.mission?.reference}/gescom-create`}
                underline
              />
              <LabelField
                colorValue={
                  cra?.mission?.status === EMissionStatus?.VALIDATED
                    ? Theme?.colors?.success?.default
                    : cra?.mission?.status === EMissionStatus?.TERMINATED
                      ? Theme?.colors?.error?.default
                      : Theme?.colors?.darkBlue
                }
                value={FR_STATUS[cra?.mission?.status]}
                label="Statut"
                underline
              />
            </Row>
            <Row p={10} spacing={20}>
              <LabelField
                value={getHumanDate(cra?.mission?.startAt as Date)}
                label="Date de début"
                underline
              />
              <LabelField
                value={getHumanDate(cra?.mission?.endAt as Date)}
                label="Date de fin"
                underline
              />
            </Row>
            <Row p={10} spacing={20}>
              <LabelField
                value={cra?.mission?.billingInformation?.structure}
                label="Structure"
                underline
              />
              <LabelField
                value={cra?.mission?.title}
                label="Intitulé de la mission"
                underline
              />
            </Row>
            <Divider />
          </Box>
        )}
        {displayGescom && showMore && (
          <Box>
            <Text variant="h3">Informations GESCOM</Text>
            <Row p={10} spacing={20}>
              <LabelField
                colorValue={
                  bdlProvider
                    ? Theme?.colors?.success?.default
                    : Theme?.colors?.warning?.default
                }
                value={
                  bdlProvider
                    ? `${invoiceProvider?.sage?.invoiceId}`
                    : 'NON crée dans GESCOM'
                }
                label="Bon de livraison fournisseur"
                underline
              />
              <LabelField
                colorValue={
                  bdlCustomer
                    ? Theme?.colors?.success?.default
                    : Theme?.colors?.warning?.default
                }
                value={
                  bdlCustomer
                    ? `${invoiceCustomer?.sage?.invoiceId}`
                    : 'NON crée dans GESCOM'
                }
                label="Bon de livraison client"
                underline
              />
            </Row>
            <Divider />
          </Box>
        )}
        {displayInvoice && showMore && (
          <Box>
            <Text variant="h3">Informations Facture</Text>
            <Row p={10} spacing={20}>
              <LabelField
                colorValue={
                  //@ts-ignore
                  invoiceProvider?.status === EInvoiceStatus?.VALIDATED
                    ? Theme?.colors?.success?.default
                    : //@ts-ignore
                      invoiceProvider?.status ===
                          EInvoiceStatus?.TO_BE_VALIDATED ||
                        //@ts-ignore
                        invoiceProvider?.status === EInvoiceStatus?.PROJECTED
                      ? Theme?.colors?.warning?.default
                      : //@ts-ignore
                        invoiceProvider?.status === EInvoiceStatus?.REJECTED
                        ? Theme?.colors?.error?.default
                        : Theme?.colors?.darkBlue
                }
                //@ts-ignore
                value={FR_STATUS?.[cra?.invoices?.provider?.status] || 'N/A'}
                label="Statut facture fournisseur"
                underline
              />
              <LabelField
                colorValue={
                  //@ts-ignore
                  invoiceCustomer?.status === EInvoiceStatus?.VALIDATED
                    ? Theme?.colors?.success?.default
                    : //@ts-ignore
                      invoiceCustomer?.status ===
                          EInvoiceStatus?.TO_BE_VALIDATED ||
                        //@ts-ignore
                        invoiceCustomer?.status === EInvoiceStatus?.PROJECTED
                      ? Theme?.colors?.warning?.default
                      : //@ts-ignore
                        invoiceCustomer?.status === EInvoiceStatus?.REJECTED
                        ? Theme?.colors?.error?.default
                        : Theme?.colors?.darkBlue
                }
                //@ts-ignore
                value={FR_STATUS?.[invoiceCustomer?.status] || 'N/A'}
                label="Statut facture client"
                underline
              />
            </Row>
            <Divider />
          </Box>
        )}
        <Box>
          <Text variant="h3">Informations du CRA</Text>
          <Row p={10} spacing={20}>
            <LabelField
              linkValue={`/orders/cra/${cra?.refCra}/informations`}
              value={cra?.refCra}
              label="Référence du cra"
              underline
            />
            <></>
          </Row>
          <Box
            p={10}
            border={disabledEvidence ? 'none' : '1px solid red'}
            borderRadius={'8px'}
          >
            <Row spacing={20}>
              <LabelField
                value={formatDate(cra?.month as Date)?.substring(
                  (formatDate(cra?.month as Date)?.indexOf('/') || -1) + 1
                )}
                label="Mois concerné"
                boldValue
                underline
              />
              <LabelField
                value={cra?.nbWorkingDays}
                label="Nombre de jours"
                boldValue
                underline
              />
            </Row>
          </Box>
          <Row p={10} spacing={20}>
            <LabelField
              value={`${cra?.mission?.billing?.clientRate} €`}
              label="Tarif jour payé par le client"
              underline
            />
            <LabelField
              value={`${cra?.contractorRate} €`}
              label="Tarif jour perçu par l'intervenant"
              underline
            />
          </Row>
          <Row p={10} spacing={20}>
            <LabelField
              value={`${cra?.proof?.fileName || 'N/A'}`}
              linkValue={
                cra?.proof?.fileLocation
                  ? `/file/${cra?.proof?.fileLocation.replaceAll('/', '§')}`
                  : undefined
              }
              label="Justificatif"
              underline
            />
            <></>
          </Row>
        </Box>
        {(displayMission || displayGescom || displayInvoice) && (
          <Box mt={20} mb={20}>
            <Link
              onClick={() => setShowMore(!showMore)}
              iconLeft={showMore ? <EyeSlashIcon /> : <EyeIcon />}
            >
              {showMore
                ? "Voir moins d'informations"
                : "Voir plus d'informations"}
            </Link>
          </Box>
        )}
      </Box>
    </Container>
  );
};
