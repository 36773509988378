import {
  ETaskStatus,
  ETaskType,
  ITask,
  ETaskManageAction,
} from '@freelancelabs/teoreme-commons';
import { Header } from 'components/Header';
import {
  Box,
  Button,
  CheckBox,
  Container,
  CustomToolTip,
  Flex,
  FormLabel,
  Input,
  Itemize,
  Link,
  Menu,
  MenuItem,
  Spinner,
  SpinnerBox,
  Status,
  Tab,
  Table,
  TableColumn,
  Text,
} from 'components/ui';
import {
  queryClient,
  useAdminTaskFindMany,
  getHumanDate,
  cutLongText,
  useAdminTaskManage,
  getErrorMessage,
} from '@commons';
import {
  CancelIcon,
  DotsIcon,
  EyeIcon,
  RefreshIcon,
  CheckIcon,
} from 'components/ui/icons';
import { useSetCrumbs } from 'hooks/breadCrumb';
import { useDebounce } from 'hooks/useDebounce';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useListAdminTasksStore } from 'store/admin';
import {
  taskTypeMapping,
  isRetryableTask,
  isCancellableTask,
  isResolvableTask,
  checkDisplayTaskMenuList,
} from 'helpers/business';
import { Theme } from 'styles';
import { useShowMessage } from 'hooks/useShowMessage';
import { StaticSelect } from 'components/selects/StaticSelect';
import { showManageTaskModal } from 'components/modals/admin/ManageTaskModal';
import { showDialogModal } from 'components/modals/DialogModal';
type Item = Itemize<ITask>;

const tabItems = (
  <>
    <Tab variant="secondary" href={'/admin/tasks/all'}>
      Tous
    </Tab>
    <Tab variant="secondary" href={'/admin/tasks/pending'}>
      En pause
    </Tab>
    <Tab variant="secondary" href={'/admin/tasks/ongoing'}>
      En cours
    </Tab>
    <Tab variant="secondary" href={'/admin/tasks/error'}>
      En erreur
    </Tab>
    <Tab variant="secondary" href={'/admin/tasks/completed'}>
      Terminées
    </Tab>
  </>
);

export const TasksList = () => {
  const { filter: filterParams } = useParams<{
    filter: 'all' | 'ongoing' | 'pending' | 'error' | 'completed';
  }>();
  const {
    filter,
    order,
    sortedBy,
    searchQuery,
    typeSelected,
    page,
    selectedTask,
    updateListAdminTasksStore,
  } = useListAdminTasksStore();
  const debouncedFilterQuery = useDebounce(searchQuery, 500);
  const getFilterObject = () => {
    let status;
    if (filterParams === 'completed') {
      status = { $in: [ETaskStatus.COMPLETED, ETaskStatus.RESOLVED] };
    }
    if (filterParams === 'all') {
      status = undefined;
    }
    if (filterParams !== 'all' && filterParams !== 'completed') {
      status = filterParams.toUpperCase();
    }
    return {
      status: status,
      type: typeSelected,
      $text: debouncedFilterQuery
        ? { $search: debouncedFilterQuery }
        : undefined,
    };
  };
  const {
    data: tasks,
    status,
    isFetching,
  } = useAdminTaskFindMany({
    filterObject: getFilterObject(),
    limit: 20,
    skip: 20 * page,
    sort: '-createdAt',
  });
  const { mutateAsync: taskManage } = useAdminTaskManage();
  const showMessage = useShowMessage();
  useSetCrumbs(
    [filter],
    [
      {
        label: 'Admin - Tâches',
        path: `/admin/tasks/all`,
      },
      {
        label: `${filterParams}`,
        path: `/admin/tasks/${filterParams}`,
      },
    ]
  );

  //@ts-ignore
  const items: Item[] = tasks?.tasks.map(task => {
    return { key: `${task.uuid}_${new Date()?.toISOString()}`, ...task };
  });

  const history = useHistory();
  const onSortedChange = React.useCallback(
    (shortBy: string, order: 'asc' | 'desc'): void => {
      //@ts-ignore
      updateListAdminTasksStore({ order, sortedBy: shortBy });
    },
    []
  );

  const totalCount = tasks?.totalCount;

  const loading = status === 'pending';
  const onSelecteTask = (checked: boolean, task: any) => {
    const arrTask: any[] | [] = new Array(...selectedTask);
    const fIndex = selectedTask?.findIndex(t => t.uuid === task.uuid);
    if (fIndex !== -1) {
      arrTask.splice(fIndex, 1);
    } else {
      arrTask.push(task);
    }
    updateListAdminTasksStore({ selectedTask: arrTask });
    return;
  };
  const handleCancelTask = async (task?: ITask) => {
    showDialogModal({
      title: `Êtes-vous sûr de vouloir annuler la tâche ?`,
      text: `Avant d'annuler la tâche assurer vous de bien avoir vérifié les éléments !`,
      confirmLabel: 'Relancer',
      cancelLabel: 'Fermer',
      beforeValidation: async () => {
        try {
          const response = await taskManage({
            uuids: [task?.uuid],
            action: ETaskManageAction.CANCEL,
          });
          queryClient.refetchQueries({
            queryKey: ['admin-tasks-search'],
            type: 'active',
          });
          queryClient.refetchQueries({
            queryKey: [task?.uuid],
            type: 'active',
          });
          if (response?.hasError) {
            showMessage(
              'error',
              getErrorMessage(response?.errors?.[0]?.errorCode)
            );
          } else {
            showMessage('success', 'La tâche à été annulé');
          }
        } catch (e) {
          //
        }
      },
    });
  };
  const handleRetryTask = async (task?: ITask) => {
    showDialogModal({
      title: `Êtes-vous sûr de vouloir relancer la tâche ?`,
      text: `Avant de relancer la tâche assurer vous de bien avoir vérifié les éléments !`,
      confirmLabel: 'Relancer',
      cancelLabel: 'Fermer',
      beforeValidation: async () => {
        try {
          const response = await taskManage({
            uuids: [task?.uuid],
            action: ETaskManageAction.RETRY,
          });
          queryClient.refetchQueries({
            queryKey: ['admin-tasks-search'],
            type: 'active',
          });
          queryClient.refetchQueries({
            queryKey: [task?.uuid],
            type: 'active',
          });
          if (response?.hasError) {
            showMessage(
              'error',
              getErrorMessage(response?.errors?.[0]?.errorCode)
            );
          } else {
            showMessage('success', 'La tâche à été relancé');
          }
        } catch (e) {
          //
        }
      },
    });
  };
  const handleResolveTask = async (task?: ITask) => {
    showDialogModal({
      title: `Êtes-vous sûr de vouloir passer la tâche en résolu ?`,
      text: `Avant de résoudre la tâche assurer vous de bien avoir vérifié les éléments !`,
      confirmLabel: 'Résoudre',
      cancelLabel: 'Fermer',
      beforeValidation: async () => {
        try {
          const response = await taskManage({
            uuids: [task?.uuid],
            action: ETaskManageAction.RESOLVE,
          });
          queryClient.refetchQueries({
            queryKey: ['admin-tasks-search'],
            type: 'active',
          });
          queryClient.refetchQueries({
            queryKey: [task?.uuid],
            type: 'active',
          });
          if (response?.hasError) {
            showMessage(
              'error',
              getErrorMessage(response?.errors?.[0]?.errorCode)
            );
          } else {
            showMessage('success', 'La tâche à été résolu');
          }
        } catch (e) {
          //
        }
      },
    });
  };
  const CheckRow = ({
    item,
    selectedTask: sTask,
    onSelect,
  }: {
    item: any;
    selectedTask: [] | any[];
    onSelect: (checked: boolean, uuid: string) => void;
  }) => {
    return (
      <CheckBox
        key={`${item?.uuid}_checkBox`}
        id={`${item?.uuid}_checkBox`}
        onChange={e => onSelect(e?.target?.checked, item)}
        //onClick={() => onSelect(item)}
        checked={
          sTask?.find(s => s?.uuid === (item?.uuid as any)) ? true : false
        }
      >
        <></>
      </CheckBox>
    );
  };
  const handleCheckAllRow = (checked: boolean) => {
    if (!checked) {
      updateListAdminTasksStore({
        selectedTask: [],
      });
    } else {
      updateListAdminTasksStore({
        selectedTask: tasks?.tasks?.filter((t: any) =>
          isResolvableTask(t as any)
        ),
      });
    }
    return !checked;
  };
  const columns: TableColumn<Item>[] = [
    {
      key: 'select',
      text: () => (
        <CheckBox
          key={`select_ALL`}
          id={'select_ALL'}
          onChange={e => handleCheckAllRow(e?.target?.checked)}
          checked={selectedTask?.length > 0 ? true : false}
        >
          <></>
        </CheckBox>
      ),
      render: item => {
        if (isResolvableTask(item)) {
          return (
            <CheckRow
              item={item}
              selectedTask={selectedTask}
              onSelect={onSelecteTask}
            />
          );
        }
        return <></>;
      },
    },
    {
      key: 'type',
      text: 'TYPE',
      render: item => {
        return (
          <CustomToolTip id={item?.uuid} text={taskTypeMapping[item?.type]}>
            {cutLongText(taskTypeMapping[item?.type], 20)}
          </CustomToolTip>
        );
      },
    },
    {
      key: 'createdAt',
      text: 'Date de création',
      render: item => {
        return (
          <Text>
            {getHumanDate(item?.createdAt as Date, ' dd/MM/yyyy à HH:mm')}
          </Text>
        );
      },
    },
    {
      key: 'completedAt',
      text: 'Date de fin',
      render: item => {
        return (
          <Text>
            {item?.status === ETaskStatus.COMPLETED
              ? getHumanDate(item?.completedAt as Date, ' dd/MM/yyyy à HH:mm')
              : getHumanDate(item?.updatedAt as Date, ' dd/MM/yyyy à HH:mm')}
          </Text>
        );
      },
    },
    {
      key: 'status',
      text: 'STATUT',
      render: item => {
        const stepper = `${item?.currentStep} / ${item.totalSteps}`;
        if (item?.status === ETaskStatus.ONGOING) {
          return (
            <Status variantColor="warning">
              En cours d'éxécution ({stepper})
            </Status>
          );
        }
        if (item?.status === ETaskStatus.PENDING) {
          return <Status variantColor="grey">En pause ({stepper})</Status>;
        }
        if (item?.status === ETaskStatus.ERROR) {
          return <Status variantColor="error">En erreur ({stepper})</Status>;
        }
        if (item?.status === ETaskStatus.CANCELLED) {
          return <Status variantColor="error">Annulé ({stepper})</Status>;
        }
        if (item?.status === ETaskStatus.COMPLETED) {
          return <Status variantColor="success">Terminé ({stepper})</Status>;
        }
        if (item?.status === ETaskStatus.RESOLVED) {
          return <Status variantColor="primary">Résolu ({stepper})</Status>;
        }
      },
    },
    {
      key: 'step',
      text: 'ÉTAPE',
      render: item => {
        const currentStep = item?.currentStep;
        const step = item?.steps?.find(s => s.stepNumber === currentStep);
        const name = step?.name;
        if (item?.status === ETaskStatus.PENDING) {
          return <Status variantColor="grey">{name || 'N/A'}</Status>;
        }
        if (item?.status === ETaskStatus.ONGOING) {
          return <Status variantColor="warning">{name || 'N/A'}</Status>;
        }
        if (
          item?.status === ETaskStatus.ERROR ||
          item?.status === ETaskStatus.CANCELLED
        ) {
          return <Status variantColor="error">{name || 'N/A'}</Status>;
        }
        if (item?.status === ETaskStatus.COMPLETED) {
          return <Status variantColor="success">{name || 'N/A'}</Status>;
        }
        if (item?.status === ETaskStatus.RESOLVED) {
          return <Status variantColor="primary">{name || 'N/A'}</Status>;
        }
      },
    },
    {
      key: 'message',
      text: 'MESSAGE',
      render: item => {
        const message = item?.message ? cutLongText(item?.message, 20) : 'N/A';
        if (item?.status === ETaskStatus.PENDING) {
          return (
            <CustomToolTip
              color={Theme?.colors?.grey?.default}
              text={item?.message || 'N/A'}
              id={item?.uuid + 'title'}
            >
              <Status variantColor="grey">{message}</Status>
            </CustomToolTip>
          );
        }
        if (item?.status === ETaskStatus.ONGOING) {
          return (
            <CustomToolTip
              color={Theme?.colors?.warning?.default}
              text={item?.message || 'N/A'}
              id={item?.uuid + 'title'}
            >
              <Status variantColor="warning">{message}</Status>
            </CustomToolTip>
          );
        }
        if (
          item?.status === ETaskStatus.ERROR ||
          item?.status === ETaskStatus.CANCELLED
        ) {
          return (
            <CustomToolTip
              color={Theme?.colors?.error?.default}
              text={item?.message || 'N/A'}
              id={item?.uuid + 'title'}
            >
              <Status variantColor="error">{message}</Status>
            </CustomToolTip>
          );
        }
        if (item?.status === ETaskStatus.COMPLETED) {
          return (
            <CustomToolTip
              color={Theme?.colors?.success?.default}
              text={item?.message || 'N/A'}
              id={item?.uuid + 'title'}
            >
              <Status variantColor="success">{message}</Status>
            </CustomToolTip>
          );
        }
        if (item?.status === ETaskStatus.RESOLVED) {
          return (
            <CustomToolTip
              color={Theme?.colors?.success?.default}
              text={item?.message || 'N/A'}
              id={item?.uuid + 'title'}
            >
              <Status variantColor="primary">{message}</Status>
            </CustomToolTip>
          );
        }
      },
    },
    {
      key: 'action',
      text: 'ACTIONS',
      render: item => {
        return (
          <Flex alignItems="center">
            <Link
              onClick={() => history.push(`/admin/tasks/${item?.uuid}`)}
              iconLeft={<EyeIcon />}
            ></Link>
            {checkDisplayTaskMenuList(item) && (
              <Menu
                align="right"
                menuItems={
                  <>
                    {isCancellableTask(item) && (
                      <MenuItem onClick={() => handleCancelTask(item)}>
                        <Link iconLeft={<CancelIcon />}>Annuler</Link>
                      </MenuItem>
                    )}
                    {isResolvableTask(item) && (
                      <MenuItem onClick={() => handleResolveTask(item)}>
                        <Link iconLeft={<CheckIcon />}>Résoudre</Link>
                      </MenuItem>
                    )}
                    {isRetryableTask(item) && (
                      <MenuItem onClick={() => handleRetryTask(item)}>
                        <Link iconLeft={<RefreshIcon />}>Relancer</Link>
                      </MenuItem>
                    )}
                  </>
                }
              >
                <DotsIcon
                  fill={Theme?.colors?.primary?.default}
                  fontSize={20}
                />
              </Menu>
            )}
          </Flex>
        );
      },
    },
  ];

  return (
    <Box>
      <Header tabs={tabItems}>
        <Flex justifyContent="space-between">
          <Box width={3 / 12} mt={10}>
            <Text variant="h1">Tâches</Text>
          </Box>
          <Flex justifyContent="flex-end" width={9 / 12} mt={10}>
            <Button
              isDisabled={selectedTask?.length === 0}
              onClick={() =>
                showManageTaskModal({
                  tasks: selectedTask as ITask[],
                  action: ETaskManageAction.RESOLVE,
                })
              }
              mr={10}
            >
              Résoudres les tâches
            </Button>
            {/* <Button
              onClick={() =>
                showManageTaskModal({
                  tasks: tasks?.tasks as ITask[],
                  action: ETaskManageAction.RETRY,
                })
              }
            >
              Relancer des tâches
            </Button> */}
          </Flex>
        </Flex>
      </Header>
      <Container p="20px 0">
        <Flex
          alignItems="center"
          justifyContent="flex-start"
          ml={20}
          mr={20}
          mb={10}
        >
          <Box width={3 / 12} mr={10}>
            <FormLabel>RECHERCHER</FormLabel>
            <Input
              isFullWidth
              type="search"
              value={searchQuery}
              onChange={e => {
                //@ts-ignore
                updateListAdminTasksStore({ searchQuery: e?.target?.value });
              }}
            />
          </Box>

          <Box width={3 / 12} mr={10}>
            <FormLabel>Type de tâche</FormLabel>
            <StaticSelect
              options={[
                { value: ETaskType.CRA_PATCH, label: 'Correction de CRA' },
                { value: ETaskType.CRA_REJECT, label: 'Refus de CRA' },
                {
                  value: ETaskType.CRA_VALIDATION,
                  label: 'Validation de CRA',
                },
                {
                  value: ETaskType.ADDITIONAL_ACTIVITY_REJECT,
                  label: "Refus d'une préstation complémentaire/frais/jalons",
                },
                {
                  value: ETaskType.ADDITIONAL_ACTIVITY_VALIDATION,
                  label:
                    "Validation d'une préstation complémentaire/frais/jalons",
                },
                {
                  value: ETaskType.EXPORT_XLSX,
                  label: 'Export',
                },
                {
                  value: ETaskType.CREATE_MISSION_FROM_SF,
                  label: "Création d'une mission depuis SF",
                },
                {
                  value: ETaskType.CHECK_INVOICE_RIB,
                  label: "Vérification de l'IBAN",
                },
                {
                  value: ETaskType.HISTORY_RETENTION_CHECK,
                  label: "Vérification de la rétention de l'historique",
                },
              ]}
              onChange={value =>
                updateListAdminTasksStore({ typeSelected: value as string })
              }
              value={typeSelected as string}
              name="type"
              placeholder="Type de tâche"
            />
          </Box>
          <Box width={1 / 12} mr={10} mt={10}>
            <Link
              iconLeft={!isFetching ? <RefreshIcon /> : <Spinner size={20} />}
              onClick={() =>
                queryClient?.refetchQueries({
                  queryKey: ['admin-tasks-search'],
                  type: 'active',
                })
              }
            >
              Actualiser
            </Link>
          </Box>
        </Flex>

        {loading && <SpinnerBox />}
        {!loading && (
          <Table<Item>
            columns={columns}
            items={items}
            sortedBy={sortedBy}
            order={order}
            page={page}
            total={totalCount}
            perPage={totalCount && totalCount < 20 ? totalCount : 20}
            onSortedChange={onSortedChange}
            onChangePage={page => updateListAdminTasksStore({ page })}
            isLoading={isFetching}
          />
        )}
      </Container>
    </Box>
  );
};
