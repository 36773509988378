import * as React from 'react';

function InfoIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <g fill={props?.fill || '#00ABEF'}>
          <g>
            <path
              d="M7.99.002c-2.138.035-4.176.91-5.674 2.435C.802 3.944-.034 6.003.001 8.139c-.002 2.086.826 4.088 2.301 5.563C3.778 15.177 5.781 16.004 7.867 16h.143c4.452-.046 8.026-3.687 7.99-8.138.005-2.113-.843-4.138-2.35-5.617C12.142.765 10.1-.043 7.99.002zM7 12.02c-.009-.264.088-.52.27-.713.181-.192.432-.302.696-.307h.018c.547.001.995.434 1.015.98.01.264-.087.521-.268.713-.182.192-.433.303-.697.307h-.018c-.547-.002-.994-.434-1.015-.98zm0-3.645v-3.75C7 4.28 7.448 4 8 4s1 .28 1 .625v3.75C9 8.72 8.552 9 8 9s-1-.28-1-.625z"
              transform="translate(-882 -708) rotate(-180 449 362)"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default InfoIcon;
