import {
  EInvoiceStatus,
  EadditionalActivityStatus,
  IJoinedAdditionalActivityWithInvoice,
} from '@freelancelabs/teoreme-commons';

export const canDisplayShowCorrectRejectButton = (
  additionalActivity: IJoinedAdditionalActivityWithInvoice
) => {
  return additionalActivity.status === EadditionalActivityStatus.VALIDATED;
};

export const canDisplayCreateBdlButton = (
  additionalActivity: IJoinedAdditionalActivityWithInvoice,
  bdlProvider: any
) => {
  return (
    additionalActivity.status === EadditionalActivityStatus.VALIDATED &&
    !bdlProvider
  );
};

export const canDisplayGenerateCustomerActivityInvoiceButton = (
  additionalActivity: IJoinedAdditionalActivityWithInvoice
) => {
  /*
    - Check CRA status
    - Only CRAs with existing BL can generate the customer invoice
    - Check  customer invoice  status
    - TODO CHECK IF VALIDATED BY SAGE !
    */

  return (
    additionalActivity?.status === EadditionalActivityStatus?.VALIDATED &&
    additionalActivity?.invoices?.customer?.status ===
      EInvoiceStatus?.PROJECTED &&
    additionalActivity?.invoices?.provider?.sage?.invoiceId &&
    !additionalActivity?.invoices?.customer?.sage?.invoiceId
  );
};

export const checkDisplayAdditionalActivityMenuList = (
  additionalActivity: IJoinedAdditionalActivityWithInvoice,
  bdlProvider: any
) => {
  return (
    canDisplayShowCorrectRejectButton(additionalActivity) ||
    canDisplayCreateBdlButton(additionalActivity, bdlProvider) ||
    canDisplayGenerateCustomerActivityInvoiceButton(additionalActivity)
  );
};
