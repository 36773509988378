import {
  EContractorSocialStatus,
  IJoinedContractor,
} from '@freelancelabs/teoreme-commons';
import {
  Box,
  Button,
  Flex,
  Text,
  FormControl,
  StaticSelectControlled,
  UserSelectControlled,
  EstablishmentSelectControlled,
} from 'components/ui';
import React from 'react';
import { create } from 'react-modal-promise';
import { ModalFrame, ModalProps } from '../ModalFrame';
import { useForm } from 'react-hook-form';
import { contractorUpdateOne, CONTRACTOR_SOCIAL_STATUS_SELECT } from '@commons';
import { queryClient } from '@commons';
type Props = ModalProps & {
  beforeValidation?: () => void;
  contractor: IJoinedContractor;
};
type FormValues = {
  establishment: string;
  contact: string;
  socialStatus: EContractorSocialStatus;
};
export const LinkEstablishmentModal = ({
  onResolve,
  isOpen,
  contractor,
}: Props) => {
  const {
    handleSubmit,
    control,
    formState: { errors, isDirty },
  } = useForm<FormValues>({
    defaultValues: {},
  });

  const [loading, setLoading] = React.useState(false);

  const onSubmit = async (formValues: FormValues) => {
    setLoading(true);

    try {
      await contractorUpdateOne({
        uuid: contractor?.uuid,
        scopedData: [
          {
            disabled: false,
            establishment: formValues.establishment,
            contact: formValues.contact,
            socialStatus: formValues?.socialStatus,
          },
        ],
        contractor: {},
      });
      queryClient.refetchQueries({ queryKey: ['contractors'], type: 'active' });
      queryClient.refetchQueries({ queryKey: [contractor?.uuid] });
      onResolve(true);
    } catch (err) {
      onResolve(false);
    }
    setLoading(false);
  };

  return (
    <ModalFrame
      isOpen={isOpen}
      onClose={() => onResolve(false)}
      width={550}
      closeIcon
    >
      <Box mt={20}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Text variant="h2" mb={20}>
            Rattacher un établissement fournisseur
          </Text>
          <Box>
            <FormControl
              label={'Établissement fournisseur'}
              required
              errorMessage={errors?.establishment?.message}
            >
              <EstablishmentSelectControlled
                id="contractor-provid-select-establishment"
                control={control}
                name="establishment"
                filter={{
                  'provider.manager': { $exists: true },
                  uuid:
                    contractor?.scopedData?.length > 0
                      ? {
                          $nin: contractor?.scopedData?.map(
                            sd => sd?.establishment?.uuid
                          ),
                        }
                      : undefined,
                }}
                width="100%"
                referenceValue="uuid"
                placeholder="Rechercher un N° SIRET, raison sociale ..."
                rules={{ required: 'Ce champs est requis' }}
              />
            </FormControl>
            <FormControl
              label={'Contact fournisseur'}
              required
              errorMessage={errors?.contact?.message}
            >
              <UserSelectControlled
                id="contractor-provid-select-contact"
                control={control}
                name="contact"
                role={'PROVIDER_CONTACT'}
                width="100%"
                placeholder="Rechercher un nom, prénom, email …"
                rules={{ required: 'Ce champs est requis' }}
              />
            </FormControl>
            <FormControl
              required
              label={"Statut social pour l'établissement renseigné"}
              errorMessage={errors?.socialStatus?.message}
            >
              <StaticSelectControlled
                control={control}
                rules={{ required: 'Ce champs est requis' }}
                name="socialStatus"
                options={CONTRACTOR_SOCIAL_STATUS_SELECT}
              />
            </FormControl>
          </Box>
          <Flex>
            <Button type="submit" isLoading={loading} isDisabled={!isDirty}>
              Enregistrer
            </Button>
          </Flex>
        </form>
      </Box>
    </ModalFrame>
  );
};

export const showLinkEstablishmentModal = create<Props, boolean>(
  LinkEstablishmentModal
);
