import { getWorkingDays, missionUpdateOne, removeOffsetDate } from '@commons';
import {
  EBillingType,
  EMissionStatus,
  EMissionStructure,
  IAdditionalService,
  IAddress,
  IBilling,
  IBillingInformation,
} from '@freelancelabs/teoreme-commons';
import { showDialogModal } from 'components/modals/DialogModal';
import { ConfirmNavigationModal } from 'components/modals/ConfirmNavigationModal';
import { Section } from 'components/Section';
import { Button, Form } from 'components/ui';
import { MissionGescomPartialForm } from 'forms/partials/MissionGescomPartialForm';
import { useSetCrumbs } from 'hooks/breadCrumb';
import { useCreateOrUpdateMission, useGetMission } from 'hooks/missionHooks';
import { useShowMessage } from 'hooks/useShowMessage';
import React from 'react';
import { useForm } from 'react-hook-form';
import { queryClient } from '@commons';

type FormValues = {
  isMandateMode?: boolean;
  shouldCreatePastCra: boolean;
  startAt: Date;
  endAt: Date;
  title: string;
  location?: IAddress;
  description?: string;
  billing: IBilling;
  billingInformation?: IBillingInformation;
  estimatedEndAt?: Date;
  accountManager?: any;
  //additionalServices?: IAdditionalService[];
};
type GescomMissionProps = {
  isDisabled: boolean;
};
export const GescomMission = ({ isDisabled }: GescomMissionProps) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const { mission, reference } = useGetMission();
  const missionStatus = mission?.status;
  const [ignoreNav, setIgnoreNav] = React.useState(false);
  const form = useForm<FormValues>({
    defaultValues: {
      billing: { type: EBillingType.DAY },
      ...mission,
      shouldCreatePastCra: mission?.shouldCreatePastCra,
    },
  });
  const {
    handleSubmit,
    formState: { isDirty, isSubmitSuccessful },
    setError,
    clearErrors,
    reset,
    watch,
  } = form;
  const [createOrUpdate] = useCreateOrUpdateMission();
  const showMessage = useShowMessage();
  const onSubmit = async (data: FormValues) => {
    setIsLoading(true);
    if (!data.description || data.description.length === 0) {
      setError('description', { message: 'Ce champs est requis' });
    } else {
      clearErrors('description');
      setIgnoreNav(true);
      delete data.billingInformation;
      //@ts-ignore
      delete data.additionalServices;
      //@ts-ignore
      delete data.endAt;
      //@ts-ignore
      delete data.title;
      //@ts-ignore
      delete data.startAt;
      try {
        const response = await createOrUpdate(reference, {
          // ...data,
          //@ts-ignore
          //accountManager: data?.accountManager,
          isMandateMode: data?.isMandateMode,
          startAt: data?.startAt,
          endAt: data?.endAt,
          title: data?.title,
          shouldCreatePastCra: data?.shouldCreatePastCra,
          description: data?.description,
          billing: {
            ...mission?.billing,
            ...data.billing,
          },
          location: data?.location,
        });
        showMessage('success', 'Vos modifications ont bien été enregistrées.');
        reset(response);
      } catch (e) {
        /*showMessage(
            'error',
            'Vos modifications n’ont pas pu être prises en compte dans GESCOM et n’ont pas été enregistrées dans votre fiche mission. Merci de réessayer'
          );*/
      }
    }
    setIsLoading(false);
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onUpdateServices = async (service: IAdditionalService | false) => {
    if (service && mission) {
      const submitData = {
        reference: mission.reference,
        mission: {
          additionalServices: {
            update: [],
            add: [],
            cancel: [],
          },
        },
      };

      if (service?.uuid) {
        submitData?.mission?.additionalServices.update.push(service as never);
      } else {
        submitData?.mission?.additionalServices.add.push(service as never);
      }
      try {
        await missionUpdateOne(submitData);
        queryClient.refetchQueries({ queryKey: [mission.reference] });
      } catch (e) {
        //
      }
      // createOrUpdate(reference, {
      //   additionalServices: services,
      // });
    }
  };

  useSetCrumbs(
    [],
    [
      { label: 'prestations', path: '/delivery' },
      { label: 'missions', path: '/delivery/missions' },
      {
        label: mission?.displayReference || 'nouvelle mission',
        path: `/delivery/missions/${reference}`,
      },
      {
        label: 'informations',
        path: `/delivery/missions/${reference}/information`,
      },
    ]
  );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [estimatedDays, setEstimatedDays] = React.useState(0);
  const shouldCreatePastCra = watch('shouldCreatePastCra');
  const title = watch('title');
  //@ts-ignore
  const accountManager = watch('accountManager');
  const structure = watch('billingInformation.structure');
  // const missionType = watch('billingInformation.missionType');
  const startAt = watch('startAt');
  const endAt = watch('endAt');
  const label = watch('billingInformation.missionType.label');
  const providedBy = watch('billingInformation.missionType.providedBy');
  const contact = watch('billingInformation.missionType.contact');
  //@ts-ignore
  const internalCommercial = watch('internalCommercial');
  const estimatedEndAt = watch('estimatedEndAt');
  const isMandateMode = watch('isMandateMode');
  const billingType = watch('billing.type');
  const onUpdateMission = async () => {
    let onError = false;
    setIgnoreNav(true);
    // const providedBy = mission?.billingInformation?.missionType?.providedBy;
    // const contact = mission?.billingInformation?.missionType?.contact;
    // const label = mission?.billingInformation?.missionType?.label;
    const billingInformation = {
      structure: mission?.billingInformation?.structure,
      missionType: {
        label: label,
        contact: contact,
        providedBy: providedBy && providedBy !== null ? providedBy : undefined,
      },
    };

    const missionTypeContact = billingInformation?.missionType?.contact;

    if (missionTypeContact && missionTypeContact !== null) {
      //@ts-ignore
      if (missionTypeContact?.cognitoUserId) {
        billingInformation.missionType.contact =
          //@ts-ignore
          missionTypeContact.cognitoUserId;
      }
    }

    if (!accountManager) {
      setError('accountManager', { message: 'Ce champs est requis' });
      onError = true;
    } else {
      clearErrors('accountManager');
    }
    if (setIgnoreNav) {
      setIgnoreNav(true);
    }
    setIsLoading(true);
    if (title.length > 35) {
      onError = true;
      setError('title', { message: 'Veuillez saisir moins de 36 caractères' });
    } else {
      clearErrors('title');
    }
    if (onError === false) {
      try {
        const response = await createOrUpdate(reference, {
          isMandateMode: isMandateMode,
          accountManager:
            typeof accountManager === 'object'
              ? accountManager?.cognitoUserId !==
                mission?.accountManager?.cognitoUserId
                ? accountManager
                : undefined
              : accountManager !== mission?.accountManager?.cognitoUserId
                ? accountManager
                : undefined,
          internalCommercial:
            typeof internalCommercial === 'object'
              ? internalCommercial?.cognitoUserId !==
                mission?.internalCommercial?.cognitoUserId
                ? internalCommercial?.cognitoUserId
                : undefined
              : internalCommercial !==
                  mission?.internalCommercial?.cognitoUserId
                ? internalCommercial
                : undefined,
          title,
          endAt: removeOffsetDate(endAt as Date),
          startAt: removeOffsetDate(startAt as Date),
          shouldCreatePastCra: shouldCreatePastCra,
          estimatedEndAt: estimatedEndAt,
          billing: { type: billingType },
          billingInformation: {
            ...billingInformation,
            structure:
              structure === EMissionStructure.FCOM ||
              structure === EMissionStructure.FONE ||
              structure === EMissionStructure.COWORKEES ||
              structure === EMissionStructure.INOPS
                ? structure
                : undefined,
          },
        });
        showMessage(
          'success',
          'Vos modifications ont bien été enregistrées et prises en compte dans GESCOM.'
        );
        if (
          (mission?.status === EMissionStatus.DRAFT &&
            mission?.startAt &&
            new Date(startAt).getTime() !==
              new Date(mission?.startAt).getTime()) ||
          (mission?.endAt &&
            new Date(mission?.endAt).getTime() !== new Date(endAt).getTime())
        ) {
          await showDialogModal({
            title: 'Vous venez de modifier les dates de la mission.',
            text: 'Vous venez de modifier les dates de la mission , vous devez dès maintenant remplacer le devis client , le bon de commande client et le bon de commande fournisseur avant de valider la mission.',
            confirmLabel: 'Continuer',
            checkboxConfirmation: [
              'J’ai bien pris connaissance des modifications à réaliser suite aux modifications des dates.',
            ],
          });
        }
        reset(response);
      } catch (e) {
        showMessage(
          'error',
          'Vos modifications n’ont pas pu être prises en compte dans GESCOM et n’ont pas été enregistrées dans votre fiche mission. Merci de réessayer'
        );
      }
    }

    setIsLoading(false);
  };
  React.useEffect(() => {
    if (startAt && endAt) {
      setEstimatedDays(getWorkingDays(startAt, endAt));
    }
  }, [startAt, endAt, setEstimatedDays]);
  const isDisableGescomButton = () => {
    if (isDisabled) {
      return true;
    }
    if (title && endAt && startAt && structure && label) {
      return false;
    }
    return true;
  };

  return (
    <>
      <Form
        onSubmit={handleSubmit(onSubmit)}
        mb={20}
        autoComplete="off"
        data-cy="mission-form"
      >
        <ConfirmNavigationModal
          active={isDirty && ignoreNav === false && !isSubmitSuccessful}
        />
        <Section variant="h2" title="Informations de la mission">
          <MissionGescomPartialForm
            mission={mission}
            missionStatus={missionStatus}
            sage={mission?.sage}
            form={form}
            forRole="ALL"
            reference={mission?.reference}
            setIgnoreNav={() => setIgnoreNav(true)}
            isDisabled={isDisabled}
            // isDisabled={true}
          />
        </Section>
        <Button
          variant="filled"
          type="button"
          onClick={onUpdateMission}
          isDisabled={isDisableGescomButton()}
          isLoading={isLoading}
          data-cy="save-gescom"
        >
          Enregistrer et mettre à jour dans GESCOM
        </Button>
      </Form>
    </>
  );
};
