import {
  ISignOfficer,
  ESignOfficerType,
  IEstablishment,
  IEstablishmentFullUsers,
  IEstablishmentMinifiedUsers,
} from '@freelancelabs/teoreme-commons';
import React from 'react';
import { Box, Row, Text, FormLabel, Link } from 'components/ui';
import { showDisplayPdfModal } from 'components/modals/DisplayPdfModal';
import { transformText } from '@commons';

export const SignOfficerCard = ({
  establishment,
  signOfficer,
  signOfficerType,
  delegatedSignOfficer,
  ...props
}: {
  signOfficer: ISignOfficer;
  signOfficerType: ESignOfficerType;
  delegatedSignOfficer?: ISignOfficer;
  establishment?:
    | IEstablishment
    | IEstablishmentFullUsers
    | IEstablishmentMinifiedUsers;
}) => {
  const delegationOrder = delegatedSignOfficer?.delegationOrder;
  return (
    <Box mb={20}>
      <Row spacing={20}>
        <Box>
          <FormLabel>Qui signera le contrat cadre ?</FormLabel>
          <Text mb={20} variant="p">
            {signOfficerType === 'DELEGATED_OFFICER'
              ? "Un tiers muni d'une délégation de signature"
              : 'Le mandataire social'}
          </Text>
        </Box>
        <></>
      </Row>
      {signOfficerType === 'DELEGATED_OFFICER' && (
        <>
          <Row spacing={20}>
            <Box alignContent="right" alignItems="top">
              <FormLabel>Personne habilité à signer</FormLabel>
              <Text variant="p">{`${
                delegatedSignOfficer?.civility === 1 ? 'M' : 'Mme'
              } ${transformText(
                delegatedSignOfficer?.firstName,
                'capitalize'
              )} ${transformText(
                delegatedSignOfficer?.lastName,
                'uppercase'
              )}`}</Text>
              <Text variant="p">{`${
                delegatedSignOfficer?.jobTitle || ''
              }`}</Text>
              <Text variant="p">{`Tel : ${
                delegatedSignOfficer?.phone || ''
              }`}</Text>
              <Text variant="p">{`Email : ${
                delegatedSignOfficer?.email || ''
              }`}</Text>
            </Box>
            <Box>
              <FormLabel>Mandataire social</FormLabel>
              <Text variant="p">{`${
                signOfficer?.civility === 1 ? 'M' : 'Mme'
              } ${transformText(
                signOfficer?.firstName,
                'capitalize'
              )} ${transformText(signOfficer?.lastName, 'uppercase')}`}</Text>
              <Text variant="p">{`${signOfficer?.jobTitle || ''}`}</Text>
              <Text variant="p">{`Tel : ${signOfficer?.phone || ''}`}</Text>
              <Text variant="p">{`Email : ${signOfficer?.email || ''}`}</Text>
            </Box>
          </Row>
          <Row spacing={20}>
            <Box mt={20}>
              <FormLabel>Délégation de signature</FormLabel>
              <Text variant="p">
                <Link
                  mr={10}
                  onClick={() =>
                    showDisplayPdfModal({
                      fileLocation: delegationOrder?.fileLocation || '',
                      fileName: `${delegationOrder?.fileName}.pdf`,
                      layer: 200,
                    })
                  }
                >
                  {`${delegationOrder?.fileName}`}
                </Link>
              </Text>
            </Box>
            <Box></Box>
          </Row>
        </>
      )}
      {signOfficerType !== 'DELEGATED_OFFICER' && (
        <Row spacing={20}>
          <Box>
            <FormLabel>Mandataire social</FormLabel>
            <Text variant="p">{`${
              signOfficer?.civility === 1 ? 'M' : 'Mme'
            } ${transformText(
              signOfficer?.firstName,
              'capitalize'
            )} ${transformText(signOfficer?.lastName, 'uppercase')}`}</Text>
            <Text variant="p">{`${signOfficer?.jobTitle || ''}`}</Text>
            <Text variant="p">{`Tel : ${signOfficer?.phone || ''}`}</Text>
            <Text variant="p">{`Email : ${signOfficer?.email || ''}`}</Text>
          </Box>
          <></>
        </Row>
      )}
    </Box>
  );
};
