import { IEstablishmentMinifiedUsers } from '@freelancelabs/teoreme-commons';
import { create } from 'react-modal-promise';
import { ModalFrame, ModalProps } from '../ModalFrame';
import { AddCustomerEstablishmentAdressForm } from 'forms/AddCustomerEstablishmentAdress';

type Props = ModalProps & {
  beforeValidation?: () => void;
  establishment: IEstablishmentMinifiedUsers;
  layer?: number;
  defaultAdress?: any;
};

export const AddEstablishmentAdressModal = ({
  onResolve,
  isOpen,
  establishment,
  defaultAdress,
  layer,
}: Props) => {
  return (
    <ModalFrame
      isOpen={isOpen}
      onClose={() => onResolve(false)}
      width={850}
      closeIcon
      layer={layer}
    >
      <AddCustomerEstablishmentAdressForm
        defaultValues={defaultAdress}
        establishment={establishment}
        onSubmit={() => false}
        onClose={(action?: boolean | undefined) => onResolve(action || false)}
      />
    </ModalFrame>
  );
};

export const showAddEstablishmentAdressModal = create<Props>(
  AddEstablishmentAdressModal
);
