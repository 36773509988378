import React, { ReactNode } from 'react';
import { Box, BoxProps, Text } from './ui';

export const Section = ({
  variant = 'h2',
  children,
  title,
  hidden,
  ...props
}: {
  variant?: 'h1' | 'h2' | 'h3';
  children?: ReactNode;
  title?: string | ReactNode;
  hidden?: boolean;
} & BoxProps) => {
  return (
    <Box hidden={hidden} mb={40} {...props}>
      {title ? (
        <Text
          mb={variant === 'h1' ? 40 : variant === 'h2' ? 20 : 10}
          variant={variant}
        >
          {title}
        </Text>
      ) : null}
      {children}
    </Box>
  );
};
