import React from 'react';
import {
  Text,
  Separator,
  Box,
  Link,
  Spinner,
  Itemize,
  Tag,
} from 'components/ui';
import { EyeIcon, TaskIcon, StarIcon } from 'components/ui/icons';
import { useNotificationFindMany, getCivilityAllKeys } from '@commons';
import {
  INotification,
  IJoinedNotificationUser,
} from '@freelancelabs/teoreme-commons';
import { useTranslationFormat } from 'hooks/useTranslateFormat';
import { ALink } from 'components/ALink';
import { NOTIFICATION_TYPES } from '@commons';
import { Theme } from 'styles';
import { formatDateAndTime } from '@commons';

type Item = Itemize<IJoinedNotificationUser>;

export const LastNotifications = () => {
  const t = useTranslationFormat();
  const { data: notificationsQuery, status } = useNotificationFindMany({
    filterObject: {
      'notificationUser.state.read': false,
    },
    sort: '-createdAt',
    limit: 5,
    skip: 0,
  });
  const loading = status === 'pending';
  // const totalCount = notificationsQuery?.totalCount;
  const items: Item[] =
    //@ts-ignore
    notificationsQuery?.results.map((notification: INotification) => ({
      ...notification,
      key: notification.uuid || '',
    })) || [];

  return (
    <>
      <Box
        display={'inline-flex'}
        width={'100%'}
        justifyContent={'space-between'}
      >
        <Text variant="h3">Mes dernières notifications</Text>
        <ALink href={'/notifications/all'}>
          <Link iconLeft={<EyeIcon />} mt={1}>
            Voir tout
          </Link>
        </ALink>
      </Box>
      <Separator />
      {!loading && items.length === 0 && (
        <Box alignContent="center" alignItems="center">
          <TaskIcon style={{ margin: '0 auto' }} />
          <Text variant="h3" color={'primary.light'} textAlign="center">
            Aucune notification
          </Text>
          <Text color={'primary.light'} textAlign="center">
            Dès que vous recevrez une nouvelle notification, elle sera visible
            ici.
          </Text>
        </Box>
      )}
      {loading && <Spinner />}
      {items.map(item => (
        <Box
          key={item.uuid}
          opacity={
            item.notificationUser?.state.read || item.defaultState?.read
              ? 0.5
              : 1
          }
        >
          <Box display="flex" mb={2}>
            <StarIcon
              fill={
                item.notificationUser?.state.priority ||
                item.defaultState?.priority
                  ? Theme.colors.primary.default
                  : Theme.colors.lightBlue
              }
            />
            <Tag
              marginLeft={2}
              variantColor={
                item.type === 'ALERT'
                  ? 'warning'
                  : item.type === 'ACTION'
                    ? 'error'
                    : 'success'
              }
            >
              {NOTIFICATION_TYPES[item.type]}
            </Tag>
            <Text marginLeft={2} fontWeight="bold" maxWidth={'75%'}>
              {
                t(`notifications:${item.reference}`, false, false, {
                  ...getCivilityAllKeys(item.parameters, item?.reference),
                }).split('--')[0]
              }
            </Text>
            <ALink
              style={{ marginLeft: 'auto' }}
              href={t(`links:${item.reference}`, false, false, item.parameters)}
            >
              <Link iconLeft={<EyeIcon />}>Voir</Link>
            </ALink>
          </Box>
          <Box pl={4}>
            <Text>
              {t(`notifications:${item.reference}`, false, false, {
                ...getCivilityAllKeys(item.parameters, item?.reference),
              })
                .split('--')[1]
                ?.replaceAll('&amp;', '&')}
            </Text>
          </Box>
          {item.createdAt && (
            <Box mt={3} pl={4}>
              <Text color={Theme.colors.lightBlue}>
                {formatDateAndTime(item.createdAt || '')}
              </Text>
            </Box>
          )}
          <Separator />
        </Box>
      ))}
    </>
  );
};
export default LastNotifications;
