import { useState } from 'react';
import {
  EBillingType,
  IEstablishmentMinifiedUsers,
  IEstablishmentFullUsers,
  IContractor,
} from '@freelancelabs/teoreme-commons';
import {
  Box,
  Text,
  Link,
  Row,
  FormControl,
  Button,
  FormLabel,
  Radio,
  NumberFormInputControlled,
} from 'components/ui';
import { create } from 'react-modal-promise';
import { sageCreateContractor } from '@commons';
import { queryClient } from '@commons';
import { ModalFrame, ModalProps } from './ModalFrame';
import { CancelIcon, EuroIcon } from 'components/ui/icons';
import { useShowMessage } from 'hooks/useShowMessage';
import { useForm } from 'react-hook-form';

type Props = ModalProps & {
  beforeValidation?: () => void;
  contractor: IContractor;
};
type FormValues = any;

export const AddContractorGescomModal = ({
  onResolve,
  isOpen,
  contractor,
}: Props) => {
  const [loading, setLoading] = useState(false);
  const showMessage = useShowMessage();
  const {
    control,
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {
      billing: {
        type: EBillingType.DAY,
        dailyRate: 0,
      },
    },
  });

  const onSubmit = async (formValues: FormValues) => {
    setLoading(true);
    try {
      await sageCreateContractor({
        dailyRate:
          billingType === EBillingType.DAY
            ? formValues.billing.contractorRate
            : 0,
        contractor: contractor.uuid,
      });
      queryClient.refetchQueries({ queryKey: [contractor.uuid] });
      showMessage('success', 'Intervenant crée dans Gescom');
      onResolve(true);
    } catch (e) {
      //
    }
    setLoading(false);
  };
  const billingType = watch('billing.type');
  return (
    <ModalFrame
      isOpen={isOpen}
      onClose={() => onResolve(false)}
      width={550}
      closeIcon
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box>
          <Text variant="h1" mb={20}>
            Créer dans GESCOM
          </Text>
          <Text variant="p" mb={20}>
            Afin de valider la création de cet intervenant dans GESCOM, merci de
            vous assurer qu'au moins un de ses établissements fournisseurs est
            créé dans GESCOM et d’indiquer sa tarification.
          </Text>
          <Box mb={20}>
            <FormLabel mb={20}>Type de tarification</FormLabel>
            <Radio
              {...register('billing.type', { required: 'Ce champ est requis' })}
              value={EBillingType.DAY}
            >
              Temps passé
            </Radio>
            <Radio
              {...register('billing.type', { required: 'Ce champ est requis' })}
              value={EBillingType.FLAT_RATE}
            >
              Forfait
            </Radio>
          </Box>
          {billingType === EBillingType.DAY && (
            <Row spacing={20}>
              <FormControl
                required
                label="Tarif jour payé intervenant (HT)"
                // @ts-ignore
                errorMessage={
                  errors?.billing?.contractorRate?.type === 'min'
                    ? 'Supérieur à 0'
                    : // @ts-ignore
                      errors?.billing?.contractorRate?.message
                }
              >
                <NumberFormInputControlled
                  control={control}
                  isFullWidth
                  maxDecimal={2}
                  step="0.01"
                  name="billing.contractorRate"
                  rules={{ required: 'Ce champ est requis', min: 1 }}
                  icon={<EuroIcon />}
                />
              </FormControl>
              <></>
            </Row>
          )}
        </Box>
        <Box>
          <Row spacing={20} width={'300px'}>
            <Button key="submit" type="submit" isLoading={loading}>
              Créer
            </Button>
            <Link
              iconLeft={<CancelIcon />}
              mt={'20px'}
              onClick={() => onResolve(false)}
            >
              Annuler
            </Link>
          </Row>
        </Box>
      </form>
    </ModalFrame>
  );
};

export const showAddContractorGescomModal = create<
  Props,
  IEstablishmentMinifiedUsers | IEstablishmentFullUsers
>(AddContractorGescomModal);
