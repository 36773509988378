import * as React from 'react';

function SvgDocumentIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      data-name="Calque 1"
      viewBox="0 0 41.13 50.5"
      width="1em"
      height="1em"
      {...props}
    >
      <title>{'doc'}</title>
      <path d="M41.12 13.31v-.21a1.3 1.3 0 00-.32-.54L29.15.39a1.33 1.33 0 00-.42-.3h-.14l-.06.24V.08H5.02a5.14 5.14 0 00-5 5.25v40a5.15 5.15 0 005 5.25H36.1a5.15 5.15 0 005-5.25V13.31zm-11.64-9l7.55 7.92h-7.55zm-27.07 1a2.68 2.68 0 012.61-2.75h22.05v11a1.23 1.23 0 001.2 1.25h10.48v30.44A2.68 2.68 0 0136.14 48H5.02a2.68 2.68 0 01-2.61-2.75z" />
    </svg>
  );
}

export default SvgDocumentIcon;
