import {
  IEstablishment,
  ICompanyGetResponse,
} from '@freelancelabs/teoreme-commons';
import { useEstablishmentCreateOne } from '@commons'; // moved
import { establishmentLookupIdentifier } from '@commons';
import { EstablishmentExistMessage } from 'components/EstablishmentExistMessage';
import { Box, FormControl } from 'components/ui';
import { AddEstablishmentForm } from 'forms/AddEstablishmentForm';
import { CreateManualEstablishmentForm } from 'forms/CreateManualEstablishmentForm';
import { VerifySiretForm } from 'forms/VerifySiretForm';
import React, { ReactNode, useState } from 'react';
import { create } from 'react-modal-promise';
import { queryClient } from '@commons';
import { ModalFrame, ModalProps } from './ModalFrame';
import { AddForeignEstablishmentForm } from 'forms/AddForeignEstablishmentForm';

type Props = ModalProps & {
  beforeValidation?: () => void;
  establishmentRole?: 'PROVIDER' | 'CUSTOMER';
};

export const AddEstablishmentModal = ({
  onResolve,
  isOpen,
  establishmentRole,
}: Props) => {
  const [establishment, setEstablishment] = useState<
    Partial<IEstablishment> | undefined
  >(undefined);
  const [previewError, setPreviewError] = useState<ReactNode | undefined>(
    undefined
  );
  const [onError, setOnError] = useState(false);
  const [showDisabled, setShowDisabled] = useState(false);
  const { mutateAsync: addEstablishment } = useEstablishmentCreateOne();
  const [notDiffusible, setNotDiffusible] = useState(false);
  const [isForeign, setIsForeign] = useState(false);
  const [siret, setSiret] = useState<String | undefined>(undefined);
  const onCheckIdentifier = async ({ identifier }: { identifier?: string }) => {
    setPreviewError(undefined);
    setSiret(identifier);
    if (!identifier) return;
    try {
      const establishment = await establishmentLookupIdentifier(identifier);
      //établissement non diffusible
      const closedSince =
        //@ts-ignore
        establishment?.closedSince || establishment?.details?.closedSince;
      if (
        //@ts-ignore
        establishment?.errorCode === 'INSI-COMP-003' &&
        !closedSince
      ) {
        setNotDiffusible(true);
        return;
      }
      if (establishment?.uuid || closedSince) {
        if (
          (establishment.customer && establishmentRole === 'CUSTOMER') ||
          (establishment.provider && establishmentRole === 'PROVIDER')
        ) {
          setOnError(true);
        } else {
          setEstablishment(establishment);
        }
        // ON ERROR TRUE  establishment?.closedSince exist !
        if (closedSince) {
          setOnError(true);
        }

        setPreviewError(
          <EstablishmentExistMessage
            establishment={establishment}
            establishmentRole={establishmentRole}
          />
        );
      } else {
        setOnError(false);
        setShowDisabled(false);
        setEstablishment(establishment);
      }
    } catch (err) {
      setPreviewError("Impossible de trouver l'établissement spécifié");
    }
  };

  const onAddEstablishment = async (establishment: Partial<IEstablishment>) => {
    try {
      const newEstablishment = await addEstablishment(establishment);
      queryClient.refetchQueries({
        queryKey: ['establishments'],
        type: 'active',
      });
      if (newEstablishment) onResolve(newEstablishment);
    } catch (err) {
      //console.log(err.errorCode);
    }
  };

  return (
    <ModalFrame
      isOpen={isOpen}
      onClose={() => onResolve(false)}
      width={850}
      closeIcon
    >
      <VerifySiretForm
        onSubmit={onCheckIdentifier}
        error={onError && previewError}
        isLocked={!!establishment || notDiffusible}
        onUnlock={() => {
          setEstablishment(undefined);
          setNotDiffusible(false);
        }}
        onForeignEstablishment={() => {
          setEstablishment(undefined);
          setIsForeign(true);
        }}
        establishmentRole={establishmentRole}
      />
      {notDiffusible && siret && (
        <Box>
          <FormControl
            label=""
            warningMessage={
              "L'établissement à fait valoir son droit à ne pas faire partie de la diffusion dans les fichiers de l'Insee, conformément à l'article A123-96 du code de commerce. Il est ainsi impossible de récupérer les informations de cet établissement du répertoire SIRENE. Afin de rajouter cet établissement dans Connecteed, veuillez remplir les informations manuellement via le formulaire ci-dessous."
            }
          ></FormControl>
          <Box mt={20}>
            <CreateManualEstablishmentForm
              defaultValues={{ siret: siret as string }}
              establishmentRole={establishmentRole}
              onSubmit={(formValues: any) => onAddEstablishment(formValues)}
              onResolve={onResolve}
            />
          </Box>
        </Box>
      )}
      {establishment && !notDiffusible && (!onError || showDisabled) && (
        <Box mt={20}>
          <AddEstablishmentForm
            isDisabled={showDisabled}
            establishmentRole={establishmentRole}
            defaultValues={establishment}
            onSubmit={onAddEstablishment}
            onResolve={onResolve}
          />
        </Box>
      )}
      {!establishment &&
        isForeign &&
        !notDiffusible &&
        (!onError || showDisabled) && (
          <Box mt={20}>
            <AddForeignEstablishmentForm
              isDisabled={showDisabled}
              establishmentRole={establishmentRole}
              defaultValues={establishment}
              onSubmit={onAddEstablishment}
              onResolve={onResolve}
            />
          </Box>
        )}
    </ModalFrame>
  );
};

export const showAddEstablishmentModal = create<Props, ICompanyGetResponse>(
  AddEstablishmentModal
);
