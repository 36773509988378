import { useTranslation } from 'react-i18next';
import { transformText } from '@commons';

// Hook
export function useTranslationFormat(): (
  key: string,
  format?: string | false,
  plural?: any,
  params?: any
) => string {
  const { t } = useTranslation([
    'texts',
    'status',
    'errors',
    'notifications',
    'links',
    'traces',
    'linkTraces',
  ]);

  return (key, format, plural, params) => {
    if (format === 'capitalize-first') {
      return (
        //@ts-ignore
        transformText(t(key, params), 'capitalize-first') + (plural ? 's' : '')
      );
    }
    if (format === 'lowercase') {
      //@ts-ignore
      return transformText(t(key, params), 'lowercase') + (plural ? 's' : '');
    }
    if (format === 'uppercase') {
      //@ts-ignore
      return t(key, params).toUpperCase() + (plural ? 's' : '');
    }
    return t(key, params) + (plural ? 's' : '');
  };
}
