import {
  ERoles,
  formatDate,
  FR_STATUS,
  getEstablishmentName,
  getFullName,
  STATUS_COLOR,
  useAdditionalActivityFindMany,
  useMe,
  ADDITIONAL_ACTIVITY_TYPE_SELECT,
  getAdditionalActivitiesTotalAmount,
  formatInvoiceOrAdditionalActivitySort,
  checkDefaultManagerSelected,
  exportFileXlxs,
} from '@commons';
import {
  EadditionalActivityStatus,
  EAdditionalServiceType,
  EAdditionalServiceStatus,
  IJoinedAdditionalActivity,
  IJoinedAdditionalActivityWithInvoice,
  EadditionalActivityType,
  ETaskStatus,
  EBillingType,
  EExportModel,
} from '@freelancelabs/teoreme-commons';
import { showPatchAdditionalActivityModal } from 'components/modals/activities/PatchAdditionalActivityModal';
import { ALink } from 'components/ALink';
import { Header } from 'components/Header';
import { showAdditionalActivityCreateGescomBDLModal } from 'components/modals/gescom/CreateGescomAdditionalActivityBDLModal';
import { showRejectAdditionalActivityModal } from 'components/modals/RejectAdditionalActivityModal';
import { StaticSelect } from 'components/selects/StaticSelect';
import { UserSelect } from 'components/selects/UserSelect';
import { showGenerateInvoiceModal } from 'components/modals/invoices/GenerateInvoiceModal';
import {
  Box,
  Container,
  DatePickerRange,
  Flex,
  FormLabel,
  Input,
  Itemize,
  Link,
  Menu,
  MenuItem,
  SpinnerBox,
  Status,
  Tab,
  Table,
  TableColumn,
  Text,
  CustomToolTip,
  Button,
} from 'components/ui';
import {
  AddIcon,
  DotsIcon,
  EyeIcon,
  InfoIcon,
  FastCashIcon,
  RefreshIcon,
  CancelIcon,
  EditIcon,
  SendIcon,
} from 'components/ui/icons';
import { add, getTime } from 'date-fns';
import { useSetCrumbs } from 'hooks/breadCrumb';
import { useDebounce } from 'hooks/useDebounce';
import { useShowMessage } from 'hooks/useShowMessage';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Theme } from 'styles';
import { useListAdditionalActivityStore } from 'store/addtionalActivities';
import { useTranslationFormat } from 'hooks/useTranslateFormat';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import {
  canDisplayCreateBdlButton,
  canDisplayGenerateCustomerActivityInvoiceButton,
  canDisplayShowCorrectRejectButton,
  checkDisplayAdditionalActivityMenuList,
  taskTypeMapping,
} from 'helpers';
import { useGlobalStore } from 'store';
import { showDialogModal } from 'components/modals/DialogModal';
import EXPORT_IMG from 'assets/images/export_excel_tutorial.png';

type Item = Itemize<IJoinedAdditionalActivity>;

const Tabs = (
  <>
    <Tab
      variant="secondary"
      href={'/orders/additional-activities/to-be-validated'}
    >
      A valider
    </Tab>
    <Tab variant="secondary" href={'/orders/additional-activities/validated'}>
      Validés
    </Tab>
    <Tab variant="secondary" href={'/orders/additional-activities/rejected'}>
      Refusés
    </Tab>
  </>
);

const columns = (
  status:
    | 'awaiting'
    | 'to-be-validated'
    | 'validated'
    | 'rejected'
    | 'archived',
  RejectButton: any,
  additionlaActivityTypeText: any,
  showMessage: any,
  me: any
) =>
  [
    {
      key: 'displayReference',
      sortKey: 'mission.displayReference',
      text: 'REF.MISSION',
      render: (item: IJoinedAdditionalActivity) => {
        const paymentDeadlineFC = item?.mission?.additionalServices?.find(
          sc =>
            sc?.type === EAdditionalServiceType.FAST_CASH &&
            sc?.status === EAdditionalServiceStatus.ACTIVE
        );
        const deaddline = paymentDeadlineFC?.data?.deadline;
        return (
          <ALink
            href={`/delivery/missions/${item?.mission?.reference}/additional-activities`}
            target="_blank"
          >
            {status === 'to-be-validated' &&
            //@ts-ignore
            item.hasFastCash &&
            paymentDeadlineFC ? (
              <Box>
                <a data-for={item.uuid} data-tip="" data-iscapture="true">
                  <ReactTooltip
                    id={item.uuid}
                    place={'bottom'}
                    variant={'dark'}
                    float={false}
                    style={{
                      backgroundColor:
                        deaddline > 10
                          ? Theme.colors.warning.default
                          : Theme.colors.error.default,
                    }}
                  >
                    <Text color="white" fontSize={11}>
                      Fast cash {paymentDeadlineFC?.data?.deadline} jours
                    </Text>
                  </ReactTooltip>
                  <Box mr={20}>
                    <FastCashIcon
                      deaddline={deaddline}
                      fill={
                        deaddline > 10
                          ? Theme.colors.warning.default
                          : Theme.colors.error.default
                      }
                    />
                  </Box>
                </a>
              </Box>
            ) : (
              <Box ml={status === 'to-be-validated' ? '40px' : 0}> </Box>
            )}
            <CustomToolTip
              text={item?.mission?.description}
              id={item?.uuid + '_description'}
            >
              <ALink
                href={`/delivery/missions/${item?.mission?.reference}/additional-activities`}
                target="_blank"
              >
                <Text>{item.mission.displayReference}</Text>
              </ALink>
            </CustomToolTip>
          </ALink>
        );
      },
    },
    {
      key: 'structure',
      sortKey: 'mission.sage.sucture',
      text: 'STRU',
      render: (item: IJoinedAdditionalActivity) =>
        item?.mission?.sage?.structure,
    },
    {
      key: 'month',
      text: 'MOIS',
      sortKey: 'month',
      render: (item: IJoinedAdditionalActivity) => {
        //@ts-ignore
        const monthOrDate = item?.month || item?.normalizedDate;
        return (
          <Box>
            <Text>
              {monthOrDate
                ? `${
                    new Date(monthOrDate as Date)?.getUTCMonth() + 1
                  }/${new Date(monthOrDate as Date)?.getUTCFullYear()}`
                : 'N/A'}
            </Text>
          </Box>
        );
      },
    },
    {
      key: 'type',
      text: 'TYPE',
      render: (item: IJoinedAdditionalActivity) => {
        return additionlaActivityTypeText(item.type, 'capitalize-first');
      },
    },
    {
      key: 'submittedAt',
      text: 'RECU LE',
      sortKey: 'submittedAt',
      hidden: status !== 'to-be-validated',
      render: (item: IJoinedAdditionalActivity) => {
        const submitAt = item?.submittedAt || item?.statusChangedAt;
        const sumbitDate = submitAt ? new Date(submitAt) : undefined;
        return (
          <Box>
            <Text>{sumbitDate ? `${formatDate(sumbitDate)}` : 'N/A'}</Text>
          </Box>
        );
      },
    },
    {
      key: 'estCustomer',
      text: 'ÉTABLIS. CLIENT',
      sortKey: 'estCustomer.businessName',
      render: (item: IJoinedAdditionalActivity) => (
        <ALink
          href={`/clients/establishments/${item?.estCustomer?.uuid}`}
          target="_blank"
        >
          {getEstablishmentName(item.estCustomer)}
        </ALink>
      ),
    },
    {
      key: 'estProvider',
      text: 'ÉTABLIS. FOURN.',
      sortKey: 'estProvider.businessName',
      render: (item: IJoinedAdditionalActivity) => (
        <ALink
          href={`/providers/establishments/${item?.estProvider?.uuid}`}
          target="_blank"
        >
          {getEstablishmentName(item.estProvider)}
        </ALink>
      ),
    },
    {
      key: 'contractor',
      text: 'INTERVENANT',
      sortKey: 'contractor.firstName',
      render: (item: IJoinedAdditionalActivity) => (
        <ALink
          href={`/providers/contractors/${item?.contractor?.uuid}`}
          target="_blank"
        >
          {getFullName(item.contractor)}
        </ALink>
      ),
    },
    {
      key: 'status',
      text: 'STATUT',
      render: (item: IJoinedAdditionalActivity) => {
        return (
          <Status variantColor={STATUS_COLOR[item.status]}>
            {item.status !== EadditionalActivityStatus.TO_BE_FILLED
              ? FR_STATUS[item.status]
              : FR_STATUS[EadditionalActivityStatus.TO_BE_SUBMITTED]}{' '}
            {(item.status === EadditionalActivityStatus.REJECTED ||
              item.status === EadditionalActivityStatus.VALIDATED) &&
              ` le ${formatDate(item.statusChangedAt || '')}`}
          </Status>
        );
      },
      hidden: status !== 'awaiting',
    },
    {
      key: 'totalAmount',
      text: 'MONTANT  HT',
      render: (item: IJoinedAdditionalActivity) => {
        return `${getAdditionalActivitiesTotalAmount(
          [item],
          item?.type,
          'PROVIDER'
        )} €`;
      },
    },
    {
      key: 'quoteStatus',
      text: 'DEVIS',
      render: (item: IJoinedAdditionalActivity) => (
        <Status variantColor={!item?.quote ? 'grey' : 'success'}>
          {(item?.quote && FR_STATUS[item?.quote?.status]) || 'Non envoyé'}
        </Status>
      ),
    },
    {
      key: 'customerPurchaseOrderStatus',
      text: 'BDC CLIENT',
      render: (item: any) => {
        const purchaseOrder = item?.purchaseOrder;
        // const purchaseOrderStatus = purchaseOrder?.status ?? '';
        return (
          <Status
            variantColor={
              !purchaseOrder || !purchaseOrder?.status ? 'grey' : 'success'
            }
          >
            {!purchaseOrder || !purchaseOrder?.status ? 'Non reçu' : 'Importé'}
            {/* {purchaseOrder?.status ? (
              //@ts-ignore
              <> le {new Date(purchaseOrder?.updatedAt).toLocaleDateString()}</>
            ) : (
              <></>
            )} */}
          </Status>
        );
      },
    },
    {
      key: 'invoice',
      text: 'BDL FOURNISSEUR',
      hidden: status === 'awaiting',
      render: (item: IJoinedAdditionalActivityWithInvoice) => {
        let bdlCustomer = false;
        let bdlProvider = false;
        const customerReference =
          item?.mission?.customer?.purchaseOrder?.reference;

        if (item?.invoices?.customer?.sage?.documentType) {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          bdlCustomer = true;
        }
        if (item?.invoices?.provider?.sage?.documentType) {
          bdlProvider = true;
        }
        if (!customerReference) {
          return (
            <>
              <a data-for={item.uuid} data-tip="" data-iscapture="true">
                <ReactTooltip
                  id={item.uuid}
                  place={'top'}
                  variant={'dark'}
                  float={false}
                  style={{ backgroundColor: Theme.colors.error.default }}
                >
                  <Text color="white" fontSize={11}>
                    Le bon de commande client est requis pour créer les bons de
                    livraison dans gescom.
                  </Text>
                </ReactTooltip>
                <ALink
                  href={`/delivery/missions/${item?.mission?.reference}/client`}
                >
                  <Status variantColor="error">BDC Client manquant</Status>
                </ALink>
              </a>
            </>
          );
        }
        if (bdlProvider) {
          return <Status variantColor="success">Créé dans GESCOM</Status>;
        }
        if (!bdlProvider) {
          return <Status variantColor="error">BDL fournisseur manquant</Status>;
        }
      },
    },
    {
      key: 'task',
      text: 'TÂCHES',
      hidden: status !== 'validated',
      render: (item: IJoinedAdditionalActivityWithInvoice) => {
        const displayedStatus = [ETaskStatus.ERROR, ETaskStatus.ONGOING];
        const getDisplayTask = () =>
          item?.tasks?.filter(task =>
            displayedStatus?.includes(task?.lastTaskStatus)
          );
        const tasks = getDisplayTask();
        if (tasks && tasks?.length > 0) {
          return (
            <CustomToolTip
              color={Theme?.colors?.warning?.default}
              text={
                <Box>
                  <ul>
                    {tasks?.map(task => (
                      <li>
                        {taskTypeMapping?.[task.taskType]}:&nbsp;
                        {FR_STATUS?.[task?.lastTaskStatus]}
                      </li>
                    ))}
                  </ul>
                </Box>
              }
              id={item?.uuid + '_task'}
            >
              <InfoIcon
                //onClick={() => showCraTaskDetails({ cra: item })}
                fill={Theme?.colors?.warning?.default}
                style={{ cursor: 'pointer' }}
              />
            </CustomToolTip>
          );
        } else {
          return 'N/A';
        }
      },
    },
    {
      key: 'actions',
      text: 'ACTIONS',
      align: 'flex-end',
      hidden: status === 'archived',
      render: (item: IJoinedAdditionalActivityWithInvoice) => {
        let bdlCustomer = false;
        let bdlProvider = false;

        const customerReference =
          item?.mission?.customer?.purchaseOrder?.reference;
        if (item?.invoices?.customer?.sage?.documentType) {
          bdlCustomer = true;
        }
        if (item?.invoices?.provider?.sage?.documentType) {
          bdlProvider = true;
        }
        /* CHECK PERMISSION FIX CRA */
        // const invoices = item?.invoices;
        // const isTeamLeader = checkUserHasRole(me, 'TEAM_LEADER');
        // const userHavePermissionToFix = true;
        const billingType = item?.mission?.billing?.type;
        return (
          <Flex alignItems="center">
            <Box width={1 / 2}>
              {billingType === EBillingType.DAY ? (
                <ALink href={`/orders/cra/${item?.cra?.refCra}/informations`}>
                  <Link iconLeft={<EyeIcon />}></Link>{' '}
                </ALink>
              ) : (
                <ALink
                  href={`/orders/activities/flat-rate/${item?.mission?.reference}`}
                >
                  <Link iconLeft={<EyeIcon />}></Link>{' '}
                </ALink>
              )}
            </Box>
            <Box width={1 / 2}>
              {checkDisplayAdditionalActivityMenuList(item, bdlProvider) && (
                <Menu
                  align="right"
                  menuItems={
                    <>
                      {canDisplayCreateBdlButton(item, bdlProvider) && (
                        <MenuItem>
                          <Link
                            onClick={() =>
                              showAdditionalActivityCreateGescomBDLModal({
                                mission: item?.mission,
                                delivreyOrders: { bdlProvider, bdlCustomer },
                                additionalActivity: item,
                                customerReference: customerReference as string,
                                refetchQueryKey: 'Additionalactivity',
                              })
                            }
                            iconLeft={<AddIcon />}
                          >
                            Créer le BDL Fourn. dans GESCOM
                          </Link>
                        </MenuItem>
                      )}
                      {canDisplayShowCorrectRejectButton(item) && (
                        <MenuItem>
                          <Link
                            onClick={() =>
                              showPatchAdditionalActivityModal({
                                additionalActivity: item,
                              })
                            }
                            iconLeft={<EditIcon />}
                          >
                            Corriger / Refuser
                          </Link>
                        </MenuItem>
                      )}
                      {/*
                    - Check CRA status
                    - Only CRAs with existing BL can generate the customer invoice
                    - Check  customer invoice  status
                    - TODO CHECK IF VALIDATED BY SAGE !
                  */}
                      {canDisplayGenerateCustomerActivityInvoiceButton(
                        item
                      ) && (
                        <MenuItem>
                          <Link
                            iconLeft={<SendIcon />}
                            onClick={() =>
                              showGenerateInvoiceModal({
                                invoiceUuid: item?.invoices?.customer
                                  ?.uuid as string,
                              })
                            }
                          >
                            Générer et envoyer la facture client
                          </Link>
                        </MenuItem>
                      )}
                    </>
                  }
                >
                  <DotsIcon
                    fill={Theme?.colors?.primary?.default}
                    fontSize={20}
                  />
                </Menu>
              )}
            </Box>
          </Flex>
        );
      },
    },
  ].filter(Boolean) as TableColumn<Item>[];

const getSortBy = (
  filterParams: string,
  sortString: string,
  initialSate: boolean
) => {
  let sortby = '';
  if (filterParams === 'to-be-validated') {
    sortby = `-hasFastCash fastCashDeadline ${
      sortString?.includes('-submittedAt') ? '-submittedAt' : 'submittedAt'
    }`;
  } else {
    sortby = sortString;
  }
  if (sortString?.includes('month') || sortString?.includes('submittedAt')) {
    sortby = `${sortString?.replaceAll('month', 'normalizedDate')} uuid`;
  }
  return sortby;
};

export const AdditionalActivityList = () => {
  const { filter: filterParams } = useParams<{
    filter:
      | 'awaiting'
      | 'validated'
      | 'to-be-validated'
      | 'archived'
      | 'rejected';
  }>();
  useSetCrumbs(
    [filterParams],
    [
      {
        label: 'Prestations complémentaires et frais',
        path: '/orders',
      },
      {
        label: 'Prestations complémentaires',
        path: '/orders/additional-activities',
      },
      {
        label:
          filterParams === 'awaiting'
            ? 'A traiter'
            : filterParams === 'to-be-validated'
              ? 'à valider'
              : filterParams === 'rejected'
                ? 'refusés'
                : 'validés',
        path: '/orders/additional-activities/' + filterParams,
      },
    ]
  );
  const {
    initialSate,
    filter,
    order,
    sortedBy,
    searchQuery,
    startDate,
    endDate,
    managerSelected,
    statusSelect,
    selectedType,
    page,
    updateListAdditionalActivityStore,
    resetListAdditionalActivityStore,
  } = useListAdditionalActivityStore();
  const { me } = useMe();
  const { tasksInProgress, updateGlobalStore } = useGlobalStore();
  const [isLoadingExport, setIsLoadingExport] = useState(false);
  const t = useTranslationFormat();
  const sortString = (order === 'desc' ? '-' : '') + sortedBy;
  const debouncedFilterQuery = useDebounce(searchQuery, 500);
  const limit = 20;
  const buildQuery = () => {
    return {
      accountManager: checkDefaultManagerSelected(me, managerSelected)
        ? managerSelected
        : undefined,
      type:
        selectedType === 'ALL'
          ? { $nin: [EadditionalActivityType.MILESTONE] }
          : selectedType !== null && selectedType !== 'N/A'
            ? selectedType
            : { $nin: [EadditionalActivityType.MILESTONE] },
      status:
        !statusSelect || statusSelect === 'N/A'
          ? filterParams === 'awaiting'
            ? {
                $in: [
                  EadditionalActivityStatus.TO_BE_FILLED,
                  EadditionalActivityStatus.TO_BE_SUBMITTED,
                  EadditionalActivityStatus.REJECTED,
                ],
              }
            : filterParams === 'archived'
              ? EadditionalActivityStatus.ARCHIVED
              : filterParams === 'to-be-validated'
                ? EadditionalActivityStatus.TO_BE_VALIDATED
                : filterParams === 'rejected'
                  ? EadditionalActivityStatus.REJECTED
                  : EadditionalActivityStatus.VALIDATED
          : statusSelect,
      $and:
        startDate && endDate
          ? [
              {
                $expr: {
                  $gte: [{ $toLong: '$month' }, getTime(startDate)],
                },
              },
              {
                $expr: {
                  $lte: [
                    { $toLong: '$month' },
                    getTime(add(endDate, { months: 1 })),
                  ],
                },
              },
            ]
          : undefined,
      $or: debouncedFilterQuery
        ? [
            {
              'mission.title': {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
            {
              'mission.displayReference': {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
            {
              'mission.cra.refCra': {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
            // customer
            {
              'estCustomer.businessName': {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
            {
              'estCustomer.tradeName': {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
            {
              'estCustomer.signBoard1': {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
            {
              'estCustomer.signBoard2': {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
            {
              'estCustomer.signBoard3': {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
            {
              'estCustomer.identifier': {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
            {
              'estCustomer.siret': {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
            {
              'estCustomer.siren': {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
            // provider
            {
              'estProvider.businessName': {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
            {
              'estProvider.tradeName': {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
            {
              'estProvider.signBoard1': {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
            {
              'estProvider.signBoard2': {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
            {
              'estProvider.signBoard3': {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
            {
              'estProvider.identifier': {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
            {
              'estProvider.siret': {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
            {
              'estProvider.siren': {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
          ]
        : undefined,
    };
  };

  const {
    data: AdditionalActivityQuery,
    status,
    isFetching,
    isPending,
  } = useAdditionalActivityFindMany({
    joinInvoice: true,
    filterObject: buildQuery(),
    limit: limit,
    skip: limit * page,
    sort: formatInvoiceOrAdditionalActivitySort(
      getSortBy(filterParams, sortString, initialSate)
    ),
  });

  const loading = isPending;
  const totalCount = AdditionalActivityQuery?.totalCount || 0;
  let items: Itemize<IJoinedAdditionalActivityWithInvoice>[] | [] | undefined =
    [];
  items = AdditionalActivityQuery?.additionalActivities?.map(
    (activity: any) => ({
      key: activity.uuid + '_' + activity.createdAt || '',
      ...activity,
    })
  );

  const onSortedChange = React.useCallback(
    (shortBy: string, order: 'asc' | 'desc'): void => {
      //@ts-ignore
      updateListAdditionalActivityStore({ order, sortedBy: shortBy });
    },
    []
  );

  const showMessage = useShowMessage();
  const onExportToExcel = async () => {
    setIsLoadingExport(true);
    try {
      const exportResponse = await exportFileXlxs({
        model: EExportModel.ACTIVITY,
        query: {
          filter: {
            ...buildQuery(),
            type: {
              $in:
                selectedType === 'ALL'
                  ? [
                      EadditionalActivityType.EXPENSE,
                      EadditionalActivityType.STAND_BY_DUTY,
                    ]
                  : selectedType !== null && selectedType !== 'N/A'
                    ? [selectedType]
                    : [
                        EadditionalActivityType.EXPENSE,
                        EadditionalActivityType.STAND_BY_DUTY,
                      ],
            },
          },
        },
      });
      const uuid = exportResponse?.taskUuid;
      if (Array.isArray(tasksInProgress)) {
        updateGlobalStore({ tasksInProgress: [...tasksInProgress, uuid] });
      } else {
        updateGlobalStore({ tasksInProgress: [uuid] });
      }
      showDialogModal({
        title: 'Export excel en cours',
        text: (
          <Box>
            <Text>
              La création de votre export excel est en cours. Cette action peut
              prendre quelques minutes. Vous pouvez suivre l'état de progression
              en cliquant sur l'icone ci-dessous.
            </Text>
            <img src={EXPORT_IMG} />
          </Box>
        ),
        confirmLabel: "J'ai compris",
      });
      setIsLoadingExport(false);
    } catch (e) {
      setIsLoadingExport(false);
    }
  };

  const additionlaActivityTypeText = (
    additionalActivityType: EadditionalActivityType
  ) => {
    if (additionalActivityType === EadditionalActivityType?.STAND_BY_DUTY) {
      return 'Prest. comp.';
    }
    return t(`${additionalActivityType}`, 'capitalize-first');
  };

  const RejectButton = ({
    additionalActivity,
  }: {
    additionalActivity: IJoinedAdditionalActivityWithInvoice;
  }) => {
    return (
      <Box>
        <Link
          iconLeft={<CancelIcon />}
          onClick={async () => {
            await showRejectAdditionalActivityModal({
              additionalActivity,
            });
          }}
        >
          Refuser
        </Link>
      </Box>
    );
  };
  React.useMemo(() => {
    if (filterParams === 'awaiting') {
      updateListAdditionalActivityStore({
        //@ts-ignore
        filter: filterParams,
        isOpen: true,
        initialSate,
        statusSelect: 'N/A',
      });
    } else {
      updateListAdditionalActivityStore({
        //@ts-ignore
        filter: filterParams,
        isOpen: false,
        initialSate,
        statusSelect: 'N/A',
      });
    }
  }, [filterParams]);

  return (
    <>
      <Header
        tabs={Tabs}
        actions={
          <Button isLoading={isLoadingExport} onClick={() => onExportToExcel()}>
            Exporter
          </Button>
        }
      >
        <Text variant="h1">Prestations complémentaires - Frais</Text>
      </Header>
      <Container p="20px 0">
        <Flex justifyContent="space-between" ml={20} mr={20} mb={10}>
          <Box width={filterParams === 'awaiting' ? 3 / 12 : 4 / 12} mr={10}>
            <FormLabel>RECHERCHER</FormLabel>
            <Input
              isFullWidth
              type="search"
              value={searchQuery}
              onChange={e => {
                updateListAdditionalActivityStore({
                  //@ts-ignore
                  searchQuery: e?.target?.value,
                });
              }}
            />
          </Box>

          <Box width={filter === 'awaiting' ? 3 / 12 : 4 / 12} mr={10}>
            <FormLabel>Chargés de comptes</FormLabel>
            <UserSelect
              placeholder="Tous les Chargés de comptes"
              role={ERoles.ACCOUNT_MANAGER}
              onChange={value =>
                updateListAdditionalActivityStore({ managerSelected: value })
              }
              value={managerSelected}
            />
          </Box>
          <Box width={filter === 'awaiting' ? 3 / 12 : 4 / 12} mr={10}>
            <FormLabel pl={20}>PÉRIODE</FormLabel>
            <DatePickerRange
              startDate={startDate}
              endDate={endDate}
              isClearable={true}
              setStartDate={value => {
                updateListAdditionalActivityStore({
                  startDate: value as Date,
                  page: 0,
                });
              }}
              setEndDate={value => {
                updateListAdditionalActivityStore({
                  endDate: value as Date,
                  page: 0,
                });
              }}
            />
          </Box>
          {filter === 'awaiting' && (
            <Box width={3 / 12} mr={10}>
              <FormLabel>Statuts</FormLabel>
              <StaticSelect
                options={[
                  {
                    label: 'À saisir',
                    value: EadditionalActivityStatus.TO_BE_FILLED,
                    key: EadditionalActivityStatus.TO_BE_FILLED,
                  },
                  {
                    label: 'À soumettre',
                    value: EadditionalActivityStatus.TO_BE_SUBMITTED,
                    key: EadditionalActivityStatus.TO_BE_SUBMITTED,
                  },
                  {
                    label: 'Refusé',
                    value: EadditionalActivityStatus.REJECTED,
                    key: EadditionalActivityStatus.REJECTED,
                  },
                  //"TO_BE_FILLED", "TO_BE_SUBMITTED", "REJECTED"
                ]}
                onChange={value =>
                  updateListAdditionalActivityStore({
                    statusSelect: value as string,
                  })
                }
                value={statusSelect}
              />
            </Box>
          )}
          <Box width={3 / 12} mr={10}>
            <FormLabel>Type</FormLabel>
            <StaticSelect
              placeholder="Tous les types"
              options={ADDITIONAL_ACTIVITY_TYPE_SELECT}
              onChange={value =>
                updateListAdditionalActivityStore({
                  selectedType: value as EadditionalActivityType,
                })
              }
              value={selectedType}
            />
          </Box>
          <Box>
            <Flex
              justifyContent={'center'}
              alignItems={'center'}
              flexWrap="wrap"
              alignContent="center"
              width={1 / 1}
              height={'100%'}
            >
              <Link
                isDisabled={initialSate}
                iconLeft={
                  <RefreshIcon
                    fill={
                      initialSate ? Theme?.colors?.grey?.default : undefined
                    }
                  />
                }
                mt={'5px'}
                onClick={() =>
                  !initialSate ? resetListAdditionalActivityStore({}) : false
                }
              >
                Réinitialiser
              </Link>
            </Flex>
          </Box>
        </Flex>
        {loading && <SpinnerBox />}
        {!loading && (
          <Table<Itemize<IJoinedAdditionalActivity>>
            isLoading={isFetching}
            columns={columns(
              filterParams,
              RejectButton,
              additionlaActivityTypeText,
              showMessage,
              me
            )}
            items={items}
            sortedBy={sortedBy}
            order={order}
            page={page}
            total={totalCount}
            perPage={totalCount < limit ? totalCount : limit}
            opacity={filterParams === 'archived' ? 0.5 : 1}
            onSortedChange={onSortedChange}
            onChangePage={page => updateListAdditionalActivityStore({ page })}
          />
        )}
      </Container>
    </>
  );
};
