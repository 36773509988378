import { getEstablishmentName } from '@commons';
import { useEstablishmentFindMany, useEstablishmentFindOne } from '@commons'; // moved
import { Select } from 'components/ui';
import { useDebounce } from 'hooks/useDebounce';
import React, { ComponentPropsWithoutRef, useState } from 'react';

type EstablishmentSelectProps = {
  isDisabled?: boolean;
  value?: string;
  onChange?: (id: string) => void;
  onChangeCompleteObject?: (object: any) => void;
  filter: any;
  referenceValue: 'uuid' | 'siren';
} & Omit<ComponentPropsWithoutRef<'input'>, 'event' | 'onChange' | 'value'>;

export const EstablishmentSelect = ({
  value,
  onChange,
  filter: globalFilter,
  referenceValue = 'uuid',
  isDisabled,
  ...props
}: EstablishmentSelectProps) => {
  const [filter, setFilter] = useState('');
  const debouncedFilter = useDebounce(filter, 500);
  const getEstablishmentIdentifier = (establishment: any) => {
    if (establishment?.siret) return establishment.siret;
    if (establishment?.identifier) return establishment.identifier;
    return '';
  };

  const { data: establishmentsQuery, isFetching } = useEstablishmentFindMany({
    filterObject: {
      ...globalFilter,
      $or: [
        { businessName: { $regex: debouncedFilter, $options: 'i' } },
        { tradeName: { $regex: debouncedFilter, $options: 'i' } },
        { signBoard1: { $regex: debouncedFilter, $options: 'i' } },
        { signBoard2: { $regex: debouncedFilter, $options: 'i' } },
        { signBoard3: { $regex: debouncedFilter, $options: 'i' } },
        { identifier: { $regex: debouncedFilter, $options: 'i' } },
        { siret: { $regex: debouncedFilter, $options: 'i' } },
        { siren: { $regex: debouncedFilter, $options: 'i' } },
        { identifier: { $regex: debouncedFilter, $options: 'i' } },
      ],
    },
    limit: 10,
  });

  /** Get selected establishment */
  const { data: selectedEstablishment } = useEstablishmentFindOne(value);

  const selectedOption = selectedEstablishment && {
    label: `${getEstablishmentName(
      selectedEstablishment
    )} ${getEstablishmentIdentifier(selectedEstablishment)}`,
    value: selectedEstablishment.uuid,
  };

  const establishmentOptions =
    establishmentsQuery?.establishments?.map(e => ({
      label: getEstablishmentName(e) + ' ' + (e.siret || e.identifier),
      value: e.uuid || '',
    })) || [];

  React.useEffect(() => {
    if (props?.onChangeCompleteObject) {
      if (selectedEstablishment) {
        props.onChangeCompleteObject(selectedEstablishment);
      }
      if (!value) {
        props.onChangeCompleteObject(undefined);
      }
    }
  }, [selectedEstablishment, value]);

  return (
    <Select
      isDisabled={isDisabled}
      options={establishmentOptions}
      isLoading={isFetching}
      onInputChange={(value: string) => setFilter(value)}
      onChange={(option: any) => {
        onChange && onChange(option && option.value);
        props.onChangeCompleteObject &&
          props.onChangeCompleteObject(selectedEstablishment);
      }}
      isClearable={true}
      value={selectedOption}
      {...props}
    />
  );
};
