import {
  EFileType,
  IJoinedMission,
  EMissionStatus,
} from '@freelancelabs/teoreme-commons';
import { missionUpdateOne } from '@commons';
import { uploadFile } from '@commons';
import { FileCard } from 'components/cards/FileCard';
import { showDialogModal } from 'components/modals/DialogModal';
import {
  FileInputStyled,
  Link,
  MenuItem,
  SpinnerBox,
  Status,
  BlocInformation,
} from 'components/ui';
import { AddIcon, DeleteIcon, EditIcon } from 'components/ui/icons';
import { useState } from 'react';
import { queryClient } from '@commons';
import { formatDate } from '@commons';
import { showImportBDCModal } from 'components/modals/missions/ImportBDCModal';

type POCardProps = {
  mission: IJoinedMission;
  isDisabled: boolean;
};

export const POCard = ({ mission, isDisabled }: POCardProps) => {
  const [loading, setLoading] = useState(false);

  const client = mission.customer;

  const fileName = `${
    client.purchaseOrder?.reference || 'BDC'
  }_${client.establishment.businessName.replace(/ /g, '_')}.pdf`;

  const onDeletePo = async () => {
    const confirm = await showDialogModal({
      title: 'Êtes-vous sûr(e) de vouloir supprimer le BDC client?',
      text: 'Attention ce document sera définitivement supprimé de Connecteed.',
      confirmLabel: 'Confirmer',
      cancelLabel: 'Annuler',
    });

    if (!confirm) return;

    setLoading(true);
    await missionUpdateOne({
      reference: mission.reference,
      mission: {
        bcc: {
          // @ts-ignore
          file: null,
        },
      },
    });
    queryClient.refetchQueries({
      queryKey: [mission.customer?.contact?.cognitoUserId || ''],
    });
    queryClient.refetchQueries({ queryKey: [mission.reference || ''] });
    setLoading(false);
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onImportPo = async (fileSelected: File[]) => {
    setLoading(true);
    try {
      const s3Response = await uploadFile({
        file: fileSelected[0],
        fileType: EFileType.PURCHASE_ORDER_CUSTOMER,
        actionType: 'upload',
        missionRef: mission.reference,
      });

      if (!s3Response) throw new Error();

      await missionUpdateOne({
        reference: mission.reference,
        mission: {
          // @ts-ignore
          bcc: {
            file: {
              eTag: s3Response?.eTag,
              fileLocation: s3Response?.fileLocation,
            },
            //status: EPurchaseOrderStatus.IMPORTED,
          },
        },
      });
      setLoading(false);
      // refresh customer contact to fetch GESCOM status
      queryClient.refetchQueries({
        queryKey: [mission.customer?.contact?.cognitoUserId || ''],
      });
      queryClient.refetchQueries({ queryKey: [mission.reference || ''] });
      await showDialogModal({
        title: 'Votre BDC client a bien été importé',
        text: "Attention : Les informations de la fiche Mission courante n'ont pas été mises à jour avec les informations contenues dans ce BDC client, merci de vérifier et de procéder si besoin à leur mise à jour manuelle.",
        confirmLabel: "J'ai bien compris",
      });
    } finally {
      setLoading(false);
    }
  };

  if (loading) return <SpinnerBox height={92} />;
  if (isDisabled && !client.purchaseOrder?.file) {
    return (
      <BlocInformation>
        <p>Aucun bon de commande client</p>
      </BlocInformation>
    );
  }
  if (!client.purchaseOrder?.file)
    return (
      <FileInputStyled variant="area">
        <Link
          onClick={() => showImportBDCModal({ mission })}
          iconLeft={<AddIcon />}
        >
          Importer le bon de commande
        </Link>
      </FileInputStyled>
    );

  return (
    <FileCard
      fileName={fileName || ''}
      fileLocation={client.purchaseOrder?.file.fileLocation || ''}
      complement={
        <Status variantColor="success">
          Importé le{' '}
          {formatDate(
            client.purchaseOrder?.updatedAt ||
              client.purchaseOrder?.createdAt ||
              ''
          )}
        </Status>
      }
      actions={
        isDisabled ? (
          false
        ) : (
          <>
            {mission?.status === EMissionStatus.DRAFT && (
              <MenuItem onClick={onDeletePo}>
                <Link iconLeft={<DeleteIcon />}>Supprimer le BDC client</Link>
              </MenuItem>
            )}
            {mission?.status === EMissionStatus.VALIDATED && (
              <MenuItem onClick={() => showImportBDCModal({ mission })}>
                <Link iconLeft={<EditIcon />}>Modifier le BDC client</Link>
              </MenuItem>
            )}
          </>
        )
      }
    />
  );
};
