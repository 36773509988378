import * as React from 'react';
import styled from 'styled-components';
import { Box, boxProperties, BoxProps } from 'components/ui';
import { useHistory } from 'react-router-dom';
type DropDownContentType = {
  align?: string;
};
export const DropDownContent = styled.div<DropDownContentType>`
  display: none;
  position: absolute;
  background-color: ${p => p.theme.colors.white};
  min-width: 300px;
  width: auto;
  border: ${p => `1px solid ${p.theme.colors.grayBlue}`};
  overflow: hidden;
  border-radius: 5px;
  z-index: 200;
  overflow: hidden;
  top: 100%;
  ${props => (props.align === 'right' ? 'right' : 'left')} : 0px;
`;

export const MenuItemStyled = styled.div`
  color: ${p => p.theme.colors.darkBlue};
  padding: 10px 16px;
  text-decoration: none;
  font-size: 14px;
  display: flex;
  justify-content: flex-start;
  &:hover,
  &:focus {
    background-color: ${p => p.theme.colors.lightGray};
  }
`;

export const DropDownWrapper = styled.div<BoxProps>`
  position: relative;
  display: inline-block;
  cursor: pointer;

  &:hover ${DropDownContent} {
    position: absolute;
    display: block;
  }

  ${boxProperties}
`;
type MenuItemProps = {
  children: React.ReactNode;
  href?: string;
};
export const MenuItem: React.FC<
  React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > &
    MenuItemProps &
    BoxProps
> = ({ children, ...props }) => {
  const history = useHistory();
  let hasHref: false | string = false;
  let hasState: false | string = false;
  // get children props ton include href
  React.Children.forEach(children, element => {
    if (!React.isValidElement(element)) return;
    if (element?.props?.href) {
      hasHref = element?.props?.href;
    }
    if (element?.props?.params) {
      hasState = element?.props?.params;
    }
  });
  if (hasHref) {
    return (
      //@ts-ignore
      <MenuItemStyled
        {...props}
        onClick={() =>
          history?.push({
            pathname: hasHref as string,
            state: hasState || undefined,
          })
        }
      >
        {children}
      </MenuItemStyled>
    );
  }
  //@ts-ignore
  return <MenuItemStyled {...props}>{children}</MenuItemStyled>;
};

type MenuProps = {
  menuItems: React.ReactNode;
  children: React.ReactNode;
  align?: 'left' | 'right';
  openOnClick?: boolean | undefined;
};

export const Menu: React.FC<React.PropsWithChildren<MenuProps & BoxProps>> = ({
  children,
  menuItems,
  align,
  openOnClick = true,

  ...props
}) => {
  const [open, setOpen] = React.useState(false);
  const [hover, setHover] = React.useState<boolean | null>(false);
  const [dropDownTop, setDropDownTop] = React.useState<string | undefined>(
    undefined
  );
  const ref = React.useRef<HTMLDivElement>(null);
  const autoClose = React.useCallback(async (): Promise<void> => {
    await setTimeout(function () {
      //@ts-ignore
      if (!hover) {
        setOpen(false);
      }
    }, 1000);
  }, []);

  React.useEffect(() => {
    //@ts-ignore
    let dropDownBottom;
    const windowHeight = window.innerHeight;
    if (ref.current) {
      dropDownBottom = ref.current.getBoundingClientRect().bottom;
    }
    if (dropDownBottom && dropDownBottom > windowHeight) {
      setDropDownTop(`-${dropDownBottom - windowHeight + 10}px`);
    }

    if (open && !hover) {
      autoClose();
    }
  }, [autoClose, open, hover, openOnClick]);

  return (
    <DropDownWrapper
      onClick={async () => {
        setOpen(!open);
        setHover(true);
      }}
      {...props}
    >
      {children}
      <Box position={'relative'} top={openOnClick ? -20 : 0}>
        <DropDownContent
          ref={ref}
          onMouseOver={() => !hover && setHover(true)}
          onMouseLeave={() => setOpen(false)}
          style={
            openOnClick
              ? open
                ? { position: 'absolute', display: 'block', top: dropDownTop }
                : { position: 'relative', display: 'none' }
              : {}
          }
          align={align}
        >
          {menuItems}
        </DropDownContent>
      </Box>
    </DropDownWrapper>
  );
};
