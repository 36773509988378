import { FlatUser } from '@freelancelabs/teoreme-commons';
import { checkEmail, changeUserEmail } from '@commons';
import { queryClient } from '@commons';
import {
  Link,
  Flex,
  Box,
  Text,
  Button,
  FormControl,
  Input,
} from 'components/ui';
import React, { useState } from 'react';
import { create } from 'react-modal-promise';
import { ModalFrame, ModalProps } from '../ModalFrame';
import { CloseIcon } from 'components/ui/icons';
import { useForm } from 'react-hook-form';
import { useShowMessage } from 'hooks/useShowMessage';
type FormValues = {
  email: string;
};
type Props = ModalProps & {
  beforeValidation?: () => void;
  contact: FlatUser;
  contactRole: 'CUSTOMER' | 'PROVIDER_CONTACT';
};

export const UpdateProviderContactEmailModal = ({
  onResolve,
  isOpen,
  contact,
  contactRole,
}: Props) => {
  const showMessage = useShowMessage();
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {
      email: contact?.email,
    },
  });
  const onSubmit = async (formValues: FormValues) => {
    setLoading(true);

    try {
      await changeUserEmail({
        action: 'REQUEST_CHANGE',
        cognitoUserId: contact?.cognitoUserId,
        email: formValues?.email,
      });
      queryClient.refetchQueries({ queryKey: [contact?.cognitoUserId] });
      if (contactRole === 'PROVIDER_CONTACT') {
        showMessage('success', 'Email envoyé au contact fournisseur');
      } else {
        showMessage('success', 'Email modifié');
      }
      onResolve(true);
    } catch (e) {
      //
    }

    setLoading(false);
  };

  return (
    <ModalFrame
      isOpen={isOpen}
      onClose={() => onResolve(false)}
      width={470}
      closeIcon
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Text variant="h2">
          Modifier l’email du contact{' '}
          {contactRole === 'PROVIDER_CONTACT' ? 'fournisseur' : 'client'}
        </Text>
        <Box width={'375px'} mt={20}>
          <FormControl
            label="Email"
            required
            errorMessage={errors?.email?.message}
          >
            <Input
              isFullWidth
              type="email"
              {...register('email', {
                required: 'Ce champs est requis',
                validate: value =>
                  !checkEmail(value)
                    ? 'Veuillez entrer une adresse e-mail valide'
                    : undefined,
              })}
            />
          </FormControl>
          {contactRole === 'PROVIDER_CONTACT' && (
            <Text>
              Un email contenant un lien de confirmation sera envoyé à cette
              nouvelle adresse. La modification ne sera visible dans la fiche
              contact qu’après confirmation du fournisseur.
            </Text>
          )}
        </Box>
        <Flex mt={20} width={'250px'} justifyContent="space-between">
          <Button isLoading={loading} type="submit">
            Enregistrer
          </Button>
          <Link
            mt={20}
            iconLeft={<CloseIcon />}
            onClick={() => onResolve(false)}
          >
            Annuler
          </Link>
        </Flex>
      </form>
    </ModalFrame>
  );
};

export const showUpdateProviderContactEmailModal = create<Props, boolean>(
  UpdateProviderContactEmailModal
);
