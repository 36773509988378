import { Roles as RolesTypes } from '@freelancelabs/inside-commons/dist/lib/models/enum/role.enum';
import { UserSelect } from 'components/selects/UserSelect';
import { Box, Button, Link, Row, Text } from 'components/ui';
import { CancelIcon } from 'components/ui/icons';
import React from 'react';
import { create } from 'react-modal-promise';
import { ModalFrame, ModalProps } from './ModalFrame';

type Props = ModalProps & {
  beforeValidation?: () => void;
  manager?: any;
};

export const UpdateAccountManagerModal = ({
  onResolve,
  isOpen,
  manager,
}: Props) => {
  const [managerSelected, setManagerSelected] = React.useState(manager);

  return (
    <ModalFrame
      isOpen={isOpen}
      onClose={() => onResolve(false)}
      width={520}
      closeIcon
    >
      <Box>
        <Text variant="h1" mb={30}>
          Modifier le chargé de comptes assigné
        </Text>
        <Box>
          <UserSelect
            placeholder="Chargés de comptes"
            role={RolesTypes.ACCOUNT_MANAGER}
            onChange={value => setManagerSelected(value)}
            value={managerSelected}
          />
        </Box>
        <Row spacing={20} width="300px" mt={20}>
          <Button
            type="button"
            isDisabled={!managerSelected || manager === managerSelected}
            onClick={() => onResolve(managerSelected)}
          >
            Enregistrer
          </Button>
          <Link
            iconLeft={<CancelIcon />}
            mt={'20px'}
            onClick={() => onResolve(false)}
          >
            Annuler
          </Link>
        </Row>
      </Box>
    </ModalFrame>
  );
};

export const showUpdateAccountManagerModal = create<Props>(
  UpdateAccountManagerModal
);
