import { useUserFindOne, createOrUpdateUser } from '@commons';
import { Header } from 'components/Header';
import { Avatar, Box, Container, Flex, Text } from 'components/ui';
import { getFirstCaracts, getFullName } from '@commons';
import React from 'react';
import { useParams } from 'react-router-dom';
import { UserForm } from 'forms/support/users';
import { useBreadCrumb } from 'hooks/breadCrumb';
import { useShowMessage } from 'hooks/useShowMessage';
export const UserDetails = () => {
  const showMessage = useShowMessage();
  const { uuid } = useParams<{ uuid: string }>();
  const { data: contact } = useUserFindOne(uuid);
  const { setCrumbs } = useBreadCrumb();
  React.useEffect(() => {
    setCrumbs([
      {
        label: 'Admin - Utilisateur',
        path: `/admin/users`,
      },
      {
        label: `${contact && getFullName(contact)}`,
        path: `/admin/users/${uuid}`,
      },
    ]);
  }, [contact]);
  const handleSubmit = async (values: any) => {
    try {
      await createOrUpdateUser({
        update: [values],
      });

      showMessage('success', "L'utilisateur à bien été mis à jours");
    } catch (e) {
      //
    }
  };
  return (
    <Box>
      <Header>
        <Flex alignItems="center">
          <Avatar text={contact && getFirstCaracts(contact)} />
          <Flex flexWrap="wrap">
            <Text ml={10} variant="h1">
              {contact && getFullName(contact)}
            </Text>
          </Flex>
        </Flex>
      </Header>

      <Container display="flex" pt={50}>
        <Flex width={1 / 1} justifyContent={'center'} p={20}>
          <Box width={'500px'}>
            {contact && <UserForm user={contact} onSubmit={handleSubmit} />}
          </Box>
        </Flex>
      </Container>
    </Box>
  );
};
