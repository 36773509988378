import * as React from 'react';
import {
  getFullName,
  useUserFindOne,
  useEstablishmentFindMany,
  queryClient,
  useEstablishmentUpdateOne,
  checkUserHasRole,
  useMe,
  ERoles,
} from '@commons';
import { Box, Text, Link, Spinner, Flex, Button } from 'components/ui';
import { useBreadCrumb } from 'hooks/breadCrumb';
import { useParams } from 'react-router-dom';
import { showLinkEstablishmentToContactModal } from 'components/modals/othersContacts/LinkEstablishmentToContactModal';
import { EstablishmentStructuredCard } from 'components/cards';
import { showDialogModal } from 'components/modals/DialogModal';

export const OtherContactsEstablishments: React.FunctionComponent<
  React.PropsWithChildren<unknown>
> = () => {
  const { me } = useMe();
  const { id } = useParams<{ id: string }>();
  const { data: contact } = useUserFindOne(id);
  const { mutateAsync: updateEstablishment } = useEstablishmentUpdateOne();

  const { setCrumbs } = useBreadCrumb();
  const establishmentUuids = contact?.roles
    ?.filter(r => r?.name === 'PORTAL_CLIENT_CUSTOMER_ACCESS')
    ?.map(r => r?.resource);
  const { data: establishments, isFetching: isFetchingEsta } =
    useEstablishmentFindMany({
      filterObject: establishmentUuids
        ? {
            uuid: { $in: establishmentUuids },
          }
        : undefined,
    });

  React.useEffect(() => {
    setCrumbs([
      {
        label: 'Autres contacts',
        path: '/others/contacts/active',
      },
      {
        label: contact ? getFullName(contact) : '-',
        path: `/others/contacts/${id}/information`,
      },
      {
        label: 'établissements',
        path: `/others/contacts/${id}/establishments`,
      },
    ]);
  }, [contact, id]);
  const editPortal =
    checkUserHasRole(me, ERoles?.ADMIN) ||
    checkUserHasRole(me, 'PORTAL_CLIENT_ADMIN');
  return (
    <Box>
      <Box>
        <Text variant="h2" mb={20}>
          Établissements visibles
        </Text>
        {editPortal &&
          checkUserHasRole(contact, ERoles.INTERNAL_COMMERCIAL) && (
            <Link
              onClick={() =>
                showLinkEstablishmentToContactModal({
                  contact: contact as any,
                })?.then(() =>
                  queryClient?.refetchQueries({
                    queryKey: [id],
                    type: 'active',
                  })
                )
              }
            >
              Ajouter un établissement
            </Link>
          )}
      </Box>

      {isFetchingEsta ? (
        <Spinner />
      ) : (
        <Flex
          display={'inline-flex'}
          justifyContent={'flex-start'}
          flexWrap={'wrap'}
        >
          {establishments?.establishments?.map(e => (
            <Box p={10} width={1 / 1}>
              <EstablishmentStructuredCard
                establishment={e}
                forRole={'CUSTOMER'}
                renderActions={
                  editPortal &&
                  checkUserHasRole(contact, ERoles.INTERNAL_COMMERCIAL) && (
                    <Button
                      onClick={() =>
                        showDialogModal({
                          title:
                            'Êtes-vous sûr de vouloir détacher cet établissement ? ',
                          confirmLabel: 'Détacher',
                          cancelLabel: 'Annuler',
                          beforeValidation: async () => {
                            const establishmentData = {
                              uuid: e.uuid,
                              removePortalClientAccess: [
                                contact?.cognitoUserId,
                              ],
                            };
                            await updateEstablishment(establishmentData as any);
                            queryClient.refetchQueries({
                              queryKey: [contact?.cognitoUserId],
                            });
                          },
                        })
                      }
                    >
                      Détacher
                    </Button>
                  )
                }
              />
            </Box>
          ))}
        </Flex>
      )}
    </Box>
  );
};

export default OtherContactsEstablishments;
