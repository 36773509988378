import { IEstablishmentMinifiedUsers } from '@freelancelabs/teoreme-commons';
import {
  checkUserHasRole,
  getEstablishmentName,
  ERoles,
  useMe,
  checkDefaultManagerSelected,
} from '@commons';
import { useEstablishmentFindMany } from '@commons';
import { ALink } from 'components/ALink';
import { Header } from 'components/Header';
import { showAddEstablishmentModal } from 'components/modals/AddEstablishmentModal';
import { UserSelect } from 'components/selects/UserSelect';
import {
  Box,
  Button,
  Container,
  Flex,
  Input,
  Itemize,
  Link,
  SpinnerBox,
  Tab,
  Table,
  TableColumn,
  TableItem,
  Text,
} from 'components/ui';
import { EyeIcon, RefreshIcon } from 'components/ui/icons';
import { format } from 'date-fns';
import { useSetCrumbs } from 'hooks/breadCrumb';
import { useDebounce } from 'hooks/useDebounce';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { transformText } from '@commons';
import { useCustomerEstablishmentListStore } from 'store';
import { Theme } from 'styles';
const tabItems = (
  <>
    <Tab variant="secondary" href={'/clients/establishments/active'}>
      Établissements actifs
    </Tab>
    <Tab variant="secondary" href={'/clients/establishments/closed'}>
      Établissements fermés
    </Tab>
  </>
);

const columns: TableColumn<Itemize<Partial<IEstablishmentMinifiedUsers>>>[] = [
  {
    key: 'businessName',
    text: 'RAISON SOCIALE',
    render: item =>
      transformText(getEstablishmentName(item, 100), 'capitalize') || '',
  },
  {
    key: 'country',
    text: 'PAYS',
    render: item => item.address?.country || '',
  },
  {
    key: 'id',
    text: 'IDENTIFICATION',
    render: item => item.siret || item.siren || item.identifier || '',
  },
  {
    key: 'activeSince',
    text: 'ACTIF DEPUIS LE',
    render: item => item.activeSince && format(item.activeSince, 'dd/MM/yyyy'),
  },
  {
    key: 'accountManager',
    text: 'CHARGÉ DE COMPTE',
    render: item =>
      (item.customer?.accountManager?.firstName || '') +
      ' ' +
      (item.customer?.accountManager?.lastName || ''),
  },
  {
    key: 'actions',
    text: 'ACTIONS',
    align: 'flex-end',
    render: (item: TableItem) => (
      <Link iconLeft={<EyeIcon />}>
        <ALink href={`/clients/establishments/${item.uuid}/information`}>
          Voir la fiche
        </ALink>
      </Link>
    ),
  },
];

export const ClientsEstabishmentsList = () => {
  const { filter: filterParams } = useParams<{ filter: string }>();
  const { me } = useMe();
  useSetCrumbs(
    [filterParams],
    [
      {
        label: 'clients',
        path: '/clients',
      },
      {
        label: 'établissements',
        path: '/clients/establishments',
      },
      {
        label: filterParams === 'active' ? 'actifs' : 'fermés',
        path: '/clients/establishments/' + filterParams,
      },
    ]
  );
  const {
    initialSate,
    order,
    sortedBy,
    searchQuery,
    managerSelected,
    page,
    updateCustomerEstablishmentListStore,
    resetCustomerEstablishmentListStore,
  } = useCustomerEstablishmentListStore();
  const sortString = (order === 'desc' ? '-' : '') + sortedBy;
  const debouncedFilterQuery = useDebounce(searchQuery, 500);

  const onSortedChange = async (
    sortedBy: string,
    order: 'desc' | 'asc'
  ): Promise<void> => {
    //@ts-ignore
    updateCustomerEstablishmentListStore({ sortedBy, order });
  };
  const {
    data: establishmentsQuery,
    isFetching,
    status,
  } = useEstablishmentFindMany({
    filterObject: {
      active: filterParams === 'active',
      customer: {
        $exists: true,
      },
      ...(managerSelected && {
        'customer.accountManager': checkDefaultManagerSelected(
          me,
          managerSelected
        )
          ? managerSelected
          : undefined,
      }),
      $or: [
        { businessName: { $regex: debouncedFilterQuery, $options: 'i' } },
        { tradeName: { $regex: debouncedFilterQuery, $options: 'i' } },
        { signBoard1: { $regex: debouncedFilterQuery, $options: 'i' } },
        { signBoard2: { $regex: debouncedFilterQuery, $options: 'i' } },
        { signBoard3: { $regex: debouncedFilterQuery, $options: 'i' } },
        { identifier: { $regex: debouncedFilterQuery, $options: 'i' } },
        { siret: { $regex: debouncedFilterQuery, $options: 'i' } },
        { siren: { $regex: debouncedFilterQuery, $options: 'i' } },
      ],
    },
    limit: 20,
    skip: page * 20,
    sort: sortString,
  });
  const totalCount = establishmentsQuery?.totalCount;
  const loading = status === 'pending';

  const items = establishmentsQuery?.establishments?.map(e => ({
    key: e.uuid,
    ...e,
  }));

  const onAddEstablishment = () => {
    showAddEstablishmentModal({ establishmentRole: 'CUSTOMER' });
  };
  useEffect(() => {
    if (me && !checkUserHasRole(me, ERoles.ACCOUNT_MANAGER)) {
      updateCustomerEstablishmentListStore({ managerSelected: undefined });
    }
  }, [me]);
  return (
    <Box>
      <Header
        tabs={tabItems}
        actions={
          <Button onClick={onAddEstablishment}>Ajouter un établissement</Button>
        }
      >
        <Text variant="h1">Établissements clients</Text>
      </Header>
      <Container>
        <Flex m="10px 0">
          <Box width={1 / 2}>
            <Input
              mr={20}
              isFullWidth
              data-cy="search-estaCustomer-list"
              type="search"
              name="search"
              placeholder="Rechercher ..."
              onChange={(e: any) => {
                updateCustomerEstablishmentListStore({
                  searchQuery: e?.target?.value,
                });
              }}
              value={searchQuery}
            />
          </Box>
          <Box width={1 / 2} ml={20}>
            <UserSelect
              placeholder="Tous les chargés de compte"
              role="ACCOUNT_MANAGER"
              onChange={id => {
                updateCustomerEstablishmentListStore({ managerSelected: id });
              }}
              value={managerSelected}
            />
          </Box>
          <Box ml={10}>
            <Flex
              justifyContent={'center'}
              alignItems={'center'}
              flexWrap="wrap"
              alignContent="center"
              width={1 / 1}
              height={'100%'}
            >
              <Link
                isDisabled={initialSate}
                iconLeft={
                  <RefreshIcon
                    fill={
                      initialSate ? Theme?.colors?.grey?.default : undefined
                    }
                  />
                }
                mt={'5px'}
                onClick={() =>
                  !initialSate ? resetCustomerEstablishmentListStore({}) : false
                }
              >
                Réinitialiser
              </Link>
            </Flex>
          </Box>
        </Flex>
        {loading && <SpinnerBox />}
        {!loading && (
          <Table
            columns={columns}
            items={items}
            sortedBy={sortedBy}
            order={order}
            page={page}
            total={totalCount}
            perPage={totalCount && totalCount < 20 ? totalCount : 20}
            onSortedChange={onSortedChange}
            onChangePage={page =>
              updateCustomerEstablishmentListStore({ page })
            }
            isLoading={isFetching}
          />
        )}
      </Container>
    </Box>
  );
};
