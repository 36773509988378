import { Box } from 'components/ui';
import React from 'react';
import { create } from 'react-modal-promise';
import { ModalFrame, ModalProps } from './ModalFrame';
import { AddICustomerRefPatternForm } from 'forms/AddICustomerRefPatternForm';

type Props = ModalProps & {
  customerRefPattern?: string;
  beforeValidation: (data?: string) => void;
};

export const AddICustomerRefPatternModal = ({
  onResolve,
  isOpen,
  customerRefPattern,
  beforeValidation,
}: Props) => {
  const handleSubmit = async (data: any) => {
    try {
      await beforeValidation(data);
      onResolve();
    } catch (e) {
      //
    }
  };
  return (
    <ModalFrame
      isOpen={isOpen}
      onClose={() => onResolve(false)}
      width={900}
      closeIcon
    >
      <Box>
        <AddICustomerRefPatternForm
          onClose={data => onResolve(data)}
          customerRefPattern={customerRefPattern}
          onSubmit={handleSubmit}
        />
      </Box>
    </ModalFrame>
  );
};

export const showAddICustomerRefPatternModal = create<Props>(
  AddICustomerRefPatternModal
);
