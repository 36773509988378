import { commentManage, useCommentFindMany } from '@commons';
import {
  ECommentActionList,
  EServicesCommentList,
  IJoinedComment,
} from '@freelancelabs/teoreme-commons';
import { CommentCard } from 'components/cards/CommentCard';
import { showDialogModal } from 'components/modals/DialogModal';
import { StaticSelect } from 'components/selects/StaticSelect';
import {
  Box,
  Button,
  Flex,
  FormControl,
  Link,
  Spinner,
  SpinnerBox,
  TextArea,
  FormLabel,
} from 'components/ui';
import { DotsIcon } from 'components/ui/icons';
import { useShowMessage } from 'hooks/useShowMessage';
import React from 'react';
import { queryClient } from '@commons';
type CommentsHandlerProps = {
  parentServiceUuid: String;
  maxWidth?: string;
  serviceName: EServicesCommentList;
};

export const CommentHandler: React.FC<
  React.PropsWithChildren<CommentsHandlerProps>
> = ({ parentServiceUuid, maxWidth, serviceName }) => {
  const [sortBy, setSortBy] = React.useState('-createdAt');
  const MAX_COUNT_COMMENT = 3;
  const showMessage = useShowMessage();
  const [isLoading, setIsLoading] = React.useState(false);
  const [newComment, setNewComment] = React.useState('');
  const [disableCreate, setDisableCreate] = React.useState(false);
  const [page, setPage] = React.useState(1);
  //@ts-ignore
  const { data, isFetching } = useCommentFindMany({
    filterObject: { parentServiceUuid },
    sort: sortBy,
    limit: page * MAX_COUNT_COMMENT,
    skip: 0,
  });

  const onSubmit = async () => {
    try {
      setIsLoading(true);
      await commentManage({
        action: ECommentActionList.ADD,
        parentServiceUuid: parentServiceUuid,
        content: newComment,
        serviceName: serviceName,
      });
      showMessage('success', 'Votre commentaire à bien été envoyé');
      setNewComment('');
      queryClient.refetchQueries({ queryKey: ['comments'], type: 'active' });
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    }
  };

  const onSubmitUpdateComment = async (comment: IJoinedComment) => {
    try {
      setIsLoading(true);
      await commentManage({
        action: ECommentActionList.UPDATE,
        commentUuid: comment?.uuid,
        content: comment?.content,
        serviceName: serviceName,
      });
      showMessage('success', 'Votre commentaire à bien été modifié');
      setNewComment('');
      queryClient.refetchQueries({ queryKey: ['comments'], type: 'active' });
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    }
  };
  const onDeleteComment = async (comment: IJoinedComment) => {
    await showDialogModal({
      title: 'Supprimer le commentaire',
      text: 'Êtes-vous sûr de vouloir supprimer ce commentaire ?',
      confirmLabel: 'Supprimer',
      cancelLabel: 'Ne pas Supprimer',
      beforeValidation: async () => {
        try {
          setIsLoading(true);
          await commentManage({
            action: ECommentActionList.DELETE,
            commentUuid: comment?.uuid,
          });
          showMessage('success', 'Votre commentaire à bien été supprimé');
          queryClient.refetchQueries({
            queryKey: ['comments'],
            type: 'active',
          });
          setIsLoading(false);
        } catch (e) {
          setIsLoading(false);
        }
      },
    });
  };
  const onLikeComment = async (comment: IJoinedComment) => {
    try {
      await commentManage({
        action: ECommentActionList.LIKE,
        commentUuid: comment?.uuid,
      });
      queryClient.refetchQueries({ queryKey: ['comments'], type: 'active' });
    } catch (e) {
      //
    }
  };
  const items = data?.comments?.map((comment: IJoinedComment) => ({
    key: comment?.uuid || '',
    ...comment,
  }));
  const hasMore = data?.comments?.length !== data?.totalCount;
  if (!data) {
    return <SpinnerBox />;
  }
  return (
    <Box maxWidth={maxWidth ? maxWidth : '700px'}>
      <Flex justifyContent="flex-start" mb={20}>
        <Box width={'300px'}>
          <FormLabel>Filtrer par</FormLabel>
          <StaticSelect
            isClearable={false}
            onChange={value => {
              setSortBy(value as string);
            }}
            value={sortBy}
            options={[
              { value: '-createdAt', label: "Les plus récents d'abord" },
              { value: 'createdAt', label: "Les plus anciens d'abord" },
            ]}
          />
        </Box>
      </Flex>
      <Flex display="inline-flex" flexWrap="wrap" width={1 / 1}>
        {!disableCreate && sortBy === '-createdAt' && (
          <Box width={1 / 1} mb={20}>
            <FormControl label="Votre commentaire">
              <TextArea
                placeholder={'Écrivez un commentaire ...'}
                minRows={4}
                onChange={e => setNewComment(e.target.value)}
                value={newComment}
              />
            </FormControl>
            <Button
              isLoading={isLoading}
              isDisabled={newComment?.length < 1}
              type="button"
              onClick={onSubmit}
            >
              Envoyer
            </Button>
          </Box>
        )}
        {items.map((comment: IJoinedComment & { key: string }) => (
          <Flex width={1 / 1} mb={10} flexDirection="row-reverse">
            <CommentCard
              comment={comment}
              onDisableCreate={(bool: boolean) => setDisableCreate(bool)}
              onUpdate={comment => onSubmitUpdateComment(comment)}
              onDelete={comment => onDeleteComment(comment)}
              onLike={comment => onLikeComment(comment)}
            />
          </Flex>
        ))}
      </Flex>
      <Flex justifyContent="center">
        {hasMore && !isFetching && (
          <Link onClick={() => setPage(page + 1)} iconLeft={<DotsIcon />}>
            voir plus
          </Link>
        )}
        {isFetching && <Spinner />}
      </Flex>
      {!disableCreate && sortBy === 'createdAt' && (
        <>
          <FormControl label="Votre commentaire">
            <TextArea
              placeholder={'Écrivez un commentaire ...'}
              minRows={4}
              onChange={e => setNewComment(e.target.value)}
              value={newComment}
            />
          </FormControl>
          <Button
            isLoading={isLoading}
            isDisabled={newComment?.length < 1}
            type="button"
            onClick={onSubmit}
          >
            Envoyer
          </Button>
        </>
      )}
    </Box>
  );
};

export default CommentHandler;
