import { Flex, ALink, Box, Link, SpinnerBox, Text } from 'components/ui';
import React, { useState } from 'react';
import { create } from 'react-modal-promise';
import { ModalFrame, ModalProps } from '../ModalFrame';
import { IJoinedMission } from '@freelancelabs/teoreme-commons';
import { DuplicateMissionForm } from 'forms/missions/DuplicateMissionForm';
// type FormValues = {
//   startAt?: Date;
//   endAt?: Date;
//   title?: string;
//   provider: {
//     establishment: String;
//   };
// };
type DuplicateMissionModalProps = ModalProps & {
  beforeValidation?: () => void;
  mission: IJoinedMission;
};

export const DuplicateMissionModal: React.FC<
  React.PropsWithChildren<DuplicateMissionModalProps>
> = ({ onResolve, isOpen, mission }) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loading, setLoading] = useState(false);

  // React.useEffect(() => {

  // }, []);
  return (
    <ModalFrame
      isOpen={isOpen}
      onClose={() => onResolve(false)}
      width={800}
      closeIcon
    >
      <Box>
        <Text variant="h2" mb={20}>
          <Flex>
            Dupliquer la mission{' '}
            <ALink
              href={`/delivery/missions/${mission?.reference}/gescom-create`}
              target="_blank"
            >
              <Link fontSize={18} ml={10}>
                {mission?.displayReference}
              </Link>
            </ALink>
          </Flex>
        </Text>

        {!loading ? (
          <DuplicateMissionForm
            mission={mission}
            onClose={() => onResolve(false)}
          />
        ) : (
          <SpinnerBox />
        )}
      </Box>
    </ModalFrame>
  );
};

export const showDuplicateMissionModal = create<DuplicateMissionModalProps>(
  DuplicateMissionModal
);
