import {
  EPurchaseOrderStatus,
  IJoinedPurchaseOrder,
} from '@freelancelabs/teoreme-commons';

export const canDisplayShowPurchaseOrderButton = (
  purchaseOrder: IJoinedPurchaseOrder
) => {
  const purchaseOrderStatus = purchaseOrder.status;
  return (
    purchaseOrderStatus === EPurchaseOrderStatus.REFUSED_BY_MANAGER ||
    purchaseOrderStatus === EPurchaseOrderStatus.REFUSED_BY_PROVIDER ||
    purchaseOrderStatus === EPurchaseOrderStatus.ARCHIVED
  );
};

export const canDisplayResendPurchaseOrderButton = (
  purchaseOrder: IJoinedPurchaseOrder
) => {
  const purchaseOrderStatus = purchaseOrder.status;

  return [EPurchaseOrderStatus.TO_SIGN, EPurchaseOrderStatus.SIGNED].includes(
    purchaseOrderStatus
  );
};
export const canDisplayCancelPurchaseOrderButton = (
  purchaseOrder: IJoinedPurchaseOrder
) => {
  const purchaseOrderStatus = purchaseOrder.status;

  return purchaseOrderStatus === EPurchaseOrderStatus.SIGNED;
};

export const canDisplayCountersignPurchaseOrderButton = (
  purchaseOrder: IJoinedPurchaseOrder
) => {
  const purchaseOrderStatus = purchaseOrder.status;

  return purchaseOrderStatus === EPurchaseOrderStatus.SIGNED;
};

export const canDisplayDeletePurchaseOrderButton = (
  purchaseOrder: IJoinedPurchaseOrder
) => {
  const purchaseOrderStatus = purchaseOrder.status;

  return purchaseOrderStatus === EPurchaseOrderStatus.TO_SIGN;
};

export const canDisplayArchivePurchaseButton = (
  purchaseOrder: IJoinedPurchaseOrder
) => {
  const purchaseOrderStatus = purchaseOrder.status;

  return (
    purchaseOrderStatus === EPurchaseOrderStatus.DONE ||
    purchaseOrderStatus === EPurchaseOrderStatus.SIGNED_IMPORT
  );
};

export const checkDisplayPurchaseOrderMenuList = (
  purchaseOrder: IJoinedPurchaseOrder
): boolean => {
  const purchaseOrderStatus = purchaseOrder.status;

  return (
    purchaseOrderStatus !== EPurchaseOrderStatus.ARCHIVED &&
    purchaseOrderStatus !== EPurchaseOrderStatus.REFUSED_BY_MANAGER &&
    purchaseOrderStatus !== EPurchaseOrderStatus.REFUSED_BY_PROVIDER
  );
};
