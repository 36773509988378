import { IJoinedMission, IMission } from '@freelancelabs/teoreme-commons';
import { Box, CheckSwitch, FormControl, Spinner, Text } from 'components/ui';
import { useSetCrumbs } from 'hooks/breadCrumb';
import { useGetMission } from 'hooks/missionHooks';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { CRAMissionList } from './List';
import { missionUpdateOne } from '@commons';
import { queryClient } from '@commons';

export const MissionCra = () => {
  type FormValues = {
    mission: IJoinedMission | IMission;
  };
  const { mission, reference } = useGetMission();
  const [loading, setLoading] = useState(false);
  const [isFreeDaysEnabled, SetIsFreeDaysEnabled] = useState(
    mission?.isFreeDaysEnabled ?? false
  );
  const { register } = useForm<FormValues>({
    defaultValues: {
      mission: mission,
    },
  });

  useSetCrumbs(
    [],
    [
      { label: 'prestations', path: '/delivery' },
      { label: 'missions', path: '/delivery/missions' },
      {
        label: mission?.displayReference || 'nouvelle mission',
        path: `/delivery/missions/${reference}`,
      },
      {
        label: 'comptes-rendus d’activité',
        path: `/delivery/missions/${reference}/cra`,
      },
    ]
  );
  const handleChange = async (checked: boolean) => {
    setLoading(true);
    try {
      SetIsFreeDaysEnabled(checked);
      await missionUpdateOne({
        reference: mission?.reference as string,
        mission: {
          //@ts-ignore
          isFreeDaysEnabled: checked,
        },
      });
      queryClient.refetchQueries({
        queryKey: [mission?.reference],
        type: 'active',
      });
      queryClient.refetchQueries({ queryKey: ['Cras'], type: 'active' });
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
    setLoading(false);
  };
  return (
    <Box>
      <Text variant="h2">Comptes-rendus d’activité</Text>
      <form>
        <Box mt={10}>
          <FormControl label="Jours de gratuité">
            <CheckSwitch
              id="isFreeDays"
              checked={isFreeDaysEnabled}
              {...register('mission.free')}
              onChange={e => handleChange(e?.target?.checked)}
            >
              <Text variant="span">
                {loading ? (
                  <Spinner />
                ) : isFreeDaysEnabled ? (
                  <>Permettre au fournisseur de saisir des jours de gratuité</>
                ) : (
                  <>
                    Ne pas permettre au fournisseur de saisir des jours de
                    gratuité
                  </>
                )}
              </Text>
            </CheckSwitch>
          </FormControl>
        </Box>
      </form>

      {mission && <CRAMissionList mission={mission as IJoinedMission} />}
    </Box>
  );
};
