import { Box } from 'components/ui/Box';
import { Flex } from 'components/ui/Flex';
import { ArrowUpIcon } from 'components/ui/icons';
import React from 'react';
import styled, { useTheme } from 'styled-components';
import 'styled-components/macro';

export const TableHead = styled.thead`
  background-color: ${props => props.theme.colors.primary.lighter};
`;
type SortOrder = 'asc' | 'desc';
type TableHeadCellProps = {
  children: any;
  align?: string;

  sortKey?: string;
  isSortColumn?: boolean;
  order?: SortOrder;
  onSortedChange?: (sortKey: string, order: SortOrder) => void;
  style?: any;
};
export const TableHeadCell = ({
  children,
  align = 'flex-start',
  sortKey,
  onSortedChange,
  order,
  isSortColumn,
  style,
}: TableHeadCellProps) => {
  const theme = useTheme();
  return (
    <td
      style={style}
      css={`
        height: 60px;
        font-size: 11px;
        font-weight: 600;
        color: ${theme.colors.primary.dark};
        cursor: ${sortKey ? 'pointer' : 'inherit'};
      `}
      onClick={() =>
        sortKey && onSortedChange?.(sortKey, order === 'asc' ? 'desc' : 'asc')
      }
    >
      <Flex p="0 10px" justifyContent={align} alignItems="center">
        {children}
        {sortKey && (
          <Box
            ml={10}
            fontSize={15}
            opacity={isSortColumn ? 1 : 0.5}
            style={{
              transition: '.2s',
              transform:
                (isSortColumn && order === 'desc' && 'rotate(180deg)') || '',
            }}
          >
            <ArrowUpIcon />
          </Box>
        )}
      </Flex>
    </td>
  );
};
