import { INVOICE_FOREIGN_SEARCH_FIELDS } from '@commons';
import { EInvoiceStatus, EInvoiceType } from '@freelancelabs/teoreme-commons';
import { create } from 'zustand';

enum sortType {
  ASC = 'asc',
  DESC = 'desc',
}
type MultiValueType = {
  label: string;
  value: string;
};
export type State = {
  initialSate: boolean;
  page: number;
  selectedStatus: EInvoiceStatus | 'ALL';
  selectedType: EInvoiceType | 'ALL';
  selectedPrestationType: any | 'ALL';
  selectedInvoiceType: any | 'ALL';
  startDate: Date | null;
  endDate: Date | null;
  order: sortType;
  sortedBy: string;
  searchQuery: string;
  accountManagerId?: string | number | null | undefined;
  searchInKeys: MultiValueType[];
  limit: number;
  updateInvoiceGlobalListStore: (state: Partial<State>) => void;
  resetInvoiceGlobalListStore: (state: Partial<State>) => void;
};

const initialStates = {
  initialSate: true,
  page: 0,
  selectedStatus: 'ALL' as const,
  selectedType: 'ALL' as const,
  selectedPrestationType: 'ALL' as const,
  selectedInvoiceType: 'ALL' as const,
  startDate: null,
  endDate: null,
  order: sortType.ASC,
  sortedBy: 'month',
  searchQuery: '',
  accountManagerId: undefined,
  searchInKeys: [INVOICE_FOREIGN_SEARCH_FIELDS?.[0]],
  limit: 20,
};

const store = (set: any) => ({
  ...initialStates,
  updateInvoiceGlobalListStore: (state: Partial<State>) =>
    set({ initialSate: false, page: 0, ...state }),
  resetInvoiceGlobalListStore: (state: Partial<State>) =>
    set({ ...initialStates, ...state }),
});

export const useInvoiceGlobalListStore = create<State>(store);
