import styled from 'styled-components';
import { boxProperties, BoxProps } from './Box';

export type CardProps = BoxProps & {
  isFullWidth?: boolean;
  isSelectable?: boolean;
  isSelected?: boolean;
};
export const Card = styled.div<CardProps>`
  border: 1px solid
    ${p => (p.isSelected ? p.theme.colors.blue : p.theme.colors.primary.light)};
  border-radius: 8px;
  ${props => props.isFullWidth && 'width : 100%;'};
  cursor: ${props => (props.isSelectable ? 'pointer' : 'initial')};
  ${boxProperties}
`;

export const CardBody = styled.div<BoxProps>`
  padding: 20px;
  ${boxProperties}
`;
