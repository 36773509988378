import React, { ReactNode, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { Box, boxProperties, BoxProps } from './Box';
import { FormInfo, FormLabel } from './Form';
import { Theme } from 'styles';

export const FileInputStyled = styled.div<
  { variant: 'area' | 'link'; error?: boolean } & BoxProps
>`
  ${p =>
    p.variant === 'link' &&
    css`
      display: inline-block;
    `}

  ${p =>
    p.variant === 'area' &&
    css`
      display: flex;
      border-radius: 8px;
      width: 100%;
      border: 1px dashed black;
      align-items: center;
      justify-content: center;
      background-color: ${p.backgroundColor
        ? p.backgroundColor
        : p.theme.colors.primary.lighter};
      height: 125px;
      ${p.error ? `border-color: ${p.theme.colors.red};` : ''}
    `}

  cursor : pointer;
  ${boxProperties}
`;

type FileInputProps = {
  label?: string;
  subLabel?: string;
  onSelect?: (files: File[]) => void;
  style?: React.CSSProperties;
  isLoading?: boolean;
  isDisabled?: boolean;
  children?: ReactNode;
  accept?: string;
  maxSize?: number;
  multiple?: boolean;
  required?: boolean;
  errorMessage?: string;
  variant?: 'area' | 'link';
} & BoxProps;
export const FileInput = ({
  variant = 'area',
  onSelect,
  children,

  label,
  subLabel,
  accept,
  maxSize,
  multiple = false,
  required = false,
  errorMessage,
  ...props
}: FileInputProps) => {
  const input = useRef<HTMLInputElement>(null);
  const [error, setError] = useState('');
  const handleSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    setError('');

    //@ts-ignore
    const files = [...e.currentTarget.files];
    //@ts-ignore
    if (files?.[0]?.type && files?.[0]?.type !== 'application/pdf') {
      setError('Veuillez fournir un document pdf');
    } else {
      if (maxSize && files.find(file => file.size > maxSize)) {
        setError('Vous avez dépassé la taille maximale autorisée');
        return;
      }
      //@ts-ignore
      onSelect?.([...e.currentTarget.files]);
    }
  };
  return (
    <Box display="flex" flexDirection="column" {...props}>
      {label && (
        <FormLabel>
          {label}&nbsp;
          {required && <span style={{ color: Theme.colors.blue }}>*</span>}
        </FormLabel>
      )}
      {subLabel && (
        <FormLabel style={{ fontWeight: 300 }}>{subLabel}</FormLabel>
      )}

      <FileInputStyled
        {...props}
        style={{ cursor: props.isDisabled ? 'not-allowed' : 'pointer' }}
        variant={variant}
        onClick={() =>
          //@ts-ignore
          onSelect === 'MAX_FILE'
            ? false
            : !props.isDisabled
              ? input?.current?.click()
              : false
        }
        error={!!error || !!errorMessage}
      >
        <input
          //@ts-ignore
          disabled={props.isDisabled}
          style={{ display: 'none' }}
          type="file"
          onChange={handleSelect}
          accept={accept}
          multiple={multiple}
          ref={input}
        />
        {children}
      </FileInputStyled>
      {error && <FormInfo variantColor="error">{error}</FormInfo>}
      {errorMessage && <FormInfo variantColor="error">{errorMessage}</FormInfo>}
    </Box>
  );
};
