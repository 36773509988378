import { UserSelect } from 'components/selects/UserSelect';
import { Box, Button, Link, Row, Text } from 'components/ui';
import { CancelIcon } from 'components/ui/icons';
import React from 'react';
import { create } from 'react-modal-promise';
import { ModalFrame, ModalProps } from './ModalFrame';
import { ConfirmNavigationModal } from 'components/modals/ConfirmNavigationModal';

type Props = ModalProps & {
  beforeValidation?: () => void;
  internalCommercial: string | undefined;
};

export const UpdateEstaInternalCommercial = ({
  onResolve,
  isOpen,
  internalCommercial,
}: Props) => {
  const [internalCommercialSelected, setInternalCommercialSelected] =
    React.useState(internalCommercial);

  return (
    <ModalFrame
      isOpen={isOpen}
      onClose={() => onResolve(false)}
      width={520}
      closeIcon
    >
      <ConfirmNavigationModal
        active={internalCommercial !== internalCommercialSelected}
      />
      <Box>
        <Text variant="h1" mb={30}>
          Modifier le commercial interne
        </Text>
        <Box>
          <UserSelect
            placeholder="Tous les contacts"
            role={'INTERNAL_COMMERCIAL'}
            onChange={value => setInternalCommercialSelected(value)}
            value={internalCommercialSelected}
          />
        </Box>
        <Row spacing={20} width="300px" mt={20}>
          <Button
            type="button"
            isDisabled={
              !internalCommercialSelected ||
              internalCommercial === internalCommercialSelected
            }
            onClick={() => onResolve(internalCommercialSelected)}
          >
            Enregistrer
          </Button>
          <Link
            iconLeft={<CancelIcon />}
            mt={'20px'}
            onClick={() => onResolve(false)}
          >
            Annuler
          </Link>
        </Row>
      </Box>
    </ModalFrame>
  );
};

export const showUpdateEstaInternalCommercial = create<Props>(
  UpdateEstaInternalCommercial
);
