import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ProviderTimeSpentList } from './Provider/TimeSpent/List';
import { EditTimeSpent as ProviderEditTimeSpent } from './Provider/TimeSpent/Details';
import { CustomerTimeSpentList } from './Customer/TimeSpent/List';
import { EditTimeSpent as CustomerEditTimeSpent } from './Customer/TimeSpent/Details';
import { CustomerCreditNoteList } from './Customer/CreditNotes/List';
import { ProviderCreditNoteList } from './Provider/CreditNotes/List';
export const Invoices = () => {
  return (
    <Switch>
      <Route exact path="/orders/invoices/provider">
        <Redirect to="/orders/invoices/provider/time-spent/awaiting" />
      </Route>
      <Route path="/orders/invoices/provider/time-spent/:filter(all|awaiting|validated|to-be-validated|archived|cancelled|paid)">
        <ProviderTimeSpentList />
      </Route>
      <Route path="/orders/invoices/provider/time-spent/:uuid">
        <ProviderEditTimeSpent />
      </Route>
      <Route path="/orders/invoices/provider/credit-note/:filter(all|validated|sent|archived|paid)">
        <ProviderCreditNoteList />
      </Route>
      <Route exact path="/orders/invoices/customer">
        <Redirect to="/orders/invoices/customer/time-spent/projected" />
      </Route>
      <Route path="/orders/invoices/customer/credit-note/:filter(all|validated|sent|archived)">
        <CustomerCreditNoteList />
      </Route>
      <Route path="/orders/invoices/customer/time-spent/:filter(all|projected|validated|sent|archived|paid|cancelled)">
        <CustomerTimeSpentList />
      </Route>
      <Route exact path="/orders/invoices/customer/time-spent/:uuid">
        <CustomerEditTimeSpent />
      </Route>
    </Switch>
  );
};
