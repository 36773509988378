import {
  ITraceSearchParams,
  ITraceChangeHistorySearchParams,
} from '@freelancelabs/teoreme-commons';
import { useQuery, keepPreviousData } from '@tanstack/react-query';
import { traceFindMany, changehistoryFindMany } from '../routes';

export const useTraceFindMany = (variables: ITraceSearchParams) => {
  return useQuery({
    queryKey: ['traces', variables],
    queryFn: async (...[key]: any) => traceFindMany(variables),
    enabled: variables && variables.filterObject ? true : false,
    placeholderData: keepPreviousData,
  });
};

export const useChangeHistoryFindMany = (
  variables: ITraceChangeHistorySearchParams,
  config?: any
): {
  data: any;
  error: any;
  status: any;
  isFetching: boolean;
  isPending: boolean;
} => {
  return useQuery({
    queryKey: ['changeHistories', variables],
    queryFn: async (...[key]: any) => changehistoryFindMany(variables),
    enabled: variables && variables.filterObject ? true : false,
    placeholderData: keepPreviousData,
    ...config,
  });
};
