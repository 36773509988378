import {
  FlatUser,
  IEstablishmentMinifiedUsers,
} from '@freelancelabs/teoreme-commons';
import {
  Box,
  Button,
  Flex,
  Text,
  FormControl,
  EstablishmentSelectControlled,
} from 'components/ui';
import React from 'react';
import { create } from 'react-modal-promise';
import { ModalFrame, ModalProps } from '../ModalFrame';
import { useForm } from 'react-hook-form';
import { useEstablishmentUpdateOne } from '@commons';
import { queryClient } from '@commons';
import { useShowMessage } from 'hooks/useShowMessage';

type Props = ModalProps & {
  beforeValidation?: () => void;
  contact: FlatUser;
  excludesEstablishments: IEstablishmentMinifiedUsers[];
};
type FormValues = {
  establishment: string;
};
export const LinkEstablishmentContactCustomerModal = ({
  onResolve,
  isOpen,
  contact,
  excludesEstablishments,
}: Props) => {
  const { handleSubmit, control, watch } = useForm<FormValues>({
    defaultValues: {},
  });

  const [loading, setLoading] = React.useState(false);
  const { mutateAsync: updateEstablishment } = useEstablishmentUpdateOne();
  const establishment = watch('establishment');
  const showMessage = useShowMessage();
  const onSubmit = async (formValues: FormValues) => {
    setLoading(true);
    try {
      const establishmentData = {
        uuid: establishment,
        addContacts: { customer: [contact?.cognitoUserId] },
      };
      await updateEstablishment(establishmentData as any);
      showMessage('success', "L'établissement à bien été rattaché");
      queryClient.refetchQueries({ queryKey: [contact?.cognitoUserId] });
      queryClient.refetchQueries({ queryKey: ['establishments'] });

      onResolve(true);
    } catch (err) {
      onResolve(false);
    }
    setLoading(false);
  };

  return (
    <ModalFrame
      isOpen={isOpen}
      onClose={() => onResolve(false)}
      width={550}
      closeIcon
    >
      <Box mt={20}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Text variant="h2" mb={20}>
            Rattacher un établissement client
          </Text>

          <FormControl label="Etablissement client" required>
            <Box width={'375px'} mt={20}>
              <EstablishmentSelectControlled
                control={control}
                name="establishment"
                filter={{
                  'customer.accountManager': { $exists: true },
                  uuid: { $nin: excludesEstablishments?.map(e => e?.uuid) },
                }}
                width="100%"
                referenceValue="uuid"
                placeholder="Rechercher un N° SIRET, raison sociale ..."
              />
            </Box>
          </FormControl>

          <Flex>
            <Button
              type="submit"
              isLoading={loading}
              isDisabled={!establishment}
            >
              Rattacher
            </Button>
          </Flex>
        </form>
      </Box>
    </ModalFrame>
  );
};

export const showLinkEstablishmentContactCustomerModal = create<Props, boolean>(
  LinkEstablishmentContactCustomerModal
);
