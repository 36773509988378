import * as React from 'react';

function SvgLinkIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
      <path
        stroke="currentColor"
        d="M10.28 10.995a3.946 3.946 0 014.641-.755h0l-.647.647a3.117 3.117 0 00-1.151-.216c-.864 0-1.62.324-2.23.936h0l-3.13 3.13a3.127 3.127 0 00-.936 2.23c0 .827.324 1.618.936 2.23.611.611 1.403.935 2.23.935s1.619-.324 2.23-.935h0l3.166-3.094c.9-.9 1.151-2.302.647-3.453h0l.648-.684c.324.576.468 1.223.468 1.87 0 1.08-.432 2.087-1.187 2.843h0l-3.13 3.13a3.985 3.985 0 01-5.648.035A4 4 0 016 17.002a4 4 0 011.187-2.841h0zm1.727-6.835c1.583-1.547 4.137-1.547 5.684 0a4 4 0 011.187 2.842c0 1.08-.431 2.086-1.187 2.842h0l-3.13 3.13c-1.223 1.259-3.13 1.51-4.64.755h0l.684-.648c.36.144.755.216 1.15.216.864 0 1.62-.324 2.231-.935h0l3.058-3.13c.611-.611.935-1.403.935-2.23s-.324-1.619-.935-2.23a3.132 3.132 0 00-2.23-.936 3.07 3.07 0 00-2.231.936h0l-3.13 3.13a3.155 3.155 0 00-.647 3.453h0l-.648.647c-.287-.575-.467-1.223-.467-1.87 0-1.08.431-2.087 1.187-2.842h0z"
      />
    </svg>
  );
}

export default SvgLinkIcon;
