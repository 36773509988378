import * as React from 'react';

function SvgCheckIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        d="M17.911 6.358l-8.688 8.689-3.134-3.134a1.223 1.223 0 00-1.731 1.728l4 4a1.222 1.222 0 001.73 0l9.554-9.554a1.222 1.222 0 10-1.731-1.728z"
      />
    </svg>
  );
}

export default SvgCheckIcon;
