import { FlatUser } from '@freelancelabs/teoreme-commons';
import {
  Box,
  Button,
  Flex,
  Text,
  FormControl,
  EstablishmentSelectControlled,
} from 'components/ui';
import React from 'react';
import { create } from 'react-modal-promise';
import { ModalFrame, ModalProps } from '../ModalFrame';
import { useForm } from 'react-hook-form';
import { useEstablishmentUpdateOne } from '@commons';
import { queryClient } from '@commons';

type Props = ModalProps & {
  beforeValidation?: () => void;
  contact: FlatUser;
};
type FormValues = {
  establishment: string;
};
export const LinkEstablishmentToContactProviderModal = ({
  onResolve,
  isOpen,
  contact,
}: Props) => {
  const { handleSubmit, control, watch } = useForm<FormValues>({
    defaultValues: {},
  });

  const [loading, setLoading] = React.useState(false);
  const { mutateAsync: updateEstablishment } = useEstablishmentUpdateOne();
  const establishment = watch('establishment');
  const onSubmit = async (formValues: FormValues) => {
    setLoading(true);
    try {
      const establishmentData = {
        uuid: establishment,
        addContacts: { provider: [contact?.cognitoUserId] },
      };
      await updateEstablishment(establishmentData as any);
      queryClient.refetchQueries({ queryKey: [contact?.cognitoUserId] });
      queryClient.refetchQueries({ queryKey: ['establishments'] });
      onResolve(true);
    } catch (err) {
      onResolve(false);
    }
    setLoading(false);
  };

  return (
    <ModalFrame
      isOpen={isOpen}
      onClose={() => onResolve(false)}
      width={550}
      closeIcon
    >
      <Box mt={20}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Text variant="h2" mb={20}>
            Rattacher un établissement fournisseur
          </Text>

          <FormControl label="Etablissement fournisseur" required>
            <Box width={'375px'} mt={20}>
              <EstablishmentSelectControlled
                id="contractor-establishment-select"
                data-cy="contractor-establishment-select"
                control={control}
                name="establishment"
                filter={{ 'provider.manager': { $exists: true } }}
                width="100%"
                referenceValue="uuid"
                placeholder="Rechercher un N° SIRET, raison sociale ..."
              />
            </Box>
          </FormControl>

          <Flex>
            <Button
              type="submit"
              isLoading={loading}
              isDisabled={!establishment}
            >
              Rattacher
            </Button>
          </Flex>
        </form>
      </Box>
    </ModalFrame>
  );
};

export const showLinkEstablishmentToContactProviderModal = create<
  Props,
  boolean
>(LinkEstablishmentToContactProviderModal);
