import { Box } from 'components/ui';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import React, { useState } from 'react';
//import { Editor, EditorState, RichUtils } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './TextEditor.css';
import {
  replaceAllFontsInTextEditor,
  replaceAllSizeInTextEditor,
} from '@commons';
type Props = {
  value?: string;
  onChange: (html: string) => void;
  options?: string[];
  formatToDocument?: boolean;
  isDisabled?: boolean;
};

export const TextEditor: React.FC<React.PropsWithChildren<Props>> = ({
  value = '',
  onChange,
  options,
  formatToDocument,
  isDisabled = false,
}) => {
  let valueFormated = replaceAllFontsInTextEditor(value);
  valueFormated = replaceAllSizeInTextEditor(value);
  const contentBlock = htmlToDraft(formatToDocument ? valueFormated : value);
  let editorValue;
  if (contentBlock) {
    const contentState = ContentState.createFromBlockArray(
      contentBlock.contentBlocks
    );
    editorValue = EditorState.createWithContent(contentState);
  }
  const [editorState, setEditorState] = useState(editorValue);
  const onEditorStateChange = (editorState: any) => {
    setEditorState(editorState);
    if (editorState) {
      let html = draftToHtml(convertToRaw(editorState.getCurrentContent()));
      if (formatToDocument) {
        html = replaceAllFontsInTextEditor(html);
        html = replaceAllSizeInTextEditor(html);
      }

      if (html?.length === 8) {
        onChange('');
      } else {
        onChange(html);
      }
    }
  };
  return (
    <Box>
      <Editor
        readOnly={isDisabled}
        stripPastedStyles={true}
        toolbar={{
          options: options
            ? options
            : [
                'inline',
                'blockType',
                //'fontSize',
                // 'fontFamily',
                'list', // 'textAlign',
                // 'colorPicker',
                // 'link',
                // 'embedded',
                // 'emoji',
                // 'image',
                // 'remove',
                // 'history',
              ],
        }}
        //@ts-ignore
        editorState={editorState}
        wrapperClassName="demo-wrapper"
        editorClassName="textEditor-editor"
        onEditorStateChange={onEditorStateChange}
      />
    </Box>
  );
};

export default TextEditor;
