import {
  Box,
  boxProperties,
  BoxProps,
  BreadCrumb,
  Container,
  Flex,
} from 'components/ui';
import { useBreadCrumb } from 'hooks/breadCrumb';
import React, { ReactNode } from 'react';
import styled from 'styled-components';

const HeaderStyled = styled.div<BoxProps>`
  width: 100%;
  position: relative;
  border-bottom: 1px solid ${props => props.theme.colors.lightBlue};
  background-color: ${props => props.theme.colors.lightGray};
  height: 170px;
  ${boxProperties}
  z-index:1;
`;
type HeaderProps = BoxProps & {
  actions?: ReactNode;
  children?: ReactNode;
  tabs?: ReactNode;
  pre?: ReactNode;
};

export const Header = ({
  actions,
  children,
  tabs,
  pre,
  ...props
}: HeaderProps) => {
  const { crumbs } = useBreadCrumb();
  return (
    <HeaderStyled {...props}>
      <Container height="100%" position="relative">
        <Flex alignItems="center" height="100%">
          <Box flex={1}>{children}</Box>
          <Box>{actions}</Box>
        </Flex>
        <Flex position="absolute" left={0} top={20}>
          <BreadCrumb crumbs={crumbs} />
        </Flex>
        <Flex position="absolute" left={0} bottom={0}>
          {tabs}
        </Flex>
      </Container>
    </HeaderStyled>
  );
};
