import React, { ReactNode } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { boxProperties, BoxProps } from './Box';

type TabProps = {
  isSelected?: boolean;
  variant?: 'primary' | 'secondary';
  href?: string; // Maybe to deprecate
  children?: ReactNode;
  onClick?: any;
} & BoxProps;

export const StyledTab = styled.div<TabProps>`
  display: flex;
  align-items: center;
  padding: 0 10px;

  text-decoration: none;
  line-height: normal;

  letter-spacing: normal;
  height: 80px;
  white-space: nowrap;
  color: ${props => props.theme.colors.primary.light};

  ${props =>
    props.variant === 'secondary' &&
    css`
      height: 60px;
    `}

  ${props =>
    props.variant === 'primary' &&
    css`
      font-weight: 600;
    `}

  ${props =>
    props.isSelected &&
    css`
      color: ${props.color || props.theme.colors.darkBlue};
      box-shadow: 0 -4px 0 0 ${props.theme.colors.blue} inset;
    `}

  &:hover {
    background-color: ${p => p.theme.colors.lightGray};
  }

  cursor: pointer;

  ${boxProperties}
`;

export const Tab = ({ href, isSelected, ...props }: TabProps) => {
  const history = useHistory();
  const { pathname } = useLocation();

  const onNavigate = () => !!href && history.push(href);

  const routeIsSelected = pathname.includes(href || '');

  return (
    <StyledTab
      onClick={onNavigate}
      isSelected={href ? routeIsSelected : isSelected}
      {...props}
    />
  );
};

Tab.defaultProps = {
  variant: 'primary',
};
