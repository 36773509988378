import {
  Box,
  Button,
  FileInput,
  Flex,
  FormControl,
  Input,
  Link,
  StaticSelectControlled,
  Text,
} from 'components/ui';
import {
  EXECUTIVE_CONTRACTS_SELECT,
  MANDAT_CONTRACTS_SELECT,
  generateContract,
  replaceContract,
  uploadFile,
} from '@commons';
import { showDisplayPdfModal } from 'components/modals/DisplayPdfModal';
import { AddIcon } from 'components/ui/icons';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { truncate } from 'lodash';
import {
  EContractType,
  EFileType,
  ELang,
  EContractTypeLabel,
} from '@freelancelabs/teoreme-commons';
import { useShowMessage } from 'hooks/useShowMessage';
import { queryClient } from '@commons';

type FormValues = {
  version?: string;
  createdAt: Date;
  type: string;
  lang: string;
  file: File;
};
type UpdateExecutiveContractFormProps = {
  defaultValues?: FormValues;
  onSubmit?: any; // TODO MAKE TYPES
  onClose: () => void;
};

export const UpdateExecutiveContractForm = ({
  defaultValues,
  onSubmit: onSubmitParent,
  onClose,
}: UpdateExecutiveContractFormProps) => {
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors, isDirty, isSubmitSuccessful },
    control,
    setError,
  } = useForm<FormValues>({
    defaultValues: { ...defaultValues },
  });
  const showMessage = useShowMessage();
  const [contractFile, setContractFile] = React.useState<File | null>(null);

  const handleContractFileChange = async (files: File[]) => {
    setContractFile(files[0]);
  };

  const onSubmit = async (formValues: FormValues) => {
    const type = formValues.type.replace(
      `_${formValues?.type?.split('_')?.pop()}`,
      ''
    );
    const lang = formValues.type.split('_').pop();
    const version = formValues?.version;
    // transform form values as requested
    if (contractFile) {
      setLoading(true);
      try {
        const uploadResponse = await uploadFile({
          file: contractFile,
          fileType: EFileType.TEMPORARY,
          establishment: '',
          actionType: 'upload',
        });

        if (!uploadResponse) return;

        const attachment = {
          fileLocation: uploadResponse.fileLocation,
          fileName: uploadResponse.fileName,
          eTag: uploadResponse.eTag,
        };
        let label = EContractTypeLabel?.GROUP;
        if (type?.includes('LOREAL')) {
          label = EContractTypeLabel?.MANDATE;
        }
        const genrateCC = await generateContract({
          isPreview: true,
          previewData: {
            attachment,
            lang,
            type,
            label: label,
          },
        });
        await showDisplayPdfModal({
          layer: 999,
          fileLocation: genrateCC.url,
          fileName: `${(genrateCC as any)?.fileName}`,
          confirmLabel: 'Valider',
          onConfirm: async () => {
            try {
              await replaceContract({
                attachment,
                version: version,
                type: type as EContractType,
                lang: lang as ELang,
                label: label,
              });
              showMessage('success', 'Le contrat à été mis à jour');
              queryClient.refetchQueries({
                queryKey: ['contracts-historical'],
              });
              onClose();
            } catch (e) {
              //
            }
          },
        });
      } catch (e) {
        //
      }
      setLoading(false);
    } else {
      //@ts-ignore
      setError('file', { message: 'Ce champ est requis' });
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
      <Text variant="h2" mb={20}>
        Mise à jour d'un contrat
      </Text>
      <FormControl label="Version" errorMessage={errors.version?.message}>
        <Input isFullWidth {...register('version')} />
      </FormControl>

      <FormControl
        label="TYPE DE CONTRAT"
        required
        //warningMessage="Merci de vérifier que le documents que vous allez télécharger ne contiennent ni page d’en tête, ni page de signature. Vous devez uniquement télécharger les articles concernant votre contrat. "
      >
        <StaticSelectControlled
          control={control}
          options={[
            ...EXECUTIVE_CONTRACTS_SELECT,
            ...MANDAT_CONTRACTS_SELECT,
          ].filter(
            (c: any) =>
              !c.value.includes(EContractType.OTHER) &&
              !c.value.includes(EContractType.SPECIFIC)
          )}
          name="type"
          placeholder="Type de contrat"
        />
      </FormControl>
      <Box mb={40}>
        <FileInput
          label="contrat"
          required
          accept=".pdf"
          subLabel="Format accepté : PDF"
          onSelect={handleContractFileChange}
          //@ts-ignore
          errorMessage={errors?.file?.message}
        >
          <Link iconLeft={<AddIcon />}>
            {truncate(contractFile?.name, {
              length: 40,
              omission: '....pdf',
            }) || 'Ajouter contrat'}
          </Link>
        </FileInput>
      </Box>
      <Flex>
        <Button
          type="submit"
          isLoading={loading}
          isDisabled={!isDirty && !isSubmitSuccessful}
        >
          Prévisualiser
        </Button>
        <Button ml="10px" variant="ghost" onClick={onClose}>
          Annuler
        </Button>
      </Flex>
    </form>
  );
};
