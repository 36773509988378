import {
  IUserLocal,
  IUserMinimal,
  establishmentLookupIdentifier,
  checkUserHasRole,
  getEstablishmentName,
  buildOneLineAddress,
  formatAllObject,
  getTradeNameSafe,
  userFindMany,
  escapeEmail,
  validateFormPhoneFormatFR,
} from '@commons';
import { useRegister } from '@commons'; // moved
import { format } from 'date-fns';
import { IEstablishment } from '@freelancelabs/teoreme-commons';
import { ERoles } from '@commons';
import { ContactExistsMessage } from 'components/ContactExistsMessage';
import { ConfirmNavigationModal } from 'components/modals/ConfirmNavigationModal';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Radio,
  Row,
  Text,
  PhoneInputControlled,
} from 'components/ui';
import { VerifyEmailForm } from 'forms/VerifyEmailForm';
import { VerifySiretForm } from 'forms/VerifySiretForm';
import React, { useState, ReactNode } from 'react';
import { useForm } from 'react-hook-form';
import { queryClient } from '@commons';

type FormValues = Partial<Omit<IUserLocal, 'civility'>> & {
  civility: string;
};
type ContactFormProps = {
  defaultValues?: Partial<IUserMinimal>;
  onSubmit?: (user: Partial<IUserMinimal>) => any | void;
  onClose: (newUser: IUserMinimal) => void;
};

export const AddBusinessPartnerForm = ({
  defaultValues,
  onSubmit: onSubmitParent,
  onClose,
}: ContactFormProps) => {
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isDirty, isSubmitSuccessful },
    setValue,
  } = useForm<FormValues>({
    defaultValues: {
      ...defaultValues,
      civility: defaultValues?.civility + '',
      establishment: null,
    },
  });
  const [establishment, setEstablishment] = useState<
    Partial<IEstablishment> | undefined
  >(undefined);
  const [onError, setOnError] = useState(false);
  const [previewError, setPreviewError] = useState<ReactNode | undefined>(
    undefined
  );
  const [user, setUser] = useState<Partial<IUserLocal> | undefined>(undefined);
  const [errorExist, setErrorExist] = useState(false);
  const [emailChecked, setEmailChecked] = useState(false);
  const { mutateAsync: addContact } = useRegister();

  const onCheckEmail = async ({ email }: { email?: string }) => {
    const values = await userFindMany({
      filterObject: {
        email: {
          $regex: `^${escapeEmail(email && email.trim())}$`,
          $options: 'i',
        },
      },
    });

    setEmailChecked(true);
    if (values?.users?.[0]) {
      const user = values.users[0];
      setUser(user);
      setValue('civility', String(user.civility));
      setValue('firstName', user.firstName);
      setValue('lastName', user.lastName);
      setValue('phone', user.phone);
      setValue('jobTitle', user.jobTitle);
      setErrorExist(checkUserHasRole(user, ERoles.BUSINESS_PARTNER));
    } else {
      setUser({ email });
    }
  };

  const onSubmit = async (formValues: FormValues) => {
    setLoading(true);
    if (establishment) {
      const submitValues = {
        ...formValues,
        email: user?.email,
        establishment,
      };
      try {
        const newUser = await addContact({
          user: formatAllObject(submitValues),
          roles: ERoles.BUSINESS_PARTNER,
        });
        if (newUser) {
          queryClient.refetchQueries({ queryKey: ['user'] });
          onClose(newUser);
        }
      } catch (e) {}
    } else {
      setOnError(true);
      setPreviewError('Ce champ est requis');
    }

    setLoading(false);
  };
  const onCheckIdentifier = async ({ identifier }: { identifier?: string }) => {
    setOnError(false);
    setPreviewError(undefined);
    if (!identifier) return;
    try {
      const establishment = await establishmentLookupIdentifier(identifier);
      if (!establishment.closedSince) {
        setEstablishment(establishment);
        setValue('establishment', establishment);
      } else {
        setOnError(true);
        setPreviewError(` L’établissement ${getTradeNameSafe(
          establishment
        )} est Fermé depuis le :
          ${format(establishment.closedSince, 'dd/MM/yyyy')}`);
      }
    } catch (err) {
      setPreviewError("Impossible de trouver l'établissement spécifié");
    }
  };
  const handleReset = () => {
    setUser(undefined);
    setEmailChecked(false);
    setErrorExist(false);
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <ConfirmNavigationModal active={isDirty && !isSubmitSuccessful} />
      <Text variant="h2" mb={10}>
        Ajouter un apporteur d'affaires
      </Text>
      <Row spacing={20} mb={20}>
        <VerifyEmailForm
          disableConfirm
          contactRole={ERoles.BUSINESS_PARTNER}
          onSubmit={onCheckEmail}
          isLocked={emailChecked}
          canModify
          onModify={handleReset}
        />
        <></>
      </Row>
      {user && (
        <Box mt={20}>
          <ContactExistsMessage
            contactRole={ERoles.BUSINESS_PARTNER}
            contact={user as IUserLocal}
          />
        </Box>
      )}
      {emailChecked && (
        <Box>
          <Box>
            <FormControl
              required
              label={'Civilité'}
              errorMessage={errors?.civility?.message}
            >
              <>
                <Radio
                  isDisabled={errorExist}
                  {...register('civility', { required: 'Ce champ est requis' })}
                  value="1"
                >
                  Monsieur
                </Radio>
                <Radio
                  isDisabled={errorExist}
                  {...register('civility', { required: 'Ce champ est requis' })}
                  value="2"
                >
                  Madame
                </Radio>
              </>
            </FormControl>
          </Box>
          <Row spacing={20}>
            <FormControl
              required
              label="Prénom"
              errorMessage={errors?.firstName?.message}
            >
              <Input
                isDisabled={errorExist}
                isFullWidth
                {...register('firstName', {
                  required: 'Ce champ est requis',
                })}
              />
            </FormControl>
            <FormControl
              required
              label="Nom"
              errorMessage={errors?.lastName?.message}
            >
              <Input
                isDisabled={errorExist}
                isFullWidth
                {...register('lastName', { required: 'Ce champ est requis' })}
              />
            </FormControl>
          </Row>
          <Row spacing={20}>
            <FormControl
              label="Téléphone"
              errorMessage={errors?.phone?.message}
            >
              <PhoneInputControlled
                disabled={errorExist}
                control={control}
                rules={{
                  validate: (value: string) => {
                    return validateFormPhoneFormatFR(value);
                  },
                }}
                isFullWidth
                name="phone"
              />
            </FormControl>
            <FormControl
              required={
                user?.jobTitle && user?.jobTitle.length > 0 ? true : false
              }
              label="Fonction"
              errorMessage={errors?.jobTitle?.message}
            >
              <Input
                isDisabled={errorExist}
                isFullWidth
                {...register('jobTitle', {
                  required:
                    user?.jobTitle && user?.jobTitle.length > 0
                      ? 'Ce champ est requis'
                      : undefined,
                })}
                name="jobTitle"
              />
            </FormControl>
          </Row>
          {!errorExist && (
            <Box width={1 / 1}>
              <Text variant="h3" mb={10}>
                Établissement
              </Text>
              <Row spacing={20} mb={20}>
                <VerifySiretForm
                  isFullWidth
                  onSubmit={onCheckIdentifier}
                  error={onError && previewError}
                  isLocked={!!establishment}
                  onUnlock={() => setEstablishment(undefined)}
                  establishmentRole={'ALL'}
                />
                <></>
              </Row>
              {establishment && (
                <Box mt={20}>
                  <FormLabel>Raison sociale</FormLabel>
                  <Text mb={10} mt={10} variant="p">
                    {establishment && getEstablishmentName(establishment)}
                  </Text>
                  <FormLabel>Adresse du siège sociale</FormLabel>
                  <Text mb={10} mt={10} variant="p">
                    {establishment &&
                      establishment.address &&
                      buildOneLineAddress(establishment.address)}
                  </Text>
                </Box>
              )}
            </Box>
          )}
          <Box>
            <Button type="submit" isLoading={loading} isDisabled={errorExist}>
              Enregistrer
            </Button>
          </Box>
        </Box>
      )}
    </form>
  );
};
