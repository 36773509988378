import {
  formatDate,
  getEstablishmentName,
  getFullName,
  getCustomerMileStoneAmount,
  FR_STATUS,
} from '@commons';
import {
  EadditionalActivityStatus,
  EMarginType,
  ETaskStatus,
  IJoinedAdditionalActivity,
} from '@freelancelabs/teoreme-commons';
import { Header } from 'components/Header';
import { MilestonList } from 'components/lists/MilestonList';
import {
  ALink,
  Box,
  Container,
  CustomToolTip,
  Flex,
  Link,
  Menu,
  MenuItem,
  Status,
  Tab,
  Text,
} from 'components/ui';
import {
  AddIcon,
  DotsIcon,
  EditIcon,
  EyeIcon,
  InfoIcon,
} from 'components/ui/icons';
import { getStatusData, taskTypeMapping } from 'helpers';
import { useSetCrumbs } from 'hooks/breadCrumb';
import { useParams } from 'react-router-dom';
import { useMilestonesGlobalListStore } from 'store';
import { MilestonesFiltersList } from './MilestonesFilters';
import { showCreateOrUpdateMileStoneModal } from 'components/modals/activities/CreateOrUpdateMileStoneModal';
import { showPatchAdditionalActivityModal } from 'components/modals/activities/PatchAdditionalActivityModal';
import { Theme } from 'styles';
import { snakeCase } from 'lodash';
import { SideBar } from 'components/ui/BootStrap/SideBar';
const Tabs = (
  <>
    <Tab variant="secondary" href={'/orders/milestones/all'}>
      Tous
    </Tab>
    <Tab variant="secondary" href={'/orders/milestones/to-be-validated'}>
      A valider
    </Tab>
    <Tab variant="secondary" href={'/orders/milestones/validated'}>
      Validés
    </Tab>
    <Tab variant="secondary" href={'/orders/milestones/rejected'}>
      Refusés
    </Tab>
  </>
);
export const MilestonesList = () => {
  const labelFilter = {
    all: 'Tous',
    'to-be-validated': 'A Valider',
    validated: 'Validés',
    rejected: 'Refusés',
  };
  const { filter: filterParams } = useParams<{
    filter: 'all' | 'to-be-validated' | 'validated' | 'rejected';
  }>();
  useSetCrumbs(
    [filterParams],
    [
      {
        label: 'Cra et Factures',
        path: '/orders',
      },
      {
        label: 'Jalons',
        path: '/orders/milestones',
      },
      {
        label: labelFilter[filterParams],
        path: `∏/orders/milestones/${filterParams}`,
      },
    ]
  );
  const getFilterObject = () => {
    return {
      status:
        filterParams !== 'all'
          ? snakeCase(filterParams).toLocaleUpperCase()
          : undefined,
    };
  };
  const columns = () => [
    {
      key: 'status',
      text: 'status',
      sortKey: 'status',
      render: (item: IJoinedAdditionalActivity) => {
        const statusData = getStatusData(item);
        return (
          <Box>
            <Status variantColor={statusData.variantColor}>
              {statusData.statusText}
            </Status>
          </Box>
        );
      },
    },
    {
      key: 'displayReference',
      sortKey: 'mission.displayReference',
      text: 'REF.MISSION',
      render: (item: IJoinedAdditionalActivity) => (
        <ALink
          href={`/delivery/missions/${item?.mission?.reference}/additional-activities`}
          target="_blank"
        >
          <Text>{item.mission.displayReference}</Text>
        </ALink>
      ),
    },
    {
      key: 'structure',
      sortKey: 'mission.sage.sucture',
      text: 'STRU',
      render: (item: IJoinedAdditionalActivity) =>
        item?.mission?.sage?.structure,
    },
    {
      key: 'submittedAt',
      text: 'RECU LE',
      sortKey: 'submittedAt',
      render: (item: IJoinedAdditionalActivity) => {
        const submitAt = item?.submittedAt || item?.statusChangedAt;
        const sumbitDate = submitAt ? new Date(submitAt) : undefined;
        return (
          <Box>
            <Text>{sumbitDate ? `${formatDate(sumbitDate)}` : 'N/A'}</Text>
          </Box>
        );
      },
    },
    {
      key: 'estCustomer',
      text: 'ÉTABLIS. CLIENT',
      sortKey: 'estCustomer.businessName',
      render: (item: IJoinedAdditionalActivity) => (
        <ALink
          href={`/clients/establishments/${item?.estCustomer?.uuid}`}
          target="_blank"
        >
          {getEstablishmentName(item.estCustomer)}
        </ALink>
      ),
    },
    {
      key: 'estProvider',
      text: 'ÉTABLIS. FOURN.',
      sortKey: 'estProvider.businessName',
      render: (item: IJoinedAdditionalActivity) => (
        <ALink
          href={`/providers/establishments/${item?.estProvider?.uuid}`}
          target="_blank"
        >
          {getEstablishmentName(item.estProvider)}
        </ALink>
      ),
    },
    {
      key: 'contractor',
      text: 'INTERVENANT',
      sortKey: 'contractor.firstName',
      render: (item: IJoinedAdditionalActivity) => (
        <ALink
          href={`/providers/contractors/${item?.contractor?.uuid}`}
          target="_blank"
        >
          {getFullName(item.contractor)}
        </ALink>
      ),
    },
    {
      key: 'milestoneAmount',
      text: 'MONTANT  HT',
      render: (item: IJoinedAdditionalActivity) => {
        return `${item?.milestoneAmount} €`;
      },
    },
    {
      key: 'totalAmount',
      text: 'MONTANT HT CLIENT.',
      render: (item: IJoinedAdditionalActivity) => {
        return `${getCustomerMileStoneAmount(
          item?.milestoneAmount || 0,
          item?.customerMargin || 0,
          item?.customerMarginType as EMarginType,
          true
        )} €`;
      },
    },
    {
      key: 'task',
      text: 'TÂCHES',
      render: (item: IJoinedAdditionalActivity) => {
        const displayedStatus = [ETaskStatus.ERROR, ETaskStatus.ONGOING];
        const getDisplayTask = () =>
          item?.tasks?.filter(task =>
            displayedStatus?.includes(task?.lastTaskStatus)
          );
        const tasks = getDisplayTask();
        if (tasks && tasks?.length > 0) {
          return (
            <CustomToolTip
              color={Theme?.colors?.warning?.default}
              text={
                <Box>
                  <ul>
                    {tasks?.map(task => (
                      <li>
                        {taskTypeMapping?.[task.taskType]}:&nbsp;
                        {FR_STATUS?.[task?.lastTaskStatus]}
                      </li>
                    ))}
                  </ul>
                </Box>
              }
              id={item?.uuid + '_task'}
            >
              <InfoIcon
                //onClick={() => showCraTaskDetails({ cra: item })}
                fill={Theme?.colors?.warning?.default}
                style={{ cursor: 'pointer' }}
              />
            </CustomToolTip>
          );
        } else {
          return 'N/A';
        }
      },
    },
    {
      key: 'actions',
      text: 'ACTIONS',
      render: (item: IJoinedAdditionalActivity) => {
        return (
          <Flex alignItems="center">
            <ALink
              href={`/orders/activities/flat-rate/${item?.mission?.reference}`}
            >
              <Link iconLeft={<EyeIcon />}></Link>
            </ALink>
            <Menu
              align="right"
              menuItems={
                <>
                  <MenuItem>
                    <Link
                      iconLeft={<EyeIcon />}
                      onClick={() =>
                        showCreateOrUpdateMileStoneModal({
                          method: 'UPDATE',
                          uuid: item?.uuid as string,
                          mission: item?.mission as any,
                        })
                      }
                    >
                      Voir le jalon
                    </Link>
                  </MenuItem>
                  {item.status === EadditionalActivityStatus.VALIDATED && (
                    <MenuItem>
                      <Link
                        onClick={() =>
                          showPatchAdditionalActivityModal({
                            additionalActivity: item,
                          })
                        }
                        iconLeft={<EditIcon />}
                      >
                        Corriger / Refuser
                      </Link>
                    </MenuItem>
                  )}
                </>
              }
            >
              <DotsIcon fill={Theme?.colors?.primary?.default} fontSize={20} />
            </Menu>
          </Flex>
        );
      },
    },
  ];

  return (
    <Box>
      <Header tabs={Tabs}>
        <Text variant="h1">Jalons</Text>
      </Header>
      <Container p="20px 0">
        <MilestonList
          filterComponents={
            <Flex alignItems={'center'}>
              <MilestonesFiltersList
                filterParams={filterParams}
                filterObject={getFilterObject()}
                hookStore={useMilestonesGlobalListStore}
                minified
                sideBar={
                  <SideBar
                    title="Filtres des jalons"
                    customLinkIcon={<AddIcon />}
                    buttonType="link"
                    buttonText="Plus de filtres"
                  >
                    <MilestonesFiltersList
                      filterParams={filterParams}
                      row={1 / 1}
                      hookStore={useMilestonesGlobalListStore}
                      filterObject={getFilterObject()}
                    />
                  </SideBar>
                }
              />
            </Flex>
          }
          hookStore={useMilestonesGlobalListStore}
          filterObject={{
            status:
              filterParams !== 'all'
                ? snakeCase(filterParams).toLocaleUpperCase()
                : undefined,
          }}
          columns={columns}
        />
      </Container>
    </Box>
  );
};
