import React, { useEffect, useRef, useState } from 'react';
import { getDocument, GlobalWorkerOptions, version } from 'pdfjs-dist';
import { Box, Flex, Link } from 'components/ui';
import { ChevronLeftIcon, ChevronRightIcon } from 'components/ui/icons';

const srcVersion = `https://cdn.jsdelivr.net/npm/pdfjs-dist@${version}/build/pdf.worker.min.mjs`;
GlobalWorkerOptions.workerSrc = new URL(
  // 'pdfjs-dist/build/pdf.worker.min.mjs',
  srcVersion,
  import.meta.url
).toString();

type ViewerData = {
  numPages?: number;
  page?: number;
  singlePageView?: boolean;
  headerClientRect?: any;
  footerClientRect?: any;
};

const PdfViewer = ({
  pdfUrl,
  onPageChange,
  onScrolling,
  afterLoad,
}: {
  pdfUrl: any;
  onPageChange?: (page: number) => void;
  onScrolling?: (page: number) => void;
  afterLoad?: (pageDate: any) => void;
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const paginationRef = useRef<HTMLDivElement>(null);
  const footerRef = useRef<HTMLDivElement>(null);
  const [pdf, setPdf] = useState<any>(null);
  const [page, setPage] = useState<number>(1);
  const [numPages, setNumPages] = useState<number>(0);
  const [singlePageView, setSinglePageView] = useState<boolean>(true);

  useEffect(() => {
    const loadPdf = async () => {
      const loadingTask = getDocument(pdfUrl);
      const loadedPdf = await loadingTask.promise;
      setPdf(loadedPdf);
      setNumPages(loadedPdf.numPages);
      const paginationContainerRect =
        paginationRef?.current?.getBoundingClientRect() || undefined;
      const footerContainerRect =
        footerRef?.current?.getBoundingClientRect() || undefined;
      const viewerInfos: ViewerData = {
        headerClientRect: paginationContainerRect,
        footerClientRect: footerContainerRect,
      };
      afterLoad && afterLoad(viewerInfos);
    };

    loadPdf();
  }, [pdfUrl]);

  useEffect(() => {
    if (!pdf) return;

    const renderPage = async (pageNumber: number) => {
      const page = await pdf.getPage(pageNumber);
      const viewport = page.getViewport({ scale: 1 });
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
      canvas.height = viewport.height;
      canvas.width = viewport.width;
      await page.render({ canvasContext: context, viewport }).promise;
      return canvas;
    };

    const renderSinglePage = async () => {
      if (!pdf || !containerRef.current) return;

      containerRef.current.innerHTML = ''; // Clear previous page
      const canvas = await renderPage(page);
      containerRef.current.appendChild(canvas);
    };

    const renderAllPages = async () => {
      if (!pdf || !containerRef.current) return;

      containerRef.current.innerHTML = ''; // Clear previous pages
      for (let i = 1; i <= numPages; i++) {
        const canvas = await renderPage(i);
        containerRef.current.appendChild(canvas);
      }
    };

    if (singlePageView) {
      renderSinglePage();
    } else {
      renderAllPages();
    }
  }, [pdf, numPages, page, singlePageView]);

  const handleNextPage = () => {
    const currentPage = Math.min(page + 1, numPages);
    setPage(currentPage);
    if (onPageChange) onPageChange(currentPage);
  };

  const handlePreviousPage = () => {
    const currentPage = Math.max(page - 1, 1);
    setPage(currentPage);
    if (onPageChange) onPageChange(currentPage);
  };

  const toggleView = () => {
    setSinglePageView(prevView => !prevView);
  };

  useEffect(() => {
    // if (!singlePageView) {
    const handleScroll = () => {
      if (!pdf || !containerRef.current) return;

      const container = containerRef.current;
      const paginationContainer =
        paginationRef?.current?.getBoundingClientRect()?.height || 0;

      const scrollTop = container.scrollTop;
      // - paginationContainer; // Add pagination container height to have the correct scroll position from top viewer
      let currentPage = 1;
      let accumulatedHeight = 0;

      if (!singlePageView) {
        const promises = Array.from({ length: numPages }, (_, i) =>
          pdf.getPage(i + 1).then((page: any) => {
            const viewport = page.getViewport({ scale: 1 });
            const pageHeight = viewport.height;

            if (
              scrollTop >= accumulatedHeight &&
              scrollTop < accumulatedHeight + pageHeight
            ) {
              currentPage = i + 1;
            }

            accumulatedHeight += pageHeight;
          })
        );

        Promise.all(promises).then(() => {
          if (currentPage !== page) {
            setPage(currentPage);
            if (onPageChange) onPageChange(currentPage);
          }
        });
      }
      onScrolling && onScrolling(scrollTop);
    };
    if (pdf || containerRef.current) {
      const container = containerRef.current;
      // @ts-ignore
      container.addEventListener('scroll', handleScroll);

      return () => {
        container?.removeEventListener('scroll', handleScroll);
      };
    }

    // }
    // containerRef?.current?.addEventListener('scroll', () => {
    //   if (onScrolling) onScrolling(containerRef.current?.scrollTop);
    // })
  }, [pdf, numPages, onPageChange, page, singlePageView]);

  return (
    <div>
      <div ref={paginationRef}>
        <Flex
          alignContent={'space-between'}
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          <Box>
            <Link
              mr={30}
              ml={20}
              iconLeft={
                <ChevronLeftIcon height={15} style={{ marginTop: 7 }} />
              }
              onClick={() => handlePreviousPage()}
            >
              PAGE PRÉCÉDENTE
            </Link>
          </Box>
          <Box>
            <Link
              mr={30}
              ml={20}
              iconLeft={
                <ChevronRightIcon height={15} style={{ marginTop: 7 }} />
              }
              onClick={() => handleNextPage()}
            >
              PAGE SUIVANTE
            </Link>
          </Box>
        </Flex>
        {/* <button onClick={toggleView}>
          {singlePageView ? 'View All Pages' : 'View Single Page'}
        </button> */}
      </div>
      <div
        ref={containerRef}
        style={{ width: '100%', height: '70cqb', overflowY: 'scroll' }}
      ></div>
      <div ref={footerRef} style={{ textAlign: 'center' }}>
        PAGE : {page} / {numPages}
      </div>
    </div>
  );
};

export default PdfViewer;
