import { EInvoiceStatus } from '@freelancelabs/teoreme-commons';
import { Flex, Box, FormLabel, Input, Link } from 'components/ui';
import {
  INVOICE_STATUS_SELECT,
  INVOICE_PRESTATION_TYPE_SELECT,
  getPrestationInvoiceType,
} from '@commons';
import { StaticSelect } from 'components/selects/StaticSelect';
import { RefreshIcon } from 'components/ui/icons';
import { Theme } from 'styles';
import { useEffect } from 'react';

const INVOICE_STATUS_SELECT_CUSTOM = INVOICE_STATUS_SELECT;
const Findex = INVOICE_STATUS_SELECT.findIndex(
  state => state.key === EInvoiceStatus.REJECTED
);
INVOICE_STATUS_SELECT_CUSTOM[Findex].label = 'Refusé';

const factureAvoir = [
  {
    label: 'Tous les types',
    value: 'ALL',
    key: 'ALL',
  },
  {
    label: `Facture`,
    value: 'FACTURE',
    key: 'FACTURE',
  },
  {
    label: `Avoir`,
    value: 'AVOIR',
    key: 'AVOIR',
  },
];

type InvoicesFilersListProps = {
  hookStore: any;
};
export const InvoicesFilersList = ({ hookStore }: InvoicesFilersListProps) => {
  const {
    initialSate,
    updateInvoiceGlobalListStore,
    resetInvoiceGlobalListStore,
    selectedStatus,
    selectedPrestationType,
    selectedInvoiceType,
    searchQuery,
    limit,
  } = hookStore();

  useEffect(() => {
    const resultInvoiceTypes = getPrestationInvoiceType(
      selectedPrestationType,
      selectedInvoiceType,
      'PROVIDER'
    );

    updateInvoiceGlobalListStore({ selectedType: { $in: resultInvoiceTypes } });
  }, [selectedPrestationType, selectedInvoiceType]);

  return (
    <Box>
      <Flex m="10px 0">
        <Box width={1 / 4} maxWidth={200}>
          <FormLabel>Rechercher</FormLabel>
          <Input
            mr={20}
            isFullWidth
            data-cy="search-estaCustomer-list"
            type="search"
            name="search"
            placeholder="Rechercher ..."
            value={searchQuery}
            onChange={(e: any) => {
              updateInvoiceGlobalListStore({
                page: 0,
                searchQuery: e.target.value,
              });
            }}
          />
        </Box>

        <Box width={1 / 4} maxWidth={200} ml={20}>
          <FormLabel>Statuts</FormLabel>
          <StaticSelect
            width={1 / 1}
            options={INVOICE_STATUS_SELECT_CUSTOM}
            value={selectedStatus}
            placeholder="Tous les statuts"
            onChange={value =>
              updateInvoiceGlobalListStore({
                selectedStatus: value as 'ALL' | EInvoiceStatus | undefined,
                page: 0,
              })
            }
          />
        </Box>
        <Box width={1 / 4} maxWidth={200} ml={20}>
          <FormLabel>Type de prestation</FormLabel>
          <StaticSelect
            width={1 / 1}
            options={INVOICE_PRESTATION_TYPE_SELECT}
            value={selectedPrestationType}
            placeholder="Tous les types"
            onChange={value =>
              updateInvoiceGlobalListStore({
                selectedPrestationType: value as 'ALL' | any | undefined,
                page: 0,
              })
            }
          />
        </Box>

        {/* <Box width={1 / 8} ml={20}>
          <StaticSelect
            placeholder="Tous les types"
            options={INVOICE_PROVIDER_TYPE_SELECT}
            onChange={value =>
              updateInvoiceGlobalListStore({
                selectedType: value as EInvoiceType,
              })
            }
            value={selectedType}
          />
        </Box> */}
        <Box width={1 / 8} ml={20}>
          <FormLabel>Type de facture</FormLabel>
          <StaticSelect
            placeholder="Tous les types"
            options={factureAvoir}
            onChange={value => {
              updateInvoiceGlobalListStore({
                selectedInvoiceType: value,
              });
            }}
            value={selectedInvoiceType}
          />
        </Box>
        <Box width={1 / 8} maxWidth={150} ml={20}>
          <FormLabel>NB. LIGNES AFFICHÉES</FormLabel>
          <StaticSelect
            isClearable={false}
            placeholder=" "
            options={[
              // {
              //   label: '5',
              //   value: 5,
              //   key: 5,
              // },
              {
                label: '20',
                value: 20,
                key: 20,
              },
              {
                label: '50',
                value: 50,
                key: 50,
              },
              {
                label: '100',
                value: 100,
                key: 100,
              },
            ]}
            onChange={value =>
              updateInvoiceGlobalListStore({ limit: value as number, page: 0 })
            }
            value={limit}
          />
        </Box>
        <Flex width={1 / 4} alignItems="center" ml={20}>
          <Link
            isDisabled={initialSate}
            iconLeft={
              <RefreshIcon
                fill={initialSate ? Theme?.colors?.grey?.default : undefined}
              />
            }
            mt={15}
            onClick={() =>
              !initialSate ? resetInvoiceGlobalListStore({}) : false
            }
          >
            Réinitialiser
          </Link>
        </Flex>
      </Flex>
    </Box>
  );
};
