import React from 'react';
import { Form, Button } from 'components/ui';
import { Section } from 'components/Section';
import { ConfirmNavigationModal } from 'components/modals/ConfirmNavigationModal';
import { create } from 'react-modal-promise';
import { ModalFrame, ModalProps } from '../ModalFrame';
import { useShowMessage } from 'hooks/useShowMessage';
import { useForm } from 'react-hook-form';
import { missionCreateOne, useMe, removeOffsetDate } from '@commons';
import {
  IJoinedMission,
  IAddress,
  IBilling,
  IBillingInformation,
  IUpdateMissionData,
  EMissionType,
} from '@freelancelabs/teoreme-commons';
import { MissionGescomPartialForm } from 'forms/partials/MissionGescomPartialForm';
import { useHistory } from 'react-router';
import { CreateMissionFromSfOppForm } from 'forms/missions/CreateMissionFromSfOppForm';
type FormValues = {
  shouldCreatePastCra: boolean;
  startAt: Date;
  endAt: Date;
  title: string;
  location?: IAddress;
  description?: string;
  billing: IBilling;
  billingInformation?: IBillingInformation;
  estimatedEndAt?: Date;
  structure: any;
  accountManager?: any;
};
type CreateMissionModalProps = ModalProps & {
  beforeValidation?: () => void;
  mission: IJoinedMission;
};

export const CreateMissionModal: React.FC<
  React.PropsWithChildren<CreateMissionModalProps>
> = ({ onResolve, isOpen }) => {
  const { me } = useMe();
  const form = useForm<FormValues>({
    defaultValues: {
      accountManager: me?.cognitoUserId,
    },
  });
  const {
    handleSubmit,
    formState: { isDirty, isSubmitSuccessful },
    setError,
    watch,
  } = form;
  const [isLoading, setIsLoading] = React.useState(false);
  const [ignoreNav, setIgnoreNav] = React.useState(false);
  const showMessage = useShowMessage();

  const history = useHistory();
  const onSubmit = async (data: IUpdateMissionData) => {
    setIsLoading(true);
    setIgnoreNav(true);
    // const mission = data;

    try {
      if (data?.accountManager) {
        // Type Guard
        if (!me?.cognitoUserId) return;
        const newMission = await missionCreateOne({
          // @ts-ignore
          mission: {
            accountManager: me?.cognitoUserId,
            ...data,
            startAt: removeOffsetDate(data?.startAt as unknown as Date),
            endAt: removeOffsetDate(data?.endAt as unknown as Date),
          },
        });
        const reference = newMission.reference;
        // await missionUpdateOne({
        //   reference,
        //   mission,
        // });
        showMessage(
          'success',
          'Vos modifications ont bien été enregistrées et prises en compte dans GESCOM.'
        );
        history.push(`/delivery/missions/${reference}/gescom-create`);
      } else {
        //@ts-ignore
        setError('accountManager', { message: 'Ce champs est requis' });
      }
    } catch (e) {
      showMessage(
        'error',
        'Vos modifications n’ont pas pu être prises en compte dans GESCOM et n’ont pas été enregistrées dans votre fiche mission. Merci de réessayer'
      );
      setIsLoading(false);
    }

    setIsLoading(false);
  };
  const title = watch('title');
  const endAt = watch('endAt');
  const startAt = watch('startAt');
  const structure = watch('billingInformation.structure');
  const label = watch('billingInformation.missionType.label');
  const isDisableGescomButton = () => {
    if (title && endAt && startAt && structure && label) {
      return false;
    }
    return true;
  };
  // SF EXPECTION FOR TA !! https://fcomdev.atlassian.net/browse/TEOR-5738
  return (
    <ModalFrame
      isOpen={isOpen}
      onClose={() => onResolve(false)}
      width={800}
      closeIcon
    >
      <Form
        onSubmit={handleSubmit(onSubmit)}
        mb={label === EMissionType.RESOURCE_MANAGEMENT ? 20 : 0}
        autoComplete="off"
        data-cy="mission-form"
      >
        {label === EMissionType.RESOURCE_MANAGEMENT && (
          <ConfirmNavigationModal
            active={isDirty && ignoreNav === false && !isSubmitSuccessful}
          />
        )}
        <Section
          mb={label === EMissionType.RESOURCE_MANAGEMENT ? 40 : 0}
          variant="h2"
          title="Informations de la mission"
        >
          <MissionGescomPartialForm
            form={form}
            forRole="ALL"
            setIgnoreNav={() => setIgnoreNav(true)}
            isDisabled={false}
          />
        </Section>
        {label === EMissionType.RESOURCE_MANAGEMENT && (
          <Button
            variant="filled"
            type="submit"
            isDisabled={isDisableGescomButton()}
            isLoading={isLoading}
            data-cy="save-gescom"
          >
            Enregistrer et mettre à jour dans GESCOM
          </Button>
        )}
      </Form>
      {label === EMissionType.CLASSIC && <CreateMissionFromSfOppForm />}
    </ModalFrame>
  );
};

export const showCreateMissionModal =
  create<CreateMissionModalProps>(CreateMissionModal);
