import { TopBar } from 'components/TopBar';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { DocumentsSupport } from './Documents';

export const SupportRoute = () => {
  return (
    <>
      <TopBar />
      <Switch>
        <Route exact path="/support">
          <Redirect to="/support/documents/provider" />
        </Route>
        <Route exact path="/support/contracts">
          <Redirect to="/support/documents/provider" />
        </Route>
        <Route path="/support/documents/:filter(provider|manager)">
          <DocumentsSupport />
        </Route>
      </Switch>
    </>
  );
};

export default SupportRoute;
