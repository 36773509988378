import { IUserLocal, IUserMinimal, useUserUpdateOne } from '@commons';
import { FlatUser } from '@freelancelabs/teoreme-commons';
import { showUpdatePasswordModal } from 'components/modals/manager/UpdatePassword';
import {
  Box,
  Button,
  FormControl,
  Input,
  Link,
  Radio,
  Text,
} from 'components/ui';
import { useShowMessage } from 'hooks/useShowMessage';

import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { queryClient } from '@commons';

type FormValues = Partial<Omit<IUserLocal, 'civility'>> & {
  civility: string;
};
type ManagerDetailsFormProps = {
  defaultValues?: Partial<IUserMinimal>;
  onSubmit?: (user: Partial<IUserMinimal>) => any | void;
};

export const ManagerDetailsForm = ({
  defaultValues,
  onSubmit: onSubmitParent,
}: ManagerDetailsFormProps) => {
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {
      ...defaultValues,
      civility: String(defaultValues?.civility),
    },
  });
  const showMessage = useShowMessage();
  const { mutateAsync: updateUser } = useUserUpdateOne();
  const onSubmit = async (formValues: FormValues) => {
    setLoading(true);
    try {
      await updateUser({
        id: defaultValues?.cognitoUserId as string,
        user: {
          //@ts-ignore
          civility: formValues.civility,
          firstName: formValues.firstName,
          lastName: formValues.lastName,
        },
      });
      queryClient.refetchQueries({ queryKey: ['me'] });
      showMessage('success', 'Les informations ont été mises à jour');
    } catch (e) {}
    setLoading(false);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box>
        <FormControl label="Email">
          <Box>
            <Input isFullWidth type="email" {...register('email')} isDisabled />
            <Link
              mt={10}
              onClick={() =>
                showUpdatePasswordModal({ user: defaultValues as FlatUser })
              }
            >
              Modifier votre mot de passe
            </Link>
          </Box>
        </FormControl>
        <Text mt={40} variant="h2">
          Information générales
        </Text>
        <Box>
          <FormControl
            label={'Civilité'}
            errorMessage={errors?.civility?.message}
          >
            <>
              <Radio {...register('civility')} value="1">
                Monsieur
              </Radio>
              <Radio {...register('civility')} value="2">
                Madame
              </Radio>
            </>
          </FormControl>
          <FormControl
            required
            label="Prénom"
            errorMessage={errors?.firstName?.message}
          >
            <Input
              isFullWidth
              type="text"
              {...register('firstName', { required: 'Ce champs est requis' })}
            />
          </FormControl>
          <FormControl
            required
            label="Nom"
            errorMessage={errors?.lastName?.message}
          >
            <Input
              isFullWidth
              type="text"
              {...register('lastName', { required: 'Ce champs est requis' })}
            />
          </FormControl>
        </Box>
        <Button type="submit" isLoading={loading} isDisabled={loading}>
          Enregistrer
        </Button>
      </Box>
    </form>
  );
};
