import {
  EContractLabel,
  EFileType,
  EContractType,
  ELang,
} from '@freelancelabs/teoreme-commons';
import { createContract, removeOffsetDate } from '@commons';
import { uploadFile } from '@commons';
import { Box, SpinnerBox } from 'components/ui';
import { ImportExecutiveContractForm } from 'forms/ImportExecutiveContractForm';
import { useShowMessage } from 'hooks/useShowMessage';
import React from 'react';
import { create } from 'react-modal-promise';
import { queryClient } from '@commons';
import { ModalFrame, ModalProps } from './ModalFrame';
import { useEstablishmentFindOne } from '@commons';

type Props = ModalProps & {
  beforeValidation?: () => void;
  establishmentUuid: string;
  refOrder?: string;
  contractLabel: EContractLabel;
};
type FormValues = {
  refContract: string;
  createdAt: Date;
  label: EContractLabel;
  type: string;
  lang: string;
  file: File;
};

export const ImportExecutiveContractModal = ({
  onResolve,
  isOpen,
  establishmentUuid,
  refOrder,
  contractLabel,
}: Props) => {
  const showMessage = useShowMessage();
  const { data: establishment, status } =
    useEstablishmentFindOne(establishmentUuid);

  const handleSubmit = async (formData: FormValues) => {
    if (!establishment) return null;
    //Timezone SAGE USE SIMPLE DATE WITHOUT HOURS
    const signedAt = removeOffsetDate(formData?.createdAt);
    try {
      const uploadResponse = await uploadFile({
        file: formData.file,
        fileType:
          formData.label === EContractLabel?.EXECUTIVE
            ? EFileType.EXECUTIVE
            : formData.label === EContractLabel?.MANDATE
              ? EFileType.MANDATE
              : EFileType.TRAINING,
        establishment: establishment?.uuid || '',
        actionType: 'upload',
      });
      if (uploadResponse) {
        const cognitoUserId = localStorage?.getItem('cognitoUserId');
        await createContract({
          contract: {
            refOrder: refOrder,
            accountManager: cognitoUserId?.toString(), //establishment.provider.manager.cognitoUserId,,
            attachment: {
              eTag: uploadResponse.eTag,
              fileLocation: uploadResponse.fileLocation,
              fileName: uploadResponse.fileName,
            },
            signedAt: signedAt,
            establishment: establishment.uuid,
            label: formData.label,
            refContract: formData.refContract,
            type: formData.type as EContractType,
            lang: formData.lang as ELang,
          },
        });
        queryClient.refetchQueries({ queryKey: ['contracts'], type: 'active' });
        queryClient.refetchQueries({
          queryKey: ['establishments'],
          type: 'active',
        });
      }
      showMessage('success', 'Contrat-cadre créé');
      onResolve(true);
    } catch (e) {
      // error is handled with fetch
    }
  };

  const loading = status === 'pending';

  return (
    <ModalFrame
      isOpen={isOpen}
      onClose={() => onResolve(false)}
      width={470}
      closeIcon
    >
      <Box>
        {loading && <SpinnerBox />}
        {!loading && (
          <ImportExecutiveContractForm
            onClose={() => onResolve(false)}
            onSubmit={handleSubmit}
            contractLabel={contractLabel}
          />
        )}
      </Box>
    </ModalFrame>
  );
};

export const showImportExecutiveContractModal = create<Props>(
  ImportExecutiveContractModal
);
