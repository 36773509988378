import * as React from 'react';

function SvgNotificationDotIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
      <g fill="none" fillRule="evenodd">
        <path
          fill="currentColor"
          d="M10 20h4a2 2 0 11-4 0zm8.88-5.12a3 3 0 01-.88-2.12V10a6 6 0 00-1.2-3.6l-.9-1.2A3 3 0 0013.5 4H13V2.5a.5.5 0 00-.5-.5h-1a.5.5 0 00-.5.5V4h-.5a3 3 0 00-2.4 1.2l-.9 1.2A6 6 0 006 10v2.76a3 3 0 01-.88 2.12l-.83.83a1 1 0 00-.29.7V17a1 1 0 001 1h14a1 1 0 001-1v-.59a1 1 0 00-.29-.7l-.83-.83z"
        />
        <circle cx={19} cy={5} r={5} fill="#F24750" stroke="#FFF" />
      </g>
    </svg>
  );
}

export default SvgNotificationDotIcon;
