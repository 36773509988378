// import React from 'react';
import { ERoles, checkUserHasRole, useUserFindOne } from '@commons';
import { ApiError } from '@commons';
import { Header } from 'components/Header';
import { SideBar } from 'components/SideBar';
import { SideLink } from 'components/SideLink';
import {
  Avatar,
  Box,
  Container,
  Flex,
  Separator,
  SpinnerBox,
  Text,
} from 'components/ui';
import { getFirstCaracts, getFullName } from '@commons';
import { Route, Switch, useParams } from 'react-router-dom';
import PageNotFound from 'routes/NotFound';
import { OtherContactsInformation } from './Informations';
import { OtherContactsEstablishments } from './Establishments';
export const OtherContactsDetails = () => {
  const { id } = useParams<{ id: string }>();
  const { data: contact, error, isFetching } = useUserFindOne(id);

  if (error) {
    return (
      <PageNotFound
        top={80}
        customText="Le contact que vous cherchez semble introuvable."
      />
    );
  }
  return (
    <Box>
      <Header>
        <Flex alignItems="center">
          <Avatar text={contact && getFirstCaracts(contact)} />
          <Flex flexWrap="wrap">
            <Text ml={10} variant="h1">
              {contact && getFullName(contact)}
            </Text>
            {/* {DISPLAY_SF_STATUS && (
              <Box width={1 / 1} ml={10}>
                <Status variantColor={salesForce ? 'success' : 'grey'}>
                  {salesForce ? CREATED_SF_STATUS : NOT_CREATED_SF_STATUS}
                </Status>
              </Box>
            )} */}
          </Flex>
        </Flex>
      </Header>

      <Container display="flex" pt={50}>
        <SideBar>
          <SideLink exact href={`/others/contacts/${id}/information`}>
            Informations générales
          </SideLink>
          {checkUserHasRole(contact, ERoles.INTERNAL_COMMERCIAL) && (
            <SideLink href={`/others/contacts/${id}/establishments`}>
              Établissements
            </SideLink>
          )}

          <Separator />
        </SideBar>
        <Box flex={1}>
          {isFetching && <SpinnerBox />}
          {!isFetching && (
            <Switch>
              <Route exact path="/others/contacts/:id/information">
                <OtherContactsInformation />
              </Route>
              <Route path="/others/contacts/:id/establishments">
                <OtherContactsEstablishments />
              </Route>
            </Switch>
          )}
          {!!error && <p>{(error as ApiError).message}</p>}
        </Box>
      </Container>
    </Box>
  );
};
