import { EInvoiceStatus } from '@freelancelabs/teoreme-commons';
import { Flex, Box, FormLabel, Input, Link } from 'components/ui';
import {
  ADDITIONAL_ACTIVITY_STATUS_SELECT,
  INVOICE_STATUS_SELECT,
} from '@commons';
import { StaticSelect } from 'components/selects/StaticSelect';
import { RefreshIcon } from 'components/ui/icons';
import { Theme } from 'styles';

const INVOICE_STATUS_SELECT_CUSTOM = INVOICE_STATUS_SELECT;
const Findex = INVOICE_STATUS_SELECT.findIndex(
  state => state.key === EInvoiceStatus.REJECTED
);
INVOICE_STATUS_SELECT_CUSTOM[Findex].label = 'Refusé';

type MissionMilestonesFiltersProps = {
  hookStore: any;
};
export const MissionMilestonesFilters = ({
  hookStore,
}: MissionMilestonesFiltersProps) => {
  const {
    initialSate,
    updateStore,
    resetStore,
    selectedStatus,
    searchQuery,
    limit,
  } = hookStore();

  return (
    <Box>
      <Flex m="10px 0">
        <Box width={1 / 4} maxWidth={200}>
          <FormLabel>Rechercher</FormLabel>
          <Input
            mr={20}
            isFullWidth
            data-cy="search-estaCustomer-list"
            type="search"
            name="search"
            placeholder="Rechercher ..."
            value={searchQuery}
            onChange={(e: any) => {
              updateStore({
                page: 0,
                searchQuery: e.target.value,
              });
            }}
          />
        </Box>

        <Box width={1 / 4} maxWidth={200} ml={20}>
          <FormLabel>Statuts</FormLabel>
          <StaticSelect
            width={1 / 1}
            options={ADDITIONAL_ACTIVITY_STATUS_SELECT}
            value={selectedStatus}
            placeholder="Tous les statuts"
            onChange={value =>
              updateStore({
                selectedStatus: value as 'ALL' | EInvoiceStatus | undefined,
                page: 0,
              })
            }
          />
        </Box>
        <Box width={1 / 8} maxWidth={200} ml={20}>
          <FormLabel>NB. LIGNES AFFICHÉES</FormLabel>
          <StaticSelect
            isClearable={false}
            placeholder=" "
            options={[
              {
                label: '5',
                value: 5,
                key: 5,
              },
              {
                label: '20',
                value: 20,
                key: 20,
              },
              {
                label: '50',
                value: 50,
                key: 50,
              },
              {
                label: '100',
                value: 100,
                key: 100,
              },
            ]}
            onChange={value =>
              updateStore({
                limit: value as number,
                page: 0,
              })
            }
            value={limit}
          />
        </Box>
        <Flex width={1 / 4} alignItems="center" ml={20}>
          <Link
            isDisabled={initialSate}
            iconLeft={
              <RefreshIcon
                fill={initialSate ? Theme?.colors?.grey?.default : undefined}
              />
            }
            mt={15}
            onClick={() => (!initialSate ? resetStore({}) : false)}
          >
            Réinitialiser
          </Link>
        </Flex>
      </Flex>
    </Box>
  );
};
