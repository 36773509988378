import {
  ITraceSearchParams,
  ITraceSearchResponse,
  ITraceChangeHistorySearchParams,
  ITraceChangeHistorySearchResponse,
} from '@freelancelabs/teoreme-commons';
import { TRACER_SERVICE_URL } from '../../constantz';
import { fetcher } from '../helpers/fetcher';

export const traceFindMany = (
  body: ITraceSearchParams,
  disableAlert?: boolean
) =>
  fetcher<ITraceSearchParams, ITraceSearchResponse>(
    TRACER_SERVICE_URL + `/search`,
    {
      method: 'post',
      body,
    },
    disableAlert
  );
export const changehistoryFindMany = (
  body: ITraceChangeHistorySearchParams,
  disableAlert?: boolean
) =>
  fetcher<ITraceChangeHistorySearchParams, ITraceChangeHistorySearchResponse>(
    TRACER_SERVICE_URL + `/change-history/search`,
    {
      method: 'post',
      body,
    },
    disableAlert
  );
