import { checkVAT, countries, VatCheckResult } from 'jsvat-next';
import {
  IEstablishment,
  IAddress,
  EInvoicePaymentLabel,
  EInvoiceSendMethod,
  EFileType,
  IContractorCreateParams,
  IFile,
  euCountries,
} from '@freelancelabs/teoreme-commons';
import { ConfirmNavigationModal } from 'components/modals/ConfirmNavigationModal';
import { showAddContactModal } from 'components/modals/AddContactModal';
import {
  Flex,
  Box,
  Button,
  FormLabel,
  Link,
  Text,
  FormControl,
  Input,
  UserSelectControlled,
  TextAreaControlled,
  CheckSwitch,
  Radio,
  DatePickerControlled,
  Row,
  FileInput,
} from 'components/ui';
import { AddIcon } from 'components/ui/icons';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  COUNTRIES_SELECT,
  contractorCreateOne,
  formatAllObject,
  getFullName,
  getTradeNameSafe,
  isNotEmpty,
  isPartOfEU,
  uploadFile,
  useEstablishmentCreateOne,
  useRegister,
  COUNTRIES_WORLD_ISO,
  checkVatNumber,
} from '@commons';
import { useMe } from '@commons';
import { StaticSelect } from 'components/selects/StaticSelect';
import { showAddContractorModal } from 'components/modals/AddContractorModal';
import { useShowMessage } from 'hooks/useShowMessage';
const COUNTRIES_SELECT_NO_FRANCE = COUNTRIES_SELECT.filter(
  e => e.label.toLowerCase() !== 'france'
);

type FormValues = Partial<IEstablishment> & {
  siret?: string;
  address?: IAddress;
  customer?: {
    accountManager: string;
    contact: string;
    invoiceContact: any;
    internalCommercial: string;
  };
  provider?: {
    manager: string;
    isContractRequired: boolean;
  };
};
type AddForeignEstablishmentFormProps = {
  establishmentRole?: 'PROVIDER' | 'CUSTOMER';
  defaultValues?: Partial<IEstablishment>;
  onSubmit?: (formValues: FormValues) => any | void;
  isDisabled?: boolean;
  noContactRequired?: boolean;
  buttonHidden?: boolean;
  onResolve?: (data: any) => void;
};

export const AddForeignEstablishmentForm = ({
  establishmentRole,
  defaultValues,
  onSubmit: onSubmitParent,
  isDisabled,
  noContactRequired,
  buttonHidden,
  onResolve,
}: AddForeignEstablishmentFormProps) => {
  const [createWithContractor, setCreatWithContractor] = useState<
    IContractorCreateParams | undefined
  >();
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState<File | false>(false);
  const [registrationFileError, setRegistrationFileError] = useState<
    false | string
  >(false);
  const {
    watch,
    handleSubmit,
    control,
    register,
    setValue,
    setError,
    getValues,
    clearErrors,
    formState: { errors, isDirty, isSubmitSuccessful },
  } = useForm<FormValues>({
    defaultValues: {
      ...defaultValues,
      provider:
        establishmentRole === 'PROVIDER'
          ? { isContractRequired: true }
          : undefined,
    },
  });
  const isAutoSendEmail = watch('customer.billingOptions.isAutoSendEmail');
  const isAutoBilling = watch('customer.billingOptions.isAutoBilling');
  const country = watch('address.country');

  //const internalCommercial = watch('customer.internalCommercial');
  const shouldJoinProofToInvoice = watch(
    'customer.billingOptions.shouldJoinProofToInvoice'
  );
  const isContractRequired = watch('provider.isContractRequired');
  const shouldMergeProofWithInvoice = watch(
    'customer.billingOptions.shouldMergeProofWithInvoice'
  );
  const providerManager = watch('provider.manager');
  const onSubmit = async (data: FormValues) => {
    if (!file) {
      // REGISRATIONFILE REQUIRED ! TEOR-5720
      setRegistrationFileError('Ce champs est requis');
      return;
    }
    const shouldMergeProofWithInvoice =
      //@ts-ignore
      data?.customer?.billingOptions?.shouldMergeProofWithInvoice;
    setLoading(true);
    let isValid = true;
    if (establishmentRole === 'CUSTOMER' && !data?.customer?.contact) {
      isValid = false;
      setError('customer.contact', { message: 'Ce champ est requis' });
    }
    if (establishmentRole === 'CUSTOMER' && !data?.customer?.accountManager) {
      isValid = false;
      setError('customer.accountManager', { message: 'Ce champ est requis' });
    }
    if (
      establishmentRole === 'CUSTOMER' &&
      !data?.customer?.internalCommercial
    ) {
      isValid = false;
      setError('customer.internalCommercial', {
        message: 'Ce champ est requis',
      });
    }
    if (
      establishmentRole === 'PROVIDER' &&
      !data?.provider?.manager &&
      !noContactRequired
    ) {
      isValid = false;
      setError('provider.manager', { message: 'Ce champ est requis' });
    }
    if (!data?.address?.country) {
      isValid = false;
      setError(
        'address.country',
        {
          type: 'required',
          message: 'Ce champ est requis',
        },
        { shouldFocus: true }
      );
    }

    if (
      !data?.vatNumber &&
      data?.address?.country &&
      isPartOfEU(data?.address?.country as string)
    ) {
      isValid = false;
      setError(
        'vatNumber',
        {
          type: 'required',
        },
        { shouldFocus: true }
      );
    }
    if (isValid) {
      setError('customer.contact', { message: undefined });
      setError('address.country', { message: undefined });
      const customerData: any = data?.customer;
      if (establishmentRole === 'CUSTOMER') {
        const billingOptions = {
          ...customerData.billingOptions,
          paymentLabel: EInvoicePaymentLabel.FIXED,
          shouldMergeProofWithInvoice: shouldJoinProofToInvoice
            ? shouldMergeProofWithInvoice
            : false,
        };
        customerData.billingOptions = billingOptions;
      }

      const submitData = {
        identifier: data?.identifier || '',
        businessName: data?.businessName,
        activeSince: data?.activeSince || undefined,
        vatNumber: data?.vatNumber || undefined,
        address: {
          ...defaultValues?.address,
          ...data?.address,
          street: data?.address?.street,
        },
        customer: establishmentRole === 'CUSTOMER' ? customerData : undefined,
        provider: establishmentRole === 'PROVIDER' ? data?.provider : undefined,
      };

      if (file) {
        const uploadResponse = await uploadFile({
          file,
          fileType: EFileType.TEMPORARY,
          establishment: '',
          actionType: 'upload',
        });

        if (!uploadResponse) return;

        const attachment = {
          fileLocation: uploadResponse.fileLocation,
          fileName: uploadResponse.fileName,
          eTag: uploadResponse.eTag,
        };
        //@ts-ignore
        submitData.registrationFile = attachment;
      }
      if (createWithContractor && establishmentRole === 'PROVIDER') {
        await handleCreateEstablishmentAndContactContractor(
          createWithContractor,
          submitData
        );
      } else {
        onSubmitParent && (await onSubmitParent(submitData));
      }
    }

    setLoading(false);
  };

  const { me } = useMe();
  const address = defaultValues?.address;

  const checkAddressIsComplete = (adress?: any) => {
    let adressCheck = adress || defaultValues?.address;
    let isValide = true;
    if (!adressCheck?.city) {
      isValide = false;
    }
    if (!adressCheck?.country) {
      isValide = false;
    }
    if (!adressCheck?.postalCode) {
      isValide = false;
    }
    if (!adressCheck?.street) {
      isValide = false;
    }
    return isValide;
  };

  const getVatNumberErrorMessage = (vatError: any) => {
    if (vatError) {
      switch (vatError.type) {
        case 'maxLength':
          return 'Veuillez saisir moins de 50 caractères';
        case 'required':
          return 'Ce champ est requis';
      }
      return vatError.message;
    }
    return undefined;
  };
  const checkAllRequiredFields = () => {
    const data = getValues();
    if (!data?.identifier) {
      return false;
    }
    if (!data?.businessName) {
      return false;
    }
    if (!checkAddressIsComplete(data?.address)) {
      return false;
    }
    return true;
  };
  const showMessage = useShowMessage();
  const { mutateAsync: addContact } = useRegister();
  const { mutateAsync: addEstablishment } = useEstablishmentCreateOne();
  const handleCreateEstablishmentAndContactContractor = async (
    contractor: IContractorCreateParams,
    establishment: any
  ) => {
    const contact = {
      email: contractor?.email,
      civility: contractor?.contractor?.civility,
      firstName: contractor?.contractor?.firstName,
      lastName: contractor?.contractor?.lastName,
      phone: contractor?.contractor?.phone,
      jobTitle: contractor?.contractor?.jobTitle,
    };
    try {
      //step 1 :  create contact
      const providerContact = await addContact({
        user: formatAllObject(contact),
        roles: 'PROVIDER_CONTACT',
      });
      establishment.provider.manager = providerContact?.cognitoUserId;
      // step 2 : create establishment
      const providerEstablishment = await addEstablishment(establishment);

      // step 3 :  create contractor
      const newContractor = await contractorCreateOne({
        ...formatAllObject(contractor),
        establishment: providerEstablishment?.uuid,
      });
      if (onResolve) {
        showMessage(
          'success',
          `Le contact ${getFullName(
            providerContact
          )} , l'intervenant ${getFullName(
            newContractor
          )} et l'établissement ${getTradeNameSafe(
            providerEstablishment
          )} ont bien été créés`
        );
        onResolve(providerEstablishment);
      }
    } catch (e) {
      //
    }
  };
  useEffect(() => {
    if (createWithContractor && typeof providerManager === 'string') {
      setCreatWithContractor(undefined);
    }
  }, [providerManager, createWithContractor]);
  const sendMethod = watch('customer.billingOptions.sendMethod');
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <ConfirmNavigationModal active={isDirty && !isSubmitSuccessful} />

      <Box mb={10}>
        <Row spacing={20}>
          <FormControl
            required
            label="Raison sociale"
            errorMessage={errors?.businessName?.message}
          >
            <Input
              isFullWidth
              {...register('businessName', {
                required: 'Ce champs est requis',
              })}
            />
          </FormControl>
          <FormControl
            label="Date de création"
            errorMessage={errors?.activeSince?.message}
          >
            <DatePickerControlled
              control={control}
              name="activeSince"
              maxDate={new Date()}
              valueName="selected"
            />
          </FormControl>
        </Row>
      </Box>
      <Box>
        <FormControl
          label={'Sélectionner un Pays'}
          required
          errorMessage={errors?.address?.country?.message}
        >
          <StaticSelect
            options={COUNTRIES_SELECT_NO_FRANCE}
            onChange={value => {
              setValue('address.country', value as string);
            }}
            name="address.country"
            placeholder="Pays"
            required
          />
          {/* </Box> */}
        </FormControl>
      </Box>
      <Box>
        <FormControl
          required
          label="Numero d’immatriculation"
          errorMessage={errors?.identifier?.message}
        >
          <Input
            isFullWidth
            {...register('identifier', {
              required: 'Ce champs est requis',
            })}
          />
        </FormControl>
      </Box>
      <Box mb={10}>
        <FormControl
          required={
            country
              ? isPartOfEU(country) || country?.toLocaleUpperCase() === 'MONACO'
              : false
          }
          label="Numero Tva"
          errorMessage={getVatNumberErrorMessage(errors?.vatNumber)}
        >
          <Input
            isFullWidth
            // required={country ? isPartOfEU(country) : false},
            {...register('vatNumber', {
              maxLength: 50,
              required:
                (country && isPartOfEU(country)) ||
                country?.toUpperCase() === 'MONACO'
                  ? 'Ce champs est requis'
                  : undefined,
              validate: (value: any) => {
                clearErrors('vatNumber');
                if (
                  (country && isPartOfEU(country)) ||
                  country?.toUpperCase() === 'MONACO'
                ) {
                  if (!value) return 'Ce champs est requis';
                  const selectedCountry = COUNTRIES_WORLD_ISO.find(
                    e => e?.name?.toUpperCase() === country?.toUpperCase()
                  );
                  try {
                    const vatRes = checkVatNumber(value, {
                      forCountryCode: selectedCountry?.alpha2,
                    });
                    if (!vatRes)
                      return 'Le numéro de tva est invalide pour ce pays';
                  } catch (error) {
                    console.log('error vat check', error);
                  }
                }
                return undefined;
              },
            })}
          />
        </FormControl>
      </Box>
      {checkAddressIsComplete() ? (
        <Box mb={10}>
          <FormLabel>Adresse de l'établissement</FormLabel>
          <Text variant="p">
            {defaultValues?.address?.street} {defaultValues?.address?.city}{' '}
            {defaultValues?.address?.postalCode}
            {', '}
            {defaultValues?.address?.country}
          </Text>
        </Box>
      ) : (
        <Box>
          <Box>
            <FormControl
              label={'Adresse'}
              // @ts-ignore
              errorMessage={errors?.address?.street?.message}
              required
            >
              <Input
                isDisabled={
                  address?.street && address?.street?.length > 0 ? true : false
                }
                data-cy="errors?.address.street.message"
                isFullWidth
                {...register('address.street', {
                  required: 'Ce champ est requis',
                  validate: value => {
                    if (value) {
                      if (!isNotEmpty(value?.trim())) {
                        return 'Ce champ est requis';
                      } else {
                        return undefined;
                      }
                    }
                  },
                })}
                type="text"
              />
            </FormControl>
          </Box>
          <Box>
            <FormControl
              label="Complément d'adresse"
              errorMessage={errors?.address?.additional?.message}
            >
              <Input isFullWidth {...register('address.additional')} />
            </FormControl>
          </Box>
          <Flex>
            <Box width={3 / 12}>
              <FormControl
                label={'Code postal'}
                // @ts-ignore
                errorMessage={errors?.address?.postalCode?.message}
                required
              >
                <Input
                  isDisabled={
                    address?.postalCode && address?.postalCode?.length > 0
                      ? true
                      : false
                  }
                  data-cy="errors?.address.postalCode.message"
                  isFullWidth
                  {...register('address.postalCode', {
                    required: 'Ce champ est requis',
                    validate: value => {
                      if (value) {
                        if (!isNotEmpty(value?.trim())) {
                          return 'Ce champ est requis';
                        } else {
                          return undefined;
                        }
                      }
                    },
                  })}
                  type="text"
                />
              </FormControl>
            </Box>
            <Box width={3 / 12} />
            <Box width={6 / 12}>
              <FormControl
                label={'Ville'}
                // @ts-ignore
                errorMessage={errors?.address?.city?.message}
                required
              >
                <Input
                  isDisabled={
                    address?.city && address?.city?.length > 0 ? true : false
                  }
                  data-cy="errors?.address.city.message"
                  isFullWidth
                  {...register('address.city', {
                    required: 'Ce champ est requis',
                    validate: value => {
                      if (value) {
                        if (!isNotEmpty(value?.trim())) {
                          return 'Ce champ est requis';
                        } else {
                          return undefined;
                        }
                      }
                    },
                  })}
                  type="text"
                />
              </FormControl>
            </Box>
          </Flex>
        </Box>
      )}
      <FormControl label="" errorMessage={registrationFileError}>
        <FileInput
          mb={20}
          required
          label="Document D'immatriculation"
          onSelect={files => {
            if (files?.[0]) {
              setFile(files[0]);
              setRegistrationFileError(false);
            }
          }}
          accept=".pdf"
          subLabel="Format accepté : PDF"
        >
          <Link iconLeft={<AddIcon />}>
            {file ? (file as File).name : 'Importer une pièce jointe'}
          </Link>
        </FileInput>
      </FormControl>
      {/* {establishmentRole === 'PROVIDER' && !isDisabled && (
        <Box>
          <FormControl label="Contrat-cadre">
            <CheckSwitch
              id="isContractRequired"
              {...register('provider.isContractRequired')}
            >
              <Text variant="span">
                {!isContractRequired
                  ? "La signature d'un contrat cadre n'est pas obligatoire pour pouvoir valider les missions de cet établissement"
                  : "La signature d'un contrat cadre est obligatoire pour pouvoir valider les missions de cet établissement"}
              </Text>
            </CheckSwitch>
          </FormControl>
        </Box>
      )} */}
      {establishmentRole === 'PROVIDER' &&
        !noContactRequired &&
        !isDisabled && (
          <Box mb={20} hidden={!checkAllRequiredFields()}>
            <Text variant="h2" mb={20}>
              Rattacher un contact fournisseur
            </Text>
            <Text variant="p" mb={20}>
              Le contact fournisseur que vous allez rattacher au nouvel
              établissement fournisseur deviendra le responsable de compte de ce
              dernier.
            </Text>
            <Box mb={2}>
              <UserSelectControlled
                disableCall={createWithContractor}
                addedData={
                  createWithContractor
                    ? [
                        {
                          label: `${createWithContractor?.contractor?.firstName} ${createWithContractor?.contractor?.lastName} ${createWithContractor?.contractor?.email}`,
                          value: 'contactAndContractor',
                          data: {
                            cognitoUserId: 'contactAndContractor',
                            firstName:
                              createWithContractor?.contractor?.firstName,
                            lastName:
                              createWithContractor?.contractor?.lastName,
                            email: createWithContractor?.contractor?.email,
                            roles: [{ name: 'PROVIDER_CONTACT' }],
                          },
                        },
                      ]
                    : undefined
                }
                id="provider-contact-select"
                control={control}
                placeholder="Tous les contacts"
                role={'PROVIDER_CONTACT'}
                name="provider.manager"
              />
              {errors?.provider?.manager?.message && (
                <FormControl
                  label=""
                  errorMessage={errors?.provider?.manager?.message}
                ></FormControl>
              )}
            </Box>
            <Link
              width={1 / 1}
              iconLeft={<AddIcon />}
              onClick={() =>
                showAddContactModal({
                  contactRole: 'PROVIDER_CONTACT',
                }).then(c => {
                  c.cognitoUserId &&
                    setValue('provider.manager', c.cognitoUserId);
                })
              }
            >
              Ajouter un contact Fournisseur
            </Link>
            <Link
              iconLeft={<AddIcon />}
              onClick={() =>
                showAddContractorModal({
                  contactIsContractor: true,
                  noEstablishment: true,
                  onSubmit: async contractor => {
                    setCreatWithContractor(contractor);
                    //@ts-ignore
                    setValue('provider.manager', contractor);
                  },
                })
              }
            >
              Ajouter un contact Fournisseur également intervenant
            </Link>
          </Box>
        )}
      {establishmentRole === 'CUSTOMER' && !isDisabled && (
        <>
          <Text variant="h3" mb={15} mt={20}>
            Contact de facturation
          </Text>
          <FormControl
            label={'Email'}
            // @ts-ignore
            errorMessage={errors?.customer?.invoiceContact?.email?.message}
            required
          >
            <Input
              data-cy="establishment-customer-invoiceContact-email"
              isFullWidth
              {...register('customer.invoiceContact.email', {
                required: 'Ce champ est requis',
              })}
              type="email"
            />
          </FormControl>
          <FormControl
            required
            label={'Mode de distribution'}
            errorMessage={errors?.customer?.billingOptions?.sendMethod?.message}
          >
            <>
              <Radio
                {...register('customer.billingOptions.sendMethod', {
                  required: 'Ce champ est requis',
                })}
                value={EInvoiceSendMethod?.EMAIL}
              >
                Email
              </Radio>
              <Radio
                {...register('customer.billingOptions.sendMethod', {
                  required: 'Ce champ est requis',
                })}
                value={EInvoiceSendMethod?.OTHER}
              >
                Autre ( plateforme , fax etc .... )
              </Radio>
            </>
          </FormControl>
          <FormControl
            label={'Facturer automatiquement le client'}
            errorMessage={
              //@ts-ignore
              errors?.customer?.billingOptions?.isAutoBilling?.message
            }
            required
          >
            <CheckSwitch
              id="isAutoBilling"
              {...register(
                //@ts-ignore
                'customer.billingOptions.isAutoBilling'
              )}
              onChange={e => {
                setValue(
                  //@ts-ignore
                  'customer.billingOptions.isAutoBilling',
                  // @ts-ignore
                  e.target.checked
                );
                return e.target.checked;
              }}
            >
              <Text variant="p">
                {isAutoBilling
                  ? 'Facturer automatiquement le client'
                  : 'Ne pas facturer automatiquement le client'}
              </Text>
            </CheckSwitch>
          </FormControl>
          {sendMethod === EInvoiceSendMethod.EMAIL && (
            <FormControl
              label={
                'Envoyer automatiquement les factures client à cette adresse'
              }
              errorMessage={
                errors?.customer?.billingOptions?.isAutoSendEmail?.message
              }
              required
            >
              <CheckSwitch
                id="isAutoSendEmail"
                {...register('customer.billingOptions.isAutoSendEmail')}
                onChange={e => {
                  setValue(
                    'customer.billingOptions.isAutoSendEmail',
                    e.target.checked
                  );
                  return e.target.checked;
                }}
              >
                <Text variant="p">
                  {isAutoSendEmail
                    ? 'Envoyer automatiquement'
                    : 'Ne pas envoyer automatiquement'}
                </Text>
              </CheckSwitch>
            </FormControl>
          )}
          {isAutoBilling && sendMethod === EInvoiceSendMethod.EMAIL && (
            <>
              <FormControl
                label={'Joindre les justificatifs à la facture client'}
                errorMessage={
                  errors?.customer?.billingOptions?.shouldJoinProofToInvoice
                    ?.message
                }
                required
              >
                <CheckSwitch
                  id="shouldJoinProofToInvoice"
                  {...register(
                    'customer.billingOptions.shouldJoinProofToInvoice'
                  )}
                  onChange={e => {
                    setValue(
                      'customer.billingOptions.shouldJoinProofToInvoice',
                      e.target.checked
                    );
                    return e.target.checked;
                  }}
                >
                  <Text variant="p">
                    {shouldJoinProofToInvoice
                      ? 'Joindre les justificatifs à la facture client'
                      : 'Ne pas Joindre les justificatifs à la facture client'}
                  </Text>
                </CheckSwitch>
              </FormControl>
              {shouldJoinProofToInvoice && (
                <FormControl
                  required
                  label={'Fusionner les justificatifs avec la facture'}
                  errorMessage={
                    errors?.customer?.billingOptions
                      ?.shouldMergeProofWithInvoice?.message
                  }
                >
                  <CheckSwitch
                    id="shouldMergeProofWithInvoice"
                    {...register(
                      'customer.billingOptions.shouldMergeProofWithInvoice'
                    )}
                  >
                    <Text variant="p">
                      {shouldMergeProofWithInvoice
                        ? 'Fusionner les justificatifs avec la facture'
                        : ' Séparer les justificatifs et la facture'}
                    </Text>
                  </CheckSwitch>
                </FormControl>
              )}
            </>
          )}
          <FormControl label={'Commentaire'}>
            <TextAreaControlled
              control={control}
              minRows={3}
              isFullWidth
              data-cy="establishment-customer-invoiceContact-comment"
              name="customer.invoiceContact.comment"
            />
          </FormControl>

          <Text variant="h3" mb={15}>
            Gestion des rattachements
          </Text>
          <FormControl
            label={'Contact client'}
            required
            errorMessage={errors?.customer?.contact?.message}
          >
            <>
              <UserSelectControlled
                control={control}
                id="customer-contact-select"
                placeholder="Tous les contacts"
                role={'CUSTOMER'}
                name="customer.contact"
              />
              <Link
                iconLeft={<AddIcon />}
                onClick={() =>
                  showAddContactModal({
                    contactRole: 'CUSTOMER',
                  }).then(c => {
                    c.cognitoUserId &&
                      setValue('customer.contact', c.cognitoUserId);
                  })
                }
                mt={2}
              >
                Ajouter un contact client
              </Link>
            </>
          </FormControl>
          <FormControl
            label={'Chargé de compte'}
            required
            errorMessage={errors?.customer?.accountManager?.message}
          >
            <UserSelectControlled
              id="manager-contact-select"
              control={control}
              defaultValue={me?.cognitoUserId}
              placeholder="Tous les chargés de compte"
              role={'ACCOUNT_MANAGER'}
              name="customer.accountManager"
            />
          </FormControl>
          <FormControl
            label={'Sélectionner le commercial interne'}
            required
            errorMessage={errors?.customer?.internalCommercial?.message}
          >
            <Box>
              <UserSelectControlled
                control={control}
                name="customer.internalCommercial"
                role={'INTERNAL_COMMERCIAL'}
              />
            </Box>
          </FormControl>
        </>
      )}
      <Box hidden={buttonHidden}>
        <Button
          data-cy="submit-establishment"
          key="submit"
          type="submit"
          isLoading={loading}
          disabled={isDisabled}
          isDisabled={isDisabled}
        >
          Enregistrer
        </Button>
      </Box>
    </form>
  );
};
