import * as React from 'react';

function SvgUploadIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 25 24" {...props}>
      <path
        fillRule="evenodd"
        d="M18.933 13.847c-.588 0-1.066.551-1.066 1.23v2.462H6.133v-2.461c0-.68-.478-1.231-1.066-1.231-.59 0-1.067.551-1.067 1.23v4.308c0 .294.278.615.533.615h14.934c.322 0 .533-.282.533-.615v-4.307c0-.68-.478-1.231-1.067-1.231zM9.867 8.925h1.066v5.537c0 .68.478 1.23 1.067 1.23s1.067-.55 1.067-1.23V8.925h1.066c.376 0 .708.058.917-.185a.692.692 0 000-.877l-2.636-3.687a.49.49 0 00-.405-.175.486.486 0 00-.406.175L8.967 7.863a.69.69 0 000 .877c.209.243.69.185.9.185z"
      />
    </svg>
  );
}

export default SvgUploadIcon;
