import React, { useEffect } from 'react';
import { format as formatfns } from 'date-fns';
import { useTranslateStore } from 'store/translate';
import i18next from 'i18next';
import {
  FR_ERRORS,
  EN_ERRORS,
  FR_TEXTS,
  EN_TEXTS,
  EN_STATUS,
  FR_STATUS_JSON,
  FR_NOTIFICATIONS,
  EN_NOTIFICATIONS,
  FR_LINKS,
  EN_LINKS,
  FR_TRACES,
  FR_TRACES_LINKS,
  EN_TRACES,
  EN_TRACES_LINKS,
} from '@commons';

// initialize i18next with catalog and language to use
const resources = {
  fr: {
    texts: FR_TEXTS,
    status: FR_STATUS_JSON,
    errors: FR_ERRORS,
    notifications: FR_NOTIFICATIONS,
    links: FR_LINKS,
    traces: FR_TRACES,
    linkTraces: FR_TRACES_LINKS,
  },
  en: {
    texts: EN_TEXTS,
    status: EN_STATUS,
    errors: EN_ERRORS,
    notifications: EN_NOTIFICATIONS,
    links: EN_LINKS,
    traces: EN_TRACES,
    linkTraces: EN_TRACES_LINKS,
  },
};

type Props = {
  children: React.ReactNode;
};
export const Translator = ({ children }: Props) => {
  const { translateLang } = useTranslateStore();
  useEffect(() => {
    i18next.init({
      interpolation: {
        format: function (value, format, lng) {
          if (format === 'uppercase') return value.toUpperCase();
          if (value instanceof Date) return formatfns(value, format as string);
          return value;
        },
      },
      resources,
      lng: translateLang,
    });
  }, [translateLang]);

  return <>{children}</>;
};

export default Translator;
