import { eq, isArray, isEmpty } from 'lodash';
import { checkVAT, countries } from 'jsvat-next';
import {
  FlatUser,
  EContractorSocialStatus,
  IContractorScopedData,
  IJoinedContractorScopedData,
  IJoinedCraWithInvoice,
  IContractor,
  EMissionType,
  IJoinedMission,
  ISageReference,
  EMissionStructure,
  ISageContractorReference,
  IJoinedAdditionalActivity,
  EInvoiceType,
  IEmailContact,
  IEstablishment,
  IEstablishmentFullUsers,
  IEstablishmentBase,
  IEstablishmentMinifiedUsers,
  IInvoiceFileMetaData,
} from '@freelancelabs/teoreme-commons';
import {
  isWithinInterval,
  addMonths,
  subMonths,
  getMonth,
  isAfter,
} from 'date-fns';
import {
  removeOffsetDate,
  getFirstDayOfMonth,
  setDateTimeToZero,
  isDisableFONEDate,
} from './date';
import {
  COUNTRIES_WORLD_ISO,
  EEE_COUNTRIES,
  ERoles,
  EU_COUNTRIES,
  PERMISSION_MANAGER_PATH_ROLES,
  PUBLIC_MANAGER_PATH,
} from '../constantz';
import {
  DELIVERY_NOTE_INVOICE_TYPES,
  STAND_BY_DUTY_INVOICE_TYPES,
  EXPENSE_INVOICE_TYPES,
  MILESTONE_INVOICE_TYPES,
  OPPOSITE_INVOICE_TYPE_MAPPING,
} from '../constantz/mapping';
import { getCountrySpecifications } from 'ibantools';
export const isNotEmpty = (value?: string | number | null | undefined) => {
  if (value === 0) {
    return true;
  }
  if (value && value !== null && value !== '') {
    return true;
  }
  return false;
};
export const isNotEmptyOrNaN = (
  value: string | number | null | undefined
): boolean => {
  if (value === '') return false;
  if (value === undefined) return false;
  if (value === null) return false;
  if (Number.isNaN(value)) return false;

  return true;
};

export const checkUserHasRole = (
  user: any /*User | Contact | null | boolean | FlatUser*/,
  role: string,
  adminHasAcces?: boolean
): boolean => {
  if (
    typeof user === 'object' &&
    user?.roles &&
    Array.isArray(user?.roles) &&
    role
  ) {
    for (let i = 0; i < user.roles.length; i++) {
      const userRole = user?.roles[i].name;
      if (adminHasAcces === true) {
        if (userRole === ERoles?.ADMIN) {
          return true;
        }
      }
      if (userRole === role) {
        return true;
      }
    }
  }
  return false;
};
export const checkPathPermission = (user: any, path: string) => {
  if (path === '/') {
    return true;
  }
  let havePermission = false;
  const domainePath = path?.split('/')?.[1];
  const isPublic = PUBLIC_MANAGER_PATH?.includes(domainePath);
  if (isPublic) {
    return true;
  }
  user?.roles?.forEach((role: any) => {
    if (role?.name === 'ADMIN') {
      havePermission = true;
      return;
    } else {
      //@ts-ignore
      const pathAvalaible = PERMISSION_MANAGER_PATH_ROLES?.[role?.name];
      if (
        Array?.isArray(pathAvalaible) &&
        pathAvalaible?.find((domaine: string) => domaine === domainePath)
      ) {
        havePermission = true;
      }
    }
  });
  return havePermission;
};

export const checkProviderEstaLinkedToRole = (
  establishment: any,
  role: 'PROVIDER_CONTACT' | 'PROVIDER_RESPONSIBLE',
  cognitoUserId: string
): boolean => {
  if (establishment && establishment.provider && role) {
    const estaProvider = establishment.provider;
    if (role === 'PROVIDER_RESPONSIBLE') {
      if (estaProvider?.manager === cognitoUserId) {
        return true;
      }
      if (estaProvider?.manager?.cognitoUserId === cognitoUserId) {
        return true;
      }
      return false;
    }
    if (role === 'PROVIDER_CONTACT') {
      if (
        Array.isArray(estaProvider?.contacts) &&
        estaProvider?.contacts.length !== 0
      ) {
        const results = estaProvider.contacts.find(
          (user: any) =>
            cognitoUserId === user.cognitoUserId || cognitoUserId === user
        );
        if (results) {
          return true;
        }
      }
      return false;
    }
  }
  return false;
};

export const arrayContainsSameCustomerIds = (
  subset: Array<any>,
  superset: Array<any>
) => {
  if (0 === subset.length) {
    return false;
  }

  const subsetCustomerIds = subset.map(value => value.customerId);
  const supersetCustpmerIds = superset.map(value => value.customerId);

  return subsetCustomerIds.every(function (value) {
    return supersetCustpmerIds.indexOf(value) >= 0;
  });
};

export const contractorNeedDpae = (
  scopedData: IContractorScopedData | IJoinedContractorScopedData
) => {
  if (
    scopedData.socialStatus === EContractorSocialStatus.SUBCONTRACTOR ||
    scopedData.socialStatus === EContractorSocialStatus.COMPANY_MANAGER ||
    scopedData.socialStatus === EContractorSocialStatus.MAJORITY_MANAGER
  ) {
    return false;
  } else {
    return true;
  }
};
const regex =
  /^([\w!#$%&'*+=?`{|}~^-]+(?:\.[\w!#$%&'*+=?`{|}~^-]+)*|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const checkEmail = (value: string | undefined): boolean => {
  if (!value) {
    return false;
  }

  if (regex.test(value)) {
    return true;
  } else {
    return false;
  }
};
export const checkFormEmail = (value: string): string | undefined => {
  if (value && value !== '') {
    if (regex.test(value)) {
      return undefined;
    } else {
      return 'Veuillez entrer une adresse e-mail valide';
    }
  } else {
    return undefined;
  }
};
export const checkAvalaibleCreateCra = (cra: IJoinedCraWithInvoice) => {
  const estProvider = cra?.estProvider;
  const estCustomer = cra?.estCustomer;
  const contractor = cra?.contractor;
  const structure = cra?.mission?.billingInformation?.structure;
  const labelType =
    cra?.mission?.billingInformation?.missionType?.label ===
    EMissionType.RESOURCE_MANAGEMENT
      ? 'RM'
      : 'CL';
  const contractorReady = (
    contractor as IContractor
  )?.sage?.contractorIds?.find(
    sage => sage?.structure === structure && sage?.type === labelType
  )
    ? true
    : false;
  const estProviderReady = estProvider?.provider?.sage?.references?.find(
    sage => sage?.structure === structure
  )
    ? true
    : false;
  const estCustomerReady = estCustomer?.customer?.sage?.references?.find(
    sage => sage?.structure === structure
  )
    ? true
    : false;
  return {
    contractorReady,
    estProviderReady,
    estCustomerReady,
    ready: contractorReady && estProviderReady && estCustomerReady,
  };
};
export const checkAvalaibleCreateAdditionalActivity = (
  additionalactivity: IJoinedAdditionalActivity
) => {
  const estProvider = additionalactivity?.estProvider;
  const estCustomer = additionalactivity?.estCustomer;
  const contractor = additionalactivity?.contractor;
  const structure = additionalactivity?.mission?.billingInformation?.structure;
  const labelType =
    additionalactivity?.mission?.billingInformation?.missionType?.label ===
    EMissionType.RESOURCE_MANAGEMENT
      ? 'RM'
      : 'CL';
  const contractorReady = (
    contractor as IContractor
  )?.sage?.contractorIds?.find(
    sage => sage?.structure === structure && sage?.type === labelType
  )
    ? true
    : false;
  const estProviderReady = estProvider?.provider?.sage?.references?.find(
    sage => sage?.structure === structure
  )
    ? true
    : false;
  const estCustomerReady = estCustomer?.customer?.sage?.references?.find(
    sage => sage?.structure === structure
  )
    ? true
    : false;
  return {
    contractorReady,
    estProviderReady,
    estCustomerReady,
    ready: contractorReady && estProviderReady && estCustomerReady,
  };
};
export const checkGescomAvalaibleGenerateInvoiceCustomer = (
  mission: IJoinedMission
) => {
  const estProvider = mission?.provider?.establishment;
  const estCustomer = mission?.customer?.establishment;
  const contractor = mission?.provider?.contractor;
  const structure = mission?.billingInformation?.structure;
  const labelType =
    mission?.billingInformation?.missionType?.label ===
    EMissionType.RESOURCE_MANAGEMENT
      ? 'RM'
      : 'CL';
  const contractorReady = (
    contractor as IContractor
  )?.sage?.contractorIds?.find(
    sage => sage?.structure === structure && sage?.type === labelType
  )
    ? true
    : false;
  const estProviderReady = estProvider?.provider?.sage?.references?.find(
    sage => sage?.structure === structure
  )
    ? true
    : false;
  const estCustomerReady = estCustomer?.customer?.sage?.references?.find(
    sage => sage?.structure === structure
  )
    ? true
    : false;
  return {
    contractorReady,
    estProviderReady,
    estCustomerReady,
    ready: contractorReady && estProviderReady && estCustomerReady,
  };
};
export const checkGescomAvalaibleMissionCustomer = (
  mission: IJoinedMission
) => {
  const estCustomer = mission?.customer?.establishment;
  const structure = mission?.billingInformation?.structure;
  const estCustomerReady = estCustomer?.customer?.sage?.references?.find(
    sage => sage?.structure === structure
  )
    ? true
    : false;
  return {
    estCustomerReady,
    ready: estCustomerReady,
  };
};
export const checkGescomAvalaibleMissionProvider = (
  mission: IJoinedMission
) => {
  const estProvider = mission?.provider?.establishment;
  const contractor = mission?.provider?.contractor;
  const structure = mission?.billingInformation?.structure;
  const labelType =
    mission?.billingInformation?.missionType?.label ===
    EMissionType.RESOURCE_MANAGEMENT
      ? 'RM'
      : 'CL';
  const contractorReady = (
    contractor as IContractor
  )?.sage?.contractorIds?.find(
    sage => sage?.structure === structure && sage?.type === labelType
  )
    ? true
    : false;
  const estProviderReady = estProvider?.provider?.sage?.references?.find(
    sage => sage?.structure === structure
  )
    ? true
    : false;
  return {
    contractorReady,
    estProviderReady,
    ready: contractorReady && estProviderReady,
  };
};

export type IcheckSageStructure = {
  isReady: boolean;
  structuresRequired: string[];
  structuresCreated: string[];
  missingStructure: string[];
};
export const checkAllStrcutureCreatedInGescom = (
  sage?: ISageReference,
  customStructureRequired?: string[]
): IcheckSageStructure => {
  let structuresRequired = customStructureRequired
    ? customStructureRequired
    : Object?.keys(EMissionStructure);
  if (isDisableFONEDate()) {
    structuresRequired = structuresRequired?.filter(
      sr => sr !== EMissionStructure.FONE
    );
  }
  let structuresCreated: string[] = [];
  let missingStructure: string[] = [];
  let isReady = true;
  if (sage && sage?.references) {
    structuresCreated = sage?.references?.map(data => data?.structure);
    structuresRequired?.forEach(sr => {
      if (!structuresCreated?.find(sc => sc === sr)) {
        missingStructure?.push(sr);
        isReady = false;
      }
    });
  } else {
    missingStructure = structuresRequired;
    isReady = false;
  }
  return {
    structuresRequired,
    structuresCreated,
    missingStructure,
    isReady,
  };
};
export const checkAllStrcutureCreatedInGescomForContractor = (
  sage?: ISageContractorReference,
  customStructureRequired?: string[]
): IcheckSageStructure => {
  let structuresRequired = customStructureRequired
    ? customStructureRequired
    : Object?.keys(EMissionStructure);
  if (isDisableFONEDate()) {
    structuresRequired = structuresRequired?.filter(
      sr => sr !== EMissionStructure.FONE
    );
  }
  let structuresCreated: string[] = [];
  let missingStructure: string[] = [];
  let isReady = true;
  if (sage && sage?.contractorIds) {
    structuresCreated = sage?.contractorIds?.map(data => data?.structure);
    structuresRequired?.forEach(sr => {
      // NEED 4 CODE ARTICLE ( RM , CL, FRAISRM , FRAISCL)
      if (structuresCreated?.filter(sc => sc === sr)?.length !== 4) {
        missingStructure?.push(sr);
        isReady = false;
      }
      // ONLY ONE CODE ARTICLE
      // if (!structuresCreated?.find(sc => sc === sr)) {
      //   missingStructure?.push(sr);
      //   isReady = false;
      // }
    });
  } else {
    missingStructure = structuresRequired;
    isReady = false;
  }
  return {
    structuresRequired,
    structuresCreated,
    missingStructure,
    isReady,
  };
};
export const checkAllStructureCreatedInGescomForContactOnEstablishment = (
  estSage?: ISageReference,
  contactSage?: ISageReference,
  customStructureRequired?: string[]
): IcheckSageStructure => {
  let structuresRequired = customStructureRequired
    ? customStructureRequired
    : Object?.keys(EMissionStructure);
  if (isDisableFONEDate()) {
    structuresRequired = structuresRequired?.filter(
      sr => sr !== EMissionStructure.FONE
    );
  }
  const structuresCreated: string[] = [];
  let missingStructure: string[] = [];
  let isReady = true;
  if (estSage && contactSage) {
    contactSage?.references?.forEach(cs => {
      if (
        estSage?.references?.find(
          es =>
            es?.customerId === cs?.customerId && es?.structure === cs?.structure
        )
      ) {
        structuresCreated?.push(cs?.structure);
      }
    });
    structuresRequired?.forEach(structureR => {
      if (!structuresCreated?.find(structureC => structureC === structureR)) {
        missingStructure?.push(structureR);
        isReady = false;
      }
    });
  } else {
    missingStructure = structuresRequired;
    isReady = false;
  }
  return {
    structuresRequired,
    structuresCreated,
    missingStructure,
    isReady,
  };
};
export const getRcproAvalaibleDates = (
  invoice: {
    month: Date;
    mission: {
      endAt: Date;
    };
  },
  Rcpros: {
    validityStart: Date;
    validityEnd?: Date;
    missionEndAt: Date;
  }[]
):
  | {
      start: Date;
      end: Date;
      isAfter?: boolean; //is after invoice month
      results?: {
        start?: Date | undefined;
        end?: Date | null | undefined;
      }[];
    }
  | false => {
  const missionDatesCurrentInvoice = {
    start: new Date(invoice?.month as Date),
    end: new Date(invoice?.mission?.endAt as Date),
  };
  // 1 -  NO RCPRO
  if (Rcpros?.length === 0) {
    return {
      start: removeOffsetDate(invoice?.month as Date),
      end: removeOffsetDate(invoice?.mission?.endAt as Date),
      isAfter: false,
    };
  }
  // 2 - ONLY 1 RCPRO
  if (Rcpros?.length === 1) {
    if (
      isWithinInterval(Rcpros[0]?.validityStart, missionDatesCurrentInvoice)
    ) {
      // 2 -1 RCPRO COVER ALL MISSION
      if (
        (Rcpros[0]?.validityEnd || Rcpros[0]?.missionEndAt) >
        new Date(invoice?.mission?.endAt as Date)
      ) {
        return false;
      } else {
        // 2 - 2 RCPRO  START BEFORE PREVIOUS RCPRO
        const start = removeOffsetDate(
          Rcpros[0]?.validityEnd
            ? addMonths(
                setDateTimeToZero(new Date(Rcpros[0]?.validityEnd) as Date),
                1
              )
            : setDateTimeToZero(new Date(Rcpros[0]?.missionEndAt))
        );
        if (getMonth(start) === getMonth(new Date(invoice?.mission?.endAt))) {
          return false;
        } else {
          const start = removeOffsetDate(
            Rcpros[0]?.validityEnd
              ? addMonths(
                  setDateTimeToZero(new Date(Rcpros[0]?.validityEnd) as Date),
                  1
                )
              : new Date(Rcpros[0]?.missionEndAt)
          );
          return {
            start: start,
            end: setDateTimeToZero(invoice?.mission?.endAt),
            isAfter: isAfter(
              removeOffsetDate(start as Date),
              new Date(invoice?.month as Date)
            ),
          };
        }
      }
    }
  }
  // 4 MULTIPLE RCPRO DETECTED SEARCH AVALAIBLE RANGE
  const includesRCPRO = Rcpros?.filter(rc => {
    if (
      isWithinInterval(rc?.validityStart, missionDatesCurrentInvoice) ||
      isWithinInterval(
        rc?.validityEnd || rc?.missionEndAt,
        missionDatesCurrentInvoice
      )
    ) {
      return rc;
    }
    return false;
  })?.sort(
    (a, b) =>
      new Date(a?.validityStart as Date)?.getTime() -
      new Date(b?.validityStart as Date)?.getTime()
  );

  const interval: { start?: Date; end?: Date | null }[] = [];
  // NO RCPRO IN INVOICE MISSION INTERVAL
  if (includesRCPRO?.length === 0) {
    return {
      start: removeOffsetDate(invoice?.month),
      end: removeOffsetDate(invoice?.mission?.endAt),
      isAfter: false,
    };
  }
  if (includesRCPRO?.length === 1) {
    const rcCheck = includesRCPRO?.[0];
    if (isWithinInterval(rcCheck?.validityStart, missionDatesCurrentInvoice)) {
      if (isAfter(rcCheck?.validityStart, invoice?.month)) {
        return {
          start: removeOffsetDate(invoice?.month),
          end: removeOffsetDate(
            subMonths(includesRCPRO?.[0]?.validityStart, 1)
          ),
          isAfter: false,
        };
      } else {
        return {
          start: addMonths(removeOffsetDate(rcCheck?.validityStart), 1),
          end: removeOffsetDate(
            subMonths(includesRCPRO?.[0]?.validityStart, 1)
          ),
          isAfter: false,
        };
      }
    } else {
      return {
        start: removeOffsetDate(invoice?.month),
        end: removeOffsetDate(invoice?.mission?.endAt),
        isAfter: false,
      };
    }
  } else {
    includesRCPRO?.forEach((rc, index) => {
      let ignore = false;
      if (index === 0) {
        interval?.push({
          start: removeOffsetDate(
            addMonths(
              getFirstDayOfMonth(
                setDateTimeToZero(
                  rc?.validityEnd
                    ? new Date(rc?.validityEnd as Date)
                    : (rc?.missionEndAt as Date)
                )
              ),
              1
            )
          ),
          end: null,
        });
      } else {
        // const prevstart = removeOffsetDate(
        //   rc?.validityEnd
        //     ? addMonths(new Date(rc?.validityEnd as Date), 1)
        //     : new Date(rc?.missionEndAt)
        // );
        const prevstart = interval[index - 1].start;
        if (
          getMonth(prevstart as Date) ===
          getMonth(new Date(rc?.validityEnd || (rc?.missionEndAt as Date)))
        ) {
          ignore = true;
        } else {
          interval?.push({
            start: removeOffsetDate(
              rc?.validityEnd
                ? addMonths(
                    getFirstDayOfMonth(
                      setDateTimeToZero(new Date(rc?.validityEnd as Date))
                    ),
                    1
                  )
                : new Date(rc?.missionEndAt)
            ),
            end: null,
          });
        }
      }
      if (ignore === false) {
        // NOT FIRST
        if (index !== 0) {
          const end = removeOffsetDate(
            subMonths(new Date(rc?.validityStart as Date), 1)
          );
          if (getMonth(end) < getMonth(interval[index - 1].start as Date)) {
          } else {
            interval[index - 1].end = end;
          }
        }
        // LAST
        if (index === includesRCPRO?.length - 1) {
          interval[index].end = removeOffsetDate(invoice?.mission?.endAt);
        }
      }
    });
  }
  const intervalSorted = interval
    ?.filter(i => i?.start && i?.end)
    ?.sort(
      (a, b) =>
        new Date(a?.start as Date)?.getTime() -
        new Date(b?.start as Date)?.getTime()
    );
  return {
    start: removeOffsetDate(intervalSorted?.[0]?.start as Date),
    end: removeOffsetDate(intervalSorted?.[0]?.end as Date),
    results: intervalSorted,
    isAfter: isAfter(
      removeOffsetDate(intervalSorted?.[0]?.start as Date),
      new Date(invoice?.month as Date)
    ),
  };
};

/**
 * Determine if the specified invoice is Customer  document
 * @param invoiceType
 */
export const isCustomerInvoice = (invoiceType: EInvoiceType): boolean => {
  return invoiceType?.includes('CUSTOMER');
};
/**
 * Determine if the specified invoice is Provider  document
 * @param invoiceType
 */
export const isProviderInvoice = (invoiceType: EInvoiceType): boolean => {
  return invoiceType?.includes('PROVIDER');
};
/**
 * Determine if the specified invoice type represent a time-spent  document
 * @param invoiceType
 */
export const isTimeSpentInvoice = (invoiceType: EInvoiceType): boolean => {
  return [
    EInvoiceType?.CUSTOMER,
    EInvoiceType?.CUSTOMER_CREDIT_NOTE,
    EInvoiceType?.PROVIDER,
    EInvoiceType?.PROVIDER_CREDIT_NOTE,
  ].includes(invoiceType);
};
/**
 * Determine if the specified invoice type represent a delivery note document
 * @param invoiceType
 */
export const isDeliveryNoteInvoice = (invoiceType: EInvoiceType): boolean => {
  return DELIVERY_NOTE_INVOICE_TYPES.includes(invoiceType);
};
/**
 * Determine if the specified invoice type represent a stand by duty document
 * @param invoiceType
 */
export const isStandByDutyInvoice = (invoiceType: EInvoiceType): boolean => {
  return STAND_BY_DUTY_INVOICE_TYPES.includes(invoiceType);
};
/**
 * Determine if the specified invoice type represent a stand by duty document
 * @param invoiceType
 */
export const isExpenseInvoice = (invoiceType: EInvoiceType): boolean => {
  return EXPENSE_INVOICE_TYPES.includes(invoiceType);
};
/**
 * Determine if the specified invoice type represent a milestone document
 * @param invoiceType
 */
export const isMileStoneInvoice = (invoiceType: EInvoiceType): boolean => {
  return MILESTONE_INVOICE_TYPES.includes(invoiceType);
};
/**
 * Determine if the specified invoice type represent an additional activity
 * @param invoiceType
 */
export const isAdditionalActivityInvoice = (
  invoiceType: EInvoiceType
): boolean => {
  return (
    isDeliveryNoteInvoice(invoiceType) ||
    isStandByDutyInvoice(invoiceType) ||
    isExpenseInvoice(invoiceType) ||
    isMileStoneInvoice(invoiceType)
  );
};
/**
 * Determine if the specified invoice type represent a credit note document
 * @param invoiceType
 */
export const isCreditNoteInvoice = (invoiceType: EInvoiceType): boolean => {
  return invoiceType.includes('CREDIT_NOTE');
};

/**
 * Return the opposite invoice type of the one specified in parameter.
 * eg: if EInvoiceType.CUSTOMER is passed, will return EInvoiceType.PROVIDER
 * if EInvoiceType.CUSTOMER_EXPENSE is passed, will return EInvoiceType.PROVIDER_EXPENSE
 * @param invoiceType
 */
export const getOppositeInvoiceType = (
  invoiceType: EInvoiceType
): EInvoiceType | undefined => {
  // @ts-ignore
  if (!OPPOSITE_INVOICE_TYPE_MAPPING[invoiceType as string]) {
    console.log(`Opposite type for ${invoiceType} is unknown.`);

    return undefined;
  }

  // @ts-ignore
  return OPPOSITE_INVOICE_TYPE_MAPPING[invoiceType as string];
};

// use this function to check if states of store equal initialStates
export const checkIfStoreIsInititalState = (
  newStates: any,
  getState: () => any,
  initialStates: any
) => {
  const mergedState = {
    ...getState(),
    ...newStates,
  };
  if (newStates.initialSate) {
    return true;
  }
  let isInitialeSate = true;
  const notSameValue: any = [];
  Object.keys(initialStates)
    .filter(v => v !== 'initialSate' && v !== 'filter' && v !== 'isOpen')
    ?.forEach(key => {
      if (initialStates[key] !== mergedState[key]) {
        notSameValue?.push({
          key,
          initialState: initialStates[key],
          nextStates: mergedState[key],
        });
        isInitialeSate = false;
      }
    });
  return isInitialeSate;
};
export const isEmptyState = (param: any) => {
  if (isArray(param) && isEmpty(param)) return true;
  if (param === 'N/A') return true;
  if (param === '') return true;
  if (param === undefined) return true;
  if (param === null) return true;
  return false;
};
// count the number of states that are different from initialState
// or not empty state ('N/A', '', null, [], undefined)
// used to count active filters
export const countDiffStates = (
  initialStates: any,
  currentStates: any,
  additionalFiltersVars: string[]
): number => {
  let counter = 0;
  for (const elt of additionalFiltersVars) {
    if (
      !isEmptyState(currentStates[elt]) &&
      !eq(currentStates[elt], initialStates[elt as string])
    ) {
      counter++;
    }
  }
  return counter;
};
/** * Perform a deep check for equality for 2 objects. Will compare all properties recursively.
 * @param obj1
 * @param obj2
 * @param opts */
export const objIsEqual = (
  obj1: any,
  obj2: any,
  opts?: { excludes?: string[] }
): boolean => {
  if (obj1 === obj2) return true;
  if (
    (obj1 === undefined && obj2 !== undefined) ||
    (obj1 !== undefined && obj2 === undefined)
  )
    return false;
  if ((obj1 === null && obj2 !== null) || (obj1 !== null && obj2 === null))
    return false;
  if (obj1 instanceof Date || obj2 instanceof Date) {
    // if one is a date, but not both, return false
    if (!(obj1 instanceof Date)) return false;
    if (!(obj2 instanceof Date)) return false;
    // Compare the iso string to determine if the is the same date    return obj1.toISOString() === obj2.toISOString()
  }
  // If one of the object is an array but not the other, return false directly
  if (
    (Array.isArray(obj1) && !Array.isArray(obj2)) ||
    (!Array.isArray(obj1) && Array.isArray(obj2))
  )
    return false;
  const entries1 = Object.entries(obj1);
  const entries2 = Object.entries(obj2);
  // not the same number of entries
  if (entries1.length !== entries2.length) return false;
  return entries1.every(([key, value]) => {
    if (opts?.excludes?.includes(key)) return true;
    // If the compared object does not have the property and the current value isn't undefined,
    // then return false directly
    if (value !== undefined && obj2[key] === undefined) return false;
    if (Array.isArray(value)) {
      if (value.length !== obj2[key].length) return false;
      return value.every((v, index) => objIsEqual(v, obj2[key][index]));
    }
    if (typeof value === 'object') {
      return objIsEqual(value, obj2[key]);
    }
    return obj2[key] === value;
  });
};
export type OverrideEmailContact = IEmailContact & {
  active?: boolean | undefined;
  inherited?: boolean | undefined;
};
export const isNotSameContacts = (
  oldContacts: OverrideEmailContact[],
  newContacts: OverrideEmailContact[],
  compareActive = true
) => {
  const keys = [
    'email',
    'lastName',
    'firstName',
    'civility',
    'cognitoUserId',
    'recipientType',
    'description',
  ];
  if (compareActive) {
    keys?.push('active');
  }
  const isNotSame: any = [];
  newContacts?.forEach((nc: OverrideEmailContact) => {
    const findOC = oldContacts?.find(oc => oc?.email === nc?.email);
    if (!findOC) {
      isNotSame?.push(nc);
    } else {
      let isSame = true;
      keys?.forEach(key => {
        //@ts-ignore
        if (nc[key] !== findOC[key]) {
          isSame = false;
        }
      });
      if (!isSame) {
        isNotSame?.push(nc);
      }
    }
  });
  return isNotSame;
};

export const isPartOfEU = (ctry: string): boolean =>
  EU_COUNTRIES.find(c => c.toLowerCase() === ctry.toLowerCase()) !== undefined;

// is part of European Economic Area (EEA)
export const isPartOfEEA = (ctry: string): boolean =>
  EEE_COUNTRIES.find(c => c.toLowerCase() === ctry.toLowerCase()) !== undefined;

export const isFrenchEstablishment = (
  establishment: IEstablishment | IEstablishmentFullUsers
) => {
  if (
    establishment?.address?.country?.toLowerCase() === 'france' ||
    establishment?.address?.country?.toLowerCase() === 'monaco'
  )
    return true;
  return false;
};

export interface ICountrySpec {
  id: number;
  alpha2: string;
  alpha3: string;
  name: string;
}
// eslint-disable-next-line prettier/prettier
type CountryFields<T extends keyof ICountrySpec = keyof ICountrySpec> = {
  [P in T]?: ICountrySpec[P];
};
export const getCountryData = <
  FIELD extends keyof ICountrySpec = keyof ICountrySpec,
>(
  field: FIELD,
  source: CountryFields
): ICountrySpec[FIELD] | string | ICountrySpec[FIELD] | undefined => {
  const sourceField = Object.keys(source)?.[0] as keyof ICountrySpec;
  if (!sourceField) return undefined;
  // Normalized representation remove the diacritic characters (accents like éàî) to allow more flexibility
  // on the comparison if the source field is a string (if its a search on "id" it would be a number)
  let normalizedSrc: ICountrySpec[FIELD] | any;
  if (source[sourceField]) {
    normalizedSrc =
      typeof source[sourceField] === 'string'
        ? (source[sourceField] as string)
            .normalize('NFD')
            .replace(/\p{Diacritic}/gu, '')
            .toUpperCase()
        : source[sourceField];
  }

  const result = COUNTRIES_WORLD_ISO?.find(entry => {
    // compare normalized version of the strings, and also test with or without the "dash" character
    // (eg: "Wallis-et-Futuna" can also match "Wallis et Futuna"
    if (typeof entry[sourceField] === 'string') {
      const normalizedEntry = (entry[sourceField] as string)
        .normalize('NFD')
        .replace(/\p{Diacritic}/gu, '')
        .toUpperCase();
      const withoutDash = normalizedEntry.replace(/-/gi, ' ').toUpperCase();
      return normalizedEntry === normalizedSrc || withoutDash === normalizedSrc;
    }
    return entry[sourceField] === source[sourceField];
  });
  return result?.[field] && typeof result?.[field] === 'string'
    ? result?.[field]?.toString()?.toUpperCase()
    : (result?.[field] as string);
};
export const establishmentUseIban = (
  establishment:
    | IEstablishmentBase
    | IEstablishmentMinifiedUsers
    | IEstablishmentFullUsers
) => {
  const estCountry = establishment?.address?.country;

  const countryCode = getCountryData('alpha2', {
    name: estCountry as string,
  });
  if (countryCode) {
    const spec = getCountrySpecifications()?.[countryCode];
    //if (!spec || !(spec?.bban_regexp || spec?.chars)) {
    if (!spec?.IBANRegistry) {
      // NOT supported by IBANTOOL
      return false;
    } else {
      // is supported by IBANTOOL
      return true;
    }
  }
};

export const providerExtractVerifyRibNeedWarning = (
  verifyFileData: IInvoiceFileMetaData
) => {
  if (verifyFileData?.hasRibOnFile) {
    if (verifyFileData?.hasRibOnSage && !verifyFileData?.ribMatches) {
      return true;
    }
  }
  return false;
};
export const managerExtractVerifyRibNeedWarning = (
  verifyFileData?: IInvoiceFileMetaData
) => {
  if (verifyFileData?.hasRibOnFile) {
    if (!verifyFileData?.hasRibOnSage || !verifyFileData?.ribMatches) {
      return true;
    }
  }
  return false;
};
export const isNotLocal = (stage?: string) => {
  if (
    stage === 'develop' ||
    stage === 'staging' ||
    stage === 'preprod' ||
    stage === 'production'
  ) {
    return true;
  }

  return false;
};
/**
 *  Check if managerSelected is userLogged and have AccountManager role
 * @param me
 * @param managerSelectedUuid
 * @returns
 */
export const checkDefaultManagerSelected = (
  me: FlatUser | undefined,
  managerSelectedUuid: string | undefined
) => {
  // Check if managerSelected is userLogged and have AccountManager role
  if (me?.cognitoUserId === managerSelectedUuid) {
    if (checkUserHasRole(me, ERoles?.ACCOUNT_MANAGER)) {
      return true;
    }
  } else {
    // Manager select have role AccountManager
    if (managerSelectedUuid && managerSelectedUuid !== me?.cognitoUserId) {
      return true;
    }
  }
  return false;
};

/**
 * Checks if a given VAT number is valid for a specific country code.
 *
 * @param {string} vatNumber - The VAT number to be checked.
 * @param {Object} [opts] - Optional parameters.
 * @param {string} [opts.forCountryCode] If the vat number must match a specific country validation scheme, this option can be
 * used with an alpha2 ISO code. If omitted, the helper will try on all supported validation scheme.
 * Note that the check will be ignored if the country specified is not supported by the library (jsvat-next).
 * @return {boolean} Returns true if the VAT number is valid, false otherwise.
 */
export const checkVatNumber = (
  vatNumber: string,
  opts?: { forCountryCode?: string }
): boolean => {
  const cntCode: string | undefined = opts?.forCountryCode;
  // eslint-disable-next-line prettier/prettier
  let countriesCheck: readonly any[]
  if (cntCode) {
    // check if jsvat support the country of the targeted establishment, using the alpha2 ISO code
    countriesCheck = [
      countries.find(c => c?.codes[0] === cntCode?.toUpperCase()),
    ];
  } else {
    countriesCheck = countries;
  }
  if (countriesCheck?.length) {
    const vatCheckRes = checkVAT(vatNumber, countriesCheck);

    if (vatCheckRes.isValid) return true;

    return false;
  }
  return false;
};
