import {
  IJoinedPurchaseOrder,
  EManagePurchaseOrderAction,
  EPurchaseOrderStatus,
} from '@freelancelabs/teoreme-commons';
import { ALink } from 'components/ALink';
import { Flex, Itemize, Link, Menu, MenuItem, Spinner } from 'components/ui';
import {
  ArchiveIcon,
  DotsIcon,
  EyeIcon,
  RefreshIcon,
  CancelIcon,
  DeleteIcon,
  FileSignatureIcon,
} from 'components/ui/icons';
import React from 'react';
import { showDisplayPdfModal } from 'components/modals/DisplayPdfModal';
import { useMutation } from '@tanstack/react-query';
import {
  purchaseOrderResendEmail,
  managePurchaseOrder,
  checkUserHasRole,
  useMe,
  purchaseOrderGetSignatureRequest,
} from '@commons';
import { useShowMessage } from 'hooks/useShowMessage';
import { useState } from 'react';
import { queryClient } from '@commons';
import {
  canDisplayArchivePurchaseButton,
  canDisplayCancelPurchaseOrderButton,
  canDisplayDeletePurchaseOrderButton,
  canDisplayResendPurchaseOrderButton,
  checkDisplayPurchaseOrderMenuList,
  canDisplayCountersignPurchaseOrderButton,
} from 'helpers/business/purchaseOrder';
import { Theme } from 'styles';

export const PurchaseOrderActions = ({
  purchaseOrder,
}: {
  purchaseOrder: Itemize<IJoinedPurchaseOrder>;
}) => {
  const showMessage = useShowMessage();
  const { me } = useMe();
  const { mutateAsync: counterSign, status: counterSignStatus } = useMutation({
    mutationFn: (item: any) =>
      purchaseOrderGetSignatureRequest({
        procedureId: item?.procedureId,
      }),
  });

  const missionLink = `/delivery/missions/${purchaseOrder.mission.reference}`;
  const [loadingAbort, setLoadingAbort] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const ReSendButton = () => {
    const { mutateAsync: onResend, status } = useMutation({
      mutationFn: () =>
        purchaseOrderResendEmail({
          purchaseOrder: {
            reference: purchaseOrder.reference || '',
          },
        }),
    });
    if (status === 'pending') return <Spinner size={20} />;
    if (status === 'success')
      showMessage('success', 'Bon de commande fournisseur envoyé');
    return (
      <Link iconLeft={<RefreshIcon />} onClick={() => onResend()}>
        Relancer
      </Link>
    );
  };

  return (
    <Flex alignItems="center">
      <>
        {
          <Link
            iconLeft={<EyeIcon />}
            onClick={() =>
              showDisplayPdfModal({
                fileLocation: purchaseOrder.file?.fileLocation || '',
                fileName: purchaseOrder.file?.fileName || 'BDC_fournisseur.pdf',
              })
            }
          >
            {/* Voir le bon de commande */}
          </Link>
        }

        {checkDisplayPurchaseOrderMenuList(purchaseOrder) && (
          <Menu
            align="right"
            ml={10}
            menuItems={
              <>
                {canDisplayResendPurchaseOrderButton(purchaseOrder) && (
                  <MenuItem>
                    <ReSendButton />
                  </MenuItem>
                )}
                <MenuItem>
                  <ALink href={missionLink}>
                    <Link iconLeft={<EyeIcon />}>Voir la commande</Link>
                  </ALink>
                </MenuItem>
                {canDisplayCountersignPurchaseOrderButton(purchaseOrder) &&
                  checkUserHasRole(me, 'TEAM_LEADER') && (
                    <MenuItem
                      hidden={
                        purchaseOrder?.status !== EPurchaseOrderStatus.SIGNED ||
                        !checkUserHasRole(me, 'TEAM_LEADER')
                      }
                    >
                      <Link
                        iconLeft={
                          <FileSignatureIcon
                            height="14px"
                            style={{ marginTop: '5px' }}
                          />
                        }
                        onClick={async () => {
                          try {
                            const res = await counterSign(purchaseOrder);
                            const fileName = res?.url;
                            const a = document.createElement('a');
                            a.href = fileName || '';
                            a.target = '_blank';
                            a.click();
                          } catch (error) {
                            console.log(error);
                            showMessage(
                              'error',
                              'Erreur lors de la demande de signature'
                            );
                          }
                        }}
                      >
                        Signer le bon de commande
                      </Link>
                    </MenuItem>
                  )}
                {canDisplayCancelPurchaseOrderButton(purchaseOrder) && (
                  <MenuItem>
                    {loadingAbort ? (
                      <Spinner />
                    ) : (
                      <Link
                        onClick={async () => {
                          setLoadingAbort(true);
                          try {
                            await managePurchaseOrder({
                              reference: purchaseOrder?.reference as string,
                              action: EManagePurchaseOrderAction?.ABORT,
                            });
                            showMessage(
                              'success',
                              'Bon de commande fournisseur Annulé'
                            );
                            queryClient.refetchQueries({
                              queryKey: ['purchaseOrders'],
                              type: 'active',
                            });
                          } catch (e) {
                            //
                          }
                          setLoadingAbort(false);
                        }}
                        iconLeft={<CancelIcon />}
                      >
                        Annuler le bon de commande
                      </Link>
                    )}
                  </MenuItem>
                )}
                {canDisplayArchivePurchaseButton(purchaseOrder) && (
                  <MenuItem>
                    <Link iconLeft={<ArchiveIcon />}>
                      Archiver le bon de commande
                    </Link>
                  </MenuItem>
                )}
                {canDisplayDeletePurchaseOrderButton(purchaseOrder) && (
                  <MenuItem>
                    {loadingDelete ? (
                      <Spinner />
                    ) : (
                      <Link
                        onClick={async () => {
                          setLoadingDelete(true);
                          try {
                            await managePurchaseOrder({
                              reference: purchaseOrder.reference as string,
                              action: EManagePurchaseOrderAction?.DELETE,
                            });
                            showMessage(
                              'success',
                              'Bon de commande fournisseur supprimé'
                            );
                            queryClient.refetchQueries({
                              queryKey: ['purchaseOrders'],
                              type: 'active',
                            });
                          } catch (e) {
                            //
                          }
                          setLoadingDelete(true);
                        }}
                        iconLeft={<DeleteIcon />}
                      >
                        Supprimer le bon de commande
                      </Link>
                    )}
                  </MenuItem>
                )}
              </>
            }
          >
            <DotsIcon fill={Theme?.colors?.primary?.default} fontSize={20} />
          </Menu>
        )}
      </>
    </Flex>
  );
};
