import { FlatUser } from '@freelancelabs/teoreme-commons';
import { IUserMinimal } from '@commons';
import { Box, Button, Text } from 'components/ui';
import { AddBusinessPartnerForm } from 'forms/AddBusinessPartnerForm';
import { AddFreelancePartnerForm } from 'forms/AddFreelancePartnerForm';
import React, { useState } from 'react';
import { create } from 'react-modal-promise';
import { ModalFrame, ModalProps } from './ModalFrame';
import { StaticSelect } from 'components/selects/StaticSelect';

type Props = ModalProps & {
  beforeValidation?: () => void;
  role?: 'FREELANCE_PARTNER' | 'BUSINESS_PARTNER' | 'INTERNAL_COMMERCIAL';
};

export const AddOtherContactModal = ({ onResolve, isOpen, role }: Props) => {
  const [validated, setValidated] = useState(role ? true : false);
  const [contactRole, setContactRole] = useState<string | undefined>(role);
  return (
    <ModalFrame
      isOpen={isOpen}
      onClose={() => onResolve(false)}
      width={850}
      closeIcon
    >
      {!validated && (
        <Text variant="h1" mb={10}>
          Ajouter un contact
        </Text>
      )}
      {!validated && (
        <Box width={1 / 3}>
          <StaticSelect
            options={[
              { value: 'FREELANCE_PARTNER', label: 'Freelance partner' },
              { value: 'BUSINESS_PARTNER', label: "Apporteur d'affaires" },
            ]}
            onChange={value => setContactRole(value as string)}
            value={contactRole as string}
            name="type"
            placeholder="Type de contact"
          />
        </Box>
      )}
      {!validated && (
        <Button
          mt={20}
          onClick={() => setValidated(true)}
          isDisabled={!contactRole}
        >
          Valider
        </Button>
      )}
      <Box>
        {contactRole === 'BUSINESS_PARTNER' && validated && (
          <AddBusinessPartnerForm
            onClose={(newUser: IUserMinimal) => onResolve(newUser)}
          />
        )}
        {contactRole === 'FREELANCE_PARTNER' && validated && (
          <AddFreelancePartnerForm
            onClose={(newUser: IUserMinimal) => onResolve(newUser)}
          />
        )}
      </Box>
    </ModalFrame>
  );
};

export const showAddOtherContactModal = create<Props, FlatUser>(
  AddOtherContactModal
);
