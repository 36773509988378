import {
  EContractorAsEmployee,
  ESocialStatus,
  EOnboardingStep,
} from '@freelancelabs/teoreme-commons';
import { Roles as RolesTypes } from '@freelancelabs/inside-commons/dist/lib/models/enum/role.enum';
import {
  useUserFindMany,
  MAX_COUNT_REMINDER,
  checkDefaultManagerSelected,
  useMe,
  getHumanDate,
} from '@commons';
import { userReSendInvitation } from '@commons';
import { ALink } from 'components/ALink';
import { Header } from 'components/Header';
import { showAddContactModal } from 'components/modals/AddContactModal';
import {
  Box,
  Button,
  Container,
  Flex,
  Input,
  Link,
  Menu,
  MenuItem,
  Spinner,
  SpinnerBox,
  Table,
  TableColumn,
  TableItem,
  Text,
  Tab,
  Status,
  FormLabel,
  DatePickerRange,
  CustomToolTip,
} from 'components/ui';
import { UserSelect } from 'components/selects/UserSelect';
import {
  CancelIcon,
  CheckIcon,
  DotsIcon,
  ExclamationCircleIcon,
  EyeIcon,
  RefreshIcon,
} from 'components/ui/icons';
import { ERoles } from '@commons';
import { formatDate } from '@commons';
import { useDebounce } from 'hooks/useDebounce';
import { useShowMessage } from 'hooks/useShowMessage';
import React, { useState } from 'react';
import { useSetCrumbs } from 'hooks/breadCrumb';
import { useHistory, useParams } from 'react-router-dom';
import { getFullName, UserWithName } from '@commons';
import { userUpdateOne } from '@commons';
import { queryClient } from '@commons';
import { showDialogModal } from 'components/modals/DialogModal';
import { add, getTime } from 'date-fns';
import { useProviderContactsListStore } from 'store';
import { Theme } from 'styles';
import {
  canDisplayOnBoardingButton,
  canDisplayValidateContactButton,
  canDisplayResendContactInvicationButton,
  checkDisplayProviderContactMenuList,
} from 'helpers';
const tabItems = (
  <>
    <Tab variant="secondary" href={'/providers/contacts/all'}>
      Tous
    </Tab>
    <Tab variant="secondary" href={'/providers/contacts/sent-invitations'}>
      Invitations envoyées
    </Tab>
    <Tab variant="secondary" href={'/providers/contacts/on-boarding'}>
      En cours d’embarquement
    </Tab>
    {/* <Tab variant="secondary" href={'/providers/contacts/awaiting'}>
      À valider
    </Tab> */}
    <Tab variant="secondary" href={'/providers/contacts/active'}>
      Actifs
    </Tab>
  </>
);

const Actions = ({ item }: { item: TableItem }) => {
  const showMessage = useShowMessage();
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const onResendInvitationClick = async (
    email: string,
    cognitoUserId: string
  ) => {
    setLoading(true);
    if (email && cognitoUserId) {
      try {
        await userReSendInvitation(email, cognitoUserId);
        showMessage('success', 'Invitation envoyée');
        queryClient.refetchQueries({ queryKey: ['users'], type: 'active' });
      } catch (e) {
        showMessage(
          'error',
          "Une erreur est survenue lors de l'envoie de l'invitation"
        );
      }
    }
    setLoading(false);
  };
  const onStopResendInvitationClick = async (
    email: string,
    cognitoUserId: string
  ) => {
    setLoading(true);
    if (email && cognitoUserId) {
      try {
        await userUpdateOne(cognitoUserId, {
          stopSentInviteEmail: true,
        });
        showMessage('success', 'Relance automatique désactivée');
        queryClient.refetchQueries({ queryKey: ['users'], type: 'active' });
      } catch (e) {
        showMessage(
          'error',
          "Une erreur est survenue lors de l'arret des relances automatique"
        );
      }
    }
    setLoading(false);
  };

  const onValidateClick = async (
    email: string,
    cognitoUserId: string,
    nextMissionSpec: any
  ) => {
    setLoading(true);
    if (email && cognitoUserId) {
      try {
        showDialogModal({
          title: 'Valider le contact fournisseur',
          text: 'Attention : Lors de son embarquement, le contact fournisseur a indiqué :',
          confirmLabel: 'Valider',
          cancelLabel: 'Annuler',
          children: (
            <>
              {nextMissionSpec && !nextMissionSpec.willStepInOnNextMission && (
                <Status variantColor="error">
                  Qu’il n’interviendra pas lors de la prochaine mission
                </Status>
              )}
              {nextMissionSpec &&
                !nextMissionSpec.willUseContractorOnNextMisison && (
                  <Status variantColor="error">
                    Aucun consultant de son établissement fournisseur ne va
                    intervenir lors de la prochaine mission
                  </Status>
                )}
              {nextMissionSpec &&
                nextMissionSpec.socialStatus === ESocialStatus.OTHER && (
                  <Status variantColor="error">
                    Qu’il interviendra lors de la prochaine mission et que son
                    statut social est “Autre”
                  </Status>
                )}
              {nextMissionSpec &&
                nextMissionSpec.contractorAsEmployee ===
                  EContractorAsEmployee.NONE && (
                  <Status variantColor="error">
                    Qu’aucun intervenant de la prochaine mission n’est salarié
                    de l'établissement renseigné
                  </Status>
                )}
              {nextMissionSpec && nextMissionSpec?.juridicalLabel && (
                <Status variantColor="error">
                  Que la nature juridique de son enteprise est :{' '}
                  {nextMissionSpec?.juridicalLabel}
                </Status>
              )}
            </>
          ),

          beforeValidation: async () => {
            await userUpdateOne(cognitoUserId, {
              onboardingStep: {
                done: true,
                ableToAccessSite: true,
                step: EOnboardingStep.STEP_THREE,
              },
            });
            queryClient.refetchQueries({ queryKey: ['user'] });
            history.push('/providers/contacts/active');
          },
        });
      } catch (e) {
        showMessage(
          'error',
          'Une erreur est survenue lors de la validation du contact'
        );
      }
    }
    setLoading(false);
  };

  if (loading) return <Spinner size={20} />;
  let targetPath = '';
  if (item?.onboardingStep?.step === 'STEP_ONE') {
    targetPath = 'information';
  }
  if (item?.onboardingStep?.step === 'STEP_TWO') {
    targetPath = 'establishments';
  }
  if (item?.onboardingStep?.step === 'STEP_THREE') {
    targetPath = 'contractors';
  }
  return (
    <Flex>
      {/* {item.onboardingStep.done && (
        <Link iconLeft={<EyeIcon fill="#00ABEF" />}>
          <ALink href={`/providers/contacts/${item.cognitoUserId}/information`}>
            Voir la fiche contact
          </ALink>
        </Link>
      )} */}
      <Box width={1 / 2}>
        <ALink href={`/providers/contacts/${item.cognitoUserId}/information`}>
          <Link iconLeft={<EyeIcon />}></Link>
        </ALink>
      </Box>
      <Box
        width={1 / 2}
        marginLeft={!checkDisplayProviderContactMenuList(item) ? 30 : ''}
      >
        {checkDisplayProviderContactMenuList(item) && (
          <Menu
            align="right"
            ml={10}
            menuItems={
              <>
                {canDisplayOnBoardingButton(item) && (
                  <MenuItem>
                    <Link
                      iconLeft={
                        <EyeIcon fill={Theme?.colors?.primary?.default} />
                      }
                    >
                      <ALink
                        href={`/providers/contacts/on-boarding-card/${item.cognitoUserId}/${targetPath}`}
                      >
                        Voir la fiche d'embarquement
                      </ALink>
                    </Link>
                  </MenuItem>
                )}
                {canDisplayResendContactInvicationButton(item) && (
                  <MenuItem>
                    <Link
                      onClick={() =>
                        onResendInvitationClick(
                          item.email,
                          item.cognitoUserId || ''
                        )
                      }
                      iconLeft={<RefreshIcon />}
                    >
                      Renvoyer
                    </Link>
                  </MenuItem>
                )}
                {canDisplayResendContactInvicationButton(item) &&
                  !item?.stopSentInviteEmail && (
                    <MenuItem>
                      <Link
                        onClick={() =>
                          onStopResendInvitationClick(
                            item.email,
                            item.cognitoUserId || ''
                          )
                        }
                        iconLeft={<CancelIcon />}
                      >
                        Arreter les relances automatiques
                      </Link>
                    </MenuItem>
                  )}
                {canDisplayValidateContactButton(item) && (
                  <MenuItem>
                    <Link
                      onClick={() =>
                        onValidateClick(
                          item.email,
                          item.cognitoUserId || '',
                          /*item.providerEstablishmentsPopulated.filter(
                            (establishment: IEstablishment) =>
                              establishment.provider?.nextMissionSpec
                          )[0]*/
                          item?.onboardingStep?.tmpData?.nextMissionSpec
                        )
                      }
                      iconLeft={<CheckIcon />}
                    >
                      Valider
                    </Link>
                  </MenuItem>
                )}
              </>
            }
          >
            <DotsIcon fill={Theme?.colors?.primary?.default} fontSize={20} />
          </Menu>
        )}
      </Box>
    </Flex>
  );
};

export const ContactsList: React.FunctionComponent<
  React.PropsWithChildren<unknown>
> = () => {
  const { me } = useMe();
  const { filter: filterParams } = useParams<{ filter: string }>();
  useSetCrumbs(
    [filterParams],
    [
      {
        label: 'fournisseurs',
        path: '/providers',
      },
      {
        label: 'contacts',
        path: '/providers/contacts',
      },
      {
        label:
          filterParams === 'sent-invitations'
            ? 'invitations envoyées'
            : filterParams === 'active'
              ? 'actifs'
              : filterParams === 'awaiting'
                ? 'à valider'
                : 'invitations envoyées',
        path: '/providers/contacts/' + filterParams,
      },
    ]
  );
  const {
    initialSate,
    order,
    sortedBy,
    searchQuery,
    startDate,
    endDate,
    managerSelected,
    page,
    updateProviderContactsListStore,
    resetProviderContactsListStore,
  } = useProviderContactsListStore();

  const debouncedFilterQuery = useDebounce(searchQuery, 500);
  const sortString = (order === 'desc' ? '-' : '') + sortedBy;
  const getRelancesCountText = (item: any): string => {
    if (filterParams === 'all' && item?.isConfirmed) return 'N/A';
    return `${
      item?.automaticInvitationEmailSentCount || 0
    } / ${MAX_COUNT_REMINDER}`;
  };
  const onBoardingFilter: any =
    filterParams === 'sent-invitations'
      ? {
          isConfirmed: false,
        }
      : filterParams === 'on-boarding'
        ? {
            isConfirmed: true,
            'onboardingStep.done': false,
          }
        : filterParams === 'awaiting'
          ? {
              'onboardingStep.step': 'STEP_THREE',
              'onboardingStep.done': true,
              'onboardingStep.ableToAccessSite': false,
            }
          : filterParams === 'active'
            ? {
                'onboardingStep.done': true,
                'onboardingStep.ableToAccessSite': true,
              }
            : {};

  React.useEffect(() => {
    // if (filterParams === 'active') {
    //   updateProviderContactsListStore({
    //     //@ts-ignore
    //     order: 'asc',
    //     sortedBy: 'lastName firstName',
    //   });
    //   sortString = 'lastName firstName';
    //   onBoardingFilter = {
    //     'onboardingStep.done': true,
    //     'onboardingStep.ableToAccessSite': true,
    //   };
    // } else {
    //   if (filterParams === 'awaiting') {
    //     onBoardingFilter = {
    //       'onboardingStep.step': 'STEP_THREE',
    //       'onboardingStep.done': true,
    //       'onboardingStep.ableToAccessSite': false,
    //     };
    //   } else {
    //     onBoardingFilter = {};
    //   }
    //   updateProviderContactsListStore({
    //     //@ts-ignore
    //     order: 'desc',
    //     sortedBy: 'updatedAt',
    //   });
    //   sortString = '-updatedAt';
    // }
  }, [filterParams]);

  const columns: TableColumn[] = [
    {
      key: 'updatedAt',
      text: 'DATE',
      render: (item: TableItem) => formatDate(item.updatedAt),
    },
    {
      key: 'name',
      text: 'NOM',
      render: (item: TableItem) => getFullName(item as UserWithName),
    },
    // {
    //   key: 'phone',
    //   text: 'TÉLÉPHONE',
    // },
    { key: 'email', text: 'EMAIL' },
    // {
    //   key: 'establishments',
    //   text: 'ENTREPRISE',
    //   render: item =>
    //     item?.providerEstablishmentsPopulated
    //       ?.map(
    //         (e: IEstablishment) =>
    //           `${transformText(getEstablishmentName(e), 'capitalize')} ${
    //             e.provider?.tempContacts &&
    //             e.provider?.tempContacts.filter(
    //               // @ts-ignore
    //               c => c.cognitoUserId === item.cognitoUserId
    //             ).length > 0
    //               ? '(À rattacher) '
    //               : ''
    //           }`
    //       )
    //       .join(', ') || (
    //       <div style={{ fontStyle: 'italic' }}>Pas d’entreprise rattachée</div>
    //     ),
    //   hidden: filterParams === 'sent-invitations',
    // },
    {
      key: 'accountManager',
      text: 'SUIVI PAR',
      render: (item: TableItem) =>
        item.accountManager &&
        item.accountManager.firstName &&
        item.accountManager.lastName
          ? item.accountManager.firstName + ' ' + item.accountManager.lastName
          : item.accountManager
            ? item.accountManager.email
            : 'N/A',
      hidden: filterParams === 'active',
    },
    {
      key: 'state',
      text: 'STATUT',
      align: 'flex-start',
      render: (item: TableItem) => {
        //@ts-ignore inside
        const emailDelivery = item?.emailDelivery;
        const emailIsUnreachable = emailDelivery?.isUnreachable;
        const getNbStep = (str: string) => {
          switch (str) {
            case 'STEP_ONE':
              return 1;
            case 'STEP_TWO':
              return 2;
            case 'STEP_THREE':
              return 3;
          }
        };
        if (!item?.isConfirmed) {
          return (
            <Status
              variantColor={item?.stopSentInviteEmail ? 'error' : 'primary'}
            >
              {item?.stopSentInviteEmail
                ? 'Invitation auto désactivé , invitation envoyée le'
                : 'Invitation envoyée le'}{' '}
              {item?.lastActivationEmailSentDate
                ? `${formatDate(item?.lastActivationEmailSentDate)}`
                : 'N/A'}
              {emailIsUnreachable && (
                <Box>
                  <CustomToolTip
                    color={Theme?.colors?.warning?.default}
                    id={item?.cognitoUserId}
                    //@ts-ignore inside
                    text={`${getHumanDate(emailDelivery?.bounceData?.bouncedAt)} :  Email non transmis`}
                  >
                    <ExclamationCircleIcon
                      style={{ marginLeft: '10px', cursor: 'help' }}
                      fill={Theme?.colors?.warning?.default}
                    />
                  </CustomToolTip>
                </Box>
              )}
            </Status>
          );
        }
        if (
          item?.onboardingStep?.ableToAccessSite &&
          item?.onboardingStep?.done
        ) {
          return <Status variantColor="success">Actif</Status>;
        }
        if (item?.onboardingStep !== true && item?.onboardingStep) {
          return (
            <Status variantColor="warning">
              En cours d'embarquement Étape :{' '}
              {getNbStep(item?.onboardingStep?.step)} / 2
            </Status>
          );
        }
      },
    },
    {
      key: 'automaticInvitationEmailSentCount',
      text: 'RELANCES',
      align: 'flex-end',
      hidden: ['active', 'on-boarding'].includes(filterParams),
      render: item => <>{getRelancesCountText(item)}</>,
    },
    {
      key: 'actions',
      text: 'ACTIONS',
      align: 'flex-end',
      render: item => <Actions item={item} />,
    },
  ];
  const {
    data: contactsQuery,
    status,
    isFetching,
    isPending,
  } = useUserFindMany(
    {
      filterObject: {
        accountManager: checkDefaultManagerSelected(me, managerSelected)
          ? managerSelected
          : undefined,
        'roles.name': ERoles.PROVIDER_CONTACT,
        // isConfirmed: filterParams !== 'sent-invitations' ? true : false,
        ...onBoardingFilter,
        $or: [
          { firstName: { $regex: debouncedFilterQuery, $options: 'i' } },
          { lastName: { $regex: debouncedFilterQuery, $options: 'i' } },
          {
            email: {
              $regex: debouncedFilterQuery?.replace('+', '\\+').trim(),
              $options: 'i',
            },
          },
        ],
        $and:
          startDate && endDate
            ? [
                {
                  $expr: {
                    $gte: [{ $toLong: `$updatedAt` }, getTime(startDate)],
                  },
                },
                {
                  $expr: {
                    $lte: [
                      { $toLong: `$updatedAt` },
                      getTime(add(endDate, { months: 1 })),
                    ],
                  },
                },
              ]
            : undefined,
      },
      sort: sortString,
      limit: 20,
      skip: page * 20,
    },
    { populateProviderEstablishments: filterParams !== 'sent-invitations' }
  );

  const items =
    contactsQuery?.users.map((u: any) => ({
      key: u.cognitoUserId || '',
      ...u,
    })) || [];

  const totalCount = contactsQuery?.totalCount;
  const loading = isPending;

  const onAddContact = () =>
    showAddContactModal({ contactRole: 'PROVIDER_CONTACT' });
  React.useEffect(() => {
    //
  }, [filterParams, managerSelected]);
  return (
    <Box>
      <Header
        tabs={tabItems}
        actions={<Button onClick={onAddContact}>Ajouter un contact</Button>}
      >
        <Text variant="h1">Contacts Fournisseur</Text>
      </Header>
      <Container>
        <Flex mt="10px" mb="10px" width={1 / 1}>
          <Box width={3 / 12} mr={10}>
            <FormLabel>Recherche</FormLabel>
            <Input
              isFullWidth
              type="search"
              value={searchQuery}
              placeholder="Rechercher ..."
              onChange={(e: any) => {
                updateProviderContactsListStore({
                  searchQuery: e?.target?.value,
                });
              }}
            />
          </Box>
          <Box width={3 / 12} mr={10}>
            <FormLabel>Chargés de comptes</FormLabel>
            <UserSelect
              placeholder="Tous les Chargés de comptes"
              role={RolesTypes.ACCOUNT_MANAGER}
              onChange={value =>
                updateProviderContactsListStore({
                  managerSelected: value,
                })
              }
              value={managerSelected}
            />
          </Box>
          <Box width={3 / 12} mr={10}>
            <FormLabel pl={20}>PÉRIODE</FormLabel>
            <DatePickerRange
              startDate={startDate}
              endDate={endDate}
              isClearable={true}
              setStartDate={value => {
                updateProviderContactsListStore({ startDate: value });
              }}
              setEndDate={value => {
                updateProviderContactsListStore({ endDate: value });
              }}
            />
          </Box>
          <Box ml={10} mt={10}>
            <Flex
              justifyContent={'center'}
              alignItems={'center'}
              flexWrap="wrap"
              alignContent="center"
              width={1 / 1}
              height={'100%'}
            >
              <Link
                isDisabled={initialSate}
                iconLeft={
                  <RefreshIcon
                    fill={
                      initialSate ? Theme?.colors?.grey?.default : undefined
                    }
                  />
                }
                mt={'5px'}
                onClick={() =>
                  !initialSate ? resetProviderContactsListStore({}) : false
                }
              >
                Réinitialiser
              </Link>
            </Flex>
          </Box>
        </Flex>
        {loading && <SpinnerBox />}
        {!loading && (
          <Table
            isLoading={isFetching}
            columns={columns}
            items={items}
            sortedBy={sortedBy}
            order={order}
            page={page}
            total={totalCount}
            perPage={totalCount && totalCount < 20 ? totalCount : 20}
            onSortedChange={(sortedBy: string, order: 'asc' | 'desc') => {
              //@ts-ignore
              updateProviderContactsListStore({ sortedBy, order });
            }}
            onChangePage={page => updateProviderContactsListStore({ page })}
          />
        )}
      </Container>
    </Box>
  );
};
