import React, { useState } from 'react';
import { ICompanyGetResponse } from '@freelancelabs/teoreme-commons';
import {
  Box,
  Text,
  Table,
  TableItem,
  Itemize,
  Flex,
  CheckSwitch,
  Menu,
  MenuItem,
  Link,
  Button,
  CustomToolTip,
  Spinner,
} from 'components/ui';
import { useSetCrumbs } from 'hooks/breadCrumb';
import { useParams } from 'react-router-dom';
import {
  useEstablishmentFindOne,
  getTradeNameSafe,
  PROVIDER_FORMULA_TAG_WHITELIST,
  MIXED_TAG_INPUT_SETTING,
  cutLongText,
  transformFormulaToTag,
  useEstablishmentUpdateOne,
  queryClient,
  useMe,
  checkUserHasRole,
} from '@commons';
import { useCustomerEstablishmentFeeListStore } from 'store';
import { MixedModeTagify } from 'components/InputTags';
import { DotsIcon, EditIcon } from 'components/ui/icons';
import { showCreateOrUpdateStandByDutyModal } from 'components/modals/clients/CreateOrUpdateStandByDutyModal';
import { showImportStandByDutyModal } from 'components/modals/clients/ImportStandByDutyModal';
import { Theme } from 'styles';

const ActiveFormula = ({
  item,
  establishment,
}: {
  item: TableItem;
  establishment: ICompanyGetResponse;
}) => {
  const [active, setActive] = useState(item?.active);
  const [isLoading, setIsLoading] = useState(false);
  const { mutateAsync: updateEstablishment } = useEstablishmentUpdateOne();
  const handleChange = async (checked: boolean) => {
    setActive(checked);
    setIsLoading(true);
    try {
      await updateEstablishment({
        uuid: establishment?.uuid,
        //@ts-ignore
        updateStandByDutyFormulas: [{ uuid: item?.uuid, active: checked }],
      });
      queryClient.refetchQueries({ queryKey: [establishment?.uuid] });
    } catch (e) {
      setActive(!checked);
      queryClient.refetchQueries({ queryKey: [establishment?.uuid] });
      setIsLoading(false);
    }
    setIsLoading(false);
  };
  return (
    <CheckSwitch
      checked={active}
      id={`active_${item.uuid}`}
      onChange={e => handleChange(e?.target?.checked)}
    >
      {isLoading ? <Spinner /> : <></>}
    </CheckSwitch>
  );
};
type Item = Itemize<any>;
const columns = (establishment: ICompanyGetResponse) => [
  {
    key: 'active',
    text: 'Active',
    render: (item: Item) => (
      <ActiveFormula item={item} establishment={establishment} />
    ),
  },
  {
    key: 'name',
    text: 'Titre',
    render: (item: Item) => <Text maxWidth={150}>{item?.name}</Text>,
  },
  {
    key: 'description',
    text: 'DESCRIPTION',
    render: (item: Item) => (
      <Box maxWidth={200}>
        <CustomToolTip text={item?.description} id={item?.uuid}>
          <Text fontSize={9}>
            {cutLongText(item?.description, 100) || 'N/A'}
          </Text>
        </CustomToolTip>
      </Box>
    ),
  },
  // {
  //   key: 'margin',
  //   text: 'Taux de marque',
  //   render: (item: Item) => <Text>{item?.margin}%</Text>,
  // },
  // {
  //   key: 'unit',
  //   text: 'Unité de saisie',
  //   render: (item: Item) => <Text>{item?.unit}</Text>,
  // },
  {
    key: 'providerFormula',
    text: 'FORMULE FOURNISSEUR',
    render: (item: Item) => (
      <Flex
        height={'100%'}
        flexWrap="wrap"
        display="inline-flex"
        justifyContent={'center'}
        alignContent={'center'}
        alignItems={'center'}
      >
        <MixedModeTagify
          readOnly
          settings={{
            ...MIXED_TAG_INPUT_SETTING,
            whitelist: PROVIDER_FORMULA_TAG_WHITELIST,
          }}
          value={transformFormulaToTag(
            item?.providerFormula,
            PROVIDER_FORMULA_TAG_WHITELIST
          )}
        />
      </Flex>
    ),
  },
  {
    key: 'actions',
    text: 'ACTIONS',
    render: (item: Item) => {
      return (
        <Flex alignItems="center">
          <Menu
            align="right"
            menuItems={
              <>
                <MenuItem>
                  <Link
                    iconLeft={<EditIcon />}
                    onClick={() =>
                      showCreateOrUpdateStandByDutyModal({
                        establishment: establishment as ICompanyGetResponse,
                        standByDuty: item,
                        from: 'ESTABLISHMENT',
                      })
                    }
                  >
                    Modifier
                  </Link>
                </MenuItem>
              </>
            }
          >
            <DotsIcon fill={Theme?.colors?.primary?.default} fontSize={20} />
          </Menu>
        </Flex>
      );
    },
  },
];
export const StandByDuty = () => {
  const { me } = useMe();
  const { uuid } = useParams<{ uuid: string }>();
  const { data: establishment } = useEstablishmentFindOne(uuid);
  const { order, sortedBy, page, updateCustomerEstablishmentFeeListStore } =
    useCustomerEstablishmentFeeListStore();
  useSetCrumbs(
    [establishment],
    [
      {
        label: 'clients',
        path: '/clients',
      },
      {
        label: 'établissements',
        path: `/clients/establishments`,
      },
      {
        label: establishment ? getTradeNameSafe(establishment) : '-',
        path: `/clients/establishments/${establishment?.uuid}`,
      },
      {
        label: 'Gestion des prestations complémentaires',
        path: `/clients/establishments/${establishment?.uuid}/fee`,
      },
    ]
  );
  const onSortedChange = React.useCallback(
    (sortBy: string, order: 'asc' | 'desc'): void => {
      //@ts-ignore
      updateCustomerEstablishmentFeeListStore({ sortBy, order });
    },
    []
  );
  //@ts-ignore
  const standByDutyFormulas = establishment?.customer?.standByDutyFormulas;
  const isFetching = false;

  const items: Item[] =
    standByDutyFormulas?.map((f: any) => ({
      ...f,
      key: f.uuid,
    })) || [];
  const totalCount = items?.length;
  return (
    <Box p={20}>
      <Text variant="h2" mb={20}>
        Types de prestation
      </Text>
      <Flex mb={10} mt={10} width={1 / 1} justifyContent={'flex-end'}>
        {checkUserHasRole(me, 'TEAM_LEADER') && (
          <Box mr={10}>
            <Button
              onClick={() =>
                showImportStandByDutyModal({
                  establishment: establishment as ICompanyGetResponse,
                })
              }
            >
              Importer depuis un autre etablissement
            </Button>
          </Box>
        )}

        <Box>
          <Button
            onClick={() =>
              showCreateOrUpdateStandByDutyModal({
                establishment: establishment as ICompanyGetResponse,
                from: 'ESTABLISHMENT',
              })
            }
          >
            Créer un type de prestation complémentaire
          </Button>
        </Box>
      </Flex>
      <Box>
        <Table
          columns={columns(establishment as ICompanyGetResponse)}
          items={items}
          sortedBy={sortedBy}
          order={order}
          page={page}
          total={totalCount}
          perPage={totalCount && totalCount < 20 ? totalCount : 20}
          onSortedChange={onSortedChange}
          onChangePage={page =>
            updateCustomerEstablishmentFeeListStore({ page })
          }
          isLoading={isFetching}
        />
      </Box>
    </Box>
  );
};
