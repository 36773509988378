import { Box, Button, FormControl, TextArea, CheckBox } from 'components/ui';
import { useState } from 'react';
import { create } from 'react-modal-promise';
import { ModalFrame, ModalProps } from '../ModalFrame';

type Props = ModalProps & {
  beforeValidation?: () => void;
  text: string;
  label: string;
  type?: string;
};

export const UpdateInvoiceLineText = ({
  onResolve,
  isOpen,
  text,
  label,
  type,
}: Props) => {
  const [textValue, setText] = useState(text);
  const [updateMission, setUpdateMission] = useState(false);
  return (
    <ModalFrame
      isOpen={isOpen}
      onClose={() => onResolve(false)}
      width={550}
      closeIcon
    >
      <FormControl label={label}>
        <TextArea
          value={textValue}
          onChange={(e: any) => setText(e?.target?.value)}
          minRows={3}
        />
      </FormControl>
      {type === 'PRESTATION' && (
        <Box mb={20}>
          <CheckBox
            checked={updateMission}
            onChange={e => setUpdateMission(!updateMission)}
            mb={10}
            key={'updateMission'}
            id={'updateMission'}
          >
            Appliquer à l’ensemble des factures de cette mission
          </CheckBox>
        </Box>
      )}
      <Button
        onClick={() =>
          onResolve(
            type === 'PRESTATION'
              ? { textValue: textValue?.trim(), updateMission }
              : textValue
          )
        }
      >
        Valider
      </Button>
    </ModalFrame>
  );
};

export const ShowUpdateInvoiceLineText = create<Props, boolean>(
  UpdateInvoiceLineText
);
