import { useMe, REACT_APP_SEED_BUILD_ID } from '@commons';
import { TopBar } from 'components/TopBar';
import { Text, Box, Flex, SpinnerBox } from 'components/ui';
import { useBreadCrumb } from 'hooks/breadCrumb';
import React, { useEffect } from 'react';
import { Theme } from 'styles';
import { ManagerDetailsForm } from 'forms/manager/ManagerDetailsForm';
import { TransferCustomerAccountForm } from 'forms/manager/TransferCustomerAccount';

export type Props = {};

export const UserRoute: React.FC<React.PropsWithChildren<Props>> = () => {
  const { me } = useMe();
  const { setCrumbs } = useBreadCrumb();
  useEffect(() => {
    setCrumbs([
      {
        label: 'Paramètres de compte',
        path: '/user',
      },
    ]);
  }, []);

  return (
    <Box>
      <Box>
        <TopBar />
      </Box>
      {me ? (
        <Flex minHeight={'100vh'}>
          <Box
            backgroundColor={Theme.colors.lightGray}
            width={1 / 2}
            pr={'10%'}
            pl={'10%'}
          >
            <Flex justifyContent="center" mt={40}>
              <Box>
                <Text variant="h2">Paramètres de compte</Text>
                <Text variant="h3" mb={20}>
                  Informations de connexion
                </Text>
                <ManagerDetailsForm defaultValues={me} />
                <Box mt={20}>
                  <Text>Version : {REACT_APP_SEED_BUILD_ID}</Text>
                </Box>
              </Box>
            </Flex>
          </Box>
          <Box width={1 / 2} p={'10%'}>
            <Flex justifyContent="flex-start" mt={20}>
              <Text variant="h2">Transférer un compte client</Text>
            </Flex>
            <Flex justifyContent="center" mt={20}>
              <TransferCustomerAccountForm />
            </Flex>
          </Box>
        </Flex>
      ) : (
        <SpinnerBox />
      )}
    </Box>
  );
};

export default UserRoute;
