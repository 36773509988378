import { IJoinedMission, EMissionStatus } from '@freelancelabs/teoreme-commons';
import { useMissionFindMany, FR_STATUS } from '@commons';
import { format } from 'date-fns';
import {
  Link,
  Container,
  Flex,
  Box,
  Text,
  Table,
  TableColumn,
  SpinnerBox,
  Status,
  Itemize,
  ALink,
} from 'components/ui';

import { EyeIcon } from 'components/ui/icons';
import { useSetCrumbs } from 'hooks/breadCrumb';
import { useGetMission } from 'hooks/missionHooks';
import React from 'react';
type Item = Itemize<IJoinedMission>;
const LIMIT = 5;
export const AmendmentList = () => {
  const { mission, reference } = useGetMission();
  const [order, setOrder] = React.useState<'desc' | 'asc'>('asc');
  const [sortedBy, setSortedBy] = React.useState<string>('startAt');
  const [page, setPage] = React.useState(0);
  const sortString = (order === 'desc' ? '-' : '') + sortedBy;
  useSetCrumbs(
    [],
    [
      { label: 'prestations', path: '/delivery' },
      { label: 'missions', path: '/delivery/missions' },
      {
        label: mission?.displayReference || 'nouvelle mission',
        path: `/delivery/missions/${reference}`,
      },
      {
        label: 'avenants',
        path: `/delivery/missions/${reference}/amendment`,
      },
    ]
  );
  const columns: TableColumn<Item>[] = [
    { key: 'displayReference', text: 'RÉF.', sortKey: 'displayReference' },
    {
      key: 'startAt',
      text: 'DATE DE DÉBUT',
      sortKey: 'startAt',
      render: item =>
        // @ts-ignore
        !!item?.startAt && format(item?.startAt, 'dd/MM/yyyy'),
    },
    {
      key: 'endAt',
      text: 'DATE DE FIN',
      sortKey: 'endAt',
      render: item =>
        // @ts-ignore
        !!item?.endAt && format(item?.endAt, 'dd/MM/yyyy'),
    },
    {
      key: 'status',
      text: 'STATUT',
      sortKey: 'status',
      render: item => (
        <Status
          variantColor={
            item.status === EMissionStatus.ABORTED ||
            item.status === EMissionStatus.TERMINATED
              ? 'error'
              : item.status === EMissionStatus.DRAFT
                ? 'warning'
                : item.status === EMissionStatus.VALIDATED
                  ? 'success'
                  : 'grey'
          }
        >
          {FR_STATUS[item.status]}
          {/* {item.status === EMissionStatus.DRAFT && 'Brouillon'}
          {item.status === EMissionStatus.VALIDATED && 'Validé'} */}
        </Status>
      ),
    },
    {
      key: 'actions',
      text: 'ACTIONS',
      render: item => (
        <ALink href={`/delivery/missions/${item?.reference}`}>
          <Link iconLeft={<EyeIcon />}>Voir la fiche mission</Link>
        </ALink>
      ),
    },
  ];
  const onSortedChange = React.useCallback(
    (sortBy: string, order: 'asc' | 'desc'): void => {
      setSortedBy(sortBy);
      setOrder(order);
    },
    []
  );
  const {
    data: missionsQuery,
    status,
    isFetching,
  } = useMissionFindMany({
    filterObject: {
      //@ts-ignore
      parent: mission?.parent?.reference || mission?.reference,
    },
    limit: LIMIT,
    skip: LIMIT * page,
    sort: sortString,
  });
  const totalCount = missionsQuery?.totalCount;
  const loading = status === 'pending';
  const items: Item[] =
    missionsQuery?.missions?.map(m => ({
      ...m,
      key: m.reference,
    })) || [];
  return (
    <Box>
      <Text variant="h2">Avenants</Text>
      <Box>
        <Container p="20px 0">
          <Flex justifyContent="space-between" ml={20} mr={20} mb={10}></Flex>
          {loading && <SpinnerBox />}
          {!loading &&
            (items?.length ? (
              <Table<Item>
                columns={columns}
                items={items}
                sortedBy={sortedBy}
                order={order}
                page={page}
                total={totalCount}
                perPage={totalCount && totalCount < LIMIT ? totalCount : LIMIT}
                onSortedChange={onSortedChange}
                onChangePage={page => setPage(page)}
                opacity={1}
                isLoading={isFetching}
              />
            ) : (
              'Aucun Avenant pour cette mission'
            ))}
        </Container>
      </Box>
    </Box>
  );
};
