import {
  EAdditionalServiceType,
  IAdditionalService,
} from '@freelancelabs/teoreme-commons';
import { missionUpdateOne } from '@commons';
import { queryClient } from '@commons';
import {
  Flex,
  Box,
  Text,
  Button,
  FormControl,
  BlocInformation,
  DatePickerControlled,
} from 'components/ui';
import { useForm } from 'react-hook-form';
import { useState } from 'react';
import { create } from 'react-modal-promise';
import { ModalFrame, ModalProps } from '../ModalFrame';
type FormValues = {
  uuid: string;
  validityEnd: Date | null;
};
type Props = ModalProps & {
  beforeValidation?: () => void;
  service: IAdditionalService;
  mission: { reference: string };
};

export const CancelRCPROModal = ({
  onResolve,
  isOpen,
  service,
  mission,
}: Props) => {
  const [loading, setLoading] = useState(false);
  const { watch, handleSubmit, control } = useForm<FormValues>({
    defaultValues: {
      validityEnd: null,
    },
  });
  const [messageRCPRO, setMessageRCPRO] = useState(false);
  const onSubmit = async (formValues: FormValues) => {
    setLoading(true);
    if (service && mission) {
      const submitData = {
        reference: mission.reference,
        mission: {
          additionalServices: {
            cancel: [
              {
                uuid: service.uuid,
                validityEnd: formValues.validityEnd as Date,
              },
            ],
          },
        },
      };
      try {
        //@ts-ignore
        await missionUpdateOne(submitData);
        queryClient.refetchQueries({ queryKey: [mission.reference] });
        if (
          (service.type =
            EAdditionalServiceType.PROFESSIONAL_INDEMNITY_INSURANCE)
        ) {
          setMessageRCPRO(true);
        }
      } catch (e) {
        //
      }
    }
    setLoading(false);
  };
  const validityEnd = watch('validityEnd');
  return (
    <ModalFrame
      isOpen={isOpen}
      onClose={() => onResolve(false)}
      width={870}
      closeIcon
    >
      {!messageRCPRO ? (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box mt={20}>
            <Text variant="h2">
              Êtes-vous sûr de vouloir résilier la RC Pro pour cette
              établissement ?
            </Text>
            <Text variant="p" fontSize={12}>
              Suite à cette résiliation, l’attestation RC Pro Freelance.com de
              l’entreprise fournisseur sera invalidée sur PROVIGIS. Une nouvelle
              RC PRO valide devra être téléchargée par l’entreprise fournisseur
              dans le cadre de ses obligations de conformité.
            </Text>
            <Box mt={20}>
              <FormControl label="Date de désactivation" required>
                <Box width={1 / 4}>
                  <DatePickerControlled
                    control={control}
                    name="validityEnd"
                    valueName="selected"
                    dateFormat="MM/yyyy"
                    showMonthYearPicker
                    showFullMonthYearPicker
                  />
                </Box>
              </FormControl>
            </Box>
            <Box mb={20}>
              <BlocInformation>
                <p>
                  Le service sera facturé jusqu’à la date de désactivation du
                  service (incluse).
                </p>
              </BlocInformation>
            </Box>
            <Flex>
              <Button
                type="submit"
                isLoading={loading}
                isDisabled={validityEnd === null}
              >
                Désactiver
              </Button>
            </Flex>
          </Box>
        </form>
      ) : (
        <Box mt={20}>
          <Text variant="h2" mb={20}>
            Votre demande de résiliation a bien été prise en compte.
          </Text>
          <Text mb={20}>
            Une demande d’invalidation de la RC Pro Freelance.com de ce
            fournisseur a été envoyée aux équipes PROVIGIS en leur précisant le
            mois de résiliation de la RC PRO. A noter : la RC PRO devra être
            facturé jusqu’au dernier mois de validité de la RC PRO inclus.
          </Text>
          <Box>
            <Button
              type="button"
              isLoading={loading}
              onClick={() => onResolve()}
            >
              C’est noté
            </Button>
          </Box>
        </Box>
      )}
    </ModalFrame>
  );
};

export const showCancelRCPROModal = create<Props, boolean>(CancelRCPROModal);
