import {
  INVOICE_FOREIGN_SEARCH_FIELDS,
  INVOICE_SEARCH_DEBOUNCE_DELAY,
  INVOICE_STATUS_SELECT,
  checkDefaultManagerSelected,
  exportFileXlxs,
  formatInvoiceOrAdditionalActivitySort,
  getCustomerEstGescomCode,
  getEstablishmentName,
  getProviderEstGescomCode,
  isMileStoneInvoice,
  useInvoiceFindMany,
  useInvoiceUpdateOne,
  useMe,
} from '@commons';

import {
  EBillingType,
  EExportModel,
  EInvoiceStatus,
  EInvoiceType,
  IJoinedInvoice,
} from '@freelancelabs/teoreme-commons';
import { ALink } from 'components/ALink';
import { Header } from 'components/Header';
import { showDisplayPdfModal } from 'components/modals/DisplayPdfModal';
import { UserSelect } from 'components/selects/UserSelect';

import { queryClient } from '@commons';
import EXPORT_IMG from 'assets/images/export_excel_tutorial.png';
import { showDialogModal } from 'components/modals/DialogModal';
import {
  Box,
  Button,
  Container,
  CustomToolTip,
  Flex,
  Input,
  Itemize,
  Link,
  Menu,
  MenuItem,
  SpinnerBox,
  Status,
  Tab,
  Table,
  TableColumn,
  Text,
} from 'components/ui';
import { SideBar } from 'components/ui/BootStrap/SideBar';
import {
  AddIcon,
  CheckIcon,
  CloseIcon,
  DotsIcon,
  EyeIcon,
  MoreFilterIcon,
  RefreshIcon,
} from 'components/ui/icons';
import { useSetCrumbs } from 'hooks/breadCrumb';
import { useDebounce } from 'hooks/useDebounce';
import { useShowMessage } from 'hooks/useShowMessage';
import { snakeCase } from 'lodash';
import * as React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useCreditNoteProviderListStore } from 'store';
import { useGlobalStore } from 'store/global';
import { Theme } from 'styles';
import { InvoicesFiltersList } from '../../InvoicesFiltersList';
import { getObjectFilterInvoice, getSearchInKeysText } from '../../helpers';
import { InvoiceStatusEnum } from '../../types';
import { StaticSelect } from 'components/selects/StaticSelect';

type Item = Itemize<IJoinedInvoice>;
const INVOICE_STATUS_SELECT_CUSTOM = INVOICE_STATUS_SELECT;
const Findex = INVOICE_STATUS_SELECT.findIndex(
  state => state.key === EInvoiceStatus.REJECTED
);
INVOICE_STATUS_SELECT_CUSTOM[Findex].label = 'Refusé';

const columns = (
  status: 'all' | 'projected' | 'validated' | 'sent' | 'archived' | 'paid',
  history: any,
  showMessage: any,
  updateInvoice: (data: any) => void,
  invoiceTypeText: any
) =>
  [
    {
      key: 'displayReference',
      sortKey: status !== 'all' && 'mission.displayReference',
      text: 'REF.MISSION',
      render: (item: IJoinedInvoice) => (
        <ALink
          href={`/delivery/missions/${item?.mission?.reference}`}
          target="_blank"
        >
          {item?.comment ? (
            <CustomToolTip
              text={item?.comment || 'Aucun commentaire'}
              id={item?.uuid}
            >
              {item.mission.displayReference}
            </CustomToolTip>
          ) : (
            item.mission.displayReference
          )}
        </ALink>
      ),
    },
    {
      key: 'structure',
      sortKey: status !== 'all' && 'mission.sage.sucture',
      text: 'STRU',
      render: (item: IJoinedInvoice) => item?.mission?.sage?.structure,
    },
    {
      key: 'month',
      sortKey: status !== 'all' && 'month',
      text: 'MOIS',
      render: (item: IJoinedInvoice) => {
        if (
          isMileStoneInvoice(item?.invoiceType) ||
          item?.mission?.billing?.type === EBillingType.FLAT_RATE
        ) {
          return `${
            new Date(item?.createdAt as Date)?.getUTCMonth() + 1
          }/${new Date(item?.createdAt as Date)?.getUTCFullYear()}`;
        }

        return `${new Date(item?.month as Date)?.getUTCMonth() + 1}/${new Date(
          item?.month as Date
        )?.getUTCFullYear()}`;
      },
    },
    {
      key: 'invoiceType',
      sortKey: status !== 'all' && 'invoiceType',
      text: 'TYPE',
      render: (item: IJoinedInvoice) => {
        const isMarginInvoice = item?.isMarginInvoice ? ' (marge)' : '';
        return invoiceTypeText(item.invoiceType) + isMarginInvoice;
      },
    },
    {
      key: 'statusChangedAt',
      sortKey: status !== 'all' && 'statusChangedAt',
      text: 'REÇUE LE',
      hidden: status !== 'sent',
      render: (item: IJoinedInvoice) =>
        //@ts-ignore
        item?.statusChangedAt && item?.statusChangedAt.toLocaleDateString(),
    },
    {
      key: 'estCustomer',
      sortKey: status !== 'all' && 'estCustomer.businessName',
      text: 'ÉTABLIS. CLIENT',
      render: (item: IJoinedInvoice) => (
        <CustomToolTip
          text={<Box>{getCustomerEstGescomCode(item)}</Box>}
          id={item?.uuid + '_structCustomer'}
        >
          <ALink
            href={`/clients/establishments/${item?.estCustomer?.uuid}`}
            target="_blank"
          >
            {getEstablishmentName(item?.estCustomer)}
          </ALink>
        </CustomToolTip>
      ),
    },
    {
      key: 'estProvider',
      sortKey: status !== 'all' && 'estProvider.businessName',
      text: 'ÉTABLIS. FOURN.',
      render: (item: IJoinedInvoice) => (
        <CustomToolTip
          text={<Box>{getProviderEstGescomCode(item)}</Box>}
          id={item?.uuid + '_structProvider'}
        >
          <ALink
            href={`/providers/establishments/${item?.estProvider?.uuid}`}
            target="_blank"
          >
            {getEstablishmentName(item.estProvider)}
          </ALink>
        </CustomToolTip>
      ),
    },
    {
      key: 'totalBeforeTaxes',
      sortKey: status !== 'all' && 'totalBeforeTaxes',
      text: `Montant HT`,
      //hidden: status === 'awaiting',
      render: (item: IJoinedInvoice) =>
        `${item?.totalBeforeTaxes?.toFixed(2) || 'N/A'} €`,
    },
    {
      key: 'status',
      sortKey: status !== 'all' && 'status',
      text: `STATUT`,
      hidden: status !== 'paid',
      render: (item: IJoinedInvoice) => (
        <Status variantColor="success">
          Payée
          {item?.sage?.paymentDetails?.paymentDate
            ? '- Virement émis le : ' +
              item?.sage?.paymentDetails?.paymentDate?.toLocaleDateString()
            : ''}
        </Status>
      ),
    },
    {
      key: 'sageInvoice',
      text: 'FACTURE GESCOM',
      render: (item: IJoinedInvoice) => {
        if (item?.sage?.invoiceId) {
          return (
            <ALink
              href={`/orders/invoices/provider/time-spent/${item?.uuid}`}
              target="_blank"
            >
              <Status variantColor="success">{item?.sage?.invoiceId}</Status>
            </ALink>
          );
        }
        return <Status variantColor="error"> N/A </Status>;
      },
    },
    {
      key: 'sageOriginalInvoice',
      text: 'REF FACTURE ANNULEE',
      render: (item: IJoinedInvoice) => {
        if (item?.sage?.originalInvoiceId) {
          return (
            <ALink
              href={`/orders/invoices/provider/time-spent/${item?.originalInvoice?.uuid}`}
              target="_blank"
            >
              <Status variantColor="success">
                {item?.sage?.originalInvoiceId}
              </Status>
            </ALink>
          );
        }
        return <Status variantColor="error"> N/A </Status>;
      },
    },
    {
      key: 'actions',
      text: 'ACTIONS',
      align: 'flex-end',
      render: (
        item: IJoinedInvoice & {
          providerInvoice?: IJoinedInvoice;
        }
      ) => {
        const icon = <EyeIcon style={{ marginTop: 4 }} />;
        const invoiceId = item?.uuid;
        const missionRef = item?.mission?.reference;
        const contractorId = item?.contractor?.uuid;
        const invoiceFile = item?.invoiceFile;
        return (
          <Flex alignItems="center">
            <ALink href={`/orders/invoices/provider/time-spent/${invoiceId}`}>
              <Link iconLeft={icon}></Link>
            </ALink>
            <Menu
              align="right"
              menuItems={
                <>
                  <MenuItem>
                    {invoiceFile?.fileLocation ? (
                      <Link
                        iconLeft={<EyeIcon />}
                        onClick={() =>
                          showDisplayPdfModal({
                            //@ts-ignore
                            fileLocation: invoiceFile?.fileLocation,
                            //@ts-ignore
                            fileName: invoiceFile?.fileName,
                          })
                        }
                      >
                        Voir la facture
                      </Link>
                    ) : (
                      <Status variantColor="warning">
                        En attente de soumission par le fournisseur
                      </Status>
                    )}
                  </MenuItem>
                  {item?.originalInvoice?.invoiceFile?.fileLocation && (
                    <MenuItem>
                      <Link
                        iconLeft={<EyeIcon />}
                        onClick={() =>
                          showDisplayPdfModal({
                            //@ts-ignore
                            fileLocation:
                              item?.originalInvoice?.invoiceFile?.fileLocation,
                            //@ts-ignore
                            fileName:
                              item?.originalInvoice?.invoiceFile?.fileName,
                          })
                        }
                      >
                        Voir la facture originale
                      </Link>
                    </MenuItem>
                  )}
                  <MenuItem>
                    <ALink href={`/delivery/missions/${missionRef}`}>
                      <Link iconLeft={icon}>Voir la mission</Link>
                    </ALink>
                  </MenuItem>
                  <MenuItem>
                    <ALink href={`/providers/contractors/${contractorId}`}>
                      <Link iconLeft={icon}>Voir la fiche intervenant</Link>
                    </ALink>
                  </MenuItem>
                </>
              }
            >
              <DotsIcon fill={Theme?.colors?.primary?.default} fontSize={20} />
            </Menu>
          </Flex>
        );
      },
    },
  ].filter(Boolean) as TableColumn<Item>[];

export const ProviderCreditNoteList = () => {
  const { me } = useMe();
  const { tasksInProgress, updateGlobalStore } = useGlobalStore();
  const [isLoadingExport, setIsLoadingExport] = React.useState(false);
  const { mutateAsync: updateInvoice } = useInvoiceUpdateOne();
  const history = useHistory();
  const { filter: filterParams } = useParams<{
    filter: 'all' | 'projected' | 'validated' | 'sent' | 'archived';
  }>();
  useSetCrumbs(
    [filterParams],
    [
      {
        label: 'Avoirs',
        path: '/orders/invoices',
      },
      {
        label: 'Fournisseur',
        path: '/orders/invoices/provider',
      },
      {
        label:
          filterParams === 'all'
            ? 'Tous'
            : filterParams === 'projected'
              ? 'À créer'
              : filterParams === 'validated'
                ? 'À envoyer'
                : filterParams === 'sent'
                  ? 'Envoyées'
                  : 'Archivées',
        path: '/orders/invoices/provider/credit-note/awaiting',
      },
    ]
  );
  const Tabs = (
    <>
      <Tab
        variant="secondary"
        href={'/orders/invoices/provider/credit-note/all'}
      >
        Tous
      </Tab>
      <Tab
        variant="secondary"
        href={'/orders/invoices/provider/credit-note/validated'}
      >
        À envoyer
      </Tab>
      <Tab
        variant="secondary"
        href={'/orders/invoices/provider/credit-note/sent'}
      >
        Envoyées
      </Tab>
      <Tab
        variant="secondary"
        href={'/orders/invoices/provider/credit-note/paid'}
      >
        Payées
      </Tab>
    </>
  );
  const {
    initialSate,
    updateStore,
    resetStore,
    getNbActiveFilters,
    startDate,
    endDate,
    sortedBy,
    order,
    selectedStatus,
    selectedType,
    searchQuery,
    page,
    accountManagerId,
    commercialSelected,
    structureSelected,
    clientSelected,
    providerSelected,
    multiProviderSelected,
    multiClientSelected,
    contractorSelected,
    searchInKeys,
    //invoiceTypeSelected,
    invoiceDate,
    limit,
  } = useCreditNoteProviderListStore();
  const debouncedFilterQuery = useDebounce(
    searchQuery,
    INVOICE_SEARCH_DEBOUNCE_DELAY
  );
  const sortString = (order === 'desc' ? '-' : '') + sortedBy;
  const additionalFiltersFields = [
    'commercialSelected',
    'structureSelected',
    'multiProviderSelected',
    'multiClientSelected',
    'contractorSelected',
    'invoiceDate',
  ];
  const {
    data: invoiceQuery,
    status,
    isFetching,
  } = useInvoiceFindMany({
    filterObject: getObjectFilterInvoice(
      {
        debouncedFilterQuery,
        startDate,
        endDate,
        filterUrl: snakeCase(
          filterParams
        ).toLocaleUpperCase() as InvoiceStatusEnum,
        selectedStatus,
        selectedType,
        accountManagerId: checkDefaultManagerSelected(
          me,
          accountManagerId as string
        )
          ? accountManagerId
          : undefined,
        commercialSelected,
        structureSelected,
        clientSelected,
        providerSelected,
        multiProviderSelected,
        multiClientSelected,
        contractorSelected,
        invoiceDate,
        searchInKeys,
      },
      'PROVIDER_CREDIT_NOTE'
    ),
    limit: limit,
    skip: limit * page,
    sort: formatInvoiceOrAdditionalActivitySort(sortString),
  });
  const onExportToExcel = async () => {
    setIsLoadingExport(true);
    try {
      const exportResponse = await exportFileXlxs({
        model: EExportModel.INVOICE,
        query: {
          filter: getObjectFilterInvoice(
            {
              debouncedFilterQuery,
              startDate,
              endDate,
              filterUrl: snakeCase(
                filterParams
              ).toLocaleUpperCase() as InvoiceStatusEnum,
              selectedStatus,
              selectedType,
              accountManagerId: checkDefaultManagerSelected(
                me,
                accountManagerId as string
              )
                ? accountManagerId
                : undefined,
              commercialSelected,
              structureSelected,
              clientSelected,
              providerSelected,
              multiProviderSelected,
              multiClientSelected,
              contractorSelected,
              invoiceDate,
              searchInKeys,
            },
            'PROVIDER_CREDIT_NOTE'
          ),
          //fields:[],
          // limit: limit,
          // skip: limit * page,
          // sort: sortString,
        },
      });
      const uuid = exportResponse?.taskUuid;
      if (Array.isArray(tasksInProgress)) {
        updateGlobalStore({ tasksInProgress: [...tasksInProgress, uuid] });
      } else {
        updateGlobalStore({ tasksInProgress: [uuid] });
      }
      showDialogModal({
        title: 'Export excel en cours',
        text: (
          <Box>
            <Text>
              La création de votre export excel est en cours. Cette action peut
              prendre quelques minutes. Vous pouvez suivre l'état de progression
              en cliquant sur l'icone ci-dessous.
            </Text>
            <img src={EXPORT_IMG} />
          </Box>
        ),
        confirmLabel: "J'ai compris",
      });
      setIsLoadingExport(false);
    } catch (e) {
      setIsLoadingExport(false);
    }
  };
  const onSortedChange = React.useCallback(
    (sortedBy: string, order: 'asc' | 'desc'): void => {
      //@ts-ignore
      updateStore({ page: 0, order, sortedBy });
    },
    []
  );

  const showMessage = useShowMessage();

  React.useEffect(() => {
    //resetInvoiceStore({});
    //setFilterAccountManager(localStorage?.cognitoUserId);
    queryClient.invalidateQueries({ queryKey: ['Invoices'] });
    //setPage(0);
  }, [filterParams]);

  const loading = status === 'pending';
  const totalCount = invoiceQuery?.totalCount || 0;

  const items = invoiceQuery?.invoices?.map(invoice => ({
    key: invoice.uuid || '',
    ...invoice,
  }));
  const invoiceTypeText = (invoiceType: EInvoiceType) => {
    if (invoiceType === EInvoiceType?.PROVIDER_CREDIT_NOTE) {
      return 'Prestation standard';
    }
    if (invoiceType === EInvoiceType?.PROVIDER_STAND_BY_DUTY_CREDIT_NOTE) {
      return 'Prestation complémentaire';
    }
    if (invoiceType === EInvoiceType?.PROVIDER_EXPENSE_CREDIT_NOTE) {
      return 'Frais';
    }
    if (invoiceType === EInvoiceType?.PROVIDER_MILESTONE_CREDIT_NOTE) {
      return 'Jalon';
    }
  };
  //   React.useEffect(() => {
  //     updateStore({ page: 0 });
  //   }, [filterParams]);
  return (
    <>
      <Header
        tabs={Tabs}
        actions={
          <Button isLoading={isLoadingExport} onClick={() => onExportToExcel()}>
            Exporter
          </Button>
        }
      >
        <Text variant="h1">Avoirs fournisseur</Text>
      </Header>
      <Container p="20px 0">
        <Flex
          display={'inline-flex'}
          justifyContent={'flex-start'}
          flexWrap={'wrap'}
          m="10px 0"
          width={1 / 1}
        >
          <Flex width={1 / 1} mb={10}>
            <Box width={7 / 12} pr={'5px'} pt={'5px'}>
              <Flex>
                <Box width={3 / 10}>
                  <StaticSelect
                    isFieldSet
                    isClearable={false}
                    options={INVOICE_FOREIGN_SEARCH_FIELDS}
                    value={searchInKeys?.[0]?.value}
                    components={{ IndicatorSeparator: () => null }}
                    onChange={value =>
                      updateStore({
                        searchInKeys: [
                          INVOICE_FOREIGN_SEARCH_FIELDS?.find(
                            e => e?.value === value
                          ) as any,
                        ],
                      })
                    }
                  />
                </Box>
                <Box width={7 / 10} ml={'-5px'}>
                  <Input
                    isFieldSet
                    mr={20}
                    isFullWidth
                    data-cy="search-estaCustomer-list"
                    type="search"
                    name="search"
                    icon={
                      <SideBar
                        title="Filtres de facture"
                        customLinkIcon={
                          <MoreFilterIcon
                            style={{ cursor: 'pointer' }}
                            fill={Theme?.colors?.grey?.default}
                          />
                        }
                        buttonType="icon"
                        buttonText=" "
                      >
                        <InvoicesFiltersList
                          filterInvoiceType="PROVIDER_CREDIT_NOTE"
                          hookStore={useCreditNoteProviderListStore}
                        />
                      </SideBar>
                    }
                    placeholder={
                      ('Rechercher dans : ' +
                        getSearchInKeysText(searchInKeys, true)) as string
                    }
                    value={searchQuery}
                    onChange={(e: any) => {
                      updateStore({
                        page: 0,
                        searchQuery: e.target.value,
                      });
                    }}
                  />
                </Box>
              </Flex>
            </Box>
            {/* <Box width={5 / 12} pr={'5px'} pt={'5px'}>
              <StaticMultiSelect
                placeholder="Étendre la recherche dans ..."
                onChange={value => updateStore({ searchInKeys: value })}
                options={INVOICE_FOREIGN_SEARCH_FIELDS}
                values={searchInKeys}
              />
            </Box> */}
            <Box width={3 / 12} ml={20} mt={'5px'}>
              <UserSelect
                placeholder="Tous les chargés de compte"
                role="ACCOUNT_MANAGER"
                onChange={id => updateStore({ accountManagerId: id })}
                value={accountManagerId as string}
              />
            </Box>
            <Box width={2 / 12} ml={20}>
              <Flex
                justifyContent={'center'}
                alignItems={'center'}
                flexWrap="wrap"
                alignContent="center"
                width={1 / 1}
                height={'100%'}
              >
                <SideBar
                  title="Filtres de facture"
                  customLinkIcon={<AddIcon />}
                  buttonType="link"
                  buttonText="Plus de filtres"
                >
                  <InvoicesFiltersList
                    filterInvoiceType="PROVIDER_CREDIT_NOTE"
                    hookStore={useCreditNoteProviderListStore}
                  />
                </SideBar>
                {initialSate === false &&
                  getNbActiveFilters(additionalFiltersFields) > 0 && (
                    <Box
                      backgroundColor={Theme?.colors?.primary?.default}
                      border={`1px solid ${Theme?.colors?.primary?.default}`}
                      borderRadius={'50%'}
                      height={15}
                      width={15}
                      ml={-2}
                      mt={-4}
                    >
                      <Flex justifyContent={'center'}>
                        <Text color="white" fontSize={'10px'}>
                          {' '}
                          {getNbActiveFilters(additionalFiltersFields)}
                        </Text>
                      </Flex>
                    </Box>
                  )}
                <Link
                  isDisabled={initialSate}
                  iconLeft={
                    <RefreshIcon
                      fill={
                        initialSate ? Theme?.colors?.grey?.default : undefined
                      }
                    />
                  }
                  ml={'5px'}
                  onClick={() => (!initialSate ? resetStore({}) : false)}
                >
                  Réinitialiser
                </Link>
              </Flex>
            </Box>
          </Flex>
        </Flex>
        {/* <Flex m="10px 0">
          <Box width={1 / 5}>
            <Input
              mr={20}
              isFullWidth
              data-cy="search-estaProvider-list"
              type="search"
              name="search"
              placeholder="Rechercher ..."
              value={searchQuery}
              onChange={(e: any) => {
                updateStore({
                  page: 0,
                  searchQuery: e.target.value,
                });
              }}
            />
          </Box>
          <Box width={1 / 5} ml={20}>
            <DatePickerRange
              startDate={startDate}
              endDate={endDate}
              isClearable={true}
              setStartDate={value => updateStore({ startDate: value })}
              setEndDate={value => updateStore({ endDate: value })}
            />
          </Box>
          <Box width={1 / 5} ml={20}>
            <UserSelect
              placeholder="Tous les chargés de compte"
              role="ACCOUNT_MANAGER"
              onChange={id => updateStore({ accountManagerId: id })}
              value={accountManagerId as string}
            />
          </Box>
          <Box width={1 / 5} ml={20}>
            <StaticSelect
              placeholder="Tous les types"
              options={INVOICE_CREDIT_NOTE_PROVIDER_TYPE_SELECT}
              onChange={value =>
                updateStore({ selectedType: value as EInvoiceType })
              }
              value={selectedType}
            />
          </Box>

          <Box ml={10}>
            <Flex
              justifyContent={'center'}
              alignItems={'center'}
              flexWrap="wrap"
              alignContent="center"
              width={1 / 1}
              height={'100%'}
            >
              <SideBar
                title="Filtres de facture"
                customLinkIcon={<AddIcon />}
                buttonType="link"
                buttonText="Plus de filtres"
              >
                <InvoicesFiltersList
                  filterInvoiceType="PROVIDER_CREDIT_NOTE"
                  hookStore={useCreditNoteProviderListStore}
                />
              </SideBar>
              {initialSate === false &&
                getNbActiveFilters(additionalFiltersFields) > 0 && (
                  <Box
                    backgroundColor={Theme?.colors?.primary?.default}
                    border={`1px solid ${Theme?.colors?.primary?.default}`}
                    borderRadius={'50%'}
                    height={15}
                    width={15}
                    ml={-9}
                    mt={-4}
                  >
                    <Flex justifyContent={'center'}>
                      <Text color="white" fontSize={'10px'}>
                        {' '}
                        {getNbActiveFilters(additionalFiltersFields)}
                      </Text>
                    </Flex>
                  </Box>
                )}
              <Link
                isDisabled={initialSate}
                iconLeft={
                  <RefreshIcon
                    fill={
                      initialSate ? Theme?.colors?.grey?.default : undefined
                    }
                  />
                }
                ml={'5px'}
                onClick={() => (!initialSate ? resetStore({}) : false)}
              >
                Réinitialiser
              </Link>
            </Flex>
          </Box>
        </Flex> */}
        {loading && <SpinnerBox />}
        {!loading && (
          <Table<Itemize<IJoinedInvoice & { providerInvoice?: IJoinedInvoice }>>
            isLoading={isFetching}
            columns={columns(
              filterParams,
              history,
              showMessage,
              updateInvoice,
              invoiceTypeText
            )}
            items={items || []}
            sortedBy={sortedBy}
            order={order}
            page={page}
            total={totalCount}
            perPage={totalCount < 20 ? totalCount : 20}
            opacity={filterParams === 'archived' ? 0.5 : 1}
            onSortedChange={onSortedChange}
            onChangePage={page => updateStore({ page: page })}
          />
        )}
      </Container>
    </>
  );
};
