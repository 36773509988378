import { Button, Text, Box, FormLabel } from 'components/ui';
import { EContractorSocialStatus } from '@freelancelabs/teoreme-commons';
import { CONTRACTOR_SOCIAL_STATUS_SELECT } from '@commons';
import React, { useState } from 'react';
import styled from 'styled-components';
import { create } from 'react-modal-promise';
import { ModalFrame, ModalProps } from './ModalFrame';
import { StaticSelect } from 'components/selects/StaticSelect';

const Actions = styled.div`
  display: flex;
`;
type Props = ModalProps & {
  confirmLabel?: string;
  cancelLabel?: string;
  title?: string;
  text?: string;
  defaultSocialStatus?: EContractorSocialStatus;
  beforeValidation?: (params?: any) => void;
};

export const OnConfirmLinkContactModal: React.FC<
  React.PropsWithChildren<Props>
> = ({
  onResolve,
  isOpen,
  text,
  title,
  confirmLabel,
  cancelLabel,
  beforeValidation,
  defaultSocialStatus,
}) => {
  const [loading, setLoading] = useState(false);
  const [socialStatus, setSocialStatus] = React.useState<
    EContractorSocialStatus | false
  >(defaultSocialStatus as EContractorSocialStatus);
  const onValidate = async () => {
    try {
      if (beforeValidation) {
        setLoading(true);
        await beforeValidation(socialStatus);
        setLoading(false);
      }
      onResolve(true);
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <ModalFrame
      isOpen={isOpen}
      onClose={() => onResolve(false)}
      width={850}
      closeIcon
    >
      {title && (
        <Text variant="h1" mb={30}>
          {title}
        </Text>
      )}
      {text && (
        <Text variant="p" mb={30}>
          {text}
        </Text>
      )}
      <Box mb={20}>
        <FormLabel>statut social de l'intervenant</FormLabel>
        <StaticSelect
          id="contractor-socialStatus-select"
          onChange={value => setSocialStatus(value as EContractorSocialStatus)}
          value={socialStatus as EContractorSocialStatus}
          options={CONTRACTOR_SOCIAL_STATUS_SELECT}
          placeholder="Rechercher un statut"
        />
      </Box>
      <Actions>
        <Button
          onClick={onValidate}
          isDisabled={!socialStatus}
          mr={10}
          isLoading={loading}
        >
          {confirmLabel}
        </Button>
        {cancelLabel && (
          <Button variant="ghost" onClick={() => onResolve(false)}>
            {cancelLabel}
          </Button>
        )}
      </Actions>
    </ModalFrame>
  );
};

export const showOnConfirmLinkContactModal = create<Props>(
  OnConfirmLinkContactModal
);
