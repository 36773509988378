import * as React from 'react';

function Success(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="15"
      viewBox="0 0 15 15"
      {...props}
    >
      <g fill="none" fill-rule="evenodd">
        <g fill="#1BC773">
          <g>
            <path
              d="M7.5 15c4.142 0 7.5-3.358 7.5-7.5C15 3.358 11.642 0 7.5 0 3.358 0 0 3.358 0 7.5 0 11.642 3.358 15 7.5 15zm2.82-10.107c.266-.274.698-.274.964 0 .267.275.267.72 0 .995L7.19 10.107c-.267.274-.699.274-.965 0L3.95 7.763c-.267-.275-.267-.72 0-.995.266-.274.698-.274.965 0l1.792 1.847 3.612-3.722z"
              transform="translate(-431 -255) translate(431 255)"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Success;
