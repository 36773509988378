import {
  ICompanyGetResponse,
  IEstablishment,
  IJoinedMission,
} from '@freelancelabs/teoreme-commons';
import { Box, Text } from 'components/ui';
import { create } from 'react-modal-promise';
import { ModalFrame, ModalProps } from '../ModalFrame';
import { ImportMissionStandByDutyForm } from 'forms/ImportMissionStandByDutyForm';

type Props = ModalProps & {
  beforeValidation?: () => void;
  standByDuty?: any;
  establishment?: ICompanyGetResponse | IEstablishment;
  mission?: IJoinedMission;
};

export const ImportMissionStandByDutyModal = ({
  onResolve,
  isOpen,
  standByDuty,
  establishment,
  mission,
}: Props) => {
  return (
    <ModalFrame
      isOpen={isOpen}
      onClose={() => onResolve(false)}
      width={1350}
      closeIcon
    >
      <Box mt={20}>
        <Text variant="h2" mb={20}>
          Importer des formules d'astreintes
        </Text>
        <ImportMissionStandByDutyForm
          standByDuty={standByDuty}
          mission={mission}
          onResolve={onResolve}
        />
      </Box>
    </ModalFrame>
  );
};

export const showImportMissionStandByDutyModal = create<Props, boolean>(
  ImportMissionStandByDutyModal
);
