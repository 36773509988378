import * as React from 'react';

function SvgEyeCloseIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      {...props}
    >
      <g fill="none" fill-rule="evenodd">
        <g fill="#819CAD">
          <g>
            <g>
              <g>
                <g>
                  <path
                    d="M19.898 11.971c-.62-.93-1.355-1.779-2.188-2.525l-7.867 7.859c.697.226 1.424.344 2.157.348 4.714 0 7.77-4.77 7.898-4.973.136-.217.136-.492 0-.709zm-.76-6.776c-.261-.26-.683-.26-.943 0L15.49 7.897c-1.073-.578-2.27-.887-3.49-.9-4.714 0-7.77 4.77-7.898 4.974-.136.217-.136.492 0 .709.873 1.301 1.97 2.438 3.24 3.357l-1.813 1.811c-.178.166-.25.416-.19.651.06.236.244.42.48.48.235.06.485-.012.652-.19L19.137 6.137c.26-.26.26-.681 0-.941zm-9.805 7.13c0-1.471 1.194-2.664 2.667-2.664.474.003.938.135 1.341.383l-3.625 3.62c-.247-.403-.38-.866-.383-1.339z"
                    transform="translate(-651 -389) translate(315 258) translate(0 94) translate(0 19) translate(336 18)"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default SvgEyeCloseIcon;
