import {
  EMissionStatus,
  EMissionStructure,
  EMissionType,
  IJoinedMission,
} from '@freelancelabs/teoreme-commons';

const canAbortMission = (missionStatus: EMissionStatus): boolean => {
  const allowebAbortStatus = [EMissionStatus.DRAFT, EMissionStatus.VALIDATED];
  return allowebAbortStatus.includes(missionStatus);
};

export const checkDisplayMenuList = (mission: any): boolean => {
  const missionStatus = mission?.status;
  if (mission?.isSfFlowError) return false;

  return (
    canAbortMission(missionStatus) || missionStatus === EMissionStatus.DRAFT
  );
};
//https://fcomdev.atlassian.net/browse/TEOR-5206
//https://fcomdev.atlassian.net/wiki/spaces/TRM/pages/2668822530/Flux+TA+Salesforce+-+Connecteed+v2
export const isMissionFieldEditable = (
  mission: IJoinedMission | undefined,
  field: string
): boolean | undefined => {
  if (mission?.createdFromSFFlow === true) {
    return isFieldEditableForSalesforce(mission, field);
    //return isFieldEditableForConnecteed(mission, field);
  } else {
    return isFieldEditableForConnecteed(mission, field);
  }
};

export const isFieldEditableForSalesforce = (
  mission: IJoinedMission | undefined,
  field: string
): boolean | undefined => {
  if (
    mission?.billingInformation?.missionType?.label === EMissionType.CLASSIC
  ) {
    return isFieldEditableForTA(mission, field);
  } else {
    return isFieldEditableForClassic(mission, field);
  }
};
export const isFieldEditableForConnecteed = (
  mission: IJoinedMission | undefined,
  field: string
): boolean | undefined => {
  const isAmendment = mission?.parent;

  if (!mission?.status) {
    return true;
  }

  switch (mission?.status) {
    case EMissionStatus.DRAFT:
      switch (true) {
        case field === 'title':
        case field === 'startAt':
        case field === 'endAt':
        case field === 'billing.type':
        case field === 'internalCommercial':
        case field === 'description':
        case field === 'billing.contractorRate':
        case field === 'billing.clientRate':
        case field === 'billing.margin':
        case field === 'billing.marginType':
        case field === 'billing.estimatedDays':
        case field === 'customer.contact':
        case field === 'customer.quote':
        case field === 'marketData':
        case field === 'provider.contact':
        case field.includes('location.'):
          return true;
        case field === 'isMandateMode':
          return mission?.billingInformation?.structure ===
            EMissionStructure.FCOM
            ? true
            : false;
        case field === 'billingInformation.structure':
          return mission?.sage ? false : true;

        case field === 'billingInformation.missionType.label':
        case field === 'customer.establishment':
        case field === 'provider.establishment':
        case field === 'provider.contractor':
          return true;
      }
      break;

    case EMissionStatus.VALIDATED:
      switch (true) {
        case field === 'title':
        case field === 'startAt':
        case field === 'endAt':
        case field === 'internalCommercial':
        case field.includes('location.'):
        case field === 'description':
        case field === 'billing.contractorRate':
        case field === 'billing.clientRate':
        case field === 'billing.margin':
        case field === 'billing.marginType':
        case field === 'billing.estimatedDays':
        case field === 'customer.contact':
        case field === 'customer.quote':
        case field === 'marketData':
        case field === 'provider.contact':
          return true;

        case field === 'isMandateMode':
        case field === 'billingInformation.structure':
        case field === 'billing.type':
        case field === 'billingInformation.missionType.label':
        case field === 'customer.establishment':
        case field === 'provider.establishment':
        case field === 'provider.contractor':
          return false;
      }
      break;

    case EMissionStatus.TERMINATED:
      switch (true) {
        case field === 'marketData':
          return true;
      }
      break;
    case EMissionStatus.ARCHIVED:
    case EMissionStatus.ABORTED:
      return false;
  }

  return undefined;
};

//TA ( CLASSIC ) IS CREATED BY SF !
export const isFieldEditableForTA = (
  mission: IJoinedMission,
  field: string
): boolean | undefined => {
  if (!mission?.status) {
    return true;
  }
  const isAmendment = mission?.parent;
  if (mission?.status === EMissionStatus.TERMINATED) {
    return false;
  }
  if (mission?.status === EMissionStatus.ARCHIVED) {
    return false;
  }
  if (mission?.status === EMissionStatus.ABORTED) {
    return false;
  }
  if (!isAmendment) {
    if (mission.status === EMissionStatus.DRAFT) {
      switch (true) {
        case field === 'title':
        case field === 'endAt':
        case field?.includes('location.'):
        case field === 'description':
        case field === 'billing.estimatedDays':
        case field === 'customer.establishment':
        case field === 'provider.establishment':
        case field === 'provider.contact':
        case field === 'customer.quote':
        case field === 'provider.contractor':
          return true;
      }
      switch (true) {
        case field === 'billingInformation.structure':
        case field === 'startAt':
        case field === 'billing.type':
        case field === 'internalCommercial':
        case field === 'isMandateMode':
        case field === 'billingInformation.missionType.label':
        case field === 'billing.contractorRate':
        case field === 'billing.clientRate':
        case field === 'billing.margin':
        case field === 'billing.marginType':
        case field === 'customer.contact':
        case field === 'marketData':
          return false;
      }
    }
    if (mission.status === EMissionStatus.VALIDATED) {
      switch (true) {
        case field === 'endAt':
        case field === 'title':
        case field?.includes('location.'):
        case field === 'description':
        case field === 'billing.estimatedDays':
        case field === 'provider.contact':
        case field === 'customer.quote':
          return true;
      }
      switch (true) {
        // case field === 'endAt': // https://fcomdev.atlassian.net/browse/TEOR-5602
        case field === 'billingInformation.structure':
        case field === 'startAt':
        case field === 'billing.type':
        case field === 'internalCommercial':
        case field === 'isMandateMode':
        case field === 'billingInformation.missionType.label':
        case field === 'billing.contractorRate':
        case field === 'billing.clientRate':
        case field === 'billing.margin':
        case field === 'billing.marginType':
        case field === 'customer.establishment':
        case field === 'provider.establishment':
        case field === 'provider.contractor':
        case field === 'customer.contact':
        case field === 'marketData':
          return false;
      }
    }
  }
  if (isAmendment) {
    if (mission.status === EMissionStatus.DRAFT) {
      switch (true) {
        case field === 'title':
        case field === 'startAt':
        case field === 'endAt':
        case field === 'billingInformation.missionType.label':
        case field?.includes('location.'):
        case field === 'description':
        case field === 'billing.estimatedDays':
        case field === 'customer.establishment':
        case field === 'provider.establishment':
        case field === 'provider.contact':
        case field === 'provider.contractor':
        case field === 'customer.quote':
          return true;
      }
      switch (true) {
        case field === 'billingInformation.structure':
        case field === 'billing.type':
        case field === 'internalCommercial':
        case field === 'isMandateMode':
        case field === 'billing.contractorRate':
        case field === 'billing.clientRate':
        case field === 'billing.margin':
        case field === 'billing.marginType':
        case field === 'customer.contact':
        case field === 'marketData':
          return false;
      }
    }
    switch (true) {
      case field === 'title':
      case field === 'provider.contact':
      case field === 'endAt':
      case field?.includes('location.'):
      case field === 'description':
      case field === 'billing.estimatedDays':
      case field === 'customer.quote':
        return true;
    }
    switch (true) {
      case field === 'billingInformation.structure':
      case field === 'startAt':
      case field === 'billing.type':
      case field === 'internalCommercial':
      case field === 'isMandateMode':
      case field === 'billingInformation.missionType.label':
      case field === 'billing.contractorRate':
      case field === 'billing.clientRate':
      case field === 'billing.margin':
      case field === 'billing.marginType':
      case field === 'customer.establishment':
      case field === 'provider.establishment':
      case field === 'provider.contractor':
      case field === 'customer.contact':
      case field === 'marketData':
        return false;
    }
    if (mission.status === EMissionStatus.VALIDATED) {
    }
  }
};

export const isFieldEditableForClassic = (
  mission: IJoinedMission | undefined,
  field: string
): boolean => {
  if (!mission?.status) {
    return true;
  }
  // Ajouter les conditions spécifiques pour CLASSIC
  // ...
  return true;
};
