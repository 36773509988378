import {
  additionalActivityFindMany,
  formatInvoiceOrAdditionalActivitySort,
  INVOICE_STATUS_SELECT,
  useInvoiceFindMany,
} from '@commons';
import {
  EInvoiceStatus,
  IInvoiceSearchParams,
  IJoinedInvoice,
} from '@freelancelabs/teoreme-commons';
import { Box, Itemize, SpinnerBox, Table, TableColumn } from 'components/ui';
import { useSetCrumbs } from 'hooks/breadCrumb';
import { useDebounce } from 'hooks/useDebounce';
import * as React from 'react';
import { useParams } from 'react-router-dom';
import { getObjectFilterInvoice } from './helpers';
const INVOICE_STATUS_SELECT_CUSTOM = INVOICE_STATUS_SELECT;
const Findex = INVOICE_STATUS_SELECT.findIndex(
  state => state.key === EInvoiceStatus.REJECTED
);
INVOICE_STATUS_SELECT_CUSTOM[Findex].label = 'Refusé';

type InvoiceListProps = {
  columns: () => TableColumn<Itemize<IJoinedInvoice>>[];
  filterObject: IInvoiceSearchParams['filterObject'];
  hookStore: any;
  filterComponents: React.ReactNode;
};
export const InvoiceList = ({
  filterObject,
  columns,
  hookStore,
  filterComponents,
}: InvoiceListProps) => {
  const [items, setItems] = React.useState<IJoinedInvoice[]>([]);
  const { filter: filterParams } = useParams<{
    filter:
      | 'all'
      | 'awaiting'
      | 'validated'
      | 'to-be-validated'
      | 'archived'
      | 'paid';
  }>();
  useSetCrumbs(
    [filterParams],
    [
      {
        label: 'Fournisseurs',
        path: '/orders/invoices',
      },
      {
        label: 'Factures',
        path: '/orders/invoices',
      },
      {
        label: 'Factures fournisseurs',
        path: '/orders/invoices/provider/time-spent/awaiting',
      },
      {
        label:
          filterParams === 'awaiting'
            ? 'En attente'
            : filterParams === 'to-be-validated'
              ? 'À valider'
              : filterParams === 'archived'
                ? 'Archivés'
                : 'validés',
        path: '/orders/invoices/provider/time-spent/' + filterParams,
      },
    ]
  );

  const {
    updateInvoiceGlobalListStore,
    resetInvoiceGlobalListStore,
    selectedStatus,
    searchQuery,
    limit,
    page,
    accountManagerId,
    selectedType,
    startDate,
    endDate,
    sortedBy,
    order,
  } = hookStore();
  const debouncedFilterQuery = useDebounce(searchQuery, 500);
  const sortString = (order === 'desc' ? '-' : '') + sortedBy;
  React.useEffect(() => {
    // RESET GLOBAL STATE ON COMPONENT FIRST LOADING
    return resetInvoiceGlobalListStore({});
  }, []);
  const buildFilters = () => {
    // filtre interne
    const hookFilters = getObjectFilterInvoice(
      debouncedFilterQuery,
      startDate,
      endDate,
      selectedStatus,
      selectedType,
      accountManagerId
    );
    // filtre extern
    const defaultParamFilters = filterObject;
    const { invoiceType: defaultType, ...restFilter } = defaultParamFilters;

    // use component parameters filterObject (invoiceType) as default when user have not yet selected a type in the view
    const finalFilters = {
      ...hookFilters,
      invoiceType: hookFilters.invoiceType ?? defaultType,
      ...restFilter,
    };

    return finalFilters;
  };
  const {
    data: invoiceQuery,
    status,
    isFetching,
  } = useInvoiceFindMany({
    filterObject: buildFilters(),
    limit: limit,
    skip: limit * page,
    sort: formatInvoiceOrAdditionalActivitySort(sortString),
  });

  const loading = status === 'pending';
  const totalCount = invoiceQuery?.totalCount || 0;

  const onSortedChange = React.useCallback(
    (sortedBy: string, order: 'asc' | 'desc'): void => {
      //@ts-ignore
      updateInvoiceGlobalListStore({ page: 0, sortedBy, order });
    },
    []
  );

  const handleMergeInvoiceProviderAndAA = async (
    invoices: IJoinedInvoice[]
  ) => {
    const aaUuid = invoices?.filter(i => {
      return i?.additionalActivity;
    });
    const findAA = aaUuid?.length > 0;
    try {
      const aa = findAA
        ? await additionalActivityFindMany({
            filterObject: {
              uuid: { $in: aaUuid?.map(i => i?.additionalActivity) },
            },
          })
        : undefined;
      let items = invoiceQuery?.invoices?.map(invoice => ({
        key: invoice.uuid || '',
        ...invoice,
      }));
      items = items?.map((invoicesProvider: any) => ({
        ...invoicesProvider,
        additionalActivity: findAA
          ? aa?.additionalActivities?.find(
              a => a?.uuid === invoicesProvider?.additionalActivity
            )
          : undefined,
      }));
      //@ts-ignore
      setItems(items);
    } catch (e) {
      //
    }
  };
  React.useEffect(() => {
    if (invoiceQuery) {
      handleMergeInvoiceProviderAndAA(invoiceQuery?.invoices);
    }
  }, [invoiceQuery]);

  // React.useEffect(() => {
  //   //resetInvoiceStore({});
  //   //queryClient.invalidateQueries('Invoices');
  // }, [filterParams]);

  return (
    <Box>
      <Box>{filterComponents}</Box>
      {loading && <SpinnerBox />}
      <Box>
        {!loading && (
          <Table<Itemize<IJoinedInvoice>>
            isLoading={isFetching}
            columns={columns()}
            items={items as Itemize<IJoinedInvoice>[]}
            sortedBy={sortedBy}
            order={order}
            page={page}
            total={totalCount}
            perPage={totalCount && totalCount < limit ? totalCount : limit}
            opacity={filterParams === 'archived' ? 0.5 : 1}
            onSortedChange={onSortedChange}
            onChangePage={page => updateInvoiceGlobalListStore({ page: page })}
          />
        )}
      </Box>
    </Box>
  );
};
