import {
  contractorFindOne,
  contractorUpdateOne,
  establishmentFindOne,
  getEstablishmentName,
  getFullName,
} from '@commons';
import {
  IJoinedContractor,
  IJoinedMission,
  IMinifiedUser,
  IMissionCustomer,
  IMissionProvider,
  IUpdateMissionData,
} from '@freelancelabs/teoreme-commons';
import { Text } from 'components/ui';
import { AddMissionCustomerOrProviderForm } from 'forms/AddMissionCustomerOrProviderForm';
import { useCreateOrUpdateMission, useGetMission } from 'hooks/missionHooks';
import React from 'react';
import { create } from 'react-modal-promise';
import { queryClient } from '@commons';
import { showDialogModal } from './DialogModal';
import { ModalFrame, ModalProps } from './ModalFrame';
import { showOnConfirmLinkContactModal } from './OnConfirmLinkContactModal';

type Props = ModalProps & {
  beforeValidation?: () => void;
  method: 'UPDATE' | 'CREATE';
  mission?: IJoinedMission;
  missionHasNoValidatedCraOrMilestone?: boolean;
};

export const AddMissionProviderModal = ({
  onResolve,
  isOpen,
  method,
  mission,
  missionHasNoValidatedCraOrMilestone,
}: Props) => {
  const [createOrUpdate] = useCreateOrUpdateMission();

  const { reference } = useGetMission();

  // returns -1 if no attachment // 0 if only establishment is attached // 1 if both are attached
  const checkAttachment = (
    contractor: IJoinedContractor | null,
    establishmentId: string,
    contactId: string
  ) => {
    let linkContractorEstaIsDisabled;
    let hasEstablishment,
      hasContact = false;
    let attachedContact: Partial<IMinifiedUser> = {};
    if (contractor && contractor.scopedData) {
      contractor.scopedData.forEach(data => {
        if (data.establishment.uuid === establishmentId) {
          hasEstablishment = true;
          if (data?.disabled === true) {
            linkContractorEstaIsDisabled = true;
          }
          attachedContact = data.contact;
          if (data.contact.cognitoUserId === contactId) {
            hasContact = true;
          }
        }
      });
    }
    return {
      attachment:
        hasEstablishment && hasContact ? 1 : hasEstablishment ? 0 : -1,
      attachedContact,
      linkContractorEstaIsDisabled,
    };
  };

  const onAddMissionProvider = async (
    provider: IMissionProvider | IMissionCustomer
  ) => {
    try {
      // this data is loaded when contractor is selected
      const selectedContractor = await contractorFindOne(
        (provider as IMissionProvider).contractor
      );
      const establishmentSelected = await establishmentFindOne(
        provider.establishment,
        'uuid',
        true
      );

      const { attachment, attachedContact, linkContractorEstaIsDisabled } =
        checkAttachment(
          selectedContractor,
          provider.establishment,
          provider.contact
        );
      const mission: IUpdateMissionData = {
        provider,
        incPurchaseOrderCounter: false,
        incQuoteCounter: false,
      };
      let newMission;
      const estaSelected = await establishmentFindOne(
        provider.establishment,
        'uuid'
      );
      /*
      const contractorLinkEstaSelected = selectedContractor.scopedData.findIndex(
        //@ts-ignore
        esta => esta.establishment.siret === estaSelected.siret
      );*/
      const contractorLinkEstaParentSelected =
        selectedContractor.scopedData.findIndex(
          esta => esta.establishment.siren === estaSelected.siren
        );
      /*
      const contactLinkEstaSelected = estaSelected.provider.contacts.findIndex(
        contactEsta => contactEsta.cognitoUserId === provider.contact
      );*/
      const socialStatusParent =
        contractorLinkEstaParentSelected !== -1
          ? selectedContractor.scopedData[contractorLinkEstaParentSelected]
              .socialStatus
          : undefined;
      if (attachment === 1) {
        /*
        IF contractor unlink establishment , propose link
        */
        if (linkContractorEstaIsDisabled === true) {
          showDialogModal({
            title: 'Attention',
            text: `L'intervenant ${getFullName(
              selectedContractor
            )} et l'établissement ${getEstablishmentName(
              establishmentSelected
            )} ont été détaché manuellement , souhaitez vous les rattacher à nouveau ?`,
            confirmLabel: 'Rattacher',
            cancelLabel: 'Annuler',
            beforeValidation: async () => {
              await contractorUpdateOne({
                uuid: (provider as IMissionProvider).contractor,
                scopedData: [
                  {
                    establishment: provider.establishment,
                    contact: provider.contact,
                    disabled: false,
                  },
                ],
              });
              newMission = await createOrUpdate(reference, mission);
              queryClient.refetchQueries({
                queryKey: ['missions'],
                type: 'active',
              });
              if (newMission) onResolve(newMission);
            },
          });
        } else {
          newMission = await createOrUpdate(reference, mission);
          queryClient.refetchQueries({
            queryKey: ['missions'],
            type: 'active',
          });
          if (newMission) onResolve(newMission);
        }

        /*
        Si l'établissement fournisseur et l’intervenant sont rattachés entre eux,
        mais que le contact fournisseur sélectionné est différent du contact fournisseur renseigné en base :
        */
      } else if (attachment === 0) {
        showDialogModal({
          title: `Êtes-vous sûr(e) de vouloir mettre à jour le contact fournisseur de l'intervenant?`,
          text: `${
            linkContractorEstaIsDisabled === true ? '1 - ' : ''
          }L'intervenant est déjà rattaché à un autre contact fournisseur pour cet établissement :
          ${attachedContact.firstName + ' ' || ''}
          ${attachedContact.lastName + ' ' || ''}
          ${attachedContact.email + ' ' || ''}.
           Pour pouvoir continuer, veuillez mettre à jour le contact fournisseur de cet établissement. ${
             linkContractorEstaIsDisabled === true
               ? ` 2 -L'intervenant ${getFullName(
                   selectedContractor
                 )} et l'établissement ${getEstablishmentName(
                   establishmentSelected
                 )} ont été détaché manuellement , souhaitez vous les rattacher à nouveau ?`
               : ''
           }`,
          confirmLabel: `Mettre à jour ${
            linkContractorEstaIsDisabled ? 'et rattacher' : ''
          }`,
          cancelLabel: 'Annuler',

          beforeValidation: async params => {
            await contractorUpdateOne({
              uuid: (provider as IMissionProvider).contractor,
              scopedData: [
                {
                  disabled: false,
                  establishment: provider.establishment,
                  contact: provider.contact,
                  socialStatus: socialStatusParent,
                },
              ],
              contractor: {},
            });
            newMission = await createOrUpdate(reference, mission);
            queryClient.refetchQueries({
              queryKey: ['missions'],
              type: 'active',
            });
            if (newMission) onResolve(newMission);
          },
        });
      } else {
        //  attachement -1
        showOnConfirmLinkContactModal({
          title: `Êtes-vous sûr(e) de vouloir rattacher l'établissement et l'intervenant?`,
          text: `L'établissement, le contact fournisseur et l’intervenant que vous avez sélectionnés ne sont pas rattachés. Pour pouvoir continuer, veuillez vérifier ou préciser le statut social de l’intervenant puis valider le rattachement.
          ${
            contractorLinkEstaParentSelected !== -1
              ? 'A noter : nous avons détecté que cet intervenant était déjà rattaché à un ou plusieurs autres établissements de la même entreprise fournisseur :'
              : ''
          }
          ${
            contractorLinkEstaParentSelected !== -1
              ? // eslint-disable-next-line array-callback-return
                selectedContractor.scopedData.map(esta => {
                  if (esta.establishment.siren === estaSelected.siren) {
                    return (
                      getEstablishmentName(esta.establishment) +
                      '-' +
                      esta.establishment.siret +
                      ' '
                    );
                  }
                })
              : ''
          }  `,
          confirmLabel: 'Rattacher',
          cancelLabel: 'Annuler',
          defaultSocialStatus: socialStatusParent,

          beforeValidation: async params => {
            try {
              await contractorUpdateOne({
                uuid: (provider as IMissionProvider).contractor,
                scopedData: [
                  {
                    establishment: provider.establishment,
                    contact: provider.contact,
                    socialStatus: params,
                  },
                ],
                contractor: {},
              });
              newMission = await createOrUpdate(reference, mission);
              queryClient.refetchQueries({
                queryKey: ['missions'],
                type: 'active',
              });
              if (newMission) onResolve(newMission);
            } catch (e) {
              onResolve(false);
            }
          },
        });
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <ModalFrame
      isOpen={isOpen}
      onClose={() => onResolve(false)}
      width={650}
      closeIcon
    >
      <Text variant="h1" mb={20}>
        {method === 'CREATE' ? 'Sélectionner un' : 'Modifier le'} fournisseur
      </Text>
      <AddMissionCustomerOrProviderForm
        onSubmit={onAddMissionProvider}
        type="PROVIDER"
        method={method}
        mission={mission}
        missionHasNoValidatedCraOrMilestone={
          missionHasNoValidatedCraOrMilestone
        }
      />
    </ModalFrame>
  );
};

export const showAddMissionProviderModal = create<Props, boolean>(
  AddMissionProviderModal
);
