import { Link, Button, Container, Flex, Text } from 'components/ui';
import { CloseIcon } from 'components/ui/icons';
import React, { ReactNode } from 'react';
import styled, { createGlobalStyle } from 'styled-components';

const Global = createGlobalStyle`
  body {
    overflow : hidden;
  }
`;

const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  height: 100vh;

  background-color: rgb(251, 250, 250);
  z-index: 90;
`;

const Header = styled.div`
  background-color: #fff;
  padding: 20px;
`;
const Footer = styled.div`
  padding: 20px;
  background-color: #fff;
`;
const Body = styled.div`
  flex: 1;
  overflow: auto;
`;
const CloseButton = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 10px;
  cursor: pointer;
`;

type ValidateContractorProps = {
  isOpen?: boolean;
  isLoading?: boolean;
  onClose: () => any;
  onSubmit?: () => any;
  children?: ReactNode;
  title: string;
  isEditable?: boolean;
  onReject: () => void;
};

export const ValidateContractorModal = ({
  title,
  isLoading,
  onClose,
  onSubmit,
  children,
  isEditable,
  onReject,
}: ValidateContractorProps) => {
  return (
    <Modal>
      <Global />
      <Header>
        <Container>
          <Flex justifyContent="center">
            <Text variant="h1">{title}</Text>
          </Flex>
        </Container>
        <CloseButton onClick={onClose}>
          <CloseIcon fontSize={20} />
        </CloseButton>
      </Header>
      <Body>
        <Container p="20px 0">{children}</Container>
      </Body>
      <Footer>
        <Container>
          <Flex justifyContent="flex-end" alignItems="center">
            <Link
              onClick={() => onClose()}
              iconLeft={<CloseIcon height={15} style={{ marginTop: 7 }} />}
            >
              Annuler
            </Link>
            {isEditable && (
              <>
                <Link
                  mr={20}
                  ml={20}
                  iconLeft={<CloseIcon height={15} style={{ marginTop: 7 }} />}
                  onClick={() => onReject()}
                >
                  Refuser
                </Link>
                <Button isLoading={isLoading} onClick={onSubmit}>
                  Valider
                </Button>
              </>
            )}
          </Flex>
        </Container>
      </Footer>
    </Modal>
  );
};
