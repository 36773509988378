import { IUserLocal } from '@commons';
import { ALink } from 'components/ALink';
import { Link } from 'components/ui';
import { ExclamationCircleIcon } from 'components/ui/icons';
import { ERoles } from '@commons';
import { checkUserHasRole } from '@commons';
import React from 'react';
import { useTheme } from 'styled-components';
import { BlocInformation, Flex, Text, Box } from './ui';
export const ContactExistsMessage = ({
  contact,
  contactRole,
}: {
  contact?: IUserLocal;
  contactRole:
    | 'PROVIDER_CONTACT'
    | 'CUSTOMER'
    | 'FREELANCE_PARTNER'
    | 'BUSINESS_PARTNER'
    | 'CONTRACTOR'
    | 'INTERNAL_COMMERCIAL'
    | 'ACCOUNT_MANAGER'
    | 'LEGAL_SQUAD'
    | 'TEAM_LEADER';
}) => {
  const theme = useTheme();
  const red = theme.colors.red;
  if (!contact) return null;
  const isManager = checkUserHasRole(contact, ERoles.ACCOUNT_MANAGER);
  const isCustomer = checkUserHasRole(contact, ERoles.CUSTOMER);
  const isProvider =
    checkUserHasRole(contact, ERoles.PROVIDER_CONTACT) ||
    checkUserHasRole(contact, ERoles.PROVIDER_RESPONSIBLE);
  const isFreelancePartner = checkUserHasRole(
    contact,
    ERoles.FREELANCE_PARTNER
  );
  //const isManager = checkUserHasRole(contact, ERoles.ACCOUNT_MANAGER);
  const isInternalCommercial = checkUserHasRole(
    contact,
    ERoles.INTERNAL_COMMERCIAL
  );
  const isBusinessPartner = checkUserHasRole(contact, ERoles.BUSINESS_PARTNER);
  const isContractor = checkUserHasRole(contact, ERoles.CONTRACTOR);
  const roleExist = checkUserHasRole(contact, contactRole);
  if (contactRole === 'PROVIDER_CONTACT') {
    if (isProvider) {
      return (
        <Flex ml={10}>
          <ExclamationCircleIcon fill={red} />
          &nbsp;
          <Text variant={'p'} color={red}>
            Contact déja existant&nbsp;
          </Text>
          <ALink
            href={`/providers/contacts/${contact.cognitoUserId}/information`}
            color={red}
          >
            <Text variant={'u'} color={red}>
              voir la fiche contact
            </Text>
          </ALink>
        </Flex>
      );
    }
    if (isCustomer) {
      return (
        <BlocInformation>
          <p>
            L’utilisateur existe déjà dans Connecteed en tant que Client (
            <Link>
              <ALink href={`/clients/contacts/${contact.cognitoUserId}`}>
                Voir la fiche
              </ALink>
            </Link>
            ) . Complétez tous les champs obligatoires pour pouvoir l’ajouter en
            tant que Fournisseur.
          </p>
        </BlocInformation>
      );
    }
  }
  if (contactRole === 'CUSTOMER') {
    if (isCustomer) {
      return (
        <Flex ml={10}>
          <ExclamationCircleIcon fill={red} />
          &nbsp;
          <Text variant={'p'} color={red}>
            Contact déja existant&nbsp;
          </Text>
          <ALink
            href={`/clients/contacts/${contact.cognitoUserId}`}
            color={red}
          >
            <Text variant={'u'} color={red}>
              voir la fiche contact
            </Text>
          </ALink>
        </Flex>
      );
    }
    if (isProvider) {
      return (
        <BlocInformation>
          <p>
            L’utilisateur existe déjà dans Connecteed en tant que Fournisseur (
            &nbsp;
            <Link>
              <ALink
                href={`/providers/contacts/${contact.cognitoUserId}/information`}
              >
                Voir la fiche
              </ALink>
            </Link>
            &nbsp;). Complétez tous les champs obligatoires pour pouvoir
            l’ajouter en tant que Client.
          </p>
        </BlocInformation>
      );
    }
  }
  // if (isManager) {
  //   return (
  //     <BlocInformation>
  //       <p>
  //         L’utilisateur existe déjà dans Connecteed en tant que Chargé de compte
  //       </p>
  //     </BlocInformation>
  //   );
  // }
  if (
    contactRole === ERoles.FREELANCE_PARTNER ||
    contactRole === ERoles.BUSINESS_PARTNER ||
    contactRole === ERoles.CONTRACTOR ||
    contactRole === ERoles.INTERNAL_COMMERCIAL ||
    ERoles.ACCOUNT_MANAGER
  ) {
    if (roleExist) {
      return (
        <Flex ml={10}>
          <ExclamationCircleIcon fill={red} />
          &nbsp;
          <Text variant={'p'} color={red}>
            Contact déja existant&nbsp;
          </Text>
        </Flex>
      );
    }
    const links = [];
    if (isContractor) {
      links.push(
        <ALink
          href={`/providers/contractors/${contact.cognitoUserId as string}`}
          color={red}
        >
          <Text variant={'u'} color={red}>
            intervenant
          </Text>
        </ALink>
      );
    }
    if (isManager) {
      links.push(
        <Text variant={'u'} color={red}>
          apporteur d'affaires
        </Text>
      );
    }
    if (isCustomer) {
      links.push(
        <ALink href={`/clients/contacts/${contact.cognitoUserId}`} color={red}>
          <Text variant={'u'} color={red}>
            client
          </Text>
        </ALink>
      );
    }
    if (isProvider) {
      links.push(
        <ALink
          href={`/providers/contacts/${contact.cognitoUserId}/information`}
          color={red}
        >
          <Text variant={'u'} color={red}>
            fournisseur
          </Text>
        </ALink>
      );
    }
    if (isBusinessPartner) {
      links.push(
        /*<ALink
          href={`/providers/contacts/${contact.cognitoUserId}/information`}
          color={red}
        >
          <Text variant={'u'} color={red}>
            apporteur d'affaires
          </Text>
        </ALink>*/
        <Text variant={'u'} color={red}>
          apporteur d'affaires
        </Text>
      );
    }
    if (isFreelancePartner) {
      links.push(
        /*<ALink
          href={`/providers/contacts/${contact.cognitoUserId}/information`}
          color={red}
        >
          <Text variant={'u'} color={red}>
            freelance partner
          </Text>
        </ALink>*/
        <Text variant={'u'} color={red}>
          freelance partner
        </Text>
      );
    }
    if (isInternalCommercial) {
      links.push(
        /*<ALink
          href={`/providers/contacts/${contact.cognitoUserId}/information`}
          color={red}
        >
          <Text variant={'u'} color={red}>
            apporteur d'affaires
          </Text>
        </ALink>*/
        <Text variant={'u'} color={red}>
          commercial interne
        </Text>
      );
    }
    if (links && links.length > 0) {
      return (
        <Flex ml={10}>
          <ExclamationCircleIcon fill={red} />
          &nbsp;
          <Text variant={'p'} color={red}>
            Contact déja existant voir la fiche :&nbsp;
          </Text>
          {links.map((link, index) => (
            <Box mr={'2px'} ml={'2px'} key={index}>
              {link}
            </Box>
          ))}
        </Flex>
      );
    } else {
      <></>;
    }
  }
  // Contact ready to create
  return <></>;
};
