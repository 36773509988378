import {
  getFullName,
  useUserFindOne,
  useUserUpdateOne,
  queryClient,
} from '@commons';
import { FlatUser } from '@freelancelabs/teoreme-commons';
import { showUpdateProviderContactEmailModal } from 'components/modals/providers/UpdateProviderContactEmailModal';
import { BlocInformation, Box, Flex, Link, Text } from 'components/ui';
import { EditIcon } from 'components/ui/icons';
import { ContactForm } from 'forms/ContactForm';
import { VerifyEmailForm } from 'forms/VerifyEmailForm';
import { useBreadCrumb } from 'hooks/breadCrumb';
import * as React from 'react';
import { useParams } from 'react-router-dom';
export const ClientsContactsInformation: React.FunctionComponent<
  React.PropsWithChildren<unknown>
> = () => {
  const { id } = useParams<{ id: string }>();
  const { data: client, isFetching } = useUserFindOne(id);
  const { mutateAsync: updateClient } = useUserUpdateOne();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [refresh, setRefresh] = React.useState(false);
  const { setCrumbs } = useBreadCrumb();

  React.useEffect(() => {
    setCrumbs([
      {
        label: 'clients',
        path: '/clients',
      },
      {
        label: 'contacts',
        path: '/clients/contacts',
      },
      {
        label: client ? getFullName(client) : '-',
        path: `/clients/contacts/${id}`,
      },
      {
        label: 'informations',
        path: `/clients/contacts/${id}`,
      },
    ]);
  }, [client, id]);
  return (
    <>
      <Text variant="h2" mb={20}>
        Informations générales
      </Text>
      <Flex alignContent="center" alignItems="center">
        <Box width={8 / 10}>
          {!isFetching && (
            <VerifyEmailForm
              contactRole={'CUSTOMER'}
              defaultValues={client}
              isLocked
            />
          )}
        </Box>
        {/* @ts-ignore */}
        {client?.appScopedCount < 2 && (
          <Link
            ml={20}
            iconLeft={<EditIcon />}
            onClick={() =>
              showUpdateProviderContactEmailModal({
                contact: client as FlatUser,
                contactRole: 'CUSTOMER',
              }).then(action => {
                setRefresh(action);
              })
            }
          >
            Modifier
          </Link>
        )}
      </Flex>
      {/* @ts-ignore */}
      {client?.appScopedCount > 1 && (
        <BlocInformation mt={20}>
          L'email de ce contact ne peut etre modifié car sont email est utilisé
          pour d'autres applications Freelance.
        </BlocInformation>
      )}
      <Box height={20} />
      <ContactForm
        contactRole={'CUSTOMER'}
        defaultValues={client}
        onSubmit={async client => {
          await updateClient({ id: id || '', user: client });
          queryClient.refetchQueries({ queryKey: [id] });
        }}
      />
      <Box height={20} />
    </>
  );
};

export default ClientsContactsInformation;
