import { useState } from 'react';
import {
  IEstablishmentBase,
  IEstablishmentMinifiedUsers,
  IEstablishmentFullUsers,
} from '@freelancelabs/teoreme-commons';
import { useEstablishmentUpdateOne } from '@commons'; // moved
import {
  Box,
  Text,
  Link,
  Row,
  FormControl,
  Input,
  Button,
  UserSelectControlled,
} from 'components/ui';
import { sageCreateEstablishment } from '@commons';
import { create } from 'react-modal-promise';
import { queryClient } from '@commons';
import { ModalFrame, ModalProps } from './ModalFrame';
import { AddIcon, CancelIcon } from 'components/ui/icons';
import { showAddContactModal } from 'components/modals/AddContactModal';
import { useForm } from 'react-hook-form';
import { useShowMessage } from 'hooks/useShowMessage';

type Props = ModalProps & {
  beforeValidation?: () => void;
  establishment: IEstablishmentBase;
};
type FormValues = any;

export const AddEstablishmentGescomModal = ({
  onResolve,
  isOpen,
  establishment,
}: Props) => {
  const { mutateAsync: updateEstablishment } = useEstablishmentUpdateOne();
  const [loading, setLoading] = useState(false);
  const showMessage = useShowMessage();
  let dContact;
  const contacts = establishment?.customer?.contacts;
  if (Array.isArray(contacts)) {
    //@ts-ignore
    if (contacts.length > 0) {
      //@ts-ignore
      dContact = contacts[0].cognitoUserId;
    }
  }
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setError,
  } = useForm<FormValues>({
    defaultValues: {
      paymentDeadline:
        establishment?.customer?.paymentDetails?.paymentDeadline || 60,
      email: establishment?.customer?.invoiceContact?.email,
      contact: dContact,
    },
  });
  //const { isDirty } = useFormState();

  const onSubmit = async (formValues: FormValues) => {
    setLoading(true);
    if (!formValues.contact) {
      setError('contact', { message: 'Veuillez renseigner le contact client' });
    } else {
      const findedContact = contacts?.filter(
        c => (c as any).cognitoUserId === formValues.contact
      );
      if (findedContact?.length === 0) {
        try {
          const establishmentData = {
            uuid: establishment?.uuid,
            addContacts: { customer: [formValues.contact] },
          };
          await updateEstablishment(establishmentData as any);
          queryClient.refetchQueries({
            queryKey: ['establishments'],
            type: 'active',
          });
          if (establishment.uuid) {
            try {
              await sageCreateEstablishment({
                establishment: establishment.uuid,
                contact: formValues.contact,
                type: 'customer',
              });
              showMessage('success', 'Établissement ajouté dans GESCOM');
              queryClient.refetchQueries({ queryKey: [establishment.uuid] });
              onResolve(true);
            } catch (e) {
              //
            }
          }
        } catch (e) {
          //s
        }
      } else {
        if (establishment.uuid) {
          try {
            await sageCreateEstablishment({
              establishment: establishment.uuid,
              contact: formValues.contact,
              type: 'customer',
            });
            showMessage('success', 'Établissement ajouté dans GESCOM');
            queryClient.refetchQueries({ queryKey: [establishment.uuid] });
            onResolve(true);
          } catch (e) {}
        }
      }
    }
    setLoading(false);
  };
  return (
    <ModalFrame
      isOpen={isOpen}
      onClose={() => onResolve(false)}
      width={400}
      closeIcon
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box>
          <Text variant="h1" mb={20}>
            Créer dans GESCOM
          </Text>
          <Text variant="p" mb={20}>
            Avant de valider la création de cet établissement client dans
            GESCOM, merci de vérifier le contact de facturation, le délai de
            paiement et de sélectionner un contact opérationnel client.
          </Text>
          <Box width={2 / 2}>
            <FormControl label={'Contact de facturation'}>
              <Input
                isFullWidth
                {...register('email')}
                type="email"
                isDisabled
              />
            </FormControl>
          </Box>
          <Box width={2 / 2}>
            <FormControl label={'Délai de paiement'}>
              <Input
                isFullWidth
                {...register('paymentDeadline')}
                value={60}
                type="number"
                isDisabled
              />
            </FormControl>
          </Box>
          <Box width={2 / 2}>
            <FormControl
              label="Contact client "
              required
              errorMessage={errors?.contact?.message}
            >
              <>
                <UserSelectControlled
                  control={control}
                  name="contact"
                  role="CUSTOMER"
                  placeholder="Contact client"
                />
                <Link
                  mt={'5px'}
                  iconLeft={<AddIcon />}
                  onClick={() =>
                    showAddContactModal({ contactRole: 'CUSTOMER' })
                  }
                >
                  Ajouter un contact client
                </Link>
              </>
            </FormControl>
          </Box>
        </Box>
        <Box>
          <Row spacing={20} width={'300px'}>
            <Button key="submit" type="submit" isLoading={loading}>
              Créer
            </Button>
            <Link
              iconLeft={<CancelIcon />}
              mt={'20px'}
              onClick={() => onResolve(false)}
            >
              Annuler
            </Link>
          </Row>
        </Box>
      </form>
    </ModalFrame>
  );
};

export const showAddEstablishmentGescomModal = create<
  Props,
  IEstablishmentMinifiedUsers | IEstablishmentFullUsers
>(AddEstablishmentGescomModal);
