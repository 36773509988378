import React from 'react';
import { missionUpdateOne, establishmentFindRCPRO } from '@commons';
import { queryClient } from '@commons';
import {
  EAdditionalServiceType,
  EAdditionalServiceStatus,
  EPriceUnit,
  IAdditionalService,
  IJoinedMission,
  EMissionStatus,
  ICompanySearchRcproResponse,
} from '@freelancelabs/teoreme-commons';
import { showAdditionalServicesModal } from 'components/modals/AdditionalServicesModal';
import { showCancelAdditionalServicesModal } from 'components/modals/CancelAdditionalServicesModal';

import {
  Status,
  Itemize,
  Box,
  Flex,
  Link,
  Text,
  Table,
  TableColumn,
  Menu,
  MenuItem,
} from 'components/ui';
import { DotsIcon, CloseIcon } from 'components/ui/icons';
import { AddIcon, EditIcon } from 'components/ui/icons';
import { ADDITIONAL_SERVICES_TYPE_FR } from '@commons';
import { showDialogModal } from 'components/modals/DialogModal';
import { Theme } from 'styles';
const additionalServiceStatus = {
  ACTIVE: 'Actif',
  PENDING: 'En cours de souscription',
  INACTIVE: 'Inactif',
};
type Item = Itemize<IAdditionalService>;
const columns = (
  forRole: 'ALL' | 'CUSTOMER' | 'PROVIDER',
  services: IAdditionalService[],
  mission: IJoinedMission,
  isDisabled: boolean = false,
  onUpdate: (service: IAdditionalService, index?: number) => any,
  onDelete: (service: IAdditionalService, index?: number) => any
) =>
  [
    {
      key: 'type',
      text: 'TYPE',
      opacity: (item: Item) =>
        item.status === EAdditionalServiceStatus.INACTIVE ? 0.5 : 1,
      render: item => {
        if (item?.type === EAdditionalServiceType.FAST_CASH) {
          return <Text fontSize={12}>Fast Cash</Text>;
        } else {
          return (
            <Text fontSize={12}>
              <Flex>
                <Text fontSize={12}>
                  {ADDITIONAL_SERVICES_TYPE_FR[item?.type]}
                </Text>
              </Flex>
              <Flex>
                {' '}
                <Text fontSize={9} variant="disabled">
                  {item?.description}
                </Text>
              </Flex>
            </Text>
          );
        }
      },
    },
    {
      key: 'status',
      text: 'STATUT',
      opacity: (item: Item) =>
        item.status === EAdditionalServiceStatus.INACTIVE ? 0.5 : 1,
      render: item => {
        if (item.status === undefined) {
          return (
            <Status variantColor="warning">
              {/*@ts-ignore*/}
              {additionalServiceStatus[EAdditionalServiceStatus.PENDING]}
            </Status>
          );
        }
        switch (item?.status) {
          case EAdditionalServiceStatus.ACTIVE:
            return (
              <Status variantColor="success">
                {/*@ts-ignore*/}
                {additionalServiceStatus[item?.status]}
              </Status>
            );
          case EAdditionalServiceStatus.PENDING:
            return (
              <Status variantColor="warning">
                {/*@ts-ignore*/}
                {additionalServiceStatus[item?.status]}
              </Status>
            );
          case EAdditionalServiceStatus.INACTIVE:
            if (item?.type === EAdditionalServiceType.HANDLING_FEE) {
              return <Status variantColor="success">Payé</Status>;
            }
            return (
              <Status variantColor="grey">
                {/*@ts-ignore*/}
                {additionalServiceStatus[item?.status]}
              </Status>
            );
        }
      },
    },
    {
      key: 'validity',
      text: 'PÉRIODE DE VALIDITÉ',
      opacity: (item: Item) =>
        item.status === EAdditionalServiceStatus.INACTIVE ? 0.5 : 1,
      render: item => {
        if (item?.type === EAdditionalServiceType.FAST_CASH) {
          return 'N/A';
        }
        const validityStart = new Date(item?.validityStart as Date);
        const validityEnd = item?.validityEnd
          ? new Date(item.validityEnd as Date)
          : false;
        return (
          <Text fontSize={12}>{`${
            validityStart.getUTCMonth() + 1
          }/${validityStart.getUTCFullYear()} >  ${
            validityEnd ? validityEnd.getUTCMonth() + 1 : ''
          }${validityEnd ? '/' : ''}${
            validityEnd ? validityEnd.getUTCFullYear() : 'N/A'
          }`}</Text>
        );
      },
    },
    {
      key: 'paidByProvider',
      text: 'PAYÉ PAR',
      opacity: (item: Item) =>
        item.status === EAdditionalServiceStatus.INACTIVE ? 0.5 : 1,
      render: item => {
        return (
          <Text fontSize={12}>
            {item?.paidByProvider ? 'Fournisseur' : 'Client'}
          </Text>
        );
      },
    },
    {
      key: 'price',
      text: 'MONTANT',
      opacity: (item: Item) =>
        item.status === EAdditionalServiceStatus.INACTIVE ? 0.5 : 1,
      render: item => {
        const price = item?.price;
        const unit = item?.unit;
        return (
          <Text fontSize={12}>{`${price || 'N/A'} ${
            price ? getPriceByUnit(unit as EPriceUnit) : ''
          }`}</Text>
        );
      },
    },
    {
      key: 'actions',
      text: 'ACTIONS',
      align: 'flex-end',
      opacity: (item: Item) =>
        item.status === EAdditionalServiceStatus.INACTIVE ? 0.5 : 1,
      render: item => {
        const notEditable =
          (item.type === EAdditionalServiceType.GDPR &&
            mission?.status === EMissionStatus.VALIDATED) ||
          item.status === EAdditionalServiceStatus.INACTIVE ||
          item.type === EAdditionalServiceType.MISC;

        return (
          <Flex>
            {!notEditable && !isDisabled ? (
              <Menu
                align="right"
                menuItems={
                  <>
                    <MenuItem>
                      <Link
                        onClick={() =>
                          showAdditionalServicesModal({
                            service: item as IAdditionalService,
                            forRole: forRole,
                            additionalServices: services,
                            mission: mission,
                          }).then(
                            service =>
                              service &&
                              onUpdate(
                                service,
                                services.findIndex(
                                  //@ts-ignore
                                  data => data.key === service.key
                                )
                              )
                          )
                        }
                        iconLeft={<EditIcon />}
                      >
                        Modifier
                      </Link>
                    </MenuItem>
                    {mission?.status === EMissionStatus.VALIDATED &&
                    item?.type !== EAdditionalServiceType.FAST_CASH ? (
                      <MenuItem>
                        <Link
                          onClick={() =>
                            showCancelAdditionalServicesModal({
                              service: item,
                              mission: mission,
                            })
                          }
                          iconLeft={<CloseIcon />}
                        >
                          Résilier
                        </Link>
                      </MenuItem>
                    ) : (
                      item?.type !== EAdditionalServiceType.FAST_CASH &&
                      mission.status !== EMissionStatus.DRAFT && (
                        <MenuItem>
                          <Link
                            onClick={() =>
                              onDelete(
                                item,
                                //@ts-ignore
                                services.findIndex(
                                  (data: any) => data?.key === item.key
                                )
                              )
                            }
                            iconLeft={<CloseIcon />}
                          >
                            Supprimer
                          </Link>
                        </MenuItem>
                      )
                    )}
                  </>
                }
              >
                <DotsIcon
                  fill={Theme?.colors?.primary?.default}
                  fontSize={20}
                />
              </Menu>
            ) : (
              <DotsIcon fill="grey" fontSize={20} />
            )}
          </Flex>
        );
      },
    },
  ] as TableColumn<Item>[];

const getPriceByUnit = (unit: EPriceUnit): string => {
  switch (unit) {
    case EPriceUnit.PERCENT_INVOICE:
      return '% du montant de la facture du payeur';

    case EPriceUnit.FLAT_PRICE:
    default:
      return `€`;

    case EPriceUnit.PRICE_PER_WORKING_DAY:
      return `€ /jour`;

    case EPriceUnit.PRICE_PER_DAY:
      return `€ /jour`;

    case EPriceUnit.PRICE_PER_MONTH:
      return `€ /mois`;

    case EPriceUnit.PRICE_PER_YEAR:
      return `€ /ans`;
    case EPriceUnit.ONE_TIME_PERCENT:
      return `% du montant de la facture en paiement unique`;
    case EPriceUnit.ONE_TIME_PRICE:
      return `€ en paiement unique`;
  }
};
type MissionFormValues = {
  formValues?: {
    endAt: Date;
    startAt: Date;
  };
};
type FormValues = {
  forRole: 'ALL' | 'CUSTOMER' | 'PROVIDER';
  value?: IAdditionalService[];
  onChange?: (service: IAdditionalService | false) => any;
  mission: IJoinedMission & MissionFormValues;
  isDisabled?: boolean;
};

export const ServiceHandler = ({
  forRole,
  value: services = [],
  onChange,
  mission,
  isDisabled = false,
}: FormValues) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loadingRc, setLoadingRc] = React.useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [estaHaveRCPRO, setEstaHaveRCPRO] = React.useState<
    ICompanySearchRcproResponse | null | false
  >(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [missionRCPRO, setMissionRCPRO] = React.useState<[string] | []>([]);
  const [order, setOrder] = React.useState<'desc' | 'asc' | undefined>('asc');
  const [sortedBy, setSortedBy] = React.useState('lastName firstName');
  // const sortString = (order === 'desc' ? '-' : '') + sortedBy;

  const [page, setPage] = React.useState(0);
  const onSortedChange = async (
    shortBy: string,
    order: 'desc' | 'asc'
  ): Promise<void> => {
    setSortedBy(shortBy);
    setOrder(order);
  };
  const onDelete = async (service: IAdditionalService, index?: number) => {
    await showDialogModal({
      title: 'Supprimer le service',
      text: 'Êtes-vous sûr de vouloir supprimer ce service complémentaire ? Attention, cette modification ne sera pas automatiquement communiquée à votre client/fournisseur. Si besoin, pensez à le(s) notifier par mail et/ou à leur renvoyer le devis/bdc fournisseur correspondant.',
      confirmLabel: 'Supprimer',
      cancelLabel: 'Ne pas Supprimer',
    }).then(async action => {
      if (action) {
        if (forRole === 'ALL') {
          if (service && mission) {
            const submitData = {
              reference: mission.reference,
              mission: {
                additionalServices: {
                  cancel: [
                    {
                      uuid: service?.uuid,
                    },
                  ],
                },
              },
            };
            try {
              //@ts-ignore
              await missionUpdateOne(submitData);
              queryClient.refetchQueries({ queryKey: [mission.reference] });
            } catch (e) {
              //
            }
          }
        } else {
          setServices(index, undefined, true);
        }
      }
    });
  };
  const setServices = (
    index?: number,
    service?: IAdditionalService,
    toBedeleted?: boolean
  ) => {
    if (index === undefined && service) {
      services.push(service);
    } else {
      if (index !== undefined) {
        if (toBedeleted === true) {
          services.splice(index, 1);
        } else {
          services[index] = service as IAdditionalService;
        }
      } else {
        console.warn('SET SERVICE INDEX UNDEFINED');
      }
    }
    onChange?.(services as any);
  };

  const onAdd = (service: IAdditionalService | false, index?: number) => {
    /*
    WARNING:
    !!! IF forRole !== 'ALL'
      update of the service is not by API but locally with (setServices).
    !!!
     */
    if (forRole === 'ALL') {
      onChange?.(service);
    } else {
      if (service) {
        if (index !== undefined) {
          //@ts-ignore
          service.updated = true;
        }
        setServices(
          index !== undefined && index >= 0 ? index : undefined,
          service,
          false
        );
      }
    }
  };

  const items =
    services?.map(u => ({
      key: u.uuid || String(new Date().getTime()),
      ...u,
    })) || [];
  const filteredServices = items.filter(
    s => forRole === 'ALL' || (forRole === 'PROVIDER') === s.paidByProvider
  );
  const findEstablishmentRCPO = async () => {
    setLoadingRc(true);
    const missionRCP: [string] | [] = [];
    try {
      const rcPro = await establishmentFindRCPRO({
        uuid: mission?.provider?.establishment?.uuid,
        filterObject: {},
      });
      let rcproActive = false;
      rcPro?.results.forEach(element => {
        //@ts-ignore
        missionRCP.push(element.displayRefMission);
        if (
          element?.status === EAdditionalServiceStatus.ACTIVE ||
          element?.status === EAdditionalServiceStatus.PENDING
        ) {
          rcproActive = true;
        }
      });
      if (rcproActive) {
        setEstaHaveRCPRO(rcPro);
      } else {
        setEstaHaveRCPRO(false);
      }
    } catch (e) {
      //
    }
    setLoadingRc(false);
    setMissionRCPRO(missionRCP);
  };
  React.useEffect(() => {
    if (mission && mission?.reference && mission?.provider?.establishment) {
      findEstablishmentRCPO();
    }
  }, [mission?.reference]);
  return (
    <>
      {items.length > 0 ? (
        <Box>
          <Link
            mb={20}
            isDisabled={isDisabled}
            onClick={() => {
              if (isDisabled) return;
              showAdditionalServicesModal({
                mission: mission,
                forRole: forRole,
                additionalServices: items,
              }).then(onAdd);
            }}
            iconLeft={<AddIcon />}
          >
            Ajouter un service
          </Link>
          <Table
            columns={columns(
              forRole,
              items,
              mission,
              isDisabled,
              onAdd,
              onDelete
            )}
            items={filteredServices.sort((a, b) => {
              return a.status === EAdditionalServiceStatus.INACTIVE ? 1 : -1;
            })}
            sortedBy={sortedBy}
            order={order}
            page={page}
            total={services.length}
            perPage={2000}
            onSortedChange={onSortedChange}
            onChangePage={page => setPage(page)}
            isLoading={false}
          />
        </Box>
      ) : (
        <Box>
          <Text mb={20}>Aucun service complémentaire</Text>
          <Link
            isDisabled={isDisabled}
            onClick={() => {
              if (isDisabled) return;
              showAdditionalServicesModal({
                forRole: forRole,
                additionalServices: items,
                mission: mission,
              }).then(onAdd);
            }}
            iconLeft={<AddIcon />}
          >
            Ajouter un service
          </Link>
        </Box>
      )}
    </>
  );
};
