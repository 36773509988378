import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ClientsContactsDetails } from './Detail';
import { ClientsContactsList } from './List';

export const ClientsContacts = () => {
  return (
    <Switch>
      <Route exact path="/clients/contacts">
        <ClientsContactsList />
      </Route>
      <Route path="/clients/contacts/:id">
        <ClientsContactsDetails />
      </Route>
    </Switch>
  );
};
