import { boxProperties, BoxProps } from 'components/ui';
import Dropdown from 'react-bootstrap/Dropdown';
import { lighten } from 'polished';

import 'styles/bootstrap.css';
import styled, { css } from 'styled-components';

type DropDownStyledProps = BoxProps & {
  variant?:
    | 'primary'
    | 'filled'
    | 'ghost'
    | 'warning'
    | 'error'
    | 'success'
    | 'ghostError';
};
const DropDownStyled = styled.div<DropDownStyledProps>`
  button:nth-child(1) {
    min-width: ${props => props?.minWidth || 'auto'};
  } /**primary */
  button {
    background-color: ${props => props?.theme?.colors?.primary?.default};
    border-color: ${props => props?.theme?.colors?.primary?.default} !important;
    font-size: 16px;
    height: 50px;
    font-weight: 600;
    border-radius: 6px;
    &:hover {
      background-color: ${p => lighten(0.15, p.theme.colors.blue)};
    }
  }
  .dropdown-menu {
    color: white;
    background-color: ${props => props?.theme?.colors?.primary?.default};
    border-color: ${props => props?.theme?.colors?.primary?.default};
  }
  .dropdown-item {
    color: white;
    background-color: ${props => props?.theme?.colors?.primary?.default};
    border-color: ${props => props?.theme?.colors?.primary?.default};
    &:hover {
      background-color: ${p => lighten(0.15, p.theme.colors.blue)};
    }
  }
  .dropdown {
    overflow: visible !important;
    position: relative !important;
    padding: 0;
    z-index: 10;
  }
  ${props =>
    props.variant === 'primary' &&
    css`
      button {
        background-color: ${props => props?.theme?.colors?.primary?.default};
        border-color: ${props => props?.theme?.colors?.primary?.default};
        border-radius: 16px;
      }
    `}
  ${props =>
    props.variant === 'ghost' &&
    css`
      button {
        p {
          color: ${props.theme.colors.primary.default};
        }
        color: ${props.theme.colors.primary.default};
        background-color: white;
        border: 2px solid ${props.theme.colors.primary.default};
        border-radius: 16px;
      }
      button:hover {
        p {
          color: white;
        }
        background-color: ${props.theme.colors.primary.default};
        border: 2px solid ${props.theme.colors.primary.default};
        border-radius: 16px;
      }
    `}
  ${props =>
    props.variant === 'ghostError' &&
    css`
      button {
        color: ${props.theme.colors.error.default};
        background-color: transparent;
        border: 2px solid ${props.theme.colors.error.default};
        border-radius: 16px;
      }
      button:hover {
        p {
          color: white;
        }
        background-color: ${props.theme.colors.error.default};
        border: 2px solid ${props.theme.colors.error.default};
        border-radius: 16px;
      }
    `}
    ${props =>
    props.variant === 'warning' &&
    css`
      button {
        p {
          color: ${props.theme.colors.warning.default};
        }
        color: ${props.theme.colors.warning.default};
        background-color: white;
        border: 2px solid ${props.theme.colors.warning.default};
        border-radius: 16px;
      }
      button:hover {
        p {
          color: white;
        }
        background-color: ${props.theme.colors.warning.default};
        border: 2px solid ${props.theme.colors.warning.default};
        border-radius: 16px;
      }
    `}
    ${props =>
    props.variant === 'error' &&
    css`
      button {
        p {
          color: ${props.theme.colors.error.default};
        }
        color: ${props.theme.colors.error.default};
        background-color: white;
        border: 2px solid ${props.theme.colors.error.default};
        border-radius: 16px;
      }
      button:hover {
        p {
          color: white;
        }
        background-color: ${props.theme.colors.error.default};
        border: 2px solid ${props.theme.colors.error.default};
        border-radius: 16px;
      }
    `}
    ${props =>
    props.variant === 'success' &&
    css`
      button {
        p {
          color: ${props.theme.colors.success.default};
        }
        color: ${props.theme.colors.success.default};
        background-color: white;
        border: 2px solid ${props.theme.colors.success.default};
        border-radius: 16px;
      }
      button:hover {
        p {
          color: white;
        }
        background-color: ${props.theme.colors.success.default};
        border: 2px solid ${props.theme.colors.success.default};
        border-radius: 16px;
      }
    `}
    &:disabled {
    button {
      background-color: ${p => lighten(0.6, p.theme.colors.primary.default)};
      color: ${p => lighten(0.3, p.theme.colors.primary.default)};
      border: none;
      cursor: not-allowed;
      border-radius: 16px;
    }
  }

  ${boxProperties}
`;
type DropDownButtonItemProps = {
  href?: string;
  onClick?: (e: any) => void;
  children: any;
};
type DropDownButtonProps = DropDownStyledProps & {
  buttonText: string;
  items: DropDownButtonItemProps[];
  onClick: () => void;
  minWidth?: string;
};
export const DropDownButtonCustom = ({
  buttonText,
  items,
  onClick,
  minWidth,
  ...props
}: DropDownButtonProps) => {
  return (
    <DropDownStyled {...props} minWidth={minWidth}>
      <Dropdown>
        <Dropdown.Toggle>{buttonText}</Dropdown.Toggle>
        <Dropdown.Menu>
          {items?.map(item => (
            <Dropdown.Item
              onClick={(e: any) => (item?.onClick ? item?.onClick(e) : false)}
              href={item?.href}
            >
              {item?.children}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </DropDownStyled>
  );
};

export default DropDownButtonCustom;
