import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { AdditionalActivityList } from './List';

export const AdditionalActivity = () => {
  return (
    <Switch>
      <Route exact path="/orders/additional-activities">
        <Redirect to="/orders/additional-activities/to-be-validated" />
      </Route>
      <Route path="/orders/additional-activities/:filter(awaiting|validated|to-be-validated|archived|rejected)">
        <AdditionalActivityList />
      </Route>
    </Switch>
  );
};
