import { ECraStatus, IJoinedCra } from '@freelancelabs/teoreme-commons';
import { useCraUpdateOne } from '@commons'; // moved
import { queryClient } from '@commons';
import { Box } from 'components/ui';
import { RejectCraForm } from 'forms/RejectCraForm';
import React from 'react';
import { create } from 'react-modal-promise';
import { ModalFrame, ModalProps } from './ModalFrame';
import { useShowMessage } from 'hooks/useShowMessage';

type Props = ModalProps & {
  beforeValidation?: () => void;
  cra: IJoinedCra;
  layer?: number;
};

export const RejectCraModal = ({ onResolve, isOpen, cra, layer }: Props) => {
  const { mutateAsync: updateCra } = useCraUpdateOne();
  const showMessage = useShowMessage();
  const onRejectCra = async (craData: { cra: IJoinedCra }) => {
    try {
      const updateCRAResponse = await updateCra({
        refCras: [cra.refCra],
        cra: {
          state: ECraStatus.REJECTED,
          // @ts-ignore
          rejectReason: craData.cra.rejectReason,
          rejectMetaData: craData.cra.rejectMetaData,
        },
      });
      if (
        //@ts-ignore
        updateCRAResponse?.errorUpdateArray &&
        //@ts-ignore
        updateCRAResponse?.errorUpdateArray?.length > 0
      ) {
        showMessage('error', 'Une erreur est survenue sur le  CRA');
        throw new Error('Une erreur est survenue sur le  CRA');
      } else {
        onResolve(true);
      }
      queryClient.refetchQueries({ queryKey: ['Cras'], type: 'active' });
      queryClient.refetchQueries({ queryKey: [craData.cra.refCra] });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <ModalFrame
      isOpen={isOpen}
      onClose={() => onResolve(false)}
      width={550}
      closeIcon
      layer={layer}
    >
      {cra && (
        <Box mt={20}>
          <RejectCraForm defaultValues={{ cra }} onSubmit={onRejectCra} />
        </Box>
      )}
    </ModalFrame>
  );
};

export const showRejectCraModal = create<Props, boolean>(RejectCraModal);
