import { getEstablishmentName } from '@commons';
import { useEstablishmentFindMany } from '@commons';
import { Select } from 'components/ui';
import { components, GetStyles, GroupBase } from 'react-select';
import { useDebounce } from 'hooks/useDebounce';
import { ComponentPropsWithoutRef, useState, ReactNode } from 'react';

type MultiValueType = {
  label: string;
  value: string;
};
type RadioSelectEstablishmentsProps = {
  isDisabled?: boolean;
  values?: MultiValueType[] | undefined;
  onChange?: (establishments: MultiValueType[]) => void;
  filter: any;
  referenceValue: 'uuid' | 'siren';
} & Omit<ComponentPropsWithoutRef<'input'>, 'event' | 'onChange' | 'value'>;

type InputOptionsProps = {
  getStyles: GetStyles<unknown, boolean, GroupBase<unknown>>;
  Icon: ReactNode;
  isDisabled: boolean;
  isFocused: boolean;
  isSelected: boolean;
  children: ReactNode;
  innerProps: any;
};
const InputOption = ({
  getStyles,
  Icon,
  isDisabled,
  isFocused,
  isSelected,
  children,
  innerProps,
  ...rest
}: InputOptionsProps) => {
  const [isActive, setIsActive] = useState(false);
  const onMouseDown = () => setIsActive(true);
  const onMouseUp = () => setIsActive(false);
  const onMouseLeave = () => setIsActive(false);

  // styles
  let bg = 'transparent';
  if (isFocused) bg = '#eee';
  if (isActive) bg = '#B2D4FF';

  const style = {
    alignItems: 'center',
    backgroundColor: bg,
    color: 'inherit',
    display: 'flex ',
  };

  // prop assignment
  const props = {
    ...innerProps,
    onMouseDown,
    onMouseUp,
    onMouseLeave,
    style,
  };

  return (
    //@ts-ignore
    <components.Option
      {...rest}
      isDisabled={isDisabled}
      isFocused={isFocused}
      isSelected={isSelected}
      getStyles={getStyles}
      innerProps={props}
    >
      <input
        style={{ marginRight: '5px' }}
        type="checkbox"
        checked={isSelected}
      />
      {children}
    </components.Option>
  );
};
export const RadioSelectEstablishments = ({
  values,
  onChange,
  filter: globalFilter,
  referenceValue = 'uuid',
  isDisabled,
  ...props
}: RadioSelectEstablishmentsProps) => {
  const [filter, setFilter] = useState('');
  const debouncedFilter = useDebounce(filter, 500);
  const { data: establishmentsQuery, isFetching } = useEstablishmentFindMany({
    filterObject: {
      ...globalFilter,
      $or: [
        { businessName: { $regex: debouncedFilter, $options: 'i' } },
        { tradeName: { $regex: debouncedFilter, $options: 'i' } },
        { signBoard1: { $regex: debouncedFilter, $options: 'i' } },
        { signBoard2: { $regex: debouncedFilter, $options: 'i' } },
        { signBoard3: { $regex: debouncedFilter, $options: 'i' } },
        { identifier: { $regex: debouncedFilter, $options: 'i' } },
        { siret: { $regex: debouncedFilter, $options: 'i' } },
        { siren: { $regex: debouncedFilter, $options: 'i' } },
        { identifier: { $regex: debouncedFilter, $options: 'i' } },
      ],
    },
    limit: 10,
  });

  const selectedOption = values;

  const establishmentOptions =
    establishmentsQuery?.establishments?.map(e => ({
      label: getEstablishmentName(e) + ' ' + (e.siret || e.identifier),
      value: e.uuid || '',
    })) || [];
  //console.log('selectedEstablishments', selectedEstablishments);
  return (
    <Select
      isDisabled={isDisabled}
      options={establishmentOptions}
      isLoading={isFetching}
      onInputChange={(value: string) => {
        if (value !== '') {
          setFilter(value);
        }
      }}
      onChange={(option: any) => {
        onChange && onChange(option && option);
      }}
      isClearable={true}
      value={selectedOption}
      closeMenuOnSelect={false}
      hideSelectedOptions={false}
      isMulti
      components={{
        //@ts-ignore
        Option: InputOption,
      }}
      {...props}
    />
  );
};
