import {
  IJoinedContractor,
  IJoinedContractorScopedData,
  IContractorUpdateScopedData,
} from '@freelancelabs/teoreme-commons';
import { contractorUpdateOne } from '@commons';
import { Box } from 'components/ui';
import React from 'react';
import { create } from 'react-modal-promise';
import { queryClient } from '@commons';
import { ModalFrame, ModalProps } from './ModalFrame';
import { UpdateContractorScopedDataForm } from 'forms/UpdateContractorScopedDataForm';

type Props = ModalProps & {
  beforeValidation?: () => void;
  contractor?: IJoinedContractor;
  scopedData?: IJoinedContractorScopedData;
};

export const UpdateContractorScopedDataModal = ({
  onResolve,
  isOpen,
  contractor,
  scopedData,
}: Props) => {
  const onUpdateContractorScopedData = async (contractorData: {
    scopedData: IContractorUpdateScopedData;
  }) => {
    try {
      const establishmentUuid = scopedData?.establishment.uuid || '';
      const newContractor = await contractorUpdateOne({
        uuid: contractor?.uuid || '',
        scopedData: [
          {
            ...contractorData.scopedData,
            establishment: establishmentUuid,
          },
        ],
      });
      queryClient.refetchQueries({ queryKey: [contractor?.uuid] });
      if (newContractor) onResolve(newContractor);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <ModalFrame
      isOpen={isOpen}
      onClose={() => onResolve(false)}
      width={850}
      closeIcon
    >
      {contractor && (
        <Box mt={20}>
          <UpdateContractorScopedDataForm
            contractor={contractor}
            defaultValues={{ scopedData }}
            onSubmit={onUpdateContractorScopedData}
          />
        </Box>
      )}
    </ModalFrame>
  );
};

export const showUpdateContractorScopedDataModal = create<
  Props,
  IJoinedContractor
>(UpdateContractorScopedDataModal);
