import * as React from 'react';
import {
  IUserLocal,
  ERoles,
  getFullName,
  useUserFindOne,
  useUserUpdateOne,
} from '@commons';
import { Box, Flex, Text } from 'components/ui';
import { useBreadCrumb } from 'hooks/breadCrumb';
import { useParams } from 'react-router-dom';
import { OthersContactForm } from 'forms/OthersContactForm';
import { VerifyEmailForm } from 'forms/VerifyEmailForm';

type FormValues = Partial<Omit<IUserLocal, 'civility'>> & {
  civility: string;
};
export const OtherContactsInformation: React.FunctionComponent<
  React.PropsWithChildren<unknown>
> = () => {
  const { id } = useParams<{ id: string }>();
  const { data: contact, isFetching } = useUserFindOne(id);
  const { mutateAsync: updateContact } = useUserUpdateOne();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [refresh, setRefresh] = React.useState(false);
  const { setCrumbs } = useBreadCrumb();

  React.useEffect(() => {
    setCrumbs([
      {
        label: 'Autres contacts',
        path: '/others/contacts/active',
      },
      {
        label: contact ? getFullName(contact) : '-',
        path: `/others/contacts/${id}/information`,
      },
      {
        label: 'informations',
        path: `/others/contacts/${id}/information`,
      },
    ]);
  }, [contact, id]);
  const handleUpdateContact = async (formValues: FormValues) => {
    try {
      // transform form values as requested
      const values = {
        email: contact?.email,
        havePortalClientAccess: formValues?.havePortalClientAccess,
      };
      await updateContact({ id: id || '', user: values });
    } catch (e) {
      //
    }
  };
  return (
    <>
      <Text variant="h2" mb={20}>
        Informations générales
      </Text>
      <Flex alignContent="center" alignItems="center">
        <Box width={8 / 10}>
          {!isFetching && (
            <VerifyEmailForm
              contactRole={ERoles.INTERNAL_COMMERCIAL}
              defaultValues={contact}
              isLocked
            />
          )}
        </Box>
      </Flex>
      <Box height={20} />
      <OthersContactForm
        defaultValues={contact}
        onSubmit={(formValues: FormValues) => handleUpdateContact(formValues)}
      />
      <Box height={20} />
    </>
  );
};

export default OtherContactsInformation;
