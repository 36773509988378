import * as React from 'react';

function SvgDuplicateIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        d="M15.5 4c.01 0 .019.005.028.006a.521.521 0 01.136.027l.045.016a.494.494 0 01.144.097l2.501 2.501a.486.486 0 01.096.143c.007.015.012.031.017.047a.5.5 0 01.027.132c0 .011.006.02.006.031v10.5a.5.5 0 01-.5.5H8.5a.5.5 0 01-.5-.5v-10H7V19h8.5a.5.5 0 010 1h-9a.5.5 0 01-.5-.5V7a.5.5 0 01.5-.5H8v-2a.5.5 0 01.5-.5zM15 5H9v12h8.5V7.5h-2A.5.5 0 0115 7V5zm-1.75 4c.237 0 .429.192.429.429v2.142h2.142a.429.429 0 110 .858H13.68v2.142a.429.429 0 11-.858 0V12.43H10.68a.429.429 0 110-.858h2.142V9.43c0-.237.192-.429.429-.429zM16 5.707V6.5h.793L16 5.707z"
      />
    </svg>
  );
}

export default SvgDuplicateIcon;
