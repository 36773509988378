import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { FlatRateDetails } from './Details';
export const FlatRate = () => {
  return (
    <Switch>
      <Route path="/orders/activities/flat-rate/:missionRef">
        <FlatRateDetails />
      </Route>
    </Switch>
  );
};
