import { isTimeSpentInvoice, isAdditionalActivityInvoice } from '@commons';
import {
  IJoinedInvoice,
  EInvoiceStatus,
  IAdditionalActivity,
  EInvoiceDocumentType,
  EInvoiceType,
} from '@freelancelabs/teoreme-commons';
//Valider
export const isValidableInvoice = (
  invoice: IJoinedInvoice & {
    //mergeInvoiceAndAdditionalActivity
    additionalActivity?: IAdditionalActivity;
  }
) => {
  const notValidatedStatus = [
    'TO_BE_FILLED',
    'TO_BE_SUBMITTED',
    'REJECTED',
    'TO_BE_VALIDATED',
  ];
  if (invoice?.status === EInvoiceStatus?.TO_BE_VALIDATED) {
    if (
      isTimeSpentInvoice(invoice?.invoiceType) &&
      !notValidatedStatus?.includes(invoice?.cra?.state)
    ) {
      return true;
    }
    if (
      isAdditionalActivityInvoice(invoice?.invoiceType) &&
      //@ts-ignore
      !notValidatedStatus?.includes(invoice?.additionalActivity?.status)
    ) {
      return true;
    }
  }
  return false;
};
//Relancer
export const isSendableReminderInvoice = (invoice: IJoinedInvoice) => {
  const status = invoice?.status;
  if (
    status === EInvoiceStatus.TO_BE_FILLED ||
    status === EInvoiceStatus.TO_BE_SUBMITTED ||
    status === EInvoiceStatus.REJECTED
  ) {
    return true;
  }
  return false;
};
//Passer au statut “Envoyé”
export const isSendableCustomerInvoice = (invoice: IJoinedInvoice) => {
  if (
    invoice?.invoiceFile &&
    !invoice?.rpaErrorDetails &&
    invoice?.status === EInvoiceStatus?.VALIDATED
  ) {
    return true;
  }
  return false;
};
//Réinitialiser l’erreur RPA
export const isResetableErrorRPA = (invoice: IJoinedInvoice) => {
  if (
    invoice?.rpaErrorDetails &&
    [EInvoiceStatus.VALIDATED, EInvoiceStatus.SENT].includes(invoice?.status)
  ) {
    return true;
  }
  return false;
};
//Générer et envoyer la facture client
//NEED MERGE CUSTOMER INVOICE AND PROVIDER INVOICE !
export const isGenerableCustomerInvoice = (
  customerInvoice: IJoinedInvoice & { providerInvoice?: IJoinedInvoice }
) => {
  if (
    customerInvoice?.providerInvoice?.sage &&
    !customerInvoice.sage &&
    customerInvoice.status === EInvoiceStatus.PROJECTED
  ) {
    return true;
  }
  return false;
};

// provider invoice
export const canDisplayPoviderInvoiceResendButton = (
  invoice: IJoinedInvoice & { providerInvoice?: IJoinedInvoice }
) => {
  const invoiceStatus = invoice?.status;
  const allowedResendStatus = [
    EInvoiceStatus.TO_BE_FILLED,
    EInvoiceStatus.TO_BE_SUBMITTED,
    EInvoiceStatus.REJECTED,
  ];
  return allowedResendStatus.includes(invoiceStatus);
};

//https://fcomdev.atlassian.net/browse/TEOR-5429
export const canDisplayPoviderCreateCreditNoteButton = (
  invoice: IJoinedInvoice & { providerInvoice?: IJoinedInvoice }
) => {
  return (
    (invoice?.invoiceType === EInvoiceType?.PROVIDER ||
      invoice?.invoiceType === EInvoiceType?.PROVIDER_MILESTONE) &&
    (invoice?.status === EInvoiceStatus?.VALIDATED ||
      invoice?.status === EInvoiceStatus?.PAID) &&
    invoice?.sage?.documentType === EInvoiceDocumentType.INVOICE
  );
};

export const canDisplayPoviderInvoiceShowCreditNoteButton = (
  invoice: IJoinedInvoice & { providerInvoice?: IJoinedInvoice }
) => {
  return invoice?.status === EInvoiceStatus?.CANCELLED;
};

export const canDisplayPoviderInvoiceCreateInGescomButton = (
  invoice: IJoinedInvoice & { providerInvoice?: IJoinedInvoice },
  statusFilter: string
) => {
  return (
    (statusFilter === 'validated' || statusFilter === 'archived') &&
    invoice?.sage &&
    invoice?.sage?.documentType !== EInvoiceDocumentType.INVOICE
  );
};

export const canCreateSageInvoice = (invoice: IJoinedInvoice) => {
  //FIX TEOR-5294
  const isValidStatus =
    invoice?.status === EInvoiceStatus.VALIDATED ||
    invoice?.status === EInvoiceStatus.ARCHIVED;
  if (
    isValidStatus &&
    invoice?.sage &&
    invoice?.sage?.documentType !== EInvoiceDocumentType.INVOICE
  ) {
    return true;
  }
};
