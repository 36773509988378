import { getHumanDate, useUserFindMany } from '@commons';
import { ALink } from 'components/ALink';
import { Header } from 'components/Header';
import { showAddManagerModal } from 'components/modals/support/AddManagerModal';
import {
  Box,
  Button,
  Container,
  Flex,
  Input,
  Link,
  SpinnerBox,
  Table,
  TableColumn,
  Text,
} from 'components/ui';
import { EyeIcon, RefreshIcon } from 'components/ui/icons';
import { useBreadCrumb } from 'hooks/breadCrumb';
import { useDebounce } from 'hooks/useDebounce';
import React, { useEffect } from 'react';
import { ERoles, TRANSLATE_ROLES } from '@commons';
import { useManagerListStore } from 'store';
import { Theme } from 'styles';
export type Props = {};

// map user in the table
const columns: TableColumn[] = [
  {
    key: 'lastName',
    sortKey: 'lastName',
    text: 'NOM',
    render: item => item.lastName + ' ' + item.firstName,
  },
  { key: 'email', text: 'EMAIL', sortKey: 'email' },
  {
    key: 'lastConnexion',
    text: 'Dernière connexion',
    render: item => getHumanDate(item?.lastConnections?.pop()?.seenAt),
  },
  {
    key: 'roles',
    text: 'Rôles',
    render: item =>
      item?.roles
        ?.filter?.((role: any) => {
          if (
            role?.name === 'SUPPORT' ||
            role?.name === 'TEAM_LEADER' ||
            role?.name === ERoles.ACCOUNT_MANAGER ||
            role?.name === ERoles?.LEGAL_SQUAD ||
            role?.name === ERoles?.ADMIN ||
            role?.name === 'MANAGER_READ_ONLY' ||
            role?.name === 'PORTAL_CLIENT_ADMIN' ||
            role?.name === 'MANAGER_RIB'
          ) {
            return true;
          }
          return false;
        })
        //@ts-ignore
        ?.map((e: any) => TRANSLATE_ROLES[e?.name])
        .join(', '),
  },
  {
    key: 'actions',
    text: 'ACTIONS',
    align: 'flex-end',
    render: item => (
      <Link iconLeft={<EyeIcon fill={Theme?.colors?.primary?.default} />}>
        <ALink href={`/admin/users/${item.cognitoUserId}`}>Voir la fiche</ALink>
      </Link>
    ),
  },
];
const LIMIT = 10;
export const ManagerList: React.FC<React.PropsWithChildren<Props>> = () => {
  const { setCrumbs } = useBreadCrumb();
  useEffect(() => {
    setCrumbs([
      {
        label: 'Admin - Utilisateurs',
        path: '/admin/users',
      },
    ]);
  }, []);
  const {
    initialSate,
    order,
    sortedBy,
    searchQuery,
    page,
    updateManagerListStore,
    resetManagerListStore,
  } = useManagerListStore();
  const sortString = (order === 'desc' ? '-' : '') + sortedBy;
  const debouncedFilterQuery = useDebounce(searchQuery, 500);

  const {
    data: contactsQuery,
    status,
    isFetching,
  } = useUserFindMany({
    filterObject: {
      $or: [
        { firstName: { $regex: debouncedFilterQuery, $options: 'i' } },
        { lastName: { $regex: debouncedFilterQuery, $options: 'i' } },
        {
          email: {
            $regex: debouncedFilterQuery?.replace('+', '\\+').trim(),
            $options: 'i',
          },
        },
      ],
      'roles.name': {
        $in: [
          ERoles.ACCOUNT_MANAGER,
          ERoles.LEGAL_SQUAD,
          'SUPPORT',
          'TEAM_LEADER',
          ERoles.ADMIN,
          'MANAGER_READ_ONLY',
          'PORTAL_CLIENT_ADMIN',
          'MANAGER_RIB',
        ],
      },
    },
    sort: sortString,
    limit: LIMIT,
    skip: page * LIMIT,
  });
  const totalCount = contactsQuery?.totalCount;
  const loading = status === 'pending';

  const onSortedChange = async (
    sortedBy: string,
    order: 'desc' | 'asc'
  ): Promise<void> => {
    //@ts-ignore
    updateManagerListStore({ sortedBy, order });
  };

  const onAddContact = () =>
    showAddManagerModal({ contactRole: 'ACCOUNT_MANAGER' });

  // we need to give items a key
  const items =
    contactsQuery?.users.map((u: any) => ({
      key: u.cognitoUserId || '',
      ...u,
    })) || [];

  return (
    <Box>
      <Header
        actions={<Button onClick={onAddContact}>Ajouter un utilisateur</Button>}
      >
        <Text variant="h1">Équipe Connecteed</Text>
      </Header>

      <Container>
        <Flex m="10px 0">
          <Box m="10px 0" width={1 / 3}>
            <Input
              isFullWidth
              type="search"
              name="search"
              placeholder="Rechercher un nom, email ..."
              onChange={(e: any) => {
                updateManagerListStore({
                  searchQuery: e?.target?.value,
                });
              }}
              value={searchQuery}
            />
          </Box>
          <Box ml={10}>
            <Flex
              justifyContent={'center'}
              alignItems={'center'}
              flexWrap="wrap"
              alignContent="center"
              width={1 / 1}
              height={'100%'}
            >
              <Link
                isDisabled={initialSate}
                iconLeft={
                  <RefreshIcon
                    fill={
                      initialSate ? Theme?.colors?.grey?.default : undefined
                    }
                  />
                }
                mt={'5px'}
                onClick={() =>
                  !initialSate ? resetManagerListStore({}) : false
                }
              >
                Réinitialiser
              </Link>
            </Flex>
          </Box>
        </Flex>
        {loading && <SpinnerBox />}
        {!loading && (
          <Table
            columns={columns}
            items={items}
            sortedBy={sortedBy}
            order={order}
            page={page}
            total={totalCount}
            perPage={totalCount && totalCount < LIMIT ? totalCount : LIMIT}
            onSortedChange={onSortedChange}
            onChangePage={page => updateManagerListStore({ page })}
            isLoading={isFetching}
          />
        )}
      </Container>
    </Box>
  );
};

export default ManagerList;
