import { TopBar } from 'components/TopBar';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { NotificationsList } from './List';

export const Notifications = () => {
  return (
    <>
      <TopBar />
      <Switch>
        <Route exact path="/notifications">
          <Redirect to="/notifications/all" />
        </Route>
        <Route path="/notifications/:filter(all|non-read|read|prioritized)">
          <NotificationsList />
        </Route>
      </Switch>
    </>
  );
};
