import { ALink } from 'components/ALink';
import React, { Fragment } from 'react';
import { Flex } from './Flex';
import { Text } from './Text';

type BreadCrumbsProps = {
  crumbs: {
    label: string;
    path: string;
  }[];
};

export const BreadCrumb = ({ crumbs }: BreadCrumbsProps) => {
  return (
    <Flex>
      {crumbs?.map((c, index) => (
        <Fragment key={index}>
          <Text fontSize={11}>
            <ALink color="primary.light" href={c.path}>
              {c.label}
            </ALink>
          </Text>
          {index !== crumbs?.length - 1 && (
            <Text fontSize={11} color="primary.light" m="0 5px">
              {'>'}
            </Text>
          )}
        </Fragment>
      ))}
    </Flex>
  );
};
