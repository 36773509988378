import { create } from 'zustand';
import { countDiffStates, DEFAULT_MISSION_DISPLAYCOL } from '@commons';
import { FlatUser } from '@freelancelabs/teoreme-commons';

enum filterType {
  DRAFT = 'draft',
  VALIDATED = 'validated',
  ARCHIVED = 'archived',
  ABORTED = 'aborted',
  TERMINATED = 'terminated',
}
enum sortType {
  ASC = 'asc',
  DESC = 'desc',
}
type MultiValueType = {
  label: string;
  value: string;
};

type State = {
  initialSate: boolean;
  filter: filterType;
  order?: sortType;
  sortedBy: string;
  searchQuery: string;
  startDate: Date | null;
  endDate: Date | null;
  managerSelected?: string;
  managerSelectedCompleteObject?: FlatUser;
  commercialSelected?: string;
  statusSelect?: string;
  statusBDCCustomerSelect?: string;
  typeSelected?: string;
  structureSelected?: string;
  clientSelected?: string;
  contactClientSelected?: string;
  providerSelected?: string;
  multiProviderSelected?: MultiValueType[];
  multiClientSelected?: MultiValueType[];
  contractorSelected?: string;
  billingTypeSelect?: string;
  missionType?: string;
  isMandateMode?: 1 | 0 | undefined;
  isDirectPayment?: 1 | 0 | undefined;
  minCustomerBudget?: number;
  maxCustomerBudget?: number;
  allMissionsSelected: boolean;
  selectedMissionKeys: string[];
  unSelectedMissionKeys: string[];
  displayCol: MultiValueType[];
  page: number;
  isOpen?: boolean;
  limit: number;
  updateListMissionStore: (state: Partial<State>) => void;
  resetListMissionStore: (state: Partial<State>) => void;
  getNbActiveFilters: (data: any) => number;
};
const initialStates = {
  initialSate: true,
  filter: filterType?.VALIDATED,
  order: sortType?.DESC,
  sortedBy: 'validatedAt',
  searchQuery: '',
  startDate: null,
  endDate: null,
  managerSelected: localStorage?.cognitoUserId,
  managerSelectedCompleteObject: undefined,
  commercialSelected: undefined,
  statusSelect: 'N/A',
  statusBDCCustomerSelect: 'N/A',
  typeSelected: 'N/A',
  structureSelected: undefined,
  clientSelected: undefined,
  contactClientSelected: undefined,
  providerSelected: undefined,
  multiProviderSelected: [],
  multiClientSelected: undefined,
  contractorSelected: undefined,
  billingTypeSelect: 'N/A',
  isMandateMode: undefined,
  isDirectPayment: undefined,
  minCustomerBudget: undefined,
  maxCustomerBudget: undefined,
  missionType: 'N/A',
  allMissionsSelected: false,
  selectedMissionKeys: [],
  unSelectedMissionKeys: [],
  displayCol: DEFAULT_MISSION_DISPLAYCOL,
  page: 0,
  isOpen: undefined,
  limit: 20,
};

const mustResetSelectedMissions = (state: any) => {
  if (Object.keys(state).length === 1 && state.page !== undefined) return false;
  return true;
};
const store = (set: any, get: any) => ({
  ...initialStates,
  updateListMissionStore: (state: Partial<State>) => {
    if (mustResetSelectedMissions(state)) {
      set({
        initialSate: false,
        allMissionsSelected: false,
        selectedMissionKeys: [],
        unSelectedMissionKeys: [],
        ...state,
      });
    } else {
      set({
        initialSate: false,
        page: 0,
        ...state,
      });
    }
  },
  resetListMissionStore: (state: Partial<State>) =>
    set({
      ...initialStates,
      ...state,
    }),
  getNbActiveFilters: (additionalFiltersVars: string[]) => {
    const currentStates = get();
    return countDiffStates(initialStates, currentStates, additionalFiltersVars);
  },
});

export const useListMissionStore = create<State>(store);
