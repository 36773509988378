import { TopBar } from 'components/TopBar';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ManagerList, UserDetails } from './Users';
import { TasksRoutes } from './Tasks';
import { SettingsRoutes } from './Settings';
export const AdminRoute = () => {
  return (
    <>
      <TopBar />
      <Switch>
        <Route exact path="/admin">
          <Redirect to="/admin/users" />
        </Route>
        <Route exact path="/admin/users">
          <ManagerList />
        </Route>
        <Route path="/admin/users/:uuid">
          <UserDetails />
        </Route>
        <Route path="/admin/tasks">
          <TasksRoutes />
        </Route>
        <Route path="/admin/settings">
          <SettingsRoutes />
        </Route>
      </Switch>
    </>
  );
};

export default AdminRoute;
