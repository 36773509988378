import React from 'react';
import { EMarginType } from '@freelancelabs/teoreme-commons';
import { Box, Text } from 'components/ui';
import { useSetCrumbs } from 'hooks/breadCrumb';
import { useParams } from 'react-router-dom';
import {
  useEstablishmentFindOne,
  getTradeNameSafe,
  useEstablishmentUpdateOne,
  queryClient,
} from '@commons';
import { ConfigureExpensesForm } from 'forms/ConfigureExpensesForm';
import { useShowMessage } from 'hooks/useShowMessage';

export const Expenses = () => {
  const { uuid } = useParams<{ uuid: string }>();
  const { data: establishment } = useEstablishmentFindOne(uuid);
  const { mutateAsync: updateEstablishment } = useEstablishmentUpdateOne();
  const showMessage = useShowMessage();
  useSetCrumbs(
    [establishment],
    [
      {
        label: 'clients',
        path: '/clients',
      },
      {
        label: 'établissements',
        path: `/clients/establishments`,
      },
      {
        label: establishment ? getTradeNameSafe(establishment) : '-',
        path: `/clients/establishments/${establishment?.uuid}`,
      },
      {
        label: 'Gestion des frais',
        path: `/clients/establishments/${establishment?.uuid}/fee`,
      },
    ]
  );
  const expensesConfig = establishment?.customer?.expensesConfig;
  const handleSubmit = async (formValues: {
    marginType: EMarginType;
    isEnabled: boolean;
    margin: number;
    shouldJoinAttachment: boolean;
  }) => {
    try {
      await updateEstablishment({
        uuid: establishment?.uuid as string,
        establishment: {
          customer: {
            expensesConfig: {
              marginType: formValues.marginType,
              isEnabled: formValues?.isEnabled,
              margin: Number(formValues?.margin),
              shouldJoinAttachment: formValues?.shouldJoinAttachment,
            },
          },
        },
      });
      queryClient?.refetchQueries({
        queryKey: [uuid],
        type: 'active',
      });
      showMessage('success', "L'établissement à été mis à jour");
    } catch (e) {
      //
    }
  };
  return (
    <Box p={20}>
      <Text variant="h2" mb={20}>
        Frais
      </Text>
      <Box>
        <ConfigureExpensesForm
          from="ESTABLISHMENT"
          expensesConfig={expensesConfig}
          onSubmit={handleSubmit}
        />
      </Box>
    </Box>
  );
};
