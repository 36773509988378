import React from 'react';
import {
  ICompanyGetResponse,
  IEstablishment,
  IJoinedMission,
} from '@freelancelabs/teoreme-commons';
import { Box, Text } from 'components/ui';
import { create } from 'react-modal-promise';
import { ModalFrame, ModalProps } from '../ModalFrame';
import { CreateOrUpdateStandByDutyForm } from 'forms/CreateOrUpdateStandByDutyForm';
type Props = ModalProps & {
  beforeValidation?: () => void;
  standByDuty?: any;
  establishment?: ICompanyGetResponse | IEstablishment;
  mission?: IJoinedMission;
  from: 'ESTABLISHMENT' | 'MISSION';
  layer?: number;
};

export const CreateOrUpdateStandByDutyModal = ({
  onResolve,
  isOpen,
  standByDuty,
  establishment,
  mission,
  from = 'ESTABLISHMENT',
  layer,
}: Props) => {
  return (
    <ModalFrame
      isOpen={isOpen}
      onClose={() => onResolve(false)}
      width={1350}
      layer={layer}
      closeIcon
    >
      <Box mt={20}>
        <Text variant="h2" mb={20}>
          {standByDuty ? 'Modifier' : 'Ajouter'} une prestation complémentaire
        </Text>
        <CreateOrUpdateStandByDutyForm
          standByDuty={standByDuty}
          establishment={establishment}
          mission={mission}
          onResolve={onResolve}
          from={from}
        />
      </Box>
    </ModalFrame>
  );
};

export const showCreateOrUpdateStandByDutyModal = create<Props, boolean>(
  CreateOrUpdateStandByDutyModal
);
