import { useState } from 'react';
import {
  checkAvalaibleCreateAdditionalActivity,
  getFullName,
  getTradeNameSafe,
  queryClient,
  useAdditionalActivityUpdateOne,
  MAX_CUSTOMER_REFERENCE_FIELD_LENGTH,
  transformText,
  getCustomerRefPattern,
  displayMonth,
  getAdditionActivityTextType,
} from '@commons';
import {
  IEstablishment,
  IJoinedAdditionalActivity,
  EadditionalActivityStatus,
  EadditionalActivityType,
  ECustomerReferencePatternVars,
  EBillingType,
} from '@freelancelabs/teoreme-commons';
import { ModalFrame, ModalProps } from 'components/modals/ModalFrame';
import {
  ALink,
  Link,
  Text,
  Flex,
  Button,
  Box,
  FormControl,
  Input,
} from 'components/ui';
import { create } from 'react-modal-promise';
import { useForm } from 'react-hook-form';
import { useShowMessage } from 'hooks/useShowMessage';
type Props = ModalProps & {
  beforeValidation?: () => void;
  layer?: number;
  additionalActivity: IJoinedAdditionalActivity & {
    addAttachments?: any;
    removeAttachments?: any;
  };
};
type FormValues = any;
export const ValidateAdditionalActivityModal = ({
  onResolve,
  isOpen,
  layer,
  additionalActivity,
}: Props) => {
  const avalaibleCreateAA = checkAvalaibleCreateAdditionalActivity(
    additionalActivity as IJoinedAdditionalActivity
  );
  const showMessage = useShowMessage();
  const [isLoading, setIsLoading] = useState(false);
  const additionalActivityCustomerRef =
    additionalActivity?.purchaseOrder?.reference;
  const customerReference =
    additionalActivity?.mission?.customer?.purchaseOrder?.reference;
  const customerRefPattern =
    additionalActivity?.type !== EadditionalActivityType.MILESTONE
      ? //@ts-ignore
        additionalActivity?.mission?.customer?.billingOptions
          ?.customerRefPattern ||
        //@ts-ignore
        additionalActivity?.estCustomer?.customer?.billingOptions
          ?.customerRefPattern
      : customerReference;
  const craDateMonth = new Date(additionalActivity?.month as Date);
  const customerRef = getCustomerRefPattern(customerRefPattern, {
    [ECustomerReferencePatternVars.CRA_FULL_YEAR]: craDateMonth
      ?.getUTCFullYear()
      ?.toString(),
    [ECustomerReferencePatternVars.CRA_YEAR]: craDateMonth
      ?.getUTCFullYear()
      ?.toString()
      ?.substring(2),

    [ECustomerReferencePatternVars.CRA_MONTH]:
      craDateMonth?.getUTCMonth() + 1 < 10
        ? `0${craDateMonth?.getUTCMonth() + 1}`
        : `${craDateMonth?.getUTCMonth() + 1}`,
    [ECustomerReferencePatternVars.MISSION_REF]:
      //@ts-ignore
      additionalActivity?.mission?.displayReference,
    [ECustomerReferencePatternVars.CUSTOMER_REF]: customerReference,
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {
      customerReference:
        additionalActivityCustomerRef ?? (customerRef || customerReference),
    },
  });

  const textType = getAdditionActivityTextType(additionalActivity);
  const { mutateAsync: updateAdditionalActivity } =
    useAdditionalActivityUpdateOne();
  const onSubmit = async (formValues: FormValues) => {
    setIsLoading(true);
    try {
      await updateAdditionalActivity({
        uuid: additionalActivity.uuid,
        status: EadditionalActivityStatus.VALIDATED,
        customerReference: formValues?.customerReference,
        milestoneAmount: additionalActivity?.milestoneAmount,
        // attachments: additionalActivity?.attachments,
        addAttachments: additionalActivity?.addAttachments,
        removeAttachments: additionalActivity?.removeAttachments,
      });
      showMessage(
        'success',
        `${transformText(textType, 'capitalize-first')} a bien été validé`
      );
      queryClient?.invalidateQueries({ queryKey: [additionalActivity.uuid] });
      queryClient.refetchQueries({
        queryKey: ['additionalActivities'],
        type: 'active',
      });
      queryClient?.refetchQueries({
        queryKey: ['ActivitiesReport'],
        type: 'active',
      });
      onResolve(true);
    } catch (e) {
      //
    }
    setIsLoading(false);
  };
  return (
    <ModalFrame
      isOpen={isOpen}
      onClose={() => onResolve(false)}
      width={700}
      closeIcon
      layer={layer}
    >
      <Box hidden={!avalaibleCreateAA?.ready}>
        <Text variant="h1" mb={20}>
          {additionalActivity?.mission?.billing?.type ===
          EBillingType?.FLAT_RATE
            ? `Référence client`
            : `Référence client du mois (
          ${displayMonth(additionalActivity?.month as Date)})`}
        </Text>
        <Text variant="p" mb={20}>
          La référence client ci-dessous sera indiqué sur la facture client
          générée.
        </Text>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormControl
            required
            label="Référence client"
            errorMessage={
              errors?.customerReference?.type === 'maxLength'
                ? `Veuillez saisir moins de ${MAX_CUSTOMER_REFERENCE_FIELD_LENGTH} caractères`
                : errors?.customerReference?.message
            }
          >
            <Input
              isFullWidth
              type="text"
              {...register('customerReference', {
                required: 'Ce champs est requis',
                maxLength: MAX_CUSTOMER_REFERENCE_FIELD_LENGTH,
              })}
            />
          </FormControl>

          <Flex alignItems="center">
            <Button type="submit" isLoading={isLoading}>
              Valider {textType}
            </Button>
          </Flex>
        </form>
      </Box>
      <Box
        hidden={avalaibleCreateAA?.ready}
        backgroundColor="white"
        height={'calc(100vh - 80px)'}
        padding={10}
      >
        <Text mb={20} textAlign={'center'} variant="h1">
          Impossible de valider {textType}
        </Text>
        <Text>
          {transformText(textType, 'capitalize-first')} ne peut être validé car
          certains liens avec la Gescom ne sont pas créés pour cette mission.
          Veuillez mettre à jour les liens Gescom suivants avant de valider
          cette {textType}.
          <Box mt={20} ml={20}>
            <ul>
              {!avalaibleCreateAA?.contractorReady && (
                <li>
                  <Flex>
                    L'intervenant &nbsp;
                    <ALink
                      ml={'5px'}
                      href={`/providers/contractors/${additionalActivity?.contractor?.uuid}`}
                      target="_blank"
                    >
                      <Link>{getFullName(additionalActivity?.contractor)}</Link>
                    </ALink>
                  </Flex>
                </li>
              )}
              {!avalaibleCreateAA?.estProviderReady && (
                <li>
                  <Flex>
                    L'établissement fournisseur &nbsp;
                    <ALink
                      ml={'5px'}
                      href={`/providers/establishments/${additionalActivity?.estProvider?.uuid}/information`}
                      target="_blank"
                    >
                      <Link>
                        {getTradeNameSafe(
                          additionalActivity?.estProvider as IEstablishment
                        )}
                      </Link>
                    </ALink>
                  </Flex>
                </li>
              )}
              {!avalaibleCreateAA?.estCustomerReady && (
                <li>
                  <Flex>
                    L'établissement client &nbsp;
                    <ALink
                      ml={'5px'}
                      href={`/clients/establishments/${additionalActivity?.estCustomer?.uuid}/information`}
                      target="_blank"
                    >
                      <Link>
                        {getTradeNameSafe(
                          additionalActivity?.estCustomer as IEstablishment
                        )}
                      </Link>
                    </ALink>
                  </Flex>
                </li>
              )}
            </ul>
          </Box>
        </Text>
      </Box>
    </ModalFrame>
  );
};

export const showValidateAdditionalActivityModal = create<Props>(
  ValidateAdditionalActivityModal
);
