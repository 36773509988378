import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { DPAEList } from './List';

export const DPAE = () => {
  return (
    <Switch>
      <Route exact path="/providers/dpae">
        <Redirect to="/providers/dpae/active" />
      </Route>
      <Route path="/providers/dpae/:filter(active|pending|to-be-validated|archived)">
        <DPAEList />
      </Route>
    </Switch>
  );
};
