import { Button, Flex, FormControl, Text, Input, Box } from 'components/ui';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { IJoinedAdditionalActivity } from '@freelancelabs/teoreme-commons';

type FormValues = {
  additionalActivity?: IJoinedAdditionalActivity;
};

type InvoiceContactFormProps = {
  defaultValues?: FormValues;
  onSubmit?: any; // TODO MAKE TYPES
  customTextButton?: string;
  customTitle?: string;
  customDefaultOption?: any;
  formSize?: any;
  alignButton?: any;
  cancelButton?: any;
};

export const RejectAdditionalActivityForm = ({
  defaultValues,
  onSubmit: onSubmitParent,
  customTextButton,
  customTitle,
  formSize,
  alignButton,
  cancelButton,
}: InvoiceContactFormProps) => {
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm<FormValues>({ defaultValues });

  const onSubmit = async (formValues: FormValues) => {
    setLoading(true);
    const values: FormValues = {
      ...defaultValues,
      ...formValues,
    };
    await onSubmitParent?.(values);
    setLoading(false);
  };

  return (
    <Box width={1 / 1}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box width={formSize ? formSize : 1 / 1}>
          <Text variant="h2" mb={20}>
            {customTitle ? customTitle : 'Refuser'}
          </Text>
          {
            <FormControl
              required
              label="Préciser le motif de refus"
              errorMessage={errors?.additionalActivity?.rejectReason?.message}
            >
              <Input
                isFullWidth
                type="text"
                {...register('additionalActivity.rejectReason', {
                  required: 'Ce champ est requis',
                })}
              />
            </FormControl>
          }
        </Box>
        <Flex justifyContent={alignButton ? alignButton : 'flex-start'}>
          {cancelButton && cancelButton}
          <Button type="submit" isLoading={loading} isDisabled={!isDirty}>
            {customTextButton ? customTextButton : 'Refuser'}
          </Button>
        </Flex>
      </form>
    </Box>
  );
};
