import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ContactProviderDetails } from './Details';
import { ContactsList } from './List';
import { OnBoardingList } from './OnBoarding/OnBoardingList';
import { SendEmail } from './SendEmail';
import { ContactProviderOnBoardingDetails } from './OnBoarding/Details';
export const Contacts = () => {
  return (
    <Switch>
      <Route exact path="/providers/contacts">
        <Redirect to="/providers/contacts/sent-invitations" />
      </Route>
      <Route path="/providers/contacts/:filter(all|sent-invitations|on-boarding|active|awaiting)">
        <ContactsList />
      </Route>
      <Route path="/providers/contacts/send-email">
        <SendEmail />
      </Route>
      <Route path="/providers/contacts/on-boarding-card/:id">
        <ContactProviderOnBoardingDetails />
      </Route>
      <Route path="/providers/contacts/on-boarding">
        <OnBoardingList />
      </Route>
      <Route path="/providers/contacts/:id">
        <ContactProviderDetails />
      </Route>
    </Switch>
  );
};
