import {
  IDpae,
  IEstablishment,
  IJoinedContractor,
} from '@freelancelabs/teoreme-commons';
import { dpaeDeleteOne } from '@commons';
import { FileCard } from 'components/cards/FileCard';
import { showDialogModal } from 'components/modals/DialogModal';
import { DeleteIcon, EditIcon } from 'components/ui/icons';
import { MenuItem, Link, FileInput, Row, Status } from 'components/ui';
import { queryClient } from '@commons';

type DpaeCardProps = {
  establishment: IEstablishment;
  dpae: IDpae;
  contractor: IJoinedContractor;
  onNewDpae?: (files: File[]) => void;
  isDisabled?: boolean;
};
export const DpaeCard = ({
  contractor,
  establishment,
  dpae,
  onNewDpae,
  isDisabled,
}: DpaeCardProps) => {
  const fileName = `DPAE - ${contractor?.firstName} ${contractor?.lastName} - ${establishment.businessName}.pdf`;

  const onDeleteDpae = () => {
    showDialogModal({
      title:
        'Êtes-vous sûr(e) de vouloir supprimer la DPAE de cet intervenant?',
      text: 'Attention ce document sera définitivement supprimé de Connecteed.',
      confirmLabel: 'Confirmer',
      cancelLabel: 'Annuler',

      beforeValidation: async () => {
        if (!dpae.uuid) return;
        await dpaeDeleteOne(dpae.uuid);
        queryClient.refetchQueries({ queryKey: [contractor?.uuid] });
      },
    });
  };

  return (
    <FileCard
      fileLocation={dpae.attachment?.fileLocation || ''}
      fileName={fileName}
      complement={
        <Row spacing={20}>
          <Status
            variantColor={dpae.status === 'ACTIVE' ? 'success' : 'warning'}
          >
            {dpae.status === 'ACTIVE' ? 'Active' : 'En attente'}
          </Status>
          <></>
        </Row>
      }
      actions={
        !isDisabled && (
          <>
            <MenuItem>
              <FileInput variant="link" accept=".pdf" onSelect={onNewDpae}>
                <Link iconLeft={<EditIcon />}>Modifier</Link>
              </FileInput>
            </MenuItem>
            <MenuItem onClick={onDeleteDpae}>
              <Link iconLeft={<DeleteIcon />}>Supprimer</Link>
            </MenuItem>
          </>
        )
      }
    />
  );
};
