import * as React from 'react';

function SvgRefreshIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 25 24" {...props}>
      <path
        fill={props?.fill}
        fillRule="evenodd"
        d="M18.24 4.008a.851.851 0 00-.736.843v1.494l-.187-.147a7.677 7.677 0 00-10.202.827 7.683 7.683 0 00.271 10.86 7.676 7.676 0 0010.856-.273 7.683 7.683 0 002.007-6.573.85.85 0 00-1.691.155l.013.13a6.057 6.057 0 11-11.943 2.027 6.06 6.06 0 014.957-6.987l.276-.041a6.056 6.056 0 014.81 1.529l.09.085-2.126.71a.851.851 0 00-.574.927l.028.126c.137.416.53.655.934.598l.126-.029 3.475-1.157a.851.851 0 00.582-.808V4.85a.851.851 0 00-.851-.85l-.116.008z"
      />
    </svg>
  );
}

export default SvgRefreshIcon;
