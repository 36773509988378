import { Button, Flex, Text, Box, TextEditorControlled } from 'components/ui';
import { useState } from 'react';
import { create } from 'react-modal-promise';
import { ModalFrame, ModalProps } from './ModalFrame';
import { useForm } from 'react-hook-form';
type Props = ModalProps & {
  customMessageForProvider?: string;
  beforeValidation: (data?: string) => void;
};
type FormValues = {
  customMessageForProvider?: string;
};

export const AddProviderCustomMessageModal = ({
  onResolve,
  isOpen,
  customMessageForProvider,
  beforeValidation,
}: Props) => {
  const { handleSubmit, control } = useForm<FormValues>({
    defaultValues: {
      customMessageForProvider: customMessageForProvider,
    },
  });
  const [isLoading, setIsLoading] = useState(false);
  const onSubmit = async (data: any) => {
    setIsLoading(true);
    try {
      if (beforeValidation) {
        await beforeValidation(data.customMessageForProvider);
      }
      onResolve(true);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    }
  };
  return (
    <ModalFrame
      isOpen={isOpen}
      onClose={() => onResolve(false)}
      width={900}
      closeIcon
    >
      <Box>
        <Text variant="h1">
          Message à afficher au partenaire lors du dépôt de son CRA
        </Text>
        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          <Box mt={20}>
            <TextEditorControlled
              options={[
                'fontSize',
                'blockType',
                'inline',
                'list',
                'textAlign',
                'colorPicker',
              ]}
              control={control}
              name="customMessageForProvider"
            />
          </Box>
          <Flex mt={20}>
            <Button isLoading={isLoading} type="submit">
              Enregistrer
            </Button>
            <Button
              onClick={() => onResolve()}
              ml={'5px'}
              variant="ghost"
              type="button"
            >
              Annuler
            </Button>
          </Flex>
        </form>
      </Box>
    </ModalFrame>
  );
};

export const showAddProviderCustomMessageModal = create<Props>(
  AddProviderCustomMessageModal
);
