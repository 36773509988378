import { TopBar } from 'components/TopBar';
import { Redirect, Route, Switch } from 'react-router-dom';
import { MissionsDetails } from './Details';
import { MissionsList } from './List';

export const Missions = () => {
  return (
    <>
      <TopBar />
      <Switch>
        <Route exact path="/delivery/missions">
          <Redirect to="/delivery/missions/validated" />
        </Route>
        <Route
          exact
          path="/delivery/missions/:filter(all|validated|aborted|draft|terminated)"
        >
          <MissionsList />
        </Route>

        <Route path="/delivery/missions/:reference">
          <MissionsDetails />
        </Route>
      </Switch>
    </>
  );
};
