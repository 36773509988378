import {
  getEstablishmentName,
  getFullName,
  getHumanDate,
  queryClient,
  useAdditionalActivityUpdateOne,
  useCreateAditionalActivity,
  useAdditionalActivityFindOne,
  round,
  getAdditionActivityTextType,
  transformText,
  lenientLte,
  BUDGET_COMPARISON_TOLERANCE,
} from '@commons';
import {
  EadditionalActivityStatus,
  EadditionalActivityType,
  EChangeOwnerType,
  IJoinedAdditionalActivity,
  IJoinedMission,
} from '@freelancelabs/teoreme-commons';
import { ConfirmNavigationModal } from 'components/modals/ConfirmNavigationModal';
import {
  Box,
  Button,
  FileListControlled,
  Flex,
  FormControl,
  LabelField,
  NumberFormInputControlled,
  Tab,
  Text,
} from 'components/ui';
import { HistoryList } from 'components/lists/HistoryList';
import { columns } from '../HistoryListData';
import { useShowMessage } from 'hooks/useShowMessage';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Theme } from 'styles';
import { showRejectAdditionalActivityModal } from 'components/modals/RejectAdditionalActivityModal';
import TextInformation from 'components/TextInformation';
import { showValidateAdditionalActivityModal } from 'components/modals/activities/ValidateAdditionalActivityModal';

type FormValues = any;
type MilestonesDetailsFormProps = {
  mission: IJoinedMission;
  mileStone?: IJoinedAdditionalActivity;
  method: 'CREATE' | 'UPDATE';
  infoHidden?: boolean;
  isDisabled?: boolean;
  onResolve?: (data: any) => any | void;
  onClose?: (value?: any) => void;
};

export const MilestonesDetailsForm = ({
  method: methodProps,
  mission,
  mileStone: mileStoneProps,
  infoHidden,
  isDisabled,
  onResolve,
  onClose,
}: MilestonesDetailsFormProps) => {
  const [uuid, setUuid] = useState(mileStoneProps?.uuid);
  const { data: mileStone } = useAdditionalActivityFindOne(uuid);
  const [method, setMethod] = useState(methodProps);
  const [loading, setLoading] = useState(false);
  const [loadingLater, setLoadingLater] = useState(false);
  const [colorStepper, setColorStepper] = useState(Theme?.colors?.blue);
  const showMessage = useShowMessage();
  const [formSelected, setFormSelected] = useState<'INFORMATIONS' | 'HISTORY'>(
    'INFORMATIONS'
  );
  const {
    handleSubmit,
    control,
    formState: { errors, isDirty, isSubmitSuccessful },
    watch,
    setValue,
    getValues,
  } = useForm<FormValues>({
    mode: 'onChange',
    defaultValues: {
      milestoneAmount: mileStone?.milestoneAmount,
    },
  });
  const { mutateAsync: createAdditionalActivity } =
    useCreateAditionalActivity();
  const { mutateAsync: updateAdditionalActivity } =
    useAdditionalActivityUpdateOne();
  const onSaveLater = async () => {
    const formValues = getValues();
    setLoadingLater(true);
    try {
      await updateAdditionalActivity({
        uuid: mileStone?.uuid,
        status:
          mileStone?.status === EadditionalActivityStatus?.REJECTED
            ? EadditionalActivityStatus.TO_BE_VALIDATED
            : undefined,
        milestoneAmount: formValues?.milestoneAmount,
        attachments: formValues?.attachments,
      });
      showMessage('success', 'Le jalon à été mis à jour');
      queryClient?.refetchQueries({
        queryKey: [mileStone?.uuid],
        type: 'active',
      });
      queryClient?.refetchQueries({
        queryKey: ['additionalActivities'],
        type: 'active',
      });
      queryClient?.refetchQueries({
        queryKey: [mission?.reference],
        type: 'active',
      });
      setLoadingLater(false);
    } catch (e) {
      setLoadingLater(false);
    }
  };
  const validateAdditionalActivity = async (additionalActivity: any) => {
    try {
      setLoading(true);
      const textType = getAdditionActivityTextType(additionalActivity);
      await updateAdditionalActivity({
        uuid: additionalActivity.uuid,
        status: EadditionalActivityStatus.VALIDATED,
        milestoneAmount: additionalActivity?.milestoneAmount,
        addAttachments: additionalActivity?.addAttachments,
        removeAttachments: additionalActivity?.removeAttachments,
      });
      showMessage(
        'success',
        `${transformText(textType, 'capitalize-first')} a bien été validé`
      );
      queryClient?.invalidateQueries({ queryKey: [additionalActivity.uuid] });
      queryClient.refetchQueries({
        queryKey: ['additionalActivities'],
        type: 'active',
      });
      queryClient?.refetchQueries({
        queryKey: ['ActivitiesReport'],
        type: 'active',
      });
      queryClient?.refetchQueries({
        queryKey: [mission?.reference],
        type: 'active',
      });
    } catch (e) {
      //
      setLoading(false);
    }
    setLoading(false);
  };
  const existingAttachments = mileStone?.attachments;
  const onSubmit = async (formValues: FormValues) => {
    setLoading(true);
    const attachments = formValues?.attachments;
    const addAttachments: any[] = [];
    const removeAttachments: any[] = [];
    const existingAttachmentsFileNames =
      existingAttachments?.map((f: any) => f.fileName) || [];

    const currentAttachmentsFileNames =
      attachments?.map((file: any) => file.fileName) || [];

    attachments?.forEach((sd: any) => {
      if (!existingAttachmentsFileNames?.includes(sd?.fileName)) {
        addAttachments?.push(sd);
      }
    });
    existingAttachments?.forEach((sd: any) => {
      if (!currentAttachmentsFileNames?.includes(sd?.fileName)) {
        removeAttachments?.push(sd);
      }
    });
    if (method === 'UPDATE') {
      try {
        // await updateAdditionalActivity({
        //   uuid: mileStone?.uuid,
        //   status: EadditionalActivityStatus.VALIDATED,
        //   milestoshowValidateAdditionalActivityModalneAmount: formValues?.milestoneAmount,
        //   attachments: formValues?.attachments,
        // });
        const additionalActivity = {
          ...mileStone,
          ...formValues,
          attachments: undefined,
          addAttachments,
          removeAttachments,
        };
        if (mission?.isMandateMode) {
          await validateAdditionalActivity(additionalActivity);
        } else {
          await showValidateAdditionalActivityModal({
            additionalActivity,
          })?.then(action => {
            if (action) {
              if (onResolve) {
                onResolve(true);
              }
            }
          });
          //showMessage('success', 'Le jalon à été mis à jour');
          queryClient?.refetchQueries({
            queryKey: [mileStone?.uuid],
            type: 'active',
          });
          queryClient?.refetchQueries({
            queryKey: ['additionalActivities'],
            type: 'active',
          });
          queryClient?.refetchQueries({
            queryKey: [mission?.reference],
            type: 'active',
          });
          setLoading(false);
          if (onClose) {
            onClose();
          }
        }
      } catch (e) {
        setLoading(false);
      }
    }
    if (method === 'CREATE') {
      setLoading(true);
      try {
        const mileStoneCreated = await createAdditionalActivity({
          type: EadditionalActivityType.MILESTONE,
          milestoneAmount: formValues?.milestoneAmount,
          attachments: formValues?.attachments,
          missionReference: mission?.reference,
        });
        setUuid(mileStoneCreated?.uuid);
        setMethod('UPDATE');
        showMessage('success', 'Le jalon à été créé');
        queryClient?.refetchQueries({
          queryKey: ['additionalActivities'],
          type: 'active',
        });
        queryClient?.refetchQueries({
          queryKey: [mission?.reference],
          type: 'active',
        });
        setLoading(false);
      } catch (e) {
        setLoading(false);
      }
    }
  };

  const contractorRate = mission?.billing?.contractorRate || 0;
  const milestoneAmount = watch('milestoneAmount');
  const consumedPurchaseBudget = mission?.billing?.consumedPurchaseBudget || 0;
  const getCurrentConsumedBudget = () => {
    if (
      method === 'CREATE' ||
      mileStone?.status === EadditionalActivityStatus.REJECTED
    ) {
      return consumedPurchaseBudget + Number(milestoneAmount || 0);
    }
    // console.log('consumedPurchaseBudget', consumedPurchaseBudget);
    // console.log('mileStone?.milestoneAmount', mileStone?.milestoneAmount);
    // console.log('milestoneAmount', milestoneAmount);
    return (
      consumedPurchaseBudget -
      (mileStone?.milestoneAmount || 0) +
      Number(milestoneAmount)
    );
  };
  const currentConsumedBudget = getCurrentConsumedBudget();
  const stepperPercent = () => {
    const percent = (currentConsumedBudget / (contractorRate || 0)) * 100;
    if (percent > 100) {
      if (colorStepper !== Theme?.colors?.error?.default) {
        setColorStepper(Theme?.colors?.error?.default);
      }

      return '100%';
    }
    if (percent >= 80) {
      if (colorStepper !== Theme?.colors?.warning?.default) {
        setColorStepper(Theme?.colors?.warning?.default);
      }
    } else {
      if (colorStepper !== Theme?.colors?.blue) {
        setColorStepper(Theme?.colors?.blue);
      }
    }

    return `${percent}%`;
  };

  const stepperWidth = stepperPercent();
  const checkIsEditable = () => {
    if (method === 'CREATE') {
      return true;
    }
    if (isDisabled) {
      return false;
    }
    // if (mileStone?.status === EadditionalActivityStatus.REJECTED) {
    //   return false;
    // }
    if (
      //mileStone?.status === EadditionalActivityStatus.TO_BE_VALIDATED ||
      mileStone?.status === EadditionalActivityStatus.VALIDATED ||
      mileStone?.status === EadditionalActivityStatus.ARCHIVED
    ) {
      return false;
    }
    return true;
  };
  const isEditable = checkIsEditable();
  const estCustomer = mileStone?.estCustomer?.uuid
    ? mileStone?.estCustomer
    : mission?.customer?.establishment;
  const estProvider = mileStone?.estProvider?.uuid
    ? mileStone?.estProvider
    : mission?.provider?.establishment;
  const contractor = mileStone?.contractor?.uuid
    ? mileStone?.contractor
    : mission?.provider?.contractor;

  const createdByProvider =
    mileStone?.estProvider?.provider?.contacts?.includes(
      mileStone?.createdBy?.cognitoUserId
    ) ||
    (mileStone?.estProvider?.provider?.manager &&
      mileStone?.estProvider?.provider?.manager ===
        mileStone?.createdBy?.cognitoUserId);
  useEffect(() => {
    if (mileStone && mileStone?.attachments) {
      setValue('attachments', mileStone?.attachments);
    }
  }, [mileStone]);
  return (
    <Box>
      <Flex mb={10}>
        <Tab
          onClick={() => setFormSelected('INFORMATIONS')}
          isSelected={formSelected === 'INFORMATIONS'}
          variant="secondary"
          fontSize={12}
        >
          Informations
        </Tab>
        {method === 'UPDATE' && (
          <Tab
            onClick={() => setFormSelected('HISTORY')}
            isSelected={formSelected === 'HISTORY'}
            variant="secondary"
            fontSize={12}
          >
            Historique
          </Tab>
        )}
      </Flex>
      <Flex>
        {formSelected === 'INFORMATIONS' && (
          <Box width={infoHidden ? 12 / 12 : 8 / 12} pr={10}>
            {mileStone?.rejectReason &&
              mileStone?.status === EadditionalActivityStatus?.REJECTED && (
                <Box>
                  <TextInformation variant="error" width={1 / 1} mb={10}>
                    {`Ce jalon à été refusé le ${new Date(
                      mileStone?.statusChangedAt as Date
                    ).toLocaleDateString()} pour cause de : ${mileStone?.rejectReason}`}
                  </TextInformation>
                </Box>
              )}
            <Box mb={20}>
              <Flex
                width={1 / 1}
                zIndex={100}
                justifyContent="space-around"
                borderBottom={'12px solid #eaeefc'}
                borderRadius={'6px'}
              >
                <Box
                  position="absolute"
                  left={0}
                  bottom={-12}
                  //@ts-ignore
                  width={stepperWidth}
                  borderRadius={'6px'}
                  borderBottom={`12px solid ${colorStepper}`}
                />
              </Flex>
              <Text mt={20} width={1 / 1}>
                {`Le fournisseur à consommé ${round(
                  currentConsumedBudget
                )} € euros sur ${contractorRate} €
                euros prévus sur cette mission.`}
              </Text>
            </Box>
            <form onSubmit={handleSubmit(onSubmit)}>
              <ConfirmNavigationModal active={isDirty && !isSubmitSuccessful} />
              <FormControl
                label="Montant HT à facturer"
                errorMessage={errors?.milestoneAmount?.message}
              >
                <NumberFormInputControlled
                  isDisabled={
                    !isEditable ||
                    mileStone?.status ===
                      EadditionalActivityStatus.TO_BE_VALIDATED
                  }
                  control={control}
                  isFullWidth
                  step="0.01"
                  maxDecimal={2}
                  nb
                  name="milestoneAmount"
                  type="number"
                  rules={{
                    required: 'Ce champ est requis',
                    validate: (value: number) => {
                      let result = 0;
                      let remainingPurchBudget =
                        (mission?.billing?.contractorRate || 0) -
                        (mission.billing.consumedPurchaseBudget || 0);

                      if (
                        method === 'CREATE' ||
                        mileStone?.status === EadditionalActivityStatus.REJECTED
                      ) {
                        result = consumedPurchaseBudget + Number(value);
                      } else {
                        remainingPurchBudget =
                          (mission?.billing?.contractorRate || 0) -
                          (mission.billing.consumedPurchaseBudget || 0) +
                          (mileStone?.milestoneAmount || 0);
                        result =
                          consumedPurchaseBudget -
                          (mileStone?.milestoneAmount || 0) +
                          Number(value);
                      }
                      if (Number(value) <= 0.01) {
                        return 'La valeur doit être supérieur à 0.01';
                      }
                      if (
                        !lenientLte(
                          Number(value),
                          Number(remainingPurchBudget),
                          BUDGET_COMPARISON_TOLERANCE
                        )
                      ) {
                        return `Le montant saisi ${result} € dépasse le budget  ${Number(
                          contractorRate
                        )} €`;
                      }
                      return undefined;
                    },
                  }}
                />
              </FormControl>

              <FormControl
                mb={-20}
                label="Justificatifs"
                errorMessage={errors?.attachments?.message}
              />
              <FileListControlled
                control={control}
                maxFile={1}
                name="attachments"
                missionRef={mission?.reference}
                isDisabled={
                  !isEditable ||
                  mileStone?.status ===
                    EadditionalActivityStatus.TO_BE_VALIDATED
                }
                actionName={'Importer un justificatif'}
                accept=".pdf"
                defaultValue={mileStone?.attachments as any}
                // onChangeAction={(e: any) => {
                //   setError('attachments', { message: undefined });
                // }}
              />
              <Box>
                <Flex justifyContent="flex-end" mt={20}>
                  {isEditable && (
                    <>
                      {method === 'UPDATE' &&
                        mileStone?.status !==
                          EadditionalActivityStatus.TO_BE_VALIDATED &&
                        (!createdByProvider ||
                          mileStone?.status ===
                            EadditionalActivityStatus.REJECTED) && (
                          <Button
                            onClick={() => onSaveLater()}
                            key="saveLater"
                            type="button"
                            isLoading={loadingLater}
                            isDisabled={!isEditable || loading || loadingLater}
                            mr={10}
                          >
                            Enregistrer
                          </Button>
                        )}
                      {method === 'CREATE' && (
                        <Button
                          key="submit"
                          type="submit"
                          isLoading={loading}
                          variant={'filled'}
                          mr={10}
                          isDisabled={!isEditable || loading || loadingLater}
                        >
                          Créer
                        </Button>
                      )}
                      {method === 'UPDATE' &&
                        mileStone?.status ===
                          EadditionalActivityStatus.TO_BE_VALIDATED && (
                          <Button
                            key="submit"
                            type="submit"
                            isLoading={loading}
                            variant={'filled'}
                            mr={10}
                            isDisabled={!isEditable || loading || loadingLater}
                          >
                            Valider
                          </Button>
                        )}
                      {method === 'UPDATE' &&
                        mileStone?.status ===
                          EadditionalActivityStatus.TO_BE_VALIDATED && (
                          <Button
                            type="button"
                            isDisabled={!isEditable || loading || loadingLater}
                            variant="ghostError"
                            onClick={async () => {
                              await showRejectAdditionalActivityModal({
                                additionalActivity:
                                  mileStone as IJoinedAdditionalActivity,
                              })?.then(action => {
                                if (action) {
                                  queryClient?.invalidateQueries({
                                    queryKey: ['additionalActivities'],
                                    type: 'active',
                                  });
                                  queryClient?.refetchQueries({
                                    queryKey: [mission?.reference],
                                    type: 'active',
                                  });
                                  if (onResolve) {
                                    onResolve(true);
                                  }
                                }
                              });
                            }}
                          >
                            Refuser
                          </Button>
                        )}
                    </>
                  )}
                </Flex>
              </Box>
            </form>
          </Box>
        )}
        {formSelected === 'HISTORY' && (
          <Box width={infoHidden ? 12 / 12 : 8 / 12} pr={10}>
            <HistoryList
              columns={columns}
              filterObject={{
                ownerId: mileStone?.uuid,
                ownerType: EChangeOwnerType?.ACTIVITY,
              }}
            />
          </Box>
        )}
        {/* */}
        <Box hidden={infoHidden} width={4 / 12}>
          <Box>
            <Box width={1 / 1} borderRadius={'5px'} backgroundColor="#e5f5ff">
              <Box p={10}>
                <LabelField
                  label="Date de mission"
                  value={`${getHumanDate(
                    mission?.startAt as Date
                  )} au ${getHumanDate(mission?.endAt as Date)}`}
                  underline
                  mb={10}
                />
                <LabelField
                  label="Référence de la mission"
                  value={mission?.displayReference}
                  linkValue={`/delivery/missions/${mission?.reference}/gescom-create`}
                  underline
                  mb={10}
                />
                <LabelField
                  label="Client"
                  value={getEstablishmentName(estCustomer)}
                  linkValue={`/clients/establishments/${estCustomer?.uuid}/information`}
                  underline
                  mb={10}
                />
                <LabelField
                  label="Fournisseur"
                  value={getEstablishmentName(estProvider)}
                  linkValue={`/providers/establishments/${estProvider?.uuid}/information`}
                  underline
                  mb={10}
                />
                <LabelField
                  label="Intervenant"
                  value={getFullName(contractor)}
                  linkValue={`/providers/contractors/${contractor?.uuid}`}
                  underline
                  mb={10}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Flex>
    </Box>
  );
};
