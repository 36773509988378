import {
  EContractType,
  EContractTypeLabel,
} from '@freelancelabs/teoreme-commons';
import {
  FR_STATUS,
  getFullName,
  getHumanDate,
  useContractFindManyHistorical,
  EXECUTIVE_CONTRACTS_SELECT,
  MANDAT_CONTRACTS_SELECT,
} from '@commons';
import { Header } from 'components/Header';
import {
  Link,
  Menu,
  MenuItem,
  Table,
  Container,
  Tab,
  Text,
  TableColumn,
  Itemize,
  Button,
  SpinnerBox,
} from 'components/ui';
import { EyeIcon, DotsIcon } from 'components/ui/icons';
import { useSetCrumbs } from 'hooks/breadCrumb';
import React from 'react';
import { useParams } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { showUpdateExecutiveContractModal } from 'components/modals/legals/UpdateExecutiveContractModal';
import { showDisplayPdfModal } from 'components/modals/DisplayPdfModal';

export const ContractLegalListPage = () => {
  const theme = useTheme();
  const [order, setOrder] = React.useState<'desc' | 'asc'>('asc');

  const [sortedBy, setSortedBy] = React.useState<string>('createdAt');
  const { filter } = useParams<{ filter: string }>();
  const [page, setPage] = React.useState(0);
  const sortString = (order === 'desc' ? '-' : '') + sortedBy;
  const { data: contactsQuery, isFetching } = useContractFindManyHistorical({
    filterObject: {},
    sort: sortString,
    limit: 20,
    skip: page * 20,
  });
  type Item = Itemize<any>;
  const columns: TableColumn<Item>[] = [
    {
      key: 'value',
      text: 'NOM',
      sortKey: 'value',
      render: item => `${FR_STATUS[item?.type as EContractType]}_${item?.lang}`,
    },
    {
      key: 'version',
      text: 'VERSION',
      sortKey: 'version',
      render: item => item?.version,
    },
    {
      key: 'createdAt',
      text: 'MODIFIÉ LE',
      sortKey: 'createdAt',
      render: item => getHumanDate(item?.updatedAt),
    },
    {
      key: 'updatedBy',
      text: 'MODIFIÉ PAR',
      sortKey: 'updatedBy',
      render: item => getFullName(item?.replacedBy),
    },
    {
      align: 'flex-end',
      key: 'actions',
      text: 'ACTIONS',
      render: item => (
        <>
          <Menu
            align="right"
            ml={10}
            menuItems={
              <>
                <MenuItem>
                  <Link
                    onClick={() =>
                      showDisplayPdfModal({
                        fileLocation: item?.originalContract?.fileLocation,
                        fileName: item?.originalContract?.fileName,
                      })
                    }
                  >
                    <Link iconLeft={<EyeIcon />}>Voir le contrat</Link>
                  </Link>
                </MenuItem>
                {/* <MenuItem>
                  <FileInput
                    variant="link"
                    //onSelect={handleSetFile}
                    accept=".pdf"
                    subLabel=""
                  >
                    <Link iconLeft={<DownloadIcon />}>
                      Mettre à jour le contrat
                    </Link>
                  </FileInput>
                </MenuItem> */}
              </>
            }
          >
            <DotsIcon fill={theme?.colors?.primary?.default} fontSize={20} />
          </Menu>
        </>
      ),
    },
  ];
  const columnsActive: TableColumn<Item>[] = [
    {
      key: 'value',
      text: 'NOM',
      sortKey: 'value',
      render: item => `${item?.label}`,
    },
    {
      align: 'flex-end',
      key: 'actions',
      text: 'ACTIONS',
      render: item => {
        const label = item?.value?.includes('LOREAL')
          ? EContractTypeLabel?.MANDATE
          : EContractTypeLabel?.GROUP;
        const lang = item?.value?.split('_')?.pop();
        const name = item?.value.replace(
          `_${item?.value?.split('_')?.pop()}`,
          ''
        );
        return (
          <>
            <Menu
              align="right"
              ml={10}
              menuItems={
                <>
                  <MenuItem>
                    <Link
                      onClick={() =>
                        showDisplayPdfModal({
                          fileLocation: `templates/${label}/${lang}/${name}.pdf`,
                          fileName: `${name}.pdf`,
                        })
                      }
                    >
                      <Link iconLeft={<EyeIcon />}>Voir le contrat</Link>
                    </Link>
                  </MenuItem>
                </>
              }
            >
              <DotsIcon fill={theme?.colors?.primary?.default} fontSize={20} />
            </Menu>
          </>
        );
      },
    },
  ];
  useSetCrumbs(
    [filter],
    [
      {
        label: 'contrats',
        path: '/legals/contracts',
      },
      {
        label: filter === 'active' ? 'actifs' : 'historique',
        path: '/legals/contracts/' + filter,
      },
    ]
  );
  // we need to give items a key
  const items =
    contactsQuery?.contracts.map((u: any) => ({
      key: u.uuid || '',
      ...u,
    })) || [];

  const onSortedChange = React.useCallback(
    (sortBy: string, order: 'asc' | 'desc'): void => {
      setSortedBy(sortBy);
      setOrder(order);
    },
    []
  );
  return (
    <>
      <Header
        actions={
          <Button onClick={() => showUpdateExecutiveContractModal()}>
            Mettre à jour un contrat
          </Button>
        }
        tabs={
          <>
            <Tab variant="secondary" href={'/legals/contracts/active'}>
              Actif
            </Tab>
            <Tab variant="secondary" href={'/legals/contracts/historical'}>
              Historique
            </Tab>
          </>
        }
      >
        <Text variant="h1">Contrats</Text>
      </Header>
      {isFetching && <SpinnerBox />}
      {!isFetching && (
        <Container pt={10}>
          {filter === 'historical' ? (
            <Table<Item>
              columns={columns}
              items={items}
              order={order}
              sortedBy={sortedBy}
              page={1}
              onChangePage={page => setPage(page)}
              total={items?.length}
              perPage={items?.length && items?.length < 20 ? items?.length : 20}
              onSortedChange={onSortedChange}
            />
          ) : (
            <Table<Item>
              columns={columnsActive}
              items={[
                ...MANDAT_CONTRACTS_SELECT,
                ...EXECUTIVE_CONTRACTS_SELECT.filter(
                  (c: any) =>
                    !c.value.includes(EContractType.OTHER) &&
                    !c.value.includes(EContractType.SPECIFIC)
                ),
              ]}
              page={1}
            />
          )}
        </Container>
      )}
    </>
  );
};
