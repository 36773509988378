import React, { useEffect, useCallback, useRef, useState } from 'react';
import { getMe, refreshTheToken, isExpTokenValid } from '@commons';
import { cleanSession } from '@commons';
import { differenceInMinutes } from 'date-fns';
import { jwtDecode } from 'jwt-decode';
import { showDialogModal } from 'components/modals/DialogModal';
import { OPEN_MODAL_INACTIVITY_MINUTES } from '@commons';
import { useHistory } from 'react-router-dom';
type Props = {
  children: React.ReactNode;
};

export const AuthGuard = ({ children }: Props) => {
  const [modalInactivityOpen, setModalInactivityOpen] = useState(false);
  const intervalRef = useRef();
  const history = useHistory();

  const getTimeLeft = useCallback(async () => {
    const token = localStorage.getItem('token');

    if (token) {
      //@ts-ignore
      const exp = (jwtDecode(token) as any)?.exp || 0;
      const isValidToken = isExpTokenValid(exp);
      /** If token is expired - try to use the refresh tken */
      const endDate = new Date((exp as number) * 1000);
      const timeLeft = differenceInMinutes(endDate, new Date());

      /* Refresh Token before 10 last minutes */
      if (timeLeft <= OPEN_MODAL_INACTIVITY_MINUTES && timeLeft > 1) {
        if (!modalInactivityOpen) {
          onOpenModalInactivity();
        }
      }
      if (/*timeLeft < 1*/ !isValidToken) {
        history.push('/login');
        setModalInactivityOpen(false);
        cleanSession();
      }
    }
  }, [modalInactivityOpen]);

  const onOpenModalInactivity = useCallback(async () => {
    setModalInactivityOpen(true);
    await showDialogModal({
      title: 'Êtes-vous toujours là?',
      text: `En raison d'une période d'inactivité, vous allez être automatiquement déconnecté dans 5 minutes.`,
      confirmLabel: 'Oui, je suis là !',
    }).then(async action => {
      if (action) {
        try {
          const token = await refreshTheToken(localStorage.refreshToken);
          localStorage.token = token;
          await getMe();
          setModalInactivityOpen(false);
        } catch (e) {
          cleanSession();
        }
      }
    });
  }, []);
  useEffect(() => {
    //@ts-ignore
    intervalRef.current = setInterval(getTimeLeft, 1000);
    return () => {
      clearInterval(intervalRef.current);
    };
  }, [getTimeLeft]);

  return <>{children}</>;
};

export default React.memo(AuthGuard);
