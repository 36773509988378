import { useQuery } from '@tanstack/react-query';
import { searchGeocodingMapBox, IMapBoxParams } from '../routes';

export const useAdressFindMany = (variables?: IMapBoxParams) => {
  return useQuery({
    queryKey: ['mapbox', variables],
    queryFn: async () => searchGeocodingMapBox(variables as IMapBoxParams),
    enabled: variables?.search_text && variables?.endpoint ? true : false,
  });
};
