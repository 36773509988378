import { TopBar } from 'components/TopBar';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Contacts } from './Contacts';
import { Contractors } from './Contractors';
import { Contracts } from './Contracts';
import { DPAE } from './DPAE';
import { Establishments } from './Establishments';
import { ValidationBankAccount } from './ValidationBankAccount';

export const Providers = () => {
  return (
    <>
      <TopBar />
      <Switch>
        <Route exact path="/providers">
          <Redirect to="/providers/contacts" />
        </Route>
        <Route path="/providers/contacts">
          <Contacts />
        </Route>
        <Route path="/providers/establishments">
          <Establishments />
        </Route>
        <Route path="/providers/contracts">
          <Contracts />
        </Route>
        <Route path="/providers/contractors">
          <Contractors />
        </Route>
        <Route path="/providers/dpae">
          <DPAE />
        </Route>
        <Route path="/providers/validate-bank-account">
          <ValidationBankAccount />
        </Route>
      </Switch>
    </>
  );
};
