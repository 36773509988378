import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ClientsEstablishmentsDetail } from './Detail';
import { ClientsEstabishmentsList } from './List';

export const ClientsEstablishments = () => {
  return (
    <Switch>
      <Route exact path="/clients/establishments">
        <Redirect to="/clients/establishments/active" />
      </Route>
      <Route exact path="/clients/establishments/:filter(active|closed)">
        <ClientsEstabishmentsList />
      </Route>
      <Route path="/clients/establishments/:uuid">
        <ClientsEstablishmentsDetail />
      </Route>
    </Switch>
  );
};
