import {
  getEstablishmentName,
  getFullName,
  usePurchaseOrderFindMany,
} from '@commons';
import {
  EPurchaseOrderStatus,
  IJoinedPurchaseOrder,
} from '@freelancelabs/teoreme-commons';
import { Header } from 'components/Header';
import { UserSelect } from 'components/selects/UserSelect';
import {
  Box,
  Container,
  DatePickerRange,
  Flex,
  FormLabel,
  Input,
  Itemize,
  Link,
  SpinnerBox,
  Status,
  Tab,
  Table,
  TableColumn,
  Text,
} from 'components/ui';
import { RefreshIcon } from 'components/ui/icons';
import { add, getTime } from 'date-fns';
import { format } from 'date-fns';
import { useSetCrumbs } from 'hooks/breadCrumb';
import { useDebounce } from 'hooks/useDebounce';
import * as React from 'react';
import { useParams } from 'react-router-dom';
import { usePurchaseOrdersListStore } from 'store';
import { Theme } from 'styles';
import { PurchaseOrderActions } from './PurchaseOrderActions';
type Item = Itemize<IJoinedPurchaseOrder>;

const filterLabel = {
  sent: 'Envoyés',
  countersign: 'À contresigner',
  active: 'Actifs',
  archived: 'Archivés',
  canceled: 'Annulés',
  rejected: 'Refusés',
};

const filterStatus = {
  sent: EPurchaseOrderStatus.TO_SIGN,
  countersign: EPurchaseOrderStatus.SIGNED,
  active: {
    $in: [
      EPurchaseOrderStatus.DONE,
      EPurchaseOrderStatus.SIGNED_IMPORT,
      EPurchaseOrderStatus.IMPORTED,
    ],
  },
  rejected: {
    $in: [
      EPurchaseOrderStatus.REFUSED_BY_MANAGER,
      EPurchaseOrderStatus.REFUSED_BY_PROVIDER,
    ],
  },
  canceled: {
    $in: [EPurchaseOrderStatus.DELETED, EPurchaseOrderStatus.ABORTED],
  },
  archived: EPurchaseOrderStatus.ARCHIVED,
};

const tabItems = (
  <>
    <Tab variant="secondary" href={'/delivery/purchase-orders/sent'}>
      {filterLabel.sent}
    </Tab>
    <Tab variant="secondary" href={'/delivery/purchase-orders/countersign'}>
      {filterLabel.countersign}
    </Tab>
    <Tab variant="secondary" href={'/delivery/purchase-orders/active'}>
      {filterLabel.active}
    </Tab>
    <Tab variant="secondary" href={'/delivery/purchase-orders/rejected'}>
      {filterLabel.rejected}
    </Tab>
    <Tab variant="secondary" href={'/delivery/purchase-orders/canceled'}>
      {filterLabel.canceled}
    </Tab>
    <Tab variant="secondary" href={'/delivery/purchase-orders/archived'}>
      {filterLabel.archived}
    </Tab>
  </>
);

export const PurchaseOrdersList = () => {
  const { filter: filterParams } = useParams<{
    filter:
      | 'sent'
      | 'countersign'
      | 'active'
      | 'archived'
      | 'rejected'
      | 'canceled';
  }>();
  useSetCrumbs(
    [filterParams],
    [
      { label: 'prestations', path: '/delivery' },
      {
        label: 'bons de commande fournisseurs',
        path: '/delivery/purchase-orders',
      },
      {
        label: filterLabel[filterParams].toLowerCase(),
        path: '/delivery/purchase-orders/' + filterParams,
      },
    ]
  );
  const {
    initialSate,
    order,
    sortedBy,
    searchQuery,
    startDate,
    endDate,
    managerSelected,
    page,
    updatePurchaseOrdersListStore,
    resetPurchaseOrdersListStore,
  } = usePurchaseOrdersListStore();
  const debouncedFilterQuery = useDebounce(searchQuery, 500);
  const sortString = (order === 'desc' ? '-' : '') + sortedBy;
  const {
    data: purchaseOrdersQuery,
    status,
    isFetching,
  } = usePurchaseOrderFindMany({
    filterObject: {
      status: filterStatus[filterParams],
      $or: [
        { reference: { $regex: debouncedFilterQuery, $options: 'i' } },
        {
          'mission.displayReference': {
            $regex: debouncedFilterQuery,
            $options: 'i',
          },
        },
        {
          'establishment.businessName': {
            $regex: debouncedFilterQuery,
            $options: 'i',
          },
        },
        {
          'establishment.siret': {
            $regex: debouncedFilterQuery,
            $options: 'i',
          },
        },
      ],
      $and:
        startDate && endDate
          ? [
              {
                $expr: {
                  $gte: [{ $toLong: `$updatedAt` }, getTime(startDate)],
                },
              },
              {
                $expr: {
                  $lte: [
                    { $toLong: `$updatedAt` },
                    getTime(add(endDate, { months: 1 })),
                  ],
                },
              },
            ]
          : undefined,
      ...(managerSelected && {
        'accountManager.cognitoUserId': managerSelected,
      }),
    },
    limit: 20,
    skip: 20 * page,
    sort: sortString,
  });

  const totalCount = purchaseOrdersQuery?.totalCount;
  const loading = status === 'pending';

  const items: Item[] =
    purchaseOrdersQuery?.purchaseOrders?.map((po, index) => ({
      ...po,
      key: `${po.reference}_${po?.mission?.reference}_${index}`,
    })) || [];

  const onSortedChange = React.useCallback(
    (sortedBy: string, order: 'asc' | 'desc'): void => {
      //@ts-ignore
      updatePurchaseOrdersListStore({ sortedBy, order });
    },
    []
  );
  React.useEffect(() => {
    //
  }, [filterParams]);
  const columns: TableColumn<Item>[] = [
    {
      key: 'updatedAt',
      text: 'DATE',
      sortKey: 'updatedAt',
      render: item =>
        // @ts-ignore
        !!item.updatedAt && format(item.updatedAt, 'dd/MM/yyyy'),
    },

    { key: 'reference', text: 'RÉF.', sortKey: 'reference' },
    {
      key: 'establishment',
      text: 'ÉTABLISSEMENT FOURNISSEUR',
      sortKey: 'establishment.businessName',
      render: item => getEstablishmentName(item.establishment),
    },
    {
      key: 'mission.displayReference',
      text: 'RÉF. DE LA PRESTATION',
      sortKey: 'mission.displayReference',
      render: item => item.mission.displayReference || item.mission.reference,
    },
    {
      key: 'acountManager',
      text: 'SUIVI PAR',
      sortKey: 'accountManager.lastName acountManager.firstName',
      render: item => getFullName(item.accountManager),
    },
    {
      key: 'status',
      text: 'REJETÉ PAR ',
      hidden: filterParams !== 'rejected',
      render: item => {
        if (item?.status === EPurchaseOrderStatus.REFUSED_BY_MANAGER) {
          return <Status variantColor="error">Chargé de compte</Status>;
        }
        if (item?.status === EPurchaseOrderStatus.REFUSED_BY_PROVIDER) {
          return <Status variantColor="error">Fournisseur</Status>;
        }
      },
    },
    {
      key: 'declineReason',
      text: 'MOTIF ',
      hidden: filterParams !== 'rejected',
      render: item => (
        <Status variantColor="error">{item?.declineReason}</Status>
      ),
    },
    {
      align: 'flex-end',
      key: 'actions',
      text: 'ACTIONS',
      render: item => <PurchaseOrderActions purchaseOrder={item} />,
    },
  ];

  return (
    <Box>
      <Header tabs={tabItems}>
        <Text variant="h1">Bons de commande fournisseurs</Text>
      </Header>
      <Container p="20px 0">
        <Flex mt="10px" mb="10px">
          <Box width={3 / 12}>
            <FormLabel>Rechercher</FormLabel>
            <Input
              isFullWidth
              type="search"
              value={searchQuery}
              placeholder="Rechercher une référence, un établissement..."
              onChange={(e: any) => {
                updatePurchaseOrdersListStore({
                  searchQuery: e?.target?.value,
                });
              }}
            />
          </Box>
          <Box width={3 / 12} ml={20}>
            <FormLabel>Chargés de compte</FormLabel>
            <UserSelect
              placeholder="Tous les chargés de compte"
              role="ACCOUNT_MANAGER"
              onChange={id =>
                updatePurchaseOrdersListStore({ managerSelected: id })
              }
              value={managerSelected}
            />
          </Box>
          <Box width={3 / 12} mr={10} ml={20}>
            <FormLabel ml={10}>PÉRIODE</FormLabel>
            <DatePickerRange
              startDate={startDate}
              endDate={endDate}
              isClearable={true}
              setStartDate={value => {
                updatePurchaseOrdersListStore({ startDate: value });
              }}
              setEndDate={value => {
                updatePurchaseOrdersListStore({ endDate: value });
              }}
            />
          </Box>
          <Box ml={10} mt={10}>
            <Flex
              justifyContent={'center'}
              alignItems={'center'}
              flexWrap="wrap"
              alignContent="center"
              width={1 / 1}
              height={'100%'}
            >
              <Link
                isDisabled={initialSate}
                iconLeft={
                  <RefreshIcon
                    fill={
                      initialSate ? Theme?.colors?.grey?.default : undefined
                    }
                  />
                }
                mt={'5px'}
                onClick={() =>
                  !initialSate ? resetPurchaseOrdersListStore({}) : false
                }
              >
                Réinitialiser
              </Link>
            </Flex>
          </Box>
        </Flex>
        {loading && <SpinnerBox />}
        {!loading && (
          <Table<Item>
            columns={columns}
            items={items}
            sortedBy={sortedBy}
            order={order}
            page={page}
            total={totalCount}
            perPage={totalCount && totalCount < 20 ? totalCount : 20}
            onSortedChange={onSortedChange}
            onChangePage={page => updatePurchaseOrdersListStore({ page })}
            opacity={
              filterParams === 'archived' || filterParams === 'canceled'
                ? 0.5
                : 1
            }
            isLoading={isFetching}
          />
        )}
      </Container>
    </Box>
  );
};
