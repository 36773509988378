import { Box } from 'components/ui';
import { UpdateExecutiveContractForm } from 'forms/legals/UpdateExecutiveContractForm';
import { create } from 'react-modal-promise';
import { ModalFrame, ModalProps } from '../ModalFrame';

type Props = ModalProps & {
  beforeValidation?: () => void;
  establishmentUuid: string;
  refOrder?: string;
};
type FormValues = {
  refContract: string;
  createdAt: Date;
  type: string;
  lang: string;
  file: File;
};

export const UpdateExecutiveContractModal = ({
  onResolve,
  isOpen,
  establishmentUuid,
  refOrder,
}: Props) => {
  const handleSubmit = async (formData: FormValues) => {};

  return (
    <ModalFrame
      isOpen={isOpen}
      onClose={() => onResolve(false)}
      width={470}
      closeIcon
    >
      <Box>
        <UpdateExecutiveContractForm
          onClose={() => onResolve(false)}
          onSubmit={handleSubmit}
        />
      </Box>
    </ModalFrame>
  );
};

export const showUpdateExecutiveContractModal = create<Props>(
  UpdateExecutiveContractModal
);
