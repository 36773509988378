import { EContractState, EContractLabel } from '@freelancelabs/teoreme-commons';
import { Header } from 'components/Header';
import { ContractList } from 'components/lists/ContractList';
import { StaticSelect } from 'components/selects/StaticSelect';
import { UserSelect } from 'components/selects/UserSelect';
import {
  Flex,
  Box,
  Input,
  Container,
  Tab,
  Text,
  FormLabel,
  DatePickerRange,
  Link,
} from 'components/ui';
import { useSetCrumbs } from 'hooks/breadCrumb';
import { useParams } from 'react-router-dom';
import { useDebounce } from 'hooks/useDebounce';
import { add, getTime } from 'date-fns';
import { useContractsListStore } from 'store';
import { RefreshIcon } from 'components/ui/icons';
import { Theme } from 'styles';
import { checkDefaultManagerSelected } from '../../../../../../libs/commons/src/helpers';
import { useMe } from '../../../../../../libs/commons/src/api';
export const ContractListPage = () => {
  const { me } = useMe();
  const { filter: filterParams } = useParams<{ filter: string }>();
  const {
    initialSate,
    searchQuery,
    startDate,
    endDate,
    managerSelected,
    typeSelected,
    updateContractsListStore,
    resetContractsListStore,
  } = useContractsListStore();
  const debouncedFilterQuery = useDebounce(searchQuery, 500);
  useSetCrumbs(
    [filterParams],
    [
      {
        label: 'fournisseurs',
        path: '/providers',
      },
      {
        label: 'contrats',
        path: '/providers/contracts',
      },
      {
        label:
          filterParams === 'active'
            ? 'actifs'
            : filterParams === 'archived'
              ? 'archivés'
              : filterParams === 'sent'
                ? 'envoyés'
                : filterParams === 'countersign'
                  ? 'à contresigner'
                  : filterParams === 'refused'
                    ? 'refusés'
                    : 'actifs',
        path: '/providers/contracts/' + filterParams,
      },
    ]
  );
  const ORFilter = [
    { refContract: { $regex: debouncedFilterQuery, $options: 'i' } },
    {
      'establishment.businessName': {
        $regex: debouncedFilterQuery,
        $options: 'i',
      },
    },
    {
      'establishment.tradeName': {
        $regex: debouncedFilterQuery,
        $options: 'i',
      },
    },
    {
      'establishment.signBoard1': {
        $regex: debouncedFilterQuery,
        $options: 'i',
      },
    },
    {
      'establishment.signBoard2': {
        $regex: debouncedFilterQuery,
        $options: 'i',
      },
    },
    {
      'establishment.signBoard3': {
        $regex: debouncedFilterQuery,
        $options: 'i',
      },
    },
    {
      'establishment.siret': {
        $regex: debouncedFilterQuery,
        $options: 'i',
      },
    },
  ];

  const mongoFilter =
    filterParams === 'cancel'
      ? EContractState.ARCHIVED
      : filterParams === 'archived'
        ? EContractState.ARCHIVED
        : filterParams === 'sent'
          ? EContractState.TO_SIGN_PROVIDER
          : filterParams === 'countersign'
            ? EContractState.SIGNED_BY_PROVIDER
            : filterParams === 'refused'
              ? {
                  $in: [
                    EContractState.REFUSED_BY_PROVIDER,
                    EContractState.REFUSED_BY_FCOM,
                  ],
                }
              : EContractState.DONE;

  return (
    <>
      <Header
        tabs={
          <>
            <Tab variant="secondary" href={'/providers/contracts/sent'}>
              Envoyés
            </Tab>
            <Tab variant="secondary" href={'/providers/contracts/countersign'}>
              À contresigner
            </Tab>
            <Tab variant="secondary" href={'/providers/contracts/active'}>
              Actifs
            </Tab>
            <Tab variant="secondary" href={'/providers/contracts/archived'}>
              Archivés
            </Tab>
            <Tab variant="secondary" href={'/providers/contracts/refused'}>
              Refusés
            </Tab>
            <Tab variant="secondary" href={'/providers/contracts/cancel'}>
              Résiliés
            </Tab>
          </>
        }
      >
        <Text variant="h1">Contrats</Text>
      </Header>
      <Container pt={10}>
        <Flex justifyContent="flex-start">
          <Box width={3 / 12} mb={20}>
            <FormLabel>Rechercher</FormLabel>
            <Input
              data-cy="search-contractor-list"
              isFullWidth
              value={searchQuery}
              placeholder="Rechercher"
              onChange={e =>
                updateContractsListStore({ searchQuery: e.currentTarget.value })
              }
            />
          </Box>
          <Box width={3 / 12} ml={20}>
            <FormLabel>Chargés de compte</FormLabel>
            <UserSelect
              placeholder="Tous les chargés de compte"
              role="ACCOUNT_MANAGER"
              onChange={id => updateContractsListStore({ managerSelected: id })}
              value={managerSelected}
            />
          </Box>
          <Box width={3 / 12} mr={10}>
            <FormLabel ml={10}>PÉRIODE</FormLabel>
            <DatePickerRange
              startDate={startDate}
              endDate={endDate}
              isClearable={true}
              setStartDate={value => {
                updateContractsListStore({ startDate: value });
              }}
              setEndDate={value => {
                updateContractsListStore({ endDate: value });
              }}
            />
          </Box>
          <Box width={3 / 12} ml={10}>
            <FormLabel>TYPE DE CONTRAT</FormLabel>
            <StaticSelect
              placeholder="Tous les types"
              options={[
                { value: 'N/A', label: 'Tous les types' },
                { value: 'SIMPLIFIED', label: 'Simplifié Groupe' },
                { value: 'CLASSIC', label: 'Classique Groupe' },
                { value: 'LOREAL', label: "Mandat( l'Oreal )" },
                { value: 'LOREAL_4_5', label: "Mandat( l'Oreal ) 4,5%" },
                { value: 'LOREAL_5', label: "Mandat( l'Oreal ) 5%" },
                { value: 'OTHER', label: 'Autre' },
              ]}
              onChange={value =>
                updateContractsListStore({ typeSelected: value as string })
              }
              value={typeSelected}
            />
          </Box>
          <Box ml={10}>
            <Flex
              justifyContent={'center'}
              alignItems={'center'}
              flexWrap="wrap"
              alignContent="center"
              width={1 / 1}
              height={'100%'}
            >
              <Link
                isDisabled={initialSate}
                iconLeft={
                  <RefreshIcon
                    fill={
                      initialSate ? Theme?.colors?.grey?.default : undefined
                    }
                  />
                }
                mt={'5px'}
                onClick={() =>
                  !initialSate ? resetContractsListStore({}) : false
                }
              >
                Réinitialiser
              </Link>
            </Flex>
          </Box>
        </Flex>
        <ContractList
          showStatus={false}
          filterObject={{
            ...(managerSelected && {
              accountManager: checkDefaultManagerSelected(me, managerSelected)
                ? managerSelected
                : undefined,
            }),
            label: { $in: [EContractLabel.EXECUTIVE, EContractLabel.MANDATE] },
            state: mongoFilter,
            type: typeSelected !== 'N/A' ? typeSelected : undefined,
            $or: ORFilter,
            cancelReason:
              filterParams === 'cancel' ? { $exists: true } : undefined,
            $and:
              startDate && endDate
                ? [
                    {
                      $expr: {
                        $gte: [{ $toLong: `$createdAt` }, getTime(startDate)],
                      },
                    },
                    {
                      $expr: {
                        $lte: [
                          { $toLong: `$createdAt` },
                          getTime(add(endDate, { months: 1 })),
                        ],
                      },
                    },
                  ]
                : undefined,
          }}
        />
      </Container>
    </>
  );
};
