import { Header } from 'components/Header';
import { SideBar } from 'components/SideBar';
import { SideLink } from 'components/SideLink';
import { Text, Box, Container, Flex, Separator } from 'components/ui';
import { Route, Switch } from 'react-router-dom';
import { History } from './History';
export const SettingDetails = () => {
  //
  return (
    <>
      <Flex>
        <Header>
          <Flex justifyContent="space-between" alignItems={'center'}>
            <Text variant="h1" mt={20}>
              Paramètres
            </Text>
          </Flex>
        </Header>
      </Flex>
      <Container p="50px 0">
        <Flex>
          <SideBar>
            <SideLink href={`/admin/settings/history`}>
              <Box>Paramètres des historiques </Box>
            </SideLink>
            <Separator />
          </SideBar>

          <Box flex={1}>
            <Switch>
              <Route path="/admin/settings/history">
                <History />
              </Route>
            </Switch>
          </Box>
        </Flex>
      </Container>
    </>
  );
};
