import { create } from 'zustand';
enum filterType {
  SENT = 'sent',
  COUNTERSIGN = 'countersign',
  ACTIVE = 'active',
  ARCHIVED = 'archived',
  REJECTED = 'rejected',
  CANCELED = 'canceled',
}
enum sortType {
  ASC = 'asc',
  DESC = 'desc',
}
type State = {
  initialSate: boolean;
  filter: filterType;
  order?: sortType;
  sortedBy: string;
  searchQuery: string;
  startDate: Date | null;
  endDate: Date | null;
  managerSelected?: string;
  statusSelect?: string;
  typeSelected?: string;
  page: number;
  isOpen?: boolean;
  updatePurchaseOrdersListStore: (state: Partial<State>) => void;
  resetPurchaseOrdersListStore: (state: Partial<State>) => void;
};
const initialStates = {
  initialSate: true,
  filter: filterType?.ACTIVE,
  order: sortType?.DESC,
  sortedBy: 'updatedAt',
  searchQuery: '',
  startDate: null,
  endDate: null,
  managerSelected: localStorage?.cognitoUserId,
  statusSelect: 'N/A',
  typeSelected: 'N/A',
  page: 0,
  isOpen: undefined,
};

const store = (set: any) => ({
  ...initialStates,
  updatePurchaseOrdersListStore: (state: Partial<State>) =>
    set({ initialSate: false, page: 0, ...state }),
  resetPurchaseOrdersListStore: (state: Partial<State>) =>
    set({ ...initialStates, ...state }),
});

export const usePurchaseOrdersListStore = create<State>(store);
