import {
  useAdminSettingFindOne,
  DURATION_TAG_WHITELIST,
  MIXED_TAG_INPUT_SETTING,
  transformDurationToTag,
  transformTagToFormula,
  transformDurationFormulaToObject,
  useAdminSettingUpdateOne,
  createTask,
} from '@commons';
import {
  IAppSetting,
  IAppSettingPredefined,
  IChangeRetentionPolicy,
  ETaskType,
} from '@freelancelabs/teoreme-commons';
import MixedModeTagify from 'components/InputTags';
import { showDialogModal } from 'components/modals/DialogModal';
import {
  Box,
  Button,
  Flex,
  FormControl,
  Input,
  SpinnerBox,
  Text,
} from 'components/ui';
import { useShowMessage } from 'hooks/useShowMessage';
import { useEffect, useState } from 'react';
export const History = () => {
  const showMessage = useShowMessage();
  const [tagValue, setTagValue] = useState<string | undefined>();
  const [maxHistory, setMaxHistory] = useState<number | undefined>();
  const [duration, setDuration] = useState<
    IChangeRetentionPolicy['duration'] | undefined
  >();
  const [isFetchingLaunchTask, setIsFetchingLaunchTask] = useState(false);
  const { mutateAsync: updateSettings, isLoading: isFetchingUpdate } =
    useAdminSettingUpdateOne();
  const { data: settings, isPending: isLoadingSettings } =
    useAdminSettingFindOne('change.history.retention.policy.BANK_ACCOUNT');
  const value = settings?.value as IAppSetting<
    IAppSettingPredefined['change.history.retention.policy.BANK_ACCOUNT']
  >['value'];

  const onChangeDuration = (e: string) => {
    let result = transformTagToFormula(e);
    let duration = transformDurationFormulaToObject(result);
    setDuration(duration);
  };
  const onSubmit = async () => {
    let submitData: any = {};
    if (maxHistory) {
      submitData.maxHistory = Number(maxHistory);
    }
    if (duration) {
      submitData.duration = duration;
    }
    if (Object?.keys(submitData)?.length > 0) {
      try {
        await updateSettings({
          updates: [
            {
              key: 'change.history.retention.policy.BANK_ACCOUNT',
              value: submitData,
            },
          ],
        });
        showMessage('success', 'Paramètres enregistré');
      } catch (e) {
        //
      }
    }
  };
  const onLaunchTask = async () => {
    try {
      await showDialogModal({
        title: 'Lancer la tâche',
        text: 'Êtes-vous sûr de vouloir lancer la tâche ? ',
        confirmLabel: 'Lancer',
        cancelLabel: 'Annuler',
      }).then(async action => {
        if (action) {
          setIsFetchingLaunchTask(true);
          await createTask({ type: ETaskType.HISTORY_RETENTION_CHECK });
          showMessage('success', 'Tâche lancé');
          setIsFetchingLaunchTask(false);
        }
      });
    } catch (e) {
      setIsFetchingLaunchTask(false);
    }
  };

  useEffect(() => {
    if (value && !tagValue) {
      setTagValue(transformDurationToTag(value?.duration));
      setDuration(value?.duration);
      setMaxHistory(value?.maxHistory);
    }
  }, [value]);

  return (
    <Box>
      <Text variant="h3" mb={20}>
        Paramètres des historiques
      </Text>
      {isLoadingSettings && <SpinnerBox />}
      <Box width={800} hidden={isLoadingSettings}>
        <Flex>
          <Box width={2 / 3}>
            {tagValue !== undefined && (
              <FormControl label="Durée de rétention des historiques" p={'5px'}>
                <MixedModeTagify
                  placeholder="Ecrivez votre formule (@ pour utiliser les variables)"
                  settings={{
                    ...MIXED_TAG_INPUT_SETTING,
                    whitelist: DURATION_TAG_WHITELIST,
                  }}
                  onChange={e => onChangeDuration(e)}
                  value={tagValue || ''}
                />
              </FormControl>
            )}
          </Box>
          <Box width={1 / 3} p={'5px'}>
            <FormControl label="Nombre d'historique maximum">
              <Input
                onChange={(e: any) => setMaxHistory(e?.target?.value)}
                value={maxHistory}
                isFullWidth
                type="number"
              />
            </FormControl>
          </Box>
        </Flex>
        <Flex>
          <Button
            isLoading={isFetchingUpdate || isFetchingLaunchTask}
            onClick={() => onSubmit()}
          >
            Enregister
          </Button>
          <Button
            isLoading={isFetchingUpdate || isFetchingLaunchTask}
            ml={5}
            variant="ghost"
            onClick={() => onLaunchTask()}
          >
            Lancer la tâche
          </Button>
        </Flex>
      </Box>
    </Box>
  );
};
