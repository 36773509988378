import React from 'react';
import { useController } from 'react-hook-form';
import { Input } from 'components/ui';

export const NumberFormInputControlled = (props: any) => {
  const {
    field: { ref, ...inputProps },
  } = useController({
    name: props?.name,
    control: props?.control,
    rules: props?.rules,
    defaultValue: props?.defaultValue,
  });

  return <Input type={'number'} {...inputProps} {...props} inputRef={ref} />;
};
