import { checkFormEmail } from '@commons';
import {
  EEmailRecipientType,
  IEmailContact,
} from '@freelancelabs/teoreme-commons';
import {
  Box,
  Button,
  FormControl,
  Input,
  Flex,
  StaticSelectControlled,
  CheckSwitch,
} from 'components/ui';
import { AddIcon, CloseIcon, DeleteIcon, EditIcon } from 'components/ui/icons';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
type FormValues = {
  active?: boolean;
  email: string;
  // lastName?: string | undefined;
  // firstName?: string | undefined;
  // civility?: Civility | undefined;
  // cognitoUserId?: string | undefined;
  recipientType?: EEmailRecipientType | undefined;
  //description?: string | undefined;
};

type AddEmailContactProps = {
  contacts: IEmailContact[] | [];
  onEdit: (email: string | false) => void;
  onDelete?: (email: string) => void;
  onActive?: (
    contact: IEmailContact & {
      inherited?: boolean | undefined;
      active?: boolean | undefined;
    },
    active: boolean
  ) => void;
  readOnly: boolean;
  contact?: IEmailContact & {
    inherited?: boolean;
    active?: boolean;
  };
  onSubmit: (contact: {
    email: string;
    recipientType?: EEmailRecipientType;
  }) => void;
};

export const AddEmailContactForm = ({
  contacts,
  onEdit,
  onDelete,
  onActive,
  readOnly,
  contact,
  onSubmit: onSubmitParent,
}: AddEmailContactProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setError,
    getValues,
    reset,
  } = useForm<FormValues>({
    defaultValues: {
      active: contact?.active,
      email: contact?.email,
      recipientType: contact?.recipientType || EEmailRecipientType.CC,
    },
  });
  const onSubmit = async () => {
    const values = getValues();
    const email = values?.email;
    setLoading(true);
    let errorEmail = checkFormEmail(email);
    if (contacts?.find(c => c?.email === email)) {
      if (!contact) {
        // CREATE
        errorEmail = 'Cette email est déja dans la liste';
      } else {
        // UPDATE
        if (
          contacts
            ?.filter(c => c?.email !== contact?.email)
            ?.find(c => c?.email === email)
        ) {
          errorEmail = 'Cette email est déja dans la liste';
        }
      }
    }
    if (errorEmail === undefined && email) {
      onSubmitParent(values);
    } else {
      setError('email', {
        message: errorEmail || 'Ce champs est requis',
      });
    }
    setLoading(false);
  };
  React.useEffect(() => {
    if (contact) {
      reset({ ...contact });
    }
  }, [contact]);
  const isDisabled = readOnly;
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Flex>
        <Box width={4 / 10} pr={10}>
          <FormControl
            label="email"
            errorMessage={errors?.email?.message}
            required
          >
            <Input
              isFullWidth
              {...register('email', {
                required: 'Ce champ est requis',
                validate: (value: string) => checkFormEmail(value),
              })}
              isDisabled={isDisabled}
            />
          </FormControl>
        </Box>
        <Box width={4 / 10} pl={10}>
          <FormControl
            label="En tant que"
            errorMessage={errors?.recipientType?.message}
            required
          >
            <StaticSelectControlled
              isDisabled={isDisabled}
              isClearable={false}
              control={control}
              name="recipientType"
              options={[
                {
                  label: `Copie Carbone ( cc )`,
                  value: EEmailRecipientType.CC,
                },
                {
                  label: `Copie Carbone Invisible ( cci )`,
                  value: EEmailRecipientType.BCC,
                },

                // {
                //   label: `Date d'émission la facture`,
                //   value: EEmailRecipientType?.TO,
                // },
              ]}
              rules={{ required: 'Ce champ est requis' }}
              placeholder=""
            />
          </FormControl>
        </Box>
        <Flex alignItems="center" mb={15} pl={20}>
          {!readOnly && (
            <>
              {!contact && (
                <Button title="Ajouter" type="button" onClick={onSubmit}>
                  <AddIcon fill="white" />
                </Button>
              )}
              {contact && (
                <Button title="Ajouter" type="button" onClick={onSubmit}>
                  <EditIcon fill="white" />
                </Button>
              )}
              <Button
                title="Annuler"
                ml={'3px'}
                color="red"
                variant="ghostError"
                type="button"
                onClick={() => onEdit(false)}
              >
                <CloseIcon fill="red" />
              </Button>
            </>
          )}
          {readOnly &&
            (contact?.inherited ? (
              <FormControl
                label="Activer"
                errorMessage={errors?.active?.message}
              >
                <CheckSwitch
                  mt={15}
                  id={contact?.email || 'new'}
                  {...register('active')}
                  onChange={e =>
                    onActive && onActive(contact, e?.target?.checked)
                  }
                >
                  <></>
                </CheckSwitch>
              </FormControl>
            ) : (
              <>
                <Button
                  title="Modifier"
                  type="button"
                  onClick={() => onEdit(contact?.email as string)}
                >
                  <EditIcon fill="white" />
                </Button>
                <Button
                  title="Supprimer"
                  ml={'3px'}
                  color="red"
                  variant="ghostError"
                  type="button"
                  onClick={() => onDelete && onDelete(contact?.email as string)}
                >
                  <DeleteIcon fill="red" />
                </Button>
              </>
            ))}
        </Flex>
      </Flex>
    </form>
  );
};
