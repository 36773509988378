import {
  Button,
  Flex,
  FormInfo,
  Input,
  Text,
  FormLabel,
  Box,
  Link,
} from 'components/ui';

import { AddIcon, ChevronRightIcon } from 'components/ui/icons';
import React, { ReactNode, useState } from 'react';
import { useForm } from 'react-hook-form';

type FormValues = {
  identifier?: string;
};
type VerifySiretFormProps = {
  defaultValues?: FormValues;
  onSubmit?: (formValues: FormValues) => any | void;
  error?: ReactNode;
  isLocked?: boolean;
  onUnlock?: () => void;
  onForeignEstablishment?: () => void;
  establishmentRole?: 'PROVIDER' | 'CUSTOMER' | 'ALL';
  isFullWidth?: boolean;
  disableTitle?: boolean;
};

export const VerifySiretForm = ({
  defaultValues,
  onSubmit: onSubmitParent,
  error,
  isLocked,
  onUnlock,
  onForeignEstablishment,
  establishmentRole,
  isFullWidth,
  disableTitle,
}: VerifySiretFormProps) => {
  const [loading, setLoading] = useState(false);
  const [siretError, setSiretError] = useState<false | string>(false);
  const [showSiretInput, setShowSiretInput] = useState<boolean>(true);
  const { register, handleSubmit, watch } = useForm<FormValues>({
    defaultValues,
  });
  const identifier = watch('identifier');
  const onSubmit = async () => {
    if (identifier && identifier?.length !== 14) {
      setSiretError('N° siret invalide');
    } else {
      setSiretError(false);
      setLoading(true);
      onSubmitParent &&
        (await onSubmitParent({ identifier: identifier?.trim() }));
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {establishmentRole !== 'ALL' && !disableTitle && (
        <Text variant="h2" mb={10}>
          Ajouter un établissement{' '}
          {establishmentRole === 'CUSTOMER' ? 'client' : 'fournisseur'}
        </Text>
      )}
      {showSiretInput && (
        <>
          <FormLabel>N° SIRET (14 CHIFFRES)</FormLabel>
          <Flex width={isFullWidth ? 1 / 1 : 2 / 3}>
            <Input
              isFullWidth
              {...register('identifier')}
              type="number"
              placeholder="n° SIRET (14 chiffres)"
              autoComplete="siret"
              isDisabled={!!isLocked}
            />
            {!isLocked ? (
              <Button
                data-cy="submit-verify-siret"
                key="submit"
                type="button"
                onClick={() => onSubmit()}
                isLoading={loading}
                ml={10}
              >
                <ChevronRightIcon fill="#fff" />
              </Button>
            ) : (
              <Button key="modify" type="button" onClick={onUnlock} ml={10}>
                Modifier
              </Button>
            )}
          </Flex>
          {siretError && <FormInfo variantColor="error">{siretError}</FormInfo>}
          {error && <FormInfo variantColor="error">{error}</FormInfo>}
          <Box mt="10px">
            <Link
              iconLeft={<AddIcon />}
              onClick={() => {
                if (onForeignEstablishment) onForeignEstablishment();
                setShowSiretInput(false);
              }}
            >
              Je souhaite créer un établissement étranger (qui ne dispose pas
              d'un SIRET)
            </Link>
          </Box>
        </>
      )}
    </form>
  );
};
