import {
  IUserLocal,
  IUserMinimal,
  transformText,
  formatAllObject,
  TRANSLATE_ROLES,
} from '@commons';
import { ConfirmNavigationModal } from 'components/modals/ConfirmNavigationModal';
import {
  Flex,
  Box,
  Button,
  FormControl,
  Input,
  Radio,
  Row,
  Text,
  CheckBox,
} from 'components/ui';
import { useState } from 'react';
import { useForm } from 'react-hook-form';

type FormValues = Partial<Omit<IUserLocal, 'civility'>> & {
  civility: string;
  roles: string[];
  firstName: string;
  lastName: string;
};
type UserFormProps = {
  defaultValues?: Partial<IUserMinimal>;
  onSubmit?: (user: Partial<IUserMinimal>) => any | void;
  // we could just check if we have a cognitoUserId in out defaultValues - but let's be clear.
  isNew?: boolean;
  user?: Partial<IUserMinimal>;
};

export const UserForm = ({
  defaultValues,
  onSubmit: onSubmitParent,
  isNew,
  user,
}: UserFormProps) => {
  const [loading, setLoading] = useState(false);
  const [deactivated, setDeactivated] = useState(user?.deactivated);
  const {
    register,
    handleSubmit,
    formState: { errors, isDirty, isSubmitSuccessful },
    setError,
    clearErrors,
    watch,
  } = useForm<FormValues>({
    defaultValues: {
      email: user?.email,
      civility: user?.civility + '',
      firstName: user?.firstName,
      lastName: user?.lastName,
    },
  });
  const defaultsRoles = (roles: { name: string }[]) => {
    const _roles: string[] = [];
    roles?.forEach(role => {
      const _role: string = role?.name;
      if (
        _role === 'ACCOUNT_MANAGER' ||
        _role === 'MANAGER_READ_ONLY' ||
        _role === 'TEAM_LEADER' ||
        _role === 'SUPPORT' ||
        _role === 'LEGAL_SQUAD' ||
        _role === 'PORTAL_CLIENT_ADMIN' ||
        _role === 'MANAGER_RIB'
      ) {
        if (!_roles.find(e => e === _role)) {
          _roles.push(_role);
        }
      }
    });
    return _roles;
  };
  const email = watch('email');
  const [roles, setRoles] = useState<any>(defaultsRoles(user?.roles || []));
  const [roleDisabled, setRoleDisabled] = useState(false);
  const onSubmit = async (formValues: any) => {
    setLoading(true);
    let onError = false;
    // transform form values as requested
    const values = {
      cognitoUserId: !isNew ? user?.cognitoUserId : undefined,
      deactivated: !isNew ? deactivated : undefined,
      email: isNew ? email : undefined,
      roles,
      firstName: !isNew
        ? undefined
        : transformText(formValues.firstName, 'capitalize').trim(),
      lastName: !isNew
        ? undefined
        : transformText(formValues.lastName, 'uppercase').trim(),
      civility: !isNew ? undefined : parseInt(formValues.civility || '1', 10),
    };
    if (roles?.length < 1) {
      onError = true;
      if (isNew) {
        setError('roles', { message: 'Veuillez renseigner au moins un roles' });
      } else {
        setError('roles', {
          message:
            'Il n’est pas possible de supprimer tous les rôles d’un d’un utilisateur. Vous pouvez le désactiver si vous souhaitez lui retirer les accès.',
        });
      }
    } else {
      clearErrors('roles');
    }

    if (onError === false) {
      onSubmitParent && (await onSubmitParent(formatAllObject(values)));
    }
    setLoading(false);
  };
  const onChangeUserRole = (role: any, checked?: boolean) => {
    clearErrors('roles');
    if (role === 'MANAGER_READ_ONLY') {
      if (checked) {
        setRoles(['MANAGER_READ_ONLY']);
        setRoleDisabled(true);
      } else {
        setRoleDisabled(false);
        setRoles([]);
      }
    } else {
      setRoleDisabled(false);
      const _roles = roles;

      if (role === 'ACCOUNT_MANAGER' && !checked) {
        const index = _roles?.findIndex((r: any) => r === role);
        _roles?.splice(index, 1);

        //delete team leader if account manager not selected
        const indexTL = _roles?.findIndex((r: any) => r === 'TEAM_LEADER');
        _roles?.splice(indexTL, 1);
      } else {
        if (checked) {
          _roles?.push(role);
        } else {
          const index = _roles?.findIndex((r: any) => r === role);
          _roles?.splice(index, 1);
        }
      }
      //ADD team leader if account manager  selected
      if (
        _roles?.find((r: string) => r === 'TEAM_LEADER') ||
        (_roles?.find((r: string) => r === 'MANAGER_RIB') &&
          !_roles?.find((r: string) => r === 'ACCOUNT_MANAGER'))
      ) {
        _roles?.push('ACCOUNT_MANAGER');
      }
      setRoles(_roles);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <ConfirmNavigationModal active={isDirty && !isSubmitSuccessful} />

      <Box>
        <Box>
          <FormControl
            required={true}
            label={'Civilité'}
            errorMessage={errors?.civility?.message}
          >
            <>
              <Radio
                {...register('civility', {
                  required: isNew ? 'Ce champ est requis' : undefined,
                })}
                value="1"
                isDisabled={!isNew}
              >
                Monsieur
              </Radio>
              <Radio
                {...register('civility', {
                  required: isNew ? 'Ce champ est requis' : undefined,
                })}
                value="2"
                isDisabled={!isNew}
              >
                Madame
              </Radio>
            </>
          </FormControl>
        </Box>
        {!isNew && (
          <Box mt={20}>
            <CheckBox
              checked={deactivated}
              onChange={e => setDeactivated(!deactivated)}
              mb={10}
              key={'deactivated'}
              id={'deactivated'}
            >
              <Box>Désactivé</Box>
            </CheckBox>
          </Box>
        )}
        {!isNew && (
          <FormControl label="Email" p={'5px'}>
            <Input
              isDisabled
              isFullWidth
              {...register('email', {
                required: 'Ce champ est requis',
              })}
            />
          </FormControl>
        )}
        <Row spacing={2}>
          <FormControl
            label="Prénom"
            p={'5px'}
            errorMessage={errors?.firstName?.message}
          >
            <Input
              isDisabled={!isNew}
              isFullWidth
              {...register('firstName', {
                required: 'Ce champ est requis',
              })}
            />
          </FormControl>
          <FormControl
            label="Nom"
            p={'5px'}
            errorMessage={errors?.lastName?.message}
          >
            <Input
              isDisabled={!isNew}
              isFullWidth
              {...register('lastName', {
                required: 'Ce champ est requis',
              })}
            />
          </FormControl>
        </Row>
        <Box>
          <FormControl
            label={'Quels rôles souhaitez vous ajouter ?'}
            //@ts-ignore
            errorMessage={errors?.roles?.message}
          >
            <Box mt={20}>
              <CheckBox
                checked={
                  !!roles?.find((e: string) => e === 'MANAGER_READ_ONLY')
                }
                onChange={e =>
                  onChangeUserRole('MANAGER_READ_ONLY', e?.target?.checked)
                }
                mb={10}
                key={'MANAGER_READ_ONLY'}
                id={'MANAGER_READ_ONLY'}
              >
                <Box>
                  {TRANSLATE_ROLES['MANAGER_READ_ONLY']}{' '}
                  <Text variant="i">
                    {' '}
                    ne peut être cumulé avec un autre rôle
                  </Text>
                </Box>
              </CheckBox>
              <CheckBox
                checked={!!roles?.find((e: string) => e === 'ACCOUNT_MANAGER')}
                onChange={e =>
                  onChangeUserRole('ACCOUNT_MANAGER', e?.target?.checked)
                }
                isDisabled={roleDisabled}
                mb={10}
                key={'ACCOUNT_MANAGER'}
                id={'ACCOUNT_MANAGER'}
              >
                <Box>{TRANSLATE_ROLES['ACCOUNT_MANAGER']}</Box>
              </CheckBox>
              <Box ml={20}>
                <CheckBox
                  checked={!!roles?.find((e: string) => e === 'TEAM_LEADER')}
                  onChange={e =>
                    onChangeUserRole('TEAM_LEADER', e?.target?.checked)
                  }
                  isDisabled={roleDisabled}
                  mb={10}
                  key={'TEAM_LEADER'}
                  id={'TEAM_LEADER'}
                >
                  <Box>{TRANSLATE_ROLES['TEAM_LEADER']}</Box>
                </CheckBox>
              </Box>
              <Box ml={20}>
                <CheckBox
                  checked={!!roles?.find((e: string) => e === 'MANAGER_RIB')}
                  onChange={e =>
                    onChangeUserRole('MANAGER_RIB', e?.target?.checked)
                  }
                  isDisabled={roleDisabled}
                  mb={10}
                  key={'MANAGER_RIB'}
                  id={'MANAGER_RIB'}
                >
                  <Box>{TRANSLATE_ROLES['MANAGER_RIB']}</Box>
                </CheckBox>
              </Box>
              <CheckBox
                checked={!!roles?.find((e: string) => e === 'LEGAL_SQUAD')}
                onChange={e =>
                  onChangeUserRole('LEGAL_SQUAD', e?.target?.checked)
                }
                isDisabled={roleDisabled}
                mb={10}
                key={'LEGAL_SQUAD'}
                id={'LEGAL_SQUAD'}
              >
                <Box>{TRANSLATE_ROLES['LEGAL_SQUAD']}</Box>
              </CheckBox>
              <CheckBox
                checked={!!roles?.find((e: string) => e === 'SUPPORT')}
                onChange={e => onChangeUserRole('SUPPORT', e?.target?.checked)}
                isDisabled={roleDisabled}
                mb={10}
                key={'SUPPORT'}
                id={'SUPPORT'}
              >
                <Box>{TRANSLATE_ROLES['SUPPORT']}</Box>
              </CheckBox>
              <CheckBox
                checked={
                  !!roles?.find((e: string) => e === 'PORTAL_CLIENT_ADMIN')
                }
                onChange={e =>
                  onChangeUserRole('PORTAL_CLIENT_ADMIN', e?.target?.checked)
                }
                isDisabled={roleDisabled}
                mb={10}
                key={'PORTAL_CLIENT_ADMIN'}
                id={'PORTAL_CLIENT_ADMIN'}
              >
                <Box>{TRANSLATE_ROLES['PORTAL_CLIENT_ADMIN']}</Box>
              </CheckBox>
            </Box>
          </FormControl>
        </Box>
        <Flex>
          <Button type="submit" isLoading={loading}>
            Enregistrer
          </Button>
        </Flex>
      </Box>
    </form>
  );
};
