import { create } from 'zustand';
import { countDiffStates } from '@commons';
import { FlatUser } from '@freelancelabs/teoreme-commons';

enum filterType {
  DRAFT = 'draft',
  VALIDATED = 'validated',
  ARCHIVED = 'archived',
  ABORTED = 'aborted',
  TERMINATED = 'terminated',
}
enum sortType {
  ASC = 'asc',
  DESC = 'desc',
}
type MultiValueType = {
  label: string;
  value: string;
};
type State = {
  initialSate: boolean;
  filter: filterType;
  order?: sortType;
  sortedBy: string;
  searchQuery: string;
  startDate: Date | null;
  endDate: Date | null;
  managerSelected?: string;
  managerSelectedCompleteObject?: FlatUser;
  commercialSelected?: string;
  statusSelect?: string;
  statusBDCCustomerSelect?: string;
  typeSelected?: string;
  structureSelected?: string;
  clientSelected?: string;
  contactClientSelected?: string;
  providerSelected?: string;
  multiProviderSelected?: MultiValueType[];
  multiClientSelected?: MultiValueType[];
  contractorSelected?: string;
  billingTypeSelect?: string;
  missionType?: string;
  isMandateMode?: 1 | 0 | undefined;
  minCustomerBudget?: number;
  maxCustomerBudget?: number;
  allMissionsSelected: boolean;
  selectedMissionKeys: string[];
  unSelectedMissionKeys: string[];
  page: number;
  isOpen?: boolean;
  limit: number;
  updateStore: (state: Partial<State>) => void;
  resetStore: (state: Partial<State>) => void;
  getNbActiveFilters: (data: any) => number;
};
const initialStates = {
  initialSate: true,
  filter: filterType?.VALIDATED,
  order: sortType?.DESC,
  sortedBy: 'month',
  searchQuery: '',
  startDate: null,
  endDate: null,
  managerSelected: localStorage?.cognitoUserId,
  managerSelectedCompleteObject: undefined,
  commercialSelected: undefined,
  statusSelect: undefined,
  statusBDCCustomerSelect: undefined,
  typeSelected: undefined,
  structureSelected: undefined,
  clientSelected: undefined,
  contactClientSelected: undefined,
  providerSelected: undefined,
  multiProviderSelected: [],
  multiClientSelected: undefined,
  contractorSelected: undefined,
  billingTypeSelect: undefined,
  isMandateMode: undefined,
  minCustomerBudget: undefined,
  maxCustomerBudget: undefined,
  missionType: undefined,
  allMissionsSelected: false,
  selectedMissionKeys: [],
  unSelectedMissionKeys: [],
  page: 0,
  isOpen: undefined,
  limit: 5,
};

const store = (set: any, get: any) => ({
  ...initialStates,
  updateStore: (state: Partial<State>) => {
    set({
      initialSate: false,
      page: 0,
      ...state,
    });
  },
  resetStore: (state: Partial<State>) =>
    set({
      ...initialStates,
      ...state,
    }),
  getNbActiveFilters: (additionalFiltersVars: string[]) => {
    const currentStates = get();
    return countDiffStates(initialStates, currentStates, additionalFiltersVars);
  },
});

export const useGlobalListMissionStore = create<State>(store);
