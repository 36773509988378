import {
  buildContractorByScopedData,
  getFullName,
  getScopedDataMinified,
  contractorFindOne,
  establishmentFindOne,
  userFindOne,
  contractorUpdateOne,
  getEstablishmentName,
  missionCreateAmendment,
  removeOffsetDate,
  useSaleforceGetContractData,
} from '@commons';
import {
  IJoinedMission,
  IMinifiedUser,
  IJoinedContractor,
  EMissionStatus,
  EMissionType,
} from '@freelancelabs/teoreme-commons';
import { ContractorCard } from 'components/cards/ContractorCard';
import { EstablishmentStructuredCard } from 'components/cards/EstablishmentStructuredCard';
import { UserStructuredCard } from 'components/cards/UserStructuredCard';
import { showAddContactModal } from 'components/modals/AddContactModal';
import { showDialogModal } from 'components/modals/DialogModal';
import { showOnConfirmLinkContactModal } from 'components/modals/OnConfirmLinkContactModal';
import { showAddEstablishmentModal } from 'components/modals/AddEstablishmentModal';
import { Section } from 'components/Section';
import {
  BlocInformation,
  Box,
  Button,
  CheckSwitch,
  DatePickerControlled,
  EstablishmentSelectControlled,
  Flex,
  FormControl,
  Input,
  LabelField,
  Link,
  Text,
  UserSelectControlled,
} from 'components/ui';
import { AddIcon, CancelIcon } from 'components/ui/icons';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { queryClient } from '@commons';
import { useHistory } from 'react-router-dom';
import { addDays } from 'date-fns';

type FormValues = IJoinedMission;
type CreateMissionAmendmentFormProps = {
  beforeValidation?: () => void;
  mission: IJoinedMission;
  onClose: (data: any) => void;
  lastAmendment: IJoinedMission | undefined;
};

export const CreateMissionAmendmentForm: React.FC<
  React.PropsWithChildren<CreateMissionAmendmentFormProps>
> = ({ onClose, mission, lastAmendment }) => {
  const needCheckSfContract =
    mission &&
    mission?.createdFromSFFlow &&
    mission?.billingInformation?.missionType?.label === EMissionType.CLASSIC
      ? true
      : false;
  const { data: sfContractData } = useSaleforceGetContractData(
    needCheckSfContract === true ? mission?.salesforce?.externalId : undefined,
    { enabled: needCheckSfContract }
  );
  const [loading, setLoading] = useState(false);
  const [newEsta, setNewEsta] = useState(false);
  const customerPO = mission?.customer?.purchaseOrder;
  const [sfMaxEndDate, setSfMaxEndDate] = React.useState<string | undefined>();
  const {
    control,
    watch,
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {
      startAt: lastAmendment
        ? //@ts-ignore
          addDays(new Date(lastAmendment?.endAt), 1)
        : //@ts-ignore
          addDays(new Date(mission?.endAt), 1),
      title: lastAmendment ? lastAmendment?.title : mission?.title,
      provider: lastAmendment ? lastAmendment?.provider : mission?.provider,
    },
  });
  const startAt = watch('startAt');
  const endAt = watch('endAt');
  const minStartDate = lastAmendment
    ? //@ts-ignore
      addDays(new Date(lastAmendment?.startAt), 1)
    : //@ts-ignore
      addDays(new Date(mission?.startAt), 1);
  const scopedData = getScopedDataMinified(
    mission?.provider?.contractor?.scopedData,
    mission?.provider?.establishment.uuid
  );
  const contractorBuild = buildContractorByScopedData(
    mission?.provider?.contractor,
    scopedData
  );
  const history = useHistory();
  // returns -1 if no attachment // 0 if only establishment is attached // 1 if both are attached
  const checkAttachment = (
    contractor: IJoinedContractor | null,
    establishmentId: string,
    contactId: string
  ) => {
    let hasEstablishment,
      hasContact = false;
    let attachedContact: Partial<IMinifiedUser> = {};
    if (contractor && contractor.scopedData) {
      contractor.scopedData.forEach(data => {
        if (data.establishment.uuid === establishmentId) {
          hasEstablishment = true;
          attachedContact = data.contact;
          if (data.contact.cognitoUserId === contactId) {
            hasContact = true;
          }
        }
      });
    }

    return {
      attachment:
        hasEstablishment && hasContact ? 1 : hasEstablishment ? 0 : -1,
      attachedContact,
    };
  };
  const onSubmit = async (formValues: any) => {
    setLoading(true);
    try {
      // this data is loaded when contractor is selected
      const selectedContractor = await contractorFindOne(
        mission?.provider?.contractor?.uuid
      );
      const establishment = await establishmentFindOne(
        formValues?.provider?.establishment?.uuid ||
          mission?.provider?.establishment?.uuid,
        'uuid'
      );
      const contact = await userFindOne(
        formValues?.provider?.contact?.cognitoUserId ||
          mission?.provider.contact?.cognitoUserId
      );
      const { attachment, attachedContact } = checkAttachment(
        selectedContractor,
        establishment?.uuid,
        contact?.cognitoUserId
      );
      const contractorLinkEstaParentSelected =
        selectedContractor.scopedData.findIndex(
          esta => esta.establishment.siren === establishment.siren
        );
      const socialStatusParent =
        contractorLinkEstaParentSelected !== -1
          ? selectedContractor.scopedData[contractorLinkEstaParentSelected]
              .socialStatus
          : undefined;
      if (attachment === 1) {
        const newAvenant = await missionCreateAmendment({
          parentMission: mission?.parent
            ? mission?.parent?.reference
            : mission?.reference,
          title: formValues?.title,
          startAt: removeOffsetDate(formValues?.startAt),
          endAt: removeOffsetDate(formValues?.endAt),
          provider: {
            establishment: establishment?.uuid,
            contact: contact?.cognitoUserId,
            //contractor: selectedContractor?.uuid,
          },
          copyCustomerPo: formValues?.copyCustomerPo,
        });
        queryClient.refetchQueries({ queryKey: ['missions'], type: 'active' });
        if (newAvenant) {
          history.push(
            `/delivery/missions/${newAvenant?.reference}/information`
          );
          onClose(newAvenant);
        }
      }
      if (attachment === 0) {
        showDialogModal({
          title: `Êtes-vous sûr(e) de vouloir mettre à jour le contact fournisseur de l'intervenant?`,
          text: `L'intervenant est déjà rattaché à un autre contact fournisseur pour cet établissement :
            ${attachedContact.firstName + ' ' || ''}
            ${attachedContact.lastName + ' ' || ''}
            ${attachedContact.email + ' ' || ''}.
            Pour pouvoir continuer, veuillez mettre à jour le contact fournisseur de cet établissement.`,
          confirmLabel: 'Mettre à jour',
          cancelLabel: 'Annuler',

          beforeValidation: async params => {
            await contractorUpdateOne({
              uuid: selectedContractor?.uuid,
              scopedData: [
                {
                  establishment: establishment?.uuid,
                  contact: contact?.cognitoUserId,
                  socialStatus: socialStatusParent,
                },
              ],
              contractor: {},
            });
            const newAvenant = await missionCreateAmendment({
              parentMission: mission?.reference,
              title: formValues?.title,
              startAt: removeOffsetDate(formValues?.startAt),
              endAt: removeOffsetDate(formValues?.endAt),
              provider: {
                establishment: establishment?.uuid,
                contact: contact?.cognitoUserId,
                //contractor: selectedContractor?.uuid,
              },
              copyCustomerPo: formValues?.copyCustomerPo,
            });
            queryClient.refetchQueries({
              queryKey: ['missions'],
              type: 'active',
            });
            if (newAvenant) {
              history.push(
                `/delivery/missions/${newAvenant?.reference}/information`
              );
              onClose(newAvenant);
            }
          },
        });
      }
      if (attachment === -1) {
        showOnConfirmLinkContactModal({
          title: `Êtes-vous sûr(e) de vouloir rattacher l'établissement et l'intervenant?`,
          text: `L'établissement, le contact fournisseur et l’intervenant que vous avez sélectionnés ne sont pas rattachés. Pour pouvoir continuer, veuillez vérifier ou préciser le statut social de l’intervenant puis valider le rattachement.
            ${
              contractorLinkEstaParentSelected !== -1
                ? 'A noter : nous avons détecté que cet intervenant était déjà rattaché à un ou plusieurs autres établissements de la même entreprise fournisseur :'
                : ''
            }
            ${
              contractorLinkEstaParentSelected !== -1
                ? // eslint-disable-next-line array-callback-return
                  selectedContractor.scopedData.map(esta => {
                    if (esta.establishment.siren === establishment.siren) {
                      return (
                        getEstablishmentName(esta.establishment) +
                        '-' +
                        esta.establishment.siret +
                        ' '
                      );
                    }
                  })
                : ''
            }  `,
          confirmLabel: 'Rattacher',
          cancelLabel: 'Annuler',
          defaultSocialStatus: socialStatusParent,

          beforeValidation: async params => {
            try {
              await contractorUpdateOne({
                uuid: selectedContractor?.uuid,
                scopedData: [
                  {
                    establishment: establishment?.uuid,
                    contact: contact?.cognitoUserId,
                    socialStatus: params,
                  },
                ],
                contractor: {},
              });
              const newAvenant = await missionCreateAmendment({
                parentMission: mission?.reference,
                title: formValues?.title,
                startAt: removeOffsetDate(formValues?.startAt),
                endAt: removeOffsetDate(formValues?.endAt),
                provider: {
                  establishment: establishment?.uuid,
                  contact: contact?.cognitoUserId,
                  //contractor: selectedContractor?.uuid,
                },
                copyCustomerPo: formValues?.copyCustomerPo,
              });
              queryClient.refetchQueries({
                queryKey: ['missions'],
                type: 'active',
              });
              if (newAvenant) {
                history.push(
                  `/delivery/missions/${newAvenant?.reference}/information`
                );
                onClose(newAvenant);
              }
            } catch (e) {
              setLoading(false);
            }
          },
        });
      }
    } catch (e) {
      setLoading(false);
    }
    setLoading(false);
  };
  const checkSFcontractData = async () => {
    try {
      const endDate = sfContractData?.endDate;
      setSfMaxEndDate(new Date(endDate as Date)?.toISOString());
    } catch (e) {
      //
    }
  };

  // SALEFORCE CHECK MAX DATE https://fcomdev.atlassian.net/browse/TEOR-5206
  React.useEffect(() => {
    if (
      mission &&
      mission?.createdFromSFFlow &&
      mission?.billingInformation?.missionType?.label === EMissionType.CLASSIC
    ) {
      checkSFcontractData();
    }
  }, [mission, sfContractData]);
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Flex alignContent="space-between">
        <FormControl
          p={10}
          required
          label="Intitulé de la mission"
          errorMessage={
            // @ts-ignore
            errors?.title?.type === 'maxLength'
              ? 'Veuillez saisir moins de 36 caractères'
              : // @ts-ignore
                errors?.title?.message
          }
          warningMessage={
            'Cet intitulé n’apparaîtra pas dans vos devis et BDC Fournisseur.'
          }
        >
          <Input isFullWidth {...register('title', { maxLength: 35 })} />
        </FormControl>
        <FormControl
          p={10}
          required
          label="Date de début"
          errorMessage={errors?.startAt?.message}
        >
          <DatePickerControlled
            control={control}
            maxDate={endAt}
            minDate={minStartDate}
            name="startAt"
            rules={{ required: 'Ce champs est requis' }}
            valueName="selected"
          />
        </FormControl>
        <FormControl
          p={10}
          required
          label="Date de fin"
          errorMessage={errors?.endAt?.message}
        >
          <DatePickerControlled
            control={control}
            valueName="selected"
            minDate={startAt}
            maxDate={sfMaxEndDate ? new Date(sfMaxEndDate) : undefined}
            name="endAt"
            rules={{ required: 'Ce champs est requis' }}
          />
        </FormControl>
      </Flex>
      <BlocInformation>
        <p>
          Si la date de début de l’avenant est antérieure à la date de fin de la
          mission initiale, celle-ci se terminera à la date de début de
          l’avenant.
        </p>
      </BlocInformation>
      <Section
        mt={20}
        variant="h2"
        title={
          <Text variant="h3">
            Informations du fournisseur{' '}
            {!newEsta && (
              <Link ml={'5px'} onClick={() => setNewEsta(true)}>
                Modifier
              </Link>
            )}
          </Text>
        }
      >
        {!newEsta ? (
          <Box>
            <LabelField label="Établissement fournisseur" value="" />
            <EstablishmentStructuredCard
              forRole={'PROVIDER'}
              checkSage
              establishment={
                lastAmendment
                  ? lastAmendment?.provider?.establishment
                  : mission?.provider?.establishment
              }
              mb={20}
            />
            <Box>
              <LabelField label="Contact fournisseur" value="" />
              <UserStructuredCard
                mb={20}
                isFullWidth
                user={
                  lastAmendment
                    ? lastAmendment?.provider?.contact
                    : mission?.provider?.contact
                }
                establishment={
                  lastAmendment
                    ? lastAmendment?.provider?.establishment
                    : mission?.provider?.establishment
                }
                forRole={'PROVIDER'}
                checkSage
              />
            </Box>
            <Box>
              <LabelField label="Intervenant" value="" />
              <ContractorCard contractor={contractorBuild} />
            </Box>
          </Box>
        ) : (
          <Box mb={30}>
            <FormControl label="Établissement Fournisseur">
              <Box>
                <EstablishmentSelectControlled
                  control={control}
                  name="provider.establishment.uuid"
                  filter={{ 'provider.manager': { $exists: true } }}
                  width="100%"
                  referenceValue="uuid"
                  placeholder="Rechercher un N° SIRET, raison sociale ..."
                />
                <Link
                  iconLeft={<AddIcon />}
                  mt={10}
                  onClick={() =>
                    showAddEstablishmentModal({
                      establishmentRole: 'PROVIDER',
                    }).then(e => {
                      e.uuid && setValue('provider.establishment.uuid', e.uuid);
                    })
                  }
                >
                  Ajouter un établissement fournisseur
                </Link>
              </Box>
            </FormControl>
            <FormControl label="Contact Fournisseur">
              <Box>
                <UserSelectControlled
                  control={control}
                  name="provider.contact.cognitoUserId"
                  role={'PROVIDER_CONTACT'}
                  width="100%"
                  placeholder="Rechercher un nom, prénom, email …"
                />
                <Link
                  iconLeft={<AddIcon />}
                  mt={10}
                  onClick={() =>
                    showAddContactModal({
                      contactRole: 'PROVIDER_CONTACT',
                    }).then((c: IMinifiedUser) => {
                      c.cognitoUserId &&
                        setValue(
                          'provider.contact.cognitoUserId',
                          c.cognitoUserId
                        );
                    })
                  }
                >
                  Ajouter un contact fournisseur
                </Link>
              </Box>
            </FormControl>
            <FormControl label="Intervenant">
              <Box>
                <Input
                  isFullWidth
                  isDisabled
                  value={getFullName(mission?.provider?.contractor)}
                />
              </Box>
            </FormControl>
          </Box>
        )}
      </Section>
      {customerPO && (
        <Section
          mt={20}
          variant="h2"
          title={<Text variant="h3">Récupération des documents</Text>}
        >
          <Flex
            justifyContent={'flex-start'}
            alignContent={'center'}
            alignItems={'center'}
          >
            <CheckSwitch id="copyCustomerPo" {...register('copyCustomerPo')}>
              Conserver le bon de commande client
            </CheckSwitch>
          </Flex>
        </Section>
      )}
      <Flex p={10} alignItems="center">
        <Button key="submit" type="submit" isLoading={loading}>
          Valider
        </Button>
        <Button onClick={() => onClose(false)} ml={10}>
          Annuler
        </Button>
      </Flex>
    </form>
  );
};
