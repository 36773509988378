import { IJoinedMission } from '@freelancelabs/teoreme-commons';
import { ModalFrame, ModalProps } from 'components/modals/ModalFrame';
import {
  Button,
  Text,
  Box,
  FormControl,
  TextAreaControlled,
} from 'components/ui';
import React from 'react';
import { useForm } from 'react-hook-form';
import { create } from 'react-modal-promise';
import { missionUpdateOne, stripHtmlTags } from '@commons';
import { queryClient } from '@commons';
type Props = ModalProps & {
  beforeValidation?: () => void;
  mission: IJoinedMission;
};
type FormValues = {
  customInvoicesDescription: string;
};

export const UpdateCustomMissionDescription = ({
  onResolve,
  isOpen,
  mission,
}: Props) => {
  const [loading, setLoading] = React.useState(false);
  const strMissionDescription = stripHtmlTags(mission?.description);
  const { handleSubmit, control } = useForm<FormValues>({
    defaultValues: {
      customInvoicesDescription:
        mission?.customer?.customInvoicesDescription || strMissionDescription,
    },
  });
  const onSubmit = async (formData: FormValues) => {
    setLoading(true);
    try {
      await missionUpdateOne({
        reference: mission?.reference || '',
        mission: {
          customer: {
            customInvoicesDescription: formData?.customInvoicesDescription,
          },
        },
      });
      queryClient.refetchQueries({ queryKey: [mission?.reference] });
    } catch (e) {
      setLoading(false);
      onResolve(false);
    }
    onResolve(formData?.customInvoicesDescription);
    setLoading(false);
  };

  return (
    <ModalFrame
      isOpen={isOpen}
      onClose={() => onResolve(false)}
      width={670}
      closeIcon
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box>
          <Text variant="h3" mb={20}>
            Modifier la description de la prestation
          </Text>
          <Box>
            <FormControl label="Description de la prestation ( Affichée sur la facture client )">
              <TextAreaControlled
                control={control}
                name="customInvoicesDescription"
                defaultValue={strMissionDescription}
                minRows={3}
              />
            </FormControl>
          </Box>
          <Button key="submit" type="submit" isLoading={loading}>
            Enregistrer
          </Button>
        </Box>
      </form>
    </ModalFrame>
  );
};

export const showUpdateCustomMissionDescription = create<Props>(
  UpdateCustomMissionDescription
);
