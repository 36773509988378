import styled from 'styled-components';
import { boxProperties, BoxProps } from '../Box';

type SquareProps = {
  size?: number;
};
export const Square = styled.div<SquareProps & BoxProps>`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-shrink: 0;
  flex-wrap: wrap;
  width: ${p => p.size}px;
  height: ${p => p.size}px;
  background: #b3c6d3;
  border-radius: 5px;

  span {
    color: white !important;
    font-weight: 400;
    font-size: 20px;
  }

  ${boxProperties}
`;
