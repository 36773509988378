import React from 'react';
import { Text, Form, Button, Box, Flex, Link, SpinnerBox } from 'components/ui';
import { create } from 'react-modal-promise';
import { ModalFrame, ModalProps } from '../ModalFrame';
import { useShowMessage } from 'hooks/useShowMessage';
import { saleforceRestartOppCreation } from '@commons';
import { IJoinedMission } from '@freelancelabs/teoreme-commons';
import { CloseIcon } from 'components/ui/icons';
import { useGlobalStore } from 'store/global';
const RELOADING_TIME = 1000 * 60 * 1; // 1 minute
type SyncSaleforceMissionModalProps = ModalProps & {
  beforeValidation?: () => void;
  mission: IJoinedMission;
};

export const SyncSaleforceMissionModal: React.FC<
  React.PropsWithChildren<SyncSaleforceMissionModalProps>
> = ({ onResolve, isOpen, mission }) => {
  const {
    tasksInProgress = [],
    updateGlobalStore,
    resetGlobalStore,
  } = useGlobalStore();

  const [isLoading, setIsLoading] = React.useState(false);
  const showMessage = useShowMessage();

  return (
    <ModalFrame
      isOpen={isOpen}
      onClose={() => onResolve(false)}
      width={900}
      closeIcon
    >
      <Form mb={20} autoComplete="off" data-cy="mission-form-sync-saleforce">
        <Text variant="h1" mb={20}>
          Synchroniser la mission avec Salesforce
        </Text>

        <Box>
          <Text variant="h2">
            Voulez-vous vraiment actualiser les données de cette mission depuis
            Salesforce ?
          </Text>
        </Box>

        {isLoading && (
          <Box>
            <Text variant="p">
              La synchronisation est en cours, et peut prendre plus d’une
              minute. Elle continuera en arrière-plan même si vous quittez la
              page.
            </Text>
            <SpinnerBox />
          </Box>
        )}

        <Box>
          <Flex mt={40} width={'250px'} justifyContent="space-between">
            <Button
              isLoading={isLoading}
              isDisabled={isLoading}
              onClick={async () => {
                if (isLoading) return;
                const taskReference = 'refresh-salesforce' + mission?.reference;

                try {
                  setIsLoading(true);
                  await saleforceRestartOppCreation({
                    // @ts-ignore
                    missionId: mission?.reference,
                  });
                  updateGlobalStore({
                    tasksInProgress: [...tasksInProgress, taskReference],
                  });
                  setTimeout(() => {
                    resetGlobalStore({
                      tasksInProgress: tasksInProgress?.filter(
                        (task: any) => task !== taskReference
                      ),
                    });
                    setIsLoading(false);
                    window.location.reload();
                  }, RELOADING_TIME);
                } catch (error) {
                  setIsLoading(false);
                  onResolve(false);
                }
              }}
              type="button"
            >
              Confirmer
            </Button>
            <Button
              // iconLeft={<CloseIcon />}
              isDisabled={isLoading}
              onClick={() => {
                if (isLoading) return;
                onResolve(false);
              }}
            >
              Annuler
            </Button>
          </Flex>
        </Box>
      </Form>
    </ModalFrame>
  );
};

export const showSyncSaleforceMissionModal =
  create<SyncSaleforceMissionModalProps>(SyncSaleforceMissionModal);
