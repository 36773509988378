import React, { useState } from 'react';
import {
  PROVIDER_FORMULA_TAG_WHITELIST,
  CUSTOMER_FORMULA_TAG_WHITELIST,
  MIXED_TAG_INPUT_SETTING,
  transformTagToFormula,
  transformFormulaToTag,
  useEstablishmentUpdateOne,
  removeOffsetDate,
  queryClient,
  missionUpdateOne,
  STAND_BY_DUTY_UNIT_SELECT,
  setDateTimeToZero,
} from '@commons';
import {
  Row,
  Flex,
  Box,
  Button,
  FormControl,
  Input,
  Text,
  TextAreaControlled,
  CheckSwitch,
  DatePickerControlled,
  StaticSelectControlled,
  LabelField,
} from 'components/ui';
import { MixedModeTagify } from 'components/InputTags';
import { useForm } from 'react-hook-form';
import {
  ICompanyGetResponse,
  IEstablishment,
  IJoinedMission,
  EStandByDutyFormulaVars,
} from '@freelancelabs/teoreme-commons';
import { useShowMessage } from 'hooks/useShowMessage';
import { evaluate } from 'mathjs';

type FormValues = {
  name: string;
  //margin: number;
  description: string;
  providerFormula: string;
  customerFormula: string;
  shouldJoinAttachment: boolean;
  unit: any; // TODO CHANGE THIS
  validityStart: Date;
  validityEnd: Date;
};
type CreateOrUpdateStandByDutyFormProps = {
  standByDuty: any;
  establishment?: ICompanyGetResponse | IEstablishment;
  mission?: IJoinedMission;
  onResolve: (value?: any) => void;
  from: 'ESTABLISHMENT' | 'MISSION';
};
const FORMULA_PARAMETERS_PREVIEW = {
  contractorRate: '50.5',
  clientRate: '100.5',
  missionMargin: '20.5',
  input: '10.5',
};
export const CreateOrUpdateStandByDutyForm = ({
  standByDuty,
  establishment,
  onResolve,
  from,
  mission,
}: CreateOrUpdateStandByDutyFormProps) => {
  const showMessage = useShowMessage();
  const [previewParameters, setPreviewParameters] = useState(
    FORMULA_PARAMETERS_PREVIEW
  );
  const [loading, setLoading] = useState(false);
  const [providerFormula, setProviderFormula] = useState(
    standByDuty
      ? transformFormulaToTag(
          standByDuty?.providerFormula,
          PROVIDER_FORMULA_TAG_WHITELIST
        ) + ' '
      : ''
  );
  const [customerFormula, setCustomerFormula] = useState(
    standByDuty
      ? transformFormulaToTag(
          standByDuty?.customerFormula,
          CUSTOMER_FORMULA_TAG_WHITELIST
        ) + ' '
      : ''
  );
  const { mutateAsync: updateEstablishment } = useEstablishmentUpdateOne();

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm<FormValues>({
    defaultValues: {
      ...standByDuty,
      validityEnd: standByDuty?.validityEnd
        ? setDateTimeToZero(standByDuty?.validityEnd)
        : undefined,
    },
  });

  const onSubmit = async (formValues: FormValues) => {
    setLoading(true);
    try {
      if (from === 'ESTABLISHMENT') {
        await updateEstablishment({
          uuid: establishment?.uuid || '',
          //@ts-ignore
          addStandByDutyFormulas: !standByDuty
            ? [
                {
                  active: true,
                  ...formValues,
                  validityStart: removeOffsetDate(formValues?.validityStart),
                  validityEnd: removeOffsetDate(formValues?.validityEnd),
                },
              ]
            : undefined,
          //@ts-ignore
          updateStandByDutyFormulas: standByDuty
            ? [
                {
                  ...formValues,
                  validityStart: removeOffsetDate(formValues?.validityStart),
                  validityEnd: removeOffsetDate(formValues?.validityEnd),
                },
              ]
            : undefined,
        });
        queryClient.refetchQueries({ queryKey: [establishment?.uuid] });
      }
      if (from === 'MISSION') {
        await missionUpdateOne({
          reference: mission?.reference as string,
          mission: {
            //@ts-ignore
            addStandByDutyFormulas: !standByDuty
              ? [
                  {
                    active: true,
                    //margin: 0.2, // TODO FORCE DELETE THIS AFTER !!
                    ...formValues,
                    validityStart: removeOffsetDate(formValues?.validityStart),
                    validityEnd: removeOffsetDate(formValues?.validityEnd),
                  },
                ]
              : undefined,
            //@ts-ignore
            updateStandByDutyFormulas: standByDuty
              ? [
                  {
                    ...formValues,
                    // margin: 0.2, // TODO FORCE DELETE THIS AFTER !!
                    validityStart: removeOffsetDate(formValues?.validityStart),
                    validityEnd: removeOffsetDate(formValues?.validityEnd),
                  },
                ]
              : undefined,
          },
        });
        queryClient.refetchQueries({ queryKey: [mission?.reference] });
      }
      showMessage(
        'success',
        `Votre formule à été ${standByDuty ? 'mise à jour' : 'créée'}`
      );

      onResolve(formValues);
    } catch (e) {
      //
    }

    setLoading(false);
  };
  const onChangeProviderFormula = (value: string) => {
    setProviderFormula(value);
    setValue('providerFormula', transformTagToFormula(value));
  };
  const onChangeCustomerFormula = (value: string) => {
    setCustomerFormula(value);
    setValue('customerFormula', transformTagToFormula(value));
  };
  const checkIsValideProviderFormula = () => {
    if (providerFormula && providerFormula?.length > 0) {
      const contractorRate = previewParameters.contractorRate;
      const clientRate = previewParameters.clientRate;
      const missionMargin = previewParameters.missionMargin;
      const input = previewParameters.input;
      const formulaJS = transformTagToFormula(providerFormula);
      let rp = formulaJS;
      rp = rp?.replaceAll(
        `{{${EStandByDutyFormulaVars?.PROVIDER_RATE}}}`,
        contractorRate
      );
      rp = rp?.replaceAll(
        `{{${EStandByDutyFormulaVars?.CUSTOMER_RATE}}}`,
        clientRate
      );
      rp = rp?.replaceAll(
        `{{${EStandByDutyFormulaVars?.I_RATE}}}`,
        missionMargin
      );
      rp = rp?.replaceAll(`{{${EStandByDutyFormulaVars?.INPUT}}}`, input);
      if (formulaJS?.trim() === '{{input}}') {
        return {
          isValid: true,
          test: 'Formule = saisie',
        };
      }
      try {
        const result = evaluate(rp);
        const strCalc = `${rp} = ${result?.entries?.[0] || result}`;

        if (!result) {
          throw new Error();
        }
        return {
          isValid: true,
          test: (
            <Box top={-40}>
              <Text variant="h3">Résultat de la formule fournisseur</Text>
              <Flex width={1 / 1}>
                <LabelField
                  mr={10}
                  label="TJM fournisseur"
                  value={previewParameters.contractorRate}
                />
                <LabelField
                  mr={10}
                  label="TJM client"
                  value={previewParameters.clientRate}
                />
                <LabelField
                  mr={10}
                  label="SAISIE"
                  value={previewParameters.input}
                />
                <LabelField
                  mr={10}
                  label="Taux de marque mission"
                  value={previewParameters.missionMargin}
                />
              </Flex>
              <Text variant="b">{strCalc}</Text>
            </Box>
          ),
        };
      } catch (e) {
        console.warn('Formule invalide :', e);
        return {
          isValid: false,
          test: (
            <Box top={-40}>
              <Text variant="h3">Résultat de la formule fournisseur</Text>
              <Flex width={1 / 1}>
                <LabelField
                  mr={10}
                  label="TJM fournisseur"
                  value={previewParameters.contractorRate}
                />
                <LabelField
                  mr={10}
                  label="TJM client"
                  value={previewParameters.clientRate}
                />
                <LabelField
                  mr={10}
                  label="SAISIE"
                  value={previewParameters.input}
                />
                <LabelField
                  mr={10}
                  label="Taux de marque mission"
                  value={previewParameters.missionMargin}
                />
              </Flex>
              <Text color="red" variant="b">
                Formule invalide
              </Text>
            </Box>
          ),
        };
      }
    }
  };
  const checkIsValideCustomerFormula = () => {
    if (customerFormula && customerFormula?.length > 0) {
      const contractorRate = previewParameters.contractorRate;
      const clientRate = previewParameters.clientRate;
      const missionMargin = previewParameters.missionMargin;
      const input = previewParameters.input;
      const formulaJS = transformTagToFormula(customerFormula);
      let rp = formulaJS;
      rp = rp?.replaceAll(
        `{{${EStandByDutyFormulaVars?.PROVIDER_RATE}}}`,
        contractorRate
      );
      //@ts-ignore
      rp = rp?.replaceAll(
        `{{${EStandByDutyFormulaVars?.CUSTOMER_RATE}}}`,
        clientRate
      );
      rp = rp?.replaceAll(
        `{{${EStandByDutyFormulaVars?.I_RATE}}}`,
        missionMargin
      );
      rp = rp?.replaceAll(`{{${EStandByDutyFormulaVars?.INPUT}}}`, input);
      if (formulaJS?.trim() === '{{input}}') {
        return {
          isValid: true,
          test: 'Formule = saisie',
        };
      }
      try {
        const result = evaluate(rp);
        const strCalc = `${rp} = ${result?.entries?.[0] || result}`;
        if (!result) {
          throw new Error();
        }
        // console.log('function', Function(rp));
        // const strCalc = '';

        return {
          isValid: true,
          test: (
            <Box top={-40}>
              <Text variant="h3">Résultat de la formule client</Text>
              <Flex width={1 / 1}>
                <LabelField
                  mr={10}
                  label="TJM fournisseur"
                  value={previewParameters.contractorRate}
                />
                <LabelField
                  mr={10}
                  label="TJM client"
                  value={previewParameters.clientRate}
                />
                <LabelField
                  mr={10}
                  label="SAISIE"
                  value={previewParameters.input}
                />
                <LabelField
                  mr={10}
                  label="Taux de marque mission"
                  value={previewParameters.missionMargin}
                />
              </Flex>
              <Text variant="b">{strCalc}</Text>
            </Box>
          ),
        };
      } catch (e) {
        console.warn('Formule invalide :', e);
        return {
          isValid: false,
          test: (
            <Box top={-40}>
              <Text variant="h3">Résultat de la formule client</Text>
              <Flex width={1 / 1}>
                <LabelField
                  mr={10}
                  label="TJM fournisseur"
                  value={previewParameters.contractorRate}
                />
                <LabelField
                  mr={10}
                  label="TJM client"
                  value={previewParameters.clientRate}
                />
                <LabelField
                  mr={10}
                  label="SAISIE"
                  value={previewParameters.input}
                />
                <LabelField
                  mr={10}
                  label="Taux de marque mission"
                  value={previewParameters.missionMargin}
                />
              </Flex>
              <Text color="red" variant="b">
                Formule invalide
              </Text>
            </Box>
          ),
        };
      }
    }
  };
  const resultProviderFormula = checkIsValideProviderFormula();
  const resultCustomerFormula = checkIsValideCustomerFormula();
  return (
    <Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Row spacing={20} width={2 / 3} maxWidth={'450px'} mt={20}>
          <FormControl
            errorMessage={errors?.validityStart?.message}
            label="À partir du"
            required
          >
            <DatePickerControlled
              control={control}
              name="validityStart"
              rules={{ required: 'Ce champs est requis' }}
              valueName="selected"
              dateFormat="MM/yyyy"
              showMonthYearPicker
              showFullMonthYearPicker
            />
          </FormControl>
          <FormControl
            errorMessage={errors?.validityEnd?.message}
            label="Jusqu'au"
            required
          >
            <DatePickerControlled
              name="validityEnd"
              control={control}
              valueName="selected"
              dateFormat="MM/yyyy"
              showMonthYearPicker
              showFullMonthYearPicker
              rules={{ required: 'Ce champs est requis' }}
            />
          </FormControl>
        </Row>
        <Flex>
          <Box width={4 / 4}>
            <FormControl
              label="Intitulé de la prestation complémentaire"
              required
              errorMessage={errors?.name?.message}
            >
              <Input
                isFullWidth
                {...register('name', { required: 'Ce champ est requis' })}
              />
            </FormControl>
          </Box>
        </Flex>
        <Box>
          <FormControl
            label="Description"
            required
            errorMessage={errors?.description?.message}
          >
            <TextAreaControlled
              control={control}
              name="description"
              defaultValue=""
              minRows={3}
            />
          </FormControl>
        </Box>
        <Text variant="h3">Résultat des formules</Text>
        <Text variant="p">
          Vous pouvez ici modifier les valeurs pour visualiser le résultat de
          vos formules.
          <br />
          <b>
            Veuillez utiliser des points et non des virgules pour les nombres
            décimaux.
          </b>
        </Text>
        <Flex mb={'5px'}>
          <FormControl label="TJM Fournisseur" p={10}>
            <Input
              value={previewParameters?.contractorRate}
              onChange={(e: any) =>
                setPreviewParameters({
                  ...previewParameters,
                  contractorRate: e?.target?.value,
                })
              }
            />
          </FormControl>
          <FormControl label="TJM client" p={10}>
            <Input
              value={previewParameters?.clientRate}
              onChange={(e: any) =>
                setPreviewParameters({
                  ...previewParameters,
                  clientRate: e?.target?.value,
                })
              }
            />
          </FormControl>
          <FormControl label="Taux de marque mission" p={10}>
            <Input
              value={previewParameters?.missionMargin}
              onChange={(e: any) =>
                setPreviewParameters({
                  ...previewParameters,
                  missionMargin: e?.target?.value,
                })
              }
            />
          </FormControl>
          <FormControl label="Saisie" p={10}>
            <Input
              value={previewParameters?.input}
              onChange={(e: any) =>
                setPreviewParameters({
                  ...previewParameters,
                  input: e?.target?.value,
                })
              }
            />
          </FormControl>
        </Flex>
        <Flex>
          <Box width={2 / 3} pr={'5px'}>
            <FormControl label="Formule de calcul fournisseur">
              <MixedModeTagify
                placeholder="Ecrivez votre formule (@ pour utiliser les variables)"
                settings={{
                  ...MIXED_TAG_INPUT_SETTING,
                  whitelist: PROVIDER_FORMULA_TAG_WHITELIST,
                }}
                onChange={e => onChangeProviderFormula(e)}
                value={providerFormula}
              />
            </FormControl>
          </Box>
          <Box width={1 / 3} pl={'5px'}>
            <FormControl label="Unité" errorMessage={errors?.unit?.message}>
              <StaticSelectControlled
                control={control}
                name="unit"
                rules={{ required: 'Ce champs est requis' }}
                options={STAND_BY_DUTY_UNIT_SELECT}
              />
            </FormControl>
          </Box>
        </Flex>
        <Box width={1 / 1}>{resultProviderFormula?.test}</Box>
        <Flex>
          <Box width={2 / 3} pr={'5px'}>
            <FormControl label="Formule de calcul client">
              <MixedModeTagify
                placeholder="Ecrivez votre formule (@ pour utiliser les variables)"
                settings={{
                  ...MIXED_TAG_INPUT_SETTING,
                  whitelist: CUSTOMER_FORMULA_TAG_WHITELIST,
                }}
                onChange={e => onChangeCustomerFormula(e)}
                value={customerFormula}
              />
            </FormControl>
          </Box>
        </Flex>
        <Box width={1 / 1}>{resultCustomerFormula?.test}</Box>
        <FormControl label="">
          <CheckSwitch
            {...register('shouldJoinAttachment')}
            onChange={e => {
              setValue('shouldJoinAttachment', e.target.checked);
              return e.target.checked;
            }}
            id="shouldJoinAttachment"
          >
            <Text>Justificatif requis</Text>
          </CheckSwitch>
        </FormControl>
        <Flex justifyContent={'flex-end'}>
          <Button
            isLoading={loading}
            isDisabled={
              !resultCustomerFormula?.isValid || !resultProviderFormula?.isValid
            }
            type="submit"
          >
            {standByDuty ? 'Modifier' : 'Valider'}
          </Button>
        </Flex>
      </form>
    </Box>
  );
};
