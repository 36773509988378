import { Box, Text, SpinnerBox } from 'components/ui';
import { missionFindMany, saleforceGetContractData } from '@commons';
import React, { useState } from 'react';
import { create } from 'react-modal-promise';
import { ModalFrame, ModalProps } from '../ModalFrame';
import { CreateMissionAmendmentForm } from 'forms/missions/CreateMissionAmendmentForm';
import {
  IJoinedMission,
  EMissionStatus,
  EMissionType,
} from '@freelancelabs/teoreme-commons';
// type FormValues = {
//   startAt?: Date;
//   endAt?: Date;
//   title?: string;
//   provider: {
//     establishment: String;
//   };
// };
type CreateMissionAmendmentProps = ModalProps & {
  beforeValidation?: () => void;
  mission: IJoinedMission;
};

export const CreateMissionAmendmentModal: React.FC<
  React.PropsWithChildren<CreateMissionAmendmentProps>
> = ({ onResolve, isOpen, mission }) => {
  const [loading, setLoading] = useState(false);
  const [lastAmendment, setLastAmendment] = useState<any>(false);
  const findAmendment = async () => {
    setLoading(true);
    const last = await missionFindMany({
      filterObject: {
        parent: mission?.parent
          ? mission?.parent?.reference
          : mission?.reference,
        status: {
          $in: [
            EMissionStatus?.DRAFT,
            EMissionStatus?.VALIDATED,
            EMissionStatus?.TERMINATED,
            EMissionStatus?.ARCHIVED,
          ],
        },
      },
      sort: '-startAt',
      limit: 1,
    });
    setLastAmendment(last?.missions?.[0]);
    setLoading(false);
  };

  React.useEffect(() => {
    if (!lastAmendment) {
      findAmendment();
    }
  }, [mission, lastAmendment]);

  return (
    <ModalFrame
      isOpen={isOpen}
      onClose={() => onResolve(false)}
      width={800}
      closeIcon
    >
      <Box>
        <Text variant="h2" mb={20}>
          Créer un avenant
        </Text>
        <Text mb={20}>
          Merci de renseigner un intitulé de mission ainsi qu’une date de début
          et de fin pour la période de renouvellement.
        </Text>
        {!loading ? (
          <CreateMissionAmendmentForm
            mission={mission}
            lastAmendment={lastAmendment}
            onClose={() => onResolve(false)}
          />
        ) : (
          <SpinnerBox />
        )}
      </Box>
    </ModalFrame>
  );
};

export const showCreateMissionAmendmentModal =
  create<CreateMissionAmendmentProps>(CreateMissionAmendmentModal);
