import { useState } from 'react';
import { IEstablishment, IJoinedMission } from '@freelancelabs/teoreme-commons';
import {
  cutLongText,
  MIXED_TAG_INPUT_SETTING,
  PROVIDER_FORMULA_TAG_WHITELIST,
  transformFormulaToTag,
  missionUpdateOne,
  checkUserHasRole,
  useMe,
} from '@commons';
import {
  Flex,
  Box,
  Table,
  TableItem,
  Text,
  CheckSwitch,
  Spinner,
  Link,
  CustomToolTip,
  Menu,
  MenuItem,
  Itemize,
  Button,
} from 'components/ui';
import { MixedModeTagify } from 'components/InputTags';
import { EditIcon, DotsIcon } from 'components/ui/icons';
import { useGetMission } from 'hooks/missionHooks';
import { showCreateOrUpdateStandByDutyModal } from 'components/modals/clients/CreateOrUpdateStandByDutyModal';
import { showImportMissionStandByDutyModal } from 'components/modals/missions/ImportMissionStandByDutyModal';
import { Theme } from 'styles';

const ActiveFormula = ({
  item,
  mission,
}: {
  item: TableItem;
  mission: IJoinedMission;
}) => {
  const [active, setActive] = useState(item?.active);
  const [isLoading, setIsLoading] = useState(false);
  const handleChange = async (checked: boolean) => {
    setActive(checked);
    setIsLoading(true);
    try {
      if (checked) {
        await missionUpdateOne({
          reference: mission?.reference as string,
          mission: {
            //@ts-ignore
            activateFormulas: [item?.uuid],
          },
        });
      } else {
        await missionUpdateOne({
          reference: mission?.reference as string,
          mission: {
            //@ts-ignore
            deactivateFormulas: [item?.uuid],
          },
        });
      }
    } catch (e) {
      setActive(!checked);
      //
      setIsLoading(false);
    }
    setIsLoading(false);
  };
  return (
    <CheckSwitch
      checked={active}
      id={`active_${item.uuid}`}
      onChange={e => handleChange(e?.target?.checked)}
    >
      {isLoading ? <Spinner /> : <></>}
    </CheckSwitch>
  );
};
type Item = Itemize<any>;
const columns = (mission: IJoinedMission, establishment: IEstablishment) => [
  {
    key: 'active',
    text: 'Active',
    render: (item: Item) => <ActiveFormula item={item} mission={mission} />,
  },
  {
    key: 'name',
    text: 'Titre',
    render: (item: Item) => <Text maxWidth={200}>{item?.name}</Text>,
  },
  {
    key: 'description',
    text: 'DESCRIPTION',
    render: (item: Item) => (
      <Box maxWidth={200}>
        <CustomToolTip text={item?.description} id={item?.uuid}>
          <Text fontSize={9}>
            {cutLongText(item?.description, 100) || 'N/A'}
          </Text>
        </CustomToolTip>
      </Box>
    ),
  },
  {
    key: 'providerFormula',
    text: 'FORMULE FOURNISSEUR',
    render: (item: Item) => (
      <Flex
        height={'100%'}
        flexWrap="wrap"
        display="inline-flex"
        justifyContent={'center'}
        alignContent={'center'}
        alignItems={'center'}
      >
        <MixedModeTagify
          readOnly
          settings={{
            ...MIXED_TAG_INPUT_SETTING,
            whitelist: PROVIDER_FORMULA_TAG_WHITELIST,
          }}
          value={transformFormulaToTag(
            item?.providerFormula,
            PROVIDER_FORMULA_TAG_WHITELIST
          )}
        />
      </Flex>
    ),
  },
  {
    key: 'actions',
    text: 'ACTIONS',
    render: (item: Item) => {
      return (
        <Flex alignItems="center">
          {!item?.inherited && (
            <Menu
              align="right"
              menuItems={
                <>
                  <MenuItem>
                    <Link
                      iconLeft={<EditIcon />}
                      onClick={() =>
                        showCreateOrUpdateStandByDutyModal({
                          mission: mission,
                          standByDuty: item,
                          from: 'MISSION',
                          layer: 104,
                        })
                      }
                    >
                      Modifier
                    </Link>
                  </MenuItem>
                </>
              }
            >
              <DotsIcon fill={Theme?.colors?.primary?.default} fontSize={20} />
            </Menu>
          )}
        </Flex>
      );
    },
  },
];
export type StandByDutyTableProps = {};
export const StandByDutyTable = () => {
  const { mission } = useGetMission();
  const { me } = useMe();
  const establishment = mission?.customer?.establishment;
  // const customerStandByDutyFormulas =
  //   mission?.customer?.establishment?.customer?.standByDutyFormulas;
  const missionStandByDutyFormulas = mission?.standByDutyFormulas;
  const isFetching = false;

  const mapMissionStandByDutyFormulas: Item[] =
    missionStandByDutyFormulas?.map((f: any) => ({
      ...f,
      key: f.uuid,
    })) || [];
  const items: Item[] = mapMissionStandByDutyFormulas;
  const totalCount = items?.length;
  return (
    <Box>
      <Flex mb={10} mt={10} width={1 / 1} justifyContent={'flex-end'}>
        {checkUserHasRole(me, 'TEAM_LEADER') && (
          <Box mr={10}>
            <Button
              onClick={() =>
                showImportMissionStandByDutyModal({
                  mission: mission,
                })
              }
            >
              Importer depuis une autre mision
            </Button>
          </Box>
        )}
        <Box>
          <Button
            onClick={() =>
              showCreateOrUpdateStandByDutyModal({
                establishment: establishment as IEstablishment,
                mission: mission,
                from: 'MISSION',
                layer: 104,
              })
            }
          >
            Créer un type de prestation
          </Button>
        </Box>
      </Flex>
      <Table
        columns={columns(
          mission as IJoinedMission,
          establishment as IEstablishment
        )}
        items={items}
        total={totalCount}
        perPage={totalCount && totalCount < 20 ? totalCount : 20}
        isLoading={isFetching}
      />
    </Box>
  );
};
export default StandByDutyTable;
