import React, { useState } from 'react';
import {
  EContractState,
  IEstablishmentBase,
  IEstablishmentMinifiedUsers,
  IEstablishmentFullUsers,
} from '@freelancelabs/teoreme-commons';
import {
  Box,
  Text,
  Link,
  Row,
  FormControl,
  Input,
  Button,
  FormLabel,
} from 'components/ui';
import { sageCreateEstablishment } from '@commons';
import { create } from 'react-modal-promise';
import { queryClient } from '@commons';
import { useForm } from 'react-hook-form';
import { ModalFrame, ModalProps } from './ModalFrame';
import { CancelIcon } from 'components/ui/icons';
import { useShowMessage } from 'hooks/useShowMessage';

type Props = ModalProps & {
  beforeValidation?: () => void;
  establishment: IEstablishmentBase;
};
type FormValues = any;

export const AddEstablishmentProviderGescomModal = ({
  onResolve,
  isOpen,
  establishment,
}: Props) => {
  const [loading, setLoading] = useState(false);
  const showMessage = useShowMessage();
  const manager = establishment?.provider?.manager as any;
  const contractsDone = establishment?.provider?.contracts?.filter(
    c => (c as any).state === EContractState.DONE
  );
  const contract =
    Array.isArray(contractsDone) && contractsDone.length > 0
      ? contractsDone[0]
      : false;
  const { register, handleSubmit } = useForm<FormValues>({
    defaultValues: {
      firstName: manager?.firstName,
      lastName: manager?.lastName,
      email: manager?.email,
    },
  });

  const onSubmit = async (formValues: FormValues) => {
    setLoading(true);
    if (establishment.uuid) {
      try {
        await sageCreateEstablishment({
          establishment: establishment.uuid,
          type: 'provider',
        });
        showMessage('success', 'Etablissement ajouté dans GESCOM');
        queryClient.refetchQueries({ queryKey: [establishment.uuid] });
        onResolve(true);
      } catch (e) {}
    }
    setLoading(false);
  };
  return (
    <ModalFrame
      isOpen={isOpen}
      onClose={() => onResolve(false)}
      width={870}
      closeIcon
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box>
          <Text variant="h1" mb={20}>
            Créer dans GESCOM
          </Text>
          <Text variant="p" mb={20}>
            Avant de valider la création de cet établissement fournisseur
            GESCOM, merci de vérifier que :
            <ul>
              <li>
                L’établissement dispose d’un contrat-cadre (ou de formation)
                actif, sinon merci d’en importer un.
              </li>
              <li>
                Les NOM et Prénom du responsable de comptes de l’établissement
                fournisseur sont biens renseignés dans Connecteed, sinon merci
                de mettre à jour sa fiche contact.
              </li>
            </ul>
          </Text>
          <Text variant="h3">Contrat</Text>
          {contract ? (
            <Row spacing={20} mb={20}>
              <Box mt={'10px'}>
                <FormLabel>Référence</FormLabel>
                <Text variant="p">
                  {contract && (contract as any)?.refContract}
                </Text>
              </Box>
              <Box mt={'10px'}>
                <FormLabel mb={10}>Date de signature</FormLabel>
                <Text variant="p">
                  Le{' '}
                  {contract &&
                    (contract as any)?.signedAt?.toLocaleDateString()}
                </Text>
              </Box>
            </Row>
          ) : (
            <Text mt={20} mb={20} variant="p">
              Pas de contrat-cadre actif
            </Text>
          )}
          <Text variant="h3" mb={20}>
            Responsable de compte
          </Text>
          <Row spacing={20}>
            <FormControl label={'Nom'}>
              <Input
                isFullWidth
                {...register('lastName')}
                type="text"
                isDisabled
              />
            </FormControl>
            <FormControl label={'Prénom'}>
              <Input
                isFullWidth
                {...register('firstName')}
                type="text"
                isDisabled
              />
            </FormControl>
          </Row>
          <Row spacing={20}>
            <FormControl label={'Email'}>
              <Input
                isFullWidth
                {...register('email')}
                type="email"
                isDisabled
              />
            </FormControl>
            <></>
          </Row>
        </Box>
        <Box>
          <Row spacing={20} width={'300px'}>
            <Button
              key="submit"
              type="submit"
              isLoading={loading}
              isDisabled={
                establishment?.provider?.isContractRequired ? !contract : false
              }
            >
              Créer
            </Button>
            <Link
              iconLeft={<CancelIcon />}
              mt={'20px'}
              onClick={() => onResolve(false)}
            >
              Annuler
            </Link>
          </Row>
        </Box>
      </form>
    </ModalFrame>
  );
};

export const showAddEstablishmentProviderGescomModal = create<
  Props,
  IEstablishmentMinifiedUsers | IEstablishmentFullUsers
>(AddEstablishmentProviderGescomModal);
