import { useUserFindOne } from '@commons';
import {
  CREATED_SF_STATUS,
  NOT_CREATED_SF_STATUS,
  DISPLAY_SF_STATUS,
} from '@commons';
import { ApiError } from '@commons';
import { Header } from 'components/Header';
import { SideBar } from 'components/SideBar';
import { SideLink } from 'components/SideLink';
import {
  Avatar,
  Box,
  Container,
  Flex,
  Link,
  Separator,
  SpinnerBox,
  Status,
  Text,
} from 'components/ui';
import { DeleteIcon, SendIcon } from 'components/ui/icons';
import { getFirstCaracts, getFullName } from '@commons';
import React from 'react';
import { Route, Switch, useHistory, useParams } from 'react-router-dom';
import { ClientsContactsEstablishments } from './Establishment';
import { ClientsContactsInformation } from './Information';
import { Comments } from './Comment';
import PageNotFound from 'routes/NotFound';

export const ClientsContactsDetails = () => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const { data: contact, error, status } = useUserFindOne(id);
  const loading = status === 'pending';
  const salesForce = contact?.salesforce;
  if (error) {
    return (
      <PageNotFound
        top={80}
        customText="Le contact que vous cherchez semble introuvable."
      />
    );
  }
  return (
    <Box>
      <Header>
        <Flex alignItems="center">
          <Avatar text={contact && getFirstCaracts(contact)} />
          <Flex flexWrap="wrap">
            <Text ml={10} variant="h1">
              {contact && getFullName(contact)}
            </Text>
            {DISPLAY_SF_STATUS && (
              <Box width={1 / 1} ml={10}>
                <Status variantColor={salesForce ? 'success' : 'grey'}>
                  {salesForce ? CREATED_SF_STATUS : NOT_CREATED_SF_STATUS}
                </Status>
              </Box>
            )}
          </Flex>
        </Flex>
      </Header>

      <Container display="flex" pt={50}>
        <SideBar>
          <SideLink exact href={`/clients/contacts/${id}`}>
            Informations générales
          </SideLink>
          <SideLink href={`/clients/contacts/${id}/establishments`}>
            Établissements
          </SideLink>
          <SideLink href={`/clients/contacts/${id}/comments`}>
            Commentaires
          </SideLink>
          <Separator />
          <Link
            onClick={() =>
              history.push('/clients/contacts/send-email', {
                contact: contact,
                forRole: 'CUSTOMER',
              })
            }
            isFullWidth
            iconLeft={<SendIcon />}
            mb={10}
            width={'100%'}
          >
            Envoyer un email
          </Link>
          <Link isFullWidth iconLeft={<DeleteIcon />} width={'100%'}>
            Supprimer le contact
          </Link>
        </SideBar>
        <Box flex={1}>
          {loading && <SpinnerBox />}
          {!loading && (
            <Switch>
              <Route exact path="/clients/contacts/:id">
                <ClientsContactsInformation />
              </Route>
              <Route path="/clients/contacts/:id/establishments">
                <ClientsContactsEstablishments />
              </Route>
              <Route path="/clients/contacts/:id/comments">
                <Comments />
              </Route>
            </Switch>
          )}
          {!!error && <p>{(error as ApiError).message}</p>}
        </Box>
      </Container>
    </Box>
  );
};
