import { ModalFrame, ModalProps } from 'components/modals/ModalFrame';
import { CreateOrUpdateExpensesForm } from 'forms/activities/Expenses/CreateOrUpdateExpensesForm';
import { create } from 'react-modal-promise';
type Props = ModalProps & {
  beforeValidation?: () => void;
  layer?: number;
  missionRef: string;
  month?: Date;
  method: 'CREATE' | 'UPDATE';
  uuid?: string;
};

export const CreateOrUpdateExpensesModal = ({
  onResolve,
  isOpen,
  layer,
  missionRef,
  month,
  method,
  uuid,
}: Props) => {
  return (
    <ModalFrame
      isOpen={isOpen}
      onClose={() => onResolve(false)}
      width={1200}
      closeIcon
      layer={layer}
    >
      <CreateOrUpdateExpensesForm
        missionRef={missionRef}
        month={month}
        method={method}
        uuid={uuid}
        onResolve={(params: any) => onResolve(params)}
      />
    </ModalFrame>
  );
};

export const showCreateOrUpdateExpensesModal = create<Props>(
  CreateOrUpdateExpensesModal
);
