import {
  Box,
  Button,
  FileInput,
  Flex,
  FormControl,
  Link,
  Text,
  FormLabel,
  DatePickerControlled,
} from 'components/ui';
import { AddIcon } from 'components/ui/icons';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { truncate } from 'lodash';

type FormValues = {
  createdAt: Date;
  type: string;
  lang: string;
  file: File;
};
type AmendmentFormProps = {
  defaultValues?: FormValues;
  refContract?: string;
  onSubmit?: any; // TODO MAKE TYPES
  onClose: () => void;
  contract: any;
};

export const ImportAmendmentForm = ({
  contract,
  defaultValues,
  refContract,
  onSubmit: onSubmitParent,
  onClose,
}: AmendmentFormProps) => {
  const [loading, setLoading] = useState(false);
  const {
    handleSubmit,
    control,
    formState: { errors, isDirty },
    setError,
    clearErrors,
  } = useForm<FormValues>({
    defaultValues: { ...defaultValues },
  });
  const [amendmentFile, setAmendmentFile] = React.useState<File | null>(null);

  const handleAmendmentFileChange = async (files: File[]) => {
    setAmendmentFile(files[0]);
    clearErrors('file');
  };

  const onSubmit = async (formValues: FormValues) => {
    if (amendmentFile) {
      setLoading(true);
      const values: FormValues = {
        ...defaultValues,
        ...formValues,
        file: amendmentFile,
      };
      await onSubmitParent?.(values);
      setLoading(false);
    } else {
      setError('file', { message: 'Ce champ est requis' });
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
      <Text variant="h2" mb={20}>
        Importer un avenant signé
      </Text>

      <FormLabel>Référence du contract cadre</FormLabel>
      <Text mb={20}>{refContract}</Text>

      <FormControl
        required
        label="Date de signature"
        errorMessage={errors?.createdAt?.message}
      >
        <DatePickerControlled
          control={control}
          valueName="selected"
          minDate={contract?.signedAt ? new Date(contract?.signedAt) : false}
          maxDate={new Date()}
          name="createdAt"
          rules={{ required: 'Ce champ est requis' }}
        />
      </FormControl>

      <Box mb={40}>
        <FileInput
          label="avenant"
          accept=".pdf"
          subLabel="Format accepté : PDF"
          onSelect={handleAmendmentFileChange}
          required
          //@ts-ignore
          errorMessage={errors?.file?.message}
        >
          <Link iconLeft={<AddIcon />}>
            {truncate(amendmentFile?.name, {
              length: 40,
              omission: '....pdf',
            }) || 'Ajouter un avenant'}
          </Link>
        </FileInput>
      </Box>
      <Flex>
        <Button type="submit" isLoading={loading} isDisabled={!isDirty}>
          importer
        </Button>
        <Button ml="10px" variant="ghost" onClick={onClose}>
          Annuler
        </Button>
      </Flex>
    </form>
  );
};
