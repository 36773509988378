import React from 'react';
import {
  ITask,
  ITaskStep,
  ETaskStepStatus,
} from '@freelancelabs/teoreme-commons';
import { Box, Flex, Link, Spinner, Status, Text } from 'components/ui';
import { FR_STATUS } from '@commons';
import { EyeIcon, LogsIcon } from 'components/ui/icons';
import {
  variantColorStepStatus,
  getTimeOfTaksStep,
  getStepTimeByStatus,
  getAllStepOutputValues,
  isActiveStep,
  isLastActiveStep,
} from 'helpers/business';
import { Theme } from 'styles';
import { showDialogModal } from 'components/modals/DialogModal';

export type StepCardProps = {
  step: ITaskStep;
  task?: ITask;
};

export const StepCard = ({ step, task }: StepCardProps) => {
  const [seeLogs, setSeeLogs] = React.useState(false);
  const _logs = step.logs && step?.logs?.length > 0 ? step.logs : [];
  const output = getAllStepOutputValues(step);

  return (
    <Box width={1 / 1} p={10}>
      <Flex
        p={10}
        display="inline-flex"
        justifyContent="flex-start"
        flexWrap="wrap"
        width={1 / 1}
        border="1px solid black"
        borderRadius="8px"
      >
        <Flex
          display="inline-flex"
          width={8 / 12}
          minHeight={25}
          alignItems="center"
        >
          <Status
            variantColor={variantColorStepStatus[step.status] as any}
            fontWeight={isLastActiveStep(task, step) ? 'bold' : 'initial'}
          >
            Étape - {step.stepNumber} {FR_STATUS?.[step?.status]} -{' '}
            {getStepTimeByStatus(step)}
          </Status>
          {isActiveStep(task, step) && (
            <Spinner
              style={{ marginTop: '3px', marginLeft: '5px' }}
              color={'#FF8C4C'}
              size={20}
            />
          )}
          <Text
            ml={10}
            variant="h3"
            fontSize={
              typeof step.name === 'string' && step?.name?.length > 50 ? 10 : 16
            }
          >
            {step.name}
          </Text>
          {step?.status === ETaskStepStatus.COMPLETED && (
            <Text ml={10} mt={'2px'} variant="i">
              {/*@ts-ignore*/}
              {`${
                getTimeOfTaksStep(
                  new Date(step.completedAt as Date),
                  /*@ts-ignore*/
                  new Date(step.startedAt as Date)
                )?.value
              } ${
                getTimeOfTaksStep(
                  new Date(step.completedAt as Date),
                  /*@ts-ignore*/
                  new Date(step.startedAt as Date)
                )?.label
              }`}
            </Text>
          )}
        </Flex>

        <Flex
          display="inline-flex"
          flexWrap="wrap"
          justifyContent="flex-start"
          width={4 / 12}
        >
          <Box width={6 / 10}>
            <Link
              isDisabled={_logs?.length === 0 ? true : false}
              onClick={() =>
                _logs?.length === 0 ? false : setSeeLogs(!seeLogs)
              }
              iconLeft={
                _logs?.length === 0 ? <EyeIcon fill="grey" /> : <EyeIcon />
              }
            >
              {!seeLogs ? 'Voir les logs' : 'Masquer les logs'}
            </Link>
            {output && output?.length > 0 && (
              <Link
                ml={20}
                iconLeft={
                  <LogsIcon
                    width={'15px'}
                    height={'15px'}
                    style={{ marginTop: '5px' }}
                    fill={Theme?.colors?.blue}
                  />
                }
                onClick={() =>
                  showDialogModal({
                    width: 1380,
                    title: 'Sorties',
                    text: (
                      <Flex
                        display="inline-flex"
                        flexWrap="wrap"
                        justifyContent="flex-start"
                        width={1 / 1}
                        backgroundColor="#5d5866"
                        color="white"
                      >
                        <Box p={10}>
                          <code style={{ wordBreak: 'break-all' }}>
                            {output?.map((output: any) => (
                              <p style={{ marginBottom: '5px' }}>
                                <p style={{ color: '#e2433a' }}>
                                  {output?.key}:
                                </p>
                                <p style={{ wordBreak: 'break-word' }}>
                                  {' '}
                                  {output?.value}
                                </p>
                              </p>
                            ))}
                          </code>
                        </Box>
                      </Flex>
                    ),
                    confirmLabel: 'Fermer',
                  })
                }
              >
                Voir les sorties
              </Link>
            )}
          </Box>
        </Flex>
        {seeLogs && (
          <Flex
            display="inline-flex"
            flexWrap="wrap"
            justifyContent="flex-start"
            width={1 / 1}
            backgroundColor="#5d5866"
            color="white"
          >
            <Box p={10}>
              <code style={{ wordBreak: 'break-all' }}>
                {_logs?.map(log => (
                  <p>{`${log?.createdAt?.toISOString()} -- ${log?.content}`}</p>
                ))}
              </code>
            </Box>
          </Flex>
        )}
      </Flex>
    </Box>
  );
};
