import {
  getEstablishmentName,
  invoiceSendReminder,
  INVOICE_STATUS_SELECT,
  INVOICE_PROVIDER_TYPES,
  useInvoiceFindMany,
  invoiceFindMany,
  useInvoiceUpdateOne,
  getHumanDate,
  SEND_METHOD_TRANSLATE_FR,
  isStandByDutyInvoice,
  isDeliveryNoteInvoice,
  invoiceUpdateOne,
  queryClient,
  isMileStoneInvoice,
  getCustomerEstGescomCode,
  getProviderEstGescomCode,
  formatInvoiceOrAdditionalActivitySort,
  exportFileXlxs,
  GenerateInvoice,
  INVOICE_FOREIGN_SEARCH_FIELDS,
  INVOICE_SEARCH_DEBOUNCE_DELAY,
} from '@commons';
import {
  isSendableReminderInvoice,
  isSendableCustomerInvoice,
  isResetableErrorRPA,
  isGenerableCustomerInvoice,
} from 'helpers';
import {
  EInvoiceDocumentType,
  EInvoiceSendMethod,
  EInvoiceStatus,
  IJoinedInvoice,
  EInvoiceType,
  EBillingType,
  EExportModel,
} from '@freelancelabs/teoreme-commons';
import { ALink } from 'components/ALink';
import { Header } from 'components/Header';
import { showDialogModal } from 'components/modals/DialogModal';
import { showDisplayPdfModal } from 'components/modals/DisplayPdfModal';
import { showCreateGescomBDLModal } from 'components/modals/gescom/CreateGescomBDLModal';
import { StaticSelect } from 'components/selects/StaticSelect';
import { UserSelect } from 'components/selects/UserSelect';
import {
  Button,
  Box,
  Container,
  CustomToolTip,
  Flex,
  Input,
  Itemize,
  Link,
  Menu,
  MenuItem,
  Spinner,
  SpinnerBox,
  Status,
  Tab,
  Table,
  TableColumn,
  Text,
} from 'components/ui';
import {
  AddIcon,
  CheckIcon,
  CloseIcon,
  DotsIcon,
  ExclamationCircleIcon,
  EyeIcon,
  MoreFilterIcon,
  RefreshIcon,
  SendIcon,
  ValidateIcon,
} from 'components/ui/icons';
import { useSetCrumbs } from 'hooks/breadCrumb';
import { useShowMessage } from 'hooks/useShowMessage';
import { snakeCase } from 'lodash';
import React, { useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { useHistory, useParams } from 'react-router-dom';
import { useInvoiceCustomerListStore } from 'store';
import { Theme } from 'styles';
import { getObjectFilterInvoice, getSearchInKeysText } from '../../helpers';
import { showGenerateInvoiceModal } from 'components/modals/invoices/GenerateInvoiceModal';
import { useDebounce } from 'hooks/useDebounce';
import { SideBar } from 'components/ui/BootStrap/SideBar';
import { InvoicesFiltersList } from '../../InvoicesFiltersList';
import { useGlobalStore } from 'store/global';
import EXPORT_IMG from 'assets/images/export_excel_tutorial.png';

const ResetRpaErrorAction = ({ invoice }: { invoice: IJoinedInvoice }) => {
  const [isLoading, setLoading] = useState(false);
  const showMessage = useShowMessage();
  const resetRpaError = async (e: any) => {
    e.stopPropagation();
    setLoading(true);
    try {
      await invoiceUpdateOne({
        invoice: {
          resetRpaErrors: true,
        },
        uuids: [invoice?.uuid],
      });
      showMessage('success', "L'erreur rpa à bien été Réinitialisé");
      queryClient.refetchQueries({ queryKey: ['Invoices'], type: 'active' });
    } catch (e) {
      setLoading(false);
    }
    setLoading(false);
  };

  return (
    <MenuItem backgroundColor="red">
      {isLoading ? (
        <Spinner size={15} />
      ) : (
        <Link
          //@ts-ignore
          onClick={(e: any) => resetRpaError(e)}
          iconLeft={<RefreshIcon />}
        >
          Réinitialiser l’erreur RPA
        </Link>
      )}
    </MenuItem>
  );
};
const GenerateOrderPdf = ({ invoice }: { invoice: IJoinedInvoice }) => {
  const [isLoading, setLoading] = useState(false);
  const showMessage = useShowMessage();
  const regeneratePDF = async (e: any) => {
    e.stopPropagation();
    setLoading(true);
    try {
      const generateInvoiceFile = await GenerateInvoice({
        invoice: invoice?.uuid,
      });
      await invoiceUpdateOne({
        invoice: {
          invoiceFile: generateInvoiceFile,
        },
        uuids: [invoice?.uuid],
      });
      showMessage('success', 'La facture pdf à bien été créée');
      queryClient.refetchQueries({ queryKey: ['Invoices'], type: 'active' });
    } catch (e) {
      setLoading(false);
    }
    setLoading(false);
  };

  return (
    <MenuItem onClick={regeneratePDF}>
      {isLoading ? (
        <Spinner />
      ) : (
        <Link iconLeft={<RefreshIcon />}>Générer le PDF de la facture</Link>
      )}
    </MenuItem>
  );
};

const ChangeStatusAction = ({ invoice }: { invoice: IJoinedInvoice }) => {
  const [isLoading, setLoading] = useState(false);
  const showMessage = useShowMessage();
  const updateStatus = async () => {
    setLoading(true);
    try {
      await invoiceUpdateOne({
        invoice: {
          status: EInvoiceStatus?.SENT,
        },
        uuids: [invoice?.uuid],
      });
      showMessage('success', 'La facture est passée au statut envoyée');
      queryClient.refetchQueries({ queryKey: ['Invoices'], type: 'active' });
    } catch (e) {
      setLoading(false);
    }
    setLoading(false);
  };

  return (
    <MenuItem onClick={() => updateStatus()}>
      {isLoading ? (
        <Spinner />
      ) : (
        <Link iconLeft={<SendIcon />}>Passer au statut “Envoyé”</Link>
      )}
    </MenuItem>
  );
};
type Item = Itemize<IJoinedInvoice>;
const INVOICE_STATUS_SELECT_CUSTOM = INVOICE_STATUS_SELECT;
const Findex = INVOICE_STATUS_SELECT.findIndex(
  state => state.key === EInvoiceStatus.REJECTED
);
INVOICE_STATUS_SELECT_CUSTOM[Findex].label = 'Refusée';

const columns = (
  status: 'all' | 'projected' | 'validated' | 'sent' | 'archived' | 'paid',
  ReSendButton: any,
  invoiceTypeText: any,
  history: any,
  showMessage: any,
  updateInvoice: (data: any) => void
) =>
  [
    {
      key: 'sageInvoice',
      text: 'N° FACTURE',
      hidden: status === 'projected',
      render: (item: IJoinedInvoice) => {
        if (item?.sage?.documentType === EInvoiceDocumentType.INVOICE) {
          return (
            <ALink
              href={`/orders/invoices/customer/time-spent/${item?.uuid}`}
              target="_blank"
            >
              {' '}
              <Text>{item?.sage?.invoiceId || 'N/A'}</Text>{' '}
            </ALink>
          );
        }
      },
    },
    {
      key: 'invoiceDate',
      text: 'DATE DE FACTURE',
      hidden: status === 'projected',
      render: (item: IJoinedInvoice) => {
        if (item?.invoiceDate) {
          return <Text minWidth={100}>{getHumanDate(item.invoiceDate)}</Text>;
        }
      },
    },
    {
      key: 'displayReference',
      sortKey: status !== 'all' && 'mission.displayReference',
      text: 'REF.MISSION',
      render: (item: IJoinedInvoice) => (
        <ALink
          href={`/delivery/missions/${item?.mission?.reference}`}
          target="_blank"
        >
          {item?.comment ? (
            <CustomToolTip
              text={item?.comment || 'Aucun commentaire'}
              id={item?.uuid}
            >
              {item.mission.displayReference}
            </CustomToolTip>
          ) : (
            item.mission.displayReference
          )}
        </ALink>
      ),
    },
    {
      key: 'structure',
      sortKey: status !== 'all' && 'mission.sage.sucture',
      text: 'STRU',
      render: (item: IJoinedInvoice) => item?.mission?.sage?.structure,
    },
    {
      key: 'month',
      sortKey: status !== 'all' && 'month',
      text: 'MOIS',
      render: (item: IJoinedInvoice) => {
        if (
          isMileStoneInvoice(item?.invoiceType) ||
          item?.mission?.billing?.type === EBillingType.FLAT_RATE
        ) {
          return `${
            new Date(item?.createdAt as Date)?.getUTCMonth() + 1
          }/${new Date(item?.createdAt as Date)?.getUTCFullYear()}`;
        }

        return `${new Date(item?.month as Date)?.getUTCMonth() + 1}/${new Date(
          item?.month as Date
        )?.getUTCFullYear()}`;
      },
    },
    {
      key: 'invoiceType',
      sortKey: status !== 'all' && 'invoiceType',
      text: 'TYPE',
      render: (item: IJoinedInvoice) => {
        const isMarginInvoice = item?.isMarginInvoice ? ' (marge)' : '';
        return invoiceTypeText(item.invoiceType) + isMarginInvoice;
      },
    },
    {
      key: 'statusChangedAt',
      sortKey: status !== 'all' && 'statusChangedAt',
      text: "DATE D'ENVOI",
      hidden: status !== 'sent',
      render: (item: IJoinedInvoice) =>
        //@ts-ignore
        item?.statusChangedAt && item?.statusChangedAt.toLocaleDateString(),
    },
    {
      key: 'dueDate',
      sortKey: status !== 'all' && 'dueDate',
      text: "DATE D'ECHEANCE",
      hidden: status !== 'sent',
      render: (item: IJoinedInvoice) => (
        <>
          {item?.dueDate && item?.dueDate.toLocaleDateString()}
          {item?.dueDate && item?.dueDate < new Date() && (
            <ExclamationCircleIcon
              fill={Theme.colors.warning.default}
              fontSize={20}
              style={{ marginLeft: '10px' }}
            />
          )}
        </>
      ),
    },
    {
      key: 'estCustomer',
      sortKey: status !== 'all' && 'estCustomer.businessName',
      text: 'CLIENT',
      render: (item: IJoinedInvoice) => (
        <CustomToolTip
          text={<Box>{getCustomerEstGescomCode(item)}</Box>}
          id={item?.uuid + '_structCustomer'}
        >
          <ALink
            href={`/clients/establishments/${item?.estCustomer?.uuid}`}
            target="_blank"
          >
            {getEstablishmentName(item.estCustomer, 20)}
          </ALink>
        </CustomToolTip>
      ),
    },
    {
      key: 'estProvider',
      sortKey: status !== 'all' && 'estProvider.businessName',
      text: 'FOURNISSEUR',
      render: (item: IJoinedInvoice) => (
        <CustomToolTip
          text={<Box>{getProviderEstGescomCode(item)}</Box>}
          id={item?.uuid + '_structProvider'}
        >
          <ALink
            href={`/providers/establishments/${item?.estProvider?.uuid}`}
            target="_blank"
          >
            {getEstablishmentName(item.estProvider, 20)}
          </ALink>
        </CustomToolTip>
      ),
    },
    // {
    //   key: 'accountManager',
    //   sortKey: 'accountManager',
    //   text: 'SUIVI PAR',
    //   render: (item: IJoinedInvoice) =>
    //     //@ts-ignore
    //     getFullName(item?.mission?.accountManager),
    // },
    {
      key: 'totalBeforeTaxes',
      sortKey: status !== 'all' && 'totalBeforeTaxes',
      text: `MONTANT HT ${status === 'projected' ? '' : ''}`,
      //hidden: status === 'awaiting',
      render: (item: IJoinedInvoice) => {
        return (
          <Box minWidth={100} textAlign="end">
            {item?.totalBeforeTaxes
              ? `${item?.totalBeforeTaxes?.toFixed(2)}€`
              : 'N/A'}{' '}
          </Box>
        );
      },
    },
    {
      key: 'PAID',
      text: `PAYÉ`,
      align: 'left',
      hidden: !['cancelled', 'archived'].includes(status),
      render: (item: IJoinedInvoice) => {
        let paymentDate = item?.sage?.paymentDetails?.paymentDate;
        if (paymentDate) {
          return (
            <CustomToolTip
              text={`Virement émis le :  ${paymentDate?.toLocaleString()}`}
              id={item?.uuid + '_paid'}
            >
              <Flex>
                <CheckIcon
                  fontSize={25}
                  fill={Theme?.colors?.success?.default}
                />
              </Flex>
            </CustomToolTip>
          );
        } else {
          return (
            <Flex>
              <CloseIcon fontSize={20} fill={Theme?.colors?.error?.default} />
            </Flex>
          );
        }
      },
    },
    {
      key: 'sendMethod',
      text: 'ENVOI',
      hidden: status !== 'validated',
      render: (item: IJoinedInvoice) => {
        const sendMethod = item?.invoiceIssue?.sendMethod;
        return (
          <Text>
            {sendMethod &&
              SEND_METHOD_TRANSLATE_FR[
                item?.invoiceIssue?.sendMethod as EInvoiceSendMethod
              ]}
          </Text>
        );
      },
    },
    {
      key: 'deliveryOrder',
      //sortKey: 'invoice',
      text: 'STATUT',
      // hidden: status !== 'all',
      render: (item: IJoinedInvoice & { providerInvoice: IJoinedInvoice }) => {
        const ErrorInvoice = () => {
          const customerReference =
            item?.mission?.customer?.purchaseOrder?.reference;
          const errors = [];
          if (
            item?.sage?.documentType === EInvoiceDocumentType.DELIVERY_ORDER
          ) {
            errors?.push('Bon de livraison à traiter dans GESCOM');
          }
          if (item?.status === EInvoiceStatus?.PROJECTED) {
            if (!item?.providerInvoice?.sage) {
              errors?.push('BDL fournisseur à créer dans gescom');
            }
            if (!customerReference) {
              errors?.push(
                'Le bon de commande client est requis pour créer la facture.'
              );
            }
          }
          if (
            item?.status === EInvoiceStatus?.VALIDATED ||
            item?.status === EInvoiceStatus?.SENT
          ) {
            if (item?.rpaErrorDetails) {
              errors?.push(`Erreur RPA : ${item?.rpaErrorDetails}`);
            }
            if (!item?.sage?.documentType) {
              const reason = !customerReference
                ? 'BDC Client manquant'
                : 'BDL manquant';
              errors?.push(`Erreur GESCOM : ${reason}`);
            }
            // if (item?.sage?.documentType && !item?.rpaErrorDetails) {
            //   errors?.push('Facture à envoyer');
            // }
          }
          if (errors?.length > 0) {
            return (
              <CustomToolTip
                color={Theme?.colors?.error?.default}
                text={<ul>{errors?.map(err => <li>{err}</li>)}</ul>}
                id={item?.uuid}
              >
                <Text color={Theme?.colors?.error?.default}>
                  <ExclamationCircleIcon
                    fill={Theme.colors.error.default}
                    fontSize={20}
                    style={{ marginLeft: '10px' }}
                  />
                </Text>
              </CustomToolTip>
            );
          } else {
            return <></>;
          }
        };
        switch (item?.status) {
          case EInvoiceStatus.TO_BE_FILLED: {
            return (
              <Status variantColor="grey">
                A saisir <ErrorInvoice />
              </Status>
            );
          }
          case EInvoiceStatus.ARCHIVED: {
            return (
              <Status variantColor="grey">
                Archivé <ErrorInvoice />{' '}
              </Status>
            );
          }
          case EInvoiceStatus.CANCELLED: {
            return (
              <Status variantColor="error">
                Annulé le {item?.statusChangedAt?.toLocaleDateString()}
                <ErrorInvoice />
              </Status>
            );
          }
          case EInvoiceStatus.PROJECTED: {
            return (
              <Status variantColor="grey">
                Brouillon <ErrorInvoice />
              </Status>
            );
          }
          case EInvoiceStatus.REJECTED: {
            return (
              <Status variantColor="error">
                Rejeté le {item?.statusChangedAt?.toLocaleDateString()}
                <ErrorInvoice />
              </Status>
            );
          }
          case EInvoiceStatus.SENT: {
            if (item?.sage?.documentType === EInvoiceDocumentType.INVOICE) {
              if (item?.invoiceIssue?.sentAt) {
                return (
                  <Status variantColor="success">
                    Envoyé le : {getHumanDate(item?.invoiceIssue?.sentAt)}{' '}
                    <ErrorInvoice />
                  </Status>
                );
              } else {
                return (
                  <Status variantColor="success">
                    Envoyé le : {getHumanDate(item?.validatedAt as Date)}
                    <ErrorInvoice />
                  </Status>
                );
              }
            }
            break;
          }
          case EInvoiceStatus.VALIDATED: {
            return (
              <Status variantColor="warning">
                À Envoyer
                <ErrorInvoice />
              </Status>
            );
          }
          case EInvoiceStatus.PAID: {
            return (
              <Status variantColor="success">
                Payée{' '}
                {item?.sage?.paymentDetails?.paymentDate
                  ? `- Virement Recu le : ${item?.sage?.paymentDetails?.paymentDate?.toLocaleDateString()}`
                  : ''}
                <ErrorInvoice />
              </Status>
            );
          }
        }
      },
    },
    {
      key: 'actions',
      text: 'ACTIONS',
      align: 'flex-end',
      render: (
        item: IJoinedInvoice & {
          providerInvoice?: IJoinedInvoice;
        }
      ) => {
        const icon = <EyeIcon style={{ marginTop: 4 }} />;
        const invoiceId = item?.uuid;
        const invoiceStatus = item?.status;
        const missionRef = item?.mission?.reference;
        const contractorId = item?.contractor?.uuid;
        const invoiceFile = item?.invoiceFile;
        const customerReference =
          item?.mission?.customer?.purchaseOrder?.reference;
        return (
          <Flex alignItems="center">
            <ALink href={`/orders/invoices/customer/time-spent/${invoiceId}`}>
              <Link iconLeft={icon}></Link>
            </ALink>
            {isSendableReminderInvoice(item) && (
              <Flex mr={'5px'} mt={'10px'}>
                <ReSendButton invoice={item} />
              </Flex>
            )}
            {invoiceStatus === EInvoiceStatus.TO_BE_VALIDATED && (
              <Flex mr={'5px'} mt={'10px'} mb={'10px'}>
                {item?.sage && (
                  <>
                    <ValidateIcon fill={'red !important'} />
                    <ALink
                      href={`/orders/invoices/provider/time-spent/${invoiceId}`}
                    >
                      <Link>Valider</Link>
                    </ALink>
                  </>
                )}
              </Flex>
            )}
            <Menu
              align="right"
              menuItems={
                <>
                  {item?.status === EInvoiceStatus?.CANCELLED && (
                    <MenuItem>
                      {item?.creditNote?.invoiceFile?.fileLocation ? (
                        <Link
                          iconLeft={<EyeIcon />}
                          onClick={() =>
                            showDisplayPdfModal({
                              //@ts-ignore
                              fileLocation:
                                item?.creditNote?.invoiceFile?.fileLocation,
                              //@ts-ignore
                              fileName: item?.creditNote?.invoiceFile?.fileName,
                            })
                          }
                        >
                          Voir la facture d'avoir
                        </Link>
                      ) : (
                        <Link iconLeft={<EyeIcon />} isDisabled>
                          Aucune facture généré
                        </Link>
                      )}
                    </MenuItem>
                  )}
                  {[EInvoiceStatus?.SENT, EInvoiceStatus?.PAID].includes(
                    item?.status
                  ) && (
                    <MenuItem>
                      {invoiceFile?.fileLocation ? (
                        <Link
                          iconLeft={<EyeIcon />}
                          onClick={() =>
                            showDisplayPdfModal({
                              //@ts-ignore
                              fileLocation: invoiceFile?.fileLocation,
                              //@ts-ignore
                              fileName: invoiceFile?.fileName,
                            })
                          }
                        >
                          Voir la facture
                        </Link>
                      ) : (
                        <Link iconLeft={<EyeIcon />} isDisabled>
                          Aucune facture généré
                        </Link>
                      )}
                    </MenuItem>
                  )}
                  {item?.status === EInvoiceStatus.VALIDATED &&
                    item?.sage &&
                    !item?.invoiceFile && (
                      <GenerateOrderPdf invoice={item as IJoinedInvoice} />
                    )}
                  {item?.providerInvoice?.sage ? (
                    isGenerableCustomerInvoice(item) && (
                      <MenuItem>
                        <Link
                          onClick={() =>
                            showGenerateInvoiceModal({
                              invoiceUuid: item?.uuid,
                            })
                          }
                          iconLeft={<SendIcon />}
                        >
                          Générer et envoyer la facture client
                        </Link>
                      </MenuItem>
                    )
                  ) : (
                    <MenuItem>
                      <Link
                        onClick={async () =>
                          await showCreateGescomBDLModal({
                            mission: item?.mission,
                            delivreyOrders: {
                              bdlProvider: false,
                              bdlCustomer: false,
                            },
                            invoice: item,
                            cra: item?.cra,
                            customerReference: customerReference as string,
                            refetchQueryKey: 'Invoices',
                          })
                        }
                        iconLeft={<SendIcon />}
                      >
                        Créer le BL fournisseur
                      </Link>
                    </MenuItem>
                  )}
                  {isResetableErrorRPA(item) && (
                    <ResetRpaErrorAction invoice={item as IJoinedInvoice} />
                  )}
                  {isSendableCustomerInvoice(item) && (
                    <ChangeStatusAction invoice={item as IJoinedInvoice} />
                  )}
                  {item?.status === EInvoiceStatus?.VALIDATED && (
                    <MenuItem>
                      {invoiceFile?.fileLocation ? (
                        <Link
                          onClick={() =>
                            showDisplayPdfModal({
                              //@ts-ignore
                              fileLocation: invoiceFile?.fileLocation,
                              //@ts-ignore
                              fileName: invoiceFile?.fileName,
                              confirmLabel:
                                item?.invoiceIssue?.sendMethod ===
                                EInvoiceSendMethod?.EMAIL
                                  ? 'Envoyer la facture au client'
                                  : undefined,
                              onConfirm:
                                item?.invoiceIssue?.sendMethod ===
                                EInvoiceSendMethod?.EMAIL
                                  ? async () => {
                                      return await showDialogModal({
                                        title:
                                          'Êtes-vous sûr de vouloir envoyer la facture au client ?',
                                        text: "L'envoi du document passera le statut de la facture à 'Envoyé au client'",
                                        confirmLabel: 'Envoyer',
                                        cancelLabel: 'Ne pas envoyer',
                                        layer: 202,
                                      }).then(async action => {
                                        if (action === true) {
                                          try {
                                            const responseUpdateInvoice: any =
                                              await updateInvoice({
                                                uuids: [item?.uuid as string],
                                                invoice: {
                                                  status: EInvoiceStatus?.SENT,
                                                },
                                              });
                                            if (
                                              responseUpdateInvoice?.errorUpdateArray &&
                                              responseUpdateInvoice
                                                ?.errorUpdateArray?.length > 0
                                            ) {
                                              showMessage(
                                                'error',
                                                'Une erreur est survenue'
                                              );
                                              return;
                                            } else {
                                              queryClient.refetchQueries({
                                                queryKey: ['Invoices'],
                                              });
                                            }
                                          } catch (e) {
                                            //
                                          }
                                        }
                                        return action;
                                      });
                                    }
                                  : undefined,
                              downloadConfirm: async () => {
                                return await showDialogModal({
                                  title: 'Télécharger',
                                  text: "Le téléchargement du document passera le statut de la facture à 'Envoyé au client'",
                                  confirmLabel: 'Télécharger',
                                  cancelLabel: 'Ne pas télécharger',
                                  layer: 202,
                                }).then(async action => {
                                  if (action === true) {
                                    try {
                                      const responseUpdateInvoice =
                                        await updateInvoice({
                                          uuids: [item?.uuid as string],
                                          invoice: {
                                            status: EInvoiceStatus?.SENT,
                                          },
                                        });
                                      if (
                                        //@ts-ignore
                                        responseUpdateInvoice?.errorUpdateArray &&
                                        //@ts-ignore
                                        responseUpdateInvoice?.errorUpdateArray
                                          ?.length > 0
                                      ) {
                                        showMessage(
                                          'error',
                                          'Une erreur est survenue'
                                        );
                                        return;
                                      } else {
                                        queryClient.refetchQueries({
                                          queryKey: ['invoices'],
                                          type: 'active',
                                        });
                                      }
                                    } catch (e) {}
                                  }
                                  return action;
                                });
                              },
                            })
                          }
                          iconLeft={<EyeIcon />}
                        >
                          Voir la facture
                        </Link>
                      ) : (
                        <Link iconLeft={<SendIcon />} isDisabled>
                          Aucune facture généré
                        </Link>
                      )}
                    </MenuItem>
                  )}
                  <MenuItem>
                    <ALink href={`/delivery/missions/${missionRef}`}>
                      <Link iconLeft={icon}>Voir la mission</Link>
                    </ALink>
                  </MenuItem>
                  <MenuItem>
                    <ALink href={`/providers/contractors/${contractorId}`}>
                      <Link iconLeft={icon}>Voir la fiche intervenant</Link>
                    </ALink>
                  </MenuItem>
                </>
              }
            >
              <DotsIcon fill={Theme?.colors?.primary?.default} fontSize={20} />
            </Menu>
          </Flex>
        );
      },
    },
  ].filter(Boolean) as TableColumn<Item>[];

export const CustomerTimeSpentList = () => {
  const { tasksInProgress, updateGlobalStore } = useGlobalStore();
  const [isLoadingExport, setIsLoadingExport] = React.useState(false);
  const { mutateAsync: updateInvoice } = useInvoiceUpdateOne();
  const history = useHistory();
  const [isFetchingProviderInvoice, setIsFetchingProviderInvoice] =
    React.useState(false);
  const [items, setItems] = React.useState<IJoinedInvoice[]>([]);
  const { filter: filterParams } = useParams<{
    filter: 'all' | 'projected' | 'validated' | 'sent' | 'paid' | 'archived';
  }>();
  useSetCrumbs(
    [filterParams],
    [
      {
        label: 'Factures',
        path: '/orders/invoices',
      },
      {
        label: 'Client',
        path: '/orders/invoices/customer',
      },
      {
        label:
          filterParams === 'all'
            ? 'Tous'
            : filterParams === 'projected'
              ? 'À créer'
              : filterParams === 'validated'
                ? 'À envoyer'
                : filterParams === 'sent'
                  ? 'Envoyées'
                  : filterParams === 'paid'
                    ? 'Payées'
                    : 'Archivées',
        path: '/orders/invoices/customer/time-spent/awaiting',
      },
    ]
  );
  const Tabs = (
    <>
      <Tab
        variant="secondary"
        href={'/orders/invoices/customer/time-spent/all'}
      >
        Tous
      </Tab>
      <Tab
        variant="secondary"
        href={'/orders/invoices/customer/time-spent/projected'}
      >
        À créer
      </Tab>
      <Tab
        variant="secondary"
        href={'/orders/invoices/customer/time-spent/validated'}
      >
        À envoyer
      </Tab>
      <Tab
        variant="secondary"
        href={'/orders/invoices/customer/time-spent/sent'}
      >
        Envoyées
      </Tab>
      <Tab
        variant="secondary"
        href={'/orders/invoices/customer/time-spent/paid'}
      >
        Payées
      </Tab>
      <Tab
        variant="secondary"
        href={'/orders/invoices/customer/time-spent/cancelled'}
      >
        Annulées
      </Tab>
    </>
  );
  const {
    initialSate,
    updateStore,
    resetStore,
    getNbActiveFilters,
    startDate,
    endDate,
    sortedBy,
    order,
    selectedStatus,
    selectedType,
    searchQuery,
    page,
    accountManagerId,
    commercialSelected,
    structureSelected,
    clientSelected,
    providerSelected,
    multiProviderSelected,
    multiClientSelected,
    contractorSelected,
    invoiceDate,
    searchInKeys,
    selectedCustomStatus, //WARN
    limit,
  } = useInvoiceCustomerListStore();
  const debouncedFilterQuery = useDebounce(
    searchQuery,
    INVOICE_SEARCH_DEBOUNCE_DELAY
  );
  const sortString = (order === 'desc' ? '-' : '') + sortedBy;
  const additionalFiltersFields = [
    'selectedType',
    'commercialSelected',
    'structureSelected',
    'multiProviderSelected',
    'multiClientSelected',
    'contractorSelected',
    'invoiceDate',
  ];
  const {
    data: invoiceQuery,
    status,
    isFetching,
  } = useInvoiceFindMany({
    filterObject: getObjectFilterInvoice(
      {
        debouncedFilterQuery,
        startDate,
        endDate,
        filterUrl: snakeCase(filterParams).toLocaleUpperCase() as any,
        selectedStatus,
        selectedType,
        accountManagerId,
        commercialSelected,
        structureSelected,
        clientSelected,
        providerSelected,
        multiProviderSelected,
        multiClientSelected,
        contractorSelected,
        invoiceDate,
        selectedCustomStatus,
        searchInKeys,
      },
      'CUSTOMER'
    ),
    limit: limit,
    skip: limit * page,
    sort: formatInvoiceOrAdditionalActivitySort(sortString),
  });

  const onSortedChange = React.useCallback(
    (sortedBy: string, order: 'asc' | 'desc'): void => {
      //@ts-ignore
      updateStore({ page: 0, order, sortedBy });
    },
    []
  );

  const showMessage = useShowMessage();

  const invoiceTypeText = (invoiceType: EInvoiceType) => {
    if (invoiceType === EInvoiceType?.CUSTOMER) {
      return 'Prest. std.';
    }
    if (invoiceType === EInvoiceType?.CUSTOMER_STAND_BY_DUTY) {
      return 'Prest. comp.';
    }
    if (invoiceType === EInvoiceType?.CUSTOMER_EXPENSE) {
      return 'Frais';
    }
    if (invoiceType === EInvoiceType?.CUSTOMER_MILESTONE) {
      return 'Jalon';
    }
  };

  const ReSendButton = ({ invoice }: { invoice: IJoinedInvoice }) => {
    const { mutateAsync: onResend, status } = useMutation({
      mutationFn: () =>
        invoiceSendReminder({
          uuid: invoice.uuid,
        }),
    });
    if (status === 'pending') return <Spinner size={20} />;
    if (status === 'success') {
      showMessage('success', 'Votre relance a bien été envoyée');
    }
    if (status === 'error') {
      showMessage(
        'error',
        'Suite à une erreur technique, votre relance n’a pas pu être envoyée. Veuillez réessayer'
      );
    }

    return (
      <Link
        style={{ paddingBottom: 15 }}
        iconLeft={<RefreshIcon />}
        onClick={() => onResend()}
      >
        Relancer
      </Link>
    );
  };

  React.useEffect(() => {
    const filterParamsWithErrors = ['all', 'validated', 'sent'];

    if (!filterParamsWithErrors.includes(filterParams)) {
      updateStore({ page: 0, selectedCustomStatus: undefined });
    } else {
      updateStore({ page: 0 });
    }
  }, [filterParams]);
  const loading = status === 'pending';
  const totalCount = invoiceQuery?.totalCount || 0;

  const handleMergeInvoiceCustomerAndProvider = async () => {
    setIsFetchingProviderInvoice(true);
    let items = invoiceQuery?.invoices?.map((invoice: IJoinedInvoice) => ({
      key: invoice.uuid || '',
      ...invoice,
    }));
    if (invoiceQuery) {
      const providerInvoices = await invoiceFindMany({
        filterObject: {
          invoiceType: {
            $in: INVOICE_PROVIDER_TYPES,
          },
          $or: [
            {
              cra: {
                $in: invoiceQuery?.invoices
                  ?.map(
                    (invoicesCustomer: any) => invoicesCustomer?.cra?.refCra
                  )
                  ?.filter(cra => cra),
              },
            },
            {
              additionalActivity: {
                $in: invoiceQuery?.invoices
                  ?.map(
                    (invoicesCustomer: any) =>
                      invoicesCustomer?.additionalActivity
                  )
                  ?.filter(i => i),
              },
            },
          ],
          // cra: {
          //   $in: invoiceQuery?.invoices?.map(
          //     (invoicesCustomer: any) => invoicesCustomer?.cra?.refCra
          //   ),
          // },
        },
      });
      items = items?.map((invoicesCustomer: any) => ({
        ...invoicesCustomer,
        providerInvoice:
          // CHECK IF PROVIDER INVOICE IS CREATE IN SAGE
          invoicesCustomer?.invoiceType === EInvoiceType?.CUSTOMER
            ? providerInvoices?.invoices?.find(
                invoiceProvider =>
                  invoiceProvider?.cra?.refCra === invoicesCustomer?.cra?.refCra
              )
            : // CHECK IF PROVIDER (SBD || FEES || MILESTONE) INVOICE IS CREATE IN SAGE
              isStandByDutyInvoice(invoicesCustomer?.invoiceType) ||
                isDeliveryNoteInvoice(invoicesCustomer?.invoiceType) ||
                isMileStoneInvoice(invoicesCustomer?.invoiceType)
              ? providerInvoices?.invoices?.find(
                  invoiceProvider =>
                    invoiceProvider?.additionalActivity ===
                    invoicesCustomer?.additionalActivity
                )
              : undefined,
      }));
      //@ts-ignore
      setItems(items);
    }
    setIsFetchingProviderInvoice(false);
  };
  const onExportToExcel = async () => {
    setIsLoadingExport(true);
    try {
      const exportResponse = await exportFileXlxs({
        model: EExportModel.INVOICE,
        query: {
          filter: getObjectFilterInvoice(
            {
              debouncedFilterQuery,
              startDate,
              endDate,
              filterUrl: snakeCase(filterParams).toLocaleUpperCase() as any,
              selectedStatus,
              selectedType,
              accountManagerId,
              commercialSelected,
              structureSelected,
              clientSelected,
              providerSelected,
              multiProviderSelected,
              multiClientSelected,
              contractorSelected,
              invoiceDate,
              selectedCustomStatus,
              searchInKeys,
            },
            'CUSTOMER'
          ),
          //fields:[],
          // limit: limit,
          // skip: limit * page,
          // sort: sortString,
        },
      });
      const uuid = exportResponse?.taskUuid;
      if (Array.isArray(tasksInProgress)) {
        updateGlobalStore({ tasksInProgress: [...tasksInProgress, uuid] });
      } else {
        updateGlobalStore({ tasksInProgress: [uuid] });
      }
      showDialogModal({
        title: 'Export excel en cours',
        text: (
          <Box>
            <Text>
              La création de votre export excel est en cours. Cette action peut
              prendre quelques minutes. Vous pouvez suivre l'état de progression
              en cliquant sur l'icone ci-dessous.
            </Text>
            <img src={EXPORT_IMG} />
          </Box>
        ),
        confirmLabel: "J'ai compris",
      });
      setIsLoadingExport(false);
    } catch (e) {
      setIsLoadingExport(false);
    }
  };
  React.useEffect(() => {
    if (invoiceQuery) {
      handleMergeInvoiceCustomerAndProvider();
    }
  }, [invoiceQuery]);

  return (
    <>
      <Header
        tabs={Tabs}
        actions={
          <Button isLoading={isLoadingExport} onClick={() => onExportToExcel()}>
            Exporter
          </Button>
        }
      >
        <Text variant="h1">Factures clients</Text>
      </Header>
      <Container p="20px 0">
        <Flex
          display={'inline-flex'}
          justifyContent={'flex-start'}
          flexWrap={'wrap'}
          m="10px 0"
          width={1 / 1}
        >
          <Flex width={1 / 1} mb={10}>
            <Box width={7 / 12} pr={'5px'} pt={'5px'}>
              <Flex>
                <Box width={5 / 12}>
                  <StaticSelect
                    isFieldSet
                    isClearable={false}
                    options={INVOICE_FOREIGN_SEARCH_FIELDS}
                    value={searchInKeys?.[0]?.value}
                    components={{ IndicatorSeparator: () => null }}
                    onChange={value =>
                      updateStore({
                        searchInKeys: [
                          INVOICE_FOREIGN_SEARCH_FIELDS?.find(
                            e => e?.value === value
                          ) as any,
                        ],
                      })
                    }
                  />
                </Box>
                <Box width={7 / 12} ml={'-5px'}>
                  <Input
                    isFieldSet
                    mr={20}
                    isFullWidth
                    data-cy="search-estaCustomer-list"
                    type="search"
                    name="search"
                    placeholder={
                      ('Rechercher dans : ' +
                        getSearchInKeysText(searchInKeys, true)) as string
                    }
                    icon={
                      <SideBar
                        title="Filtres de facture"
                        customLinkIcon={
                          <MoreFilterIcon
                            style={{ cursor: 'pointer' }}
                            fill={Theme?.colors?.grey?.default}
                          />
                        }
                        buttonType="icon"
                        buttonText=" "
                      >
                        <InvoicesFiltersList
                          filterInvoiceType="CUSTOMER"
                          hookStore={useInvoiceCustomerListStore}
                        />
                      </SideBar>
                    }
                    value={searchQuery}
                    onChange={(e: any) => {
                      updateStore({
                        page: 0,
                        searchQuery: e.target.value,
                      });
                    }}
                  />
                </Box>
              </Flex>
            </Box>
            {/* <Box width={5 / 12} pr={'5px'} pt={'5px'}>
              <StaticMultiSelect
                placeholder="Étendre la recherche dans ..."
                onChange={value => updateStore({ searchInKeys: value })}
                options={INVOICE_FOREIGN_SEARCH_FIELDS}
                values={searchInKeys}
              />
            </Box> */}
            <Box width={3 / 12} ml={20} mt={'5px'}>
              <UserSelect
                placeholder="Tous les chargés de compte"
                role="ACCOUNT_MANAGER"
                onChange={id => updateStore({ accountManagerId: id })}
                value={accountManagerId as string}
              />
            </Box>
            {['validated', 'all', 'sent'].includes(filterParams) && (
              <Box width={2 / 12} pl={20} mt={'5px'}>
                <StaticSelect
                  placeholder="Tous les états"
                  options={[
                    { label: 'Tous', value: 'ALL' },
                    { label: 'OK', value: 'TO_SENT' },
                    { label: 'Erreur RPA', value: 'ERROR_RPA' },
                    { label: 'Erreur GESCOM', value: 'ERROR_GESCOM' },
                  ]}
                  onChange={value =>
                    updateStore({
                      selectedCustomStatus: value as any,
                    })
                  }
                  value={selectedCustomStatus}
                />
              </Box>
            )}
            <Box width={2 / 12} ml={20}>
              <Flex
                justifyContent={'center'}
                alignItems={'center'}
                flexWrap="wrap"
                alignContent="center"
                width={1 / 1}
                height={'100%'}
              >
                <SideBar
                  title="Filtres de facture"
                  customLinkIcon={<AddIcon />}
                  buttonType="link"
                  buttonText="Plus de filtres"
                >
                  <InvoicesFiltersList
                    filterInvoiceType="CUSTOMER"
                    hookStore={useInvoiceCustomerListStore}
                  />
                </SideBar>
                {initialSate === false &&
                  getNbActiveFilters(additionalFiltersFields) > 0 && (
                    <Box
                      backgroundColor={Theme?.colors?.primary?.default}
                      border={`1px solid ${Theme?.colors?.primary?.default}`}
                      borderRadius={'50%'}
                      height={15}
                      width={15}
                      ml={-2}
                      mt={-4}
                    >
                      <Flex justifyContent={'center'}>
                        <Text color="white" fontSize={'10px'}>
                          {' '}
                          {getNbActiveFilters(additionalFiltersFields)}
                        </Text>
                      </Flex>
                    </Box>
                  )}
                <Link
                  isDisabled={initialSate}
                  iconLeft={
                    <RefreshIcon
                      fill={
                        initialSate ? Theme?.colors?.grey?.default : undefined
                      }
                    />
                  }
                  ml={'5px'}
                  onClick={() => (!initialSate ? resetStore({}) : false)}
                >
                  Réinitialiser
                </Link>
              </Flex>
            </Box>
          </Flex>
        </Flex>
        {/* <Flex
          justifyContent="flex-start"
          display="inline-flex"
          flexWrap="wrap"
          m="10px 0"
        >
          <Box width={1 / 5}>
            <Input
              mr={20}
              isFullWidth
              data-cy="search-estaCustomer-list"
              type="search"
              name="search"
              placeholder="Rechercher ..."
              value={searchQuery}
              onChange={(e: any) => {
                updateStore({
                  page: 0,
                  searchQuery: e.target.value,
                });
              }}
            />
          </Box>
          <Box width={1 / 5} pl={20}>
            <DatePickerRange
              startDate={startDate}
              endDate={endDate}
              isClearable={true}
              setStartDate={value => updateStore({ startDate: value })}
              setEndDate={value => updateStore({ endDate: value })}
            />
          </Box>
          <Box width={1 / 5} pl={20}>
            <UserSelect
              placeholder="Tous les chargés de compte"
              role="ACCOUNT_MANAGER"
              onChange={id => updateStore({ accountManagerId: id })}
              value={accountManagerId as string}
            />
          </Box>
          {filterParams !== 'validated' && (
            <Box width={1 / 5} pl={20}>
              <StaticSelect
                placeholder="Tous les types"
                options={INVOICE_CUSTOMER_TYPE_SELECT}
                onChange={value =>
                  updateStore({ selectedType: value as EInvoiceType })
                }
                value={selectedType}
              />
            </Box>
          )}
          {filterParams === 'validated' && (
            <Box width={1 / 5} pl={20}>
              <StaticSelect
                placeholder="Tous les états"
                options={[
                  { label: 'Tous', value: 'ALL' },
                  { label: 'OK', value: 'TO_SENT' },
                  { label: 'Erreur RPA', value: 'ERROR_RPA' },
                  { label: 'Erreur GESCOM', value: 'ERROR_GESCOM' },
                ]}
                onChange={value =>
                  updateStore({
                    selectedCustomStatus: value as any,
                  })
                }
                value={selectedCustomStatus}
              />
            </Box>
          )}
          <Box mb={'5px'} width={1 / 5}>
            <Flex
              justifyContent={'center'}
              alignItems={'center'}
              flexWrap="wrap"
              alignContent="center"
              width={1 / 1}
              height={'100%'}
            >
              <SideBar
                title="Filtres de facture"
                customLinkIcon={<AddIcon />}
                buttonType="link"
                buttonText="Plus de filtres"
              >
                <InvoicesFiltersList
                  filterInvoiceType="CUSTOMER"
                  hookStore={useInvoiceCustomerListStore}
                />
              </SideBar>
              {initialSate === false &&
                getNbActiveFilters(additionalFiltersFields) > 0 && (
                  <Box
                    backgroundColor={Theme?.colors?.primary?.default}
                    border={`1px solid ${Theme?.colors?.primary?.default}`}
                    borderRadius={'50%'}
                    height={15}
                    width={15}
                    ml={-2}
                    mt={-4}
                  >
                    <Flex justifyContent={'center'}>
                      <Text color="white" fontSize={'10px'}>
                        {' '}
                        {getNbActiveFilters(additionalFiltersFields)}
                      </Text>
                    </Flex>
                  </Box>
                )}
              <Link
                isDisabled={initialSate}
                iconLeft={
                  <RefreshIcon
                    fill={
                      initialSate ? Theme?.colors?.grey?.default : undefined
                    }
                  />
                }
                ml={'5px'}
                onClick={() => (!initialSate ? resetStore({}) : false)}
              >
                Réinitialiser
              </Link>
            </Flex>
          </Box>
        </Flex> */}
        {loading && <SpinnerBox />}
        {!loading && (
          <Table<Itemize<IJoinedInvoice & { providerInvoice?: IJoinedInvoice }>>
            isLoading={isFetching || isFetchingProviderInvoice}
            columns={columns(
              filterParams,
              ReSendButton,
              invoiceTypeText,
              history,
              showMessage,
              updateInvoice
            )}
            //@ts-ignore
            items={items || []}
            sortedBy={sortedBy}
            order={order}
            page={page}
            total={totalCount}
            perPage={totalCount < 20 ? totalCount : 20}
            opacity={filterParams === 'archived' ? 0.5 : 1}
            onSortedChange={onSortedChange}
            onChangePage={page => updateStore({ page: page })}
          />
        )}
      </Container>
    </>
  );
};
