import { Flex, Box, Text } from 'components/ui';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { ProviderIcon, CustomerIcon } from 'components/ui/icons';
import { Card, CardBody } from '../ui/Card';

const ContainerCard = styled(Card)`
  cursor: pointer;
  &:hover,
  &:focus {
    background-color: ${p => p.theme.colors.lightGray};
  }
`;

export const CardAmountInfo = ({
  icon,
  label,
  link,
  children,
  providerValue,
  customerValue,
  borderColor,
  ...props
}: {
  icon?: any;
  children?: any;
  label: any;
  customerValue: any;
  providerValue: any;
  link?: string;
  borderColor?: string;
}) => {
  const history = useHistory();
  return (
    <Box
      width={1 / 1}
      p={'5px'}
      onClick={() => (link ? history.push(link) : false)}
    >
      <ContainerCard
        maxWidth={'100%'}
        minWidth={'190px'}
        height="100px"
        borderColor={borderColor}
        borderWidth={'2px'}
        p={'1px'}
        mx={'5px'}
      >
        <CardBody width={1 / 1} height={'100%'} p={'1px'}>
          <Flex justifyContent="space-around" flexWrap={'wrap'} height={'100%'}>
            <Box width={12 / 12}>
              <Flex justifyContent="space-around" flexWrap={'wrap'}>
                <Box width={1 / 1} textAlign={'center'}>
                  <Text fontSize={13} variant="h3">
                    {label}
                  </Text>
                </Box>
                <Box>
                  <Box width={1 / 1} mt={2}>
                    <Text mb={'4px'}>
                      <Flex>
                        <Box mr={'5px'}>
                          <ProviderIcon
                            width={20}
                            height={20}
                            fill={borderColor}
                          />
                        </Box>
                        {providerValue}
                      </Flex>
                    </Text>
                    <Text>
                      <Flex>
                        <Box mr={'5px'}>
                          <CustomerIcon
                            width={20}
                            height={20}
                            fill={borderColor}
                          />
                        </Box>
                        {customerValue}
                      </Flex>
                    </Text>
                  </Box>
                </Box>
              </Flex>
            </Box>
          </Flex>
        </CardBody>
      </ContainerCard>
    </Box>
  );
};
