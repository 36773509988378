import { checkUserHasRole } from '@commons';
import { useUserFindMany, useUserFindOne } from '@commons'; //moved
import { Select } from 'components/ui';
import { useDebounce } from 'hooks/useDebounce';
import React, { ComponentPropsWithoutRef, useState } from 'react';

type UserSelectProps = {
  isDisabled?: boolean;
  value?: string | any;
  onChange?: (id: string) => void;
  onChangeCompleteObject?: (object: any) => void;
  disableCall?: boolean;
  role: string;
  dataCy?: string;
  filter?: any;
  isClearable?: boolean;
  addedData?: any[];
  disableRoleMatch?: boolean;
  excludeDeactivatedUser?: boolean;
} & Omit<ComponentPropsWithoutRef<'input'>, 'event' | 'onChange' | 'value'>;

export const UserSelect = ({
  value,
  onChange,
  role,
  disableCall,
  filter: globalFilter,
  isClearable = true,
  addedData,
  disableRoleMatch,
  excludeDeactivatedUser,
  ...props
}: UserSelectProps) => {
  const [filter, setFilter] = useState('');
  const debouncedFilter = useDebounce(filter, 500);
  //@ts-ignore
  const valueContact = value?.cognitoUserId || value;
  /** Get selected user */
  const { data: selectedUser } = useUserFindOne(
    disableCall ? false : valueContact
  );

  const roleMatch = checkUserHasRole(selectedUser, role);
  const selectedOption =
    disableCall && value
      ? {
          label: `${value?.lastName || ''} ${
            value?.firstName || ''
          } ${value?.email}`,
          value: value?.cognitoUserId || value?.email || '',
        }
      : selectedUser &&
        (roleMatch || disableRoleMatch) && {
          label: `${selectedUser.lastName || ''} ${
            selectedUser.firstName || ''
          } ${selectedUser?.email}`,
          value: selectedUser.cognitoUserId || selectedUser.email || '',
        };

  const filterObject = {
    ...globalFilter,
    'roles.name': role,
    $and: excludeDeactivatedUser
      ? [
          {
            $or: [{ deactivated: false }, { deactivated: { $exists: false } }],
          },
          {
            $or: [
              { firstName: { $regex: debouncedFilter, $options: 'i' } },
              { lastName: { $regex: debouncedFilter, $options: 'i' } },
              {
                email: {
                  $regex: debouncedFilter?.replace('+', '\\+').trim(),
                  $options: 'i',
                },
              },
            ],
          },
        ]
      : undefined,
    $or: !excludeDeactivatedUser
      ? [
          { firstName: { $regex: debouncedFilter, $options: 'i' } },
          { lastName: { $regex: debouncedFilter, $options: 'i' } },
          {
            email: {
              $regex: debouncedFilter?.replace('+', '\\+').trim(),
              $options: 'i',
            },
          },
        ]
      : undefined,
  };
  if (globalFilter?.$or) {
    filterObject?.$or?.contact(globalFilter?.$or);
  }
  // get user list
  const { data: usersQuery, isFetching } = useUserFindMany({
    filterObject: filterObject,
    limit: 10,
  });

  const userOptions =
    usersQuery?.users?.map((u: any) => ({
      label: `${u.lastName || ''} ${u.firstName || ''} ${u.email}`,
      value: u.cognitoUserId || '',
      data: u,
    })) || [];
  return (
    <Select
      id={props.id}
      options={addedData ? userOptions.concat(addedData) : userOptions}
      isLoading={isFetching}
      onInputChange={(value: string) => setFilter(value)}
      onChange={(option: any) => {
        onChange && onChange(option && option.value);
        props.onChangeCompleteObject &&
          props.onChangeCompleteObject(option && option.data);
      }}
      isClearable={isClearable ? isClearable : false}
      value={selectedOption}
      {...props}
    />
  );
};
