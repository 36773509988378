import { displayMonth } from '@commons';
import {
  IJoinedCraWithInvoice,
  ICra,
  IMission,
  IMinifiedUser,
} from '@freelancelabs/teoreme-commons';
import {
  Text,
  Flex,
  Box,
  Button,
  FormControl,
  LabelField,
  Radio,
} from 'components/ui';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { create } from 'react-modal-promise';
import { ModalFrame, ModalProps } from '../ModalFrame';
type Props = ModalProps & {
  beforeValidation?: () => void;
  mission:
    | IMission
    | (Omit<IMission, 'accountManager'> & { accountManager: IMinifiedUser });
  cra: IJoinedCraWithInvoice | ICra;
  customerReference: string;
  refetchQueryKey: string;
};
type FormValues = any;
export const CraFreeDaysBillingModalModal = ({ onResolve, cra }: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [billFreeDaysToCustomer, setBillFreeDaysToCustomer] = useState(false);
  const allowFreeDays = cra?.nbFreeDays && cra?.nbFreeDays > 0;
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {},
  });
  const onSubmit = async () => {
    setIsLoading(true);
    await onResolve({ billFreeDaysToCustomer: billFreeDaysToCustomer });
    setIsLoading(false);
  };

  const month = displayMonth(cra?.month);
  return (
    <ModalFrame
      isOpen={true}
      onClose={() => onResolve(false)}
      width={550}
      layer={150}
      closeIcon
    >
      <Box>
        <Text variant="h1" mb={20}>
          Jours gratuits du ({month})
        </Text>
        <Text variant="p" mb={20}>
          Choix du type de facturation de jours gratuits
        </Text>
        <LabelField mb={20} label="CRA concerné :" value={cra?.refCra} />
        <form onSubmit={handleSubmit(onSubmit)}>
          {allowFreeDays && (
            <Box width={12 / 12}>
              <FormControl
                required
                label={'Facturation de la Gratuité sur ce CRA ?'}
                errorMessage={errors?.billingInformation?.structure?.message}
              >
                <Box mt={20}>
                  <Radio
                    {...register('billFreeDaysToCustomer')}
                    value={'true'}
                    onClick={e => setBillFreeDaysToCustomer(true)}
                  >
                    Facturer {cra?.nbWorkingDays} jours au client (pas de
                    remise)
                  </Radio>
                  <Radio
                    {...register('billFreeDaysToCustomer')}
                    value={'false'}
                    onClick={e => setBillFreeDaysToCustomer(false)}
                    checked={!billFreeDaysToCustomer}
                  >
                    Facturer{' '}
                    {(cra?.nbWorkingDays ?? 0) - (cra?.nbFreeDays ?? 0)} jours
                    au client (remise de {cra?.nbFreeDays ?? 0} jours)
                  </Radio>
                </Box>
              </FormControl>
            </Box>
          )}
          <Flex alignItems="center">
            <Button type="submit" isLoading={isLoading}>
              Valider le cra
            </Button>
          </Flex>
        </form>
      </Box>
    </ModalFrame>
  );
};

export const showCraFreeDaysBillingModalModal = create<Props, boolean>(
  CraFreeDaysBillingModalModal
);
