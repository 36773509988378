import { Box, Button, Flex, Radio, Text } from 'components/ui';
import { ReactChild, ReactNode, useState } from 'react';
import { create } from 'react-modal-promise';
import styled from 'styled-components';
import { ModalFrame, ModalProps } from '../ModalFrame';

const Actions = styled.div`
  display: flex;
  justify-content: center;
`;

type Props = ModalProps & {
  confirmLabel?: string;
  cancelLabel?: string;
  title?: string | ReactNode;
  text?: string | ReactNode;
  closeIcon?: boolean;
  radioConfirmation?: boolean;
  checkboxConfirmation?: string[];
  isDisabled?: boolean;
  downloadPdfFile: (param?: any) => void;
  beforeValidation?: (param?: any) => void;
  downloadDocFile?: (param?: any) => void;
  closeModal?: (param?: any) => void;
  children?: ReactChild;
  layer?: number;
  width?: number;
};

export const SelectAdditionalActivityFileTypeDialogModal = ({
  onResolve,
  isOpen,
  text,
  title,
  closeIcon = false,
  isDisabled,
  beforeValidation,
  downloadDocFile,
  downloadPdfFile,
  closeModal,
  children,
  layer,
  width,
}: Props) => {
  const [loading, setLoading] = useState(false);
  const [iswordConfirmed, setIsWordRadioConfirmed] = useState(false);

  const exitModal = () => {
    if (closeModal) closeModal();
    onResolve(false);
  };

  const onValidate = async (updateCounter = true) => {
    setLoading(true);
    try {
      if (iswordConfirmed) {
        if (downloadDocFile) await downloadDocFile();
      } else {
        downloadPdfFile();
      }
      // if (beforeValidation) {
      //   await beforeValidation();
      // }
      onResolve(true);
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  };

  return (
    <ModalFrame
      isOpen={isOpen}
      onClose={() => {
        exitModal();
      }}
      width={width ? width : 650}
      closeIcon={closeIcon}
      layer={layer}
    >
      {title && (
        <Text variant="h1" mb={30}>
          {title}
        </Text>
      )}
      {text && (
        <Text variant="p" mb={30}>
          Choisissez le format du fichier à télécharger
        </Text>
      )}
      <Box mb={20}>
        <Radio
          name="confirmationWord"
          onClick={() => {
            setIsWordRadioConfirmed(false);
          }}
          onChange={() => {
            setIsWordRadioConfirmed(false);
          }}
          checked={!iswordConfirmed}
        >
          PDF (.pdf, non éditable)
        </Radio>
        <Radio
          name="confirmationWord"
          onClick={() => {
            setIsWordRadioConfirmed(true);
          }}
          onChange={() => {
            setIsWordRadioConfirmed(true);
          }}
        >
          Word (.docx, éditable)
        </Radio>
      </Box>
      {children ? (
        <Box mt={-20} mb={20}>
          {children}
        </Box>
      ) : (
        <></>
      )}
      <Flex justifyContent={'flex-start'} mt={40}>
        <Actions>
          <Button
            isDisabled={isDisabled}
            onClick={() => onValidate(true)}
            mr={20}
            isLoading={loading}
          >
            Télécharger
          </Button>
          <Button variant="ghost" ml={10} onClick={() => exitModal()}>
            Annuler
          </Button>
        </Actions>
      </Flex>
    </ModalFrame>
  );
};

export const showSelectAdditionalActivityFileTypeDialogModal = create<
  Props,
  boolean
>(SelectAdditionalActivityFileTypeDialogModal);
