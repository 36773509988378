import {
  IUserLocal,
  IUserMinimal,
  validateFormPhoneFormatFR,
  checkFormEmail,
  useMe,
  checkUserHasRole,
  ERoles,
} from '@commons';
import { ConfirmNavigationModal } from 'components/modals/ConfirmNavigationModal';
import {
  Box,
  Button,
  FormControl,
  FormInfo,
  Input,
  Radio,
  Row,
  Text,
  PhoneInputControlled,
  CheckSwitch,
  BlocInformation,
} from 'components/ui';
import { useState } from 'react';
import { useForm } from 'react-hook-form';

type FormValues = Partial<Omit<IUserLocal, 'civility'>> & {
  civility: string;
};
type OthersContactFormProps = {
  defaultValues?: Partial<IUserMinimal>;
  onSubmit?: (user: FormValues) => any | void;
  // contactRole?:
  //   | typeof ERoles.INTERNAL_COMMERCIAL
  //   | typeof ERoles.BUSINESS_PARTNER
  //   | typeof ERoles.BUSINESS_PARTNER;
};

export const OthersContactForm = ({
  defaultValues,
  onSubmit: onSubmitParent,
}: OthersContactFormProps) => {
  const { me } = useMe();
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isDirty, isSubmitSuccessful },
    watch,
  } = useForm<FormValues>({
    defaultValues: {
      ...defaultValues,
      civility: defaultValues?.civility + '',
    },
  });
  const onSubmit = async (formValues: FormValues) => {
    setLoading(true);
    if (onSubmitParent) {
      await onSubmitParent(formValues);
    }
    setLoading(false);
  };
  const havePortalClientAccess = watch('havePortalClientAccess');
  const editPortal =
    checkUserHasRole(me, ERoles?.ADMIN) ||
    checkUserHasRole(me, 'PORTAL_CLIENT_ADMIN');
  // DO NOT UPDATE INTERNAL_COMMERCIAL
  const isEditable = false;
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <ConfirmNavigationModal active={isDirty && !isSubmitSuccessful} />
      <Box mb={20} width={1 / 1}>
        <FormControl label="Autre adresse email">
          <>
            <Input
              isFullWidth
              isDisabled={!isEditable}
              {...register('alternateEmail', {
                required: false,
                validate: value => checkFormEmail(value as string),
              })}
            />
            {!!errors?.alternateEmail && (
              <FormInfo variantColor="error">Email incorrect</FormInfo>
            )}
          </>
        </FormControl>

        {editPortal &&
          checkUserHasRole(defaultValues, ERoles.INTERNAL_COMMERCIAL) && (
            <Box>
              {!checkUserHasRole(
                defaultValues,
                'PORTAL_CLIENT_CUSTOMER_ACCESS'
              ) && (
                <BlocInformation>
                  Pour donner l'accès au portail client veuillez lui associer un
                  établissement via l'onget 'établissements'.
                </BlocInformation>
              )}
              <FormControl
                mt={20}
                label="Accès au Portail Client Freelance.com"
              >
                <>
                  <CheckSwitch
                    isDisabled={
                      !checkUserHasRole(
                        defaultValues,
                        'PORTAL_CLIENT_CUSTOMER_ACCESS'
                      )
                    }
                    id="havePortalClientAccess"
                    mb={10}
                    {...register('havePortalClientAccess')}
                  >
                    <Text variant="span">
                      {havePortalClientAccess
                        ? 'A accès au Portail Client Freelance.com'
                        : 'N’a pas accès au Portail Client Freelance.com'}
                    </Text>
                  </CheckSwitch>
                </>
              </FormControl>
            </Box>
          )}
      </Box>
      {
        /*!isNew*/ false && (
          <Text variant="h2" mb={10}>
            Informations du contact
          </Text>
        )
      }
      <Box>
        <FormControl
          label={'Civilité'}
          errorMessage={errors?.civility?.message}
        >
          <>
            <Radio
              isDisabled={!isEditable}
              {...register('civility', {
                //required: 'Ce champ est requis',
              })}
              value="1"
            >
              Monsieur
            </Radio>
            <Radio
              isDisabled={!isEditable}
              {...register('civility', {
                //required: 'Ce champ est requis',
              })}
              value="2"
            >
              Madame
            </Radio>
          </>
        </FormControl>
      </Box>
      <Row spacing={20}>
        <FormControl label="Prénom" errorMessage={errors?.firstName?.message}>
          <Input
            isDisabled={!isEditable}
            isFullWidth
            {...register('firstName', {
              //required: 'Ce champ est requis',
            })}
            onChange={e => {
              // @ts-ignore
              e.target.value = e.target.value.trimStart();
            }}
          />
        </FormControl>
        <FormControl label="Nom" errorMessage={errors?.lastName?.message}>
          <Input
            isDisabled={!isEditable}
            isFullWidth
            {...register('lastName', {
              //required: 'Ce champ est requis',
            })}
            onChange={e => {
              // @ts-ignore
              e.target.value = e.target.value.trimStart();
            }}
          />
        </FormControl>
      </Row>

      <Row spacing={20}>
        <FormControl label="Téléphone" errorMessage={errors?.phone?.message}>
          <PhoneInputControlled
            disabled={!isEditable}
            rules={{
              validate: (value: string) => {
                return validateFormPhoneFormatFR(value);
              },
            }}
            isFullWidth
            name="phone"
            control={control}
          />
        </FormControl>
        <></>
      </Row>
      <Box>
        {checkUserHasRole(defaultValues, ERoles.INTERNAL_COMMERCIAL) && (
          <Button
            type="submit"
            isLoading={loading}
            isDisabled={loading}
            data-cy="submit-create-contact"
          >
            Enregistrer
          </Button>
        )}
      </Box>
    </form>
  );
};
