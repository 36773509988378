import * as React from 'react';
import DatePicker, {
  ReactDatePickerProps,
  registerLocale,
} from 'react-datepicker';
import { Input } from './Input';
import 'react-datepicker/dist/react-datepicker.css';
import { CalendarIcon, ChevronRightIcon } from 'components/ui/icons';
import styled from 'styled-components';
import { Theme } from '../../styles';
import { fr } from 'date-fns/locale';

registerLocale('fr', fr);

type Props = Omit<ReactDatePickerProps, 'onChange'> & {
  setStartDate: (date: Date | null) => void;
  setEndDate: (date: Date | null) => void;
};

const Styles = styled.div`
  display: flex;
  align-items: center;

  .react-datepicker__close-icon {
    right: 40px;
  }

  .react-datepicker-wrapper {
    margin: 0px;
  }

  .react-datepicker__header {
    padding: 10px 0;
  }

  .react-datepicker {
    font-family: 'Montserrat';
    font-size: 14px;
  }
  .react-datepicker__month-wrapper {
    padding: 10px 0;
  }

  .react-datepicker__month .react-datepicker__month-text,
  .react-datepicker__month .react-datepicker__quarter-text {
    padding: 6px;
  }

  .react-datepicker__month--selected,
  .react-datepicker__month--in-selecting-range,
  .react-datepicker__month--in-range,
  .react-datepicker__quarter--selected,
  .react-datepicker__quarter--in-selecting-range,
  .react-datepicker__quarter--in-range {
    background-color: ${Theme.colors.primary.light};
    z-index: 9999;
  }
  .react-datepicker__day--keyboard-selected,
  .react-datepicker__month-text--keyboard-selected,
  .react-datepicker__quarter-text--keyboard-selected,
  .react-datepicker__year-text--keyboard-selected {
    background-color: ${Theme.colors.primary.default};
    z-index: 9999;
  }
`;

export const DatePickerRange: React.FC<React.PropsWithChildren<Props>> = ({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  ...props
}) => {
  return (
    <Styles>
      <DatePicker
        {...props}
        selected={startDate}
        onChange={setStartDate}
        selectsStart
        startDate={startDate}
        endDate={endDate}
        locale="fr"
        dateFormat="MM/yyyy"
        showMonthYearPicker
        customInput={<Input isFullWidth icon={<CalendarIcon />} />}
      />
      <ChevronRightIcon
        color={Theme.colors.primary.default}
        height={20}
        width={20}
        style={{ marginLeft: '5px', marginRight: '5px' }}
      />
      <DatePicker
        {...props}
        selected={endDate}
        onChange={setEndDate}
        selectsEnd
        startDate={startDate}
        endDate={endDate}
        locale="fr"
        dateFormat="MM/yyyy"
        showMonthYearPicker
        customInput={<Input isFullWidth icon={<CalendarIcon />} />}
      />
    </Styles>
  );
};
