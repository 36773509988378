import {
  Link,
  FileInput,
  Box,
  FormControl,
  Input,
  Text,
  Button,
  Flex,
} from 'components/ui';
import {
  cutLongText,
  additionalActivityUpdateOne,
  uploadFile,
  queryClient,
} from '@commons';
import { AddIcon } from 'components/ui/icons';
import { useForm } from 'react-hook-form';
import React, { useState } from 'react';
import { create } from 'react-modal-promise';
import { ModalFrame, ModalProps } from '../ModalFrame';
import {
  EFileType,
  IJoinedAdditionalActivity,
} from '@freelancelabs/teoreme-commons';
type FormValues = {
  reference: string;
};
type ImportBDCAdditionalActivityModalProps = ModalProps & {
  beforeValidation?: () => void;
  additionalActivity: IJoinedAdditionalActivity;
};

export const ImportBDCAdditionalActivityModal: React.FC<
  React.PropsWithChildren<ImportBDCAdditionalActivityModalProps>
> = ({ onResolve, isOpen, additionalActivity }) => {
  const purchaseOrder = additionalActivity?.purchaseOrder?.file;
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState<File | false>(false);
  const [fileUrl, setFileUrl] = useState<string>();
  const [fileError, setFileError] = useState<string | false>(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {
      reference: additionalActivity?.purchaseOrder?.reference,
    },
  });
  const onImportPo = (fileSelected: File[]) => {
    const bdcFileUrl = fileSelected[0]
      ? URL?.createObjectURL(fileSelected[0])
      : fileUrl;
    setFile(fileSelected[0]);
    setFileUrl(bdcFileUrl);
    setFileError(false);
  };
  const onSubmit = async (formValues: FormValues) => {
    let onError = false;
    if (!file && !purchaseOrder) {
      onError = true;
      setFileError('Ce champs est requis');
    }

    if (!onError) {
      setLoading(true);
      if (file) {
        try {
          const s3Response = await uploadFile({
            file: file as File,
            fileType: EFileType.PURCHASE_ORDER_ADDITIONAL_ACTIVITY,
            actionType: 'upload',
            additionalActivity: additionalActivity?.uuid || '',
          });
          if (!s3Response) throw new Error();
          await additionalActivityUpdateOne({
            uuid: additionalActivity?.uuid || '',
            // @ts-ignore
            purchaseOrder: {
              file: {
                eTag: s3Response?.eTag,
                fileLocation: s3Response?.fileLocation,
              },
              reference: formValues?.reference,
            },
          });
          queryClient.refetchQueries({
            queryKey: [additionalActivity.uuid || ''],
          });
        } catch (e) {
          //
        }

        setLoading(false);
        onResolve(true);
      } else {
        try {
          await additionalActivityUpdateOne({
            uuid: additionalActivity?.uuid || '',
            // @ts-ignore
            purchaseOrder: {
              reference: formValues?.reference,
            },
          });
          setLoading(false);
          queryClient.refetchQueries({
            queryKey: [additionalActivity.uuid || ''],
          });
        } finally {
          setLoading(false);
          onResolve(true);
        }
      }
    }
  };

  return (
    <ModalFrame
      isOpen={isOpen}
      onClose={() => onResolve(false)}
      width={fileUrl ? 1380 : 520}
      closeIcon
    >
      <Flex width={1 / 1} minHeight={400}>
        <Box width={fileUrl ? 2 / 5 : 1 / 1} paddingRight={fileUrl ? 40 : 1}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box>
              <Text variant="h2" mb={20}>
                Importer un nouveau BDC client
              </Text>
              <Box>
                <FormControl
                  label="référence"
                  required
                  errorMessage={errors?.reference?.message}
                >
                  <Input
                    isFullWidth
                    type="text"
                    {...register('reference', {
                      required: 'Ce champ est requis',
                    })}
                  />
                </FormControl>
              </Box>

              <FormControl label="" errorMessage={fileError}>
                <FileInput
                  onSelect={onImportPo}
                  accept=".pdf"
                  subLabel="Format accepté : PDF"
                >
                  <Link iconLeft={<AddIcon />}>
                    {/*@ts-ignore*/}
                    {!file
                      ? purchaseOrder?.fileLocation
                        ? cutLongText(
                            purchaseOrder.fileLocation?.split('/')[
                              purchaseOrder.fileLocation?.split.length + 1
                            ],
                            50
                          )
                        : 'Importer le bon de commande'
                      : file?.name}
                  </Link>
                </FileInput>
              </FormControl>
            </Box>
            <Box mt={20}>
              <Button isLoading={loading} type="submit">
                Importer
              </Button>
            </Box>
          </form>
        </Box>
        {fileUrl && (
          <Box width={3 / 5} minHeight={900} p={10}>
            <iframe
              src={fileUrl + '#toolbar=0'}
              title="bdc"
              style={{ width: '100%', height: '100%' }}
            ></iframe>
          </Box>
        )}
      </Flex>
    </ModalFrame>
  );
};

export const showImportBDCAdditionalActivityModal =
  create<ImportBDCAdditionalActivityModalProps>(
    ImportBDCAdditionalActivityModal
  );
