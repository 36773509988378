import * as React from 'react';

function SvgArrowUpIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 16 16" {...props}>
      <path
        fillRule="evenodd"
        d="M8.457 6.2l3.428 4c.282.33.013.8-.457.8H4.572c-.47 0-.74-.47-.457-.8l3.428-4a.622.622 0 01.914 0z"
      />
    </svg>
  );
}

export default SvgArrowUpIcon;
