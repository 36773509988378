import {
  EFileType,
  EQuoteStatus,
  EadditionalActivityType,
  IJoinedAdditionalActivity,
  IJoinedAdditionalActivityWithInvoice,
} from '@freelancelabs/teoreme-commons';
import { useAdditionalActivityUpdateOne } from '@commons';
import { uploadFile } from '@commons';
import { FileCard } from 'components/cards/FileCard';
import {
  BlocInformation,
  FileInput,
  Flex,
  Link,
  MenuItem,
  SpinnerBox,
  Status,
  Text,
} from 'components/ui';
import { SendIcon, UploadIcon } from 'components/ui/icons';
import React, { useState } from 'react';
import AddBloc from 'components/AddBloc';
import { FR_STATUS, formatDate, queryClient } from '@commons';
import { showGenerateActivityQuoteModal } from 'components/modals/activities/GenerateActivityQuoteModal';

type ActivityQuoteCardProps = {
  additionalActivity:
    | IJoinedAdditionalActivity
    | IJoinedAdditionalActivityWithInvoice
    | undefined;
  isDisabled: boolean;
};

export const ActivityQuoteCard = ({
  additionalActivity,
  isDisabled,
}: ActivityQuoteCardProps) => {
  const [loading, setLoading] = useState(false);
  const { mutateAsync: updateAdditionalActivity } =
    useAdditionalActivityUpdateOne();
  const mission: any = additionalActivity?.mission;

  const createFileName = (activity: any) => {
    const cra = activity?.cra;
    const typeText =
      activity?.type === EadditionalActivityType.STAND_BY_DUTY ? 'AS' : 'FR';
    return `DV_${typeText}_${cra?.refCra}_${cra?.mission}.pdf`;
  };

  const onImportQuote = async (fileSelected: File[] | null) => {
    if (!fileSelected) return;
    setLoading(true);

    try {
      const s3Response = await uploadFile({
        file: fileSelected[0],
        fileType: EFileType.STAND_BY_DUTY_QUOTE,
        actionType: 'upload',
        missionRef: mission.reference,
      });

      if (!s3Response) throw new Error();

      await updateAdditionalActivity({
        uuid: additionalActivity?.uuid,
        incQuoteCounter: true,
        quote: {
          file: {
            eTag: s3Response?.eTag,
            fileLocation: s3Response?.fileLocation,
            fileName: createFileName(additionalActivity),
          },
          status: EQuoteStatus.IMPORTED,
        },
      });

      queryClient.refetchQueries({ queryKey: [additionalActivity?.uuid] });

      setLoading(false);
    } finally {
      setLoading(false);
    }
  };
  if (loading) return <SpinnerBox height={92} />;
  if (isDisabled && !additionalActivity?.quote) {
    return (
      <BlocInformation>
        <p>Aucun devis client</p>
      </BlocInformation>
    );
  }
  if (!additionalActivity?.quote && !isDisabled) {
    return (
      <>
        <AddBloc>
          <Flex>
            {additionalActivity?.uuid ? (
              <>
                <Link
                  iconLeft={<SendIcon />}
                  onClick={() =>
                    showGenerateActivityQuoteModal({
                      additionalActivity:
                        additionalActivity as IJoinedAdditionalActivity,
                    })
                  }
                >
                  Génerer et envoyer le devis
                </Link>

                <Text m="0 10px">ou</Text>
              </>
            ) : (
              <>
                <Link variantColor="grey" iconLeft={<SendIcon />}>
                  Génerer et envoyer le devis
                </Link>

                <Text m="0 10px">ou</Text>
              </>
            )}
            <FileInput onSelect={onImportQuote} variant="link" accept={'.pdf'}>
              <Link iconLeft={<UploadIcon />}>Importer le devis (PDF)</Link>
            </FileInput>
          </Flex>
        </AddBloc>
      </>
    );
  }
  return (
    <FileCard
      layer={105}
      fileName={additionalActivity?.quote?.file?.fileName || ''}
      fileLocation={
        (additionalActivity && additionalActivity.quote?.file.fileLocation) ||
        ''
      }
      complement={
        <Status variantColor="success">{`${
          additionalActivity?.quote?.status
            ? FR_STATUS[additionalActivity.quote?.status]
            : ''
        } le ${formatDate(
          additionalActivity?.quote?.updatedAt ||
            additionalActivity?.quote?.createdAt ||
            ''
        )}`}</Status>
      }
      actions={
        !isDisabled && (
          <>
            <MenuItem
              onClick={() => {
                showGenerateActivityQuoteModal({
                  additionalActivity:
                    additionalActivity as IJoinedAdditionalActivity,
                });
              }}
            >
              <Link iconLeft={<SendIcon />}>Génerer et envoyer un devis</Link>
            </MenuItem>
            <MenuItem>
              <FileInput
                variant="link"
                onSelect={onImportQuote}
                accept={'.pdf'}
              >
                <Link iconLeft={<UploadIcon />}>Importer un autre devis</Link>
              </FileInput>
            </MenuItem>
          </>
        )
      }
    />
  );
};
