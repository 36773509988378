import {
  checkUserHasRole,
  formatAllObject,
  IUserLocal,
  IUserMinimal,
  userFindMany,
  escapeEmail,
  validateFormPhoneFormatFR,
} from '@commons';
import { useRegister } from '@commons';
import { ContactExistsMessage } from 'components/ContactExistsMessage';
import { ERoles } from '@commons';
import { ConfirmNavigationModal } from 'components/modals/ConfirmNavigationModal';
import {
  Box,
  Button,
  FormControl,
  Input,
  Radio,
  Row,
  Text,
  PhoneInputControlled,
} from 'components/ui';
import { VerifyEmailForm } from 'forms/VerifyEmailForm';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { queryClient } from '@commons';

type FormValues = Partial<Omit<IUserLocal, 'civility'>> & {
  civility: string;
};
type ContactFormProps = {
  defaultValues?: Partial<IUserMinimal>;
  onSubmit?: (user: Partial<IUserMinimal>) => any | void;
  onClose: (newUser: IUserMinimal) => void;
};

export const AddFreelancePartnerForm = ({
  defaultValues,
  onSubmit: onSubmitParent,
  onClose,
}: ContactFormProps) => {
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isDirty, isSubmitSuccessful },
    setValue,
  } = useForm<FormValues>({
    defaultValues: {
      ...defaultValues,
      civility: defaultValues?.civility + '',
    },
  });
  const { mutateAsync: addContact } = useRegister();

  const onSubmit = async (formValues: FormValues) => {
    if (user) {
      setLoading(true);
      const values = {
        email: user?.email,
        ...formatAllObject(formValues),
      };
      try {
        const newUser = await addContact({
          user: values,
          roles: ERoles.FREELANCE_PARTNER,
        });
        if (newUser) {
          queryClient.refetchQueries({ queryKey: ['user'] });
          onClose(newUser);
        }
      } catch (e) {}
      setLoading(false);
    }
  };
  const [user, setUser] = useState<Partial<IUserLocal> | undefined>(undefined);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [userExist, setUserExist] = useState(false);
  const [errorExist, setErrorExist] = useState(false);
  const [emailChecked, setEmailChecked] = useState(false);

  const onCheckEmail = async ({ email }: { email?: string }) => {
    const values = await userFindMany({
      filterObject: {
        email: {
          $regex: `^${escapeEmail(email && email.trim())}$`,
          $options: 'i',
        },
      },
    });

    setEmailChecked(true);
    if (values?.users?.[0]) {
      const user = values.users[0];
      setUserExist(true);
      setUser(user);
      setValue('civility', String(user.civility));
      setValue('firstName', user.firstName);
      setValue('lastName', user.lastName);
      setValue('phone', user.phone);
      setErrorExist(checkUserHasRole(user, ERoles.FREELANCE_PARTNER));
    } else {
      setUserExist(false);
      setUser({ email });
    }
  };
  const handleReset = () => {
    setUser(undefined);
    setEmailChecked(false);
    setErrorExist(false);
    setUserExist(false);
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <ConfirmNavigationModal active={isDirty && !isSubmitSuccessful} />
      <Text variant="h2" mb={10}>
        Ajouter un freelance partner
      </Text>
      <Row spacing={20} mb={20}>
        <VerifyEmailForm
          disableConfirm
          contactRole={'FREELANCE_PARTNER'}
          onSubmit={onCheckEmail}
          isLocked={emailChecked}
          canModify
          onModify={handleReset}
        />
        <></>
      </Row>
      {user && (
        <Box mt={20}>
          <ContactExistsMessage
            contactRole={'FREELANCE_PARTNER'}
            contact={user as IUserLocal}
          />
        </Box>
      )}
      {emailChecked && (
        <Box mt={20}>
          <Text variant="h3" mb={20}>
            Informations personnelles
          </Text>
          <Box>
            <FormControl
              required
              label={'Civilité'}
              errorMessage={errors?.civility?.message}
            >
              <>
                <Radio
                  isDisabled={errorExist}
                  {...register('civility', { required: 'Ce champ est requis' })}
                  value="1"
                >
                  Monsieur
                </Radio>
                <Radio
                  isDisabled={errorExist}
                  {...register('civility', { required: 'Ce champ est requis' })}
                  value="2"
                >
                  Madame
                </Radio>
              </>
            </FormControl>
          </Box>
          <Row spacing={20}>
            <FormControl
              required
              label="Prénom"
              errorMessage={errors?.firstName?.message}
            >
              <Input
                isDisabled={errorExist}
                isFullWidth
                {...register('firstName', { required: 'Ce champ est requis' })}
              />
            </FormControl>
            <FormControl
              required
              label="Nom"
              errorMessage={errors?.lastName?.message}
            >
              <Input
                isDisabled={errorExist}
                isFullWidth
                {...register('lastName', { required: 'Ce champ est requis' })}
              />
            </FormControl>
          </Row>

          <Row spacing={20}>
            <FormControl
              label="Téléphone"
              errorMessage={errors?.phone?.message}
            >
              <PhoneInputControlled
                disabled={errorExist}
                control={control}
                rules={{
                  validate: (value: string) => {
                    return validateFormPhoneFormatFR(value);
                  },
                }}
                isFullWidth
                name="phone"
              />
            </FormControl>
            <></>
          </Row>
          <Box>
            <Button type="submit" isLoading={loading} isDisabled={errorExist}>
              Ajouter
            </Button>
          </Box>
        </Box>
      )}
    </form>
  );
};
