import {
  formatDate,
  replaceSpecificCaractereInFileName,
  useCraFindOne,
  useDownloadFile,
  useContractorFindOne,
  CONTRACTOR_SOCIAL_STATUS,
  getFirstCaracts,
  getFullName,
  getHumanDate,
  checkAvalaibleCreateCra,
  getTradeNameSafe,
  craUpdateOne,
} from '@commons';
import {
  ALink,
  FormLabel,
  Box,
  Button,
  Flex,
  LabelField,
  Link,
  Row,
  SpinnerBox,
  Text,
} from 'components/ui';
import {
  EstablishmentStructuredCard,
  UserStructuredCard,
  StructuredCard,
} from 'components/cards';
import {
  CancelIcon,
  ChevronLeftIcon,
  CloseIcon,
  DownloadIcon,
} from 'components/ui/icons';
import React, { useEffect, useRef, useState } from 'react';
import { create } from 'react-modal-promise';
import { useLocation } from 'react-router-dom';
import styled, { createGlobalStyle } from 'styled-components';
import {
  IJoinedCraWithInvoice,
  IEstablishment,
  IMission,
  ECraStatus,
} from '@freelancelabs/teoreme-commons';
import { showUpdateInvoiceReferenceModal } from 'components/modals/cra/UpdateInvoiceReferenceModal';
import { queryClient } from '@commons';
import { useShowMessage } from 'hooks/useShowMessage';
import { ModalProps } from './ModalFrame';
import { showCraFreeDaysBillingModalModal } from './cra/CraFreeDaysBillingModal';
const GlobalStyle = createGlobalStyle`
    body {
      overflow : hidden;
    }
  `;

const FixedContainer = styled.div<{ layer?: number }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  z-index: 100;
  overflow-y: auto;
  padding: 80px 0 40px 0;
  z-index: ${props => (props.layer || 0) + 101};
`;

const FileContainer = styled.div`
  position: relative;
  box-sizing: border-box;
  width: 100%;
  display: block;
  z-index: 101;
`;

const Background = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: #000;
  opacity: 0.5;
`;

const TopBar = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  height: 80px;
  background-color: #000;
  z-index: 200;
  display: flex;
  align-items: center;
  padding: 0 30px;
`;
type ValidateCraModalProps = ModalProps & {
  layer?: number;
  fileLocation?: string;
  fileName?: string;
  onResolve: (result?: any) => void;
  isOpen: boolean;
  confirmLabel?: string;
  rejectLabel?: string;
  nextLabel?: string;
  previousLabel?: string;
  hookStore?: () => any;
  onConfirm?: (data?: any) => any;
  onRejectCra?: (cra: IJoinedCraWithInvoice) => Promise<any>;
  onCancel?: (data?: any) => any;
  downloadConfirm?: () => Promise<any>;
  refCra: string;
};
type hookStoreProps = {
  craToBeValidated: IJoinedCraWithInvoice[] | [];
};
export const ValidateCraModal = ({
  onResolve,
  isOpen,
  layer,
  // fileLocation,
  // fileName,
  confirmLabel,
  rejectLabel,
  nextLabel,
  previousLabel,
  hookStore,
  onConfirm,
  onRejectCra,
  onCancel,
  downloadConfirm,
  refCra,
}: ValidateCraModalProps) => {
  const { craToBeValidated }: hookStoreProps = hookStore ? hookStore() : {};
  const showMessage = useShowMessage();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [currentCra, setCurrentCra] = useState(refCra);
  const haveNext =
    craToBeValidated &&
    craToBeValidated?.findIndex(c => c?.refCra === currentCra) + 1 <=
      craToBeValidated?.length - 1;
  const havePrevious =
    craToBeValidated &&
    craToBeValidated?.findIndex(c => c?.refCra === currentCra) - 1 >= 0;
  const { data: cra, isFetching: isFetchingCra } = useCraFindOne(
    currentCra,
    true
  );
  const avalaibleCreateCra = checkAvalaibleCreateCra(
    cra as IJoinedCraWithInvoice
  );
  const fileLocation = cra?.proof?.fileLocation;
  const fileName = cra?.proof?.fileName;
  // hide on location change
  const location = useLocation();
  const isMountRef = useRef(true);
  useEffect(() => {
    if (!isMountRef.current) onResolve();
    isMountRef.current = false;
  }, [location, onResolve]);
  // load file
  const [file, setFile] = useState<string>();
  const [actionLoading, setActionLoading] = useState(false);
  const { isFetching: isFetchingFile, data } = useDownloadFile(
    fileLocation as string
  );

  useEffect(() => {
    // use for download file
    if (data?.data) {
      const reader = new FileReader();
      reader.readAsDataURL(data.data); // converts the blob to base64 and calls onload
      reader.onload = () => {
        setFile(reader.result as string);
      };
    }
  }, [data]);

  const loading = isFetchingFile || isFetchingCra;
  const validateCraAction = async (invoiceCustomerRefObj?: any) => {
    try {
      const customCustomerReference =
        invoiceCustomerRefObj?.customCustomerReference;
      const billFreeDaysToCustomer =
        invoiceCustomerRefObj?.billFreeDaysToCustomer;

      const updateCRAResponse = await craUpdateOne({
        refCras: [cra?.refCra as string],
        cra: {
          state: ECraStatus.VALIDATED,
          customerReference: customCustomerReference,
          // @ts-ignore
          billFreeDaysToCustomer: billFreeDaysToCustomer,
        },
      });
      if (
        //@ts-ignore
        updateCRAResponse?.errorUpdateArray &&
        //@ts-ignore
        updateCRAResponse?.errorUpdateArray?.length > 0
      ) {
        showMessage('error', 'Une erreur est survenue sur le  CRA');
      } else {
        showMessage('success', 'Le CRA à été validé');
        if (craToBeValidated && haveNext) {
          handleChangeCra(1);
        } else {
          onResolve(true);
        }
        await queryClient.invalidateQueries({ queryKey: ['Cras'] });
        await queryClient.refetchQueries({
          queryKey: ['Cras'],
          type: 'active',
        });
        if (customCustomerReference) {
          await onConfirm?.(customCustomerReference);
        }

        setActionLoading(false);
      }
    } catch (e) {
      setActionLoading(false);
    }
    setActionLoading(false);
  };
  const handleConfirm = async () => {
    setActionLoading(true);
    const craMission = cra?.mission;
    if (craMission?.isMandateMode) {
      if (cra?.nbFreeDays && cra?.nbFreeDays > 0) {
        await showCraFreeDaysBillingModalModal({
          mission: cra?.mission as IMission,
          cra: cra as IJoinedCraWithInvoice,
          customerReference: cra?.mission?.customer?.purchaseOrder
            ?.reference as string,
          refetchQueryKey: 'Cras',
        })?.then(async (billFreeDaysToCustomerObject: any) => {
          if (billFreeDaysToCustomerObject) {
            await validateCraAction({
              billFreeDaysToCustomer:
                billFreeDaysToCustomerObject?.billFreeDaysToCustomer,
            });
          }
          setActionLoading(false);
        });
      } else {
        await validateCraAction();
      }
      setActionLoading(false);
      return;
    } else {
      showUpdateInvoiceReferenceModal({
        mission: cra?.mission as IMission,
        delivreyOrders: {
          bdlProvider: false,
          bdlCustomer: false,
        },
        cra: cra as IJoinedCraWithInvoice,
        customerReference: cra?.mission?.customer?.purchaseOrder
          ?.reference as string,
        refetchQueryKey: 'Cras',
      })?.then(async (invoiceCustomerRefObj: any) => {
        if (invoiceCustomerRefObj) {
          await validateCraAction(invoiceCustomerRefObj);
        } else {
          setActionLoading(false);
        }
      });
    }
  };

  const handleReject = async () => {
    setActionLoading(true);
    if (onRejectCra) {
      const currentCRA = craToBeValidated?.find(c => c?.refCra === currentCra);
      await onRejectCra(
        craToBeValidated && currentCRA
          ? (currentCRA as IJoinedCraWithInvoice)
          : (cra as IJoinedCraWithInvoice)
      );
      setActionLoading(false);
      if (craToBeValidated && haveNext) {
        handleChangeCra(1);
      } else {
        onResolve(true);
      }
    }
  };

  const onDownload = async () => {
    if (fileLocation && fileName) {
      const confirm = !downloadConfirm || (await downloadConfirm());
      if (!confirm) return;
      const a = document.createElement('a');
      a.href = file || '';
      a.download = fileName.endsWith('.pdf')
        ? replaceSpecificCaractereInFileName(fileName)
        : replaceSpecificCaractereInFileName(fileName) + '.pdf';
      a.click();
    }
  };

  const handleChangeCra = (index: number) => {
    const indexCurrentCRA = craToBeValidated?.findIndex(
      c => c?.refCra === currentCra
    );
    if (indexCurrentCRA !== -1) {
      const nexCurrentCra =
        craToBeValidated?.[(indexCurrentCRA as number) + index];
      setCurrentCra(nexCurrentCra?.refCra as string);
    }
  };
  useEffect(() => {
    if (!file && fileLocation) {
      const uploadFile = async (url: string) => {
        await fetch(url)
          .then(response => response.blob())
          .then(blob => {
            // Create a new FileReader innstance
            const reader = new FileReader();
            reader.readAsDataURL(blob); // converts the blob to base64 and calls onload

            reader.onload = () => {
              setFile(reader.result as string);
            };
          });
      };
      uploadFile(fileLocation);
    }
  }, [file]);
  const contractorUser = useContractorFindOne(cra?.contractor?.uuid as string);
  const contractorScopedData = cra?.contractor?.scopedData?.find(
    sd => sd.establishment === cra?.estProvider.uuid
  );
  return (
    <FixedContainer layer={layer}>
      <GlobalStyle />

      {isOpen && (
        <>
          <TopBar>
            <Flex flex={1} alignItems="center">
              <Link
                color="#fff"
                iconLeft={<ChevronLeftIcon fill="#fff" />}
                onClick={() => {
                  onCancel && onCancel();
                  onResolve();
                }}
              >
                Retour
              </Link>
            </Flex>
            <Flex alignItems="center">
              {previousLabel && (
                <Button
                  title="Revenir au cra précédent"
                  mr={30}
                  data-cy="confirm-modal-pdf"
                  isDisabled={!havePrevious}
                  onClick={() => handleChangeCra(-1)}
                >
                  {previousLabel}
                </Button>
              )}
              {nextLabel && (
                <Button
                  title="Passer au cra suivant"
                  mr={30}
                  isDisabled={!haveNext}
                  data-cy="confirm-modal-pdf"
                  onClick={() => handleChangeCra(1)}
                >
                  {nextLabel}
                </Button>
              )}
              {!rejectLabel && !loading && (
                <Link mr={30} iconLeft={<DownloadIcon />} onClick={onDownload}>
                  Télécharger
                </Link>
              )}
              {rejectLabel && avalaibleCreateCra?.ready && (
                <Link mr={30} iconLeft={<CancelIcon />} onClick={handleReject}>
                  {rejectLabel}
                </Link>
              )}
              {confirmLabel && avalaibleCreateCra?.ready && (
                <Button
                  mr={30}
                  data-cy="confirm-modal-pdf"
                  isLoading={actionLoading}
                  onClick={handleConfirm}
                >
                  {`Valider (${cra?.nbWorkingDays} jours)`}
                </Button>
              )}
              <CloseIcon
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  onCancel && onCancel();
                  onResolve();
                }}
                fontSize={20}
                fill="#fff"
              />
            </Flex>
          </TopBar>
          <Background />
          {loading && (
            <Box mt={100} zIndex={100} width="100%">
              <SpinnerBox color="#fff" />
            </Box>
          )}
          {!loading && (
            <Flex width={1 / 1}>
              <FileContainer>
                <Box hidden={!avalaibleCreateCra?.ready}>
                  <Flex
                    p={10}
                    width={1 / 1}
                    height={'calc(100vh - 80px)'}
                    overflowY={'auto'}
                    justifyContent={'center'}
                    backgroundColor="white"
                  >
                    <Box maxWidth={800}>
                      <Text variant="h2" mb={20}>
                        Informations du CRA
                      </Text>
                      <Row p={10} spacing={20}>
                        <LabelField
                          underline
                          label={'Référence du cra'}
                          value={cra?.refCra}
                        />
                        <LabelField
                          underline
                          label={'Référence de la mission'}
                          value={cra?.mission.displayReference}
                        />
                      </Row>
                      <Row p={10} spacing={20}>
                        <LabelField
                          underline
                          label={'Date de début de la mission'}
                          value={getHumanDate(cra?.mission?.startAt as Date)}
                        />
                        <LabelField
                          underline
                          label={'Date de fin de la mission'}
                          value={getHumanDate(cra?.mission?.endAt as Date)}
                        />
                      </Row>
                      <Box p={10} border={'1px solid red'} borderRadius={'8px'}>
                        <Row spacing={20}>
                          <LabelField
                            underline
                            label={'Mois concerné'}
                            value={
                              cra &&
                              formatDate(cra.month)?.substring(
                                (formatDate(cra.month)?.indexOf('/') || -1) + 1
                              )
                            }
                          />
                          <LabelField
                            underline
                            label={'Nombre de jours'}
                            value={`${cra?.nbWorkingDays} ${
                              cra?.nbFreeDays && cra?.nbFreeDays > 0
                                ? 'dont ' + cra?.nbFreeDays + ' gratuit(s)'
                                : ''
                            }`}
                          />
                        </Row>
                      </Box>
                      <Row p={10} spacing={20}>
                        <LabelField
                          underline
                          label={'Tarif jour payé par le client'}
                          value={`${cra?.mission.billing?.clientRate} €`}
                        />
                        <LabelField
                          underline
                          label="Tarif jour perçu par l'intervenant"
                          value={`${cra?.contractorRate} €`}
                        />
                      </Row>
                      <Box p={10} width={1 / 1}>
                        <LabelField
                          underline
                          label={'Commentaire de la part du fournisseur'}
                          value={cra?.accountManagerComment || 'N/A'}
                        />
                      </Box>
                      {cra?.contractor &&
                        contractorScopedData &&
                        contractorUser && (
                          <Box mt={20}>
                            <FormLabel>INTERVENANT</FormLabel>
                            <StructuredCard
                              mb={20}
                              avatarLetter={getFirstCaracts(cra?.contractor)}
                              primaryTopText={getFullName(cra?.contractor)}
                              secondaryTopText={
                                contractorScopedData?.jobTitle || ''
                              }
                              primaryBottomText={
                                contractorScopedData?.phone
                                  ? `Téléphone lié à l'établissement : ${contractorScopedData?.phone}`
                                  : undefined
                              }
                              secondaryBottomText={`Email : ${
                                contractorScopedData?.email || 'N/A'
                              }`}
                              secondLineBottomText={`Statut social : ${
                                contractorScopedData?.socialStatus
                                  ? CONTRACTOR_SOCIAL_STATUS[
                                      contractorScopedData?.socialStatus
                                    ]
                                  : '-'
                              }`}
                            />
                          </Box>
                        )}
                      {cra?.estCustomer && (
                        <Box mt={20}>
                          <FormLabel>ÉTABLISSEMENT CLIENT</FormLabel>
                          <EstablishmentStructuredCard
                            establishment={cra?.estCustomer}
                            forRole={'CUSTOMER'}
                          />
                        </Box>
                      )}
                      {cra?.mission?.customer?.contact && (
                        <Box mt={20}>
                          <FormLabel>CONTACT CLIENT</FormLabel>
                          <UserStructuredCard
                            user={cra?.mission?.customer?.contact}
                            forRole={'CUSTOMER'}
                          />
                        </Box>
                      )}

                      {cra?.estProvider && (
                        <Box mt={20}>
                          <FormLabel>ÉTABLISSEMENT FOURNISSEUR</FormLabel>
                          <EstablishmentStructuredCard
                            establishment={cra?.estProvider}
                            forRole={'PROVIDER'}
                          />
                        </Box>
                      )}

                      {cra?.providerContact && (
                        <Box mt={20}>
                          <FormLabel>CONTACT FOURNISSEUR</FormLabel>
                          <UserStructuredCard
                            user={cra?.providerContact}
                            forRole={'PROVIDER'}
                          />
                        </Box>
                      )}
                    </Box>
                  </Flex>
                </Box>
                <Box
                  hidden={avalaibleCreateCra?.ready}
                  backgroundColor="white"
                  height={'calc(100vh - 80px)'}
                  padding={10}
                >
                  <Text mb={20} textAlign={'center'} variant="h1">
                    Impossible de valider le CRA
                  </Text>
                  <Text>
                    Ce CRA ne peut être validé car certains liens avec la Gescom
                    ne sont pas créés pour cette mission. Veuillez mettre à jour
                    les liens Gescom suivants avant de valider ce CRA.
                    <Box mt={20} ml={20}>
                      <ul>
                        {!avalaibleCreateCra?.contractorReady && (
                          <li>
                            <Flex>
                              L'intervenant &nbsp;
                              <ALink
                                ml={'5px'}
                                href={`/providers/contractors/${cra?.contractor?.uuid}`}
                                target="_blank"
                              >
                                <Link>{getFullName(cra?.contractor)}</Link>
                              </ALink>
                            </Flex>
                          </li>
                        )}
                        {!avalaibleCreateCra?.estProviderReady && (
                          <li>
                            <Flex>
                              L'établissement fournisseur &nbsp;
                              <ALink
                                ml={'5px'}
                                href={`/providers/establishments/${cra?.estProvider?.uuid}/information`}
                                target="_blank"
                              >
                                <Link>
                                  {getTradeNameSafe(
                                    cra?.estProvider as IEstablishment
                                  )}
                                </Link>
                              </ALink>
                            </Flex>
                          </li>
                        )}
                        {!avalaibleCreateCra?.estCustomerReady && (
                          <li>
                            <Flex>
                              L'établissement client &nbsp;
                              <ALink
                                ml={'5px'}
                                href={`/clients/establishments/${cra?.estCustomer?.uuid}/information`}
                                target="_blank"
                              >
                                <Link>
                                  {getTradeNameSafe(
                                    cra?.estCustomer as IEstablishment
                                  )}
                                </Link>
                              </ALink>
                            </Flex>
                          </li>
                        )}
                      </ul>
                    </Box>
                  </Text>
                </Box>
              </FileContainer>

              <FileContainer>
                {cra?.proof?.fileLocation ? (
                  <iframe
                    //@ts-ignore
                    src={data && (data as any)?.config?.url}
                    frameBorder="0"
                    title="iframe"
                    style={{ width: '100%', height: 'calc(100vh - 80px)' }}
                  ></iframe>
                ) : (
                  <Box height={'calc(100vh - 80px)'} backgroundColor="#484c4e">
                    <Text paddingTop={'50%'} textAlign={'center'} color="white">
                      Aucun justificatif
                    </Text>
                  </Box>
                )}
              </FileContainer>
            </Flex>
          )}
        </>
      )}
    </FixedContainer>
  );
};

export const showValidateCraModal = create<ValidateCraModalProps, boolean>(
  ValidateCraModal
);
