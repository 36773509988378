import { ALink } from 'components/ALink';
import { Avatar, SpinnerBox } from 'components/ui';
import React from 'react';
import styled from 'styled-components';
import { cutLongText } from '@commons';
export type Props = {
  selected?: boolean;
  firstName?: string;
  lastName?: string;
};

const Container = styled(ALink)`
  display: flex;
  align-items: center;
  text-decoration: none;
  color: ${p => p.theme.colors.darkBlue};
  font-weight: 500;
`;

export const UserButton: React.FC<React.PropsWithChildren<Props>> = ({
  selected,
  firstName,
  lastName,
}) => {
  const name = `${firstName || ''} ${lastName || ''}` || '';

  return (
    <Container href="/user">
      {firstName || lastName ? (
        <>
          <Avatar
            textSize={'15px'}
            text={`${firstName?.[0] || ''}${lastName?.[0] || ''}`}
            size={35}
            mr={20}
          />
          {cutLongText(name, 25)}
        </>
      ) : (
        <SpinnerBox />
      )}
    </Container>
  );
};
UserButton.displayName = 'UserButton';

export default UserButton;
