import React, { ReactNode } from 'react';
import styled from 'styled-components';

export const AddBlocContainer = styled.a`
  background-color: ${p => p.theme.colors.superLightBlue};
  height: 90px;
  color: ${p => p.theme.colors.blue};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-top: 15px;
  border-radius: 8px;
`;
type Props = {
  children?: ReactNode;
};

export const AddBloc: React.FC<React.PropsWithChildren<Props>> = ({
  children,
}) => {
  return <AddBlocContainer>{children}</AddBlocContainer>;
};

export default AddBloc;
