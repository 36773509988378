import { cutLongText } from '@commons';
import * as React from 'react';
import { Box, BoxProps } from '../Box';
import { CheckBox } from '../CheckBox';
import { LoaderLine } from './components/LoaderLine';
import { TableBody, TableBodyCell, TableBodyRow } from './components/TableBody';
import { TableHead, TableHeadCell } from './components/TableHead';
import TablePagination from './components/TablePagination';

export type SortOrder = 'asc' | 'desc';

export type TableColumn<T extends TableItem = TableItem> = {
  sortKey?: string;
  onSort?: (key: string, order: SortOrder) => any;
  key: string;
  text: string | (() => React.ReactNode);
  state?: string;
  render?: (item: T) => React.ReactNode;
  hidden?: boolean;
  opacity?: (item: T) => React.ReactNode;
  fixed?: boolean;
  align?: 'flex-start' | 'flex-end' | 'center';
};

export type TableItem = {
  key: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [_: string]: any;
};

export type Itemize<T> = T & { key: string };

export type Props<Item extends TableItem = TableItem> = {
  columns?: TableColumn<Item>[];
  items?: Item[];

  sortedBy?: string;
  order?: SortOrder;
  onSortedChange?: (sortKey: string, order: SortOrder) => any;

  page?: number;
  total?: number;
  perPage?: number;
  onChangePage?: (pageNumber: number) => void;

  disabled?: boolean;
  isLoading?: boolean;

  isSelectable?: boolean;
  onSelectionChange?: (
    selectAll: boolean,
    selecteds: string[],
    unSelecteds: string[]
  ) => void;
  selectAll?: boolean;
  selectedItemsKeys?: string[];
  unSelectedItemsKeys?: string[];
} & BoxProps;

export const Table = <Item extends TableItem = TableItem>({
  items,
  columns,
  sortedBy,
  order,
  disabled,
  total = 0,
  page = 1,
  perPage = 0,
  isSelectable = false,
  onChangePage,
  isLoading,
  onSortedChange,
  onSelectionChange,
  selectAll = false,
  selectedItemsKeys = [],
  unSelectedItemsKeys = [],
  ...props
}: Props<Item>) => {
  const totalPages = Math.ceil(total / perPage);
  const getFixedStyle = (col: any, item: any, zIndex: number) => {
    if (col?.fixed) {
      return {
        position: 'sticky',
        right: 0,
        background: 'white',
        opacity: col.opacity ? col.opacity(item) : 1,
        zIndex: zIndex,
      };
    }
    return { opacity: col.opacity ? col.opacity(item) : 1 };
  };
  return (
    <Box width="100%" {...props}>
      <LoaderLine isLoading={isLoading} />
      <table style={{ width: '100%' }}>
        <TableHead>
          <tr>
            {isSelectable && (
              <TableHeadCell key={'select'} align={'center'}>
                <CheckBox
                  mb={10}
                  key={'selectAll'}
                  id={'selectAll'}
                  checked={selectAll}
                  onChange={(e: any) => {
                    if (onSelectionChange)
                      onSelectionChange(e.currentTarget.checked, [], []);
                  }}
                >
                  <></>
                </CheckBox>
              </TableHeadCell>
            )}
            {columns
              ?.filter(col => !col.hidden)
              .map(col => (
                <TableHeadCell
                  style={
                    col.fixed
                      ? {
                          position: 'sticky',
                          right: 0,
                          zIndex: 1, // 99
                          background: '#eef6fa',
                        }
                      : {}
                  }
                  // style={
                  //   col.fixed
                  //     ? { width: '100%', borderCollapse: 'collapse' }
                  //     : undefined
                  // }
                  key={col.key}
                  sortKey={col.sortKey}
                  align={col.align}
                  isSortColumn={sortedBy === col.sortKey}
                  order={order}
                  onSortedChange={onSortedChange}
                >
                  {typeof col.text === 'string'
                    ? col?.text
                    : typeof col?.text === 'function'
                      ? col?.text()
                      : col?.text}
                </TableHeadCell>
              ))}
          </tr>
        </TableHead>
        <TableBody>
          {items?.map((item, i) => {
            return (
              <TableBodyRow key={item.key}>
                {isSelectable && (
                  <TableBodyCell
                    key={`${item.key}`}
                    align={'center'}
                    //@ts-ignore
                    style={{
                      opacity: 1,
                    }}
                  >
                    <CheckBox
                      mb={10}
                      key={`${item.key}`}
                      id={`${item.key}`}
                      checked={
                        selectAll
                          ? !unSelectedItemsKeys?.includes(item?.key)
                          : selectedItemsKeys?.includes(item?.key)
                      }
                      onChange={(e: any) => {
                        const isSelectedRow = e.currentTarget.checked;
                        let sc = [];

                        if (!isSelectedRow) {
                          if (selectAll) {
                            sc = [...(unSelectedItemsKeys ?? []), item.key];
                            if (onSelectionChange)
                              onSelectionChange(
                                selectAll,
                                selectedItemsKeys ?? [],
                                sc
                              );
                          } else {
                            sc =
                              selectedItemsKeys?.filter(e => e !== item.key) ??
                              [];
                            if (onSelectionChange)
                              onSelectionChange(selectAll, sc, []);
                          }
                        }
                        if (isSelectedRow) {
                          if (!selectAll) {
                            sc = [...selectedItemsKeys, item.key];

                            if (onSelectionChange)
                              onSelectionChange(
                                selectAll,
                                sc,
                                unSelectedItemsKeys
                              );
                          } else {
                            sc = unSelectedItemsKeys.filter(
                              e => e !== item.key
                            );
                            if (onSelectionChange)
                              onSelectionChange(selectAll, [], sc);
                          }
                        }
                      }}
                    >
                      <span></span>
                    </CheckBox>
                  </TableBodyCell>
                )}
                {Array.isArray(columns) &&
                  columns.map(
                    col =>
                      !col.hidden && (
                        <TableBodyCell
                          key={`${item.key}-${col.key}`}
                          align={col.align}
                          //@ts-ignore
                          style={getFixedStyle(col, item, 100 - i)}
                        >
                          {col.render
                            ? col.render(item)
                            : cutLongText(item[col.key], 50)}
                        </TableBodyCell>
                      )
                  )}
              </TableBodyRow>
            );
          })}
        </TableBody>
      </table>
      {totalPages > 1 && (
        <TablePagination
          total={total}
          page={page}
          perPage={perPage}
          onChangePage={onChangePage}
        />
      )}
    </Box>
  );
};

export default Table;
