import {
  ETaskStatus,
  ETaskType,
  ITask,
  ETaskManageAction,
} from '@freelancelabs/teoreme-commons';
import { Header } from 'components/Header';
import {
  Box,
  Button,
  Container,
  Flex,
  Link,
  Spinner,
  SpinnerBox,
  Status,
  Text,
} from 'components/ui';
import {
  FR_STATUS,
  queryClient,
  useAdminTaskFindOne,
  useAdminTaskManage,
  getErrorMessage,
  useUserFindMany,
  getFullName,
} from '@commons';
import React, { useState } from 'react';
import {
  CancelIcon,
  CheckIcon,
  EyeIcon,
  LogsIcon,
  RefreshIcon,
} from 'components/ui/icons';
import { useSetCrumbs } from 'hooks/breadCrumb';
import { useHistory, useParams } from 'react-router-dom';
import { StepCard } from './StepCard';
import { Theme } from 'styles';
import { showDialogModal } from 'components/modals/DialogModal';
import {
  taskTypeMapping,
  variantColorTaskStatus,
  getAllTaskInputValues,
  getTotalTaskTime,
  getIdentifierByTaskType,
  isCancellableTask,
  isRetryableTask,
  isResolvableTask,
} from 'helpers/business';
import { useShowMessage } from 'hooks/useShowMessage';

export const TasksDetails = () => {
  const showMessage = useShowMessage();
  const { mutateAsync: taskManage } = useAdminTaskManage();
  const { uuid } = useParams<{ uuid: string }>();
  const [refetchInterval, setRefetchInterval] = useState<Number | undefined>(
    5000
  );
  const [usersUuid, setUsersUuid] = useState<[] | string[]>([]);
  const {
    data: task,
    status,
    isFetching,
  } = useAdminTaskFindOne(uuid, { refetchInterval: refetchInterval });
  useSetCrumbs(
    [task],
    [
      {
        label: `Admin - Tâches`,
        path: `/admin/tasks/all`,
      },
      {
        label: ` ${taskTypeMapping?.[task?.type as ETaskType]} `,
        path: `/admin/tasks/${uuid}`,
      },
    ]
  );
  const { data: users } = useUserFindMany(
    {
      filterObject: {
        cognitoUserId: { $in: usersUuid },
      },
      limit: 20 * 3,
      skip: 0,
    },
    undefined,
    { enabled: usersUuid?.length > 0 ? true : false }
  );
  const history = useHistory();

  const loading = status === 'pending';
  const steps =
    task?.steps?.map((step: any, index: number) => {
      return {
        key: step.uuid,
        ...step,
      };
    }) || [];
  // const step = task?.currentStep ? task?.currentStep + 1 : 0;
  const stepper = `${task?.currentStep} / ${task?.totalSteps}`;
  const totalTaskTime = getTotalTaskTime(task as ITask);
  const identifierActions = getIdentifierByTaskType(task);
  const handleCancelTask = async (task?: ITask) => {
    showDialogModal({
      title: `Êtes-vous sûr de vouloir annuler la tâche ?`,
      text: `Avant d'annuler la tâche assurer vous de bien avoir vérifié les éléments !`,
      confirmLabel: 'Annuler',
      cancelLabel: 'Fermer',
      beforeValidation: async () => {
        try {
          const response = await taskManage({
            uuids: [task?.uuid],
            action: ETaskManageAction.CANCEL,
          });
          queryClient.refetchQueries({
            queryKey: ['admin-tasks-search'],
            type: 'active',
          });
          queryClient.refetchQueries({
            queryKey: [task?.uuid],
            type: 'active',
          });
          if (response?.hasError) {
            showMessage(
              'error',
              getErrorMessage(response?.errors?.[0]?.errorCode)
            );
          } else {
            showMessage('success', 'La tâche à été annulé');
          }
        } catch (e) {
          //
        }
      },
    });
  };
  const handleRetryTask = async (task?: ITask) => {
    showDialogModal({
      title: `Êtes-vous sûr de vouloir relancer la tâche ?`,
      text: `Avant de relancer la tâche assurer vous de bien avoir vérifié les éléments !`,
      confirmLabel: 'Relancer',
      cancelLabel: 'Fermer',
      beforeValidation: async () => {
        try {
          const response = await taskManage({
            uuids: [task?.uuid],
            action: ETaskManageAction.RETRY,
          });
          queryClient.refetchQueries({
            queryKey: ['admin-tasks-search'],
            type: 'active',
          });
          queryClient.refetchQueries({
            queryKey: [task?.uuid],
            type: 'active',
          });
          if (response?.hasError) {
            showMessage(
              'error',
              getErrorMessage(response?.errors?.[0]?.errorCode)
            );
          } else {
            showMessage('success', 'La tâche à été relancé');
          }
        } catch (e) {
          //
        }
      },
    });
  };
  const handleResolveTask = async (task?: ITask) => {
    showDialogModal({
      title: `Êtes-vous sûr de vouloir passer la tâche en résolu ?`,
      text: `Avant de résoudre la tâche assurer vous de bien avoir vérifié les éléments !`,
      confirmLabel: 'Résoudre',
      cancelLabel: 'Fermer',
      beforeValidation: async () => {
        try {
          const response = await taskManage({
            uuids: [task?.uuid],
            action: ETaskManageAction.RESOLVE,
          });
          queryClient.refetchQueries({
            queryKey: ['admin-tasks-search'],
            type: 'active',
          });
          queryClient.refetchQueries({
            queryKey: [task?.uuid],
            type: 'active',
          });
          if (response?.hasError) {
            showMessage(
              'error',
              getErrorMessage(response?.errors?.[0]?.errorCode)
            );
          } else {
            showMessage('success', 'La tâche à été résolu');
          }
        } catch (e) {
          //
        }
      },
    });
  };
  React.useEffect(() => {
    if (task) {
      const usersUuid = [];
      if (task?.startedBy) {
        usersUuid.push(task?.startedBy);
      }
      if (task?.resolvedBy) {
        usersUuid.push(task?.resolvedBy);
      }
      if (task?.cancelledBy) {
        usersUuid.push(task?.cancelledBy);
      }
      setUsersUuid(usersUuid);
      if (task.status === ETaskStatus.ONGOING) {
        setRefetchInterval(5000);
      } else {
        setRefetchInterval(undefined);
      }
    }
  }, [task]);

  return (
    <Box>
      <Header>
        <Flex
          width={1 / 1}
          display="inline-flex"
          justifyContent="flex-start"
          flexWrap="wrap"
        >
          <Box width={2 / 3}>
            <Text width={1 / 1} variant="h1">
              {taskTypeMapping[task?.type as ETaskType]}
            </Text>
            <Status
              ml={10}
              variantColor={
                variantColorTaskStatus?.[task?.status as ETaskStatus] as any
              }
            >
              {FR_STATUS?.[task?.status as ETaskStatus]} étape {stepper}&nbsp;
              Temps total : {totalTaskTime?.value} {totalTaskTime?.label}
            </Status>
          </Box>
          <Flex
            width={1 / 3}
            display="inline-flex"
            justifyContent="flex-end"
            flexWrap="wrap"
          >
            {task?.startedBy && (
              <Text textAlign="end" width={1 / 1} variant="b">
                Crée par:{' '}
                {getFullName(
                  users?.users?.find(
                    (u: any) => u?.cognitoUserId === task?.startedBy
                  )
                )}
              </Text>
            )}
            {task?.resolvedBy && (
              <Text textAlign="end" width={1 / 1} variant="b">
                Résolu par:{' '}
                {getFullName(
                  users?.users?.find(
                    (u: any) => u?.cognitoUserId === task?.resolvedBy
                  )
                )}
              </Text>
            )}
            {task?.cancelledBy && (
              <Text textAlign="end" width={1 / 1} variant="b">
                Annulé par:{' '}
                {getFullName(
                  users?.users?.find(
                    (u: any) => u?.cognitoUserId === task?.cancelledBy
                  )
                )}
              </Text>
            )}
            {task?.retryCount ? (
              <Text textAlign="end" width={1 / 1} variant="b">
                Nombre de relance : {task?.retryCount || 0}
              </Text>
            ) : (
              false
            )}
          </Flex>
        </Flex>
      </Header>
      <Container>
        <Flex justifyContent="space-between" mt={20}>
          <Box>
            <Link
              iconLeft={
                !isFetching ? (
                  <RefreshIcon width={20} height={20} />
                ) : (
                  <Spinner size={20} />
                )
              }
              onClick={() => queryClient?.refetchQueries({ queryKey: [uuid] })}
            >
              Actualiser
            </Link>
            <Link
              ml={20}
              iconLeft={
                <LogsIcon
                  width={'15px'}
                  height={'15px'}
                  style={{ marginTop: '5px' }}
                  fill={Theme?.colors?.blue}
                />
              }
              onClick={() =>
                showDialogModal({
                  width: 1380,
                  title: 'Logs',
                  text: (
                    <Flex
                      display="inline-flex"
                      flexWrap="wrap"
                      justifyContent="flex-start"
                      width={1 / 1}
                      backgroundColor="#5d5866"
                      color="white"
                    >
                      <Box p={10}>
                        <code style={{ wordBreak: 'break-all' }}>
                          {task?.logs?.map((log: any) => (
                            <p>
                              {log.createdAt?.toISOString()} -- {log.content}
                            </p>
                          ))}
                        </code>
                      </Box>
                    </Flex>
                  ),
                  confirmLabel: 'Fermer',
                })
              }
            >
              Voir les logs de la tâche
            </Link>
            <Link
              ml={20}
              iconLeft={
                <LogsIcon
                  width={'15px'}
                  height={'15px'}
                  style={{ marginTop: '5px' }}
                  fill={Theme?.colors?.blue}
                />
              }
              onClick={() =>
                showDialogModal({
                  width: 1380,
                  title: 'Entrées',
                  text: (
                    <Flex
                      display="inline-flex"
                      flexWrap="wrap"
                      justifyContent="flex-start"
                      width={1 / 1}
                      backgroundColor="#5d5866"
                      color="white"
                    >
                      <Box p={10}>
                        <code style={{ wordBreak: 'break-all' }}>
                          {getAllTaskInputValues(task)?.map((input: any) => (
                            <p style={{ marginBottom: '5px' }}>
                              <p style={{ color: '#e2433a' }}>{input?.key}:</p>
                              <p style={{ wordBreak: 'break-word' }}>
                                {' '}
                                {input?.value}
                              </p>
                            </p>
                          ))}
                        </code>
                      </Box>
                    </Flex>
                  ),
                  confirmLabel: 'Fermer',
                })
              }
            >
              Voir les entrées
            </Link>
            {identifierActions?.label && (
              <Link
                ml={20}
                onClick={() => history.push(identifierActions?.link as string)}
                iconLeft={<EyeIcon />}
              >
                {identifierActions?.label}
              </Link>
            )}
            {isCancellableTask(task) && (
              <Link
                ml={20}
                onClick={() => handleCancelTask(task)}
                iconLeft={<CancelIcon />}
              >
                Annuler la tâche
              </Link>
            )}
            {isResolvableTask(task) && (
              <Link
                ml={20}
                onClick={() => handleResolveTask(task)}
                iconLeft={<CheckIcon />}
              >
                Résoudre la tâche
              </Link>
            )}
          </Box>

          <Button
            isDisabled={!isRetryableTask(task)}
            onClick={() => handleRetryTask(task)}
          >
            Relancer la tâche
          </Button>
        </Flex>
      </Container>
      {loading && <SpinnerBox />}
      {!loading && (
        <Container p="20px 0">
          <Flex flexWrap="wrap" justifyContent="flex-start" width={1 / 1}>
            {steps.map((step: any) => (
              <StepCard task={task as ITask} step={step} />
            ))}
          </Flex>
        </Container>
      )}
    </Box>
  );
};
