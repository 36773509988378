import {
  IAdditionalService,
  IFastCashService,
  IJoinedMission,
} from '@freelancelabs/teoreme-commons';
//import { AdditionalServicesForm } from 'forms/AdditionalServicesForm';
import { AdditionalServicesForm } from 'forms/missions/AdditionalServices';

import React from 'react';
import { create } from 'react-modal-promise';
import { ModalFrame, ModalProps } from './ModalFrame';
type MissionFormValues = {
  formValues?: {
    endAt: Date;
    startAt: Date;
  };
};
type Props = ModalProps & {
  forRole: 'ALL' | 'CUSTOMER' | 'PROVIDER' | 'ESTABLISHMENT_PROVIDER';
  beforeValidation?: () => void;
  service?: IAdditionalService;
  additionalServices: IAdditionalService[] | IFastCashService[];
  mission: IJoinedMission & MissionFormValues;
};

export const AdditionalServicesModal = ({
  forRole,
  additionalServices,
  onResolve,
  isOpen,
  service,
  mission,
}: Props) => {
  return (
    <ModalFrame
      isOpen={isOpen}
      onClose={() => onResolve(false)}
      width={1000}
      closeIcon
    >
      <AdditionalServicesForm
        service={service}
        forRole={forRole}
        additionalServices={additionalServices}
        onClose={() => onResolve(false)}
        mission={mission}
        onSubmit={
          service => onResolve(service || false)
          // update inside form
          //(service: IAdditionalService) => onResolve(service)
        }
      />
    </ModalFrame>
  );
};

export const showAdditionalServicesModal = create<
  Props,
  IAdditionalService | false
>(AdditionalServicesModal);
