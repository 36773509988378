import { IJoinedMission } from '@freelancelabs/teoreme-commons';
import { Box, Button, CheckBox, Flex, Link, Text } from 'components/ui';
import { CloseIcon } from 'components/ui/icons';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { useShowMessage } from 'hooks/useShowMessage';
import React, { useState } from 'react';
import { create } from 'react-modal-promise';
import { queryClient, createCra, getHumanDate } from '@commons';
import { ModalFrame, ModalProps } from '../ModalFrame';
type Props = ModalProps & {
  beforeValidation?: () => void;
  mission: IJoinedMission;
  missingCra: Date[];
};

export const GenerateCraInPastModal = ({
  onResolve,
  isOpen,
  mission,
  missingCra,
}: Props) => {
  const [dates, setDate] = React.useState<Date[] | []>([]);
  const [loading, setLoading] = useState(false);
  const showMessage = useShowMessage();
  const onSubmit = async () => {
    setLoading(true);

    try {
      const responseCra = await createCra({
        missionReference: mission?.reference,
        //@ts-ignore
        months: dates,
      });
      if (responseCra.errors.length > 0) {
        showMessage(
          'error',
          `Une erreur est survenue certains CRAS n'ont pas été créé`
        );
      } else {
        showMessage('success', `Vous avez bien généré les CRAS`);
      }
      queryClient.refetchQueries({ queryKey: ['Cras'], type: 'active' });
      onResolve(true);
    } catch (e) {
      //
    }

    setLoading(false);
  };
  const onSelectDate = (date: Date) => {
    const arrDate: Date[] | [] = new Array(...dates);
    const fIndex = dates?.findIndex(d => d === date);
    if (fIndex !== -1) {
      arrDate.splice(fIndex, 1);
    } else {
      //@ts-ignore
      arrDate.push(date);
    }
    setDate(arrDate);
  };
  return (
    <ModalFrame
      isOpen={isOpen}
      onClose={() => onResolve(false)}
      width={470}
      closeIcon
    >
      <Box>
        <Text variant="h2">Générer les CRA </Text>
        <Text mt={20}>
          Veuillez séléctionner les cra à générer pour la mission{' '}
          {mission.displayReference} , les dates de cette mission sont du{' '}
          <b>{getHumanDate(mission?.startAt as Date)}</b> au{' '}
          <b>{getHumanDate(mission?.endAt as Date)}</b>
        </Text>
        <Box width={'375px'} mt={20}>
          {missingCra?.map(date => (
            <CheckBox
              id={date.toISOString()}
              onChange={() => onSelectDate(date)}
            >
              <Text mb={10}>{format(date, 'MMMM yyyy', { locale: fr })}</Text>
            </CheckBox>
          ))}
        </Box>
        <Flex mt={20} width={'250px'} justifyContent="space-between">
          <Button
            isLoading={loading}
            isDisabled={dates?.length === 0}
            onClick={() => onSubmit()}
            type="button"
          >
            Génerer
          </Button>
          <Link
            mt={20}
            iconLeft={<CloseIcon />}
            onClick={() => onResolve(false)}
          >
            Annuler
          </Link>
        </Flex>
      </Box>
    </ModalFrame>
  );
};

export const showGenerateCraInPastModal = create<Props, boolean>(
  GenerateCraInPastModal
);
