import {
  getFullName,
  getWorkingDays,
  MAX_FILE_SIZE_ANNEXE_MB,
  missionUpdateOne,
} from '@commons';
import {
  EFileType,
  IAdditionalService,
  IAddress,
  IBilling,
  IJoinedMission,
  QUOTE,
} from '@freelancelabs/teoreme-commons';
import { ServiceHandler } from 'components/ServiceHandler';
import {
  Box,
  FileListControlled,
  FormControl,
  FormLabel,
  Input,
  StaticSelectControlled,
  Text,
  TextAreaControlled,
} from 'components/ui';
import { BillingPartialForm } from 'forms/partials/BillingPartialForm';
import { MissionPartialForm } from 'forms/partials/MissionPartialForm';
import { ConfigureExpensesPartialForm } from 'forms/partials/ConfigureExpensesPartialForm';
import React from 'react';
import { useController, useForm } from 'react-hook-form';
import { create } from 'react-modal-promise';
import { queryClient } from '@commons';
import { ConfirmNavigationModal } from '../ConfirmNavigationModal';
import { showDialogModal } from '../DialogModal';
import { ModalProps } from '../ModalFrame';
import { useGenerateFileActions } from './generateFileActions';
import { GenerateFileModal } from './GenerateFileModal';

type FormValues = {
  externalReference?: string;

  startAt?: Date;
  endAt?: Date;
  location?: IAddress;
  description?: string;

  billing: IBilling;

  additionalServices: IAdditionalService[];
  additionalInformations: string;

  additionalFiles: { fileName: string; fileLocation: string; eTag: string }[];
};
export const ServiceHandlerControlled = (props: any) => {
  const {
    field: { ref, ...inputProps },
    // fieldState: { invalid, isTouched, isDirty },
    // formState: { touchedFields, dirtyFields },
  } = useController({
    name: props.name,
    control: props.control,
    rules: { required: false },
    defaultValue: '',
  });

  return <ServiceHandler {...inputProps} {...props} inputRef={ref} />;
};
export const GenerateQuoteModal = ({
  isDisabled,
  missionReference,
  mission: initialMission,
  onResolve,
}: {
  mission?: IJoinedMission;
  isDisabled: boolean;
  missionReference?: string;
} & ModalProps) => {
  const {
    onGenerate,
    loading: generateLoading,
    mission,
    calculateBillingTotal,
  } = useGenerateFileActions(missionReference || '', QUOTE, onResolve);

  const form = useForm<FormValues>({ defaultValues: { ...mission } });
  const {
    register,
    handleSubmit,
    formState: { isDirty, isSubmitSuccessful },
    control,
    watch,
    setError,
    getValues,
  } = form;
  const externalReference = watch('externalReference');
  const billing = watch('billing');
  const billingTotal = calculateBillingTotal(
    { ...mission?.billing, ...billing },
    QUOTE
  );
  const [estimatedDays, setEstimatedDays] = React.useState(0);

  const handleCloseConfirm = async () => {
    const confirm = isDirty
      ? await showDialogModal({
          title: 'Êtes-vous sûr(e) de vouloir fermer sans enregistrer ?',
          text: 'Toutes vos modifications seront perdues.',
          confirmLabel: 'Fermer sans enregistrer',
          cancelLabel: 'Ne pas fermer',
        })
      : true;
    if (confirm) onResolve(false);
  };
  const onSubmitGenerate = async (data: any) => {
    const expenseConfigMargin =
      // @ts-ignore
      data?.expensesConfigRaw?.margin ??
      (mission?.expensesConfig?.margin
        ? Number(mission?.expensesConfig?.margin)
        : undefined);

    const expenseConfigPurchaseBudget =
      // @ts-ignore
      data?.expensesConfigRaw?.purchaseBudget ??
      (mission?.expensesConfig?.purchaseBudget
        ? Number(mission?.expensesConfig?.purchaseBudget)
        : undefined);

    const expenseConfigSaleBudget =
      // @ts-ignore
      data?.expensesConfigRaw?.saleBudget ??
      (mission?.expensesConfig?.saleBudget
        ? Number(mission?.expensesConfig?.saleBudget)
        : undefined);

    if (!data.description || data.description.length === 0) {
      setError('description', { message: 'Ce Champs est requis' });
    } else {
      setError('description', { message: undefined });
      if (
        !data.externalReference ||
        data.externalReference === undefined ||
        data.externalReference === null
      ) {
        data.externalReference = '';
      }

      const submitData = {
        modelLabel: data?.modelLabel,
        outputType: 'PDF',
        title: data?.title,
        startAt: data?.startAt,
        endAt: data?.endAt,
        location: data?.location,
        billing: data?.billing,
        description: data?.description,
        additionalServices: data?.additionalServices,
        additionalInformations: data?.additionalInformations,
        additionalFiles: data?.additionalFiles,
        externalReference: data?.externalReference,
        isRemote: data?.isRemote || false,
        expensesConfig: {
          ...data?.expensesConfig,
          margin: expenseConfigMargin,
          purchaseBudget: expenseConfigPurchaseBudget,
          saleBudget: expenseConfigSaleBudget,
          createdAt: undefined,
          updatedAt: undefined,
        },
        nbRemoteDaysPerWeek: data?.isRemote
          ? data?.nbRemoteDaysPerWeek
          : undefined,
      };
      await onGenerate(submitData);
      queryClient.refetchQueries({
        queryKey: [mission?.reference || 'missions'],
      });
    }
  };
  const startAt = watch('startAt');
  const endAt = watch('endAt');

  React.useEffect(() => {
    if (startAt && endAt) {
      setEstimatedDays(getWorkingDays(startAt, endAt));
    }
  }, [startAt, endAt, setEstimatedDays]);
  React.useEffect(() => {
    //@ts-ignore
    if (mission?.salesforce?.customerReference && !externalReference) {
      form?.setValue(
        'externalReference',
        //@ts-ignore
        mission?.salesforce?.customerReference
      );
    }
  }, [mission, externalReference]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onUpdateServices = async (service: IAdditionalService | false) => {
    if (service && mission) {
      const submitData = {
        reference: mission.reference,
        mission: {
          additionalServices: {
            update: [],
            add: [],
            cancel: [],
          },
        },
      };
      if (service?.uuid) {
        submitData?.mission?.additionalServices.update.push(service as never);
      } else {
        submitData?.mission?.additionalServices.add.push(service as never);
      }
      try {
        await missionUpdateOne(submitData);
        queryClient.refetchQueries({ queryKey: [mission.reference] });
      } catch (e) {
        //
      }
    }
  };
  const expensesConfig = mission?.expensesConfig;
  const establishmentEnableExpense =
    mission?.customer?.establishment?.customer?.expensesConfig?.isEnabled;
  return (
    <GenerateFileModal
      title="Envoyer le devis client"
      onSubmit={handleSubmit(onSubmitGenerate)}
      isLoading={generateLoading}
      onClose={handleCloseConfirm}
    >
      <form onSubmit={handleSubmit(onSubmitGenerate)} autoComplete="off">
        <ConfirmNavigationModal active={isDirty && !isSubmitSuccessful} />
        <Box>
          {mission?.isMandateMode && (
            <Box width={1 / 3} mb={20}>
              <FormLabel>Type de devis</FormLabel>{' '}
              <StaticSelectControlled
                control={control}
                name="modelLabel"
                options={[
                  { label: 'Classique', value: 'CONNECTEED' },
                  { label: 'Mandat de gestion', value: 'LOREAL' },
                ]}
              />
            </Box>
          )}

          <Text variant="h2" mb={20}>
            1. Client
          </Text>

          <FormLabel>Référence client</FormLabel>
          <Input mb={10} {...register('externalReference')} />

          <FormLabel>contact client</FormLabel>
          <Text mb={10}>
            {mission?.customer.contact
              ? getFullName(mission?.customer.contact)
              : ''}
          </Text>

          <FormLabel>établissement client</FormLabel>
          <Text mb={10}>
            {mission?.customer.establishment.businessName} / SIRET :{' '}
            {mission?.customer.establishment.siret}
          </Text>
        </Box>

        <Box mb={20}>
          <Text variant="h2" mb={20}>
            2. Mission
          </Text>
          <MissionPartialForm
            mission={initialMission}
            isDisabled={isDisabled}
            form={form}
            forRole="CUSTOMER"
          />
        </Box>

        <Text variant="h2" mb={20}>
          3. Tarification
        </Text>
        <BillingPartialForm
          mission={mission as IJoinedMission}
          isDisabled={isDisabled}
          form={form}
          forRole="CUSTOMER"
          estimatedDays={estimatedDays}
        />
        <FormLabel>Montant</FormLabel>
        <Text fontSize={20} fontWeight={600} mb={20}>
          {billingTotal?.toFixed(2)}
        </Text>
        {establishmentEnableExpense && (
          <Box>
            <Text variant="h3" mb={20}>
              Paramétrage des frais
            </Text>
            <ConfigureExpensesPartialForm
              //mission={mission as IJoinedMission}
              form={form}
              expensesConfig={expensesConfig}
              isDisabled={isDisabled}
            />
          </Box>
        )}
        <Box mb={20}>
          <Text variant="h2" mb={20}>
            5. Services complémentaires
          </Text>
          <ServiceHandlerControlled
            //@ts-ignore
            mission={{
              ...mission,
              //@ts-ignore
              formValues: { ...getValues() },
            }}
            control={control}
            forRole="CUSTOMER"
            name="additionalServices"
          />
          {/* <ServiceHandler
            mission={mission as IJoinedMission}
            forRole="CUSTOMER"
            value={mission?.additionalServices || []}
            onChange={onUpdateServices}
          /> */}
        </Box>

        <Text variant="h2" mb={20}>
          6. Informations complémentaires
        </Text>
        <FormControl label="Informations complémentaires">
          <TextAreaControlled
            control={control}
            name="additionalInformations"
            defaultValue=""
            minRows={3}
          />
        </FormControl>

        <Text variant="h2" mb={20}>
          6. Pièces-jointes
        </Text>

        <FileListControlled
          control={control}
          name="additionalFiles"
          missionRef={mission?.reference}
          fileType={EFileType.QUOTE_EXTRA}
          accept=".pdf"
          maxSize={MAX_FILE_SIZE_ANNEXE_MB}
        />
      </form>
    </GenerateFileModal>
  );
};

export const showGenerateQuoteModal = create(GenerateQuoteModal, {
  enterTimeout: 0,
  exitTimeout: 0,
});
