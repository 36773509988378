import { IEstablishmentMinifiedUsers } from '@freelancelabs/teoreme-commons';
import { SignatoryInformationForm } from 'forms/SignatoryInformationForm';
import React from 'react';
import { create } from 'react-modal-promise';
import { ModalFrame, ModalProps } from './ModalFrame';

type Props = ModalProps & {
  beforeValidation?: () => void;
  establishment: IEstablishmentMinifiedUsers;
  layer?: number;
};

export const SignatoryInformationModal = ({
  onResolve,
  isOpen,
  establishment,
  layer,
}: Props) => {
  return (
    <ModalFrame
      isOpen={isOpen}
      onClose={() => onResolve(false)}
      width={850}
      closeIcon
      layer={layer}
    >
      <SignatoryInformationForm
        establishment={establishment}
        onSubmit={() => false}
        onClose={(action?: boolean | undefined) => onResolve(action || false)}
      />
    </ModalFrame>
  );
};

export const showSignatoryInformationModal = create<Props>(
  SignatoryInformationModal
);
