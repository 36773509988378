import { Link, Itemize, Status, Row } from 'components/ui';
import {
  IEstablishmentMinifiedUsers,
  IEstablishmentFullUsers,
} from '@freelancelabs/teoreme-commons';
import { buildOneLineAddress } from '@commons';
import { CheckIcon, EditIcon, CloseIcon } from 'components/ui/icons';

import { queryClient } from '@commons';
import { showAddEstablishmentAdressModal } from 'components/modals/clients/AddEstablishmentAdressModal';
export type Item = Itemize<any>;

export const columnsInvoiceAdresses = (
  items: any[],
  establishment: IEstablishmentMinifiedUsers | IEstablishmentFullUsers,
  createAdressInGescom: (item: any) => void
) => {
  return [
    {
      key: 'adress',
      text: 'ADRESSE',
      render: (item: Item) => {
        return buildOneLineAddress(item);
      },
    },
    {
      key: 'default',
      text: 'PAR DEFAUT',
      render: (item: Item) => {
        if (item?.default) {
          return (
            <>
              <Link
                iconLeft={<CheckIcon />}
                mb={10}
                width={'100%'}
                variantColor="success"
              ></Link>
            </>
          );
        }
        return <>{/* <Text mx={10}>non</Text> */}</>;
      },
    },
    {
      key: 'status',
      text: 'STATUT',
      render: (item: Item) => {
        if (item?.sage) {
          return (
            <>
              <Status variantColor="success">Créé dans GESCOM</Status>
            </>
          );
        }
        return (
          <>
            <Status variantColor="warning">À créer dans GESCOM</Status>
          </>
        );
      },
    },
    {
      key: 'locked',
      text: 'ACTIVÉE',
      render: (item: Item) => {
        if (item?.locked) {
          return (
            <>
              <Link
                iconLeft={<CloseIcon />}
                mb={10}
                width={'100%'}
                variantColor="error"
              ></Link>
            </>
          );
        }
        return (
          <>
            <Link
              iconLeft={<CheckIcon />}
              mb={10}
              width={'100%'}
              variantColor="success"
            ></Link>
          </>
        );
      },
    },
    {
      key: 'actions',
      text: 'ACTIONS',
      render: (item: Item) => {
        return (
          <>
            <Row spacing={10}>
              <Link
                onClick={() => {
                  showAddEstablishmentAdressModal({
                    establishment: establishment,
                    defaultAdress: item,
                  })?.then(async action => {
                    queryClient?.refetchQueries({
                      queryKey: [establishment?.uuid],
                    });
                  });
                }}
                iconLeft={<EditIcon />}
              >
                Modifier
              </Link>
              {!item?.sage && (
                <Link
                  onClick={async () => {
                    try {
                      await createAdressInGescom(item);
                    } catch (error) {
                      console.log(error);
                    }
                  }}
                  iconLeft={<EditIcon />}
                >
                  Créer dans GESCOM
                </Link>
              )}
              {/* <Link
              iconLeft={<DeleteIcon />}
              //   onClick={() => removeOtherSigner(item)}
            >
              Supprimer
            </Link> */}
              <></>
            </Row>
          </>
        );
      },
    },
  ];
};
