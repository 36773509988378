import { Box, Button, CheckBox, Radio, Text } from 'components/ui';
import React, { ReactChild, ReactNode, useState } from 'react';
import { create } from 'react-modal-promise';
import styled from 'styled-components';
import { ModalFrame, ModalProps } from './ModalFrame';

const Actions = styled.div`
  display: flex;
`;

type Props = ModalProps & {
  confirmLabel?: string;
  cancelLabel?: string;
  title?: string | ReactNode;
  text?: string | ReactNode;
  closeIcon?: boolean;
  radioConfirmation?: boolean;
  checkboxConfirmation?: string[];
  isDisabled?: boolean;
  beforeValidation?: (param?: any) => void;
  children?: ReactChild;
  layer?: number;
  width?: number;
};

export const DialogModal = ({
  onResolve,
  isOpen,
  close,
  text,
  title,
  confirmLabel = 'Valider',
  cancelLabel,
  closeIcon = false,
  radioConfirmation = false,
  checkboxConfirmation = [],
  isDisabled,
  beforeValidation,
  children,
  layer,
  width,
}: Props) => {
  const [loading, setLoading] = useState(false);
  const [isRadioConfirmed, setRadioConfirmed] = useState(!radioConfirmation);
  const [checkboxConfirmedCount, setCheckboxConfirmedCount] = useState(0);

  const onValidate = async () => {
    setLoading(true);
    try {
      if (beforeValidation) {
        await beforeValidation();
      }
      onResolve(true);
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  };

  return (
    <ModalFrame
      isOpen={isOpen}
      onClose={() => onResolve(false)}
      width={width ? width : 650}
      closeIcon={closeIcon}
      layer={layer}
    >
      {title && (
        <Text variant="h1" mb={30}>
          {title}
        </Text>
      )}
      {text && (
        <Text variant="p" mb={30}>
          {text}
        </Text>
      )}
      {radioConfirmation && (
        <Box mb={20}>
          <Radio
            name="confirmation"
            onChange={e => e.currentTarget.checked && setRadioConfirmed(true)}
          >
            Oui
          </Radio>
          <Radio
            name="confirmation"
            onChange={e => e.currentTarget.checked && setRadioConfirmed(false)}
          >
            Non
          </Radio>
        </Box>
      )}
      {!!checkboxConfirmation.length && (
        <Box mb={20}>
          {checkboxConfirmation.map(message => {
            return (
              <CheckBox
                mb={10}
                key={message}
                id={message}
                onChange={e => {
                  if (e.currentTarget.checked)
                    setCheckboxConfirmedCount(count => count + 1);
                  else setCheckboxConfirmedCount(count => count - 1);
                }}
              >
                <Box>{message}</Box>
              </CheckBox>
            );
          })}
        </Box>
      )}
      {children ? (
        <Box mt={-20} mb={20}>
          {children}
        </Box>
      ) : (
        <></>
      )}
      <Actions>
        <Button
          data-cy="confirm-dialog-modal"
          isDisabled={
            isDisabled ||
            !isRadioConfirmed ||
            checkboxConfirmedCount !== checkboxConfirmation.length
          }
          onClick={onValidate}
          mr={10}
          isLoading={loading}
        >
          {confirmLabel}
        </Button>
        {cancelLabel && (
          <Button variant="ghost" onClick={() => onResolve(false)}>
            {cancelLabel}
          </Button>
        )}
      </Actions>
    </ModalFrame>
  );
};

export const showDialogModal = create<Props, boolean>(DialogModal);
