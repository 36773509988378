import {
  IUserLocal,
  IUserMinimal,
  transformText,
  formatAllObject,
  validateFormPhoneFormatFR,
  checkFormEmail,
  useUserFindOne,
  getFullName,
  useUserUpdateOne,
  useMe,
  checkUserHasRole,
  ERoles,
} from '@commons';
import { ConfirmNavigationModal } from 'components/modals/ConfirmNavigationModal';
import { showUpdateAccountManagerModal } from 'components/modals/UpdateAccountManagerModal';
import {
  Spinner,
  Link,
  BlocInformation,
  Box,
  Button,
  FormControl,
  FormInfo,
  Input,
  Radio,
  Row,
  Text,
  PhoneInputControlled,
  CheckSwitch,
} from 'components/ui';
import { EditIcon } from 'components/ui/icons';
import { useShowMessage } from 'hooks/useShowMessage';
import { useState } from 'react';
import { useForm } from 'react-hook-form';

type FormValues = Partial<Omit<IUserLocal, 'civility'>> & {
  civility: string;
};
type ContactFormProps = {
  defaultValues?: Partial<IUserMinimal>;
  onSubmit?: (user: Partial<IUserMinimal>) => any | void;
  // we could just check if we have a cognitoUserId in out defaultValues - but let's be clear.
  isNew?: boolean;
  contactRole?: 'PROVIDER_CONTACT' | 'CUSTOMER';
  isDisabled?: boolean;
};

export const ContactForm = ({
  defaultValues,
  onSubmit: onSubmitParent,
  isNew,
  contactRole,
  isDisabled,
}: ContactFormProps) => {
  const { me } = useMe();
  const showMessage = useShowMessage();
  const { mutateAsync: updateUser } = useUserUpdateOne();
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isDirty, isSubmitSuccessful },
    setError,
    watch,
    reset,
  } = useForm<FormValues>({
    defaultValues: {
      ...defaultValues,
      civility: defaultValues?.civility + '',
    },
  });
  const { data: accountManager, isFetching: isFetchingAccountManager } =
    useUserFindOne(
      //@ts-ignore
      defaultValues?.accountManager?.cognitoUserId
        ? //@ts-ignore
          defaultValues?.accountManager?.cognitoUserId
        : //@ts-ignore
          defaultValues?.accountManager
    );
  // !!!  if user ( defaultValue) exist do not delete data !!!!
  const requiredFields = contactRole === 'CUSTOMER';
  const userExistCheck =
    defaultValues !== undefined && Object.keys(defaultValues).length > 1;
  const onSubmit = async (formValues: FormValues) => {
    setLoading(true);
    let onError = false;
    // transform form values as requested
    const values = {
      email: defaultValues?.email,
      alternateEmail: formValues?.alternateEmail,
      firstName: transformText(formValues.firstName, 'capitalize').trim(),
      lastName: transformText(formValues.lastName, 'uppercase').trim(),
      businessUnit: transformText(
        formValues.businessUnit,
        'capitalize-first'
      ).trim(),
      jobTitle: transformText(formValues.jobTitle, 'capitalize-first').trim(),
      civility: parseInt(formValues.civility || '1', 10),
      phone: formValues?.phone,
      havePortalClientAccess: formValues?.havePortalClientAccess,
    };
    if (requiredFields) {
      if (formValues.phone === undefined || formValues.phone.length < 11) {
        onError = true;
        setError('phone', { message: 'Ce champ est requis' });
      }
    }
    if (onError === false) {
      onSubmitParent && (await onSubmitParent(formatAllObject(values)));
      // reset dirty values
      formValues.firstName = transformText(
        formValues.firstName,
        'capitalize'
      ).trim();
      formValues.lastName = transformText(
        formValues.lastName,
        'uppercase'
      ).trim();
      formValues.businessUnit = transformText(
        formValues.businessUnit,
        'capitalize-first'
      ).trim();
      formValues.jobTitle = transformText(
        formValues.jobTitle,
        'capitalize-first'
      ).trim();
      formValues.civility = formValues.civility || '1';
      reset(formValues);
    }
    setLoading(false);
  };
  const havePortalClientAccess = watch('havePortalClientAccess');
  const editPortal =
    checkUserHasRole(me, ERoles?.ADMIN) ||
    checkUserHasRole(me, 'PORTAL_CLIENT_ADMIN');
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <ConfirmNavigationModal active={isDirty && !isSubmitSuccessful} />
      <Box mb={20} width={contactRole === 'PROVIDER_CONTACT' ? 8 / 10 : 1 / 1}>
        <FormControl label="Autre adresse email">
          <>
            <Input
              isDisabled={isDisabled}
              isFullWidth
              {...register('alternateEmail', {
                required: false,
                validate: value => checkFormEmail(value as string),
              })}
            />
            {!!errors?.alternateEmail && (
              <FormInfo variantColor="error">Email incorrect</FormInfo>
            )}
          </>
        </FormControl>

        <BlocInformation>
          <p>{`Si besoin, ajoutez un deuxième email qui sera mis en copie des emails liés aux commandes créées par le contact ${
            contactRole === 'CUSTOMER' ? 'client' : 'fournisseur'
          }.`}</p>
        </BlocInformation>
        {editPortal && contactRole === 'CUSTOMER' && !isNew && (
          <FormControl mt={20} label="Accès au Portail Client Freelance.com">
            <>
              <CheckSwitch
                isDisabled={isDisabled}
                id="havePortalClientAccess"
                mb={10}
                {...register('havePortalClientAccess')}
              >
                <Text variant="span">
                  {havePortalClientAccess
                    ? 'A accès au Portail Client Freelance.com'
                    : 'N’a pas accès au Portail Client Freelance.com'}
                </Text>
              </CheckSwitch>
            </>
          </FormControl>
        )}
      </Box>
      {
        /*!isNew*/ false && (
          <Text variant="h2" mb={10}>
            Informations du contact
          </Text>
        )
      }
      <Box>
        <FormControl
          required={requiredFields}
          label={'Civilité'}
          errorMessage={errors?.civility?.message}
        >
          <>
            <Radio
              isDisabled={isDisabled}
              {...register('civility', {
                required:
                  requiredFields || (userExistCheck && defaultValues?.civility)
                    ? 'Ce champ est requis'
                    : '',
              })}
              value="1"
            >
              Monsieur
            </Radio>
            <Radio
              isDisabled={isDisabled}
              {...register('civility', {
                required:
                  requiredFields || (userExistCheck && defaultValues?.civility)
                    ? 'Ce champ est requis'
                    : '',
              })}
              value="2"
            >
              Madame
            </Radio>
          </>
        </FormControl>
      </Box>
      <Row spacing={20}>
        <FormControl
          required={requiredFields}
          label="Prénom"
          errorMessage={errors?.firstName?.message}
        >
          <Input
            isDisabled={isDisabled}
            isFullWidth
            {...register('firstName', {
              required:
                requiredFields || (userExistCheck && defaultValues?.firstName)
                  ? 'Ce champ est requis'
                  : '',
            })}
            onChange={e => {
              // @ts-ignore
              e.target.value = e.target.value.trimStart();
            }}
          />
        </FormControl>
        <FormControl
          required={requiredFields}
          label="Nom"
          errorMessage={errors?.lastName?.message}
        >
          <Input
            isDisabled={isDisabled}
            isFullWidth
            {...register('lastName', {
              required:
                requiredFields || (userExistCheck && defaultValues?.lastName)
                  ? 'Ce champ est requis'
                  : '',
            })}
            onChange={e => {
              // @ts-ignore
              e.target.value = e.target.value.trimStart();
            }}
          />
        </FormControl>
      </Row>

      <Row spacing={20}>
        <FormControl
          required={requiredFields}
          label="Téléphone"
          errorMessage={errors?.phone?.message}
        >
          <PhoneInputControlled
            disabled={isDisabled}
            isDisabled={isDisabled}
            rules={{
              validate: (value: string) => {
                return validateFormPhoneFormatFR(value);
              },
            }}
            isFullWidth
            name="phone"
            control={control}
          />
        </FormControl>
        {contactRole === 'PROVIDER_CONTACT' ? (
          <FormControl
            required={requiredFields}
            label="Fonction"
            errorMessage={errors?.jobTitle?.message}
          >
            <Input
              isDisabled={isDisabled}
              isFullWidth
              {...register('jobTitle', {
                required:
                  requiredFields || (userExistCheck && defaultValues?.jobTitle)
                    ? 'Ce champ est requis'
                    : undefined,
              })}
              onChange={e => {
                // @ts-ignore
                e.target.value = e.target.value.trimStart();
              }}
            />
          </FormControl>
        ) : (
          <Box />
        )}
      </Row>

      <Row spacing={20}>
        {contactRole === 'CUSTOMER' && (
          <FormControl
            required={requiredFields}
            label="Fonction"
            errorMessage={errors?.jobTitle?.message}
          >
            <Input
              isDisabled={isDisabled}
              isFullWidth
              {...register('jobTitle', {
                required:
                  requiredFields || (userExistCheck && defaultValues?.jobTitle)
                    ? 'Ce champ est requis'
                    : '',
              })}
              onChange={e => {
                // @ts-ignore
                e.target.value = e.target.value.trimStart();
              }}
            />
          </FormControl>
        )}
        {contactRole === 'CUSTOMER' && (
          <FormControl
            label="Business Unit"
            errorMessage={errors?.businessUnit?.message}
          >
            <Input
              isDisabled={isDisabled}
              isFullWidth
              {...register('businessUnit')}
              onChange={e => {
                // @ts-ignore
                e.target.value = e.target.value.trimStart();
              }}
            />
          </FormControl>
        )}
      </Row>
      {contactRole === 'PROVIDER_CONTACT' && !isNew && (
        <Box mb={20}>
          <Text variant="h2">
            Chargé de compte assigné{' '}
            <Link
              isDisabled={isDisabled}
              fontWeight="lighter"
              iconLeft={<EditIcon />}
              onClick={() =>
                showUpdateAccountManagerModal({ manager: accountManager })
                  .then(async manager => {
                    if (manager) {
                      try {
                        await updateUser({
                          id: defaultValues?.cognitoUserId as string,
                          user: {
                            accountManager: manager,
                          },
                        });
                        showMessage(
                          'success',
                          'Le chargé de compte à été modifié'
                        );
                      } catch (e) {
                        //
                      }
                    }
                  })
                  .catch(e => {
                    //
                  })
              }
            >
              {accountManager ? 'Modifier' : 'Ajouter'}
            </Link>
          </Text>
          <Text variant="p" mt={20}>
            {!isFetchingAccountManager ? (
              (accountManager && getFullName(accountManager)) ||
              'Aucun chargé de compte'
            ) : (
              <Spinner />
            )}
          </Text>
        </Box>
      )}
      <Box hidden={isDisabled}>
        <Button
          type="submit"
          isLoading={loading}
          isDisabled={loading}
          data-cy="submit-create-contact"
        >
          {isNew ? 'Ajouter' : 'Enregistrer'}
        </Button>
      </Box>
    </form>
  );
};
