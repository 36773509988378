import {
  buildOneLineAddress,
  createContract,
  downloadFile,
  EXECUTIVE_CONTRACTS_SELECT,
  generateContract,
  getEstablishmentName,
  getFirstCaractsEstablisement,
  MANDAT_CONTRACTS_SELECT,
  MAX_FILE_SIZE_ANNEXE_MB,
  transformText,
  updateContractReference,
  uploadFile,
} from '@commons';
import {
  EContractLabel,
  EContractType,
  EFileType,
  ICompanyGetResponse,
} from '@freelancelabs/teoreme-commons';
import { EstablishmentStructuredCard } from 'components/cards/EstablishmentStructuredCard';
import { StructuredCard } from 'components/cards/StructuredCard';
import { ConfirmNavigationModal } from 'components/modals/ConfirmNavigationModal';
import { showDisplayPdfModal } from 'components/modals/DisplayPdfModal';
import { showSignatoryInformationModal } from 'components/modals/SignatoryInformationModal';
import {
  Box,
  Button,
  CheckSwitch,
  FileInput,
  FileListControlled,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Link,
  Row,
  StaticSelectControlled,
  Text,
} from 'components/ui';
import { Card, CardBody } from 'components/ui/Card';
import { AddIcon } from 'components/ui/icons';
import { useShowMessage } from 'hooks/useShowMessage';
import { truncate } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { queryClient } from '@commons';
import { useHistory } from 'react-router-dom';

type FormValues = {
  manualReference?: string;
  rpps?: string;
  contractType?: any;
  additionalFiles?: any;
  contractTitle?: string;
  shouldUseHqInformation?: '1' | '0';
};
type GenerateExecutiveContractFormProps = {
  defaultValues?: FormValues;
  onSubmit?: any; // TODO MAKE TYPES
  onClose: (action: boolean) => void;
  establishment: ICompanyGetResponse;
  refOrder?: string;
  contractLabel: EContractLabel;
};

export const GenerateExecutiveContractForm = ({
  defaultValues,
  onSubmit: onSubmitParent,
  onClose,
  establishment,
  refOrder,
  contractLabel,
}: GenerateExecutiveContractFormProps) => {
  const contractLabelName =
    contractLabel === EContractLabel.EXECUTIVE ? 'contrat-cadre' : 'mandat';
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [customContractTitle, setCustomContractTitle] = useState(false);
  const [errorSignOfficer, setErrorSignOfficer] = useState<boolean | string>(
    false
  );
  const [fileCCOther, setFileCCOther] = useState<File | null>(null);
  const showMessage = useShowMessage();
  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors, isDirty, isSubmitSuccessful },
    setError,
  } = useForm<FormValues>({
    defaultValues: { shouldUseHqInformation: '1', ...defaultValues },
  });

  const contractType = watch('contractType');
  const additionalFiles = watch('additionalFiles');
  const manualReference = watch('manualReference');
  const contractTitle = watch('contractTitle');
  const shouldUseHqInformation = watch('shouldUseHqInformation');
  const rpps = watch('rpps');
  const onSubmit = async () => {
    setLoading(true);
    let shouldUpdateReferenceOnDownload = true;
    if (
      establishment &&
      establishment.provider &&
      establishment.provider.signOfficer
    ) {
      setErrorSignOfficer(false);
      const shouldUseHqInformationBOOL =
        shouldUseHqInformation === '1' ? true : false;
      let onError = false;
      // await onSubmitParent?.(values);
      // params: IContractGenerateParams
      const params: any = {
        shouldUseHqInformation:
          establishment?.parent && !establishment?.headquarter
            ? shouldUseHqInformationBOOL
            : false,
        establishment: establishment.uuid,
        contractType:
          contractType &&
          (contractType as any).replace(
            `_${(contractType as any).split('_').pop()}`,
            ''
          ),
        lang: contractType && (contractType as EContractType).split('_').pop(),
      };
      if (contractType && (contractType as string).includes('LOREAL')) {
        params.manualReference = manualReference;
        params.rpps = rpps && rpps?.length > 0 ? rpps : undefined;
      }
      if (
        contractType &&
        ((contractType as string).includes('OTHER') ||
          (contractType as string).includes('SPECIFIC'))
      ) {
        if (customContractTitle) {
          params.contractTitle = contractTitle;
        }
        if (contractType.includes('OTHER')) {
          params.manualReference = manualReference;
        }
        if (fileCCOther) {
          try {
            const uploadCCOtherResponse = await uploadFile({
              file: fileCCOther as File,
              fileType: EFileType.TEMPORARY,
              establishment: establishment?.uuid || '',
              actionType: 'upload',
            });
            params.additionalFiles = [
              {
                path: uploadCCOtherResponse?.fileLocation,
              },
            ];
            additionalFiles &&
              (additionalFiles as any).forEach((file: any) => {
                params.additionalFiles.push({ path: file.fileLocation });
              });
          } catch (e) {
            //
          }
        } else {
          //@ts-ignore
          setError('fileCCOther', { message: 'Ce champ est requis' });
          onError = true;
        }
      } else {
        //@ts-ignore
        params.additionalFiles = additionalFiles
          ? (additionalFiles as any).map((file: any) => {
              return {
                path: file.fileLocation,
              };
            })
          : [];
      }

      /*additionalFiles: additionalFiles ? (additionalFiles as any).map((file:any) => {
        return {
          path:file.fileLocation
         }
       }): [],*/

      if (!onError) {
        try {
          const genrateCC = await generateContract(params);
          const fileCCGenerate = await downloadFile(genrateCC.url);
          const fileCC = {
            eTag: fileCCGenerate?.headers.etag.split('"').join(''),
            fileLocation: genrateCC.url,
            fileName: genrateCC.fileName,
          };
          await showDisplayPdfModal({
            layer: 999,
            fileLocation: genrateCC.url,
            fileName: `${(genrateCC as any)?.fileName}`,
            confirmLabel: 'Envoyer',
            onConfirm: async () => {
              const cognitoUserId = localStorage?.getItem('cognitoUserId');
              await createContract({
                isGenerated: true,
                contract: {
                  refOrder: refOrder,
                  accountManager: cognitoUserId?.toString(), //establishment.provider.manager.cognitoUserId,
                  attachment: {
                    eTag: fileCC.eTag as string,
                    fileLocation: fileCC.fileLocation,
                    fileName: fileCC?.fileName,
                  },
                  establishment: establishment.uuid,
                  label: contractLabel, //EContractLabel?.EXECUTIVE,
                  refContract: genrateCC?.refContract,
                  type:
                    contractType &&
                    (contractType as any).replace(
                      `_${(contractType as any).split('_').pop()}`,
                      ''
                    ),
                  lang: contractType && (contractType as any).split('_').pop(),
                  signOfficer:
                    establishment.provider.signOfficerType ===
                    'CORPORATE_OFFICER'
                      ? establishment.provider.signOfficer
                      : undefined,
                  delegatedSignOfficer:
                    establishment.provider.signOfficerType ===
                    'DELEGATED_OFFICER'
                      ? establishment.provider.delegatedSignOfficer
                      : undefined,
                },
              });
            },
            downloadConfirm: async () => {
              // update counter in case of download
              if (shouldUpdateReferenceOnDownload) {
                await updateContractReference({
                  establishment: establishment.uuid,
                });
                shouldUpdateReferenceOnDownload = false;
              }

              // whatever choice was made, download will not be block. Returning true will start downloading.
              return true;
            },
          });
          queryClient.refetchQueries({ queryKey: [establishment.uuid] });
          queryClient.refetchQueries({
            queryKey: ['contracts'],
            type: 'active',
          });
          queryClient.refetchQueries({
            queryKey: ['establishments'],
            type: 'active',
          });
          shouldUpdateReferenceOnDownload = true;
          onClose(true);
        } catch (e) {
          showMessage(
            'error',
            `Une erreur est survenue lors de la génération du ${contractLabelName}`
          );
        }
      }
    } else {
      setErrorSignOfficer(
        'Veuillez renseigner les informations du signataire.'
      );
    }
    setLoading(false);
  }; //, [shouldUpdateReferenceOnDownload, setShouldUpdateReferenceOnDownload]);

  const { signOfficerType, delegatedSignOfficer, signOfficer } =
    establishment?.provider;
  const delegationOrder = delegatedSignOfficer?.delegationOrder;
  useEffect(() => {
    // register({ name: 'fileCC' });
  }, []);
  const siretOrIdentifier = establishment.siret
    ? `N° SIRET : ${establishment.siret}`
    : `N° d’immatriculation : ${establishment.identifier}`;
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <ConfirmNavigationModal active={isDirty && !isSubmitSuccessful} />
      <Text variant="h2" mb={20}>
        Envoyer un {contractLabelName}
      </Text>
      <Text variant="h3" mb={20}>
        Informations du fournisseur
      </Text>
      <Box mt={20} mb={20}>
        {!establishment?.parent && (
          <>
            <FormLabel>Établissement</FormLabel>
            <Box
              style={{ cursor: 'pointer' }}
              onClick={() =>
                history.push(
                  `/providers/establishments/${establishment.uuid}/information`
                )
              }
            >
              <StructuredCard
                avatarLetter={getFirstCaractsEstablisement(establishment)}
                primaryTopText={getEstablishmentName(establishment)}
                secondaryTopText={
                  establishment.address &&
                  buildOneLineAddress(establishment.address)
                }
                primaryBottomText={`${siretOrIdentifier} - N° TVA : ${establishment.vatNumber}`}
              />
            </Box>
          </>
        )}
        {establishment?.parent && !establishment?.headquarter && (
          <Box mb={20} mt={20}>
            <FormLabel mb={20}>Séléctionner l'établissement</FormLabel>
            <EstablishmentStructuredCard
              disabledRedirect
              isSelectable
              isSelected={shouldUseHqInformation === '1'}
              onChangeSelected={() => setValue('shouldUseHqInformation', '1')}
              forRole={'PROVIDER'}
              establishment={establishment?.parent}
              mb={20}
            />
            <EstablishmentStructuredCard
              disabledRedirect
              isSelectable
              isSelected={shouldUseHqInformation === '0'}
              onChangeSelected={() => setValue('shouldUseHqInformation', '0')}
              forRole={'PROVIDER'}
              establishment={establishment}
              mb={20}
            />
          </Box>
        )}
      </Box>
      <FormLabel>Signataire</FormLabel>
      {!signOfficer && (
        <Box mb={20}>
          <Link
            iconLeft={<AddIcon />}
            onClick={async () =>
              await showSignatoryInformationModal({
                establishment: establishment,
              }).then(action => {
                if (action === true) {
                  setErrorSignOfficer(false);
                }
              })
            }
          >
            Ajouter les informations du signataire
          </Link>
        </Box>
      )}
      {errorSignOfficer && (
        <FormControl label="" errorMessage={errorSignOfficer}></FormControl>
      )}
      {signOfficer && (
        <Box mb={20}>
          <Card>
            <CardBody>
              <Row spacing={20}>
                <Box>
                  <FormLabel>Qui signera le contrat cadre ?</FormLabel>
                  <Text mb={20} variant="p">
                    {signOfficerType === 'DELEGATED_OFFICER'
                      ? "Un tiers muni d'une délégation de signature"
                      : 'Le mandataire social'}
                  </Text>
                </Box>
                <Box>
                  <Link
                    ml={'80%'}
                    onClick={() =>
                      showSignatoryInformationModal({
                        establishment: establishment,
                      }).then(action => {
                        if (action === true) {
                          setErrorSignOfficer(false);
                        }
                      })
                    }
                  >
                    Modifier
                  </Link>
                </Box>
              </Row>
              {signOfficerType === 'DELEGATED_OFFICER' && (
                <>
                  <Row spacing={20}>
                    <Box alignContent="right" alignItems="top">
                      <FormLabel>Personne habilité à signer</FormLabel>
                      <Text variant="p">{`${
                        (delegatedSignOfficer?.civility as any) === 2
                          ? 'Mme.'
                          : 'M.'
                      } ${transformText(
                        delegatedSignOfficer?.firstName,
                        'capitalize'
                      )} ${transformText(
                        delegatedSignOfficer?.lastName,
                        'uppercase'
                      )}`}</Text>
                      <Text variant="p">{`${
                        delegatedSignOfficer?.jobTitle || ''
                      }`}</Text>
                      <Text variant="p">{`Tel : ${
                        delegatedSignOfficer?.phone || ''
                      }`}</Text>
                      <Text variant="p">{`Email : ${
                        delegatedSignOfficer?.email || ''
                      }`}</Text>
                    </Box>
                    <Box>
                      <FormLabel>Mandataire social</FormLabel>
                      <Text variant="p">{`${
                        (signOfficer?.civility as any) === 2 ? 'Mme.' : 'M.'
                      }
                      ${transformText(signOfficer?.firstName, 'capitalize')}
                      ${transformText(
                        signOfficer?.lastName,
                        'uppercase'
                      )}`}</Text>
                      <Text variant="p">{`${
                        signOfficer?.jobTitle || ''
                      }`}</Text>
                      <Text variant="p">{`Tel : ${
                        signOfficer?.phone || ''
                      }`}</Text>
                      <Text variant="p">{`Email : ${
                        signOfficer?.email || ''
                      }`}</Text>
                    </Box>
                  </Row>
                  <Row spacing={20}>
                    <Box mt={20}>
                      <FormLabel>Délégation de signature</FormLabel>
                      <Text variant="p">
                        <Link
                          mr={10}
                          onClick={() =>
                            showDisplayPdfModal({
                              fileLocation: delegationOrder?.fileLocation || '',
                              fileName: `${delegationOrder?.fileName}.pdf`,
                              layer: 200,
                            })
                          }
                        >
                          {`${delegationOrder?.fileName}`}
                        </Link>
                      </Text>
                    </Box>
                    <Box></Box>
                  </Row>
                </>
              )}
              {signOfficerType !== 'DELEGATED_OFFICER' && (
                <Row spacing={20}>
                  <Box>
                    <FormLabel>Mandataire social</FormLabel>
                    <Text variant="p">{`${
                      (signOfficer?.civility as any) === 2 ? 'Mme.' : 'M.'
                    } ${transformText(
                      signOfficer?.firstName,
                      'capitalize'
                    )} ${transformText(
                      signOfficer?.lastName,
                      'uppercase'
                    )}`}</Text>
                    <Text variant="p">{`${signOfficer?.jobTitle || ''}`}</Text>
                    <Text variant="p">{`Tel : ${
                      signOfficer?.phone || ''
                    }`}</Text>
                    <Text variant="p">{`Email : ${
                      signOfficer?.email || ''
                    }`}</Text>
                  </Box>
                  <></>
                </Row>
              )}
            </CardBody>
          </Card>
        </Box>
      )}
      <Row spacing={20}>
        <FormControl
          mb={contractType?.includes('SPECIFIC') ? 20 : 0}
          label={`TYPE DE ${contractLabelName?.toLocaleUpperCase()}`}
          required
          errorMessage={errors?.contractType?.message}
          warningMessage={
            contractType?.includes('SPECIFIC') &&
            'Merci de vérifier que le documents que vous allez télécharger ne contiennent ni page d’en tête, ni page de signature.  Vous devez uniquement télécharger les articles concernant votre contrat spécifique. '
          }
        >
          <StaticSelectControlled
            control={control}
            options={
              contractLabel === EContractLabel.EXECUTIVE
                ? EXECUTIVE_CONTRACTS_SELECT
                : MANDAT_CONTRACTS_SELECT
            }
            name="contractType"
            placeholder={`TYPE DE ${contractLabelName?.toLocaleUpperCase()}`}
            rules={{ required: 'Ce champs est requis' }}
          />
        </FormControl>
        <Box>
          {(contractType as string) &&
            (contractType as string).includes('SPECIFIC') && (
              <CheckSwitch
                id="checkOne"
                mb={20}
                mt={25}
                defaultChecked={false}
                onChange={() => {
                  setCustomContractTitle(!customContractTitle);
                }}
              >
                <Text variant="p">Modifier le titre du contrat</Text>
                {(contractType as string) &&
                  (contractType as string).includes('SPECIFIC') &&
                  customContractTitle === false && (
                    <Text variant="disabled" fontSize={11}>
                      Par défaut : CONTRACT CADRE DE SOUS-TRAITANCE
                    </Text>
                  )}
              </CheckSwitch>
            )}

          {(contractType as string) &&
            (contractType as string).includes('SPECIFIC') &&
            customContractTitle === true && (
              <FormControl
                label="Titre du contrat"
                errorMessage={
                  errors?.contractTitle?.type === 'maxLength'
                    ? 'Veuillez saisir moins de 33 caractères'
                    : errors?.contractTitle?.message
                }
                required
              >
                <Input
                  placeholder="CONTRAT CADRE DE SOUS-TRAITANCE"
                  isFullWidth
                  {...register('contractTitle', {
                    required: 'Ce champ est requis',
                    maxLength: 32,
                  })}
                />
              </FormControl>
            )}
          {(contractType as string) &&
            (contractType as string).includes('OTHER') && (
              <FormControl
                label="Référence du contrat"
                errorMessage={errors?.manualReference?.message}
              >
                <Input
                  isFullWidth
                  {...register('manualReference', {
                    required: 'Ce champ est requis',
                  })}
                />
              </FormControl>
            )}
        </Box>
      </Row>
      {(contractType as string) &&
        (contractType as string)?.includes('LOREAL') && (
          <Row spacing={20}>
            {/* <FormControl
              label="Référence du contrat"
              required
              errorMessage={errors?.manualReference?.message}
            >
              <Input
                isFullWidth
                {...register('manualReference', {
                  required: 'Ce champ est requis',
                })}
              />
            </FormControl> */}
            {contractType === 'LOREAL_MANDATE_GENERIC_FR' ? (
              <FormControl
                label="N° RPPS"
                errorMessage={
                  errors?.rpps?.type === 'maxLength' ||
                  errors?.rpps?.type === 'minLength'
                    ? 'Veuillez saisir 11 caractères'
                    : errors?.rpps?.message
                }
              >
                <Input
                  isFullWidth
                  {...register('rpps', {
                    minLength: 11,
                    maxLength: 11,
                  })}
                />
              </FormControl>
            ) : (
              <></>
            )}
            <></>
          </Row>
        )}
      {contractType &&
        !(contractType as string).includes('OTHER') &&
        !(contractType as string).includes('SPECIFIC') && (
          <Box mb={40}>
            <FormLabel>Annexes (Optionnel)</FormLabel>
            <FormLabel style={{ fontWeight: 300 }}>
              Format accepté : PDF
            </FormLabel>
            <FileListControlled
              control={control}
              name="additionalFiles"
              fileType={EFileType.TEMPORARY}
              actionName={'Importer un document'}
              accept=".pdf"
              maxSize={MAX_FILE_SIZE_ANNEXE_MB}
            />
          </Box>
        )}
      {contractType &&
        ((contractType as string).includes('OTHER') ||
          (contractType as string).includes('SPECIFIC')) && (
          <Row spacing={20}>
            <Box mb={40}>
              <FileInput
                mb={20}
                label={transformText(contractLabelName, 'capitalize')}
                onSelect={files => setFileCCOther(files[0])}
                accept=".pdf"
                subLabel="Format accepté : PDF"
                required
                // @ts-ignore
                errorMessage={errors?.fileCCOther?.message}
              >
                <Link iconLeft={<AddIcon />}>
                  {fileCCOther
                    ? truncate((fileCCOther as File).name, {
                        length: 40,
                        omission: '....pdf',
                      })
                    : `Importer un ${contractLabelName}`}
                </Link>
              </FileInput>
            </Box>
            <Box mb={40}>
              <FormLabel>Annexes (Optionnel)</FormLabel>
              <FormLabel style={{ fontWeight: 300 }}>
                Format accepté : PDF
              </FormLabel>
              <FileListControlled
                control={control}
                name="additionalFiles"
                fileType={EFileType.TEMPORARY}
                actionName={'Importer un document'}
                accept=".pdf"
                maxSize={MAX_FILE_SIZE_ANNEXE_MB}
              />
            </Box>
          </Row>
        )}
      <Flex>
        <Button type="submit" isLoading={loading} isDisabled={!isDirty}>
          Générer
        </Button>
      </Flex>
    </form>
  );
};
