import {
  IJoinedCraWithInvoice,
  ECraPatchActions,
} from '@freelancelabs/teoreme-commons';
import { ConfirmNavigationModal } from 'components/modals/ConfirmNavigationModal';
import {
  Flex,
  Box,
  Button,
  Text,
  Divider,
  Radio,
  FormControl,
} from 'components/ui';
import { ExclamationCircleIcon } from 'components/ui/icons';
import React from 'react';
import { useForm } from 'react-hook-form';
import { Theme } from 'styles';
import { CardCra } from 'components/cards';
type FormValues = {
  action: ECraPatchActions;
};
type StepOneFormProps = {
  dataStep: any;
  steps: any;
  cra: IJoinedCraWithInvoice;
  onSubmit: (data: any) => any | void;
};

export const StepOne = ({
  dataStep,
  steps,
  cra,
  onSubmit: onSubmitParent,
}: StepOneFormProps) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isDirty, isSubmitSuccessful },
    watch,
  } = useForm<FormValues>({
    defaultValues: {
      ...dataStep,
    },
  });
  const disableButton = () => {
    if (!action) {
      return true;
    }
    return false;
  };
  const action = watch('action');
  const isDisabled = disableButton();
  const onSubmit = async (formValues: FormValues) => {
    onSubmitParent(formValues);
  };
  return (
    <Box p={20}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <ConfirmNavigationModal active={isDirty && !isSubmitSuccessful} />

        <CardCra cra={cra} displayGescom displayInvoice displayMission />
        <Text mb={20} variant="h1">
          Étape 1 : Type d'action
        </Text>
        <Box>
          {cra.nbWorkingDays !== 0 && (
            <Flex ml={'5px'} alignContent={'center'} alignItems={'center'}>
              <ExclamationCircleIcon
                width={25}
                fill={Theme?.colors?.warning?.default}
              />
              <Text variant="span" ml={2}>
                <b>
                  Corriger le CRA : Cette option vous permet de rectifier une
                  erreur sur le CRA (ainsi que les factures fournisseur et
                  client qui lui sont liées), sans modifier le statut du CRA et
                  sans le renvoyer pour correction par le fournisseur.
                </b>
              </Text>
            </Flex>
          )}
          <Flex mb={20} mt={20} alignContent={'center'} alignItems={'center'}>
            <ExclamationCircleIcon
              fill={Theme?.colors?.error?.default}
              width={25}
            />
            <Text variant="span" ml={2}>
              <b>
                Refuser le CRA : Cette option vous permet de passer un CRA
                validé au statut “Refusé”. Le fournisseur doit alors soumettre
                de nouveau son CRA à la validation.
              </b>
            </Text>
          </Flex>
          <Divider />
        </Box>
        <Box mb={20} p={20}>
          <FormControl
            label="Que souhaitez vous faire ? "
            required
            errorMessage={errors?.action?.message}
          >
            <Flex>
              {!cra?.mission?.customer?.billingOptions?.isDirectPayment &&
                cra.nbWorkingDays !== 0 && (
                  <Radio
                    {...register('action', {
                      required: 'Ce champ est requis',
                    })}
                    value={ECraPatchActions?.FIX_CRA}
                  >
                    Corriger le CRA
                  </Radio>
                )}
              <Radio
                {...register('action', {
                  required: 'Ce champ est requis',
                })}
                value={ECraPatchActions?.REJECT_CRA}
              >
                Refuser le CRA
              </Radio>
            </Flex>
          </FormControl>
        </Box>
        <Flex justifyContent={'flex-end'} width={1 / 1} mb={20} mr={20}>
          <Box>
            <Button type="submit" isDisabled={isDisabled}>
              Suivant
            </Button>
          </Box>
        </Flex>
      </form>
    </Box>
  );
};
