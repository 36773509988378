import { IJoinedInvoice } from '@freelancelabs/teoreme-commons';
import { Box, SpinnerBox, Text } from 'components/ui';
import { create } from 'react-modal-promise';
import { ModalFrame, ModalProps } from '../ModalFrame';
import { GenerateCustomerInvoiceForm } from 'forms/invoices/Customer/GenerateCustomerInvoice';
import { useInvoiceFindOne } from '@commons';
type Props = ModalProps & {
  invoiceUuid: string;
  beforeValidation?: () => void;
};

export const GenerateInvoiceModal = ({
  onResolve,
  isOpen,
  invoiceUuid,
}: Props) => {
  const { data: invoice, status } = useInvoiceFindOne(invoiceUuid);

  return (
    <ModalFrame
      isOpen={isOpen}
      onClose={() => onResolve(true)}
      width={1380}
      closeIcon
    >
      <Text width={1 / 1} textAlign={'center'} variant="h1">
        Génération de la facture client
      </Text>
      {status === 'pending' ? (
        <SpinnerBox />
      ) : (
        <Box mt={20}>
          <GenerateCustomerInvoiceForm
            invoice={invoice}
            onClose={() => onResolve(true)}
          />
        </Box>
      )}
    </ModalFrame>
  );
};

export const showGenerateInvoiceModal = create<Props, IJoinedInvoice>(
  GenerateInvoiceModal
);
