import {
  EContractLabel,
  EContractState,
  EContractType,
  EFileType,
} from '@freelancelabs/teoreme-commons';
import { createContract, removeOffsetDate } from '@commons';
import { uploadFile } from '@commons';
import { Box, SpinnerBox } from 'components/ui';
import { ImportAmendmentForm } from 'forms/ImportAmendmentForm';
import { useShowMessage } from 'hooks/useShowMessage';
import React from 'react';
import { create } from 'react-modal-promise';
import { queryClient } from '@commons';
import { ModalFrame, ModalProps } from './ModalFrame';
import { useEstablishmentFindOne, useContractFindMany } from '@commons'; // moved

type Props = ModalProps & {
  beforeValidation?: () => void;
  establishmentUuid: string;
};
type FormValues = {
  refContract: string;
  createdAt: Date;
  type: string;
  lang: string;
  file: File;
};

export const ImportAmendmentModal = ({
  onResolve,
  isOpen,
  establishmentUuid,
}: Props) => {
  const showMessage = useShowMessage();
  const { data: establishment, status } =
    useEstablishmentFindOne(establishmentUuid);

  const { data: contractsQuery } = useContractFindMany({
    filterObject: {
      uuid: { $in: establishment?.provider?.contracts?.map(e => e?.uuid) },
      label: EContractLabel.EXECUTIVE,
      state: 'DONE',
    },
    limit: 1,
  });
  const refContract = contractsQuery?.contracts[0].refContract;
  const lang = contractsQuery?.contracts[0].lang;

  const handleSubmit = async (formData: FormValues) => {
    if (!establishment) return null;
    //Timezone SAGE USE SIMPLE DATE WITHOUT HOURS
    const signedAt = removeOffsetDate(formData?.createdAt);
    try {
      const uploadResponse = await uploadFile({
        file: formData.file,
        fileType: EFileType.EXECUTIVE,
        establishment: establishment?.uuid || '',
        actionType: 'upload',
      });
      if (uploadResponse) {
        const cognitoUserId = localStorage?.getItem('cognitoUserId');
        await createContract({
          contract: {
            accountManager: cognitoUserId?.toString(), //establishment.provider.manager.cognitoUserId,,
            attachment: {
              eTag: uploadResponse.eTag,
              fileLocation: uploadResponse.fileLocation,
              fileName: uploadResponse.fileName,
            },
            signedAt: signedAt,
            establishment: establishment.uuid,
            label: EContractLabel.EXECUTIVE,
            lang,
            refContract,
            type: EContractType.AMENDMENT,
          },
        });
        queryClient.refetchQueries({ queryKey: ['contracts'], type: 'active' });
      }
      showMessage('success', 'Avenant créé');
    } catch (e) {
      showMessage(
        'error',
        `Une erreur est survenue lors de la création de l'avenant`
      );
      // TODO MAKE BACK ERROR
    }
    onResolve(false);
  };

  const loading = status === 'pending';
  const lastInitialContractDone = contractsQuery?.contracts?.find(
    contract =>
      contract?.type !== EContractType?.AMENDMENT &&
      contract?.state === EContractState.DONE
  );
  return (
    <ModalFrame
      isOpen={isOpen}
      onClose={() => onResolve(false)}
      width={470}
      closeIcon
    >
      <Box>
        {loading && <SpinnerBox />}
        {!loading && (
          <ImportAmendmentForm
            contract={lastInitialContractDone}
            refContract={refContract}
            onClose={() => onResolve(false)}
            onSubmit={handleSubmit}
          />
        )}
      </Box>
    </ModalFrame>
  );
};

export const showImportAmendmentModal = create<Props>(ImportAmendmentModal);
