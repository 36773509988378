import React, { forwardRef, ReactNode } from 'react';
import styled, { css } from 'styled-components';
import { Box, boxProperties, BoxProps } from './Box';
import { getDecimalInput } from '@commons';
import { EyeIcon, SvgEyeCloseIcon } from 'components/ui/icons';
import { Theme } from 'styles';

type InputStyledProps = {
  variantColor?: 'primary' | 'error';
  isDisabled?: boolean;
  isFullWidth?: boolean;
  width?: string | number;
  icon?: ReactNode;
  min?: string;
  step?: string;
  maxDecimal?: number;
  isFieldSet?: boolean;
};

const InputStyled = styled.input<InputStyledProps>`
  height: 50px;
  border-radius: ${props => (props?.isFieldSet ? '0px 4px 4px 0px' : '4px')};
  border: solid 1px ${props => props.theme.colors?.grayBlue};
  ${props => props.theme.colors[props.variantColor || 'primary'].light};
  color: ${props => props.theme.colors[props.variantColor || 'primary'].dark};
  background-color: #fff;
  padding: 15px;
  font-size: 14px;
  box-sizing: border-box;
  outline: none;
  &:focus {
    border: solid 1px
      ${props => props.theme.colors[props.variantColor || 'primary'].default};
  }
  &:disabled {
    background-color: ${props =>
      props.theme.colors[props.variantColor || 'primary'].lighter};
    cursor: not-allowed;
  }

  ${props =>
    props.width &&
    css`
      width: ${props.width};
    `}

  ${props =>
    props.isFullWidth &&
    css`
      width: 100%;
    `}

  padding-right: ${props => props.icon && '50px'};

  /* Hide input number arrows */
  -moz-appearance: textfield;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  ${boxProperties}
`;

const Icon = styled.div`
  position: absolute;
  top: 0px;
  height: 100%;
  display: flex;
  align-items: center;
  right: 17px;
  font-size: 20px;
  color: #ccc;
`;
const IconPassword = styled.div`
  position: relative;
  top: -36px;
  height: 100%;
  display: flex;
  align-items: center;
  right: -75%;
  font-size: 20px;
  color: #ccc;
`;

type InputProps = BoxProps &
  Omit<React.HTMLProps<HTMLInputElement>, 'ref' | 'as'> &
  InputStyledProps;

export const Input = forwardRef(
  (
    {
      value,
      onChange,
      isFullWidth,
      icon,
      type,
      name,
      placeholder,
      variantColor,
      min,
      step,
      maxDecimal = 2,
      autoComplete,
      isFieldSet,
      ...props
    }: InputProps,
    ref
  ) => {
    const [passwordType, setPasswordType] = React.useState(type);

    return (
      <Box
        display="inline-block"
        width={isFullWidth ? '100%' : props.width ? props.width : 'auto'}
        position="relative"
        {...props}
      >
        <InputStyled
          {...{
            autoComplete,
            value,
            onChange,
            isFullWidth,
            type: passwordType,
            icon,
            name,
            placeholder,
            variantColor,
            step,
            isFieldSet,
          }}
          //FIX disabled increment on Scroll in input number !
          //@ts-ignore
          onWheel={e => type === 'number' && document?.activeElement?.blur()}
          onChange={e => {
            if (type === 'number' && maxDecimal) {
              e.target.value = getDecimalInput(
                e.target.value,
                maxDecimal
              ) as string;
            }
            if (typeof onChange === 'function') {
              onChange(e);
            }
            return e;
          }}
          width={props.width}
          disabled={props.isDisabled}
          ref={ref as any}
        />
        {icon && <Icon>{icon}</Icon>}
        {type === 'password' ? (
          passwordType === 'text' ? (
            <IconPassword>
              <SvgEyeCloseIcon
                fill={Theme.colors.blue}
                onClick={() => setPasswordType('password')}
                style={{ marginRight: 50, cursor: 'pointer' }}
              />
            </IconPassword>
          ) : (
            <IconPassword>
              <EyeIcon
                fill={Theme.colors.blue}
                onClick={() => setPasswordType('text')}
                viewBox="0 0 20 20"
                style={{
                  marginRight: 55,
                  cursor: 'pointer',
                }}
              />
            </IconPassword>
          )
        ) : null}
      </Box>
    );
  }
);
