import { Theme } from 'styles';
import {
  ECraStatus,
  IJoinedCraWithInvoice,
  IJoinedAdditionalActivityWithInvoice,
  IJoinedInvoice,
  EInvoiceStatus,
  EMissionStatus,
} from '@freelancelabs/teoreme-commons';
import { getHumanDate } from '@commons';

export const getStatusData = (
  data:
    | IJoinedCraWithInvoice
    | IJoinedAdditionalActivityWithInvoice
    | IJoinedInvoice,
  hiddenDate?: boolean
): {
  variantColor: 'grey' | 'warning' | 'success' | 'error';
  statusText: string;
  textColor: string;
} => {
  //@ts-ignore
  const status = data?.state || data?.status;
  const validatedDate = hiddenDate
    ? ''
    : `le ${getHumanDate(
        //@ts-ignore
        (data?.validatedAt as Date) || (data?.statusChangedAt as Date)
      )}`;
  const sendAt = hiddenDate
    ? ''
    : //@ts-ignore
      `le ${getHumanDate(data?.statusChangedAt as Date)}`;
  const paidDate = hiddenDate
    ? ''
    : `- Virement émis le :${getHumanDate(
        //@ts-ignore
        data?.sage?.paymentDetails?.paymentDate as Date
      )}`;
  const rejectedDate = hiddenDate
    ? ''
    : `le ${
        data?.rejectedAt
          ? getHumanDate(data?.rejectedAt as Date)
          : getHumanDate(
              //@ts-ignore
              (data?.stateChangedAt || data?.statusChangedAt) as Date
            )
      }`;
  switch (status) {
    case ECraStatus.TO_BE_FILLED:
      return {
        variantColor: 'grey',
        statusText: 'A saisir',
        textColor: Theme.colors.grey.default,
      };
    case ECraStatus.TO_BE_SUBMITTED:
      return {
        variantColor: 'grey',
        statusText: 'A soumettre',
        textColor: Theme.colors.grey.default,
      };
    case ECraStatus.TO_BE_VALIDATED:
      return {
        variantColor: 'warning',
        statusText: 'En attente de validation',
        textColor: Theme.colors.warning.default,
      };
    case ECraStatus.VALIDATED:
      return {
        variantColor: 'success',
        statusText: `Validée ${validatedDate}`,
        textColor: Theme.colors.success.default,
      };
    case ECraStatus.REJECTED:
      return {
        variantColor: 'error',
        statusText: `Refusée ${rejectedDate}`,
        textColor: Theme.colors.error.default,
      };
    case EMissionStatus.ABORTED:
      return {
        variantColor: 'error',
        statusText: `Annulé`,
        textColor: Theme.colors.error.default,
      };
    case EMissionStatus.DRAFT:
      return {
        variantColor: 'grey',
        statusText: 'Brouillion',
        textColor: Theme.colors.grey.default,
      };
    case ECraStatus.ARCHIVED:
      return {
        variantColor: 'grey',
        statusText: `Archivée`,
        textColor: Theme.colors.grey.default,
      };
    case EInvoiceStatus?.SENT:
      return {
        variantColor: 'success',
        statusText: `Envoyée ${sendAt}`,
        textColor: Theme.colors.success.default,
      };
    case EInvoiceStatus?.PAID:
      return {
        variantColor: 'success',
        statusText: `Payée ${paidDate}`,
        textColor: Theme.colors.success.default,
      };
    default:
      return {
        variantColor: 'grey',
        statusText: 'A saisir',
        textColor: Theme.colors.grey.default,
      };
  }
};

export const getStatusText = (
  status: any
): {
  variantColor: 'grey' | 'warning' | 'success' | 'error';
  statusText: string;
  textColor: string;
} => {
  switch (status) {
    case ECraStatus.TO_BE_FILLED:
      return {
        variantColor: 'grey',
        statusText: 'A saisir',
        textColor: Theme.colors.grey.default,
      };
    case ECraStatus.TO_BE_SUBMITTED:
      return {
        variantColor: 'grey',
        statusText: 'A soumettre',
        textColor: Theme.colors.grey.default,
      };
    case ECraStatus.TO_BE_VALIDATED:
      return {
        variantColor: 'warning',
        statusText: 'En attente de validation',
        textColor: Theme.colors.warning.default,
      };
    case ECraStatus.VALIDATED:
      return {
        variantColor: 'success',
        statusText: `Validé`,
        textColor: Theme.colors.success.default,
      };
    case ECraStatus.REJECTED:
      return {
        variantColor: 'error',
        statusText: `Refusé`,
        textColor: Theme.colors.error.default,
      };
    case EMissionStatus.DRAFT:
      return {
        variantColor: 'grey',
        statusText: 'Brouillion',
        textColor: Theme.colors.grey.default,
      };
    case EMissionStatus.ABORTED:
      return {
        variantColor: 'error',
        statusText: `Annulé`,
        textColor: Theme.colors.error.default,
      };
    case ECraStatus.ARCHIVED:
      return {
        variantColor: 'grey',
        statusText: `Archivé`,
        textColor: Theme.colors.grey.default,
      };
    case EInvoiceStatus?.SENT:
      return {
        variantColor: 'success',
        statusText: `Envoyé`,
        textColor: Theme.colors.success.default,
      };
    case EInvoiceStatus?.PAID:
      return {
        variantColor: 'success',
        statusText: `Payé`,
        textColor: Theme.colors.success.default,
      };
    case EInvoiceStatus.CANCELLED:
      return {
        variantColor: 'error',
        statusText: `Annulé`,
        textColor: Theme.colors.error.default,
      };
    default:
      return {
        variantColor: 'grey',
        statusText: 'A saisir',
        textColor: Theme.colors.grey.default,
      };
  }
};
