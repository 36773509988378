import {
  STAND_BY_DUTY_UNIT_TRANSLATE_FR,
  getFormulaDutyFormulaAmount,
} from '@commons';
import {
  EStandByDutyUnits,
  IFile,
  IJoinedMission,
  IStandByDutyFormulaLine,
  IStandByDutyLine,
} from '@freelancelabs/teoreme-commons';
import {
  Box,
  Button,
  Flex,
  FormControl,
  Input,
  StaticSelectControlled,
  Text,
  NumberFormInputControlled,
} from 'components/ui';
import { EuroIcon } from 'components/ui/icons';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Theme } from 'styles';

type Line = IStandByDutyLine & {
  lock?: boolean;
  key?: string;
};
type FormValues = Line & {
  uuid: string;
  standByDutyFormulaLineUuid?: string;
  providerFormula?: any;
  customerformula?: string;
  formulaParameters?: any;
  attachment?: IFile;
  amount?: number;
  unit?: EStandByDutyUnits;
  //margin?: number;
  lock: boolean;
};
type AddDutyLineProps = {
  line: Line;
  readOnly?: boolean;
  avalaibleFormulas: any[];
  mission: IJoinedMission;
  onCreateOrUpdateLine: (line: Line) => void;
  onDeleteLine: (line: Line) => void;
  unLockToUpdate?: (line: Line) => void;
  isEditable?: boolean;
};
export const AddDutyLine: React.FC<
  React.PropsWithChildren<AddDutyLineProps>
> = ({
  line,
  readOnly,
  isEditable,
  avalaibleFormulas,
  mission,
  onCreateOrUpdateLine,
  onDeleteLine,
  unLockToUpdate,
}) => {
  const [loading, setLoading] = useState(false);
  // const [attachmentFile, setAttachmentFile] = useState<File | undefined>();
  // const [loadingFile, setLoadingFile] = useState(false);

  const {
    control,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {
      ...line,
    },
  });

  const standByDutyFormulaLineUuid = watch('standByDutyFormulaLineUuid');

  const formulaSelected: IStandByDutyFormulaLine = avalaibleFormulas?.find(
    af => af?.key === standByDutyFormulaLineUuid
  )?.data;
  const input = watch('formulaParameters.INPUT');
  const calculateProviderAmount = () => {
    try {
      const resultA = getFormulaDutyFormulaAmount(
        {
          providerFormula:
            line?.providerFormula || formulaSelected?.providerFormula,
          customerFormula: '',
          formulaParameters: {
            INPUT: input,
            PROVIDER_RATE:
              line?.formulaParameters?.PROVIDER_RATE ||
              mission?.billing?.contractorRate,
            CUSTOMER_RATE:
              line?.formulaParameters?.CUSTOMER_RATE ||
              mission?.billing?.clientRate,
            I_RATE: line?.formulaParameters?.I_RATE || mission?.billing?.margin,
          },
        },
        'PROVIDER'
      );
      return String(resultA);
    } catch (e) {
      console.warn(e);
      return undefined;
    }
  };
  const calculateCustomerAmount = () => {
    try {
      const resultB = getFormulaDutyFormulaAmount(
        {
          providerFormula: '',
          customerFormula:
            line?.customerFormula || formulaSelected?.customerFormula,
          formulaParameters: {
            INPUT: input,
            PROVIDER_RATE:
              line?.formulaParameters?.PROVIDER_RATE ||
              mission?.billing?.contractorRate,
            CUSTOMER_RATE:
              line?.formulaParameters?.CUSTOMER_RATE ||
              mission?.billing?.clientRate,
            I_RATE: line?.formulaParameters?.I_RATE || mission?.billing?.margin,
          },
        },
        'CUSTOMER'
      );
      return String(resultB);
    } catch (e) {
      console.warn(e);
      return undefined;
    }
  };
  const providerAmount = calculateProviderAmount();
  const customerAmount = calculateCustomerAmount();
  const onSubmit = async (data: FormValues) => {
    const onError = false;
    setLoading(true);
    try {
      if (!onError) {
        const submitValues = {
          uuid: line?.uuid || standByDutyFormulaLineUuid,
          key: line?.uuid
            ? undefined
            : line?.key || String(new Date()?.getTime()),
          standByDutyFormulaLineUuid: standByDutyFormulaLineUuid,
          providerFormula:
            line?.providerFormula || formulaSelected?.providerFormula,
          customerFormula:
            line?.customerFormula || formulaSelected?.customerFormula,
          formulaParameters: {
            //...data.formulaParameters,
            INPUT: Number(data.formulaParameters?.INPUT),
            PROVIDER_RATE: mission?.billing?.contractorRate,
            CUSTOMER_RATE: mission?.billing?.clientRate,
            I_RATE: mission?.billing?.margin,
          },
          amount: Number(providerAmount),
          lock: true,
        };
        //@ts-ignore
        onCreateOrUpdateLine(submitValues);
      }
    } catch (e) {
      setLoading(false);
    }

    setLoading(false);
  };
  // const onChangeFile = (files: any) => {
  //   setLoadingFile(true);
  //   clearErrors('attachment');
  //   setAttachmentFile(files[0]);
  //   setLoadingFile(false);
  // };

  return (
    <Box width={1 / 1} mt={10}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Flex width={12 / 12}>
          <Box width={3 / 12}>
            <FormControl required label="Type de prestation" p={'5px'}>
              <StaticSelectControlled
                isDisabled={readOnly}
                control={control}
                rules={{ required: 'Ce champs est requis' }}
                name="standByDutyFormulaLineUuid"
                options={avalaibleFormulas}
              />
            </FormControl>
          </Box>
          <Box width={2 / 12}>
            <FormControl
              required
              label="Travaillé"
              p={'5px'}
              errorMessage={
                errors?.formulaParameters?.INPUT?.type === 'min'
                  ? 'Ne peut pas être inférieur à 1'
                  : errors?.formulaParameters?.INPUT?.message
              }
            >
              <Flex>
                <NumberFormInputControlled
                  control={control}
                  name="formulaParameters.INPUT"
                  maxDecimal={3}
                  step={'0.001'}
                  type="number"
                  isDisabled={readOnly || !formulaSelected}
                  isFullWidth
                  rules={{
                    required: 'Ce champ est requis',
                    min: 1,
                  }}
                />
                {formulaSelected?.unit && (
                  <Flex
                    alignItems="center"
                    ml={'-3px'}
                    p={'5px'}
                    borderTopRightRadius="8px"
                    borderBottomRightRadius="8px"
                    backgroundColor={Theme?.colors?.primary?.default}
                  >
                    <Text color="white">
                      {STAND_BY_DUTY_UNIT_TRANSLATE_FR[formulaSelected?.unit]}
                    </Text>
                  </Flex>
                )}
              </Flex>
            </FormControl>
          </Box>
          <Box width={2 / 12}>
            <FormControl label="Total fournisseur" p={'5px'}>
              <Input
                isDisabled
                value={providerAmount}
                icon={<EuroIcon />}
                isFullWidth
              />
            </FormControl>
          </Box>
          <Box width={2 / 12}>
            <FormControl label="Total client" p={'5px'}>
              <Input
                isDisabled
                value={customerAmount}
                icon={<EuroIcon />}
                isFullWidth
              />
            </FormControl>
          </Box>
          <Box width={3 / 12}>
            <Flex
              width={1 / 1}
              alignItems="center"
              justifyContent="center"
              mt={27}
            >
              {/* <Flex width={3 / 12}> */}
              {readOnly && isEditable ? (
                <>
                  <Button
                    onClick={() => unLockToUpdate && unLockToUpdate(line)}
                    height="40px"
                    ml={'5px'}
                    mr={'5px'}
                    variant="ghost"
                  >
                    Modifier
                  </Button>
                  <Button
                    onClick={() => onDeleteLine(line)}
                    height="40px"
                    variant="ghostError"
                  >
                    Supprimer
                  </Button>
                </>
              ) : (
                <Box>
                  {!readOnly && (
                    <Button
                      isLoading={loading}
                      height="40px"
                      variant="filled"
                      type="submit"
                    >
                      Ajouter
                    </Button>
                  )}
                </Box>
              )}
              {/* </Flex> */}
            </Flex>
          </Box>
        </Flex>
        {standByDutyFormulaLineUuid && (
          <Flex width={1 / 1}>
            <Text minWidth={210} variant="p" fontWeight="bolder">
              Description de la prestation :
            </Text>
            <Text>&nbsp;{formulaSelected?.description}</Text>
          </Flex>
        )}
      </form>
    </Box>
  );
};

AddDutyLine.displayName = 'AddDutyLine';

export default AddDutyLine;
