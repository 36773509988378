import { CloseIcon } from 'components/ui/icons';
import { AnimatePresence, motion } from 'framer-motion';
import React, {
  ReactNode,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { showDialogModal } from './DialogModal';
import { useLocation } from 'react-router-dom';

// layer singleton
let layer = 0;

const ModalContext = React.createContext<
  React.Dispatch<React.SetStateAction<boolean>>
>(() => {});

export const usePreventClose = () =>
  useContext(ModalContext) || ((active: boolean) => {});

const GlobalStyle = createGlobalStyle`
  body {
    overflow : hidden;
  }
`;

export type ModalProps = {
  onResolve: (params?: any) => any | undefined;
  close: (params?: any) => any | undefined;
  isOpen: boolean;
  open: boolean;
  instanceId: string;
  onReject: (rej?: any) => void;
};

const FixedContainer = styled.div<{ layer?: number; fullScreen?: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  z-index: 100;
  overflow-y: auto;
  padding: ${props => (props?.fullScreen ? '0px' : '40px 0')};
  z-index: ${props => (props.layer || 0) + 101};
`;

const ModalContainer = styled(motion.div)<{
  width: number;
  height?: number;
  fullScreen?: boolean;
}>`
  background: #ffffff;
  position: relative;
  width: ${props => (props?.fullScreen ? '100vw' : props.width + 'px')};
  height: ${props => (props?.fullScreen ? '100vh' : props.height + 'px')};
  box-sizing: border-box;
  padding: ${props => (props?.fullScreen ? '0px' : '40px')};
  z-index: 101;
  margin: auto;
`;

const CloseIconStyled = styled.div`
  position: absolute;
  top: 25px;
  right: 25px;
  cursor: pointer;
  opacity: 0.5;
  &:hover {
    opacity: 1;
  }
  z-index: 9999;
`;

const Background = styled(motion.div)`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: #000;
  opacity: 0.5;
  z-index: 100;
`;
type ModalFrameProps = {
  children?: ReactNode;
  isOpen?: boolean;
  closeIcon?: boolean;
  onClose?: (result?: any) => void;
  width?: number;
  height?: number;
  layer?: number;
  fullScreen?: boolean;
};
export const ModalFrame = ({
  children,
  onClose,
  isOpen,
  closeIcon = false,
  width = 100,
  height,
  fullScreen,
  layer: addedLayer,
  ...props
}: ModalFrameProps) => {
  const [preventClose, setPreventClose] = useState<boolean>(false);

  // by setting a ref to the singleton layer we are keeping its value along the component's life
  // TODO CREATE PROPS ADDEDLAYER FOR GENERATE CUSTOMER INVOICE ( 3 MODAL LVL)
  const thisLayer = useRef<number>(addedLayer ? addedLayer : layer);
  useEffect(() => {
    if (addedLayer) {
      layer = addedLayer;
    }
    layer++;
    return () => {
      layer--;
    };
  }, []);

  // hide on location change
  const isMountRef = useRef(true);
  const location = useLocation();
  useEffect(() => {
    if (!isMountRef.current) onClose && onClose();
    isMountRef.current = false;
  }, [location, isMountRef]);

  const handleCloseConfirm = async (result?: any) => {
    const confirm = preventClose
      ? await showDialogModal({
          title: 'Êtes-vous sûr(e) de vouloir fermer sans enregistrer ?',
          text: 'Toutes vos modifications seront perdues.',
          confirmLabel: 'Fermer sans enregistrer',
          cancelLabel: 'Ne pas fermer',
        })
      : true;
    if (confirm) onClose?.(result);
  };

  return (
    <ModalContext.Provider value={setPreventClose}>
      <FixedContainer fullScreen={fullScreen} layer={thisLayer.current}>
        <GlobalStyle />
        {/* @ts-ignore */}
        <AnimatePresence>
          {isOpen && (
            <Background
              onClick={handleCloseConfirm}
              key="background"
              initial={{ opacity: 0 }}
              animate={{ opacity: 0.5 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.2 }}
            />
          )}
          {isOpen && (
            <ModalContainer
              fullScreen={fullScreen}
              width={width}
              height={height}
              key="modal"
              initial={{ y: -15, opacity: 0 }}
              exit={{ y: -15, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ duration: 0.2 }}
            >
              {closeIcon && (
                <CloseIconStyled onClick={handleCloseConfirm}>
                  <CloseIcon width="25px" height="25px" color="#004269" />
                </CloseIconStyled>
              )}
              {children}
            </ModalContainer>
          )}
        </AnimatePresence>
      </FixedContainer>
    </ModalContext.Provider>
  );
};
