/* eslint-disable react-hooks/rules-of-hooks */
import {
  EChangeOwnerType,
  ECraStatus,
  EFileType,
  EInvoiceDocumentType,
  EInvoiceStatus,
  EInvoiceType,
  EMissionStructure,
  EStandByDutyUnits,
  EadditionalActivityStatus,
  EadditionalActivityType,
  ICra,
  IFile,
  IJoinedInvoice,
  IMission,
  IStandByDutyFormulaLine,
} from '@freelancelabs/teoreme-commons';
import { useShowMessage } from 'hooks/useShowMessage';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { showDialogModal } from 'components/modals/DialogModal';
import {
  EXPENSES_TYPE_TRANSLATE_FR,
  STAND_BY_DUTY_UNIT_TRANSLATE_FR,
  additionalActivityFindOne,
  buildOneLineAddress,
  cutLongText,
  getEstablishmentName,
  getFormulaDutyFormulaAmount,
  getFullName,
  getHumanDate,
  getInvoiceTotalAmount,
  getInvoiceWithTaxeAndDecution,
  isAdditionalActivityInvoice,
  isCreditNoteInvoice,
  isDisableFONEDate,
  isExpenseInvoice,
  isMileStoneInvoice,
  isProviderInvoice,
  isStandByDutyInvoice,
  isTimeSpentInvoice,
  managerExtractVerifyRibNeedWarning,
  queryClient,
  removeOffsetDate,
  round,
  sageCreateInvoice,
  uploadFile,
  useAdditionalActivityFindOne,
  useDownloadFile,
  useEstablishmentFindOne,
  useInvoiceUpdateOne,
  useMissionFindOne,
} from '@commons';
import { Accordion } from 'components/Accordion';
import { InvoiceInformation } from 'components/InvoiceInformation';
import { Section } from 'components/Section';
import { HistoryList } from 'components/lists/HistoryList';
import { showCreateGescomBDLModal } from 'components/modals/gescom/CreateGescomBDLModal';
import { showOnConfirmFormModal } from 'components/modals/invoices/OnConfirmFormModal';
import { showRejectInvoiceModal } from 'components/modals/invoices/RejectInvoiceModal';
import {
  ALink,
  BlocInformation,
  Box,
  Button,
  CheckSwitch,
  Container,
  CustomToolTip,
  DatePickerControlled,
  Divider,
  FileInput,
  Flex,
  FormControl,
  FormLabel,
  Input,
  LabelField,
  Link,
  Spinner,
  StaticSelectControlled,
  Status,
  Tab,
  Text,
} from 'components/ui';
import {
  CalendarIcon,
  CheckIcon,
  CloseIcon,
  DownloadIcon,
  EuroIcon,
  ExclamationCircleIcon,
} from 'components/ui/icons';
import { printFormat } from 'iban';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import styled, { useTheme } from 'styled-components';
import { Theme } from 'styles';
import { columns } from '../HistoryListData';
import { InvoiceLinesForm } from '../InvoiceLinesForm';
import { showAddInvoiceStampModal } from 'components/modals/invoices/AddInvoiceStampModal';
import { lowerCase } from 'lodash';

const Footer = styled.div`
  padding: 20px;
  background-color: #fff;
`;
const Header = styled.div`
  background-color: #fff;
  padding: 20px;
`;
const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  height: 100vh;

  background-color: rgb(251, 250, 250);
  z-index: 90;
`;
export type FormValues = IJoinedInvoice & { invoiceFile: any };
type EditTimeSpentFormProps = {
  invoice?: IJoinedInvoice & { invoiceFile: any };
  title: string;
  switchButtons: React.ReactNode;
  onChangeInvoice?: (index: number) => void;
  from?: 'MISSION' | 'INVOICES';
};

export const EditTimeSpentForm = ({
  invoice,
  title,
  switchButtons,
  onChangeInvoice,
  from,
}: EditTimeSpentFormProps) => {
  const [invoiceFile, setInvoiceFile] = React.useState<IFile | null>(
    invoice?.invoiceFile ? invoice?.invoiceFile : null
  );
  const [invoiceStampedFile, setInvoiceStampedFile] =
    React.useState<IFile | null>(
      invoice?.invoiceFileDirectPayment
        ? invoice?.invoiceFileDirectPayment
        : null
    );
  const [checkFileWarning, setCheckFileWarning] = useState<
    React.ReactNode | undefined
  >();
  const fileLocation =
    invoiceStampedFile?.fileLocation ?? invoice?.invoiceFile?.fileLocation;
  const { data } = useDownloadFile(fileLocation as string, {
    enabled: fileLocation ? true : false,
  });
  useEffect(() => {
    // use for download file
    if (data) {
      const reader = new FileReader();
      reader.readAsDataURL(data.data); // converts the blob to base64 and calls onload
      reader.onload = () => {
        setFile(reader.result as string);
      };
    }
  }, [data]);

  const [formSelected, setFormSelected] = useState<'INFORMATIONS' | 'HISTORY'>(
    'INFORMATIONS'
  );
  const { mutateAsync: updateInvoice } = useInvoiceUpdateOne();
  const history = useHistory();
  const location = useLocation();
  const showMessage = useShowMessage();
  const theme = useTheme();
  const [file, setFile] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);
  const [loadingFile, setLoadingFile] = useState(false);
  const [invoiceFormFile, setInvoiceFormFile] = useState<File | null>(null);
  const [toolTipReason, setToolTipeReason] = useState<string | undefined>();

  useEffect(() => {
    if (!file && fileLocation) {
      const uploadFile = async (url: string) => {
        await fetch(url)
          .then(response => response.blob())
          .then(blob => {
            // Create a new FileReader innstance
            const reader = new FileReader();
            reader.readAsDataURL(blob); // converts the blob to base64 and calls onload

            reader.onload = () => {
              setFile(reader.result as string);
            };
          });
      };
      uploadFile(fileLocation);
    }
  }, [file]);
  const {
    reset,
    setValue,
    setError,
    watch,
    control,
    register,
    handleSubmit,
    clearErrors,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {
      ...invoice,
      totalBeforeTaxes: invoice?.totalBeforeTaxes
        ? Number(invoice?.totalBeforeTaxes?.toFixed(2))
        : 0,
      totalWithTaxes: invoice?.totalWithTaxes
        ? Number(invoice?.totalWithTaxes?.toFixed(2))
        : 0,
      vat: invoice?.vat ? Number(invoice?.vat?.toFixed(2)) : 0,
    },
  });
  const invoiceFormUuuid = watch('uuid');
  const providerInvoiceRef = watch('providerInvoiceRef');
  const { data: activity } = useAdditionalActivityFindOne(
    invoice?.additionalActivity
  );
  const { data: mission } = useMissionFindOne(invoice?.mission?.reference);

  const isDirectPayment =
    mission?.customer?.billingOptions?.isDirectPayment ?? false;

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { data: establishment } = useEstablishmentFindOne(
    invoice?.estProvider?.uuid
  );
  const handleRedirect = (history: any) => {
    //@ts-ignore
    const invoicePathFrom = location?.state?.invoicePathFrom;
    if (!history || !invoice) {
      return;
    }
    const providerOrClientPath = isProviderInvoice(
      invoice?.invoiceType as EInvoiceType
    )
      ? 'provider'
      : 'customer';
    const timeSpentOrCreditNote = isCreditNoteInvoice(
      invoice?.invoiceType as EInvoiceType
    )
      ? 'credit-note'
      : 'time-spent';
    const awaitStatus = [
      EInvoiceStatus?.TO_BE_FILLED,
      EInvoiceStatus?.TO_BE_SUBMITTED,
    ];
    const isAwaitingStatus = awaitStatus?.includes(
      invoice?.status as EInvoiceStatus
    );
    const isToBeValidatedStatus =
      invoice?.status === EInvoiceStatus.TO_BE_VALIDATED;
    let pathStatus = 'all';
    if (providerOrClientPath === 'provider') {
      if (timeSpentOrCreditNote === 'time-spent') {
        if (isAwaitingStatus) {
          pathStatus = 'awaiting';
        }
        if (isToBeValidatedStatus) {
          pathStatus = 'to-be-validated';
        }
      }
    }
    if (providerOrClientPath === 'customer') {
      if (timeSpentOrCreditNote === 'time-spent') {
        pathStatus = lowerCase(invoice?.status);
      }
    }
    const defaultPath = `/orders/invoices/${providerOrClientPath}/${timeSpentOrCreditNote}/${pathStatus}`;

    switch (invoicePathFrom) {
      case 'CUSTOMER_ESTABLISHMENTS':
        history.push(
          `/clients/establishments/${invoice?.estCustomer?.uuid}/invoices`
        );
        break;
      case 'PROVIDER_ESTABLISHMENTS':
        history.push(
          `/providers/establishments/${invoice?.estProvider?.uuid}/invoices`
        );
        break;
      case 'MISSION_CREDITNOTE':
        history.push(
          `/delivery/missions/${invoice?.mission?.reference}/credit-note`
        );
        break;
      case 'MISSION_INVOICE':
        history.push(
          `/delivery/missions/${invoice?.mission?.reference}/invoice`
        );
        break;
      default:
        // no invoicePathFrom
        history.push(defaultPath);
    }
  };

  const handleRejectInvoice = async () => {
    await showRejectInvoiceModal({ invoice }).then(action => {
      if (action) {
        if (onChangeInvoice) {
          onChangeInvoice(1);
        } else {
          showMessage('success', 'Facture rejetée');
          //history.push('/orders/invoices/provider/time-spent/awaiting');
          handleRedirect(history);
        }
      }
    });
  };

  const invoiceStatus = invoice?.status;
  const getStatusInfo = () => {
    const info = {
      text: '',
      color: '',
    };
    if (
      isCreditNoteInvoice(invoice?.invoiceType as EInvoiceType) &&
      invoiceStatus === EInvoiceStatus.VALIDATED
    ) {
      info.text = `Validée le : ${invoice?.validatedAt?.toLocaleDateString()}`;
      info.color = 'success';
      return info;
    }
    if (invoiceStatus === EInvoiceStatus.REJECTED) {
      info.text = `Refusée le : ${invoice?.statusChangedAt?.toLocaleDateString()}`;
      info.color = 'error';
      return info;
    }
    if (isTimeSpentInvoice(invoice?.invoiceType as EInvoiceType)) {
      if (
        invoiceStatus === EInvoiceStatus.TO_BE_VALIDATED &&
        (invoice?.cra?.state === ECraStatus?.VALIDATED ||
          invoice?.cra?.state === ECraStatus?.ARCHIVED)
      ) {
        info.text = `A valider`;
        info.color = 'warning';
        return info;
      }
      if (
        invoiceStatus === EInvoiceStatus.TO_BE_VALIDATED &&
        invoice?.cra?.state !== ECraStatus?.VALIDATED &&
        invoice?.cra?.state !== ECraStatus?.ARCHIVED
      ) {
        info.text = `En attente de validation du CRA`;
        info.color = 'warning';
        return info;
      }
    } else {
      if (invoiceStatus === EInvoiceStatus.TO_BE_VALIDATED) {
        info.text = `A valider`;
        info.color = 'warning';
        return info;
      }
    }

    if (invoiceStatus === EInvoiceStatus.VALIDATED) {
      info.text = `Validée le : ${invoice?.statusChangedAt?.toLocaleDateString()}`;
      info.color = 'success';
      return info;
    }
    if (invoiceStatus === EInvoiceStatus.PAID) {
      const paidDate = invoice?.sage?.paymentDetails?.paymentDate
        ? `- Virement émis le : ${invoice?.sage?.paymentDetails?.paymentDate?.toLocaleDateString()}`
        : '';
      info.text = `Payée ${paidDate}`;
      info.color = 'success';
      return info;
    }
    return false;
  };
  const isEditable =
    (!isCreditNoteInvoice(invoice?.invoiceType as EInvoiceType) &&
      isProviderInvoice(invoice?.invoiceType as EInvoiceType) &&
      invoice?.status === EInvoiceStatus?.TO_BE_FILLED) ||
    invoice?.status === EInvoiceStatus?.TO_BE_SUBMITTED ||
    invoice?.status === EInvoiceStatus?.TO_BE_VALIDATED ||
    invoice?.status === EInvoiceStatus?.REJECTED;
  // const isEditable = isTimeSpentInvoice(invoice?.invoiceType as EInvoiceType)
  //   ? invoice?.status === EInvoiceStatus.TO_BE_VALIDATED &&
  //     (invoice?.cra?.state === ECraStatus?.VALIDATED ||
  //       invoice?.cra?.state === ECraStatus?.ARCHIVED)
  //   : invoice?.status === EInvoiceStatus.TO_BE_VALIDATED;
  const statusInfo = getStatusInfo();
  const onValidateInvoice = async (
    formValues: FormValues,
    directPaymentFile?: any
  ) => {
    let onError = false;
    const submitData = {
      customerReference: formValues?.customerReference,
      invoiceDate: removeOffsetDate(formValues.invoiceDate as Date),
      providerInvoiceRef: formValues?.providerInvoiceRef,
      vatRate: formValues?.vatRate,
      socialSecurityNumber: formValues?.socialSecurityNumber,
      isVat: formValues?.isVat,
      isDeductible: formValues?.isDeductible,
      deductionAmount: formValues?.isDeductible
        ? formValues?.deductionAmount &&
          !isNaN(Number(formValues?.deductionAmount))
          ? Number(formValues?.deductionAmount)
          : 0
        : undefined,
      invoiceFile: formValues?.invoiceFile,
      invoiceFileDirectPayment: directPaymentFile
        ? directPaymentFile
        : undefined,
      status: EInvoiceStatus.VALIDATED,
    };
    if (!formValues?.invoiceFile && !invoiceFormFile) {
      onError = true;
      setError(
        'invoiceFile',
        { message: 'Ce champs est requis' },
        { shouldFocus: true }
      );
    } else {
      if (invoiceFormFile) {
        try {
          const s3Response = await uploadFile({
            file: invoiceFormFile,
            fileType: EFileType.TEMPORARY,
            missionRef: (invoice as IJoinedInvoice)?.mission?.reference,
            actionType: 'upload',
          });
          const data = {
            fileName: s3Response?.fileName,
            fileLocation: s3Response?.fileLocation as string,
            eTag: s3Response?.eTag,
          };
          submitData.invoiceFile = data;
        } catch (e) {
          //
        }
      }
    }
    if (!onError) {
      try {
        await updateInvoice({
          uuids: [(invoice as IJoinedInvoice).uuid],
          //@ts-ignore
          invoice: submitData,
        });
        queryClient.refetchQueries({ queryKey: ['Invoices'], type: 'active' });
        queryClient.refetchQueries({
          queryKey: [(invoice as IJoinedInvoice).uuid],
        });
        showMessage('success', 'Facture validée');
        if (onChangeInvoice) {
          onChangeInvoice(1);
        } else {
          handleRedirect(history);
          //history.goBack();
        }
      } catch (e) {
        console.log(' CATCH ERROR AFTER UPDATE INVOICE', e);
      }
    }
  };
  const invoiceValidationRequest = async (
    formValues: FormValues,
    customerReference?: string
  ) => {
    try {
      await onValidateInvoice({
        ...formValues,
        customerReference: customerReference,
      });
      await sageCreateInvoice({ invoice: invoice?.uuid as string });
      queryClient.refetchQueries({ queryKey: ['Invoices'], type: 'active' });
    } catch (e) {
      console.log(e);
    }
  };

  const validationProcess = async (
    formValues: FormValues,
    options?: { ignoreCheckBDL: Boolean }
  ) => {
    let onError = false;
    if (!formValues?.invoiceFile && !invoiceFormFile) {
      onError = true;
      setError('invoiceFile', { message: 'Ce champs est requis' });
    }
    if (onError === false) {
      //const resultsStatus = await handleCheckInvoice();
      const gescomReady = options?.ignoreCheckBDL
        ? true
        : invoice?.sage?.documentType === EInvoiceDocumentType.DELIVERY_ORDER;
      if (gescomReady) {
        const text = invoice?.mission?.isMandateMode ? (
          <>
            <b>{` Vérifiez que la facture du fournisseur porte la mention « Facture adressée par Freelance.com, mandataire, au nom et pour le compte de ${getEstablishmentName(
              invoice?.estProvider
            )}, mandant ».`}</b>
            <Text mt={10}>
              Les données de facturation saisies dans le formulaire seront
              envoyées en l’état à GESCOM pour comptabilisation et le bon de
              livraison fournisseur sera remplacé par la facture fournisseur
              GESCOM.
            </Text>
          </>
        ) : (
          'Les données de facturation saisies dans le formulaire seront envoyées en l’état à GESCOM pour comptabilisation et le bon de livraison fournisseur sera remplacé par la facture fournisseur GESCOM.'
        );
        await showOnConfirmFormModal({
          title: 'Etes-vous sûr(e) de vouloir valider cette facture ?',
          text: text,
          confirmLabelButton: 'Valider',
          data: {
            invoice,
            invoiceFile: invoice?.invoiceFile,
            providerInvoiceRef,
          },
          checkInvoice: true,
        }).then(async action => {
          if (action) {
            try {
              const mission = invoice?.mission;
              const structure = mission?.billingInformation?.structure ?? '';
              const isDirectPayment =
                mission?.customer?.billingOptions?.isDirectPayment ?? false;
              if (
                isDirectPayment &&
                structure &&
                [EMissionStructure.FCOM, EMissionStructure.INOPS].includes(
                  structure
                )
              ) {
                showAddInvoiceStampModal({
                  invoiceUuid: invoice?.uuid ?? '',
                  fileInvoice: formValues?.invoiceFile,
                  invoiceTotalWithTaxes: formValues?.totalWithTaxes ?? 0,
                  beforeValidation: async (data: {
                    directPaymentFile: IFile;
                  }) => {
                    const directPaymentFile = data?.directPaymentFile;
                    await onValidateInvoice(formValues, directPaymentFile);
                    await sageCreateInvoice({
                      invoice: invoice?.uuid as string,
                    });
                    queryClient?.refetchQueries({
                      queryKey: [invoice?.uuid],
                    });
                    queryClient.refetchQueries({
                      queryKey: ['Invoices'],
                      type: 'active',
                    });
                  },
                });
              } else {
                await onValidateInvoice(formValues);
                await sageCreateInvoice({ invoice: invoice?.uuid as string });
                queryClient.refetchQueries({
                  queryKey: ['Invoices'],
                  type: 'active',
                });
              }
            } catch (e) {
              //
            }
          }
        });
      } else {
        await showDialogModal({
          title: 'Impossible de valider la facture ! ',
          text: "Le bon de livraison fournisseur n'a pas été crée dans GESCOM.",
          confirmLabel: "J'ai compris",
        });
      }
    }
  };
  const onSubmit = async (formValues: FormValues) => {
    setIsLoading(true);

    let additionalActivity;
    if (isAdditionalActivityInvoice(invoice?.invoiceType as EInvoiceType)) {
      additionalActivity = await additionalActivityFindOne({
        uuid: invoice?.additionalActivity as string,
      });
    }

    if (
      invoice?.sage?.documentType !== EInvoiceDocumentType.DELIVERY_ORDER &&
      (invoice?.mission?.isMandateMode ||
        invoice?.mission?.customer?.billingOptions?.isDirectPayment)
    ) {
      showCreateGescomBDLModal({
        invoice: invoice,
        beforeValidation: async customerReference => {
          // await invoiceValidationRequest(formValues, customerReference);
          await queryClient?.refetchQueries({ queryKey: [invoice?.uuid] });
          await validationProcess(
            {
              ...formValues,
              customerReference,
            },
            { ignoreCheckBDL: true }
          );
        },
        optionalText: 'Creation de BDL et Validation',
        cra: invoice?.cra as ICra,
        mission: invoice?.mission as IMission,
        delivreyOrders: {
          bdlProvider: true,
          bdlCustomer: false,
        },
        customerReference:
          additionalActivity?.purchaseOrder?.reference ||
          invoice?.mission?.customer?.purchaseOrder?.reference ||
          '',
        refetchQueryKey: 'Invoices',
      });
    } else {
      await validationProcess(formValues);
    }
    handleRedirect(history);
    setIsLoading(false);
  };
  const handleChangeInvoiceFile = async (files: File[]) => {
    setLoadingFile(true);
    try {
      const s3Response = await uploadFile({
        file: files[0],
        actionType: 'upload',
        fileType: EFileType.TEMPORARY,
        missionRef: invoice?.mission?.reference || '',
      });
      const attachment = {
        eTag: s3Response?.eTag,
        fileLocation: s3Response?.fileLocation,
        fileName: s3Response?.fileName,
      };
      const invoiceResponse = await updateInvoice({
        uuids: [invoice?.uuid as string],
        invoice: { invoiceFile: attachment as IFile },
      });
      queryClient?.refetchQueries({
        queryKey: [invoice?.uuid],
      });
      clearErrors('invoiceFile');
      //setInvoiceFile(s3Response as any);
      setInvoiceFormFile(files[0]);
      setLoadingFile(false);
    } catch (e) {
      setLoadingFile(false);
    }
  };
  const isVat = watch('isVat');
  const vatRate = watch('vatRate');
  const isDeductible = watch('isDeductible');
  const totalBeforeTaxes = watch('totalBeforeTaxes');
  const deductionAmount = watch('deductionAmount');
  const nbWorkingDays = watch('nbWorkingDays');
  const invoiceType = invoice?.invoiceType as EInvoiceType;
  const isForeignEstablishment =
    invoice?.estProvider?.origin?.toLocaleUpperCase() !== 'FRANCE' &&
    invoice?.estProvider?.address?.country?.toLocaleUpperCase() !== 'MONACO';
  const totalAmount = getInvoiceTotalAmount(
    invoice as any,
    'PROVIDER',
    activity
  );
  const isValidableByManager = () => {
    let textType = '';
    switch (activity?.type) {
      case EadditionalActivityType.MILESTONE:
        textType = 'le jalon';
        break;
      case EadditionalActivityType.EXPENSE:
        textType = 'le frais';
        break;
      case EadditionalActivityType.STAND_BY_DUTY:
        textType = 'la prestation complémentaire';
        break;
    }
    if (isTimeSpentInvoice(invoiceType)) {
      if (
        invoice?.cra?.state === ECraStatus?.VALIDATED ||
        invoice?.cra?.state === ECraStatus?.ARCHIVED
      ) {
        return true;
      } else {
        if (!toolTipReason) {
          setToolTipeReason(
            "Impossible de valider la facture car le CRA n'est pas validé"
          );
        }
        return false;
      }
    }
    if (isAdditionalActivityInvoice(invoiceType)) {
      if (
        activity?.status === EadditionalActivityStatus?.VALIDATED ||
        activity?.status === EadditionalActivityStatus?.ARCHIVED
      ) {
        return true;
      } else {
        if (!toolTipReason && activity) {
          setToolTipeReason(
            `Impossible de valider la facture car ${textType} n'est pas validé`
          );
        }

        return false;
      }
    }
  };

  useEffect(() => {
    if (invoice?.uuid !== invoiceFormUuuid) {
      reset(invoice);
      if (invoice?.invoiceFile?.fileLocation !== invoiceFile?.fileLocation) {
        setInvoiceFile(invoice?.invoiceFile);
      }
    }
    if (invoice?.invoiceFileDirectPayment)
      setInvoiceStampedFile(invoice?.invoiceFileDirectPayment);
  }, [invoice, invoiceFormUuuid]);

  return (
    <Modal>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Header>
          <Container>
            <Flex justifyContent="center">
              <Text variant="h1">{title}</Text>
            </Flex>
          </Container>
        </Header>
        <Flex mb={10}>
          <Tab
            onClick={() => setFormSelected('INFORMATIONS')}
            isSelected={formSelected === 'INFORMATIONS'}
            variant="secondary"
            fontSize={12}
          >
            Informations
          </Tab>
          <Tab
            onClick={() => setFormSelected('HISTORY')}
            isSelected={formSelected === 'HISTORY'}
            variant="secondary"
            fontSize={12}
          >
            Historique
          </Tab>
        </Flex>
        {formSelected === 'HISTORY' && (
          <Flex
            flexWrap={'wrap'}
            overflow={'hidden'}
            height={'calc(100vh - 90px)'}
          >
            <Box
              width={3 / 5}
              flex={1}
              overflow={'scroll'}
              height={'calc(100vh - 180px)'}
              pl={'20px'}
              pr={'20px'}
              pt={'20px'}
            >
              <HistoryList
                columns={columns}
                filterObject={{
                  ownerId: invoice?.uuid,
                  ownerType: EChangeOwnerType?.INVOICE,
                }}
              />
              <Box width={1 / 1} position={'fixed'} bottom={0} right={'5px'}>
                <Footer>
                  <Flex justifyContent="flex-end" alignItems="center">
                    <Link
                      onClick={() => {
                        handleRedirect(history);
                        // const providerInvoiceFrom =
                        //   //@ts-ignore
                        //   location?.state?.providerInvoiceFrom;
                        // if (
                        //   providerInvoiceFrom &&
                        //   providerInvoiceFrom === 'MISSION'
                        // ) {
                        //   history.push(
                        //     `/delivery/missions/${mission?.reference}/invoice`
                        //   );
                        // } else {
                        //   history.push(
                        //     '/orders/invoices/provider/time-spent/to-be-validated'
                        //   );
                        // }
                      }}
                      iconLeft={
                        <CloseIcon height={15} style={{ marginTop: 7 }} />
                      }
                    >
                      Quitter
                    </Link>
                  </Flex>
                </Footer>
              </Box>
            </Box>
          </Flex>
        )}
        {formSelected === 'INFORMATIONS' && (
          <Flex
            flexWrap={'wrap'}
            overflow={'hidden'}
            height={'calc(100vh - 90px)'}
          >
            <Box
              width={3 / 5}
              flex={1}
              overflow={'scroll'}
              height={'calc(100vh - 180px)'}
              pl={'20px'}
              pr={'20px'}
              pt={'20px'}
            >
              <InvoiceInformation invoice={invoice} />
              <>
                {invoice?.mission?.isMandateMode && (
                  <Flex alignItems={'center'}>
                    <Box width={50} mr={10}>
                      <ExclamationCircleIcon
                        fontSize={30}
                        fill={theme?.colors?.error?.default}
                      />
                    </Box>

                    <Box borderRadius={5} border="1px solid red" p={10}>
                      <Text variant="p">
                        Prestation gérée sous mandat de gestion,{' '}
                        <b color="red">
                          Vérifiez que la facture du fournisseur porte la
                          mention « Facture adressée par Freelance.com,
                          mandataire, au nom et pour le compte de{' '}
                          {getEstablishmentName(invoice?.estProvider)}, mandant
                          ».
                        </b>
                      </Text>
                    </Box>
                  </Flex>
                )}
                {isDisableFONEDate() &&
                  invoice?.mission?.billingInformation?.structure ===
                    EMissionStructure.FONE && (
                    <Flex alignItems={'center'} mt={10}>
                      <Box width={50}>
                        <ExclamationCircleIcon
                          fontSize={30}
                          fill={Theme?.colors?.error?.default}
                        />
                      </Box>

                      <Box
                        borderRadius={5}
                        border="1px solid red"
                        p={10}
                        width={1 / 1}
                      >
                        <Text variant="p" width={1 / 1}>
                          <b color="red">
                            Attention depuis le 1 janvier 2024, la structure
                            FONE ne peut plus recevoir de facture fournisseur.
                            <br />
                            Vérifiez bien que la facture est à l'ordre de
                            Freelance.com avec le SIREN 384174348.
                          </b>
                        </Text>
                      </Box>
                    </Flex>
                  )}
                <Section
                  mt={20}
                  variant="h2"
                  title={
                    <Flex>
                      Facture{' '}
                      {statusInfo && (
                        //@ts-ignore
                        <Status ml={20} variantColor={statusInfo.color}>
                          {statusInfo.text}
                        </Status>
                      )}
                    </Flex>
                  }
                >
                  <Flex justifyContent="space-between">
                    <Box width={3 / 12}>
                      <FormControl
                        required
                        label={'N° de facture fournisseur'}
                        errorMessage={errors?.providerInvoiceRef?.message}
                      >
                        <Input
                          isDisabled={!isEditable}
                          isFullWidth
                          {...register('providerInvoiceRef', {
                            required: 'Ce champ est requis',
                          })}
                          type="text"
                        />
                      </FormControl>
                    </Box>
                    <Box width={2 / 12}>
                      <FormControl
                        required
                        label={'Date de facture'}
                        errorMessage={errors?.invoiceDate?.message}
                      >
                        <DatePickerControlled
                          disabled={!isEditable}
                          control={control}
                          required
                          valueName="selected"
                          name="invoiceDate"
                          rules={{
                            required: 'Ce champs est requis',
                          }}
                        />
                      </FormControl>
                    </Box>
                    <Box width={2 / 12}>
                      <FormControl label={'Date de transmission'}>
                        <Input
                          value={
                            invoice?.submittedAt
                              ? (getHumanDate(
                                  new Date(invoice?.submittedAt)
                                ) as string)
                              : undefined
                          }
                          isDisabled
                          isFullWidth
                          icon={<CalendarIcon />}
                        />
                      </FormControl>
                    </Box>
                    <Box width={2 / 12}>
                      <FormControl label={'Echéance (en jours nets)'}>
                        <Input
                          isDisabled
                          isFullWidth
                          {...register('paymentDeadline')}
                          value={invoice?.paymentDeadline}
                          type="number"
                        />
                      </FormControl>
                    </Box>

                    {!isDirectPayment &&
                      invoice?.status === EInvoiceStatus.VALIDATED && (
                        <Box width={2 / 12}>
                          <FormControl label={`Date d'échéance`}>
                            <Input
                              isDisabled
                              isFullWidth
                              value={
                                invoice?.dueDate
                                  ? (getHumanDate(invoice?.dueDate) as string)
                                  : undefined
                                // invoice?.cra
                                //   ? (getHumanDate(
                                //       calculateInvoiceDeadlineDate(
                                //         invoice as any,
                                //         invoice?.cra
                                //       )
                                //     ) as string)
                                //   : undefined
                              }
                            />
                          </FormControl>
                        </Box>
                      )}
                  </Flex>
                </Section>
                <Section
                  mt={20}
                  variant="h2"
                  title={'Etablissement fournisseur'}
                >
                  <Flex>
                    <Box width={3 / 10}>
                      <FormLabel>Raison sociale</FormLabel>
                      <Text variant="p">
                        {getEstablishmentName(invoice?.estProvider, 80)}
                      </Text>
                    </Box>
                    <Box width={3 / 10}>
                      <FormLabel>N° SIRET</FormLabel>
                      <Text variant="p">{invoice?.estProvider?.siret}</Text>
                    </Box>
                    <Box width={3 / 10}>
                      <FormLabel>adresse de l'établissement</FormLabel>
                      <Text variant="p">
                        {buildOneLineAddress(invoice?.estProvider?.address)}
                      </Text>
                    </Box>
                  </Flex>
                  <Flex mt={20}>
                    <Box width={3 / 10}>
                      <FormLabel>Coordonnées bancaires</FormLabel>
                      <Text variant="p">
                        IBAN:{' '}
                        {establishment?.provider?.bankAccount?.iban || 'N/A'}
                      </Text>
                    </Box>
                    <Box width={3 / 10} mt={20}>
                      <Flex>
                        {' '}
                        IBAN:&nbsp;
                        {establishment?.provider?.bankAccount?.rib?.fileName ? (
                          <ALink
                            style={{ color: '#0000EE' }}
                            target="_blank"
                            href={`/file/${establishment?.provider?.bankAccount?.rib?.fileLocation?.replaceAll(
                              '/',
                              '§'
                            )}`}
                          >
                            {
                              establishment?.provider?.bankAccount?.rib
                                ?.fileName
                            }
                          </ALink>
                        ) : (
                          'N/A'
                        )}
                      </Flex>
                    </Box>
                    <Box width={3 / 10} mt={20}>
                      <Text variant="p">
                        BIC:{' '}
                        {establishment?.provider?.bankAccount?.bic || 'N/A'}
                      </Text>
                    </Box>
                  </Flex>
                  <Flex>
                    <Box width={3 / 10}>
                      <Text variant="p">
                        Nom de la banque :{' '}
                        {establishment?.provider?.bankAccount?.bankName ||
                          'N/A'}
                      </Text>
                    </Box>
                    <Box width={3 / 10} />
                    <Box width={3 / 10}>
                      <Text variant="p">
                        Titulaire du compte:{' '}
                        {establishment?.provider?.bankAccount?.owner || 'N/A'}
                      </Text>
                    </Box>
                  </Flex>
                  <Flex>
                    <Box width={10 / 10}>
                      {managerExtractVerifyRibNeedWarning(
                        invoice?.invoiceFileMetaData
                      ) && (
                        <Box
                          mt={10}
                          color={Theme?.colors?.warning?.default}
                          padding={'20px'}
                          border={`1px solid ${Theme?.colors?.warning?.default}`}
                          backgroundColor="rgba(253,238,239,0.5)"
                          borderRadius={'5px'}
                        >
                          <Text color={Theme?.colors?.warning?.default}>
                            Attention suite aux vérifications effectuées le{' '}
                            {getHumanDate(
                              invoice?.invoiceFileMetaData?.extractedAt as Date
                            )}{' '}
                            nous avons détécté les anomalies suivantes :
                          </Text>
                          <ul>
                            {!invoice?.invoiceFileMetaData?.hasRibOnSage && (
                              <Text
                                variant="li"
                                color={Theme?.colors?.warning?.default}
                              >
                                L'établissement n'a pas de RIB enregistré dans
                                la GESCOM
                              </Text>
                            )}
                            {invoice?.invoiceFileMetaData?.hasRibOnSage &&
                              !invoice?.invoiceFileMetaData?.ribMatches && (
                                <Text
                                  variant="li"
                                  color={Theme?.colors?.warning?.default}
                                >
                                  L'IBAN indiqué sur la facture pdf (
                                  {printFormat(
                                    invoice?.invoiceFileMetaData
                                      ?.extractedIban as string
                                  )}
                                  ) ne correspond pas à l'IBAN enregistré dans
                                  la GESCOM (
                                  {invoice?.invoiceFileMetaData?.ibanHint} )
                                </Text>
                              )}
                          </ul>
                        </Box>
                      )}
                    </Box>
                  </Flex>
                </Section>
                <Section mt={20} variant="h2" title={'Mission'}>
                  <Flex>
                    <Box width={3 / 10}>
                      <FormLabel>Référence</FormLabel>
                      <Text variant="p">
                        {invoice?.mission?.displayReference}
                      </Text>
                    </Box>
                    <Box width={3 / 10}>
                      <FormLabel>Structure de Facturation</FormLabel>
                      <Text variant="p">
                        {invoice?.mission?.billingInformation?.structure}
                      </Text>
                    </Box>
                    {invoice?.month &&
                      !isMileStoneInvoice(invoiceType as EInvoiceType) && (
                        <Box width={3 / 10}>
                          <FormLabel>Mois</FormLabel>
                          <Text variant="p">
                            {invoice?.month &&
                              new Date(invoice?.month).getUTCMonth() + 1}{' '}
                            /{' '}
                            {invoice?.month &&
                              new Date(invoice?.month).getUTCFullYear()}
                          </Text>
                        </Box>
                      )}
                    <Box width={3 / 10}>
                      <FormLabel>Intervenant</FormLabel>
                      <Text variant="p">
                        {getFullName(invoice?.contractor)}
                      </Text>
                    </Box>
                  </Flex>
                  {isStandByDutyInvoice(invoiceType as EInvoiceType) && (
                    <>
                      <Accordion
                        defaultOpen
                        openOnTop
                        propsBar={{
                          //borderRadius: '8px',
                          backgroundColor: `rgb(251,250,250)`,
                          //border: '1px solid #d0d4e3',
                        }}
                        childrenTop={
                          <Text width={23 / 24} variant="h2">
                            Types de prestation complémentaire
                          </Text>
                        }
                        key={`STAND_BY_DUTIES`}
                      >
                        <Flex
                          width={12 / 12}
                          p={'12px'}
                          justifyContent="flex-wrap"
                          flexWrap="wrap"
                        >
                          {activity?.standByDutyLines?.map(
                            (sdb: any, index: number) => {
                              const formula =
                                mission?.standByDutyFormulas?.find(
                                  (f: any) =>
                                    f?.uuid === sdb?.standByDutyFormulaLineUuid
                                ) as IStandByDutyFormulaLine;
                              return (
                                <>
                                  <Box width={2 / 12}>
                                    <LabelField
                                      underline
                                      label="Type de prestation"
                                      value={formula?.name}
                                    />
                                  </Box>
                                  <Box width={4 / 12} pl={'5px'}>
                                    <LabelField
                                      underline
                                      label="Description"
                                      value={formula?.description}
                                    />
                                  </Box>
                                  <Box width={2 / 12}>
                                    <LabelField
                                      label="Déclaré"
                                      underline
                                      value={`${sdb?.formulaParameters?.INPUT} ${
                                        STAND_BY_DUTY_UNIT_TRANSLATE_FR[
                                          formula?.unit
                                        ]
                                      }`}
                                    />
                                  </Box>
                                  <Box width={2 / 12}>
                                    <LabelField
                                      underline
                                      label="Tarif unitaire HT"
                                      value={`${
                                        formula?.unit ===
                                        EStandByDutyUnits.EUROS
                                          ? //sdb?.formulaParameters?.INPUT
                                            round(
                                              (getFormulaDutyFormulaAmount(
                                                sdb,
                                                'PROVIDER'
                                              ) /
                                                sdb?.formulaParameters
                                                  ?.INPUT) as number
                                            )
                                          : //@ts-ignore
                                            round(
                                              (getFormulaDutyFormulaAmount(
                                                sdb,
                                                'PROVIDER'
                                              ) /
                                                sdb?.formulaParameters
                                                  ?.INPUT) as number
                                            )
                                      } €`}
                                    />
                                  </Box>
                                  <Box width={2 / 12}>
                                    <LabelField
                                      underline
                                      label="Montant HT"
                                      value={`${getFormulaDutyFormulaAmount(
                                        sdb,
                                        'PROVIDER'
                                      )} €`}
                                    />
                                  </Box>
                                  {activity?.standByDutyLines &&
                                    index !==
                                      activity?.standByDutyLines?.length -
                                        1 && (
                                      <Box width={12 / 12}>
                                        <Divider mb={10} mt={10} />
                                      </Box>
                                    )}
                                </>
                              );
                            }
                          )}
                        </Flex>
                        <Divider mb={10} mt={10} />
                      </Accordion>
                    </>
                  )}
                  {isExpenseInvoice(invoiceType as EInvoiceType) && (
                    <>
                      <Accordion
                        defaultOpen
                        openOnTop
                        propsBar={{
                          //borderRadius: '8px',
                          backgroundColor: `rgb(251,250,250)`,
                          //border: '1px solid #d0d4e3',
                        }}
                        childrenTop={
                          <Text width={23 / 24} variant="h2">
                            Types de frais
                          </Text>
                        }
                        key={`cras`}
                      >
                        <Flex
                          width={12 / 12}
                          p={'12px'}
                          justifyContent="flex-wrap"
                          flexWrap="wrap"
                        >
                          {activity?.expenseLines?.map(
                            (expense: any, index: number) => {
                              return (
                                <>
                                  <Box width={2 / 12}>
                                    <LabelField
                                      underline
                                      label="Type de frais"
                                      value={
                                        EXPENSES_TYPE_TRANSLATE_FR[
                                          expense?.type as keyof typeof EXPENSES_TYPE_TRANSLATE_FR
                                        ]
                                      }
                                    />
                                  </Box>
                                  <Box width={4 / 12} pl={'5px'}>
                                    <LabelField
                                      underline
                                      label="Informations complémentaires"
                                      value={expense?.comment || 'N/A'}
                                    />
                                  </Box>
                                  <Box width={2 / 12}>
                                    <LabelField
                                      label="Montant HT"
                                      underline
                                      value={`${expense?.amount} €`}
                                    />
                                  </Box>
                                  {activity?.expenseLines &&
                                    index !==
                                      activity?.expenseLines?.length - 1 && (
                                      <Box width={12 / 12}>
                                        <Divider mb={10} mt={10} />
                                      </Box>
                                    )}
                                </>
                              );
                            }
                          )}
                        </Flex>
                        <Divider mb={10} mt={10} />
                      </Accordion>
                    </>
                  )}
                  <Flex justifyContent="flex-start" mt={20}>
                    <Box
                      width={4 / 12}
                      hidden={
                        isStandByDutyInvoice(invoiceType as EInvoiceType) ||
                        isExpenseInvoice(invoiceType as EInvoiceType) ||
                        isMileStoneInvoice(invoiceType as EInvoiceType)
                      }
                    >
                      <FormControl label="TJM HT perçu par l’intervenant">
                        <Input
                          isDisabled
                          value={invoice?.contractorRate}
                          type="number"
                          icon={<EuroIcon />}
                        />
                      </FormControl>
                    </Box>
                    <Box
                      width={4 / 12}
                      hidden={
                        isStandByDutyInvoice(invoiceType as EInvoiceType) ||
                        isExpenseInvoice(invoiceType as EInvoiceType) ||
                        isMileStoneInvoice(invoiceType as EInvoiceType)
                      }
                    >
                      <FormControl label="Nombre de jours travaillés">
                        <Input
                          isDisabled
                          value={invoice?.nbWorkingDays}
                          maxDecimal={2}
                          step={'0.01'}
                          type="number"
                        />
                      </FormControl>
                    </Box>
                    <Box width={4 / 12}>
                      <FormControl label={'Montant HT'}>
                        <Input
                          isDisabled
                          isFullWidth
                          maxDecimal={2}
                          step={'0.01'}
                          value={totalAmount}
                          type="number"
                          icon={<EuroIcon />}
                        />
                      </FormControl>
                    </Box>
                  </Flex>
                </Section>
                <Divider />
                <Section
                  mt={20}
                  variant="h2"
                  title={'Services complémentaires et autres frais'}
                >
                  {isEditable && (
                    <BlocInformation
                      style={{
                        marginBottom: 20,
                        background: 'rgba(180, 198, 211, 0.2)',
                        borderRadius: '8px',
                      }}
                    >
                      En cas d’erreur ou de mise à jour à effectuer sur un ou
                      plusieurs services de la mission en cours, merci de vous
                      rendre sur la fiche mission correspondante pour effectuer
                      les modifications nécessaires. Une fois enregistrées, vous
                      pourrez retrouver vos mises à jour sur le formulaire
                      “facture fournisseur” correspondant.
                    </BlocInformation>
                  )}
                  <InvoiceLinesForm invoice={invoice} activity={activity} />
                </Section>
                {isEditable && (
                  <>
                    <Divider />
                    <Section
                      mt={20}
                      variant="h2"
                      title={'Commentaire du fournisseur'}
                    >
                      <Text variant="p">
                        {invoice?.comment || 'Aucun commentaire'}
                      </Text>
                    </Section>
                  </>
                )}
                {isEditable && (
                  <Section mt={20} variant="h2" title={'Document transmis'}>
                    <FormControl
                      label=""
                      errorMessage={errors?.invoiceFile?.message}
                    >
                      <Flex
                        width={5 / 10}
                        padding={'8px'}
                        backgroundColor="white"
                        border="solid 1px"
                        borderRadius="8px"
                      >
                        {invoiceFormFile || invoiceFile?.fileName ? (
                          <Flex
                            alignItems="center"
                            justifyContent="center"
                            width={7 / 10}
                            // onClick={() =>
                            //   invoiceFile?.fileName
                            //     ? showDisplayPdfModal({
                            //         fileName: invoiceFile.fileName,
                            //         fileLocation: invoiceFile.fileLocation,
                            //       })
                            //     : false
                            // }
                          >
                            <Box>
                              <Link>
                                <ALink
                                  target={'_blank'}
                                  href={`/file/${invoiceFile?.fileLocation.replaceAll(
                                    '/',
                                    '§'
                                  )}`}
                                >
                                  {
                                    //@ts-ignore
                                    invoiceFormFile?.name
                                      ? //@ts-ignore
                                        cutLongText(invoiceFormFile.name, 20)
                                      : cutLongText(
                                          invoiceFile && invoiceFile.fileName
                                            ? invoiceFile?.fileName
                                            : '',
                                          20
                                        )
                                  }
                                </ALink>
                              </Link>
                              <Box width={1 / 1}>
                                <Text
                                  variant="disabled"
                                  style={{ textDecorationLine: 'none' }}
                                >
                                  {`${
                                    (invoiceFormFile &&
                                      invoiceFormFile?.size / 1000 + 'ko') ||
                                    ''
                                  }`}
                                </Text>
                              </Box>
                            </Box>
                          </Flex>
                        ) : (
                          <></>
                        )}

                        <FileInput
                          width={
                            !invoiceFormFile && !invoiceFile ? 1 / 1 : 3 / 10
                          }
                          border="none"
                          backgroundColor="white"
                          isDisabled={!isEditable}
                          isLoading={loadingFile}
                          label=""
                          accept=".pdf"
                          //fileType={EFileType.INVOICE_PROVIDER}
                          subLabel=""
                          onSelect={handleChangeInvoiceFile}
                        >
                          {invoiceFormFile || invoiceFile?.fileName ? (
                            <Flex
                              width={1 / 1}
                              flexWrap="wrap"
                              justifyContent="flex-end"
                            >
                              <Box width={1 / 1}>
                                {!loadingFile && (
                                  <DownloadIcon
                                    width={25}
                                    height={25}
                                    fill={theme.colors.blue}
                                  />
                                )}
                                {loadingFile && <Spinner />}
                              </Box>
                            </Flex>
                          ) : (
                            <Flex justifyContent="center" width={1 / 1}>
                              <Link
                                style={{
                                  color: isEditable
                                    ? 'inital'
                                    : theme.colors.grayBlue,
                                  cursor: isEditable
                                    ? 'pointer'
                                    : 'not-allowed',
                                }}
                                iconRight={
                                  !loadingFile && (
                                    <DownloadIcon
                                      width={25}
                                      height={25}
                                      style={{
                                        fill: isEditable
                                          ? theme.colors.blue
                                          : theme.colors.grayBlue,
                                      }}
                                    />
                                  )
                                }
                              >
                                importer un document{' '}
                                {loadingFile && <Spinner />}
                              </Link>
                            </Flex>
                          )}
                        </FileInput>
                      </Flex>
                    </FormControl>
                    {checkFileWarning}
                  </Section>
                )}
                <Divider />
                <Flex justifyContent="flex-end">
                  <Box width={6 / 10} />
                  <Box width={3 / 10}>
                    <FormControl label={'Montant Net (HT)'}>
                      <Input
                        value={invoice?.totalBeforeTaxes}
                        isDisabled
                        isFullWidth
                        type="number"
                        maxDecimal={2}
                        step={'0.01'}
                        icon={<EuroIcon />}
                      />
                    </FormControl>
                  </Box>
                </Flex>
                <Divider />
                <Box>
                  <Flex mt={20} justifyContent="space-between">
                    <Box width={2 / 10} mt={30}>
                      <CheckSwitch
                        isDisabled={!isEditable || isForeignEstablishment}
                        id="isVat"
                        {...register('isVat')}
                      >
                        <Text variant="h3">TVA</Text>
                      </CheckSwitch>
                    </Box>
                    {isVat && (
                      <>
                        <Box width={2 / 10}>
                          <FormControl label={'N° de TVA'}>
                            <Input
                              isDisabled={
                                !isEditable ||
                                (isEditable && !isVat) ||
                                isForeignEstablishment
                              }
                              isFullWidth
                              {...register('vatNumber')}
                              maxDecimal={2}
                              step={'0.01'}
                            />
                          </FormControl>
                        </Box>
                        <Box width={2 / 10}>
                          <FormControl required label={'% TVA'}>
                            <StaticSelectControlled
                              isClearable={false}
                              control={control}
                              name="vatRate"
                              isDisabled={!isEditable || isForeignEstablishment}
                              onChange={(value: any) => {
                                const vatNumber = value;
                                //@ts-ignore
                                setValue('vatRate', vatNumber);
                                //@ts-ignore
                                setValue(
                                  'totalWithTaxes',
                                  Number(totalBeforeTaxes) -
                                    Number(deductionAmount || 0) +
                                    (Number(totalBeforeTaxes) / 100) *
                                      Number(vatNumber || 0)
                                );
                                setValue(
                                  'vat',
                                  round(
                                    ((Number(totalBeforeTaxes) as number) /
                                      100) *
                                      (Number(value) as number)
                                  )
                                );

                                return vatNumber;
                              }}
                              options={[
                                { value: 20, label: '20%' },
                                { value: 10, label: '10%' },
                                { value: 5.5, label: '5,5%' },
                                { value: 2.1, label: '2,1%' },
                                { value: 0, label: '0%' },
                              ]}
                              placeholder="% TVA"
                            />
                          </FormControl>
                        </Box>
                        <Box width={2 / 10}>
                          <FormControl label={'Montant TVA'}>
                            <Input
                              isDisabled
                              isFullWidth
                              {...register('vat')}
                              maxDecimal={2}
                              step={'0.01'}
                              type="number"
                              icon={<EuroIcon />}
                            />
                          </FormControl>
                        </Box>
                      </>
                    )}

                    {!isVat && (
                      <Box ml={80}>
                        <Text textAlign="right" width={1 / 1} mt={20}>
                          Le contact fournisseur a déclaré être{' '}
                          <b>exonéré de TVA.</b>
                        </Text>
                        <Text textAlign="right" width={1 / 1}>
                          Merci de vérifier dans sa <b>facture PDF</b> que
                          l’article qui l’exonère de TVA a été renseigné.
                        </Text>
                      </Box>
                    )}
                  </Flex>
                </Box>
                <Box>
                  <Flex mt={20} justifyContent="space-between">
                    <Box width={4 / 10} mt={30}>
                      <CheckSwitch
                        isDisabled={!isEditable}
                        id="isDeductible"
                        {...register('isDeductible')}
                        onChange={(e: any) => {
                          if (!e.target.checked) {
                            setValue('deductionAmount', undefined);
                            setValue('socialSecurityNumber', undefined);
                          }
                          setValue('isDeductible', e.target.checked);
                        }}
                      >
                        <Text variant="h3">Précompte</Text>
                      </CheckSwitch>
                    </Box>
                    <Box width={3 / 10}>
                      <FormControl
                        required
                        label={'N° de sécurité sociale'}
                        errorMessage={
                          errors?.socialSecurityNumber?.type === 'maxLength' ||
                          errors?.socialSecurityNumber?.type === 'minLength'
                            ? 'Veuillez saisir 15 caractères'
                            : errors?.socialSecurityNumber?.message
                        }
                      >
                        <Input
                          isDisabled={
                            !isEditable || (isEditable && !isDeductible)
                          }
                          isFullWidth
                          {...(isEditable && isDeductible
                            ? register('socialSecurityNumber', {
                                required: 'ce champs est requis',
                                maxLength: 15,
                                minLength: 15,
                              })
                            : register('socialSecurityNumber', {
                                required: undefined,
                              }))}
                          type="number"
                          maxDecimal={2}
                          step={'0.01'}
                        />
                      </FormControl>
                    </Box>
                    <Box width={2 / 10}>
                      <FormControl
                        required
                        label={'Montant'}
                        errorMessage={
                          errors?.deductionAmount?.type === 'max'
                            ? `Veuillez saisir un montant inférieur à ${totalBeforeTaxes}`
                            : errors?.deductionAmount?.type === 'min'
                              ? `Veuillez saisir un montant positif`
                              : errors?.deductionAmount?.message
                        }
                      >
                        <Input
                          isDisabled={
                            !isEditable || (isEditable && !isDeductible)
                          }
                          isFullWidth
                          {...(isDeductible && isEditable
                            ? register('deductionAmount', {
                                min: 0,
                                max: totalBeforeTaxes,
                                required: 'Ce champs est requis',
                              })
                            : register('deductionAmount', {
                                required: undefined,
                              }))}
                          onChange={e => {
                            //@ts-ignore
                            const value = e.target.value;
                            setValue('deductionAmount', value);
                            setValue(
                              'totalWithTaxes',
                              Number(totalBeforeTaxes) -
                                Number(value || 0) +
                                (Number(totalBeforeTaxes) / 100) *
                                  (vatRate || 0)
                            );
                            return e;
                          }}
                          type="number"
                          maxDecimal={2}
                          step={'0.01'}
                          icon={<EuroIcon />}
                        />
                      </FormControl>
                    </Box>
                  </Flex>
                </Box>
                <Divider />
                <Flex justifyContent="flex-end">
                  <Box width={6 / 10} />
                  <Box width={3 / 10} mb={20}>
                    <FormControl label={'Montant Total TTC'}>
                      <Input
                        isDisabled
                        isFullWidth
                        {...register('totalWithTaxes')}
                        type="number"
                        maxDecimal={2}
                        step={'0.01'}
                        icon={<EuroIcon />}
                        value={
                          isStandByDutyInvoice(invoiceType as EInvoiceType)
                            ? round(
                                (invoice?.totalBeforeTaxes as number) +
                                  (isVat && vatRate
                                    ? ((invoice?.totalBeforeTaxes as number) *
                                        vatRate) /
                                      100
                                    : 0)
                              )
                            : isExpenseInvoice(invoiceType as EInvoiceType)
                              ? round(
                                  (invoice?.totalBeforeTaxes as number) +
                                    (isVat && vatRate
                                      ? ((invoice?.totalBeforeTaxes as number) *
                                          vatRate) /
                                        100
                                      : 0)
                                )
                              : getInvoiceWithTaxeAndDecution(
                                  totalAmount as number,
                                  nbWorkingDays || 0,
                                  invoice as any,
                                  isVat ? vatRate || 0 : 0,
                                  deductionAmount || 0
                                ).toFixed(2)
                        }
                      />
                    </FormControl>
                  </Box>
                </Flex>
              </>
            </Box>
            {fileLocation && (
              <Box
                pl={'5px'}
                width={2 / 5}
                overflow={'scroll'}
                height={'calc(100vh - 180px)'}
              >
                <iframe
                  //@ts-ignore
                  // src={data && (data as any)?.config?.url}
                  //@ts-ignore
                  src={data && (data as any)?.config?.url}
                  frameBorder="0"
                  title="iframe"
                  style={{ width: '100%', height: 'calc(100vh - 80px)' }}
                ></iframe>
              </Box>
            )}

            <Box width={1 / 1} position={'fixed'} bottom={0}>
              <Footer>
                <Flex justifyContent="flex-end" alignItems="center">
                  {switchButtons && switchButtons}
                  <Link
                    mr={10}
                    onClick={() => {
                      handleRedirect(history);
                      // const providerInvoiceFrom =
                      //   //@ts-ignore
                      //   location?.state?.providerInvoiceFrom;
                      // if (
                      //   providerInvoiceFrom &&
                      //   providerInvoiceFrom === 'MISSION'
                      // ) {
                      //   history.push(
                      //     `/delivery/missions/${mission?.reference}/invoice`
                      //   );
                      // } else {
                      //   history.push(
                      //     '/orders/invoices/provider/time-spent/to-be-validated'
                      //   );
                      // }
                    }}
                    iconLeft={
                      <CloseIcon height={15} style={{ marginTop: 7 }} />
                    }
                  >
                    Quitter
                  </Link>
                  {isEditable &&
                    !isCreditNoteInvoice(invoiceType as EInvoiceType) &&
                    invoice?.status !== EInvoiceStatus?.VALIDATED &&
                    invoice?.status !== EInvoiceStatus?.PAID && (
                      // !isCreditNoteInvoice(invoiceType as EInvoiceType) &&
                      // isValidableInvoice({
                      //   ...(invoice as any),
                      //   additionalActivity: activity as any,
                      // }) && (
                      <>
                        {invoice?.status ===
                          EInvoiceStatus?.TO_BE_VALIDATED && (
                          <Link
                            mr={30}
                            ml={20}
                            iconLeft={
                              <CloseIcon height={15} style={{ marginTop: 7 }} />
                            }
                            onClick={() => handleRejectInvoice()}
                          >
                            Refuser
                          </Link>
                        )}
                        {!isValidableByManager() ? (
                          <CustomToolTip
                            text={toolTipReason}
                            color={Theme?.colors?.warning?.default}
                          >
                            <Button
                              type="submit"
                              isLoading={isLoading}
                              isDisabled={true}
                            >
                              Valider
                            </Button>
                          </CustomToolTip>
                        ) : (
                          <Button type="submit" isLoading={isLoading}>
                            Valider
                          </Button>
                        )}
                      </>
                    )}
                </Flex>
              </Footer>
            </Box>
          </Flex>
        )}
      </form>
    </Modal>
  );
};
