import {
  EFileType,
  IAdditionalService,
  IAddress,
  IBilling,
  IJoinedMission,
  ISigner,
  PURCHASE_ORDER,
} from '@freelancelabs/teoreme-commons';
import { ServiceHandler } from 'components/ServiceHandler';
import {
  Link,
  Box,
  FormControl,
  FormLabel,
  Text,
  TextAreaControlled,
  FileListControlled,
} from 'components/ui';
import { BillingPartialForm } from 'forms/partials/BillingPartialForm';
import { MissionPartialForm } from 'forms/partials/MissionPartialForm';
import React from 'react';
import { useForm, useController } from 'react-hook-form';
import { StaticSelect } from 'components/selects/StaticSelect';
import { create } from 'react-modal-promise';
import { ModalProps } from '../ModalFrame';
import { useGenerateFileActions } from './generateFileActions';
import { GenerateFileModal } from './GenerateFileModal';
import { ConfirmNavigationModal } from '../ConfirmNavigationModal';
import { showOtherSignersModal } from 'components/modals/providers/OtherSignersModal';
import { showDialogModal } from '../DialogModal';
import {
  getWorkingDays,
  missionUpdateOne,
  MAX_FILE_SIZE_ANNEXE_MB,
  useUserFindMany,
  getFullName,
} from '@commons';
import { queryClient } from '@commons';
import { EditIcon } from 'components/ui/icons';

type FormValues = {
  startAt?: Date;
  endAt?: Date;
  location?: IAddress;
  description?: string;
  title?: string;
  billing: IBilling;

  additionalServices: IAdditionalService[];
  additionalInformations: string;
  additionalFiles: { fileName: string; fileLocation: string; eTag: string }[];
};
export const ServiceHandlerControlled = (props: any) => {
  const {
    field: { ref, ...inputProps },
    // fieldState: { invalid, isTouched, isDirty },
    // formState: { touchedFields, dirtyFields },
  } = useController({
    name: props.name,
    control: props.control,
    rules: { required: false },
    defaultValue: '',
  });

  return <ServiceHandler {...inputProps} {...props} inputRef={ref} />;
};
export const GenerateOrderModal = ({
  isDisabled,
  missionReference,
  onResolve,
}: {
  missionReference?: string;
  isDisabled: boolean;
} & ModalProps) => {
  const { onGenerate, loading, mission, calculateBillingTotal } =
    useGenerateFileActions(missionReference || '', PURCHASE_ORDER, onResolve);
  const [errorSigner, setErrorSigner] = React.useState<string | undefined>();
  const [orderSigner, setOrderSigner] = React.useState<any>();
  const [orderSignerCreated, setOrderSignerCreated] = React.useState<any>();
  const form = useForm<FormValues>({
    defaultValues: { ...mission },
  });
  const {
    handleSubmit,
    formState: { isDirty, isSubmitSuccessful },
    control,
    watch,
    setError,
    getValues,
  } = form;

  const billing = watch('billing');
  const billingTotal = calculateBillingTotal(
    { ...mission?.billing, ...billing },
    PURCHASE_ORDER
  );
  const establishmentContactsIds =
    mission?.provider?.establishment?.provider?.contacts;
  const signOfficer = mission?.provider?.establishment?.provider?.signOfficer;
  const delegatedSignOfficer =
    mission?.provider?.establishment?.provider?.delegatedSignOfficer;
  const otherSigners = mission?.provider?.establishment?.provider?.otherSigners;
  const managerId = mission?.provider?.establishment?.provider?.manager;
  if (!establishmentContactsIds?.find(id => id === managerId)) {
    establishmentContactsIds?.push(managerId as string);
  }
  const [estimatedDays, setEstimatedDays] = React.useState(0);
  const { data: contactsQuery } = useUserFindMany({
    filterObject: {
      cognitoUserId: { $in: establishmentContactsIds },
    },
  });
  const signersOptions: {
    key: string;
    value: string;
    data: any;
    label: string;
  }[] = [];
  if (signOfficer) {
    signersOptions.push({
      key: signOfficer?.email as string,
      value: signOfficer?.email as string,
      data: signOfficer,
      label: `${getFullName(
        signOfficer
      )} | ${signOfficer?.email} |  Signataire du contrat cadre`,
    });
  }
  if (delegatedSignOfficer) {
    signersOptions.push({
      key: delegatedSignOfficer?.email as string,
      value: delegatedSignOfficer?.email as string,
      data: delegatedSignOfficer,
      label: `${getFullName(
        delegatedSignOfficer
      )} | ${delegatedSignOfficer?.email} |  Délégué de signature du contrat cadre`,
    });
  }
  if (otherSigners) {
    otherSigners.forEach(otherSigner =>
      signersOptions.push({
        key: otherSigner?.email as string,
        value: otherSigner?.email as string,
        data: otherSigner,
        label: `${getFullName(
          otherSigner
        )} | ${otherSigner?.email} | Signataire des bons de commande fournisseurs`,
      })
    );
  }
  if (contactsQuery && contactsQuery?.users?.length > 0) {
    contactsQuery?.users?.forEach((user: any) =>
      signersOptions.push({
        key: user?.email as string,
        value: user?.email as string,
        data: user,
        label: `${getFullName(user)} | ${user?.email} |  ${
          user?.cognitoUserId === managerId
            ? "Responsable de l'établissement"
            : 'Contact fournisseur'
        }`,
      })
    );
  }
  if (orderSignerCreated) {
    if (
      signersOptions.findIndex(
        signer => signer.key === orderSignerCreated.key
      ) === -1
    ) {
      signersOptions.push(orderSignerCreated);
    }
  }

  const handleCloseConfirm = async () => {
    const confirm = isDirty
      ? await showDialogModal({
          title: 'Êtes-vous sûr(e) de vouloir fermer sans enregistrer ?',
          text: 'Toutes vos modifications seront perdues.',
          confirmLabel: 'Fermer sans enregistrer',
          cancelLabel: 'Ne pas fermer',
        })
      : true;
    if (confirm) onResolve(false);
  };
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const additionalServices = watch('additionalServices');
  const onSubmitGenerate = (data: any) => {
    if (!data.description || data.description.length === 0) {
      setError('description', { message: 'Ce champ est requis' });
    } else {
      setError('description', { message: undefined });
      if (
        !data.externalReference ||
        data.externalReference === undefined ||
        data.externalReference === null
      ) {
        data.externalReference = '';
      }
      const submitData = {
        title: data?.title,
        startAt: data?.startAt,
        endAt: data?.endAt,
        location: data?.location,
        billing: data?.billing,
        description: data?.description,
        additionalServices: data?.additionalServices,
        additionalInformations: data?.additionalInformations,
        additionalFiles: data?.additionalFiles,
        externalReference: data?.externalReference,
        isRemote: data?.isRemote || false,
        nbRemoteDaysPerWeek: data?.isRemote
          ? data?.nbRemoteDaysPerWeek
          : undefined,
      };
      if (!orderSigner) {
        setErrorSigner('Ce champs est requis');
      } else {
        setErrorSigner(undefined);
        const signer =
          //@ts-ignore
          signersOptions?.[
            //@ts-ignore
            signersOptions?.findIndex(data => data?.key === orderSigner)
          ];
        onGenerate(submitData, signer.data as ISigner);
      }
    }
  };
  const startAt = watch('startAt');
  const endAt = watch('endAt');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onUpdateServices = async (service: IAdditionalService | false) => {
    if (service && mission) {
      const submitData = {
        reference: mission.reference,
        mission: {
          additionalServices: {
            update: [],
            add: [],
            cancel: [],
          },
        },
      };
      if (service?.uuid) {
        submitData?.mission?.additionalServices.update.push(service as never);
      } else {
        submitData?.mission?.additionalServices.add.push(service as never);
      }
      try {
        await missionUpdateOne(submitData);
        queryClient.refetchQueries({ queryKey: [mission.reference] });
      } catch (e) {
        //
      }
    }
  };
  React.useEffect(() => {
    if (startAt && endAt) {
      setEstimatedDays(getWorkingDays(startAt, endAt));
    }
  }, [startAt, endAt, setEstimatedDays]);
  return (
    <GenerateFileModal
      onSubmit={handleSubmit(onSubmitGenerate)}
      title="Envoyer le bon de commande fournisseur"
      isLoading={loading}
      onClose={handleCloseConfirm}
    >
      <form onSubmit={handleSubmit(onSubmitGenerate)} autoComplete="off">
        <ConfirmNavigationModal active={isDirty && !isSubmitSuccessful} />
        <Box mb={20}>
          <Text variant="h2" mb={20}>
            1. Fournisseur
          </Text>
          <FormLabel>établissement fournisseur</FormLabel>
          <Text mb={20}>
            {mission?.provider.establishment?.businessName} / SIRET :{' '}
            {mission?.provider.establishment.siret}
          </Text>
          <FormControl
            required
            label="Signataire du bon de commande"
            // @ts-ignore
            errorMessage={errorSigner}
          >
            <StaticSelect
              options={signersOptions}
              onChange={value => setOrderSigner(value as string)}
              value={orderSigner as string}
              name="signer"
              bcWhite
              placeholder="Signataire du bon de commande"
            />
          </FormControl>
          <Link
            iconLeft={<EditIcon />}
            onClick={() =>
              showOtherSignersModal({
                establishment: mission?.provider.establishment as any,
              }).then(signer => {
                if (signer) {
                  const signerOpt = {
                    key: signer?.email as string,
                    value: signer?.email as string,
                    data: signer,
                    label: `${getFullName(
                      signer
                    )} | ${signer?.email} | Signataire des bons de commande fournisseurs`,
                  };
                  setOrderSignerCreated(signerOpt);
                  setOrderSigner(signerOpt.key);
                }
              })
            }
          >
            Créer un Signataire
          </Link>
        </Box>
        <Box mb={20}>
          <Text variant="h2" mb={20}>
            2. Client
          </Text>
          <FormLabel>établissement client</FormLabel>
          <Text>
            {mission?.customer.establishment.businessName} / SIRET :{' '}
            {mission?.customer.establishment.siret}
          </Text>
        </Box>
        <Box mb={20}>
          <Text variant="h2" mb={20}>
            3. Mission
          </Text>
          <MissionPartialForm
            mission={mission}
            isDisabled={isDisabled}
            form={form}
            forRole="CUSTOMER"
          />
        </Box>

        <Text variant="h2" mb={20}>
          4. Tarification
        </Text>
        <BillingPartialForm
          mission={mission as IJoinedMission}
          isDisabled={isDisabled}
          form={form}
          forRole="PROVIDER"
          estimatedDays={estimatedDays}
        />
        <FormLabel>Montant du bon de commande</FormLabel>
        <Text fontSize={20} fontWeight={600} mb={20}>
          {billingTotal?.toFixed(2)}
        </Text>

        <Box mb={20}>
          <Text variant="h2" mb={20}>
            5. Services complémentaires
          </Text>

          <ServiceHandlerControlled
            mission={{
              ...mission,
              //@ts-ignore
              formValues: { ...getValues() },
            }}
            control={control}
            forRole="PROVIDER"
            name="additionalServices"
          />
        </Box>

        <Text variant="h2" mb={20}>
          6. Informations complémentaires
        </Text>
        <FormControl label="Informations complémentaires">
          <TextAreaControlled
            control={control}
            name="additionalInformations"
            defaultValue=""
            minRows={3}
          />
        </FormControl>

        <Text variant="h2" mb={20}>
          6. Pièces-jointes
        </Text>

        <FileListControlled
          control={control}
          name="additionalFiles"
          missionRef={mission?.reference}
          fileType={EFileType.PURCHASE_ORDER_PROVIDER_EXTRA}
          accept=".pdf"
          maxSize={MAX_FILE_SIZE_ANNEXE_MB}
        />
      </form>
    </GenerateFileModal>
  );
};

export const showGenerateOrderModal = create(GenerateOrderModal, {
  enterTimeout: 0,
  exitTimeout: 0,
});
