import * as React from 'react';
import { Theme } from 'styles';

function MicrosoftIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect x="17" y="17" width="10" height="10" fill="#FEBA08" />
      <rect x="5" y="17" width="10" height="10" fill="#05A6F0" />
      <rect x="17" y="5" width="10" height="10" fill="#80BC06" />
      <rect x="5" y="5" width="10" height="10" fill="#F25325" />
    </svg>
  );
}

export default MicrosoftIcon;
