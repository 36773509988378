import styled from 'styled-components';

export const LoaderLine = styled.div<{ isLoading?: boolean }>`
  width: 100%;
  height: 2px;
  background-color: ${props => props.theme.colors.primary.lighter};
  position: relative;

  &:after {
    content: '';
    opacity: ${props => (props.isLoading ? 1 : 0)};
    position: absolute;
    display: block;
    height: 100%;
    background-color: ${props => props.theme.colors.primary.default};
    left: 0%;
    right: 100%;
    animation: loading 1s ease infinite;
  }

  @keyframes loading {
    0% {
      left: 0;
      right: 100%;
    }
    50% {
      left: 0;
      right: 0;
    }
    100% {
      left: 100%;
      right: 0;
    }
  }
`;
