import { ADDITIONAL_SERVICES_TYPE_FR, PRICE_UNIT_SELECT } from '@commons';
import {
  EAdditionalServiceType,
  EPriceUnit,
  IAdditionalService,
  IJoinedMission,
} from '@freelancelabs/teoreme-commons';
import {
  Box,
  CheckBoxControlled,
  Flex,
  FormControl,
  TextAreaControlled,
} from 'components/ui';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { setDate } from 'date-fns';
import {
  Button,
  DatePickerControlled,
  StaticSelectControlled,
  Input,
  FormLabel,
} from 'components/ui';
import { EuroIcon, PercentIcon } from 'components/ui/icons';

type FormValues = IAdditionalService;
type MissionFormValues = {
  formValues?: {
    endAt: Date;
    startAt: Date;
  };
};
type RCProFormProps = {
  forRole: 'ALL' | 'CUSTOMER' | 'PROVIDER' | 'ESTABLISHMENT_PROVIDER';
  onSubmit: (service?: IAdditionalService | undefined) => any | void;
  service?: IAdditionalService;
  additionalServices: IAdditionalService[];
  onClose: () => void;
  mission: IJoinedMission & MissionFormValues;
};

export const RCProForm = ({
  additionalServices,
  forRole,
  onClose,
  onSubmit: onSubmitParent,
  service,
  mission,
}: RCProFormProps) => {
  const formMethod = service ? 'update' : 'create';
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    watch,
  } = useForm<FormValues>({
    defaultValues: {
      description: ADDITIONAL_SERVICES_TYPE_FR.PROFESSIONAL_INDEMNITY_INSURANCE,
      unit: service?.unit || EPriceUnit.PRICE_PER_MONTH,
      price: service?.price || 50,
      paidByProvider: !(forRole === 'CUSTOMER'),
      ...service,
      type: EAdditionalServiceType.PROFESSIONAL_INDEMNITY_INSURANCE,
    },
  });
  const selectedUnit = watch('unit');
  const validityStart = watch('validityStart');
  const paidByProvider = watch('paidByProvider');
  const onSubmit = async (formValues: FormValues) => {
    setLoading(true);
    try {
      await onSubmitParent(formValues);
    } catch (e) {
      //
    }
    setLoading(false);
  };
  const checkPrice = (price: number): string | undefined => {
    switch (selectedUnit) {
      case EPriceUnit.PERCENT_INVOICE:
        if (price <= -1 || price > 100) {
          return 'Compris entre 0 & 100';
        } else {
          return undefined;
        }
      default:
        if (price <= -1) {
          return 'Supérieur à 0';
        } else {
          return undefined;
        }
    }
  };
  return (
    <Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Flex mb={20} justifyContent="space-between">
          <Box width={2 / 12}>
            <FormControl
              label="MONTANT ( HT )"
              required
              errorMessage={errors?.price?.message}
            >
              <Input
                icon={
                  selectedUnit === EPriceUnit.ONE_TIME_PERCENT ||
                  selectedUnit === EPriceUnit.PERCENT_INVOICE ? (
                    <PercentIcon />
                  ) : (
                    <EuroIcon />
                  )
                }
                isFullWidth
                {...register('price', {
                  validate: (value: any) => checkPrice(Number(value)),
                })}
                type="number"
                maxDecimal={2}
                step="0.01"
              />
            </FormControl>
          </Box>
          <Box width={4 / 12}>
            <FormControl
              label="Unité"
              required
              errorMessage={errors?.unit?.message}
            >
              <StaticSelectControlled
                control={control}
                name="unit"
                options={PRICE_UNIT_SELECT}
                rules={{ required: 'Ce champ est requis' }}
                placeholder="Type de service"
              />
            </FormControl>
          </Box>
          <Box width={2 / 12}>
            <FormControl
              label="A partir du"
              required
              errorMessage={errors?.validityStart?.message}
            >
              <DatePickerControlled
                control={control}
                name="validityStart"
                minDate={
                  mission?.formValues?.startAt
                    ? setDate(new Date(mission?.formValues?.startAt as Date), 1)
                    : setDate(new Date(mission?.startAt as Date), 1)
                }
                maxDate={mission?.formValues?.endAt || mission?.endAt}
                rules={{ required: 'Ce champ est requis' }}
                valueName="selected"
                dateFormat="MM/yyyy"
                showMonthYearPicker
                showFullMonthYearPicker
              />
            </FormControl>
          </Box>
          <Box width={2 / 12}>
            <FormControl label="Jusqu'au (inclus)">
              <DatePickerControlled
                control={control}
                name="validityEnd"
                minDate={
                  validityStart
                    ? validityStart
                    : mission?.formValues?.endAt || mission?.endAt || null
                }
                maxDate={mission?.formValues?.endAt || mission?.endAt}
                valueName="selected"
                dateFormat="MM/yyyy"
                showMonthYearPicker
                showFullMonthYearPicker
              />
            </FormControl>
          </Box>
        </Flex>
        {forRole === 'PROVIDER' ||
          (forRole === 'ESTABLISHMENT_PROVIDER' && (
            <FormLabel mb={4}>Payé par le fournisseur</FormLabel>
          ))}
        {forRole === 'CUSTOMER' && (
          <FormLabel mb={4}>Payé par le client</FormLabel>
        )}
        <Flex mb={0}>
          {forRole === 'ALL' && (
            <>
              <FormControl label="">
                <CheckBoxControlled
                  control={control}
                  {...register('paidByProvider')}
                  checked={paidByProvider}
                  name="paidByProvider"
                  id={'paidByProvider'}
                  key="paidByProvider"
                  label="Payé par le fournisseur ( sinon payé par le client )"
                />
              </FormControl>
            </>
          )}
        </Flex>
        <Box>
          <FormControl
            label="Commentaire"
            errorMessage={errors?.comment?.message}
          >
            <TextAreaControlled
              control={control}
              minRows={3}
              isFullWidth
              name="comment"
            />
          </FormControl>
        </Box>
        <Box>
          <Button type="submit" variant="filled">
            {formMethod === 'create' ? 'Ajouter' : 'Modifier'}
          </Button>
        </Box>
      </form>
    </Box>
  );
};
