import { create } from 'zustand';
enum filterType {
  DRAFT = 'draft',
  VALIDATED = 'validated',
  ARCHIVED = 'archived',
  ABORTED = 'aborted',
  TERMINATED = 'terminated',
}
enum sortType {
  ASC = 'asc',
  DESC = 'desc',
}
type State = {
  initialSate: boolean;
  filter: filterType;
  order?: sortType;
  sortedBy: string;
  searchQuery: string;
  startDate: Date | null;
  endDate: Date | null;
  managerSelected?: string;
  statusSelect?: string;
  typeSelected?: string;
  page: number;
  isOpen?: boolean;
  selectedTask: [] | any[];
  updateListAdminTasksStore: (state: Partial<State>) => void;
  resetListAdminTasksStore: (state: Partial<State>) => void;
};
const initialStates = {
  initialSate: true,
  filter: filterType?.VALIDATED,
  order: sortType?.DESC,
  sortedBy: 'validatedAt',
  searchQuery: '',
  startDate: null,
  endDate: null,
  managerSelected: localStorage?.cognitoUserId,
  statusSelect: 'N/A',
  typeSelected: undefined,
  page: 0,
  isOpen: undefined,
  selectedTask: [],
};

const store = (set: any) => ({
  ...initialStates,
  updateListAdminTasksStore: (state: Partial<State>) =>
    set({ initialSate: false, selectedTask: [], page: 0, ...state }),
  resetListAdminTasksStore: (state: Partial<State>) =>
    set({ ...initialStates, ...state }),
});

export const useListAdminTasksStore = create<State>(store);
