import { Button, Flex, FormInfo, Input } from 'components/ui';
import { ChevronRightIcon } from 'components/ui/icons';
import React, { ReactNode, useState } from 'react';
import { useForm } from 'react-hook-form';
import { ConfirmNavigationModal } from 'components/modals/ConfirmNavigationModal';
import { checkFormEmail, checkEmail } from '@commons';
type FormValues = {
  email?: string;
};
type ContactFormProps = {
  disableConfirm?: boolean;
  defaultValues?: FormValues;
  onSubmit?: (formValues: FormValues) => any | void;
  error?: ReactNode;
  isLocked?: boolean;
  canModify?: boolean;
  onModify?: () => any;
  contactRole:
    | 'PROVIDER_CONTACT'
    | 'CUSTOMER'
    | 'FREELANCE_PARTNER'
    | 'BUSINESS_PARTNER'
    | 'CONTRACTOR'
    | 'INTERNAL_COMMERCIAL'
    | 'ACCOUNT_MANAGER'
    | 'LEGAL_SQUAD'
    | 'TEAM_LEADER';
};

export const VerifyEmailForm: React.FC<
  React.PropsWithChildren<ContactFormProps>
> = ({
  defaultValues,
  onSubmit: onSubmitParent,
  isLocked,
  canModify,
  onModify,
  contactRole,
  disableConfirm,
}) => {
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty, isSubmitSuccessful },
    setError,
    watch,
  } = useForm<FormValues>({
    defaultValues,
  });

  //const { isDirty } = useFormState();
  const email = watch('email');
  const onSubmit = async (data: FormValues) => {
    setLoading(true);
    if (onSubmitParent) {
      if (checkEmail(email)) {
        setError('email', { message: undefined });
        await onSubmitParent({ email: data?.email?.trim() });
      } else {
        setError('email', { message: 'Email incorrect' });
      }
    }

    setLoading(false);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <ConfirmNavigationModal
        active={isDirty && !disableConfirm && !isSubmitSuccessful}
      />
      <Flex>
        <Input
          isFullWidth
          {...register('email', {
            required: true,
            validate: value => checkFormEmail(value as string),
          })}
          placeholder="john.doe@freelance.com"
          isDisabled={isLocked}
        />
        {!isLocked && (
          <Button
            data-cy="submit-verify-email"
            key="submit"
            type="button"
            onClick={() => onSubmit({ email })}
            isLoading={loading}
            ml={10}
          >
            <ChevronRightIcon fill="#fff" />
          </Button>
        )}
        {isLocked && canModify && (
          <Button key="modify" type="button" onClick={onModify} ml={10}>
            Modifier
          </Button>
        )}
      </Flex>
      {!!errors?.email?.message && (
        <FormInfo variantColor="error">Email incorrect</FormInfo>
      )}
    </form>
  );
};
