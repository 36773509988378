import React from 'react';
import { EServicesCommentList } from '@freelancelabs/teoreme-commons';
import { Box, Text } from 'components/ui';
import { useSetCrumbs } from 'hooks/breadCrumb';
import { useParams } from 'react-router-dom';
import CommentHandler from 'components/CommentsHandler';
import { useEstablishmentFindOne, getTradeNameSafe } from '@commons';

export const Comments = () => {
  const { uuid } = useParams<{ uuid: string }>();
  const { data: establishment } = useEstablishmentFindOne(uuid);
  const comment = establishment?.customer?.comment;

  useSetCrumbs(
    [establishment],
    [
      {
        label: 'clients',
        path: '/clients',
      },
      {
        label: 'établissements',
        path: `/clients/establishments`,
      },
      {
        label: establishment ? getTradeNameSafe(establishment) : '-',
        path: `/clients/establishments/${establishment?.uuid}`,
      },
      {
        label: 'commentaires',
        path: `/clients/establishments/${establishment?.uuid}/comments`,
      },
    ]
  );

  return (
    <Box p={20}>
      <Text variant="h2" mb={20}>
        Commentaires
      </Text>
      {comment && (
        <CommentHandler
          parentServiceUuid={comment}
          serviceName={EServicesCommentList?.COMPANY}
        />
      )}
    </Box>
  );
};
