import {
  establishmentFindRCPRO,
  getTradeNameSafe,
  useEstablishmentFindOne,
  missionUpdateOne,
  missionFindMany,
  useContractFindMany,
  useEstablishmentUpdateOne,
} from '@commons';
import {
  EMissionStatus,
  EContractLabel,
  RcProListResult,
  IJoinedMission,
  ICompanyGetResponse,
} from '@freelancelabs/teoreme-commons';
import { ContractList } from 'components/lists/ContractList';
import { showGenerateExecutiveContractModal } from 'components/modals/generateFile/GenerateExecutiveContractModal';
import { showImportAmendmentModal } from 'components/modals/ImportAmendmentModal';
import { showImportExecutiveContractModal } from 'components/modals/ImportExecutiveContractModal';
import { showAddRCPROModal } from 'components/modals/providers/AddRCPROModal';
import {
  BlocInformation,
  Box,
  CheckSwitch,
  Flex,
  FormControl,
  Link,
  Menu,
  MenuItem,
  Table,
  Text,
} from 'components/ui';
import { AddIcon, DotsIcon, SendIcon, UploadIcon } from 'components/ui/icons';
import { useSetCrumbs } from 'hooks/breadCrumb';
import React, { useState } from 'react';
import { queryClient } from '@commons';
import { useParams } from 'react-router-dom';
import { columns } from './TableConfig';
import { useShowMessage } from 'hooks/useShowMessage';
import { Theme } from 'styles';

export const ExecutiveContractList = () => {
  const showMessage = useShowMessage();
  const { id } = useParams<{ id: string }>();
  const { mutateAsync: updateEstablishment } = useEstablishmentUpdateOne();
  const { data: establishment } = useEstablishmentFindOne(id);
  const [isContractRequired, setIsContractRequired] = useState<boolean>(
    establishment?.provider?.isContractRequired || false
  );
  const [missionQuery, setMissionQuery] = React.useState<
    IJoinedMission[] | null
  >(null);
  const [RCPRO, setRCPRO] = React.useState<RcProListResult[]>([]);
  const findMissionAvalaible = async () => {
    try {
      const missions = await missionFindMany({
        limit: 10,
        skip: 0,
        sort: 'uuid',
        filterObject: {
          'provider.establishment.uuid': {
            $regex: id,
            $options: 'i',
          },
          $or: [
            {
              $expr: { $lte: ['$endAt', new Date()] },
              status: EMissionStatus.VALIDATED,
            },
            { status: EMissionStatus.DRAFT },
          ],
        },
      });
      setMissionQuery(missions.missions);
    } catch (e) {
      //
    }
  };

  const getEstablishmentContractFilter = (establishment: any) => {
    if (establishment?.provider?.contracts) {
      return {
        uuid: {
          $in: [
            ...(establishment?.provider?.contracts?.map(
              (e: any) => e?.uuid
            ) as string[]),
          ],
        },
      };
    } else {
      return {
        uuid: {
          $in: [],
        },
      };
    }
  };
  // const [page, setPage] = React.useState(0);
  const findEstablishmentRCPO = async () => {
    try {
      const rcPro = await establishmentFindRCPRO({
        uuid: establishment?.uuid,
        filterObject: {},
      });
      setRCPRO(rcPro?.results);
    } catch (e) {
      //
    }
  };
  useSetCrumbs(
    [establishment],
    [
      {
        label: 'fournisseurs',
        path: '/providers',
      },
      {
        label: 'établissements',
        path: '/providers/establishments',
      },
      {
        label: establishment ? getTradeNameSafe(establishment) : '-',
        path: '/providers/establishments/' + establishment?.uuid || '',
      },
      {
        label: 'contrats cadres & RC Pro',
        path: `/providers/establishments/${
          establishment?.uuid || ''
        }/executive-contract`,
      },
    ]
  );
  React.useEffect(() => {
    if (id && establishment) {
      findEstablishmentRCPO();
      findMissionAvalaible();
    }
  }, [id]);
  React.useEffect(() => {
    if (establishment) {
      setIsContractRequired(
        establishment?.provider?.isContractRequired || false
      );
    }
  }, [establishment]);
  const { data: contractsQuery } = useContractFindMany({
    filterObject: {
      ...getEstablishmentContractFilter(establishment as ICompanyGetResponse),
      state: 'DONE',
    },
    limit: 1,
  });
  const updateService = async (formValues: any, refMission: string) => {
    const submitData = {
      reference: refMission,
      mission: {
        additionalServices: {
          update: [
            {
              uuid: formValues.uuid,
              //type: EAdditionalServiceType.PROFESSIONAL_INDEMNITY_INSURANCE,
              validityStart: formValues.validityStart as Date,
              validityEnd: formValues.validityEnd as Date,
              unit: formValues.unit,
              price: Number(formValues.price),
              paidByProvider: true,
              currency: 'EUR',
            },
          ],
        },
      },
    };
    try {
      //@ts-ignore
      await missionUpdateOne(submitData);
      queryClient.refetchQueries({ queryKey: [id] });
      findEstablishmentRCPO();
    } catch (e) {
      //
    }
  };
  const items =
    RCPRO?.map(u => ({
      key: u.uuid || String(new Date().getTime()),
      ...u,
    })) || [];
  if (!establishment) return null;
  const establishmentHaveMission = missionQuery && missionQuery?.length > 0;
  const handleChangeIsContractRequired = async (value: boolean) => {
    setIsContractRequired(value);
    try {
      await updateEstablishment({
        uuid: id,
        establishment: {
          provider: {
            isContractRequired: value,
          },
        },
      });
      showMessage('success', 'Les informations ont été mises à jour');
      queryClient?.refetchQueries({ queryKey: [establishment.uuid] });
    } catch (e) {
      //
    }
  };
  const isLocked = establishment?.provider?.locked;
  return (
    <Box>
      {/* <Box>
        <FormControl label="Contrat-cadre">
          <CheckSwitch
            id="isContractRequired"
            onChange={e => handleChangeIsContractRequired(e?.target?.checked)}
            checked={isContractRequired}
          >
            <Text variant="span">
              {!isContractRequired
                ? "La signature d'un contrat cadre n'est pas obligatoire pour pouvoir valider les missions de cet établissement"
                : "La signature d'un contrat cadre est obligatoire pour pouvoir valider les missions de cet établissement"}
            </Text>
          </CheckSwitch>
        </FormControl>
      </Box> */}
      <Text variant="h2" mb={20}>
        Contrat-Cadre
      </Text>
      {!isLocked && (
        <Flex mb={10}>
          <Link
            iconLeft={<SendIcon />}
            mr={20}
            onClick={() => {
              showGenerateExecutiveContractModal({
                id: establishment.uuid,
                contractLabel: EContractLabel.EXECUTIVE,
              }).then(refresh => {
                if (refresh) {
                  queryClient.refetchQueries({
                    queryKey: [establishment.uuid],
                  });
                }
              });
            }}
          >
            Envoyer un nouveau contrat
          </Link>

          <Link
            iconLeft={<UploadIcon />}
            onClick={() =>
              showImportExecutiveContractModal({
                establishmentUuid: establishment.uuid || '',
                contractLabel: EContractLabel.EXECUTIVE,
              }).then(refresh => {
                if (refresh) {
                  queryClient.refetchQueries({
                    queryKey: [establishment.uuid],
                  });
                }
              })
            }
            mr={20}
          >
            Importer un contrat-cadre signé
          </Link>
          {contractsQuery && contractsQuery?.contracts?.length > 0 ? (
            <Menu
              menuItems={
                <>
                  {/* <MenuItem>
                  <Link iconLeft={<SendIcon />}>Envoyer un avenant</Link>
                </MenuItem> */}
                  <MenuItem>
                    <Link
                      iconLeft={<UploadIcon />}
                      onClick={() =>
                        showImportAmendmentModal({
                          establishmentUuid: establishment.uuid || '',
                        })
                      }
                    >
                      Importer un avenant signé
                    </Link>
                  </MenuItem>
                </>
              }
            >
              <DotsIcon fill={Theme?.colors?.primary?.default} fontSize={20} />
            </Menu>
          ) : (
            <></>
          )}
        </Flex>
      )}
      <Box>
        <ContractList
          columnsHidden={['establishment']}
          filterObject={{
            label: EContractLabel.EXECUTIVE,
            ...getEstablishmentContractFilter(establishment),
          }}
        />
      </Box>
      <Text variant="h2" mb={20} mt={20}>
        Mandats
      </Text>
      {!isLocked && (
        <Flex mb={10}>
          <Link
            iconLeft={<SendIcon />}
            mr={20}
            onClick={() => {
              showGenerateExecutiveContractModal({
                id: establishment.uuid,
                contractLabel: EContractLabel.MANDATE,
              }).then(refresh => {
                if (refresh) {
                  queryClient.refetchQueries({
                    queryKey: [establishment.uuid],
                  });
                }
              });
            }}
          >
            Envoyer un nouveau mandat
          </Link>
          <Link
            iconLeft={<UploadIcon />}
            onClick={() =>
              showImportExecutiveContractModal({
                establishmentUuid: establishment.uuid || '',
                contractLabel: EContractLabel.MANDATE,
              }).then(refresh => {
                if (refresh) {
                  queryClient.refetchQueries({
                    queryKey: [establishment.uuid],
                  });
                }
              })
            }
            mr={20}
          >
            Importer un mandat signé
          </Link>
        </Flex>
      )}
      <Box>
        <ContractList
          columnsHidden={['establishment']}
          filterObject={{
            label: EContractLabel.MANDATE,
            ...getEstablishmentContractFilter(establishment),
          }}
        />
      </Box>
      <Box mt={20} mb={20}>
        <Text variant="h2" mb={20}>
          Responsabilité Civile Professionnelle
        </Text>

        {items.findIndex(
          rc => rc?.status === 'ACTIVE' || rc.status === 'PENDING'
        ) > -1
          ? false
          : true && (
              <Flex mb={20} mt={20}>
                <Link
                  variantColor={establishmentHaveMission ? 'primary' : 'grey'}
                  onClick={() =>
                    establishmentHaveMission
                      ? showAddRCPROModal({ establishmentId: id }).then(
                          action => {
                            if (action) {
                              findEstablishmentRCPO();
                            }
                          }
                        )
                      : false
                  }
                  iconLeft={<AddIcon />}
                >
                  Ajouter une RC PRO
                </Link>
              </Flex>
            )}
        {!establishmentHaveMission && (
          <Text mb={20}>
            Aucune mission en cours ou à venir pour cet établissement. Afin de
            pouvoir activer ce service, une mission doit être préalablement
            créée.
          </Text>
        )}
        <BlocInformation>
          <p>
            Une fois activée, la RC PRO protège tous les intervenants de
            l'entreprise fournisseur durant toute la durée de la mission dans le
            cadre de laquelle elle a été souscrite. A la fin de cette mission,
            si l’entreprise fournisseur souhaite continuer à être couverte par
            la RC PRO Freelance.com, pensez à lui rappeler d’y souscrire dans le
            cadre d’une autre mission.
          </p>
        </BlocInformation>
        {items.length > 0 && (
          <Table
            mt={20}
            columns={columns(items, updateService, findEstablishmentRCPO)}
            items={items}
            total={0}
            perPage={2000}
            isLoading={false}
          />
        )}
      </Box>
    </Box>
  );
};
