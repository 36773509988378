import {
  checkDefaultManagerSelected,
  checkUserHasRole,
  exportFileXlxs,
  round,
  stripHtmlTags,
  useMe,
  useMissionFindMany,
} from '@commons';
import { Roles as RolesTypes } from '@freelancelabs/inside-commons/dist/lib/models/enum/role.enum';
import {
  EBillingType,
  EExportModel,
  EMissionStatus,
  EPurchaseOrderStatus,
  IJoinedMission,
} from '@freelancelabs/teoreme-commons';
import EXPORT_IMG from 'assets/images/export_excel_tutorial.png';
import {
  FR_STATUS,
  getEstablishmentName,
  getFullName,
  isNotEmpty,
  transformText,
} from '@commons';
import { Header } from 'components/Header';
import { showDialogModal } from 'components/modals/DialogModal';
import { showBulkUpdateMissionManagerModal } from 'components/modals/missions/BulkUpdateMissionManagerModal';
import { showCreateMissionModal } from 'components/modals/missions/CreateMissionModal';
import { StaticSelect } from 'components/selects/StaticSelect';
import { UserSelect } from 'components/selects/UserSelect';
import {
  ALink,
  Box,
  Button,
  Container,
  CustomToolTip,
  DropDownButton,
  Flex,
  FormLabel,
  Input,
  Itemize,
  Link,
  SpinnerBox,
  Status,
  Tab,
  Table,
  TableColumn,
  Text,
} from 'components/ui';
import { SideBar } from 'components/ui/BootStrap/SideBar';
import { AddIcon, RefreshIcon } from 'components/ui/icons';
import { add, getTime } from 'date-fns';
import { format } from 'date-fns';
import { useSetCrumbs } from 'hooks/breadCrumb';
import { useDebounce } from 'hooks/useDebounce';
import * as React from 'react';
import { useParams } from 'react-router-dom';
import { useGlobalStore } from 'store/global';
import { useListMissionStore } from 'store/mission';
import { Theme } from 'styles';
import { MissionActions } from './MissionActions';
import { MissionsFilersList } from './MissionsFilersList';
type Item = Itemize<IJoinedMission>;

const tabItems = (
  <>
    <Tab variant="secondary" href={'/delivery/missions/all'}>
      Tous
    </Tab>
    <Tab variant="secondary" href={'/delivery/missions/draft'}>
      Brouillons
    </Tab>
    <Tab variant="secondary" href={'/delivery/missions/validated'}>
      Validées
    </Tab>
    <Tab variant="secondary" href={'/delivery/missions/terminated'}>
      Terminées
    </Tab>
    <Tab variant="secondary" href={'/delivery/missions/aborted'}>
      Annulées
    </Tab>
  </>
);

const filterLabel = {
  all: 'tous',
  validated: 'validées',
  draft: 'brouillons',
  aborted: 'annulées',
  terminated: 'terminées',
};

export const MissionsList = () => {
  const { filter: filterParams } = useParams<{
    filter: 'all' | 'validated' | 'draft' | 'aborted' | 'terminated';
  }>();
  const {
    initialSate,
    filter,
    order,
    sortedBy,
    searchQuery,
    startDate,
    endDate,
    managerSelected,
    commercialSelected,
    structureSelected,
    //clientSelected,
    contactClientSelected,
    //providerSelected,
    multiProviderSelected,
    multiClientSelected,
    contractorSelected,
    statusBDCCustomerSelect,
    statusSelect,
    typeSelected,
    billingTypeSelect,
    isMandateMode,
    isDirectPayment,
    minCustomerBudget,
    maxCustomerBudget,
    missionType,
    allMissionsSelected,
    selectedMissionKeys,
    unSelectedMissionKeys,
    displayCol,
    page,
    limit,
    updateListMissionStore,
    resetListMissionStore,
    getNbActiveFilters,
  } = useListMissionStore();
  const { tasksInProgress, updateGlobalStore } = useGlobalStore();
  const { me } = useMe();
  const additionalFiltersFields = [
    'startDate',
    'endDate',
    'commercialSelected',
    'structureSelected',
    'contactClientSelected',
    'multiProviderSelected',
    'multiClientSelected',
    'contractorSelected',
    'statusBDCCustomerSelect',
    'typeSelected',
    'billingTypeSelect',
    'isMandateMode',
    'isDirectPayment',
    'minCustomerBudget',
    'maxCustomerBudget',
    'missionType',
  ];

  useSetCrumbs(
    [filter],
    [
      { label: 'prestations', path: '/delivery' },
      { label: 'missions', path: '/delivery/missions' },
      {
        label: filterLabel[filterParams],
        path: '/delivery/missions/' + filter,
      },
    ]
  );

  const [isLoadingExport, setIsLoadingExport] = React.useState(false);
  const [displayDate, setDisplayDate] = React.useState<
    'createdAt' | 'validatedAt' | 'abortedAt' | 'endAt' | 'startAt'
  >('startAt');

  React.useEffect(() => {
    if (filterParams === 'all') {
      setDisplayDate('startAt');
      updateListMissionStore({
        //@ts-ignore
        order: 'desc',
        initialSate,
        sortedBy: 'createdAt',
        page: 0,
        statusSelect: 'N/A',
      });
    }
    if (filterParams === 'validated') {
      setDisplayDate('startAt');
      updateListMissionStore({
        //@ts-ignore
        order: 'desc',
        initialSate,
        sortedBy: 'validatedAt',
        page: 0,
        statusSelect: EMissionStatus.VALIDATED,
      });
    }
    if (filterParams === 'aborted') {
      setDisplayDate('startAt');
      updateListMissionStore({
        //@ts-ignore
        order: 'desc',
        initialSate,
        sortedBy: 'abortedAt',
        page: 0,
        statusSelect: EMissionStatus.ABORTED,
      });
    }
    if (filterParams === 'draft') {
      setDisplayDate('startAt');
      updateListMissionStore({
        //@ts-ignore
        order: 'asc',
        initialSate,
        sortedBy: 'createdAt',
        statusSelect: EMissionStatus.DRAFT,
        page: 0,
      });
    }
    if (filterParams === 'terminated') {
      setDisplayDate('startAt');
      updateListMissionStore({
        //@ts-ignore
        order: 'asc',
        initialSate,
        sortedBy: 'endAt',
        statusSelect: EMissionStatus.TERMINATED,
        page: 0,
      });
    }
  }, [filterParams, setDisplayDate]);

  const sortString = (order === 'desc' ? '-' : '') + sortedBy;
  const debouncedFilterQuery = useDebounce(searchQuery, 500);
  const buildQuery = () => {
    const $and = [];
    if (startDate && endDate) {
      $and?.push({
        $expr: {
          $gte: [{ $toLong: `$${displayDate}` }, getTime(startDate)],
        },
      });
      $and?.push({
        $expr: {
          $lte: [
            { $toLong: `$${displayDate}` },
            getTime(add(endDate, { months: 1 })),
          ],
        },
      });
    }
    if (minCustomerBudget && maxCustomerBudget) {
      $and?.push({
        'billing.estimatedSaleBudget': {
          $gte: Number(minCustomerBudget),
          $lte: Number(maxCustomerBudget),
        },
      });
    }
    const getStatus = () => {
      if (filterParams === 'all') {
        if (statusSelect && statusSelect !== 'N/A') {
          return statusSelect;
        } else {
          return undefined;
        }
      } else {
        return filterParams?.toLocaleUpperCase();
      }
    };
    return {
      'billing.type':
        billingTypeSelect !== 'N/A' ? billingTypeSelect : undefined,
      parent:
        typeSelected === 'Mission'
          ? { $exists: false }
          : typeSelected === 'Amendment'
            ? { $exists: true }
            : undefined,
      status: getStatus(),
      'customer.purchaseOrder.status':
        statusBDCCustomerSelect !== 'N/A' ? statusBDCCustomerSelect : undefined,
      accountManager: checkDefaultManagerSelected(me, managerSelected)
        ? managerSelected
        : undefined,
      internalCommercial: isNotEmpty(commercialSelected)
        ? commercialSelected
        : undefined,
      'provider.establishment':
        multiProviderSelected && multiProviderSelected?.length > 0
          ? { $in: multiProviderSelected?.map((mps: any) => mps?.value) }
          : undefined,

      'provider.contractor': isNotEmpty(contractorSelected)
        ? contractorSelected
        : undefined,
      'customer.establishment':
        multiClientSelected !== null
          ? multiClientSelected && multiClientSelected?.length > 0
            ? { $in: multiClientSelected?.map((mps: any) => mps?.value) }
            : undefined
          : undefined,
      'customer.contact': isNotEmpty(contactClientSelected)
        ? contactClientSelected
        : undefined,
      'billingInformation.structure': isNotEmpty(structureSelected)
        ? structureSelected
        : undefined,
      'billingInformation.missionType.label':
        missionType !== 'N/A' ? missionType : undefined,
      isMandateMode:
        isMandateMode === 1 ? true : isMandateMode === 0 ? false : undefined,
      'customer.billingOptions.isDirectPayment':
        isDirectPayment === 1
          ? true
          : isDirectPayment === 0
            ? false
            : undefined,
      $or: isNotEmpty(debouncedFilterQuery)
        ? [
            {
              'sage.missionId': {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
            {
              'salesforce.externalId': {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
            // customer
            {
              'customer.establishment.businessName': {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
            {
              'customer.establishment.tradeName': {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
            {
              'customer.establishment.signBoard1': {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
            {
              'customer.establishment.signBoard2': {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
            {
              'customer.establishment.signBoard3': {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
            {
              'customer.establishment.identifier': {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
            {
              'customer.establishment.siret': {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
            {
              'customer.establishment.siren': {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
            // provider
            {
              'provider.establishment.businessName': {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
            {
              'provider.establishment.tradeName': {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
            {
              'provider.establishment.signBoard1': {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
            {
              'provider.establishment.signBoard2': {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
            {
              'provider.establishment.signBoard3': {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
            {
              'provider.establishment.identifier': {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
            {
              'provider.establishment.siret': {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
            {
              'provider.establishment.siren': {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
            {
              'provider.contractor.firstName': {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
            {
              'provider.contractor.lastName': {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
            {
              'customer.purchaseOrder.reference': {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
          ]
        : undefined,
      $and: $and?.length > 0 ? $and : undefined,
    };
  };
  const buildQueryAnd = async () => {
    let BulkUpdateFilter = {};
    if (allMissionsSelected && unSelectedMissionKeys.length) {
      BulkUpdateFilter = { reference: { $nin: unSelectedMissionKeys } };
    } else if (selectedMissionKeys.length) {
      BulkUpdateFilter = { reference: { $in: selectedMissionKeys } };
    }
    const qry = { ...buildQuery(), ...BulkUpdateFilter };

    return qry;
  };

  const {
    data: missionsQuery,
    status,
    isFetching,
  } = useMissionFindMany({
    filterObject: buildQuery(),
    limit: limit,
    skip: limit * page,
    sort: sortString,
  });
  const totalCount = missionsQuery?.totalCount;
  const loading = status === 'pending';

  const items: Item[] =
    missionsQuery?.missions?.map(m => ({
      ...m,
      key: m.reference,
    })) || [];

  const onSortedChange = React.useCallback(
    (sortBy: string, order: 'asc' | 'desc'): void => {
      //@ts-ignore
      updateListMissionStore({ sortBy, order });
    },
    []
  );
  const onSelectionChange = React.useCallback(
    (selectAll: boolean, selecteds: string[], unSelecteds: string[]): void => {
      updateListMissionStore({
        allMissionsSelected: selectAll,
        selectedMissionKeys: selecteds,
        unSelectedMissionKeys: unSelecteds,
      });
    },
    []
  );

  type MultiValueType = {
    label: string;
    value: string;
  };
  const columns: (displayCol: MultiValueType[]) => TableColumn<Item>[] = () => [
    {
      key: 'startAt',
      text: 'DATE DE DÉBUT',
      sortKey: filterParams !== 'all' ? 'startAt' : undefined,
      hidden: !displayCol?.find(e => e?.value === 'startAt'),
      render: item => (
        <Text>{item?.startAt && format(item?.startAt, 'dd/MM/yyyy')}</Text>
      ),
    },
    {
      key: 'endAt',
      text: 'DATE DE FIN',
      sortKey: filterParams !== 'all' ? 'endAt' : undefined,
      hidden: !displayCol?.find(e => e?.value === 'endAt'),
      render: item => (
        <Text
          color={item.status === EMissionStatus.TERMINATED ? 'red' : undefined}
        >
          {item?.endAt && format(item?.endAt, 'dd/MM/yyyy')}
        </Text>
      ),
    },
    {
      key: 'displayReference',
      text: 'RÉF.',
      sortKey: filterParams !== 'all' ? 'displayReference' : undefined,
      hidden: !displayCol?.find(e => e?.value === 'displayReference'),
      render: item => (
        <CustomToolTip
          text={stripHtmlTags(item?.description)}
          id={item?.reference}
        >
          <ALink href={`/delivery/missions/${item?.reference}`} target="_blank">
            {item?.displayReference}
          </ALink>
        </CustomToolTip>
      ),
    },
    {
      key: 'sage.structure',
      text: 'STRUCT.',
      hidden: !displayCol?.find(e => e?.value === 'sage.structure'),
      sortKey: filterParams !== 'all' ? 'sage' : undefined,
      render: item => {
        if (item.sage) {
          return item.sage.structure;
        } else {
          return 'N/A';
        }
      },
    },
    {
      key: 'billing.type',
      text: 'TARIFICATION ',
      sortKey: filterParams !== 'all' ? 'billing.type' : undefined,
      hidden: !displayCol?.find(e => e?.value === 'billing.type'),
      render: item => {
        if (item.billing?.type) {
          if (item.billing?.type === EBillingType.DAY) {
            return 'Temps passé';
          }
          if (item.billing?.type === EBillingType.FLAT_RATE) {
            return 'Forfait';
          }
        } else {
          return 'N/A';
        }
      },
    },
    {
      key: 'isAmendment',
      text: 'TYPE',
      hidden: !displayCol?.find(e => e?.value === 'isAmendment'),
      render: item => (item.parent ? 'Avenant' : 'Mission'),
    },
    {
      key: 'customerEstablishment',
      text: 'ÉTABLIS.CLIENT',
      hidden: !displayCol?.find(e => e?.value === 'customerEstablishment'),
      sortKey:
        filterParams !== 'all'
          ? 'customer.establishment.businessName'
          : undefined,
      render: item => (
        <ALink
          href={
            item?.customer?.establishment?.uuid
              ? `/clients/establishments/${item?.customer?.establishment?.uuid}`
              : undefined
          }
          target="_blank"
        >
          {transformText(
            getEstablishmentName(item.customer.establishment),
            'capitalize'
          )}
        </ALink>
      ),
    },
    {
      key: 'providerEstablishment',
      text: 'ÉTABLIS.FOURN.',
      hidden: !displayCol?.find(e => e?.value === 'providerEstablishment'),
      render: item => (
        <ALink
          href={
            item?.provider?.establishment?.uuid
              ? `/providers/establishments/${item?.provider?.establishment?.uuid}`
              : undefined
          }
          target="_blank"
        >
          {transformText(
            getEstablishmentName(item.provider.establishment),
            'capitalize'
          )}
        </ALink>
      ),
    },
    {
      key: 'contractor',
      sortKey:
        filterParams !== 'all' ? 'provider.contractor.firstName' : undefined,
      text: 'INTERVENANT',
      hidden: !displayCol?.find(e => e?.value === 'contractor'),
      render: item => (
        <ALink
          href={
            item?.provider?.contractor?.uuid
              ? `/providers/contractors/${item?.provider?.contractor?.uuid}`
              : undefined
          }
          target="_blank"
        >
          {getFullName(item.provider.contractor)}
        </ALink>
      ),
    },
    {
      key: 'quoteStatus',
      text: 'DEVIS',
      render: item => (
        <Status variantColor={!item.customer.quote ? 'grey' : 'success'}>
          {(item.customer.quote && FR_STATUS[item.customer.quote?.status]) ||
            'Non envoyé'}
        </Status>
      ),
      hidden:
        !displayCol?.find(e => e?.value === 'quoteStatus') ||
        filterParams === 'aborted',
    },
    {
      key: 'customerPurchaseOrderStatus',
      text: 'BDC CLIENT',
      hidden:
        !displayCol?.find(e => e?.value === 'customerPurchaseOrderStatus') ||
        filter === 'aborted',
      render: item => {
        const purchaseOrder = item?.customer?.purchaseOrder;
        return (
          <Status
            variantColor={
              !purchaseOrder ||
              purchaseOrder.status === EPurchaseOrderStatus.UNRECEIVED
                ? 'grey'
                : // : purchaseOrder.status ===
                  //     EPurchaseOrderStatus.REFUSED_BY_MANAGER ||
                  //   purchaseOrder.status ===
                  //     EPurchaseOrderStatus.REFUSED_BY_PROVIDER
                  // ? 'error'
                  'success'
            }
          >
            {(purchaseOrder && FR_STATUS[purchaseOrder?.status]) || 'Non reçu'}
            {purchaseOrder?.status ===
              EPurchaseOrderStatus.REFUSED_BY_MANAGER ||
            purchaseOrder?.status ===
              EPurchaseOrderStatus.REFUSED_BY_PROVIDER ||
            purchaseOrder?.status === EPurchaseOrderStatus.DONE ||
            purchaseOrder?.status === EPurchaseOrderStatus.SIGNED_IMPORT ? (
              //@ts-ignore
              <> le {new Date(purchaseOrder?.updatedAt).toLocaleDateString()}</>
            ) : (
              <></>
            )}
          </Status>
        );
      },
    },
    {
      key: 'status',
      text: 'STATUT',
      hidden:
        !displayCol?.find(e => e?.value === 'status') || filterParams !== 'all',

      render: item => {
        if (item.status === EMissionStatus.ABORTED) {
          return <Status variantColor="error">Annulée</Status>;
        }
        if (item.status === EMissionStatus.ARCHIVED) {
          return <Status variantColor="grey">Archivée</Status>;
        }
        if (item.status === EMissionStatus.DRAFT) {
          return <Status variantColor="warning">Broullion</Status>;
        }
        if (item.status === EMissionStatus.TERMINATED) {
          return <Status variantColor="error">Terminée</Status>;
        }
        if (item.status === EMissionStatus.VALIDATED) {
          return <Status variantColor="success">Validée</Status>;
        }
      },
    },
    {
      key: 'nbWorkingDays',
      text: 'FACT./COM.',
      hidden: !displayCol?.find(e => e?.value === 'nbWorkingDays'),
      render: item => {
        if (item?.billing?.type === EBillingType?.DAY) {
          return (
            <Box>
              {item?.billing?.declaredWorkingDaysInCras || 0}/
              {item?.billing?.estimatedDays || 0}{' '}
            </Box>
          );
        }
        if (item?.billing?.type === EBillingType?.FLAT_RATE) {
          return (
            <Box>
              {round(item?.billing?.consumedSaleBudget || 0)}€/
              {item?.billing?.clientRate || 0}€
            </Box>
          );
        }
      },
    },
    {
      align: 'flex-end',
      key: 'actions',
      text: 'ACTIONS',
      fixed: true,
      render: item => <MissionActions mission={item} />,
    },
  ];
  const onExportToExcel = async () => {
    setIsLoadingExport(true);
    try {
      const exportResponse = await exportFileXlxs({
        model: EExportModel.MISSION,
        query: {
          filter: buildQuery(),
          //fields:[],
          // limit: limit,
          // skip: limit * page,
          // sort: sortString,
        },
      });
      const uuid = exportResponse?.taskUuid;
      if (Array.isArray(tasksInProgress)) {
        updateGlobalStore({ tasksInProgress: [...tasksInProgress, uuid] });
      } else {
        updateGlobalStore({ tasksInProgress: [uuid] });
      }
      showDialogModal({
        title: 'Export excel en cours',
        text: (
          <Box>
            <Text>
              La création de votre export excel est en cours. Cette action peut
              prendre quelques minutes. Vous pouvez suivre l'état de progression
              en cliquant sur l'icone ci-dessous.
            </Text>
            <img src={EXPORT_IMG} />
          </Box>
        ),
        confirmLabel: "J'ai compris",
      });
      setIsLoadingExport(false);
    } catch (e) {
      setIsLoadingExport(false);
    }
  };
  const getSelectedMissionsCount = (): number => {
    if (allMissionsSelected)
      return (totalCount ?? 0) - unSelectedMissionKeys.length;
    if (!allMissionsSelected) return selectedMissionKeys.length;
    return 0;
  };

  const canShowMissionActions = () => {
    return checkUserHasRole(me, 'TEAM_LEADER') || checkUserHasRole(me, 'ADMIN');
  };
  const onChangeMissionManager = async () => {
    try {
      const bulkUpdateFiler = await buildQueryAnd();
      const isTeamLeader = checkUserHasRole(me, 'TEAM_LEADER');
      const selectedMissionsCount = getSelectedMissionsCount();
      const isSelectedFromManager = isNotEmpty(managerSelected);
      const hasSelectedMissions = selectedMissionsCount > 0;
      showBulkUpdateMissionManagerModal({
        bulkUpdateFiler,
        isTeamLeader,
        selectedMissionsCount,
        hasSelectedMissions,
        isSelectedFromManager,
      }).then(async () => {
        updateListMissionStore({ page: 0, initialSate });
      });

      // setIsLoadingExport(false);
    } catch (e) {
      setIsLoadingExport(false);
    }
  };
  React.useEffect(() => {
    updateListMissionStore({ page: 0, initialSate });
  }, [filterParams, typeSelected, managerSelected]);

  return (
    <Box>
      <Header
        tabs={tabItems}
        actions={
          <Button onClick={() => showCreateMissionModal()}>
            Ajouter une mission
          </Button>
        }
      >
        <Text variant="h1">Missions</Text>
      </Header>
      <Container p="10px 0">
        <Flex
          display="inline-flex"
          justifyContent="flex-start"
          flexWrap={'wrap'}
          width={1 / 1}
        >
          <Box width={3 / 10} pr={'5px'} pt={'5px'}>
            <FormLabel>RECHERCHER</FormLabel>
            <Input
              isFullWidth
              type="search"
              onChange={e => {
                //@ts-ignore
                updateListMissionStore({ searchQuery: e.target.value });
              }}
              value={searchQuery}
            />
          </Box>
          <Box width={3 / 10} pr={'5px'} pt={'5px'}>
            <FormLabel>Chargés de comptes</FormLabel>
            <UserSelect
              placeholder="Tous les Chargés de comptes"
              role={RolesTypes.ACCOUNT_MANAGER}
              onChange={value =>
                updateListMissionStore({ managerSelected: value })
              }
              onChangeCompleteObject={value => {
                updateListMissionStore({
                  managerSelectedCompleteObject: value,
                });
              }}
              value={managerSelected}
            />
          </Box>
          <Box width={1 / 10} pr={'5px'} pt={'5px'}>
            <FormLabel>NB. LIGNES AFFICHÉES</FormLabel>
            <StaticSelect
              isClearable={false}
              placeholder=" "
              options={[
                {
                  label: '20',
                  value: 20,
                  key: 20,
                },
                {
                  label: '50',
                  value: 50,
                  key: 50,
                },
                {
                  label: '100',
                  value: 100,
                  key: 100,
                },
              ]}
              onChange={value =>
                updateListMissionStore({ limit: value as number })
              }
              value={limit}
            />
          </Box>
          <Flex
            width={3 / 10}
            mt={'20px'}
            justifyContent={'space-around'}
            alignItems="center"
          >
            <SideBar
              title="Filtres de mission"
              customLinkIcon={<AddIcon />}
              buttonType="link"
              buttonText="Plus de filtres"
            >
              <MissionsFilersList />
            </SideBar>
            {initialSate === false &&
              getNbActiveFilters(additionalFiltersFields) > 0 && (
                <Box
                  backgroundColor={Theme?.colors?.primary?.default}
                  border={`1px solid ${Theme?.colors?.primary?.default}`}
                  borderRadius={'50%'}
                  height={15}
                  width={15}
                  ml={-9}
                  mt={-3}
                  // mt={-7}
                >
                  <Flex justifyContent={'center'}>
                    <Text color="white" fontSize={'10px'}>
                      {' '}
                      {getNbActiveFilters(additionalFiltersFields)}
                    </Text>
                  </Flex>
                </Box>
              )}
            <Link
              isDisabled={initialSate}
              iconLeft={
                <RefreshIcon
                  fill={initialSate ? Theme?.colors?.grey?.default : undefined}
                />
              }
              onClick={() => (!initialSate ? resetListMissionStore({}) : false)}
            >
              Réinitialiser
            </Link>
            {canShowMissionActions() ? (
              <DropDownButton
                buttonText="Actions"
                ml={10}
                mt={-15}
                minWidth="150px"
                height="33.5px"
                onClick={() => {}}
                items={[
                  {
                    children: <Text color="white">Exporter</Text>,
                    onClick: () => {
                      onExportToExcel();
                    },
                  },
                  {
                    children: (
                      <Text color="white">Modifier le resp. de mission</Text>
                    ),
                    onClick: () => {
                      onChangeMissionManager();
                    },
                  },
                ]}
              />
            ) : (
              <Button
                isLoading={isLoadingExport}
                onClick={() => onExportToExcel()}
              >{`Exporter`}</Button>
            )}
          </Flex>

          <Flex mt={10}></Flex>
        </Flex>
      </Container>
      {loading && <SpinnerBox />}
      {!loading && (
        <Container
          p="20px 0"
          style={{ overflowX: 'scroll', overflow: 'scroll' }}
        >
          <Table<Item>
            columns={columns(displayCol)}
            items={items}
            sortedBy={sortedBy}
            order={order}
            page={page}
            total={totalCount}
            perPage={totalCount && totalCount < limit ? totalCount : limit}
            onSortedChange={onSortedChange}
            onChangePage={page => updateListMissionStore({ page })}
            opacity={filterParams === 'aborted' ? 0.5 : 1}
            isLoading={isFetching}
            isSelectable={
              checkUserHasRole(me, 'TEAM_LEADER') ||
              checkUserHasRole(me, 'ADMIN')
            }
            onSelectionChange={onSelectionChange}
            selectAll={allMissionsSelected}
            selectedItemsKeys={selectedMissionKeys}
            unSelectedItemsKeys={unSelectedMissionKeys}
          />
        </Container>
      )}
    </Box>
  );
};
