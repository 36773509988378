import {
  checkDefaultManagerSelected,
  getFullName,
  transformText,
  useDpaeFindMany,
  useMe,
} from '@commons';
import { Roles as RolesTypes } from '@freelancelabs/inside-commons/dist/lib/models/enum/role.enum';
import { IJoinedDpae } from '@freelancelabs/teoreme-commons';
import { Header } from 'components/Header';
import { UserSelect } from 'components/selects/UserSelect';
import {
  Box,
  Container,
  DatePickerRange,
  Flex,
  FormLabel,
  Input,
  Itemize,
  Link,
  SpinnerBox,
  Tab,
  Table,
  TableColumn,
  Text,
} from 'components/ui';
import { RefreshIcon } from 'components/ui/icons';
import { add, getTime } from 'date-fns';
import { format } from 'date-fns';
import { useSetCrumbs } from 'hooks/breadCrumb';
import { useDebounce } from 'hooks/useDebounce';
import { snakeCase } from 'lodash';
import * as React from 'react';
import { useParams } from 'react-router-dom';
import { useDPAEListStore } from 'store';
import { Theme } from 'styles';
import { DPAEActions } from './DPAEActions';
type Item = Itemize<IJoinedDpae>;

const Tabs = (
  <>
    <Tab variant="secondary" href={'/providers/dpae/pending'}>
      En attente
    </Tab>
    <Tab variant="secondary" href={'/providers/dpae/to-be-validated'}>
      À valider
    </Tab>
    <Tab variant="secondary" href={'/providers/dpae/active'}>
      Actives
    </Tab>
    <Tab variant="secondary" href={'/providers/dpae/archived'}>
      Archivés
    </Tab>
  </>
);

const columns = (
  status: 'active' | 'pending' | 'to-be-validated' | 'archived'
) =>
  [
    status === 'active' && {
      key: 'dateCreated',
      text: 'DATE',
      // @ts-ignore
      render: (item: IJoinedDpae) => format(item.createdAt, 'dd/MM/yyyy'),
    },
    status === 'pending' && {
      key: 'dateUpdated',
      text: 'DATE',
      // @ts-ignore
      render: (item: IJoinedDpae) => format(item.updatedAt, 'dd/MM/yyyy'),
      hide: status !== 'pending',
    },
    status === 'archived' && {
      key: 'dateUpdated',
      text: 'DATE',
      // @ts-ignore
      render: (item: IJoinedDpae) => format(item.updatedAt, 'dd/MM/yyyy'),
    },
    status === 'to-be-validated' && {
      key: 'dateUpdated',
      text: 'DATE',
      // @ts-ignore
      render: (item: IJoinedDpae) =>
        item.createdAt ? format(item.createdAt, 'dd/MM/yyyy') : 'N/A',
      hide: status !== 'to-be-validated',
    },
    {
      key: 'contractor',
      text: 'INTERVENANT',
      render: (item: IJoinedDpae) => getFullName(item.contractor),
    },
    {
      key: 'entreprise',
      text: 'ENTREPRISE FOURNISSEUR',
      render: (item: IJoinedDpae) =>
        transformText(item.businessName, 'capitalize'),
    },
    {
      key: 'contact',
      text: 'CONTACT FOURNISSEUR',
      render: (item: IJoinedDpae) => {
        const estaContactIndex = item?.contractor?.scopedData.findIndex(
          //@ts-ignore
          scop => scop.establishment === item?.establishment?.uuid
        );
        return estaContactIndex > -1
          ? getFullName(
              item.contractor?.scopedData?.[estaContactIndex]?.contact
            )
          : 'N/A';
      },
    },
    status === 'pending' && {
      key: 'accountManager',
      text: 'SUIVI PAR',
      render: (item: IJoinedDpae) =>
        getFullName(item.contractor?.accountManager),
    },
    {
      key: 'actions',
      text: 'ACTIONS',
      align: 'flex-end',
      render: (item: IJoinedDpae) => (
        <DPAEActions DPAE={item} filter={status} />
      ),
    },
  ].filter(Boolean) as TableColumn<Item>[];
export const DPAEList = () => {
  const { me } = useMe();
  const { filter: filterParams } = useParams<{
    filter: 'active' | 'pending' | 'to-be-validated' | 'archived';
  }>();
  useSetCrumbs(
    [filterParams],
    [
      {
        label: 'fournisseurs',
        path: '/providers',
      },
      {
        label: 'DPAE',
        path: '/providers/dpae',
      },
      {
        label:
          filterParams === 'active'
            ? 'actives'
            : filterParams === 'archived'
              ? 'archivés'
              : filterParams === 'to-be-validated'
                ? 'à valider'
                : 'en attente',
        path: '/providers/dpae/' + filterParams,
      },
    ]
  );
  const {
    initialSate,
    order,
    sortedBy,
    searchQuery,
    startDate,
    endDate,
    managerSelected,
    page,
    updateDPAEListStore,
    resetDPAEListStore,
  } = useDPAEListStore();
  const sortString = (order === 'desc' ? '-' : '') + sortedBy;
  const debouncedFilterQuery = useDebounce(searchQuery, 500);

  const {
    data: dpaesQuery,
    status,
    isFetching,
  } = useDpaeFindMany({
    filterObject: {
      status: snakeCase(filterParams).toUpperCase(),
      'contractor.accountManager.cognitoUserId': checkDefaultManagerSelected(
        me,
        managerSelected
      )
        ? managerSelected
        : undefined,
      $or: debouncedFilterQuery
        ? [
            //contractor
            {
              'contractor.firstName': {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
            {
              'contractor.lastName': {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
            {
              'contractor.email': {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },

            // establishment
            {
              'establishment.businessName': {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
            {
              'establishment.tradeName': {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
            {
              'establishment.signBoard1': {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
            {
              'establishment.signBoard2': {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
            {
              'establishment.signBoard3': {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
            {
              'establishment.identifier': {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
            {
              'establishment.siret': {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
            {
              'establishment.siren': {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
            {
              'establishment.provider.manager.firstName': {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
            {
              'establishment.provider.manager.lastName': {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
            {
              'establishment.provider.manager.email': {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
          ]
        : undefined,
      $and:
        startDate && endDate
          ? [
              {
                $expr: {
                  $gte: [{ $toLong: `$${'updatedAt'}` }, getTime(startDate)],
                },
              },
              {
                $expr: {
                  $lte: [
                    { $toLong: `$${'updatedAt'}` },
                    getTime(add(endDate, { months: 1 })),
                  ],
                },
              },
            ]
          : undefined,
    },
    limit: 20,
    skip: 20 * page,
    sort: sortString,
  });

  const loading = status === 'pending';
  const totalCount = dpaesQuery?.totalCount || 0;

  const items = dpaesQuery?.dpaes?.map(dpae => ({
    key: dpae.uuid || '',
    ...dpae,
  }));

  const onSortedChange = React.useCallback(
    (sortedBy: string, order: 'asc' | 'desc'): void => {
      //@ts-ignore
      updateDPAEListStore({ sortedBy, order });
    },
    []
  );

  return (
    <>
      <Header tabs={Tabs}>
        <Text variant="h1">Déclaration préalable à l’embauche</Text>
      </Header>
      <Container p="20px 0">
        <Flex justifyContent="space-between" ml={20} mr={20} mb={10}>
          <Box width={3 / 12}>
            <FormLabel>RECHERCHER</FormLabel>
            <Input
              isFullWidth
              type="search"
              onChange={e => {
                //@ts-ignore
                updateDPAEListStore({ searchQuery: e?.target?.value });
              }}
              value={searchQuery}
            />
          </Box>
          <Box width={4 / 12}>
            <FormLabel pl={20}>PÉRIODE</FormLabel>
            <DatePickerRange
              startDate={startDate}
              endDate={endDate}
              isClearable={true}
              setStartDate={value => {
                updateDPAEListStore({ startDate: value });
              }}
              setEndDate={value => {
                updateDPAEListStore({ endDate: value });
              }}
            />
          </Box>
          <Box width={4 / 12}>
            <FormLabel>Chargés de comptes</FormLabel>
            <UserSelect
              placeholder="Tous les Chargés de comptes"
              role={RolesTypes.ACCOUNT_MANAGER}
              onChange={value =>
                updateDPAEListStore({ managerSelected: value })
              }
              value={managerSelected}
            />
          </Box>
          <Box ml={10} mt={10}>
            <Flex
              justifyContent={'center'}
              alignItems={'center'}
              flexWrap="wrap"
              alignContent="center"
              width={1 / 1}
              height={'100%'}
            >
              <Link
                isDisabled={initialSate}
                iconLeft={
                  <RefreshIcon
                    fill={
                      initialSate ? Theme?.colors?.grey?.default : undefined
                    }
                  />
                }
                mt={'5px'}
                onClick={() => (!initialSate ? resetDPAEListStore({}) : false)}
              >
                Réinitialiser
              </Link>
            </Flex>
          </Box>
        </Flex>

        {loading && <SpinnerBox />}
        {!loading && (
          <Table<Itemize<IJoinedDpae>>
            isLoading={isFetching}
            columns={columns(filterParams)}
            items={items}
            sortedBy={sortedBy}
            order={order}
            page={page}
            total={totalCount}
            perPage={totalCount < 20 ? totalCount : 20}
            onSortedChange={onSortedChange}
            onChangePage={page => updateDPAEListStore({ page })}
            opacity={filterParams === 'archived' ? 0.5 : 1}
          />
        )}
      </Container>
    </>
  );
};
