import { IJoinedContractor } from '@freelancelabs/teoreme-commons';
import { Roles as RolesTypes } from '@freelancelabs/inside-commons/dist/lib/models/enum/role.enum';
import { getHumanDate, useContractorFindMany } from '@commons';
import { ALink } from 'components/ALink';
import { Header } from 'components/Header';
import { showAddContractorModal } from 'components/modals/AddContractorModal';
import {
  Button,
  Container,
  Flex,
  Itemize,
  Link,
  SpinnerBox,
  Tab,
  Table,
  TableColumn,
  Text,
  Input,
  Box,
  DatePickerRange,
  Status,
} from 'components/ui';
import { EyeIcon, ValidateIcon, RefreshIcon } from 'components/ui/icons';
import { getFullName, getTradeNameSafe } from '@commons';
import { useSetCrumbs } from 'hooks/breadCrumb';
import React from 'react';
import { useParams } from 'react-router-dom';
import { useDebounce } from 'hooks/useDebounce';
import { transformText } from '@commons';
import { UserSelect } from 'components/selects/UserSelect';
import { add, getTime } from 'date-fns';
import { useContractorsListStore } from 'store';
import { Theme } from 'styles';
type Item = Itemize<IJoinedContractor>;

const columns = (filter: string): TableColumn<Item>[] =>
  [
    {
      key: 'createdAt',
      sortKey: 'createdAt',
      text: 'DATE',
      render: item =>
        item?.[filter === 'pending' ? 'createdAt' : 'updatedAt'] &&
        getHumanDate(
          item?.[filter === 'pending' ? 'createdAt' : 'updatedAt'] as Date
        ),
    },
    {
      key: 'name',
      sortKey: 'lastName',
      text: 'NOM',
      render: item => getFullName(item),
    },
    {
      key: 'scopedData',
      text: 'ÉTABLISSEMENTS FOURNISSEURS',
      render: item =>
        item.scopedData
          ?.filter(sd => !sd.disabled)
          ?.map(sd =>
            transformText(getTradeNameSafe(sd.establishment), 'capitalize')
          )
          .join(', '),
    },
    filter === 'rejected' && {
      key: 'rejectReason',
      text: 'MOTIF',
      render: item => (
        <Status variantColor="error">{item?.rejectReason || 'N/A'}</Status>
      ),
    },
    filter === 'pending' && {
      key: 'contact',
      //sortKey: 'contact',
      text: 'CRÉÉ PAR',
      //@ts-ignore
      render: item =>
        item?.scopedData?.[0]?.contact
          ? getFullName(item?.scopedData?.[0]?.contact)
          : 'N/A',
    },
    filter === 'pending' && {
      key: 'accountManager',
      //sortKey: 'accountManager',
      text: 'SUIVI PAR',
      render: item =>
        item?.accountManager ? getFullName(item?.accountManager) : 'N/A',
    },
    {
      key: 'actions',
      text: 'ACTIONS',
      align: 'flex-end',
      render: item => (
        <Flex alignItems="center">
          {filter !== 'pending' ? (
            <Box width={1 / 2}>
              <ALink
                href={`/providers/contractors/${item.uuid}`}
                data-cy="contractor-details-link"
              >
                <Link iconLeft={<EyeIcon />}></Link>
              </ALink>
            </Box>
          ) : (
            <ALink href={`/providers/contractors/validate/${item.uuid}`}>
              <Link mr={10} iconLeft={<ValidateIcon />}>
                Valider
              </Link>
            </ALink>
          )}
        </Flex>
      ),
    },
  ] as TableColumn<Item>[];

const tabItems = (
  <>
    <Tab variant="secondary" href={'/providers/contractors/pending'}>
      A valider
    </Tab>
    <Tab variant="secondary" href={'/providers/contractors/active'}>
      Actifs
    </Tab>
    <Tab variant="secondary" href={'/providers/contractors/archived'}>
      Archivés
    </Tab>
    <Tab variant="secondary" href={'/providers/contractors/rejected'}>
      Refusés
    </Tab>
  </>
);

export const ProviderContractorList = () => {
  const { filter: filterParams } = useParams<{ filter: string }>();
  useSetCrumbs(
    [filterParams],
    [
      {
        label: 'fournisseurs',
        path: '/providers',
      },
      {
        label: 'intervenants',
        path: '/providers/contractors',
      },
      {
        label:
          filterParams === 'active'
            ? 'actifs'
            : filterParams === 'pending'
              ? 'En attente de validation'
              : 'archivés',
        path: '/providers/contractors/' + filterParams,
      },
    ]
  );
  const {
    initialSate,
    order,
    sortedBy,
    searchQuery,
    startDate,
    endDate,
    managerSelected,
    page,
    updateContractorsListStore,
    resetContractorsListStore,
  } = useContractorsListStore();
  const sortString = (order === 'desc' ? '-' : '') + sortedBy;
  const debouncedFilterQuery = useDebounce(searchQuery, 500);

  const onSortedChange = async (
    sortedBy: string,
    order: 'desc' | 'asc'
  ): Promise<void> => {
    //@ts-ignore
    updateContractorsListStore({ sortedBy, order });
  };

  const {
    data: contractorsQuery,
    status,
    isFetching,
  } = useContractorFindMany({
    filterObject: {
      status: filterParams.toUpperCase(),
      accountManager:
        filterParams === 'pending' && managerSelected !== null
          ? managerSelected
          : undefined,
      $or: [
        { firstName: { $regex: debouncedFilterQuery, $options: 'i' } },
        { lastName: { $regex: debouncedFilterQuery, $options: 'i' } },
        {
          'scopedData.establishment.businessName': {
            $regex: debouncedFilterQuery,
            $options: 'i',
          },
        },
        debouncedFilterQuery && debouncedFilterQuery.length > 0
          ? {
              'user.email': {
                $regex: debouncedFilterQuery?.replace('+', '\\+').trim(),
                $options: 'i',
              },
            }
          : { 'user.email': undefined },
      ],
      $and:
        startDate && endDate
          ? [
              {
                $expr: {
                  $gte: [
                    {
                      $toLong: `$${[
                        filterParams === 'pending' ? 'createdAt' : 'updatedAt',
                      ]}`,
                    },
                    getTime(startDate),
                  ],
                },
              },
              {
                $expr: {
                  $lte: [
                    { $toLong: `$createdAt` },
                    getTime(add(endDate, { months: 1 })),
                  ],
                },
              },
            ]
          : undefined,
    },
    sort: sortString,
    limit: 20,
    skip: page * 20,
  });
  const loading = status === 'pending';
  const totalCount = contractorsQuery?.totalCount;

  const items =
    contractorsQuery?.contractors.map(contractor => ({
      ...contractor,
      key: contractor.uuid || '',
    })) || [];

  const onAddContractor = () => {
    showAddContractorModal();
  };
  return (
    <>
      <Header
        tabs={tabItems}
        actions={
          <Button onClick={onAddContractor}>Ajouter un intervenant</Button>
        }
      >
        <Text variant="h1">Intervenants</Text>
      </Header>
      <Container pt={20}>
        <>
          <Flex mb={20}>
            <Box width={1 / 3} pr={10}>
              <Input
                data-cy="search-contractor-list"
                isFullWidth
                value={searchQuery}
                placeholder="Rechercher"
                onChange={e =>
                  updateContractorsListStore({
                    searchQuery: e.currentTarget.value,
                  })
                }
              />
            </Box>
            <Box width={1 / 3} pr={10}>
              <DatePickerRange
                startDate={startDate}
                endDate={endDate}
                isClearable={true}
                setStartDate={value => {
                  updateContractorsListStore({ startDate: value });
                }}
                setEndDate={value => {
                  updateContractorsListStore({ endDate: value });
                }}
              />
            </Box>
            {filterParams === 'pending' && (
              <Box width={1 / 3} pr={10}>
                <UserSelect
                  placeholder="Tous les Chargés de comptes"
                  role={RolesTypes.ACCOUNT_MANAGER}
                  onChange={value =>
                    updateContractorsListStore({ managerSelected: value })
                  }
                  value={managerSelected}
                />
              </Box>
            )}
            <Box ml={10}>
              <Flex
                justifyContent={'center'}
                alignItems={'center'}
                flexWrap="wrap"
                alignContent="center"
                width={1 / 1}
                height={'100%'}
              >
                <Link
                  isDisabled={initialSate as boolean}
                  iconLeft={
                    <RefreshIcon
                      fill={
                        initialSate ? Theme?.colors?.grey?.default : undefined
                      }
                    />
                  }
                  mt={'5px'}
                  onClick={() =>
                    !initialSate ? resetContractorsListStore({}) : false
                  }
                >
                  Réinitialiser
                </Link>
              </Flex>
            </Box>
          </Flex>
          {loading && <SpinnerBox />}
          {!loading && (
            <Table<Item>
              isLoading={isFetching}
              columns={columns(filterParams)}
              items={items}
              sortedBy={sortedBy}
              order={order}
              page={page}
              total={totalCount}
              perPage={totalCount && totalCount < 20 ? totalCount : 20}
              onSortedChange={onSortedChange}
              onChangePage={page => updateContractorsListStore({ page })}
            />
          )}
        </>
      </Container>
    </>
  );
};
