import { useInvoiceUpdateOne } from '@commons';
import { IJoinedInvoice, EInvoiceStatus } from '@freelancelabs/teoreme-commons';
import {
  Text,
  Box,
  Button,
  FormControl,
  TextAreaControlled,
} from 'components/ui';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { create } from 'react-modal-promise';
import { ModalFrame, ModalProps } from '../ModalFrame';
import { queryClient } from '@commons';
type Props = ModalProps & {
  beforeValidation?: () => void;
  invoice?: IJoinedInvoice;
};
type FormValues = any;
export const RejectInvoiceModal = ({ onResolve, isOpen, invoice }: Props) => {
  const { mutateAsync: updateInvoice } = useInvoiceUpdateOne();
  const [isLoading, setIsLoading] = useState(false);
  const {
    handleSubmit,
    control,
    formState: { errors, isDirty },
    getValues,
  } = useForm<FormValues>({
    defaultValues: {
      rejectReason: '',
    },
  });
  const onSubmit = async () => {
    setIsLoading(true);
    const formValues = getValues();
    try {
      await updateInvoice({
        uuids: [(invoice as IJoinedInvoice).uuid],
        invoice: {
          status: EInvoiceStatus.REJECTED,
          rejectReason: formValues.rejectReason,
        },
      });
      queryClient.refetchQueries({ queryKey: ['Invoices'], type: 'active' });
      queryClient.refetchQueries({
        queryKey: [(invoice as IJoinedInvoice).uuid],
      });
      onResolve(true);
    } catch (err) {
      //console.log(err);
    }
    setIsLoading(false);
    onResolve(false);
  };

  return (
    <ModalFrame
      isOpen={true}
      onClose={() => onResolve(false)}
      width={550}
      closeIcon
    >
      <Box>
        <Text variant="h1" mb={20}>
          Refuser la facture
        </Text>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormControl
            required
            label="Description du Motif"
            errorMessage={errors?.rejectReason?.message}
          >
            <TextAreaControlled
              control={control}
              name="rejectReason"
              minRows={3}
            />
          </FormControl>
          <Button
            type="button"
            id="rejectInvoice"
            isDisabled={!isDirty}
            isLoading={isLoading}
            onClick={onSubmit}
          >
            Valider
          </Button>
        </form>
      </Box>
    </ModalFrame>
  );
};

export const showRejectInvoiceModal = create<Props, boolean>(
  RejectInvoiceModal
);
