import {
  getEstablishmentName,
  CONTRACTOR_SOCIAL_STATUS,
  getTradeNameSafe,
  DISPLAY_SF_STATUS,
} from '@commons';
import { useContractorFindOne } from '@commons';
import { EstablishmentStructuredCard } from 'components/cards/EstablishmentStructuredCard';
import { UserStructuredCard } from 'components/cards/UserStructuredCard';
import { DpaeEditor } from 'components/DpaeEditor';
import {
  Status,
  BlocInformation,
  Box,
  FormLabel,
  Link,
  Text,
} from 'components/ui';
import { EditIcon } from 'components/ui/icons';
import { getFullName } from '@commons';
import { useSetCrumbs } from 'hooks/breadCrumb';
import React from 'react';
import { useParams } from 'react-router-dom';
import { Section } from 'components/Section';
import { showUpdateContractorScopedDataModal } from 'components/modals/UpdateContractorScopedDataModal';
import { showUpdateProviderContactModal } from 'components/modals/contractors/UpdateProviderContactModal';

export const ProvidersContractorsEstablishment = () => {
  const { userUuid, establishmentUuid } = useParams<{
    userUuid: string;
    establishmentUuid: string;
  }>();

  /** Get contractor and derived data */
  const { data: contractor } = useContractorFindOne(userUuid);
  const scopedData = contractor?.scopedData?.find(
    sd => sd.establishment.uuid === establishmentUuid
  );
  const isDisabled = scopedData?.disabled;
  const establishment = scopedData?.establishment;

  useSetCrumbs(
    [contractor, establishment],
    [
      {
        label: 'fournisseurs',
        path: '/providers',
      },
      {
        label: 'intervenants',
        path: '/providers/contractors',
      },
      {
        label: (contractor && getFullName(contractor)) || '',
        path: '/providers/contractors/' + contractor?.uuid,
      },
      {
        label: establishment ? getEstablishmentName(establishment) : ' - ',
        path:
          '/providers/contractors/' +
          contractor?.uuid +
          '/establishments/' +
          establishment?.uuid,
      },
    ]
  );
  const salesForce = scopedData?.salesforce;
  if (!contractor || !establishment) return null;
  return (
    <Box key={establishment.uuid}>
      {DISPLAY_SF_STATUS && (
        <Status
          ml={20}
          mt={20}
          mb={20}
          variantColor={!salesForce ? 'grey' : 'success'}
        >
          {DISPLAY_SF_STATUS
            ? !salesForce
              ? `L'intervenant lié à ${getTradeNameSafe(
                  establishment
                )} n'est pas créé dans Salesforce`
              : `L'intervenant lié à ${getTradeNameSafe(
                  establishment
                )} est créé dans SalesForce`
            : ''}
        </Status>
      )}
      <EstablishmentStructuredCard
        forRole={'PROVIDER'}
        checkSage
        establishment={establishment}
        mb={20}
      />

      <Box mt={60}></Box>
      <Section
        variant="h2"
        title={
          <>
            Contact fournisseur{' '}
            {!isDisabled && (
              <Link
                ml={10}
                iconLeft={<EditIcon />}
                mt={10}
                onClick={() =>
                  showUpdateProviderContactModal({
                    scopedData: scopedData,
                    contractor: contractor,
                  })
                }
              >
                Modifier
              </Link>
            )}
          </>
        }
      >
        {scopedData?.contact && (
          <>
            <FormLabel>Fournisseur</FormLabel>
            <UserStructuredCard
              isFullWidth
              user={scopedData.contact}
              checkSage
              establishment={establishment}
              forRole={'PROVIDER'}
            />
          </>
        )}
      </Section>

      <Box mt={60}></Box>
      <Section
        variant="h2"
        title={
          <>
            Contact Intervenant
            {!isDisabled && (
              <Link
                ml={10}
                iconLeft={<EditIcon />}
                mt={10}
                onClick={() =>
                  showUpdateContractorScopedDataModal({
                    scopedData: scopedData,
                    contractor: contractor,
                  })
                }
              >
                Modifier
              </Link>
            )}
          </>
        }
      >
        <FormLabel>Intervenant</FormLabel>
        <Text>
          {(contractor?.civility as any) === '2' ? 'Mme.' : 'M.'} &nbsp;
          {contractor?.lastName}&nbsp;
          {contractor?.firstName}&nbsp;
        </Text>
        <Text>
          Fonction liée à l'établissement : {scopedData?.jobTitle || '-'}
        </Text>
        <Text>
          Téléphone liée à l'établissement : {scopedData?.phone || '-'}
        </Text>
        <Text>Email secondaire : {scopedData?.email || '-'}</Text>
        <Text>
          {`Statut social de l'intervenant au sein de l'établissement fournisseur : ${
            scopedData && CONTRACTOR_SOCIAL_STATUS[scopedData?.socialStatus]
          }`}
        </Text>
        <Box mt={10}>
          <BlocInformation>
            <p>{`Si besoin, précisez un second email de l'intervenant pour l'établissement fournisseur renseigné.`}</p>
          </BlocInformation>
        </Box>
      </Section>

      <Box mt={60}></Box>
      <Section variant="h2" title="Déclaration préalable à l'embauche">
        {scopedData && (
          <>
            <Box mb={10}>
              <BlocInformation>
                <p>
                  {`Rappel : l’intervenant dispose d’une seule et même DPAE par entreprise fournisseur.
                    Le statut social et la DPAE associée pour un intervenant doivent donc se mettre à jour simultanément pour l’ensemble des établissements appartenant à la même entreprise fournisseur.`}
                </p>
              </BlocInformation>
            </Box>
            <DpaeEditor
              isDisabled={isDisabled}
              scopedData={scopedData}
              contractor={contractor}
            />
          </>
        )}
      </Section>
    </Box>
  );
};
