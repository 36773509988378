const colors = {
  white: '#FFFFFF',
  blue: '#00ABEF',
  darkBlue: '#004269',
  lightBlue: '#B4C6D3',
  grayBlue: '#819CAD',
  red: '#F24750',
  green: '#1BC773',
  lightGray: '#FAF9F9',
  paleGray: '#F4F8FA',
  lightRed: '#FDEEEF',
  superLightBlue: '#EEF6FA',
  inProgress: '#FF8C4C',

  primary: {
    lighter: '#eef6fa',
    light: '#819CAD',
    default: '#00ABEF',
    dark: '#004269',
  },

  error: {
    default: '#f24750',
    dark: '#f24750',
    light: '#fdeeef',
    lighter: '#fdeeef',
  },

  warning: { default: '#FF8C4C' },
  success: { default: '#1BC773' },
  grey: {
    default: '#819CAD',
  },
};

const fontSize = {
  small: 11,
  regular: 14,
  xlarge: 20,
};

const padding = {
  regular: 20,
};

export const Theme = {
  colors,
  fontSize,
  padding,

  innerSize: 1170,

  background: colors.white,
  text: colors.darkBlue,
  success: colors.green,
  danger: colors.red,
};

export default Theme;
