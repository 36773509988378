import {
  getAdditionalActivitiesTotalAmount,
  getEstablishmentName,
  getFullName,
  getHumanDate,
  queryClient,
  round,
  useAdditionalActivityFindMany,
  useMissionFindOne,
} from '@commons';
import {
  EadditionalActivityStatus,
  EadditionalActivityType,
  EMissionStatus,
  IJoinedAdditionalActivityWithInvoice,
  IJoinedMission,
} from '@freelancelabs/teoreme-commons';
import { Accordion } from 'components/Accordion';
import { Header } from 'components/Header';
import { showCreateOrUpdateExpensesModal } from 'components/modals/activities/CreateOrUpdateExpensesModal';
import {
  Box,
  Button,
  ColorTextBox,
  Container,
  Divider,
  Flex,
  LabelField,
  Link,
  Spinner,
  SpinnerBox,
  Status,
  Text,
} from 'components/ui';
import {
  MileStoneIcon,
  FeesIcon,
  EyeIcon,
  MailIcon,
} from 'components/ui/icons';
import { getStatusData } from 'helpers/string';
import { useSetCrumbs } from 'hooks/breadCrumb';
import * as React from 'react';
import { useParams } from 'react-router-dom';
import { Theme } from 'styles';
import { showCreateOrUpdateMileStoneModal } from 'components/modals/activities/CreateOrUpdateMileStoneModal';
export type FlatRateDetailsProps = {};

export const FlatRateDetails: React.FunctionComponent<
  React.PropsWithChildren<FlatRateDetailsProps>
> = () => {
  const { missionRef } = useParams<{ missionRef: string }>();
  const { data: mission, isFetching: isFetchingMission } =
    useMissionFindOne(missionRef);
  const { data: AdditionalActivities, isFetching: isFetchingAA } =
    useAdditionalActivityFindMany({
      filterObject: {
        'mission.reference': missionRef,
      },
    });
  useSetCrumbs(
    [mission],
    [
      {
        label: 'CRA et factures',
        path: '/orders',
      },
      {
        label: 'Activités',
        path: `/orders/activities/flat-rate/${missionRef}`,
      },
      {
        label: `mission N° ${mission?.displayReference}`,
        path: `/orders/activities/flat-rate/${missionRef}`,
      },
    ]
  );
  const mileStones = AdditionalActivities?.additionalActivities?.filter(
    (aa: any) => aa?.type === EadditionalActivityType.MILESTONE
  );
  const expenses = AdditionalActivities?.additionalActivities?.filter(
    (aa: any) => aa?.type === EadditionalActivityType.EXPENSE
  );
  const milestonesNeedActionCount = mileStones?.filter(
    (m: any) => m?.status === EadditionalActivityStatus.TO_BE_VALIDATED
  )?.length;
  const expensesNeedActionCount = expenses?.filter(
    (expense: any) =>
      expense?.status === EadditionalActivityStatus?.TO_BE_VALIDATED
  )?.length;
  let messagesCount = 0;
  mileStones
    ?.filter(
      (ms: any) => ms?.status === EadditionalActivityStatus?.TO_BE_VALIDATED
    )
    ?.forEach((ms: any) =>
      ms?.providerComment ? (messagesCount = messagesCount + 1) : false
    );
  expenses
    ?.filter(
      (expense: any) =>
        expense?.status === EadditionalActivityStatus?.TO_BE_VALIDATED
    )
    ?.forEach((expense: any) =>
      expense?.providerComment ? (messagesCount = messagesCount + 1) : false
    );

  const MileStoneActions = ({
    milestone,
  }: {
    milestone: IJoinedAdditionalActivityWithInvoice;
  }) => {
    return (
      <Flex justifyContent="flex-start">
        <Link
          onClick={() =>
            showCreateOrUpdateMileStoneModal({
              mission: milestone?.mission as any,
              method: 'UPDATE',
              uuid: milestone?.uuid as string,
              layer: 104,
            })?.then(() =>
              queryClient?.refetchQueries({ queryKey: [missionRef] })
            )
          }
          iconLeft={<EyeIcon />}
        >
          {'Voir le details'}
        </Link>
      </Flex>
    );
  };
  const ExpensesActions = ({
    expense,
  }: {
    expense: IJoinedAdditionalActivityWithInvoice;
  }) => {
    return (
      <Flex justifyContent="flex-start">
        <Link
          onClick={() =>
            showCreateOrUpdateExpensesModal({
              missionRef: expense?.mission?.reference as string,
              method: 'UPDATE',
              uuid: expense?.uuid as string,
              layer: 104,
            })?.then(() =>
              queryClient?.refetchQueries({ queryKey: [missionRef] })
            )
          }
          iconLeft={<EyeIcon />}
        >
          {'Voir le details'}
        </Link>
      </Flex>
    );
  };
  const missionConsumedBudget = mission?.billing?.consumedPurchaseBudget || 0;
  const contractorRate = mission?.billing?.contractorRate || 0;
  const exepensesIsEnabled = mission?.expensesConfig?.isEnabled;
  const expenseBudget = mission?.expensesConfig?.purchaseBudget ?? 0;
  const haveExpenseBudget = exepensesIsEnabled && expenseBudget > 0;
  const expenseConsumedBudget = mission?.expensesConfig?.consumedPurchaseBudget;
  const isOutOffBudget = haveExpenseBudget
    ? (expenseConsumedBudget || 0) >= (expenseBudget || 0)
    : false;

  const stepperPercentMission = (): { percent: string; color: string } => {
    let color = '';
    const percent = (missionConsumedBudget / (contractorRate || 0)) * 100;
    if (percent > 100) {
      color = Theme?.colors?.error?.default;
      return { percent: '100%', color };
    }
    if (percent >= 80) {
      color = Theme?.colors?.warning?.default;
    } else {
      color = Theme?.colors?.primary?.default;
    }
    return { percent: `${round(percent)}%`, color };
  };
  const stepperPercentExpenses = (): { percent: string; color: string } => {
    let color = '';
    const percent = ((expenseConsumedBudget || 0) / (expenseBudget || 0)) * 100;
    if (percent > 100) {
      color = Theme?.colors?.error?.default;
      return { percent: '100%', color };
    }
    if (percent >= 80) {
      color = Theme?.colors?.warning?.default;
    } else {
      color = Theme?.colors?.primary?.default;
    }
    return { percent: `${round(percent)}%`, color };
  };
  const stepperWidthMission = stepperPercentMission()?.percent;
  const colorStepperMission = stepperPercentMission()?.color;
  const stepperWidthExpense = stepperPercentExpenses()?.percent;
  const colorStepperExpense = stepperPercentExpenses()?.color;
  return (
    <Box>
      <Header
        actions={
          <Flex>
            <Button
              isDisabled={
                mission?.status !== EMissionStatus.VALIDATED &&
                mission?.status !== EMissionStatus?.TERMINATED
              }
              ml={10}
              onClick={() =>
                showCreateOrUpdateMileStoneModal({
                  method: 'CREATE',
                  mission: mission as IJoinedMission,
                })
              }
            >
              Créer un jalon
            </Button>
            <Button
              ml={10}
              isDisabled={isOutOffBudget || !exepensesIsEnabled}
              onClick={() =>
                showCreateOrUpdateExpensesModal({
                  method: 'CREATE',
                  missionRef: mission?.reference as string,
                })?.then(() =>
                  queryClient?.refetchQueries({ queryKey: [missionRef] })
                )
              }
            >
              Créer un frais
            </Button>
          </Flex>
        }
      >
        <Flex
          display="inline-flex"
          justifyContent="flex-start"
          flexWrap="wrap"
          alignItems="center"
          p={10}
        >
          <Box width={1 / 1} mt={'5px'}>
            <Text variant="h1">Activités au forfait</Text>
          </Box>

          <Flex width={1 / 1}>
            {milestonesNeedActionCount && milestonesNeedActionCount > 0 ? (
              <Status circleHidden variantColor="warning" mb={'5px'}>
                <Box mr={'5px'}>
                  <MileStoneIcon
                    width={15}
                    height={15}
                    fill={Theme?.colors?.warning?.default}
                  />
                </Box>
                {milestonesNeedActionCount} Jalons à traiter
              </Status>
            ) : (
              <></>
            )}
          </Flex>
          <Flex width={1 / 1}>
            {expensesNeedActionCount && expensesNeedActionCount > 0 ? (
              <Status circleHidden variantColor="warning" mb={'5px'}>
                <Box mr={'5px'}>
                  <FeesIcon
                    width={15}
                    height={15}
                    fill={Theme?.colors?.warning?.default}
                  />
                </Box>
                {expensesNeedActionCount} frais à traiter
              </Status>
            ) : (
              <></>
            )}
          </Flex>
          <Flex width={1 / 1}>
            {messagesCount > 0 ? (
              <Status circleHidden variantColor="warning" mb={'5px'}>
                <Box mr={'5px'} width={15} height={15}>
                  <MailIcon fill={Theme?.colors?.warning?.default} />
                </Box>
                {messagesCount} Message(s) de la part du fournisseur
              </Status>
            ) : (
              <></>
            )}
          </Flex>
        </Flex>
      </Header>
      {!isFetchingMission ? (
        <Container display="flex" pt={50}>
          <Flex
            display="inline-flex"
            justifyContent="flex-start"
            flexWrap="wrap"
            width={1 / 1}
          >
            <Box p={10} width={8 / 12}>
              {mileStones && mileStones?.length > 0 && (
                <Accordion
                  defaultOpen={true}
                  disableOpen={mileStones?.length === 0}
                  openOnTop
                  propsBar={{
                    borderRadius: '8px',
                    backgroundColor: 'white',
                    border: '1px solid #d0d4e3',
                    p: 12,
                  }}
                  childrenTop={
                    <Flex justifyContent="space-between" width={1 / 1}>
                      <Flex alignContent="center" alignItems="center">
                        <MileStoneIcon
                          fontSize={25}
                          fill={Theme?.colors?.primary?.default}
                        />
                        <Text ml={10} variant="h2">
                          Jalons{' '}
                        </Text>
                      </Flex>
                      <Flex alignContent="center" alignItems="center">
                        {isFetchingAA ? (
                          <Spinner />
                        ) : (
                          <Box>
                            <ColorTextBox variantColor="primary" ml={5}>
                              Fournisseur:{' '}
                              {getAdditionalActivitiesTotalAmount(
                                mileStones,
                                EadditionalActivityType?.MILESTONE,
                                'PROVIDER'
                              )}{' '}
                              €
                            </ColorTextBox>
                            <ColorTextBox variantColor="primary" ml={5}>
                              Client:{' '}
                              {getAdditionalActivitiesTotalAmount(
                                mileStones,
                                EadditionalActivityType?.MILESTONE,
                                'CUSTOMER'
                              )}{' '}
                              €
                            </ColorTextBox>
                          </Box>
                        )}
                      </Flex>
                    </Flex>
                  }
                  key={`MILETONES`}
                >
                  {mileStones?.length > 0 && (
                    <Box
                      position="relative"
                      top={-5}
                      p={10}
                      borderLeft="1px solid"
                      borderRight="1px solid"
                      borderBottom="1px solid"
                      borderColor={'#d0d4e3'}
                    >
                      {mileStones?.map((aa: any, index: number) => {
                        return (
                          <Box width={1 / 1}>
                            <Flex
                              width={1 / 1}
                              alignItems="center"
                              alignContent="center"
                            >
                              <Box width={6 / 50}>
                                <LabelField
                                  label="Déclaré le"
                                  value={
                                    <Box mt={'10px'}>
                                      <ColorTextBox variantColor="primary">
                                        {getHumanDate(aa?.createdAt as Date)}
                                      </ColorTextBox>
                                    </Box>
                                  }
                                  underline
                                />
                              </Box>
                              <Box width={10 / 50}>
                                <LabelField
                                  label="Montant HT Fournisseur"
                                  value={
                                    <Box mt={'10px'}>
                                      <ColorTextBox
                                        mr={'5px'}
                                        variantColor="primary"
                                      >
                                        {getAdditionalActivitiesTotalAmount(
                                          [aa],
                                          EadditionalActivityType.MILESTONE,
                                          'PROVIDER'
                                        )}{' '}
                                        €
                                      </ColorTextBox>
                                    </Box>
                                  }
                                  underline
                                />
                              </Box>
                              <Box width={10 / 50}>
                                <LabelField
                                  label="Montant HT Client"
                                  value={
                                    <Box mt={'10px'}>
                                      <ColorTextBox variantColor="primary">
                                        {getAdditionalActivitiesTotalAmount(
                                          [aa],
                                          EadditionalActivityType.MILESTONE,
                                          'CUSTOMER'
                                        )}{' '}
                                        €
                                      </ColorTextBox>
                                    </Box>
                                  }
                                  underline
                                />
                              </Box>
                              <Box width={9 / 50}>
                                <LabelField
                                  label="Statut"
                                  value={
                                    <Box mt={'10px'}>
                                      <ColorTextBox
                                        variantColor={
                                          getStatusData(aa)?.variantColor
                                        }
                                      >
                                        {getStatusData(aa)?.statusText}
                                      </ColorTextBox>
                                    </Box>
                                  }
                                  underline
                                />
                              </Box>
                              <Flex
                                width={19 / 50}
                                flexWrap="wrap"
                                display="inline-flex"
                                justifyContent="flex-end"
                              >
                                <Box width={1 / 1} ml={20}>
                                  <LabelField
                                    label="Actions"
                                    value={<MileStoneActions milestone={aa} />}
                                    underline
                                  />
                                </Box>
                              </Flex>
                            </Flex>
                            {index !== mileStones?.length - 1 && <Divider />}
                          </Box>
                        );
                      })}
                    </Box>
                  )}
                </Accordion>
              )}
              {expenses && expenses?.length > 0 && (
                <Accordion
                  defaultOpen={true}
                  openOnTop
                  propsBar={{
                    borderRadius: '8px',
                    backgroundColor: 'white',
                    border: '1px solid #d0d4e3',
                    p: 12,
                  }}
                  childrenTop={
                    <Flex justifyContent="space-between" width={1 / 1}>
                      <Flex alignContent="center" alignItems="center">
                        <FeesIcon
                          width={'30px'}
                          height={'30px'}
                          fill={Theme?.colors?.primary?.default}
                        />
                        <Text ml={10} variant="h2">
                          Frais
                        </Text>
                      </Flex>
                      <Flex alignContent="center" alignItems="center">
                        {isFetchingAA ? (
                          <Spinner />
                        ) : (
                          <Box>
                            <ColorTextBox variantColor="primary" ml={5}>
                              Fournisseur:{' '}
                              {getAdditionalActivitiesTotalAmount(
                                expenses,
                                EadditionalActivityType?.EXPENSE,
                                'PROVIDER'
                              )}{' '}
                              €
                            </ColorTextBox>
                            <ColorTextBox variantColor="primary" ml={5}>
                              Client:{' '}
                              {getAdditionalActivitiesTotalAmount(
                                expenses,
                                EadditionalActivityType?.EXPENSE,
                                'CUSTOMER',
                                false,
                                expenses[0]?.customerMargin
                              )}{' '}
                              €
                            </ColorTextBox>
                          </Box>
                        )}
                      </Flex>
                    </Flex>
                  }
                  key={`EXEPENSES`}
                >
                  {expenses?.length > 0 && (
                    <Box
                      position="relative"
                      top={-5}
                      p={10}
                      borderLeft="1px solid"
                      borderRight="1px solid"
                      borderBottom="1px solid"
                      borderColor={'#d0d4e3'}
                    >
                      {expenses?.map((aa: any, index: number) => {
                        return (
                          <Box width={1 / 1}>
                            <Flex
                              width={1 / 1}
                              alignItems="center"
                              alignContent="center"
                            >
                              <Box width={6 / 50}>
                                <LabelField
                                  label="Déclaré le"
                                  value={
                                    <Box mt={'10px'}>
                                      <ColorTextBox variantColor="primary">
                                        {getHumanDate(aa?.createdAt as Date)}
                                      </ColorTextBox>
                                    </Box>
                                  }
                                  underline
                                />
                              </Box>
                              <Box width={10 / 50}>
                                <LabelField
                                  label="Montant HT Fournisseur"
                                  value={
                                    <Box mt={'10px'}>
                                      <ColorTextBox
                                        mr={'5px'}
                                        variantColor="primary"
                                      >
                                        {getAdditionalActivitiesTotalAmount(
                                          [aa],
                                          EadditionalActivityType?.EXPENSE,
                                          'PROVIDER'
                                        )}{' '}
                                        €
                                      </ColorTextBox>
                                    </Box>
                                  }
                                  underline
                                />
                              </Box>
                              <Box width={10 / 50}>
                                <LabelField
                                  label="Montant HT Client"
                                  value={
                                    <Box mt={'10px'}>
                                      <ColorTextBox variantColor="primary">
                                        {getAdditionalActivitiesTotalAmount(
                                          [aa],
                                          EadditionalActivityType?.EXPENSE,
                                          'CUSTOMER',
                                          false,
                                          aa?.customerMargin
                                        )}{' '}
                                        €
                                      </ColorTextBox>
                                    </Box>
                                  }
                                  underline
                                />
                              </Box>
                              <Box width={9 / 50}>
                                <LabelField
                                  label="Statut"
                                  value={
                                    <Box mt={'10px'}>
                                      <ColorTextBox
                                        variantColor={
                                          getStatusData(aa)?.variantColor
                                        }
                                      >
                                        {getStatusData(aa)?.statusText}
                                      </ColorTextBox>
                                    </Box>
                                  }
                                  underline
                                />
                              </Box>
                              <Flex
                                width={19 / 50}
                                flexWrap="wrap"
                                display="inline-flex"
                                justifyContent="flex-end"
                              >
                                <Box width={1 / 1} ml={20}>
                                  <LabelField
                                    label="Actions"
                                    value={<ExpensesActions expense={aa} />}
                                    underline
                                  />
                                </Box>
                              </Flex>
                            </Flex>
                            {index !== expenses?.length - 1 && <Divider />}
                          </Box>
                        );
                      })}
                    </Box>
                  )}
                </Accordion>
              )}
            </Box>
            <Box width={4 / 12} mt={10}>
              <Box>
                <Box
                  width={1 / 1}
                  borderRadius={'5px'}
                  backgroundColor="#e5f5ff"
                >
                  <Box p={10}>
                    <LabelField
                      label="Responsable de la mission"
                      value={getFullName(mission?.accountManager)}
                      underline
                      mb={10}
                    />
                    <LabelField
                      label="Référence de la mission"
                      value={mission?.displayReference}
                      linkValue={`/delivery/missions/${mission?.reference}/gescom-create`}
                      underline
                      mb={10}
                    />
                    <LabelField
                      label="Date de mission"
                      value={`${getHumanDate(
                        mission?.startAt as Date
                      )} au ${getHumanDate(mission?.endAt as Date)}`}
                      linkValue={`/delivery/missions/${mission?.reference}/gescom-create`}
                      underline
                      mb={10}
                    />
                    <LabelField
                      label="Client"
                      value={getEstablishmentName(
                        mission?.customer?.establishment,
                        80
                      )}
                      linkValue={`/clients/establishments/${mission?.customer?.establishment?.uuid}/information`}
                      underline
                      mb={10}
                    />
                    <LabelField
                      label="Fournisseur"
                      value={getEstablishmentName(
                        mission?.provider?.establishment,
                        80
                      )}
                      linkValue={`/providers/establishments/${mission?.provider?.establishment?.uuid}/information`}
                      underline
                      mb={10}
                    />
                    <LabelField
                      label="Intervenant"
                      value={getFullName(mission?.provider?.contractor, 80)}
                      linkValue={`/providers/contractors/${mission?.provider?.contractor?.uuid}`}
                      underline
                      mb={10}
                    />
                    <LabelField
                      label="Budget mission consommé"
                      value={
                        <Box mb={20}>
                          <Flex
                            width={1 / 1}
                            zIndex={100}
                            justifyContent="space-around"
                            borderBottom={'12px solid #eaeefc'}
                            borderRadius={'6px'}
                          >
                            <Box
                              position="absolute"
                              left={0}
                              bottom={-12}
                              //@ts-ignore
                              width={stepperWidthMission}
                              borderRadius={'6px'}
                              borderBottom={`12px solid ${colorStepperMission}`}
                            />
                            <Box
                              width={1 / 1}
                              position="absolute"
                              left={0}
                              bottom={-12}
                            >
                              <Text
                                top="2px"
                                textAlign={'center'}
                                color={
                                  Number(
                                    stepperWidthMission?.replace('%', '')
                                  ) > 47
                                    ? 'white'
                                    : 'black'
                                }
                                fontSize={10}
                              >
                                {stepperWidthMission}
                              </Text>
                            </Box>
                          </Flex>
                          <Text mt={20} width={1 / 1}>
                            {`Le fournisseur à consommé ${round(
                              missionConsumedBudget
                            )} € sur ${contractorRate} € prévus sur cette mission.`}
                          </Text>
                        </Box>
                      }
                      underline
                      mb={-10}
                    />
                    {haveExpenseBudget && (
                      <LabelField
                        label="Budget des frais consommé"
                        value={
                          <Box mb={20}>
                            <Flex
                              width={1 / 1}
                              zIndex={100}
                              justifyContent="space-around"
                              borderBottom={'12px solid #eaeefc'}
                              borderRadius={'6px'}
                            >
                              <Box
                                position="absolute"
                                left={0}
                                bottom={-12}
                                //@ts-ignore
                                width={stepperWidthExpense}
                                borderRadius={'6px'}
                                borderBottom={`12px solid ${colorStepperExpense}`}
                              />
                              <Box
                                width={1 / 1}
                                position="absolute"
                                left={0}
                                bottom={-12}
                              >
                                <Text
                                  top="2px"
                                  textAlign={'center'}
                                  color={
                                    Number(
                                      stepperWidthExpense?.replace('%', '')
                                    ) > 47
                                      ? 'white'
                                      : 'black'
                                  }
                                  fontSize={10}
                                >
                                  {stepperWidthExpense}
                                </Text>
                              </Box>
                            </Flex>
                            <Text mt={20} width={1 / 1}>
                              {`Le fournisseur à consommé ${round(
                                expenseConsumedBudget || 0
                              )} € de frais sur ${round(
                                expenseBudget || 0
                              )} € prévus sur cette mission.`}
                            </Text>
                          </Box>
                        }
                        underline
                        mb={10}
                      />
                    )}
                  </Box>
                </Box>
              </Box>
            </Box>
          </Flex>
        </Container>
      ) : (
        <SpinnerBox />
      )}
    </Box>
  );
};
