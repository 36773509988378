import { Header } from 'components/Header';
import {
  ALink,
  Link,
  Box,
  Container,
  Text,
  Itemize,
  Input,
  Flex,
  FormLabel,
  DatePickerRange,
  Separator,
} from 'components/ui';
import TablePagination from 'components/ui/Table/components/TablePagination';
import { EyeIcon } from 'components/ui/icons';
import { add, getTime } from 'date-fns';
import { useDebounce } from 'hooks/useDebounce';
import * as React from 'react';
import { ITracer } from '@freelancelabs/teoreme-commons';
import {
  useTraceFindMany,
  formatDateAndTime,
  getCivilityAllKeys,
} from '@commons';
import { useTranslationFormat } from 'hooks/useTranslateFormat';
import { useTheme } from 'styled-components';

export type TraceListProps = {};
type Item = Itemize<ITracer>;

export const TracesList: React.FunctionComponent<
  React.PropsWithChildren<TraceListProps>
> = () => {
  const t = useTranslationFormat();
  const Theme = useTheme();
  const [page, setPage] = React.useState(0);
  const [startDate, setStartDate] = React.useState<Date | null>(null);
  const [endDate, setEndDate] = React.useState<Date | null>(null);
  const [searchQuery, setSearchQuery] = React.useState<string | undefined>();

  const debouncedFilterQuery = useDebounce(searchQuery, 500);
  React.useEffect(() => {
    setPage(0);
  }, [debouncedFilterQuery, startDate, endDate]);
  const { data: traces } = useTraceFindMany({
    filterObject: {
      $or: debouncedFilterQuery
        ? [
            {
              title: {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
            {
              description: {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
          ]
        : undefined,
      $and:
        startDate && endDate
          ? [
              {
                $expr: {
                  $gte: [{ $toLong: '$updatedAt' }, getTime(startDate)],
                },
              },
              {
                $expr: {
                  $lte: [
                    { $toLong: '$updatedAt' },
                    getTime(add(endDate, { months: 1 })),
                  ],
                },
              },
            ]
          : undefined,
    },
    sort: '-createdAt',
    limit: 10,
    skip: 10 * page,
  });
  const totalCount = traces?.totalCount || 0;
  const totalPages = Math.ceil(totalCount / 10);
  const items: Item[] =
    //@ts-ignore
    traces?.traces.map((trace: ITracer) => ({
      ...trace,
      key: trace.uuid || '',
    })) || [];

  return (
    <Box>
      <Header>
        <Text variant="h1">Historique</Text>
      </Header>
      <Container paddingTop={4}>
        <Flex
          justifyContent="flex-start"
          ml={20}
          mr={20}
          mb={20}
          padding={'10px'}
        >
          <Box width={3 / 12}>
            <FormLabel>RECHERCHER</FormLabel>
            <Input
              isFullWidth
              type="search"
              onChange={e => {
                //@ts-ignore
                setSearchQuery(e.target.value);
              }}
            />
          </Box>
          <Box width={6 / 12}>
            <FormLabel pl={20}>PÉRIODE</FormLabel>
            <DatePickerRange
              startDate={startDate}
              endDate={endDate}
              isClearable={true}
              setStartDate={value => {
                setStartDate(value as Date);
                setPage(0);
              }}
              setEndDate={value => {
                setEndDate(value as Date);
                setPage(0);
              }}
            />
          </Box>
        </Flex>
        <Box>
          {items?.length === 0 ? (
            <Text ml={2} color={'primary.light'}>
              Aucune action enregistrée
            </Text>
          ) : (
            <Flex display="inline-flex" flexWrap={'wrap'} width={1 / 1}>
              {items?.map(trace => (
                <Flex
                  display="inline-flex"
                  justifyContent="flex-start"
                  flexWrap={'wrap'}
                  width={1 / 1}
                >
                  <Flex
                    display="inline-flex"
                    justifyContent="flex-start"
                    flexWrap={'wrap'}
                    width={1 / 1}
                  >
                    <Box width={4 / 5} mb={20}>
                      <Text fontWeight="bold" maxWidth={'100%'}>
                        {
                          t(`traces:${trace.reference}`, false, false, {
                            ...getCivilityAllKeys(
                              trace.parameters,
                              trace?.reference
                            ),
                          }).split('--')[0]
                        }
                      </Text>
                    </Box>
                    <Box width={1 / 5} textAlign={'end'} pr={20}>
                      <ALink
                        mt={'-5px'}
                        style={{ marginLeft: 'auto' }}
                        href={t(
                          `linkTraces:${trace?.reference}`,
                          false,
                          false,
                          trace?.parameters
                        )}
                      >
                        <Link iconLeft={<EyeIcon />}>Voir</Link>
                      </ALink>
                    </Box>
                    <Box mb={20} width={4 / 5}>
                      {t(`traces:${trace.reference}`, false, false, {
                        ...getCivilityAllKeys(
                          trace.parameters,
                          trace?.reference
                        ),
                      })
                        .split('--')[1]
                        ?.replaceAll('&amp;', '&')
                        ?.replaceAll('&quot;', '"')
                        ?.replaceAll('&#x2F;', '/')
                        ?.split('\n')
                        ?.map(text => (
                          <>
                            {text}
                            <br />
                          </>
                        ))}
                    </Box>
                    <Box width={1 / 1}>
                      <Text color={Theme.colors.lightBlue}>
                        {formatDateAndTime(trace.createdAt || '')}
                      </Text>
                    </Box>
                  </Flex>
                  <Separator />
                </Flex>
              ))}
            </Flex>
          )}
          {items.length > 0 && totalPages > 1 && (
            <TablePagination
              total={totalCount}
              page={page}
              perPage={10}
              onChangePage={page => {
                setPage(page);
              }}
            />
          )}
        </Box>
      </Container>
    </Box>
  );
};
