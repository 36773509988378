import { checkFormEmail, ERoles } from '@commons';
import { checkUserHasRole, checkPathPermission } from '@commons';
import { useLogin } from '@commons';
import { useShowMessage } from 'hooks/useShowMessage';
import { ReactComponent as Welcome } from 'assets/welcome.svg';
import { showForgetPasswordModal } from 'components/modals/ForgetPasswordModal';
import {
  Box,
  Button,
  Flex,
  FormControl,
  Input,
  Link,
  Text,
} from 'components/ui';
import { useBreadCrumb } from 'hooks/breadCrumb';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { cleanSession } from '@commons';
import { useRouterStore } from 'store';
import { ConnecteedRoles, FlatUser } from '@freelancelabs/teoreme-commons';
import { useCleanStores } from 'hooks/cleanStores';
type FormValues = {
  email: string;
  password: string;
};

export const LoginPage = () => {
  const { resetAllStore } = useCleanStores();
  const history = useHistory();
  const showMessage = useShowMessage();
  const { setCrumbs } = useBreadCrumb();
  // use this after login on redirect on targetLocation
  const { targetlocation, resetRouteStoreSates } = useRouterStore();
  setCrumbs([
    {
      label: `Connexion`,
      path: '/login',
    },
  ]);
  /**
   * useForm returns an object with multiple tools
   * */
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>();

  /**
   * useLogin is a thin abstration over react-query useMutation. It's an array where first
   * element is the function to provoque the mutation, and second is an object of statefull infos,
   * like error, data returned, etc...
   */
  const { mutateAsync: login, status } = useLogin();
  const loading = status === 'pending';
  /**
   * react-hook-form handleSubmit manages the form handling - this just gets the form data
   * and performs actions on it.
   * This will NOT be called if there is error in form handling.
   */
  const redirectOnTarget = (defaultPathname: string, user?: FlatUser) => {
    if (targetlocation) {
      if (checkPathPermission(user, targetlocation)) {
        resetRouteStoreSates({});

        history?.push(targetlocation);
      } else {
        resetRouteStoreSates({});
        history?.push(defaultPathname);
      }
    } else {
      history?.push(defaultPathname);
    }
  };
  const onSubmit = async (data: FormValues) => {
    try {
      const loginResponse = await login(data);
      const user = loginResponse?.user;
      //Reset stores and set new store with new user logged
      resetAllStore(
        checkUserHasRole(loginResponse?.user, ERoles.ACCOUNT_MANAGER)
          ? user?.cognitoUserId
          : undefined
      );
      if (
        checkUserHasRole(loginResponse?.user, ERoles.ACCOUNT_MANAGER) ||
        checkUserHasRole(loginResponse?.user, ERoles.LEGAL_SQUAD) ||
        checkUserHasRole(loginResponse?.user, ERoles.ADMIN) ||
        checkUserHasRole(loginResponse?.user, 'SUPPORT') ||
        checkUserHasRole(loginResponse?.user, 'MANAGER_READ_ONLY') ||
        checkUserHasRole(loginResponse?.user, 'PORTAL_CLIENT_ADMIN')
      ) {
        if (
          checkUserHasRole(loginResponse?.user, ERoles.ACCOUNT_MANAGER) ||
          checkUserHasRole(loginResponse?.user, 'MANAGER_READ_ONLY')
        ) {
          redirectOnTarget('/dashboard', user);
          //history.push('/dashboard');
          return;
        }
        if (checkUserHasRole(loginResponse?.user, 'SUPPORT')) {
          redirectOnTarget('/support/documents/provider', user);
          //history.push('/support/documents/provider');
          return;
        }
        if (checkUserHasRole(loginResponse?.user, ERoles.LEGAL_SQUAD)) {
          redirectOnTarget('/legals/contracts/historical', user);
          //history.push('/legals/contracts/historical');
          return;
        }
        if (checkUserHasRole(loginResponse?.user, ERoles.ADMIN)) {
          redirectOnTarget('/admin/users', user);
          //history.push('/admin/users');
          return;
        }
        if (checkUserHasRole(loginResponse?.user, 'PORTAL_CLIENT_ADMIN')) {
          redirectOnTarget('/clients/contacts', user);
          //history.push('/admin/users');
          return;
        }
      } else {
        showMessage('error', "Vous n'avez pas accès à cet espace");
        cleanSession();
      }
    } catch (e) {
      cleanSession();
    }
  };
  return (
    <Flex height={'100vh'} alignItems="center" justifyContent="center">
      <Flex alignItems="center" width="100%" maxWidth={1000}>
        <Box flex={1}>
          <Text variant="h1" fontSize={32} mb={50}>
            Content de vous revoir
          </Text>
          {/* Pass react-hook-form's handleSubmit method preped with your submit method */}
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormControl
              required
              label="Email"
              errorMessage={errors?.email?.message}
            >
              <Input
                width={'85%'}
                type="email"
                id="email"
                {...register('email', {
                  required: 'Ce champ est obligatoire',
                  validate: value => checkFormEmail(value as string),
                })}
              />
            </FormControl>
            <FormControl
              required
              label="Mot de passe"
              errorMessage={errors?.password?.message}
            >
              <Input
                width={'85%'}
                type={'password'}
                id="password"
                {...register('password')}
              />
            </FormControl>
            <Button mb={10} type="submit" isLoading={loading}>
              Se connecter
            </Button>
          </form>
          <Link onClick={() => showForgetPasswordModal()}>
            Mot de passe oublié ?
          </Link>
        </Box>
        <Flex alignItems="center" flexDirection="column" flex={1}>
          <Welcome height="250px" width="500px" />
          {false && (
            <Box display="flex" flexDirection="row">
              <Text>Vous avez besoin d'aide ?</Text>
              <Link marginTop={1} marginLeft={2}>
                Contactez-nous
              </Link>
            </Box>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};

export default LoginPage;
