import styled from 'styled-components';
import { boxProperties, BoxProps } from './Box';

export const ContainerStyled = styled.div<BoxProps & { fullSize?: boolean }>`
  max-width: ${props => (props.fullSize ? '100vw' : '1380px')};
  width: 100%;
  margin: 0 auto;
  ${boxProperties}
`;

export const Container = ({ ...props }) => {
  return <ContainerStyled {...props} />;
};
