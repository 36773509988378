import {
  IChangeHistory,
  IChangeTypeActivity,
} from '@freelancelabs/teoreme-commons';
import { cutLongText, getFullName, getHumanDate } from '@commons';
import { getStatusText } from 'helpers';
import { Link, Box, Status, CustomToolTip } from 'components/ui';
import { showDisplayPdfModal } from 'components/modals/DisplayPdfModal';
import { EyeIcon } from 'components/ui/icons';
export const columns = () => [
  {
    key: 'changedAt',
    text: 'DATE',
    render: (item: IChangeHistory) =>
      getHumanDate(item?.changedAt as Date, 'dd/MM/yyyy à HH:mm'),
  },
  {
    key: 'user',
    text: 'UTILISATEUR',
    render: (item: IChangeHistory) =>
      item?.changedBy ? getFullName(item.changedBy as any) : 'N/A',
  },
  {
    key: 'state',
    text: 'STATUT',
    render: (item: IChangeHistory) => {
      const to = item?.to as IChangeTypeActivity;
      if (to?.status) {
        const data = getStatusText(to?.status as any);
        return (
          <Box>
            <Status variantColor={data.variantColor}>{data?.statusText}</Status>
          </Box>
        );
      }
      return 'N/A';
    },
  },
  {
    key: 'comment',
    text: 'COMMENTAIRE',
    render: (item: IChangeHistory) => {
      const to = item?.to as IChangeTypeActivity;
      if (to?.providerComment) {
        return (
          <CustomToolTip id={item?.uuid} text={to.providerComment}>
            {cutLongText(to.providerComment, 20)}
          </CustomToolTip>
        );
      }
      return 'N/A';
    },
  },
  {
    key: 'rejectReason',
    text: 'MOTIF',
    render: (item: IChangeHistory) => {
      const to = item?.to as IChangeTypeActivity;
      if (to?.rejectReason) {
        return (
          <CustomToolTip id={item?.uuid} text={to.rejectReason}>
            {cutLongText(to.rejectReason, 20)}
          </CustomToolTip>
        );
      }
      return 'N/A';
    },
  },
  {
    key: 'proof',
    text: 'JUSTIFICATIF',
    render: (item: IChangeHistory) => {
      const to = item?.to as IChangeTypeActivity;
      if (to?.attachments && to?.attachments?.length > 0) {
        return (
          <ul>
            {to?.attachments?.map((attachment, index) => {
              return (
                <Box style={{ marginTop: '5px' }}>
                  <Link
                    iconLeft={<EyeIcon />}
                    onClick={() =>
                      showDisplayPdfModal({
                        fileLocation: attachment?.fileLocation || '',
                        fileName: `${attachment?.fileName}.pdf`,
                        layer: 200,
                      })
                    }
                  >
                    Voir le fichier {index + 1}
                  </Link>
                </Box>
              );
            })}
          </ul>
        );
      } else {
        return 'N/A';
      }
    },
  },
];
