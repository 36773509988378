import {
  Flex,
  Box,
  FormLabel,
  DatePickerRange,
  Input,
  Link,
  DatePicker,
} from 'components/ui';
import * as React from 'react';
import { useParams } from 'react-router-dom';
import { StaticSelect } from 'components/selects/StaticSelect';
import { useListCraStore } from 'store';
import { RefreshIcon } from 'components/ui/icons';
import { Theme } from 'styles';
import { ContractorSelect } from 'components/selects/ContractorSelect';
import { UserSelect } from 'components/selects/UserSelect';
import { ERoles, CRA_STATUS_SELECT } from '@commons';
import { RadioSelectEstablishments } from 'components/selects/RadioSelectEstablishments';

const row = 1 / 1;
export const CraFiltersList = () => {
  const { filter: filterParams } = useParams<{
    filter: 'all' | 'validated' | 'draft' | 'aborted' | 'terminated';
  }>();
  const {
    initialSate,
    searchQuery,
    startDate,
    endDate,
    managerSelected,
    statusSelect,
    // clientSelected,
    // providerSelected,
    multiProviderSelected,
    multiClientSelected,
    contractorSelected,
    submittedAt,
    validatedAt,
    internalCommercial,
    limit,
    updateListCraStore,
    resetListCraStore,
  } = useListCraStore();

  React.useEffect(() => {
    updateListCraStore({ page: 0, initialSate });
  }, [filterParams, managerSelected]);
  return (
    <Box>
      <Flex display="inline-flex" justifyContent="flex-start" flexWrap={'wrap'}>
        <Box width={row} pr={'5px'} pt={'5px'}>
          <FormLabel>RECHERCHER</FormLabel>
          <Input
            isFullWidth
            type="search"
            onChange={e => {
              //@ts-ignore
              updateListCraStore({ searchQuery: e.target.value });
            }}
            value={searchQuery}
          />
        </Box>
        <Box width={row} pt={'5px'}>
          <FormLabel>Période</FormLabel>
          <DatePickerRange
            startDate={startDate}
            endDate={endDate}
            isClearable={true}
            setStartDate={value => {
              updateListCraStore({ startDate: value, page: 0 });
            }}
            setEndDate={value => {
              updateListCraStore({ endDate: value, page: 0 });
            }}
          />
        </Box>
        <Box width={row} pr={'5px'} pt={'5px'}>
          <FormLabel>Statuts</FormLabel>
          <StaticSelect
            isDisabled={filterParams !== 'all'}
            placeholder="Tous les statuts"
            options={CRA_STATUS_SELECT}
            onChange={value =>
              updateListCraStore({ statusSelect: value as string })
            }
            value={statusSelect}
          />
        </Box>
        <Box width={row} pr={'5px'} pt={'5px'}>
          <FormLabel>Chargés de comptes</FormLabel>
          <UserSelect
            placeholder="Tous les Chargés de comptes"
            role={ERoles.ACCOUNT_MANAGER}
            onChange={value => updateListCraStore({ managerSelected: value })}
            value={managerSelected}
          />
        </Box>
        <Box width={row} pr={'5px'} pt={'5px'}>
          <FormLabel>Commercial</FormLabel>
          <UserSelect
            placeholder="Tous les commerciaux"
            role={ERoles.INTERNAL_COMMERCIAL}
            onChange={value =>
              updateListCraStore({ internalCommercial: value })
            }
            value={internalCommercial}
          />
        </Box>
        <Box width={row} pr={'5px'} pt={'5px'}>
          <FormLabel>Établissement Clients</FormLabel>
          <RadioSelectEstablishments
            filter={{ customer: { $exists: true } }}
            referenceValue="uuid"
            placeholder="Tous les clients"
            onChange={value =>
              updateListCraStore({
                multiClientSelected: value,
              })
            }
            values={multiClientSelected}
          />
        </Box>
        <Box width={row} pr={'5px'} pt={'5px'}>
          <FormLabel>Établissements Fournisseurs</FormLabel>
          <RadioSelectEstablishments
            filter={{ 'provider.manager': { $exists: true } }}
            referenceValue="uuid"
            placeholder="tous les fournisseurs"
            onChange={value =>
              updateListCraStore({
                multiProviderSelected: value,
              })
            }
            values={multiProviderSelected}
          />
        </Box>
        <Box width={row} pr={'5px'} pt={'5px'}>
          <FormLabel>Intervenant</FormLabel>
          <ContractorSelect
            placeholder="Tous les intervenants"
            onChange={value =>
              updateListCraStore({ contractorSelected: value as string })
            }
            value={contractorSelected}
          />
        </Box>
        <Box width={row} pr={'5px'} pt={'5px'}>
          <FormLabel>Date de soumission</FormLabel>
          <DatePicker
            isClearable
            //valueName="selected"
            name="submittedAt"
            value={submittedAt as any}
            onChange={value => updateListCraStore({ submittedAt: value })}
          />
        </Box>
        <Box width={row} pr={'5px'} pt={'5px'}>
          <FormLabel>Date de validation</FormLabel>
          <DatePicker
            isClearable
            //valueName="selected"
            name="validatedAt"
            value={validatedAt as any}
            onChange={value => updateListCraStore({ validatedAt: value })}
          />
        </Box>
        <Box width={row} pr={'5px'} pt={'5px'} mb={10}>
          <FormLabel>NB. LIGNES AFFICHÉES</FormLabel>
          <StaticSelect
            isClearable={false}
            placeholder=" "
            options={[
              {
                label: '20',
                value: 20,
                key: 20,
              },
              {
                label: '50',
                value: 50,
                key: 50,
              },
              {
                label: '100',
                value: 100,
                key: 100,
              },
            ]}
            onChange={value => updateListCraStore({ limit: value as number })}
            value={limit}
          />
        </Box>
        <Box>
          <Flex
            justifyContent={'center'}
            alignItems={'center'}
            flexWrap="wrap"
            alignContent="center"
            width={1 / 1}
            height={'100%'}
          >
            <Link
              isDisabled={initialSate}
              iconLeft={
                <RefreshIcon
                  fill={initialSate ? Theme?.colors?.grey?.default : undefined}
                />
              }
              mt={'5px'}
              onClick={() => (!initialSate ? resetListCraStore({}) : false)}
            >
              Réinitialiser
            </Link>
          </Flex>
        </Box>
      </Flex>
    </Box>
  );
};
