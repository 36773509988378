import {
  ERoles,
  missionFindMany,
  useMe,
  isDisableFONEDate,
  saleforceGetContractData,
  useSaleforceGetContractData,
} from '@commons';
import { isMissionFieldEditable } from 'helpers';
import { Roles as RolesTypes } from '@freelancelabs/inside-commons/dist/lib/models/enum/role.enum';
import {
  EBillingType,
  EMissionProvider,
  EMissionStatus,
  EMissionStructure,
  EMissionType,
  IAddress,
  IJoinedMission,
  IMinifiedUser,
} from '@freelancelabs/teoreme-commons';
import { showAddOtherContactModal } from 'components/modals/AddOtherContactModal';
import { showDialogModal } from 'components/modals/DialogModal';
import { Section } from 'components/Section';
import { UserSelect } from 'components/selects/UserSelect';
import {
  BlocInformation,
  Box,
  CheckBox,
  CheckSwitch,
  CustomToolTip,
  DatePicker,
  DatePickerControlled,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Link,
  Radio,
  Row,
  Text,
  UserSelectControlled,
} from 'components/ui';
import { AddIcon, InfoIcon } from 'components/ui/icons';
import {
  differenceInCalendarMonths,
  isValid,
  subDays,
  addDays,
} from 'date-fns';
import React from 'react';
import { Theme } from 'styles';

type FormValues = {
  startAt?: Date;
  endAt?: Date;
  estimatedEndAt?: Date;
  title?: string;
  location?: IAddress;
  description?: string;
  forRole?: 'ALL' | 'CUSTOMER' | 'PROVIDER';
};
type MissionGescomPartialFormProps = {
  forRole?: 'ALL' | 'CUSTOMER' | 'PROVIDER';
  form: FormValues & any;
  sage?: any;
  mission?: IJoinedMission;
  missionStatus?: EMissionStatus;
  reference?: string;
  setIgnoreNav?: (bool: boolean) => void;
  isDisabled: boolean;
  customerContact?: IMinifiedUser;
};

export const MissionGescomPartialForm: React.FC<
  React.PropsWithChildren<MissionGescomPartialFormProps>
> = ({
  mission,
  isDisabled,
  form: {
    formState: { errors, isDirty, isSubmitting },
    register,
    watch,
    control,
    setValue,
    formState,
    reset,
    clearErrors,
  },
  forRole,
  sage,
  missionStatus,
  reference,
  customerContact,
  setIgnoreNav,
}) => {
  const needCheckSfContract =
    mission &&
    mission?.createdFromSFFlow &&
    mission?.billingInformation?.missionType?.label === EMissionType.CLASSIC
      ? true
      : false;
  const { data: sfContractData } = useSaleforceGetContractData(
    needCheckSfContract === true ? mission?.salesforce?.externalId : undefined,
    { enabled: needCheckSfContract }
  );
  const isMandateMode = watch('isMandateMode');
  const { me } = useMe();
  const [contactSelected, setContactSelected] = React.useState<
    string | undefined
  >(mission?.accountManager?.cognitoUserId || me?.cognitoUserId);
  const endAt = watch('endAt');
  const startAt = watch('startAt');
  const parent = watch('parent');
  const type = watch('billing.type');
  const structure = watch('billingInformation.structure');
  const shouldCreatePastCra = watch('shouldCreatePastCra');
  const missionTypeLabel = watch('billingInformation.missionType.label');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loading, setLoading] = React.useState(false);
  //const [sfMaxEndDate, setSfMaxEndDate] = React.useState<string | undefined>();
  const [checkAmendment, setCheckAmendment] = React.useState(false);
  const [previousAmendment, setPreviousAmendment] = React.useState<any>(false);
  const [nextAmendment, setNextAmendment] = React.useState<any>(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [formDirty, setFormDirty] = React.useState(false);
  const providedBy = watch('billingInformation.missionType.providedBy');
  const contact = watch('billingInformation.missionType.contact');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const modalConfirm = async () => {
    await showDialogModal({
      title: 'Êtes-vous sûr(e) de vouloir modifier ?',
      text: 'Attention : Les informations de la fiche courante seront perdu si vous modifiez , souhaitez vous continuer ?',
      confirmLabel: 'Oui',
      cancelLabel: 'Non',
    }).then(action => {
      if (action) {
        setFormDirty(true);
        setValue('billingInformation.missionType.contact', undefined);
      } else {
        reset({
          billingInformation: mission?.billingInformation,
        });
      }
    });
  };
  // TEOR-4692: Enlever la pop up affichée a chaque changement https://fcomdev.atlassian.net/browse/TEOR-4692
  // React.useEffect(() => {
  //   if (
  //     !isSubmitting &&
  //     mission?.billingInformation?.missionType?.label &&
  //     isDirty &&
  //     !formDirty
  //   ) {
  //     modalConfirm();
  //   }
  // }, [mission, isDirty, formDirty, isSubmitting]);

  //TEOR-5339: new rules   https://fcomdev.atlassian.net/browse/TEOR-5339
  React.useEffect(() => {
    if (!mission || mission?.status === EMissionStatus?.DRAFT) {
      if (isMandateMode === true && structure !== EMissionStructure.FCOM) {
        setValue('isMandateMode', false);
      }
    }
  }, [mission, isMandateMode, structure, missionTypeLabel]);
  const findPreviousAmendment = async () => {
    setLoading(true);
    const previous = await missionFindMany({
      filterObject: {
        parent: mission?.parent?.reference || mission?.reference,
        reference: {
          $ne: mission?.reference,
        },
        status: {
          $in: [
            EMissionStatus?.DRAFT,
            EMissionStatus?.VALIDATED,
            EMissionStatus?.TERMINATED,
            EMissionStatus?.ARCHIVED,
          ],
        },
        endAt: { $lte: mission?.startAt },
      },
      sort: '-startAt',
      limit: 1,
    });
    if (!previous?.missions?.[0] && mission?.parent) {
      const parent = await missionFindMany({
        filterObject: {
          reference: mission?.parent?.reference,
        },
        limit: 1,
      });
      setPreviousAmendment(parent?.missions?.[0]);
    } else {
      setPreviousAmendment(previous?.missions?.[0]);
    }
    setLoading(false);
  };
  const findNextAmendment = async () => {
    setLoading(true);
    const next = await missionFindMany({
      filterObject: {
        parent: mission?.parent?.reference || mission?.reference,
        reference: {
          $ne: mission?.reference,
        },
        status: {
          $in: [
            EMissionStatus?.DRAFT,
            EMissionStatus?.VALIDATED,
            EMissionStatus?.TERMINATED,
            EMissionStatus?.ARCHIVED,
          ],
        },
        startAt: { $gte: mission?.startAt },
      },
      sort: '-startAt',
      limit: 1,
    });

    setNextAmendment(next?.missions?.[0]);
    setLoading(false);
  };
  const checkSFcontractData = async () => {
    try {
      const endDate = sfContractData?.endDate;
      //setSfMaxEndDate(new Date(endDate as Date)?.toISOString());
    } catch (e) {
      //
    }
  };
  React.useEffect(() => {
    // CHECK IF MISSION IS AMENDMENT AND GET LAST AMENDMENT
    //https://fcomdev.atlassian.net/browse/TEOR-5206
    if (mission && !checkAmendment) {
      findPreviousAmendment();
      findNextAmendment();
      setCheckAmendment(true);
    }
  }, [mission, checkAmendment]);
  React.useEffect(() => {
    if (
      mission &&
      mission?.createdFromSFFlow &&
      mission?.billingInformation?.missionType?.label === EMissionType.CLASSIC
    ) {
      checkSFcontractData();
    }
  }, [mission, checkAmendment, sfContractData]);
  const getSmallDate = (date: Date) => {
    // IF MISSION END DATE IS IN PAST INGORE
    if (mission?.status === EMissionStatus?.DRAFT) {
      return date;
    }
    if (new Date(mission?.endAt as Date) > new Date()) {
      const smallDate = new Date(date) < new Date() ? new Date() : date;
      return smallDate;
    }
    return date;
  };
  /**
   * CHECK RANGE OF AMENDMENT DATE !!!
   */
  // console.log('PREVIOUS AMENDMENT', previousAmendment);
  // console.log('current mission startAt', startAt);
  // console.log('current mission endAt', endAt);
  // console.log('NEXT AMENDMENT', nextAmendment);
  let minDateStart: Date | undefined;
  let maxDateStart: Date | undefined;
  let minDateEnd: Date | undefined;
  let maxDateEnd: Date | undefined;
  if (previousAmendment || nextAmendment) {
    // CURRENT MISSION IS FIRST AMENDMENT
    if (!previousAmendment && nextAmendment) {
      maxDateStart = subDays(new Date(nextAmendment?.startAt as Date), 1);
      maxDateEnd = subDays(new Date(nextAmendment?.startAt as Date), 1);
      minDateEnd = mission?.startAt
        ? getSmallDate(new Date(mission?.startAt as Date))
        : undefined;
    }
    // CURRENT MISSION BETWEEN TWO AMENDMENT
    if (previousAmendment && nextAmendment) {
      minDateStart = addDays(new Date(previousAmendment?.endAt as Date), 1);
      maxDateStart = subDays(new Date(nextAmendment?.startAt as Date), 1);
      minDateEnd =
        addDays(getSmallDate(new Date(previousAmendment?.endAt as Date)), 1) ||
        new Date(startAt);
      maxDateEnd = subDays(new Date(nextAmendment?.startAt as Date), 1);
    }
    // CURRENT MISSION IS LAST AMENDMENT
    if (previousAmendment && !nextAmendment) {
      minDateStart = addDays(new Date(previousAmendment?.endAt as Date), 1);
      minDateEnd = mission?.startAt
        ? addDays(getSmallDate(new Date(mission?.startAt as Date)), 1)
        : undefined;
    }
    // !!  DISABLE THIS LINES https://fcomdev.atlassian.net/browse/TEOR-5736 !!
    // // SALEFORCE CHECK MAX DATE https://fcomdev.atlassian.net/browse/TEOR-5206
    // if (maxDateEnd && sfMaxEndDate) {
    //   if (new Date(sfMaxEndDate) <= new Date(maxDateEnd)) {
    //     maxDateEnd = new Date(sfMaxEndDate);
    //   }
    // }
    // if (!maxDateEnd && sfMaxEndDate) {
    //   maxDateEnd = new Date(sfMaxEndDate);
    // }
  } else {
    maxDateStart = endAt ? endAt : undefined;
    minDateEnd = startAt ? getSmallDate(startAt) : undefined;
    // !!  DISABLE THIS LINES https://fcomdev.atlassian.net/browse/TEOR-5736 !!
    // // SALEFORCE CHECK MAX DATE https://fcomdev.atlassian.net/browse/TEOR-5206
    // if (maxDateEnd && sfMaxEndDate) {
    //   if (new Date(sfMaxEndDate) <= new Date(maxDateEnd)) {
    //     maxDateEnd = new Date(sfMaxEndDate);
    //   }
    // }
    // if (!maxDateEnd && sfMaxEndDate) {
    //   maxDateEnd = new Date(sfMaxEndDate);
    // }
  }
  const checkShouldCreatePastCra = (startAt: Date) => {
    if (startAt && isValid(startAt) && isDirty && type === EBillingType.DAY) {
      if (
        differenceInCalendarMonths(startAt, new Date()) < 0 &&
        !shouldCreatePastCra
      ) {
        showDialogModal({
          title: 'Attention',
          text: `Sur la plateforme Connected, les CRA & Factures générés sont liés aux dates de début et fin de mission. La date de début de la mission saisie étant antérieure au mois en cours, les CRA correspondants aux mois antérieurs au mois en cours ne seront pas créés si vous ne cochez pas la case “Générer des CRA rétroactifs”.`,
          confirmLabel: "J'ai bien compris",
        });
      }
    }
  };
  // SF EXPECTION FOR TA !! https://fcomdev.atlassian.net/browse/TEOR-5738
  const displayForm =
    (missionTypeLabel === EMissionType.RESOURCE_MANAGEMENT && !mission) ||
    mission?.reference;
  return (
    <>
      {forRole === 'ALL' &&
        !missionStatus &&
        missionTypeLabel === EMissionType.RESOURCE_MANAGEMENT && (
          <Box mb={20} mt={20}>
            <BlocInformation>
              <p>
                Afin de pouvoir créer votre mission dans GESCOM, merci de
                renseigner les 4 informations suivantes. Une fois créée dans
                GESCOM, vous pourrez continuer de modifier/mettre à jour dans
                GESCOM les champs “intitulé, date de début et date de fin de la
                mission”. En cas d’erreur lors de la création/mise à jour dans
                GESCOM, merci de réessayer.
              </p>
            </BlocInformation>
          </Box>
        )}
      {forRole === 'ALL' && (
        <Box>
          <FormControl
            required
            label={'Quel est le type de cette mission ?'}
            errorMessage={
              errors?.billingInformation?.missionType?.label?.message
            }
          >
            <Box mt={20}>
              <Radio
                data-cy="radio-ressource_management"
                {...register('billingInformation.missionType.label', {
                  required: 'Ce champ est requis',
                })}
                onClick={async () => {
                  clearErrors('billingInformation.missionType.providedBy');
                  setValue('billingInformation.missionType.contact', undefined);
                  setValue(
                    'billingInformation.missionType.providedBy',
                    undefined
                  );
                }}
                value={EMissionType.RESOURCE_MANAGEMENT}
                disabled={
                  // mission?.parent ||
                  // isDisabled ||
                  // mission?.status === EMissionStatus?.VALIDATED ||
                  // mission?.status === EMissionStatus?.TERMINATED
                  !isMissionFieldEditable(
                    mission,
                    'billingInformation.missionType.label'
                  ) || isDisabled
                }
              >
                Ressource management
              </Radio>
              <Radio
                data-cy="radio-classic"
                {...register('billingInformation.missionType.label', {
                  required: 'Ce champ est requis',
                  disabled: isDisabled,
                })}
                onClick={async () => {
                  clearErrors('billingInformation.missionType.providedBy');
                  setValue('billingInformation.missionType.contact', undefined);
                  setValue(
                    'billingInformation.missionType.providedBy',
                    undefined
                  );
                }}
                value={EMissionType.CLASSIC}
                // IF MISSION IS CREATED UPDATE MISSION IS DISABLED !!
                disabled={mission?.reference || isDisabled}
              >
                Talent Acquisition
              </Radio>
            </Box>
          </FormControl>

          {displayForm && (
            <FormControl
              required
              label={
                'Quelle est la structure de facturation de cette mission ?'
              }
              errorMessage={errors?.billingInformation?.structure?.message}
            >
              <Box mt={20}>
                <Radio
                  disabled={
                    !isMissionFieldEditable(
                      mission,
                      'billingInformation.structure'
                    ) || isDisabled
                  }
                  {...register('billingInformation.structure')}
                  value={EMissionStructure.FCOM}
                  data-cy="structure-FCOM"
                >
                  Freelance.com
                </Radio>
                <Radio
                  disabled={
                    !isMissionFieldEditable(
                      mission,
                      'billingInformation.structure'
                    ) ||
                    isDisabled ||
                    isDisableFONEDate()
                  }
                  {...register('billingInformation.structure')}
                  value={EMissionStructure.FONE}
                >
                  {'Freelance.one' && isDisableFONEDate()
                    ? 'Freelance.one (structure désactivée)'
                    : 'Freelance.one'}
                </Radio>
                <Radio
                  disabled={
                    !isMissionFieldEditable(
                      mission,
                      'billingInformation.structure'
                    ) || isDisabled
                  }
                  {...register('billingInformation.structure')}
                  value={EMissionStructure.INOPS}
                >
                  Inop's
                </Radio>
                <Radio
                  disabled={
                    !isMissionFieldEditable(
                      mission,
                      'billingInformation.structure'
                    ) || isDisabled
                  }
                  {...register('billingInformation.structure')}
                  value={EMissionStructure.COWORKEES}
                >
                  Coworkees
                </Radio>
              </Box>
            </FormControl>
          )}
          {!missionStatus && displayForm && (
            <Box mt={-20}>
              <BlocInformation>
                Attention, une fois la mission créée, cette structure ne pourra
                plus être modifiée.
              </BlocInformation>
            </Box>
          )}
        </Box>
      )}
      {forRole === 'ALL' && displayForm && (
        <Box>
          <FormLabel>Type de tarification</FormLabel>
          <Radio
            isDisabled={
              !isMissionFieldEditable(mission, 'billing.type') || isDisabled
            }
            {...register('billing.type', { required: 'Ce champ est requis' })}
            value={EBillingType.DAY}
          >
            Temps passé
          </Radio>
          <Radio
            isDisabled={
              !isMissionFieldEditable(mission, 'billing.type') || isDisabled
            }
            {...register('billing.type', { required: 'Ce champ est requis' })}
            value={EBillingType.FLAT_RATE}
          >
            Forfait
          </Radio>
        </Box>
      )}
      {forRole === 'ALL' && displayForm && (
        <Box mt={20}>
          <FormControl label="Prestation sous mandat de gestion">
            <CustomToolTip
              color={Theme.colors.warning.default}
              text={
                "Ce toggle doit être activé uniquement si'il s'agit d'une prestation gérée dans le cadre du process mandat de gestion l’Oréal sur la structure FCOM."
              }
            >
              <CheckSwitch
                id="isMandateMode"
                {...register('isMandateMode')}
                isDisabled={
                  !isMissionFieldEditable(mission, 'isMandateMode') ||
                  isDisabled
                }
              >
                <Text variant="p">
                  {isMandateMode
                    ? 'Prestation gérée sous mandat de gestion pour l’Oréal'
                    : 'Prestation classique (hors process mandat de gestion l’Oréal)'}
                </Text>
              </CheckSwitch>
            </CustomToolTip>
          </FormControl>
        </Box>
      )}
      {forRole === 'ALL' && displayForm && type === EBillingType.DAY && (
        <FormControl label="Génération des CRA" width={'240px'}>
          <Flex alignItems={'center'}>
            <CheckBox
              isDisabled={missionStatus === 'VALIDATED' || isDisabled}
              {...register('shouldCreatePastCra')}
              name="shouldCreatePastCra"
              id={'shouldCreatePastCra'}
              key="shouldCreatePastCra"
            ></CheckBox>
            {
              <Flex justifyContent="flex-start" cursor="pointer">
                <Text mr={'5px'}>Générer des CRA retroactif </Text>
                <InfoIcon
                  onClick={() =>
                    showDialogModal({
                      title: 'Attention',
                      text: `Sur la plateforme Connecteed, les CRA & Factures générés sont liés aux dates de début et fin de mission. La date de début de la mission saisie étant antérieure au mois en cours, les CRA correspondants aux mois antérieurs au mois en cours ne seront pas créés si vous ne cochez pas la case “Générer des CRA rétroactifs”.`,
                      confirmLabel: "J'ai bien compris",
                    })
                  }
                />
              </Flex>
            }
          </Flex>
        </FormControl>
      )}
      {displayForm && (
        <Row spacing={20}>
          {forRole === 'ALL' && (
            <FormControl
              required
              label="Intitulé de la mission"
              errorMessage={
                // @ts-ignore
                errors?.title?.type === 'maxLength'
                  ? 'Veuillez saisir moins de 36 caractères'
                  : // @ts-ignore
                    errors?.title?.message
              }
              warningMessage={
                'Cet intitulé n’apparaîtra pas dans vos devis et BDC Fournisseur.'
              }
            >
              <Input
                isDisabled={
                  !isMissionFieldEditable(mission, 'title') || isDisabled
                }
                isFullWidth
                {...register('title', { maxLength: 35 })}
                data-cy="mission-title"
              />
            </FormControl>
          )}
          <FormControl
            required
            label="Date de début"
            errorMessage={errors?.startAt?.message}
            data-cy="mission-startAt"
          >
            <DatePickerControlled
              disabled={
                !isMissionFieldEditable(mission, 'startAt') || isDisabled
              }
              actionOnSelect={(date: any) => checkShouldCreatePastCra(date)}
              control={control}
              minDate={minDateStart}
              maxDate={maxDateStart}
              name="startAt"
              rules={
                forRole === 'ALL' ? {} : { required: 'Ce champs est requis' }
              }
              valueName="selected"
            />
          </FormControl>
          <FormControl
            required
            label="Date de fin"
            errorMessage={errors?.endAt?.message}
            data-cy="mission-endAt"
          >
            <DatePickerControlled
              disabled={!isMissionFieldEditable(mission, 'endAt') || isDisabled}
              control={control}
              valueName="selected"
              minDate={minDateEnd}
              maxDate={maxDateEnd}
              name="endAt"
              rules={
                forRole === 'ALL' ? {} : { required: 'Ce champs est requis' }
              }
            />
          </FormControl>
        </Row>
      )}
      {forRole === 'ALL' && displayForm && (
        <Row spacing={20}>
          {!parent ? (
            <>
              <Box mt={20} pr={10}>
                <FormControl
                  label="Date de fin éstimée"
                  errorMessage={errors?.estimatedEndAt?.message}
                  data-cy="mission-estimatedEndAt"
                >
                  <DatePickerControlled
                    disabled={isDisabled}
                    control={control}
                    valueName="selected"
                    as={DatePicker}
                    minDate={startAt}
                    name="estimatedEndAt"
                  />
                </FormControl>
                <></>
              </Box>
            </>
          ) : (
            <Box></Box>
          )}
          <></>
        </Row>
      )}

      {displayForm && (
        <Box>
          <Section mt={0} variant="h2">
            <Row spacing={20}>
              <FormControl
                label={'Sélectionner le commercial interne'}
                errorMessage={errors?.internalCommercial}
              >
                <Box>
                  <UserSelectControlled
                    control={control}
                    name="internalCommercial"
                    role={'INTERNAL_COMMERCIAL'}
                    defaultValue={
                      mission?.internalCommercial ||
                      mission?.customer?.establishment?.customer
                        ?.internalCommercial
                    }
                    isDisabled={
                      !isMissionFieldEditable(mission, 'internalCommercial') ||
                      isDisabled
                      // isDisabled ||
                      // mission?.status === EMissionStatus?.VALIDATED ||
                      // mission?.status === EMissionStatus?.TERMINATED
                    }
                  />
                </Box>
              </FormControl>
              <></>
            </Row>
            {/* <Box>
            <FormControl
              label={'Par qui le contact a-t-il été apporté ?'}
              //required={missionTypeLabel === EMissionType.CLASSIC}
              errorMessage={
                errors?.billingInformation?.missionType?.providedBy?.message
              }
            >
              <Box mt={20}>
                <Radio
                  {...register('billingInformation.missionType.providedBy')}
                  onClick={e => {
                    setValue(
                      'billingInformation.missionType.contact',
                      undefined
                    );
                    setValue(
                      'billingInformation.missionType.providedBy',
                      providedBy === EMissionProvider?.FREELANCE_PARTNER
                        ? undefined
                        : EMissionProvider?.FREELANCE_PARTNER
                    );
                  }}
                  value={EMissionProvider.FREELANCE_PARTNER}
                  disabled={
                    isDisabled ||
                    mission?.status === EMissionStatus?.VALIDATED ||
                    mission?.status === EMissionStatus?.TERMINATED
                  }
                >
                  Freelance partner
                </Radio>
                <Radio
                  {...register('billingInformation.missionType.providedBy')}
                  onClick={e => {
                    setValue(
                      'billingInformation.missionType.contact',
                      undefined
                    );
                    setValue(
                      'billingInformation.missionType.providedBy',
                      providedBy === EMissionProvider?.BUSINESS_PROVIDER
                        ? undefined
                        : EMissionProvider?.BUSINESS_PROVIDER
                    );
                  }}
                  value={EMissionProvider.BUSINESS_PROVIDER}
                  disabled={
                    isDisabled ||
                    mission?.status === EMissionStatus?.VALIDATED ||
                    mission?.status === EMissionStatus?.TERMINATED
                  }
                >
                  Apporteur d'affaires
                </Radio>
              </Box>
            </FormControl>
          </Box> */}
            {providedBy === EMissionProvider.BUSINESS_PROVIDER && (
              <Row spacing={20}>
                <FormControl
                  label={"Sélectionner l'apporteur d'affaires"}
                  required
                  errorMessage={
                    errors?.billingInformation?.missionType?.contact?.message
                  }
                >
                  <Box>
                    <UserSelectControlled
                      control={control}
                      name="billingInformation.missionType.contact"
                      role={ERoles.BUSINESS_PARTNER}
                      disabled={isDisabled}
                      disableCall={!contact}
                      defaultValue={
                        mission &&
                        mission?.billingInformation?.missionType?.contact
                      }
                    />
                    <Link
                      iconLeft={<AddIcon />}
                      onClick={() =>
                        !isDisabled &&
                        showAddOtherContactModal({
                          role: 'BUSINESS_PARTNER',
                        }).then(c => {
                          c.cognitoUserId &&
                            setValue(
                              'billingInformation.missionType.contact',
                              c.cognitoUserId
                            );
                        })
                      }
                    >
                      Ajouter un apporteur d'affaires
                    </Link>
                  </Box>
                </FormControl>
                <></>
              </Row>
            )}
            {providedBy === EMissionProvider.FREELANCE_PARTNER && (
              <Row spacing={20}>
                <FormControl
                  label={'Sélectionner le freelance partner'}
                  required
                  errorMessage={
                    errors?.billingInformation?.missionType?.contact?.message
                  }
                >
                  <Box>
                    <UserSelectControlled
                      control={control}
                      name="billingInformation.missionType.contact"
                      role={ERoles.FREELANCE_PARTNER}
                      defaultValue={
                        mission &&
                        mission?.billingInformation?.missionType?.contact
                      }
                      rules={
                        providedBy
                          ? { required: 'ce champs est requis' }
                          : undefined
                      }
                      disabled={
                        isDisabled ||
                        mission?.status === EMissionStatus?.VALIDATED ||
                        mission?.status === EMissionStatus?.TERMINATED
                      }
                      disableCall={!contact}
                    />
                    <Link
                      iconLeft={<AddIcon />}
                      onClick={() =>
                        !isDisabled &&
                        showAddOtherContactModal({
                          role: 'FREELANCE_PARTNER',
                        }).then(c => {
                          c.cognitoUserId &&
                            setValue(
                              'billingInformation.missionType.contact',
                              c.cognitoUserId
                            );
                        })
                      }
                    >
                      Ajouter un freelance partner
                    </Link>
                  </Box>
                </FormControl>
                <></>
              </Row>
            )}
            <FormControl
              required
              label="Responsable de la mission"
              errorMessage={errors?.accountManager?.message}
              data-cy="mission-responsible"
            >
              <Row spacing={20}>
                <UserSelect
                  isDisabled={isDisabled}
                  placeholder="Responsable de la mission"
                  role={RolesTypes.ACCOUNT_MANAGER}
                  onChange={value => {
                    clearErrors('accountManager');
                    setContactSelected(value);
                    setValue('accountManager', value);
                  }}
                  value={contactSelected}
                  excludeDeactivatedUser={!mission?.accountManager}
                />
                <></>
              </Row>
            </FormControl>
          </Section>
        </Box>
      )}
    </>
  );
};
