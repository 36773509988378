import * as React from 'react';

function SvgDownloadIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        d="M18.933 13.847c-.588 0-1.066.551-1.066 1.23v2.462H6.133v-2.461c0-.68-.478-1.231-1.066-1.231-.59 0-1.067.551-1.067 1.23v4.308c0 .294.278.615.533.615h14.934c.322 0 .533-.282.533-.615v-4.307c0-.68-.478-1.231-1.067-1.231zm-4.783-3.079h-1.067V5.231c0-.68-.478-1.231-1.066-1.231-.59 0-1.067.551-1.067 1.23v5.538H9.883c-.375 0-.707-.058-.916.185a.692.692 0 000 .877l2.636 3.687a.49.49 0 00.405.175.485.485 0 00.405-.175l2.637-3.687a.692.692 0 000-.877c-.21-.243-.69-.185-.9-.185z"
      />
    </svg>
  );
}

export default SvgDownloadIcon;
