import { TopBar } from 'components/TopBar';
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { TracesList } from './List';
import { useBreadCrumb } from 'hooks/breadCrumb';
export const Traces = () => {
  const { setCrumbs } = useBreadCrumb();
  setCrumbs([
    {
      label: `Mon historique`,
      path: '/traces',
    },
  ]);
  return (
    <>
      <TopBar />
      <Switch>
        {/* <Route exact path="/traces">
          <Redirect to="/traces" />
        </Route> */}
        <Route path="/traces">
          <TracesList />
        </Route>
      </Switch>
    </>
  );
};
