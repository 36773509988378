import {
  IMissionCreateParams,
  IMissionCreateResponse,
  IMissionDeleteParams,
  IMissionDeleteResponse,
  IMissionGenerateFileParams,
  IMissionGenerateFileResponse,
  IMissionGetParams,
  IMissionGetResponse,
  IMissionSearchParams,
  IMissionSearchResponse,
  IMissionSendFileParams,
  IMissionSendFileResponse,
  IMissionUpdateParams,
  IMissionUpdateResponse,
  IMissionSearchPurchaseOrderParams,
  IMissionSearchPurchaseOrderResponse,
  IYousignPurchaseOrderReminderParams,
  IYousignPurchaseOrderReminderResponse,
  IYousignGetSignatureRequestLinkParams,
  IYousignGetSignatureRequestLinkResponse,
  IMissionUpdateServiceParams,
  IMissionUpdateServiceResponse,
  IMissionManagePurchaseOrderParams,
  IMissionManagePurchaseOrderResponse,
  IMissionCreateAmendmentParams,
  IMissionCreateAmendmentResponse,
  IMissionTerminateParams,
  IMissionTerminateResponse,
  IMissionDuplicateParams,
  IMissionAbortParams,
  IMissionAbortResponse,
  IMissionBulkUpdateParams,
  IMissionBulkUpdateResponse,
} from '@freelancelabs/teoreme-commons';
import { fetcher } from '../helpers/fetcher';
import { MISSION_SERVICE_URL, YOUSING_SERVICE_URL } from '../../constantz';

export const missionFindMany = (
  body: IMissionSearchParams,
  disableAlert?: boolean
) =>
  fetcher<IMissionSearchParams, IMissionSearchResponse>(
    `${MISSION_SERVICE_URL}/search`,
    {
      method: 'post',
      body,
    },
    disableAlert
  );

export const missionFindOne = (reference: string, disableAlert?: boolean) =>
  fetcher<IMissionGetParams, IMissionGetResponse>(
    MISSION_SERVICE_URL + `/get?reference=${reference}`,
    undefined,
    disableAlert
  );

export const missionCreateOne = (
  body: IMissionCreateParams,
  disableAlert?: boolean
) =>
  fetcher<IMissionCreateParams, IMissionCreateResponse>(
    MISSION_SERVICE_URL + `/create`,
    {
      method: 'POST',
      body,
    },
    disableAlert
  );

export const missionUpdateOne = (
  body: IMissionUpdateParams,
  disableAlert?: boolean
) =>
  fetcher<IMissionUpdateParams, IMissionUpdateResponse>(
    MISSION_SERVICE_URL + `/update`,
    {
      method: 'POST',
      body,
    },
    disableAlert
  );

export const missionDeleteOne = (reference: string, disableAlert?: boolean) =>
  fetcher<IMissionDeleteParams, IMissionDeleteResponse>(
    MISSION_SERVICE_URL + `/delete?reference=${reference}`,
    { method: 'DELETE' },
    disableAlert
  );

export const missionGenerateFile = (
  body: IMissionGenerateFileParams,
  disableAlert?: boolean
) =>
  fetcher<IMissionGenerateFileParams, IMissionGenerateFileResponse>(
    MISSION_SERVICE_URL + `/generate-file`,
    { method: 'POST', body },
    disableAlert
  );

export const missionSendFile = (
  body: IMissionSendFileParams,
  disableAlert?: boolean
) =>
  fetcher<IMissionSendFileParams, IMissionSendFileResponse>(
    MISSION_SERVICE_URL + `/send-file`,
    { method: 'POST', body },
    disableAlert
  );

export const purchaseOrderFindMany = (
  body: IMissionSearchPurchaseOrderParams,
  disableAlert?: boolean
) =>
  fetcher<
    IMissionSearchPurchaseOrderParams,
    IMissionSearchPurchaseOrderResponse
  >(
    `${MISSION_SERVICE_URL}/search-purchase-order`,
    {
      method: 'post',
      body,
    },
    disableAlert
  );

export const purchaseOrderResendEmail = (
  body: IYousignPurchaseOrderReminderParams,
  disableAlert?: boolean
) =>
  fetcher<
    IYousignPurchaseOrderReminderParams,
    IYousignPurchaseOrderReminderResponse
  >(
    `${YOUSING_SERVICE_URL}/purchase-order-reminder`,
    { method: 'post', body },
    disableAlert
  );

export const purchaseOrderGetSignatureRequest = (
  body: IYousignGetSignatureRequestLinkParams,
  disableAlert?: boolean
) =>
  fetcher<
    IYousignGetSignatureRequestLinkParams,
    IYousignGetSignatureRequestLinkResponse
  >(
    `${YOUSING_SERVICE_URL}/get-signature-request-link`,
    { method: 'post', body },
    disableAlert
  );
export const missionUpdateServiceOne = (
  body: IMissionUpdateServiceParams,
  disableAlert?: boolean
) =>
  fetcher<IMissionUpdateServiceParams, IMissionUpdateServiceResponse>(
    MISSION_SERVICE_URL + `/update-service`,
    {
      method: 'POST',
      body,
    },
    disableAlert
  );
export const managePurchaseOrder = (
  body: IMissionManagePurchaseOrderParams,
  disableAlert?: boolean
) =>
  fetcher<
    IMissionManagePurchaseOrderParams,
    IMissionManagePurchaseOrderResponse
  >(
    MISSION_SERVICE_URL + `/manage-purchase-order`,
    {
      method: 'POST',
      body,
    },
    disableAlert
  );
export const missionCreateAmendment = (
  body: IMissionCreateAmendmentParams,
  disableAlert?: boolean
) =>
  fetcher<IMissionCreateAmendmentParams, IMissionCreateAmendmentResponse>(
    MISSION_SERVICE_URL + `/create-amendment`,
    {
      method: 'POST',
      body,
    },
    disableAlert
  );
export const missionEnd = (
  body: IMissionTerminateParams,
  disableAlert?: boolean
) =>
  fetcher<IMissionTerminateParams, IMissionTerminateResponse>(
    MISSION_SERVICE_URL + `/terminate`,
    {
      method: 'POST',
      body,
    },
    disableAlert
  );
export const duplicateMission = (
  body: IMissionDuplicateParams,
  disableAlert?: boolean
) =>
  fetcher<IMissionDuplicateParams, IMissionCreateResponse>(
    MISSION_SERVICE_URL + `/duplicate`,
    {
      method: 'POST',
      body,
    },
    disableAlert
  );

export const missionAbort = (
  body: IMissionAbortParams,
  disableAlert?: boolean
) =>
  fetcher<IMissionAbortParams, IMissionAbortResponse>(
    MISSION_SERVICE_URL + `/abort`,
    {
      method: 'POST',
      body,
    },
    disableAlert
  );

export const missionBulkUpdate = (
  body: IMissionBulkUpdateParams,
  disableAlert?: boolean
) =>
  fetcher<IMissionBulkUpdateParams, IMissionBulkUpdateResponse>(
    MISSION_SERVICE_URL + `/bulk-update`,
    {
      method: 'POST',
      body,
    },
    disableAlert
  );
