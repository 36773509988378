import styled, { css } from 'styled-components';
import { boxProperties, BoxProps } from './Box';

type TextProps = {
  variant?:
    | 'h1'
    | 'h2'
    | 'h3'
    | 'p'
    | 'u'
    | 'span'
    | 'disabled'
    | 'b'
    | 'li'
    | 'a'
    | 'i';
};
export const Text = styled.div.attrs<any>(props => ({
  as: props.variant,
}))<TextProps & BoxProps>`
  color: ${props => props.theme.colors.darkBlue};
  line-height: normal;
  margin: 0;
  ${props => {
    switch (props.variant) {
      case 'a':
        return css`
          text-decoration: underline;
          color: #003cc2;
          font-size: 32px;
        `;
      case 'i':
        return css`
          font-size: 14px;
        `;
      case 'h1':
        return css`
          font-size: 25px;
          font-weight: 700;
        `;
      case 'h2':
        return css`
          font-size: 22px;
          font-weight: 600;
        `;
      case 'h3':
        return css`
          font-size: 16px;
          font-weight: 600;
        `;
      case 'disabled':
        return css`
          font-size: 14px;
          color: #819cad;
        `;
      case 'b':
        return css`
          font-size: 14px;
        `;
      case 'li':
        return css`
          padding: 5px;
          color: ${p => p.theme.colors?.darkBlue};
          font-size: 14px;
          list-style: inside;
        `;
      case 'p':
      default:
        return css`
          font-size: 14px;
        `;
    }
  }}
  ${boxProperties}
`;

Text.defaultProps = {
  variant: 'p',
};
