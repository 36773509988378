import { IFile, EFileType } from '@freelancelabs/teoreme-commons';
import {
  Box,
  Button,
  FileInput,
  Flex,
  FormControl,
  Link,
  Text,
  TextAreaControlled,
} from 'components/ui';
import { useShowMessage } from 'hooks/useShowMessage';
import { AddIcon } from 'components/ui/icons';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { truncate } from 'lodash';
import { uploadFile, cancelContract } from '@commons';
import { queryClient } from '@commons';
type FormValues = {
  contractUuid: string;
  cancelReason: string;
  cancelAttachment?: IFile;
};
type ContactFormProps = {
  contractUuid: string;
  onSubmit?: any;
  onClose: () => void;
};

export const CancelContractForm = ({
  contractUuid,
  onSubmit: onSubmitParent,
  onClose,
}: ContactFormProps) => {
  const showMessage = useShowMessage();
  const [loading, setLoading] = useState(false);
  const { handleSubmit, formState, control } = useForm<FormValues>({
    defaultValues: { contractUuid: contractUuid },
  });
  const [cancelAttachmentFile, setCancelAttachment] =
    React.useState<File | null>(null);

  const { isDirty, errors } = formState;

  const handleContractFileChange = async (files: File[]) => {
    setCancelAttachment(files[0]);
  };

  const onSubmit = async (formValues: FormValues) => {
    setLoading(true);
    let attachment;
    if (cancelAttachmentFile) {
      try {
        const uploadResponse = await uploadFile({
          file: cancelAttachmentFile as File,
          fileType: EFileType.TEMPORARY,
          establishment: '',
          actionType: 'upload',
        });
        if (!uploadResponse) return;
        attachment = {
          fileLocation: uploadResponse.fileLocation,
          fileName: uploadResponse.fileName,
          eTag: uploadResponse.eTag,
        };
      } catch (e) {
        //
      }
    }
    try {
      await cancelContract({
        contractUuid: contractUuid,
        cancelReason: formValues.cancelReason,
        cancelAttachment: attachment,
      });
    } catch (e) {
      //
    }
    setLoading(true);
    queryClient.refetchQueries({ queryKey: ['contracts'] });
    showMessage('success', 'Le contrat à bien été résilié');
    onClose();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
      <Text variant="h2" mb={20}>
        Résilier un contrat-cadre
      </Text>
      <FormControl
        label="Motif"
        required
        errorMessage={errors?.cancelReason?.message}
      >
        <TextAreaControlled minRows={3} name="cancelReason" control={control} />
      </FormControl>
      <Box mb={40}>
        <FileInput
          label="pièce jointe"
          required
          accept=".pdf"
          subLabel="Format accepté : PDF"
          onSelect={handleContractFileChange}
          //@ts-ignore
          errorMessage={errors?.cancelAttachment?.message}
        >
          <Link iconLeft={<AddIcon />}>
            {truncate(cancelAttachmentFile?.name, {
              length: 40,
              omission: '....pdf',
            }) || 'Ajouter contrat'}
          </Link>
        </FileInput>
      </Box>
      <Flex>
        <Button type="submit" isLoading={loading} isDisabled={!isDirty}>
          Résilier
        </Button>
        <Button type="button" ml="10px" variant="ghost" onClick={onClose}>
          Annuler
        </Button>
      </Flex>
    </form>
  );
};
