import * as React from 'react';

function SvgArchiveIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 25" {...props}>
      <path
        fillRule="evenodd"
        d="M4.478 7A.532.532 0 004 7.53v2.823c0 .293.239.53.533.53h.356v7.588c0 .292.239.529.533.529h13.156a.532.532 0 00.533-.53v-7.587h.356a.532.532 0 00.533-.53V7.53a.532.532 0 00-.533-.53H4.478zm.589 1.06h13.866v1.764H5.067V8.06zm.889 2.823h3.2v.706c0 .32.148.549.327.728.18.179.415.33.74.33h3.555c.324 0 .56-.151.739-.33.18-.18.327-.408.327-.728v-.706h3.2v7.058H5.956v-7.058zm4.266 0h3.556v.706h-3.556v-.706z"
      />
    </svg>
  );
}

export default SvgArchiveIcon;
