import {
  IJoinedValidationRequest,
  EValidationStatus,
  IEstablishmentMinifiedUsers,
} from '@freelancelabs/teoreme-commons';
import { Roles as RolesTypes } from '@freelancelabs/inside-commons/dist/lib/models/enum/role.enum';
import {
  checkDefaultManagerSelected,
  getFullName,
  getHumanDate,
  getTradeNameSafe,
  useAdminValidationFindMany,
  useEstablishmentFindMany,
  useMe,
} from '@commons';
import { ALink } from 'components/ALink';
import { Header } from 'components/Header';
import {
  Box,
  Container,
  CustomToolTip,
  Flex,
  FormLabel,
  Itemize,
  Link,
  SpinnerBox,
  Status,
  Tab,
  Table,
  TableColumn,
  Text,
} from 'components/ui';
import { EyeIcon } from 'components/ui/icons';
import * as React from 'react';
import { useSetCrumbs } from 'hooks/breadCrumb';
import { useParams } from 'react-router-dom';
import { showValidateBankAccountModal } from 'components/modals/validations/ValidateBankAccountModal';
import { useProviderValidationBankAccountListStore } from 'store';
import { Theme } from 'styles';
import { UserSelect } from 'components/selects/UserSelect';
import { RadioSelectEstablishments } from 'components/selects/RadioSelectEstablishments';

type Item = Itemize<
  IJoinedValidationRequest & {
    establishment: IEstablishmentMinifiedUsers | undefined;
  }
>;

export type ValidationBankAccountListProps = {};
const tabItems = (
  <>
    <Tab variant="secondary" href={'/providers/validate-bank-account/pending'}>
      À valider
    </Tab>
    <Tab variant="secondary" href={'/providers/validate-bank-account/rejected'}>
      Rejetées
    </Tab>
    {/*
      <Tab variant="secondary" href={'/providers/establishments/close'}>
        Établissements fermés
      </Tab>
      */}
  </>
);

const columns: TableColumn<Item>[] = [
  {
    key: 'createdAt',
    text: 'DATE DE LA DEMANDE',
    render: item => {
      return getHumanDate(item?.createdAt as Date);
    },
  },
  {
    key: 'name',
    text: 'RAISON SOCIALE',
    render: item => {
      return item?.establishment ? (
        <ALink
          href={`/providers/establishments/${item?.establishment?.uuid}/information`}
        >
          {getTradeNameSafe(item?.establishment)}
        </ALink>
      ) : (
        'N/A'
      );
    },
  },
  {
    key: 'siret',
    text: 'SIRET / IDENTIFIANT',
    render: item => {
      return `${
        item?.establishment?.siret
          ? item?.establishment?.siret
          : item?.establishment?.identifier
      }`;
      // return `${
      //   item?.establishment?.siret
      //     ? `SIRET :${item?.establishment?.siret}`
      //     : `IDENTIFIANT :${item?.establishment?.identifier}`
      // }`;
    },
  },
  {
    key: 'createdBy',
    text: 'CRÉÉ PAR',
    render: item => {
      return item?.createdBy ? `${getFullName(item?.createdBy)}` : 'N/A';
    },
  },
  {
    key: 'createdBy',
    text: 'SUIVI PAR',
    render: item => {
      return item?.accountManager
        ? `${getFullName(item?.accountManager)}`
        : 'N/A';
    },
  },
  {
    key: 'status',
    text: 'STATUT',
    render: item => {
      if (item?.status === EValidationStatus.PENDING) {
        return (
          <Status variantColor="warning">En attente de valdidation</Status>
        );
      }
      if (item?.status === EValidationStatus.REJECTED) {
        return (
          <CustomToolTip
            color={Theme?.colors?.error?.default}
            text={item?.rejectReason}
            cursor="help"
          >
            <Status variantColor="error">
              Refusé le {getHumanDate(item?.rejectedAt as Date)}
            </Status>
          </CustomToolTip>
        );
      }
      if (item?.status === EValidationStatus.VALIDATED) {
        return (
          <Status variantColor="success">
            Validé le {getHumanDate(item?.validatedAt as Date)}
          </Status>
        );
      }
    },
  },
  {
    key: 'actions',
    text: 'ACTIONS',
    align: 'flex-end',
    render: item => {
      if (item?.status === EValidationStatus.PENDING) {
        return (
          <Link
            onClick={() =>
              showValidateBankAccountModal({ validationRequest: item })
            }
            iconLeft={<EyeIcon fill={Theme?.colors?.primary?.default} />}
          >
            Traiter la demande
          </Link>
        );
      }
    },
  },
];

export const ValidationBankAccountList: React.FunctionComponent<
  React.PropsWithChildren<ValidationBankAccountListProps>
> = () => {
  const { me } = useMe();
  const { filter: filterParams } = useParams<{
    filter: 'pending' | 'rejected';
  }>();
  const estStatus = {
    pending: 'en attente de validation',
    rejected: 'rejeté',
  };
  useSetCrumbs(
    [],
    [
      {
        label: 'fournisseurs',
        path: '/providers',
      },
      {
        label: 'modifications bancaires',
        path: '/providers/validate-bank-account',
      },
      {
        label: estStatus[filterParams],
        path: '/providers/validate-bank-account/' + filterParams,
      },
    ]
  );
  const {
    // initialSate,
    // filter,
    order,
    sortedBy,
    //searchQuery,
    // startDate,
    // endDate,
    managerSelected,
    // statusSelect,
    // typeSelected,
    // providerSelected,
    multiProviderSelected,
    page,
    // isOpen,
    limit,
    updateStore,
    //resetStore,
  } = useProviderValidationBankAccountListStore();
  const {
    data: validations,
    status,
    isFetching,
  } = useAdminValidationFindMany({
    filterObject: {
      // status: statusSelect
      //   ? statusSelect
      //   : { $in: [EValidationStatus.PENDING, EValidationStatus.REJECTED] },
      // type: EValidationType.PROVIDER_BANK_ACCOUNT,
      status:
        filterParams === 'pending'
          ? EValidationStatus.PENDING
          : EValidationStatus.REJECTED,
      accountManager: checkDefaultManagerSelected(me, managerSelected)
        ? managerSelected
        : undefined,
      ownerId:
        multiProviderSelected && multiProviderSelected?.length > 0
          ? { $in: multiProviderSelected?.map((mps: any) => mps?.value) }
          : undefined,
    },
    limit: limit,
    skip: page * limit,
    sort: 'createdAt',
  });
  const { data: establishments } = useEstablishmentFindMany({
    filterObject:
      validations?.validationRequests &&
      validations?.validationRequests?.length > 0
        ? {
            uuid: {
              $in: validations?.validationRequests?.map(
                (vr: { ownerId: any }) => vr?.ownerId
              ),
            },
          }
        : undefined,
    limit: limit,
    skip: 0,
  });
  //   const debouncedFilterQuery = useDebounce(searchQuery, 500);
  //   const sortString = (order === 'desc' ? '-' : '') + sortedBy;
  const onSortedChange = React.useCallback(
    async (sortedBy: string, order: 'asc' | 'desc'): Promise<void> => {
      //@ts-ignore
      updateStore({ sortedBy, order });
    },
    []
  );

  const loading = status === 'pending';
  const totalCount = validations?.totalCount;
  const items = validations?.validationRequests?.map(
    (v: { uuid: any; ownerId: string }) => ({
      key: v.uuid,
      ...v,
      establishment: establishments?.establishments?.find(
        e => e?.uuid === v?.ownerId
      ),
    })
  );
  return (
    <Box>
      <Header
        tabs={tabItems}
        // actions={
        //   <Button onClick={onAddEstablishment}>Ajouter un établissement</Button>
        // }
      >
        <Text variant="h1">
          Demandes de modification des coordonnées bancaires
        </Text>
      </Header>
      <Container>
        <Flex mt="10px" mb="10px">
          <Box width={3 / 10} pr={'5px'} pt={'5px'}>
            <FormLabel>Chargés de comptes</FormLabel>
            <UserSelect
              placeholder="Tous les Chargés de comptes"
              role={RolesTypes.ACCOUNT_MANAGER}
              onChange={value => updateStore({ managerSelected: value })}
              value={managerSelected}
            />
          </Box>
          <Box width={3 / 10} pr={'5px'} pt={'5px'}>
            <FormLabel>Établissements Fournisseurs</FormLabel>
            <RadioSelectEstablishments
              filter={{ 'provider.manager': { $exists: true } }}
              referenceValue="uuid"
              placeholder="Tous les fournisseurs"
              onChange={value =>
                updateStore({
                  multiProviderSelected: value,
                })
              }
              values={multiProviderSelected}
            />
          </Box>
          {/* <Box width={3 / 10} pr={'5px'} pt={'5px'}>
            <FormLabel>Statuts</FormLabel>
            <StaticSelect
              placeholder="Tous les statuts"
              options={[
                {
                  label: 'En attente de validation',
                  value: EValidationStatus.PENDING,
                  key: EValidationStatus.PENDING,
                },
                {
                  label: 'Refusée',
                  value: EValidationStatus.REJECTED,
                  key: EValidationStatus.REJECTED,
                },
              ]}
              onChange={value => updateStore({ statusSelect: value as string })}
              value={statusSelect}
            />
          </Box> */}
        </Flex>
        {loading && <SpinnerBox />}
        {!loading && (
          <Table<Item>
            isLoading={isFetching}
            columns={columns}
            items={items}
            sortedBy={sortedBy}
            order={order}
            page={page}
            total={totalCount}
            perPage={totalCount && totalCount < limit ? totalCount : limit}
            onSortedChange={onSortedChange}
            onChangePage={page => updateStore({ page })}
          />
        )}
      </Container>
    </Box>
  );
};
