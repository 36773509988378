import {
  useEstablishmentFindMany,
  useSageCreateContact,
  useUserFindOne,
  checkAllStructureCreatedInGescomForContactOnEstablishment,
  getEstablishmentName,
  useEstablishmentUpdateOne,
  getFullName,
  queryClient,
  useMissionFindMany,
} from '@commons';
import { EstablishmentStructuredCard } from 'components/cards/EstablishmentStructuredCard';
import { Box, Link, Text, Status, BlocInformation } from 'components/ui';
import { AddIcon, UnLinkIcon, EditIcon } from 'components/ui/icons';
import { useBreadCrumb } from 'hooks/breadCrumb';
import * as React from 'react';
import { useParams } from 'react-router-dom';
import { showDialogModal } from 'components/modals/DialogModal';
import {
  IEstablishmentMinifiedUsers,
  FlatUser,
  ISageReference,
  EMissionStatus,
} from '@freelancelabs/teoreme-commons';
import { showLinkEstablishmentContactCustomerModal } from 'components/modals/clients/LinkEstablishmentContactCustomerModal';
import { useShowMessage } from 'hooks/useShowMessage';
import { Theme } from 'styles';

export type EstablishmentBloc = {
  id: string;
  name: string;
  address: string;
  siret: string;
  tva: string;
};

export const ClientsContactsEstablishments: React.FunctionComponent<
  React.PropsWithChildren<unknown>
> = () => {
  const { id } = useParams<{ id: string }>();
  const showMessage = useShowMessage();
  const { data: client } = useUserFindOne(id);
  const { mutateAsync: updateEstablishment } = useEstablishmentUpdateOne();
  const { data: establishmentsQuery } = useEstablishmentFindMany(
    client && {
      filterObject: {
        'customer.contacts': client.cognitoUserId,
      },
      sort: '-createdAt',
      limit: 20,
      skip: 0,
    }
  );
  const { data: missionsQuery } = useMissionFindMany({
    filterObject:
      establishmentsQuery?.establishments &&
      establishmentsQuery?.establishments?.length > 0
        ? {
            status: {
              $in: [EMissionStatus.VALIDATED, EMissionStatus.TERMINATED],
            },
            'customer.establishment': {
              $in: establishmentsQuery?.establishments?.map(e => e.uuid),
            },
            'customer.contact': id,
          }
        : undefined,
    limit: 1,
    skip: 0,
  });
  const establishments = establishmentsQuery?.establishments;

  const { mutateAsync: sageCreateContact } = useSageCreateContact();

  const { setCrumbs } = useBreadCrumb();

  React.useEffect(() => {
    setCrumbs([
      {
        label: 'clients',
        path: '/clients',
      },
      {
        label: 'contacts',
        path: '/clients/contacts',
      },
      {
        label: client ? getFullName(client) : '-',
        path: `/clients/contacts/${id}`,
      },
      {
        label: 'établissement',
        path: `/clients/contacts/${id}/establishments`,
      },
    ]);
  }, [client]);

  const createInGescom = async (
    establishment: IEstablishmentMinifiedUsers,
    user: FlatUser | undefined
  ) => {
    if (user && establishment) {
      await sageCreateContact({
        establishment: establishment.uuid,
        type: 'customer',
        contact: user.cognitoUserId,
      });
      if (user.cognitoUserId)
        queryClient.refetchQueries({ queryKey: [user.cognitoUserId] });
      queryClient.refetchQueries({ queryKey: [id] });
    }
  };
  const onUnlinkEstablisment = (establishment: IEstablishmentMinifiedUsers) => {
    try {
      const establishmentData = {
        uuid: establishment?.uuid,
        removeContacts: { customer: [client?.cognitoUserId] },
      };
      showDialogModal({
        closeIcon: true,
        confirmLabel: 'Détacher',
        cancelLabel: 'Annuler',
        title:
          'Etes-vous sûr de vouloir détacher le contact de cet établissement ?',
        text: `Êtes-vous sûr de vouloir détacher l'établissement ${getEstablishmentName(
          establishment
        )} du contact client ${getFullName(client)} ?`,
        beforeValidation: async () => {
          try {
            await updateEstablishment(establishmentData as any);
            queryClient.refetchQueries({
              queryKey: [client?.cognitoUserId || ''],
            });
            queryClient.refetchQueries({ queryKey: [establishment?.uuid] });
            queryClient.refetchQueries({
              queryKey: ['establishments'],
              type: 'active',
            });
            showMessage(
              'success',
              `Le contact ${getFullName(
                client
              )} a bien été détaché de l'établissement ${getEstablishmentName(
                establishment
              )}`
            );
          } catch (e) {
            //
          }
        },
      });
    } catch (e) {
      //
    }
  };
  const isUnlinkableEstablishment = (
    establishment: IEstablishmentMinifiedUsers
  ) => {
    const isNotLinkable = missionsQuery?.missions?.find(
      m => m?.customer?.establishment?.uuid === establishment?.uuid
    );
    return isNotLinkable ? false : true;
  };
  return (
    <Box>
      <Text variant="h2" mb={20}>
        Établissements rattachés au contact client
      </Text>
      {establishments?.map(establishment => {
        const checkSageData =
          checkAllStructureCreatedInGescomForContactOnEstablishment(
            establishment?.customer?.sage,
            client?.sage as ISageReference
          );
        const isUnlinkable = isUnlinkableEstablishment(establishment);
        return (
          <>
            <Box
              display={'inline-flex'}
              justifyContent={'space-between'}
              width={'100%'}
            >
              <Box display={'inline-flex'}>
                <Text mb={2}>{client ? getFullName(client) : ''}</Text>
                {checkSageData?.isReady && (
                  <Status
                    marginTop={-2}
                    marginLeft={2}
                    variantColor={'success'}
                  >
                    Créé dans GESCOM
                  </Status>
                )}
                {!checkSageData?.isReady && (
                  <Status
                    marginTop={-2}
                    marginLeft={2}
                    variantColor={'warning'}
                  >
                    {`À créer dans GESCOM ( ${checkSageData?.missingStructure?.map(
                      ms => ms
                    )} )`}
                  </Status>
                )}
              </Box>
              {!checkSageData?.isReady && (
                <Link
                  iconLeft={<EditIcon />}
                  onClick={() =>
                    showDialogModal({
                      confirmLabel: 'Créer',
                      cancelLabel: 'Annuler',
                      title: 'Créer dans GESCOM',
                      text: `Avant de valider la création de ce contact client pour cet établissement dans GESCOM, merci de vérifier son Nom, Prénom et Email.`,
                      beforeValidation: () =>
                        createInGescom(establishment, client),
                    })
                  }
                >
                  Créer dans GESCOM
                </Link>
              )}
            </Box>
            {!establishment.customer.sage && (
              <Box mb={2}>
                <BlocInformation>
                  <p>
                    Avant de pouvoir créer ce contact dans GESCOM, veuillez
                    créer l'établissement client dans GESCOM depuis sa fiche et
                    vous assurer que l'établissement est bien rattaché au
                    contact.
                  </p>
                </BlocInformation>
              </Box>
            )}
            <EstablishmentStructuredCard
              checkSage
              forRole={'CUSTOMER'}
              mb={20}
              isFullWidth
              key={establishment.uuid}
              establishment={establishment}
              renderActions={
                <Box
                  cursor={isUnlinkable ? 'pointer' : 'not-allowed'}
                  title={
                    isUnlinkable
                      ? "Détacher l'établissement"
                      : `Cet établissement est rattaché à une ou plusieurs missions en cours ( ${missionsQuery?.missions
                          ?.filter(
                            m =>
                              m?.customer?.establishment?.uuid ===
                              establishment?.uuid
                          )
                          ?.map(mf => mf?.displayReference + ' ')})`
                  }
                  onClick={() =>
                    isUnlinkable ? onUnlinkEstablisment(establishment) : false
                  }
                >
                  <UnLinkIcon
                    fill={
                      isUnlinkable
                        ? Theme?.colors?.blue
                        : Theme?.colors?.grey?.default
                    }
                  />
                </Box>
              }
            />
          </>
        );
      }) || null}
      <Link
        iconLeft={<AddIcon fill={Theme?.colors?.primary?.default} />}
        onClick={() =>
          showLinkEstablishmentContactCustomerModal({
            contact: client as FlatUser,
            excludesEstablishments:
              establishments as IEstablishmentMinifiedUsers[],
          })
        }
      >
        Rattacher un établissement
      </Link>
    </Box>
  );
};
