import { SOCIAL_STATUS_SELECT, CONTRACTOR_AS_EMPLOYEE_SELECT } from '@commons';
import { useUserFindOne } from '@commons';
import {
  Row,
  Box,
  Text,
  CheckBox,
  FormLabel,
  FormControl,
  BlocInformation,
  SpinnerBox,
  StaticSelectControlled,
} from 'components/ui';
import { getFullName } from '@commons';
import { useSetCrumbs } from 'hooks/breadCrumb';
import * as React from 'react';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';

type FormValues = any;
export const ProviderContactsOnBoardingContractors: React.FunctionComponent<
  React.PropsWithChildren<unknown>
> = () => {
  const { id } = useParams<{ id: string }>();
  const { data: provider, isLoading } = useUserFindOne(id);
  const { onboardingStep } = provider as any;
  const tmpData = onboardingStep?.tmpData;
  //const { mutateAsync: updateClient } = useUserUpdateOne();
  const [isContact, setIsContact] = React.useState(
    tmpData?.nextMissionSpec?.willStepInOnNextMission
  );
  const [other, setOther] = React.useState(
    tmpData?.nextMissionSpec?.willUseContractorOnNextMisison
  );
  useSetCrumbs(
    [provider],
    [
      {
        label: 'fournisseurs',
        path: '/providers',
      },
      {
        label: 'contacts',
        path: '/providers/contacts',
      },
      {
        label: provider ? getFullName(provider) : '-',
        path: `/providers/contacts/${id}`,
      },
      {
        label: 'informations',
        path: `/providers/contacts/on-boarding-card/${id}/contractors`,
      },
    ]
  );
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {
      tmpData,
    },
  });
  const onSubmit = async (formValues: FormValues) => {};
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Text variant="h2" mb={20}>
        Intervenant
      </Text>
      {!isLoading && tmpData && Object.keys(tmpData).length > 0 ? (
        <Box>
          <Box width={4 / 10}>
            <Row spacing={20}>
              <CheckBox
                isDisabled
                id={'isContact'}
                mb={10}
                checked={isContact}
                onChange={e => setIsContact(e.target.checked)}
              >
                <FormLabel>Contact fournisseur</FormLabel>
              </CheckBox>
              <CheckBox
                isDisabled
                id={'other'}
                mb={10}
                checked={other}
                onChange={e => setOther(e.target.checked)}
              >
                <FormLabel>Autre (s) Intervenant(s)</FormLabel>
              </CheckBox>
            </Row>
          </Box>
          <Row mt={20} spacing={20}>
            <FormControl
              required={true}
              label="Statut social du contact fournisseur intervenant"
              errorMessage={errors?.message}
            >
              <StaticSelectControlled
                disabled
                isDisabled
                control={control}
                name="tmpData.nextMissionSpec.socialStatus"
                options={SOCIAL_STATUS_SELECT}
                placeholder="Statut social"
              />
            </FormControl>
            <></>
          </Row>
          <Row spacing={20}>
            <FormControl
              required={true}
              label="Quantité de salarié(s) parmis le ou les autres intervenants"
              errorMessage={errors?.message}
            >
              <StaticSelectControlled
                disabled
                isDisabled
                control={control}
                name="tmpData.nextMissionSpec.contractorAsEmployee"
                options={CONTRACTOR_AS_EMPLOYEE_SELECT}
                placeholder="Type de service"
              />
            </FormControl>
            <></>
          </Row>
        </Box>
      ) : (
        <BlocInformation>
          Le contact fournisseur n'a pas encore renseigné d'informations sur
          l'étape 3.
        </BlocInformation>
      )}
      {isLoading && <SpinnerBox />}
    </form>
  );
};
