import {
  formatAllObject,
  transformText,
  uploadFile,
  useEstablishmentUpdateOne,
  validateFormPhoneFormatFR,
  checkFormEmail,
  IUserLocal,
} from '@commons';
import {
  EFileType,
  ESignOfficerType,
  ICompanyGetResponse,
  ICompanyUpdateParams,
} from '@freelancelabs/teoreme-commons';
import { ConfirmNavigationModal } from 'components/modals/ConfirmNavigationModal';
import {
  Box,
  Button,
  FileInput,
  FormControl,
  Input,
  Link,
  PhoneInputControlled,
  Radio,
  Row,
  Text,
} from 'components/ui';
import { CancelIcon } from 'components/ui/icons';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { queryClient } from '@commons';
import { useShowMessage } from 'hooks/useShowMessage';
import { yousignNameRestrictionPattern } from '@commons';
import { UserSelect } from 'components/selects/UserSelect';
type FormValues = any;

type SignatoryInformationFormProps = {
  onSubmit?: () => void;
  onClose: (action?: boolean | undefined) => void;
  establishment: ICompanyGetResponse;
  readOnly?: boolean;
};

export const SignatoryInformationForm = ({
  onClose,
  onSubmit: onSubmitParent,
  establishment,
}: SignatoryInformationFormProps) => {
  const [loading, setLoading] = useState(false);
  const showMessage = useShowMessage();
  const { mutateAsync: updateEstablishment } = useEstablishmentUpdateOne();
  const { delegatedSignOfficer, signOfficer } = establishment?.provider;
  const signOType = establishment?.provider.signOfficerType;
  const delegationOrder = delegatedSignOfficer?.delegationOrder;
  const [noContactFound, setnNoContactFound] = useState(
    signOfficer ? true : false
  );
  const [noDelegatedSignOfficer, setNoDelegatedSignOfficer] = useState(
    delegatedSignOfficer ? true : false
  );
  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isDirty, isSubmitSuccessful },
    setError,
    setValue,
    clearErrors,
    watch,
  } = useForm<FormValues>({
    defaultValues: {
      signOfficerType: signOType ? signOType : 'CORPORATE_OFFICER',
      signOfficer: {
        civility: String(signOfficer?.civility),
        firstName: transformText(
          signOfficer?.firstName,
          'capitalize',
          yousignNameRestrictionPattern
        ),
        lastName: transformText(
          signOfficer?.lastName,
          'uppercase',
          yousignNameRestrictionPattern
        ),
        email: signOfficer?.email,
        phone: signOfficer?.phone ? String(signOfficer?.phone) : '',
        jobTitle: transformText(signOfficer?.jobTitle, 'capitalize-first'),
      },
      delegatedSignOfficer:
        signOType === 'DELEGATED_OFFICER'
          ? {
              civility: String(delegatedSignOfficer?.civility),
              firstName: transformText(
                delegatedSignOfficer?.firstName,
                'capitalize'
              ),
              lastName: transformText(
                delegatedSignOfficer?.lastName,
                'uppercase'
              ),
              email: delegatedSignOfficer?.email,
              phone: delegatedSignOfficer?.phone
                ? String(delegatedSignOfficer?.phone)
                : '',
              jobTitle: transformText(
                delegatedSignOfficer?.jobTitle,
                'capitalize-first'
              ),
              delegationOrder: delegationOrder,
            }
          : undefined,
    },
  });
  const signOfficerType = watch('signOfficerType');
  const [contractFile, setContractFile] = React.useState<File | null>(null);

  const handleContractFileChange = async (files: File[]) => {
    setContractFile(files[0]);
  };

  const onSubmit = async (formValues: FormValues) => {
    setLoading(true);
    const values = formatAllObject(
      {
        ...formValues,
      },
      yousignNameRestrictionPattern
    );

    const isDelegated = values.signOfficerType === 'DELEGATED_OFFICER';
    let isValid = true;
    let s3Response;
    const params: ICompanyUpdateParams = {
      uuid: establishment.uuid,
      establishment: {
        provider: {
          ...values,
        },
      },
    };

    if (isDelegated && !contractFile && !delegationOrder) {
      isValid = false;
    }
    if (isValid) {
      if (
        isDelegated &&
        params &&
        params.establishment &&
        params.establishment.provider &&
        params.establishment.provider.delegatedSignOfficer
      ) {
        try {
          if (contractFile) {
            s3Response = await uploadFile({
              file: contractFile as File,
              fileType: EFileType.SIGN_OFFICER,
              actionType: 'upload',
              establishment: establishment.uuid,
            });
            params.establishment.provider.delegatedSignOfficer.delegationOrder =
              {
                fileName: s3Response?.fileName,
                fileLocation: s3Response?.fileLocation as string,
                eTag: s3Response?.eTag as string,
              };
          }
        } catch (e) {
          //
        }
      }

      clearErrors();

      try {
        params.establishment.provider = formatAllObject(
          params.establishment.provider
        );
        await updateEstablishment({
          uuid: params.uuid,
          establishment: {
            provider: {
              signOfficer: params?.establishment?.provider?.signOfficer,
              signOfficerType: params?.establishment?.provider?.signOfficerType,
              delegatedSignOfficer:
                params?.establishment?.provider?.signOfficerType ===
                ESignOfficerType.DELEGATED_OFFICER
                  ? params?.establishment?.provider?.delegatedSignOfficer
                  : undefined,
            },
          },
        });
        queryClient.refetchQueries({ queryKey: [establishment.uuid] });
        showMessage('success', 'Informations du signataire mise à jour');
        onClose(true);
      } catch (e) {
        //
      }
    } else {
      setError('delegationOrder', {
        message: 'Veuillez ajouter la Délégation de signature',
      });
    }

    setLoading(false);
  };
  const handleSelectSignatory = (contact: IUserLocal) => {
    setValue('signOfficer', {
      civility: String(contact?.civility),
      firstName: contact?.firstName,
      lastName: contact?.lastName,
      email: contact?.email,
      phone: contact?.phone,
      jobTitle: contact?.jobTitle,
    });
    if (
      !contact?.civility ||
      !contact?.firstName ||
      !contact?.lastName ||
      !contact?.email ||
      !contact?.jobTitle
    ) {
      setnNoContactFound(true);
    }
  };
  const handleSelectDelegatedSignOfficer = (contact: IUserLocal) => {
    setValue('delegatedSignOfficer', {
      civility: String(contact?.civility),
      firstName: contact?.firstName,
      lastName: contact?.lastName,
      email: contact?.email,
      phone: contact?.phone,
      jobTitle: contact?.jobTitle,
    });
    if (
      !contact?.civility ||
      !contact?.firstName ||
      !contact?.lastName ||
      !contact?.email
    ) {
      setNoDelegatedSignOfficer(true);
    }
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <ConfirmNavigationModal active={isDirty && !isSubmitSuccessful} />
      <Text variant="h2">Informations du signataire</Text>
      <Box>
        <FormControl label="">
          <>
            <Radio {...register('signOfficerType')} value={'CORPORATE_OFFICER'}>
              Le mandataire social
            </Radio>
            <Radio {...register('signOfficerType')} value={'DELEGATED_OFFICER'}>
              Un tiers muni d'une délégation de signature
            </Radio>
          </>
        </FormControl>
        <Text variant="h3">Informations du mandataire social</Text>
        {!noContactFound && (
          <Box mt={20}>
            <FormControl
              required={true}
              label={'mandataire social'}
              errorMessage={errors?.signOfficer?.firstName?.message}
            >
              <>
                <UserSelect
                  role="PROVIDER_CONTACT"
                  filter={{
                    cognitoUserId: {
                      $in: [
                        ...establishment?.provider?.contacts?.map(
                          c => c?.cognitoUserId
                        ),
                        establishment?.provider?.manager.cognitoUserId,
                      ],
                    },
                  }}
                  onChangeCompleteObject={e => handleSelectSignatory(e)}
                />
                <Link onClick={() => setnNoContactFound(true)}>
                  Ajouter un mandataire
                </Link>
              </>
            </FormControl>
          </Box>
        )}

        <Box hidden={!noContactFound}>
          <Box mt={20}>
            <FormControl
              required={true}
              label={'Civilité'}
              errorMessage={errors?.signOfficer?.civility?.message}
            >
              <>
                <Radio
                  {...register('signOfficer.civility', {
                    required: 'Ce champ est requis',
                  })}
                  value="1"
                >
                  Monsieur
                </Radio>
                <Radio
                  {...register('signOfficer.civility', {
                    required: 'Ce champ est requis',
                  })}
                  value="2"
                >
                  Madame
                </Radio>
              </>
            </FormControl>
          </Box>
          <Row spacing={20}>
            <FormControl
              required={true}
              label="Prénom"
              errorMessage={errors?.signOfficer?.firstName?.message}
            >
              <Input
                isFullWidth
                {...register('signOfficer.firstName', {
                  required: 'Ce champ est requis',
                })}
              />
            </FormControl>
            <FormControl
              required={true}
              label="Nom"
              errorMessage={errors?.signOfficer?.lastName?.message}
            >
              <Input
                isFullWidth
                {...register('signOfficer.lastName', {
                  required: 'Ce champ est requis',
                })}
              />
            </FormControl>
          </Row>
          <Row spacing={20}>
            <FormControl
              required={signOfficerType === 'CORPORATE_OFFICER'}
              label="Email"
              errorMessage={
                errors?.signOfficer?.email?.message
                // signOfficerType === 'CORPORATE_OFFICER'
                //   ? errors?.signOfficer?.email?.message
                //   : undefined
              }
            >
              <Input
                isFullWidth
                {...register('signOfficer.email', {
                  required:
                    signOfficerType === 'CORPORATE_OFFICER'
                      ? 'Ce champ est requis'
                      : undefined,
                  validate:
                    signOfficerType === 'CORPORATE_OFFICER'
                      ? (value: string) => checkFormEmail(value)
                      : undefined,
                })}
              />
            </FormControl>
            <FormControl
              label="Téléphone"
              errorMessage={errors?.signOfficer?.phone?.message}
            >
              <PhoneInputControlled
                control={control}
                rules={{
                  validate: (value: string) => {
                    return validateFormPhoneFormatFR(value);
                  },
                }}
                isFullWidth
                name="signOfficer.phone"
              />
            </FormControl>
          </Row>
          <Row spacing={20}>
            <FormControl
              required={true}
              label="Fonction"
              errorMessage={errors?.signOfficer?.jobTitle?.message}
            >
              <Input
                {...register('signOfficer.jobTitle', {
                  required: 'Ce champ est requis',
                })}
              />
            </FormControl>
            <></>
          </Row>
        </Box>

        {signOfficerType === 'DELEGATED_OFFICER' && (
          <>
            <Text variant="h3">
              Informations de la personne habilitée à signer
            </Text>
            {!noDelegatedSignOfficer && (
              <Box mt={20}>
                <FormControl
                  required={true}
                  label={'Personne habilitée à signer'}
                  errorMessage={errors?.delegatedSignOfficer?.email?.message}
                >
                  <>
                    <UserSelect
                      role="PROVIDER_CONTACT"
                      filter={{
                        cognitoUserId: {
                          $in: [
                            ...establishment?.provider?.contacts?.map(
                              c => c?.cognitoUserId
                            ),
                            establishment?.provider?.manager.cognitoUserId,
                          ],
                        },
                      }}
                      onChangeCompleteObject={e =>
                        handleSelectDelegatedSignOfficer(e)
                      }
                    />
                    <Link onClick={() => setNoDelegatedSignOfficer(true)}>
                      Ajouter une personne habilitée à signer
                    </Link>
                  </>
                </FormControl>
              </Box>
            )}
            <Box hidden={!noDelegatedSignOfficer}>
              <Box mt={20}>
                <FormControl
                  required={true}
                  label={'Civilité'}
                  errorMessage={errors?.delegatedSignOfficer?.civility?.message}
                >
                  <>
                    <Radio
                      {...register('delegatedSignOfficer.civility', {
                        required: 'Ce champ est requis',
                      })}
                      value="1"
                    >
                      Monsieur
                    </Radio>
                    <Radio
                      {...register('delegatedSignOfficer.civility', {
                        required: 'Ce champ est requis',
                      })}
                      value="2"
                    >
                      Madame
                    </Radio>
                  </>
                </FormControl>
              </Box>
              <Row spacing={20}>
                <FormControl
                  required={true}
                  label="Prénom"
                  errorMessage={
                    errors?.delegatedSignOfficer?.firstName?.message
                  }
                >
                  <Input
                    isFullWidth
                    {...register('delegatedSignOfficer.firstName', {
                      required: 'Ce champ est requis',
                    })}
                  />
                </FormControl>
                <FormControl
                  required={true}
                  label="Nom"
                  errorMessage={errors?.delegatedSignOfficer?.lastName?.message}
                >
                  <Input
                    isFullWidth
                    {...register('delegatedSignOfficer.lastName', {
                      required: 'Ce champ est requis',
                    })}
                  />
                </FormControl>
              </Row>
              <Row spacing={20}>
                <FormControl
                  required={signOfficerType === 'DELEGATED_OFFICER'}
                  label="Email"
                  errorMessage={
                    errors?.delegatedSignOfficer?.email?.message
                    // signOfficerType === 'DELEGATED_OFFICER'
                    //   ? errors?.delegatedSignOfficer?.email?.message
                    //   : undefined
                  }
                >
                  <Input
                    isFullWidth
                    {...register('delegatedSignOfficer.email', {
                      required:
                        signOfficerType === 'DELEGATED_OFFICER'
                          ? 'Ce champ est requis'
                          : undefined,
                      validate:
                        signOfficerType === 'DELEGATED_OFFICER'
                          ? (value: string) => checkFormEmail(value)
                          : undefined,
                    })}
                  />
                </FormControl>
                <FormControl
                  label="Téléphone"
                  errorMessage={errors?.delegatedSignOfficer?.phone?.message}
                >
                  <PhoneInputControlled
                    control={control}
                    rules={{
                      validate: (value: string) => {
                        return validateFormPhoneFormatFR(value);
                      },
                    }}
                    isFullWidth
                    name="delegatedSignOfficer.phone"
                  />
                </FormControl>
              </Row>
              <Row spacing={20}>
                <FormControl
                  label="Fonction"
                  errorMessage={errors?.delegatedSignOfficer?.jobTitle?.message}
                >
                  <Input {...register('delegatedSignOfficer.jobTitle')} />
                </FormControl>
                <></>
              </Row>
            </Box>
            <Box mb={40} width={delegationOrder ? 1 / 1 : 1 / 2}>
              <FormControl
                label=""
                errorMessage={errors?.delegationOrder?.message}
              >
                <FileInput
                  required
                  label="Delégation de signature"
                  accept=".pdf"
                  subLabel="Format accepté : PDF"
                  onSelect={handleContractFileChange}
                >
                  <Link>
                    {contractFile?.name ||
                      `${
                        delegationOrder
                          ? `Remplacer la delégation de signature '${delegationOrder.fileName}' `
                          : 'Ajouter une delégation de signature'
                      }`}
                  </Link>
                </FileInput>
              </FormControl>
            </Box>
          </>
        )}
        <Row spacing={20} width={'300px'}>
          <Button key="submit" type="submit" isLoading={loading}>
            Enregistrer
          </Button>
          <Link
            iconLeft={<CancelIcon />}
            mt={'20px'}
            onClick={() => onClose(false)}
          >
            Annuler
          </Link>
        </Row>
      </Box>
    </form>
  );
};
