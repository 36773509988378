import { EDpaeStatus, IJoinedDpae } from '@freelancelabs/teoreme-commons';
import {
  Box,
  Button,
  Flex,
  Text,
  FormControl,
  Input,
  StaticSelectControlled,
} from 'components/ui';
import React from 'react';
import { create } from 'react-modal-promise';
import { ModalFrame, ModalProps } from '../ModalFrame';
import { useForm } from 'react-hook-form';
import { dpaeUpdateOne } from '@commons';
import { queryClient } from '@commons';
type Props = ModalProps & {
  beforeValidation?: () => void;
  dpae: IJoinedDpae;
};
type FormValues = {
  status: EDpaeStatus;
  rejectReason: string;
  rejectMetaData?: string;
};
export const RejectDpaeModal = ({ onResolve, isOpen, dpae }: Props) => {
  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors, isDirty },
  } = useForm<FormValues>({
    defaultValues: {},
  });

  const rejectReason = watch('rejectReason');
  const [loading, setLoading] = React.useState(false);

  const onSubmit = async (formValues: FormValues) => {
    setLoading(true);
    try {
      await dpaeUpdateOne({
        uuid: dpae.uuid as string,
        dpae: {
          rejectReason:
            rejectReason !== 'OTHER' ? rejectReason : formValues.rejectMetaData,
          status: EDpaeStatus.REJECTED,
        },
      });
      queryClient.refetchQueries({ queryKey: ['dpaes'], type: 'active' });
      onResolve(true);
    } catch (err) {
      onResolve(false);
    }
    setLoading(false);
  };

  return (
    <ModalFrame
      isOpen={isOpen}
      onClose={() => onResolve(false)}
      width={550}
      closeIcon
    >
      <Box mt={20}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Text variant="h2" mb={20}>
            Refuser la DPAE
          </Text>

          <FormControl label="Motif" required>
            <StaticSelectControlled
              control={control}
              name="rejectReason"
              options={[
                {
                  label: 'La DPAE est illisible.',
                  value: 'La DPAE est illisible.',
                },
                {
                  label:
                    'La DPAE ne correspondant pas au NOM Prénom de l’intervenant.',
                  value:
                    'La DPAE ne correspondant pas au NOM Prénom de l’intervenant.',
                },
                {
                  label:
                    "La DPAE ne correspond pas à l'entreprise renseigné pour l'intervenant.",
                  value:
                    "La DPAE ne correspond pas à l'entreprise renseigné pour l'intervenant.",
                },

                { label: 'Autre', value: 'OTHER' },
              ]}
              placeholder="Motif"
            />
          </FormControl>
          {rejectReason === 'OTHER' && (
            <FormControl
              required
              label="Préciser le motif de refus"
              // @ts-ignore
              errorMessage={errors?.cra?.rejectMetaData?.message}
            >
              <Input
                isFullWidth
                type="text"
                {...register('rejectMetaData', {
                  required: 'Ce champ est requis',
                })}
              />
            </FormControl>
          )}

          <Flex>
            <Button type="submit" isLoading={loading} isDisabled={!isDirty}>
              Refuser
            </Button>
          </Flex>
        </form>
      </Box>
    </ModalFrame>
  );
};

export const showRejectDpaeModal = create<Props, boolean>(RejectDpaeModal);
