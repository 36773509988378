import { IJoinedContractor } from '@freelancelabs/teoreme-commons';
import React from 'react';
import { Avatar, Flex, Box, Text, LabelField, Radio } from 'components/ui';
import { getFirstCaracts, getFullName, getHumanDate } from '@commons'; //moved
import { Card, CardBody } from '../ui/Card';
export const ContractorCard = ({
  contractor,
  checkSage,
  checkSaleForce,
  isSelectable,
  isSelected,
  onChangeSelected,
  renderActions,
  ...props
}: {
  contractor: IJoinedContractor;
  checkSage?: boolean;
  checkSaleForce?: boolean;
  isSelectable?: boolean;
  isSelected?: boolean;
  onChangeSelected?: () => void;
  renderActions?: React.ReactNode;
}) => {
  return (
    <Box
      onClick={() =>
        isSelectable && onChangeSelected ? onChangeSelected() : false
      }
    >
      <Card isSelectable isSelected={isSelected}>
        <CardBody>
          <Flex flexWrap="wrap">
            {isSelectable && (
              <Box>
                <Radio checked={isSelected}>
                  <></>
                </Radio>
              </Box>
            )}
            <Box width={'80px'}>
              <Avatar text={getFirstCaracts(contractor)} size={80} mr={20} />
            </Box>
            <Box width={'calc(100% - 110x)'} ml={20}>
              <Box width={'calc(100% - 110x)'} ml={20}>
                <Text variant="h3">{getFullName(contractor)}</Text>
              </Box>
              <Box width={'calc(100% - 110px)'} ml={20}>
                <Text variant="disabled">
                  {getHumanDate(contractor?.birthday as Date)}
                </Text>
              </Box>
              <Box width={'calc(100% - 110px)'} ml={20}>
                <LabelField
                  label="Email:"
                  value={contractor?.user?.email || 'N/A'}
                />
              </Box>
            </Box>
          </Flex>
        </CardBody>
        <Flex padding="0 30px" alignItems="center">
          {renderActions}
        </Flex>
      </Card>
    </Box>
  );
};
