import {
  EadditionalActivityStatus,
  EMissionStructure,
  EExportModel,
} from '@freelancelabs/teoreme-commons';
import { Roles as RolesTypes } from '@freelancelabs/inside-commons/dist/lib/models/enum/role.enum';
import { Flex, Box, FormLabel, Input, Link, Text, Button } from 'components/ui';
import {
  ADDITIONAL_ACTIVITY_STATUS_SELECT,
  checkDefaultManagerSelected,
  exportFileXlxs,
  useMe,
} from '@commons';
import { StaticSelect } from 'components/selects/StaticSelect';
import { RefreshIcon } from 'components/ui/icons';
import { Theme } from 'styles';
import EXPORT_IMG from 'assets/images/export_excel_tutorial.png';
import { ReactNode, useState } from 'react';
import { UserSelect } from 'components/selects/UserSelect';
import { RadioSelectEstablishments } from 'components/selects/RadioSelectEstablishments';
import { useGlobalStore } from 'store';
import { showDialogModal } from 'components/modals/DialogModal';
import { getFilterObjectMilestones } from 'components/lists/helpers';

// const INVOICE_STATUS_SELECT_CUSTOM = INVOICE_STATUS_SELECT;
// const Findex = INVOICE_STATUS_SELECT.findIndex(
//   state => state.key === EInvoiceStatus.REJECTED
// );
// INVOICE_STATUS_SELECT_CUSTOM[Findex].label = 'Refusé';

type MilestonesFiltersListProps = {
  hookStore: any;
  row?: number;
  minified?: boolean;
  sideBar?: ReactNode;
  filterParams?: string;
  filterObject?: any;
};
export const MilestonesFiltersList = ({
  hookStore,
  row,
  minified,
  sideBar,
  filterParams,
  filterObject,
}: MilestonesFiltersListProps) => {
  const {
    initialSate,
    startDate,
    endDate,
    updateStore,
    resetStore,
    selectedStatus,
    selectedType,
    searchQuery,
    limit,
    accountManagerId,
    structureSelected,
    clientSelected,
    providerSelected,
    contractorSelected,
    multiProviderSelected,
    multiClientSelected,
    getNbActiveFilters,
  } = hookStore();
  const width = row || 1 / 6;
  const additionalFiltersFields = [
    'structureSelected',
    'clientSelected',
    'multiProviderSelected',
    'multiClientSelected',
    'providerSelected',
  ];
  const { me } = useMe();
  const { tasksInProgress, updateGlobalStore } = useGlobalStore();
  const [isLoadingExport, setIsLoadingExport] = useState(false);

  const buildQuery = () => {
    return {
      // filtre extern
      ...filterObject,
      //   // filtre interne
      ...getFilterObjectMilestones({
        debouncedFilterQuery: searchQuery || '',
        startDate,
        endDate,
        selectedStatus:
          selectedStatus && selectedStatus !== 'ALL'
            ? selectedStatus
            : filterObject?.status,
        selectedType: selectedType,
        accountManagerId: checkDefaultManagerSelected(
          me,
          accountManagerId as string
        )
          ? accountManagerId
          : undefined,
        structureSelected,
        clientSelected,
        providerSelected,
        multiClientSelected,
        multiProviderSelected,
        contractorSelected,
      }),
    };
  };

  const onExportToExcel = async () => {
    setIsLoadingExport(true);
    try {
      const exportResponse = await exportFileXlxs({
        model: EExportModel.ACTIVITY,
        query: {
          filter: { ...buildQuery(), type: 'MILESTONE' },
          //fields:[],
          // limit: limit,
          // skip: limit * page,
          // sort: sortString,
        },
      });
      const uuid = exportResponse?.taskUuid;
      if (Array.isArray(tasksInProgress)) {
        updateGlobalStore({ tasksInProgress: [...tasksInProgress, uuid] });
      } else {
        updateGlobalStore({ tasksInProgress: [uuid] });
      }
      showDialogModal({
        title: 'Export excel en cours',
        text: (
          <Box>
            <Text>
              La création de votre export excel est en cours. Cette action peut
              prendre quelques minutes. Vous pouvez suivre l'état de progression
              en cliquant sur l'icone ci-dessous.
            </Text>
            <img src={EXPORT_IMG} />
          </Box>
        ),
        confirmLabel: "J'ai compris",
      });
      setIsLoadingExport(false);
    } catch (e) {
      setIsLoadingExport(false);
    }
  };

  return (
    <Box width={1 / 1}>
      <Flex
        m="10px 0"
        display="inline-flex"
        justifyContent="flex-start"
        alignItems={'center'}
        flexWrap={'wrap'}
        width={1 / 1}
      >
        <Box width={width} ml={minified ? 10 : 0} mb={10}>
          <FormLabel>Rechercher</FormLabel>
          <Input
            isFullWidth
            data-cy="search-estaCustomer-list"
            type="search"
            name="search"
            placeholder="Rechercher ..."
            value={searchQuery}
            onChange={(e: any) => {
              updateStore({
                page: 0,
                searchQuery: e.target.value,
              });
            }}
          />
        </Box>

        <Box
          hidden={filterParams !== 'all'}
          width={width}
          ml={minified ? 10 : 0}
          mb={10}
        >
          <FormLabel>Statuts</FormLabel>
          <StaticSelect
            width={1 / 1}
            options={ADDITIONAL_ACTIVITY_STATUS_SELECT}
            value={selectedStatus}
            placeholder="Tous les statuts"
            onChange={value =>
              updateStore({
                selectedStatus: value as
                  | 'ALL'
                  | EadditionalActivityStatus
                  | undefined,
                page: 0,
              })
            }
          />
        </Box>
        <Box width={width} ml={minified ? 10 : 0} mb={10}>
          <FormLabel>Chargés de comptes</FormLabel>
          <UserSelect
            placeholder="Tous les Chargés de comptes"
            role={RolesTypes.ACCOUNT_MANAGER}
            onChange={value => updateStore({ accountManagerId: value })}
            value={accountManagerId}
          />
        </Box>
        {minified && (
          <Box width={width} ml={minified ? 10 : 0} mb={10}>
            <FormLabel>NB. LIGNES AFFICHÉES</FormLabel>
            <StaticSelect
              isClearable={false}
              placeholder=" "
              options={[
                {
                  label: '5',
                  value: 5,
                  key: 5,
                },
                {
                  label: '20',
                  value: 20,
                  key: 20,
                },
                {
                  label: '50',
                  value: 50,
                  key: 50,
                },
                {
                  label: '100',
                  value: 100,
                  key: 100,
                },
              ]}
              onChange={value =>
                updateStore({
                  limit: value as number,
                  page: 0,
                })
              }
              value={limit}
            />
          </Box>
        )}
        {minified && sideBar ? (
          <Flex
            width={'110px'}
            mt={'5px'}
            alignItems="center"
            ml={minified ? 10 : 0}
          >
            {sideBar}
            {initialSate === false &&
              getNbActiveFilters(additionalFiltersFields) > 0 && (
                <Box
                  backgroundColor={Theme?.colors?.primary?.default}
                  border={`1px solid ${Theme?.colors?.primary?.default}`}
                  borderRadius={'50%'}
                  height={15}
                  width={15}
                  ml={-2}
                  mt={-4}
                >
                  <Flex justifyContent={'center'}>
                    <Text color="white" fontSize={'10px'}>
                      {' '}
                      {getNbActiveFilters(additionalFiltersFields)}
                    </Text>
                  </Flex>
                </Box>
              )}
          </Flex>
        ) : (
          <></>
        )}
        {!minified && (
          <>
            <Box width={row} ml={minified ? 10 : 0} mb={10}>
              <FormLabel>Structure</FormLabel>
              <StaticSelect
                placeholder="Toutes les structure"
                options={[
                  {
                    label: 'Toutes les structures',
                    value: 'N/A',
                    key: 'N/A',
                  },
                  {
                    label: 'FCOM',
                    value: EMissionStructure.FCOM,
                    key: EMissionStructure.FCOM,
                  },
                  {
                    label: 'FONE',
                    value: EMissionStructure.FONE,
                    key: EMissionStructure.FONE,
                  },
                  {
                    label: 'INOPS',
                    value: EMissionStructure.INOPS,
                    key: EMissionStructure.INOPS,
                  },
                  {
                    label: 'COWORKEES',
                    value: EMissionStructure.COWORKEES,
                    key: EMissionStructure.COWORKEES,
                  },
                ]}
                onChange={value =>
                  updateStore({ structureSelected: value as string })
                }
                value={structureSelected}
              />
            </Box>
            <Box width={row} ml={minified ? 10 : 0} mb={10}>
              <FormLabel>Établissements Clients</FormLabel>
              <RadioSelectEstablishments
                filter={{ customer: { $exists: true } }}
                referenceValue="uuid"
                placeholder="Tous les clients"
                onChange={value =>
                  updateStore({
                    multiClientSelected: value,
                  })
                }
                values={multiClientSelected}
              />
            </Box>
            <Box width={row} ml={minified ? 10 : 0} mb={10}>
              <FormLabel>Établissements Fournisseurs</FormLabel>
              <RadioSelectEstablishments
                filter={{ 'provider.manager': { $exists: true } }}
                referenceValue="uuid"
                placeholder="tous les fournisseurs"
                onChange={value =>
                  updateStore({
                    multiProviderSelected: value,
                  })
                }
                values={multiProviderSelected}
              />
            </Box>
            <Box width={width} ml={minified ? 10 : 0} mb={10}>
              <FormLabel>NB. LIGNES AFFICHÉES</FormLabel>
              <StaticSelect
                isClearable={false}
                placeholder=" "
                options={[
                  {
                    label: '5',
                    value: 5,
                    key: 5,
                  },
                  {
                    label: '20',
                    value: 20,
                    key: 20,
                  },
                  {
                    label: '50',
                    value: 50,
                    key: 50,
                  },
                  {
                    label: '100',
                    value: 100,
                    key: 100,
                  },
                ]}
                onChange={value =>
                  updateStore({
                    limit: value as number,
                    page: 0,
                  })
                }
                value={limit}
              />
            </Box>
          </>
        )}

        <Flex width={width} alignItems="center" ml={minified ? 10 : 0} mb={10}>
          <Link
            isDisabled={initialSate}
            iconLeft={
              <RefreshIcon
                fill={initialSate ? Theme?.colors?.grey?.default : undefined}
              />
            }
            mt={15}
            onClick={() => (!initialSate ? resetStore({}) : false)}
          >
            Réinitialiser
          </Link>
          <Box>
            <Button
              ml={'10px'}
              isLoading={isLoadingExport}
              onClick={() => onExportToExcel()}
            >{`Exporter`}</Button>
          </Box>
        </Flex>
      </Flex>
    </Box>
  );
};
