import React from 'react';
import ReactSelect from 'react-select';
import { Props } from 'react-select';
import styled, { useTheme } from 'styled-components';
import { boxProperties, BoxProps } from './Box';
import CSSProperties from 'styled-components';
import { Theme } from 'styles';
export const selectStyles = (
  theme: any,
  props: Props & { bcWhite: boolean; isFieldSet?: boolean; isFocused: boolean }
) => ({
  container: (provided: typeof CSSProperties) => ({
    ...provided,
    width: '100%',
    backgroundColor: props.bcWhite
      ? 'white !important'
      : props.isDisabled
        ? '#edf6f9 !important'
        : undefined,
    // border: props.isDisabled ? '1px solid #819CAD !important' : undefined,
    borderRadius: props.isDisabled ? '4px !important' : undefined,
    cursor: props.isDisabled ? 'not-allowed' : 'initial',
  }),
  control: (provided: typeof CSSProperties, state: any) => ({
    ...provided,
    minHeight: '50px',
    height: props?.isMulti ? 'auto' : '50px',
    backgroundColor: props.isDisabled ? '#edf6f9 !important' : undefined,
    cursor: props.isDisabled ? 'not-allowed' : 'initial',
    borderRight: props?.isFieldSet && 'none',
    borderRadius: '4px 0px 0px 4px',
    boxShadow: state.isFocused
      ? `1px solid ${Theme?.colors?.primary?.default} !important`
      : undefined,
    border: state.isFocused
      ? `1px solid ${Theme?.colors?.primary?.default} !important`
      : undefined,
  }),
  option: (provided: typeof CSSProperties, state: any) => ({
    ...provided,
    fontSize: '14px',
    color: theme.colors.darkBlue,
    cursor: props.isDisabled ? 'not-allowed' : 'initial',
    backgroundColor: state.isSelected ? theme.colors.lightBlue : undefined,
  }),
  singleValue: (provided: typeof CSSProperties) => ({
    ...provided,
    fontSize: '14px',
    color: theme.colors.darkBlue,
    cursor: props.isDisabled ? 'not-allowed' : 'initial',
  }),
});
//#819CAD
const SelectStyled = styled(ReactSelect)<BoxProps>`
  background-color: ${props =>
    props.isDisabled ? '#edf6f9 !important' : undefined};
  cursor: ${props => (props.isDisabled ? 'not-allowed' : 'initial')};
  ${boxProperties};
`;
export const Select = (
  props: { bcWhite?: Boolean; isFieldSet?: boolean } & BoxProps &
    Omit<Props, 'theme'>
) => {
  const theme = useTheme();
  // const DropdownIndicator = (props: DropdownIndicatorProps<any, true>) => {
  //   return (
  //     <components.DropdownIndicator {...props}>
  //       <AddIcon />
  //     </components.DropdownIndicator>
  //   );
  // };
  return (
    <SelectStyled
      id={props.id}
      //@ts-ignore
      styles={selectStyles(theme, props)}
      //components={{ IndicatorSeparator: () => null }}
      {...props}
    />
  );
};
