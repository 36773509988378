import {
  getHumanDate,
  queryClient,
  refreshCustomerToken,
  useInvoiceIbanFindMany,
  buildOneLineAddress,
  useCraFindMany,
  craFindMany,
  additionalActivityFindMany,
} from '@commons';
import {
  EMissionCraValidatedBy,
  EMissionStatus,
  IEstablishment,
  IJoinedMission,
  IUpdateMissionData,
  EBillingType,
  IInvoiceAddress,
  ECraStatus,
  EadditionalActivityStatus,
  EadditionalActivityType,
} from '@freelancelabs/teoreme-commons';
import { EstablishmentStructuredCard } from 'components/cards/EstablishmentStructuredCard';
import { UserStructuredCard } from 'components/cards/UserStructuredCard';
import { showAddMissionCustomerModal } from 'components/modals/AddMissionCustomerModal';
import { Section } from 'components/Section';
import {
  BlocInformation,
  Box,
  Button,
  CheckSwitch,
  Link,
  Spinner,
  Text,
} from 'components/ui';
import { AddIcon, EditIcon } from 'components/ui/icons';
import { useSetCrumbs } from 'hooks/breadCrumb';
import { useCreateOrUpdateMission, useGetMission } from 'hooks/missionHooks';
import { useShowMessage } from 'hooks/useShowMessage';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { POCard } from './POCard';
import { QuoteCard } from './QuoteCard';
import { showAddProviderCustomMessageModal } from 'components/modals/AddProviderCustomMessageModal';
import { isMissionFieldEditable } from 'helpers';
import { StructuredCard } from 'components/cards';
import AddBloc from 'components/AddBloc';

type ClientProps = {
  isDisabled: boolean;
  amendments: Array<IJoinedMission> | [];
};
export const Client = ({ isDisabled, amendments }: ClientProps) => {
  const [isLoadingValidateCra, setIsLoadingValidateCra] =
    useState<boolean>(false);
  const [hasSetDefaultIban, setHasSetDefaultIban] = useState(false);
  const { mission, reference } = useGetMission();
  const [missionHasMilestonesOrCras, setMissionHasMilestonesOrCras] =
    useState(true);
  let cras: any = [];
  let enableUpdate = false;
  const { data: ibans } = useInvoiceIbanFindMany({
    filterObject: {
      'scopes.structure': mission?.billingInformation?.structure,
    },
  });

  const [createOrUpdate] = useCreateOrUpdateMission();
  const [craValidatedBy, setCraValidatedBy] = React.useState(
    mission?.craValidatedBy?.includes(EMissionCraValidatedBy?.CUSTOMER_CONTACT)
  );
  const [isLoadingRefreshToken, setIsLoadingRefreshToken] = useState(false);

  const showMessage = useShowMessage();
  useSetCrumbs(
    [],
    [
      { label: 'prestations', path: '/delivery' },
      { label: 'missions', path: '/delivery/missions' },
      {
        label: mission?.displayReference || 'nouvelle mission',
        path: `/delivery/missions/${reference}`,
      },
      {
        label: 'client',
        path: `/delivery/missions/${reference}/client`,
      },
    ]
  );
  useEffect(() => {
    const getValidatedCras = async () => {
      if (reference) {
        try {
          const craData = await craFindMany({
            filterObject: {
              mission: reference,
              state: {
                $in: [ECraStatus.VALIDATED, ECraStatus.TO_BE_VALIDATED],
              },
            },
            limit: 1,
            skip: 0,
          });
          cras = craData?.cras;
          if (cras && cras?.length > 0) {
            setMissionHasMilestonesOrCras(true);
          } else {
            setMissionHasMilestonesOrCras(false);
          }
        } catch (error) {
          console.log('error getting cras of this mission', error);
        }
      }
    };

    const getValidatedMilestones = async () => {
      if (reference) {
        try {
          const mileStonesData = await additionalActivityFindMany({
            filterObject: {
              mission: reference,
              status: {
                $in: [
                  EadditionalActivityStatus.VALIDATED,
                  EadditionalActivityStatus.TO_BE_VALIDATED,
                ],
              },
              type: EadditionalActivityType.MILESTONE,
            },
            limit: 1,
            skip: 0,
          });
          cras = mileStonesData?.additionalActivities;
          if (cras && cras?.length > 0) {
            setMissionHasMilestonesOrCras(true);
          } else {
            setMissionHasMilestonesOrCras(false);
          }
        } catch (error) {
          console.log('error getting cras of this mission', error);
        }
      }
    };

    if (mission?.billing?.type === EBillingType.FLAT_RATE) {
      getValidatedMilestones();
    } else {
      getValidatedCras();
    }
  }, [reference]);

  type FormValues = any;
  const getMissionInvoiceAdress = () => {
    const missionCustomerEstablishment = mission?.customer?.establishment;
    const missionCustomerEstablishmentExistingIvAdr =
      //@ts-ignore
      mission?.customer?.billingOptions?.invoiceAddress;
    if (missionCustomerEstablishmentExistingIvAdr)
      return buildOneLineAddress(
        missionCustomerEstablishmentExistingIvAdr as IInvoiceAddress
      );

    const defaultAdress = buildOneLineAddress(
      missionCustomerEstablishment?.address
    );
    const othersInvoiceAdresses =
      missionCustomerEstablishment?.customer?.invoiceAddress || [];
    const othersDefaultAdress = othersInvoiceAdresses?.filter(
      e => e.default && !e.locked
    )[0];
    const textAdress = buildOneLineAddress(othersDefaultAdress as any);

    return textAdress || defaultAdress || 'adress';
  };
  // const getMissionInvoiceAdressText = () =>
  const { setValue } = useForm<FormValues>({
    defaultValues: {
      customer: {
        ...mission?.customer,
        billingOptions: {
          ...mission?.customer?.billingOptions,
          invoiceAddress: getMissionInvoiceAdress(),
        },
      },
    },
  });

  const getDefaultIban = () => {
    let matchIbanUuid = false;
    if (!matchIbanUuid) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const primaryMatch = ibans?.ibans?.forEach((IB: any) => {
        IB?.scopes?.forEach((scope: any) => {
          // TODO SEARCH BY STRCUTURE FCOM /FONE
          if (
            scope?.primary &&
            scope?.structure === mission?.billingInformation?.structure
          ) {
            matchIbanUuid = IB?.uuid;
            return IB?.uuid;
          }
        });
      });
    }
    return matchIbanUuid;
  };
  const missionHasNoValidatedCraOrMilestone =
    mission?.billingInformation?.missionType?.label !== 'CLASSIC' &&
    mission?.status === EMissionStatus?.VALIDATED &&
    !missionHasMilestonesOrCras;
  // TEOR-5713 new Rules
  enableUpdate =
    mission?.status === EMissionStatus?.DRAFT ||
    missionHasNoValidatedCraOrMilestone;

  const client = mission?.customer;

  const customMessageForProvider =
    //@ts-ignore
    mission?.customer?.customMessageForProvider ||
    //@ts-ignore
    mission?.customer?.establishment?.customer?.customMessageForProvider;

  const onUpdateValidateCraBy = async (value: boolean) => {
    setIsLoadingValidateCra(true);
    setCraValidatedBy(value);
    try {
      const mission: IUpdateMissionData = {
        craValidatedBy: value
          ? [
              EMissionCraValidatedBy?.ACCOUNT_MANAGER,
              EMissionCraValidatedBy?.CUSTOMER_CONTACT,
            ]
          : [EMissionCraValidatedBy?.ACCOUNT_MANAGER],
      };
      await createOrUpdate(reference, mission);
      queryClient?.refetchQueries({ queryKey: [reference] });
    } catch (e) {
      setIsLoadingValidateCra(false);
    }
    setIsLoadingValidateCra(false);
  };
  const onRefreshToken = async () => {
    setIsLoadingRefreshToken(true);
    try {
      await refreshCustomerToken({ token: mission?.craToken });
      showMessage('success', 'Un nouveau lien à été envoyé par email');
      queryClient?.refetchQueries({ queryKey: [reference] });
      setIsLoadingRefreshToken(false);
    } catch (e) {
      setIsLoadingRefreshToken(false);
    }
  };

  // eslint-disable-next-line react-hooks/rules-of-hooks
  React.useEffect(() => {
    if (
      ibans &&
      ibans?.ibans?.length > 0 &&
      !mission?.customer?.billingOptions?.iban &&
      !hasSetDefaultIban
    ) {
      setHasSetDefaultIban(true);
      const iban = getDefaultIban();

      setValue('establishment.customer.billingOptions.iban', iban);
    }
  }, [ibans, hasSetDefaultIban]);
  const onUpdateBillingOptions = (missionUpdated: IJoinedMission) => {
    if (missionUpdated) {
      setValue(
        'customer.billingOptions.isAutoBilling',
        missionUpdated?.customer?.billingOptions?.isAutoBilling
      );
      setValue(
        'customer.billingOptions.iban',
        missionUpdated?.customer?.billingOptions?.iban
      );
      setValue(
        'customer.billingOptions.isAutoSendEmail',
        missionUpdated?.customer?.billingOptions?.isAutoSendEmail
      );
      setValue(
        'customer.billingOptions.paymentDeadline',
        missionUpdated?.customer?.billingOptions?.paymentDeadline
      );
      setValue(
        'customer.billingOptions.paymentLabel',
        missionUpdated?.customer?.billingOptions?.paymentLabel
      );
      setValue(
        'customer.billingOptions.sendMethod',
        missionUpdated?.customer?.billingOptions?.sendMethod
      );
      if (
        missionUpdated?.craValidatedBy?.includes(
          EMissionCraValidatedBy?.CUSTOMER_CONTACT
        )
      ) {
        onUpdateValidateCraBy(true);
      } else {
        onUpdateValidateCraBy(false);
      }
    }
  };
  return (
    <Box>
      <Box>
        <Section variant="h2" title="Informations du client">
          <Section variant="h3" title="Établissement client">
            <EstablishmentStructuredCard
              forRole={'CUSTOMER'}
              checkSage
              mb={20}
              establishment={client?.establishment as IEstablishment}
              renderActions={
                enableUpdate && (
                  <Box>
                    <Link
                      ml={10}
                      mt={'-5px'}
                      iconLeft={<EditIcon />}
                      onClick={() => {
                        if (isDisabled) return;
                        showAddMissionCustomerModal({
                          method: 'UPDATE',
                          mission: mission,
                          missionHasNoValidatedCraOrMilestone,
                        })?.then(updateMission => {
                          //@ts-ignore
                          onUpdateBillingOptions(updateMission);
                        });
                      }}
                      isDisabled={isDisabled}
                    >
                      Modifier
                    </Link>
                  </Box>
                )
              }
            />
          </Section>
          <Section variant="h3" title="Accord Cadre">
            {mission?.marketData ? (
              <StructuredCard
                mb={20}
                avatarLetter={`${mission?.marketData?.marketName?.[0]}${mission?.marketData?.marketName?.[1]}`}
                primaryTopText={mission?.marketData?.marketName}
                secondaryTopText={mission?.marketData?.marketId}
                primaryBottomText={getHumanDate(mission?.marketData?.startDate)}
                secondaryBottomText={getHumanDate(mission?.marketData?.endDate)}
                secondLineBottomText={mission?.marketData?.legalStructure}
              />
            ) : (
              <AddBloc>
                <Link
                  isDisabled={
                    !isMissionFieldEditable(mission, 'marketData') || isDisabled
                  }
                  onClick={() => {
                    if (
                      !isMissionFieldEditable(mission, 'marketData') ||
                      isDisabled
                    )
                      return;
                    showAddMissionCustomerModal({
                      method: 'UPDATE',
                      mission: mission,
                    })?.then(updateMission => {
                      //@ts-ignore
                      onUpdateBillingOptions(updateMission);
                    });
                  }}
                  iconLeft={
                    isMissionFieldEditable(mission, 'marketData') ? (
                      <AddIcon />
                    ) : (
                      false
                    )
                  }
                >
                  {' '}
                  Ajouter un accord cadre
                </Link>
              </AddBloc>
            )}
          </Section>
          <Section variant="h3" title="Contact client">
            <UserStructuredCard
              mb={20}
              user={client?.contact as any}
              establishment={client?.establishment}
              forRole={'CUSTOMER'}
              checkSage
              renderActions={
                // // TEOR-5330 Rules updated!
                (mission?.status === EMissionStatus.DRAFT || enableUpdate) && (
                  <Box>
                    <Link
                      ml={10}
                      mt={'-5px'}
                      iconLeft={<EditIcon />}
                      isDisabled={isDisabled}
                      onClick={() => {
                        if (isDisabled) return;
                        showAddMissionCustomerModal({
                          method: 'UPDATE',
                          mission: mission,
                        })?.then((updateMission: any) => {
                          //@ts-ignore
                          onUpdateBillingOptions(updateMission);
                        });
                      }}
                    >
                      Modifier
                    </Link>
                  </Box>
                )
              }
            />
          </Section>
        </Section>

        <Section mb={20} title="Devis client">
          {mission?.isMandateMode ? (
            mission?.provider?.establishment ? (
              <QuoteCard
                mission={mission as IJoinedMission}
                isDisabled={
                  isDisabled ||
                  !isMissionFieldEditable(mission, 'customer.quote')
                }
              />
            ) : (
              <BlocInformation>
                Veuillez renseigner un établissement fournisseur pour pouvoir
                générer un devis
              </BlocInformation>
            )
          ) : (
            <QuoteCard
              mission={mission as IJoinedMission}
              isDisabled={
                isDisabled || !isMissionFieldEditable(mission, 'customer.quote')
              }
            />
          )}
        </Section>
        <Section variant="h2" title="Bon de commande client">
          <POCard mission={mission as IJoinedMission} isDisabled={isDisabled} />
        </Section>
        {mission?.billing?.type === EBillingType.DAY && (
          <Section
            mb={0}
            variant="h2"
            title={`Options de dépôt du ${
              mission?.billing?.type === EBillingType.DAY ? 'cra' : 'jalon'
            }`}
          >
            <Box mb={20}>
              <CheckSwitch
                isDisabled={isLoadingValidateCra}
                mt={20}
                id="shouldValidateCra"
                defaultChecked={craValidatedBy}
                onChange={e => onUpdateValidateCraBy(e?.target?.checked)}
                checked={craValidatedBy as boolean}
              >
                {isLoadingValidateCra ? (
                  <Spinner />
                ) : (
                  <Text variant="p">
                    {craValidatedBy
                      ? 'Permettre au partenaire de soumettre son CRA au client'
                      : 'Ne pas permettre au partenaire de soumettre son CRA au client'}
                  </Text>
                )}
              </CheckSwitch>

              {mission?.craToken &&
                craValidatedBy &&
                (mission?.status === EMissionStatus?.VALIDATED ||
                  mission?.status === EMissionStatus?.TERMINATED) && (
                  <Box mt={20}>
                    <Button
                      isLoading={isLoadingRefreshToken}
                      onClick={onRefreshToken}
                    >
                      Envoyer le lien au client
                    </Button>
                    <Text mt={10}>
                      Dernier envoi le :{' '}
                      {mission?.emailSentToCustomerAt
                        ? `${getHumanDate(
                            mission?.emailSentToCustomerAt,
                            'dd/MM/yyyy à HH:mm'
                          )} à ${mission?.customer?.contact?.email}`
                        : 'N/A'}
                    </Text>
                  </Box>
                )}
            </Box>
          </Section>
        )}
        <Section
          title={
            <Text variant="h2">
              Message à afficher au partenaire lors du dépôt de son{' '}
              {mission?.billing?.type === EBillingType.DAY ? 'cra' : 'jalon'}
              <Link
                iconLeft={<EditIcon scale={20} />}
                ml={10}
                isDisabled={isDisabled}
                onClick={() => {
                  if (isDisabled) return;
                  showAddProviderCustomMessageModal({
                    customMessageForProvider: customMessageForProvider,
                    beforeValidation: async (
                      customMessageForProvider?: string
                    ) => {
                      if (customMessageForProvider !== undefined) {
                        try {
                          const mission: IUpdateMissionData = {
                            customer: {
                              //@ts-ignore
                              customMessageForProvider:
                                customMessageForProvider,
                            },
                          };
                          await createOrUpdate(reference, mission);
                          queryClient?.refetchQueries({
                            queryKey: [reference],
                          });
                        } catch (e) {
                          //
                        }
                      }
                    },
                  });
                }}
              >
                Modifier
              </Link>
            </Text>
          }
        >
          <Box ml={10}>
            {customMessageForProvider &&
              //@ts-ignore
              !mission?.customer?.customMessageForProvider && (
                <BlocInformation>
                  <p>
                    Le paramètrage au niveau de l'établissement client va étre
                    appliqué.
                  </p>
                </BlocInformation>
              )}
            {customMessageForProvider ? (
              <div
                dangerouslySetInnerHTML={{
                  __html: customMessageForProvider,
                }}
              ></div>
            ) : (
              'N/A'
            )}
          </Box>
        </Section>
      </Box>
    </Box>
  );
};
