import { useUserFindMany } from '@commons';
import { Header } from 'components/Header';
import { showAddOtherContactModal } from 'components/modals/AddOtherContactModal';
import {
  Flex,
  //Menu,
  //ALink,
  Box,
  Button,
  Container,
  Input,
  Link,
  SpinnerBox,
  Table,
  TableColumn,
  Text,
  Tab,
  ALink,
} from 'components/ui';
import { EyeIcon, DotsIcon } from 'components/ui/icons';
import { ERoles } from '@commons';
import { useBreadCrumb } from 'hooks/breadCrumb';
import { useDebounce } from 'hooks/useDebounce';
import React, { useEffect } from 'react';
import { StaticSelect } from 'components/selects/StaticSelect';
import { /*useHistory,*/ useParams } from 'react-router-dom';
import { Theme } from 'styles';

const tabItems = (
  <>
    <Tab variant="secondary" href={'/others/contacts/active'}>
      Actifs
    </Tab>
    {false && (
      <Tab variant="secondary" href={'/others/contacts/archived'}>
        Archivés
      </Tab>
    )}
  </>
);
const getRoleNames = (roles: any) => {
  let isMulti = false;
  //@ts-ignore
  const arrRoles = [];
  roles.forEach((role: any) => {
    if (role.name === ERoles.BUSINESS_PARTNER) {
      if (isMulti) {
        arrRoles.push(", Apporteur d'affaires ");
      } else {
        arrRoles.push("Apporteur d'affaires ");
        isMulti = true;
      }
    }
    if (role.name === ERoles.FREELANCE_PARTNER) {
      if (isMulti) {
        arrRoles.push(', Freelance partner ');
      } else {
        arrRoles.push('Freelance partner ');
        isMulti = true;
      }
    }
    if (role.name === ERoles.INTERNAL_COMMERCIAL) {
      if (isMulti) {
        arrRoles.push(', Commercial interne ');
      } else {
        arrRoles.push('Commercial interne ');
        isMulti = true;
      }
    }
  });
  //@ts-ignore
  return arrRoles;
};
export type Props = {};

// map user in the table
const columns: TableColumn[] = [
  {
    key: 'lastName',
    sortKey: 'lastName',
    text: 'NOM',
    render: item => item.lastName + ' ' + item.firstName,
  },
  { key: 'phone', sortKey: 'phone', text: 'TÉLÉPHONE' },
  { key: 'email', text: 'EMAIL' },
  {
    key: 'roles',
    text: 'TYPE DE CONTACT',
    render: item => (
      <div style={{ fontStyle: 'italic' }}>
        {getRoleNames(item.roles).map(text => text)}
      </div>
    ),
  },
  {
    key: 'actions',
    text: 'ACTIONS',
    align: 'flex-end',
    render: item => (
      <Flex>
        <Link iconLeft={<EyeIcon fill={Theme?.colors?.primary?.default} />}>
          <ALink href={`/others/contacts/${item.cognitoUserId}/information`}>
            Voir la fiche
          </ALink>
        </Link>
        <Box ml={10}>
          <DotsIcon fill={Theme?.colors?.primary?.default} fontSize={20} />
        </Box>

        {/*
        <Menu
          align="right"
          ml={10}
          menuItems={
          <Link iconLeft={<EyeIcon fill="#00ABEF" />}>
            <ALink href={`/others/contacts/${item.cognitoUserId}`}>
              Voir la fiche
            </ALink>
          </Link>}
        >
          <DotsIcon fill="#00ABEF" fontSize={20} />
          </Menu>*/}
      </Flex>
    ),
  },
];

export const OthersContactsList: React.FC<
  React.PropsWithChildren<Props>
> = () => {
  const [contactRole, setContactRole] = React.useState<string | undefined>(
    'ALL'
  );
  const { setCrumbs } = useBreadCrumb();
  const { filter } = useParams<{ filter: string }>();
  useEffect(() => {
    setCrumbs([
      {
        label: 'Autres contacts',
        path: '/others',
      },
      {
        label:
          filter === 'active'
            ? 'Actifs'
            : filter === 'archived'
              ? 'Archivés'
              : '',
        path: '/providers/contacts/' + filter,
      },
    ]);
  }, [filter, setCrumbs]);

  // order ui state
  const [order, setOrder] = React.useState<'desc' | 'asc' | undefined>('asc');
  const [sortedBy, setSortedBy] = React.useState('lastName firstName');
  const sortString = (order === 'desc' ? '-' : '') + sortedBy;

  const [page, setPage] = React.useState(0);
  const [filterQuery, setFilterQuery] = React.useState('');
  const debouncedFilterQuery = useDebounce(filterQuery, 500);

  const {
    data: contactsQuery,
    status,
    isFetching,
    isPending,
  } = useUserFindMany({
    filterObject: {
      'roles.name': contactRole !== 'ALL' ? contactRole : undefined,
      $and: [
        {
          $or: [
            { 'roles.name': ERoles.BUSINESS_PARTNER },
            { 'roles.name': ERoles.FREELANCE_PARTNER },
            { 'roles.name': ERoles.INTERNAL_COMMERCIAL },
          ],
        },
        {
          $or: [
            { firstName: { $regex: debouncedFilterQuery, $options: 'i' } },
            { lastName: { $regex: debouncedFilterQuery, $options: 'i' } },
            {
              email: {
                $regex: debouncedFilterQuery?.replace('+', '\\+').trim(),
                $options: 'i',
              },
            },
          ],
        },
      ],
      //isConfirmed: filter === 'active' ? true  : false,
    },
    sort: sortString,
    limit: 20,
    skip: page * 20,
  });
  const totalCount = contactsQuery?.totalCount;
  const loading = isPending;

  const onSortedChange = async (
    shortBy: string,
    order: 'desc' | 'asc'
  ): Promise<void> => {
    setSortedBy(shortBy);
    setOrder(order);
  };

  const onAddContact = () => showAddOtherContactModal();

  // we need to give items a key
  const items =
    contactsQuery?.users.map((u: any) => ({
      key: u.cognitoUserId || '',
      ...u,
    })) || [];
  React.useEffect(() => {
    setPage(0);
  }, [filter, debouncedFilterQuery]);
  return (
    <Box>
      <Header
        tabs={tabItems}
        actions={<Button onClick={onAddContact}>Ajouter un contact</Button>}
      >
        <Text variant="h1">Autres Contacts</Text>
      </Header>
      <Container>
        <Flex>
          <Box m="10px" width={1 / 3}>
            <Input
              isFullWidth
              type="search"
              name="search"
              placeholder="Rechercher un nom, email, établissement..."
              onChange={(e: any) => setFilterQuery(e.target.value)}
            />
          </Box>
          <Box ml={10} m="10px" width={1 / 3}>
            <StaticSelect
              options={[
                { value: 'ALL', label: 'Tous les types de contact' },
                { value: 'FREELANCE_PARTNER', label: 'Freelance partner' },
                { value: 'BUSINESS_PARTNER', label: "Apporteur d'affaires" },
                {
                  value: 'INTERNAL_COMMERCIAL',
                  label: 'Commercial interne',
                },
              ]}
              onChange={value => setContactRole(value as string)}
              value={contactRole as string}
              name="type"
              placeholder="Type de contact"
            />
          </Box>
        </Flex>
        {loading && <SpinnerBox />}
        {!loading && (
          <Table
            columns={columns}
            items={items}
            sortedBy={sortedBy}
            order={order}
            page={page}
            total={totalCount}
            perPage={totalCount && totalCount < 20 ? totalCount : 20}
            onSortedChange={onSortedChange}
            onChangePage={page => setPage(page)}
            isLoading={isFetching}
          />
        )}
      </Container>
    </Box>
  );
};

export default OthersContactsList;
