import { create } from 'zustand';

enum filterType {
  ACTIVE = 'active',
}
enum sortType {
  ASC = 'asc',
  DESC = 'desc',
}
type State = {
  initialSate: boolean;
  filter: filterType;
  order?: sortType;
  sortedBy: string;
  searchQuery: string;
  startDate: Date | null;
  endDate: Date | null;
  statusSelect?: string;
  typeSelected?: string;
  page: number;
  updateCustomerEstablishmentFeeListStore: (state: Partial<State>) => void;
  resetCustomerEstablishmentFeeListStore: (state: Partial<State>) => void;
};
const initialStates = {
  initialSate: true,
  filter: filterType?.ACTIVE,
  order: sortType?.ASC,
  sortedBy: 'lastName firstName',
  searchQuery: '',
  startDate: null,
  endDate: null,
  statusSelect: 'NONE',
  typeSelected: 'N/A',
  page: 0,
};

const store = (set: any) => ({
  ...initialStates,
  updateCustomerEstablishmentFeeListStore: (state: Partial<State>) =>
    set({ initialSate: false, page: 0, ...state }),
  resetCustomerEstablishmentFeeListStore: (state: Partial<State>) =>
    set({ ...initialStates, ...state }),
});

export const useCustomerEstablishmentFeeListStore = create<State>(store);
