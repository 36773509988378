import * as React from 'react';
import styled from 'styled-components';
import { BoxProps, boxProperties } from '../Box';
const DividerH = styled.div`
  width: 80%;
  height: 1px;
  margin-top: 10px;
  margin-bottom: 10px;
  background: ${p => p.theme.colors.lightBlue};
  ${boxProperties}
`;
type Props = BoxProps & {
  width?: string;
  marginLeft?: string;
};

export const Divider: React.FC<React.PropsWithChildren<Props>> = props => {
  const { width, marginLeft } = props;
  return (
    <DividerH
      style={{
        width: width ? width : '100%',
        marginLeft: marginLeft ? marginLeft : undefined,
      }}
    />
  );
};
Divider.displayName = 'ContentLayout';

export default Divider;
