import { createRoot } from 'react-dom/client';

import './i18n';
import App from './App';
// import '@freelancelabs/themes/src/standard';
// import '@freelancelabs/styles';

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(<App />);
