import { Box, SpinnerBox } from 'components/ui';
import React from 'react';
import { create } from 'react-modal-promise';
import { ModalFrame, ModalProps } from './ModalFrame';
import { useEstablishmentFindOne } from '@commons'; // moved
import { AddPaiementEstaProviderForm } from 'forms/AddPaiementEstaProviderForm';

type Props = ModalProps & {
  beforeValidation?: () => void;
  id: string;
};

export const AddPaiementEstaProviderModal = ({
  onResolve,
  isOpen,
  id,
}: Props) => {
  const { data: establishment, status } = useEstablishmentFindOne(id);

  const loading = status === 'pending';

  return (
    <ModalFrame
      isOpen={isOpen}
      onClose={() => onResolve(false)}
      width={670}
      closeIcon
    >
      <Box>
        {loading && <SpinnerBox />}
        {!loading && establishment && (
          <AddPaiementEstaProviderForm
            establishment={establishment}
            onClose={(bool: boolean) => onResolve(bool)}
          />
        )}
      </Box>
    </ModalFrame>
  );
};

export const showAddPaiementEstaProviderModal = create<Props>(
  AddPaiementEstaProviderModal
);
