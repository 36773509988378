import React, { forwardRef, InputHTMLAttributes, useRef } from 'react';
import styled from 'styled-components';
import { SpaceProps } from 'styled-system';

import { Box, BoxProps } from './Box';
import { useCombinedRefs } from './helpers/useCombinedRefs';
import { CheckIcon } from './icons';

const Checker = styled.div`
  width: 15px;
  min-width: 15px;
  height: 15px;
  border: 1px solid #ccc;
  border-radius: 3px;
  color: #003cc2;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  svg {
    min-width: 16px;
    min-height: 16px;
    transform: scale(0.8);
    stroke-width: 2px;
  }
`;

const Label = styled.label<{ isDisabled?: boolean }>`
  cursor: ${props => (props.isDisabled ? 'not-allowed' : 'pointer')};
  display: flex;
  line-height: 15px;
  align-items: flex-start;
  font-size: 12px;
  font-weight: 500;
  border-radius: 5px;
`;

const CheckboxContainer = styled(Box)<BoxProps & { isDisabled?: boolean }>`
  position: relative;
  /* Hide the browser's default checkbox */
  input {
    position: absolute;
    opacity: 0;
    cursor: ${props => (props.isDisabled ? 'not-allowed' : 'pointer')};
    height: 0;
    width: 0;
  }

  input:focus ~ ${Label}, &:hover ${Label} {
    ${Checker} {
      border-color: ${props => props.theme.colors.primary.default};
    }
  }

  input:not(checked) ~ ${Label} {
    ${Checker} {
      svg {
        display: none;
      }
    }
  }
  input:checked ~ ${Label} {
    ${Checker} {
      background-color: ${props => props.theme.colors.primary.default};
      border-color: ${props => props.theme.colors.primary.default};
      svg {
        fill: white;
        display: block;
      }
    }
  }
`;

type CheckboxProps = {
  children: React.ReactNode;
  id: string;
  name?: string;
  isDisabled?: boolean;
  childrenWidth?: string | number;
};

export const CheckBox = forwardRef(
  (
    {
      children,
      id,
      name,
      onChange,
      checked,
      defaultChecked,
      childrenWidth,
      ...props
    }: InputHTMLAttributes<HTMLInputElement> & CheckboxProps & SpaceProps,
    ref
  ) => {
    const thisRef = useRef(null) as React.RefObject<HTMLInputElement | null>;
    const combinedRef = useCombinedRefs(
      ref,
      thisRef
    ) as React.RefObject<HTMLInputElement>;

    return (
      <CheckboxContainer {...props}>
        <input
          disabled={props.isDisabled ? true : false}
          type="checkbox"
          ref={combinedRef}
          id={id}
          name={name}
          onChange={onChange}
          checked={checked}
          defaultChecked={defaultChecked}
        />
        <Label isDisabled={props.isDisabled} htmlFor={id}>
          <Checker>
            <CheckIcon color="#fff" />
          </Checker>

          <Box width={childrenWidth ? childrenWidth : 'auto'}>{children}</Box>
        </Label>
      </CheckboxContainer>
    );
  }
);
