import React, { useEffect } from 'react';
import NavigationPrompt from 'react-router-navigation-prompt';
import { showDialogModal } from './DialogModal';
import { usePreventClose } from './ModalFrame';

const prompt = (event: BeforeUnloadEvent) => {
  event.returnValue = `Êtes-vous sûr(e) de vouloir quitter cette page sans enregistrer ?`;
};

export const ConfirmNavigationModal = ({ active }: { active: boolean }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  usePreventClose()?.(active);
  useEffect(() => {
    if (active) window.addEventListener('beforeunload', prompt);
    return () => window.removeEventListener('beforeunload', prompt);
  }, [active]);

  return (
    <NavigationPrompt
      disableNative
      renderIfNotActive={false}
      when={(crntLocation, nextLocation) => {
        // When token expire redirect without confirmModal
        if (nextLocation?.pathname === '/login') {
          return false;
        }
        return active && crntLocation.pathname !== nextLocation?.pathname;
      }}
    >
      {({ isActive, onCancel, onConfirm }) => {
        // IsOpen fix while open modal
        if (isOpen === false) {
          setIsOpen(true);
          showDialogModal({
            title:
              'Êtes-vous sûr(e) de vouloir quitter cette page sans enregistrer ?',
            text: 'Toutes vos modifications seront perdues.',
            confirmLabel: 'Confirmer',
            cancelLabel: 'Annuler',
          }).then(confirm => {
            if (confirm) {
              onConfirm();
              setIsOpen(false);
            } else {
              onCancel();
              setIsOpen(false);
            }
          });
        }
        return null;
      }}
    </NavigationPrompt>
  );
};
