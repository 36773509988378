import { TopBar } from 'components/TopBar';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { OthersContactsList } from './Contacts/List';

import { OtherContactsDetails } from './Contacts/Details';
export const Others = () => {
  return (
    <>
      <TopBar />
      <Switch>
        <Route exact path="/others">
          <Redirect to="/others/contacts" />
        </Route>
        <Route path="/others/contacts/:filter(active|archived)">
          <OthersContactsList />
        </Route>
        <Route path="/others/contacts/:id/information">
          <OtherContactsDetails />
        </Route>
        <Route path="/others/contacts/:id/establishments">
          <OtherContactsDetails />
        </Route>
      </Switch>
    </>
  );
};
