import { useAlertStore } from 'store/alert';
// Hook
export function useShowMessage(): (
  variant: 'error' | 'success' | 'info',
  message: string,
  errorLogs?: any
) => void {
  const { updateAlert } = useAlertStore();
  return (
    variant: 'error' | 'success' | 'info',
    message: string,
    errorLogs?: any
  ) => {
    updateAlert({
      open: true,
      variant,
      message,
      errorLogs,
    });
  };
}
