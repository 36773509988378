import {
  buildOneLineAddress,
  checkEmail,
  contractorFindMany,
  contractorNeedDpae,
  contractorUpdateOne,
  CONTRACTOR_SOCIAL_STATUS,
  cutLongText,
  escapeEmail,
  getHumanDate,
  getTradeNameSafe,
  userFindMany,
  validateFormPhoneFormatFR,
  queryClient,
} from '@commons';
import {
  EContractorStatus,
  IEstablishment,
  IJoinedContractor,
} from '@freelancelabs/teoreme-commons';
import { showRejectContractorModal } from 'components/modals/contractors/RejectContractorModal';
import { ValidateContractorModal } from 'components/modals/contractors/ValidateContractor';
import { showDialogModal } from 'components/modals/DialogModal';
import { showDisplayPdfModal } from 'components/modals/DisplayPdfModal';
import {
  Box,
  Divider,
  Flex,
  FormControl,
  Input,
  LabelField,
  Link,
  Radio,
  Row,
  Text,
  PhoneInputControlled,
  DatePickerControlled,
} from 'components/ui';
import {
  ChevronDownIcon,
  ChevronUpIcon,
  DownloadIcon,
  ExclamationCircleIcon,
  SuccessIcon,
} from 'components/ui/icons';
import { ContractorCard } from 'components/cards/ContractorCard';
import { UserStructuredCard } from 'components/cards/UserStructuredCard';
import { useShowMessage } from 'hooks/useShowMessage';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { useTheme } from 'styled-components';
type FormValues = any;
type ValidateContractorFormProps = {
  isEditable: boolean;
  contractor: IJoinedContractor;
  onSubmit?: (formValues: FormValues) => any | void;
};

export const ValidateContractorForm: React.FC<
  React.PropsWithChildren<ValidateContractorFormProps>
> = ({ onSubmit: onSubmitParent, isEditable, contractor }) => {
  const [openHelp, setOpenHelp] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [similarUsers, setSimilarUsers] = useState<any>();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isLink, setIsLink] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [checkedUser, setCheckedUser] = useState<any>(false);
  const [similarUserSelected, setSimilarUserSelected] = useState<any>();
  const theme = useTheme();
  const history = useHistory();
  const {
    handleSubmit,
    control,
    register,
    watch,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {
      ...contractor,
      civility: contractor?.civility + '',
      jobTile: contractor?.scopedData?.[0]?.jobTitle,
      phone: contractor?.scopedData?.[0]?.phone,
      secondaryEmail: contractor?.scopedData?.[0]?.email,
      socialStatus: contractor?.scopedData?.[0]?.socialStatus,
    },
  });
  const socialStatus = watch('socialStatus');
  const showMessage = useShowMessage();
  const onSubmit = (formValue: FormValues) => {
    const submitData = {
      uuid: contractor.uuid,
      contractor: {
        status: EContractorStatus.ACTIVE,
        tmpData: {
          email: contractor.tmpData?.email,
        },
      },
    };
    if (
      similarUserSelected &&
      (checkEmail(similarUserSelected?.email) ||
        checkEmail(similarUserSelected?.user?.email))
    ) {
      submitData.contractor.tmpData.email =
        similarUserSelected?.email || similarUserSelected?.user?.email;
    }

    showDialogModal({
      title: `Validation de l’intervenant ${
        contractorNeedDpae(contractor?.scopedData?.[0]) ? 'et de sa DPAE' : ''
      }`,
      text: `Suite à la vérification de données que vous avez faite, vous êtes sur le point de valider l’intervenant ${
        contractor.civility === 1 ? 'Mr' : 'Mme'
      } ${contractor?.firstName} ${contractor?.lastName}, né le ${getHumanDate(
        contractor?.birthday
      )} ${
        contractorNeedDpae(contractor?.scopedData?.[0]) ? 'et  sa DPAE' : ''
      }.`,
      confirmLabel: 'Valider',
      cancelLabel: 'Annuler',
    }).then(async action => {
      if (action === true) {
        try {
          setLoading(true);
          await contractorUpdateOne(submitData);
          queryClient.refetchQueries({ queryKey: ['contractors'] });
          showMessage('success', "L'intervenant à bien été validé");
          setLoading(false);
          history.push('/providers/contractors/active');
        } catch (e) {}
      }
    });
  };
  const checkExistingUser = async (
    firstName: string,
    lastName: string,
    birthday: Date,
    secondaryEmail?: string,
    email?: string
  ) => {
    const usersMatch: any = {
      users: [],
      contractors: [],
    };
    setIsLink(false);
    setCheckedUser(false);
    setSimilarUsers(undefined);
    const UEmail = email?.length && email;
    // escapeEmail(email && email.trim());
    const Semail = secondaryEmail?.length && secondaryEmail;

    //escapeEmail(secondaryEmail && secondaryEmail.trim());
    try {
      if (UEmail || Semail) {
        let OR = [];
        if (UEmail) {
          OR.push({
            'user.email': {
              $regex: `^${escapeEmail(UEmail)}$`,
              $options: 'i',
            },
          });
        }
        if (Semail) {
          OR.push({
            'user.email': {
              $regex: `^${escapeEmail(Semail)}$`,
              $options: 'i',
            },
          });
        }
        const contractorsFinded = await contractorFindMany({
          filterObject: {
            status: EContractorStatus?.ACTIVE,
            $or: OR,
          },
          sort: '',
          skip: 0,
          limit: 10,
        });
        usersMatch.contractors = [
          ...usersMatch.contractors,
          ...contractorsFinded?.contractors,
        ];
        OR = [];
        if (UEmail) {
          OR.push({
            email: {
              $regex: `^${escapeEmail(UEmail)}$`,
              $options: 'i',
            },
          });
        }
        if (Semail) {
          OR.push({
            email: {
              $regex: `^${escapeEmail(Semail)}$`,
              $options: 'i',
            },
          });
        }
        // no contractor found check in user
        if (contractorsFinded?.totalCount === 0) {
          //CHECK USERS
          const usersFinded = await userFindMany({
            filterObject: {
              $or: OR,
            },
            sort: '',
            skip: 0,
            limit: 10,
          });
          usersMatch.users = [...usersMatch.users, ...usersFinded?.users];
          if (
            usersFinded?.totalCount === 0 &&
            contractorsFinded?.totalCount === 0
          ) {
            //CHECK CONTRACTOR
            const contractorsFinded = await contractorFindMany({
              filterObject: {
                status: EContractorStatus?.ACTIVE,
                $and: [
                  {
                    firstName: {
                      $regex: `^${firstName}$`,
                      $options: 'i',
                    },
                  },
                  {
                    lastName: {
                      $regex: `^${lastName}$`,
                      $options: 'i',
                    },
                  },
                  {
                    $expr: {
                      $eq: [
                        { $year: '$birthday' },
                        new Date(birthday.toISOString()).getUTCFullYear(), // Mango start 1
                      ],
                    },
                  },
                  {
                    $expr: {
                      $eq: [
                        { $month: '$birthday' },
                        new Date(birthday.toISOString()).getUTCMonth() + 1, // Mango start 1
                      ],
                    },
                  },
                  {
                    $expr: {
                      $eq: [
                        { $dayOfMonth: '$birthday' },
                        new Date(birthday.toISOString()).getUTCDate(), // Mango start 1
                      ],
                    },
                  },
                ],
              },
              sort: '',
              skip: 0,
              limit: 10,
            });
            usersMatch.contractors = [
              ...usersMatch.contractors,
              ...contractorsFinded?.contractors,
            ];
          }
        }
      } else {
        //CHECK CONTRACTOR
        const contractorsFinded = await contractorFindMany({
          filterObject: {
            status: EContractorStatus?.ACTIVE,
            $and: [
              {
                firstName: {
                  $regex: `^${firstName}$`,
                  $options: 'i',
                },
              },
              {
                lastName: {
                  $regex: `^${lastName}$`,
                  $options: 'i',
                },
              },
              {
                $expr: {
                  $eq: [
                    { $year: '$birthday' },
                    new Date(birthday.toISOString()).getUTCFullYear(),
                  ],
                },
              },
              {
                $expr: {
                  $eq: [
                    { $month: '$birthday' },
                    new Date(birthday.toISOString()).getUTCMonth() + 1, // Mango start 1
                  ],
                },
              },
              {
                $expr: {
                  $eq: [
                    { $dayOfMonth: '$birthday' },
                    new Date(birthday.toISOString()).getUTCDate(),
                  ],
                },
              },
            ],
          },
          sort: '',
          skip: 0,
          limit: 10,
        });
        usersMatch.contractors = [
          ...usersMatch.contractors,
          ...contractorsFinded?.contractors,
        ];
      }
    } catch (e) {
      //console.log('err', e);
    }
    setSimilarUsers(usersMatch);
    setCheckedUser(true);
    return usersMatch;
  };
  const onRejectContractor = async () => {
    await showRejectContractorModal({
      contractor: contractor,
    }).then(action => {
      if (action === true) {
        history.push('/providers/contractors/pending');
      }
    });
  };
  React.useEffect(() => {
    if (contractor) {
      checkExistingUser(
        contractor?.firstName,
        contractor?.lastName,
        contractor?.birthday,
        contractor?.scopedData?.[0]?.email,
        contractor?.tmpData?.email
      );
    }
  }, [contractor]);
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <ValidateContractorModal
        isLoading={loading}
        isEditable
        onClose={() => history.push('/providers/contractors/pending')}
        onReject={() => onRejectContractor()}
        title={'Validation de la création d’un intervenant'}
      >
        <Box>
          <Box padding={40}>
            <Box>
              <Text>
                Vous trouverez ci-joint la facture fournisseur au format PDF,
                ainsi que les données de facturation saisies, transmises par
                votre contact fournisseur.
              </Text>
              <Flex alignItems="center" alignContent="center">
                <Text
                  mt={20}
                  mb={20}
                  minWidth={'170px'}
                  color={theme.colors.blue}
                  variant="h3"
                >
                  Aide à la validation
                </Text>
                <Divider mt={60} />
                {!openHelp ? (
                  <ChevronDownIcon
                    onClick={() => setOpenHelp(!openHelp)}
                    style={{ cursor: 'pointer', marginLeft: '5px' }}
                  />
                ) : (
                  <ChevronUpIcon
                    onClick={() => setOpenHelp(!openHelp)}
                    style={{ cursor: 'pointer', marginLeft: '5px' }}
                  />
                )}
              </Flex>
              {openHelp && (
                <Box>
                  <Flex ml={'5px'}>
                    <SuccessIcon width={25} />
                    <Text variant="span" ml={2}>
                      <b>
                        Dans le cas où la DPAE serait obligatoire, et afin de
                        procéder à une validation, merci de vérifier que la DPAE
                        fournie au format PDF est conforme et que les
                        informations du document sont identiques à celles
                        saisies dans le formulaire.
                      </b>
                    </Text>
                  </Flex>
                  <Flex mt={20}>
                    <ExclamationCircleIcon fill={theme.danger} width={25} />
                    <Text variant="span" ml={2}>
                      <b>
                        En cas de DPAE PDF non conforme, procédez à un refus via
                        le bouton « Refuser » du formulaire
                      </b>
                    </Text>
                  </Flex>
                  <Divider />
                </Box>
              )}
            </Box>
            <Box mb={20}>
              <Text variant="h2" mt={40} mb={20}>
                Etablissement fournisseur
              </Text>
              <Flex>
                <Flex width={1 / 4}>
                  <LabelField
                    underline
                    label={'Raison sociale'}
                    value={getTradeNameSafe(
                      contractor?.scopedData?.[0]
                        ?.establishment as IEstablishment
                    )}
                  />
                </Flex>
                <Flex width={1 / 4}>
                  <LabelField
                    underline
                    label={'N° SIRET'}
                    value={contractor?.scopedData?.[0]?.establishment?.siret}
                  />
                </Flex>
                <Flex width={1 / 4}>
                  <LabelField
                    underline
                    label={"adresse de l'établissement"}
                    value={buildOneLineAddress(
                      contractor?.scopedData?.[0]?.establishment?.address
                    )}
                  />
                </Flex>
              </Flex>
            </Box>
            <Box>
              <Text variant="h2">Informations générales de l’intervenant</Text>
            </Box>
          </Box>
          <Box mt={20}>
            <Box>
              <Box>
                <FormControl
                  label={'Civilité'}
                  errorMessage={errors?.civility?.message}
                >
                  <>
                    <Radio
                      disabled={!isEditable}
                      {...register('civility')}
                      value="1"
                    >
                      Monsieur
                    </Radio>
                    <Radio
                      disabled={!isEditable}
                      {...register('civility')}
                      value="2"
                    >
                      Madame
                    </Radio>
                  </>
                </FormControl>
              </Box>
              <Row spacing={20}>
                <FormControl
                  label={'Prénom'}
                  errorMessage={errors?.firstName?.message}
                >
                  <Input
                    isDisabled={!isEditable}
                    isFullWidth
                    {...register('firstName')}
                  />
                </FormControl>
                <FormControl
                  label={'Nom'}
                  errorMessage={errors?.lastName?.message}
                >
                  <Input
                    isDisabled={!isEditable}
                    isFullWidth
                    {...register('lastName')}
                  />
                </FormControl>
              </Row>
              <Row spacing={20}>
                <FormControl
                  label={'Date de naissance'}
                  errorMessage={errors?.birthday?.message}
                >
                  <DatePickerControlled
                    disabled={!isEditable}
                    control={control}
                    name="birthday"
                    valueName="selected"
                  />
                </FormControl>
                <FormControl
                  label={"Fonction liée à l'établissement"}
                  errorMessage={errors?.jobTitle?.message}
                >
                  <Input
                    isDisabled={!isEditable}
                    isFullWidth
                    {...register('jobTitle')}
                  />
                </FormControl>
              </Row>
              <Row spacing={20}>
                <FormControl
                  label={"Téléphone lié à l'établissement"}
                  errorMessage={errors?.phone?.message}
                >
                  <PhoneInputControlled
                    disabled={!isEditable}
                    control={control}
                    isFullWidth
                    name="phone"
                    rules={{
                      validate: (value: string) => {
                        return validateFormPhoneFormatFR(value);
                      },
                    }}
                  />
                </FormControl>

                <FormControl
                  label={"Email lié à l'établissement"}
                  errorMessage={errors?.secondaryEmail?.message}
                >
                  <Input
                    isDisabled={!isEditable}
                    isFullWidth
                    {...register('secondaryEmail')}
                  />
                </FormControl>
              </Row>
              <Row spacing={20}>
                <FormControl
                  label={"Statut social pour l'établissement renseigné"}
                >
                  <Input
                    isDisabled={!isEditable}
                    isFullWidth
                    //@ts-ignore
                    value={CONTRACTOR_SOCIAL_STATUS[socialStatus]}
                  />
                </FormControl>
                <FormControl
                  label={'Email'}
                  errorMessage={errors?.tmpData?.email?.message}
                >
                  <Input
                    isDisabled={!isEditable}
                    isFullWidth
                    {...register('tmpData.email')}
                  />
                </FormControl>
              </Row>
              {contractor?.tmpData?.dpaeAttachment && (
                <Box>
                  <Text mb={20} variant={'h2'}>
                    Document transmis
                  </Text>
                  <Flex
                    height={'80px'}
                    width={5 / 10}
                    padding={'8px'}
                    backgroundColor="white"
                    border="solid 1px"
                    borderRadius="8px"
                    justifyContent="space-around"
                    alignContent="center"
                    alignItems="center"
                    onClick={() =>
                      showDisplayPdfModal({
                        fileName: contractor?.tmpData?.dpaeAttachment
                          ?.fileName as string,
                        fileLocation: contractor?.tmpData?.dpaeAttachment
                          ?.fileLocation as string,
                      })
                    }
                  >
                    <Link>
                      {cutLongText(
                        contractor?.tmpData?.dpaeAttachment?.fileName as string,
                        20
                      )}
                    </Link>
                    <Box>
                      <DownloadIcon
                        width={25}
                        height={25}
                        fill={theme.colors.blue}
                      />
                    </Box>
                  </Flex>
                </Box>
              )}
              {similarUsers &&
              (similarUsers?.users?.length ||
                similarUsers?.contractors?.length) ? (
                <Box mt={20}>
                  <Text variant={'h2'}>
                    Contacts ayant les mêmes informations que l’intervenant à
                    valider
                  </Text>
                  {similarUsers?.users?.length > 0 && (
                    <Text variant={'p'}>
                      {`Plusieurs correspondances utilisateurs ont été détectées pour
              ${contractor.firstName}  ${contractor.lastName}. Merci de sélectionner le doublon éventuel. Si aucun
              des utilisateurs ne correspond cliquez directement sur valider et
              créer.`}
                    </Text>
                  )}
                  {similarUsers?.contractors?.length === 1 && (
                    <Text variant={'p'}>
                      L'intervenant ci-dessous à été détécté comme
                      correspondance exacte , les informations saisies seront
                      rattachées à la fiche existante
                    </Text>
                  )}
                  <Box mt={20}>
                    {similarUsers?.contractors?.map((contractor: any) => (
                      <Box mb={40}>
                        <LabelField label="Intervenant" value="" />
                        <ContractorCard
                          isSelectable={similarUsers?.contractors?.length > 1}
                          isSelected={
                            similarUserSelected?.uuid === contractor?.uuid
                          }
                          onChangeSelected={() =>
                            similarUserSelected?.uuid === contractor?.uuid
                              ? setSimilarUserSelected(undefined)
                              : setSimilarUserSelected(contractor)
                          }
                          contractor={contractor}
                        />
                      </Box>
                    ))}
                  </Box>
                  {similarUsers?.users?.length > 0 &&
                    "Les comptes utilisateurs suivant on été détéctés, si besoin vous pouvez lier l'un de ces comptes à l'intervenant"}
                  <Box>
                    {similarUsers?.users?.map((user: any) => (
                      <Box mb={40}>
                        <LabelField label="Contact" value="" />
                        <UserStructuredCard
                          user={user}
                          isSelectable
                          isSelected={
                            similarUserSelected?.cognitoUserId ===
                            user?.cognitoUserId
                          }
                          onChangeSelected={() =>
                            similarUserSelected?.cognitoUserId ===
                            user?.cognitoUserId
                              ? setSimilarUserSelected(undefined)
                              : setSimilarUserSelected(user)
                          }
                        />
                      </Box>
                    ))}
                  </Box>
                </Box>
              ) : (
                <></>
              )}
            </Box>
          </Box>
        </Box>
      </ValidateContractorModal>
    </form>
  );
};
