import { useAdressFindMany, MAPBOX_MAX_ALLOWED_TOKENS } from '@commons';
import { Select } from 'components/ui';
import { useDebounce } from 'hooks/useDebounce';
import React, { ComponentPropsWithoutRef, useState } from 'react';

type AddressSelectProps = {
  endpoint: 'mapbox.places' | 'mapbox.places-permanent';
  isDisabled?: boolean;
  value?: string;
  defaultValue?: string;
  onChange?: (id: string) => void;
  onChangeCompleteObject?: (object: any) => void;
  filter?: string;
  defaultSearch?: string;
  //referenceValue: 'uuid' | 'siren';
} & Omit<ComponentPropsWithoutRef<'input'>, 'event' | 'onChange' | 'value'>;

export const AddressSelect = ({
  endpoint,
  value,
  onChange,
  defaultSearch,
  defaultValue,
  filter: globalFilter,
  //referenceValue = 'uuid',
  isDisabled,
  ...props
}: AddressSelectProps) => {
  const [filter, setFilter] = useState<string | undefined>(defaultSearch);
  const debouncedFilter = useDebounce(filter, 500);

  // if the search string is too long only send the first 20 tokens allowed by mapbox api
  const searchTokens = debouncedFilter?.split(/[^A-Za-z0-9_-]/);
  const finalSearchText =
    searchTokens?.length > MAPBOX_MAX_ALLOWED_TOKENS
      ? searchTokens?.slice(0, MAPBOX_MAX_ALLOWED_TOKENS)?.join(' ')
      : debouncedFilter;

  const { data: addresses, isFetching } = useAdressFindMany({
    endpoint,
    search_text:
      debouncedFilter !== defaultSearch ? finalSearchText : undefined,
  });

  const selectedAddress = value;

  const AddressOptions =
    addresses?.features
      ?.filter((ad: any) => ad?.place_name?.includes('France'))
      ?.map((e: any) => ({
        label: e.place_name,
        street: `${e.address || ''} ${e.text || ''}`,
        value: e.id,
        data: e,
      })) || [];

  const selectedOption =
    selectedAddress &&
    selectedAddress &&
    AddressOptions?.find((opt: any) => opt.id === selectedAddress);

  return (
    <Select
      bcWhite
      filterOption={(option: any, inputValue: string) => {
        return true;
      }}
      isDisabled={isDisabled}
      options={AddressOptions}
      isLoading={isFetching}
      onInputChange={(value: string) => {
        setFilter(value);
      }}
      onChange={(option: any) => {
        onChange && onChange(option && option);
        props.onChangeCompleteObject &&
          props.onChangeCompleteObject(option && option);
      }}
      isClearable={true}
      value={selectedOption}
      defaultInputValue={defaultValue}
      {...props}
    />
  );
};
