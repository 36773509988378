import { IFile } from '@freelancelabs/teoreme-commons';
import { downloadFile } from '../api';

export const onDownloadFile = async (file: IFile) => {
  if (file && file?.fileLocation && file?.fileName) {
    try {
      const data = await downloadFile(file?.fileLocation);
      if (data) {
        const fileName = file.fileName;
        const reader = new FileReader();
        //@ts-ignore
        reader.readAsDataURL(data.data); // converts the blob to base64 and calls onload
        reader.onload = () => {
          const a = document.createElement('a');
          a.href = (reader.result as string) || '';
          a.download = fileName;
          //   a.download = fileName.endsWith('.xlsx')
          //     ? replaceSpecificCaractereInFileName(fileName)
          //     : replaceSpecificCaractereInFileName(fileName) + '.xlsx';
          a.click();
        };
      }
    } catch (e) {
      //
    }
  }
};
