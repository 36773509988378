import { EFileType } from '@freelancelabs/teoreme-commons';
import { missionUpdateOne } from '@commons';
import { uploadFile } from '@commons';
import {
  Button,
  FileInput,
  FormControl,
  Input,
  Link,
  Text,
} from 'components/ui';
import { AddIcon } from 'components/ui/icons';
import { useGetMission } from 'hooks/missionHooks';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { create } from 'react-modal-promise';
import { showDialogModal } from './DialogModal';
import { ModalFrame, ModalProps } from './ModalFrame';
import { truncate } from 'lodash';
import { useShowMessage } from 'hooks/useShowMessage';
import { queryClient } from '@commons';

type FormValues = {
  reference: string;
  file: File;
};

export const ImportProviderPurchaseOrderModal = ({
  onResolve,
  isOpen,
}: ModalProps) => {
  const [loading, setLoading] = useState(false);
  const {
    register,
    setValue,
    handleSubmit,
    watch,
    formState: { errors },
    setError,
  } = useForm<FormValues>();

  const { reference: missionReference } = useGetMission();
  const showMessage = useShowMessage();

  useEffect(() => {
    register('file');
  }, []);

  //@ts-ignore
  const file = watch('file');

  if (!missionReference) return null;

  const onImport = async (data: FormValues) => {
    setLoading(true);
    if (data.file) {
      try {
        const s3Response = await uploadFile({
          file: data.file,
          actionType: 'upload',
          fileType: EFileType.PURCHASE_ORDER_PROVIDER,
          missionRef: missionReference,
        });

        await missionUpdateOne({
          reference: missionReference,
          mission: {
            provider: {
              // @ts-ignore
              purchaseOrder: {
                reference: data.reference,
                file: {
                  eTag: s3Response?.eTag as string,
                  fileLocation: s3Response?.fileLocation as string,
                  fileName: s3Response?.fileName,
                },
              },
            },
          },
        });
        queryClient.refetchQueries({ queryKey: [missionReference] });

        onResolve(true);

        showDialogModal({
          title: 'Votre BDC fournisseur signé a bien été importé.',
          text: 'Attention : Les informations de la fiche Mission courante n’ont pas été mises à jour avec les informations contenues dans ce BDC fournisseur, merci de vérifier et procéder si besoin à leur mise à jour manuelle.',
          confirmLabel: "J'ai bien compris",
        });
      } catch (err) {
        // Works! We can narrow 'err' from 'unknown' to 'Error'.
        if (err instanceof Error) {
          //@ts-ignore
          if (err.errorCode === 'TEOR-ALL-003') {
            showMessage(
              'error',
              'La référence du BDC fournisseur existe déjà.'
            );
          }
        }

        setLoading(false);
      }
    } else {
      setError('file', { message: 'Ce champ est requis' });
    }
    setLoading(false);
  };

  return (
    <ModalFrame
      isOpen={isOpen}
      onClose={() => onResolve(false)}
      width={850}
      closeIcon
    >
      <form onSubmit={handleSubmit(onImport)}>
        <Text variant="h1" mb={20}>
          Importer un nouveau BDC fournisseur déjà signé
        </Text>
        <Text mb={20}>
          Avant de télécharger votre BDC fournisseur sur Connecteed,
          assurez-vous que votre document a bien été signé par le fournisseur et
          contresigné par Connecteed.
        </Text>
        <FormControl
          required
          label="Référence"
          errorMessage={errors?.reference?.message}
        >
          <Input
            isFullWidth
            {...register('reference', { required: 'Ce champ est requis' })}
          />
        </FormControl>

        <FileInput
          mb={20}
          label="Bon de commande Fournisseur"
          onSelect={files => setValue('file', files[0])}
          accept=".pdf"
          subLabel="Format accepté : PDF"
          required
          // @ts-ignore
          errorMessage={errors?.file?.message}
        >
          <Link iconLeft={<AddIcon />}>
            {file
              ? truncate(file.name, { length: 40, omission: '....pdf' })
              : 'Importer le bon de commande déjà signé'}
          </Link>
        </FileInput>
        <Button isLoading={loading}>Importer</Button>
      </form>
    </ModalFrame>
  );
};

export const showImportProviderPurchaseOrderModal = create(
  ImportProviderPurchaseOrderModal
);
