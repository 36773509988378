import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { MilestonesList } from './List';
export const Milestones = () => {
  return (
    <Switch>
      <Route exact path="/orders/milestones">
        <Redirect to="/orders/milestones/to-be-validated" />
      </Route>
      <Route path="/orders/milestones/:filter(all|awaiting|validated|to-be-validated|archived|rejected)">
        <MilestonesList />
      </Route>
    </Switch>
  );
};
