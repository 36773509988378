import {
  queryClient,
  uploadFile,
  useDownloadFile,
  useInvoiceFindOne,
  round,
} from '@commons';
import {
  EFileType,
  EMissionStructure,
  IFile,
  IJoinedInvoice,
} from '@freelancelabs/teoreme-commons';
import FCOM_STAMP from 'assets/images/fcom_stamp.png';
import INOPS_STAMP from 'assets/images/inops_stamp.png';
import { Box, Button, CustomToolTip, Flex, Text } from 'components/ui';
import { PDFDocument, rgb, StandardFonts } from 'pdf-lib';
import { useEffect, useRef, useState } from 'react';
import { create } from 'react-modal-promise';
import { ModalFrame, ModalProps } from '../ModalFrame';
import { CancelIcon, CheckCircleIcon } from 'components/ui/icons';
import { Theme } from 'styles';
import PdfViewer from 'forms/invoices/Provider/PdfViewer';

type Props = ModalProps & {
  invoiceUuid: string;
  fileInvoice: File | null;
  invoiceTotalWithTaxes: number;
  beforeValidation?: (data: { directPaymentFile: IFile }) => void;
};

/**
 * Convert a PDF document to a File object using pdf-lib.
 * @param {Uint8Array} pdfBytes - The PDF document in Uint8Array format.
 * @param {string} fileName - The desired file name for the File object.
 * @returns {Promise<File>} - The resulting File object.
 */
const convertPdfToFile = async (
  pdfBytes: Uint8Array,
  fileName: string
): Promise<File> => {
  // Load the PDF document
  const pdfDoc = await PDFDocument.load(pdfBytes);
  // Serialize the PDFDocument to bytes (a Uint8Array)
  const pdfData = await pdfDoc.save();
  // Create a blob from the PDF bytes
  const blob = new Blob([pdfData], { type: 'application/pdf' });
  // Convert the blob to a File
  const file = new File([blob], fileName, { type: 'application/pdf' });
  return file;
};

export const AddInvoiceStampModal = ({
  onResolve,
  isOpen,
  invoiceUuid,
  fileInvoice,
  invoiceTotalWithTaxes,
  beforeValidation,
}: Props) => {
  const { data: invoice, status } = useInvoiceFindOne(invoiceUuid);
  const [file, setFile] = useState<string>();
  const isFcomMission =
    invoice?.mission?.billingInformation?.structure === EMissionStructure.FCOM;

  const tamponFile = isFcomMission ? FCOM_STAMP : INOPS_STAMP;

  const fileLocation = invoice?.invoiceFile?.fileLocation;
  const { status: statusFile, data } = useDownloadFile(fileLocation as string);

  useEffect(() => {
    // use for download file
    if (data?.data) {
      const reader = new FileReader();
      reader.readAsDataURL(data.data); // converts the blob to base64 and calls onload
      reader.onload = () => {
        setFile(reader.result as string);
      };
    }
  }, [data]);

  const iframeRef = useRef(null);
  const divRef = useRef(null);
  const initialPositionRef = useRef(null);
  const [position, setPosition] = useState({ top: 240, left: -440 });
  const [dragging, setDragging] = useState(false);
  const [outputPdfUrl, setOutputPdfUrl] = useState<string | null>(null);
  const [finalPdfFile, setFinalPdfFile] = useState<any | null>(null);
  const [isStamp, setIsStamp] = useState(false);
  const [isStampInViewer, setIsStampInViewer] = useState(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [isLoading, setIsLoading] = useState(false);
  const [pdfScrollTop, setPdfScrollTop] = useState(0);
  const [pdfViewerHeaderHeight, setPdfViewerHeaderHeight] = useState(0);
  const [pdfViewerFooterHeight, setPdfViewerFooterHeight] = useState(0);
  const pngHeight = 100; //Fixed Image dimensions height;
  const pngWidth = 200; //Fixed Image dimensions width;
  const onValidate = async (data: { directPaymentFile: IFile }) => {
    try {
      if (beforeValidation) {
        await beforeValidation(data);
        setIsLoading(false);
      }
      onResolve(true);
    } catch (err) {
      console.log(err);
    }
  };
  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };
  const addImageToPdf = async () => {
    if (!file) {
      alert('Please select both PDF and image files.');
      return;
    }

    const pdfBytes = file;
    const response = await fetch(tamponFile);
    const TAMPON_BLOB = await response.arrayBuffer();
    const pdfDoc = await PDFDocument.load(pdfBytes, { ignoreEncryption: true });
    const pngImage = await pdfDoc.embedPng(TAMPON_BLOB);

    const pages = pdfDoc.getPages();
    const pdfPage = pages[currentPage - 1];

    const textSize = 12;

    pdfPage.drawImage(pngImage, {
      x: position.left,
      y:
        pdfPage.getHeight() -
        position.top -
        pngHeight +
        pdfViewerHeaderHeight -
        pdfScrollTop, // Inverser la coordonnée y car le PDF commence en bas à gauche
      width: pngWidth,
      height: pngHeight,
    });

    const mention = `Bon pour paiement direct \n de la somme de ${round(invoiceTotalWithTaxes ?? 0)} € TTC`;
    const font1 = await pdfDoc.embedFont(StandardFonts.HelveticaBoldOblique);
    pdfPage.drawText(mention, {
      x: position.left + 3,
      y:
        pdfPage.getHeight() -
        position.top -
        pngHeight -
        6 +
        pdfViewerHeaderHeight -
        pdfScrollTop, //6 = image container border top + bottom
      size: textSize,
      font: font1,
      color: rgb(0, 0.53, 0.71),
      opacity: 1,
      lineHeight: 10.5,
    });

    const pdfBytesModified = await pdfDoc.save();
    const blob = new Blob([pdfBytesModified], { type: 'application/pdf' });
    const url = URL.createObjectURL(blob);
    const outputPdfFile = await convertPdfToFile(
      pdfBytesModified,
      'invoice_tampone.pdf'
    );
    setOutputPdfUrl(url);
    setFinalPdfFile(outputPdfFile);
    setIsStamp(true);
  };

  const mergePdf = async () => {
    addImageToPdf();
  };
  const cancelMergePdf = async () => {
    setOutputPdfUrl(null);
    setIsStamp(false);
    setPosition({ top: 137, left: -465 });
    setIsStampInViewer(false);
  };

  const validateInvoice = async () => {
    setIsLoading(true);
    // UPDLOAD FILE TO S3 TEMPORARY
    try {
      const uploadResponse = await uploadFile({
        //@ts-ignore
        file: finalPdfFile,
        fileType: EFileType.TEMPORARY,
        missionRef: invoice?.mission?.reference || '',
        actionType: 'upload',
      });
      if (uploadResponse) {
        onValidate({ directPaymentFile: uploadResponse });
      }
    } catch (e) {
      setIsLoading(false);
      onResolve(false);
      console.log(
        'error',
        `Une erreur est survenue lors de la création de l'avenant`
      );
    }
  };

  const restart = () => {
    setOutputPdfUrl(null);
    setIsStamp(false);
    setCurrentPage(1);
    setPdfScrollTop(0);
  };

  useEffect(() => {
    queryClient.invalidateQueries({ queryKey: [fileLocation] });
    if (data) {
      const reader = new FileReader();
      reader.readAsDataURL(data.data); // converts the blob to base64 and calls onload
      reader.onload = () => {
        setFile(reader.result as string);
      };
    }
  }, []);

  useEffect(() => {
    const handleMouseMove = (event: any) => {
      if (!dragging) return;
      //@ts-ignore
      const iframeRect = iframeRef?.current?.getBoundingClientRect();
      const offsetX = event.clientX - iframeRect.left;
      const offsetY = event.clientY - iframeRect.top;

      //@ts-ignore
      const topPosition = offsetY - divRef?.current?.clientHeight / 2;
      //@ts-ignore
      const leftPosition = offsetX - divRef?.current?.clientWidth / 2;
      setPosition({
        top: topPosition,
        left: leftPosition,
      });
      if (leftPosition <= 0) setIsStampInViewer(false);
      else if (leftPosition + pngWidth >= iframeRect.width)
        setIsStampInViewer(false);
      else if (topPosition <= pdfViewerHeaderHeight) setIsStampInViewer(false);
      else if (
        topPosition + pngHeight + pdfViewerFooterHeight + 24 >=
        iframeRect.height
      )
        setIsStampInViewer(false);
      else setIsStampInViewer(true);
    };

    const handleMouseUp = () => {
      setDragging(false);
    };

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [dragging]);

  const handleMouseDown = () => {
    setDragging(true);
  };
  const height = window?.innerHeight;
  return (
    <ModalFrame
      isOpen={isOpen}
      onClose={() => onResolve(true)}
      width={1380}
      //@ts-ignore
      height={height - 50}
      closeIcon
    >
      <Box>
        <Text width={1 / 1} textAlign={'center'} variant="h1">
          Ajouter le Tampon sur la facture
        </Text>

        <Flex
          display={'inline-flex'}
          justifyContent={'flex-start'}
          alignContent={'center'}
          flexWrap={'wrap'}
          width={1 / 1}
          height={'100%'}
        >
          <Box width={1 / 2} height={height - 200}>
            <Box height={'430px'} width={'100%'} ref={initialPositionRef}>
              <Text width={1 / 1} textAlign={'left'} variant="p" mt={20}>
                Veuillez déplacer le tampon pour le placer sur la facture. Une
                fois l'emplacement choisi, cliquez sur "Tamponner".
                <br /> <br />
                Si vous souhaitez modifier l'emplacement du tampon, cliquez sur
                "Recommencer".
                <br /> <br />
                Cliquez sur "Valider" pour valider la facture avec le tampon à
                l'emplacement choisi.
                <br /> <br />
                Une fois validée, il n'est plus possible de modifier
                l'emplacement du tampon et la facture tamponée sera envoyée au
                client en PJ de la facture Freelance.com
              </Text>
            </Box>
            {!isStamp && (
              <Button onClick={() => mergePdf()} disabled={!isStampInViewer}>
                Tamponner
              </Button>
            )}
            {isStamp && (
              <Flex>
                <Box>
                  <Button onClick={() => restart()} m={10}>
                    Recommencer
                  </Button>
                </Box>
                <Box>
                  <Button
                    m={10}
                    isLoading={isLoading}
                    onClick={() => validateInvoice()}
                  >
                    Valider
                  </Button>
                </Box>
              </Flex>
            )}
          </Box>
          <Box width={1 / 2} height={height - 200} id="sidePosition">
            <div
              hidden={isStamp}
              ref={divRef}
              onMouseDown={handleMouseDown}
              style={{
                position: 'absolute',
                top: position.top,
                left: position.left,
                width: '200px',
                height: `100px`,
                backgroundColor: 'rgba(255, 255, 255, 0.7)',
                border: `3px solid ${isStampInViewer ? Theme.colors.success.default : Theme.colors.error.default}`,
                borderRadius: '10px',
                background: `url(${tamponFile})`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center center',
                cursor: 'grab',
                zIndex: 50,
              }}
            >
              {' '}
              <div
                style={{
                  marginTop: `100px`,
                  textAlign: 'start',
                  cursor: 'pointer',
                  lineHeight: '12px',
                  color: '#0087B5',
                  font: 'Helvetica',
                  fontSize: 12,
                  fontWeight: 'bold',
                  fontStyle: 'oblique',
                }}
              >
                {`Bon pour paiement direct \n `}
                <br />
                {`de la somme de ${round(invoiceTotalWithTaxes ?? 0)} € TTC`}
              </div>
              <div
                style={{
                  marginTop: `10px`,
                  textAlign: 'center',
                  cursor: 'pointer',
                }}
              >
                <Flex justifyContent={'center'} alignItems="center">
                  <Flex
                    justifyContent={'center'}
                    alignItems="center"
                    width={1 / 2}
                  >
                    <Box>
                      <Button
                        variant="ghostSuccess"
                        disabled={!isStampInViewer}
                        onClick={() => {
                          mergePdf();
                        }}
                      >
                        <CustomToolTip text={'Tamponner'} id={'validate_stamp'}>
                          <Text fontSize={9}>
                            <CheckCircleIcon
                              width={25}
                              height={25}
                              fill={Theme.colors.success.default}
                            />
                          </Text>
                        </CustomToolTip>
                      </Button>
                    </Box>
                  </Flex>

                  <Flex
                    justifyContent={'center'}
                    alignItems="center"
                    width={1 / 2}
                  >
                    <Box>
                      <Button
                        variant="ghostError"
                        onClick={() => cancelMergePdf()}
                      >
                        <CustomToolTip text={'Annuler'} id={'cancel_stamp'}>
                          <Text fontSize={9}>
                            <CancelIcon
                              width={30}
                              height={30}
                              fill={Theme.colors.error.default}
                            />
                          </Text>
                        </CustomToolTip>
                      </Button>
                    </Box>
                  </Flex>
                </Flex>
              </div>
            </div>
            <div ref={iframeRef}>
              {!outputPdfUrl && statusFile === 'success' && (
                <PdfViewer
                  pdfUrl={data && (data as any)?.config?.url}
                  onPageChange={handlePageChange}
                  onScrolling={n => {
                    setPdfScrollTop(n);
                  }}
                  afterLoad={pdfViewerData => {
                    setPdfViewerHeaderHeight(
                      pdfViewerData?.headerClientRect?.height ?? 0
                    );
                    setPdfViewerFooterHeight(
                      pdfViewerData?.footerClientRect?.height ?? 0
                    );
                  }}
                />
              )}
              {outputPdfUrl && (
                <PdfViewer
                  pdfUrl={outputPdfUrl}
                  onPageChange={handlePageChange}
                />
              )}
            </div>
          </Box>
        </Flex>
      </Box>
    </ModalFrame>
  );
};

export const showAddInvoiceStampModal = create<Props, IJoinedInvoice>(
  AddInvoiceStampModal
);
