import {
  Button,
  Flex,
  Text,
  Box,
  FileInput,
  Link,
  SpinnerBox,
} from 'components/ui';
import { AddIcon } from 'components/ui/icons';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { truncate } from 'lodash';
import { useDownloadFile, FILE_GUIDE_LOCATION, uploadFile } from '@commons';
import { useShowMessage } from 'hooks/useShowMessage';
import { queryClient } from '@commons';
type FormValues = {
  guideFile: File;
};

type InvoiceContactFormProps = {
  defaultValues?: FormValues;
  onSubmit?: any; // TODO MAKE TYPES
};

export const ProviderGuideFileForm = ({
  defaultValues,
  onSubmit: onSubmitParent,
}: InvoiceContactFormProps) => {
  const showMessage = useShowMessage();
  const [file, setFile] = useState<string>();
  const [refetchPreview, setRefetchPreview] = useState(true);
  const [loading, setLoading] = useState(false);
  const {
    handleSubmit,
    watch,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {},
  });
  const { status, data } = useDownloadFile(FILE_GUIDE_LOCATION);

  React.useEffect(() => {
    // use for download file
    if (data) {
      const reader = new FileReader();
      reader.readAsDataURL(data.data); // converts the blob to base64 and calls onload
      reader.onload = () => {
        setFile(reader.result as string);
      };
    }
  }, [data]);

  //@ts-ignore
  const guideFile = watch('guideFile');

  const onSubmit = async (formValues: FormValues) => {
    setLoading(true);
    if (guideFile?.name) {
      clearErrors('guideFile');
      try {
        await uploadFile({
          file: guideFile,
          //@ts-ignore
          fileType: 'PROVIDER_USER_GUIDE',
          establishment: '',
          actionType: 'upload',
        });
        showMessage('success', 'Le guide utilisateur à été mis à jours');
        setRefetchPreview(true);
      } catch (e) {
        //
      }
      // UPLOAD FILE
    } else {
      setError('guideFile', { message: 'Veuillez fournir un ficher' });
    }
    setLoading(false);
  };
  React.useEffect(() => {
    if ((!file || refetchPreview === true) && FILE_GUIDE_LOCATION) {
      const uploadFile = async (url: string) => {
        const myHeaders = new Headers();
        myHeaders.append('cache-control', 'no-cache');
        const myInit = {
          method: 'GET',
          headers: myHeaders,
        };
        await fetch(url, myInit)
          .then(response => response.blob())
          .then(blob => {
            // Create a new FileReader innstance
            const reader = new FileReader();
            reader.readAsDataURL(blob); // converts the blob to base64 and calls onload

            reader.onload = () => {
              setFile(reader.result as string);
              setRefetchPreview(false);
            };
          });
      };
      uploadFile(FILE_GUIDE_LOCATION);
      queryClient.refetchQueries({ queryKey: [FILE_GUIDE_LOCATION] });
    }
  }, [file, refetchPreview]);
  return (
    <Box width={1 / 1}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Flex display={'inline-flex'} width={1 / 1}>
          <Box
            alignItems={'center'}
            width={2 / 4}
            height={'calc(100vh - 250px)'}
            p={5}
          >
            <Box mb={20}>
              <Text variant="b">
                Ce document sera affiché dans l'espace fournisseur pour aider
                les utilisateurs.
              </Text>
            </Box>
            <Box>
              <FileInput
                mb={20}
                label="Guide utilisateur espace fournisseur"
                onSelect={files => setValue('guideFile', files[0])}
                accept=".pdf"
                subLabel="Format accepté : PDF"
                required
                // @ts-ignore
                errorMessage={errors?.guideFile?.message}
              >
                <Link iconLeft={<AddIcon />}>
                  {guideFile
                    ? truncate(guideFile.name, {
                        length: 40,
                        omission: '....pdf',
                      })
                    : 'Ajouter un fichier'}
                </Link>
              </FileInput>
              <Flex>
                <Button type="submit" isLoading={loading}>
                  Enregistrer
                </Button>
              </Flex>
            </Box>
          </Box>
          <Box
            alignItems={'center'}
            width={2 / 4}
            height={'calc(100vh - 250px)'}
          >
            <Box textAlign={'center'}>
              <Text variant="h2">Fichier actuel</Text>
            </Box>
            {status === 'pending' ? (
              <Box>
                <SpinnerBox />
              </Box>
            ) : (
              <Box>
                <iframe
                  //@ts-ignore
                  src={data && (data as any)?.config?.url + '#toolbar=0'}
                  frameBorder="0"
                  title="iframe"
                  style={{ width: '100%', height: 'calc(100vh - 80px)' }}
                ></iframe>
              </Box>
            )}
          </Box>
        </Flex>
      </form>
    </Box>
  );
};
