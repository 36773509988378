import { FlatUser } from '@freelancelabs/teoreme-commons';
import {
  IUserLocal,
  userFindMany,
  checkUserHasRole,
  escapeEmail,
  createOrUpdateUser,
} from '@commons';
import { ContactExistsMessage } from 'components/ContactExistsMessage';
import { Box, Text } from 'components/ui';
import { UserForm } from 'forms/support/users';
import { VerifyEmailForm } from 'forms/VerifyEmailForm';
import React, { useState } from 'react';
import { create } from 'react-modal-promise';
import { ModalFrame, ModalProps } from '../ModalFrame';
import { useShowMessage } from 'hooks/useShowMessage';

type Props = ModalProps & {
  contactRole: 'ACCOUNT_MANAGER' | 'TEAM_LEADER' | 'LEGAL_SQUAD';
  beforeValidation?: () => void;
};

export const AddManagerModal = ({ onResolve, isOpen, contactRole }: Props) => {
  const showMessage = useShowMessage();
  const [user, setUser] = useState<Partial<IUserLocal> | undefined>(undefined);
  const [errorExist, setErrorExist] = useState(false);
  const [emailChecked, setEmailChecked] = useState(false);
  const [email, setEmail] = useState<string | undefined>();
  const onCheckEmail = async ({ email }: { email?: string }) => {
    setEmail(email);
    const values = await userFindMany({
      filterObject: {
        email: {
          $regex: `^${escapeEmail(email && email.trim())}$`,
          $options: 'i',
        },
      },
    });

    setEmailChecked(true);

    if (values?.users?.[0]) {
      setUser(values.users[0]);
      setErrorExist(checkUserHasRole(values.users[0], contactRole));
    } else {
      setUser({ email });
    }
  };

  // const { mutateAsync: addContact } = useRegister();
  const handleSubmit = async (values: any) => {
    try {
      await createOrUpdateUser({
        create: [values],
      });
      showMessage('success', "L'utilisateur à bien été créé");
      onResolve(true);
    } catch (e) {
      //
    }
  };

  const handleReset = () => {
    setUser(undefined);
    setEmailChecked(false);
    setErrorExist(false);
  };

  return (
    <ModalFrame
      isOpen={isOpen}
      onClose={() => onResolve(true)}
      width={850}
      closeIcon
    >
      <Text variant="h1" mb={10}>
        Ajouter un utilisateur
      </Text>
      <VerifyEmailForm
        contactRole={contactRole}
        onSubmit={onCheckEmail}
        isLocked={!!user}
        canModify
        onModify={handleReset}
      />
      {user && (
        <Box mt={20}>
          <ContactExistsMessage
            contactRole={contactRole}
            contact={user as IUserLocal}
          />
        </Box>
      )}
      <Box height={20} />
      {!errorExist && emailChecked && !user && (
        <>
          <UserForm onSubmit={handleSubmit} user={user || { email }} isNew />
        </>
      )}
      {!errorExist && emailChecked && user && (
        <>
          <UserForm onSubmit={handleSubmit} user={user || { email }} isNew />
        </>
      )}
    </ModalFrame>
  );
};

export const showAddManagerModal = create<Props, FlatUser>(AddManagerModal);
