import React from 'react';
import {
  ICompanyGetResponse,
  IEstablishment,
  IJoinedMission,
} from '@freelancelabs/teoreme-commons';
import { Box, Text } from 'components/ui';
import { create } from 'react-modal-promise';
import { ModalFrame, ModalProps } from '../ModalFrame';
import { ImportStandByDutyForm } from 'forms/ImportStandByDutyForm';

type Props = ModalProps & {
  beforeValidation?: () => void;
  standByDuty?: any;
  establishment?: ICompanyGetResponse | IEstablishment;
  mission?: IJoinedMission;
};
// type FormValues = any;

export const ImportStandByDutyModal = ({
  onResolve,
  isOpen,
  standByDuty,
  establishment,
  mission,
}: Props) => {
  // const {
  //   formState: { errors, isDirty, isSubmitSuccessful },
  // } = useForm<FormValues>();

  return (
    <ModalFrame
      isOpen={isOpen}
      onClose={() => onResolve(false)}
      width={1350}
      closeIcon
    >
      <Box mt={20}>
        <Text variant="h2" mb={20}>
          Importer des formules d'astreintes
        </Text>
        <ImportStandByDutyForm
          standByDuty={standByDuty}
          establishment={establishment}
          establishmentUuid={establishment?.uuid}
          mission={mission}
          onResolve={onResolve}
        />
      </Box>
    </ModalFrame>
  );
};

export const showImportStandByDutyModal = create<Props, boolean>(
  ImportStandByDutyModal
);
