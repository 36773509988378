import styled, { useTheme } from 'styled-components';
import { Flex, Box, Divider, Text } from 'components/ui';
import {
  ChevronDownIcon,
  ChevronUpIcon,
  SuccessIcon,
  ExclamationCircleIcon,
  CloseIcon,
} from 'components/ui/icons';
import * as React from 'react';
import { IJoinedInvoice, EInvoiceStatus } from '@freelancelabs/teoreme-commons';
type InvoiceInformationProps = {
  invoice?: IJoinedInvoice;
};
export type NotificationsProps = {
  variant: 'error' | 'success' | 'info';
};
export const PopupNotifications = styled.div<NotificationsProps>`
  min-height: 50px;
  border: 2px solid
    ${p =>
      p.variant === 'success'
        ? p.theme.success
        : p.variant === 'error'
          ? p.theme.danger
          : p.theme.colors.green};
  border-radius: 5px;
  font-family: Poppins;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: ${p =>
    p.variant === 'success'
      ? p.theme.success
      : p.variant === 'error'
        ? p.theme.danger
        : p.theme.colors.green};
  background: ${p =>
    p.variant === 'success'
      ? 'rgba(234,249,242,0.5)'
      : p.variant === 'error'
        ? 'rgba(253,238,239,0.5)'
        : p.theme.colors.green};

  p {
    padding: 10px;
  }
`;
export const ContainerClose = styled.div`
  position: absolute;
  right: 20px;
  top: calc(50% - 12px);
  cursor: pointer;
`;
export const InvoiceInformation = ({ invoice }: InvoiceInformationProps) => {
  const [openHelp, setOpenHelp] = React.useState(false);
  const [openRejectReason, setOpenRejectReason] = React.useState(true);
  const theme = useTheme();
  return (
    <Box>
      {invoice &&
        invoice?.status === EInvoiceStatus.REJECTED &&
        openRejectReason && (
          <Box>
            <PopupNotifications variant="error">
              <ContainerClose onClick={() => setOpenRejectReason(false)}>
                <CloseIcon fill={theme.colors.error.default} />
              </ContainerClose>
              <p>
                {`Cette facture a été refusée le ${invoice?.statusChangedAt?.toLocaleDateString()} pour les raisons suivantes : ${invoice?.rejectReason}`}
              </p>
            </PopupNotifications>
          </Box>
        )}
      {invoice && invoice?.status === EInvoiceStatus.TO_BE_VALIDATED && (
        <Box>
          <Text>
            Vous trouverez ci-joint la facture fournisseur au format PDF, ainsi
            que les données de facturation saisies, transmises par votre contact
            fournisseur.
          </Text>
          <Flex alignItems="center" alignContent="center">
            <Text
              mt={20}
              mb={20}
              minWidth={'170px'}
              color={theme.colors.blue}
              variant="h3"
            >
              Aide à la validation
            </Text>
            <Divider mt={60} />
            {!openHelp ? (
              <ChevronDownIcon
                onClick={() => setOpenHelp(!openHelp)}
                style={{ cursor: 'pointer', marginLeft: '5px' }}
              />
            ) : (
              <ChevronUpIcon
                onClick={() => setOpenHelp(!openHelp)}
                style={{ cursor: 'pointer', marginLeft: '5px' }}
              />
            )}
          </Flex>
          {openHelp && (
            <Box>
              <Flex>
                <SuccessIcon />
                <Text variant="span" ml={2}>
                  <b>
                    Afin de procéder à une validation, merci de vérifier que :
                  </b>
                </Text>
              </Flex>
              <Box ml={20}>
                <ul>
                  <li style={{ paddingTop: 20 }}>
                    la facture PDF fournie est conforme pour paiement dans le
                    cadre de votre mission,
                  </li>
                  <li style={{ paddingTop: 20, paddingBottom: 20 }}>
                    les données de facturation saisies dans le formulaire
                    ci-dessous correspondent bien aux informations de la facture
                    PDF fournie.
                  </li>
                </ul>
              </Box>
              <Box>
                <Text variant="span">
                  <b>A noter: </b>en cas de facture PDF conforme, mais de
                  formulaire erroné (ex : erreurs de saisies), vous avez la
                  possibilité de modifier les données de facturation avant de
                  valider la facture. Ces données de facturation seront envoyées
                  en l’état à GESCOM pour comptabilisation de la facture par les
                  équipes comptables. En cas de facture PDF non conforme,
                  procédez à un refus via le bouton « Refuser » du formulaire
                </Text>
              </Box>
              <Flex mt={20}>
                <ExclamationCircleIcon fill={theme.danger} />
                <Text ml={2}>
                  En cas de facture PDF non conforme, procédez à un refus via le
                  bouton « Refuser » du formulaire
                </Text>
              </Flex>
              <Divider />
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};
