import {
  FlatUser,
  IEstablishment,
  IEstablishmentFullUsers,
  IEstablishmentMinifiedUsers,
} from '@freelancelabs/teoreme-commons';
import { Box } from 'components/ui';
import { StructuredCard, StructuredCardProps } from './StructuredCard';
import { useUserFindOne, checkAllStrcutureCreatedInGescom } from '@commons'; //moved
import {
  arrayContainsSameCustomerIds,
  transformPhoneToInternationalSafe,
} from '@commons';

export const UserStructuredCard = ({
  user,
  forRole,
  establishment,
  checkSage,
  checkSaleForce,
  ...props
}: {
  user: Partial<FlatUser> | string;
  forRole?: 'CUSTOMER' | 'PROVIDER';
  checkSage?: boolean;
  checkSaleForce?: boolean;
  onChangeSelected?: () => void;
  establishment?:
    | IEstablishment
    | IEstablishmentFullUsers
    | IEstablishmentMinifiedUsers;
} & Omit<
  StructuredCardProps,
  | 'avatarLetter'
  | 'primaryTopText'
  | 'secondaryTopText'
  | 'primaryBottomText'
  | 'secondaryBottomText'
>) => {
  const { data: userFullData } = useUserFindOne(
    (user as Partial<FlatUser>)?.cognitoUserId || (user as string) || ''
  );
  const establishmentSageReferences =
    forRole === 'CUSTOMER'
      ? establishment?.customer?.sage?.references
      : establishment?.provider?.sage?.references;
  const salesForces =
    forRole === 'CUSTOMER'
      ? establishment?.customer?.salesforce
      : establishment?.provider?.salesforce;
  const sage =
    userFullData?.sage?.references &&
    establishmentSageReferences &&
    arrayContainsSameCustomerIds(
      establishmentSageReferences,
      userFullData?.sage.references
    );

  const checkSageData = checkAllStrcutureCreatedInGescom(
    //@ts-ignore
    sage ? userFullData?.sage : undefined
  );
  type TypeLink = string | false;
  let link: TypeLink = false;
  if (forRole === 'CUSTOMER') {
    link = `/clients/contacts/${userFullData?.cognitoUserId}`;
  }
  if (forRole === 'PROVIDER') {
    link = `/providers/contacts/${userFullData?.cognitoUserId}/information`;
  }
  const activeRedirect = link;
  return (
    <Box style={{ cursor: activeRedirect ? 'pointer' : 'initial' }}>
      <StructuredCard
        redirect={link}
        checkSage={checkSage}
        sage={checkSageData}
        checkSaleForce={checkSaleForce}
        salesforce={checkSaleForce ? salesForces : false}
        avatarLetter={`${userFullData?.lastName?.[0] || ''} ${
          userFullData?.firstName?.[0] || ''
        }`}
        primaryTopText={`${userFullData?.lastName || ''} ${
          userFullData?.firstName || ''
        }`}
        secondaryTopText={userFullData?.jobTitle || ''}
        primaryBottomText={`Tél : ${
          transformPhoneToInternationalSafe(userFullData?.phone, 'FR') || 'N/A'
        }`}
        secondaryBottomText={`Email: ${userFullData?.email || 'N/A'}`}
        {...props}
      />
    </Box>
  );
};
