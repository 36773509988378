import React from 'react';
import styled from 'styled-components';
import { space, SpaceProps } from 'styled-system';
import { BoxProps } from './Box';
import { Flex } from './Flex';

type SpinnerProps = {
  size?: number;
  thickness?: number;
  speed?: number;
  color?: string;
};

export const Spinner = styled.div<SpinnerProps & SpaceProps>`
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  display: inline-block;
  width: ${props => props.size}px;
  height: ${props => props.size}px;
  border-width: ${props => props.thickness}px;
  border-color: ${props => props.color};
  border-bottom-color: transparent;
  border-left-color: transparent;
  border-style: solid;
  border-radius: 100%;
  color: ${props => props.color};
  animation: spin ${props => props.speed}s linear infinite;
  ${space}
`;

Spinner.defaultProps = {
  size: 30,
  thickness: 3,
  color: '#00ABEF',
  speed: 0.5,
};

export const SpinnerBox = ({
  size,
  thickness,
  color,
  speed,
  ...props
}: SpinnerProps & BoxProps) => {
  return (
    <Flex
      width="100%"
      alignItems="center"
      justifyContent="center"
      height={200}
      {...props}
    >
      <Spinner {...{ size, thickness, color, speed }} />
    </Flex>
  );
};
