import {
  checkDefaultManagerSelected,
  formatInvoiceOrAdditionalActivitySort,
  INVOICE_STATUS_SELECT,
  useAdditionalActivityFindMany,
  useMe,
} from '@commons';
import {
  IJoinedAdditionalActivity,
  EInvoiceStatus,
  IAdditionalActivitySearchParams,
} from '@freelancelabs/teoreme-commons';
import { Box, Itemize, SpinnerBox, Table, TableColumn } from 'components/ui';
import { useDebounce } from 'hooks/useDebounce';
import * as React from 'react';
import { useParams } from 'react-router-dom';
import { getFilterObjectMilestones } from './helpers';
const INVOICE_STATUS_SELECT_CUSTOM = INVOICE_STATUS_SELECT;
const Findex = INVOICE_STATUS_SELECT.findIndex(
  state => state.key === EInvoiceStatus.REJECTED
);
INVOICE_STATUS_SELECT_CUSTOM[Findex].label = 'Refusé';

type MilestonListProps = {
  columns: () => TableColumn<Itemize<IJoinedAdditionalActivity>>[];
  filterObject: IAdditionalActivitySearchParams['filterObject'];
  hookStore: any;
  filterComponents: React.ReactNode;
};
export const MilestonList = ({
  filterObject,
  columns,
  hookStore,
  filterComponents,
}: MilestonListProps) => {
  const { me } = useMe();
  const { filter: filterParams } = useParams<{
    filter:
      | 'all'
      | 'awaiting'
      | 'validated'
      | 'to-be-validated'
      | 'archived'
      | 'paid';
  }>();

  const {
    updateStore,
    resetStore,
    selectedStatus,
    searchQuery,
    limit,
    page,
    accountManagerId,
    selectedType,
    startDate,
    endDate,
    sortedBy,
    order,
    structureSelected,
    clientSelected,
    providerSelected,
    multiClientSelected,
    multiProviderSelected,
    contractorSelected,
  } = hookStore();
  const debouncedFilterQuery = useDebounce(searchQuery, 500);
  // const sortString = (order === 'desc' ? '-' : '') + sortedBy;
  React.useEffect(() => {
    // RESET GLOBAL STATE ON COMPONENT FIRST LOADING
    return resetStore({});
  }, []);
  const {
    data: milestones,
    status,
    isFetching,
  } = useAdditionalActivityFindMany({
    filterObject: {
      // filtre extern
      ...filterObject,
      //   // filtre interne
      ...getFilterObjectMilestones({
        debouncedFilterQuery: debouncedFilterQuery || '',
        startDate,
        endDate,
        selectedStatus:
          selectedStatus && selectedStatus !== 'ALL'
            ? selectedStatus
            : filterObject?.status,
        selectedType,
        accountManagerId: checkDefaultManagerSelected(
          me,
          accountManagerId as string
        )
          ? accountManagerId
          : undefined,
        structureSelected,
        clientSelected,
        providerSelected,
        multiClientSelected,
        multiProviderSelected,
        contractorSelected,
      }),
    },
    limit: limit,
    skip: limit * page,
    sort: formatInvoiceOrAdditionalActivitySort(`-month`),
  });

  const loading = status === 'pending';
  const totalCount = milestones?.totalCount || 0;
  const items = milestones?.additionalActivities?.map((activity: any) => {
    return {
      ...activity,
      key: 'Milestone_' + activity.uuid + '_' + new Date()?.getTime() || '',
    };
  });
  const onSortedChange = React.useCallback(
    (sortedBy: string, order: 'asc' | 'desc'): void => {
      //@ts-ignore
      //  updateInvoiceGlobalListStore({ page: 0, sortedBy, order });
    },
    []
  );

  //   React.useEffect(() => {
  //     //resetInvoiceStore({});
  //     //queryClient.invalidateQueries('Invoices');
  //   }, [filterParams]);

  return (
    <Box>
      <Box>{filterComponents}</Box>
      {loading && <SpinnerBox />}
      <Box>
        {!loading && (
          <Table<Itemize<IJoinedAdditionalActivity>>
            isLoading={isFetching}
            columns={columns()}
            items={items as Itemize<IJoinedAdditionalActivity>[]}
            sortedBy={sortedBy}
            order={order}
            page={page}
            total={totalCount}
            perPage={totalCount && totalCount < limit ? totalCount : limit}
            opacity={filterParams === 'archived' ? 0.5 : 1}
            onSortedChange={onSortedChange}
            onChangePage={page => updateStore({ page: page })}
          />
        )}
      </Box>
    </Box>
  );
};
