import {
  buildContractorByScopedData,
  getScopedDataMinified,
  duplicateMission,
  removeOffsetDate,
  isDisableFONEDate,
} from '@commons';
import {
  IJoinedMission,
  EMissionStructure,
} from '@freelancelabs/teoreme-commons';
import { ContractorCard } from 'components/cards/ContractorCard';
import { EstablishmentStructuredCard } from 'components/cards/EstablishmentStructuredCard';
import { UserStructuredCard } from 'components/cards/UserStructuredCard';
import { Section } from 'components/Section';
import {
  Box,
  Button,
  Radio,
  Flex,
  FormControl,
  Input,
  LabelField,
  Link,
  Text,
  DatePickerControlled,
  CheckSwitch,
} from 'components/ui';
import { CancelIcon, EyeIcon, EyeSlashIcon } from 'components/ui/icons';
import { useShowMessage } from 'hooks/useShowMessage';

import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

type FormValues = IJoinedMission & {
  copyBilling?: boolean;
  copyStandByDutyFormulas?: boolean;
};
type DuplicateMissionFormProps = {
  beforeValidation?: () => void;
  mission: IJoinedMission;
  onClose: (data: any) => void;
};

export const DuplicateMissionForm: React.FC<
  React.PropsWithChildren<DuplicateMissionFormProps>
> = ({ onClose, mission }) => {
  const history = useHistory();
  const showMessage = useShowMessage();
  const [loading, setLoading] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const billingExist = mission?.billing;
  const {
    control,
    watch,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {
      startAt: mission?.startAt,
      endAt: mission?.endAt,
      title: mission?.title,
      copyBilling: billingExist ? true : false,
      copyStandByDutyFormulas: false,
      billingInformation: { structure: mission?.billingInformation?.structure },
    },
  });
  const copyBilling = watch('copyBilling');
  const copyExpensesConfig = watch('copyExpensesConfig');
  const startAt = watch('startAt');
  const endAt = watch('endAt');
  const scopedData =
    mission?.provider?.contractor &&
    getScopedDataMinified(
      mission?.provider?.contractor?.scopedData,
      mission?.provider?.establishment.uuid
    );
  const contractorBuild =
    mission?.provider?.contractor &&
    buildContractorByScopedData(mission?.provider?.contractor, scopedData);

  const onSubmit = async (formValues: any) => {
    setLoading(true);
    try {
      const duplicateMissionData = await duplicateMission({
        duplicateMissionRef: mission?.reference,
        copyBilling: formValues?.copyBilling,
        copyStandByDutyFormulas: formValues?.copyStandByDutyFormulas,
        copyExpensesConfig: false,
        mission: {
          title: formValues?.title,
          endAt: removeOffsetDate(formValues?.endAt),
          startAt: removeOffsetDate(formValues?.startAt),
          billingInformation: formValues?.billingInformation,
        },
      });

      showMessage(
        'success',
        `Vous avez dupliqué la mission ${mission?.displayReference}`
      );
      history.push(
        `/delivery/missions/${duplicateMissionData?.reference}/gescom-create`
      );
    } catch (e) {
      setLoading(false);
    }
    setLoading(false);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormControl
        required
        label={'Quelle est la structure de facturation de cette mission ?'}
        errorMessage={errors?.billingInformation?.structure?.message}
      >
        <Box mt={20}>
          <Radio
            {...register('billingInformation.structure')}
            value={EMissionStructure.FCOM}
            data-cy="structure-FCOM"
          >
            Freelance.com
          </Radio>
          {!isDisableFONEDate() && (
            <Radio
              {...register('billingInformation.structure')}
              value={EMissionStructure.FONE}
            >
              Freelance.one
            </Radio>
          )}
          <Radio
            {...register('billingInformation.structure')}
            value={EMissionStructure.INOPS}
          >
            Inop's
          </Radio>
          <Radio
            {...register('billingInformation.structure')}
            value={EMissionStructure.COWORKEES}
          >
            Coworkees
          </Radio>
        </Box>
      </FormControl>

      <Flex alignContent="space-between">
        <FormControl
          p={10}
          required
          label="Intitulé de la mission"
          errorMessage={
            // @ts-ignore
            errors?.title?.type === 'maxLength'
              ? 'Veuillez saisir moins de 36 caractères'
              : // @ts-ignore
                errors?.title?.message
          }
          warningMessage={
            'Cet intitulé n’apparaîtra pas dans vos devis et BDC Fournisseur.'
          }
        >
          <Input isFullWidth {...register('title', { maxLength: 35 })} />
        </FormControl>
        <FormControl
          p={10}
          required
          label="Date de début"
          errorMessage={errors?.startAt?.message}
        >
          <DatePickerControlled
            control={control}
            maxDate={endAt}
            name="startAt"
            rules={{ required: 'Ce champs est requis' }}
            valueName="selected"
          />
        </FormControl>
        <FormControl
          p={10}
          required
          label="Date de fin"
          errorMessage={errors?.endAt?.message}
        >
          <DatePickerControlled
            control={control}
            valueName="selected"
            minDate={startAt}
            name="endAt"
            rules={{ required: 'Ce champs est requis' }}
          />
        </FormControl>
      </Flex>
      <Box mt={20} mb={20}>
        <Link
          onClick={() => setShowMore(!showMore)}
          iconLeft={showMore ? <EyeSlashIcon /> : <EyeIcon />}
        >
          {showMore
            ? 'Masquer les informations de la mission'
            : 'Voir les informations de la mission'}
        </Link>
      </Box>
      {showMore && (
        <Box>
          {mission?.provider && (
            <Section
              mt={20}
              variant="h2"
              title={<Text variant="h3">Informations du fournisseur</Text>}
            >
              <Box>
                <LabelField label="Établissement fournisseur" value="" />
                {mission?.provider?.establishment ? (
                  <EstablishmentStructuredCard
                    forRole={'PROVIDER'}
                    checkSage
                    establishment={mission?.provider?.establishment}
                    mb={20}
                  />
                ) : (
                  'N/A'
                )}
                <Box>
                  <LabelField label="Contact fournisseur" value="" />
                  {mission?.provider?.contact ? (
                    <UserStructuredCard
                      mb={20}
                      isFullWidth
                      user={mission?.provider?.contact}
                      establishment={mission?.provider?.establishment}
                      forRole={'PROVIDER'}
                      checkSage
                    />
                  ) : (
                    'N/A'
                  )}
                </Box>
                <Box>
                  <LabelField label="Intervenant" value="" />
                  {mission?.provider?.contractor ? (
                    <ContractorCard contractor={contractorBuild} />
                  ) : (
                    'N/A'
                  )}
                </Box>
              </Box>
            </Section>
          )}
          {mission?.customer && (
            <Section
              mt={20}
              variant="h2"
              title={<Text variant="h3">Informations du client</Text>}
            >
              <Box>
                <LabelField label="Établissement client" value="" />
                {mission?.customer?.establishment ? (
                  <EstablishmentStructuredCard
                    forRole={'CUSTOMER'}
                    checkSage
                    establishment={mission?.customer?.establishment}
                    mb={20}
                  />
                ) : (
                  'N/A'
                )}
                <Box>
                  <LabelField label="Contact client" value="" />
                  {mission?.customer?.contact ? (
                    <UserStructuredCard
                      mb={20}
                      isFullWidth
                      user={mission?.customer?.contact}
                      establishment={mission?.customer?.establishment}
                      forRole={'CUSTOMER'}
                      checkSage
                    />
                  ) : (
                    'N/A'
                  )}
                </Box>
              </Box>
            </Section>
          )}
          <LabelField
            label="Lieu de la mission :"
            value={mission?.location?.recipient}
          />
          <LabelField
            label="Adresse :"
            value={mission?.location?.street || 'N/A'}
          />
          <LabelField
            label="Complément d'adresse :"
            value={mission?.location?.additional || 'N/A'}
          />
          <LabelField
            label="Ville :"
            value={mission?.location?.city || 'N/A'}
          />
          <LabelField
            label="Code postal :"
            value={mission?.location?.postalCode || 'N/A'}
          />
          <LabelField
            label="Pays :"
            value={mission?.location?.country || 'N/A'}
          />
          <LabelField
            label="Département, service ou BU :"
            value={mission?.location?.businessUnit || 'N/A'}
          />
          <LabelField
            label="Marque :"
            value={mission?.location?.brand || 'N/A'}
          />
          <LabelField
            label="Description :"
            value={mission?.description || 'N/A'}
          />
        </Box>
      )}
      {billingExist && (
        <Section
          mt={20}
          variant="h2"
          title={<Text variant="h3">Tarification</Text>}
        >
          <Flex
            mb={20}
            justifyContent={'flex-start'}
            alignContent={'center'}
            alignItems={'center'}
          >
            <CheckSwitch id="copyBilling" {...register('copyBilling')}>
              Conserver les informations de tarification de la mission d’origine
            </CheckSwitch>
          </Flex>
          {copyBilling && (
            <>
              <LabelField
                label="Nombre de jours estimé :"
                value={mission?.billing?.estimatedDays || 'N/A'}
              />
              <LabelField
                label="Tarif jours payé par le client ( HT ) :"
                value={(mission?.billing?.clientRate || 'N/A') + ' €'}
              />
              <LabelField
                label="Tarif jours perçu par l'intervenant ( HT ) : "
                value={(mission?.billing?.contractorRate || 'N/A') + ' €'}
              />
              <LabelField
                label="Taux de marque : "
                value={(mission?.billing?.margin?.toFixed(2) || 'N/A') + ' %'}
              />
            </>
          )}
          {mission?.standByDutyFormulas &&
            mission?.standByDutyFormulas?.length > 0 && (
              <Flex mb={20}>
                <CheckSwitch
                  id="copyStandByDutyFormulas"
                  {...register('copyStandByDutyFormulas')}
                >
                  Conserver les formules des prestations complémentaires de la
                  mission d’origine
                </CheckSwitch>
              </Flex>
            )}
          <Flex>
            <CheckSwitch
              id="copyExpensesConfig"
              {...register('copyExpensesConfig')}
            >
              Conserver la configuration des frais de la mission d’origine
            </CheckSwitch>
          </Flex>
          {copyExpensesConfig && (
            <Box mt={20}>
              <LabelField
                label="Activer les frais : "
                value={
                  mission?.expensesConfig?.isEnabled ? 'Activé' : 'Désactivé'
                }
              />
              <LabelField
                label="Taux de marque appliqué aux frais : "
                value={`${
                  mission?.expensesConfig?.margin === undefined
                    ? 'N/A '
                    : mission?.expensesConfig?.margin
                } %`}
              />
              <LabelField
                label="Justificatif requis : "
                value={
                  mission?.expensesConfig?.shouldJoinAttachment ? 'Oui' : 'Non'
                }
              />
            </Box>
          )}
        </Section>
      )}

      <Flex p={10} alignItems="center">
        <Button key="submit" type="submit" isLoading={loading}>
          Dupliquer
        </Button>
        <Button onClick={() => onClose(false)} ml={10}>
          Annuler
        </Button>
      </Flex>
    </form>
  );
};
