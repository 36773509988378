import { IJoinedDpae, EDpaeStatus } from '@freelancelabs/teoreme-commons';
import { ALink } from 'components/ALink';
import { Flex, Link, Menu, MenuItem, Spinner } from 'components/ui';
import {
  ArchiveIcon,
  DotsIcon,
  EyeIcon,
  RefreshIcon,
  ValidateIcon,
} from 'components/ui/icons';
import React from 'react';
import { showRejectDpaeModal } from 'components/modals/dpae/RejectDpaeModal';
import { showDisplayPdfModal } from 'components/modals/DisplayPdfModal';
import { useMutation } from '@tanstack/react-query';
import { useShowMessage } from 'hooks/useShowMessage';
import { dpaeResendEmail, dpaeUpdateOne } from '@commons';
import { queryClient } from '@commons';
import {
  canDisplayDpaeResendButton,
  canDisplayDpaeShowButton,
  canDisplayDpaeValidationButton,
} from 'helpers/business/dpae';
import { Theme } from 'styles';
export const DPAEActions = ({
  DPAE,
  filter,
}: {
  DPAE: IJoinedDpae;
  filter: 'active' | 'pending' | 'to-be-validated' | 'archived';
}) => {
  const showMessage = useShowMessage();
  const ReSendButton = ({ dpae }: { dpae: IJoinedDpae }) => {
    const { mutateAsync: onResend, status } = useMutation({
      mutationFn: () =>
        dpaeResendEmail({
          contractor: {
            uuid: dpae.contractor.uuid,
            dpae: dpae.uuid || '',
            establishment:
              dpae.contractor?.scopedData?.[0]?.establishment || '',
          },
        }),
    });
    if (status === 'pending') return <Spinner size={20} />;
    if (status === 'success')
      showMessage('success', 'Votre demande de DPAE a bien été renvoyée');
    return (
      <Link iconLeft={<RefreshIcon />} onClick={() => onResend()}>
        Relancer
      </Link>
    );
  };

  return (
    <Flex alignItems="center">
      {canDisplayDpaeShowButton(filter) && (
        <ALink>
          <Link
            iconLeft={<EyeIcon />}
            onClick={() =>
              showDisplayPdfModal({
                fileLocation: DPAE.attachment?.fileLocation || '',
                fileName: `DPAE_${DPAE.businessName?.replace(/ /g, '_')}_${
                  DPAE.contractor?.firstName
                }_${DPAE.contractor?.lastName}`,
              })
            }
          >
            {/* Voir la DPAE */}
          </Link>
        </ALink>
      )}
      {!canDisplayDpaeShowButton(filter) && (
        <ALink
          color={'#00abef'}
          href={`/providers/contractors/${DPAE.contractor.uuid}`}
        >
          <Link iconLeft={<EyeIcon />}>{/* Voir la fiche intervenant */}</Link>
        </ALink>
      )}
      {canDisplayDpaeValidationButton(filter) && (
        <Link
          iconLeft={<ValidateIcon />}
          onClick={async () => {
            await showDisplayPdfModal({
              layer: -1,
              fileLocation: DPAE.attachment?.fileLocation || '',
              fileName: `DPAE_${DPAE.businessName?.replace(/ /g, '_')}_${
                DPAE.contractor?.firstName
              }_${DPAE.contractor?.lastName}`,
              confirmLabel: 'Valider',
              onConfirm: async () => {
                await dpaeUpdateOne({
                  uuid: DPAE.uuid as string,
                  dpae: {
                    status: EDpaeStatus.ACTIVE,
                  },
                });
                await queryClient.refetchQueries({
                  queryKey: ['dpaes'],
                  type: 'active',
                });
              },
              rejectLabel: 'Refuser',
              onReject: async () => {
                await showRejectDpaeModal({
                  dpae: DPAE,
                });
              },
            });
          }}
        >
          Valider
        </Link>
      )}
      <Menu
        ml={10}
        align="right"
        menuItems={
          <>
            {canDisplayDpaeResendButton(filter) && (
              <MenuItem>
                <ReSendButton dpae={DPAE} />
              </MenuItem>
            )}
            {canDisplayDpaeShowButton(filter) && (
              <MenuItem>
                <ALink
                  color={'#00abef'}
                  href={`/providers/contractors/${DPAE.contractor.uuid}`}
                >
                  <Link iconLeft={<EyeIcon />}>Voir la fiche intervenant</Link>
                </ALink>
              </MenuItem>
            )}
            <MenuItem>
              <ALink
                color={'#00abef'}
                href={`/providers/contacts/${DPAE.contractor?.scopedData?.[0]?.contact?.cognitoUserId}/information`}
              >
                <Link iconLeft={<EyeIcon />}>
                  Voir la fiche contact fournisseur
                </Link>
              </ALink>
            </MenuItem>
            <MenuItem>
              <Link iconLeft={<ArchiveIcon />}>Archiver l'intervenant</Link>
            </MenuItem>
          </>
        }
      >
        <DotsIcon fill={Theme?.colors?.primary?.default} fontSize={20} />
      </Menu>
    </Flex>
  );
};
