import {
  cutLongText,
  displayMonth,
  invoiceFindOne,
  MAX_CUSTOMER_REFERENCE_FIELD_LENGTH,
} from '@commons';
import {
  EBillingType,
  ECraPatchActions,
  EInvoiceDocumentType,
  EInvoiceStatus,
  EMissionType,
  ERejectCraReason,
  IFile,
  IJoinedCraWithInvoice,
  IJoinedInvoice,
} from '@freelancelabs/teoreme-commons';
import { CardCra } from 'components/cards';
import { ConfirmNavigationModal } from 'components/modals/ConfirmNavigationModal';
import {
  BlocInformation,
  Box,
  Button,
  FileInput,
  Flex,
  FormControl,
  Input,
  Link,
  NumberFormInputControlled,
  Radio,
  Text,
} from 'components/ui';
import {
  AddIcon,
  CloseIcon,
  DownloadIcon,
  EuroIcon,
} from 'components/ui/icons';
import { RejectCraForm } from 'forms/RejectCraForm';
import React from 'react';
import { useForm } from 'react-hook-form';
import { Theme } from 'styles';

type FormValues = {
  clientRate: number;
  contractorRate: number;
  nbWorkingDays: number;
  nbFreeDays: number;
  proof: IFile;
  customerCreditNoteReference?: string;
  billFreeDaysToCustomer?: boolean;
};
type StepTwoFormProps = {
  dataStep: any;
  steps: any;
  cra: IJoinedCraWithInvoice;
  onSubmit: (data: any) => any | void;
  onChangeStep: () => any | void;
};

export const StepTwo = ({
  dataStep,
  steps,
  cra,
  onSubmit: onSubmitParent,
  onChangeStep,
}: StepTwoFormProps) => {
  const [proofFile, setProofFile] = React.useState<File | null>(
    dataStep?.proofFile
  );
  const [providerInvoice, setProviderInvoice] = React.useState<
    false | IJoinedInvoice
  >(false);
  const [customerInvoice, setCustomerInvoice] = React.useState<
    false | IJoinedInvoice
  >(false);
  const [invoiceFileForm, setInvoiceFileForm] = React.useState<File | null>(
    dataStep?.invoiceFileForm
  );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [gescomIdentifierType, setGescomIdentifierType] = React.useState<
    'CREDIT' | 'COMPLEMENTARY_INVOICE'
  >(dataStep?.gescomIdentifierType || 'CREDIT');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [gescomCustomerIdentifierType, setGescomCustomerIdentifierType] =
    React.useState<'CREDIT' | 'COMPLEMENTARY_INVOICE'>(
      dataStep?.gescomCustomerIdentifierType || 'CREDIT'
    );

  const [errorCustomerCreditNoteReference, setErrorCustomerCreditNotReference] =
    React.useState<String | null>();

  const [invoiceFile, setInvoiceFile] = React.useState<IFile | null>();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [proof, setProof] = React.useState<IFile | null>(cra?.proof || null);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [errorGescom, setErrorGescom] = React.useState<false | string>(false);
  //GESCOM FIX
  //  avoir fournisseur
  // eslint-disable-next-line react-hooks/rules-of-hooks, @typescript-eslint/no-unused-vars
  const [providerCreditNoteId, setProviderCreditNoteId] = React.useState(
    dataStep?.providerCreditNoteId
  );
  //  facture complémentaire fournisseur

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [providerComplementaryInvoiceId, setProviderComplementaryInvoiceId] =
    // eslint-disable-next-line react-hooks/rules-of-hooks
    React.useState(dataStep?.providerComplementaryInvoiceId);
  //  avoir client
  // eslint-disable-next-line react-hooks/rules-of-hooks, @typescript-eslint/no-unused-vars
  const [customerCreditNoteId, setCustomerCreditNoteId] = React.useState(
    dataStep?.customerCreditNoteId
  );
  //  facture complémentaire client

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [customerComplementaryInvoiceId, setCustomerComplementaryInvoiceId] =
    // eslint-disable-next-line react-hooks/rules-of-hooks
    React.useState(dataStep?.customerComplementaryInvoiceId);

  const [billFreeDaysToCustomer, setBillFreeDaysToCustomer] =
    // eslint-disable-next-line react-hooks/rules-of-hooks
    React.useState(
      dataStep?.billFreeDaysToCustomer ?? cra.billFreeDaysToCustomer
    );
  const [showFreeDaysInput, setShowFreeDaysInput] =
    // eslint-disable-next-line react-hooks/rules-of-hooks
    React.useState(
      // false
      cra?.nbFreeDays && cra?.nbFreeDays > 0 ? true : false
    );

  const [showCancelFreeDaysInput, setShowCancelFreeDaysInput] =
    // eslint-disable-next-line react-hooks/rules-of-hooks
    React.useState(false);

  const canShowAddFreeDaysButton = () => {
    if (!cra?.nbFreeDays && cra?.mission?.isFreeDaysEnabled) return true;
    return false;
  };

  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isDirty, isSubmitSuccessful },
    setValue,
    watch,
    getValues,
    // eslint-disable-next-line react-hooks/rules-of-hooks
  } = useForm<FormValues>({
    defaultValues: {
      // todo change this if isMarginInvoice
      clientRate: dataStep?.clientRate || cra?.mission?.billing?.clientRate,
      contractorRate: dataStep?.contractorRate || cra?.contractorRate,
      nbWorkingDays: dataStep?.nbWorkingDays || cra?.nbWorkingDays,
      nbFreeDays: dataStep?.nbFreeDays || cra?.nbFreeDays,
      proof: dataStep?.proofFile || cra?.proof,
      billFreeDaysToCustomer:
        dataStep?.billFreeDaysToCustomer || cra?.billFreeDaysToCustomer,
    },
  });

  const nbWorkingDays = watch('nbWorkingDays');
  const nbFreeDays = watch('nbFreeDays');
  const customerCreditNoteReference = watch('customerCreditNoteReference');
  const estimatedDays = cra?.mission?.billing?.estimatedDays;
  const declaredWorkingDaysInCras =
    cra?.mission?.billing?.declaredWorkingDaysInCras;
  const nbRemainingDays =
    (estimatedDays || 0) +
    (cra?.nbWorkingDays || 0) -
    (declaredWorkingDaysInCras || 0);

  const disableButton = () => {
    //

    return false;
  };
  const isDisabled = disableButton();

  const dataStepOne = steps[0]?.data;
  const formControlSize = 4 / 12;
  let bdlProvider = false;
  let bdlCustomer = false;
  let bdlCustomerSage: any | false = false;
  let bdlProviderSage: any | false = false;
  let bdlCustomerIsInvoice = false;
  let bdlProviderIsInvoice = false;
  if (cra?.invoices?.provider?.sage?.documentType) {
    bdlProvider = true;
    bdlProviderSage = cra?.invoices?.provider?.sage;
  }
  if (cra?.invoices?.customer?.sage?.documentType) {
    bdlCustomer = true;
    bdlCustomerSage = cra?.invoices?.customer?.sage;
  }
  if (bdlProviderSage?.documentType === EInvoiceDocumentType?.INVOICE) {
    bdlProviderIsInvoice = true;
  }
  if (bdlCustomerSage?.documentType === EInvoiceDocumentType?.INVOICE) {
    bdlCustomerIsInvoice = true;
  }
  const invoiceCustomer = cra?.invoices?.customer;
  const invoiceProvider = cra?.invoices?.provider;
  const structure = cra?.mission?.billingInformation?.structure;

  const onSubmit = async (formValues: FormValues) => {
    onSubmitParent({
      ...formValues,
      proofFile,
      invoiceFileForm,
      invoiceFile,
      providerCreditNoteId,
      providerComplementaryInvoiceId,
      customerCreditNoteId,
      customerComplementaryInvoiceId,
      gescomIdentifierType,
      gescomCustomerIdentifierType,
      providerInvoice,
      customerCreditNoteReference,
      billFreeDaysToCustomer: formValues?.nbFreeDays
        ? billFreeDaysToCustomer
        : undefined,
      customerInvoiceClientRate:
        (customerInvoice as IJoinedInvoice)?.clientRate ??
        cra?.mission?.billing?.clientRate,
    });
  };
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onFinishTutorial = async () => {
    let onError = false;
    if (dataStepOne?.action === ECraPatchActions?.FIX_CRA) {
      if (invoiceProvider?.status === EInvoiceStatus?.VALIDATED) {
        /* CAS :3.2.1.1.1*/
        if (!providerCreditNoteId && !providerComplementaryInvoiceId) {
          onError = true;
          setErrorGescom(
            "Veuillez renseigner le numéro de l'avoir ou le numéro de la facture complémentaire crée dans GESCOM"
          );
        }
        /* CAS :3.2.1.2.1*/
        if (
          bdlCustomerIsInvoice &&
          !customerCreditNoteId &&
          !customerComplementaryInvoiceId
        ) {
          onError = true;
          setErrorGescom(
            "Veuillez renseigner le numéro de l'avoir ou le numéro de la facture complémentaire crée dans GESCOM"
          );
        }
      }

      if (invoiceProvider?.status !== EInvoiceStatus?.VALIDATED) {
        /* CAS :2.2.1.2.2*/
        if (
          bdlCustomerIsInvoice &&
          !customerCreditNoteId &&
          !customerComplementaryInvoiceId
        ) {
          onError = true;
          setErrorGescom(
            "Veuillez renseigner le numéro de l'avoir ou le numéro de la facture complémentaire crée dans GESCOM"
          );
        }
      }
    }
    if (dataStepOne?.action === ECraPatchActions?.REJECT_CRA) {
      if (invoiceProvider?.status === EInvoiceStatus?.VALIDATED) {
        /* CAS :3.2.2.1.1  ET CAS :3.2.2.2.1*/
        if (!providerCreditNoteId) {
          onError = true;
          setErrorGescom(
            "Veuillez renseigner le numéro de l'avoir ou le numéro de la facture complémentaire crée dans GESCOM"
          );
        }
        /* CAS :3.2.2.2.2*/
        if (bdlCustomerIsInvoice && !customerCreditNoteId) {
          onError = true;
          setErrorGescom(
            "Veuillez renseigner le numéro de l'avoir crée dans GESCOM"
          );
        }
      }
      if (invoiceProvider?.status !== EInvoiceStatus?.VALIDATED) {
        /* CAS :2.2.2.2.2*/
        if (bdlCustomerIsInvoice && !customerCreditNoteId) {
          onError = true;
          setErrorGescom(
            "Veuillez renseigner le numéro de l'avoir crée dans GESCOM"
          );
        }
      }
    }
    if (!onError) {
      setErrorGescom(false);
      onSubmitParent({
        tutorialFinish: true,
        invoiceCustomer,
        invoiceProvider,
        bdlCustomerIsInvoice,
        bdlProviderIsInvoice,
        bdlProviderSage,
        bdlCustomerSage,
        bdlProvider,
        bdlCustomer,
        structure,
        //GESCOM PROBABLY UNDEFINED !
        providerCreditNoteId,
        providerComplementaryInvoiceId,
        customerCreditNoteId,
        customerComplementaryInvoiceId,
        invoiceFileForm,
        invoiceFile,
        proofFile,
        gescomIdentifierType,
        gescomCustomerIdentifierType,
        customerInvoiceClientRate: (customerInvoice as IJoinedInvoice)
          ?.clientRate,
      });
    }
  };
  const onRejectCra = async (formValues: any) => {
    onSubmitParent({
      rejectMetaData: formValues?.cra?.rejectMetaData,
      rejectReason: formValues?.cra?.rejectReason,
      customerCreditNoteReference: customerCreditNoteReference,
    });
  };

  const handleChangeProofFile = async (files: File[]) => {
    setProofFile(files[0]);
  };
  const handleChangeInvoiceFile = async (files: File[]) => {
    setInvoiceFileForm(files[0]);
  };
  const findProviderInvoice = async () => {
    try {
      const invoice = await invoiceFindOne(
        cra?.invoices?.provider?.uuid as string
      );
      setProviderInvoice(invoice);
      setInvoiceFile(invoice?.invoiceFile);
    } catch (e) {
      //
    }
  };
  const findCustomerInvoice = async () => {
    try {
      if (cra?.invoices?.customer?.uuid) {
        const invoice = await invoiceFindOne(
          cra?.invoices?.customer?.uuid as string
        );
        setCustomerInvoice(invoice);
        setValue('clientRate', invoice?.clientRate as number);
      } else {
        setValue('clientRate', cra?.mission?.billing?.clientRate as number);
      }
    } catch (e) {
      //
    }
  };
  // eslint-disable-next-line react-hooks/rules-of-hooks
  React.useEffect(() => {
    if (!providerInvoice && dataStepOne?.action === ECraPatchActions?.FIX_CRA) {
      findProviderInvoice();
    }
    if (!customerInvoice && dataStepOne?.action === ECraPatchActions?.FIX_CRA) {
      findCustomerInvoice();
    }
  }, [providerInvoice, customerInvoice]);
  /* CAS 2 :  CRA validé (BL créés, facture fournisseur non validé) */
  // if (
  //   !dataStep?.tutorialFinish &&
  //   (bdlProvider || bdlCustomer) &&
  //   invoiceProvider?.status !== EInvoiceStatus?.VALIDATED
  // ) {
  //   return (
  //     <Box width={1 / 1} p={20}>
  //       <Text variant="h1">Actions Gescom</Text>
  //       <Flex width={1 / 1} justifyContent="center" mb={50}>
  //         <ExclamationCircleIcon
  //           fill={Theme?.colors?.warning?.default}
  //           width={200}
  //           height={200}
  //         />
  //       </Flex>
  //       <Flex mt={20}>
  //         <ExclamationCircleIcon
  //           fill={Theme?.colors?.error?.default}
  //           width={25}
  //         />
  //         <Text variant="span" ml={2}>
  //           <b>
  //             Avant de procéder aux mises à jour dans Connecteed, merci
  //             d’effectuer les modifications suivantes dans la Gescom :
  //           </b>
  //         </Text>
  //       </Flex>
  //       <Box ml={40} mt={20}>
  //         <ol>
  //           <Text
  //             variant="li"
  //             style={{
  //               listStyleType: 'none',
  //               marginLeft: 20,
  //               fontWeight: 'bold',
  //             }}
  //           >
  //             {/* CAS :2.2.1.1.1*/}
  //             {dataStepOne?.action === ECraPatchActions?.FIX_CRA &&
  //               !bdlCustomerIsInvoice &&
  //               `1 - Corriger le BL fournisseur ${structure} - ${bdlProviderSage?.invoiceId} pour tenir compte des modifications souhaitées sur le nombre de jours et/ou le TJM fournisseur`}
  //             {/* CAS :2.2.1.2.1*/}
  //             {dataStepOne?.action === ECraPatchActions?.FIX_CRA &&
  //               bdlCustomerIsInvoice &&
  //               `1 - Corriger le BL fournisseur ${structure} - ${bdlProviderSage?.invoiceId} pour tenir compte des modifications souhaitées sur le nombre de jours et/ou le TJM fournisseur`}
  //             {/* CAS :2.2.2.1.1*/}
  //             {dataStepOne?.action === ECraPatchActions?.REJECT_CRA &&
  //               !bdlCustomerIsInvoice &&
  //               `1 - Supprimer le BL fournisseur ${structure} - ${bdlProviderSage?.invoiceId} en Gescom`}
  //             {/* CAS :2.2.2.2.1*/}
  //             {dataStepOne?.action === ECraPatchActions?.REJECT_CRA &&
  //               bdlCustomerIsInvoice &&
  //               `1 - Supprimer le BL fournisseur ${structure} - ${bdlProviderSage?.invoiceId} en Gescom`}
  //           </Text>

  //           <Text
  //             variant="li"
  //             style={{
  //               listStyleType: 'none',
  //               marginLeft: 20,
  //               fontWeight: 'bold',
  //             }}
  //           >
  //             {/* CAS :2.2.1.1.2*/}
  //             {dataStepOne?.action === ECraPatchActions?.FIX_CRA &&
  //               !bdlCustomerIsInvoice &&
  //               `2 - Corriger le BL client ${structure} - ${bdlCustomerSage?.invoiceId} pour tenir compte des modifications souhaitées sur le nombre de jours et/ou le TJM client`}
  //             {/* CAS :2.2.1.2.2*/}
  //             {dataStepOne?.action === ECraPatchActions?.FIX_CRA &&
  //               bdlCustomerIsInvoice &&
  //               `2 - Créer un avoir ou une facture complémentaire Client pour tenir compte des modifications souhaitées sur le nombre de jours et/ou le TJM client`}
  //             {/* CAS :2.2.2.1.2*/}
  //             {dataStepOne?.action === ECraPatchActions?.REJECT_CRA &&
  //               !bdlCustomerIsInvoice &&
  //               `2 - Supprimer le BL client ${structure} - ${bdlCustomerSage?.invoiceId} en Gescom`}
  //             {/* CAS :2.2.2.2.2*/}
  //             {dataStepOne?.action === ECraPatchActions?.REJECT_CRA &&
  //               bdlCustomerIsInvoice &&
  //               `2 - Créer un avoir pour annuler la facture client ${structure} - ${bdlCustomerSage?.invoiceId} en Gescom`}
  //           </Text>
  //         </ol>
  //         <Flex mt={20}>
  //           {dataStepOne?.action === ECraPatchActions?.FIX_CRA &&
  //             bdlCustomerIsInvoice && (
  //               <Flex flexWrap={'wrap'}>
  //                 <Box p={20} width={1 / 1}>
  //                   <FormControl label="Qu'avez vous créé ?" required>
  //                     <Flex>
  //                       <Radio
  //                         checked={gescomCustomerIdentifierType === 'CREDIT'}
  //                         value={'CREDIT'}
  //                         onClick={() =>
  //                           setGescomCustomerIdentifierType('CREDIT')
  //                         }
  //                       >
  //                         Un avoir
  //                       </Radio>
  //                       <Radio
  //                         checked={
  //                           gescomCustomerIdentifierType ===
  //                           'COMPLEMENTARY_INVOICE'
  //                         }
  //                         value={'COMPLEMENTARY_INVOICE'}
  //                         onClick={() =>
  //                           setGescomCustomerIdentifierType(
  //                             'COMPLEMENTARY_INVOICE'
  //                           )
  //                         }
  //                       >
  //                         Une facture complémentaire
  //                       </Radio>
  //                     </Flex>
  //                   </FormControl>
  //                 </Box>
  //                 {gescomCustomerIdentifierType === 'CREDIT' && (
  //                   <FormControl required label="N° de l'avoir ( Client ) créé">
  //                     <Input
  //                       value={customerCreditNoteId}
  //                       onChange={(e: any) =>
  //                         setCustomerComplementaryInvoiceId(e?.target?.value)
  //                       }
  //                     />
  //                   </FormControl>
  //                 )}
  //                 {gescomCustomerIdentifierType === 'COMPLEMENTARY_INVOICE' && (
  //                   <FormControl
  //                     required
  //                     label="N° de la facture complémentaire ( Client ) créé"
  //                   >
  //                     <Input
  //                       value={customerComplementaryInvoiceId}
  //                       onChange={(e: any) =>
  //                         setCustomerComplementaryInvoiceId(e?.target?.value)
  //                       }
  //                     />
  //                   </FormControl>
  //                 )}
  //               </Flex>
  //             )}
  //           {dataStepOne?.action === ECraPatchActions?.REJECT_CRA &&
  //             bdlCustomerIsInvoice && (
  //               <FormControl required label="N° de l'avoir ( Client ) créé">
  //                 <Input
  //                   value={customerCreditNoteId}
  //                   onChange={(e: any) =>
  //                     setCustomerCreditNoteId(e?.target?.value)
  //                   }
  //                 />
  //               </FormControl>
  //             )}
  //         </Flex>
  //       </Box>

  //       <Flex mt={20}>
  //         <SuccessIcon fill={Theme?.colors?.error?.default} width={25} />
  //         <Text ml={2} variant="b">
  //           Une fois ces modifications effectuées, veuillez cliquer sur le
  //           bouton “Suivant”
  //         </Text>
  //       </Flex>
  //       <Box>
  //         <Text mb={20} color={Theme?.colors?.error?.default} variant="p">
  //           {errorGescom}
  //         </Text>
  //       </Box>
  //       <Flex justifyContent={'flex-end'} width={1 / 1} mb={20} pr={50}>
  //         <Button mr={20} type="button" onClick={() => onChangeStep()}>
  //           Précédent
  //         </Button>
  //         <Button type="button" onClick={() => onFinishTutorial()}>
  //           Suivant
  //         </Button>
  //       </Flex>
  //     </Box>
  //   );
  // }
  /* CAS 3 : CRA et factures fournisseur validées */
  // if (
  //   !dataStep?.tutorialFinish &&
  //   (bdlProvider || bdlCustomer) &&
  //   invoiceProvider?.status === EInvoiceStatus?.VALIDATED
  // ) {
  //   //return <Box>LE CAS 3: BDL ET FACTURE VALIDÉ</Box>;
  //   return (
  //     <Box width={1 / 1} p={20}>
  //       <Text variant="h1">Actions Gescom</Text>
  //       <Flex width={1 / 1} justifyContent="center" mb={50}>
  //         <ExclamationCircleIcon
  //           fill={Theme?.colors?.warning?.default}
  //           width={200}
  //           height={200}
  //         />
  //       </Flex>
  //       <Flex mt={20}>
  //         <ExclamationCircleIcon
  //           fill={Theme?.colors?.error?.default}
  //           width={25}
  //         />
  //         <Text variant="span" ml={2}>
  //           <b>
  //             Avant de procéder aux mises à jour dans Connecteed, merci
  //             d’effectuer les modifications suivantes dans la Gescom :
  //           </b>
  //         </Text>
  //       </Flex>
  //       <Box ml={40} mt={20}>
  //         <ol>
  //           <Text
  //             variant="li"
  //             style={{
  //               listStyleType: 'none',
  //               marginLeft: 20,
  //               fontWeight: 'bold',
  //             }}
  //           >
  //             {/* CAS :3.2.1.1.1*/}
  //             {dataStepOne?.action === ECraPatchActions?.FIX_CRA &&
  //               !bdlCustomerIsInvoice &&
  //               `1 - Créer un avoir ou une facture complémentaire à la facture fournisseur ${structure} - ${bdlProviderSage?.invoiceId} pour tenir compte des modifications souhaitées sur le nombre de jours et/ou le TJM fournisseur`}
  //             {/* CAS :3.2.1.2.1*/}
  //             {dataStepOne?.action === ECraPatchActions?.FIX_CRA &&
  //               bdlCustomerIsInvoice &&
  //               `1 - Créer un avoir ou une facture complémentaire à la facture fournisseur ${structure} - ${bdlProviderSage?.invoiceId} pour tenir compte des modifications souhaitées sur le nombre de jours et/ou le TJM fournisseur`}
  //             {/* CAS :3.2.2.1.1*/}
  //             {dataStepOne?.action === ECraPatchActions?.REJECT_CRA &&
  //               !bdlCustomerIsInvoice &&
  //               `1 - Créer un avoir pour annuler la facture fournisseur ${structure} - ${bdlProviderSage?.invoiceId} en Gescom`}
  //             {/* CAS :3.2.2.2.1*/}
  //             {dataStepOne?.action === ECraPatchActions?.REJECT_CRA &&
  //               bdlCustomerIsInvoice &&
  //               `1 - Créer un avoir pour annuler la facture fournisseur ${structure} - ${bdlProviderSage?.invoiceId} en Gescom`}
  //           </Text>
  //           <Flex mt={20}>
  //             {dataStepOne?.action === ECraPatchActions?.FIX_CRA &&
  //               !bdlProviderIsInvoice && (
  //                 <Flex flexWrap={'wrap'}>
  //                   <Box p={20} width={1 / 1}>
  //                     <FormControl label="Qu'avez vous créé ?" required>
  //                       <Flex>
  //                         <Radio
  //                           checked={gescomIdentifierType === 'CREDIT'}
  //                           value={'CREDIT'}
  //                           onClick={() => setGescomIdentifierType('CREDIT')}
  //                         >
  //                           Un avoir
  //                         </Radio>
  //                         <Radio
  //                           checked={
  //                             gescomIdentifierType === 'COMPLEMENTARY_INVOICE'
  //                           }
  //                           value={'COMPLEMENTARY_INVOICE'}
  //                           onClick={() =>
  //                             setGescomIdentifierType('COMPLEMENTARY_INVOICE')
  //                           }
  //                         >
  //                           Une facture complémentaire
  //                         </Radio>
  //                       </Flex>
  //                     </FormControl>
  //                   </Box>
  //                   {gescomIdentifierType === 'CREDIT' && (
  //                     <FormControl
  //                       required
  //                       label="N° de l'avoir ( Fournisseur ) créé"
  //                       ml={20}
  //                     >
  //                       <Input
  //                         value={providerCreditNoteId}
  //                         onChange={(e: any) =>
  //                           setProviderCreditNoteId(e?.target?.value)
  //                         }
  //                       />
  //                     </FormControl>
  //                   )}

  //                   {gescomIdentifierType === 'COMPLEMENTARY_INVOICE' && (
  //                     <FormControl
  //                       required
  //                       label="N° de la facture complémentaire ( Fournisseur ) créé"
  //                       ml={20}
  //                     >
  //                       <Input
  //                         value={providerComplementaryInvoiceId}
  //                         onChange={(e: any) =>
  //                           setProviderComplementaryInvoiceId(e?.target?.value)
  //                         }
  //                       />
  //                     </FormControl>
  //                   )}
  //                 </Flex>
  //               )}
  //             {dataStepOne?.action === ECraPatchActions?.FIX_CRA &&
  //               bdlProviderIsInvoice && (
  //                 <Flex flexWrap={'wrap'}>
  //                   <Box p={20} width={1 / 1}>
  //                     <FormControl label="Qu'avez vous créé ?" required>
  //                       <Flex>
  //                         <Radio
  //                           checked={gescomIdentifierType === 'CREDIT'}
  //                           value={'CREDIT'}
  //                           onClick={() => setGescomIdentifierType('CREDIT')}
  //                         >
  //                           Un avoir
  //                         </Radio>
  //                         <Radio
  //                           checked={
  //                             gescomIdentifierType === 'COMPLEMENTARY_INVOICE'
  //                           }
  //                           value={'COMPLEMENTARY_INVOICE'}
  //                           onClick={() =>
  //                             setGescomIdentifierType('COMPLEMENTARY_INVOICE')
  //                           }
  //                         >
  //                           Une facture complémentaire
  //                         </Radio>
  //                       </Flex>
  //                     </FormControl>
  //                   </Box>
  //                   {gescomIdentifierType === 'CREDIT' && (
  //                     <FormControl
  //                       required
  //                       label="N° de l'avoir ( Fournisseur ) créé"
  //                       ml={20}
  //                     >
  //                       <Input
  //                         isFullWidth
  //                         value={providerCreditNoteId}
  //                         onChange={(e: any) =>
  //                           setProviderCreditNoteId(e?.target?.value)
  //                         }
  //                       />
  //                     </FormControl>
  //                   )}
  //                   {gescomIdentifierType === 'COMPLEMENTARY_INVOICE' && (
  //                     <FormControl
  //                       required
  //                       label="N° de la facture complémentaire ( Fournisseur ) créé"
  //                       ml={20}
  //                     >
  //                       <Input
  //                         isFullWidth
  //                         value={providerComplementaryInvoiceId}
  //                         onChange={(e: any) =>
  //                           setProviderComplementaryInvoiceId(e?.target?.value)
  //                         }
  //                       />
  //                     </FormControl>
  //                   )}
  //                 </Flex>
  //               )}
  //             {dataStepOne?.action === ECraPatchActions?.REJECT_CRA &&
  //               !bdlProviderIsInvoice && (
  //                 <FormControl
  //                   required
  //                   label="N° de l'avoir ( Fournisseur ) créé"
  //                   ml={20}
  //                 >
  //                   <Input
  //                     value={providerCreditNoteId}
  //                     onChange={(e: any) =>
  //                       setProviderCreditNoteId(e?.target?.value)
  //                     }
  //                   />
  //                 </FormControl>
  //               )}
  //             {dataStepOne?.action === ECraPatchActions?.REJECT_CRA &&
  //               bdlProviderIsInvoice && (
  //                 <FormControl
  //                   required
  //                   label="N° de l'avoir ( Fournisseur ) créé"
  //                   ml={20}
  //                 >
  //                   <Input
  //                     value={providerCreditNoteId}
  //                     onChange={(e: any) =>
  //                       setProviderCreditNoteId(e?.target?.value)
  //                     }
  //                   />
  //                 </FormControl>
  //               )}
  //           </Flex>

  //           <Text
  //             variant="li"
  //             style={{
  //               listStyleType: 'none',
  //               marginLeft: 20,
  //               fontWeight: 'bold',
  //             }}
  //           >
  //             {/* CAS :3.2.1.1.2*/}
  //             {dataStepOne?.action === ECraPatchActions?.FIX_CRA &&
  //               !bdlCustomerIsInvoice &&
  //               `2 - Corriger le BL client ${structure} - ${bdlCustomerSage?.invoiceId} pour tenir compte des modifications souhaitées sur le nombre de jours et/ou le TJM client`}
  //             {/* CAS :3.2.1.2.2*/}
  //             {dataStepOne?.action === ECraPatchActions?.FIX_CRA &&
  //               bdlCustomerIsInvoice &&
  //               `2 - Créer un avoir ou une facture complémentaire Client pour tenir compte des modifications souhaitées sur le nombre de jours et/ou le TJM client`}
  //             {/* CAS :3.2.2.1.2*/}
  //             {dataStepOne?.action === ECraPatchActions?.REJECT_CRA &&
  //               !bdlCustomerIsInvoice &&
  //               `2 - Supprimer le BL client ${structure} - ${bdlCustomerSage?.invoiceId} en Gescom`}
  //             {/* CAS :3.2.2.2.2*/}
  //             {dataStepOne?.action === ECraPatchActions?.REJECT_CRA &&
  //               bdlCustomerIsInvoice &&
  //               `2 - Créer un avoir pour annuler la facture client ${structure} - ${bdlCustomerSage?.invoiceId} en Gescom`}
  //           </Text>
  //         </ol>
  //         <Flex width={1 / 1}>
  //           {dataStepOne?.action === ECraPatchActions?.FIX_CRA &&
  //             bdlCustomerIsInvoice && (
  //               <Box width={1 / 1}>
  //                 <Box p={20} width={1 / 1}>
  //                   <FormControl label="Qu'avez vous créé ?" required>
  //                     <Flex>
  //                       <Radio
  //                         checked={gescomCustomerIdentifierType === 'CREDIT'}
  //                         value={'CREDIT'}
  //                         onClick={() =>
  //                           setGescomCustomerIdentifierType('CREDIT')
  //                         }
  //                       >
  //                         Un avoir
  //                       </Radio>
  //                       <Radio
  //                         checked={
  //                           gescomCustomerIdentifierType ===
  //                           'COMPLEMENTARY_INVOICE'
  //                         }
  //                         value={'COMPLEMENTARY_INVOICE'}
  //                         onClick={() =>
  //                           setGescomCustomerIdentifierType(
  //                             'COMPLEMENTARY_INVOICE'
  //                           )
  //                         }
  //                       >
  //                         Une facture complémentaire
  //                       </Radio>
  //                     </Flex>
  //                   </FormControl>
  //                 </Box>

  //                 <Flex width={1 / 1}>
  //                   {gescomCustomerIdentifierType === 'CREDIT' && (
  //                     <FormControl
  //                       required
  //                       label="N° de l'avoir ( Client ) créé"
  //                       ml={20}
  //                     >
  //                       <Input
  //                         value={customerCreditNoteId}
  //                         onChange={(e: any) =>
  //                           setCustomerCreditNoteId(e?.target?.value)
  //                         }
  //                       />
  //                     </FormControl>
  //                   )}
  //                   {gescomCustomerIdentifierType ===
  //                     'COMPLEMENTARY_INVOICE' && (
  //                     <FormControl
  //                       required
  //                       label="N° de la facture complémentaire ( Client ) créé"
  //                       ml={20}
  //                     >
  //                       <Input
  //                         value={customerComplementaryInvoiceId}
  //                         onChange={(e: any) =>
  //                           setCustomerComplementaryInvoiceId(e?.target?.value)
  //                         }
  //                       />
  //                     </FormControl>
  //                   )}
  //                 </Flex>
  //               </Box>
  //             )}
  //           {dataStepOne?.action === ECraPatchActions?.REJECT_CRA &&
  //             bdlCustomerIsInvoice && (
  //               <FormControl
  //                 required
  //                 label="N° de l'avoir ( Client ) créé"
  //                 ml={20}
  //               >
  //                 <Input
  //                   value={customerCreditNoteId}
  //                   onChange={(e: any) =>
  //                     setCustomerCreditNoteId(e?.target?.value)
  //                   }
  //                 />
  //               </FormControl>
  //             )}
  //         </Flex>
  //       </Box>

  //       <Flex mt={20} alignContent={'center'} alignItems={'center'}>
  //         <ExclamationCircleIcon fill={Theme?.colors?.blue} width={25} />
  //         <Text variant="span" ml={2}>
  //           <b>
  //             Les références de factures complémetaires et des avoirs créés
  //             manuellement en Gescom sont utilisées pour ajouter un commentaire
  //             sur la mission indiquant leur création dans le cadre du rattrapage
  //             du CRA.
  //           </b>
  //         </Text>
  //       </Flex>
  //       <Flex mt={20}>
  //         <SuccessIcon fill={Theme?.colors?.error?.default} width={25} />
  //         <Text ml={2} variant="b">
  //           Une fois ces modifications effectuées, veuillez cliquer sur le
  //           bouton “Suivant”.
  //         </Text>
  //       </Flex>
  //       <Box>
  //         <Text mb={20} color={Theme?.colors?.error?.default} variant="p">
  //           {errorGescom}
  //         </Text>
  //       </Box>
  //       <Flex justifyContent={'flex-end'} width={1 / 1} mb={20} pr={50}>
  //         <Button mr={20} type="button" onClick={() => onChangeStep()}>
  //           Précédent
  //         </Button>
  //         <Button type="button" onClick={() => onFinishTutorial()}>
  //           Suivant
  //         </Button>
  //       </Flex>
  //     </Box>
  //   );
  // }
  if (
    dataStepOne?.action === ECraPatchActions?.FIX_CRA
    // dataStepOne?.action === ECraPatchActions?.FIX_CRA &&
    // (dataStep?.tutorialFinish === true || (!bdlProvider && !bdlCustomer))
  ) {
    return (
      <form onSubmit={handleSubmit(onSubmit)}>
        <ConfirmNavigationModal active={isDirty && !isSubmitSuccessful} />
        <CardCra cra={cra} displayGescom displayInvoice displayMission />
        <Text variant="h2">Étape 2 : Corriger le CRA</Text>
        <BlocInformation mb={20}>
          Nombre de jours consommés : {declaredWorkingDaysInCras} jours sur{' '}
          {estimatedDays} jours sur cette mission
        </BlocInformation>
        <Flex
          display={'inline-flex'}
          mt={20}
          width={1 / 1}
          justifyContent={'space-between'}
          flexWrap={'wrap'}
        >
          <FormControl
            minWidth={210}
            width={formControlSize}
            label="MOIS CONCERNÉ"
          >
            <Input width={210} value={displayMonth(cra?.month)} isDisabled />
          </FormControl>

          <FormControl
            width={formControlSize}
            minWidth={210}
            label="Nombre de Jours"
            required
            errorMessage={
              errors?.nbWorkingDays?.type === 'min'
                ? 'Ne peut pas être inférieur à 0'
                : errors?.nbWorkingDays?.type === 'max'
                  ? ` Le nombre de jours saisi (${nbWorkingDays}) est supérieur au nombre de
                    jour restant à facturer sur la mission (${nbRemainingDays}).`
                  : // @ts-ignore
                    errors?.nbWorkingDays?.message
            }
          >
            <NumberFormInputControlled
              width={210}
              control={control}
              maxDecimal={3}
              step="0.001"
              name="nbWorkingDays"
              rules={{
                required: 'Ce champ est requis',
                min: 0,
                max: nbRemainingDays,
              }}
            />
          </FormControl>

          <Flex
            display={'inline-flex'}
            // mt={2}
            mb={'10px'}
            width={1 / 1}
            justifyContent="flex-start"
            flexWrap={'wrap'}
          >
            <>
              {canShowAddFreeDaysButton() &&
                !showCancelFreeDaysInput &&
                !nbFreeDays && (
                  <Box mt="3px" mb="5px">
                    <Link
                      iconLeft={<AddIcon />}
                      onClick={() => {
                        setShowFreeDaysInput(true);
                        setShowCancelFreeDaysInput(true);
                      }}
                    >
                      Ajouter des jours gratuits
                    </Link>
                  </Box>
                )}
              {(showCancelFreeDaysInput || nbFreeDays > 0) && (
                <Box mt="3px" mb="5px">
                  <Link
                    iconLeft={<CloseIcon />}
                    onClick={() => {
                      setValue('nbFreeDays', 0);
                      setShowFreeDaysInput(false);
                      setShowCancelFreeDaysInput(false);
                    }}
                  >
                    Supprimer les jours gratuits
                  </Link>
                </Box>
              )}
            </>
          </Flex>
          {(nbFreeDays > 0 ||
            ((cra?.nbFreeDays || cra?.mission?.isFreeDaysEnabled) &&
              showFreeDaysInput)) && (
            <>
              <FormControl
                width={formControlSize}
                minWidth={110}
                label="Nombre de Jours Gratuits"
                errorMessage={
                  errors?.nbFreeDays?.type === 'min'
                    ? 'Ne peut pas être inférieur à 0'
                    : errors?.nbFreeDays?.type === 'max'
                      ? ` Le nombre de jours gratuits saisi (${nbFreeDays}) est supérieur au nombre de
                        jours déclarés sur le cra (${nbWorkingDays}).`
                      : // @ts-ignore
                        errors?.nbFreeDays?.message
                }
              >
                <NumberFormInputControlled
                  width={110}
                  control={control}
                  maxDecimal={3}
                  step="0.001"
                  name="nbFreeDays"
                  rules={{
                    min: 0,
                    max: nbWorkingDays,
                  }}
                />
              </FormControl>
              <Box width={4 / 12}>
                <FormControl
                  required
                  label={'Facturation de la Gratuité sur ce CRA ?'}
                  errorMessage={errors?.billFreeDaysToCustomer?.message}
                >
                  <Box mt={20}>
                    <Radio
                      {...register('billFreeDaysToCustomer')}
                      value={'true'}
                      onClick={e => setBillFreeDaysToCustomer(true)}
                      checked={billFreeDaysToCustomer}
                    >
                      Facturer {cra?.nbWorkingDays} jours au client (pas de
                      remise)
                    </Radio>
                    <Radio
                      {...register('billFreeDaysToCustomer')}
                      value={'false'}
                      onClick={e => setBillFreeDaysToCustomer(false)}
                      checked={!billFreeDaysToCustomer}
                    >
                      Facturer {(cra?.nbWorkingDays ?? 0) - (nbFreeDays ?? 0)}{' '}
                      jours au client (remise de {nbFreeDays ?? 0} jours)
                    </Radio>
                  </Box>
                </FormControl>
              </Box>
            </>
          )}

          <Flex
            display={'inline-flex'}
            mt={20}
            width={1 / 1}
            justifyContent="flex-start"
            flexWrap={'wrap'}
          >
            <Box width={8 / 12}>
              <FormControl
                width={formControlSize}
                minWidth={210}
                label="TARIF JOUR PAYÉ PAR LE CLIENT"
                required
                errorMessage={
                  errors?.clientRate?.type === 'min'
                    ? 'Ne peut pas être inférieur à 1'
                    : // @ts-ignore
                      errors?.clientRate?.message
                }
              >
                <NumberFormInputControlled
                  isDisabled={
                    cra?.mission?.billingInformation?.missionType?.label ===
                    EMissionType.CLASSIC
                  }
                  control={control}
                  maxDecimal={2}
                  step="0.01"
                  name="clientRate"
                  rules={{
                    required: 'Ce champ est requis',
                    min: 1,
                    validate: (value: any) => {
                      if (value && getValues()['contractorRate']) {
                        if (getValues()['contractorRate'] > Number(value)) {
                          return "Le tarif jour payé par l'intervenant ne peut étre supérieur à celui du client.";
                        }
                      }
                      return undefined;
                    },
                  }}
                  icon={<EuroIcon />}
                />
              </FormControl>
            </Box>
            <Box width={4 / 12}>
              <FormControl
                width={formControlSize}
                minWidth={210}
                label="TARIF JOUR PERÇU PAR L'INTERVENANT"
                required
                errorMessage={
                  errors?.contractorRate?.type === 'min'
                    ? 'Ne peut pas être inférieur à 1'
                    : // @ts-ignore
                      errors?.contractorRate?.message
                }
              >
                <NumberFormInputControlled
                  isDisabled={
                    cra?.mission?.billingInformation?.missionType?.label ===
                    EMissionType.CLASSIC
                  }
                  control={control}
                  maxDecimal={2}
                  step="0.01"
                  name="contractorRate"
                  rules={{
                    validate: (value: any) => {
                      if (value && getValues()['clientRate']) {
                        if (getValues()['clientRate'] < Number(value)) {
                          return "Le tarif jour payé par l'intervenant ne peut étre supérieur à celui du client.";
                        }
                      }
                      return undefined;
                    },
                    required: 'Ce champ est requis',
                    min: 1,
                  }}
                  icon={<EuroIcon />}
                />
              </FormControl>
            </Box>
          </Flex>
          <Flex
            display={'inline-flex'}
            mt={20}
            width={1 / 1}
            justifyContent="flex-start"
            flexWrap={'wrap'}
          >
            <Box width={formControlSize} pr={10}>
              <FormControl label="Justificatif CRA">
                <FileInput
                  maxHeight={50}
                  label=""
                  accept=".pdf"
                  subLabel=""
                  onSelect={handleChangeProofFile}
                >
                  <Link iconLeft={<DownloadIcon />}>
                    {proofFile || proof?.fileName
                      ? `Modifier le fichier ${
                          proofFile?.name
                            ? cutLongText(proofFile.name, 20)
                            : cutLongText(
                                proof && proof.fileName ? proof?.fileName : '',
                                20
                              )
                        }`
                      : 'Importer un document'}
                  </Link>
                </FileInput>
              </FormControl>
            </Box>
            {/* {(cra?.invoices?.customer?.status === EInvoiceStatus?.VALIDATED ||
              cra?.invoices?.customer?.status === EInvoiceStatus?.SENT) && (
              <Box width={3 / 12} pr={10}>
                <FormControl
                  label="Référence de l'avoir client"
                  errorMessage={
                    errors?.customerCreditNoteReference?.type === 'maxLength'
                      ? `Veuillez saisir moins de ${MAX_CUSTOMER_REFERENCE_FIELD_LENGTH} caractères`
                      : errors?.customerCreditNoteReference?.message
                  }
                >
                  <Input
                    isFullWidth
                    id="customerCreditNoteReference"
                    {...register('customerCreditNoteReference', {
                      maxLength: MAX_CUSTOMER_REFERENCE_FIELD_LENGTH,
                    })}
                  />
                </FormControl>
              </Box>
            )} */}
            {providerInvoice &&
              providerInvoice?.status === EInvoiceStatus?.VALIDATED && (
                <Box width={3 / 12}>
                  <FormControl label="Facture fournisseur correspondante">
                    <FileInput
                      maxHeight={50}
                      label=""
                      accept=".pdf"
                      subLabel=""
                      onSelect={handleChangeInvoiceFile}
                    >
                      <Link iconLeft={<DownloadIcon />}>
                        {invoiceFileForm || invoiceFile?.fileName
                          ? `Modifier le fichier ${
                              invoiceFileForm?.name
                                ? cutLongText(invoiceFileForm.name, 20)
                                : cutLongText(
                                    invoiceFile && invoiceFile.fileName
                                      ? invoiceFile?.fileName
                                      : '',
                                    20
                                  )
                            }`
                          : 'Importer un document'}
                      </Link>
                    </FileInput>
                  </FormControl>
                </Box>
              )}
          </Flex>
        </Flex>
        <Box mb={20}>
          <BlocInformation>
            <Text variant="p">
              Les modifications de TJM d’achat ou de vente ne s’appliqueront que
              sur ce CRA. Si vous souhaitez les généraliser à l’ensemble des CRA
              de la mission, il est nécessaire de reporter ces modifications sur
              les champs correspondants dans les informations générales de la
              mission.
            </Text>
          </BlocInformation>
        </Box>
        <Box>
          <Text mb={20} color={Theme?.colors?.error?.default} variant="p">
            {errorGescom}
          </Text>
        </Box>
        <Flex justifyContent={'flex-end'} width={1 / 1} mb={20} pr={50}>
          <Button mr={20} type="button" onClick={() => onChangeStep()}>
            Précédent
          </Button>
          <Button type="submit" isDisabled={isDisabled}>
            Suivant
          </Button>
        </Flex>
      </form>
    );
  }
  if (
    dataStepOne?.action === ECraPatchActions?.REJECT_CRA
    // dataStepOne?.action === ECraPatchActions?.REJECT_CRA &&
    // (dataStep?.tutorialFinish === true || (!bdlProvider && !bdlCustomer))
  ) {
    return (
      <Box width={1 / 1} mt={20}>
        <ConfirmNavigationModal active={isDirty && !isSubmitSuccessful} />
        {/* <Flex alignItems="center" alignContent="center">
          <Text
            mt={20}
            mb={20}
            minWidth={'170px'}
            color={Theme.colors.blue}
            variant="h3"
          >
            Aide à la correction
          </Text>
          <Divider mt={60} />
          {!openHelp ? (
            <ChevronDownIcon
              onClick={() => setOpenHelp(!openHelp)}
              style={{ cursor: 'pointer', marginLeft: '5px' }}
            />
          ) : (
            <ChevronUpIcon
              onClick={() => setOpenHelp(!openHelp)}
              style={{ cursor: 'pointer', marginLeft: '5px' }}
            />
          )}
        </Flex>
        {openHelp && (
          <Box>
            <Flex ml={'5px'}>
              <SuccessIcon width={25} />
              <Text variant="span" ml={2}>
                <b>
                  Dans le cas où la DPAE serait obligatoire, et afin de procéder
                  à une validation, merci de vérifier que la DPAE fournie au
                  format PDF est conforme et que les informations du document
                  sont identiques à celles saisies dans le formulaire. En cas de
                  DPAE PDF non conforme, procédez à un refus via le bouton «
                  Refuser » du formulaire
                </b>
              </Text>
            </Flex>
            <Flex mt={20}>
              <ExclamationCircleIcon
                fill={Theme?.colors?.error?.default}
                width={25}
              />
              <Text variant="span" ml={2}>
                <b>
                  En cas de DPAE PDF non conforme, procédez à un refus via le
                  bouton « Refuser » du formulaire
                </b>
              </Text>
            </Flex>
            <Divider />
          </Box>
        )} */}
        <CardCra cra={cra} displayGescom displayInvoice displayMission />
        <Text variant="h2">Étape 2 : Refuser le CRA</Text>
        <Flex alignItems="center" alignContent="center" width={1 / 1}>
          {/* {(cra?.invoices?.customer?.status === EInvoiceStatus?.VALIDATED ||
            cra?.invoices?.customer?.status === EInvoiceStatus?.SENT) && (
            <Box width={1 / 2} mb={50} mr={10}>
              <FormControl
                label="Référence de l'avoir client"
                errorMessage={errorCustomerCreditNoteReference}
              >
                <Input
                  isFullWidth
                  id="customerCreditNoteReference"
                  value={customerCreditNoteReference}
                  onChange={(e: any) => {
                    e?.taget?.value?.length >
                    MAX_CUSTOMER_REFERENCE_FIELD_LENGTH
                      ? setErrorCustomerCreditNotReference(
                          `Veuillez saisir moins de ${MAX_CUSTOMER_REFERENCE_FIELD_LENGTH} caractères`
                        )
                      : setErrorCustomerCreditNotReference(undefined);
                    setValue('customerCreditNoteReference', e?.target?.value);
                  }}
                />
              </FormControl>
            </Box>
          )} */}
          <Box width={1 / 1} mb={20}>
            <RejectCraForm
              defaultValues={{ cra }}
              onSubmit={onRejectCra}
              customTitle={' '}
              customTextButton="Suivant"
              customOptions={[
                { label: 'Autre', value: ERejectCraReason?.OTHER },
              ]}
              customDefaultOption={ERejectCraReason?.OTHER}
              formSize={6 / 12}
              alignButton={'flex-end'}
              cancelButton={
                <Button mr={20} type="button" onClick={() => onChangeStep()}>
                  Précédent
                </Button>
              }
            />
          </Box>
        </Flex>
      </Box>
    );
  }
  return <></>;
};
