import {
  MAX_PAYMENT_DEADLINE_END_OF_MONTH,
  MAX_PAYMENT_DEADLINE_FIXED,
  queryClient,
  isNotSameContacts,
  useInvoiceIbanFindMany,
} from '@commons';
import {
  EInvoicePaymentLabel,
  EInvoiceSendMethod,
  IEstablishmentBase,
  IEmailContact,
  EEmailRecipientType,
} from '@freelancelabs/teoreme-commons';
import {
  Link,
  Box,
  Button,
  CheckSwitch,
  Flex,
  FormControl,
  Input,
  Radio,
  Row,
  StaticSelectControlled,
  Text,
  TextAreaControlled,
} from 'components/ui';
import { AddEmailContactForm } from 'forms/AddEmailContactForm';
import { electronicFormat } from 'iban';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';

type FormValues = {
  establishment?: IEstablishmentBase;
};

type InvoiceContactFormProps = {
  defaultValues?: any;
  onSubmit?: any; // TODO MAKE TYPES
};

export const InvoiceContactForm = ({
  defaultValues,
  onSubmit: onSubmitParent,
}: InvoiceContactFormProps) => {
  const { data: ibans } = useInvoiceIbanFindMany({});
  const [loading, setLoading] = useState(false);
  const [additionalInvoiceContacts, setAdditionalInvoiceContacts] = useState(
    defaultValues?.establishment?.customer?.billingOptions
      ?.additionalInvoiceContacts || []
  );
  const [editAInvoiceContactEmail, setEditAInvoiceContactEmail] = useState<
    string | boolean
  >(false);
  const {
    watch,
    register,
    handleSubmit,
    setValue,
    getValues,
    control,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {
      establishment: {
        customer: {
          shouldValidateCra:
            defaultValues?.establishment?.customer?.shouldValidateCra,
          billingOptions:
            defaultValues?.establishment?.customer?.billingOptions,
          paymentDetails:
            defaultValues?.establishment?.customer?.paymentDetails,
          invoiceContact: {
            ...defaultValues?.establishment?.customer?.invoiceContact,
            civility: defaultValues?.establishment?.customer?.invoiceContact
              ?.civility
              ? String(
                  defaultValues?.establishment?.customer?.invoiceContact
                    ?.civility
                )
              : undefined,
          },
        },
      },
    },
  });

  const checkFormPaymentDeadline = (
    value: number | undefined
  ): string | undefined => {
    const payLabel =
      getValues()?.establishment?.customer?.billingOptions?.paymentLabel;
    if (!value) return undefined;
    if (payLabel === EInvoicePaymentLabel.END_OF_MONTH) {
      if (value > MAX_PAYMENT_DEADLINE_END_OF_MONTH)
        return `Le délai d'échéance ne peut pas dépasser ${MAX_PAYMENT_DEADLINE_FIXED} jours à date d'émission de la facture ou ${MAX_PAYMENT_DEADLINE_END_OF_MONTH} jours fin de mois`;
    }
    if (payLabel === EInvoicePaymentLabel.FIXED) {
      if (value > MAX_PAYMENT_DEADLINE_FIXED)
        return `Le délai d'échéance ne peut pas dépasser ${MAX_PAYMENT_DEADLINE_FIXED} jours à date d'émission de la facture ou ${MAX_PAYMENT_DEADLINE_END_OF_MONTH} jours fin de mois`;
    }
    // return;
    return undefined;
  };

  const onSubmit = async (formValues: FormValues) => {
    const me: any = queryClient.getQueryData(['me']);
    setLoading(true);
    const billingOptions = formValues?.establishment?.customer?.billingOptions;
    const invoiceContact = formValues?.establishment?.customer?.invoiceContact;
    const paymentDetails = formValues?.establishment?.customer?.paymentDetails;
    const shouldMergeProofWithInvoice =
      billingOptions?.shouldMergeProofWithInvoice;
    const defaultInvoiceContacts =
      defaultValues?.establishment?.customer?.billingOptions
        ?.additionalInvoiceContacts;
    const upsertInvoiceContacts = isNotSameContacts(
      defaultInvoiceContacts,
      additionalInvoiceContacts
    );
    const removeInvoiceContacts: string[] = [];
    defaultInvoiceContacts?.forEach((dic: IEmailContact) => {
      const isFind = additionalInvoiceContacts?.findIndex(
        (aic: IEmailContact) => aic?.email === dic?.email
      );
      if (isFind === -1) {
        removeInvoiceContacts?.push(dic?.email);
      }
    });
    const values: FormValues = {
      upsertInvoiceContacts:
        upsertInvoiceContacts?.length > 0 ? upsertInvoiceContacts : undefined,
      removeInvoiceContacts:
        removeInvoiceContacts?.length > 0 ? removeInvoiceContacts : undefined,
      establishment: {
        customer: {
          shouldValidateCra: formValues?.establishment?.customer
            ?.shouldValidateCra as boolean,
          accountManager: me?.cognitoUserId,
          invoiceContact: {
            ...invoiceContact,
            //@ts-ignore
            civility: invoiceContact?.civility
              ? String(invoiceContact?.civility)
              : undefined,
          },
          billingOptions: {
            ...billingOptions,
            sendMethod: billingOptions?.sendMethod
              ? billingOptions?.sendMethod
              : undefined,
            additionalInvoiceContacts: undefined,
            shouldMergeProofWithInvoice: shouldJoinProofToInvoice
              ? shouldMergeProofWithInvoice
              : false,
          },
          paymentDetails: paymentDetails ? paymentDetails : undefined,
        },
      },
    };
    await onSubmitParent?.(values);
    setLoading(false);
  };
  const isAutoSendEmail = watch(
    'establishment.customer.billingOptions.isAutoSendEmail'
  );
  const isAutoBilling = watch(
    'establishment.customer.billingOptions.isAutoBilling'
  );
  const shouldJoinProofToInvoice = watch(
    'establishment.customer.billingOptions.shouldJoinProofToInvoice'
  );
  const shouldMergeProofWithInvoice = watch(
    'establishment.customer.billingOptions.shouldMergeProofWithInvoice'
  );
  const isDirectPayment = watch(
    'establishment.customer.billingOptions.isDirectPayment'
  );
  React.useEffect(() => {
    if (!isAutoBilling && isAutoSendEmail) {
      setValue('establishment.customer.billingOptions.isAutoSendEmail', false);
    }
  }, [isAutoBilling, isAutoSendEmail]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const IBAN_SELECT = ibans?.ibans?.map(iban => {
    return {
      label: `NOM : ${iban?.displayName} | NOM DE LA BANQUE : ${iban?.bankName} | IBAN: ${electronicFormat(
        iban?.iban
      )}`,
      value: iban?.uuid,
      key: iban?.uuid,
    };
  });

  const onUpdateEmailContacts = (
    contact: {
      email: string;
      recipientType?: EEmailRecipientType;
    },
    oldContact?: {
      email: string;
      recipientType?: EEmailRecipientType;
    }
  ) => {
    const contacts = [...additionalInvoiceContacts];
    const indexUpdatedContact = contacts?.findIndex(
      (oldC: any) =>
        oldC?.email === oldContact?.email || oldC?.email === contact?.email
    );
    if (indexUpdatedContact !== -1) {
      const updateC = contacts[indexUpdatedContact];
      contacts[indexUpdatedContact] = { ...updateC, ...contact };
      setAdditionalInvoiceContacts(contacts);
    } else {
      contacts?.push(contact);
      setAdditionalInvoiceContacts(contacts);
    }
    setEditAInvoiceContactEmail(false);
  };
  const onDeleteEmailContacts = (email: string) => {
    let contacts = [...additionalInvoiceContacts];
    contacts = contacts?.filter((c: any) => c?.email !== email);
    setAdditionalInvoiceContacts(contacts);
  };
  const sendMethod = watch('establishment.customer.billingOptions.sendMethod');

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Text variant="h2" mb={20}>
        Modifier le contact de facturation
      </Text>

      <FormControl
        label={'Email'}
        errorMessage={
          // @ts-ignore
          errors?.establishment?.customer?.invoiceContact?.email?.message
        }
        required
      >
        <Input
          isFullWidth
          {...register('establishment.customer.invoiceContact.email', {
            required: 'Ce champ est requis',
          })}
          type="email"
        />
      </FormControl>
      <Box mt={20}>
        <FormControl
          label={'Civilité'}
          errorMessage={
            errors?.establishment?.customer?.invoiceContact?.civility?.message
          }
        >
          <>
            <Radio
              {...register('establishment.customer.invoiceContact.civility')}
              value="1"
            >
              Monsieur
            </Radio>
            <Radio
              {...register('establishment.customer.invoiceContact.civility')}
              value="2"
            >
              Madame
            </Radio>
          </>
        </FormControl>
      </Box>
      <Row spacing={20}>
        <FormControl
          label={'Nom'}
          errorMessage={
            // @ts-ignore
            errors?.establishment?.customer?.invoiceContact?.firstName?.message
          }
        >
          <Input
            isFullWidth
            {...register('establishment.customer.invoiceContact.firstName')}
          />
        </FormControl>
        <FormControl
          label={'Prénom'}
          errorMessage={
            errors?.establishment?.customer?.invoiceContact?.lastName?.message
          }
        >
          <Input
            isFullWidth
            {...register('establishment.customer.invoiceContact.lastName')}
          />
        </FormControl>
      </Row>
      <Box mb={20} mt={20}>
        <Text mb={20} variant="h3">
          Ajouter des destinataires en cc ou cci
        </Text>
        {additionalInvoiceContacts &&
          additionalInvoiceContacts?.map((contact: any) => (
            <AddEmailContactForm
              key={contact?.email}
              onSubmit={newContact =>
                onUpdateEmailContacts(newContact, contact)
              }
              contacts={additionalInvoiceContacts}
              contact={contact}
              readOnly={editAInvoiceContactEmail !== contact.email}
              onEdit={() => setEditAInvoiceContactEmail(contact?.email)}
              onDelete={onDeleteEmailContacts}
            />
          ))}
        {!editAInvoiceContactEmail ? (
          <Link onClick={() => setEditAInvoiceContactEmail(true)}>
            Ajouter un destinataire
          </Link>
        ) : editAInvoiceContactEmail === true ? (
          <AddEmailContactForm
            key={'NEW'}
            contacts={additionalInvoiceContacts}
            readOnly={false}
            onSubmit={onUpdateEmailContacts}
            onEdit={() => setEditAInvoiceContactEmail(false)}
          />
        ) : (
          <></>
        )}
      </Box>
      <Box>
        <FormControl
          required
          label={'Mode de distribution'}
          errorMessage={
            errors?.establishment?.customer?.billingOptions?.sendMethod?.message
          }
        >
          <>
            <Radio
              {...register('establishment.customer.billingOptions.sendMethod', {
                required: isAutoBilling ? 'Ce champ est requis' : undefined,
              })}
              value={EInvoiceSendMethod?.EMAIL}
            >
              Email
            </Radio>
            <Radio
              {...register('establishment.customer.billingOptions.sendMethod', {
                required: isAutoBilling ? 'Ce champ est requis' : undefined,
              })}
              value={EInvoiceSendMethod?.OTHER}
            >
              Autre ( plateforme , fax etc .... )
            </Radio>
          </>
        </FormControl>
      </Box>
      <FormControl
        label={'Paiement direct'}
        errorMessage={
          errors?.establishment?.customer?.billingOptions?.isDirectPayment
            ?.message
        }
        required
      >
        <CheckSwitch
          mt={20}
          id="isDirectPayment"
          {...register('establishment.customer.billingOptions.isDirectPayment')}
          onChange={e => {
            setValue(
              'establishment.customer.billingOptions.isDirectPayment',
              e.target.checked
            );
            return e.target.checked;
          }}
        >
          <Text variant="p">
            {isDirectPayment
              ? 'Paiement direct Actif'
              : 'Paiement direct Inactif'}
          </Text>
        </CheckSwitch>
      </FormControl>
      <FormControl
        label={'Facturer automatiquement le client'}
        errorMessage={
          errors?.establishment?.customer?.billingOptions?.isAutoBilling
            ?.message
        }
        required
      >
        <CheckSwitch
          id="isBilling"
          {...register('establishment.customer.billingOptions.isAutoBilling')}
          onChange={e => {
            setValue(
              'establishment.customer.billingOptions.isAutoBilling',
              e.target.checked
            );
            return e.target.checked;
          }}
        >
          <Text variant="p">
            {isAutoBilling
              ? 'Facturer automatiquement le client'
              : 'Ne pas facturer automatiquement le client'}
          </Text>
        </CheckSwitch>
      </FormControl>
      {sendMethod === EInvoiceSendMethod.EMAIL && (
        <FormControl
          label={'Envoyer automatiquement les factures client à cette adresse'}
          errorMessage={
            errors?.establishment?.customer?.billingOptions?.isAutoSendEmail
              ?.message
          }
          required
        >
          <CheckSwitch
            isDisabled={!isAutoBilling}
            id="isAutoSendEmail"
            {...register(
              'establishment.customer.billingOptions.isAutoSendEmail'
            )}
            onChange={e => {
              setValue(
                'establishment.customer.billingOptions.isAutoSendEmail',
                // @ts-ignore
                e.target.checked
              );
              return e.target.checked;
            }}
          >
            <Text variant="p">
              {isAutoSendEmail
                ? 'Envoyer automatiquement'
                : 'Ne pas envoyer automatiquement'}
            </Text>
          </CheckSwitch>
        </FormControl>
      )}
      {isAutoBilling && sendMethod === EInvoiceSendMethod.EMAIL && (
        <>
          <FormControl
            label={'Joindre les justificatifs à la facture client'}
            errorMessage={
              errors?.establishment?.customer?.billingOptions
                ?.shouldJoinProofToInvoice?.message
            }
            required
          >
            <CheckSwitch
              id="shouldJoinProofToInvoice"
              {...register(
                'establishment.customer.billingOptions.shouldJoinProofToInvoice'
              )}
              onChange={e => {
                setValue(
                  'establishment.customer.billingOptions.shouldJoinProofToInvoice',
                  e.target.checked
                );
                return e.target.checked;
              }}
            >
              <Text variant="p">
                {shouldJoinProofToInvoice
                  ? 'Joindre les justificatifs à la facture client'
                  : 'Ne pas Joindre les justificatifs à la facture client'}
              </Text>
            </CheckSwitch>
          </FormControl>
          {shouldJoinProofToInvoice && (
            <FormControl
              required
              label={'Fusionner les justificatifs avec la facture'}
              errorMessage={
                errors?.establishment?.customer?.billingOptions
                  ?.shouldMergeProofWithInvoice?.message
              }
            >
              <CheckSwitch
                id="shouldMergeProofWithInvoice"
                {...register(
                  'establishment.customer.billingOptions.shouldMergeProofWithInvoice'
                )}
              >
                <Text variant="p">
                  {shouldMergeProofWithInvoice
                    ? 'Fusionner les justificatifs avec la facture'
                    : ' Séparer les justificatifs et la facture'}
                </Text>
              </CheckSwitch>
            </FormControl>
          )}
        </>
      )}
      <Row spacing={20}>
        <FormControl
          label="Délai de paiement"
          required
          errorMessage={
            errors?.establishment?.customer?.paymentDetails?.paymentDeadline
              ?.type === 'min'
              ? 'Veuillez renseigner une valeur positive'
              : errors?.establishment?.customer?.paymentDetails?.paymentDeadline
                  ?.message
          }
        >
          <Input
            isFullWidth
            type="number"
            {...register(
              'establishment.customer.paymentDetails.paymentDeadline',
              {
                required: 'Ce champs est requis ',
                min: 0,
                validate: value => checkFormPaymentDeadline(value),
              }
            )}
          />
        </FormControl>
        <FormControl
          required
          label="Modalité de paiement"
          errorMessage={
            errors?.establishment?.customer?.billingOptions?.paymentLabel
              ?.message
          }
        >
          <StaticSelectControlled
            isClearable={false}
            control={control}
            name="establishment.customer.billingOptions.paymentLabel"
            options={[
              {
                label: `Fin de mois`,
                value: EInvoicePaymentLabel?.END_OF_MONTH,
              },
              {
                label: `Date d'émission la facture`,
                value: EInvoicePaymentLabel?.FIXED,
              },
            ]}
            rules={{ required: 'Ce champ est requis' }}
            placeholder=""
          />
        </FormControl>
      </Row>
      {/* <FormControl
        required
        label="Coordonnées bancaire Freelance.com"
        errorMessage={
          errors?.establishment?.customer?.billingOptions?.iban?.message
        }
      >
        <StaticSelectControlled
          control={control}
          name="establishment.customer.billingOptions.iban"
          options={IBAN_SELECT}
          rules={{ required: 'Ce champ est requis' }}
          placeholder=""
        />
      </FormControl> */}

      <FormControl label={'Commentaire'}>
        <TextAreaControlled
          control={control}
          minRows={3}
          isFullWidth
          name="establishment.customer.invoiceContact.comment"
        />
      </FormControl>

      <Flex>
        <Button type="submit" isLoading={loading}>
          Enregistrer
        </Button>
      </Flex>
    </form>
  );
};
