import * as React from 'react';
import { Text, Box } from 'components/ui';
function SvgFastCashIcon(
  props: React.SVGProps<SVGSVGElement> & { deaddline: number | string }
) {
  return (
    <Box>
      <Box
        textAlign={'center'}
        minWidth={20}
        position={'relative'}
        top={0}
        right={-10}
        borderRadius={'100%'}
        color="white"
        backgroundColor={props.fill}
      >
        <Text variant="p" fontSize={'10px'} color="white">
          {props?.deaddline}
        </Text>
      </Box>
      <svg
        width="17"
        height="25"
        viewBox="0 0 17 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9 9.5H17L7 24.5V15.5H0L9 0.5V9.5ZM7 11.5V7.72L3.532 13.5H9V17.894L13.263 11.5H7Z"
          fill={props.fill}
        />
      </svg>
    </Box>
  );
}

export default SvgFastCashIcon;
