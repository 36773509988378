import * as React from 'react';
import { EWSPayloadType, ETaskStatus } from '@freelancelabs/teoreme-commons';
import { PrivateRoute } from 'components/PrivateRoute';
import { Route, Switch, Redirect, useLocation } from 'react-router-dom';
import { Clients } from './Clients/Clients';
import { Delivery } from './Delivery/Delivery';
import { Notifications } from './Notifications/Notifications';
import { Traces } from './Traces';
import { Home } from './Home';
import Login from './Login';
import { LoginPage as LoginAzure } from './LoginAzure';
import GetAccess from './GetAccess';
import { PageNotFound } from './NotFound';
import { ResetPasswordPage } from './ResetPassword';
import { Providers } from './Providers/Providers';
import { Orders } from './Orders/Orders';
import { Others } from './Others';
import { SupportRoute } from './Support/SupportRoute';
import { AdminRoute } from './Admin/AdminRoute';
//@ts-ignore
import Websocket from 'react-websocket';
import { useNotificationsStore } from 'store/notifications';
import {
  WEBSOCKET_URL,
  onDownloadFile,
  queryClient,
  checkAuthValid,
} from '@commons';
import { UserRoute } from './User';
import { LegalsRoute } from './Legals/LegalsRoute';
import { FileReaderRoute } from './FileReader';

export const Router: React.FunctionComponent<
  React.PropsWithChildren<{}>
> = () => {
  const { updateNotifications } = useNotificationsStore();
  const isAuth = checkAuthValid();
  const location = useLocation();
  let useWebSocket = false;
  if (location.pathname !== '/login' && localStorage.token) {
    useWebSocket = true;
  }

  const setNewNotification = React.useCallback(async (): Promise<void> => {
    updateNotifications({
      newMessage: true,
    });
  }, [updateNotifications]);
  return (
    <>
      <Switch>
        <Route
          exact
          path="/login"
          render={props =>
            isAuth ? <Redirect to="/dashboard" /> : <LoginAzure />
          }
        />
        <Route
          exact
          path="/old-login"
          render={props => (isAuth ? <Redirect to="/dashboard" /> : <Login />)}
        />

        <Route path="/reset-password/:token">
          <ResetPasswordPage />
        </Route>
        <Route path="/get-access">
          <GetAccess />
        </Route>
        <Route exact path="/404" component={PageNotFound} />
        <PrivateRoute path="/admin" component={AdminRoute} />
        <PrivateRoute path="/support" component={SupportRoute} />
        <PrivateRoute path="/legals" component={LegalsRoute} />
        <PrivateRoute path="/user" component={UserRoute} />
        <PrivateRoute path="/dashboard" component={Home} />
        <PrivateRoute path="/clients" component={Clients} />
        <PrivateRoute path="/providers" component={Providers} />
        <PrivateRoute path="/delivery" component={Delivery} />
        <PrivateRoute path="/orders" component={Orders} />
        <PrivateRoute path="/others" component={Others} />
        <PrivateRoute path="/notifications" component={Notifications} />
        <PrivateRoute path="/traces" component={Traces} />
        <PrivateRoute
          exact
          path="/file/:fileLocation"
          component={FileReaderRoute}
        />
        <PrivateRoute exact path="/" component={Home} />
        <Redirect from="*" to="/404" />
      </Switch>

      {useWebSocket && (
        <Websocket
          onOpen={(a: any) => {
            // console.log('OnOpen WS ', a);
          }}
          onClose={() => {
            // console.log('onClose WS ');
          }}
          url={`${WEBSOCKET_URL}?token=${localStorage.token}`}
          onMessage={async (data: any) => {
            const wsParse = JSON.parse(data);
            if (wsParse?.type === EWSPayloadType.NOTIFICATION) {
              setNewNotification();
              queryClient.refetchQueries({
                queryKey: ['notifications'],
                type: 'active',
              });
            }
            if (wsParse?.type === EWSPayloadType.TASK) {
              if (wsParse?.data?.status === ETaskStatus.COMPLETED) {
                if (wsParse?.data?.output?.file) {
                  if (document.hasFocus()) {
                    await onDownloadFile(wsParse?.data?.output?.file);
                  }
                }
              }
            }
          }}
          debug={true}
        />
      )}
    </>
  );
};
Router.displayName = 'Router';
