import { create } from 'zustand';
export type stepType = {
  index: number;
  title: string;
  subTitle: string;
  validated: boolean;
  data: any;
};
type State = {
  initialSate: boolean;
  step: number;
  steps: Array<stepType>;
  updatePatchValidatedCraStore: (state: Partial<State>) => void;
  resetPatchValidatedCraStore: (state: Partial<State>) => void;
};
const initialSteps = [
  {
    index: 0,
    title: 'Étape 1',
    subTitle: "Type d'action",
    validated: false,
    data: {},
  },
  {
    index: 1,
    title: 'Étape 2',
    subTitle: 'Corriger / Refuser le CRA',
    validated: false,
    data: {},
  },
  {
    index: 2,
    title: 'Étape 3',
    subTitle: 'Résumé & Confirmation',
    validated: false,
    data: {},
  },
];
const initialStates = {
  initialSate: true,
  step: 0,
  steps: Array.from(initialSteps),
};

const store = (set: any) => ({
  ...initialStates,
  updatePatchValidatedCraStore: (state: Partial<State>) =>
    set({ initialSate: false, ...state }),
  resetPatchValidatedCraStore: (state: Partial<State>) =>
    set({ ...initialStates, ...state }),
});

export const usePatchValidatedCraStore = create<State>(store);
