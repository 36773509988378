import { create } from 'zustand';
type State = {
  initialSate: boolean;
  tasksInProgress: string[] | undefined;
  updateGlobalStore: (state: Partial<State>) => void;
  resetGlobalStore: (state: Partial<State>) => void;
};
const initialStates = {
  initialSate: true,
  tasksInProgress: [],
};

const store = (set: any) => ({
  ...initialStates,
  updateGlobalStore: (state: Partial<State>) =>
    set({ initialSate: false, ...state }),
  resetGlobalStore: (state: Partial<State>) =>
    set({
      ...initialStates,
      ...state,
    }),
});

export const useGlobalStore = create<State>(store);
