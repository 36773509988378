import {
  EContractState,
  IEstablishmentMinifiedUsers,
} from '@freelancelabs/teoreme-commons';
import { getEstablishmentName, useEstablishmentFindMany } from '@commons';
import { ALink } from 'components/ALink';
import { Header } from 'components/Header';
import { showAddEstablishmentModal } from 'components/modals/AddEstablishmentModal';
import {
  Box,
  Button,
  Container,
  Flex,
  FormLabel,
  Input,
  Itemize,
  Link,
  SpinnerBox,
  Status,
  Tab,
  Table,
  TableColumn,
  Text,
} from 'components/ui';
import { EyeIcon, RefreshIcon } from 'components/ui/icons';
import { FR_STATUS } from '@commons';
import { formatDate } from '@commons';
import { useDebounce } from 'hooks/useDebounce';
import * as React from 'react';
import { useSetCrumbs } from 'hooks/breadCrumb';
import { useParams } from 'react-router-dom';
import { transformText } from '@commons';
import { StaticSelect } from 'components/selects/StaticSelect';
import { useProviderEstablishmentListStore } from 'store';
import { Theme } from 'styles';
type Item = Itemize<IEstablishmentMinifiedUsers>;

export type EstablishmentsListProps = {};
const tabItems = (
  <>
    <Tab variant="secondary" href={'/providers/establishments/active'}>
      Établissements actifs
    </Tab>
    <Tab variant="secondary" href={'/providers/establishments/locked'}>
      Établissements désactivés
    </Tab>
    {/*
    <Tab variant="secondary" href={'/providers/establishments/close'}>
      Établissements fermés
    </Tab>
    */}
  </>
);

const getContractType = (establishment: IEstablishmentMinifiedUsers) => {
  if (establishment?.provider?.contracts) {
    let lastContract = establishment.provider.contracts.sort(
      (a: any, b: any) => b.updatedAt - a.updatedAt
    )[0];

    for (let i = 0; i < establishment.provider.contracts.length; i++) {
      if (establishment.provider.contracts[i].state === EContractState.DONE) {
        lastContract = establishment.provider.contracts[i];
      }
    }

    if (lastContract?.state) {
      return lastContract?.state;
    } else {
      return 'Pas de contrat';
    }
  }

  return 'Pas de contrat';
};
const columns: TableColumn<Item>[] = [
  {
    key: 'name',
    text: 'RAISON SOCIALE',
    render: item =>
      transformText(getEstablishmentName(item, 80), 'capitalize') || '',
  },
  {
    key: 'country',
    text: 'PAYS',
    render: item => item.address?.country || '',
  },
  {
    key: 'identifier',
    text: 'IDENTIFICATION',
    render: item => item.siret || item.siren || item.identifier || '',
  },
  {
    key: 'activeSince',
    text: 'ACTIF DEPUIS LE',
    render: item => item.activeSince && formatDate(item.activeSince),
  },
  {
    key: 'accountManager',
    text: 'RESPONSABLE DE COMPTE',
    render: item => {
      if (item.provider.manager) {
        if (item.provider.manager.firstName && item.provider.manager.lastName) {
          return `${item.provider.manager.firstName} ${item.provider.manager.lastName} `;
        } else {
          return `${item.provider.manager.email} `;
        }
      }
    },
  },
  {
    key: 'contractType',
    text: 'CONTRAT CADRE',
    render: item => {
      let color: 'primary' | 'warning' | 'success' | 'error' | 'grey' =
        'primary';
      const contractType = getContractType(item);
      switch (contractType) {
        case EContractState.SIGNED_BY_PROVIDER:
        case EContractState.TO_SIGN_FCOM:
        case EContractState.TO_SIGN_PROVIDER:
          color = 'warning';
          break;
        case EContractState.DONE:
        case EContractState.SIGNED_BY_FCOM:
          color = 'success';
          break;

        case EContractState.ARCHIVED:
          color = 'grey';
          break;

        case EContractState.REFUSED_BY_FCOM:
        case EContractState.REFUSED_BY_PROVIDER:
        case 'Pas de contrat':
          color = 'error';
          break;

        default:
          break;
      }
      return (
        <Status variantColor={color}>
          {contractType === 'Pas de contrat'
            ? 'Pas de contrat'
            : FR_STATUS[contractType]}
        </Status>
      );
    },
  },
  {
    key: 'actions',
    text: 'ACTIONS',
    align: 'flex-end',
    render: item => (
      <Link iconLeft={<EyeIcon />}>
        <ALink href={`/providers/establishments/${item.uuid}/information`}>
          Voir la fiche
        </ALink>
      </Link>
    ),
  },
];

export const EstablishmentsList: React.FunctionComponent<
  React.PropsWithChildren<EstablishmentsListProps>
> = () => {
  const { filter: filterParams } = useParams<{
    filter: 'active' | 'locked' | 'closed';
  }>();
  const estStatus = {
    active: 'actifs',
    locked: 'désactivés',
    closed: 'fermés',
  };
  useSetCrumbs(
    [],
    [
      {
        label: 'fournisseurs',
        path: '/providers',
      },
      {
        label: 'établissements',
        path: '/providers/establishments',
      },
      {
        label: estStatus[filterParams],
        path: '/providers/establishments/' + filterParams,
      },
    ]
  );
  const {
    initialSate,
    order,
    sortedBy,
    searchQuery,
    statusSelect,
    page,
    updateProviderEstablishmentListStore,
    resetProviderEstablishmentListStore,
  } = useProviderEstablishmentListStore();

  const debouncedFilterQuery = useDebounce(searchQuery, 500);
  const sortString = (order === 'desc' ? '-' : '') + sortedBy;
  const onSortedChange = React.useCallback(
    async (sortedBy: string, order: 'asc' | 'desc'): Promise<void> => {
      //@ts-ignore
      updateProviderEstablishmentListStore({ sortedBy, order });
    },
    []
  );
  const getContractStatus = (status: string) => {
    if (status) {
      if (status === 'WAIT') {
        return {
          $in: [
            EContractState.TO_SIGN_FCOM,
            EContractState.TO_SIGN_PROVIDER,
            EContractState.SIGNED_BY_FCOM,
            EContractState.SIGNED_BY_PROVIDER,
          ],
        };
      }
      if (status === 'DONE') {
        return { $in: [EContractState?.DONE] };
      }
      if (status === 'NONE') {
        return undefined;
      }
    }
    return undefined;
  };
  const {
    data: establishmentsQuery,
    isFetching,
    status,
  } = useEstablishmentFindMany({
    filterObject: {
      provider: {
        $exists: true,
      },
      $or: [
        { businessName: { $regex: debouncedFilterQuery, $options: 'i' } },
        { tradeName: { $regex: debouncedFilterQuery, $options: 'i' } },
        { signBoard1: { $regex: debouncedFilterQuery, $options: 'i' } },
        { signBoard2: { $regex: debouncedFilterQuery, $options: 'i' } },
        { signBoard3: { $regex: debouncedFilterQuery, $options: 'i' } },
        { identifier: { $regex: debouncedFilterQuery, $options: 'i' } },
        { siret: { $regex: debouncedFilterQuery, $options: 'i' } },
        { siren: { $regex: debouncedFilterQuery, $options: 'i' } },
      ],
      'provider.contracts.state': getContractStatus(statusSelect as string),
      'provider.contracts.0':
        statusSelect === 'NONE' ? { $exists: false } : undefined,
      'provider.locked': filterParams === 'locked' ? true : false,
    },
    limit: 20,
    skip: page * 20,
    sort: sortString,
  });
  const loading = status === 'pending';
  const totalCount = establishmentsQuery?.totalCount;
  const items = establishmentsQuery?.establishments?.map(e => ({
    key: e.uuid,
    ...e,
  }));
  const onAddEstablishment = () => {
    showAddEstablishmentModal({ establishmentRole: 'PROVIDER' });
    //showCreateContactContractorModal();
  };

  return (
    <Box>
      <Header
        tabs={tabItems}
        actions={
          <Button onClick={onAddEstablishment}>Ajouter un établissement</Button>
        }
      >
        <Text variant="h1">Établissements fournisseurs</Text>
      </Header>
      <Container>
        <Flex mt="10px" mb="10px">
          <Box width={3 / 12}>
            <FormLabel>Rechercher</FormLabel>
            <Input
              data-cy="search-estaProvider-list"
              isFullWidth
              type="search"
              value={searchQuery}
              placeholder="Rechercher un nom, email, établissement..."
              onChange={(e: any) => {
                updateProviderEstablishmentListStore({
                  searchQuery: e?.target?.value,
                });
              }}
            />
          </Box>
          <Box width={3 / 12} ml={10}>
            <FormLabel>Statut du contrat-cadre</FormLabel>
            <StaticSelect
              placeholder=""
              options={[
                { value: 'NONE', label: 'Pas de contrat' },
                { value: 'WAIT', label: 'En cours de signature' },
                { value: 'DONE', label: 'Signé' },
              ]}
              onChange={value => {
                updateProviderEstablishmentListStore({
                  statusSelect: value as string,
                });
              }}
              value={statusSelect}
            />
          </Box>
          <Box ml={10} mt={10}>
            <Flex
              justifyContent={'center'}
              alignItems={'center'}
              flexWrap="wrap"
              alignContent="center"
              width={1 / 1}
              height={'100%'}
            >
              <Link
                isDisabled={initialSate}
                iconLeft={
                  <RefreshIcon
                    fill={
                      initialSate ? Theme?.colors?.grey?.default : undefined
                    }
                  />
                }
                mt={'5px'}
                onClick={() =>
                  !initialSate ? resetProviderEstablishmentListStore({}) : false
                }
              >
                Réinitialiser
              </Link>
            </Flex>
          </Box>
        </Flex>
        {loading && <SpinnerBox />}
        {!loading && (
          <Table<Item>
            isLoading={isFetching}
            columns={columns}
            items={items}
            sortedBy={sortedBy}
            order={order}
            page={page}
            total={totalCount}
            perPage={totalCount && totalCount < 20 ? totalCount : 20}
            onSortedChange={onSortedChange}
            onChangePage={page =>
              updateProviderEstablishmentListStore({ page })
            }
          />
        )}
      </Container>
    </Box>
  );
};
