import {
  useEstablishmentUpdateOne,
  calculateContractorRateFromMarginAndClientRate,
  calculateClientRateFromMarginAndContractorRate,
  getMarginOrMarkup,
  isNotEmpty,
  round,
} from '@commons';
import { EMarginType } from '@freelancelabs/teoreme-commons';
import {
  Link,
  Flex,
  Box,
  Button,
  FormControl,
  Input,
  Radio,
  Row,
  Text,
  CheckSwitch,
  TextAreaControlled,
  BlocInformation,
} from 'components/ui';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { EuroIcon, PercentIcon, RefreshIcon } from 'components/ui/icons';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { useShowMessage } from 'hooks/useShowMessage';
import { useDebounce } from 'hooks/useDebounce';

type FormValues = {
  marginType: EMarginType;
  isEnabled: boolean;
  margin: number;
  shouldJoinAttachment: boolean;
  saleBudget?: number | undefined;
  purchaseBudget?: number | undefined;
  consumedSaleBudget?: number | undefined;
  consumedPurchaseBudget?: number | undefined;
};

type ConfigureExpensesFormProps = {
  expensesConfig?: {
    marginType?: EMarginType;
    isEnabled?: boolean;
    margin?: number;
    shouldJoinAttachment?: boolean;
    saleBudget?: number | undefined;
    purchaseBudget?: number | undefined;
    consumedSaleBudget?: number | undefined;
    consumedPurchaseBudget?: number | undefined;
  };
  from: 'ESTABLISHMENT' | 'MISSION';
  onSubmit: (formValues: FormValues) => void;
  onClose?: () => void;
};

export const ConfigureExpensesForm = ({
  expensesConfig,
  from,
  onSubmit: onSubmitParent,
  onClose,
}: ConfigureExpensesFormProps) => {
  const [loading, setLoading] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { mutateAsync: updateEstablishment } = useEstablishmentUpdateOne();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [fieldBlur, setFieldBlur] = useState<
    'marginType' | 'margin' | 'saleBudget' | 'purchaseBudget' | undefined
  >();
  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
    setValue,
  } = useForm<FormValues>({
    defaultValues: {
      ...expensesConfig,
    },
  });
  const debounceTime = 0;
  const marginType = useDebounce(watch('marginType'), debounceTime);
  const purchaseBudget = useDebounce(watch('purchaseBudget'), debounceTime);
  const consumedPurchaseBudget = expensesConfig?.consumedPurchaseBudget;
  const saleBudget = useDebounce(watch('saleBudget'), debounceTime);
  const margin = useDebounce(watch('margin'), debounceTime);
  const [roundedValues, setRoundedValues] = useState({
    margin: expensesConfig?.margin,
    saleBudget: expensesConfig?.saleBudget,
    purchaseBudget: expensesConfig?.purchaseBudget,
  });
  const consumedSaleBudget = expensesConfig?.consumedSaleBudget;
  const isOutOfRangePurchageBudget =
    from === 'MISSION' &&
    purchaseBudget &&
    Number(purchaseBudget) > 0 &&
    Number(purchaseBudget) <= (consumedPurchaseBudget || 0);
  const isOutOfRangeSaleBudget =
    from === 'MISSION' &&
    saleBudget &&
    Number(saleBudget) > 0 &&
    Number(saleBudget) <= (consumedSaleBudget || 0);
  const marginTypeLabel =
    marginType === EMarginType.MARGIN ? 'Marque' : 'Marge';

  const onSubmit = async (formValues: FormValues) => {
    setLoading(true);
    try {
      if (onSubmitParent) {
        await onSubmitParent(formValues);
      }
    } catch (e) {
      setLoading(false);
    }
    setLoading(false);
  };
  const calculateBudgetAndMargin = (
    field: 'marginType' | 'saleBudget' | 'purchaseBudget' | 'margin',
    value?: number | string
  ) => {
    const results: any = {
      marginType: marginType,
      margin: undefined,
      marginRounded: undefined,
      saleBudget: undefined,
      saleBudgetRounded: undefined,
      purchaseBudget: undefined,
      purchaseBudgetRounded: undefined,
    };

    if (from === 'MISSION') {
      /*Si modification du “taux de marque” →
      recalcule automatique du ““tarif jour/montant payé par le client”
      (pas de modification du tarif jour/montant perçu par l’intervenant)
      */
      if (field === 'margin' && isNotEmpty(value)) {
        results.margin = Number(value);
        if (isNotEmpty(purchaseBudget)) {
          results.purchaseBudget = Number(purchaseBudget);
          results.saleBudget = calculateClientRateFromMarginAndContractorRate(
            Number(purchaseBudget),
            Number(value), //margin
            marginType
            //true //round
          );
        } else if (isNotEmpty(saleBudget)) {
          results.saleBudget = Number(saleBudget);
          results.purchaseBudget =
            calculateContractorRateFromMarginAndClientRate(
              Number(value), // saleBudget
              Number(margin),
              marginType
              //true //round
            );
        }
      }
      /*Si modification du “taux de marque” →
      recalcule automatique du ““tarif jour/montant payé par le client”
      (pas de modification du tarif jour/montant perçu par l’intervenant)
      */
      if (field === 'marginType' && isNotEmpty(value)) {
        if (isNotEmpty(margin)) {
          results.margin = Number(margin);
          if (isNotEmpty(purchaseBudget)) {
            results.purchaseBudget = Number(purchaseBudget);
            results.saleBudget = calculateClientRateFromMarginAndContractorRate(
              Number(purchaseBudget),
              Number(margin), //margin
              value as EMarginType
              //true //round
            );
          } else if (isNotEmpty(saleBudget)) {
            results.saleBudget = Number(saleBudget);
            results.purchaseBudget =
              calculateContractorRateFromMarginAndClientRate(
                Number(saleBudget), // saleBudget
                Number(margin),
                value as EMarginType
                //true //round
              );
          }
        } else {
          if (isNotEmpty(saleBudget) && isNotEmpty(purchaseBudget)) {
            results.saleBudget = Number(saleBudget);
            results.purchaseBudget = Number(purchaseBudget);
            results.margin = getMarginOrMarkup(
              Number(saleBudget),
              Number(purchaseBudget),
              value as EMarginType
            );
          }
        }
      }
      /*Si modification du “tarif jour/montant perçu par l’intervenant”  →
      recalcule automatique du “tarif jour/montant payé par le client”
      (pas de modification du taux de marque)*/
      if (field === 'purchaseBudget' && isNotEmpty(value)) {
        results.purchaseBudget = Number(value);
        if (isNotEmpty(margin)) {
          results.margin = Number(margin);
          results.saleBudget = calculateClientRateFromMarginAndContractorRate(
            Number(value), // purchaseBudget
            Number(margin),
            marginType
            //true //round
          );
        } else if (isNotEmpty(saleBudget)) {
          results.saleBudget = Number(saleBudget);
          results.margin = getMarginOrMarkup(
            Number(saleBudget),
            Number(value),
            marginType
          );
        }
      }
      /* Si modification du “tarif jour/montant payé par le client” →
    recalcule automatique du “tarif jour/montant perçu par l’intervenant”
    (pas de modification du taux de marque)
    */
      if (field === 'saleBudget' && isNotEmpty(value)) {
        results.saleBudget = Number(value);
        if (isNotEmpty(margin)) {
          results.margin = Number(margin);
          results.purchaseBudget =
            calculateContractorRateFromMarginAndClientRate(
              Number(value), // saleBudget
              Number(margin),
              marginType
              //true //round
            );
        } else if (isNotEmpty(purchaseBudget)) {
          results.purchaseBudget = Number(purchaseBudget);
          results.margin = getMarginOrMarkup(
            Number(value),
            Number(purchaseBudget),
            marginType
          );
        }
      }
      setRoundedValues({
        margin: Number.isInteger(results?.margin)
          ? results?.margin
          : round(results?.margin),
        saleBudget: Number.isInteger(results?.saleBudget)
          ? results?.saleBudget
          : round(results?.saleBudget),
        purchaseBudget: Number.isInteger(results?.purchaseBudget)
          ? results?.purchaseBudget
          : round(results?.purchaseBudget),
      });
      setValue('margin', isNotEmpty(results.margin) ? results.margin : '');
      setValue(
        'saleBudget',
        isNotEmpty(results.saleBudget) ? results.saleBudget : ''
      );
      setValue(
        'purchaseBudget',
        isNotEmpty(results.purchaseBudget) ? results.purchaseBudget : ''
      );
    }
    setFieldBlur(undefined);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box>
        <Flex mb={20}>
          <CheckSwitch id="isEnabled" {...register('isEnabled')}>
            <Text>Activer les frais</Text>
          </CheckSwitch>
        </Flex>
        <CheckSwitch
          id="shouldJoinAttachment"
          {...register('shouldJoinAttachment')}
        >
          <Text>Justificatif requis</Text>
        </CheckSwitch>
        <Box mt={20}>
          <FormControl
            label="Type de taux"
            required
            errorMessage={errors?.marginType?.message}
          >
            <>
              <Radio
                {...register('marginType', {
                  required: 'Ce champ est requis',
                })}
                // Taux de marque
                value={EMarginType.MARGIN}
                onChange={(e: any) => {
                  setValue('marginType', EMarginType.MARGIN);
                  calculateBudgetAndMargin('marginType', EMarginType.MARGIN);
                }}
              >
                Taux de Marque
              </Radio>
              <Radio
                {...register('marginType', {
                  required: 'Ce champ est requis',
                })}
                // Taux de marge
                value={EMarginType.MARKUP}
                onChange={(e: any) => {
                  setValue('marginType', EMarginType.MARKUP);
                  calculateBudgetAndMargin('marginType', EMarginType.MARKUP);
                }}
              >
                Taux de Marge
              </Radio>
            </>
          </FormControl>
        </Box>

        {from === 'MISSION' && (
          <Link
            mb={20}
            iconLeft={<RefreshIcon />}
            onClick={() => {
              //@ts-ignore
              setValue('margin', '');
              //@ts-ignore
              setValue('saleBudget', '');
              //@ts-ignore
              setValue('purchaseBudget', '');
              setRoundedValues({
                //@ts-ignore
                margin: '',
                //@ts-ignore
                saleBudget: '',
                //@ts-ignore
                purchaseBudget: '',
              });
            }}
          >
            Effacer les montants
          </Link>
        )}
        <Flex justifyContent={'space-between'} flexWrap="wrap">
          <Box width={1 / 3}>
            <FormControl
              required
              label={`Taux de ${marginTypeLabel}`}
              errorMessage={errors?.margin?.message}
            >
              <Input
                {...register('margin', {
                  required: 'Ce champ est requis',
                  validate: (value: number) =>
                    value >= 100 || value < 0
                      ? 'La valeur doit être comprise entre 0 et 99%'
                      : undefined,
                })}
                step="0.001"
                type="number"
                onChange={(e: any) => {
                  setValue('margin', e?.target?.value);
                  calculateBudgetAndMargin('margin', e?.target?.value);
                }}
                isFullWidth
                pr={20}
                icon={<PercentIcon style={{ paddingRight: 20 }} />}
              />
            </FormControl>
          </Box>
          {from === 'MISSION' && (
            <Box width={2 / 3}>
              <Row spacing={20}>
                <FormControl
                  label={`Montant HT enveloppe client`}
                  errorMessage={errors?.saleBudget?.message}
                  warningMessage={
                    isOutOfRangeSaleBudget
                      ? `Attention le montant est inférieur aux montants déclarés ${consumedSaleBudget}€`
                      : undefined
                  }
                >
                  <Input
                    step="0.001"
                    isFullWidth
                    type="number"
                    icon={<EuroIcon />}
                    onBlur={() => setFieldBlur('saleBudget')}
                    onChange={(e: any) => {
                      setValue('saleBudget', e?.target?.value);
                      calculateBudgetAndMargin('saleBudget', e?.target?.value);
                    }}
                    value={roundedValues?.saleBudget}
                  />
                </FormControl>
                <FormControl
                  label={`Montant HT enveloppe fournisseur`}
                  errorMessage={errors?.purchaseBudget?.message}
                  warningMessage={
                    isOutOfRangePurchageBudget
                      ? `Attention le montant est inférieur aux montants déclarés ${consumedPurchaseBudget}€`
                      : undefined
                  }
                >
                  <Input
                    step="0.001"
                    onBlur={() => setFieldBlur('purchaseBudget')}
                    onChange={(e: any) => {
                      setValue('purchaseBudget', e?.target?.value);
                      calculateBudgetAndMargin(
                        'purchaseBudget',
                        e?.target?.value
                      );
                    }}
                    value={roundedValues?.purchaseBudget}
                    isFullWidth
                    type="number"
                    icon={<EuroIcon />}
                  />
                </FormControl>
              </Row>
            </Box>
          )}
        </Flex>
        {from === 'MISSION' && (
          <FormControl
            mt={isOutOfRangePurchageBudget || isOutOfRangeSaleBudget ? 20 : 0}
            label="Description enveloppe frais"
          >
            <TextAreaControlled
              minRows={3}
              name="description"
              control={control}
            />
          </FormControl>
        )}

        {from === 'ESTABLISHMENT' && (
          <BlocInformation>
            La modification du taux de marque/marge sera appliquée uniquement
            sur les missions au statut "Brouillon". Les mission déjà validées ou
            terminées conserveront le taux précédemment configuré.
          </BlocInformation>
        )}
        <Flex mt={20}>
          <Button type="submit" isLoading={loading}>
            Enregistrer
          </Button>
        </Flex>
      </Box>
    </form>
  );
};
