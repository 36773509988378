import styled from 'styled-components';

export const Container = styled.div<{ isFullWidth?: boolean }>`
  display: inline-flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: ${props => (props.isFullWidth ? '100%' : '375px')};
  height: 100%;
  .errorPhone {
    position: relative;
    top: 10px;
    height: 0px;
    margin: 0px;
    font-family: Poppins;
    font-size: 11px;
    font-weight: normal;
    line-height: normal;
    letter-spacing: normal;
    color: ${p => p.theme.danger};
  }

  /* Custom phone input styles */

  .country-list li {
    display: inline-flex;
    justify-content: flex-start;
    width: 100%;
  }
  .country-list span {
    display: inline-flex;
    justify-content: flex-start;
    width: 100%;
  }
  .react-tel-input :disabled {
    background-color: ${props => props.theme.colors.primary.lighter} !important;
    cursor: not-allowed;
  }
  .react-tel-input {
    .form-control {
      position: relative;
      font-size: 14px;
      letter-spacing: 0.01rem;
      z-index: 0;
      margin-top: 0 !important;
      margin-bottom: 0 !important;
      padding-left: 48px;
      margin-left: 0;
      background: #ffffff;
      border: 1px solid ${props => props.theme.colors.primary.light};
      &:focus {
        border: 1px solid ${props => props.theme.colors.primary.default};
      }
      border-radius: 5px;
      line-height: 25px;
      height: 50px;
      outline: none;
      width: 100%;
      &.invalid-number {
        border: 1px solid #d79f9f;
        background-color: #faf0f0;
        border-left-color: #cacaca;
        &:focus {
          border: 1px solid #d79f9f;
          border-left-color: #cacaca;
          background-color: #faf0f0;
        }
      }
      &.open {
        z-index: 2;
      }
    }
    .flag-dropdown {
      outline: none;
      position: absolute;
      top: 0;
      bottom: 0;
      padding: 0;
      background-color: #f5f5f5;
      border: 1px solid #cacaca;
      border-radius: 3px 0 0 3px;
      &.open {
        z-index: 2;
        background: #fff;
        border-radius: 3px 0 0 0;
        .selected-flag {
          background: #fff;
          border-radius: 3px 0 0 0;
        }
      }
      &:hover,
      &:focus {
        cursor: pointer;
        .selected-flag {
          background-color: #fff;
        }
      }
    }
    input[disabled] {
      & + .flag-dropdown {
        &:hover {
          cursor: default;
          .selected-flag {
            background-color: transparent;
          }
        }
      }
    }
    .selected-flag {
      position: relative;
      width: 38px;
      height: 100%;
      padding: 0 0 0 8px;
      border-radius: 3px 0 0 3px;
      .flag {
        position: absolute;
        top: 50%;
        margin-top: -5px;
      }
      .arrow {
        position: relative;
        top: 50%;
        margin-top: -2px;
        left: 20px;
        width: 0;
        height: 0;
        border-left: 3px solid transparent;
        border-right: 3px solid transparent;
        border-top: 4px solid #555;
        &.up {
          border-top: none;
          border-bottom: 4px solid #555;
        }
      }
    }
    .country-list {
      z-index: 1;
      list-style: none;
      position: absolute;
      padding: 0;
      margin: 10px 0 10px -1px;
      box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.35);
      background-color: white;
      color: black;
      width: 300px;
      max-height: 200px;
      overflow-y: scroll;
      border-radius: 0 0 3px 3px;
      .country-list li {
        display: inline-flex;
        justify-content: flex-start;
        width: 100%;
      }
      .country-list span {
        display: inline-flex;
        justify-content: flex-start;
        width: 100%;
      }
      .flag {
        display: inline-block;
        width: 36px;
      }
      .divider {
        padding-bottom: 5px;
        margin-bottom: 5px;
        border-bottom: 1px solid #ccc;
      }
      .country {
        padding: 7px 9px;
        .dial-code {
          color: #6b6b6b;
        }
        &:hover {
          background-color: #f1f1f1;
        }
        &.highlight {
          background-color: #f1f1f1;
        }
      }
      .flag {
        margin-right: 7px;
        margin-top: 2px;
      }
      .country-name {
        margin-right: 6px;
      }
      .search {
        position: sticky;
        top: 0;
        background-color: #fff;
        padding: 10px 0 6px 10px;
      }
      .search-emoji {
        font-size: 15px;
      }
      .search-box {
        border: 1px solid #cacaca;
        border-radius: 3px;
        font-size: 15px;
        line-height: 15px;
        margin-left: 6px;
        padding: 3px 8px 5px;
        outline: none;
      }
      .no-entries-message {
        padding: 7px 10px 11px;
        opacity: 0.7;
      }
    }
  }
  .react-tel-input :disabled {
    background-color: ${props => props.theme.colors.primary.lighter} !important;
    color: ${props => props.theme.colors.grayBlue};
    border: solid 1px ${props => props.theme.colors.grayBlue};
    cursor: not-allowed;
  }
`;
