import {
  IContractorBase,
  IContractorCreateParams,
} from '@freelancelabs/teoreme-commons';
import { contractorCreateOne, formatAllObject } from '@commons';
import { Text } from 'components/ui';
import { AddContractorForm } from 'forms/AddContractorForm';
import React from 'react';
import { create } from 'react-modal-promise';
import { queryClient } from '@commons';
import { ModalFrame, ModalProps } from './ModalFrame';

type Props = ModalProps & {
  beforeValidation?: () => void;
  contactIsContractor?: boolean;
  onSubmit?: (newContractor: IContractorCreateParams) => void;
  noEstablishment?: boolean;
};

export const AddContractorModal: React.FC<React.PropsWithChildren<Props>> = ({
  onResolve,
  isOpen,
  contactIsContractor,
  onSubmit,
  noEstablishment,
}) => {
  const onAddContractor = async (contractor: IContractorCreateParams) => {
    try {
      if (onSubmit) {
        await onSubmit(formatAllObject(contractor));
        onResolve();
      } else {
        //contractor.updateExistingUser = true;
        const newContractor = await contractorCreateOne(
          formatAllObject(contractor)
        );
        queryClient.refetchQueries({
          queryKey: ['contractors'],
          type: 'active',
        });
        if (newContractor) onResolve(newContractor);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <ModalFrame
      isOpen={isOpen}
      onClose={() => onResolve(false)}
      width={850}
      closeIcon
    >
      <Text variant="h1" mb={20}>
        Ajouter un intervenant
      </Text>
      <AddContractorForm
        contactIsContractor={contactIsContractor}
        onSubmit={onAddContractor}
        noEstablishment={noEstablishment}
      />
    </ModalFrame>
  );
};

export const showAddContractorModal = create<Props, IContractorBase>(
  AddContractorModal
);
