import { Flex } from 'components/ui/Flex';
import React from 'react';
import styled, { useTheme } from 'styled-components';
import 'styled-components/macro';
export const TableBody = styled.tbody``;
export const TableBodyRow = styled.tr`
  border-bottom: 1px solid #b4c6d3;
`;

type TableBodyCellProps = {
  children: any;
  align?: string;
  style?: any;
};
export const TableBodyCell = ({
  children,
  align = 'flex-start',
  style,
}: TableBodyCellProps) => {
  const theme = useTheme();
  return (
    <td
      style={style ? style : {}}
      css={`
        height: 50px;
        font-size: 14px;
        color: ${theme.colors.primary.dark};
      `}
    >
      <Flex p="0 10px" justifyContent={align} alignItems="center">
        {children}
      </Flex>
    </td>
  );
};
