import { UserSelect } from 'components/selects/UserSelect';
import { useController } from 'react-hook-form';
export const UserSelectControlled = (props: any) => {
  const {
    field: { ref, ...inputProps },
  } = useController({
    name: props?.name,
    control: props?.control,
    rules: props?.rules,
    defaultValue: props?.defaultValue,
  });

  return <UserSelect {...inputProps} {...props} inputRef={ref} />;
};
