import styled from 'styled-components';

export const ContainerPagination = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 10px;

  .Pagination {
    display: flex;
    align-items: center;
    & > * {
      min-width: 30px;
    }
  }

  .textPagination {
    display: flex;
    font-size: 14px;
    color: ${p => p.theme.colors.grayBlue};
  }
`;

export const PageRounded = styled.div<{ selected: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;

  ${p => p.selected && 'background-color: #eef6fa;'};

  border-radius: 100%;
  width: 30px;
  height: 30px;
  color: #004269;

  cursor: pointer;
`;
