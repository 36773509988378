import React, {
  ComponentPropsWithoutRef,
  ReactElement,
  ReactNode,
} from 'react';
import styled, { useTheme } from 'styled-components';
import { Box, boxProperties, BoxProps } from './Box';
import { ExclamationCircleIcon } from './icons';

export const FormLabel = styled.label<BoxProps>`
  text-transform: uppercase;
  color: ${props => props.color || props.theme.colors.grey.default};
  font-size: 11px;
  margin-bottom: 5px;
  display: block;
  font-weight: bold;
  ${boxProperties}
`;

type FormInfoProps = {
  variantColor?: 'primary' | 'error' | 'warning';
};
const FormInfoStyled = styled.div<BoxProps & FormInfoProps>`
  display: flex;
  align-items: center;
  margin: 0;
  margin-top: 5px;
  size: 11px;
  color: ${props =>
    props.theme.colors[props.variantColor || 'primary'].default};
  ${boxProperties};
`;

export const FormInfo = ({
  children,
  ...props
}: FormInfoProps & ComponentPropsWithoutRef<'p'>) => {
  const theme = useTheme();
  return (
    <FormInfoStyled {...props}>
      <ExclamationCircleIcon
        fill={theme.colors[props.variantColor || 'primary'].default}
        style={{ marginRight: 5 }}
      />{' '}
      {children}
    </FormInfoStyled>
  );
};

type FormControlProps = BoxProps & {
  children?: ReactElement;
  errorMessage?: ReactNode;
  warningMessage?: ReactNode | string;
  htmlFor?: string;
  label: string | ReactNode;
  required?: boolean;
};
export const FormControl = ({
  children,
  errorMessage,
  warningMessage,
  htmlFor,
  label,
  required,
  ...props
}: FormControlProps) => {
  const theme = useTheme();
  return (
    <Box {...props}>
      <FormLabel>
        {label}&nbsp;
        {required && <span style={{ color: theme.colors.blue }}>*</span>}
      </FormLabel>
      {children &&
        React.cloneElement(
          children,
          errorMessage ? { variantColor: 'error' } : undefined
        )}
      <Box minHeight={30} pt="1px">
        {errorMessage && (
          <FormInfo variantColor="error">{errorMessage}</FormInfo>
        )}
        {!errorMessage && warningMessage && (
          <FormInfo variantColor="warning">{warningMessage}</FormInfo>
        )}
      </Box>
    </Box>
  );
};

export const Form = styled.form<BoxProps>`
  width: 100%;
  ${boxProperties}
`;
