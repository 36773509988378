import { EXPENSES_TYPE_SELECT } from '@commons';
import { IJoinedMission, IExpenseLine } from '@freelancelabs/teoreme-commons';
import {
  Box,
  Button,
  Flex,
  FormControl,
  StaticSelectControlled,
  Text,
  NumberFormInputControlled,
  TextAreaControlled,
} from 'components/ui';
import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';

type Line = IExpenseLine & {
  lock?: boolean;
};
type FormValues = Line & {
  lock: boolean;
};
type AddExpensesLineProps = {
  line: Line;
  readOnly?: boolean;
  mission: IJoinedMission;
  onCreateOrUpdateLine: (line: Line) => void;
  onDeleteLine: (line: Line) => void;
  unLockToUpdate?: (line: Line) => void;
  isEditable?: boolean;
};
export const AddExpensesLine: React.FC<
  React.PropsWithChildren<AddExpensesLineProps>
> = ({
  line,
  readOnly,
  isEditable,
  mission,
  onCreateOrUpdateLine,
  onDeleteLine,
  unLockToUpdate,
}) => {
  const [loading, setLoading] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [attachmentFile, setAttachmentFile] = useState<File | undefined>();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loadingFile, setLoadingFile] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<FormValues>({
    defaultValues: {
      ...line,
    },
  });
  const onSubmit = async (formValues: FormValues) => {
    const onError = false;
    setLoading(true);
    let attachmentResponse;
    try {
      if (!onError) {
        const submitValues = {
          uuid: line?.uuid || new Date()?.getTime(),
          type: formValues?.type,
          amount: formValues?.amount,
          comment: formValues?.comment,
          attachment: attachmentResponse,
          lock: true,
        };
        //@ts-ignore
        onCreateOrUpdateLine(submitValues);
      }
    } catch (e) {
      setLoading(false);
    }

    setLoading(false);
  };
  // const onChangeFile = (files: any) => {
  //   setLoadingFile(true);
  //   setAttachmentFile(files[0]);
  //   setLoadingFile(false);
  // };
  useEffect(() => {
    reset(line);
  }, [line]);
  return (
    <Box width={1 / 1} mt={10}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Flex
          display="inline-flex"
          justifyContent="flex-start"
          flexWrap="wrap"
          width={1 / 1}
        >
          <Box width={3 / 12}>
            <FormControl
              required
              label="Type de frais"
              p={'5px'}
              errorMessage={errors?.type?.message}
            >
              <StaticSelectControlled
                isDisabled={readOnly}
                control={control}
                rules={{ required: 'Ce champs est requis' }}
                name="type"
                options={EXPENSES_TYPE_SELECT}
              />
            </FormControl>
          </Box>
          <Box width={3 / 12}>
            <FormControl
              required
              label="Montant TTC"
              p={'5px'}
              errorMessage={
                errors?.amount?.type === 'min'
                  ? 'Ne peut pas être inférieur à 1'
                  : errors?.amount?.message
              }
            >
              <Flex>
                <NumberFormInputControlled
                  control={control}
                  name="amount"
                  maxDecimal={3}
                  step={'0.001'}
                  type="number"
                  isDisabled={readOnly}
                  isFullWidth
                  rules={{
                    required: 'Ce champ est requis',
                    min: 1,
                  }}
                />
              </Flex>
            </FormControl>
          </Box>
          <Flex width={3 / 12} alignItems="center">
            <Flex
              width={1 / 1}
              alignItems="center"
              justifyContent="space-around"
            >
              <Flex width={3 / 12}>
                {readOnly && isEditable ? (
                  <>
                    <Button
                      onClick={() => unLockToUpdate && unLockToUpdate(line)}
                      height="40px"
                      ml={'5px'}
                      mr={'5px'}
                      mb={'10px'}
                      variant="ghost"
                    >
                      Modifier
                    </Button>
                    <Button
                      onClick={() => onDeleteLine(line)}
                      height="40px"
                      variant="ghostError"
                      mb={'10px'}
                    >
                      Supprimer
                    </Button>
                  </>
                ) : (
                  <Flex>
                    {!readOnly && (
                      <Button
                        isLoading={loading}
                        height="40px"
                        mb={'10px'}
                        variant="filled"
                        type="submit"
                      >
                        Ajouter
                      </Button>
                    )}
                  </Flex>
                )}
              </Flex>
            </Flex>
          </Flex>
          <Box width={12 / 12}>
            <FormControl label="Informations complémentaires">
              {!readOnly ? (
                <TextAreaControlled
                  minRows={3}
                  name="comment"
                  control={control}
                />
              ) : (
                <Text>{line?.comment || 'N/A'}</Text>
              )}
            </FormControl>
          </Box>
        </Flex>
      </form>
    </Box>
  );
};

AddExpensesLine.displayName = 'AddExpensesLine';

export default AddExpensesLine;
