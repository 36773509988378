import { useState } from 'react';
import {
  PROVIDER_FORMULA_TAG_WHITELIST,
  MIXED_TAG_INPUT_SETTING,
  transformFormulaToTag,
  queryClient,
  cutLongText,
  missionUpdateOne,
} from '@commons';
import { useCustomerEstablishmentFeeListStore } from 'store';
import { Theme } from 'styles';

import {
  Flex,
  Box,
  Button,
  FormControl,
  Text,
  CheckSwitch,
  Table,
  TableItem,
  Spinner,
  CustomToolTip,
  Itemize,
  MissionSelectControlled,
  CheckBox,
  SpinnerBox,
} from 'components/ui';
import { InfoIcon } from 'components/ui/icons';
import { MixedModeTagify } from 'components/InputTags';
import { useForm } from 'react-hook-form';
import {
  IMissionGetResponse,
  IJoinedMission,
  IStandByDutyFormulaLine,
} from '@freelancelabs/teoreme-commons';
import { useShowMessage } from 'hooks/useShowMessage';

type FormValues = {
  mission: any;
};
type ImportMissionStandByDutyFormProps = {
  standByDuty: any;
  mission?: IJoinedMission;
  onResolve: (value?: any) => void;
};

export const ImportMissionStandByDutyForm = ({
  standByDuty,
  onResolve,
  mission,
}: ImportMissionStandByDutyFormProps) => {
  const showMessage = useShowMessage();
  const [loading, setLoading] = useState(false);
  const [missionComplet, setMissionComplet] = useState<
    IMissionGetResponse | IJoinedMission | undefined
  >();
  const [selectedFormulas, setSelectedFormulas] = useState<any>([]);
  const [items, setItems] = useState<Item>([]);

  const removeFormula = (formulaUuid: string) => {
    let newSelectedFormulas = selectedFormulas;
    newSelectedFormulas = newSelectedFormulas.filter(
      (f: any) => f.uuid !== formulaUuid
    );
    setSelectedFormulas(newSelectedFormulas);
  };

  const addFormula = (formula: any) => {
    setSelectedFormulas([...selectedFormulas, formula]);
  };

  const selectMission = (selectedMission: any) => {
    setMissionComplet(selectedMission);
    const selectedMissionFormulas = selectedMission?.standByDutyFormulas || [];
    const newSelectedMissionFormulas = selectedMissionFormulas?.map(
      (formula: any) => {
        const sameFormulas: any[] = existingStandByDutyFormulas.filter(
          (f: any) => {
            const isSameFormulas =
              f.customerFormula === formula.customerFormula &&
              f.providerFormula === formula.providerFormula;

            return isSameFormulas;
          }
        );

        formula.duplicated =
          sameFormulas?.length && sameFormulas?.length > 0 ? true : false;

        formula.key = formula.uuid;

        return formula;
      }
    );
    setItems(newSelectedMissionFormulas);
    const newselectedFormulas = newSelectedMissionFormulas?.filter(
      (f: any) => !f.duplicated
    );

    setSelectedFormulas(newselectedFormulas);
  };
  const getIsDisabledForm = () => {
    return selectedFormulas?.length === 0 || !selectedFormulas;
  };

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {
      ...standByDuty,
    },
  });

  const onSubmit = async (formValues: FormValues) => {
    setLoading(true);

    try {
      const params = selectedFormulas.map((formula: any) => {
        const {
          key,
          uuid,
          createdBy,
          updatedBy,
          createdAt,
          updatedAt,
          duplicated,
          ...newFormlaData
        } = formula;

        return newFormlaData;
      });
      await missionUpdateOne({
        reference: mission?.reference || '',
        mission: {
          addStandByDutyFormulas: params as IStandByDutyFormulaLine[],
        },
      });
      queryClient?.refetchQueries({
        queryKey: [mission?.reference],
        type: 'active',
      });
      showMessage('success', `Les formules ont été importées`);

      onResolve(formValues);
    } catch (e) {
      //
    }

    setLoading(false);
  };

  // FOR TABLE IMPORT FUNCTIONS
  const ActiveFormula = ({
    item,
    mission,
  }: {
    item: TableItem;
    mission: IMissionGetResponse;
  }) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [active, setActive] = useState(item?.active);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [isLoading, setIsLoading] = useState(false);
    return (
      <CheckSwitch
        isDisabled={true}
        checked={active}
        id={`active_${item.uuid}`}
      >
        {isLoading ? <Spinner /> : <></>}
      </CheckSwitch>
    );
  };

  type Item = Itemize<any>;
  const columns = (mission: IMissionGetResponse) => [
    {
      key: 'name',
      text: 'TITRE',
      render: (item: Item) => {
        return (
          <>
            {item.duplicated && (
              <CustomToolTip
                color={Theme?.colors?.warning?.default}
                text={<Box>Formule en Doublon</Box>}
                id={item?.uuid + '_formula'}
              >
                <InfoIcon
                  fill={Theme?.colors?.warning?.default}
                  width={20}
                  height={20}
                  style={{
                    cursor: 'pointer',
                    marginBottom: 10,
                    marginRight: 10,
                  }}
                />
              </CustomToolTip>
            )}
            <CheckBox
              key={item.uuid}
              defaultChecked={!item.duplicated}
              id={`import_select_${item.uuid}`}
              onChange={e => {
                if (e.currentTarget.checked) addFormula(item);
                else removeFormula(item.uuid);
              }}
            >
              <Box>
                <Text maxWidth={150}>{item?.name}</Text>
              </Box>
            </CheckBox>
          </>
        );
      },
    },
    {
      key: 'active',
      text: 'ACTIVE',
      render: (item: Item) => <ActiveFormula item={item} mission={mission} />,
    },

    {
      key: 'description',
      text: 'DESCRIPTION',
      render: (item: Item) => (
        <Box maxWidth={200}>
          <CustomToolTip text={item?.description} id={item?.uuid}>
            <Text fontSize={9}>
              {cutLongText(item?.description, 100) || 'N/A'}
            </Text>
          </CustomToolTip>
        </Box>
      ),
    },
    {
      key: 'providerFormula',
      text: 'FORMULE FOURNISSEUR',
      render: (item: Item) => (
        <Flex
          height={'100%'}
          flexWrap="wrap"
          display="inline-flex"
          justifyContent={'center'}
          alignContent={'center'}
          alignItems={'center'}
        >
          <MixedModeTagify
            readOnly
            settings={{
              ...MIXED_TAG_INPUT_SETTING,
              whitelist: PROVIDER_FORMULA_TAG_WHITELIST,
            }}
            value={transformFormulaToTag(
              item?.providerFormula,
              PROVIDER_FORMULA_TAG_WHITELIST
            )}
          />
        </Flex>
      ),
    },
  ];
  const isFetching = false;

  const existingStandByDutyFormulas = mission?.standByDutyFormulas ?? [];

  const totalCount = items?.length;
  const { order, sortedBy, page } = useCustomerEstablishmentFeeListStore();

  return (
    <Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box>
          <FormControl
            label={'Mission'}
            required
            errorMessage={errors?.mission?.message}
          >
            <MissionSelectControlled
              id="mission-stand-by-duty-copy-formula"
              control={control}
              name="missionSelected"
              filter={{
                reference: { $ne: mission?.reference },
                standByDutyFormulas: { $exists: true, $ne: [] },
              }}
              onChangeCompleteObject={(data: any) => selectMission(data)}
              width="100%"
              referenceValue="uuid"
              placeholder="Rechercher une mission"
              rules={{ required: 'Ce champs est requis' }}
            />
          </FormControl>
          {loading && <SpinnerBox height={90} />}

          {missionComplet && (
            <Table
              columns={columns(missionComplet as IMissionGetResponse)}
              items={items}
              sortedBy={sortedBy}
              order={order}
              page={page}
              total={totalCount}
              perPage={totalCount && totalCount < 20 ? totalCount : 20}
              // onChangePage={page =>
              //   updateCustomerEstablishmentFeeListStore({ page })
              // }
              isLoading={isFetching}
            />
          )}
        </Box>
        <Flex justifyContent={'flex-end'} mt={10}>
          <Button
            isDisabled={getIsDisabledForm()}
            isLoading={loading}
            type="submit"
          >
            {'Importer'}
          </Button>
        </Flex>
      </form>
    </Box>
  );
};
