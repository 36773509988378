import * as React from 'react';

function SvgCancelIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        d="M17.657 16.04L13.617 12l4.04-4.04a1.143 1.143 0 00-1.616-1.617L12 10.383l-4.04-4.04A1.143 1.143 0 006.342 7.96L10.383 12l-4.04 4.04a1.143 1.143 0 001.616 1.617L12 13.617l4.04 4.04a1.143 1.143 0 001.617-1.616z"
      />
    </svg>
  );
}

export default SvgCancelIcon;
