import { Box } from 'components/ui';
import * as React from 'react';
import { useLocation } from 'react-router-dom';
import { SendEmailForm } from 'forms/SendEmail';
export type InformationPage = {};

export const SendEmail: React.FunctionComponent<
  React.PropsWithChildren<InformationPage>
> = () => {
  const location = useLocation<{ location: any }>();
  //@ts-ignore
  const contact = location?.state?.contact;
  return (
    <Box>
      <SendEmailForm contact={contact} forRole={'PROVIDER'} />
    </Box>
  );
};
