import { IEstablishment } from '@freelancelabs/teoreme-commons';
import { getEstablishmentName } from '@commons';
import { useUserFindMany } from '@commons';
import { ALink } from 'components/ALink';
import { Header } from 'components/Header';
import { showAddContactModal } from 'components/modals/AddContactModal';
import {
  Box,
  Button,
  Container,
  Flex,
  Input,
  Link,
  SpinnerBox,
  Table,
  TableColumn,
  Text,
} from 'components/ui';
import { EyeIcon, RefreshIcon } from 'components/ui/icons';
import { ERoles } from '@commons';
import { useBreadCrumb } from 'hooks/breadCrumb';
import { useDebounce } from 'hooks/useDebounce';
import React, { useEffect } from 'react';
import { transformText } from '@commons';
import { useCustomerContactsListStore } from 'store';
import { Theme } from 'styles';
export type Props = {};

// map user in the table
const columns: TableColumn[] = [
  {
    key: 'lastName',
    sortKey: 'lastName',
    text: 'NOM',
    render: item => item.lastName + ' ' + item.firstName,
  },
  { key: 'phone', sortKey: 'phone', text: 'TÉLÉPHONE' },
  { key: 'email', text: 'EMAIL', sortKey: 'email' },
  {
    key: 'establishments',
    text: 'ENTREPRISE',
    render: item =>
      item?.clientEstablishmentsPopulated
        ?.map((e: IEstablishment) =>
          transformText(getEstablishmentName(e), 'capitalize')
        )
        .join(', ') || (
        <div style={{ fontStyle: 'italic' }}>Pas d’entreprise rattachée</div>
      ),
  },
  {
    key: 'actions',
    text: 'ACTIONS',
    align: 'flex-end',
    render: item => (
      <Link iconLeft={<EyeIcon fill={Theme?.colors?.primary?.default} />}>
        <ALink href={`/clients/contacts/${item.cognitoUserId}`}>
          Voir la fiche
        </ALink>
      </Link>
    ),
  },
];

export const ClientsContactsList: React.FC<
  React.PropsWithChildren<Props>
> = () => {
  const { setCrumbs } = useBreadCrumb();
  useEffect(() => {
    setCrumbs([
      {
        label: 'clients',
        path: '/clients',
      },
      {
        label: 'contacts',
        path: '/clients/contacts',
      },
    ]);
  }, []);
  const {
    initialSate,
    order,
    sortedBy,
    searchQuery,
    page,
    updateCustomerContactsListStore,
    resetCustomerContactsListStore,
  } = useCustomerContactsListStore();
  let sortString = (order === 'desc' ? '-' : '') + sortedBy;
  if (!sortString?.includes('email')) {
    sortString = `${sortString} email`;
  }
  const debouncedFilterQuery = useDebounce(searchQuery, 500);

  const {
    data: contactsQuery,
    status,
    isFetching,
  } = useUserFindMany(
    {
      filterObject: {
        'roles.name': ERoles.CUSTOMER,

        $or: [
          { firstName: { $regex: debouncedFilterQuery, $options: 'i' } },
          { lastName: { $regex: debouncedFilterQuery, $options: 'i' } },
          {
            email: {
              $regex: debouncedFilterQuery?.replace('+', '\\+').trim(),
              $options: 'i',
            },
          },
        ],
      },
      sort: sortString,
      limit: 20,
      skip: page * 20,
    },
    { populateClientEstablishments: true }
  );
  const totalCount = contactsQuery?.totalCount;
  const loading = status === 'pending';

  const onSortedChange = async (
    sortedBy: string,
    order: 'desc' | 'asc'
  ): Promise<void> => {
    //@ts-ignore
    updateCustomerContactsListStore({ sortedBy, order });
  };

  const onAddContact = () => showAddContactModal({ contactRole: 'CUSTOMER' });

  // we need to give items a key
  const items =
    contactsQuery?.users.map((u: any) => ({
      key: u.cognitoUserId || '',
      ...u,
    })) || [];

  return (
    <Box>
      <Header
        actions={<Button onClick={onAddContact}>Ajouter un contact</Button>}
      >
        <Text variant="h1">Contacts Clients</Text>
      </Header>
      <Container>
        <Flex m="10px 0">
          <Box m="10px 0" width={1 / 3}>
            <Input
              isFullWidth
              type="search"
              name="search"
              placeholder="Rechercher un nom, email ..."
              onChange={(e: any) => {
                updateCustomerContactsListStore({
                  searchQuery: e?.target?.value,
                });
              }}
              value={searchQuery}
            />
          </Box>
          <Box ml={10}>
            <Flex
              justifyContent={'center'}
              alignItems={'center'}
              flexWrap="wrap"
              alignContent="center"
              width={1 / 1}
              height={'100%'}
            >
              <Link
                isDisabled={initialSate}
                iconLeft={
                  <RefreshIcon
                    fill={
                      initialSate ? Theme?.colors?.grey?.default : undefined
                    }
                  />
                }
                mt={'5px'}
                onClick={() =>
                  !initialSate ? resetCustomerContactsListStore({}) : false
                }
              >
                Réinitialiser
              </Link>
            </Flex>
          </Box>
        </Flex>
        {loading && <SpinnerBox />}
        {!loading && (
          <Table
            columns={columns}
            items={items}
            sortedBy={sortedBy}
            order={order}
            page={page}
            total={totalCount}
            perPage={totalCount && totalCount < 20 ? totalCount : 20}
            onSortedChange={onSortedChange}
            onChangePage={page => updateCustomerContactsListStore({ page })}
            isLoading={isFetching}
          />
        )}
      </Container>
    </Box>
  );
};

export default ClientsContactsList;
