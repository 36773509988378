import {
  ALink,
  Box,
  CustomToolTip,
  Flex,
  FormLabel,
  Input,
  Link,
  Status,
  Text,
} from 'components/ui';
import { useSetCrumbs } from 'hooks/breadCrumb';
import { useParams } from 'react-router-dom';
import {
  useEstablishmentFindOne,
  getTradeNameSafe,
  stripHtmlTags,
  transformText,
  getFullName,
} from '@commons';
import { MissionsList } from 'components/lists/MissionsList';
import { useGlobalListMissionStore } from 'store';
import { EMissionStatus } from '@freelancelabs/teoreme-commons';
import { format } from 'date-fns';
import { StaticSelect } from 'components/selects/StaticSelect';
import { EyeIcon } from 'components/ui/icons';

export const Missions = () => {
  const { searchQuery, statusSelect, limit, updateStore } =
    useGlobalListMissionStore();
  const { uuid } = useParams<{ uuid: string }>();
  const { data: establishment } = useEstablishmentFindOne(uuid);
  useSetCrumbs(
    [establishment],
    [
      {
        label: 'clients',
        path: '/clients',
      },
      {
        label: 'établissements',
        path: `/clients/establishments`,
      },
      {
        label: establishment ? getTradeNameSafe(establishment) : '-',
        path: `/clients/establishments/${establishment?.uuid}`,
      },
      {
        label: 'missions',
        path: `/clients/establishments/${establishment?.uuid}/missions`,
      },
    ]
  );
  return (
    <Box p={20}>
      <Text variant="h2" mb={20}>
        Missions
      </Text>
      <Box>
        <MissionsList
          filterObject={{
            'customer.establishment.uuid': uuid,
          }}
          filterComponents={
            <Flex
              display="inline-flex"
              justifyContent="flex-start"
              flexWrap={'wrap'}
              width={1 / 1}
              mb={10}
            >
              <Box width={1 / 4} pr={'5px'} pt={'5px'}>
                <FormLabel>RECHERCHER</FormLabel>
                <Input
                  isFullWidth
                  type="search"
                  onChange={e => {
                    //@ts-ignore
                    updateStore({ searchQuery: e.target.value });
                  }}
                  value={searchQuery}
                />
              </Box>
              <Box width={1 / 4} pr={'5px'} pt={'5px'}>
                <FormLabel>Statuts</FormLabel>
                <StaticSelect
                  placeholder="Tous les statuts"
                  options={[
                    {
                      label: 'Brouillons',
                      value: EMissionStatus.DRAFT,
                      key: EMissionStatus.DRAFT,
                    },
                    {
                      label: 'Validées',
                      value: EMissionStatus.VALIDATED,
                      key: EMissionStatus.VALIDATED,
                    },
                    {
                      label: 'Terminées',
                      value: EMissionStatus.TERMINATED,
                      key: EMissionStatus.TERMINATED,
                    },
                    {
                      label: 'Annulées',
                      value: EMissionStatus.ABORTED,
                      key: EMissionStatus.ABORTED,
                    },
                  ]}
                  onChange={value =>
                    updateStore({ statusSelect: value as string })
                  }
                  value={statusSelect}
                />
              </Box>
              <Box width={1 / 4} pr={'5px'} pt={'5px'}>
                <FormLabel>NB. LIGNES AFFICHÉES</FormLabel>
                <StaticSelect
                  isClearable={false}
                  placeholder=" "
                  options={[
                    {
                      label: '5',
                      value: 5,
                      key: 5,
                    },
                    {
                      label: '20',
                      value: 20,
                      key: 20,
                    },
                    {
                      label: '50',
                      value: 50,
                      key: 50,
                    },
                    {
                      label: '100',
                      value: 100,
                      key: 100,
                    },
                  ]}
                  onChange={value => updateStore({ limit: value as number })}
                  value={limit}
                />
              </Box>
            </Flex>
          }
          columns={() => [
            {
              key: 'startAt',
              text: 'DATE DE DÉBUT',
              render: item => (
                <Text>
                  {item?.startAt && format(item?.startAt, 'dd/MM/yyyy')}
                </Text>
              ),
            },
            {
              key: 'endAt',
              text: 'DATE DE FIN',
              render: item => (
                <Text
                  color={
                    item.status === EMissionStatus.TERMINATED
                      ? 'red'
                      : undefined
                  }
                >
                  {item?.endAt && format(item?.endAt, 'dd/MM/yyyy')}
                </Text>
              ),
            },
            {
              key: 'displayReference',
              text: 'RÉF.',
              render: item => (
                <CustomToolTip
                  text={stripHtmlTags(item?.description)}
                  id={item?.reference}
                >
                  <ALink
                    href={`/delivery/missions/${item?.reference}`}
                    target="_blank"
                  >
                    {item?.displayReference}
                  </ALink>
                </CustomToolTip>
              ),
            },
            {
              key: 'sage',
              text: 'STRUCT.',
              render: item => {
                if (item.sage) {
                  return item.sage.structure;
                } else {
                  return 'N/A';
                }
              },
            },
            {
              key: 'parent',
              text: 'TYPE',
              render: item => (item.parent ? 'Avenant' : 'Mission'),
            },
            {
              key: 'providerEstablishment',
              text: 'ÉTABLIS.FOURN.',
              render: item => (
                <ALink
                  href={
                    item?.provider?.establishment?.uuid
                      ? `/providers/establishments/${item?.provider?.establishment?.uuid}`
                      : undefined
                  }
                  target="_blank"
                >
                  {transformText(
                    getTradeNameSafe(item.provider.establishment),
                    'capitalize'
                  )}
                </ALink>
              ),
            },
            {
              key: 'contractor',
              text: 'INTERVENANT',
              render: item => (
                <ALink
                  href={
                    item?.provider?.contractor?.uuid
                      ? `/providers/contractors/${item?.provider?.contractor?.uuid}`
                      : undefined
                  }
                  target="_blank"
                >
                  {getFullName(item.provider.contractor)}
                </ALink>
              ),
            },
            {
              key: 'status',
              text: 'STATUT',
              render: item => {
                if (item.status === EMissionStatus.ABORTED) {
                  return <Status variantColor="error">Annulée</Status>;
                }
                if (item.status === EMissionStatus.ARCHIVED) {
                  return <Status variantColor="grey">Archivée</Status>;
                }
                if (item.status === EMissionStatus.DRAFT) {
                  return <Status variantColor="warning">Broullion</Status>;
                }
                if (item.status === EMissionStatus.TERMINATED) {
                  return <Status variantColor="error">Terminée</Status>;
                }
                if (item.status === EMissionStatus.VALIDATED) {
                  return <Status variantColor="success">Validée</Status>;
                }
              },
            },
            {
              align: 'flex-end',
              key: 'actions',
              text: 'ACTIONS',
              render: item => (
                <ALink href={`/delivery/missions/${item.reference}`}>
                  <Link iconLeft={<EyeIcon />}></Link>
                </ALink>
              ),
            },
          ]}
          hookStore={useGlobalListMissionStore}
        />
      </Box>
    </Box>
  );
};
