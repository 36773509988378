import {
  contractReminder,
  FR_STATUS,
  getEstablishmentName,
  getFullName,
  transformText,
  useContractFindMany,
} from '@commons';
import {
  EContractState,
  IContractSearchParams,
  IJoinedContract,
} from '@freelancelabs/teoreme-commons';
import { showDisplayPdfModal } from 'components/modals/DisplayPdfModal';
import { showCancelContractModal } from 'components/modals/CancelContractModal';
import {
  Flex,
  Itemize,
  Link,
  Menu,
  MenuItem,
  Spinner,
  SpinnerBox,
  Status,
  Table,
  TableColumn,
  Text,
  Box,
} from 'components/ui';
import { ALink } from 'components/ALink';

import {
  CancelIcon,
  DeleteIcon,
  DotsIcon,
  EyeIcon,
  RefreshIcon,
} from 'components/ui/icons';
import { format } from 'date-fns';
import { useShowMessage } from 'hooks/useShowMessage';
import React from 'react';
import {
  canDisplayCancelContractButton,
  canDisplayDeleteContractButton,
  canDisplayResendContractButton,
  canDisplayResiliationRequestButton,
  canDisplayTerminateContractButton,
  checkDisplayProviderContractMenuList,
} from 'helpers/business/providers';
import { Theme } from 'styles';

type Item = Itemize<IJoinedContract>;

const Actions = ({ item, isDisabled }: { item: Item; isDisabled: boolean }) => {
  const showMessage = useShowMessage();
  const [loading, setLoading] = React.useState(false);

  const onContractReminder = async () => {
    setLoading(true);
    try {
      await contractReminder({
        contract: {
          uuid: item.uuid,
        },
      });
      showMessage('success', 'Le contrat a bien été renvoyé');
    } catch (e) {
      //
    }
    setLoading(false);
  };

  if (loading) return <Spinner size={20} />;
  return (
    <Flex>
      <Box width={1 / 2}>
        <ALink>
          <Link
            iconLeft={<EyeIcon />}
            onClick={() =>
              showDisplayPdfModal({
                fileLocation: item.attachment?.fileLocation || '',
                fileName: `Contrat_Cadre_${item?.refContract}_${
                  item?.establishment?.businessName
                    .replaceAll('.', '_')
                    ?.split(' ')
                    ?.join('_') || ''
                }.pdf`,
              })
            }
          ></Link>
        </ALink>
      </Box>
      <Box
        width={1 / 2}
        marginLeft={!checkDisplayProviderContractMenuList(item) ? 20 : ''}
      >
        {checkDisplayProviderContractMenuList(item) && !isDisabled && (
          <Menu
            align="right"
            menuItems={
              <>
                {canDisplayResendContractButton(item) && (
                  <MenuItem>
                    <Link
                      iconLeft={<RefreshIcon />}
                      onClick={onContractReminder}
                    >
                      Renvoyer
                    </Link>
                  </MenuItem>
                )}
                {canDisplayResiliationRequestButton(item) && (
                  <MenuItem>
                    <Link
                      iconLeft={
                        <CancelIcon fill={Theme?.colors?.primary?.default} />
                      }
                      onClick={() =>
                        showDisplayPdfModal({
                          fileLocation:
                            item?.cancelAttachment?.fileLocation || '',
                          fileName: `Contrat_Cadre_${item?.refContract}_${
                            item?.establishment?.businessName
                              .replaceAll('.', '_')
                              ?.split(' ')
                              ?.join('_') || ''
                          }.pdf`,
                        })
                      }
                    >
                      Voir la demande de résiliation
                    </Link>
                  </MenuItem>
                )}
                {/* <MenuItem>
                  <Link
                    mr={10}
                    iconLeft={<EyeIcon />}
                    onClick={() =>
                      showDisplayPdfModal({
                        fileLocation: item.attachment?.fileLocation || '',
                        fileName: `Contrat_Cadre_${item?.refContract}_${
                          item?.establishment?.businessName
                            .replaceAll('.', '_')
                            ?.split(' ')
                            ?.join('_') || ''
                        }.pdf`,
                      })
                    }
                  >
                    Voir le contrat
                  </Link>
                </MenuItem> */}
                {canDisplayTerminateContractButton(item) && (
                  <MenuItem>
                    <Link
                      iconLeft={
                        <CancelIcon fill={Theme?.colors?.primary?.default} />
                      }
                      onClick={() =>
                        showCancelContractModal({ contractUuid: item.uuid })
                      }
                    >
                      Résilier le contrat
                    </Link>
                  </MenuItem>
                )}
                {canDisplayCancelContractButton(item) && (
                  <MenuItem>
                    <Link
                      iconLeft={
                        <CancelIcon fill={Theme?.colors?.primary?.default} />
                      }
                    >
                      Annuler le contrat
                    </Link>
                  </MenuItem>
                )}
                {canDisplayDeleteContractButton(item) && (
                  <MenuItem>
                    <Link
                      iconLeft={
                        <DeleteIcon fill={Theme?.colors?.primary?.default} />
                      }
                    >
                      Supprimer le contrat
                    </Link>
                  </MenuItem>
                )}
              </>
            }
          >
            <DotsIcon fill={Theme?.colors?.primary?.default} fontSize={20} />
          </Menu>
        )}
      </Box>
    </Flex>
  );
};

export const ContractList = ({
  filterObject = {},
  showStatus = true,
  minified,
  noStatus,
  columnsHidden = [],
  isDisabled = false,
}: {
  filterObject?: IContractSearchParams['filterObject'];
  showStatus?: boolean;
  minified?: boolean;
  noStatus?: boolean;
  columnsHidden?: string[];
  isDisabled?: boolean;
}) => {
  const [order, setOrder] = React.useState<'desc' | 'asc' | undefined>('desc');
  const [sortedBy, setSortedBy] = React.useState('createdAt');
  const sortString = (order === 'desc' ? '-' : '') + sortedBy;
  const [page, setPage] = React.useState(0);
  const {
    data: contractsQuery,
    status,
    isFetching,
  } = useContractFindMany({
    filterObject,
    limit: 20,
    skip: 20 * page,
    sort: sortString,
  });
  const totalCount = contractsQuery?.totalCount;
  const loading = status === 'pending';

  const onSortedChange = React.useCallback(
    (shortBy: string, order: 'asc' | 'desc'): void => {
      setSortedBy(shortBy);
      setOrder(order);
    },
    []
  );

  const columns: TableColumn<Item>[] = [
    {
      key: 'createdAt',
      text: 'DATE',
      //@ts-ignore
      render: item => format(item?.createdAt, 'dd/MM/yyyy'),
    },
    { key: 'refContract', text: 'RÉF', sortKey: 'refContract' },
    {
      key: 'state',
      sortKey: 'state',
      //@ts-ignore
      state: item => item.state,
      text: 'Statut',
      render: item => {
        let color: 'primary' | 'warning' | 'success' | 'error' | 'grey' =
          'primary';
        switch (item.state) {
          case EContractState.SIGNED_BY_PROVIDER:
          case EContractState.TO_SIGN_FCOM:
          case EContractState.TO_SIGN_PROVIDER:
            color = 'warning';
            break;
          case EContractState.DONE:
          case EContractState.SIGNED_BY_FCOM:
            color = 'success';
            break;

          case EContractState.ARCHIVED:
            color = 'grey';
            break;

          case EContractState.REFUSED_BY_FCOM:
          case EContractState.REFUSED_BY_PROVIDER:
            color = 'error';
            break;

          default:
            break;
        }
        return (
          <Status variantColor={color}>
            {item.state &&
              `${
                FR_STATUS[
                  item.state === EContractState.DONE
                    ? item.procedureId
                      ? EContractState.DONE
                      : 'SIGNED_IMPORT'
                    : item.state
                ]
              } ${
                item.state === EContractState.DONE
                  ? `le : ${item.signedAt?.toLocaleDateString()}`
                  : ''
              }`}
          </Status>
        );
      },
      hidden: !showStatus,
    },
    {
      key: 'type',
      text: 'TYPE DE CONTRAT',
      sortKey: 'type',
      render: item =>
        item.type && FR_STATUS[item.type] + ' ' + (item.lang || 'FR'),
    },
    {
      key: 'establishment',
      text: 'ENTREPRISE FOURNISSEUR',
      render: item =>
        transformText(getEstablishmentName(item.establishment), 'capitalize') ||
        '',
    },
    {
      key: 'refusedBy',
      text: 'REFUSÉ PAR',
      render: item =>
        item.state === EContractState.REFUSED_BY_FCOM
          ? 'Connecteed'
          : 'Le fournisseur',
      hidden:
        !filterObject.state ||
        !filterObject.state['$in'] ||
        !(
          filterObject.state['$in'].includes(EContractState.REFUSED_BY_FCOM) ||
          filterObject.state['$in'].includes(EContractState.REFUSED_BY_PROVIDER)
        ),
    },
    {
      key: 'cancelReason',
      sortKey: 'cancelReason',
      text: 'MOTIF DE RÉSILIATION',
      render: item => <Text>{item.cancelReason || ''}</Text>,
      hidden: filterObject.state !== EContractState.ARCHIVED,
    },
    {
      key: 'declineReason',
      text: 'MOTIF',
      render: item => item.declineReason || '',
      hidden:
        !filterObject.state ||
        !filterObject.state['$in'] ||
        !(
          filterObject.state['$in'].includes(EContractState.REFUSED_BY_FCOM) ||
          filterObject.state['$in'].includes(EContractState.REFUSED_BY_PROVIDER)
        ),
    },
    {
      key: 'refCommande',
      text: 'RÉF. COMMANDE',
      render: item => item.refOrder || '',
      hidden:
        !filterObject.state ||
        (filterObject.state !== EContractState.TO_SIGN_PROVIDER &&
          filterObject.state !== EContractState.SIGNED_BY_PROVIDER),
    },
    {
      key: 'accountManager',
      text: 'SUIVI PAR',
      render: item => getFullName(item.accountManager) || '',
      hidden:
        !filterObject.state ||
        (filterObject.state !== EContractState.TO_SIGN_PROVIDER &&
          filterObject.state !== EContractState.SIGNED_BY_PROVIDER),
    },
    {
      align: 'flex-end',
      key: 'actions',
      text: 'ACTIONS',
      render: item => <Actions item={item} isDisabled={isDisabled} />,
    },
  ];

  const items =
    contractsQuery?.contracts?.map(c => ({
      key: c.uuid,
      ...c,
    })) || [];

  React.useEffect(() => {
    setPage(0);
  }, [filterObject]);
  if (loading) return <SpinnerBox />;
  return (
    <>
      {items.length > 0 ? (
        <Table<Item>
          columns={columns.filter(col => {
            if (
              columnsHidden &&
              columnsHidden?.find(strCol => col.key === strCol)
            ) {
              return false;
            }
            return minified === true
              ? col.key === 'createdAt'
                ? false
                : col
              : col.key === 'state' && noStatus === true
                ? false
                : col;
          })}
          opacity={
            filterObject?.cancelReason
              ? 1
              : filterObject.state &&
                  (filterObject.state === EContractState.ARCHIVED ||
                    (filterObject.state['$in'] &&
                      (filterObject.state['$in'].includes(
                        EContractState.REFUSED_BY_FCOM
                      ) ||
                        filterObject.state['$in'].includes(
                          EContractState.REFUSED_BY_PROVIDER
                        ))))
                ? 0.5
                : 1
          }
          items={items}
          sortedBy={sortedBy}
          order={order}
          page={page}
          total={totalCount}
          perPage={totalCount && totalCount < 20 ? totalCount : 20}
          onSortedChange={onSortedChange}
          onChangePage={page => setPage(page)}
          isLoading={isFetching}
        />
      ) : (
        <></>
      )}
    </>
  );
};
