import { ISalesForceGetMarketsParams } from '@freelancelabs/teoreme-commons';
import { useQuery } from '@tanstack/react-query';
import {
  saleforceGetLink,
  saleforceGetmarket,
  saleforceGetContractData,
  saleforceGetOpp,
} from '../routes';

export const useSaleforceGetContractData = (
  reference?: string,
  config?: any // UseQueryOptions
): {
  data: any;
  error: any;
  status: any;
  isFetching: boolean;
} => {
  return useQuery({
    queryKey: [`ContractData-${reference}`, `ContractData-${reference}`],
    queryFn: async () => saleforceGetContractData(reference as string),
    enabled: reference ? true : false,
    // DISABLE CACHE
    refetchOnMount: false,
    staleTime: 0,
    gcTime: 0,
    ...config,
  });
};
export const useSaleforceGetMarkets = (
  params?: ISalesForceGetMarketsParams,
  config?: any //UseQueryOptions
): {
  data: any;
  error: any;
  status: any;
  isFetching: boolean;
  isPending: boolean;
} => {
  return useQuery({
    queryKey: ['SaleforceGetMarket', params],
    queryFn: async () =>
      saleforceGetmarket(params as ISalesForceGetMarketsParams, true),
    enabled: params && params?.uuid ? true : false,
    keepPreviousData: true,
    ...config,
  });
};

export const useSaleforceGetLink = (
  params?: any,
  config?: any //UseQueryOptions
) => {
  return useQuery({
    queryKey: ['SaleforceGetLink', params],
    queryFn: async () => saleforceGetLink(params, true),
    enabled: params ? true : false,
    keepPreviousData: true,
    ...config,
  });
};

export const useSaleforceGetOpp = (variables?: any, config?: any) => {
  return useQuery({
    queryKey: ['SaleforceGetOpp', variables],
    queryFn: async () => saleforceGetOpp(variables),
    enabled: variables && variables?.externalId ? true : false,
    ...config,
  });
};
