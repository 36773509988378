import { IJoinedMission } from '@freelancelabs/teoreme-commons';
import { Box, Text } from 'components/ui';
import { useSetCrumbs } from 'hooks/breadCrumb';
import { useGetMission } from 'hooks/missionHooks';
import React from 'react';
import { ProviderCreditNoteMission } from './Provider/List';
import { CustomerCreditNoteMission } from './Customer/List';
export const MissionCreditNote = () => {
  const { mission, reference } = useGetMission();

  useSetCrumbs(
    [],
    [
      { label: 'prestations', path: '/delivery' },
      { label: 'missions', path: '/delivery/missions' },
      {
        label: mission?.displayReference || 'nouvelle mission',
        path: `/delivery/missions/${reference}`,
      },
      {
        label: 'comptes-rendus d’activité',
        path: `/delivery/missions/${reference}/cra`,
      },
    ]
  );

  return (
    <Box>
      <Text variant="h2">Avoirs fournisseur</Text>
      {mission && (
        <ProviderCreditNoteMission mission={mission as IJoinedMission} />
      )}
      <Text mt={20} variant="h2">
        Avoirs client
      </Text>
      {mission && (
        <CustomerCreditNoteMission mission={mission as IJoinedMission} />
      )}
    </Box>
  );
};
