import {
  IUserLocal,
  checkUserHasRole,
  contractorCreateOne,
  escapeEmail,
  establishmentLookupIdentifier,
  formatAllObject,
  queryClient,
  useEstablishmentCreateOne,
  useEstablishmentUpdateOne,
  useRegister,
  userFindMany,
} from '@commons';
import {
  FlatUser,
  IContractorCreateParams,
  IEstablishment,
} from '@freelancelabs/teoreme-commons';
import { ContactExistsMessage } from 'components/ContactExistsMessage';
import { EstablishmentExistMessage } from 'components/EstablishmentExistMessage';
import { EstablishmentSelect } from 'components/selects/EstablishmentSelect';
import {
  BlocInformation,
  Box,
  Button,
  CheckSwitch,
  FormControl,
  FormLabel,
  Link,
  Spinner,
  Status,
  Text,
} from 'components/ui';
import { AddIcon, CheckIcon, CloseIcon } from 'components/ui/icons';
import { AddEstablishmentForm } from 'forms/AddEstablishmentForm';
import { AddForeignEstablishmentForm } from 'forms/AddForeignEstablishmentForm';
import { ContactForm } from 'forms/ContactForm';
import { CreateManualEstablishmentForm } from 'forms/CreateManualEstablishmentForm';
import { VerifyEmailForm } from 'forms/VerifyEmailForm';
import { VerifySiretForm } from 'forms/VerifySiretForm';
import { CreateContractorWizard } from 'forms/wizard/CreateContractorWizard';
import { ReactNode, useState } from 'react';
import { create } from 'react-modal-promise';
import { ModalFrame, ModalProps } from './ModalFrame';
import { Theme } from 'styles';
type AsynUpdateStatus = {
  start: boolean;
  contact: 'WARNING' | 'ERROR' | 'SUCCESS' | undefined;
  contractor: 'WARNING' | 'ERROR' | 'SUCCESS' | undefined;
  establishment: 'WARNING' | 'ERROR' | 'SUCCESS' | undefined;
  linkEstablishment: 'WARNING' | 'ERROR' | 'SUCCESS' | undefined;
};
type Props = ModalProps & {
  contactRole: 'PROVIDER_CONTACT' | 'CUSTOMER';
  beforeValidation?: () => void;
};
const statusIcon = {
  UNDEFINED: <></>,
  WARNING: (
    <Box ml={10}>
      <Spinner size={15} color={Theme?.colors?.warning?.default} />
    </Box>
  ),
  SUCCESS: <CheckIcon fontSize={15} fill={Theme?.colors?.success?.default} />,
  ERROR: <CloseIcon fontSize={15} fill={Theme?.colors?.error?.default} />,
};
export const AddContactModal = ({ onResolve, isOpen, contactRole }: Props) => {
  // CONTACT STATES
  const [formError, setFormError] = useState(false);
  const [createContractor, setCreateContractor] = useState(false);
  const [user, setUser] = useState<Partial<IUserLocal> | undefined>(undefined);
  const [errorExist, setErrorExist] = useState(false);
  const [emailChecked, setEmailChecked] = useState(false);
  const [email, setEmail] = useState<string | undefined>();
  const [contractorData, setCreateContractorData] = useState<
    IContractorCreateParams | undefined
  >();
  const [contactData, setContactData] = useState<
    Partial<IUserLocal> | undefined
  >();
  // ESTABLISHMENT STATES
  const establishmentRole = 'PROVIDER';
  const [createEstablishment, setCreateEstablishment] = useState(false);
  const [establishmentNotFound, setEstablishmentNotFound] = useState(false);
  const [establishment, setEstablishment] = useState<
    Partial<IEstablishment> | undefined
  >(undefined);
  const [establishmentData, setEstablishmentData] = useState<
    Partial<IEstablishment> | undefined
  >(undefined);
  const [establishmentSelected, setEstablishmentSelected] = useState<
    string | undefined
  >();
  const [previewError, setPreviewError] = useState<ReactNode | undefined>(
    undefined
  );
  const [onError, setOnError] = useState(false);
  const [showDisabled, setShowDisabled] = useState(false);
  const { mutateAsync: addEstablishment } = useEstablishmentCreateOne();
  const [notDiffusible, setNotDiffusible] = useState(false);
  const [isForeign, setIsForeign] = useState(false);
  const [siret, setSiret] = useState<String | undefined>(undefined);
  const { mutateAsync: updateEstablishment } = useEstablishmentUpdateOne();
  const [asyncUpdatesLoading, setAsyncUpdatesLoading] = useState<any>();
  const [asyncUpdatesStatus, setAsyncUpdatesStatus] =
    useState<AsynUpdateStatus>({
      start: false,
      contact: undefined,
      contractor: undefined,
      establishment: undefined,
      linkEstablishment: undefined,
    });
  const onCheckEmail = async ({ email }: { email?: string }) => {
    setEmail(email);
    const values = await userFindMany({
      filterObject: {
        email: {
          $regex: `^${escapeEmail(email && email.trim())}$`,
          $options: 'i',
        },
      },
    });

    setEmailChecked(true);
    if (values?.users?.[0]) {
      setUser(values.users[0]);
      setErrorExist(checkUserHasRole(values.users[0], contactRole));
    } else {
      setUser({ email });
    }
  };

  const { mutateAsync: addContact } = useRegister();
  const onAddContact = async (user: Partial<IUserLocal>) => {
    if (contactRole === 'CUSTOMER') {
      const newUser = await addContact({
        user: formatAllObject(user),
        roles: contactRole,
      }).catch(err => console.log(err));
      if (newUser) onResolve(newUser);
      queryClient.refetchQueries({ queryKey: ['users'] });
    } else {
      setContactData(user);
      //
    }
  };

  const handleReset = () => {
    setUser(undefined);
    setEmailChecked(false);
    setErrorExist(false);
  };
  const onCheckIdentifier = async ({ identifier }: { identifier?: string }) => {
    setPreviewError(undefined);
    setSiret(identifier);
    if (!identifier) return;
    try {
      const establishment = await establishmentLookupIdentifier(identifier);
      //établissement non diffusible
      const closedSince =
        //@ts-ignore
        establishment?.closedSince || establishment?.details?.closedSince;
      if (
        //@ts-ignore
        establishment?.errorCode === 'INSI-COMP-003' &&
        !closedSince
      ) {
        setNotDiffusible(true);
        return;
      }
      if (establishment?.uuid || closedSince) {
        if (establishment.provider && establishmentRole === 'PROVIDER') {
          setOnError(true);
        } else {
          setEstablishment(establishment);
        }
        // ON ERROR TRUE  establishment?.closedSince exist !
        if (closedSince) {
          setOnError(true);
        }

        setPreviewError(
          <EstablishmentExistMessage
            establishment={establishment}
            establishmentRole={establishmentRole}
          />
        );
      } else {
        setOnError(false);
        setShowDisabled(false);
        setEstablishment(establishment);
      }
    } catch (err) {
      setPreviewError("Impossible de trouver l'établissement spécifié");
    }
  };
  const onAddEstablishment = (establishment: any) => {
    setEstablishmentData(establishment);
  };
  const activeSaveButton = () => {
    let isActive = true;

    if (createContractor) {
      if (!contractorData) {
        isActive = false;
      }
    } else {
      if (!contactData) {
        isActive = false;
      }
    }
    if (createEstablishment) {
      if (!establishmentData && !establishmentSelected) {
        isActive = false;
      }
    }
    return isActive;
  };
  // on Create on for create Contact And Establishment Provider
  const onCreate = async () => {
    let _asyncUpdatesStatus = asyncUpdatesStatus;
    setAsyncUpdatesLoading(true);
    setAsyncUpdatesStatus({ ...asyncUpdatesStatus, start: true });

    try {
      if (createContractor) {
        _asyncUpdatesStatus.contact = 'WARNING';
        setAsyncUpdatesStatus(_asyncUpdatesStatus);
        // 1 - création du contact
        const contact = await addContact({
          user: {
            ...user,
            firstName: contractorData?.contractor.firstName,
            lastName: contractorData?.contractor.lastName,
            jobTitle: contractorData?.contractor.jobTitle,
            phone: contractorData?.contractor.phone,
          },
          roles: contactRole,
        });
        _asyncUpdatesStatus.contact = 'SUCCESS';
        setAsyncUpdatesStatus(_asyncUpdatesStatus);
        if (establishmentSelected) {
          // Rattachement
          _asyncUpdatesStatus.linkEstablishment = 'WARNING';
          setAsyncUpdatesStatus(_asyncUpdatesStatus);
          const establishmentData = {
            uuid: establishmentSelected,
            addContacts: { provider: [contact?.cognitoUserId] },
          };
          await updateEstablishment(establishmentData as any);
          _asyncUpdatesStatus.linkEstablishment = 'SUCCESS';
          setAsyncUpdatesStatus(_asyncUpdatesStatus);
          _asyncUpdatesStatus.contractor = 'WARNING';
          setAsyncUpdatesStatus(_asyncUpdatesStatus);
          await contractorCreateOne({
            ...(contractorData as IContractorCreateParams),
            establishment: establishmentSelected,
          });
          _asyncUpdatesStatus.contractor = 'SUCCESS';
          setAsyncUpdatesStatus(_asyncUpdatesStatus);
        }
        if (establishmentData) {
          // Création et rattachement
          _asyncUpdatesStatus.establishment = 'WARNING';
          setAsyncUpdatesStatus(_asyncUpdatesStatus);
          const estaCreated = await addEstablishment({
            ...establishmentData,
            provider: { manager: contact?.cognitoUserId },
          });
          _asyncUpdatesStatus.establishment = 'SUCCESS';
          setAsyncUpdatesStatus(_asyncUpdatesStatus);
          _asyncUpdatesStatus.contractor = 'WARNING';
          setAsyncUpdatesStatus(_asyncUpdatesStatus);
          await contractorCreateOne({
            ...(contractorData as IContractorCreateParams),
            establishment: estaCreated?.uuid,
          });
          _asyncUpdatesStatus.contractor = 'SUCCESS';
          setAsyncUpdatesStatus(_asyncUpdatesStatus);
        }
        setAsyncUpdatesLoading(false);
        onResolve(contact);
      } else {
        _asyncUpdatesStatus.contact = 'WARNING';
        setAsyncUpdatesStatus(_asyncUpdatesStatus);
        // 1 - création du contact
        const contact = await addContact({
          user: contactData as Partial<IUserLocal>,
          roles: contactRole,
        });
        _asyncUpdatesStatus.contact = 'SUCCESS';
        setAsyncUpdatesStatus(_asyncUpdatesStatus);
        if (createEstablishment) {
          if (establishmentSelected) {
            _asyncUpdatesStatus.linkEstablishment = 'WARNING';
            setAsyncUpdatesStatus(_asyncUpdatesStatus);
            const establishmentData = {
              uuid: establishmentSelected,
              addContacts: { provider: [contact?.cognitoUserId] },
            };
            await updateEstablishment(establishmentData as any);
            _asyncUpdatesStatus.linkEstablishment = 'SUCCESS';
            setAsyncUpdatesStatus(_asyncUpdatesStatus);
          }
          if (establishmentData) {
            // Création et rattachement
            _asyncUpdatesStatus.establishment = 'WARNING';
            setAsyncUpdatesStatus(_asyncUpdatesStatus);
            await addEstablishment({
              ...establishmentData,
              provider: { manager: contact?.cognitoUserId },
            });
            _asyncUpdatesStatus.establishment = 'SUCCESS';
            setAsyncUpdatesStatus(_asyncUpdatesStatus);
          }
        }
        setAsyncUpdatesLoading(false);
        onResolve(contact);
      }
      queryClient.refetchQueries({ queryKey: ['users'] });
    } catch (e) {
      let statusOnError;
      Object?.keys(_asyncUpdatesStatus)?.forEach(k => {
        //@ts-ignore
        let checkStatus = _asyncUpdatesStatus[k];
        if (checkStatus === 'WARNING') {
          statusOnError = k;
        }
      });
      if (statusOnError) {
        setAsyncUpdatesStatus({
          ..._asyncUpdatesStatus,
          [statusOnError]: 'ERROR',
        });
        setFormError(true);
      }
      setAsyncUpdatesLoading(false);
    }
    setAsyncUpdatesLoading(false);
  };
  const getInformationMessage = () => {
    let message = '';
    if (createContractor) {
      if (establishmentSelected) {
        message = `Le contact et l’intervenant vont être créés et rattachés à l'établissement.`;
      }
      if (establishmentData) {
        // Création et rattachement
        message = `Le contact/intervenant va être défini comme responsable de compte de l'établissement.`;
      }
    } else {
      if (createEstablishment) {
        if (establishmentSelected) {
          message = `Le contact sera rattaché à l'établissement.`;
        }
        if (establishmentData) {
          message = `Le contact sera défini comme responsable de compte de l'établissement.`;
        }
      } else {
        message = `Le contact ne sera pas rattaché à un établissement.`;
      }
    }
    return message;
  };
  return (
    <ModalFrame
      isOpen={isOpen}
      onClose={() => onResolve(true)}
      width={850}
      closeIcon
    >
      <Text variant="h1" mb={10}>
        Ajouter un contact{' '}
        {contactRole === 'CUSTOMER' ? 'client' : 'fournisseur'}
      </Text>
      <VerifyEmailForm
        contactRole={contactRole}
        onSubmit={onCheckEmail}
        isLocked={!!user}
        canModify
        onModify={handleReset}
      />
      {user && (
        <Box mt={20}>
          <ContactExistsMessage
            contactRole={contactRole}
            contact={user as IUserLocal}
          />
        </Box>
      )}
      {contactRole === 'PROVIDER_CONTACT' && !errorExist && emailChecked && (
        <Box>
          <CheckSwitch
            id="contactIsContractor"
            onChange={e => setCreateContractor(e?.target?.checked)}
            isDisabled={contractorData || contactData ? true : false}
          >
            <Text variant="p">Ce contact est également intervenant</Text>
          </CheckSwitch>
        </Box>
      )}
      <Box height={20} />
      {!errorExist && emailChecked && !user && !createContractor && (
        <>
          <ContactForm
            contactRole={contactRole}
            onSubmit={onAddContact}
            defaultValues={user}
            isNew
            isDisabled={contactData ? true : false}
          />
        </>
      )}
      {!errorExist && emailChecked && user && !createContractor && (
        <>
          <ContactForm
            contactRole={contactRole}
            onSubmit={onAddContact}
            defaultValues={user}
            isNew
            isDisabled={contactData ? true : false}
          />
        </>
      )}
      {!errorExist && emailChecked && user && createContractor && (
        <Box>
          <CreateContractorWizard
            email={email as string}
            onSubmit={data => {
              setCreateContractorData({ ...data, contactIsContractor: true });
              setCreateEstablishment(true);
            }}
            isDisabled={contractorData ? true : false}
          />
        </Box>
      )}
      {contactRole === 'PROVIDER_CONTACT' && contactData && (
        <CheckSwitch
          id="createEstablishment"
          onChange={e => setCreateEstablishment(e?.target?.checked)}
          mb={10}
        >
          Souhaitez vous créer un établissement et lui rattacher le contact ?
        </CheckSwitch>
      )}
      {/* {contactRole === 'PROVIDER_CONTACT' &&
        contactData &&
        !createEstablishment && (
          <Box mt={20}>
            <Button>Terminer</Button>
          </Box>
        )} */}
      {contactRole === 'PROVIDER_CONTACT' &&
        (contractorData || contactData) &&
        !establishmentNotFound &&
        createEstablishment && (
          <Box pr={'5px'} pt={'5px'}>
            <FormLabel>Établissement Fournisseur</FormLabel>
            <EstablishmentSelect
              filter={{ provider: { $exists: true } }}
              referenceValue="uuid"
              placeholder="Tous les fournisseurs"
              onChange={value => setEstablishmentSelected(value)}
              value={establishmentSelected}
            />
            <Link
              iconLeft={<AddIcon />}
              mt={10}
              onClick={() => {
                setEstablishmentNotFound(true);
                setEstablishmentSelected(undefined);
              }}
            >
              Ajouter un établissement fournisseur
            </Link>
          </Box>
        )}
      {contactRole === 'PROVIDER_CONTACT' &&
        (contractorData || contactData) &&
        createEstablishment &&
        establishmentNotFound && (
          <Box mt={10}>
            <VerifySiretForm
              onSubmit={onCheckIdentifier}
              error={onError && previewError}
              isLocked={!!establishment || notDiffusible}
              onUnlock={() => {
                setEstablishment(undefined);
                setNotDiffusible(false);
              }}
              onForeignEstablishment={() => {
                setEstablishment(undefined);
                setIsForeign(true);
              }}
              establishmentRole={establishmentRole}
            />
            <Link
              iconLeft={<AddIcon />}
              mt={10}
              onClick={() => {
                setEstablishmentNotFound(false);
              }}
            >
              Choisir un établissement déja existant
            </Link>
            {contactRole === 'PROVIDER_CONTACT' && notDiffusible && siret && (
              <Box>
                <FormControl
                  label=""
                  warningMessage={
                    "L'établissement à fait valoir son droit à ne pas faire partie de la diffusion dans les fichiers de l'Insee, conformément à l'article A123-96 du code de commerce. Il est ainsi impossible de récupérer les informations de cet établissement du répertoire SIRENE. Afin de rajouter cet établissement dans Connecteed, veuillez remplir les informations manuellement via le formulaire ci-dessous."
                  }
                ></FormControl>
                <Box mt={20}>
                  <CreateManualEstablishmentForm
                    noContactRequired
                    defaultValues={{ siret: siret as string }}
                    establishmentRole={establishmentRole}
                    onSubmit={(formValues: any) =>
                      onAddEstablishment(formValues)
                    }
                    onResolve={onResolve}
                    buttonHidden={establishmentData ? true : false}
                    isDisabled={
                      showDisabled || (establishmentData ? true : false)
                    }
                  />
                </Box>
              </Box>
            )}
            {contactRole === 'PROVIDER_CONTACT' &&
              establishment &&
              !notDiffusible &&
              (!onError || showDisabled) && (
                <Box mt={20}>
                  <AddEstablishmentForm
                    noContactRequired
                    isDisabled={
                      showDisabled || (establishmentData ? true : false)
                    }
                    establishmentRole={establishmentRole}
                    defaultValues={establishment}
                    onSubmit={onAddEstablishment}
                    onResolve={onResolve}
                    buttonHidden={establishmentData ? true : false}
                  />
                </Box>
              )}
            {contactRole === 'PROVIDER_CONTACT' &&
              !establishment &&
              isForeign &&
              !notDiffusible &&
              (!onError || showDisabled) && (
                <Box mt={20}>
                  <AddForeignEstablishmentForm
                    noContactRequired
                    isDisabled={
                      showDisabled || (establishmentData ? true : false)
                    }
                    establishmentRole={establishmentRole}
                    defaultValues={establishment}
                    onSubmit={onAddEstablishment}
                    onResolve={onResolve}
                    buttonHidden={establishmentData ? true : false}
                  />
                </Box>
              )}
          </Box>
        )}
      {activeSaveButton() && (
        <Box mt={10}>
          <BlocInformation>
            <p>{getInformationMessage()}</p>
          </BlocInformation>
          <Box>
            {(contactData || createContractor) && (
              <Status
                //@ts-ignore
                variantColor={
                  asyncUpdatesStatus?.contact
                    ? asyncUpdatesStatus?.contact.toLocaleLowerCase()
                    : 'grey'
                }
              >
                Création du contact
                {statusIcon[asyncUpdatesStatus?.contact || 'UNDEFINED']}
              </Status>
            )}
            {establishmentData && (
              <Status
                //@ts-ignore
                variantColor={
                  asyncUpdatesStatus?.establishment
                    ? asyncUpdatesStatus?.establishment.toLocaleLowerCase()
                    : 'grey'
                }
              >
                Création de l'établissement
                {statusIcon[asyncUpdatesStatus?.establishment || 'UNDEFINED']}
              </Status>
            )}
            {establishmentSelected && (
              <Status
                //@ts-ignore
                variantColor={
                  asyncUpdatesStatus?.linkEstablishment
                    ? asyncUpdatesStatus?.linkEstablishment.toLocaleLowerCase()
                    : 'grey'
                }
              >
                Rattachement à l'établissement
                {
                  statusIcon[
                    asyncUpdatesStatus?.linkEstablishment || 'UNDEFINED'
                  ]
                }
              </Status>
            )}
            {createContractor && (
              <Status
                //@ts-ignore
                variantColor={
                  asyncUpdatesStatus?.contractor
                    ? asyncUpdatesStatus?.contractor.toLocaleLowerCase()
                    : 'grey'
                }
              >
                Création de l'intervenant{' '}
                {statusIcon[asyncUpdatesStatus?.contractor || 'UNDEFINED']}
              </Status>
            )}
          </Box>

          <Button
            isDisabled={formError}
            isLoading={asyncUpdatesLoading}
            onClick={() => onCreate()}
            mt={10}
          >
            Terminer
          </Button>
        </Box>
      )}
    </ModalFrame>
  );
};

export const showAddContactModal = create<Props, FlatUser>(AddContactModal);
