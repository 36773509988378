import {
  EChangeOwnerType,
  EServicesCommentList,
} from '@freelancelabs/teoreme-commons';
import { Box, FormLabel, Text } from 'components/ui';
import { useSetCrumbs } from 'hooks/breadCrumb';
import { useGetMission } from 'hooks/missionHooks';
import CommentHandler from 'components/CommentsHandler';
import { StaticSelect } from 'components/selects/StaticSelect';
import { HistoryList } from 'components/lists/HistoryList';
import { useState } from 'react';

export const Comments = () => {
  const [type, setType] = useState<'comment' | 'history'>('comment');
  const { mission, reference } = useGetMission();
  const comment = mission?.comment;
  useSetCrumbs(
    [],
    [
      { label: 'prestations', path: '/delivery' },
      { label: 'missions', path: '/delivery/missions' },
      {
        label: mission?.displayReference || 'nouvelle mission',
        path: `/delivery/missions/${reference}`,
      },
      {
        label: 'Historique et commentaires',
        path: `/delivery/missions/${reference}/comments`,
      },
    ]
  );

  return (
    <Box p={20}>
      <Text variant="h2" mb={20}>
        Historique et Commentaires
      </Text>
      <Box mb={5} width={300}>
        <FormLabel>Affichage</FormLabel>
        <StaticSelect
          options={[
            { value: 'comment', label: 'Commentaires' },
            { value: 'history', label: 'Historique' },
          ]}
          onChange={value => setType((value as 'comment') || 'history')}
          value={type as string}
          name="type"
        />
      </Box>

      {comment && type === 'comment' && (
        <CommentHandler
          parentServiceUuid={comment}
          serviceName={EServicesCommentList?.MISSION}
        />
      )}

      <Box hidden={type !== 'history'}>
        <HistoryList
          filterObject={{
            mission: reference,
            ownerType: EChangeOwnerType?.MISSION,
          }}
        />
      </Box>
    </Box>
  );
};
