import { IUserLocal, IUserMinimal } from '@commons';
import { Box, Button, FormControl, Input, Text } from 'components/ui';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';

type FormValues = Partial<Omit<IUserLocal, 'civility'>> & {
  establishment: string;
  manager: string;
};
type TransferCustomerAccountFormProps = {
  onSubmit?: (user: Partial<IUserMinimal>) => any | void;
};

export const TransferCustomerAccountForm = ({
  onSubmit: onSubmitParent,
}: TransferCustomerAccountFormProps) => {
  const [loading, setLoading] = useState(false);
  const {
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {
      manager: localStorage?.cognitoUserId,
    },
  });

  const onSubmit = async (formValues: FormValues) => {
    setLoading(true);

    setLoading(false);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Text variant="p" mb={20}>
        Vous avez la possibilité de transférer la gestion d’un client et de ses
        missions à un autre chargé de compte. Celui-ci recevra également les
        notifications associées.
      </Text>
      <Box>
        <FormControl
          label="Etablissement client"
          required
          errorMessage={errors?.establishment?.message}
        >
          <Box width={'375px'} mt={20}>
            {/* <EstablishmentSelectControlled
              disabled
              id="contractor-establishment-select"
              control={control}
              name="establishment"
              filter={{ customer: { $exists: true } }}
              width="100%"
              referenceValue="uuid"
              placeholder="Rechercher un N° SIRET, raison sociale ..."
            /> */}
            <Input isDisabled isFullWidth />
          </Box>
        </FormControl>
        <Box width={'375px'} mt={20}>
          <FormControl
            label="Chargé de comptes"
            required
            errorMessage={errors?.manager?.message}
          >
            <Input isDisabled isFullWidth />
            {/* <UserSelectControlled
              disabled
              control={control}
              name="manager"
              role={ERoles.ACCOUNT_MANAGER}
              placeholder="Chargés de comptes"
            /> */}
          </FormControl>
        </Box>
      </Box>
      <Button disabled type="submit" isLoading={loading} isDisabled={loading}>
        Transférer
      </Button>
    </form>
  );
};
