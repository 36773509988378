import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { TasksList } from './list';
import { TasksDetails } from './Details';
export const TasksRoutes = () => {
  return (
    <Switch>
      <Route exact path="/admin/tasks">
        <Redirect to="/admin/tasks/all" />
      </Route>
      <Route path="/admin/tasks/:filter(all|pending|ongoing|error|completed)">
        <TasksList />
      </Route>
      <Route path="/admin/tasks/:uuid">
        <TasksDetails />
      </Route>
    </Switch>
  );
};
