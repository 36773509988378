import { EBillingType } from '@freelancelabs/teoreme-commons';
import { Box, Button, Flex, Text } from 'components/ui';
import { useSetCrumbs } from 'hooks/breadCrumb';
import { useGetMission } from 'hooks/missionHooks';
import React from 'react';
import { AdditionalActivityList } from 'components/lists/AdditionalActivityList';
import { showCreateOrUpdateExpensesModal } from 'components/modals/activities/CreateOrUpdateExpensesModal';

export const MissionAdditionalActivity = () => {
  const { mission, reference } = useGetMission();

  useSetCrumbs(
    [],
    [
      { label: 'prestations', path: '/delivery' },
      { label: 'missions', path: '/delivery/missions' },
      {
        label: mission?.displayReference || 'nouvelle mission',
        path: `/delivery/missions/${reference}`,
      },
      {
        label: 'Prestations complémentaires & Frais',
        path: `/delivery/missions/${reference}/additional-activities`,
      },
    ]
  );
  return (
    <Box>
      <Flex width={1 / 1}>
        <Text width={2 / 3} variant="h2">
          Prestations complémentaires & Frais
        </Text>
        {mission?.billing?.type === EBillingType?.FLAT_RATE && (
          <Flex width={1 / 3} justifyContent="flex-end">
            <Button
              ml={10}
              isDisabled={!mission?.expensesConfig?.isEnabled}
              onClick={() =>
                showCreateOrUpdateExpensesModal({
                  method: 'CREATE',
                  missionRef: mission?.reference as string,
                })
              }
            >
              Créer un frais
            </Button>
          </Flex>
        )}
      </Flex>

      {mission && (
        <AdditionalActivityList
          mission={mission}
          missionRef={mission.reference}
        />
      )}
    </Box>
  );
};
