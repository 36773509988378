import { create } from 'zustand';

type State = {
  newMessage: boolean;
  updateNotifications: (state: Partial<State>) => void;
  resetNotifications: (state: Partial<State>) => void;
};

const initialStates = {
  newMessage: false,
};

const store = (set: any) => ({
  ...initialStates,
  updateNotifications: (state: Partial<State>) => set(state),
  resetNotifications: (state: Partial<State>) =>
    set({ ...initialStates, ...state }),
});
export const useNotificationsStore = create<State>(store);
