import { ETeoremeSpaces } from '@freelancelabs/teoreme-commons';
import { Box, FormControl, Input, Text, Flex, Button } from 'components/ui';
import { SvgIsSendIcon } from 'components/ui/icons';
import { resetPassword } from '@commons';
import { useForm } from 'react-hook-form';
import React, { useState } from 'react';
import { create } from 'react-modal-promise';
import { ModalFrame, ModalProps } from './ModalFrame';
type FormValues = {
  email: string;
};
type ForgetPasswordProps = ModalProps & {
  beforeValidation?: () => void;
};

export const ForgetPasswordModal: React.FC<
  React.PropsWithChildren<ForgetPasswordProps>
> = ({ onResolve, isOpen }) => {
  const [loading, setLoading] = useState(false);
  const [isSend, setIsSend] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {
      email: '',
    },
  });
  const onSubmit = async (formValues: FormValues) => {
    setLoading(true);
    try {
      await resetPassword(
        { email: formValues.email, targetSpace: ETeoremeSpaces.MANAGER },
        true
      );
    } catch {
      //
    }

    setIsSend(true);
    setLoading(false);
  };
  const checkEmail = (value: string): string | undefined => {
    const regex = /[a-z0-9_\-\.]+@[a-z0-9_\-\.]+\.[a-z]+/i;
    if (regex.test(value)) {
      return undefined;
    } else {
      return 'Veuillez entrer une adresse e-mail valide';
    }
  };
  return (
    <ModalFrame
      isOpen={isOpen}
      onClose={() => onResolve(false)}
      width={470}
      closeIcon
    >
      {!isSend && (
        <>
          <Text variant="h1" mb={20}>
            Réinitialiser le mot de passe
          </Text>
          <Text variant="p" mb={20}>
            Saisissez l’adresse email associée à votre compte. Nous vous
            enverrons un lien sécurisé par email pour réinitialiser votre mot de
            passe.
          </Text>
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormControl
              required
              label="Email"
              errorMessage={errors?.email?.message}
            >
              <Input
                isFullWidth
                id="email"
                type="email"
                {...register('email', {
                  required: 'Ce champ est requis',
                  validate: value => checkEmail(value),
                })}
              />
            </FormControl>
            <Flex justifyContent="center">
              <Button
                width={1 / 1}
                key="submit"
                type="submit"
                isLoading={loading}
              >
                Envoyer le lien
              </Button>
            </Flex>
          </form>
        </>
      )}
      {isSend && (
        <Box>
          <Flex
            width="100%"
            alignItems="center"
            justifyContent="center"
            height={200}
          >
            <SvgIsSendIcon />
          </Flex>
          <Text variant="h1" textAlign="center" mb={20}>
            Un email de réinitialisation vous a été envoyé
          </Text>
          <Flex justifyContent="center">
            <Button
              width={1 / 1}
              key="submit"
              type="button"
              onClick={() => onResolve()}
            >
              Retour à la page de connexion
            </Button>
          </Flex>
        </Box>
      )}
    </ModalFrame>
  );
};

export const showForgetPasswordModal =
  create<ForgetPasswordProps>(ForgetPasswordModal);
