import {
  Flex,
  Box,
  Menu,
  MenuItem,
  Text,
  Spinner,
  Divider,
} from 'components/ui';
import { TaskLightIcon, DownloadIcon } from 'components/ui/icons';
import React, { useEffect, useRef, useState } from 'react';
import ProgressBar from 'react-bootstrap/ProgressBar';
//@ts-ignore
import Jump from 'react-reveal/Tada';
import { useGlobalStore } from 'store/global';
import { Theme } from 'styles';
import {
  formatDateAndTimeComment,
  useAdminTaskFindMany,
  setDateTimeToZero,
  onDownloadFile,
} from '@commons';
import { add, sub, getTime } from 'date-fns';
import {
  EExportModel,
  ETaskStatus,
  ETaskType,
  ITask,
} from '@freelancelabs/teoreme-commons';
export type TaskInfoProps = {};

export const TaskInfo: React.FC<
  React.PropsWithChildren<TaskInfoProps>
> = () => {
  const [isBounce, setIsBounce] = useState(false);
  const intervalRef = useRef();
  const { tasksInProgress } = useGlobalStore();
  const [refetchInterval, setRefetchInterval] = useState<Number | undefined>(
    5000
  );
  const [IsDownloadFile, setIsDownloadFile] = useState<string | false>(false);
  const currentDate = setDateTimeToZero(add(new Date(), { days: 1 }));
  const { data: tasks } = useAdminTaskFindMany(
    {
      filterObject: {
        startedBy: localStorage?.cognitoUserId,
        type: ETaskType.EXPORT_XLSX,
        $or: [
          {
            $and: [
              {
                $expr: {
                  $lte: [{ $toLong: `$createdAt` }, getTime(currentDate)],
                },
              },
              {
                $expr: {
                  $gte: [
                    { $toLong: `$createdAt` },
                    getTime(sub(currentDate, { months: 1 })),
                  ],
                },
              },
            ],
          },
        ],
      },
      limit: 5,
      skip: 0,
      sort: '-createdAt',
    },
    { refetchInterval: refetchInterval }
  );
  const onDeleteLocalExport = async (uuid: string) => {
    setIsDownloadFile(uuid);
    try {
      const task = tasks?.tasks?.find((t: any) => t?.uuid === uuid);
      if (task && task?.output?.file) {
        await onDownloadFile(task?.output?.file);
      }
    } catch (e) {
      setIsDownloadFile(false);
    }
    setIsDownloadFile(false);
  };
  useEffect(() => {
    if (isBounce) {
      //@ts-ignore
      intervalRef.current = setInterval(setIsBounce(false), 2000);
      return () => {
        clearInterval(intervalRef.current);
      };
    }
  }, [isBounce]);
  useEffect(() => {
    setIsBounce(true);
    setRefetchInterval(2000);
  }, [tasksInProgress]);
  React.useEffect(() => {
    if (
      tasks?.tasks?.find(
        (t: any) => t?.status === 'ONGOING' || t?.status === 'PENDING'
      )
    ) {
      setRefetchInterval(2000);
    } else {
      if (refetchInterval) {
        setRefetchInterval(undefined);
      }
    }
  }, [tasks]);
  const getErrorLabel = (task: ITask) => {
    if (task?.status === ETaskStatus.ERROR) {
      if (task?.output?.noResult) {
        return 'Aucun résultat pour exporter le fichier';
      }
      return 'Une erreur est survenue';
    }
  };
  const getTaskText = (task: ITask) => {
    switch (task.type) {
      case ETaskType.EXPORT_XLSX:
        if (task?.input?.model === EExportModel.MISSION) {
          return `Export fichier missions
          ${formatDateAndTimeComment(task?.startedAt as Date)}`;
        }
        if (task?.input?.model === EExportModel.CRA) {
          return `Export fichier CRA
          ${formatDateAndTimeComment(task?.startedAt as Date)}`;
        }
        if (task?.input?.model === EExportModel.INVOICE) {
          return `Export fichier Factures
          ${formatDateAndTimeComment(task?.startedAt as Date)}`;
        }
        if (task?.input?.model === EExportModel.ACTIVITY) {
          return `Export fichier activités
          ${formatDateAndTimeComment(task?.startedAt as Date)}`;
        }
        if (task?.input?.model === EExportModel.CONTACT) {
          return `Export fichier contacts
          ${formatDateAndTimeComment(task?.startedAt as Date)}`;
        }
        if (task?.input?.model === EExportModel.ESTABLISHMENT) {
          return `Export fichier Établissements
          ${formatDateAndTimeComment(task?.startedAt as Date)}`;
        }
        return `Export fichier mission
        ${formatDateAndTimeComment(task?.startedAt as Date)}`;
    }
  };
  return (
    <Box>
      <Menu
        align="right"
        openOnClick={false}
        menuItems={
          tasks && tasks?.tasks?.length > 0 ? (
            <Box width={450}>
              {tasks?.tasks?.map((t: any, index: number) => (
                <Box key={t.uuid}>
                  <MenuItem onClick={() => onDeleteLocalExport(t?.uuid)}>
                    <Flex
                      width={1 / 1}
                      mb={'5px'}
                      display={'inline-flex'}
                      flexWrap={'wrap'}
                      justifyContent={'flex-start'}
                      alignItems={'center'}
                    >
                      <Box mr={10}>
                        {t?.status !== ETaskStatus.ERROR && (
                          <DownloadIcon fontSize={22} />
                        )}
                      </Box>
                      <Text variant="h3">{getTaskText(t)}</Text>
                      {IsDownloadFile === t?.uuid && (
                        <Box ml={10}>
                          <Spinner />
                        </Box>
                      )}
                      <Box width={1 / 1}>
                        <ProgressBar
                          label={getErrorLabel(t)}
                          variant={
                            t?.status === ETaskStatus.ERROR
                              ? 'danger'
                              : t.progress === 100
                                ? 'success'
                                : 'primary'
                          }
                          animated={
                            t?.status !== ETaskStatus.ERROR &&
                            t.progress !== 100
                          }
                          now={
                            t?.status === ETaskStatus.ERROR ? 100 : t.progress
                          }
                        />
                      </Box>
                    </Flex>
                  </MenuItem>
                  {index + 1 !== tasks?.tasks?.length && <Divider />}
                </Box>
              ))}
            </Box>
          ) : (
            <MenuItem>Aucune tâche en cours</MenuItem>
          )
        }
      >
        <Box mt={'5px'}>
          <Jump spy={tasksInProgress}>
            <TaskLightIcon
              fontSize={20}
              fill={Theme?.colors?.grey?.default}
              onClick={() => setIsBounce(!isBounce)}
            />
          </Jump>
        </Box>
      </Menu>
    </Box>
  );
};
