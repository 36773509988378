import {
  IEstablishmentMinifiedUsers,
  ISigner,
} from '@freelancelabs/teoreme-commons';
import { OtherSignersForm } from 'forms/OtherSignersForm';
import React from 'react';
import { create } from 'react-modal-promise';
import { ModalFrame, ModalProps } from '../ModalFrame';

type Props = ModalProps & {
  beforeValidation?: () => void;
  establishment: IEstablishmentMinifiedUsers;
  layer?: number;
  signer?: ISigner;
};

export const OtherSignersModal = ({
  onResolve,
  isOpen,
  establishment,
  signer,
  layer,
}: Props) => {
  return (
    <ModalFrame
      isOpen={isOpen}
      onClose={() => onResolve(false)}
      width={850}
      closeIcon
      layer={layer}
    >
      <OtherSignersForm
        signer={signer}
        establishment={establishment}
        onSubmit={() => false}
        onClose={(action?: boolean | undefined) => onResolve(action || false)}
      />
    </ModalFrame>
  );
};

export const showOtherSignersModal = create<Props>(OtherSignersModal);
