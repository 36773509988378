import React, { useEffect, useState } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import 'styles/bootstrap.css';
import { Link, Button, Text, Box } from 'components/ui';
type SideBarProps = {
  children: React.ReactNode;
  buttonText?: string;
  buttonType?: 'link' | 'button' | 'icon';
  title?: string | React.ReactNode;
  customLinkIcon?: React.ReactNode;
};

export const SideBar = ({
  children,
  buttonText,
  title,
  buttonType,
  customLinkIcon,
}: SideBarProps) => {
  const [show, setShow] = React.useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      {buttonType === 'link' && (
        <Link iconLeft={customLinkIcon} onClick={handleShow}>
          {buttonText || 'Ouvrir'}
        </Link>
      )}
      {buttonType === 'button' && (
        <Button onClick={handleShow}>{buttonText || 'Ouvrir'}</Button>
      )}
      {buttonType === 'icon' && (
        <Box onClick={handleShow}>{customLinkIcon}</Box>
      )}
      <Offcanvas show={show} onHide={handleClose}>
        <Offcanvas.Header closeButton>
          {title && (
            <Offcanvas.Title>
              <Text variant="h3">{title}</Text>
            </Offcanvas.Title>
          )}
        </Offcanvas.Header>
        <Offcanvas.Body>{children}</Offcanvas.Body>
      </Offcanvas>
    </>
  );
};
