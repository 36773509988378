import { createGlobalStyle } from 'styled-components';
import { normalize } from 'styled-normalize';

export const GlobalStyles = createGlobalStyle`
  // normlize.css styles
  ${normalize}

  // Our global styles
  html, body {
    font-family: 'Poppins', sans-serif;
    font-size: ${p => p.theme.fontSize.regular}px;

    background-color: ${p => p.theme.background};
    color: ${p => p.theme.text};
    height: 100%;
    width: 100%;
  }

  .modal {
    height: 100%;
    width: 100%;
  }
`;

export * from './theme';
