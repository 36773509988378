import {
  establishmentFindRCPRO,
  missionUpdateOne,
  queryClient,
  removeOffsetDate,
} from '@commons';
import {
  EAdditionalServiceStatus,
  EAdditionalServiceType,
  IAdditionalService,
  ICompanySearchRcproResponse,
  IJoinedMission,
  IFastCashService,
  ECurrency,
} from '@freelancelabs/teoreme-commons';
import { Box, Button, Flex, FormControl, Spinner, Text } from 'components/ui';
import { useState, useEffect } from 'react';
import { StaticSelect } from 'components/selects/StaticSelect';
import { RCProForm } from './RCProForm';
import { FastCashForm } from './FastCashForm';

type FormValues = IAdditionalService;
type MissionFormValues = {
  formValues?: {
    endAt: Date;
    startAt: Date;
  };
};
type AdditionalServicesFormProps = {
  forRole: 'ALL' | 'CUSTOMER' | 'PROVIDER' | 'ESTABLISHMENT_PROVIDER';
  onSubmit: (service?: IAdditionalService | undefined) => any | void;
  service?: IAdditionalService;
  additionalServices: IAdditionalService[] | IFastCashService[];
  onClose: () => void;
  mission: IJoinedMission & MissionFormValues;
};

export const AdditionalServicesForm = ({
  additionalServices,
  forRole,
  onClose,
  onSubmit: onSubmitParent,
  service,
  mission,
}: AdditionalServicesFormProps) => {
  const [formType, setFormType] = useState<EAdditionalServiceType | undefined>(
    service?.type
  );
  const [loadingRc, setLoadingRc] = useState(false);
  const [loading, setLoading] = useState(false);
  const formMethod = service ? 'update' : 'create';
  const [estaHaveRCPRO, setEstaHaveRCPRO] = useState<
    ICompanySearchRcproResponse | null | false
  >(null);
  const [missionRCPRO, setMissionRCPRO] = useState<[string] | []>([]);

  const findEstablishmentRCPO = async () => {
    setLoadingRc(true);
    const missionRCP: [string] | [] = [];
    try {
      const rcPro = await establishmentFindRCPRO({
        uuid: mission?.provider?.establishment?.uuid,
        filterObject: {},
      });
      let rcproActive = false;
      rcPro?.results.forEach(element => {
        //@ts-ignore
        missionRCP.push(element.displayRefMission);
        if (
          element?.status === EAdditionalServiceStatus.ACTIVE ||
          element?.status === EAdditionalServiceStatus.PENDING
        ) {
          rcproActive = true;
        }
      });
      if (rcproActive) {
        setEstaHaveRCPRO(rcPro);
      } else {
        setEstaHaveRCPRO(false);
      }
    } catch (e) {
      //
    }
    setLoadingRc(false);
    setMissionRCPRO(missionRCP);
  };

  const onSubmit = async (formValues: FormValues) => {
    setLoading(true);
    try {
      if (formMethod === 'update') {
        const submitData = {
          reference: mission.reference,
          mission: {
            additionalServices: {
              update: [
                {
                  //@ts-ignore
                  catalog: formValues?.catalog,
                  uuid: formValues?.uuid,
                  price: formValues?.price,
                  unit: formValues?.unit,
                  description: formValues?.description,
                  comment: formValues?.comment,
                  //@ts-ignore
                  defaultCatalogItem: formValues?.defaultCatalogItem,
                  recurrent: formValues?.recurrent,
                  currency: ECurrency.EUR,
                  paidByProvider: formValues?.paidByProvider,
                  validityStart:
                    formValues?.type !== EAdditionalServiceType.FAST_CASH
                      ? formValues?.validityStart
                        ? removeOffsetDate(formValues?.validityStart)
                        : undefined
                      : undefined,
                  validityEnd: formValues?.validityEnd,
                },
              ],
            },
          },
        };
        await missionUpdateOne(submitData);
        queryClient.refetchQueries({ queryKey: [mission.reference] });
        onClose();
      }
      if (formMethod === 'create') {
        const submitData = {
          reference: mission.reference,
          mission: {
            additionalServices: {
              add: [
                {
                  price: formValues?.price,
                  unit: formValues?.unit,
                  description: formValues?.description,
                  comment: formValues?.comment,
                  //@ts-ignore
                  defaultCatalogItem: formValues?.defaultCatalogItem,
                  recurrent: formValues?.recurrent,
                  currency: ECurrency.EUR,
                  paidByProvider: formValues?.paidByProvider,
                  validityStart: formValues?.validityStart
                    ? removeOffsetDate(formValues?.validityStart)
                    : undefined,
                  validityEnd: formValues?.validityEnd,
                  type: formValues?.type,
                },
              ],
            },
          },
        };
        await missionUpdateOne(submitData);
        queryClient.refetchQueries({ queryKey: [mission.reference] });
        onClose();
      }
    } catch (e) {
      //
    }
    setLoading(false);
  };
  useEffect(() => {
    if (mission && mission?.reference && mission?.provider?.establishment) {
      findEstablishmentRCPO();
    }
  }, [mission?.reference]);
  if (loadingRc) {
    return (
      <Flex justifyContent="center">
        <Spinner />
      </Flex>
    );
  }

  const servicesAvalaible = [
    {
      label: 'Responsabilité Civile Pro',
      value: EAdditionalServiceType.PROFESSIONAL_INDEMNITY_INSURANCE,
    },
    {
      label: 'Fast Cash',
      value: EAdditionalServiceType.FAST_CASH,
    },
  ]?.filter(sa => {
    if (formMethod === 'update') {
      return sa?.value === service?.type;
    }
    if (formMethod === 'create') {
      const fc = additionalServices?.find(
        a => a?.type === EAdditionalServiceType?.FAST_CASH
      )?.type;
      //FIX TEOR-5362
      const rcPro = additionalServices?.find(
        a =>
          a?.type ===
            EAdditionalServiceType?.PROFESSIONAL_INDEMNITY_INSURANCE &&
          a.status === EAdditionalServiceStatus.ACTIVE
      )?.type;
      if (sa?.value !== fc && sa?.value !== rcPro) {
        return true;
      }
    }
  });
  return (
    <Box>
      {servicesAvalaible?.length > 0 && (
        <Text variant="h2">
          {service ? 'Modifer' : 'Ajouter'} un service complémentaire
        </Text>
      )}
      {servicesAvalaible?.length === 0 ? (
        <Box>
          <Text variant="h1">Aucun service n'est disponible</Text>

          <Text variant="p" mt={20}>
            Vous pouvez résilier ou modifier les services exsitants depuis le
            tableau des services complémentaire.
          </Text>
          <Button onClick={() => onClose()} mt={20} variant="filled">
            C'est noté
          </Button>
        </Box>
      ) : (
        <>
          <Box mt="20px">
            <FormControl label="TYPE" required>
              <StaticSelect
                name="type"
                isDisabled={formMethod === 'update'}
                options={servicesAvalaible}
                onChange={(value: any) => {
                  setFormType(value);
                }}
                value={formType}
                placeholder="Type de service"
              />
            </FormControl>
          </Box>
          <Box width={1 / 1}>
            {formType === EAdditionalServiceType.FAST_CASH && (
              <FastCashForm
                forRole={forRole}
                service={service as IFastCashService}
                additionalServices={additionalServices as IFastCashService[]}
                onClose={onClose}
                mission={mission}
                onSubmit={(value: any) => onSubmit(value)}
              />
            )}
            {formType ===
              EAdditionalServiceType.PROFESSIONAL_INDEMNITY_INSURANCE && (
              <RCProForm
                forRole={forRole}
                service={service}
                additionalServices={additionalServices}
                onClose={onClose}
                mission={mission}
                onSubmit={(value: any) => onSubmit(value)}
              />
            )}
          </Box>
        </>
      )}
    </Box>
  );
};
