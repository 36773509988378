import styled from 'styled-components';
import { boxProperties, BoxProps } from './ui';

export const SideBar = styled.div<BoxProps>`
  padding-right: 40px;
  margin-right: 50px;
  width: 330px;
  min-width: 330px;
  border-right: 1px solid #ccc;
  ${boxProperties}
`;
