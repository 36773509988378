import { IEstablishmentMinifiedUsers } from '@freelancelabs/teoreme-commons';
import { Roles as RolesTypes } from '@freelancelabs/inside-commons/dist/lib/models/enum/role.enum';
import { useEstablishmentUpdateOne } from '@commons';
import { queryClient } from '@commons';
import { Link, Flex, Box, Text, Button } from 'components/ui';
import React, { useState } from 'react';
import { create } from 'react-modal-promise';
import { ModalFrame, ModalProps } from '../ModalFrame';
import { UserSelect } from 'components/selects/UserSelect';
import { CloseIcon } from 'components/ui/icons';
type Props = ModalProps & {
  beforeValidation?: () => void;
  establishment: IEstablishmentMinifiedUsers;
};

export const UpdateManagerModal = ({
  onResolve,
  isOpen,
  establishment,
}: Props) => {
  const [contactSelected, setContactSelected] = React.useState<
    string | undefined
  >();
  const { mutateAsync: updateEstablishment } = useEstablishmentUpdateOne();
  const [loading, setLoading] = useState(false);
  const onSubmit = async () => {
    setLoading(true);

    try {
      // API MOVE PREVIOUS MANAGER IN ESTABLISHMENT CONTACT !!!
      // const establishmentData = {
      //   uuid: establishment?.uuid,
      //   addContacts: {
      //     provider: [establishment?.provider?.manager?.cognitoUserId],
      //   },
      //   removeContacts: { provider: [contactSelected] },
      // };
      // await updateEstablishment(establishmentData as any);
      const establishmentData2 = {
        uuid: establishment?.uuid,
        establishment: {
          provider: {
            manager: contactSelected,
          },
        },
      };
      await updateEstablishment(establishmentData2 as any);
      queryClient.refetchQueries({ queryKey: [establishment?.uuid] });
      onResolve(true);
    } catch (e) {
      //
    }

    setLoading(false);
  };

  return (
    <ModalFrame
      isOpen={isOpen}
      onClose={() => onResolve(false)}
      width={470}
      closeIcon
    >
      <Box>
        <Text variant="h2">Modifier le responsable de compte</Text>
        <Box width={'375px'} mt={20}>
          <UserSelect
            placeholder="Responsable de compte"
            role={RolesTypes.PROVIDER_CONTACT}
            onChange={value => setContactSelected(value)}
            value={contactSelected}
          />
        </Box>
        <Flex mt={20} width={'250px'} justifyContent="space-between">
          <Button
            isLoading={loading}
            isDisabled={!contactSelected}
            onClick={() => onSubmit()}
            type="button"
          >
            Enregistrer
          </Button>
          <Link
            mt={20}
            iconLeft={<CloseIcon />}
            onClick={() => onResolve(false)}
          >
            Annuler
          </Link>
        </Flex>
      </Box>
    </ModalFrame>
  );
};

export const showUpdateManagerModal = create<Props, boolean>(
  UpdateManagerModal
);
