import React from 'react';
import styled, { css } from 'styled-components';
import { lighten } from 'polished';
import { Box, BoxProps } from './Box';
import { Spinner } from './Spinner';

const ButtonStyled = styled(Box)<{
  variant?:
    | 'filled'
    | 'ghost'
    | 'warning'
    | 'error'
    | 'success'
    | 'ghostError'
    | 'ghostSuccess';
}>`
  cursor: pointer;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  padding: 0 25px;
  font-size: 15px;
  font-weight: 600;
  border-radius: 6px;
  background-color: ${p => p.theme.colors.primary.default};
  color: ${p => p.theme.colors.white};

  &:hover {
    background-color: ${p => lighten(0.15, p.theme.colors.blue)};
  }
  &:disabled {
    background-color: ${p => p.theme.colors.lightBlue};
    cursor: not-allowed;
  }

  ${props =>
    props.variant === 'ghost' &&
    css`
      color: ${props.theme.colors.primary.default};
      background-color: transparent;
      &:hover {
        color: white;
      }
    `}
  ${props =>
    props.variant === 'ghostError' &&
    css`
      color: ${props.theme.colors.error.default};
      background-color: transparent;
      border: 2px solid ${props.theme.colors.error.default};
      &:hover {
        background-color: ${props =>
          lighten(0.35, props.theme.colors.error.default)};
      }
    `}
    ${props =>
    props.variant === 'ghostSuccess' &&
    css`
      color: ${props.theme.colors.success.default};
      background-color: transparent;
      border: 2px solid ${props.theme.colors.success.default};
      &:hover {
        background-color: ${props =>
          lighten(0.35, props.theme.colors.success.default)};
      }
    `}
    ${props =>
    props.variant === 'warning' &&
    css`
      color: 'white';
      background-color: ${props.theme.colors.warning.default};
      border: 2px solid ${props.theme.colors.warning.default};
      &:hover {
        background-color: ${props =>
          lighten(0.35, props.theme.colors.warning.default)};
      }
    `}
      ${props =>
    props.variant === 'error' &&
    css`
      color: 'white';
      background-color: ${props.theme.colors.error.default};
      border: 2px solid ${props.theme.colors.error.default};
      &:hover {
        background-color: ${props =>
          lighten(0.35, props.theme.colors.error.default)};
      }
    `}
    ${props =>
    props.variant === 'success' &&
    css`
      color: 'white';
      background-color: ${props.theme.colors.success.default};
      border: 2px solid ${props.theme.colors.success.default};
      &:hover {
        background-color: ${props =>
          lighten(0.1, props.theme.colors.success.default)};
      }
    `}
`;

const Loader = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  align-items: center;
  justify-content: center;
`;

type ButtonProps = BoxProps &
  Omit<React.HTMLProps<HTMLButtonElement>, 'ref' | 'as'> & {
    isLoading?: boolean;
    isDisabled?: boolean;
    isFullWidth?: boolean;
    variant?:
      | 'filled'
      | 'ghost'
      | 'warning'
      | 'error'
      | 'success'
      | 'ghostError'
      | 'ghostSuccess';
  };

export const Button = ({
  children,
  isLoading,
  type,
  disabled,
  isDisabled,
  isFullWidth,
  variant = 'filled',
  ...props
}: ButtonProps) => {
  return (
    <ButtonStyled
      width={isFullWidth ? '100%' : 'auto'}
      {...props}
      as="button"
      disabled={isDisabled || disabled || isLoading}
      type={type as any}
      variant={variant}
    >
      <Box opacity={isLoading ? 0 : 1}>{children}</Box>
      {isLoading && (
        <Loader>
          <Spinner color="#fff" />
        </Loader>
      )}
    </ButtonStyled>
  );
};
