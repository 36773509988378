import * as React from 'react';

function SvgSendIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        stroke="currentColor"
        strokeWidth={0.25}
        d="M20.374 4.04l.01.005.028.013c.021.01.042.023.061.036 0 .002.001.002.002.003.024.016.04.03.055.043l.014.016.015.015-.03-.03c.03.026.055.055.076.087l.012.021c.014.02.024.04.034.06l.008.024c.01.02.016.04.02.061l.008.035a.504.504 0 01.007.056l.001.035c0 .019 0 .038-.002.056l-.003.023-1.913 12.87a.52.52 0 01-.516.444.535.535 0 01-.169-.028l-4.529-1.548-2.534 3.45a.523.523 0 01-.943-.309V15.15l-5.733-1.96a.523.523 0 01-.073-.956L19.93 4.06a.526.526 0 01.443-.02zM11.13 15.505v2.382l1.398-1.904-1.398-.478zm8.28-9.399l-7.867 8.438 6.293 2.151L19.41 6.106zm-1.697.29L5.841 12.595l4.62 1.579 7.252-7.778z"
      />
    </svg>
  );
}

export default SvgSendIcon;
