import { Flex, Box, Text } from 'components/ui';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { Card, CardBody } from '../ui/Card';
const ContainerCard = styled(Card)`
  cursor: pointer;
  &:hover,
  &:focus {
    background-color: ${p => p.theme.colors.lightGray};
  }
`;

export const CardStats = ({
  icon,
  label,
  value,
  link,
  ...props
}: {
  icon: any;
  label: any;
  value: any;
  link?: string;
}) => {
  const history = useHistory();
  return (
    <Box
      width={1 / 1}
      p={10}
      onClick={() => (link ? history.push(link) : false)}
    >
      <ContainerCard maxWidth={'100%'} height="100px">
        <CardBody width={1 / 1} height={'100%'}>
          <Flex
            justifyContent="space-around"
            alignItems={'center'}
            flexWrap={'wrap'}
            height={'100%'}
          >
            <Box width={2 / 12}>{icon}</Box>
            <Box width={10 / 12}>
              <Flex
                justifyContent="center"
                alignItems={'center'}
                flexWrap={'wrap'}
              >
                <Box width={1 / 1} textAlign={'center'}>
                  <Text fontSize={13} variant="h3">
                    {label}
                  </Text>
                </Box>
                <Box>
                  <Text variant="b" fontSize={13}>
                    {value}
                  </Text>
                </Box>
              </Flex>
            </Box>
          </Flex>
        </CardBody>
      </ContainerCard>
    </Box>
  );
};
