import {
  contractorUpdateOne,
  getEstablishmentName,
  useEstablishmentUpdateOne,
} from '@commons';
import { Roles as RolesTypes } from '@freelancelabs/inside-commons/dist/lib/models/enum/role.enum';
import {
  IJoinedContractor,
  IJoinedContractorScopedData,
} from '@freelancelabs/teoreme-commons';
import { UserSelect } from 'components/selects/UserSelect';
import { BlocInformation, Box, Button, Flex, Link, Text } from 'components/ui';
import { CloseIcon } from 'components/ui/icons';
import React, { useState } from 'react';
import { create } from 'react-modal-promise';
import { queryClient } from '@commons';
import { useTheme } from 'styled-components';
import { ModalFrame, ModalProps } from '../ModalFrame';
type Props = ModalProps & {
  beforeValidation?: () => void;
  contractor: IJoinedContractor;
  scopedData: IJoinedContractorScopedData | undefined;
};

export const UpdateProviderContactModal = ({
  onResolve,
  isOpen,
  contractor,
  scopedData,
}: Props) => {
  const [providerContact, setProviderContact] = React.useState<
    string | undefined
  >();
  const theme = useTheme();
  const { mutateAsync: updateEstablishment } = useEstablishmentUpdateOne();
  const [loading, setLoading] = useState(false);
  const [isLinked, setIsLinked] = useState(false);
  const [isSame, setIsSame] = useState(false);
  const [isTmpContact, setisTmpContact] = useState(false);
  const onSubmit = async () => {
    setLoading(true);
    try {
      if (!isLinked || isTmpContact) {
        const submitData = {
          uuid: scopedData?.establishment?.uuid as string,
          removeTempContacts: isTmpContact
            ? { provider: [providerContact] }
            : undefined,
          addContacts:
            !isLinked || isTmpContact
              ? { provider: [providerContact] }
              : undefined,
        };
        await updateEstablishment(submitData as any);
      }
      await contractorUpdateOne({
        uuid: contractor?.uuid || '',
        scopedData: [
          {
            establishment: scopedData?.establishment?.uuid as string,
            contact: providerContact,
          },
        ],
      });
      queryClient.refetchQueries({ queryKey: [contractor?.uuid] });
    } catch (e) {
      //
    }
    onResolve(true);
    setLoading(false);
  };

  React.useEffect(() => {
    let isTmpContact = false;
    let isLinked = false;
    let isSame = false;
    if (scopedData?.contact?.cognitoUserId === providerContact) {
      isSame = true;
    }
    if (providerContact && scopedData?.establishment) {
      scopedData?.establishment?.provider?.contacts?.forEach(element => {
        if (element === providerContact) {
          isLinked = true;
        }
      });
    }
    if (providerContact && scopedData?.establishment) {
      scopedData?.establishment?.provider?.tempContacts?.forEach(element => {
        if (element === providerContact) {
          isTmpContact = true;
        }
      });
    }
    setIsSame(isSame);
    setisTmpContact(isTmpContact);
    setIsLinked(isLinked);
  }, [providerContact]);
  return (
    <ModalFrame
      isOpen={isOpen}
      onClose={() => onResolve(false)}
      width={670}
      closeIcon
    >
      <Box>
        <Text variant="h2">
          Modifier le contact fournisseur de l'intervenant
        </Text>
        <Box mt={20}>
          <UserSelect
            placeholder="Contacts fournisseur"
            role={RolesTypes.PROVIDER_CONTACT}
            onChange={value => setProviderContact(value)}
            value={providerContact}
          />
          {isSame && (
            <Box mt={20}>
              <Text variant="p" color={theme.colors.error.default}>
                Le contact fournisseur est déja rattaché à l'intervenant.
              </Text>
            </Box>
          )}
          {!isLinked && providerContact && !isTmpContact && !isSame && (
            <Box mt={20}>
              <BlocInformation>
                <p>
                  L’établissement et le contact fournisseur que vous avez
                  sélectionné ne sont pas rattachés. En modifiant le contact
                  fournisseur vous validerez également leur rattachement.
                </p>
              </BlocInformation>
            </Box>
          )}
          {isTmpContact && !isSame && (
            <Box mt={20}>
              <BlocInformation>
                <p>
                  {`Le contact fournisseur que vous avez séléctionné est en cours
                  de rattachement sur l'établissement ${getEstablishmentName(
                    scopedData?.establishment
                  )}, en modifiant le contact
                  fournisseur vous validerez également leur rattachement.`}
                </p>
              </BlocInformation>
            </Box>
          )}
        </Box>
        <Flex mt={20} width={'250px'} justifyContent="space-between">
          <Button
            isLoading={loading}
            isDisabled={!providerContact || isSame}
            onClick={() => onSubmit()}
            type="button"
          >
            Enregistrer
          </Button>
          <Link
            mt={20}
            iconLeft={<CloseIcon />}
            onClick={() => onResolve(false)}
          >
            Annuler
          </Link>
        </Flex>
      </Box>
    </ModalFrame>
  );
};

export const showUpdateProviderContactModal = create<Props, boolean>(
  UpdateProviderContactModal
);
