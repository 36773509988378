import { Redirect, Route, Switch } from 'react-router-dom';
import { ValidationBankAccountList } from './List';

export const ValidationBankAccount = () => {
  return (
    <Switch>
      <Route exact path="/providers/validate-bank-account">
        <Redirect to="/providers/validate-bank-account/pending" />
      </Route>
      <Route
        exact
        path="/providers/validate-bank-account/:filter(pending|rejected)"
      >
        <ValidationBankAccountList />
      </Route>
    </Switch>
  );
};
