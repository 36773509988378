import { create } from 'react-modal-promise';
import { ModalFrame, ModalProps } from '../ModalFrame';
import { GenerateExecutiveContractForm } from 'forms/GenerateExecutiveContractForm';
import {
  EContractLabel,
  IEstablishmentFullUsers,
} from '@freelancelabs/teoreme-commons';
import { useEstablishmentFindOne } from '@commons'; // moved
import { Spinner } from 'components/ui';

export const GenerateExecutiveContractModal = ({
  onResolve,
  isOpen,
  id,
  refOrder,
  contractLabel,
}: {
  id: string;
  refOrder?: string;
  isOpen: boolean;
  contractLabel: EContractLabel;
} & ModalProps) => {
  const { data: establishment, status } = useEstablishmentFindOne(id);
  /*const {
      onGenerate,
      generateStatus,
      mission,
      calculateBillingTotal,
    } = useGenerateFileActions(missionReference || '', PURCHASE_ORDER, onResolve);*/

  return (
    <ModalFrame
      isOpen={isOpen}
      onClose={() => onResolve(true)}
      width={850}
      closeIcon
    >
      {status === 'pending' && <Spinner />}
      {establishment && status === 'success' && (
        <GenerateExecutiveContractForm
          establishment={establishment as IEstablishmentFullUsers}
          refOrder={refOrder}
          defaultValues={{}}
          onClose={value => onResolve(value)}
          contractLabel={contractLabel}
        />
      )}
    </ModalFrame>
  );
};

export const showGenerateExecutiveContractModal = create(
  GenerateExecutiveContractModal,
  {
    enterTimeout: 0,
    exitTimeout: 0,
  }
);
