import { TopBar } from 'components/TopBar';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ContractLegalListPage } from './Contracts/List';
export const LegalsRoute = () => {
  return (
    <>
      <TopBar />
      <Switch>
        {/* <Route exact path="/legals">
          <Redirect to="/legals/dashboard" />
        </Route>
        <Route path="/legals/dashboard">
          <div>dashboard</div>
        </Route> */}
        <Route exact path="/legals/contracts">
          <Redirect to="/legals/contracts/historical" />
        </Route>
        <Route path="/legals/contracts/:filter(active|historical)">
          <ContractLegalListPage />
        </Route>
      </Switch>
    </>
  );
};

export default LegalsRoute;
