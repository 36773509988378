import { Box, Text } from 'components/ui';
import { useSetCrumbs } from 'hooks/breadCrumb';
import { useParams } from 'react-router-dom';
import { useEstablishmentFindOne, getTradeNameSafe } from '@commons';
import { HistoryList } from 'components/lists/HistoryList';
import { EChangeOwnerType } from '@freelancelabs/teoreme-commons';
export const History = () => {
  const { id } = useParams<{ id: string }>();
  const { data: establishment } = useEstablishmentFindOne(id);
  useSetCrumbs(
    [establishment],
    [
      {
        label: 'fournisseurs',
        path: '/providers',
      },
      {
        label: 'établissements',
        path: '/providers/establishments',
      },
      {
        label: establishment ? getTradeNameSafe(establishment) : '-',
        path: '/providers/establishments/' + establishment?.uuid || '',
      },
      {
        label: 'historique',
        path: `/providers/establishments/${establishment?.uuid || ''}/history`,
      },
    ]
  );

  return (
    <Box p={20} maxWidth={1200} width={'calc(100vw - 500px)'}>
      <Text variant="h2" mb={20}>
        Historique
      </Text>
      <HistoryList
        filterObject={{
          ownerId: id,
          ownerType: {
            $in: [EChangeOwnerType.COMPANY, EChangeOwnerType.COMPANY_PROVIDER],
          },
        }}
      />
    </Box>
  );
};
