import { Box, Button, FormControl, Text, TextArea } from 'components/ui';
import { useState } from 'react';
import { create } from 'react-modal-promise';
import { ModalFrame, ModalProps } from '../ModalFrame';
import { isNotEmpty } from '../../../../../../libs/commons/src/helpers';

type Props = ModalProps & {
  beforeValidation?: () => void;
  title: string;
  subtitle?: string;
  rejectLabel?: string;
  onRejectValidation: (data: any) => void;
  layer?: number;
};

export const RejectModal = ({
  onResolve,
  isOpen,
  layer,
  title,
  subtitle,
  rejectLabel,
  onRejectValidation,
}: Props) => {
  // const showMessage = useShowMessage();
  const [rejectReason, setRejectReason] = useState();
  const [error, setError] = useState<string | undefined>();
  const onSubmit = () => {
    if (isNotEmpty(rejectReason)) {
      onRejectValidation(rejectReason);
      onResolve(rejectReason);
    }
  };
  const checkField = (value: string) => {
    if (isNotEmpty(value)) {
      setError(undefined);
    } else {
      setError('Ce champs est requis');
    }
  };
  return (
    <ModalFrame
      isOpen={isOpen}
      onClose={() => onResolve(false)}
      width={550}
      closeIcon
      layer={layer}
    >
      <Box>
        <Text variant="h1">{title}</Text>
        <Text variant="p">{subtitle}</Text>
        <FormControl
          errorMessage={error}
          required
          mt={10}
          label="Motif de refus"
        >
          <TextArea
            onChange={(e: any) => {
              setRejectReason(e?.target?.value);
              checkField(e?.target?.value);
            }}
            value={rejectReason}
            minRows={3}
          />
        </FormControl>
        <Button mt={10} onClick={onSubmit}>
          {rejectLabel || 'Refuser'}
        </Button>
      </Box>
    </ModalFrame>
  );
};

export const showRejectModal = create<Props, boolean>(RejectModal);
