import { create } from 'zustand';
enum filterType {
  ACTIVE = 'active',
  ARCHIVED = 'archived',
  SENT = 'sent',
  COUNTERSIGN = 'countersign',
  REFUSED = 'refused',
}
enum sortType {
  ASC = 'asc',
  DESC = 'desc',
}
type State = {
  initialSate: boolean;
  filter: filterType;
  order?: sortType;
  sortedBy: string;
  searchQuery: string;
  startDate: Date | null;
  endDate: Date | null;
  managerSelected?: string;
  statusSelect?: string;
  typeSelected?: string;
  page: number;
  isOpen?: boolean;
  updateContractorsListStore: (state: Partial<State>) => void;
  resetContractorsListStore: (state: Partial<State>) => void;
};
const initialStates = {
  initialSate: true,
  filter: filterType?.ACTIVE,
  order: sortType?.ASC,
  sortedBy: 'lastName firstName',
  searchQuery: '',
  startDate: null,
  endDate: null,
  managerSelected: localStorage?.cognitoUserId,
  statusSelect: 'NONE',
  typeSelected: 'N/A',
  page: 0,
  isOpen: undefined,
};

const store = (set: any) => ({
  ...initialStates,
  updateContractorsListStore: (state: Partial<State>) =>
    set({ initialSate: false, page: 0, ...state }),
  resetContractorsListStore: (state: Partial<State>) =>
    set({ ...initialStates, ...state }),
});

export const useContractorsListStore = create<State>(store);
