import { useUserFindOne, useUserUpdateOne, queryClient } from '@commons';
import { Flex, Box, Text, Link } from 'components/ui';
import { ContactForm } from 'forms/ContactForm';
import { VerifyEmailForm } from 'forms/VerifyEmailForm';
import { getFullName } from '@commons';
import { useSetCrumbs } from 'hooks/breadCrumb';
import * as React from 'react';
import { useParams } from 'react-router-dom';
import { EditIcon } from 'components/ui/icons';
import { showUpdateProviderContactEmailModal } from 'components/modals/providers/UpdateProviderContactEmailModal';
import { FlatUser } from '@freelancelabs/teoreme-commons';

export const ProviderContactsInformation: React.FunctionComponent<
  React.PropsWithChildren<unknown>
> = () => {
  const { id } = useParams<{ id: string }>();

  const { data: provider } = useUserFindOne(id);
  const { mutateAsync: updateClient } = useUserUpdateOne();

  useSetCrumbs(
    [provider],
    [
      {
        label: 'fournisseurs',
        path: '/providers',
      },
      {
        label: 'contacts',
        path: '/providers/contacts',
      },
      {
        label: provider ? getFullName(provider) : '-',
        path: `/providers/contacts/${id}`,
      },
      {
        label: 'informations',
        path: `/providers/contacts/${id}/information`,
      },
    ]
  );

  return (
    <>
      <Text variant="h2" mb={20}>
        Informations générales
      </Text>
      <Flex alignContent="center" alignItems="center">
        <Box width={8 / 10}>
          <VerifyEmailForm
            contactRole={'PROVIDER_CONTACT'}
            defaultValues={provider}
            isLocked
          />
        </Box>
        <Link
          ml={20}
          iconLeft={<EditIcon />}
          onClick={() =>
            showUpdateProviderContactEmailModal({
              contact: provider as FlatUser,
              contactRole: 'PROVIDER_CONTACT',
            })
          }
        >
          Modifier
        </Link>
      </Flex>

      <Box height={20} />
      <ContactForm
        contactRole={'PROVIDER_CONTACT'}
        defaultValues={provider}
        onSubmit={async provider => {
          await updateClient({ id: id || '', user: provider });
          queryClient.refetchQueries({ queryKey: [id] });
        }}
      />
    </>
  );
};
