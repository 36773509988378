import { TopBar } from 'components/TopBar';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { PurchaseOrdersList } from './List';

export const PurchaseOrders = () => {
  return (
    <>
      <TopBar />
      <Switch>
        <Route exact path="/delivery/purchase-orders">
          <Redirect to="/delivery/purchase-orders/active" />
        </Route>

        <Route
          exact
          path="/delivery/purchase-orders/:filter(sent|countersign|active|rejected|archived|canceled)"
        >
          <PurchaseOrdersList />
        </Route>
      </Switch>
    </>
  );
};
