import {
  PURCHASE_ORDER,
  IBilling,
  IAddress,
  IAdditionalService,
  IMissionGenerateFileResponse,
  ECurrency,
  EQuoteStatus,
  QUOTE,
  EBillingType,
  EPurchaseOrderStatus,
  EMissionStatus,
  ISigner,
  //EQuoteModelLabel,
} from '@freelancelabs/teoreme-commons';
import { missionGenerateFile, missionSendFile, downloadFile } from '@commons';
import { useMissionFindOne, round } from '@commons';
import { showDisplayPdfModal } from '../DisplayPdfModal';
import { showDialogModal } from '../DialogModal';
import { missionUpdateOne } from '@commons';
import { useShowMessage } from 'hooks/useShowMessage';
import { useState } from 'react';
import { queryClient } from '@commons';
import React from 'react';
//@ts-ignore
import jsonQ from 'jsonq';
import { showSelectFileTypeDialogModal } from './SelectFileTypeDialogModal';
const calculateBillingTotal = (
  billing: IBilling | undefined,
  fileType: typeof QUOTE | typeof PURCHASE_ORDER
) => {
  if (!billing) return 0;

  if (fileType === PURCHASE_ORDER) {
    if (billing.type === EBillingType.DAY) {
      return round(
        (billing.contractorRate || 0) * (billing.estimatedDays || 0)
      );
    }
    if (billing.type === EBillingType.FLAT_RATE) {
      return round(billing.contractorRate || 0);
    }
  }

  if (fileType === QUOTE) {
    if (billing.type === EBillingType.DAY) {
      return round((billing.clientRate || 0) * (billing.estimatedDays || 0));
    }
    if (billing.type === EBillingType.FLAT_RATE) {
      return round(billing.clientRate || 0);
    }
  }

  return 0;
};

type MinimumFormValues = {
  title?: string;
  startAt?: Date;
  endAt?: Date;
  location?: IAddress;
  description?: string;
  billing: IBilling;
  additionalServices: IAdditionalService[];
  additionalInformations: string;
  additionalFiles: { fileName: string; fileLocation: string; eTag: string }[];
  externalReference?: string;
  outputType?: string;
  modelLabel?: any; //EQuoteModelLabel | undefined;
};
export const useGenerateFileActions = (
  missionReference: string,
  fileType: typeof PURCHASE_ORDER | typeof QUOTE,
  onResolve: (success: boolean) => any
) => {
  const showMessage = useShowMessage();
  const { data: mission } = useMissionFindOne(missionReference);
  const [initialServices, setInitialServices] = useState(
    mission?.additionalServices || []
  );
  const [loading, setLoading] = useState(false);
  const onDateChange = async (data: MinimumFormValues) => {
    if (
      (mission?.status === EMissionStatus.DRAFT &&
        mission?.startAt &&
        //@ts-ignore
        new Date(data?.startAt).getTime() !==
          new Date(mission?.startAt).getTime()) ||
      (mission?.endAt &&
        new Date(mission?.endAt).getTime() !==
          //@ts-ignore
          new Date(data?.endAt).getTime())
    ) {
      await showDialogModal({
        title: 'Vous venez de modifier les dates de la mission.',
        text: 'Vous venez de modifier les dates de la mission , vous devez dès maintenant remplacer le devis client , le bon de commande client et le bon de commande fournisseur avant de valider la mission.',
        confirmLabel: 'Continuer',
        checkboxConfirmation: [
          'J’ai bien pris connaissance des modifications à réaliser suite aux modifications des dates.',
        ],
      })
        .then(reponse => {
          //
        })
        .catch(e => {
          //
        });
    }
  };
  const onSend = (draft: IMissionGenerateFileResponse, signer?: ISigner) => {
    if (!mission || !draft) return;
    return missionSendFile({
      mission: {
        reference: mission?.reference,
        // @ts-ignore
        attachments: [{ filename: draft.fileName, path: draft.fileLocation }],
      },
      signer,
      template: fileType,
    });
  };
  const onDownloadFile = async (file: any) => {
    try {
      const data = await downloadFile(file.fileLocation);
      if (data) {
        const reader = new FileReader();
        //@ts-ignore
        reader.readAsDataURL(data.data); // converts the blob to base64 and calls onload
        reader.onload = () => {
          const a = document.createElement('a');
          a.href = (reader.result as string) || '';
          a.download = file.fileName;

          a.click();
        };
      }
    } catch (e) {
      console.log(e);
    }
  };
  // This one is a big chunk, but nothing too complicated. Follow the steps.
  const onGenerate = async (dataParam: MinimumFormValues, signer?: ISigner) => {
    if (!mission) return;
    const { modelLabel, outputType, ...data } = dataParam;
    const billing = data.billing;
    setLoading(true);
    // First, generate the file on backend
    const file = await missionGenerateFile({
      // @ts-ignore
      fileType: fileType,
      // @ts-ignore
      outputType,
      modelLabel: modelLabel || 'CONNECTEED',
      mission: {
        reference: mission.reference,
        ...data,
        billing: {
          estimatedDays: 0,
          clientRate: 0,
          contractorRate: 0,
          currency: ECurrency.EUR,
          //@ts-ignore
          total: calculateBillingTotal(data.billing, fileType),
          ...billing,
        },
        additionalFiles: data.additionalFiles?.map(f => ({
          path: f.fileLocation,
          filename: f.fileName,
        })),
      },
    });
    queryClient.refetchQueries({ queryKey: [mission.reference] });
    setLoading(false);
    // A function to update mission
    const update = async (sentFile?: any, forceStatus?: EQuoteStatus) => {
      const { additionalFiles, additionalServices, ...newMission } = data;
      const { isEnabled, margin, marginType, purchaseBudget, saleBudget } =
        // @ts-ignore
        newMission?.expensesConfig || {};
      try {
        // delete DATA NOT USED !
        //@ts-ignore
        delete newMission.billingInformation;
        //@ts-ignore
        delete newMission?.accountManager;
        //@ts-ignore
        delete newMission?.customer;
        //@ts-ignore
        // delete newMission?.expensesConfig;
        //@ts-ignore
        delete newMission?.provider;
        const updateM = await missionUpdateOne({
          reference: mission.reference,
          mission: {
            ...newMission,
            title: mission.title,
            ...(sentFile
              ? {
                  [fileType === QUOTE ? 'customer' : 'provider']: {
                    [fileType === QUOTE ? 'quote' : 'purchaseOrder']: {
                      additionalFiles,
                      file: {
                        fileLocation:
                          sentFile?.file?.target || sentFile?.fileLocation,
                        fileName:
                          sentFile?.file?.fileName || sentFile?.fileName,
                        eTag: sentFile?.file?.Etag || 'undefined',
                      },
                      externalReference:
                        fileType === QUOTE
                          ? data?.externalReference
                          : undefined,
                      sentAt: new Date(),
                      status: forceStatus
                        ? forceStatus
                        : fileType === QUOTE
                          ? EQuoteStatus.SENT
                          : EPurchaseOrderStatus.TO_SIGN,
                      procedureId:
                        fileType === PURCHASE_ORDER
                          ? sentFile.procedureId
                          : undefined,
                    },
                    //...mission.provider,
                  },
                }
              : {}),
            // CHANGE UPDATE
            additionalServices: {
              //@ts-ignore
              add: additionalServices
                .filter(
                  //@ts-ignore
                  service => !service.uuid && !service.updated
                )
                .map(serviceClean => ({
                  ...serviceClean,
                  updated: undefined,
                  key: undefined,
                })),
              //@ts-ignore
              update: additionalServices
                .filter(
                  //@ts-ignore
                  service => service.uuid && service.updated
                )
                .map(serviceClean => ({
                  ...serviceClean,
                  updated: undefined,
                  key: undefined,
                  type: undefined,
                })),
              cancel: initialServices
                ? initialServices
                    ?.filter(previousService => {
                      let exist = false;
                      for (let i = 0; i < additionalServices.length; i++) {
                        if (
                          additionalServices[i].uuid === previousService.uuid
                        ) {
                          exist = true;
                        }
                      }
                      if (exist) {
                        return false;
                      } else {
                        return true;
                      }
                    })
                    .map(serviceClean => ({
                      uuid: serviceClean.uuid,
                    }))
                : [],
            },
            billing: { ...mission?.billing, ...newMission.billing },
            expensesConfig: {
              isEnabled,
              margin,
              marginType,
              purchaseBudget,
              saleBudget,
            },
          },
        });
        showMessage(
          'success',
          'Vos modifications ont bien été enregistrées et prises en compte dans GESCOM.'
        );
        await onDateChange(data);
        return updateM;
      } catch (e) {
        console.log('e', e);
        showMessage(
          'error',
          'Vos modifications n’ont pas pu être prises en compte dans GESCOM et n’ont pas été enregistrées dans votre fiche mission. Merci de réessayer'
        );
      }
    };
    // then show the pdf display modal
    const isSent = await showDisplayPdfModal({
      ...file,
      confirmLabel: 'Envoyer',
      layer: -1,
      customButtonText:
        fileType === QUOTE ? 'Enregistrer sans envoyer' : undefined,
      customButtonAction:
        fileType === QUOTE
          ? async () => {
              try {
                // We should update mission here
                await update(file, EQuoteStatus?.IMPORTED);
                queryClient.refetchQueries({ queryKey: [mission.reference] });
              } catch (e) {
                console.log('e', e);
              }
            }
          : undefined,
      // If user click on "Envoyer"
      onConfirm: async () => {
        // send the document
        const sentFiles = await onSend(file, signer);
        const sentFile = sentFiles?.[0];
        if (!sentFile) return;

        // We should update mission here
        // const { additionalFiles, additionalServices, ...newMission } = data;
        await update(sentFile);
        queryClient.refetchQueries({ queryKey: [mission.reference] });
      },

      // on modal close
      onCancel: async () => {
        if (fileType === 'PURCHASE_ORDER') {
          await missionUpdateOne({
            reference: mission.reference,
            mission: {
              provider: {
                // @ts-ignore
                wasDownloaded: false,
              },
            },
          });
          queryClient.refetchQueries({ queryKey: [mission.reference] });
        }
      },

      // If user choose to download, we show a Dialog modal
      downloadConfirm: async () => {
        let pdfOutput = false;
        let wordFile: any;
        let closedModal = false;
        if (fileType === 'QUOTE') {
          // 1 show modal select type docx or pdf
          // 2 show ?update genCount Modal
          //   if docx generete -> download
          //   if pdf return true so it execute normal download process

          await showSelectFileTypeDialogModal({
            title: 'Télécharger',
            updateMission: async () => update(),
            downloadPdfFile: () => {
              pdfOutput = true;
            },
            closeModal: () => {
              closedModal = true;
            },
            downloadDocFile: async () => {
              if (!wordFile) {
                wordFile = await missionGenerateFile({
                  // @ts-ignore
                  fileType: fileType,
                  // @ts-ignore
                  outputType: 'DOCX',
                  modelLabel: modelLabel || 'CONNECTEED',
                  mission: {
                    reference: mission.reference,
                    ...data,
                    billing: {
                      estimatedDays: 0,
                      clientRate: 0,
                      contractorRate: 0,
                      currency: ECurrency.EUR,
                      //@ts-ignore
                      total: calculateBillingTotal(data.billing, fileType),
                      ...billing,
                    },
                    additionalFiles: data.additionalFiles?.map(f => ({
                      path: f.fileLocation,
                      filename: f.fileName,
                    })),
                  },
                });
              }
              await onDownloadFile(wordFile);
            },
          });
        }

        if (fileType === 'PURCHASE_ORDER') {
          await showDialogModal({
            title: 'Télécharger',
            text: 'Avant de télécharger ce document souhaitez-vous mettre à jour la fiche mission avec les informations renseignées dans le bon de commande actuel ?',
            confirmLabel: 'Mettre à jour',
            cancelLabel: 'Ne pas mettre à jour',

            // This dialog modal will show a loader if user aprove question, while the mission is updating
            beforeValidation: () => update(),
          });
          pdfOutput = true;
        }

        if (closedModal) return false; // stop update and pdf download process if modal closed
        // update counter in case of download
        await missionUpdateOne({
          reference: mission.reference,
          mission: {
            [fileType === QUOTE
              ? 'incQuoteCounter'
              : 'incPurchaseOrderCounter']: true,
          },
        });
        queryClient.refetchQueries({ queryKey: [mission.reference] });

        // return true;

        // return true to use default pdf viewer component download of the pdf else return false we already ran download of the docx file
        // returning true will start downloading the pdf.
        return pdfOutput;
      },
    });

    // the result of the pdf modal. True if the call to action have been clicked and beforeValidation is resolved
    if (isSent) {
      // We can safely close the Order modal
      onResolve(true);
    }
  };
  React.useMemo(() => {
    //mission is mutable fix with jsonQ.clone
    setInitialServices(jsonQ.clone(mission?.additionalServices) || []);
  }, [mission?.reference]);

  return { mission, onGenerate, calculateBillingTotal, loading };
};
