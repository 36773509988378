import * as React from 'react';

function SvgPercentIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
      <path d="M8.18 11.4c.933 0 1.697-.283 2.29-.85s.89-1.35.89-2.35-.297-1.783-.89-2.35C9.877 5.283 9.113 5 8.18 5s-1.697.283-2.29.85S5 7.2 5 8.2s.297 1.783.89 2.35c.593.567 1.357.85 2.29.85zm1.18 7.94l7.86-14.16H14.5L6.64 19.34h2.72zM8.16 9.7c-.387 0-.693-.137-.92-.41-.227-.273-.34-.637-.34-1.09 0-.453.113-.817.34-1.09.227-.273.533-.41.92-.41s.7.137.94.41c.24.273.36.637.36 1.09 0 .453-.12.817-.36 1.09-.24.273-.553.41-.94.41zm7.54 9.82c.933 0 1.693-.283 2.28-.85.587-.567.88-1.35.88-2.35s-.293-1.783-.88-2.35c-.587-.567-1.347-.85-2.28-.85-.933 0-1.697.283-2.29.85s-.89 1.35-.89 2.35.297 1.783.89 2.35c.593.567 1.357.85 2.29.85zm-.02-1.68c-.387 0-.693-.137-.92-.41-.227-.273-.34-.643-.34-1.11 0-.467.113-.837.34-1.11.227-.273.533-.41.92-.41s.7.14.94.42c.24.28.36.647.36 1.1 0 .467-.12.837-.36 1.11-.24.273-.553.41-.94.41z" />
    </svg>
  );
}

export default SvgPercentIcon;
