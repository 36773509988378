import {
  ISageCreateContactParams,
  ISageCreateCompanyParams,
  ISageCreateContractorParams,
  ISageCreateDeliveryOrderParams,
  ISageCreateDeliveryOrderResponse,
  ISageCreateInvoiceParams,
  ISageCreateInvoiceResponse,
} from '@freelancelabs/teoreme-commons';
import { SAGE_SERVICE_URL } from '../../constantz';
import { fetcher } from '../helpers/fetcher';

export const sageCreateContact = (
  body: ISageCreateContactParams,
  disableAlert?: boolean
) =>
  fetcher<any>(
    SAGE_SERVICE_URL + `/create-contact`,
    {
      method: 'post',
      body,
    },
    disableAlert
  );

export const sageCreateEstablishment = (
  body: ISageCreateCompanyParams,
  disableAlert?: boolean
) =>
  fetcher<any>(
    SAGE_SERVICE_URL + `/create-company`,
    {
      method: 'post',
      body,
    },
    disableAlert
  );

export const sageCreateContractor = (
  body: ISageCreateContractorParams,
  disableAlert?: boolean
) =>
  fetcher<any>(
    SAGE_SERVICE_URL + `/create-contractor`,
    {
      method: 'post',
      body,
    },
    disableAlert
  );
//
export const sageCreateDeliveryOrders = (
  body: ISageCreateDeliveryOrderParams,
  disableAlert?: boolean
) =>
  fetcher<ISageCreateDeliveryOrderParams, ISageCreateDeliveryOrderResponse>(
    SAGE_SERVICE_URL + `/create-delivery-order`,
    {
      method: 'post',
      body,
    },
    disableAlert
  );
export const sageCreateInvoice = (
  body: ISageCreateInvoiceParams,
  disableAlert?: boolean
) =>
  fetcher<ISageCreateInvoiceParams, ISageCreateInvoiceResponse>(
    SAGE_SERVICE_URL + `/create-invoice`,
    {
      method: 'post',
      body,
    },
    disableAlert
  );
