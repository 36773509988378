import { getTradeNameSafe } from '@commons';
import {
  IEstablishment,
  IEstablishmentMinifiedUsers,
} from '@freelancelabs/teoreme-commons';
import { ALink } from 'components/ALink';
import { format } from 'date-fns';
import { useTheme } from 'styled-components';
import { Flex, Text } from './ui';

export const EstablishmentExistMessage = ({
  establishment,
  establishmentRole,
}: {
  establishment: Partial<IEstablishment | IEstablishmentMinifiedUsers>;
  establishmentRole: 'CUSTOMER' | 'PROVIDER' | undefined;
}) => {
  const theme = useTheme();
  const red = theme.colors.red;
  const closedSince =
    //@ts-ignore
    establishment?.closedSince || establishment?.details?.closedSince;
  if (closedSince) {
    return (
      <Flex>
        L’établissement {getTradeNameSafe(establishment)} est Fermé depuis le :{' '}
        {format(new Date(closedSince), 'dd/MM/yyyy')}
      </Flex>
    );
  }
  // if (establishment.address?.country !== 'France') {
  //   return (
  //     <Flex>
  //       Merci de renseigner un établissement ayant une adresse en France.
  //     </Flex>
  //   );
  // }
  if (establishment.customer && establishmentRole === 'CUSTOMER') {
    return (
      <Flex ml={10}>
        <Text variant={'p'} color={red}>
          Etablissement déjà existant&nbsp;
        </Text>
        <ALink
          href={`/clients/establishments/${establishment.uuid}/information`}
          color={red}
        >
          <Text variant={'u'} color={red}>
            Voir la fiche etablissement client
          </Text>
        </ALink>
      </Flex>
    );
  }
  if (establishment.provider && establishmentRole === 'PROVIDER') {
    return (
      <Flex ml={10}>
        <Text variant={'p'} color={red}>
          Etablissement déjà existant&nbsp;
        </Text>
        <ALink
          href={`/providers/establishments/${establishment.uuid}/information`}
          color={red}
        >
          <Text variant={'u'} color={red}>
            Voir la fiche etablissement fournisseur
          </Text>
        </ALink>
      </Flex>
    );
  }
  return <></>;
};
