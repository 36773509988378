import {
  IEstablishment,
  IAddress,
  EInvoicePaymentLabel,
  EInvoiceSendMethod,
  IContractorCreateParams,
} from '@freelancelabs/teoreme-commons';
import { ConfirmNavigationModal } from 'components/modals/ConfirmNavigationModal';
import { showAddContactModal } from 'components/modals/AddContactModal';
import {
  Flex,
  Box,
  Button,
  FormLabel,
  Link,
  Text,
  FormControl,
  Input,
  UserSelectControlled,
  TextAreaControlled,
  CheckSwitch,
  Radio,
  StaticSelectControlled,
  BlocInformation,
  Spinner,
  Status,
} from 'components/ui';
import { AddIcon, CheckIcon, CloseIcon } from 'components/ui/icons';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  COUNTRIES_SELECT,
  formatAllObject,
  getEstablishmentName,
  useEstablishmentCreateOne,
  useRegister,
  contractorCreateOne,
  getFullName,
  getTradeNameSafe,
} from '@commons';
import { useMe } from '@commons';
import { showAddContractorModal } from 'components/modals/AddContractorModal';
import { useShowMessage } from 'hooks/useShowMessage';
import { Theme } from 'styles';

const statusIcon = {
  UNDEFINED: <></>,
  WARNING: (
    <Box ml={10}>
      <Spinner size={15} color={Theme?.colors?.warning?.default} />
    </Box>
  ),
  SUCCESS: <CheckIcon fontSize={15} fill={Theme?.colors?.success?.default} />,
  ERROR: <CloseIcon fontSize={15} fill={Theme?.colors?.error?.default} />,
};
type AsynUpdateStatus = {
  start: boolean;
  contact: 'WARNING' | 'ERROR' | 'SUCCESS' | undefined;
  contractor: 'WARNING' | 'ERROR' | 'SUCCESS' | undefined;
  establishment: 'WARNING' | 'ERROR' | 'SUCCESS' | undefined;
  linkEstablishment: 'WARNING' | 'ERROR' | 'SUCCESS' | undefined;
};
type FormValues = Partial<IEstablishment> & {
  siret: string;
  address?: IAddress;
  customer?: {
    accountManager: string;
    contact: string;
    invoiceContact: any;
    internalCommercial: string;
  };
  provider?: {
    manager: string;
    isContractRequired: boolean;
  };
};
type AddEstablishmentFormProps = {
  establishmentRole?: 'PROVIDER' | 'CUSTOMER';
  defaultValues?: Partial<IEstablishment>;
  onSubmit?: (formValues: FormValues) => any | void;
  isDisabled?: boolean;
  noContactRequired?: boolean;
  buttonHidden?: boolean;
  onResolve?: (data: any) => void;
};

export const AddEstablishmentForm = ({
  establishmentRole,
  defaultValues,
  onSubmit: onSubmitParent,
  isDisabled,
  noContactRequired,
  buttonHidden,
  onResolve,
}: AddEstablishmentFormProps) => {
  const [formError, setFormError] = useState(false);
  const [asyncUpdatesStatus, setAsyncUpdatesStatus] =
    useState<AsynUpdateStatus>({
      start: false,
      contact: undefined,
      contractor: undefined,
      establishment: undefined,
      linkEstablishment: undefined,
    });
  const showMessage = useShowMessage();
  const [loading, setLoading] = useState(false);
  const [createWithContractor, setCreatWithContractor] = useState<
    IContractorCreateParams | undefined
  >();
  const {
    watch,
    handleSubmit,
    control,
    register,
    setValue,
    setError,
    getValues,
    formState: { errors, isDirty, isSubmitSuccessful },
  } = useForm<FormValues>({
    defaultValues: {
      ...defaultValues,
      provider:
        establishmentRole === 'PROVIDER'
          ? { isContractRequired: true }
          : undefined,
    },
  });
  const isAutoSendEmail = watch('customer.billingOptions.isAutoSendEmail');
  const isAutoBilling = watch('customer.billingOptions.isAutoBilling');
  //const internalCommercial = watch('customer.internalCommercial');
  const shouldJoinProofToInvoice = watch(
    'customer.billingOptions.shouldJoinProofToInvoice'
  );
  const isContractRequired = watch('provider.isContractRequired');
  const shouldMergeProofWithInvoice = watch(
    'customer.billingOptions.shouldMergeProofWithInvoice'
  );
  const providerManager = watch('provider.manager');
  const onSubmit = async (data: FormValues) => {
    const shouldMergeProofWithInvoice =
      //@ts-ignore
      data?.customer?.billingOptions?.shouldMergeProofWithInvoice;
    setLoading(true);
    let isValid = true;
    if (establishmentRole === 'CUSTOMER' && !data?.customer?.contact) {
      isValid = false;
      setError('customer.contact', { message: 'Ce champ est requis' });
    }
    if (establishmentRole === 'CUSTOMER' && !data?.customer?.accountManager) {
      isValid = false;
      setError('customer.accountManager', { message: 'Ce champ est requis' });
    }
    if (
      establishmentRole === 'CUSTOMER' &&
      !data?.customer?.internalCommercial
    ) {
      isValid = false;
      setError('customer.internalCommercial', {
        message: 'Ce champ est requis',
      });
    }

    if (
      !noContactRequired &&
      establishmentRole === 'PROVIDER' &&
      !data?.provider?.manager
    ) {
      isValid = false;
      setError('provider.manager', { message: 'Ce champ est requis' });
    }
    if (isValid) {
      setError('customer.contact', { message: undefined });
      const customerData: any = data?.customer;
      if (establishmentRole === 'CUSTOMER') {
        const billingOptions = {
          ...customerData.billingOptions,
          paymentLabel: EInvoicePaymentLabel.FIXED,
          shouldMergeProofWithInvoice: shouldJoinProofToInvoice
            ? shouldMergeProofWithInvoice
            : false,
        };
        customerData.billingOptions = billingOptions;
      }
      if (createWithContractor && establishmentRole === 'PROVIDER') {
        await handleCreateEstablishmentAndContactContractor(
          createWithContractor
        );
      } else {
        onSubmitParent &&
          (await onSubmitParent({
            //...data,
            siret: defaultValues?.siret || '',
            vatNumber: data?.vatNumber || undefined,
            address: {
              ...defaultValues?.address,
              ...data?.address,
            },
            customer:
              establishmentRole === 'CUSTOMER' ? customerData : undefined,
            provider:
              establishmentRole === 'PROVIDER' ? data?.provider : undefined,
          }));
      }
    }

    setLoading(false);
  };

  const { me } = useMe();

  const checkAddressIsComplete = () => {
    let isValide = true;
    if (!defaultValues?.address?.city) {
      isValide = false;
    }
    if (!defaultValues?.address?.country) {
      isValide = false;
    }
    if (!defaultValues?.address?.postalCode) {
      isValide = false;
    }
    if (!defaultValues?.address?.street) {
      isValide = false;
    }
    return isValide;
  };
  const { mutateAsync: addContact } = useRegister();
  const { mutateAsync: addEstablishment } = useEstablishmentCreateOne();
  const handleCreateEstablishmentAndContactContractor = async (
    contractor: IContractorCreateParams
  ) => {
    let _asyncUpdatesStatus = asyncUpdatesStatus;
    setAsyncUpdatesStatus({ ...asyncUpdatesStatus, start: true });
    const contact = {
      email: contractor?.email,
      civility: contractor?.contractor?.civility,
      firstName: contractor?.contractor?.firstName,
      lastName: contractor?.contractor?.lastName,
      phone: contractor?.contractor?.phone,
      jobTitle: contractor?.contractor?.jobTitle,
    };
    try {
      _asyncUpdatesStatus.contact = 'WARNING';
      setAsyncUpdatesStatus(_asyncUpdatesStatus);
      //step 1 :  create contact
      const providerContact = await addContact({
        user: formatAllObject(contact),
        roles: 'PROVIDER_CONTACT',
      });
      _asyncUpdatesStatus.contact = 'SUCCESS';
      setAsyncUpdatesStatus(_asyncUpdatesStatus);
      const data = getValues();
      _asyncUpdatesStatus.establishment = 'WARNING';
      setAsyncUpdatesStatus(_asyncUpdatesStatus);
      // step 2 : create establishment
      const providerEstablishment = await addEstablishment({
        //...data,
        siret: defaultValues?.siret || '',
        vatNumber: data?.vatNumber || undefined,
        address: {
          ...defaultValues?.address,
          ...data?.address,
        },
        provider: {
          ...data?.provider,
          manager: providerContact?.cognitoUserId,
        },
      });
      _asyncUpdatesStatus.establishment = 'SUCCESS';
      setAsyncUpdatesStatus(_asyncUpdatesStatus);
      // step 3 :  create contractor
      _asyncUpdatesStatus.contractor = 'WARNING';
      setAsyncUpdatesStatus(_asyncUpdatesStatus);
      const newContractor = await contractorCreateOne({
        ...formatAllObject(contractor),
        establishment: providerEstablishment?.uuid,
      });
      _asyncUpdatesStatus.contractor = 'SUCCESS';
      setAsyncUpdatesStatus(_asyncUpdatesStatus);
      if (onResolve) {
        showMessage(
          'success',
          `Le contact ${getFullName(
            providerContact
          )} , l'intervenant ${getFullName(
            newContractor
          )} et l'établissement ${getTradeNameSafe(
            providerEstablishment
          )} ont bien été créés`
        );
        onResolve(providerEstablishment);
      }
    } catch (e) {
      let statusOnError;
      Object?.keys(_asyncUpdatesStatus)?.forEach(k => {
        //@ts-ignore
        let checkStatus = _asyncUpdatesStatus[k];
        if (checkStatus === 'WARNING') {
          statusOnError = k;
        }
      });
      if (statusOnError) {
        setAsyncUpdatesStatus({
          ..._asyncUpdatesStatus,
          [statusOnError]: 'ERROR',
        });
        setFormError(true);
      }
    }
  };

  useEffect(() => {
    if (createWithContractor && typeof providerManager === 'string') {
      setCreatWithContractor(undefined);
    }
  }, [providerManager, createWithContractor]);
  const sendMethod = watch('customer.billingOptions.sendMethod');
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <ConfirmNavigationModal active={isDirty && !isSubmitSuccessful} />
      <Box mb={10}>
        <FormLabel>Raison Sociale</FormLabel>
        {defaultValues && (
          <Text variant="p">{getEstablishmentName(defaultValues)}</Text>
        )}
      </Box>
      {checkAddressIsComplete() ? (
        <>
          <Box mb={10}>
            <FormLabel>Adresse de l'établissement</FormLabel>
            <Text variant="p">
              {defaultValues?.address?.street} {defaultValues?.address?.city}{' '}
              {defaultValues?.address?.postalCode}
              {', '}
              {defaultValues?.address?.country}
            </Text>
          </Box>
          {defaultValues?.address?.country &&
            defaultValues?.address?.country?.toLocaleUpperCase() !==
              'FRANCE' && (
              <Box>
                <FormControl
                  required
                  label="Numero de TVA intracommunautaire"
                  errorMessage={errors?.identifier?.message}
                >
                  <Input
                    isFullWidth
                    {...register('vatNumber', {
                      required: 'Ce champs est requis',
                    })}
                  />
                </FormControl>
              </Box>
            )}
        </>
      ) : (
        <Box>
          <Box>
            <FormControl
              label={'Adresse'}
              // @ts-ignore
              errorMessage={errors?.address?.street?.message}
              required
            >
              <Input
                data-cy="errors?.address.street.message"
                isFullWidth
                {...register('address.street', {
                  required: 'Ce champ est requis',
                })}
                type="text"
              />
            </FormControl>
          </Box>
          <Flex>
            <Box width={3 / 12}>
              <FormControl
                label={'Code postal'}
                // @ts-ignore
                errorMessage={errors?.address?.postalCode?.message}
                required
              >
                <Input
                  data-cy="errors?.address.postalCode.message"
                  isFullWidth
                  {...register('address.postalCode', {
                    required: 'Ce champ est requis',
                  })}
                  type="text"
                />
              </FormControl>
            </Box>
            <Box width={3 / 12} />
            <Box width={6 / 12}>
              <FormControl
                label={'Ville'}
                // @ts-ignore
                errorMessage={errors?.address?.city?.message}
                required
              >
                <Input
                  data-cy="errors?.address.city.message"
                  isFullWidth
                  {...register('address.city', {
                    required: 'Ce champ est requis',
                  })}
                  type="text"
                />
              </FormControl>
            </Box>
          </Flex>
          <Box>
            <FormControl
              label={'Pays'}
              // @ts-ignore
              errorMessage={errors?.address?.country?.message}
              required
            >
              <StaticSelectControlled
                control={control}
                options={COUNTRIES_SELECT}
                name="address.country"
                placeholder="Pays"
                required
              />
            </FormControl>
          </Box>
        </Box>
      )}
      {/* {establishmentRole === 'PROVIDER' && !isDisabled && (
        <Box>
          <FormControl label="Contrat-cadre">
            <CheckSwitch
              id="isContractRequired"
              {...register('provider.isContractRequired')}
            >
              <Text variant="span">
                {!isContractRequired
                  ? "La signature d'un contrat cadre n'est pas obligatoire pour pouvoir valider les missions de cet établissement"
                  : "La signature d'un contrat cadre est obligatoire pour pouvoir valider les missions de cet établissement"}
              </Text>
            </CheckSwitch>
          </FormControl>
        </Box>
      )} */}
      {establishmentRole === 'PROVIDER' &&
        !isDisabled &&
        !noContactRequired && (
          <Box mb={20}>
            <Text variant="h2" mb={20}>
              Rattacher un contact fournisseur
            </Text>
            <Text variant="p" mb={20}>
              Le contact fournisseur que vous allez rattacher au nouvel
              établissement fournisseur deviendra le responsable de compte de ce
              dernier.
            </Text>
            <Box mb={2}>
              <UserSelectControlled
                disableCall={createWithContractor}
                addedData={
                  createWithContractor
                    ? [
                        {
                          label: `${createWithContractor?.contractor?.firstName} ${createWithContractor?.contractor?.lastName} ${createWithContractor?.contractor?.email}`,
                          value: 'contactAndContractor',
                          data: {
                            cognitoUserId: 'contactAndContractor',
                            firstName:
                              createWithContractor?.contractor?.firstName,
                            lastName:
                              createWithContractor?.contractor?.lastName,
                            email: createWithContractor?.contractor?.email,
                            roles: [{ name: 'PROVIDER_CONTACT' }],
                          },
                        },
                      ]
                    : undefined
                }
                id="provider-contact-select"
                control={control}
                placeholder="Tous les contacts"
                role={'PROVIDER_CONTACT'}
                name="provider.manager"
              />
              {errors?.provider?.manager?.message && (
                <FormControl
                  label=""
                  errorMessage={errors?.provider?.manager?.message}
                ></FormControl>
              )}
            </Box>
            <Link
              width={1 / 1}
              iconLeft={<AddIcon />}
              onClick={() =>
                showAddContactModal({
                  contactRole: 'PROVIDER_CONTACT',
                }).then(c => {
                  c.cognitoUserId &&
                    setValue('provider.manager', c.cognitoUserId);
                })
              }
            >
              Ajouter un contact Fournisseur
            </Link>
            <Link
              iconLeft={<AddIcon />}
              onClick={() =>
                showAddContractorModal({
                  contactIsContractor: true,
                  noEstablishment: true,
                  onSubmit: async contractor => {
                    setCreatWithContractor(contractor);
                    //@ts-ignore
                    setValue('provider.manager', contractor);
                    // await handleCreateEstablishmentAndContactContractor(
                    //   contractor
                    // );
                  },
                })
              }
            >
              Ajouter un contact Fournisseur également intervenant
            </Link>
            {createWithContractor && (
              <BlocInformation mt={10}>
                <p>
                  Un <b>nouveau contact fournisseur ainsi que l'intervenant </b>
                  seront créés à partir des informations fournies dans le
                  formulaire.
                </p>
              </BlocInformation>
            )}
          </Box>
        )}
      {establishmentRole === 'CUSTOMER' && !isDisabled && (
        <>
          <Text variant="h3" mb={15} mt={10}>
            Contact de facturation
          </Text>
          <FormControl
            label={'Email'}
            // @ts-ignore
            errorMessage={errors?.customer?.invoiceContact?.email?.message}
            required
          >
            <Input
              data-cy="establishment-customer-invoiceContact-email"
              isFullWidth
              {...register('customer.invoiceContact.email', {
                required: 'Ce champ est requis',
              })}
              type="email"
            />
          </FormControl>
          <FormControl
            required
            label={'Mode de distribution'}
            errorMessage={errors?.customer?.billingOptions?.sendMethod?.message}
          >
            <>
              <Radio
                {...register('customer.billingOptions.sendMethod', {
                  required: 'Ce champ est requis',
                })}
                value={EInvoiceSendMethod?.EMAIL}
              >
                Email
              </Radio>
              <Radio
                {...register('customer.billingOptions.sendMethod', {
                  required: 'Ce champ est requis',
                })}
                value={EInvoiceSendMethod?.OTHER}
              >
                Autre ( plateforme , fax etc .... )
              </Radio>
            </>
          </FormControl>
          <FormControl
            label={'Facturer automatiquement le client'}
            errorMessage={
              //@ts-ignore
              errors?.customer?.billingOptions?.isAutoBilling?.message
            }
            required
          >
            <CheckSwitch
              id="isAutoBilling"
              {...register(
                //@ts-ignore
                'customer.billingOptions.isAutoBilling'
              )}
              onChange={e => {
                setValue(
                  //@ts-ignore
                  'customer.billingOptions.isAutoBilling',
                  // @ts-ignore
                  e.target.checked
                );
                return e.target.checked;
              }}
            >
              <Text variant="p">
                {isAutoBilling
                  ? 'Facturer automatiquement le client'
                  : 'Ne pas facturer automatiquement le client'}
              </Text>
            </CheckSwitch>
          </FormControl>
          {sendMethod === EInvoiceSendMethod.EMAIL && (
            <FormControl
              label={
                'Envoyer automatiquement les factures client à cette adresse'
              }
              errorMessage={
                errors?.customer?.billingOptions?.isAutoSendEmail?.message
              }
              required
            >
              <CheckSwitch
                id="isAutoSendEmail"
                {...register('customer.billingOptions.isAutoSendEmail')}
                onChange={e => {
                  setValue(
                    'customer.billingOptions.isAutoSendEmail',
                    e.target.checked
                  );
                  return e.target.checked;
                }}
              >
                <Text variant="p">
                  {isAutoSendEmail
                    ? 'Envoyer automatiquement'
                    : 'Ne pas envoyer automatiquement'}
                </Text>
              </CheckSwitch>
            </FormControl>
          )}
          {isAutoBilling && sendMethod === EInvoiceSendMethod.EMAIL && (
            <>
              <FormControl
                label={'Joindre les justificatifs à la facture client'}
                errorMessage={
                  errors?.customer?.billingOptions?.shouldJoinProofToInvoice
                    ?.message
                }
                required
              >
                <CheckSwitch
                  id="shouldJoinProofToInvoice"
                  {...register(
                    'customer.billingOptions.shouldJoinProofToInvoice'
                  )}
                  onChange={e => {
                    setValue(
                      'customer.billingOptions.shouldJoinProofToInvoice',
                      e.target.checked
                    );
                    return e.target.checked;
                  }}
                >
                  <Text variant="p">
                    {shouldJoinProofToInvoice
                      ? 'Joindre les justificatifs à la facture client'
                      : 'Ne pas Joindre les justificatifs à la facture client'}
                  </Text>
                </CheckSwitch>
              </FormControl>
              {shouldJoinProofToInvoice && (
                <FormControl
                  required
                  label={'Fusionner les justificatifs avec la facture'}
                  errorMessage={
                    errors?.customer?.billingOptions
                      ?.shouldMergeProofWithInvoice?.message
                  }
                >
                  <CheckSwitch
                    id="shouldMergeProofWithInvoice"
                    {...register(
                      'customer.billingOptions.shouldMergeProofWithInvoice'
                    )}
                  >
                    <Text variant="p">
                      {shouldMergeProofWithInvoice
                        ? 'Fusionner les justificatifs avec la facture'
                        : ' Séparer les justificatifs et la facture'}
                    </Text>
                  </CheckSwitch>
                </FormControl>
              )}
            </>
          )}
          {/* <FormControl
            required
            label="Modalité de paiement"
            errorMessage={
              errors?.customer?.billingOptions?.paymentLabel?.message
            }
          >
            <StaticSelectControlled
              isClearable={false}
              control={control}
              name="customer.billingOptions.paymentLabel"
              options={[
                {
                  label: `Fin de mois`,
                  value: EInvoicePaymentLabel?.END_OF_MONTH,
                },
                {
                  label: `Date d'émission la facture`,
                  value: EInvoicePaymentLabel?.FIXED,
                },
              ]}
              rules={{ required: 'Ce champ est requis' }}
              placeholder=""
            />
          </FormControl> */}
          <FormControl label={'Commentaire'}>
            <TextAreaControlled
              control={control}
              minRows={3}
              isFullWidth
              data-cy="establishment-customer-invoiceContact-comment"
              name="customer.invoiceContact.comment"
            />
          </FormControl>

          <Text variant="h3" mb={15}>
            Gestion des rattachements
          </Text>
          <FormControl
            label={'Contact client'}
            required
            errorMessage={errors?.customer?.contact?.message}
          >
            <>
              <UserSelectControlled
                control={control}
                id="customer-contact-select"
                placeholder="Tous les contacts"
                role={'CUSTOMER'}
                name="customer.contact"
              />
              <Link
                iconLeft={<AddIcon />}
                onClick={() =>
                  showAddContactModal({
                    contactRole: 'CUSTOMER',
                  }).then(c => {
                    c.cognitoUserId &&
                      setValue('customer.contact', c.cognitoUserId);
                  })
                }
                mt={2}
              >
                Ajouter un contact client
              </Link>
            </>
          </FormControl>
          <FormControl
            label={'Chargé de compte'}
            required
            errorMessage={errors?.customer?.accountManager?.message}
          >
            <UserSelectControlled
              id="manager-contact-select"
              control={control}
              defaultValue={me?.cognitoUserId}
              placeholder="Tous les chargés de compte"
              role={'ACCOUNT_MANAGER'}
              name="customer.accountManager"
            />
          </FormControl>
          <FormControl
            label={'Sélectionner le commercial interne'}
            required
            errorMessage={errors?.customer?.internalCommercial?.message}
          >
            <Box>
              <UserSelectControlled
                control={control}
                name="customer.internalCommercial"
                role={'INTERNAL_COMMERCIAL'}
              />
            </Box>
          </FormControl>
        </>
      )}
      <Box>
        {createWithContractor && (
          <>
            <Status
              //@ts-ignore
              variantColor={
                asyncUpdatesStatus?.contact
                  ? asyncUpdatesStatus?.contact.toLocaleLowerCase()
                  : 'grey'
              }
            >
              Création du contact
              {statusIcon[asyncUpdatesStatus?.contact || 'UNDEFINED']}
            </Status>
            <Status
              //@ts-ignore
              variantColor={
                asyncUpdatesStatus?.establishment
                  ? asyncUpdatesStatus?.establishment.toLocaleLowerCase()
                  : 'grey'
              }
            >
              Création de l'établissement
              {statusIcon[asyncUpdatesStatus?.establishment || 'UNDEFINED']}
            </Status>
            {/* <Status
              //@ts-ignore
              variantColor={
                asyncUpdatesStatus?.linkEstablishment
                  ? asyncUpdatesStatus?.linkEstablishment.toLocaleLowerCase()
                  : 'grey'
              }
            >
              Rattachement à l'établissement
              {statusIcon[asyncUpdatesStatus?.linkEstablishment || 'UNDEFINED']}
            </Status> */}
            <Status
              //@ts-ignore
              variantColor={
                asyncUpdatesStatus?.contractor
                  ? asyncUpdatesStatus?.contractor.toLocaleLowerCase()
                  : 'grey'
              }
            >
              Création de l'intervenant{' '}
              {statusIcon[asyncUpdatesStatus?.contractor || 'UNDEFINED']}
            </Status>
          </>
        )}
      </Box>
      <Box hidden={buttonHidden}>
        <Button
          data-cy="submit-establishment"
          key="submit"
          type="submit"
          isLoading={loading}
          disabled={isDisabled || false}
          isDisabled={isDisabled || false || formError}
        >
          Enregistrer
        </Button>
      </Box>
    </form>
  );
};
