import * as React from 'react';

function SvgIsSend(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="200"
      height="200"
      viewBox="0 0 200 200"
    >
      <g fill="none" fillRule="evenodd">
        <g fillRule="nonzero">
          <g>
            <g>
              <path
                fill="#EFE9E3"
                d="M171.682 62.847C152.402 47.897 124.6 39.954 100.244 40c-20.705.046-41.649 6.39-57.803 19.335-16.043 12.863-32.354 37.917-26.056 59.229 4.496 15.216 16.789 27.389 31.205 34.018 19.887 9.139 44.389 9.36 65.508 5.084 17.34-3.512 33.439-9.553 47.56-20.65 10.142-7.971 18.554-18.544 22.315-30.892 4.597-15.115 1.6-33.283-11.29-43.277z"
                transform="translate(-135 -40) translate(50 40) translate(85)"
              />
              <g stroke="#004269" strokeWidth=".75">
                <path
                  fill="#004269"
                  strokeLinejoin="round"
                  d="M92.938 90L68.038 110.823 68.668 90z"
                  transform="translate(-135 -40) translate(50 40) translate(85) translate(10 45)"
                />
                <path
                  fill="#1BC773"
                  strokeLinejoin="round"
                  d="M150 0L68.668 89.601 68 111 42.927 90z"
                  transform="translate(-135 -40) translate(50 40) translate(85) translate(10 45)"
                />
                <path
                  fill="#1BC773"
                  d="M150 0L42.927 90H1.032c-1.051 0-1.427-1.399-.52-1.933L150 0z"
                  transform="translate(-135 -40) translate(50 40) translate(85) translate(10 45)"
                />
                <path
                  fill="#1BC773"
                  strokeLinejoin="round"
                  d="M150 0l-19.672 88.881c-.145.655-.719 1.119-1.38 1.119l-60.28-.399L150 0z"
                  transform="translate(-135 -40) translate(50 40) translate(85) translate(10 45)"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default SvgIsSend;
