import * as React from 'react';
import { Theme } from 'styles';

function SvgCalendarIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      viewBox="0 0 24 25"
    >
      <path
        fill={Theme.colors.grey.default}
        fillRule="evenodd"
        d="M18.222 5.684h-.889V4.421c0-.232-.199-.421-.444-.421H16c-.245 0-.444.189-.444.421v1.263H8.444V4.421C8.444 4.19 8.245 4 8 4h-.889c-.245 0-.444.189-.444.421v1.263h-.89C4.797 5.684 4 6.438 4 7.368v10.948C4 19.246 4.796 20 5.778 20h12.444c.982 0 1.778-.754 1.778-1.684V7.368c0-.93-.796-1.684-1.778-1.684zm0 12.632H5.778V9.053h12.444v9.263zm-6.666-5.895h.888c.246 0 .445-.188.445-.421v-.842c0-.233-.2-.421-.445-.421h-.888c-.246 0-.445.188-.445.42V12c0 .233.2.421.445.421zm3.555 0H16c.245 0 .444-.188.444-.421v-.842c0-.233-.199-.421-.444-.421h-.889c-.245 0-.444.188-.444.42V12c0 .233.199.421.444.421zm-7.111 0h.889c.245 0 .444-.188.444-.421v-.842c0-.233-.199-.421-.444-.421H8c-.245 0-.444.188-.444.42V12c0 .233.199.421.444.421zm3.556 3.368h.888c.246 0 .445-.188.445-.42v-.843c0-.232-.2-.42-.445-.42h-.888c-.246 0-.445.188-.445.42v.842c0 .233.2.421.445.421zM8 15.79h.889c.245 0 .444-.188.444-.42v-.843c0-.232-.199-.42-.444-.42H8c-.245 0-.444.188-.444.42v.842c0 .233.199.421.444.421z"
      />
    </svg>
  );
}

export default SvgCalendarIcon;
