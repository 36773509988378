import {
  Box,
  FormControl,
  Input,
  Text,
  Flex,
  Button,
  DatePickerControlled,
} from 'components/ui';
import { missionUpdateOne, removeOffsetDate } from '@commons';
import { queryClient } from '@commons';
import { useForm } from 'react-hook-form';
import React, { useState } from 'react';
import { create } from 'react-modal-promise';
import { ModalFrame, ModalProps } from '../ModalFrame';
type FormValues = {
  startAt: Date;
  nbDays: number;
};
type AddPaymentDeadlinesModalProps = ModalProps & {
  beforeValidation?: () => void;
  mission: any;
};

export const AddPaymentDeadlinesModal: React.FC<
  React.PropsWithChildren<AddPaymentDeadlinesModalProps>
> = ({ onResolve, isOpen, mission }) => {
  const [loading, setLoading] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isSend, setIsSend] = useState(false);
  const {
    control,
    watch,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {
      //startAt: new Date(),
      nbDays: 60,
    },
  });
  const startAt = watch('startAt');
  const onSubmit = async (formValues: FormValues) => {
    setLoading(true);
    try {
      const submitData = {
        reference: mission.reference,
        mission: {
          provider: {
            paymentDeadline: {
              startAt: removeOffsetDate(formValues.startAt),
              nbDays: formValues.nbDays,
            },
          },
        },
      };
      await missionUpdateOne(submitData);
      queryClient.refetchQueries({ queryKey: [mission.reference] });
      queryClient.refetchQueries({ queryKey: ['missions'], type: 'active' });
    } catch (e) {
      //console.log('e', e);
    }
    setIsSend(true);
    setLoading(false);
    onResolve();
  };

  return (
    <ModalFrame
      isOpen={isOpen}
      onClose={() => onResolve(false)}
      width={470}
      closeIcon
    >
      <Box>
        <Text variant="h2" mb={20}>
          Modifier l’échéance
        </Text>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Flex alignContent="space-between">
            <FormControl
              label="A parti de"
              required
              errorMessage={errors?.startAt?.message}
            >
              <Box width={8 / 10}>
                <DatePickerControlled
                  control={control}
                  dateFormat="MM/yyyy"
                  showMonthYearPicker
                  showFullMonthYearPicker
                  selected={startAt as Date}
                  rules={{ required: 'Ce champ est requis' }}
                  name="startAt"
                />
              </Box>
            </FormControl>
            <FormControl
              label="Nombre de jours nets"
              required
              errorMessage={errors?.nbDays?.message}
            >
              <Box width={8 / 10}>
                <Input
                  type="number"
                  step="0"
                  isFullWidth
                  {...register('nbDays', {
                    required: 'Ce champs est requis',
                  })}
                />
              </Box>
            </FormControl>
          </Flex>
          <Box>
            <Button key="submit" type="submit" isLoading={loading}>
              Modifier
            </Button>
          </Box>
        </form>
      </Box>
    </ModalFrame>
  );
};

export const showAddPaymentDeadlinesModal =
  create<AddPaymentDeadlinesModalProps>(AddPaymentDeadlinesModal);
