import {
  CREATED_SF_STATUS,
  DISPLAY_SF_STATUS,
  FR_STATUS,
  NOT_CREATED_SF_STATUS,
  checkAllStrcutureCreatedInGescomForContractor,
  checkGescomAvalaibleMissionCustomer,
  getFullName,
  getSructure,
  isDisableFONEDate,
  missionFindMany,
  round,
  saleforceGetLink,
  useBilledInvoicesStats,
  useCommentFindMany,
  useContractFindMany,
} from '@commons';
import {
  EBillingType,
  EContractState,
  EContractType,
  EMissionStatus,
  EMissionStructure,
  EPurchaseOrderStatus,
  IJoinedMission,
  IMission,
} from '@freelancelabs/teoreme-commons';
import { Header } from 'components/Header';
import { SideBar } from 'components/SideBar';
import { SideLink } from 'components/SideLink';
import { CardAmountInfo } from 'components/cards/CardAmountInfo';
import { showAddMissionCustomerModal } from 'components/modals/AddMissionCustomerModal';
import { showDialogModal } from 'components/modals/DialogModal';
import { showCreateMissionAmendmentModal } from 'components/modals/missions/CreateMissionAmendmentModal';
import { showDuplicateMissionModal } from 'components/modals/missions/DuplicateMissionModal';
import { showEndMissionModal } from 'components/modals/missions/EndMissionModal';
import { showUpdateMissionManagerModal } from 'components/modals/missions/UpdateMissionManagerModal';
import { showSyncSaleforceMissionModal } from 'components/modals/missions/SyncSaleforceMissionModal';
import {
  Box,
  Button,
  Container,
  Flex,
  Link,
  Separator,
  Spinner,
  SpinnerBox,
  Status,
  Text,
} from 'components/ui';
import {
  AddIcon,
  ArchiveIcon,
  CheckIcon,
  CloseIcon,
  DeleteIcon,
  DuplicateIcon,
  ExclamationCircleIcon,
  RepeatIcon,
  TerminateMissionIcon,
} from 'components/ui/icons';
import { useGetMission, useMissionActions } from 'hooks/missionHooks';
import * as React from 'react';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import PageNotFound from 'routes/NotFound';
import { useTheme } from 'styled-components';
import { Theme } from 'styles';
import { AmendmentList } from './AmendmentList';
import { Client } from './Client';
import { Comments } from './Comments';
import { GescomMission } from './Gescom';
import { Information } from './Information';
import { InvoicingOptions } from './InvoicingOptions';
import { MissionAdditionalActivity } from './MissionAdditionalActivity';
import { MissionCra } from './MissionCra/index';
import { MissionCreditNote } from './MissionCreditNote';
import { MissionInvoice } from './MissionInvoice';
import { MissionMileStones } from './MissionMilestones';
import { PricingMission } from './PricingMission';
import { Provider } from './Provider';
import { useGlobalStore } from 'store/global';

export const MissionsDetails = () => {
  const history = useHistory();
  const theme = useTheme();
  const { tasksInProgress } = useGlobalStore();

  const {
    isNew,
    loading: getLoading,
    mission,
    reference,
    status: statuGet,
  } = useGetMission();

  const { data: contractsQuery } = useContractFindMany({
    filterObject: mission?.provider?.establishment?.uuid
      ? {
          uuid: {
            $in: [
              ...(mission?.provider?.establishment?.provider
                ?.contracts as string[]),
            ],
          },
          state: EContractState.DONE,
        }
      : undefined,
  });

  const missionActions = useMissionActions(mission, contractsQuery?.contracts);
  const loading = missionActions?.loading || getLoading;
  //@ts-ignore
  const { data } = useCommentFindMany(
    mission?.comment
      ? {
          filterObject: mission && { parentServiceUuid: mission?.comment },
          sort: '',
          limit: 1,
          skip: 0,
        }
      : undefined
  );
  const { data: billedInvoicesStats } = useBilledInvoicesStats(reference);
  const [amendments, setAmendments] = React.useState<
    Array<IJoinedMission> | []
  >([]);

  const [latestAmendment, setLatestAmendment] = React.useState<
    IMission | IJoinedMission | undefined
  >(undefined);
  const [isFetchingValidateMission, setIsFetchingValidateMission] =
    React.useState(false);
  const isDayBilling = mission?.billing.type === EBillingType.DAY;
  const missionBilling = mission?.billing ?? {};
  const {
    estimatedDays = 0,
    clientRate = 0,
    contractorRate = 0,
    estimatedPurchaseBudget = 0,
    estimatedSaleBudget = 0,
  } = missionBilling;

  const hasOnGoingSalforceRefreshTasks = tasksInProgress?.includes(
    'refresh-salesforce' + mission?.reference
  );

  const getOrderedCustomerStats = () => {
    const estimatedCustomerAmount = estimatedDays * clientRate;
    if (!missionBilling) return 0;
    if (isDayBilling)
      return `${estimatedDays} jrs / ${round(estimatedCustomerAmount)} €`;
    return `${round(estimatedSaleBudget)} €`;
  };

  const getOrderedProviderStats = () => {
    const estimatedProviderAmount = estimatedDays * contractorRate;
    if (!missionBilling) return 0;
    if (isDayBilling)
      return `${estimatedDays} jrs / ${round(estimatedProviderAmount)} €`;
    return `${round(estimatedPurchaseBudget)} €`;
  };

  const billedCustomerInvoices = () => {
    const { customerInvoices } = billedInvoicesStats ?? {};
    const workingDays = round(customerInvoices?.nbWorkingDays ?? 0);
    const totalBeforeTaxes = round(customerInvoices?.totalBeforeTaxes ?? 0);

    if (isDayBilling)
      return `${workingDays} jrs /
        ${totalBeforeTaxes} €`;
    return `${totalBeforeTaxes} €`;
  };

  const billedProviderInvoices = () => {
    const { providerInvoices } = billedInvoicesStats ?? {};
    const workingDays = round(providerInvoices?.nbWorkingDays ?? 0);
    const totalBeforeTaxes = round(providerInvoices?.totalBeforeTaxes ?? 0);
    if (isDayBilling)
      return `${workingDays} jrs /
        ${totalBeforeTaxes} €`;
    return `${totalBeforeTaxes} €`;
  };

  const remainingProviderInvoicing = () => {
    const { providerInvoices } = billedInvoicesStats ?? {};
    const remainingDays =
      estimatedDays - (providerInvoices?.nbWorkingDays ?? 0);

    const remainingAmount = round(
      estimatedPurchaseBudget - (providerInvoices?.totalBeforeTaxes ?? 0)
    );
    if (isDayBilling)
      return `${remainingDays ?? 0} jrs /
        ${remainingAmount ?? 0} €`;
    return `${remainingAmount ?? 0} €`;
  };

  const remainingCustomerInvoicing = () => {
    const { customerInvoices } = billedInvoicesStats ?? {};
    const remainingDays =
      estimatedDays - (customerInvoices?.nbWorkingDays ?? 0);

    const remainingAmount = round(
      estimatedSaleBudget - (customerInvoices?.totalBeforeTaxes ?? 0)
    );
    if (isDayBilling)
      return `${remainingDays ?? 0} jrs /
        ${remainingAmount ?? 0} €`;
    return `${remainingAmount ?? 0} €`;
  };

  const findAmendment = async (mission: IJoinedMission) => {
    const $or: any = [
      { parent: mission?.parent?.reference || mission?.reference },
    ];
    if (mission?.parent?.reference) {
      $or?.push({ reference: mission?.parent?.reference });
    }
    const allLinkedMissions = await missionFindMany({
      filterObject: { $or: $or },
      sort: '-startAt',
    });
    const missions = allLinkedMissions.missions;

    // can be amendment or main mission if no amendments
    const latestMission = missions[0];
    setLatestAmendment(latestMission);
    setAmendments(missions);
  };
  const title = isNew
    ? 'Nouvelle mission'
    : mission
      ? `${
          mission.status === EMissionStatus.DRAFT ? '' : 'Réf.'
        } ${mission?.displayReference}`
      : `-`;

  const onValidateMission = async () => {
    setIsFetchingValidateMission(true);
    return missionActions
      ?.onValidateMission()
      .then(
        c => c && history.push(`/delivery/missions/${reference}/information`)
      )
      ?.finally(() => setIsFetchingValidateMission(false));
  };

  const onDeleteMission = () =>
    missionActions
      ?.onDeleteMission()
      .then(c => c && history.push(`/delivery/missions/draft`));

  const onDisableMission = () =>
    missionActions
      ?.onDisableMission()
      .then(c => c && history.push(`/delivery/missions/draft`));

  // const onReactivateMission = () =>
  //   missionActions
  //     ?.onReactivateMission()
  //     .then(() => history.push(`/delivery/missions/${reference}/information`));
  //const isAmendment = mission?.parent || mission?.children;
  const parent = mission?.parent?.displayReference;
  const refParent = mission?.parent?.reference;

  const canAbortMission = (
    missionStatus: EMissionStatus | undefined
  ): boolean => {
    const allowebAbortStatus = [EMissionStatus.DRAFT, EMissionStatus.VALIDATED];
    if (!missionStatus) return false;
    return allowebAbortStatus.includes(missionStatus);
  };
  // const { data: missionsLink } = useMissionFindMany({
  //   filterObject: {
  //     $or: [
  //       { parent: mission?.parent?.reference || mission?.reference },
  //       { reference: mission?.parent?.reference },
  //     ],
  //   },
  //   limit: 20,
  //   skip: 0,
  // });
  const checkCreateAmendmentEnable = () => {
    if (mission?.status === EMissionStatus.DRAFT) {
      return {
        enable: false,
        cause: 'Cette mission est en brouillon',
      };
    }

    if (amendments.length > 0) {
      const draftAmendemnt = amendments.find(
        amendment => amendment.status === EMissionStatus.DRAFT
      );
      if (draftAmendemnt) {
        return {
          enable: false,
          cause: `L'avenant ${draftAmendemnt.displayReference} de cette mission est encore en brouillon`,
        };
      }
    }

    if (latestAmendment && mission?.reference !== latestAmendment?.reference) {
      return {
        enable: false,
        cause: `Vous ne pouvez créer un avenant que depuis le dernier avenant ${latestAmendment?.displayReference}`,
      };
    }
    return { enable: true, cause: false };
  };
  const createAmendmentEnable = checkCreateAmendmentEnable();
  const checkIsDisabled = () => {
    if (mission?.isSfFlowError) return true;
    if (
      mission?.status === EMissionStatus.TERMINATED ||
      mission?.status === EMissionStatus.ABORTED ||
      mission?.status === EMissionStatus.ARCHIVED
    ) {
      return true;
    }
    return false;
  };
  //Informations générales
  const checkMissionInfoSageRequired = () => {
    if (
      !mission?.sage ||
      !mission?.billingInformation?.structure ||
      !mission?.billingInformation?.missionType?.label ||
      !mission?.billing?.type ||
      !mission?.internalCommercial ||
      !mission?.startAt ||
      !mission?.endAt ||
      !mission?.accountManager
    ) {
      return false;
    }
    return true;
  };
  //Autres informations
  const checkMissionInfoRequired = () => {
    if (
      !mission?.location?.recipient ||
      !mission?.location?.street ||
      !mission?.location?.city ||
      !mission?.location?.country ||
      !mission?.location?.postalCode ||
      !mission?.description
    ) {
      return false;
    }
    return true;
  };

  //Tarification
  const checkMissionPricingRequired = () => {
    if (
      !mission?.billing?.type ||
      !mission?.billing?.clientRate ||
      !mission?.billing?.contractorRate
    ) {
      return false;
    }
    return true;
  };
  // const checkMissionTypeRequired = () => {
  //   if (!mission?.billingInformation?.missionType?.label) {
  //     return false;
  //   }
  //   return true;
  // };
  const isMandateMode = mission?.isMandateMode;
  const isContractRequired =
    mission?.provider?.establishment?.provider?.isContractRequired;
  const contracts = contractsQuery?.contracts;

  // check if provider bdc is generated and sent to provider
  const checkProviderBdc = () => {
    const allowedPoStatus = [
      EPurchaseOrderStatus.DONE,
      EPurchaseOrderStatus.IMPORTED,
      EPurchaseOrderStatus.SIGNED,
      EPurchaseOrderStatus.SIGNED_IMPORT,
      EPurchaseOrderStatus.TO_SIGN,
    ];
    const missionProviderPos = mission?.provider?.purchaseOrders || [];
    const res = missionProviderPos?.some(e =>
      allowedPoStatus.includes(e?.status)
    );

    return res;
  };
  const checkValidationAvalaible = () => {
    if (isMandateMode) {
      if (
        contracts?.find(
          po =>
            po?.state === EContractState.DONE &&
            po.type?.includes(EContractType.LOREAL)
        )
      ) {
        return true;
      }
    } else {
      if (isContractRequired) {
        if (
          contracts?.find(
            po =>
              !po.type?.includes(EContractType.LOREAL) &&
              po?.state === EContractState.DONE
          )
        ) {
          return true;
        }
      } else {
        return true;
      }
    }
  };
  //
  const checkMissionCustomerRequired = () => {
    let billingAutoOK = true;
    if (mission?.customer?.billingOptions?.isAutoBilling) {
      if (!checkGescomAvalaibleMissionCustomer(mission)?.ready) {
        billingAutoOK = false;
      }
    }

    if (!mission?.customer?.purchaseOrder?.reference || !billingAutoOK) {
      return false;
    }
    return true;
  };
  //Paramètres de facturation
  const checkMissionInvoicingOptionsRequired = () => {
    let billingAutoOK = true;
    // if (mission?.customer?.billingOptions?.isAutoBilling) {
    //   if (!checkGescomAvalaibleGenerateInvoiceCustomer(mission)?.ready) {
    //     billingAutoOK = false;
    //   }
    // }
    if (
      !mission?.customer?.billingOptions?.invoiceAddress &&
      mission?.customer?.establishment?.customer?.invoiceAddress?.length === 0
    ) {
      return false;
    }
    if (!mission?.customer?.billingOptions?.paymentDeadline) {
      return false;
    }
    if (!mission?.customer?.billingOptions?.paymentLabel) {
      return false;
    }

    if (!mission?.customer?.billingOptions?.iban || !billingAutoOK) {
      return false;
    }
    return true;
  };
  const isFrenchEstablishment = (establishment: any) => {
    if (establishment?.address?.country?.toLowerCase() === 'france')
      return true;
    return false;
  };
  const isProviderEstablishmentAvailable = (providerEstablishment: any) => {
    if (
      isFrenchEstablishment(providerEstablishment) &&
      !providerEstablishment?.siret
    )
      return false;
    if (
      !isFrenchEstablishment(providerEstablishment) &&
      !providerEstablishment?.identifier
    )
      return false;
    return true;
  };
  const checkMissionProviderRequired = () => {
    let customStructureRequired: any = {};
    switch (mission?.billingInformation?.structure) {
      case EMissionStructure.COWORKEES:
        customStructureRequired = [EMissionStructure.COWORKEES];
        break;
      case EMissionStructure.FCOM:
        customStructureRequired = [EMissionStructure.FCOM];
        break;
      case EMissionStructure.FONE:
        customStructureRequired = [EMissionStructure.FONE];
        break;
      case EMissionStructure.INOPS:
        customStructureRequired = [EMissionStructure.INOPS];
        break;
      default:
        customStructureRequired = undefined;
    }
    if (
      !isProviderEstablishmentAvailable(mission?.provider?.establishment) ||
      !mission?.provider?.contractor ||
      !checkAllStrcutureCreatedInGescomForContractor(
        mission?.provider?.contractor?.sage,
        customStructureRequired
      )?.isReady ||
      !mission?.provider?.contact ||
      !checkValidationAvalaible() ||
      // ignore this on draft because isContractRequired can change after validation
      (mission?.status === EMissionStatus?.DRAFT &&
        !mission?.isMandateMode &&
        (mission?.provider?.establishment?.provider?.isContractRequired
          ? !checkProviderBdc()
          : false))
      //(!mission?.isMandateMode && !checkProviderBdc())
    ) {
      return false;
    }
    return true;
  };
  const isDisabled = checkIsDisabled();
  // eslint-disable-next-line react-hooks/rules-of-hooks
  React.useEffect(() => {
    if (
      mission
      // !mission.parent &&
      // mission?.status !== EMissionStatus?.DRAFT
    ) {
      try {
        findAmendment(mission);
      } catch (e) {
        //
      }
    } else {
      setAmendments([]);
    }
  }, [mission]);
  const customeNotSet = !mission?.customer?.establishment;
  if (statuGet === 'error') {
    return (
      <PageNotFound
        top={80}
        customText="La mission que vous cherchez semble introuvable."
      />
    );
  }

  // HOTFIX TEOR-4625
  const allowedToDuplicate = () => {
    const customerLocked = mission?.customer?.establishment?.customer?.locked;
    const providerLocked = mission?.provider?.establishment?.provider?.locked;
    if (customerLocked || providerLocked) {
      return false;
    } else {
      return true;
    }
  };
  const isNotDeadFONE = () => {
    if (mission?.billingInformation?.structure === EMissionStructure?.FONE) {
      if (isDisableFONEDate()) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  };
  const handleRedirectToSf = async () => {
    try {
      if (mission?.reference) {
        let link = await saleforceGetLink({
          mission: mission?.reference as string,
        });
        const a = document.createElement('a');
        a.href = link;
        a.target = '_blank';
        a.click();
      }
    } catch (e) {
      //
    }
  };

  return (
    <>
      <Flex>
        <Header>
          <Flex justifyContent="space-between" alignItems={'center'}>
            <Flex>
              <Box>
                <Text variant="h1" mt={20}>
                  {title}{' '}
                  {mission && (
                    <Box>
                      <Text>
                        Responsable de la mission :{' '}
                        {getFullName(mission?.accountManager)}
                        {
                          <Link
                            width={'auto'}
                            ml={10}
                            onClick={() =>
                              showUpdateMissionManagerModal({ mission })
                            }
                          >
                            Modifier
                          </Link>
                        }
                      </Text>
                    </Box>
                  )}
                </Text>

                <Flex maxWidth={830} mt={0}>
                  <Flex
                    justifyContent="space-between"
                    alignContent="center"
                    alignItems="center"
                  >
                    <Box p={10}>
                      <Status
                        variantColor={
                          mission?.status === EMissionStatus.VALIDATED
                            ? 'success'
                            : mission?.status === EMissionStatus.DRAFT
                              ? 'warning'
                              : mission?.status === EMissionStatus.ARCHIVED
                                ? 'grey'
                                : 'error'
                        }
                      >
                        Statut de la mission :{' '}
                        {mission?.status ? FR_STATUS[mission?.status] : ''}
                      </Status>
                      <Status
                        variantColor={mission?.sage ? 'success' : 'warning'}
                      >
                        {mission?.sage
                          ? 'Créé dans GESCOM'
                          : 'A créer dans GESCOM'}
                      </Status>
                      {DISPLAY_SF_STATUS && (
                        <Status
                          variantColor={
                            mission?.isSfFlowError
                              ? 'warning'
                              : mission?.salesforce
                                ? 'success'
                                : 'grey'
                          }
                          style={{ cursor: 'pointer' }}
                          onClick={handleRedirectToSf}
                          id={'tooltip_fcom_warning'}
                        >
                          <span>
                            {mission?.salesforce
                              ? `${CREATED_SF_STATUS} référence : ${mission?.salesforce?.externalId}`
                              : NOT_CREATED_SF_STATUS}
                          </span>

                          {mission?.isSfFlowError && (
                            <>
                              <a data-iscapture="true">
                                <ExclamationCircleIcon
                                  fill={Theme?.colors?.warning?.default}
                                  style={{ marginLeft: 5 }}
                                />
                              </a>
                              <ReactTooltip
                                place={'left'}
                                anchorSelect={'#tooltip_fcom_warning'}
                                variant={'dark'}
                                float={false}
                                style={{
                                  backgroundColor: Theme.colors.warning.default,
                                  zIndex: 99,
                                }}
                              >
                                <Text color="white" fontSize={11}>
                                  Une erreur est survenue lors de la création de
                                  cette mission via le flux Salesforce, sa
                                  modification n’est donc pour l’instant pas
                                  possible.
                                  <br />
                                  Cette mission sera de nouveau modifiable dès
                                  que l’erreur sera corrigée.
                                  <br />
                                  Pour débloquer cette mission, veuillez
                                  contacter salesforce@freelance.com
                                </Text>
                              </ReactTooltip>
                            </>
                          )}
                        </Status>
                      )}
                    </Box>
                    <Box ml={20} p={10}>
                      {mission?.sage && (
                        <Text fontSize={11} variant="p">
                          Structure:{' '}
                          {getSructure(mission?.sage?.structure)?.complete}
                        </Text>
                      )}
                      {parent && (
                        <Text fontSize={11} variant="p">
                          Avenant à la mission Réf :{' '}
                          <a
                            href={`/delivery/missions/${refParent}/information`}
                          >
                            <Link>{parent}</Link>
                          </a>
                        </Text>
                      )}
                    </Box>
                  </Flex>
                </Flex>
              </Box>
            </Flex>

            <Flex
              alignContent="center"
              alignItems="center"
              justifyContent="space-between"
              mt={20}
            >
              <Box>
                <Flex
                  alignItems={'center'}
                  flexWrap={'nowrap'}
                  justifyContent="flex-end"
                  mb={20}
                >
                  <Box width={'100%'}>
                    <CardAmountInfo
                      label={'Commandé'}
                      borderColor={Theme?.colors?.primary?.default}
                      customerValue={getOrderedCustomerStats()}
                      providerValue={getOrderedProviderStats()}
                    />
                  </Box>
                  <Box width={'100%'}>
                    <CardAmountInfo
                      label={'Facturé'}
                      borderColor={Theme?.colors?.success?.default}
                      customerValue={billedCustomerInvoices()}
                      providerValue={billedProviderInvoices()}
                    />
                  </Box>
                  <Box width={'100%'}>
                    <CardAmountInfo
                      label={'Reste à facturer'}
                      borderColor={Theme?.colors?.warning?.default}
                      customerValue={remainingCustomerInvoicing()}
                      providerValue={remainingProviderInvoicing()}
                    />
                  </Box>
                </Flex>
              </Box>
            </Flex>
          </Flex>
        </Header>
      </Flex>
      <Container p="50px 0">
        <Flex>
          <SideBar>
            <SideLink
              href={`/delivery/missions/${reference || 'new'}/gescom-create`}
              data-cy="mission-left-menu-gescom-create"
            >
              <Flex>
                <Status
                  size={20}
                  variantColor={
                    checkMissionInfoSageRequired() ? 'success' : 'error'
                  }
                />
                <Box>Informations générales</Box>
              </Flex>
            </SideLink>
            <SideLink
              href={`/delivery/missions/${reference || 'new'}/information`}
              data-cy="mission-left-menu-information"
            >
              <Flex>
                <Status
                  size={20}
                  variantColor={
                    checkMissionInfoRequired() ? 'success' : 'error'
                  }
                />
                <Box>Autres informations</Box>
              </Flex>
            </SideLink>

            <SideLink
              href={`/delivery/missions/${reference}/pricing`}
              data-cy="mission-left-menu-information"
            >
              <Flex>
                <Status
                  size={20}
                  variantColor={
                    checkMissionPricingRequired() ? 'success' : 'error'
                  }
                />
                <Box>Tarification</Box>
              </Flex>
            </SideLink>
            {/* <SideLink
              data-cy="mission-left-menu-billing-information"
              href={`/delivery/missions/${
                reference || 'new'
              }/billing-information`}
            >
              <Flex>
                <Status
                  size={20}
                  variantColor={
                    checkMissionTypeRequired() ? 'success' : 'error'
                  }
                />
                <Box>Informations de facturation</Box>
              </Flex>
            </SideLink> */}
            <SideLink
              data-cy="mission-left-menu-customer"
              href={`/delivery/missions/${reference || 'new'}/client`}
            >
              <Flex>
                <Status
                  size={20}
                  variantColor={
                    checkMissionCustomerRequired() ? 'success' : 'error'
                  }
                />
                <Box>Client</Box>
              </Flex>
            </SideLink>
            <SideLink
              data-cy="mission-left-menu-provider"
              href={`/delivery/missions/${reference || 'new'}/provider`}
            >
              <Flex>
                <Status
                  size={20}
                  variantColor={
                    checkMissionProviderRequired() ? 'success' : 'error'
                  }
                />
                <Box>Fournisseur</Box>
              </Flex>
            </SideLink>
            <SideLink
              href={`/delivery/missions/${
                reference || 'new'
              }/invoicing-options`}
              data-cy="mission-left-menu-invoicing"
            >
              <Flex>
                <Status
                  size={20}
                  variantColor={
                    checkMissionInvoicingOptionsRequired() ? 'success' : 'error'
                  }
                />
                <Box>Paramètres de facturation</Box>
              </Flex>
            </SideLink>

            {!mission?.parent &&
              !isNew &&
              mission?.status !== EMissionStatus?.DRAFT && (
                <SideLink
                  data-cy="mission-left-menu-provider"
                  href={`/delivery/missions/${reference}/amendment`}
                >
                  <Flex>
                    <Box ml={20}>Avenant(s)</Box>
                  </Flex>
                </SideLink>
              )}
            {reference &&
              !isNew &&
              mission?.status !== EMissionStatus?.DRAFT && (
                <>
                  {mission?.billing?.type === EBillingType.DAY && (
                    <SideLink href={`/delivery/missions/${reference}/cra`}>
                      <Flex>
                        <Box ml={20}>CRA</Box>
                      </Flex>
                    </SideLink>
                  )}
                  {mission?.billing?.type === EBillingType.FLAT_RATE && (
                    <SideLink
                      href={`/delivery/missions/${reference}/milestones`}
                    >
                      <Flex>
                        <Box ml={20}>Jalons</Box>
                      </Flex>
                    </SideLink>
                  )}
                  <SideLink
                    href={`/delivery/missions/${reference}/additional-activities`}
                  >
                    <Flex>
                      <Box ml={20}>Prestations comp. & Frais</Box>
                    </Flex>
                  </SideLink>
                  <SideLink href={`/delivery/missions/${reference}/invoice`}>
                    <Flex>
                      <Box ml={20}>Factures</Box>
                    </Flex>
                  </SideLink>
                  {/*<SideLink>Commentaires</SideLink>*/}
                </>
              )}

            {reference &&
              !isNew &&
              mission?.status !== EMissionStatus?.DRAFT && (
                <SideLink
                  data-cy="mission-left-menu-credit-note"
                  href={`/delivery/missions/${reference}/credit-note`}
                >
                  <Flex ml={20}>Avoirs&nbsp;</Flex>
                </SideLink>
              )}
            {/* <SideLink
              data-cy="mission-left-menu-history"
              href={`/delivery/missions/${reference}/history`}
            >
              <Flex ml={20}>
                <Box>Historique</Box>
              </Flex>
            </SideLink> */}
            <SideLink
              data-cy="mission-left-menu-comment"
              href={`/delivery/missions/${reference}/comments`}
            >
              <Flex ml={20}>
                Historique & Commentaires&nbsp;
                {data?.totalCount ? (
                  <Box
                    backgroundColor={Theme?.colors?.error?.default}
                    border={`1px solid ${Theme?.colors?.error?.default}`}
                    borderRadius={'50%'}
                    height={20}
                    width={20}
                  >
                    <Flex justifyContent={'center'}>
                      <Text color="white">{data?.totalCount}</Text>
                    </Flex>
                  </Box>
                ) : (
                  false
                )}{' '}
              </Flex>
            </SideLink>
            <Separator />
            {mission?.status === EMissionStatus.DRAFT && isNotDeadFONE() && (
              <Link
                iconLeft={
                  isFetchingValidateMission ? (
                    <Spinner size={20} />
                  ) : (
                    <CheckIcon />
                  )
                }
                onClick={onValidateMission}
                mb={10}
                width={'100%'}
              >
                Valider la mission
              </Link>
            )}

            <Link
              isDisabled={isNew || !mission || !allowedToDuplicate()}
              iconLeft={<DuplicateIcon />}
              onClick={() => {
                if (isNew || !mission || !allowedToDuplicate()) {
                  showDialogModal({
                    title: 'Impossible de dupliquer la mission',
                    text: 'Un ou plusieurs des établissements semble étre désactivé.',
                    confirmLabel: "J'ai bien compris",
                  });
                } else {
                  showDuplicateMissionModal({
                    mission: mission as IJoinedMission,
                  });
                }
              }}
              mb={10}
              width={'100%'}
            >
              Dupliquer la mission
            </Link>

            <Box width={'130px'}>
              {!isNew &&
                isNotDeadFONE() &&
                (mission?.status === EMissionStatus?.VALIDATED ||
                  mission?.status === EMissionStatus?.TERMINATED) && (
                  <a
                    data-for={mission?.reference}
                    data-tip=""
                    data-iscapture="true"
                  >
                    <ReactTooltip
                      id={mission?.reference}
                      place={'top'}
                      variant={'dark'}
                      float={false}
                      style={{ backgroundColor: theme.colors.warning.default }}
                    >
                      {!createAmendmentEnable?.enable && (
                        <Text color="white" fontSize={11}>
                          {createAmendmentEnable?.cause}
                        </Text>
                      )}
                    </ReactTooltip>
                    <Link
                      color={
                        createAmendmentEnable?.enable
                          ? theme.colors.blue
                          : theme.colors.grey.default
                      }
                      onClick={() =>
                        createAmendmentEnable?.enable
                          ? showCreateMissionAmendmentModal({
                              mission: mission as IJoinedMission,
                            })
                          : false
                      }
                      iconLeft={
                        <AddIcon
                          style={{
                            fill: createAmendmentEnable?.enable
                              ? theme.colors.blue
                              : theme.colors.grey.default,
                          }}
                        />
                      }
                      mb={10}
                      width={'100%'}
                    >
                      Créer un avenant
                    </Link>
                  </a>
                )}
            </Box>
            {/* {mission?.status && (
              <Link iconLeft={<DuplicateIcon />} mb={10} width={'100%'}>
                Dupliquer la mission
              </Link>
            )} */}
            {mission?.status &&
              mission?.status === EMissionStatus.DRAFT &&
              mission.createdFromSFFlow !== true && (
                <Link
                  iconLeft={<DeleteIcon />}
                  onClick={onDeleteMission}
                  mb={10}
                >
                  Supprimer la mission
                </Link>
              )}
            {canAbortMission(mission?.status) && (
              <>
                <Link
                  iconLeft={<CloseIcon />}
                  onClick={onDisableMission}
                  mb={10}
                  width={'100%'}
                >
                  Annuler la mission
                </Link>
              </>
            )}
            {mission?.isSfFlowError && (
              <Box id="tooltip_sync_salesforce">
                <Link
                  iconLeft={<RepeatIcon width={'19px'} height={'19px'} />}
                  onClick={() => {
                    if (
                      hasOnGoingSalforceRefreshTasks ||
                      !mission?.salesforce?.externalId
                    )
                      return false;
                    showSyncSaleforceMissionModal({ mission });
                  }}
                  mb={10}
                  ml={'2px'}
                  isDisabled={
                    hasOnGoingSalforceRefreshTasks ||
                    !mission?.salesforce?.externalId
                  }
                  width={'100%'}
                >
                  Synchroniser avec Salesforce
                </Link>
                {!mission?.salesforce?.externalId && (
                  <ReactTooltip
                    place={'top'}
                    anchorSelect={'#tooltip_sync_salesforce'}
                    variant={'dark'}
                    float={false}
                    style={{
                      backgroundColor: Theme.colors.warning.default,
                      zIndex: 99,
                    }}
                  >
                    <Text color="white" fontSize={11}>
                      Cette mission n'a pas de salesforce.externalId
                    </Text>
                  </ReactTooltip>
                )}
              </Box>
            )}
            {/* {mission?.status === EMissionStatus.ABORTED && (
              <Link
                iconLeft={<RefreshIcon />}
                onClick={onReactivateMission}
                width={'100%'}
              >
                Réactiver la mission
              </Link>
            )} */}
            {mission?.status === EMissionStatus.VALIDATED && (
              <Link iconLeft={<ArchiveIcon />} width={'100%'}>
                Archiver la mission
              </Link>
            )}
            {mission?.status === EMissionStatus.VALIDATED && (
              <Link
                iconLeft={<TerminateMissionIcon />}
                onClick={() => showEndMissionModal({ mission })}
                mb={10}
                ml={'7px'}
                width={'100%'}
              >
                Terminer la mission
              </Link>
            )}
          </SideBar>
          {loading && <SpinnerBox />}
          {!loading && (
            <Box flex={1}>
              <Switch>
                <Route exact path="/delivery/missions/:reference">
                  <Redirect
                    to={`/delivery/missions/${
                      reference || 'new'
                    }/gescom-create`}
                  />
                </Route>
                <Route path="/delivery/missions/:reference/client">
                  {customeNotSet ? (
                    <Flex flexDirection="column" alignItems="center" p="50px 0">
                      <Text variant="h2" color="primary.light" mb="10">
                        Aucun client sélectionné
                      </Text>
                      <Text color="primary.light" mb={20}>
                        Vous n’avez pas encore sélectionné de client pour cette
                        prestation.
                      </Text>
                      <Button
                        data-cy="mission-customer-form-select-customer"
                        onClick={() =>
                          showAddMissionCustomerModal({
                            method: 'CREATE',
                            mission,
                          })
                        }
                      >
                        Sélectionner un client
                      </Button>
                    </Flex>
                  ) : (
                    <Client isDisabled={isDisabled} amendments={amendments} />
                  )}
                </Route>
                <Route path="/delivery/missions/:reference/provider">
                  <Provider isDisabled={isDisabled} amendments={amendments} />
                </Route>
                <Route path="/delivery/missions/:reference/gescom-create">
                  <GescomMission isDisabled={isDisabled} />
                </Route>
                <Route path="/delivery/missions/:reference/information">
                  <Information isDisabled={isDisabled} />
                </Route>
                <Route path="/delivery/missions/:reference/pricing">
                  <PricingMission isDisabled={isDisabled} />
                </Route>{' '}
                <Route path="/delivery/missions/:reference/invoicing-options">
                  {customeNotSet ? (
                    <Flex flexDirection="column" alignItems="center" p="50px 0">
                      <Text variant="h2" color="primary.light" mb="10">
                        Aucun client sélectionné
                      </Text>
                      <Text color="primary.light" mb={20}>
                        Vous n’avez pas encore sélectionné de client pour cette
                        prestation.
                      </Text>
                    </Flex>
                  ) : (
                    <InvoicingOptions
                      isDisabled={isDisabled}
                      amendments={amendments}
                    />
                  )}
                </Route>
                {/* <Route path="/delivery/missions/:reference/billing-information">
                  <BillingInformation isDisabled={isDisabled} />
                </Route> */}
                <Route path="/delivery/missions/:reference/amendment">
                  <AmendmentList />
                </Route>
                <Route path="/delivery/missions/:reference/cra">
                  <MissionCra />
                </Route>
                <Route path="/delivery/missions/:reference/milestones">
                  <MissionMileStones />
                </Route>
                <Route path="/delivery/missions/:reference/additional-activities">
                  <MissionAdditionalActivity />
                </Route>
                <Route path="/delivery/missions/:reference/invoice">
                  <MissionInvoice />
                </Route>
                <Route path="/delivery/missions/:reference/credit-note">
                  <MissionCreditNote />
                </Route>
                <Route path="/delivery/missions/:reference/comments">
                  <Comments />
                </Route>
                {/* <Route path="/delivery/missions/:reference/history">
                  <MissionHistory />
                </Route> */}
              </Switch>
            </Box>
          )}
        </Flex>
      </Container>
    </>
  );
};
