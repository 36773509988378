import { getHumanDate } from '@commons';
import {
  ETaskStatus,
  ETaskType,
  ITask,
  ETaskStepStatus,
  ITaskStep,
} from '@freelancelabs/teoreme-commons';
import {
  differenceInHours,
  differenceInMinutes,
  differenceInSeconds,
  differenceInMilliseconds,
} from 'date-fns';
export const taskTypeMapping = {
  [ETaskType.CHECK_INVOICE_RIB]: "Vérification de l'IBAN",
  [ETaskType.EXPORT_XLSX]: 'Export XLSX',
  [ETaskType.ADDITIONAL_ACTIVITY_REJECT]:
    "Refus d'une préstation complémentaire/frais/jalons",
  [ETaskType.CRA_VALIDATION]: "Validation d'un CRA",
  [ETaskType.CRA_PATCH]: "Correction d'un CRA",
  [ETaskType.CRA_REJECT]: "Refus d'un CRA",
  [ETaskType.ADDITIONAL_ACTIVITY_VALIDATION]:
    "Validation d'une préstation complémentaire/frais/jalons",
  [ETaskType.CREATE_MISSION_FROM_SF]: "Création d'une mission depuis SF",
  [ETaskType.HISTORY_RETENTION_CHECK]:
    "Vérification de la rétention de l'historique",
  [ETaskType.PATCH_CUSTOMER_INVOICE]: "Correction d'une facture client",
};

export const variantColorStepStatus = {
  [ETaskStepStatus.CANCELLED]: 'grey',
  [ETaskStepStatus.COMPLETED]: 'success',
  [ETaskStepStatus.ERROR]: 'error',
  [ETaskStepStatus.ONGOING]: 'warning',
  [ETaskStepStatus.PENDING]: 'warning',
  [ETaskStepStatus.SKIPPED]: 'grey',
};
export const variantColorTaskStatus = {
  [ETaskStatus.CANCELLED]: 'grey',
  [ETaskStatus.COMPLETED]: 'success',
  [ETaskStatus.RESOLVED]: 'primary',
  [ETaskStatus.ERROR]: 'error',
  [ETaskStatus.ONGOING]: 'warning',
  [ETaskStatus.PENDING]: 'warning',
};

export const getIdentifierByTaskType = (task?: ITask) => {
  switch (task?.type) {
    case ETaskType.ADDITIONAL_ACTIVITY_REJECT:
      return {
        label: 'Voir les activitées',
        value: task?.input?.activityUuid,
        link: `/orders/additional-activities/to-be-validated`,
      };
    case ETaskType.ADDITIONAL_ACTIVITY_VALIDATION:
      return {
        label: 'Voir les activitées',
        value: task?.input?.activityUuid,
        link: `/orders/additional-activities/validated`,
      };
    case ETaskType.CRA_VALIDATION:
      return {
        label: 'Voir le cra',
        value: task?.input?.refCra,
        link: `/orders/cra/${task?.input?.refCra}/informations`,
      };
    case ETaskType.CRA_PATCH:
      return {
        label: 'Voir le cra',
        value: task?.input?.refCra,
        link: `/orders/cra/${task?.input?.refCra}/informations`,
      };
    case ETaskType.CRA_REJECT:
      return {
        label: 'Voir le cra',
        value: task?.input?.refCra,
        link: `/orders/cra/${task?.input?.refCra}/informations`,
      };
    default:
      return {
        label: false,
        value: 'N/A',
        link: false,
      };
  }
};
export const isCancellableTask = (task?: ITask) => {
  const avalaibleStatus = [ETaskStatus.ONGOING, ETaskStatus.PENDING];
  if (task?.cancellable && avalaibleStatus?.includes(task?.status)) {
    return true;
  }
  return false;
};
export const isResolvableTask = (task?: ITask) => {
  const avalaibleStatus = [ETaskStatus.ERROR];
  if (task && avalaibleStatus?.includes(task?.status)) {
    return true;
  }
  return false;
};
export const isRetryableTask = (task?: ITask) => {
  const avalaibleStatus = [ETaskStatus.ERROR];
  if (task?.retryable === true && avalaibleStatus?.includes(task?.status)) {
    return true;
  }
  return false;
};
export const isRetryableStep = (task?: ITask, step?: ITaskStep) => {
  if (task?.retryable === true && task?.status === ETaskStatus.ERROR) {
    if (step?.retryable && step?.status !== ETaskStepStatus.ERROR) {
      return true;
    }
  }
  return false;
};

export const checkDisplayTaskMenuList = (task?: ITask): boolean => {
  return (
    isCancellableTask(task) || isResolvableTask(task) || isRetryableTask(task)
  );
};

export const getStepTimeByStatus = (step: ITaskStep) => {
  const format = ' dd/MM/yyyy à HH:mm';
  if (step?.status === ETaskStepStatus?.CANCELLED) {
    return getHumanDate(step?.updatedAt as Date, format);
  }
  if (step?.status === ETaskStepStatus?.COMPLETED) {
    return getHumanDate(step?.completedAt as Date, format);
  }
  if (step?.status === ETaskStepStatus?.ERROR) {
    return getHumanDate(step?.updatedAt as Date, format);
  }
  if (step?.status === ETaskStepStatus?.ONGOING) {
    return getHumanDate(step?.updatedAt as Date, format);
  }
  if (step?.status === ETaskStepStatus?.PENDING) {
    return getHumanDate(step?.updatedAt as Date, format);
  }
  if (step?.status === ETaskStepStatus?.SKIPPED) {
    return getHumanDate(step?.updatedAt as Date, format);
  }
};

export const getTimeOfTaksStep = (completedAt: Date, startedAt: Date) => {
  if (completedAt && startedAt) {
    const diffML = differenceInMilliseconds(completedAt, startedAt);
    const diffSecondes = differenceInSeconds(completedAt, startedAt);
    const diffMinutes = differenceInMinutes(completedAt, startedAt);
    const diffHours = differenceInHours(completedAt, startedAt);
    if (diffHours > 0) {
      return { value: diffHours, label: 'Heure(s)' };
    }
    if (diffMinutes > 0) {
      return { value: diffMinutes, label: 'Minutes(s)' };
    }
    if (diffSecondes > 0) {
      return { value: diffSecondes, label: 'Secondes(s)' };
    }
    if (diffML > 0) {
      return { value: diffML, label: 'Milliseconde(s)' };
    }
  }
  return { value: '', label: '' };
};

export const getTotalTaskTime = (task: ITask) => {
  // const lastCompletedTask = task?.steps
  //   ?.filter(step => step.status === ETaskStepStatus.COMPLETED)
  //   .pop();
  const startedAt = task?.startedAt as Date;
  let completedAt = task?.completedAt as Date;
  if (task?.status !== ETaskStatus.COMPLETED) {
    completedAt = task?.updatedAt as Date;
  }
  if (completedAt && startedAt) {
    return getTimeOfTaksStep(completedAt, startedAt);
  }
  return { value: 'N/A', label: '' };
};

export const getAllTaskInputValues = (task?: ITask) => {
  const formatedInput: any = [];
  if (task?.input) {
    const input = task?.input;
    const keys = Object?.keys(input);
    keys?.forEach(key => {
      if (typeof input[key] === 'string') {
        formatedInput?.push({ key, value: input[key] });
      }
      if (typeof input[key] === 'object') {
        formatedInput?.push({ key, value: JSON?.stringify(input[key]) });
      }
      if (typeof input[key] === 'number') {
        formatedInput?.push({ key, value: input[key] });
      }
    });
  }
  return formatedInput;
};
export const getAllStepOutputValues = (step?: ITaskStep) => {
  const formatedOutput: any = [];
  if (step?.output) {
    const output = step?.output;
    const keys = Object?.keys(output);
    keys?.forEach(key => {
      if (typeof output[key] === 'string') {
        formatedOutput?.push({ key, value: output[key] });
      }
      if (typeof output[key] === 'object') {
        formatedOutput?.push({ key, value: JSON?.stringify(output[key]) });
      }
      if (typeof output[key] === 'number') {
        formatedOutput?.push({ key, value: output[key] });
      }
    });
  }
  return formatedOutput;
};

export const isActiveStep = (task?: ITask, step?: ITaskStep) => {
  const inactiveStatus = [
    ETaskStatus.RESOLVED,
    ETaskStatus.COMPLETED,
    ETaskStatus.CANCELLED,
  ];
  if (inactiveStatus?.includes(task?.status as ETaskStatus)) {
    return false;
  }
  if (step?.stepNumber === task?.currentStep) {
    if (
      step?.status === ETaskStepStatus.ONGOING ||
      step?.status === ETaskStepStatus.PENDING
    ) {
      return true;
    }
  }
};
export const isLastActiveStep = (task?: ITask, step?: ITaskStep) => {
  if (
    task?.status !== ETaskStatus.COMPLETED &&
    task?.status !== ETaskStatus.RESOLVED &&
    step?.stepNumber === task?.currentStep
  ) {
    return true;
  }
};
