import { IEstablishmentFullUsers } from '@freelancelabs/teoreme-commons';
import {
  useEstablishmentFindOne,
  getTradeNameSafe,
  useEstablishmentUpdateOne,
} from '@commons';
import { UserStructuredCard } from 'components/cards/UserStructuredCard';
import { Box, Link, Text } from 'components/ui';
import { LinkIcon } from 'components/ui/icons';
import { useSetCrumbs } from 'hooks/breadCrumb';
import React from 'react';
import { useParams } from 'react-router-dom';
import { showLinkCustomerContactModal } from 'components/modals/clients/LinkContactModal';
import { queryClient } from '@commons';

export const ClientsEstablishmentsContacts = () => {
  const { uuid } = useParams<{ uuid: string }>();
  const { data: establishment } = useEstablishmentFindOne(uuid);
  const contacts = establishment?.customer?.contacts || [];
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [shouldValidateCra, setShouldValidateCra] = React.useState(
    establishment?.customer?.shouldValidateCra
  );
  const { mutateAsync: updateEstablishment } = useEstablishmentUpdateOne();
  useSetCrumbs(
    [establishment],
    [
      {
        label: 'clients',
        path: '/clients',
      },
      {
        label: 'établissements',
        path: `/clients/establishments`,
      },
      {
        label: establishment ? getTradeNameSafe(establishment) : '-',
        path: `/clients/establishments/${establishment?.uuid}`,
      },
      {
        label: 'contacts',
        path: `/clients/establishments/${establishment?.uuid}/contacts`,
      },
    ]
  );
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onUpdateValidateCra = async (SValidateCra: boolean) => {
    setShouldValidateCra(SValidateCra);
    try {
      const establishmentData = {
        uuid: establishment?.uuid as string,
        establishment: {
          customer: {
            shouldValidateCra: SValidateCra,
          },
        },
      };
      await updateEstablishment(establishmentData);
    } catch (e) {
      //
    }
    queryClient.refetchQueries({ queryKey: [establishment?.uuid] });
  };
  React.useEffect(() => {
    setShouldValidateCra(
      establishment?.customer?.shouldValidateCra ? true : false
    );
  }, [establishment]);
  return (
    <Box>
      <Text variant="h2" mb={20}>
        Contacts opérationnels
      </Text>
      <Box>
        {contacts.map(contact => (
          <UserStructuredCard
            mb={20}
            isFullWidth
            key={contact.cognitoUserId}
            user={contact}
            establishment={establishment}
            forRole={'CUSTOMER'}
            checkSage
          />
        ))}
      </Box>
      <Link
        onClick={() =>
          showLinkCustomerContactModal({
            establishment: establishment as IEstablishmentFullUsers,
          })
        }
        iconLeft={<LinkIcon />}
      >
        Rattacher un contact
      </Link>
    </Box>
  );
};
