import {
  IJoinedMission,
  ICompanyGetResponse,
  IMissionCustomer,
  IMissionProvider,
  EMissionStatus,
  EBillingType,
  EMissionType,
  EMissionStructure,
  ISalesForceGetMarketsResponse,
  IMarketDataInterface,
} from '@freelancelabs/teoreme-commons';
import {
  useEstablishmentUpdateOne,
  saleforceGetmarket,
  useSaleforceGetMarkets,
  REACT_APP_MARKET_REQUIRED,
} from '@commons';
import { showAddContactModal } from 'components/modals/AddContactModal';
import { showAddContractorModal } from 'components/modals/AddContractorModal';
import { showAddEstablishmentModal } from 'components/modals/AddEstablishmentModal';
import {
  BlocInformation,
  Box,
  Button,
  FormLabel,
  Link,
  UserSelectControlled,
  EstablishmentSelectControlled,
  ContractorSelectControlled,
  StaticSelectControlled,
} from 'components/ui';
import { AddIcon } from 'components/ui/icons';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { queryClient } from '@commons';
import { Theme } from 'styles';
import { isMissionFieldEditable } from 'helpers';
import { showDialogModal } from 'components/modals/DialogModal';

type FormValues = IMissionProvider | IMissionCustomer;
type AddMissionClientOrProviderFormProps = {
  onSubmit?: (formValues: FormValues) => any | void;
  type?: 'PROVIDER' | 'CUSTOMER';
  method: 'UPDATE' | 'CREATE';
  mission?: IJoinedMission;
  missionHasNoValidatedCraOrMilestone?: boolean;
};

export const AddMissionCustomerOrProviderForm = ({
  onSubmit: onSubmitParent,
  type = 'PROVIDER',
  mission,
  method,
  missionHasNoValidatedCraOrMilestone = false,
}: AddMissionClientOrProviderFormProps) => {
  // const [market, setMarket] = useState<ISalesForceGetMarketsResponse>(
  //   mission?.marketData ? [mission?.marketData] : []
  // );
  const [loading, setLoading] = useState(false);
  const { handleSubmit, control, watch, setValue, getValues } =
    useForm<FormValues>({
      defaultValues:
        method === 'CREATE'
          ? undefined
          : type === 'PROVIDER'
            ? {
                contractor: mission?.provider?.contractor?.uuid,
                contact: mission?.provider?.contact?.cognitoUserId,
                establishment: mission?.provider?.establishment?.uuid,
              }
            : {
                contact: mission?.customer?.contact?.cognitoUserId,
                establishment: mission?.customer?.establishment?.uuid,
                //@ts-ignore
                marketData: mission?.marketData,
                marketId: mission?.marketId,
              },
    });

  const [showAttachmentMessage, setShowAttachmentMessage] =
    React.useState(false);

  const { mutateAsync: updateEstablishment } = useEstablishmentUpdateOne();

  const onSubmit = async (data: FormValues) => {
    setLoading(true);
    if (
      method === 'UPDATE' &&
      // type === 'CUSTOMER' &&
      mission?.status === EMissionStatus.VALIDATED &&
      missionHasNoValidatedCraOrMilestone
    ) {
      const editEstablishmentWarningText =
        type === 'CUSTOMER'
          ? `La modification de l'établissement client va entrainer la suppression du devis, du BDC client et du BDC fournisseur et
              le retour de la mission au statut “Brouillon”. Etes-vous sur de vouloir modifier le client ?`
          : `La modification de l'établissement fournisseur va entrainer la suppression du BDC fournisseur et le retour de la mission
              au statut “Brouillon”. Etes-vous sur de vouloir modifier le fournisseur ?`;
      await showDialogModal({
        title: `Êtes-vous sûr(e) de vouloir valider la modification de l'établissement?`,
        text: editEstablishmentWarningText,
        confirmLabel: 'Confirmer',
        cancelLabel: 'Annuler',

        beforeValidation: async () => {
          if (type === 'CUSTOMER' && showAttachmentMessage) {
            try {
              await updateEstablishment({
                uuid: establishment,
                establishment: {},
                addContacts: {
                  customer: [contact],
                  provider: [],
                },
              });
            } catch (error) {
              console.error(error);
            }
          }

          onSubmitParent && (await onSubmitParent(data));
          setLoading(false);
        },
      });
    } else {
      if (type === 'CUSTOMER' && showAttachmentMessage) {
        try {
          await updateEstablishment({
            uuid: establishment,
            establishment: {},
            addContacts: {
              customer: [contact],
              provider: [],
            },
          });
        } catch (error) {
          console.error(error);
        }
      }

      onSubmitParent && (await onSubmitParent(data));
      setLoading(false);
    }
    setLoading(false);
  };

  const establishment = watch('establishment');
  const contact = watch('contact');
  const marketId = watch('marketId');
  const marketData = watch('marketData');
  const contractor = watch('contractor');
  const { data: sfMarkets, isPending: loadingMarket } = useSaleforceGetMarkets(
    {
      uuid: establishment,
      startAt: mission?.startAt as Date,
      endAt: mission?.endAt as Date,
      structure: mission?.billingInformation?.structure as EMissionStructure,
      billingType: mission?.billing?.type as EBillingType,
      missionType: mission?.billingInformation?.missionType
        ?.label as EMissionType,
    },
    { enabled: type === 'CUSTOMER' && mission && establishment ? true : false }
  );
  const contactIsAttachedToEstablishment = (
    selectedEstablishment: ICompanyGetResponse | null,
    selectedContactId: string
  ) => {
    if (selectedEstablishment && selectedEstablishment.customer) {
      /*if (
        selectedEstablishment.customer.accountManager.cognitoUserId ===
        selectedContactId
      )
        return true;*/
      for (
        let i = 0;
        i < selectedEstablishment.customer.contacts?.length;
        i++
      ) {
        const contact = selectedEstablishment.customer.contacts[i];
        if (contact.cognitoUserId === selectedContactId) return true;
      }
    }

    return false;
  };

  const onSelectChange = async (
    selectType: 'ESTABLISHMENT' | 'CONTACT' | 'MARKET',
    value: any
  ) => {
    if (type === 'CUSTOMER') {
      if (selectType === 'MARKET') {
        if (value) {
          //@ts-ignore
          setValue('marketId', value);
          setValue(
            'marketData',
            //@ts-ignore
            sfMarkets?.find(m => m?.marketId === value)
          );
        } else {
          //@ts-ignore
          setValue('marketId', undefined);
          setValue(
            'marketData',
            //@ts-ignore
            null
          );
        }
      }
      // this data is loaded when establishment is selected
      const selectedEstablishment: ICompanyGetResponse | null =
        queryClient.getQueryData([
          selectType === 'ESTABLISHMENT' ? value : establishment,
        ]) || null;
      if (selectType === 'ESTABLISHMENT' && value?.uuid !== establishment) {
        //@ts-ignore
        setValue('marketId', undefined);
        //@ts-ignore
        setValue('marketData', undefined);
      }

      if (
        (selectType === 'ESTABLISHMENT' && !contact) ||
        (selectType === 'CONTACT' && !establishment) ||
        !value
      ) {
        setShowAttachmentMessage(false);
      } else {
        setShowAttachmentMessage(
          !contactIsAttachedToEstablishment(
            selectedEstablishment,
            selectType === 'CONTACT' ? value : contact
          )
        );
      }
    }
  };
  let isDisabledOnUpdate = true;
  if (method === 'UPDATE') {
    if (mission?.status === EMissionStatus?.DRAFT) isDisabledOnUpdate = false;

    if (mission?.status === EMissionStatus?.VALIDATED)
      isDisabledOnUpdate = missionHasNoValidatedCraOrMilestone ? false : true;
  }
  if (method === 'CREATE') isDisabledOnUpdate = false;

  const accordDisabled = () => {
    if (method === 'UPDATE') return isDisabledOnUpdate;
    return !establishment || !isMissionFieldEditable(mission, 'marketData');
  };
  let mergedMarket = sfMarkets;
  // if (marketData) {
  //   if (
  //     !mergedMarket?.find(
  //       sf => sf?.marketId === (marketData as IMarketDataInterface)?.marketId
  //     )
  //   )
  //     mergedMarket?.push(marketData);
  // }
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box mb={30}>
        <FormLabel>
          ÉTABLISSEMENT {type === 'CUSTOMER' ? 'CLIENT' : 'FOURNISSEUR'}{' '}
          <span style={{ color: Theme.colors.blue }}>*</span>
        </FormLabel>
        {method === 'UPDATE' && type === 'PROVIDER' && (
          <BlocInformation disableIcon mb={20}>
            <p>
              Attention, la modification de l'établissement fournisseur
              entrainera la suppression :
              <ul>
                <li>Du bon de commande fournisseur associé à cette mission.</li>
                <li>
                  Des éventuels services complémentaires souscrits associés à
                  cette mission.
                </li>
              </ul>
            </p>
          </BlocInformation>
        )}
        {method === 'UPDATE' && type === 'CUSTOMER' && (
          <BlocInformation mb={20}>
            <p>
              En cas de modification de l'établissement ou du contact client,
              veuillez vérifier que les devis et/ou les bon de commandes générés
              sont toujours valides et si besoin procéder à leur mise à jour
              manuelle.
            </p>
          </BlocInformation>
        )}
        <EstablishmentSelectControlled
          disabled={isDisabledOnUpdate}
          id="mission-custoProvid-select-establishment"
          onChangeCompleteObject={(value: string) => {
            onSelectChange('ESTABLISHMENT', value);
          }}
          control={control}
          name="establishment"
          filter={
            type === 'CUSTOMER'
              ? { customer: { $exists: true }, 'customer.locked': false }
              : {
                  'provider.manager': { $exists: true },
                  'provider.locked': false,
                  //siren: { $nin: ['819317942', '851267245'] },
                }
          }
          width="100%"
          referenceValue="uuid"
          placeholder="Rechercher un N° SIRET, raison sociale ..."
        />
        {!isDisabledOnUpdate && (
          <Link
            iconLeft={<AddIcon />}
            mt={10}
            onClick={() =>
              showAddEstablishmentModal({
                establishmentRole: type,
              }).then(e => {
                e.uuid && setValue('establishment', e.uuid);
                if (type === 'CUSTOMER') {
                  setShowAttachmentMessage(
                    !contactIsAttachedToEstablishment(e, contact)
                  );
                }
              })
            }
          >
            Ajouter un établissement{' '}
            {type === 'CUSTOMER' ? 'client' : 'fournisseur'}
          </Link>
        )}
      </Box>
      {type === 'CUSTOMER' && (
        <Box mb={50}>
          <FormLabel>
            Accord Cadre{' '}
            {REACT_APP_MARKET_REQUIRED && (
              <span style={{ color: Theme.colors.blue }}>*</span>
            )}
          </FormLabel>
          <StaticSelectControlled
            isDisabled={accordDisabled()}
            control={control}
            rules={{
              required:
                REACT_APP_MARKET_REQUIRED === true
                  ? 'Ce champs est requis'
                  : undefined,
            }}
            onChange={(value: string) => {
              onSelectChange('MARKET', value);
            }}
            isLoading={loadingMarket}
            name="marketId"
            options={mergedMarket?.map((m: any) => {
              return {
                label: `${m?.marketId} | ${m?.marketName}`,
                value: m?.marketId,
                data: m,
              };
            })}
          />
        </Box>
      )}
      <Box mb={30}>
        <FormLabel>
          CONTACT {type === 'CUSTOMER' ? 'CLIENT' : 'FOURNISSEUR'}{' '}
          <span style={{ color: Theme.colors.blue }}>*</span>
        </FormLabel>
        <UserSelectControlled
          id="mission-custoProvid-select-contact"
          onChangeCompleteObject={(value: string) =>
            onSelectChange('CONTACT', value)
          }
          control={control}
          name="contact"
          role={type === 'CUSTOMER' ? 'CUSTOMER' : 'PROVIDER_CONTACT'}
          width="100%"
          placeholder="Rechercher un nom, prénom, email …"
        />
        <Link
          iconLeft={<AddIcon />}
          mt={10}
          onClick={() =>
            showAddContactModal({
              contactRole:
                type === 'CUSTOMER' ? 'CUSTOMER' : 'PROVIDER_CONTACT',
            }).then(c => {
              c.cognitoUserId && setValue('contact', c.cognitoUserId);
              if (type === 'CUSTOMER') {
                const selectedEstablishment: ICompanyGetResponse | null =
                  queryClient.getQueryData([establishment]) || null;
                setShowAttachmentMessage(
                  !contactIsAttachedToEstablishment(
                    selectedEstablishment,
                    c.cognitoUserId || ''
                  )
                );
              }
            })
          }
        >
          Ajouter un contact {type === 'CUSTOMER' ? 'client' : 'fournisseur'}
        </Link>
      </Box>
      {type === 'PROVIDER' && (
        <Box mb={30}>
          <FormLabel>INTERVENANT</FormLabel>
          <ContractorSelectControlled
            id="mission-custoProvid-select-contractor"
            disabled={isDisabledOnUpdate}
            control={control}
            name="contractor"
            width="100%"
            placeholder="Rechercher un nom, prénom…"
          />
          {!isDisabledOnUpdate && (
            <Link
              iconLeft={<AddIcon />}
              mt={10}
              onClick={() =>
                showAddContractorModal().then(c => {
                  c.uuid && setValue('contractor', c.uuid);
                })
              }
            >
              Ajouter un intervenant
            </Link>
          )}
        </Box>
      )}
      {showAttachmentMessage && (
        <Box marginBottom={25}>
          <BlocInformation>
            <p>
              {`L'établissement et le contact client que vous avez sélectionnés ne sont pas rattachés.
            En validant le formulaire en cours vous validerez également leur rattachement.`}
            </p>
          </BlocInformation>
        </Box>
      )}
      <Button
        data-cy="submit-select-customerOrProvider"
        key="submit"
        type="submit"
        isLoading={loading}
        isDisabled={
          (type === 'PROVIDER' && !contractor) ||
          !establishment ||
          !contact ||
          (type === 'CUSTOMER' &&
            REACT_APP_MARKET_REQUIRED === true &&
            !marketId)
        }
      >
        Enregistrer
      </Button>
    </form>
  );
};
