import { Box, Flex, BoxProps } from 'components/ui';
import { ChevronDownIcon, ChevronUpIcon } from 'components/ui/icons';
import React, { ReactNode, useState } from 'react';
import { Theme } from 'styles';
export const Accordion = ({
  childrenTop,
  children,
  defaultOpen,
  propsBar,
  openOnTop,
  iconOpen,
  disableOpen,
}: {
  childrenTop: ReactNode;
  children: any;
  defaultOpen?: boolean;
  propsBar?: BoxProps;
  openOnTop?: boolean;
  iconOpen?: boolean;
  disableOpen?: boolean;
}) => {
  const [open, setOpen] = useState(defaultOpen);
  return (
    <Box mb={10} width={1 / 1}>
      <Flex
        width={1 / 1}
        height={'52px'}
        backgroundColor="#edf3ff"
        borderRadius="8px"
        pt={'12px'}
        pb={'12px'}
        {...propsBar}
        borderBottomLeftRadius={open ? '0px' : propsBar?.borderRadius}
        borderBottomRightRadius={open ? '0px' : propsBar?.borderRadius}
        onClick={() => (openOnTop && !disableOpen ? setOpen(!open) : false)}
        cursor={openOnTop && !disableOpen ? 'pointer' : 'initial'}
      >
        {childrenTop}
        <Box
          ml={'5px'}
          mr={'5px'}
          cursor={!disableOpen ? 'pointer' : 'initial'}
          onClick={() => (!disableOpen ? setOpen(!open) : false)}
        >
          <Box hidden={disableOpen}>
            {open ? (
              <ChevronUpIcon fill={Theme?.colors?.primary?.default} />
            ) : iconOpen ? (
              <Box width={24}></Box>
            ) : (
              <ChevronDownIcon fill={Theme?.colors?.primary?.default} />
            )}
          </Box>
        </Box>
      </Flex>

      <Box width={1 / 1} hidden={!open}>
        {children}
      </Box>
    </Box>
  );
};
export default Accordion;
