import {
  EAdditionalServiceType,
  EMissionStatus,
  EPriceUnit,
} from '@freelancelabs/teoreme-commons';
import {
  useMissionFindOne,
  missionUpdateOne,
  PRICE_UNIT_SELECT,
  removeOffsetDate,
} from '@commons';
import { queryClient } from '@commons';
import {
  Input,
  Row,
  Flex,
  Box,
  Text,
  Button,
  FormControl,
  DatePickerControlled,
  StaticSelectControlled,
  MissionSelectControlled,
} from 'components/ui';
import { useForm } from 'react-hook-form';
import React, { useState } from 'react';
import { create } from 'react-modal-promise';
import { ModalFrame, ModalProps } from '../ModalFrame';
type FormValues = {
  validityStart: Date;
  validityEnd: Date;
  mission: string;
  type: EAdditionalServiceType;
  unit: EPriceUnit;
  price: number;
  paidByProvider: boolean;
  currency: string;
};
type Props = ModalProps & {
  beforeValidation?: () => void;
  establishmentId: string;
};

export const AddRCPROModal = ({
  onResolve,
  isOpen,
  establishmentId,
}: Props) => {
  const [loading, setLoading] = useState(false);
  const {
    watch,
    register,
    handleSubmit,
    control,
    formState: { errors, isDirty },
  } = useForm<FormValues>({
    defaultValues: {},
  });
  const onSubmit = async (formValues: FormValues) => {
    setLoading(true);
    const submitData = {
      reference: formValues.mission,
      mission: {
        additionalServices: {
          add: [
            {
              type: EAdditionalServiceType.PROFESSIONAL_INDEMNITY_INSURANCE,

              validityEnd: formValues.validityEnd,
              validityStart: removeOffsetDate(formValues.validityStart),
              unit: formValues.unit,
              price: Number(formValues.price),
              paidByProvider: true,
              currency: 'EUR',
            },
          ],
        },
      },
    };
    try {
      //@ts-ignore
      await missionUpdateOne(submitData);
      queryClient.refetchQueries({ queryKey: [establishmentId] });
    } catch (e) {
      //
    }
    onResolve(true);
    setLoading(false);
  };

  const selectedUnit = watch('unit');
  const mission = watch('mission');
  const { data: missionSelected } = useMissionFindOne(mission);
  const checkPrice = (price: number): string | undefined => {
    switch (selectedUnit) {
      case EPriceUnit.PERCENT_INVOICE:
        if (price <= 0 || price > 100) {
          return 'Compris entre 0 & 100';
        } else {
          return undefined;
        }
      default:
        if (price <= 0) {
          return 'Supérieur à 0';
        } else {
          return undefined;
        }
    }
  };
  const validityStart = watch('validityStart');
  return (
    <ModalFrame
      isOpen={isOpen}
      onClose={() => onResolve(false)}
      width={870}
      closeIcon
    >
      <Box>
        <Text variant="h3" mb={20}>
          Ajouter une RC Pro
        </Text>
        <Text variant="p" mb={20}>
          Afin de déterminer la mission pour laquelle le montant de la RC Pro va
          être prélevé, merci de sélectionner une mission en cours. La période
          de souscription à la RC Pro ne pourra être en dehors des dates de
          début et de fin de ladite mission.
        </Text>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box>
            <FormControl label=" " errorMessage={errors?.mission?.message}>
              <MissionSelectControlled
                control={control}
                name="mission"
                filter={{
                  'provider.establishment.uuid': {
                    $regex: establishmentId,
                    $options: 'i',
                  },
                  $or: [
                    {
                      $expr: { $lte: ['$endAt', new Date()] },
                      status: EMissionStatus.VALIDATED,
                    },
                    { status: EMissionStatus.DRAFT },
                  ],
                }}
                width="100%"
                referenceValue="uuid"
                placeholder="Rechercher..."
                rules={{ required: 'Ce champs est requis' }}
              />
            </FormControl>
          </Box>

          {mission && mission !== null && (
            <Flex>
              <Row spacing={20} width={1 / 2} mt={20}>
                <FormControl
                  label="MONTANT ( HT )"
                  required
                  errorMessage={errors?.price?.message}
                >
                  <Input
                    isDisabled={missionSelected === null}
                    isFullWidth
                    {...register('price', {
                      validate: (value: any) => checkPrice(Number(value)),
                    })}
                    type="number"
                    maxDecimal={2}
                    step="0.01"
                  />
                </FormControl>
                <FormControl
                  label="Unité"
                  required
                  errorMessage={errors?.unit?.message}
                >
                  <StaticSelectControlled
                    isDisabled={missionSelected === null}
                    control={control}
                    name="unit"
                    options={PRICE_UNIT_SELECT}
                    rules={{ required: 'Ce champ est requis' }}
                    placeholder=""
                  />
                </FormControl>
              </Row>
              <Row spacing={20} width={1 / 2} ml={20} mt={20}>
                <FormControl
                  errorMessage={errors?.validityStart?.message}
                  label="Souscrire à partir du"
                  required
                >
                  <DatePickerControlled
                    disabled={missionSelected === null}
                    control={control}
                    name="validityStart"
                    minDate={missionSelected && missionSelected?.startAt}
                    maxDate={missionSelected && missionSelected?.endAt}
                    rules={{ required: 'Ce champs est requis' }}
                    valueName="selected"
                    dateFormat="MM/yyyy"
                    showMonthYearPicker
                    showFullMonthYearPicker
                  />
                </FormControl>
                <FormControl
                  label="Jusqu'au"
                  required
                  errorMessage={errors?.validityEnd?.message}
                >
                  <DatePickerControlled
                    control={control}
                    name="validityEnd"
                    minDate={
                      validityStart
                        ? validityStart
                        : missionSelected && missionSelected?.startAt
                    }
                    maxDate={missionSelected && missionSelected?.endAt}
                    rules={{ required: 'Ce champs est requis' }}
                    valueName="selected"
                    dateFormat="MM/yyyy"
                    showMonthYearPicker
                    showFullMonthYearPicker
                  />
                </FormControl>
              </Row>
            </Flex>
          )}
          <Button type="submit" isLoading={loading} isDisabled={!isDirty}>
            Ajouter
          </Button>
        </form>
      </Box>
    </ModalFrame>
  );
};

export const showAddRCPROModal = create<Props, boolean>(AddRCPROModal);
