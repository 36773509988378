import { TopBar } from 'components/TopBar';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ClientsContacts } from './Contacts';
import { ClientsEstablishments } from './Establishments';
import { SendEmail } from './SendEmail';
export const Clients = () => {
  return (
    <>
      <TopBar />
      <Switch>
        <Route exact path="/clients">
          <Redirect to="/clients/contacts" />
        </Route>
        <Route path="/clients/contacts/send-email">
          <SendEmail />
        </Route>
        <Route path="/clients/contacts">
          <ClientsContacts />
        </Route>
        <Route path="/clients/establishments">
          <ClientsEstablishments />
        </Route>
      </Switch>
    </>
  );
};
