import { EMissionStatus, IJoinedMission } from '@freelancelabs/teoreme-commons';
import { ALink } from 'components/ALink';
import {
  Box,
  Flex,
  Itemize,
  Link,
  Menu,
  MenuItem,
  Spinner,
  Text,
} from 'components/ui';
import {
  CheckIcon,
  CloseIcon,
  DeleteIcon,
  DotsIcon,
  EyeIcon,
  ExclamationCircleIcon,
} from 'components/ui/icons';
import { checkDisplayMenuList } from 'helpers/business';
import { useMissionActions } from 'hooks/missionHooks';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { Theme } from 'styles';
import { Tooltip as ReactTooltip } from 'react-tooltip';

export const MissionActions = ({
  mission,
}: {
  mission: Itemize<IJoinedMission>;
}) => {
  const history = useHistory();
  type MissionActionsReturn = {
    onDisableMission: () => Promise<boolean>;
    onDeleteMission: () => Promise<boolean>;
    onValidateMission: () => Promise<boolean>;
    onReactivateMission: () => Promise<void>;
    loading: boolean;
  };

  const { onDeleteMission, onDisableMission, onValidateMission, loading } =
    useMissionActions(mission) as MissionActionsReturn;

  const missionLink = `/delivery/missions/${mission.reference}`;
  const missionStatus = mission.status;

  const canAbortMission = (missionStatus: EMissionStatus): boolean => {
    const allowebAbortStatus = [EMissionStatus.DRAFT, EMissionStatus.VALIDATED];
    return allowebAbortStatus.includes(missionStatus);
  };

  return (
    <Flex alignItems="center">
      {loading && <Spinner />}
      {mission?.isSfFlowError && (
        <>
          <a
            id={'tooltip_fcom_warning' + mission.reference}
            data-tip=""
            data-iscapture="true"
          >
            <ExclamationCircleIcon
              fill={Theme?.colors?.warning?.default}
              style={{ marginRight: 3 }}
            />
          </a>
          <ReactTooltip
            place={'top'}
            anchorSelect={'#tooltip_fcom_warning' + mission.reference}
            variant={'dark'}
            float={false}
            style={{ backgroundColor: Theme.colors.warning.default }}
          >
            <Text color="white" fontSize={11}>
              Une erreur est survenue lors de la création de cette mission via
              le flux Salesforce, sa modification n’est donc pour l’instant pas
              possible.
              <br />
              Cette mission sera de nouveau modifiable dès que l’erreur sera
              corrigée.
              <br />
              Pour débloquer cette mission, veuillez contacter
              salesforce@freelance.com
            </Text>
          </ReactTooltip>
        </>
      )}
      <ALink href={missionLink}>
        <Link iconLeft={<EyeIcon />}></Link>
      </ALink>
      {!loading && checkDisplayMenuList(mission) && (
        <Box>
          <Menu
            align="right"
            ml={10}
            menuItems={
              <>
                {/* <MenuItem>
                  <ALink href={missionLink}>
                    <Link iconLeft={<EyeIcon />}>Voir la fiche</Link>
                  </ALink>
                </MenuItem> */}
                {missionStatus === EMissionStatus.DRAFT && (
                  <MenuItem>
                    <Link
                      onClick={() =>
                        onValidateMission().then(
                          c => c && history.push('/delivery/missions/validated')
                        )
                      }
                      iconLeft={<CheckIcon />}
                    >
                      <div>Valider la mission</div>
                    </Link>
                  </MenuItem>
                )}

                {canAbortMission(missionStatus) && (
                  <>
                    <MenuItem>
                      <Link
                        iconLeft={<CloseIcon />}
                        onClick={() => onDisableMission()}
                      >
                        Annuler la mission
                      </Link>
                    </MenuItem>
                  </>
                )}
                {missionStatus === EMissionStatus.DRAFT &&
                  mission.createdFromSFFlow !== true && (
                    <MenuItem>
                      <Link onClick={onDeleteMission} iconLeft={<DeleteIcon />}>
                        Supprimer la mission
                      </Link>
                    </MenuItem>
                  )}

                {/* {missionStatus === EMissionStatus.ABORTED && (
                  <MenuItem>
                    <Link
                      iconLeft={<RefreshIcon />}
                      onClick={() =>
                        onReactivateMission().then(() =>
                          history.push('/delivery/missions/draft')
                        )
                      }
                    >
                      Réactiver la mission
                    </Link>
                  </MenuItem>
                )} */}

                {/* {missionStatus === EMissionStatus.VALIDATED && (
                  <MenuItem>
                    <Link iconLeft={<ArchiveIcon />}>Archiver la mission</Link>
                  </MenuItem>
                )} */}
              </>
            }
          >
            <DotsIcon fill={Theme?.colors?.primary?.default} fontSize={20} />
          </Menu>
        </Box>
      )}
    </Flex>
  );
};
