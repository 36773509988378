import { IJoinedMission } from '@freelancelabs/teoreme-commons';
import { Roles as RolesTypes } from '@freelancelabs/inside-commons/dist/lib/models/enum/role.enum';
import { getFullName, missionBulkUpdate, queryClient, useMe } from '@commons';
import { Link, Flex, Box, Text, Button, Status } from 'components/ui';
import React, { useState } from 'react';
import { create } from 'react-modal-promise';
import { ModalFrame, ModalProps } from '../ModalFrame';
import { UserSelect } from 'components/selects/UserSelect';
import { CloseIcon } from 'components/ui/icons';
import { useListMissionStore } from 'store';
type Props = ModalProps & {
  beforeValidation?: () => void;
  mission?: IJoinedMission;
  bulkUpdateFiler: any;
  selectedMissionsCount?: number;
  isTeamLeader?: boolean;
  isSelectedFromManager?: boolean;
  hasSelectedMissions?: boolean;
  fromManager?: any;
};

export const BulkUpdateMissionManagerModal = ({
  onResolve,
  isOpen,
  mission,
  bulkUpdateFiler,
  isTeamLeader,
  selectedMissionsCount,
  isSelectedFromManager,
  hasSelectedMissions,
}: Props) => {
  const { managerSelected, managerSelectedCompleteObject } =
    useListMissionStore();
  const { me } = useMe();

  const [contactSelected, setContactSelected] = React.useState<
    string | undefined
  >(mission?.accountManager?.cognitoUserId);
  const [fullUserSelected, setFullUserSelected] = React.useState<
    any | undefined
  >();

  const formatQuery = () => {
    const bulkQuery = {
      bulkRequest: {
        name: 'CHANGE_ACCOUNT_MANAGER',
        changeAcc: {
          to: contactSelected,
          from: {
            ...bulkUpdateFiler,
          },
        },
      },
    };

    return bulkQuery;
  };

  const [loading, setLoading] = useState(false);
  const isAllowedBulkUpdated = () => {
    if (!hasSelectedMissions) return false;
    // if (!isTeamLeader) return false;
    if (!isSelectedFromManager) return false;

    return true;
  };
  const onSubmit = async () => {
    setLoading(true);
    const bulkData = formatQuery();

    try {
      await missionBulkUpdate(bulkData as any);
      queryClient.refetchQueries({ queryKey: ['missions'], type: 'active' });
      onResolve(true);
    } catch (e) {}

    setLoading(false);
  };
  return (
    <ModalFrame
      isOpen={isOpen}
      onClose={() => onResolve(false)}
      width={600}
      closeIcon
    >
      <Box>
        <Text variant="h2">Modifier en masse le responsable de mission</Text>
        {!isAllowedBulkUpdated() ? (
          <Box>
            <Box ml={20} mt={20}>
              {
                <>
                  {!isSelectedFromManager && (
                    <Status
                      ml={10}
                      mt={10}
                      variantColor={'warning'}
                      fontSize={'17px'}
                    >
                      Sélectionnez un manager dans le filtre
                    </Status>
                  )}
                  {!hasSelectedMissions && (
                    <Status
                      ml={10}
                      mt={10}
                      variantColor={'warning'}
                      fontSize={'17px'}
                    >
                      Sélectionnez des missions à modifier{' '}
                    </Status>
                  )}
                </>
              }
            </Box>
          </Box>
        ) : (
          <Box>
            <Box width={'375px'} mt={20}>
              <UserSelect
                placeholder="Responsable de la mission"
                role={RolesTypes.ACCOUNT_MANAGER}
                onChange={value => setContactSelected(value)}
                onChangeCompleteObject={value => {
                  setFullUserSelected(value);
                }}
                filter={{
                  deactivated: { $ne: true },
                  cognitoUserId: {
                    $ne: managerSelected,
                  },
                }}
                value={contactSelected}
              />
            </Box>
            <Box mt={10}>
              <Text fontSize={'14px'} variant="h3">
                {fullUserSelected && (
                  <>
                    Vous allez affecter {selectedMissionsCount} mission(s) de{' '}
                    {getFullName(managerSelectedCompleteObject ?? me)}
                    <br />à {getFullName(fullUserSelected)}
                  </>
                )}
              </Text>
            </Box>
            <Flex mt={20} width={'250px'} justifyContent="space-between">
              <Button
                isLoading={loading}
                isDisabled={!contactSelected}
                onClick={() => onSubmit()}
                type="button"
              >
                Enregistrer
              </Button>
              <Link
                mt={20}
                iconLeft={<CloseIcon />}
                onClick={() => onResolve(false)}
              >
                Annuler
              </Link>
            </Flex>
          </Box>
        )}
      </Box>
    </ModalFrame>
  );
};

export const showBulkUpdateMissionManagerModal = create<Props, boolean>(
  BulkUpdateMissionManagerModal
);
