import { Box, boxProperties, BoxProps, Flex, Text } from 'components/ui';
import * as React from 'react';
import styled, { useTheme } from 'styled-components';
import { Theme } from 'styles';
export type StepType = {
  index: number;
  title: string;
  subTitle: string;
  validated: boolean;
};
export type StepperProps = {
  steps: Array<StepType>;
  currentStep: number;
  onSelectStep?: (step: StepType) => void;
};
type CircleProps = {
  children?: React.ReactNode;
  variantColor?: 'primary' | 'grey' | 'error' | 'success' | 'warning' | 'grey';
};
export const Stepper = ({ steps, currentStep, onSelectStep }: StepperProps) => {
  const Theme = useTheme();

  return (
    <Flex
      width={1 / 1}
      zIndex={100}
      justifyContent="space-around"
      borderBottom={'6px solid #eaeefc'}
    >
      {steps.map((step, index) => (
        <Box
          key={index}
          mt={20}
          mb={20}
          cursor={onSelectStep && step?.validated ? 'pointer' : 'not-allowed'}
          onClick={() =>
            onSelectStep && step?.validated ? onSelectStep(step) : false
          }
        >
          <Text
            variant="p"
            textAlign="center"
            color={Theme.colors?.darkBlue}
            fontWeight={'bold'}
          >
            {step.title}
          </Text>
          <Text
            variant="h3"
            textAlign="center"
            color={Theme.colors.primary.default}
          >
            {step.subTitle}
          </Text>
          <Flex justifyContent="center">
            <Circle
              color={
                index <= currentStep ? Theme.colors.primary.default : '#eaeefc'
              }
            />
          </Flex>
        </Box>
      ))}
      <Box
        position="absolute"
        left={0}
        bottom={-6}
        width={
          currentStep + 1 === steps.length
            ? (currentStep * 2.5) / 6
            : (currentStep * 3) / 6
        }
        borderBottom={`6px solid ${Theme.colors.primary.default}`}
      />
    </Flex>
  );
};
const Circle = styled.span<BoxProps & CircleProps>`
display: flex;
align-items: center;
font-size: 11px;
color: ${props => props.theme.colors[props.variantColor || 'primary'].default};
${boxProperties}

&:before {
  content: '';
  position: absolute;
  top:13px;
  left:calc(50% - (20px / 2));
  height: 20px;
  width: 20px;
  min-width: 20px;
  border-radius: 100%;
  background-color: ${props =>
    props.color ? props.color : Theme.colors.primary.default};
`;
export default Stepper;
