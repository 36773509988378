import * as React from 'react';
import { LockIcon } from 'components/ui/icons';
import { Box, Flex, Link, Text } from 'components/ui';
import { PUBLIC_APP_TEAM_CONNECTEED } from '@commons';
type GetAccessProps = {
  customText?: string;
  top?: number;
};
export const GetAccess = ({ customText, top }: GetAccessProps) => {
  return (
    <Flex
      top={top ? top : 0}
      left={0}
      bottom={0}
      right={0}
      alignItems="center"
      justifyContent="center"
      position="fixed"
    >
      <Flex alignItems="center" justifyContent="center">
        <Box marginRight={20}>
          <LockIcon width={200} height={200} />
        </Box>
        <Box>
          <Text variant="h1">Droits d'accès limités</Text>
          <Text variant="p" maxWidth={400}>
            Vos droits d'accès à Connecteed n'ont pas encore été définis.
            Veuillez contacter <Link>{PUBLIC_APP_TEAM_CONNECTEED}</Link> pour
            demander l'activation de votre compte.
          </Text>
        </Box>
      </Flex>
    </Flex>
  );
};
export default GetAccess;
