import {
  ICompanyUpdateParams,
  IEstablishmentBase,
  ICompanyGetResponse,
} from '@freelancelabs/teoreme-commons';
import { useEstablishmentUpdateOne } from '@commons'; // moved
import { Box } from 'components/ui';
import { InvoiceContactForm } from 'forms/InvoiceContactForm';
import React from 'react';
import { create } from 'react-modal-promise';
import { queryClient } from '@commons';
import { ModalFrame, ModalProps } from './ModalFrame';

type Props = ModalProps & {
  beforeValidation?: () => void;
  establishment?: IEstablishmentBase;
};

export const InvoiceContactModal = ({
  onResolve,
  isOpen,
  establishment,
}: Props) => {
  const { mutateAsync: updateEstablishment } = useEstablishmentUpdateOne();

  const onUpdateInvoiceContact = async (
    establishmentData: ICompanyUpdateParams & { uuid: string }
  ) => {
    try {
      establishmentData.uuid = `${establishment?.uuid || ''}`;
      const newEstablishment = await updateEstablishment(establishmentData);
      queryClient.refetchQueries({ queryKey: ['establishment'] });
      queryClient.refetchQueries({
        queryKey: ['establishments'],
        type: 'active',
      });
      if (newEstablishment) onResolve(newEstablishment);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <ModalFrame
      isOpen={isOpen}
      onClose={() => onResolve(false)}
      width={850}
      closeIcon
    >
      {establishment && (
        <Box mt={20}>
          <InvoiceContactForm
            defaultValues={{ establishment }}
            onSubmit={onUpdateInvoiceContact}
          />
        </Box>
      )}
    </ModalFrame>
  );
};

export const showInvoiceContactModal = create<Props, ICompanyGetResponse>(
  InvoiceContactModal
);
