import {
  checkFormPassword,
  checkValidPassword,
  changePassword,
} from '@commons';
import { FlatUser } from '@freelancelabs/teoreme-commons';
import { Box, Button, FormControl, Input, Text } from 'components/ui';
import { useShowMessage } from 'hooks/useShowMessage';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { create } from 'react-modal-promise';
import { ModalFrame, ModalProps } from '../ModalFrame';
type FormValues = {
  currentPassword: string;
  newPassword: string;
  confirmeNewPassword: string;
};
type Props = ModalProps & {
  user: FlatUser;
  beforeValidation?: () => void;
};

export const UpdatePasswordModal = ({ onResolve, isOpen, user }: Props) => {
  const [loading, setLoading] = useState(false);
  const showMessage = useShowMessage();
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {},
  });
  const onSubmit = async (data: FormValues) => {
    setLoading(true);
    try {
      let onError = false;
      if (data.newPassword === data.confirmeNewPassword) {
        if (checkValidPassword(data.newPassword)) {
          setError('newPassword', { message: undefined });
          setError('confirmeNewPassword', { message: undefined });
        } else {
          onError = true;
          setError('newPassword', {
            message: "Le format du mot de passe n'est pas valide",
          });
          setError('confirmeNewPassword', {
            message: "Le format du mot de passe n'est pas valide",
          });
        }
      } else {
        onError = true;
        setError('newPassword', {
          message: 'Les mots de passe ne sont pas identiques',
        });
        setError('confirmeNewPassword', {
          message: 'Les mots de passe ne sont pas identiques',
        });
      }
      if (onError === false) {
        await changePassword({
          oldPassword: data.currentPassword,
          newPassword: data.newPassword,
        });
        onResolve(true);
        showMessage('success', 'Votre mot de passe à été modifié');
      }
    } catch (err) {
      //onResolve(false);
    }
    setLoading(false);
  };
  return (
    <ModalFrame
      isOpen={isOpen}
      onClose={() => onResolve(true)}
      width={400}
      closeIcon
    >
      <Box mt={20}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Text variant="h2" mb={10}>
            Modifer votre mot de passe
          </Text>

          <FormControl label="Mot de passe actuel" required>
            <Input
              isFullWidth
              type="password"
              {...register('currentPassword', {
                required: 'Ce champs est requis',
              })}
            />
          </FormControl>

          <FormControl
            label="Nouveau mot de passe"
            required
            errorMessage={errors?.newPassword?.message}
          >
            <Input
              isFullWidth
              type="password"
              {...register('newPassword', {
                validate: (value: any) => checkFormPassword(value),
              })}
            />
          </FormControl>

          <FormControl
            errorMessage={errors?.confirmeNewPassword?.message}
            label="Confirmer votre nouveau mot de passe"
            required
          >
            <Input
              isFullWidth
              type="password"
              {...register('confirmeNewPassword', {
                validate: (value: any) => checkFormPassword(value),
              })}
            />
          </FormControl>

          <Box width={1 / 1}>
            <Button width={1 / 1} type="submit" isLoading={loading}>
              Enregistrer
            </Button>
          </Box>
        </form>
      </Box>
    </ModalFrame>
  );
};

export const showUpdatePasswordModal = create<Props, FlatUser>(
  UpdatePasswordModal
);
