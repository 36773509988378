import { useAmplifyLogout, checkUserHasRole, ERoles, useMe } from '@commons'; // moved
import { ALink } from 'components/ALink';
import { showDialogModal } from 'components/modals/DialogModal';
import { Box, Flex, Menu, MenuItem, Tab } from 'components/ui';
import { LogoutIcon, DotsIcon, SettingsIcon } from 'components/ui/icons';
import * as React from 'react';
import styled, { useTheme } from 'styled-components';
import NotificationsBell from './NotificationsIcon';
import { useNotificationsStore } from 'store/notifications';
import UserButton from './UserButton';
import { TaskInfo } from './TaskInfo';
import { queryClient } from '@commons';
import { useTranslationFormat } from 'hooks/useTranslateFormat';
import { useHistory } from 'react-router-dom';
import { Theme } from 'styles';

export const Container = styled.div`
  height: 80px;

  display: flex;
  align-items: stretch;

  background-color: ${p => p.theme.colors.white};
  box-shadow: rgba(180, 198, 211, 1) 0 2px 3px 0;
  margin-bottom: 3px;
`;
Container.displayName = 'TopBarContainer';

export const Right = styled.div`
  margin-left: 30px;
  flex: 1 1 auto;

  display: flex;
  align-items: stretch;
  justify-content: flex-end;
`;

export const TopBar = () => {
  const { newMessage, updateNotifications } = useNotificationsStore();
  //const { me } = useMe();
  //@ts-ignore
  const { me } = useMe();
  const history = useHistory();
  const { mutateAsync: logout } = useAmplifyLogout();
  const t = useTranslationFormat();
  const theme = useTheme();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const adminClientItem = (
    <>
      <Menu
        openOnClick={false}
        menuItems={
          <>
            <MenuItem>
              <ALink href={'/clients/contacts'}>Contacts</ALink>
            </MenuItem>
            <MenuItem>
              <ALink href={'/clients/establishments/active'}>
                Établissements
              </ALink>
            </MenuItem>
          </>
        }
      >
        <Tab href={'/clients'}>
          {t('texts:customer', 'capitalize-first', true)}
        </Tab>
      </Menu>
    </>
  );
  const legalsItems = (
    <>
      {/* <Tab href="/legals/dashboard">
        {t('texts:dashboard', 'capitalize-first')}
      </Tab> */}
      <Menu
        openOnClick={false}
        menuItems={
          <>
            <MenuItem>
              <ALink href={'/legals/contracts'}>Modifier les contrats</ALink>
            </MenuItem>
          </>
        }
      >
        <Tab href={'/legals/contracts'}>Contrats</Tab>
      </Menu>
    </>
  );
  const items = (
    <>
      <Tab href="/dashboard">{t('texts:dashboard', 'capitalize-first')}</Tab>

      <Menu
        openOnClick={false}
        menuItems={
          <>
            <MenuItem>
              <ALink href={'/clients/contacts'}>Contacts</ALink>
            </MenuItem>
            <MenuItem>
              <ALink href={'/clients/establishments/active'}>
                Établissements
              </ALink>
            </MenuItem>
          </>
        }
      >
        <Tab href={'/clients'}>
          {t('texts:customer', 'capitalize-first', true)}
        </Tab>
      </Menu>

      <Menu
        openOnClick={false}
        menuItems={
          <>
            <MenuItem>
              <ALink href={'/providers/contacts/sent-invitations'}>
                Contacts
              </ALink>
            </MenuItem>

            <MenuItem>
              <ALink href={'/providers/establishments/active'}>
                Établissements
              </ALink>
            </MenuItem>

            <MenuItem>
              <ALink href={'/providers/contracts'}>Contrats</ALink>
            </MenuItem>

            <MenuItem>
              <ALink href={'/providers/contractors/active'}>Intervenants</ALink>
            </MenuItem>
            <MenuItem>
              <ALink href={'/providers/dpae'}>DPAE</ALink>
            </MenuItem>
            <MenuItem>
              <ALink href={'/providers/validate-bank-account'}>
                Coordonnées bancaires
              </ALink>
            </MenuItem>
          </>
        }
      >
        <Tab href={'/providers'}>
          {t('texts:provider', 'capitalize-first', true)}
        </Tab>
      </Menu>

      <Menu
        openOnClick={false}
        menuItems={
          <>
            <MenuItem>
              <ALink href={'/delivery/missions'}>Missions</ALink>
            </MenuItem>
            <MenuItem>
              <ALink href={'/delivery/purchase-orders'}>
                Bons de commande fournisseurs
              </ALink>
            </MenuItem>
          </>
        }
      >
        <Tab href={'/delivery'}>
          {t('texts:benefit', 'capitalize-first', true)}
        </Tab>
      </Menu>

      <Menu
        openOnClick={false}
        menuItems={
          <>
            <MenuItem>
              <ALink href={'/orders/cra/to-be-validated'}>CRA</ALink>
            </MenuItem>
            <MenuItem>
              <ALink href={'/orders/milestones/to-be-validated'}>Jalons</ALink>
            </MenuItem>
            <MenuItem>
              <ALink href={'/orders/additional-activities'}>
                Prestation complémentaire & Frais
              </ALink>
            </MenuItem>
            <MenuItem>
              <ALink
                href={'/orders/invoices/provider/time-spent/to-be-validated'}
              >
                Factures fournisseurs
              </ALink>
            </MenuItem>
            <MenuItem>
              <ALink href={'/orders/invoices/customer'}>Factures clients</ALink>
            </MenuItem>
            <MenuItem>
              <ALink href={'/orders/invoices/customer/credit-note/validated'}>
                Avoirs clients
              </ALink>
            </MenuItem>
            <MenuItem>
              <ALink href={'/orders/invoices/provider/credit-note/validated'}>
                Avoirs fournisseurs
              </ALink>
            </MenuItem>
          </>
        }
      >
        <Tab href={'/orders'}>{t('texts:order', 'capitalize-first', true)}</Tab>
      </Menu>
      <Menu
        openOnClick={false}
        menuItems={
          <>
            <MenuItem>
              <ALink href={'/others/contacts/active'}>Autres contacts</ALink>
            </MenuItem>
          </>
        }
      >
        <Tab href={'/orders'}>
          {' '}
          <DotsIcon fill={theme.colors.grey.default} fontSize={20} />
        </Tab>
      </Menu>
    </>
  );

  const onLogout = () => {
    showDialogModal({
      closeIcon: true,
      confirmLabel: 'Déconnexion',
      title: 'Etes-vous sûr(e) de vouloir vous déconnecter ? ',
      text: 'Toutes vos données en cours de saisie seront perdues.',

      beforeValidation: async () => {
        await logout();
        history.push('/login');
      },
    });
  };

  const disableNewNotification = React.useCallback(async (): Promise<void> => {
    updateNotifications({
      newMessage: false,
    });
  }, [updateNotifications]);
  React.useEffect(() => {
    if (!me) {
      queryClient.refetchQueries({ queryKey: ['me'] });
    }
  }, [me]);
  // const TaskLightCustom = (
  //   <TaskLightIcon
  //     hasTerminatedTask={true}
  //     fill={Theme?.colors?.grey?.default}
  //   />
  // );
  return (
    <Container>
      <Flex paddingLeft={10} alignItems="center">
        {/* <Input
          type="search"
          name="search"
          placeholder="Rechercher..."
          width={'400px'}
        /> */}
      </Flex>
      <Right>
        {/* {checkUserHasRole(me, 'PORTAL_CLIENT_ADMIN') ? adminClientItem : null} */}
        {checkUserHasRole(me, ERoles?.ACCOUNT_MANAGER) ||
        checkUserHasRole(me, 'MANAGER_READ_ONLY')
          ? items
          : null}
        {checkUserHasRole(me, 'LEGAL_SQUAD') && legalsItems}
        {me &&
          (checkUserHasRole(me, ERoles?.ADMIN) ||
            checkUserHasRole(me, ERoles?.ACCOUNT_MANAGER) ||
            checkUserHasRole(me, 'MANAGER_READ_ONLY') ||
            checkUserHasRole(me, 'TEAM_LEADER')) && (
            <>
              <Box borderLeft="1px solid #e7e7e7" marginRight={20} />
              <Flex alignItems="center">
                <Box cursor="pointer">
                  <TaskInfo />
                </Box>
              </Flex>
              <Box
                borderLeft="1px solid #e7e7e7"
                marginRight={20}
                marginLeft={20}
              />
              <Flex alignItems="center">
                <ALink
                  href={'/notifications'}
                  onClick={() => disableNewNotification()}
                >
                  <NotificationsBell hasUnread={newMessage} />
                </ALink>
              </Flex>
            </>
          )}
        <Box borderLeft="1px solid #e7e7e7" margin="0 20px" />

        <UserButton
          firstName={me?.firstName || ''}
          lastName={me?.lastName || ''}
        />
        {(checkUserHasRole(me, 'SUPPORT') ||
          checkUserHasRole(me, ERoles?.ADMIN)) && (
          <>
            <Box borderLeft="1px solid #e7e7e7" ml={10} />
            <Flex justifyContent={'center'} alignItems="center">
              <Menu
                align="right"
                openOnClick={false}
                menuItems={
                  <>
                    <MenuItem>
                      <ALink href={'/support/documents/provider'}>
                        Gestion des documents
                      </ALink>
                    </MenuItem>
                    {checkUserHasRole(me, ERoles?.ADMIN) && (
                      <MenuItem>
                        <ALink href={'/admin/users'}>
                          Gestion des utilisateurs
                        </ALink>
                      </MenuItem>
                    )}
                    {checkUserHasRole(me, ERoles?.ADMIN) && (
                      <MenuItem>
                        <ALink href={'/admin/tasks'}>Gestion des Tâches</ALink>
                      </MenuItem>
                    )}
                    {checkUserHasRole(me, ERoles?.ADMIN) && (
                      <MenuItem>
                        <ALink href={'/admin/settings'}>Paramètres</ALink>
                      </MenuItem>
                    )}
                  </>
                }
              >
                <Box ml={20}>
                  <SettingsIcon fill={Theme?.colors?.grayBlue} />
                </Box>
              </Menu>
            </Flex>
          </>
        )}
        <Box borderLeft="1px solid #e7e7e7" marginLeft={20} />
        <Flex
          padding="0 20px"
          alignItems="center"
          onClick={onLogout}
          cursor="pointer"
        >
          <LogoutIcon color="#819cad" fontSize={20} id="logout-icon" />
        </Flex>
      </Right>
    </Container>
  );
};
TopBar.displayName = 'TopBar';

export default TopBar;
