import {
  contractorCreateOne,
  establishmentFindMany,
  formatAllObject,
  getFullName,
  getTradeNameSafe,
  isPartOfEU,
  useEstablishmentCreateOne,
  useRegister,
} from '@commons';
import {
  EInvoicePaymentLabel,
  EInvoiceSendMethod,
  FlatUser,
  IContractorCreateParams,
  IEstablishmentMinifiedUsers,
} from '@freelancelabs/teoreme-commons';
import { EstablishmentStructuredCard } from 'components/cards/EstablishmentStructuredCard';
import { ConfirmNavigationModal } from 'components/modals/ConfirmNavigationModal';
import { Section } from 'components/Section';
import { StaticSelect } from 'components/selects/StaticSelect';
import { showAddContactModal } from 'components/modals/AddContactModal';
import { AddressSelect } from 'components/selects/AddressSelect';
import { showDialogModal } from 'components/modals/DialogModal';
import {
  Link,
  BlocInformation,
  Box,
  Button,
  Flex,
  FormControl,
  Input,
  Radio,
  Row,
  SpinnerBox,
  Text,
  DatePickerControlled,
  StaticSelectControlled,
  UserSelectControlled,
  TextAreaControlled,
  CheckSwitch,
} from 'components/ui';
import { AddIcon } from 'components/ui/icons';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMe } from '@commons';
import { useShowMessage } from 'hooks/useShowMessage';
import { showAddContractorModal } from 'components/modals/AddContractorModal';

type FormValues = any;
type CreateManualEstablishmentFormProps = {
  defaultValues: { siret: string };
  onSubmit?: any;
  establishmentRole?: 'PROVIDER' | 'CUSTOMER';
  noContactRequired?: boolean;
  buttonHidden?: boolean;
  onResolve?: (data: any) => void;
  isDisabled?: boolean;
};

export const CreateManualEstablishmentForm = ({
  defaultValues,
  onSubmit: onSubmitParent,
  establishmentRole,
  noContactRequired,
  buttonHidden,
  onResolve,
  isDisabled,
}: CreateManualEstablishmentFormProps) => {
  const [createWithContractor, setCreatWithContractor] = useState<
    IContractorCreateParams | undefined
  >();
  const [contactCreated, setContactCreated] = useState<FlatUser | undefined>();
  const [selectedMBAddress, setSelectedMBAddress] = React.useState<any>();
  const showMessage = useShowMessage();
  const { me } = useMe();
  const { mutateAsync: addEstablishment } = useEstablishmentCreateOne();
  const [loading, setLoading] = useState(false);
  const [checkHQloading, setCheckHQloading] = useState(false);
  const [needCreateEstablishment, setNeedCreateEstablishment] = useState(false);
  const [formStep, setFormStep] = useState(0);
  const siren = defaultValues?.siret?.slice(0, 9);
  const [headQuarterChecked, setHeadQuarterChecked] = useState<
    false | IEstablishmentMinifiedUsers[]
  >(false);
  const { register, handleSubmit, formState, control, setValue, watch, reset } =
    useForm<FormValues>({
      defaultValues: {
        ...defaultValues,
        siren: siren,
        headquarter: '0',
        address: {
          country: 'France',
        },
      },
    });
  const providerManager = watch('provider.manager');
  const headquarter = watch('headquarter');
  const country = watch('address.country');
  const { isDirty, errors, isSubmitSuccessful } = formState;

  const findHeadQuarter = async () => {
    setCheckHQloading(true);
    try {
      const hq = await establishmentFindMany({
        filterObject: {
          siren: siren,
          headquarter: true,
        },
      });
      setHeadQuarterChecked(hq?.establishments);
    } catch (err) {
      setCheckHQloading(false);
    }
    setCheckHQloading(false);
  };
  React.useEffect(() => {
    if (siren) {
      findHeadQuarter();
    }
  }, [siren]);

  React.useEffect(() => {
    if (
      headQuarterChecked &&
      headQuarterChecked?.length > 0 &&
      headquarter === undefined
    ) {
      setValue('headquarter', '0');
    }
    if (
      headquarter === '0' &&
      headQuarterChecked &&
      headQuarterChecked?.length === 0
    ) {
      setValue('siret', '');
    }
  }, [headQuarterChecked, headquarter]);
  const getCase = ():
    | 'ESTABLISHMENT'
    | 'HEADQUARTER'
    | 'HEADQUARTER_AND_ESTABLISHMENT'
    | 'HEADQUARTER_ALREADY_EXIST'
    | undefined => {
    if (headquarter === '0') {
      if (headQuarterChecked && headQuarterChecked?.length > 0) {
        if (needCreateEstablishment && formStep === 0) {
          setNeedCreateEstablishment(false);
        }
        return 'ESTABLISHMENT';
      } else {
        if (!needCreateEstablishment && formStep === 0) {
          setNeedCreateEstablishment(true);
        }
        return 'HEADQUARTER_AND_ESTABLISHMENT';
      }
    }
    if (headquarter === '1') {
      if (headQuarterChecked && headQuarterChecked?.length > 0) {
        if (needCreateEstablishment && formStep === 0) {
          setNeedCreateEstablishment(false);
        }
        return 'HEADQUARTER_ALREADY_EXIST';
      } else {
        if (needCreateEstablishment && formStep === 0) {
          setNeedCreateEstablishment(false);
        }
        return 'HEADQUARTER';
      }
    }
  };
  const formType = getCase();
  const onSubmit = async (formValues: FormValues) => {
    const values = {
      ...formValues,
    };
    const shouldMergeProofWithInvoice =
      //@ts-ignore
      formValues?.customer?.billingOptions?.shouldMergeProofWithInvoice;
    Object.keys(values).forEach((key: any) => {
      if (values[key] === '') {
        delete values[key];
      }
    });
    let submitData = {
      ...values,
      headquarter: headquarter === '0' ? false : true,
    };
    if (establishmentRole === 'CUSTOMER') {
      const billingOptions = {
        ...submitData.billingOptions,
        paymentLabel: EInvoicePaymentLabel.FIXED,
        shouldMergeProofWithInvoice: shouldJoinProofToInvoice
          ? shouldMergeProofWithInvoice
          : false,
      };
      submitData.billingOptions = billingOptions;
    }
    setLoading(true);
    try {
      if (formType !== 'HEADQUARTER_AND_ESTABLISHMENT' || formStep === 1) {
        submitData.siret = defaultValues?.siret;
        if (createWithContractor && establishmentRole === 'PROVIDER') {
          if (formStep === 0) {
            const providerContact =
              await handleCreateEstablishmentAndContactContractor(
                createWithContractor,
                submitData,
                headquarter === '1' ? false : true
              );
            setContactCreated(providerContact);
          } else {
            if (contactCreated) {
              await addEstablishment({
                ...submitData,
                provider: { manager: contactCreated?.cognitoUserId },
              });
            } else {
              await addEstablishment(submitData);
            }
          }
        } else {
          await onSubmitParent(submitData);
        }
      }
      if (formType === 'HEADQUARTER_AND_ESTABLISHMENT' && formStep === 0) {
        submitData.headquarter = true;
        if (createWithContractor && establishmentRole === 'PROVIDER') {
          const providerContact =
            await handleCreateEstablishmentAndContactContractor(
              createWithContractor,
              submitData,
              true
            );
          setContactCreated(providerContact);
        } else {
          await addEstablishment(submitData);
        }

        showMessage(
          'success',
          `Le siège social${submitData?.businessName} à bien été créé`
        );
        setFormStep(formStep + 1);
        reset({
          headquarter: '0',
          siret: '',
          siren,
          address: { country: 'France' },
          provider: { manager: createWithContractor },
        });
      }
    } catch (err) {
      //
    }

    setLoading(false);
  };
  const street = watch('address.street');
  const isAutoSendEmail = watch('customer.billingOptions.isAutoSendEmail');
  const isAutoBilling = watch('customer.billingOptions.isAutoBilling');
  const shouldJoinProofToInvoice = watch(
    'customer.billingOptions.shouldJoinProofToInvoice'
  );
  const shouldMergeProofWithInvoice = watch(
    'customer.billingOptions.shouldMergeProofWithInvoice'
  );
  const { mutateAsync: addContact } = useRegister();
  const handleCreateEstablishmentAndContactContractor = async (
    contractor: IContractorCreateParams,
    establishment: any,
    noResolve?: boolean
  ) => {
    const contact = {
      email: contractor?.email,
      civility: contractor?.contractor?.civility,
      firstName: contractor?.contractor?.firstName,
      lastName: contractor?.contractor?.lastName,
      phone: contractor?.contractor?.phone,
      jobTitle: contractor?.contractor?.jobTitle,
    };
    try {
      //step 1 :  create contact
      const providerContact = await addContact({
        user: formatAllObject(contact),
        roles: 'PROVIDER_CONTACT',
      });
      establishment.provider.manager = providerContact?.cognitoUserId;
      // step 2 : create establishment
      const providerEstablishment = await addEstablishment(establishment);

      // step 3 :  create contractor
      const newContractor = await contractorCreateOne({
        ...formatAllObject(contractor),
        establishment: providerEstablishment?.uuid,
      });
      if (onResolve) {
        showMessage(
          'success',
          `Le contact ${getFullName(
            providerContact
          )} , l'intervenant ${getFullName(
            newContractor
          )} et l'établissement ${getTradeNameSafe(
            providerEstablishment
          )} ont bien été créés`
        );
        if (!noResolve) {
          onResolve(providerEstablishment);
        }
      }
      return providerContact;
    } catch (e) {
      //
    }
  };
  useEffect(() => {
    if (createWithContractor && typeof providerManager === 'string') {
      setCreatWithContractor(undefined);
    }
  }, [providerManager, createWithContractor]);
  const sendMethod = watch('customer.billingOptions.sendMethod');
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <ConfirmNavigationModal active={isDirty && !isSubmitSuccessful} />
      {checkHQloading === true && <SpinnerBox />}
      {headQuarterChecked &&
        headQuarterChecked?.length === 0 &&
        formStep === 0 && (
          <Row spacing={20}>
            <FormControl
              label="S'agit t'il du siège social ? "
              errorMessage={errors?.rpps?.message}
            >
              <Flex>
                <Radio
                  isDisabled={formStep !== 0 || isDisabled}
                  {...register('headquarter', {
                    required: 'Ce champ est obligatoire',
                  })}
                  value={1}
                >
                  Oui
                </Radio>
                <Radio
                  isDisabled={formStep !== 0 || isDisabled}
                  {...register('headquarter', {
                    required: 'Ce champ est obligatoire',
                  })}
                  value={0}
                >
                  Non
                </Radio>
              </Flex>
            </FormControl>
            <></>
          </Row>
        )}

      {formType === 'HEADQUARTER_AND_ESTABLISHMENT' && !checkHQloading && (
        <BlocInformation mb={20}>
          <p>
            Aucun siège social n'a été détécté sur Connecteed avec le numéro
            SIREN. Vous devez remplir les informations du siège social, les
            informations de l'établissement seront ensuite demandées.
          </p>
        </BlocInformation>
      )}
      {formType === 'ESTABLISHMENT' && (
        <Box>
          <Text variant="h2">Siège Social</Text>
          <EstablishmentStructuredCard
            checkSage
            forRole={'PROVIDER'}
            mb={20}
            isFullWidth
            key={(headQuarterChecked as any)?.[0]?.uuid}
            establishment={(headQuarterChecked as any)?.[0]}
          />
        </Box>
      )}
      {formType === 'HEADQUARTER_ALREADY_EXIST' && (
        <FormControl
          label=""
          errorMessage={`Un établissement principal existe déja avec ce siren ${getTradeNameSafe(
            //@ts-ignore
            headQuarterChecked?.[0]
          )}`}
        />
      )}
      {headQuarterChecked && formType !== 'HEADQUARTER_ALREADY_EXIST' && (
        <Box>
          {formType !== 'ESTABLISHMENT' && (
            <Text variant="h1">
              {(formStep === 0 &&
                formType === 'HEADQUARTER_AND_ESTABLISHMENT') ||
              formType === 'HEADQUARTER'
                ? `Création du Siège social`
                : "Création de l'établissement"}
            </Text>
          )}
          <Section mt={20} variant="h2" title={'1. Informations principales'}>
            {formStep === 0 &&
            headquarter === '0' &&
            formType === 'HEADQUARTER_AND_ESTABLISHMENT' ? (
              <Row spacing={20}>
                <FormControl
                  required
                  label="N° Siret du Siège social"
                  errorMessage={
                    errors?.siret?.type === 'maxLength' ||
                    errors?.siret?.type === 'minLength'
                      ? 'Veuillez saisir 14 caractères'
                      : errors?.siret?.message
                  }
                >
                  <Input
                    isDisabled={isDisabled}
                    type="number"
                    isFullWidth
                    {...register('siret', {
                      validate: (value: string) => {
                        if (value?.slice(0, 9) !== siren) {
                          return 'le  N° SIRET  ne correspond pas avec le N° SIREN';
                        } else {
                          return undefined;
                        }
                      },
                      required: 'Ce champs est requis',
                      minLength: 14,
                      maxLength: 14,
                    })}
                  />
                </FormControl>
                <FormControl
                  required
                  label="Date de création"
                  errorMessage={errors?.activeSince?.message}
                >
                  <DatePickerControlled
                    control={control}
                    name="activeSince"
                    maxDate={new Date()}
                    valueName="selected"
                    isDisabled={isDisabled}
                  />
                </FormControl>
              </Row>
            ) : (
              <Row spacing={20}>
                <FormControl
                  required
                  label="Date de création"
                  errorMessage={errors?.activeSince?.message}
                >
                  <DatePickerControlled
                    control={control}
                    name="activeSince"
                    maxDate={new Date()}
                    valueName="selected"
                    isDisabled={isDisabled}
                  />
                </FormControl>
                <></>
              </Row>
            )}
            <Row spacing={20}>
              <FormControl
                required
                label="N° Siren"
                errorMessage={errors?.siren?.message}
              >
                <Input
                  isDisabled
                  type="number"
                  isFullWidth
                  {...register('siren', { required: 'Ce champs est requis' })}
                />
              </FormControl>
              <FormControl
                label={
                  'N° RPPS (Répertoire Partagé des Professionnels de Santé)'
                }
                errorMessage={
                  errors?.rpps?.type === 'maxLength' ||
                  errors?.rpps?.type === 'minLength'
                    ? 'Veuillez saisir 11 caractères'
                    : errors?.rpps?.message
                }
              >
                <Input
                  isFullWidth
                  isDisabled={isDisabled}
                  {...register('rpps', {
                    minLength: 11,
                    maxLength: 11,
                  })}
                />
              </FormControl>
            </Row>
            <Row spacing={20}>
              <FormControl
                label="N° TVA"
                errorMessage={errors?.vatNumber?.message}
                required={
                  country
                    ? isPartOfEU(country) ||
                      country?.toLocaleUpperCase() === 'MONACO'
                    : false
                }
              >
                <Input
                  isDisabled={isDisabled}
                  isFullWidth
                  {...register('vatNumber', {
                    required:
                      country?.toUpperCase() === 'MONACO'
                        ? 'Ce champs est requis'
                        : undefined,
                  })}
                />
              </FormControl>
              <FormControl
                required
                label="Code APE (activité principale exercée)"
                errorMessage={
                  errors?.activityCode?.type === 'maxLength'
                    ? 'Veuillez saisir 7 caractères maximum'
                    : errors?.activityCode?.message
                }
              >
                <Input
                  isFullWidth
                  isDisabled={isDisabled}
                  {...register('activityCode', {
                    required: 'Ce champs est requis',
                    maxLength: 7,
                  })}
                />
              </FormControl>
            </Row>
          </Section>
          <Section
            mt={20}
            variant="h2"
            title={'2. Informations légale et administrative'}
          >
            <Row spacing={20}>
              <FormControl
                required
                label="Raison sociale"
                errorMessage={errors?.businessName?.message}
              >
                <Input
                  isFullWidth
                  isDisabled={isDisabled}
                  {...register('businessName', {
                    required: 'Ce champs est requis',
                    validate: (value: string) =>
                      value?.length > 50
                        ? 'Veuillez saisir moins de 50 caractères'
                        : undefined,
                  })}
                />
              </FormControl>
              <FormControl
                label="Nom commercial"
                errorMessage={errors?.tradeName?.message}
              >
                <Input
                  isFullWidth
                  isDisabled={isDisabled}
                  {...register('tradeName', {
                    validate: (value: string) =>
                      value?.length > 50
                        ? 'Veuillez saisir moins de 50 caractères'
                        : undefined,
                  })}
                />
              </FormControl>
            </Row>
            <BlocInformation mb={20}>
              <p>
                L'enseigne identifie l'emplacement où le local dans lequel est
                exercé l'activité. Un établissement peut posséder une à
                plusieurs enseignes ou aucune.
              </p>
            </BlocInformation>
            <Row spacing={20}>
              <FormControl
                label="Nom de l'enseigne - 1"
                errorMessage={errors?.signBoard1?.message}
              >
                <Input
                  isFullWidth
                  isDisabled={isDisabled}
                  {...register('signBoard1', {
                    validate: (value: string) =>
                      value?.length > 50
                        ? 'Veuillez saisir moins de 50 caractères'
                        : undefined,
                  })}
                />
              </FormControl>
              <FormControl
                label="Nom de l'enseigne - 2"
                errorMessage={errors?.signBoard2?.message}
              >
                <Input
                  isFullWidth
                  isDisabled={isDisabled}
                  {...register('signBoard2', {
                    validate: (value: string) =>
                      value?.length > 50
                        ? 'Veuillez saisir moins de 50 caractères'
                        : undefined,
                  })}
                />
              </FormControl>
            </Row>
            <Row spacing={20}>
              <FormControl
                label="Nom de l'enseigne - 3"
                errorMessage={errors?.signBoard3?.message}
              >
                <Input
                  isFullWidth
                  isDisabled={isDisabled}
                  {...register('signBoard3', {
                    validate: (value: string) =>
                      value?.length > 50
                        ? 'Veuillez saisir moins de 50 caractères'
                        : undefined,
                  })}
                />
              </FormControl>
              <FormControl label="Catégorie">
                <StaticSelectControlled
                  isDisabled={isDisabled}
                  control={control}
                  name="category"
                  isClearable={true}
                  as={StaticSelect}
                  placeholder=""
                  options={[
                    {
                      label: 'Petite ou moyenne entreprise',
                      value: 'PME',
                    },
                    {
                      label: 'Entreprise de taille intermédiaire',
                      value: 'ETI',
                    },
                    {
                      label: 'Grande entreprise',
                      value: 'GE',
                    },
                  ]}
                />
              </FormControl>
            </Row>
          </Section>
          <Section mt={20} variant="h2" title={'3. Adresse'}>
            <FormControl
              label="Rechercher une adresse"
              data-cy="address-select-mission"
            >
              <AddressSelect
                defaultSearch={street}
                placeholder="Rechercher une adresse"
                endpoint="mapbox.places"
                isDisabled={isDisabled}
                onChange={async (value: any) => {
                  if (value) {
                    const postalCode = value?.data?.context?.find((c: any) =>
                      c?.id?.includes('postcode')
                    )?.text;
                    const city = value?.data?.context?.find((c: any) =>
                      c?.id?.includes('place')
                    )?.text;
                    if (postalCode && city) {
                      await showDialogModal({
                        title:
                          "Êtes-vous sûr de vouloir utiliser l'adresse suivante ?",
                        text: (
                          <Box>
                            <Text mt={20} variant="p">
                              {value.label}
                            </Text>
                          </Box>
                        ),
                        confirmLabel: 'Oui',
                        cancelLabel: 'Annuler',
                      }).then(async action => {
                        if (action) {
                          setSelectedMBAddress(value?.value.id);
                          setValue('address.street', value?.street);
                          setValue('address.country', 'France');

                          setValue('address.postalCode', postalCode);
                          setValue('address.city', city);
                        }
                      });
                    } else {
                      await showDialogModal({
                        title: 'Adresse incomplète',
                        text: (
                          <Box>
                            <Text>
                              L'adresse sélectionné est incomplète , veuillez
                              renseigner les valeurs manuellement.
                            </Text>
                            <Text mt={20} variant="b">
                              {value.label}
                            </Text>
                          </Box>
                        ),
                        confirmLabel: "J'ai compris",
                      });
                    }
                  }
                }}
                value={selectedMBAddress as string}
              />
            </FormControl>
            <FormControl
              required
              label="Adresse"
              errorMessage={errors?.address?.street?.message}
            >
              <Input
                isFullWidth
                {...register('address.street', {
                  required: 'Ce champs est requis',
                })}
                isDisabled={isDisabled}
              />
            </FormControl>
            <FormControl
              label="Complément d'adresse"
              errorMessage={errors?.address?.additional?.message}
            >
              <Input isFullWidth {...register('address.additional')} />
            </FormControl>
            <Row spacing={20}>
              <FormControl
                required
                label="Ville"
                errorMessage={errors?.address?.city?.message}
              >
                <Input
                  isFullWidth
                  {...register('address.city', {
                    required: 'Ce champs est requis',
                  })}
                  isDisabled={isDisabled}
                />
              </FormControl>
              <FormControl
                required
                label="Code postal"
                errorMessage={errors?.address?.postalCode?.message}
              >
                <Input
                  required
                  isFullWidth
                  {...register('address.postalCode', {
                    required: 'Ce champs est requis',
                  })}
                  isDisabled={isDisabled}
                />
              </FormControl>
            </Row>
            <Row spacing={20}>
              <FormControl
                required
                label="Pays"
                errorMessage={errors?.address?.country?.message}
              >
                <Input
                  isDisabled
                  isFullWidth
                  {...register('address.country', {
                    required: 'Ce champs est requis',
                  })}
                />
              </FormControl>
              <></>
            </Row>
          </Section>

          <Section mt={20} variant="h2" title="4. Gestion des rattachements">
            {establishmentRole === 'PROVIDER' && !noContactRequired && (
              <Box mb={20}>
                <Text variant="h2" mb={20}>
                  Rattacher un contact fournisseur
                </Text>
                <Text variant="p" mb={20}>
                  Le contact fournisseur que vous allez rattacher au nouvel
                  établissement fournisseur deviendra le responsable de compte
                  de ce dernier.
                </Text>
                <Box mb={2}>
                  <UserSelectControlled
                    disableCall={createWithContractor}
                    addedData={
                      createWithContractor
                        ? [
                            {
                              label: `${createWithContractor?.contractor?.firstName} ${createWithContractor?.contractor?.lastName} ${createWithContractor?.contractor?.email}`,
                              value: 'contactAndContractor',
                              data: {
                                cognitoUserId: 'contactAndContractor',
                                firstName:
                                  createWithContractor?.contractor?.firstName,
                                lastName:
                                  createWithContractor?.contractor?.lastName,
                                email: createWithContractor?.contractor?.email,
                                roles: [{ name: 'PROVIDER_CONTACT' }],
                              },
                            },
                          ]
                        : undefined
                    }
                    id="provider-contact-select"
                    control={control}
                    placeholder="Tous les contacts"
                    rules={{ required: 'Ce champs est requis' }}
                    role={'PROVIDER_CONTACT'}
                    name="provider.manager"
                    isDisabled={isDisabled}
                  />
                  {errors?.provider?.manager?.message && (
                    <FormControl
                      label=""
                      errorMessage={errors?.provider?.manager?.message}
                    ></FormControl>
                  )}
                </Box>
                <Link
                  width={1 / 1}
                  iconLeft={<AddIcon />}
                  onClick={() =>
                    showAddContactModal({
                      contactRole: 'PROVIDER_CONTACT',
                    }).then(c => {
                      c.cognitoUserId &&
                        setValue('provider.manager', c.cognitoUserId);
                    })
                  }
                >
                  Ajouter un contact Fournisseur
                </Link>
                <Link
                  iconLeft={<AddIcon />}
                  onClick={() =>
                    showAddContractorModal({
                      contactIsContractor: true,
                      noEstablishment: true,
                      onSubmit: async contractor => {
                        setCreatWithContractor(contractor);
                        //@ts-ignore
                        setValue('provider.manager', contractor);
                      },
                    })
                  }
                >
                  Ajouter un contact Fournisseur également intervenant
                </Link>
              </Box>
            )}
            {establishmentRole === 'CUSTOMER' && (
              <>
                <Text variant="h3" mb={15} mt={30}>
                  Contact de facturation
                </Text>
                <FormControl
                  label={'Email'}
                  // @ts-ignore
                  errorMessage={
                    errors?.customer?.invoiceContact?.email?.message
                  }
                  required
                >
                  <Input
                    data-cy="establishment-customer-invoiceContact-email"
                    isFullWidth
                    {...register('customer.invoiceContact.email', {
                      required: 'Ce champ est requis',
                    })}
                    isDisabled={isDisabled}
                    type="email"
                  />
                </FormControl>
                <FormControl
                  label={'Facturer automatiquement le client'}
                  errorMessage={
                    //@ts-ignore
                    errors?.customer?.billingOptions?.isAutoBilling?.message
                  }
                  required
                >
                  <CheckSwitch
                    id="isAutoBilling"
                    {...register(
                      //@ts-ignore
                      'customer.billingOptions.isAutoBilling'
                    )}
                    isDisabled={isDisabled}
                    onChange={e => {
                      setValue(
                        //@ts-ignore
                        'customer.billingOptions.isAutoBilling',
                        // @ts-ignore
                        e.target.checked
                      );
                      return e.target.checked;
                    }}
                  >
                    <Text variant="p">
                      {isAutoBilling
                        ? 'Facturer automatiquement le client'
                        : 'Ne pas facturer automatiquement le client'}
                    </Text>
                  </CheckSwitch>
                </FormControl>
                {sendMethod === EInvoiceSendMethod.EMAIL && (
                  <FormControl
                    label={
                      'Envoyer automatiquement les factures client à cette adresse'
                    }
                    errorMessage={
                      errors?.customer?.billingOptions?.isAutoSendEmail?.message
                    }
                    required
                  >
                    <CheckSwitch
                      id="isAutoSendEmail"
                      {...register('customer.billingOptions.isAutoSendEmail')}
                      onChange={e => {
                        setValue(
                          'customer.billingOptions.isAutoSendEmail',
                          e.target.checked
                        );
                        return e.target.checked;
                      }}
                    >
                      <Text variant="p">
                        {isAutoSendEmail
                          ? 'Envoyer automatiquement'
                          : 'Ne pas envoyer automatiquement'}
                      </Text>
                    </CheckSwitch>
                  </FormControl>
                )}
                {isAutoBilling && sendMethod === EInvoiceSendMethod.EMAIL && (
                  <>
                    <FormControl
                      label={'Joindre les justificatifs à la facture client'}
                      errorMessage={
                        errors?.customer?.billingOptions
                          ?.shouldJoinProofToInvoice?.message
                      }
                      required
                    >
                      <CheckSwitch
                        id="shouldJoinProofToInvoice"
                        {...register(
                          'customer.billingOptions.shouldJoinProofToInvoice'
                        )}
                        isDisabled={isDisabled}
                        onChange={e => {
                          setValue(
                            'customer.billingOptions.shouldJoinProofToInvoice',
                            e.target.checked
                          );
                          if (!e.target.checked) {
                            setValue(
                              'customer.billingOptions.shouldMergeProofWithInvoice',
                              false
                            );
                          }
                          return e.target.checked;
                        }}
                      >
                        <Text variant="p">
                          {shouldJoinProofToInvoice
                            ? 'Joindre les justificatifs à la facture client'
                            : 'Ne pas Joindre les justificatifs à la facture client'}
                        </Text>
                      </CheckSwitch>
                    </FormControl>
                    {shouldJoinProofToInvoice && (
                      <FormControl
                        required
                        label={'Fusionner les justificatifs avec la facture'}
                        errorMessage={
                          errors?.customer?.billingOptions
                            ?.shouldMergeProofWithInvoice?.message
                        }
                      >
                        <CheckSwitch
                          id="shouldMergeProofWithInvoice"
                          isDisabled={isDisabled}
                          {...register(
                            'customer.billingOptions.shouldMergeProofWithInvoice'
                          )}
                        >
                          <Text variant="p">
                            {shouldMergeProofWithInvoice
                              ? 'Fusionner les justificatifs avec la facture'
                              : ' Séparer les justificatifs et la facture'}
                          </Text>
                        </CheckSwitch>
                      </FormControl>
                    )}
                  </>
                )}
                <FormControl
                  required
                  label={'Mode de distribution'}
                  errorMessage={
                    errors?.customer?.billingOptions?.sendMethod?.message
                  }
                >
                  <>
                    <Radio
                      {...register('customer.billingOptions.sendMethod', {
                        required: 'Ce champ est requis',
                      })}
                      isDisabled={isDisabled}
                      value={EInvoiceSendMethod?.EMAIL}
                    >
                      Email
                    </Radio>
                    <Radio
                      {...register('customer.billingOptions.sendMethod', {
                        required: 'Ce champ est requis',
                      })}
                      isDisabled={isDisabled}
                      value={EInvoiceSendMethod?.OTHER}
                    >
                      Autre ( plateforme , fax etc .... )
                    </Radio>
                  </>
                </FormControl>
                <FormControl label={'Commentaire'}>
                  <TextAreaControlled
                    control={control}
                    minRows={3}
                    isFullWidth
                    data-cy="establishment-customer-invoiceContact-comment"
                    name="customer.invoiceContact.comment"
                    isDisabled={isDisabled}
                  />
                </FormControl>

                <Text variant="h3" mb={15}>
                  Rattachements
                </Text>
                <FormControl
                  label={'Contact client'}
                  required
                  errorMessage={errors?.customer?.contact?.message}
                >
                  <>
                    <UserSelectControlled
                      control={control}
                      id="customer-contact-select"
                      placeholder="Tous les contacts"
                      role={'CUSTOMER'}
                      rules={{ required: 'Ce champs est requis' }}
                      name="customer.contact"
                      isDisabled={isDisabled}
                    />
                    <Link
                      iconLeft={<AddIcon />}
                      onClick={() =>
                        showAddContactModal({
                          contactRole: 'CUSTOMER',
                        }).then(c => {
                          c.cognitoUserId &&
                            setValue('customer.contact', c.cognitoUserId);
                        })
                      }
                      mt={2}
                    >
                      Ajouter un contact client
                    </Link>
                  </>
                </FormControl>
                <FormControl
                  label={'Chargé de compte'}
                  required
                  errorMessage={errors?.customer?.accountManager?.message}
                >
                  <UserSelectControlled
                    id="manager-contact-select"
                    control={control}
                    defaultValue={me?.cognitoUserId}
                    placeholder="Tous les chargés de compte"
                    rules={{ required: 'Ce champs est requis' }}
                    role={'ACCOUNT_MANAGER'}
                    name="customer.accountManager"
                    isDisabled={isDisabled}
                  />
                </FormControl>
              </>
            )}
          </Section>
          <Box hidden={buttonHidden}>
            <Button
              type="submit"
              isLoading={loading}
              isDisabled={loading}
              data-cy="submit-create-establishment"
            >
              {formType === 'HEADQUARTER_AND_ESTABLISHMENT'
                ? `Créer ${
                    formStep === 0
                      ? 'le siège social étape 1 / 2'
                      : "l'établissement étape 2 / 2"
                  }`
                : `Créer ${
                    formType === 'ESTABLISHMENT'
                      ? "l'établissement"
                      : 'le siège social'
                  }`}
            </Button>
          </Box>
        </Box>
      )}
    </form>
  );
};
