import { EServicesCommentList } from '@freelancelabs/teoreme-commons';
import { Box, Text } from 'components/ui';
import { useSetCrumbs } from 'hooks/breadCrumb';
import { useParams } from 'react-router-dom';
import CommentHandler from 'components/CommentsHandler';
import { useEstablishmentFindOne, getTradeNameSafe } from '@commons';

export const Comments = () => {
  const { id } = useParams<{ id: string }>();
  const { data: establishment } = useEstablishmentFindOne(id);
  const comment = establishment?.provider?.comment;
  useSetCrumbs(
    [establishment],
    [
      {
        label: 'fournisseurs',
        path: '/providers',
      },
      {
        label: 'établissements',
        path: '/providers/establishments',
      },
      {
        label: establishment ? getTradeNameSafe(establishment) : '-',
        path: '/providers/establishments/' + establishment?.uuid || '',
      },
      {
        label: 'commentaires',
        path: `/providers/establishments/${establishment?.uuid || ''}/comments`,
      },
    ]
  );

  return (
    <Box p={20}>
      <Text variant="h2" mb={20}>
        Commentaires
      </Text>
      {comment && (
        <CommentHandler
          parentServiceUuid={comment}
          serviceName={EServicesCommentList?.COMPANY}
        />
      )}
    </Box>
  );
};
