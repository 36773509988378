import { NotificationDotIcon, NotificationIcon } from 'components/ui/icons';
import * as React from 'react';

export type Props = {
  hasUnread?: boolean;
};

export const NotificationsBell: React.FC<React.PropsWithChildren<Props>> = ({
  hasUnread,
}) => {
  if (hasUnread) return <NotificationDotIcon color="#819CAD" fontSize={20} />;
  else return <NotificationIcon color="#819CAD" fontSize={20} />;
};
NotificationsBell.displayName = 'Notifications';

export default NotificationsBell;
