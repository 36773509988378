import { IJoinedCraWithInvoice } from '@freelancelabs/teoreme-commons';
import { Box } from 'components/ui';
import { CraDetailsForm } from 'forms/cra/Details';
import { create } from 'react-modal-promise';
import { ModalFrame, ModalProps } from '../ModalFrame';
type Props = ModalProps & {
  cra: IJoinedCraWithInvoice;
  beforeValidation?: () => void;
  infoHidden?: boolean;
  isDisabled?: boolean;
  layer?: number;
};

export const CraDetailsModal = ({
  onResolve,
  isOpen,
  cra,
  infoHidden,
  isDisabled,
  layer,
}: Props) => {
  // const showMessage = useShowMessage();

  return (
    <ModalFrame
      isOpen={isOpen}
      onClose={() => onResolve(true)}
      width={1200}
      closeIcon
      layer={layer}
    >
      <Box mt={20}>
        <CraDetailsForm
          isDisabled={isDisabled}
          cra={cra}
          infoHidden={infoHidden}
          onResolve={onResolve}
        />
      </Box>
    </ModalFrame>
  );
};

export const showCraDetailsModal = create<Props>(CraDetailsModal);
