import {
  sageCreateDeliveryOrders,
  getFullName,
  getTradeNameSafe,
  displayMonth,
  MAX_CUSTOMER_REFERENCE_FIELD_LENGTH,
  getCustomerRefPattern,
} from '@commons';
import {
  IJoinedAdditionalActivityWithInvoice,
  IMission,
  IMinifiedUser,
  IJoinedInvoice,
  EMissionType,
  IContractor,
  IEstablishment,
  IAdditionalActivity,
  ECustomerReferencePatternVars,
} from '@freelancelabs/teoreme-commons';
import {
  ALink,
  Link,
  Text,
  Flex,
  Box,
  Button,
  FormControl,
  Input,
} from 'components/ui';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { create } from 'react-modal-promise';
import { ModalFrame, ModalProps } from '../ModalFrame';
import { queryClient } from '@commons';
import { useShowMessage } from 'hooks/useShowMessage';
import { CloseIcon } from 'components/ui/icons';
type Props = ModalProps & {
  beforeValidation?: () => void;
  invoice?: IJoinedInvoice; // REQUIRED FROM INVOICE !!
  mission:
    | IMission
    | (Omit<IMission, 'accountManager'> & { accountManager: IMinifiedUser });
  additionalActivity:
    | IJoinedAdditionalActivityWithInvoice
    | IAdditionalActivity;
  customerReference: string;
  delivreyOrders: { bdlProvider: boolean; bdlCustomer: boolean };
  refetchQueryKey: string;
};
type FormValues = any;
export const CreateGescomBDLModal = ({
  onResolve,
  invoice,
  mission,
  additionalActivity,
  customerReference,
  delivreyOrders,
  refetchQueryKey,
}: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const customerRefPattern =
    (additionalActivity as IJoinedAdditionalActivityWithInvoice)?.mission
      ?.customer?.billingOptions?.customerRefPattern ||
    (additionalActivity as IJoinedAdditionalActivityWithInvoice)?.estCustomer
      ?.customer?.billingOptions?.customerRefPattern;
  const craDateMonth = new Date(additionalActivity?.month as Date);
  const customerRef = getCustomerRefPattern(customerRefPattern, {
    [ECustomerReferencePatternVars.CRA_FULL_YEAR]: craDateMonth
      ?.getUTCFullYear()
      ?.toString(),
    [ECustomerReferencePatternVars.CRA_YEAR]: craDateMonth
      ?.getUTCFullYear()
      ?.toString()
      ?.substring(2),

    [ECustomerReferencePatternVars.CRA_MONTH]:
      craDateMonth?.getUTCMonth() + 1 < 10
        ? `0${craDateMonth?.getUTCMonth() + 1}`
        : `${craDateMonth?.getUTCMonth() + 1}`,
    [ECustomerReferencePatternVars.MISSION_REF]: (
      additionalActivity as IJoinedAdditionalActivityWithInvoice
    )?.mission?.displayReference,
    [ECustomerReferencePatternVars.CUSTOMER_REF]: customerReference,
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm<FormValues>({
    defaultValues: {
      customerReference: customerRef || customerReference,
    },
  });

  const showMessage = useShowMessage();
  const onSubmit = async () => {
    setIsLoading(true);
    const formValues = getValues();
    try {
      // @ts-ignore
      await sageCreateDeliveryOrders({
        additionalActivity: additionalActivity?.uuid,
        customerReference: formValues?.customerReference,
      });
      queryClient.refetchQueries({ queryKey: [refetchQueryKey] });
      showMessage('success', 'Bon de livraison crée dans GESCOM');
      onResolve(true);
    } catch (err) {
      //console.log(err);
    }
    setIsLoading(false);
    onResolve(false);
  };
  const checkAvalaibleCreate = (): {
    estProvider: IEstablishment;
    estCustomer: IEstablishment;
    contractor: IContractor;
    contractorReady: boolean;
    estProviderReady: boolean;
    estCustomerReady: boolean;
    ready: boolean;
  } => {
    let contractor;
    const structure = mission?.billingInformation?.structure;
    const labelType =
      mission?.billingInformation?.missionType?.label ===
      EMissionType.RESOURCE_MANAGEMENT
        ? 'RM'
        : 'CL';
    let estProvider;
    let estCustomer;

    if (invoice) {
      estProvider = invoice?.estProvider;
      estCustomer = invoice?.estCustomer;
      contractor = invoice?.contractor;
    } else {
      estProvider = additionalActivity?.estProvider;
      estCustomer = additionalActivity?.estCustomer;
      contractor = additionalActivity?.contractor;
    }
    const contractorReady = (
      contractor as IContractor
    )?.sage?.contractorIds?.find(
      sage => sage?.structure === structure && sage?.type === labelType
    )
      ? true
      : false;
    //@ts-ignore
    const estProviderReady = estProvider?.provider?.sage?.references?.find(
      //@ts-ignore
      sage => sage?.structure === structure
    )
      ? true
      : false;
    //@ts-ignore
    const estCustomerReady = estCustomer?.customer?.sage?.references?.find(
      //@ts-ignore
      sage => sage?.structure === structure
    )
      ? true
      : false;

    console.table({ contractorReady, estProviderReady, estCustomerReady });

    return {
      estProvider: estProvider as IEstablishment,
      estCustomer: estCustomer as IEstablishment,
      contractor: contractor as IContractor,
      contractorReady,
      estProviderReady,
      estCustomerReady,
      ready: contractorReady && estProviderReady && estCustomerReady,
    };
  };

  const avalaibleStatusToCreate = checkAvalaibleCreate();
  if (avalaibleStatusToCreate?.ready) {
    const month = displayMonth(
      additionalActivity?.month || (additionalActivity?.createdAt as Date)
    );
    return (
      <ModalFrame
        isOpen={true}
        onClose={() => onResolve(false)}
        width={550}
        closeIcon
      >
        <Box>
          <Text variant="h1" mb={20}>
            Créer le bon de livraison fournisseur ({month})
          </Text>
          <Text variant="p" mb={20}>
            Si vous souhaitez créer le bon de livraison fournisseur dans GESCOM,
            merci de confirmer la référence client ci-dessous. En cas de
            modification, cela n’affectera que la prestation complémentaire et
            la facture client concernés.
          </Text>
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormControl
              required
              label="Référence client"
              errorMessage={
                errors?.customerReference?.type === 'maxLength'
                  ? `Veuillez saisir moins de ${MAX_CUSTOMER_REFERENCE_FIELD_LENGTH} caractères`
                  : errors?.customerReference?.message
              }
            >
              <Input
                isFullWidth
                type="text"
                {...register('customerReference', {
                  required: 'Ce champs est requis',
                  maxLength: MAX_CUSTOMER_REFERENCE_FIELD_LENGTH,
                })}
              />
            </FormControl>
            <Flex alignItems="center">
              <Button type="submit" isLoading={isLoading}>
                Valider
              </Button>
              <Link
                ml={20}
                onClick={() => onResolve(false)}
                iconLeft={<CloseIcon />}
              >
                Créer plus tard
              </Link>
            </Flex>
          </form>
        </Box>
      </ModalFrame>
    );
  }
  return (
    <ModalFrame
      isOpen={true}
      onClose={() => onResolve(false)}
      width={670}
      closeIcon
    >
      <Box>
        <Text variant="h1" mb={20}>
          Impossible de créer le bon de livraison
        </Text>
        <Text>
          Ce bon de livraison ne peut être crée car certains liens avec la
          Gescom ne sont pas créés. Veuillez mettre à jour les liens Gescom
          suivants avant de crée ce bon de livraison.
          <Box mt={20} ml={20}>
            <ul>
              {!avalaibleStatusToCreate?.contractorReady && (
                <li>
                  <Flex>
                    L'intervenant &nbsp;
                    <ALink
                      ml={'5px'}
                      href={`/providers/contractors/${avalaibleStatusToCreate?.contractor?.uuid}`}
                      target="_blank"
                    >
                      <Link>
                        {getFullName(avalaibleStatusToCreate?.contractor)}
                      </Link>
                    </ALink>
                  </Flex>
                </li>
              )}
              {!avalaibleStatusToCreate?.estProviderReady && (
                <li>
                  <Flex>
                    L'établissement fournisseur &nbsp;
                    <ALink
                      ml={'5px'}
                      href={`/providers/establishments/${avalaibleStatusToCreate?.estProvider?.uuid}/information`}
                      target="_blank"
                    >
                      <Link>
                        {getTradeNameSafe(avalaibleStatusToCreate?.estProvider)}
                      </Link>
                    </ALink>
                  </Flex>
                </li>
              )}
              {!avalaibleStatusToCreate?.estCustomerReady && (
                <li>
                  <Flex>
                    L'établissement client &nbsp;
                    <ALink
                      ml={'5px'}
                      href={`/clients/establishments/${avalaibleStatusToCreate?.estCustomer?.uuid}/information`}
                      target="_blank"
                    >
                      <Link>
                        {getTradeNameSafe(avalaibleStatusToCreate?.estCustomer)}
                      </Link>
                    </ALink>
                  </Flex>
                </li>
              )}
            </ul>
          </Box>
        </Text>
        <Flex mt={20} alignItems="center">
          <Button
            type="button"
            isLoading={isLoading}
            onClick={() => onResolve(false)}
          >
            J'ai bien compris
          </Button>
        </Flex>
      </Box>
    </ModalFrame>
  );
};

export const showAdditionalActivityCreateGescomBDLModal = create<
  Props,
  boolean
>(CreateGescomBDLModal);
