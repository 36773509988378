import { PRICE_UNIT_SELECT } from '@commons';
import { EPriceUnit } from '@freelancelabs/teoreme-commons';
import {
  Box,
  Button,
  Flex,
  FormControl,
  Input,
  StaticSelectControlled,
} from 'components/ui';
import { useForm } from 'react-hook-form';
import { EuroIcon, PercentIcon } from 'components/ui/icons';

type FormValues = {
  deadline: number;
  price: number;
  unit: EPriceUnit;
};

type FastCatalogFormProps = {
  onSubmit: (catalog: any) => void;
  catalog?: any;
  onEdit?: (uuid: string) => void;
  onDelete?: (uuid: string) => void;
  isEditable: boolean;
};

export const FastCatalogForm = ({
  catalog,
  onSubmit: onSubmitParent,
  isEditable,
  onEdit,
  onDelete,
}: FastCatalogFormProps) => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    watch,
  } = useForm<FormValues>({
    defaultValues: { ...catalog },
  });
  const selectedUnit = watch('unit');
  const onSubmit = async (formValues: FormValues) => {
    if (onSubmitParent) {
      onSubmitParent(formValues);
    }
  };
  const checkPrice = (price: number): string | undefined => {
    switch (selectedUnit) {
      case EPriceUnit.PERCENT_INVOICE:
        if (price <= -1 || price > 100) {
          return 'Compris entre 0 & 100';
        } else {
          return undefined;
        }
      default:
        if (price <= -1) {
          return 'Supérieur à 0';
        } else {
          return undefined;
        }
    }
  };
  return (
    <Box width={1 / 1}>
      <form style={{ width: '100%' }} onSubmit={handleSubmit(onSubmit)}>
        <Flex
          width={1 / 1}
          display={'inline-flex'}
          justifyContent={'flex-start'}
          flexWrap={'wrap'}
          alignItems={'center'}
        >
          <Box width={2 / 13} p={10}>
            <FormControl
              label="Échéance (jours)"
              required
              //@ts-ignore
              errorMessage={errors?.deadline?.message}
            >
              <Input
                isDisabled={!isEditable}
                isFullWidth
                {...register('deadline', {
                  validate: (value: any) =>
                    value > 0 ? undefined : 'Supérieur à 0',
                })}
                maxDecimal={0}
                step="0"
                name="deadline"
                type="number"
              />
            </FormControl>
          </Box>
          <Box width={3 / 14} p={10}>
            <FormControl
              label="MONTANT ( HT )"
              required
              errorMessage={errors?.price?.message}
            >
              <Input
                isDisabled={!isEditable}
                icon={
                  selectedUnit === EPriceUnit.ONE_TIME_PERCENT ||
                  selectedUnit === EPriceUnit.PERCENT_INVOICE ? (
                    <PercentIcon />
                  ) : (
                    <EuroIcon />
                  )
                }
                isFullWidth
                {...register('price', {
                  required: 'Ce champs est requis',
                  validate: (value: any) => checkPrice(Number(value)),
                })}
                type="number"
                maxDecimal={2}
                step="0.01"
              />
            </FormControl>
          </Box>
          <Box width={5 / 14} p={10}>
            <FormControl
              label="Unité"
              required
              errorMessage={errors?.unit?.message}
            >
              <StaticSelectControlled
                isDisabled={!isEditable}
                control={control}
                name="unit"
                options={PRICE_UNIT_SELECT}
                rules={{ required: 'Ce champ est requis' }}
                placeholder="Type de service"
              />
            </FormControl>
          </Box>
          <Flex
            width={3 / 14}
            mb={10}
            display={'inline-flex'}
            justifyContent={'flex-start'}
            flexWrap={'wrap'}
            alignItems={'center'}
          >
            {!isEditable && (
              <Flex>
                <Button
                  mr={'3px'}
                  variant="filled"
                  onClick={() => onEdit && onEdit(catalog?.uuid)}
                >
                  Modifier
                </Button>
                <Button
                  variant="ghostError"
                  onClick={() => onDelete && onDelete(catalog?.uuid)}
                >
                  Supprimer
                </Button>
              </Flex>
            )}
            {isEditable && <Button type="submit">Enregistrer</Button>}
          </Flex>
        </Flex>
      </form>
    </Box>
  );
};
