import * as STORES from 'store';

export function useCleanStores(): any {
  const { resetContractorsListStore } = STORES.useContractorsListStore();
  const { resetContractsListStore } = STORES.useContractsListStore();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { resetStore: resetInvoiceStoreCN } =
    STORES.useCreditNoteCustomerListStore();
  const { resetStore: resetInvoiceStorePLCN } =
    STORES.useCreditNoteProviderListStore();
  const { resetCustomerContactsListStore } =
    STORES.useCustomerContactsListStore();
  const { resetCustomerEstablishmentFeeListStore } =
    STORES.useCustomerEstablishmentFeeListStore();
  const { resetCustomerEstablishmentListStore } =
    STORES.useCustomerEstablishmentListStore();
  const { resetDPAEListStore } = STORES.useDPAEListStore();
  const { resetStore: resetInvoiceStoreCL } =
    STORES.useInvoiceCustomerListStore();
  const { resetStore: resetInvoiceStorePL } =
    STORES.useInvoiceProviderListStore();
  const { resetListAdditionalActivityStore } =
    STORES.useListAdditionalActivityStore();
  const { resetListCraStore } = STORES.useListCraStore();
  const { resetListMissionStore } = STORES.useListMissionStore();
  const { resetManagerListStore } = STORES.useManagerListStore();
  const { resetPatchValidatedCraStore } = STORES.usePatchValidatedCraStore();
  const { resetProviderContactsListStore } =
    STORES.useProviderContactsListStore();
  const { resetProviderEstablishmentListStore } =
    STORES.useProviderEstablishmentListStore();
  const { resetPurchaseOrdersListStore } = STORES.usePurchaseOrdersListStore();

  const resetAllStore = (cognitoUserId?: string) => {
    const managerSelected = cognitoUserId;
    resetContractorsListStore({ managerSelected });
    resetContractsListStore({ managerSelected });
    resetInvoiceStorePLCN({ accountManagerId: managerSelected });
    resetCustomerContactsListStore({ managerSelected });
    resetCustomerEstablishmentFeeListStore({});
    resetCustomerEstablishmentListStore({ managerSelected });
    resetDPAEListStore({ managerSelected });
    resetInvoiceStoreCL({ accountManagerId: managerSelected });
    resetInvoiceStorePL({ accountManagerId: managerSelected });
    resetListAdditionalActivityStore({ managerSelected });
    resetListCraStore({ managerSelected });
    resetListMissionStore({ managerSelected });
    resetManagerListStore({});
    resetPatchValidatedCraStore({});
    resetProviderContactsListStore({ managerSelected });
    resetProviderEstablishmentListStore({ managerSelected });
    resetPurchaseOrdersListStore({ managerSelected });
  };
  return { resetAllStore };
}
