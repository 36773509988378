import {
  ISageCreateContactResponse,
  ISageCreateContactParams,
} from '@freelancelabs/teoreme-commons';
import { useMutation } from '@tanstack/react-query';
import { sageCreateContact } from '../routes';

export const useSageCreateContact = () =>
  useMutation<ISageCreateContactResponse, unknown, ISageCreateContactParams>({
    mutationFn: params => sageCreateContact(params),
  });
