import { TopBar } from 'components/TopBar';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { CRA } from './CRA';
import { AdditionalActivity } from './AdditionalActivity';
import { Invoices } from './Invoices';
import { Milestones } from './Milestones';
import { FlatRate } from './FlatRate';
export const Orders = () => {
  return (
    <>
      <TopBar />
      <Switch>
        <Route exact path="/orders">
          <Redirect to="/orders/cra" />
        </Route>
        <Route path="/orders/cra">
          <CRA />
        </Route>
        <Route path="/orders/invoices">
          <Invoices />
        </Route>
        <Route path="/orders/additional-activities">
          <AdditionalActivity />
        </Route>
        <Route path="/orders/milestones">
          <Milestones />
        </Route>
        <Route path="/orders/activities/flat-rate">
          <FlatRate />
        </Route>
      </Switch>
    </>
  );
};
