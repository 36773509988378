import {
  IJoinedAdditionalActivity,
  EadditionalActivityStatus,
} from '@freelancelabs/teoreme-commons';
import { useAdditionalActivityUpdateOne } from '@commons';
import { queryClient } from '@commons';
import { Box } from 'components/ui';
import { RejectAdditionalActivityForm } from 'forms/RejectAdditionalActivityForm';
import React from 'react';
import { create } from 'react-modal-promise';
import { ModalFrame, ModalProps } from './ModalFrame';

type Props = ModalProps & {
  beforeValidation?: () => void;
  additionalActivity: IJoinedAdditionalActivity;
};

export const RejectAdditionalActivityModal = ({
  onResolve,
  isOpen,
  additionalActivity,
}: Props) => {
  const { mutateAsync: updateAdditionalActivity } =
    useAdditionalActivityUpdateOne();

  const onRejectAdditionalActivity = async (additionalActivityData: {
    additionalActivity: IJoinedAdditionalActivity;
  }) => {
    try {
      await updateAdditionalActivity({
        uuid: additionalActivity.uuid,
        status: EadditionalActivityStatus.REJECTED,
        rejectReason: additionalActivityData.additionalActivity.rejectReason,
      });
      queryClient.refetchQueries({
        queryKey: ['AdditionalActivity'],
        type: 'active',
      });
      queryClient.refetchQueries({
        queryKey: [additionalActivityData.additionalActivity.uuid],
      });
      onResolve(true);
    } catch (err) {
      console.log(err);
    }
  };
  const text = {
    STAND_BY_DUTY: 'la prestation complémentaire',
    EXPENSE: 'les Frais',
    MILESTONE: 'le jalon',
  };
  return (
    <ModalFrame
      isOpen={isOpen}
      onClose={() => onResolve(false)}
      width={550}
      closeIcon
    >
      {additionalActivity && (
        <Box mt={20}>
          <RejectAdditionalActivityForm
            defaultValues={{ additionalActivity }}
            customTitle={`Refuser ${text?.[additionalActivity.type]}`}
            onSubmit={onRejectAdditionalActivity}
          />
        </Box>
      )}
    </ModalFrame>
  );
};

export const showRejectAdditionalActivityModal = create<Props, boolean>(
  RejectAdditionalActivityModal
);
