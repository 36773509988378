import React from 'react';
import {
  IJoinedMission,
  EMissionStatus,
  IAddress,
} from '@freelancelabs/teoreme-commons';
import {
  Box,
  FormControl,
  Input,
  Row,
  Text,
  DatePickerControlled,
  TextEditorControlled,
  CheckSwitch,
} from 'components/ui';
import { AddressSelect } from 'components/selects/AddressSelect';
import { showDialogModal } from 'components/modals/DialogModal';
import { buildOneLineAddress } from '@commons';
type FormValues = {
  startAt?: Date;
  endAt?: Date;
  estimatedEndAt?: Date;
  title?: string;
  location?: IAddress;
  description?: string;
  forRole?: 'ALL' | 'CUSTOMER' | 'PROVIDER';
  nbRemoteDaysPerWeek?: number;
  isRemote: boolean;
  isManualAdress: boolean;
};
type MissionPartialFormProps = {
  forRole?: 'ALL' | 'CUSTOMER' | 'PROVIDER';
  form: FormValues & any;
  sage?: any;
  mission?: IJoinedMission;
  missionStatus?: EMissionStatus;
  reference?: string;
  setIgnoreNav?: (bool: boolean) => void;
  isDisabled: boolean;
};

export const MissionPartialForm: React.FC<
  React.PropsWithChildren<MissionPartialFormProps>
> = ({
  mission,
  isDisabled,
  form: {
    formState: { errors, isDirty },
    register,
    watch,
    control,
    setValue,
    formState,
    reset,
    setError,
    setFocus,
  },
  forRole,
  sage,
  missionStatus,
  reference,
  setIgnoreNav,
}) => {
  const endAt = watch('endAt');
  const startAt = watch('startAt');
  const isRemote = watch('isRemote');
  const street = watch('location.street');
  const [selectedMBAddress, setSelectedMBAddress] = React.useState<any>(
    buildOneLineAddress(mission?.location)
  );
  const [isAdressDisabled, setIsAdressDisabled] = React.useState(true);

  return (
    <Box maxWidth={1000}>
      {(forRole === 'CUSTOMER' || forRole === 'PROVIDER') && (
        <Row spacing={20}>
          <FormControl
            required
            label="Date de début"
            errorMessage={errors?.startAt?.message}
            data-cy="mission-startAt"
          >
            <DatePickerControlled
              disabled={
                isDisabled ||
                mission?.status === EMissionStatus?.VALIDATED ||
                mission?.status === EMissionStatus?.TERMINATED
              }
              control={control}
              maxDate={endAt}
              name="startAt"
              rules={{ required: 'Ce champs est requis' }}
              valueName="selected"
            />
          </FormControl>
          <FormControl
            required
            label="Date de fin"
            errorMessage={errors?.endAt?.message}
            data-cy="mission-endAt"
          >
            <DatePickerControlled
              disabled={isDisabled}
              control={control}
              valueName="selected"
              minDate={startAt}
              name="endAt"
              rules={{ required: 'Ce champs est requis' }}
            />
          </FormControl>
        </Row>
      )}
      <Text mt={20} mb={20} variant="h2">
        Autres informations
      </Text>
      <FormControl
        label={'Télétravail'}
        errorMessage={errors?.isRemote?.message}
      >
        <CheckSwitch
          isDisabled={isDisabled}
          id="isRemote"
          {...register('isRemote')}
          onChange={e => {
            setValue('isRemote', e.target.checked);
            return e.target.checked;
          }}
        >
          <Text variant="p">
            {isRemote
              ? "Cette mission s'éffectue en télétravail"
              : "Cette mission s'éffectue en présentiel"}
          </Text>
        </CheckSwitch>
      </FormControl>
      <FormControl
        width={4 / 12}
        display={isRemote ? '' : 'none'}
        required
        label={'Nombre de jours de télétravail par semaine'}
        errorMessage={
          errors?.nbRemoteDaysPerWeek?.type === 'min'
            ? 'Ne peut pas être inférieur à 1'
            : errors?.nbRemoteDaysPerWeek?.type === 'max'
              ? 'Ne peut pas être supérieur à 5'
              : errors?.nbRemoteDaysPerWeek?.message
        }
      >
        <Input
          type="number"
          isDisabled={isDisabled}
          control={control}
          isFullWidth
          {...register('nbRemoteDaysPerWeek', {
            required: isRemote ? 'Ce champs est requis' : false,
            min: isRemote ? 1 : undefined,
            max: isRemote ? 5 : undefined,
          })}
          name="nbRemoteDaysPerWeek"
        />
      </FormControl>
      <FormControl
        required
        label="Lieu de la mission (renseigner la raison sociale de l'entreprise où la mission aura lieu)"
        data-cy="mission-location"
        // @ts-ignore
        errorMessage={errors?.location?.recipient?.message}
      >
        <Input
          isDisabled={isDisabled}
          isFullWidth
          data-cy="mission-location-input"
          {...register('location.recipient', {
            required: 'Ce champ est requis',
          })}
        />
      </FormControl>
      <Box my={10} hidden={isDisabled}>
        <CheckSwitch
          id="isManualAdress"
          defaultChecked={true}
          {...register('isManualAdress')}
          onChange={async e => {
            setValue('isManualAdress', e.target.checked);
            await setIsAdressDisabled(!isAdressDisabled);
            document.getElementsByName('location.street')[0].focus();
            return e.target.checked;
          }}
        >
          <Text variant="p">
            {isAdressDisabled
              ? "Saisie Automatique de l'adresse"
              : "Saisie Manuelle de l'adresse"}
          </Text>
        </CheckSwitch>
      </Box>
      <Box hidden={!isAdressDisabled || isDisabled}>
        <FormControl
          label="adresse"
          data-cy="address-select-mission"
          required
          errorMessage={
            errors?.location?.street?.message ||
            errors?.location?.postalCode?.message ||
            errors?.location?.country?.message ||
            errors?.location?.city?.message
          }
        >
          <AddressSelect
            isDisabled={isDisabled}
            defaultSearch={street}
            placeholder="Rechercher une adresse"
            defaultValue={buildOneLineAddress(mission?.location)}
            endpoint="mapbox.places"
            onChange={async (value: any) => {
              if (value) {
                const postalCode = value?.data?.context?.find((c: any) =>
                  c?.id?.includes('postcode')
                )?.text;
                const city = value?.data?.context?.find((c: any) =>
                  c?.id?.includes('place')
                )?.text;
                if (postalCode && city) {
                  await showDialogModal({
                    title:
                      "Êtes-vous sûr de vouloir utiliser l'adresse suivante ?",
                    text: (
                      <Box>
                        <Text mt={20} variant="p">
                          {value.label}
                        </Text>
                      </Box>
                    ),
                    confirmLabel: 'Oui',
                    cancelLabel: 'Annuler',
                  }).then(async action => {
                    if (action) {
                      setSelectedMBAddress(value?.value.id);
                      setValue('location.street', value?.street);
                      setValue('location.country', 'France');

                      setValue('location.postalCode', postalCode);
                      setValue('location.city', city);
                    }
                  });
                } else {
                  await showDialogModal({
                    title: 'Adresse incomplète',
                    text: (
                      <Box>
                        <Text>
                          L'adresse sélectionné est incomplète , veuillez
                          renseigner les valeurs manuellement.
                        </Text>
                        <Text mt={20} variant="b">
                          {value.label}
                        </Text>
                      </Box>
                    ),
                    confirmLabel: "J'ai compris",
                  });
                }
              }
            }}
            value={selectedMBAddress as string}
          />
        </FormControl>
      </Box>
      <Box hidden={isAdressDisabled && !isDisabled}>
        <FormControl
          required
          label="Adresse"
          data-cy="mission-location"
          // @ts-ignore
          errorMessage={errors?.location?.street?.message}
        >
          <Input
            isDisabled={isDisabled || isAdressDisabled}
            id="locationStreet"
            isFullWidth
            data-cy="mission-location-input"
            {...register('location.street', {
              required: 'Ce champ est requis',
              pattern: {
                value: /\S/g,
                message: 'ce champs ne peut être vide',
              },
            })}
          />
        </FormControl>
      </Box>
      <FormControl
        label="Complément d'adresse"
        // @ts-ignore
        errorMessage={errors?.location?.additional?.message}
      >
        <Input
          isDisabled={isDisabled}
          isFullWidth
          {...register('location.additional')}
        />
      </FormControl>
      <Row spacing={20} hidden={isAdressDisabled && !isDisabled}>
        <FormControl
          required
          label="Ville"
          data-cy="mission-city"
          errorMessage={errors?.location?.city?.message}
        >
          <Input
            isDisabled={isDisabled || isAdressDisabled}
            isFullWidth
            data-cy="mission-city-input"
            {...register('location.city', {
              required: 'Ce champ est requis',
              pattern: {
                value: /\S/g,
                message: 'ce champs ne peut être vide',
              },
            })}
          />
        </FormControl>

        <FormControl
          required
          label="Code postal"
          data-cy="mission-postalCode"
          errorMessage={errors?.location?.postalCode?.message}
        >
          <Input
            isDisabled={isDisabled || isAdressDisabled}
            isFullWidth
            data-cy="mission-postalCode-input"
            {...register('location.postalCode', {
              required: 'Ce champ est requis',
              pattern: {
                value: /\S/g,
                message: 'ce champs ne peut être vide',
              },
            })}
          />
        </FormControl>
      </Row>
      <Box hidden={isAdressDisabled && !isDisabled}>
        <FormControl
          required
          label="Pays"
          data-cy="mission-country"
          errorMessage={errors?.location?.country?.message}
        >
          <Input
            isDisabled={isDisabled || isAdressDisabled}
            isFullWidth
            data-cy="mission-country-input"
            {...register('location.country', {
              required: 'Ce champ est requis',
              pattern: {
                value: /\S/g,
                message: 'ce champs ne peut être vide',
              },
            })}
          />
        </FormControl>
      </Box>
      <FormControl label="Département, service ou BU">
        <Input
          isDisabled={isDisabled}
          isFullWidth
          {...register('location.businessUnit')}
        />
      </FormControl>
      <FormControl label="Marque">
        <Input
          isDisabled={isDisabled}
          isFullWidth
          {...register('location.brand')}
        />
      </FormControl>
      <FormControl
        label="Description"
        required
        errorMessage={errors?.description?.message}
        data-cy="mission-description"
      >
        <TextEditorControlled
          control={control}
          isDisabled={isDisabled}
          name="description"
          rules={{ required: 'Ce champ est requis' }}
          formatToDocument
          options={[
            'blockType',
            'inline',
            'list',
            'textAlign',
            'colorPicker',
            //'history',
          ]}
        />
      </FormControl>
    </Box>
  );
};
