import { FormLabel, CheckBox } from 'components/ui';
import { useController } from 'react-hook-form';
export const CheckBoxControlled = (props: any) => {
  const {
    field: { ref, ...inputProps },
  } = useController({
    name: props?.name,
    control: props?.control,
    rules: props?.rules,
    defaultValue: props?.defaultValue,
  });
  return (
    <CheckBox {...props} {...inputProps} inputRef={ref} mb={10}>
      <FormLabel>{props.label}</FormLabel>
    </CheckBox>
  );
};
