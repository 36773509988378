import { IEstablishmentMinifiedUsers } from '@freelancelabs/teoreme-commons';
import { useEstablishmentFindOne, useEstablishmentUpdateOne } from '@commons';
import { showDialogModal } from 'components/modals/DialogModal';
import { UserStructuredCard } from 'components/cards/UserStructuredCard';
import { Box, Link, Text, Flex, Button } from 'components/ui';
import { LinkIcon, CloseIcon, EditIcon } from 'components/ui/icons';
import * as React from 'react';
import { useParams } from 'react-router-dom';
import { queryClient } from '@commons';
import { useTheme } from 'styled-components';
import { getTradeNameSafe } from '@commons';
import { useSetCrumbs } from 'hooks/breadCrumb';
import { showLinkProviderContact } from 'components/modals/providers/LinkProviderContact';
import { showUpdateManagerModal } from 'components/modals/providers/UpdateManagerModal';
import { useShowMessage } from 'hooks/useShowMessage';
export type PropsType = {
  establishment: any;
};

export const EstablishmentProviderContacts = () => {
  const theme = useTheme();
  const showMessage = useShowMessage();
  const { id } = useParams<{ id: string }>();
  const { data: establishment } = useEstablishmentFindOne(id);
  const { mutateAsync: updateEstablishment } = useEstablishmentUpdateOne();
  useSetCrumbs(
    [establishment],
    [
      {
        label: 'fournisseurs',
        path: '/providers',
      },
      {
        label: 'établissements',
        path: '/providers/establishments',
      },
      {
        label: establishment ? getTradeNameSafe(establishment) : '-',
        path: '/providers/establishments/' + establishment?.uuid || '',
      },
      {
        label: 'contacts',
        path: `/providers/establishments/${establishment?.uuid || ''}/contacts`,
      },
    ]
  );

  const manager = establishment?.provider.manager;
  // const contact = establishment?.provider?.contacts?.[0];
  const addTempContact = async (
    establishment: IEstablishmentMinifiedUsers,
    tmpContact: any
  ) => {
    try {
      const establishmentData = {
        uuid: establishment?.uuid,
        removeTempContacts: { provider: [tmpContact] },
        addContacts: { provider: [tmpContact] },
      };
      showDialogModal({
        closeIcon: true,
        confirmLabel: 'Rattacher',
        cancelLabel: 'Annuler',
        title:
          'Etes-vous sûr de vouloir rattacher ce contact à cet établissement ?',
        text: '',
        beforeValidation: async () => {
          await updateEstablishment(establishmentData as any);
          queryClient.refetchQueries({ queryKey: [establishment?.uuid] });
          queryClient.refetchQueries({
            queryKey: ['establishments'],
            type: 'active',
          });
          showMessage('success', 'Le contact à bien été rattaché');
        },
      });
    } catch (e) {
      //
    }
  };
  const removeTempContact = async (
    establishment: IEstablishmentMinifiedUsers,
    tmpContact: any
  ) => {
    try {
      const establishmentData = {
        uuid: establishment?.uuid,
        removeTempContacts: { provider: [tmpContact] },
      };
      showDialogModal({
        closeIcon: true,
        confirmLabel: 'Refuser le rattachement',
        cancelLabel: 'Annuler',
        title:
          'Etes-vous sûr de vouloir refuser le rattachement de ce contact à cet établissement ?',
        text: '',
        beforeValidation: async () => {
          await updateEstablishment(establishmentData as any);
          queryClient.refetchQueries({ queryKey: [establishment?.uuid] });
          queryClient.refetchQueries({
            queryKey: ['establishments'],
            type: 'active',
          });
        },
      });
    } catch (e) {
      //
    }
  };
  return (
    <Box>
      {manager && (
        <Box mb={'20px'}>
          <Text variant="h2" mb={20}>
            Responsable de compte
            <Link
              onClick={() =>
                showUpdateManagerModal({
                  establishment: establishment as IEstablishmentMinifiedUsers,
                })
              }
              ml={10}
              iconLeft={<EditIcon />}
            >
              Modifier
            </Link>
          </Text>
          <UserStructuredCard
            isFullWidth
            user={manager}
            establishment={establishment}
            forRole={'PROVIDER'}
            checkSage
          />
        </Box>
      )}
      <Box mb={'20px'}>
        <Text variant="h2" mb={20}>
          Contacts fournisseur
        </Text>
        {establishment?.provider?.contacts &&
          establishment?.provider?.contacts?.length > 0 &&
          establishment?.provider?.contacts?.map(contact => (
            <Box key={contact.cognitoUserId} marginBottom={10}>
              <UserStructuredCard
                user={contact}
                establishment={establishment}
                forRole={'PROVIDER'}
                checkSage
              />
            </Box>
          ))}
        {establishment?.provider?.tempContacts &&
          establishment?.provider?.tempContacts.length > 0 && (
            <Box mb={'20px'}>
              {establishment?.provider?.tempContacts.map(
                (contact: any, index) => (
                  <Box key={index} marginBottom={10}>
                    <UserStructuredCard
                      user={contact}
                      establishment={establishment}
                      forRole={'PROVIDER'}
                      checkSage
                      renderActions={
                        <Flex
                          justifyContent="center"
                          alignContent="center"
                          alignItems="center"
                        >
                          <Button
                            onClick={() =>
                              addTempContact(
                                establishment,
                                contact.cognitoUserId
                              )
                            }
                            mr={'10px'}
                          >
                            Rattacher
                          </Button>
                          <Flex
                            justifyContent="center"
                            alignContent="center"
                            alignItems="center"
                          >
                            <CloseIcon
                              height={20}
                              width={20}
                              fill={theme.colors.blue}
                            />
                            <Link
                              onClick={() =>
                                removeTempContact(
                                  establishment,
                                  contact.cognitoUserId
                                )
                              }
                            >
                              Refuser
                            </Link>
                          </Flex>
                        </Flex>
                      }
                    />
                  </Box>
                )
              )}
            </Box>
          )}
      </Box>

      <Link
        iconLeft={<LinkIcon color={theme.colors.blue} />}
        onClick={() =>
          showLinkProviderContact({
            establishment: establishment as IEstablishmentMinifiedUsers,
          })
        }
      >
        Rattacher un contact
      </Link>
    </Box>
  );
};
