import { useDownloadFile, replaceSpecificCaractereInFileName } from '@commons'; // moved
import { Box, Button, Flex, Link, SpinnerBox } from 'components/ui';
import {
  CancelIcon,
  ChevronLeftIcon,
  CloseIcon,
  DownloadIcon,
} from 'components/ui/icons';
import React, { useEffect, useRef, useState } from 'react';
import { create } from 'react-modal-promise';
import { useLocation } from 'react-router-dom';
import styled, { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  body {
    overflow : hidden;
  }
`;

const FixedContainer = styled.div<{ layer?: number }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  z-index: 100;
  overflow-y: auto;
  padding: 80px 0 40px 0;
  z-index: ${props => (props.layer || 0) + 101};
`;

const FileContainer = styled.div`
  position: relative;
  box-sizing: border-box;
  width: 100%;
  display: block;
  z-index: 101;
`;

const Background = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: #000;
  opacity: 0.5;
`;

const TopBar = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  height: 80px;
  background-color: #000;
  z-index: 200;
  display: flex;
  align-items: center;
  padding: 0 30px;
`;
type DisplayPdfModalProps = {
  layer?: number;
  fileLocation: string;
  fileName: string;
  //fileUrl?: string;
  onResolve: (result?: any) => void;
  isOpen: boolean;
  confirmLabel?: string;
  rejectLabel?: string;
  onConfirm?: (data?: any) => any;
  onReject: (rej?: any) => void;
  onCancel?: (data?: any) => any;
  downloadConfirm?: () => Promise<any>;
  customButtonText?: string;
  customButtonAction?: () => Promise<any>;
  afterDownloadAction?: () => Promise<any>;
  open: boolean;
  instanceId: string;
  close: (params?: any) => any | undefined;
};

export const DisplayPdfModal = ({
  onResolve,
  isOpen,
  layer,
  //fileUrl,
  fileLocation,
  fileName,
  confirmLabel,
  rejectLabel,
  customButtonText,
  customButtonAction,
  onConfirm,
  onReject,
  onCancel,
  downloadConfirm,
  afterDownloadAction,
}: DisplayPdfModalProps) => {
  // hide on location change
  const location = useLocation();
  const isMountRef = useRef(true);
  useEffect(() => {
    if (!isMountRef.current) onResolve();
    isMountRef.current = false;
  }, [location, onResolve]);

  // load file
  const [file, setFile] = useState<string>();
  const [actionLoading, setActionLoading] = useState(false);
  /*if (fileUrl) {
    const uploadFile = async (url: string) => {
      await fetch(url)
        .then(response => response.blob())
        .then(blob => {
          // Create a new FileReader innstance
          const reader = new FileReader();
          reader.readAsDataURL(blob); // converts the blob to base64 and calls onload

          reader.onload = () => {
            setFile(reader.result as string);
          };
        });
    };
    uploadFile(fileUrl);
  }*/
  const { status, data } = useDownloadFile(fileLocation);

  useEffect(() => {
    // use for download file
    if (data) {
      const reader = new FileReader();
      reader.readAsDataURL(data.data); // converts the blob to base64 and calls onload
      reader.onload = () => {
        setFile(reader.result as string);
      };
    }
  }, [data]);

  const loading = status === 'pending';
  const handleConfirm = async () => {
    setActionLoading(true);
    await onConfirm?.();
    setActionLoading(false);
    onResolve(true);
  };

  const handleReject = async () => {
    setActionLoading(true);
    await onReject?.();
    setActionLoading(false);
    onResolve(true);
  };
  const customAction = async () => {
    if (customButtonAction) {
      setActionLoading(true);
      await customButtonAction();
      setActionLoading(false);
      onResolve(true);
    }
  };

  const onDownload = async () => {
    const confirm = !downloadConfirm || (await downloadConfirm());
    if (!confirm) return;
    const a = document.createElement('a');
    a.href = file || '';
    a.download = fileName.endsWith('.pdf')
      ? replaceSpecificCaractereInFileName(fileName)
      : replaceSpecificCaractereInFileName(fileName) + '.pdf';
    a.click();
    if (afterDownloadAction) {
      afterDownloadAction();
    }
  };

  useEffect(() => {
    if (!file && fileLocation) {
      console.log('filelocation', fileLocation);

      const uploadFile = async (url: string) => {
        await fetch(url)
          .then(response => {
            console.log('response', response);
            return response.blob();
          })
          .then(blob => {
            // Create a new FileReader innstance
            console.log('***blob', blob);

            const reader = new FileReader();
            reader.readAsDataURL(blob); // converts the blob to base64 and calls onload

            reader.onload = () => {
              setFile(reader.result as string);
            };
          });
      };
      uploadFile(fileLocation);
    }
  }, [file]);

  return (
    <FixedContainer layer={layer}>
      <GlobalStyle />

      {isOpen && (
        <>
          <TopBar>
            <Flex flex={1} alignItems="center">
              <Link
                color="#fff"
                iconLeft={<ChevronLeftIcon fill="#fff" />}
                onClick={() => {
                  onCancel && onCancel();
                  onResolve();
                }}
              >
                Retour
              </Link>
            </Flex>
            <Flex alignItems="center">
              {!rejectLabel && !loading && (
                <Link mr={30} iconLeft={<DownloadIcon />} onClick={onDownload}>
                  Télécharger
                </Link>
              )}
              {rejectLabel && (
                <Link mr={30} iconLeft={<CancelIcon />} onClick={handleReject}>
                  {rejectLabel}
                </Link>
              )}
              {customButtonText && customButtonAction && (
                <Link
                  mt={'2px'}
                  mr={30}
                  data-cy="custom-modal-pdf"
                  onClick={customAction}
                >
                  {customButtonText}
                </Link>
              )}
              {confirmLabel && (
                <Button
                  mr={30}
                  data-cy="confirm-modal-pdf"
                  isLoading={actionLoading}
                  onClick={handleConfirm}
                >
                  {confirmLabel}
                </Button>
              )}
              <CloseIcon
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  onCancel && onCancel();
                  onResolve();
                }}
                fontSize={20}
                fill="#fff"
              />
            </Flex>
          </TopBar>
          <Background />
          {loading && (
            <Box mt={100} zIndex={100} width="100%">
              <SpinnerBox color="#fff" />
            </Box>
          )}
          {!loading && data && (
            <FileContainer>
              <iframe
                //@ts-ignore
                src={data && (data as any)?.config?.url + '#toolbar=0'}
                frameBorder="0"
                title="iframe"
                style={{ width: '100%', height: 'calc(100vh - 80px)' }}
              ></iframe>
            </FileContainer>
          )}
        </>
      )}
    </FixedContainer>
  );
};

export const showDisplayPdfModal = create<DisplayPdfModalProps, boolean>(
  DisplayPdfModal
);
