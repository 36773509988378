import { showDisplayPdfModal } from 'components/modals/DisplayPdfModal';
import { Box, BoxProps, Flex, Link, Menu, MenuItem } from 'components/ui';
import { Card } from 'components/ui/Card';
import {
  CloseIcon,
  DocumentIcon,
  DotsIcon,
  EditIcon,
} from 'components/ui/icons';
import { ReactNode } from 'react';
import { Theme } from 'styles';

type FileCardProps = {
  fileName: string;
  fileLocation: string;
  complement?: ReactNode;
  onUpdate?: () => any;
  onDelete?: () => any;
  actions?: ReactNode;
  layer?: number;
  isDisabled?: boolean;
} & BoxProps;
export const FileCard = ({
  fileName,
  fileLocation,
  complement,
  onUpdate,
  onDelete,
  actions,
  layer,
  isDisabled,
  ...props
}: FileCardProps) => {
  return (
    <Card p={20} {...props}>
      <Flex>
        <Box fontSize={50} mr={15} opacity={0.5}>
          <DocumentIcon />
        </Box>
        <Box flex={1}>
          <Link
            mb={10}
            onClick={() =>
              showDisplayPdfModal({
                fileLocation,
                fileName,
                layer: layer,
              })
            }
          >
            {fileName}
          </Link>
          {complement}
        </Box>
        {(onUpdate || onDelete || actions) && !isDisabled && (
          <Flex alignItems="center">
            <Menu
              align="right"
              menuItems={
                actions || (
                  <>
                    {onUpdate && (
                      <MenuItem onClick={onUpdate}>
                        <Link iconLeft={<EditIcon />}>Modifier</Link>
                      </MenuItem>
                    )}
                    {onDelete && (
                      <MenuItem onClick={onDelete}>
                        <Link iconLeft={<CloseIcon />}>Supprimer</Link>
                      </MenuItem>
                    )}
                  </>
                )
              }
            >
              <DotsIcon fill={Theme?.colors?.primary?.default} fontSize={20} />
            </Menu>
          </Flex>
        )}
      </Flex>
    </Card>
  );
};
