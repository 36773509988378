import * as React from 'react';

function ChevronUp(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      {...props}
    >
      <g fill="none" fill-rule="evenodd">
        <g fill="#00ABEF">
          <g>
            <path
              d="M10.333 12l6.256-5.787c.548-.507.548-1.335 0-1.842-.535-.495-1.39-.495-1.925 0l-7.253 6.71c-.548.506-.548 1.334 0 1.841l7.253 6.706c.258.239.605.372.961.372.36 0 .712-.133.973-.384.536-.495.536-1.323-.012-1.83L10.333 12z"
              transform="translate(-1160 -213) translate(1160 213) rotate(90 12 12)"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default ChevronUp;
