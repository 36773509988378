import { create } from 'zustand';

type State = {
  translateLang: string;
  updateTranslate: (state: Partial<State>) => void;
  resetTranslate: (state: Partial<State>) => void;
};

const initialStates = {
  translateLang: 'fr',
};

const store = (set: any) => ({
  ...initialStates,
  updateTranslate: (state: Partial<State>) => set(state),
  resetTranslate: (state: Partial<State>) =>
    set({ ...initialStates, ...state }),
});

export const useTranslateStore = create<State>(store);
