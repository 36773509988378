import { IJoinedComment } from '@freelancelabs/teoreme-commons';
import React from 'react';
import { useTheme } from 'styled-components';
import { Avatar, Button, Flex, Box, Text, Link, TextArea } from 'components/ui';
import {
  getFirstCaracts,
  getFullName,
  formatDateAndTimeComment,
  cutLongText,
} from '@commons'; //moved
import { Card, CardBody } from '../ui/Card';
import {
  DeleteIcon,
  EditIcon,
  CancelIcon,
  ThumbUpIcon,
  ThumbUpSolideIcon,
} from 'components/ui/icons';
export const CommentCard = ({
  comment,
  onUpdate,
  onDelete,
  onDisableCreate,
  onLike,
  ...props
}: {
  onDisableCreate: (bool: boolean) => void;
  onUpdate: (comment: IJoinedComment) => void;
  onDelete: (comment: IJoinedComment) => void;
  onLike: (comment: IJoinedComment) => void;
  comment: IJoinedComment & { key: string };
}) => {
  const theme = useTheme();
  const [updateComment, setUpdateComment] = React.useState(comment?.content);
  const [openUpdate, setOpenUpdate] = React.useState(false);
  const onOpenUpdate = () => {
    setUpdateComment(comment.content);
    onDisableCreate(true);
    setOpenUpdate(true);
  };
  const onSubmitUpdateComment = () => {
    onUpdate({ ...comment, content: updateComment });
    onDisableCreate(false);
    setOpenUpdate(false);
  };
  const onSubmitDeleteComment = () => {
    onDelete({ ...comment });
    onDisableCreate(false);
    setOpenUpdate(false);
  };
  return (
    <Box width={1 / 1} key={comment.key}>
      <Card maxWidth={'100%'}>
        <CardBody width={1 / 1}>
          <Flex display="inline-flex" flexWrap="wrap" width={'100%'}>
            <Box width={'50px'}>
              <Avatar
                /*@ts-ignore */
                text={getFirstCaracts(comment?.authorId)}
                size={40}
                mr={20}
              />
            </Box>
            <Box width={'calc(100% - 50px)'}>
              <Flex
                display="inline-flex"
                flexWrap="wrap"
                justifyContent="flex-start"
                width={'100%'}
              >
                <Box width={'40%'} mt={'0px'}>
                  <Text variant="h3" fontSize={14}>
                    {/*@ts-ignore */}
                    {cutLongText(getFullName(comment?.authorId))}
                  </Text>
                  <Text variant="disabled" fontSize={10}>
                    {comment?.commentUpdatedAt
                      ? `${formatDateAndTimeComment(
                          comment?.commentUpdatedAt as Date
                        )} - Modifié`
                      : formatDateAndTimeComment(comment?.createdAt as Date)}
                  </Text>
                </Box>
                <Flex width={'60%'} justifyContent="flex-end" mt={'8px'}>
                  <Flex ml={'5px'} mr={'5px'}>
                    {!openUpdate &&
                      /*@ts-ignore */
                      comment?.authorId?.cognitoUserId ===
                        localStorage?.cognitoUserId && (
                        <Flex>
                          <Box>
                            <Link
                              iconLeft={<EditIcon />}
                              onClick={onOpenUpdate}
                            ></Link>
                            <Link
                              iconLeft={<DeleteIcon />}
                              onClick={onSubmitDeleteComment}
                            ></Link>
                          </Box>
                        </Flex>
                      )}
                    {comment?.likes?.findIndex(
                      like =>
                        like?.cognitoUserId === localStorage?.cognitoUserId
                    ) > -1 ? (
                      <ThumbUpSolideIcon
                        style={{
                          cursor: 'pointer',
                          marginTop: 3,
                          marginLeft: 5,
                        }}
                        fill={theme?.colors?.blue}
                        onClick={() => onLike(comment)}
                      />
                    ) : (
                      <ThumbUpIcon
                        style={{
                          cursor: 'pointer',
                          marginTop: 3,
                          marginLeft: 5,
                        }}
                        fill={theme?.colors?.blue}
                        onClick={() => onLike(comment)}
                      />
                    )}{' '}
                    <Text ml={'5px'} mr={'5px'} variant="p">
                      {' '}
                      {comment?.likes?.length}{' '}
                    </Text>
                  </Flex>
                </Flex>
              </Flex>
              <Box width={'calc(100% - 50px)'} mt={20}>
                {!openUpdate ? (
                  <Text variant="p">
                    {comment?.content?.split('\n')?.map(text => (
                      <>
                        {text}
                        <br />
                      </>
                    ))}
                  </Text>
                ) : (
                  <>
                    <TextArea
                      minRows={3}
                      value={updateComment}
                      onChange={e => setUpdateComment(e.target.value)}
                    />
                    <Flex mt={'5px'}>
                      <Button
                        isDisabled={updateComment === comment?.content}
                        onClick={onSubmitUpdateComment}
                      >
                        Modifier
                      </Button>
                      <Link
                        iconLeft={<CancelIcon />}
                        ml={'10px'}
                        mt={15}
                        onClick={() => {
                          onDisableCreate(false);
                          setOpenUpdate(false);
                        }}
                      >
                        Annuler
                      </Link>
                    </Flex>
                  </>
                )}

                {/* {!openUpdate &&
                  comment?.authorId?.cognitoUserId ===
                    localStorage?.cognitoUserId && (
                    <Flex>
                      <Row spacing={0} width={150}>
                        <Link
                          mt={10}
                          ml={-2}
                          iconLeft={<EditIcon />}
                          onClick={onOpenUpdate}
                        >
                          Modifier
                        </Link>
                        <Link
                          mt={10}
                          ml={-2}
                          iconLeft={<DeleteIcon />}
                          onClick={onSubmitDeleteComment}
                        >
                          Supprimer
                        </Link>
                      </Row>
                    </Flex>
                  )} */}
              </Box>
            </Box>
          </Flex>
        </CardBody>
      </Card>
    </Box>
  );
};
