import { EContractLabel } from '@freelancelabs/teoreme-commons';
import {
  Box,
  Button,
  FileInput,
  Flex,
  FormControl,
  Input,
  Link,
  Text,
  DatePickerControlled,
  StaticSelectControlled,
} from 'components/ui';
import {
  EXECUTIVE_CONTRACTS_SELECT,
  MANDAT_CONTRACTS_SELECT,
  MAX_REFERENCE_CONTRACT_FIELD_LENGTH,
} from '@commons';
import { AddIcon } from 'components/ui/icons';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { truncate } from 'lodash';

type FormValues = {
  refContract: string;
  createdAt: Date;
  label: EContractLabel;
  type: string;
  lang: string;
  file: File;
};
type ContactFormProps = {
  defaultValues?: FormValues;
  contractLabel: EContractLabel;
  onSubmit?: any; // TODO MAKE TYPES
  onClose: () => void;
};

export const ImportExecutiveContractForm = ({
  defaultValues,
  contractLabel,
  onSubmit: onSubmitParent,
  onClose,
}: ContactFormProps) => {
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isDirty },
    setError,
    clearErrors,
  } = useForm<FormValues>({
    defaultValues: { ...defaultValues },
  });
  const contractLabelName =
    contractLabel === EContractLabel.EXECUTIVE ? 'contrat-cadre' : 'mandat';
  const [contractFile, setContractFile] = React.useState<File | null>(null);

  const handleContractFileChange = async (files: File[]) => {
    setContractFile(files[0]);
    clearErrors('file');
  };

  const onSubmit = async (formValues: FormValues) => {
    //Ôconst wType = formValues.type.split('_');
    const type = formValues.type.replace(
      `_${formValues.type.split('_').pop()}`,
      ''
    );
    const lang = formValues.type.split('_').pop();
    // transform form values as requested
    if (contractFile) {
      setLoading(true);
      const values: FormValues = {
        ...defaultValues,
        ...formValues,
        label: contractLabel,
        file: contractFile,
        type: type,
        lang: lang as string,
      };
      await onSubmitParent?.(values);
      setLoading(false);
    } else {
      setError('file', { message: 'Ce champ est requis' });
    }
  };
  const getErrorMessage = (error: any) => {
    if (error) {
      switch (error.type) {
        case 'maxLength':
          return `Veuillez saisir moins de ${MAX_REFERENCE_CONTRACT_FIELD_LENGTH} caractères`;
        case 'required':
          return 'Ce champ est requis';
      }
      return error.message;
    }
    return undefined;
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
      <Text variant="h2" mb={20}>
        Importer un {contractLabelName} signé
      </Text>

      <FormControl
        label="Référence"
        required
        errorMessage={getErrorMessage(errors?.refContract)}
      >
        <Input
          isFullWidth
          {...register('refContract', {
            required: 'Ce champ est requis',
            maxLength: MAX_REFERENCE_CONTRACT_FIELD_LENGTH,
          })}
        />
      </FormControl>

      <FormControl
        label={`TYPE DE ${contractLabelName?.toLocaleUpperCase()}`}
        required
        errorMessage={errors?.type?.message}
      >
        <StaticSelectControlled
          control={control}
          options={
            contractLabel === EContractLabel.EXECUTIVE
              ? EXECUTIVE_CONTRACTS_SELECT
              : MANDAT_CONTRACTS_SELECT
          }
          rules={{ required: 'Ce champs est requis' }}
          name="type"
          placeholder={`TYPE DE ${contractLabelName?.toLocaleUpperCase()}`}
        />
      </FormControl>

      <FormControl
        required
        label="Date de signature"
        errorMessage={errors?.createdAt?.message}
      >
        <DatePickerControlled
          control={control}
          valueName="selected"
          maxDate={new Date()}
          name="createdAt"
          rules={{ required: 'Ce champ est requis' }}
        />
      </FormControl>

      <Box mb={40}>
        <FileInput
          label={contractLabelName}
          required
          accept=".pdf"
          subLabel="Format accepté : PDF"
          onSelect={handleContractFileChange}
          //@ts-ignore
          errorMessage={errors?.file?.message}
        >
          <Link iconLeft={<AddIcon />}>
            {truncate(contractFile?.name, {
              length: 40,
              omission: '....pdf',
            }) || 'Ajouter contrat'}
          </Link>
        </FileInput>
      </Box>
      <Flex>
        <Button type="submit" isLoading={loading} isDisabled={!isDirty}>
          importer
        </Button>
        <Button ml="10px" variant="ghost" onClick={onClose}>
          Annuler
        </Button>
      </Flex>
    </form>
  );
};
