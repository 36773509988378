import {
  ApiError,
  getEstablishmentName,
  getFirstCaracts,
  getFullName,
  useContractorFindOne,
  contractorUpdateOne,
  useMissionFindMany,
} from '@commons';
import {
  EContractorStatus,
  EMissionStatus,
  IJoinedContractor,
  IJoinedContractorScopedData,
} from '@freelancelabs/teoreme-commons';
import { showDialogModal } from 'components/modals/DialogModal';
import { Header } from 'components/Header';
import { SideBar } from 'components/SideBar';
import { SideLink } from 'components/SideLink';
import {
  Avatar,
  Box,
  Container,
  Flex,
  Link,
  Separator,
  SpinnerBox,
  Status,
  Text,
  Spinner,
} from 'components/ui';
import { AddIcon, LinkIcon } from 'components/ui/icons';
import React from 'react';
import {
  Route,
  Switch,
  useLocation,
  useParams,
  useHistory,
} from 'react-router-dom';
import { ProvidersContractorsEstablishment } from './Establishment';
import { ProvidersContractorsInformation } from './Information';
import { MissionsList } from './MissionsList';
import { showLinkEstablishmentModal } from 'components/modals/contractors/LinkEstablishmentModal';
import { Comments } from './Comment';
import { queryClient } from '@commons';
import { useShowMessage } from 'hooks/useShowMessage';
export const ProviderContractorDetails = () => {
  const history = useHistory();
  const showMessage = useShowMessage();
  const location = useLocation();
  const { id } = useParams<{ id: string }>();
  const { data: contractor, error, status } = useContractorFindOne(id);
  const [estaSelected, setEstaSelected] =
    React.useState<null | IJoinedContractorScopedData>(null);
  const [filterObjectMission, setFilterObjectMission] = React.useState<
    undefined | any
  >(undefined);
  const {
    data: missions,
    isFetching: isFetchingMission,
    //errorMission,
    //statusMission,
  } = useMissionFindMany(filterObjectMission);
  const loading = status === 'pending';
  const getStatus = (): { variantColor: any; text: string } => {
    switch (contractor?.status) {
      case EContractorStatus.ACTIVE:
        return { variantColor: 'success', text: 'Actif' };
      case EContractorStatus.PENDING:
        return { variantColor: 'warning', text: 'En attente de validation' };
      case EContractorStatus.REJECTED:
        return { variantColor: 'error', text: 'Refusé' };
      case EContractorStatus.ARCHIVED:
        return { variantColor: 'grey', text: 'Archivé' };
    }
    return { variantColor: 'grey', text: 'Archivé' };
  };
  const disabledEstablishment = async () => {
    await showDialogModal({
      title: "Désactiver l'établissement de l'intervenant",
      text: `Etes vous sur de vouloir désactiver le lien entre l’intervenant ${getFullName(
        contractor
      )} de l'établissement  ${getEstablishmentName(
        estaSelected?.establishment
      )} ? La fiche intervenant restera visible pour le contact fournisseur de l'établissement dans son espace fournisseur mais il ne pourra plus y apporter de modifications.`,
      confirmLabel: 'Désactivé',
      cancelLabel: 'Annuler',
    }).then(async action => {
      if (action === true) {
        try {
          await contractorUpdateOne({
            uuid: contractor?.uuid as string,
            scopedData: [
              {
                establishment: estaSelected?.establishment?.uuid as string,
                disabled: true,
              },
            ],
          });
          showMessage(
            'success',
            `Le lien entre l'établissement ${getEstablishmentName(
              estaSelected?.establishment
            )} à bien été désactivé de l'intervenant ${getFullName(contractor)}`
          );
          queryClient.refetchQueries({ queryKey: [contractor?.uuid] });
          history.push(`/providers/contractors/${contractor?.uuid}`);
        } catch (e) {
          //
        }
      }
    });
  };
  const enabledEstablishment = async () => {
    await showDialogModal({
      title: "Réactiver l'établissement de l'intervenant",
      text: `Etes vous sur de vouloir réactiver le lien entre l’intervenant  ${getFullName(
        contractor
      )} de l'établissement ${getEstablishmentName(
        estaSelected?.establishment
      )} ? Le contact fournisseur de l'établissement pourra de nouveau modifier la fiche intervenant dans son espace fournisseur.`,
      confirmLabel: 'Réactiver',
      cancelLabel: 'Annuler',
    }).then(async action => {
      if (action === true) {
        try {
          await contractorUpdateOne({
            uuid: contractor?.uuid as string,
            scopedData: [
              {
                establishment: estaSelected?.establishment?.uuid as string,
                disabled: false,
              },
            ],
          });
          showMessage(
            'success',
            `Le lien entre l'établissement ${getEstablishmentName(
              estaSelected?.establishment
            )} à bien été réactivé de l'intervenant ${getFullName(contractor)}`
          );
          queryClient.refetchQueries({ queryKey: [contractor?.uuid] });
          history.push(`/providers/contractors/${contractor?.uuid}`);
        } catch (e) {
          //
        }
      }
    });
  };
  const unLinkEstablishment = async () => {
    await showDialogModal({
      title: "Détacher l'établissement de l'intervenant",
      text: `Etes vous sur de vouloir détacher l’intervenant  ${getFullName(
        contractor
      )} de l'établissement ${getEstablishmentName(
        estaSelected?.establishment
      )}  ? La fiche intervenant ne sera plus visible pour le contact fournisseur de l'établissement dans son espace fournisseur.`,
      confirmLabel: 'Détacher',
      cancelLabel: 'Annuler',
    }).then(async action => {
      if (action === true) {
        try {
          await contractorUpdateOne({
            uuid: contractor?.uuid as string,
            //@ts-ignore
            deleteScopedData: [estaSelected?.establishment?.uuid],
          });
          showMessage(
            'success',
            `Le lien entre l'établissement ${getEstablishmentName(
              estaSelected?.establishment
            )} à bien été détaché de l'intervenant ${getFullName(contractor)}`
          );
          queryClient.refetchQueries({ queryKey: [contractor?.uuid] });
          history.push(`/providers/contractors/${contractor?.uuid}`);
        } catch (e) {
          //
        }
      }
    });
  };

  React.useEffect(() => {
    if (contractor && location?.pathname?.includes('establishments')) {
      const split = location?.pathname?.split('/');
      const establishment = location?.pathname?.includes('establishments')
        ? // eslint-disable-next-line array-callback-return
          contractor?.scopedData?.find(scop => {
            if (scop?.establishment?.uuid === split[split?.length - 1]) {
              return true;
            }
          }) || null
        : null;
      setEstaSelected(establishment);
      setFilterObjectMission({
        filterObject: {
          'provider.establishment': establishment?.establishment?.uuid,
          'provider.contractor': id,
          $or: [
            { status: EMissionStatus.VALIDATED },
            { status: EMissionStatus.ARCHIVED },
          ],
        },
        limit: 5,
      });
    } else {
      setEstaSelected(null);
      setFilterObjectMission(undefined);
    }
  }, [contractor, location]);

  return (
    <Box>
      <Header>
        <Flex width={1 / 1} alignItems="center" flexWrap="wrap">
          <Avatar text={contractor && getFirstCaracts(contractor)} />
          <Flex width={'calc(100% - 70px)'} flexWrap="wrap">
            <Text ml={10} variant="h1" width={1 / 1}>
              {contractor && getFullName(contractor)}
            </Text>
            <Status ml={10} variantColor={getStatus()?.variantColor}>
              {getStatus()?.text}
            </Status>
          </Flex>
        </Flex>
      </Header>

      <Container display="flex" pt={50} pb={50}>
        <SideBar>
          <SideLink exact href={`/providers/contractors/${id}`}>
            Informations générales
          </SideLink>
          <SideLink exact href={`/providers/contractors/${id}/missions`}>
            Missions
          </SideLink>
          <SideLink exact href={`/providers/contractors/${id}/comments`}>
            Commentaires
          </SideLink>
          <SideLink>Établissements</SideLink>
          {contractor?.scopedData?.map(
            ({ establishment, disabled }) =>
              !disabled && (
                <SideLink
                  key={establishment.uuid}
                  variant="secondary"
                  href={`/providers/contractors/${contractor.uuid}/establishments/${establishment.uuid}`}
                  //onClick={() => setEstaSelected(establishment)}
                >
                  {getEstablishmentName(establishment)}
                </SideLink>
              )
          )}
          {contractor &&
            contractor?.scopedData?.findIndex(esta => esta.disabled === true) >
              -1 && <SideLink>Établissements désactivés</SideLink>}
          {contractor?.scopedData?.map(
            ({ establishment, disabled }) =>
              disabled === true && (
                <SideLink
                  key={establishment.uuid}
                  variant="secondary"
                  href={`/providers/contractors/${contractor.uuid}/establishments/${establishment.uuid}`}
                  //onClick={() => setEstaSelected(establishment)}
                >
                  {getEstablishmentName(establishment)}
                </SideLink>
              )
          )}
          <Separator />
          {estaSelected && isFetchingMission && <Spinner />}
          {!isFetchingMission &&
          !estaSelected?.disabled &&
          estaSelected &&
          missions?.totalCount &&
          missions?.totalCount > 0 ? (
            <Link
              isFullWidth
              iconLeft={<LinkIcon />}
              mb={10}
              width={'100%'}
              onClick={disabledEstablishment}
            >
              Désactiver l'établissement
            </Link>
          ) : (
            <></>
          )}
          {estaSelected && estaSelected?.disabled === true && (
            <Link
              isFullWidth
              iconLeft={<LinkIcon />}
              mb={10}
              width={'100%'}
              onClick={enabledEstablishment}
            >
              Réactiver l'établissement
            </Link>
          )}
          {!isFetchingMission && estaSelected && missions?.totalCount === 0 ? (
            <Link
              isFullWidth
              iconLeft={<LinkIcon />}
              mb={10}
              width={'100%'}
              onClick={unLinkEstablishment}
            >
              Détacher l'établissement
            </Link>
          ) : (
            <></>
          )}
          <Link
            onClick={() =>
              showLinkEstablishmentModal({
                contractor: contractor as IJoinedContractor,
              })
            }
            isFullWidth
            iconLeft={<AddIcon />}
            mb={10}
            width={'100%'}
          >
            Rattacher un établissement
          </Link>
        </SideBar>
        <Box flex={1}>
          {loading && <SpinnerBox />}

          {!loading && (
            <Switch>
              <Route exact path="/providers/contractors/:id">
                <ProvidersContractorsInformation />
              </Route>
              <Route path="/providers/contractors/:userUuid/establishments/:establishmentUuid">
                <ProvidersContractorsEstablishment />
              </Route>
              <Route path="/providers/contractors/:userUuid/comments">
                <Comments />
              </Route>
              <Route path="/providers/contractors/:userUuid/missions">
                <MissionsList />
              </Route>
            </Switch>
          )}

          {!!error && <p>{(error as ApiError).message}</p>}
        </Box>
      </Container>
    </Box>
  );
};
