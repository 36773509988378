import React, { ComponentPropsWithoutRef } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
// import { boxProperties, BoxProps } from './ui';

import { boxProperties, BoxProps } from './ui/Box';

const A = styled(Link)`
  display: inline-block;
  cursor: pointer;
  ${boxProperties}
`;
type ALinkProps = ComponentPropsWithoutRef<'a'> & {
  children: any;
  href?: string;
  params?: any;
  state?: any;
} & BoxProps;

export const ALink = ({ children, href, params, ...props }: ALinkProps) => {
  return (
    <A
      to={{ pathname: href || '', state: params }}
      style={{ display: 'flex', alignItems: 'center' }}
      {...props}
    >
      {children}
    </A>
  );
};
