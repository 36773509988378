import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { SettingDetails } from './Details';
export const SettingsRoutes = () => {
  return (
    <Switch>
      <Route exact path="/admin/settings">
        <Redirect to="/admin/settings/history" />
      </Route>
      <Route path="/admin/settings">
        <SettingDetails />
      </Route>
    </Switch>
  );
};
