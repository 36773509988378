import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ProviderEstablishmentDetails } from './Details';
import { EstablishmentsList } from './List';

export const Establishments = () => {
  return (
    <Switch>
      <Route exact path="/providers/establishments">
        <Redirect to="/providers/establishments/active" />
      </Route>
      <Route exact path="/providers/establishments/:filter(active|locked)">
        <EstablishmentsList />
      </Route>
      <Route path="/providers/establishments/:id/">
        <ProviderEstablishmentDetails />
      </Route>
    </Switch>
  );
};
