export const canDisplayDpaeValidationButton = (filter: any) => {
  return filter === 'to-be-validated';
};

export const canDisplayDpaeResendButton = (filter: any) => {
  return filter === 'pending';
};

export const canDisplayDpaeShowButton = (filter: any) => {
  return filter === 'active';
};
