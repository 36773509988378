import { ModalFrame, ModalProps } from 'components/modals/ModalFrame';
import { MilestonesDetailsForm } from 'forms/activities/Milestones';
import { create } from 'react-modal-promise';
import { useAdditionalActivityFindOne } from '@commons';
import {
  IJoinedAdditionalActivity,
  IJoinedMission,
} from '@freelancelabs/teoreme-commons';
import { SpinnerBox, Box, Status, Text, Flex } from 'components/ui';
import { getStatusData } from 'helpers';
type Props = ModalProps & {
  mission: IJoinedMission;
  beforeValidation?: () => void;
  layer?: number;
  method: 'CREATE' | 'UPDATE';
  uuid?: string;
};

export const CreateOrUpdateMileStoneModal = ({
  onResolve,
  isOpen,
  layer,
  method,
  mission,
  uuid,
}: Props) => {
  const { data: activity, isFetching } = useAdditionalActivityFindOne(uuid);
  const isLoading = isFetching;
  const statusData = activity ? getStatusData(activity) : undefined;
  return (
    <ModalFrame
      isOpen={isOpen}
      onClose={() => onResolve(false)}
      width={1200}
      closeIcon
      layer={layer}
    >
      <Flex justifyContent={'space-between'}>
        <Text variant="h1">
          {method === 'CREATE' ? 'Créer un jalon' : 'Jalon'}
        </Text>
        {method !== 'CREATE' && (
          <Box mr={20}>
            <Status variantColor={statusData?.variantColor}>
              {statusData?.statusText}
            </Status>
          </Box>
        )}
      </Flex>

      {isLoading && <SpinnerBox />}
      {!isLoading && (
        <MilestonesDetailsForm
          mission={mission}
          method={method}
          mileStone={activity as IJoinedAdditionalActivity}
          onResolve={(params: any) => onResolve(params)}
          onClose={(value: any) => onResolve(value)}
        />
      )}
    </ModalFrame>
  );
};

export const showCreateOrUpdateMileStoneModal = create<Props>(
  CreateOrUpdateMileStoneModal
);
