import { ContractorSelect } from 'components/selects/ContractorSelect';
import { useController } from 'react-hook-form';
export const ContractorSelectControlled = (props: any) => {
  const {
    field: { ref, ...inputProps },
  } = useController({
    name: props?.name,
    control: props?.control,
    rules: props?.rules,
    defaultValue: props?.defaultValue,
  });

  return (
    <ContractorSelect
      isDisabled={props.disabled}
      {...inputProps}
      {...props}
      inputRef={ref}
    />
  );
};
