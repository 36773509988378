import { getFirstCaractsEstablisement, queryClient } from '@commons';
import { useEstablishmentFindOne } from '@commons';
import {
  CREATED_SF_STATUS,
  NOT_CREATED_SF_STATUS,
  DISPLAY_SF_STATUS,
} from '@commons';
import { Header } from 'components/Header';
import { SideBar } from 'components/SideBar';
import { SideLink } from 'components/SideLink';
import {
  Avatar,
  Box,
  Container,
  Flex,
  Text,
  SpinnerBox,
  Status,
  Link,
  Divider,
} from 'components/ui';
import { getEstablishmentName, useEstablishmentUpdateOne } from '@commons';
import * as React from 'react';
import { Route, Switch, useParams, Redirect } from 'react-router-dom';
import { EstablishmentProviderContacts } from './Contacts';
import { ExecutiveContractList } from './ExecutiveContractList';
import { EstablishmentProviderInformation } from './Information';
import { format } from 'date-fns';
import { Comments } from './Comment';
import { Invoices } from './Invoices';
import { Missions } from './Missions';
import { History } from './History';
import PageNotFound from 'routes/NotFound';
import { showDialogModal } from 'components/modals/DialogModal';
import { LockIcon, UnLockIcon } from 'components/ui/icons';
import { ELockAction } from '@freelancelabs/teoreme-commons';
import { Theme } from 'styles';
export type InformationPage = {};

export const ProviderEstablishmentDetails: React.FunctionComponent<
  React.PropsWithChildren<InformationPage>
> = () => {
  const { id } = useParams<{ id: string }>();
  const { data: establishment, status } = useEstablishmentFindOne(id);
  const { mutateAsync: updateEstablishment } = useEstablishmentUpdateOne();
  const loading = status === 'pending';
  const salesForce = establishment?.provider?.salesforce;
  if (status === 'error') {
    return (
      <PageNotFound
        top={80}
        customText="L'établissement que vous cherchez semble introuvable."
      />
    );
  }
  const isLocked = establishment?.provider?.locked;
  return (
    <Box>
      <Header>
        <Flex>
          <Avatar
            text={
              (establishment && getFirstCaractsEstablisement(establishment)) ||
              ''
            }
            mr={10}
          />
          <Box>
            <Text variant="h1">
              {(establishment && getEstablishmentName(establishment, 80)) || ''}
            </Text>
            <Status variantColor="success">
              Actif depuis le{' '}
              {establishment?.activeSince
                ? format(establishment.activeSince, 'dd/MM/yyyy')
                : ''}
            </Status>
            {DISPLAY_SF_STATUS && (
              <Status variantColor={salesForce ? 'success' : 'grey'}>
                {salesForce ? CREATED_SF_STATUS : NOT_CREATED_SF_STATUS}
              </Status>
            )}
            <Status
              circleHidden={true}
              variantColor={isLocked ? 'error' : 'success'}
            >
              {isLocked ? (
                <LockIcon
                  fill={Theme?.colors?.error?.default}
                  style={{ marginRight: 7 }}
                />
              ) : (
                <UnLockIcon
                  fill={Theme?.colors?.success?.default}
                  style={{ marginRight: 7 }}
                />
              )}{' '}
              Établissement {isLocked ? 'désactivé' : 'actif'}
            </Status>
          </Box>
        </Flex>
      </Header>
      <Container pt={50}>
        <Flex>
          <SideBar>
            <SideLink
              exact
              href={`/providers/establishments/${id}/information`}
            >
              Informations générales
            </SideLink>
            <SideLink exact href={`/providers/establishments/${id}/contacts`}>
              Contacts
            </SideLink>
            <SideLink
              exact
              href={`/providers/establishments/${id}/executive-contract`}
            >
              {'Contrats-Cadre & RC Pro'}
            </SideLink>
            <SideLink exact href={`/providers/establishments/${id}/missions`}>
              Missions
            </SideLink>
            <SideLink exact href={`/providers/establishments/${id}/invoices`}>
              Factures
            </SideLink>
            <SideLink exact href={`/providers/establishments/${id}/history`}>
              Historique
            </SideLink>
            <SideLink exact href={`/providers/establishments/${id}/comments`}>
              Commentaires
            </SideLink>
            <Divider />
            <SideLink>
              {isLocked ? (
                <Link
                  onClick={() =>
                    showDialogModal({
                      title: `Êtes-vous sûr de vouloir réactiver l'établissement ${getEstablishmentName(
                        establishment
                      )} ?`,
                      text: 'Cette établissement pourra étre séléctionné comme fournisseur pour une nouvelle mission.',
                      confirmLabel: 'Réactiver',
                      cancelLabel: 'Annuler',
                      beforeValidation: async () => {
                        try {
                          await updateEstablishment({
                            uuid: id,
                            establishment: {
                              lockAction: ELockAction.UNLOCK_PROVIDER,
                            },
                          });
                          queryClient?.invalidateQueries({
                            queryKey: ['establishments'],
                          });
                        } catch (e) {
                          //
                        }
                        queryClient?.refetchQueries({
                          queryKey: [id],
                          type: 'active',
                        });
                      },
                    })
                  }
                  iconLeft={<UnLockIcon style={{ marginTop: '5px' }} />}
                >
                  Réactiver l'établissement
                </Link>
              ) : (
                <Link
                  onClick={() =>
                    showDialogModal({
                      title: `Êtes-vous sûr de vouloir désactiver l'établissement ${getEstablishmentName(
                        establishment
                      )} ?`,
                      text: 'Cette établissement ne pourra plus étre séléctionné comme fournisseur pour une nouvelle mission.',
                      confirmLabel: 'Désactiver',
                      cancelLabel: 'Annuler',
                      beforeValidation: async () => {
                        try {
                          await updateEstablishment({
                            uuid: id,
                            establishment: {
                              lockAction: ELockAction.LOCK_PROVIDER,
                            },
                          });
                          queryClient?.invalidateQueries({
                            queryKey: ['establishments'],
                          });
                        } catch (e) {
                          //
                        }
                        queryClient?.refetchQueries({
                          queryKey: [id],
                          type: 'active',
                        });
                      },
                    })
                  }
                  iconLeft={<LockIcon style={{ marginTop: '5px' }} />}
                >
                  Désactiver l'établissement
                </Link>
              )}
            </SideLink>
          </SideBar>
          <Box>
            {loading && <SpinnerBox />}
            {!loading && (
              <Switch>
                <Route exact path="/providers/establishments/:id">
                  <Redirect
                    to={`/providers/establishments/${id}/information`}
                  />
                </Route>
                <Route exact path="/providers/establishments/:id/information">
                  <EstablishmentProviderInformation />
                </Route>
                <Route path="/providers/establishments/:id/contacts">
                  <EstablishmentProviderContacts />
                </Route>
                <Route
                  exact
                  path="/providers/establishments/:id/executive-contract"
                >
                  <ExecutiveContractList />
                </Route>
                <Route path="/providers/establishments/:id/comments">
                  <Comments />
                </Route>
                <Route path="/providers/establishments/:id/history">
                  <History />
                </Route>
                <Route path="/providers/establishments/:id/invoices">
                  <Invoices />
                </Route>
                <Route path="/providers/establishments/:id/missions">
                  <Missions />
                </Route>
              </Switch>
            )}
          </Box>
        </Flex>
      </Container>
    </Box>
  );
};
