import {
  formatAllObject,
  useEstablishmentUpdateOne,
  validateFormPhoneFormatFR,
  checkFormEmail,
} from '@commons';
import {
  ICompanyGetResponse,
  ISigner,
  ESignerType,
} from '@freelancelabs/teoreme-commons';
import { ConfirmNavigationModal } from 'components/modals/ConfirmNavigationModal';
import {
  Box,
  Button,
  FormControl,
  Input,
  Link,
  PhoneInputControlled,
  Radio,
  Row,
  Text,
} from 'components/ui';
import { CancelIcon } from 'components/ui/icons';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yousignNameRestrictionPattern } from '@commons';
type FormValues = any;

type OtherSignersFormProps = {
  onSubmit?: () => void;
  onClose: (action?: any | undefined) => void;
  establishment: ICompanyGetResponse;
  readOnly?: boolean;
  signer?: ISigner;
};

export const OtherSignersForm = ({
  onClose,
  onSubmit: onSubmitParent,
  establishment,
  signer,
}: OtherSignersFormProps) => {
  const [loading, setLoading] = useState(false);
  const { mutateAsync: updateEstablishment } = useEstablishmentUpdateOne();
  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isDirty, isSubmitSuccessful },
  } = useForm<FormValues>({
    defaultValues: {
      signer: { ...signer, civility: String(signer?.civility) },
    },
  });
  const onSubmit = async (formValues: FormValues) => {
    setLoading(true);
    const values = formatAllObject(
      {
        ...formValues,
      },
      yousignNameRestrictionPattern
    );
    try {
      await updateEstablishment({
        uuid: establishment?.uuid,
        establishment: {},
        //@ts-ignore
        addOtherSigners: [
          { ...values?.signer, type: [ESignerType?.BCF_SIGNER] } as ISigner,
        ],
      });
      onClose({
        ...values?.signer,
        type: [ESignerType?.BCF_SIGNER],
      } as ISigner);
    } catch (e) {
      //
    }
    setLoading(false);
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <ConfirmNavigationModal active={isDirty && !isSubmitSuccessful} />
      <Text variant="h2">Informations du signataire des bons de commande</Text>
      <Box>
        <Box mt={20}>
          <FormControl
            required={true}
            label={'Civilité'}
            errorMessage={errors?.signer?.civility?.message}
          >
            <>
              <Radio
                {...register('signer.civility', {
                  required: 'Ce champ est requis',
                })}
                value="1"
              >
                Monsieur
              </Radio>
              <Radio
                {...register('signer.civility', {
                  required: 'Ce champ est requis',
                })}
                value="2"
              >
                Madame
              </Radio>
            </>
          </FormControl>
        </Box>
        <Row spacing={20}>
          <FormControl
            required={true}
            label="Prénom"
            errorMessage={errors?.signer?.firstName?.message}
          >
            <Input
              isFullWidth
              {...register('signer.firstName', {
                required: 'Ce champ est requis',
              })}
            />
          </FormControl>
          <FormControl
            required={true}
            label="Nom"
            errorMessage={errors?.signer?.lastName?.message}
          >
            <Input
              isFullWidth
              {...register('signer.lastName', {
                required: 'Ce champ est requis',
              })}
            />
          </FormControl>
        </Row>
        <Row spacing={20}>
          <FormControl
            required={true}
            label="Email"
            errorMessage={errors?.signer?.email?.message}
          >
            <Input
              isFullWidth
              {...register('signer.email', {
                required: 'Ce champ est requis',
                validate: (value: string) => checkFormEmail(value),
              })}
              isDisabled={signer ? true : false}
            />
          </FormControl>
          <FormControl
            label="Téléphone"
            errorMessage={errors?.signer?.phone?.message}
          >
            <PhoneInputControlled
              control={control}
              rules={{
                validate: (value: string) => {
                  return validateFormPhoneFormatFR(value);
                },
              }}
              isFullWidth
              name="signer.phone"
            />
          </FormControl>
        </Row>
        <Row spacing={20}>
          <FormControl
            required={true}
            label="Fonction"
            errorMessage={errors?.signer?.jobTitle?.message}
          >
            <Input
              {...register('signer.jobTitle', {
                required: 'Ce champ est requis',
              })}
            />
          </FormControl>
          <></>
        </Row>
        <Row spacing={20} width={'300px'}>
          <Button key="submit" type="submit" isLoading={loading}>
            Enregistrer
          </Button>
          <Link
            iconLeft={<CancelIcon />}
            mt={'20px'}
            onClick={() => onClose(false)}
          >
            Annuler
          </Link>
        </Row>
      </Box>
    </form>
  );
};
