import { IEstablishmentMinifiedUsers } from '@freelancelabs/teoreme-commons';
import { ModalFrame, ModalProps } from 'components/modals/ModalFrame';
import {
  Button,
  Text,
  Box,
  FormControl,
  Input,
  Row,
  NumberFormInputControlled,
  TextAreaControlled,
} from 'components/ui';
import React from 'react';
import { useForm } from 'react-hook-form';
import { create } from 'react-modal-promise';
import { useEstablishmentUpdateOne } from '@commons';
import { queryClient } from '@commons';
import { useShowMessage } from 'hooks/useShowMessage';
type Props = ModalProps & {
  beforeValidation?: () => void;
  establishment: IEstablishmentMinifiedUsers;
};
type FormValues = {
  margin: number;
  marginComment?: string;
};

export const UpdateMarginModal = ({
  onResolve,
  isOpen,
  establishment,
}: Props) => {
  const [loading, setLoading] = React.useState(false);
  const {
    handleSubmit,
    formState: { errors },
    control,
    watch,
  } = useForm<FormValues>({
    defaultValues: {
      margin: establishment?.customer?.margin,
      marginComment: establishment?.customer?.marginComment,
    },
  });
  const showMessage = useShowMessage();
  const { mutateAsync: updateEstablishment } = useEstablishmentUpdateOne();
  const onSubmit = async (formData: FormValues) => {
    setLoading(true);
    try {
      await updateEstablishment({
        uuid: establishment?.uuid,
        establishment: {
          customer: {
            margin: formData.margin,
            marginComment: formData.marginComment,
          },
        },
      });
      queryClient.refetchQueries({ queryKey: [establishment?.uuid] });
      showMessage('success', 'Taux de marque mis à jour');
    } catch (e) {
      setLoading(false);
    }
    onResolve();
    setLoading(false);
  };

  const margin = watch('margin');

  return (
    <ModalFrame
      isOpen={isOpen}
      onClose={() => onResolve(false)}
      width={670}
      closeIcon
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box>
          <Text variant="h3" mb={20}>
            Modifier le taux de marque de l'établissement client
          </Text>
          <Row spacing={20}>
            <FormControl
              required
              label="Taux de marque"
              errorMessage={errors?.margin?.message}
            >
              <NumberFormInputControlled
                control={control}
                isFullWidth
                name="margin"
                type="number"
                rules={{
                  required: 'Ce champ est requis',
                  validate: (value: number) =>
                    value >= 100 || value < 0
                      ? 'La valeur doit être comprise entre 0 et 99%'
                      : undefined,
                }}
              />
            </FormControl>
            <FormControl required label="Unité">
              <Input isFullWidth isDisabled value="% du prix de vente HT" />
            </FormControl>
          </Row>
          <Box>
            <FormControl label="Commentaire">
              <TextAreaControlled
                minRows={3}
                name="marginComment"
                control={control}
              />
            </FormControl>
          </Box>

          <Button
            key="submit"
            type="submit"
            isLoading={loading}
            isDisabled={!margin}
          >
            Enregistrer
          </Button>
        </Box>
      </form>
    </ModalFrame>
  );
};

export const showUpdateMarginModal = create<Props>(UpdateMarginModal);
