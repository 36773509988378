import {
  EFileType,
  EQuoteStatus,
  IJoinedMission,
} from '@freelancelabs/teoreme-commons';
import {
  getEstablishmentName,
  getFullName,
  getHumanDate,
  missionSendFile,
  missionUpdateOne,
} from '@commons';
import { uploadFile } from '@commons';
import { FileCard } from 'components/cards/FileCard';
import { showDialogModal } from 'components/modals/DialogModal';
import { showGenerateQuoteModal } from 'components/modals/generateFile/GenerateQuoteModal';
import {
  BlocInformation,
  FileInput,
  Flex,
  Link,
  MenuItem,
  SpinnerBox,
  Status,
  Text,
} from 'components/ui';
import { EditIcon, SendIcon, UploadIcon } from 'components/ui/icons';
import React, { useState } from 'react';
import AddBloc from 'components/AddBloc';
import { FR_STATUS } from '@commons';
import { formatDate } from '@commons';
import { queryClient } from '@commons';
import { useShowMessage } from 'hooks/useShowMessage';
type QuoteCardProps = {
  mission: IJoinedMission;
  isDisabled: boolean;
};

export const QuoteCard = ({ mission, isDisabled }: QuoteCardProps) => {
  const showMessage = useShowMessage();
  const [loading, setLoading] = useState(false);

  const client = mission.customer;

  const fileName =
    client.quote?.status === EQuoteStatus.SENT
      ? client.quote.file.fileName
      : `${
          client.quote?.reference || 'DV'
        }_${client.establishment.businessName.replace(/ /g, '_')}.pdf`;

  const onImportQuote = async (fileSelected: File[] | null) => {
    if (!fileSelected) return;
    setLoading(true);

    try {
      const s3Response = await uploadFile({
        file: fileSelected[0],
        fileType: EFileType.QUOTE,
        actionType: 'upload',
        missionRef: mission.reference,
      });

      if (!s3Response) throw new Error();

      await missionUpdateOne({
        reference: mission.reference,
        mission: {
          customer: {
            // @ts-ignore
            quote: {
              file: {
                eTag: s3Response?.eTag,
                fileLocation: s3Response?.fileLocation,
              },
              //status: EQuoteStatus.IMPORTED,
            },
          },
        },
      });
      queryClient.refetchQueries({ queryKey: [mission.reference] });

      setLoading(false);

      await showDialogModal({
        title: 'Votre devis client a bien été importé',
        text: "Attention : Les informations de la fiche Mission courante n'ont pas été mises à jour avec les informations contenues dans ce devis client, merci de vérifier et de procéder si besoin à leur mise à jour manuelle.",
        confirmLabel: "J'ai bien compris",
      });
    } finally {
      setLoading(false);
    }
  };
  const handSendEmailQuote = async () => {
    try {
      await missionSendFile({
        mission: {
          reference: mission?.reference,
        },
        sendToProvider: true,
        template: EFileType.QUOTE,
      });
      showMessage('success', 'Le devis à bien été envoyé au fournisseur');
      queryClient.refetchQueries({ queryKey: [mission?.reference] });
    } catch (e) {
      //
    }
  };
  const handleOpenQuoteModal = async () => {
    if (Object.keys(mission?.provider).length === 0) {
      try {
        await showDialogModal({
          title:
            'Vous n’avez pas encore ajouté le fournisseur de la prestation à venir.',
          text: 'Avant d’envoyer le devis, merci de vous assurer des éléments suivants :',
          confirmLabel: 'Continuer',
          checkboxConfirmation: [
            "J’ai bien pris connaissance du statut et du type de contrat-cadre de l'établissement fournisseur.",
            'J’ai bien pris connaissance du statut de la DPAE de l’intervenant dans l’établissement fournisseur.',
          ],
        })
          .then(reponse => {
            if (reponse === true) {
              showGenerateQuoteModal({
                mission: mission,
                missionReference: mission.reference,
                isDisabled: isDisabled,
              });
            }
          })
          .catch(e => {
            //
          });
      } catch (e) {}
    } else {
      showGenerateQuoteModal({
        mission: mission,
        missionReference: mission.reference,
        isDisabled: isDisabled,
      });
    }
  };
  if (loading) return <SpinnerBox height={92} />;
  if (isDisabled && !client.quote) {
    return (
      <BlocInformation>
        <p>Aucun devis client</p>
      </BlocInformation>
    );
  }
  if (!client.quote && !isDisabled) {
    return (
      <>
        {!mission.sage || !mission?.billing ? (
          <BlocInformation>
            <p>
              Avant de pouvoir envoyer votre premier devis, merci de créer votre
              mission dans GESCOM en complétant les champs{' '}
              <b>
                “structure de facturation”, “Intitulé de la mission”, “date de
                début” et “date de fin”, "Tarification"
              </b>{' '}
              depuis vos rubriques{' '}
              <b>“Informations de la mission“ et “Informations générales“</b>
            </p>
          </BlocInformation>
        ) : (
          <></>
        )}
        <AddBloc>
          <Flex>
            {mission.sage && mission?.billing ? (
              <>
                <Link iconLeft={<SendIcon />} onClick={handleOpenQuoteModal}>
                  Génerer et envoyer le devis
                </Link>

                <Text m="0 10px">ou</Text>
              </>
            ) : (
              <>
                <Link variantColor="grey" iconLeft={<SendIcon />}>
                  Génerer et envoyer le devis
                </Link>

                <Text m="0 10px">ou</Text>
              </>
            )}
            <FileInput onSelect={onImportQuote} variant="link" accept={'.pdf'}>
              <Link iconLeft={<UploadIcon />}>Importer le devis (PDF)</Link>
            </FileInput>
          </Flex>
        </AddBloc>
      </>
    );
  }
  const getMailToHref = () => {
    const email = mission?.provider?.contact?.email;
    const subject = `Prestation ${getEstablishmentName(
      client?.establishment,
      1000
    )}`;
    const body = `Bonjour,%0D%0A%0D%0AVeuillez trouver ci-joint le devis transmis à l’Oréal pour la prise en charge de cette prestation.%0D%0A%0D%0AUne fois réalisée, vous pourrez transmettre votre facture selon le process du mandat de gestion en place, pour rappel :%0D%0A%0D%0AVotre facture devra être libellée au nom de l’Oréal (comme dans les devis) et devra être déposée sur https://connecteed.freelance.com .%0D%0A%0D%0AIMPORTANT : Il faudra également faire apparaître sur la facture la mention : « Facture adressée par Freelance.com, mandataire, au nom et pour le compte de (votre société), mandant ».%0D%0A%0D%0AVos factures seront ensuite adressées par Freelance.com à votre client en votre nom et pour votre compte.%0D%0A%0D%0AA votre disposition pour tout complément d’information.%0D%0A%0D%0ABien cordialement,%0D%0A%0D%0A ${getFullName(
      mission?.accountManager
    )}`;
    return `mailto:${encodeURIComponent(
      email || ''
    )}?subject=${encodeURIComponent(subject || '')}&body=${body}`;
  };
  return (
    <FileCard
      fileName={fileName || ''}
      fileLocation={client.quote?.file.fileLocation || ''}
      complement={
        <Flex flexWrap={'wrap'}>
          <Status variantColor="success">{`${
            client?.quote?.status ? FR_STATUS[client.quote?.status] : ''
          } le ${formatDate(
            client?.quote?.updatedAt || client?.quote?.createdAt || ''
          )}`}</Status>
          {client?.quote?.quoteSentToProviderContactAt && (
            <Status width={1 / 1} variantColor="success">
              Envoyé au fournisseur le{' '}
              {getHumanDate(
                client?.quote?.quoteSentToProviderContactAt,
                ' dd/MM/yyyy à HH:mm'
              )}{' '}
            </Status>
          )}
        </Flex>
      }
      actions={
        !isDisabled && (
          <>
            {mission?.customer?.quote &&
              mission?.isMandateMode &&
              mission?.provider?.contact && (
                <MenuItem onClick={handSendEmailQuote}>
                  <Link iconLeft={<SendIcon />}>
                    Envoyer le devis au fournisseur
                  </Link>
                </MenuItem>
              )}
            {mission?.customer?.quote &&
              mission?.isMandateMode &&
              mission?.provider?.contact && (
                <MenuItem>
                  <a href={getMailToHref()}>
                    <Link iconLeft={<EditIcon />}>
                      Créer un email pour le devis du fournisseur
                    </Link>
                  </a>
                </MenuItem>
              )}

            <MenuItem
              onClick={() =>
                showGenerateQuoteModal({
                  mission: mission,
                  missionReference: mission.reference,
                  isDisabled: isDisabled,
                })
              }
            >
              <Link iconLeft={<SendIcon />}>Génerer et envoyer un devis</Link>
            </MenuItem>
            <MenuItem>
              <FileInput
                variant="link"
                onSelect={onImportQuote}
                accept={'.pdf'}
              >
                <Link iconLeft={<UploadIcon />}>Importer un autre devis</Link>
              </FileInput>
            </MenuItem>
          </>
        )
      }
    />
  );
};
