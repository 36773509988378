import * as React from 'react';
import { BoxProps } from '../Box';
import { Square } from './avatar.styled';

type Props = {
  size?: number; // px
  text?: string;
  textSize?: string;
};

export const Avatar: React.FC<React.PropsWithChildren<Props & BoxProps>> = ({
  size,
  text,
  textSize,
  ...props
}) => {
  return (
    <Square size={size ? size : 60} {...props}>
      <span style={{ fontSize: textSize ? textSize : '20px' }}>{text}</span>
    </Square>
  );
};

export default Avatar;
