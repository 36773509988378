import { COMMENT_SERVICE_URL } from '../../constantz';
import { fetcher } from '../helpers/fetcher';

export const commentFindMany = (searchParams: any, disableAlert?: boolean) =>
  fetcher<any, any>(
    COMMENT_SERVICE_URL + `/search`,
    {
      method: 'POST',
      body: searchParams,
    },
    disableAlert
  );

export const commentManage = (body: any, disableAlert?: boolean) =>
  fetcher<any, any>(
    COMMENT_SERVICE_URL + `/manage`,
    {
      method: 'POST',
      body,
    },
    disableAlert
  );
