import {
  EInvoicePaymentLabel,
  EInvoiceSendMethod,
  IEstablishmentBase,
} from '@freelancelabs/teoreme-commons';
import {
  formatDate,
  useEstablishmentFindOne,
  getTradeNameSafe,
  useEstablishmentUpdateOne,
  useMe,
  checkUserHasRole,
  ERoles,
  MIXED_TAG_INPUT_SETTING,
  CUSTOMER_REFERENCE_PATTERN_TAG_WHITELIST,
  transformFormulaToTag,
} from '@commons';
import {
  Flex,
  Box,
  Link,
  Text,
  CheckSwitch,
  Row,
  FormLabel,
  BlocInformation,
  Spinner,
  Table,
} from 'components/ui';
import { MixedModeTagify } from 'components/InputTags';
import { AddIcon, EditIcon } from 'components/ui/icons';
import { showInvoiceContactModal } from 'components/modals/InvoiceContactModal';
import { useSetCrumbs } from 'hooks/breadCrumb';
import React from 'react';
import { useParams } from 'react-router-dom';
import { showAddICustomerRefPatternModal } from 'components/modals/AddICustomerRefPatternModal';
import { useTheme } from 'styled-components';
import { queryClient } from '@commons';
import { showAddPaiementEstaClientModal } from 'components/modals/AddPaiementEstaClientModal';
import { EPaymentMethod } from '@freelancelabs/teoreme-commons';
import { showDisplayPdfModal } from 'components/modals/DisplayPdfModal';
import { printFormat } from 'iban';
import { useShowMessage } from 'hooks/useShowMessage';
import { showAddProviderCustomMessageModal } from 'components/modals/AddProviderCustomMessageModal';
import AddBloc from 'components/AddBloc';
import { columnsInvoiceAdresses } from './TableConfig';
import { showAddEstablishmentAdressModal } from 'components/modals/clients/AddEstablishmentAdressModal';

export const ClientsEstablishmentsBilling = () => {
  const { me } = useMe();
  const { uuid } = useParams<{ uuid: string }>();
  const { data: establishment, isFetching } = useEstablishmentFindOne(uuid);
  const [shouldValidateCra, setShouldValidateCra] = React.useState(
    establishment?.customer?.shouldValidateCra
  );
  const [isDirectPayment, setIsDirectPayment] = React.useState(
    establishment?.customer?.billingOptions?.isDirectPayment || false
  );

  const [isLoadingAdresses, setIsLoadingAdresses] = React.useState(false);
  const showMessage = useShowMessage();
  const { mutateAsync: updateEstablishment } = useEstablishmentUpdateOne();
  const Theme = useTheme();
  useSetCrumbs(
    [establishment],
    [
      {
        label: 'clients',
        path: '/clients',
      },
      {
        label: 'établissements',
        path: `/clients/establishments`,
      },
      {
        label: establishment ? getTradeNameSafe(establishment) : '-',
        path: `/clients/establishments/${establishment?.uuid}`,
      },
      {
        label: 'contacts',
        path: `/clients/establishments/${establishment?.uuid}/contacts`,
      },
    ]
  );
  const onUpdateValidateCra = async (SValidateCra: boolean) => {
    setShouldValidateCra(SValidateCra);
    try {
      const establishmentData = {
        uuid: establishment?.uuid as string,
        establishment: {
          customer: {
            shouldValidateCra: SValidateCra,
          },
        },
      };
      await updateEstablishment(establishmentData);
    } catch (e) {
      //
    }
    queryClient.refetchQueries({ queryKey: [establishment?.uuid] });
  };

  const onUpdateIsDirectPayment = async (isDirectPayment: boolean) => {
    setIsDirectPayment(isDirectPayment);
    try {
      const establishmentData = {
        uuid: establishment?.uuid as string,
        establishment: {
          customer: {
            billingOptions: {
              isDirectPayment,
            },
          },
        },
      };
      await updateEstablishment(establishmentData);
    } catch (e) {
      //
    }
    queryClient.refetchQueries({ queryKey: [establishment?.uuid] });
  };

  const createAdressInGescom = async (adress: any) => {
    try {
      setIsLoadingAdresses(true);
      //@ts-ignore
      await updateEstablishment({
        uuid: establishment?.uuid as string,
        upsertInvoiceAddress: adress,
      });
      setIsLoadingAdresses(false);
      showMessage('success', 'Adresse ajoutée dans GESCOM');

      queryClient.refetchQueries({ queryKey: [establishment?.uuid] });
    } catch (error) {
      setIsLoadingAdresses(false);
    }
  };
  React.useEffect(() => {
    setShouldValidateCra(
      establishment?.customer?.shouldValidateCra ? true : false
    );
  }, [establishment]);
  if (!establishment) return null;
  const { invoiceAddress } = establishment?.customer;

  const customMessageForProvider =
    establishment?.customer?.customMessageForProvider;
  return (
    <Box mb={20}>
      <Box mb={20}>
        <Text variant="h2">Options de dépôt du CRA</Text>
        <Box mb={20}>
          <CheckSwitch
            mt={20}
            id="shouldValidateCra"
            defaultChecked={shouldValidateCra}
            onChange={e => onUpdateValidateCra(e?.target?.checked)}
            checked={shouldValidateCra as boolean}
          >
            <Text
              variant="p"
              color={
                shouldValidateCra
                  ? Theme?.colors?.success?.default
                  : Theme?.colors?.error?.default
              }
            >
              {shouldValidateCra
                ? 'Permettre au partenaire de soumettre son CRA au client'
                : 'Ne pas permettre au partenaire de soumettre son CRA au client'}
            </Text>
          </CheckSwitch>
        </Box>
      </Box>
      <Box mb={20}>
        <Text variant="h2">
          Message à afficher au partenaire lors du dépôt de son CRA
          <Link
            iconLeft={<EditIcon scale={20} />}
            ml={10}
            onClick={() =>
              showAddProviderCustomMessageModal({
                customMessageForProvider: customMessageForProvider,
                beforeValidation: async (customMessageForProvider?: string) => {
                  if (customMessageForProvider !== undefined) {
                    try {
                      await updateEstablishment({
                        uuid: establishment?.uuid as string,
                        establishment: {
                          customer: {
                            //@ts-ignore
                            customMessageForProvider: customMessageForProvider,
                          },
                        },
                      });
                      queryClient.refetchQueries({
                        queryKey: [establishment?.uuid],
                      });
                    } catch (e) {
                      //
                    }
                  }
                },
              })
            }
          >
            Modifier
          </Link>
        </Text>
        <Box ml={10} mt={20}>
          {customMessageForProvider ? (
            <div
              dangerouslySetInnerHTML={{
                __html: customMessageForProvider,
              }}
            ></div>
          ) : (
            'N/A'
          )}
        </Box>
      </Box>
      <Box>
        <Text mb={20} variant="h2">
          Référence de facturation
          {checkUserHasRole(me, ERoles?.ADMIN) && (
            <Link
              iconLeft={<EditIcon scale={20} />}
              ml={10}
              onClick={() =>
                showAddICustomerRefPatternModal({
                  customerRefPattern:
                    establishment?.customer?.billingOptions?.customerRefPattern,
                  beforeValidation: async customerRefPattern => {
                    if (customerRefPattern !== undefined) {
                      try {
                        const establishmentData = {
                          uuid: establishment?.uuid as string,
                          establishment: {
                            customer: {
                              billingOptions: {
                                customerRefPattern,
                              },
                            },
                          },
                        };
                        await updateEstablishment(establishmentData);
                        queryClient?.refetchQueries({
                          queryKey: [establishment?.uuid as string],
                        });
                        showMessage(
                          'success',
                          'La référence à été mise à jours'
                        );
                      } catch (e) {
                        //
                      }
                    }
                  },
                })
              }
            >
              Modifier
            </Link>
          )}
        </Text>
        {isFetching ? (
          <Spinner />
        ) : (
          <Flex mb={20} alignItems="center">
            <Text mb={10}>Référence de facturation paramétrée : </Text>
            {establishment?.customer?.billingOptions?.customerRefPattern ? (
              <MixedModeTagify
                settings={{
                  ...MIXED_TAG_INPUT_SETTING,
                  whitelist: CUSTOMER_REFERENCE_PATTERN_TAG_WHITELIST,
                }}
                value={
                  transformFormulaToTag(
                    establishment?.customer?.billingOptions?.customerRefPattern,
                    CUSTOMER_REFERENCE_PATTERN_TAG_WHITELIST
                  ) + ' '
                }
                readOnly
              />
            ) : (
              <Text mb={10} ml={'5px'}>
                N/A
              </Text>
            )}
          </Flex>
        )}
      </Box>
      <Box>
        <Text mb={20} variant="h2">
          Adresse de facturation
        </Text>
        {invoiceAddress && invoiceAddress.length > 0 && (
          <Box ml={20} mt={'10px'}>
            <Link
              iconLeft={<EditIcon />}
              onClick={() =>
                showAddEstablishmentAdressModal({
                  establishment: establishment,
                })?.then(async action => {
                  queryClient?.refetchQueries({
                    queryKey: [establishment?.uuid],
                  });
                })
              }
            >
              Ajouter une adresse de facturation
            </Link>
          </Box>
        )}
        {invoiceAddress && invoiceAddress?.length > 0 ? (
          <Table
            my={20}
            columns={columnsInvoiceAdresses(
              invoiceAddress,
              establishment,
              createAdressInGescom
            )}
            items={invoiceAddress as any}
            total={0}
            perPage={2000}
            isLoading={isLoadingAdresses}
          />
        ) : (
          <AddBloc>
            <Link
              data-cy="add-customer-adress"
              iconLeft={<AddIcon />}
              onClick={() =>
                showAddEstablishmentAdressModal({
                  establishment: establishment,
                })?.then(async action => {
                  queryClient?.refetchQueries({
                    queryKey: [establishment?.uuid],
                  });
                })
              }
            >
              Ajouter une adresse de facturation
            </Link>
          </AddBloc>
        )}
      </Box>
      <Box>
        {/* <Text mb={20} mt={20} variant="h2">
          Conditions de règlement
          {establishment.customer.paymentDetails && (
            <Link
              iconLeft={<EditIcon scale={20} />}
              ml={10}
              onClick={() =>
                showAddPaiementEstaClientModal({ id: establishment.uuid })
              }
            >
              Modifier
            </Link>
          )}
        </Text> */}
      </Box>
      <Box>
        <Box display={'inline-flex'} mb={20}>
          <Text variant="h2">Options de facturation</Text>
          <Link
            ml={3}
            mt={1}
            onClick={() =>
              showInvoiceContactModal({
                establishment: establishment as unknown as IEstablishmentBase,
              })?.then(() =>
                queryClient?.refetchQueries({ queryKey: [establishment?.uuid] })
              )
            }
            iconLeft={<EditIcon />}
          >
            Modifier
          </Link>
        </Box>
        <Box>
          {!establishment.customer.paymentDetails && (
            <Box>
              <Box mb={10}>
                <BlocInformation>
                  <p>
                    A noter : par défaut, les conditions de règlement de ce
                    client sont : Virement à 60 jours
                  </p>
                </BlocInformation>
              </Box>
              <Flex
                alignItems="center"
                justifyContent="center"
                backgroundColor="primary.lighter"
                p={50}
                mb={20}
              >
                <Link
                  onClick={() =>
                    showAddPaiementEstaClientModal({
                      id: establishment?.uuid as string,
                    })
                  }
                  iconLeft={<AddIcon />}
                >
                  Modifier les conditions de règlement
                </Link>
              </Flex>
            </Box>
          )}
          {establishment.customer.paymentDetails &&
            establishment?.customer?.paymentDetails?.paymentMethod ===
              EPaymentMethod.BANK_TRANSFER && (
              <Box mb={20}>
                <FormLabel>Mode de règlement : </FormLabel>
                <Text variant="p">Virement</Text>
                {establishment?.customer?.paymentDetails?.paymentDeadline && (
                  <></>
                  // <Box mt={20}>
                  //   <FormLabel>Délai de paiement :</FormLabel>
                  //   <Text variant="p">
                  //     {`${establishment?.customer?.paymentDetails?.paymentDeadline} jours`}
                  //   </Text>
                  // </Box>
                )}
              </Box>
            )}

          {establishment.customer.paymentDetails &&
            establishment?.customer?.paymentDetails?.paymentMethod ===
              EPaymentMethod.DIRECT_DEBIT && (
              <Box mb={20}>
                <Row spacing={20}>
                  <Box>
                    <FormLabel>Mode de règlement : </FormLabel>
                    <Text variant="p">Prélévement</Text>
                  </Box>
                  <Box>
                    <FormLabel>Type de paiement : </FormLabel>
                    <Text variant="p">
                      {establishment?.customer?.paymentDetails?.sepaMandate
                        ?.recurrent
                        ? 'Paiements récurrents'
                        : 'Paiements ponctuels'}
                    </Text>
                  </Box>
                </Row>

                <Row spacing={20} mt={20}>
                  <Box>
                    <FormLabel>RUM (RÉFÉRENCE UNIQUE DU MANDAT) : </FormLabel>
                    <Text variant="p">
                      {
                        establishment?.customer?.paymentDetails?.sepaMandate
                          ?.rum
                      }
                    </Text>
                  </Box>
                  <Box>
                    <FormLabel>Date de signature : </FormLabel>
                    <Text variant="p">
                      {formatDate(
                        establishment?.customer?.paymentDetails?.sepaMandate
                          ?.signedAt || ''
                      )}
                    </Text>
                  </Box>
                </Row>

                <Row spacing={20} mt={20}>
                  <Box>
                    <FormLabel>IBAN : </FormLabel>
                    <Text variant="p">
                      {establishment?.customer?.paymentDetails?.sepaMandate
                        ?.bankAccount?.iban
                        ? printFormat(
                            establishment?.customer?.paymentDetails?.sepaMandate
                              ?.bankAccount?.iban,
                            ' '
                          )
                        : 'N/A'}
                    </Text>
                  </Box>
                  <Box>
                    <FormLabel>BIC: </FormLabel>
                    <Text variant="p">
                      {
                        establishment?.customer?.paymentDetails?.sepaMandate
                          ?.bankAccount?.bic
                      }
                    </Text>
                  </Box>
                </Row>
                <Row spacing={20} mt={20}>
                  <Box>
                    <FormLabel>NOM DE LA BANQUE : </FormLabel>
                    <Text variant="p">
                      {
                        establishment?.customer?.paymentDetails?.sepaMandate
                          ?.bankAccount?.bankName
                      }
                    </Text>
                  </Box>
                  <Box>
                    <FormLabel>TITULAIRE DU COMPTE : </FormLabel>
                    <Text variant="p">
                      {
                        establishment?.customer?.paymentDetails?.sepaMandate
                          ?.bankAccount?.owner
                      }
                    </Text>
                  </Box>
                </Row>

                <Row spacing={20}>
                  <Box mt={20}>
                    <FormLabel>Mandat SEPA signé : </FormLabel>
                    <Text variant="p">
                      <Link
                        mr={10}
                        onClick={() =>
                          showDisplayPdfModal({
                            fileLocation:
                              establishment?.customer?.paymentDetails
                                ?.sepaMandate?.mandateFile?.fileLocation || '',
                            fileName: `${establishment?.customer?.paymentDetails?.sepaMandate?.mandateFile?.fileName}.pdf`,
                            layer: 200,
                          })
                        }
                      >
                        {`${establishment?.customer?.paymentDetails?.sepaMandate?.mandateFile?.fileName}`}
                      </Link>
                    </Text>
                  </Box>
                  <Box mt={20}>
                    <FormLabel>RELEVÉ D'IDENTITÉ BANCAIRE : </FormLabel>
                    <Text variant="p">
                      <Link
                        mr={10}
                        onClick={() =>
                          showDisplayPdfModal({
                            fileLocation:
                              establishment?.customer?.paymentDetails
                                ?.sepaMandate?.bankAccount?.rib?.fileLocation ||
                              '',
                            fileName: `${establishment?.customer?.paymentDetails?.sepaMandate?.bankAccount?.rib?.fileName}.pdf`,
                            layer: 200,
                          })
                        }
                      >
                        {`${establishment?.customer?.paymentDetails?.sepaMandate?.bankAccount?.rib?.fileName}`}
                      </Link>
                    </Text>
                  </Box>
                </Row>
              </Box>
            )}
        </Box>
        <Box width={1 / 1}>
          Email :
          <Text ml={'5px'} mb={'2px'} fontSize={13} variant="a">
            {establishment?.customer?.invoiceContact?.email ? (
              <a
                href={`mailto: ${establishment?.customer?.invoiceContact?.email}`}
              >
                {establishment?.customer?.invoiceContact?.email}
              </a>
            ) : (
              'N/A'
            )}
          </Text>
        </Box>
        <Flex display={'inline-flex'} flexWrap={'wrap'} width={1 / 1}>
          <Text>Edition automatique de la facture client :</Text>
          {/*@ts-ignore*/}
          {establishment?.customer?.billingOptions?.isAutoBilling ? (
            <Text ml={'5px'} color={Theme.colors?.success?.default}>
              activé
            </Text>
          ) : (
            <Text ml={'5px'} color={Theme.colors?.error?.default}>
              désactivé
            </Text>
          )}
        </Flex>
        <Flex display={'inline-flex'} flexWrap={'wrap'} width={1 / 1}>
          <Text>Envoi automatique de la facture client par email :</Text>
          {establishment?.customer?.billingOptions?.isAutoSendEmail ? (
            <Text ml={'5px'} color={Theme.colors?.success?.default}>
              activé
            </Text>
          ) : (
            <Text ml={'5px'} color={Theme.colors?.error?.default}>
              désactivé
            </Text>
          )}
        </Flex>
        <Flex display={'inline-flex'} flexWrap={'wrap'} width={1 / 1}>
          <Text>Joindre les justificatifs à la facture client :</Text>
          {/*@ts-ignore*/}
          {establishment?.customer?.billingOptions?.shouldJoinProofToInvoice ? (
            <Text ml={'5px'} color={Theme.colors?.success?.default}>
              activé
            </Text>
          ) : (
            <Text ml={'5px'} color={Theme.colors?.error?.default}>
              désactivé
            </Text>
          )}
        </Flex>
        <Box width={1 / 1}>
          <Text>
            Mode de distribution : &nbsp;
            {establishment?.customer?.billingOptions?.sendMethod ===
              EInvoiceSendMethod?.EMAIL && 'Email'}
            {establishment?.customer?.billingOptions?.sendMethod ===
              EInvoiceSendMethod?.OTHER && 'Autre'}
            {!establishment?.customer?.billingOptions?.sendMethod && 'N/A'}
          </Text>
        </Box>
        <Box width={1 / 1}>
          <Text>
            Modalité de paiement : &nbsp;
            {establishment?.customer?.billingOptions?.paymentLabel ===
              EInvoicePaymentLabel?.END_OF_MONTH && 'Fin de mois'}
            {establishment?.customer?.billingOptions?.paymentLabel ===
              EInvoicePaymentLabel?.FIXED && "Date d'émission de la facture"}
            {!establishment?.customer?.billingOptions?.paymentLabel && 'N/A'}
          </Text>
        </Box>

        <Text>
          Délai de paiement :{' '}
          {establishment?.customer?.paymentDetails?.paymentDeadline +
            ' jours' || '_'}
        </Text>

        <Text>
          Commentaire :{' '}
          {establishment?.customer?.invoiceContact?.comment || '_'}
        </Text>
      </Box>
    </Box>
  );
};
