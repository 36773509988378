import {
  IJoinedContractor,
  IContractorCreateParams,
  IJoinedFlatUser,
} from '@freelancelabs/teoreme-commons';
import { ConfirmNavigationModal } from 'components/modals/ConfirmNavigationModal';
import {
  ERoles,
  CONTRACTOR_SOCIAL_STATUS_SELECT,
  IUserLocal,
  userFindOne,
  userFindMany,
  checkUserHasRole,
  contractorFindOneByCognitoUserId,
  escapeEmail,
  setBirthdayDateToZeroHours,
  validateFormPhoneFormatFR,
  checkFormEmail,
} from '@commons';
import { useUserFindOne, useEstablishmentFindOne } from '@commons'; // moved
import { BlocInformation } from 'components/ui';
import { showAddContactModal } from 'components/modals/AddContactModal';
import { showAddEstablishmentModal } from 'components/modals/AddEstablishmentModal';
import { ContactExistsMessage } from 'components/ContactExistsMessage';
import {
  Flex,
  Box,
  Button,
  CheckSwitch,
  FormControl,
  FormLabel,
  Input,
  Link,
  Radio,
  Row,
  Text,
  PhoneInputControlled,
  DatePickerControlled,
  StaticSelectControlled,
  UserSelectControlled,
  EstablishmentSelectControlled,
} from 'components/ui';
import { AddIcon } from 'components/ui/icons';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { differenceInYears } from 'date-fns';
import { useTheme } from 'styled-components';

type FormValues = IContractorCreateParams & any;
type AddContractorFormProps = {
  onSubmit?: (formValues: FormValues) => any | void;
  contactIsContractor?: boolean;
  noEstablishment?: boolean;
};

export const AddContractorForm: React.FC<
  React.PropsWithChildren<AddContractorFormProps>
> = ({
  onSubmit: onSubmitParent,
  contactIsContractor: defaultContactIsContractor,
  noEstablishment,
}) => {
  const [userCheckedContractor, setUserCheckedContractor] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [sendAppInvitation, setSendAppInvitation] = React.useState(false);
  const [isLinked, setIsLinked] = useState<boolean | null>(null);
  const [userExist, setUserExist] = useState(false);
  const [user, setUser] = useState<
    Partial<IUserLocal | IJoinedContractor> | undefined
  >(undefined);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [errorExist, setErrorExist] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [emailForCheck, setEmail] = useState<string | false>(false);
  const [emailChecked, setEmailChecked] = useState(true);
  const [loading, setLoading] = useState(false);
  const theme = useTheme();
  const {
    handleSubmit,
    control,
    register,
    watch,
    setValue,
    getValues,
    formState: { errors, isDirty, isSubmitSuccessful },
  } = useForm<FormValues>({
    defaultValues: { contactIsContractor: defaultContactIsContractor },
  });
  const contactIsContractor = watch('contactIsContractor');

  const onSubmit = async (data: FormValues) => {
    setLoading(true);
    const formatedBirthday = setBirthdayDateToZeroHours(
      data?.contractor?.birthday
    );
    data.contractor.sendAppInvitation = sendAppInvitation;
    data.contractor.birthday = formatedBirthday;
    if (!errors?.contractor?.phone?.message) {
      const submitData = {
        ...data,
        //email: emailForCheck as string,
        updateExistingUser: userExist,
      };
      onSubmitParent && (await onSubmitParent(submitData));
    }
    setLoading(false);
  };
  const contact = watch('contact');
  const establishment = watch('establishment');
  const socialStatus = watch('contractor.socialStatus');
  const { data: providerContact } = useUserFindOne(contact);
  const { data: estaProvider } = useEstablishmentFindOne(establishment);
  const setFormValues = (user: IJoinedFlatUser) => {
    setValue(
      'contractor.civility',
      user?.civility ? user.civility?.toString() : '1'
    );
    setValue('contractor.firstName', user.firstName);
    setValue('contractor.lastName', user.lastName);
    setValue('phone', user.phone);
    setValue('jobTitle', user.jobTitle);
  };
  const copyContactInfo = async (checked: boolean) => {
    if (checked) {
      const { contact } = getValues();
      if (!contact) return;
      const contactObject = await userFindOne(contact);
      // Set form values, the long way
      setValue(
        'contractor.civility',
        contactObject?.civility ? contactObject.civility.toString() : '1'
      );
      setValue('contractor.firstName', contactObject.firstName);
      setValue('contractor.lastName', contactObject.lastName);
      setValue('phone', contactObject.phone);
      setValue('jobTitle', contactObject.jobTitle);
    } else {
      if (userExist) {
        setFormValues(user as IJoinedFlatUser);
      } else {
        setValue('contractor.civility', '1');
        setValue('contractor.firstName', undefined);
        setValue('contractor.lastName', undefined);
        setValue('phone', undefined);
        setValue('jobTitle', undefined);
        setValue('birthday', undefined);
      }
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onCheckEmail = async ({ email }: { email?: string }) => {
    const values = await userFindMany({
      filterObject: {
        email: {
          $regex: `^${escapeEmail(email && email.trim())}$`,
          $options: 'i',
        },
      },
    });
    setEmail(email as string);
    setEmailChecked(true);
    if (values?.users?.[0]) {
      setUserExist(true);
      const isContractor = checkUserHasRole(values.users[0], 'CONTRACTOR');
      setErrorExist(isContractor);
      setUserCheckedContractor(isContractor);
      let contractor;
      if (isContractor) {
        try {
          contractor = await contractorFindOneByCognitoUserId(
            values.users[0].cognitoUserId as string,
            true
          );
        } catch (e) {
          //console.log('e contractor error', e);
        }
      }
      if (isContractor && !contractor) {
        console.warn('user have role contractor but contractor does not exist');
      }
      const user = contractor
        ? { ...contractor, ...values.users[0] }
        : values?.users?.[0];
      setFormValues(user as IJoinedFlatUser);
      setUser(user as IJoinedFlatUser);
    } else {
      setValue('contractor.civility', undefined);
      setUser({ email });
    }
  };
  // const handleReset = () => {
  //   setUserCheckedContractor(false);
  //   setUserExist(false);
  //   setUser(undefined);
  //   setEmail(false);
  //   setEmailChecked(false);
  //   setErrorExist(false);
  // };
  const isDisabled = () => {
    if (!contactIsContractor && !contact) {
      return true;
    }
    if (!socialStatus) {
      return true;
    }
    if (!noEstablishment && !establishment) {
      return true;
    }
    return false;
  };
  React.useEffect(() => {
    if (contactIsContractor) {
      if (contact) {
        copyContactInfo(true);
      } else {
        copyContactInfo(false);
      }
    } else {
      //copyContactInfo(false);
    }
  }, [contact, contactIsContractor]);
  React.useEffect(() => {
    let isLinked = false;
    if (contact && establishment) {
      estaProvider?.provider?.contacts.forEach(element => {
        if (element.cognitoUserId === contact) {
          isLinked = true;
        }
      });
    }
    setIsLinked(isLinked);
  }, [contact, establishment, providerContact, estaProvider]);
  return (
    <Box>
      <ConfirmNavigationModal active={isDirty && !isSubmitSuccessful} />
      <Box hidden={noEstablishment} mb={10}>
        <FormLabel>
          ÉTABLISSEMENT FOURNISSEUR{' '}
          <span style={{ color: theme.colors.blue }}>*</span>
        </FormLabel>
        <EstablishmentSelectControlled
          id="contractor-establishment-select"
          isDisabled={userCheckedContractor}
          control={control}
          name="establishment"
          filter={{ 'provider.manager': { $exists: true } }}
          width="100%"
          referenceValue="uuid"
          placeholder="Rechercher un N° SIRET, raison sociale ..."
        />
        <Link
          iconLeft={<AddIcon />}
          mt={10}
          onClick={() =>
            showAddEstablishmentModal({
              establishmentRole: 'PROVIDER',
            }).then(e => {
              e.uuid && setValue('establishment', e.uuid);
            })
          }
        >
          Ajouter un établissement fournisseur
        </Link>
      </Box>
      <Box>
        {/* <VerifyEmailForm
          disableConfirm
          contactRole={'CONTRACTOR'}
          onSubmit={onCheckEmail}
          isLocked={!!user}
          canModify
          onModify={handleReset}
        /> */}
        {userExist && (
          <Box mt={20}>
            <ContactExistsMessage
              contactRole={ERoles.CONTRACTOR}
              contact={user as IUserLocal}
            />
          </Box>
        )}
      </Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        {emailChecked && (
          <Box>
            <Text variant="h2" mb={20} mt={20}>
              Informations générales de l’intervenant
            </Text>
            <CheckSwitch
              isDisabled={userCheckedContractor || defaultContactIsContractor}
              id="checkOne"
              mb={10}
              {...register('contactIsContractor')}
            >
              <Text>L’intervenant est également contact fournisseur</Text>
            </CheckSwitch>
            {contactIsContractor && (
              <Box mt={20} mb={20}>
                <BlocInformation>
                  <p>
                    Un nouveau contact fournisseur sera créé à partir des
                    informations fournies dans ce formulaire.
                  </p>
                </BlocInformation>
              </Box>
            )}
            <Box mb={20}>
              <FormControl
                label="Civilité"
                required
                errorMessage={errors?.contractor?.civility?.message}
              >
                <Flex>
                  <Radio
                    data-cy="contractorCivility-1"
                    isDisabled={userCheckedContractor}
                    {...register('contractor.civility', {
                      required: 'Ce champ est requis',
                    })}
                    value="1"
                  >
                    Monsieur
                  </Radio>
                  <Radio
                    data-cy="contractorCivility-2"
                    isDisabled={userCheckedContractor}
                    {...register('contractor.civility', {
                      required: 'Ce champ est requis',
                    })}
                    value="2"
                  >
                    Madame
                  </Radio>
                </Flex>
              </FormControl>
            </Box>
            <Row spacing={20}>
              <FormControl
                label="Prénom"
                required
                errorMessage={errors?.contractor?.firstName?.message}
              >
                <Input
                  data-cy="contractorFirstName"
                  isDisabled={userCheckedContractor}
                  isFullWidth
                  {...register('contractor.firstName', {
                    required: 'Ce champ est requis',
                  })}
                />
              </FormControl>

              <FormControl
                label="Nom"
                required
                errorMessage={errors?.contractor?.lastName?.message}
              >
                <Input
                  data-cy="contractoLastName"
                  isDisabled={userCheckedContractor}
                  isFullWidth
                  {...register('contractor.lastName', {
                    required: 'Ce champ est requis',
                  })}
                />
              </FormControl>
            </Row>
            <Row spacing={20}>
              <FormControl
                label="Date de naissance"
                required
                errorMessage={errors?.contractor?.birthday?.message}
              >
                <DatePickerControlled
                  disabled={userCheckedContractor}
                  control={control}
                  rules={{
                    required: 'Ce champs est requis',
                    validate: (value: any) => {
                      let age = differenceInYears(new Date(), value);
                      if (age < 18) {
                        return "L'intervenant doit etre majeur";
                      }
                      if (age >= 99) {
                        return 'Ne peut dépasser 99';
                      }
                      return undefined;
                    },
                  }}
                  name="contractor.birthday"
                />
              </FormControl>
              <FormControl
                label="Téléphone"
                errorMessage={errors?.phone?.message}
              >
                <PhoneInputControlled
                  disabled={userCheckedContractor}
                  control={control}
                  rules={{
                    validate: (value: string) => {
                      return validateFormPhoneFormatFR(value);
                    },
                  }}
                  isFullWidth
                  name="phone"
                />
              </FormControl>
              {/* <FormControl label="Fonction">
                <Input
                  isDisabled={userCheckedContractor}
                  isFullWidth
                  {...register('jobTitle')}
                />
              </FormControl> */}
            </Row>
            <Row spacing={20}>
              <Box>
                {contactIsContractor ? (
                  <FormControl
                    required
                    label="Email"
                    errorMessage={errors?.email?.message}
                  >
                    <Input
                      isFullWidth
                      {...register('email', {
                        required: 'Ce champ est requis',
                      })}
                      name="email"
                    />
                  </FormControl>
                ) : (
                  <></>
                )}
              </Box>
              <></>
            </Row>
            {/* TODO WAIT APP TALENT READY !! */}
            {/* <Box mb={20}>
              <CheckBox
                id={'contractor.sendAppInvitation'}
                onChange={() => setSendAppInvitation(!sendAppInvitation)}
                checked={sendAppInvitation as boolean}
              >
                <Box>
                  <Text mr={20}>
                    Créer un compte sur l’application mobile talents pour cet
                    intervenant.
                  </Text>
                </Box>
              </CheckBox>
              <></>
            </Box>
            {sendAppInvitation && !contactIsContractor && (
              <Row spacing={20}>
                <FormControl
                  label="Email compte talent"
                  //@ts-ignore
                  errorMessage={errors?.email?.message}
                >
                  <Input
                    isFullWidth
                    {...register('email', { required: 'Ce champ est requis' })}
                  />
                </FormControl>
                <></>
              </Row>
            )} */}
            <Text mb={20} variant="h3">
              Informations spécifiques à l’établissement
            </Text>
            {!userCheckedContractor && (
              <Box>
                <Box>
                  <Row spacing={20}>
                    <FormControl label="Fonction">
                      <Input
                        data-cy="contractorJobTitle"
                        isDisabled={userCheckedContractor}
                        isFullWidth
                        {...register('contractor.jobTitle')}
                      />
                    </FormControl>
                    <FormControl
                      label="Téléphone lié à l'établissement"
                      errorMessage={errors?.contractor?.phone?.message}
                    >
                      <PhoneInputControlled
                        disabled={userCheckedContractor}
                        control={control}
                        rules={{
                          validate: (value: string) => {
                            return validateFormPhoneFormatFR(value);
                          },
                        }}
                        isFullWidth
                        name="contractor.phone"
                      />
                    </FormControl>
                  </Row>
                  <Row spacing={20}>
                    <FormControl
                      label="Email secondaire"
                      errorMessage={errors?.contractor?.email?.message}
                    >
                      <Input
                        data-cy="contractorEmail"
                        isDisabled={userCheckedContractor}
                        isFullWidth
                        {...register('contractor.email', {
                          validate: value => checkFormEmail(value as string),
                        })}
                        name="contractor.email"
                      />
                    </FormControl>
                    {!userCheckedContractor && (
                      <Box mt={20}>
                        <BlocInformation>
                          <p>
                            Si besoin, précisez un second mail de l’intervenant
                            pour l'établissement fournisseur renseigné.
                          </p>
                        </BlocInformation>
                      </Box>
                    )}
                  </Row>

                  {!contactIsContractor && (
                    <Box mb={30}>
                      <FormLabel>
                        CONTACT FOURNISSEUR{' '}
                        <span style={{ color: theme.colors.blue }}>*</span>
                      </FormLabel>
                      <UserSelectControlled
                        id="provider-contact-select"
                        isDisabled={userCheckedContractor}
                        control={control}
                        name="contact"
                        role="PROVIDER_CONTACT"
                        width="100%"
                        placeholder="Rechercher un nom, prénom, email …"
                      />
                      <Link
                        iconLeft={<AddIcon />}
                        mt={10}
                        onClick={() =>
                          showAddContactModal({
                            contactRole: 'PROVIDER_CONTACT',
                          }).then(c => {
                            c.cognitoUserId &&
                              setValue('contact', c.cognitoUserId);
                          })
                        }
                      >
                        Ajouter un contact fournisseur
                      </Link>
                      {contact && establishment && isLinked === false && (
                        <Box mt={20}>
                          <BlocInformation>
                            <p>
                              L’établissement et le contact fournisseur que vous
                              avez sélectionné ne sont pas rattachés. En
                              ajoutant l’intervenant, vous validerez également
                              leur rattachement.
                            </p>
                          </BlocInformation>
                        </Box>
                      )}
                    </Box>
                  )}
                  <Box>
                    <FormControl
                      label="Statut social de l’intervenant au sein de l’établissement fournisseur"
                      required
                    >
                      <StaticSelectControlled
                        id="contractor-socialStatus-select"
                        isDisabled={userCheckedContractor}
                        control={control}
                        name="contractor.socialStatus"
                        required
                        options={CONTRACTOR_SOCIAL_STATUS_SELECT}
                        placeholder="Rechercher un statut"
                      />
                    </FormControl>
                  </Box>
                </Box>
              </Box>
            )}
            <Button
              data-cy="submit-create-contractor"
              key="submit"
              type="submit"
              isLoading={loading}
              isDisabled={isDisabled() || userCheckedContractor}
            >
              Enregistrer
            </Button>
          </Box>
        )}
      </form>
    </Box>
  );
};
