import { formatDate, userReSendInvitation } from '@commons';
import { useUserFindMany } from '@commons';
import { ALink } from 'components/ALink';
import { Header } from 'components/Header';
import { showAddContactModal } from 'components/modals/AddContactModal';
import {
  Box,
  Button,
  Container,
  Flex,
  Input,
  Link,
  Spinner,
  SpinnerBox,
  Table,
  TableColumn,
  TableItem,
  Text,
  Tab,
  Menu,
  MenuItem,
} from 'components/ui';
import { EyeIcon, DotsIcon } from 'components/ui/icons';
import { useDebounce } from 'hooks/useDebounce';
import { useShowMessage } from 'hooks/useShowMessage';
import React, { useState } from 'react';
import { useSetCrumbs } from 'hooks/breadCrumb';
import { useParams } from 'react-router-dom';
import { ERoles } from '@commons';
import { Theme } from 'styles';

const tabItems = (
  <>
    <Tab variant="secondary" href={'/providers/contacts/sent-invitations'}>
      Invitations envoyées
    </Tab>
    <Tab variant="secondary" href={'/providers/contacts/on-boarding'}>
      En cours d’embarquement
    </Tab>
    {/* <Tab variant="secondary" href={'/providers/contacts/awaiting'}>
      À valider
    </Tab> */}
    <Tab variant="secondary" href={'/providers/contacts/active'}>
      Actifs
    </Tab>
  </>
);

const Actions = ({ item }: { item: TableItem }) => {
  const showMessage = useShowMessage();
  const [loading, setLoading] = useState(false);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onResendInvitationClick = async (
    email: string,
    cognitoUserId: string
  ) => {
    setLoading(true);
    if (email && cognitoUserId) {
      try {
        await userReSendInvitation(email, cognitoUserId);
        showMessage('success', 'Invitation envoyée');
      } catch (e) {
        showMessage(
          'error',
          "Une erreur est survenue lors de l'envoie de l'invitation"
        );
      }
    }
    setLoading(false);
  };

  if (loading) return <Spinner size={20} />;
  let targetPath = '';
  if (item?.onboardingStep?.step === 'STEP_ONE') {
    targetPath = 'information';
  }
  if (item?.onboardingStep?.step === 'STEP_TWO') {
    targetPath = 'establishments';
  }
  if (item?.onboardingStep?.step === 'STEP_THREE') {
    targetPath = 'contractors';
  }
  return (
    <Flex>
      {item.isConfirmed && (
        <Menu
          align="right"
          ml={10}
          menuItems={
            <>
              <MenuItem>
                <Link
                  iconLeft={<EyeIcon fill={Theme?.colors?.primary?.default} />}
                >
                  <ALink
                    href={`/providers/contacts/on-boarding-card/${item.cognitoUserId}/${targetPath}`}
                  >
                    Voir la fiche d'embarquement
                  </ALink>
                </Link>
              </MenuItem>
              <MenuItem>
                <Link
                  iconLeft={<EyeIcon fill={Theme?.colors?.primary?.default} />}
                >
                  <ALink
                    href={`/providers/contacts/${item.cognitoUserId}/information`}
                  >
                    Voir la fiche contact
                  </ALink>
                </Link>
              </MenuItem>
            </>
          }
        >
          <DotsIcon fill={Theme?.colors?.primary?.default} fontSize={20} />
        </Menu>
      )}
    </Flex>
  );
};

export const OnBoardingList = () => {
  const { filter } = useParams<{ filter: string }>();
  useSetCrumbs(
    [filter],
    [
      {
        label: 'fournisseurs',
        path: '/providers',
      },
      {
        label: 'contacts',
        path: '/providers/contacts',
      },
      {
        label: 'En cours d’embarquement',
        path: '/providers/contacts/on-boarding',
      },
    ]
  );

  const [order, setOrder] = React.useState<'desc' | 'asc' | undefined>('desc');
  const [sortedBy, setSortedBy] = React.useState('updatedAt');
  const [page, setPage] = React.useState(0);
  const [filterQuery, setFilterQuery] = React.useState('');
  const debouncedFilterQuery = useDebounce(filterQuery, 500);
  const sortString = (order === 'desc' ? '-' : '') + sortedBy;
  const displayStep = (dataStep: 'STEP_ONE' | 'STEP_TWO' | 'STEP_THREE') => {
    switch (dataStep) {
      case 'STEP_ONE': {
        return 'Étape 1';
      }
      case 'STEP_TWO': {
        return 'Étape 2';
      }
      case 'STEP_THREE': {
        return 'Étape 3';
      }
    }
  };
  const columns: TableColumn[] = [
    {
      key: 'updatedAt',
      text: 'DATE',
      render: (item: TableItem) => formatDate(item.updatedAt),
      hidden: filter === 'active',
    },
    { key: 'email', text: 'EMAIL' },
    {
      key: 'step',
      text: 'Etape',
      render: (item: TableItem) => displayStep(item.onboardingStep.step),
    },
    {
      key: 'accountManager',
      text: 'SUIVI PAR',
      render: (item: TableItem) =>
        item.accountManager &&
        item.accountManager.firstName &&
        item.accountManager.lastName
          ? item.accountManager.firstName + ' ' + item.accountManager.lastName
          : item.accountManager
            ? item.accountManager.email
            : 'N/A',
      hidden: filter === 'active',
    },
    {
      key: 'actions',
      text: 'ACTIONS',
      align: 'flex-end',
      render: item => <Actions item={item} />,
    },
  ];
  const {
    data: contactsQuery,
    status,
    isFetching,
    isPending,
  } = useUserFindMany(
    {
      filterObject: {
        isConfirmed: true,
        'roles.name': {
          $in: [ERoles.PROVIDER_CONTACT, ERoles.PROVIDER_RESPONSIBLE],
        },
        $and: [
          {
            $or: [
              { firstName: { $regex: debouncedFilterQuery, $options: 'i' } },
              { lastName: { $regex: debouncedFilterQuery, $options: 'i' } },
              {
                email: {
                  $regex: debouncedFilterQuery?.replace('+', '\\+').trim(),
                  $options: 'i',
                },
              },
            ],
          },
          {
            $or: [
              {
                'onboardingStep.ableToAccessSite': false,
                'onboardingStep.step': {
                  $in: ['STEP_ONE', 'STEP_TWO'],
                },
              },
              {
                $and: [
                  { 'onboardingStep.step': 'STEP_THREE' },
                  { 'onboardingStep.done': false },
                ],
              },
            ],
          },
        ],
      },
      sort: sortString,
      limit: 20,
      skip: page * 20,
    }
    // { populateProviderEstablishments: filter === 'active' }
  );

  const items =
    contactsQuery?.users.map((u: any) => ({
      key: u.cognitoUserId || '',
      ...u,
    })) || [];

  const totalCount = contactsQuery?.totalCount;
  const loading = isPending;

  const onAddContact = () =>
    showAddContactModal({ contactRole: 'PROVIDER_CONTACT' });
  React.useEffect(() => {
    setPage(0);
  }, [filter]);
  return (
    <Box>
      <Header
        tabs={tabItems}
        actions={<Button onClick={onAddContact}>Ajouter un contact</Button>}
      >
        <Text variant="h1">Contacts Fournisseur</Text>
      </Header>
      <Container>
        <Flex mt="10px" mb="10px" width={1 / 3}>
          <Input
            isFullWidth
            type="search"
            value={filterQuery}
            placeholder="Rechercher ..."
            onChange={(e: any) => setFilterQuery(e.target.value)}
          />
        </Flex>
        {loading && <SpinnerBox />}
        {!loading && (
          <Table
            isLoading={isFetching}
            columns={columns}
            items={items}
            sortedBy={sortedBy}
            order={order}
            page={page}
            total={totalCount}
            perPage={totalCount && totalCount < 20 ? totalCount : 20}
            onSortedChange={(shortBy: string, order: 'asc' | 'desc') => {
              setSortedBy(shortBy);
              setOrder(order);
            }}
            onChangePage={newPage => setPage(newPage)}
          />
        )}
      </Container>
    </Box>
  );
};
