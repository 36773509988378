import { Header } from 'components/Header';
import { Box, Container, Text, Tab } from 'components/ui';
import { ProviderGuideFileForm } from 'forms/support/documents';
import { useSetCrumbs } from 'hooks/breadCrumb';
import { useParams } from 'react-router-dom';

export const DocumentsSupport = () => {
  const { filter: filterParams } = useParams<{ filter: string }>();
  useSetCrumbs(
    [filterParams],
    [
      {
        label: `Support - Documents ${
          filterParams === 'provider' ? 'fournisseur' : 'manager'
        }`,
        path: `/support/documents/${filterParams}`,
      },
    ]
  );
  return (
    <>
      <Header
        tabs={
          <>
            <Tab variant="secondary" href={'/support/documents/provider'}>
              Espace fournisseur
            </Tab>
            {/* <Tab variant="secondary" href={'/support/documents/manager'}>
              Espace manager
            </Tab> */}
          </>
        }
      >
        <Text variant="h1">Gestion des documents</Text>
      </Header>
      <Container>
        {filterParams === 'provider' && (
          <Box p={20}>
            <ProviderGuideFileForm />
          </Box>
        )}
      </Container>
    </>
  );
};
