import {
  EInvoiceType,
  IJoinedInvoice,
  EInvoicePaymentLabel,
  IInvoice,
  ICra,
  IMission,
  IContractor,
  IJoinedMission,
  EMissionType,
  EMissionCraValidatedBy,
} from '@freelancelabs/teoreme-commons';
import { daysDiffBetweenDates } from './date';
import {
  CUSTOMER_INVOICE_TYPES,
  PROVIDER_INVOICE_TYPES,
} from '../constantz/mapping';
import { lastDayOfMonth } from 'date-fns';
import {
  isCreditNoteInvoice,
  isExpenseInvoice,
  isMileStoneInvoice,
  isStandByDutyInvoice,
  isTimeSpentInvoice,
  isNotLocal,
} from './';
import { REACT_APP_SEED_STAGE_NAME } from '../constantz';

/**
 * Calculate the deadline date of the specified invoice, using as reference the submittedAt date and the paymentDeadline
 * field. It only add the exact number of days to the date.
 * If the invoice submittedAt is available, use that date. Otherwise thate the date the cra was VALIDATED
 * @param cra
 * @param invoice
 */
// TODO: CHECK TSCONFIG STRICT MODE
export const calculateDeadlineDate = (
  cra: ICra,
  invoice: IInvoice | IJoinedInvoice
): Date => {
  // ONLY FOR PROVIDER INVOICE
  let paymentDeadlineDate: Date = new Date();
  if (invoice.invoiceType === EInvoiceType.PROVIDER) {
    if (cra.craValidatedBy === EMissionCraValidatedBy.CUSTOMER_CONTACT) {
      const craValidationDate = cra?.validatedAt
        ? cra.validatedAt
        : cra.stateChangedAt;
      paymentDeadlineDate = new Date(craValidationDate as Date);
    } else if (cra.craValidatedBy === EMissionCraValidatedBy.ACCOUNT_MANAGER) {
      const craSubmittedAtDate = cra.submittedAt
        ? cra.submittedAt
        : cra.stateChangedAt;
      paymentDeadlineDate = new Date(craSubmittedAtDate as Date);
    }
    // calculate paimentDeadline
    paymentDeadlineDate.setDate(
      //@ts-ignore
      paymentDeadlineDate.getDate() + invoice?.paymentDeadline
    );
    //@ts-ignore
    const invoiceSubmittedDate = new Date(invoice.submittedAt);
    // check day difference between le paimentDeadline witch invoice sumittedAt date
    const diffDays = daysDiffBetweenDates(
      paymentDeadlineDate,
      invoiceSubmittedDate
    );
    // case invoice submittedAt > paymentDeadlineDate
    // invoiceSubmittedAt -> 12 mars
    // paymentDeadlineDate (date soumission Cra + fastCash) -> 13 mars
    // diffDays = 1
    if (diffDays <= 7) {
      paymentDeadlineDate = new Date(
        invoiceSubmittedDate.setDate(invoiceSubmittedDate.getDate() + 10)
      );
    }
  }
  return paymentDeadlineDate as Date;
};

/**
 * Resolve the customer due date depending on the initial invoiceDate and the paymentDeadline on the invoice.
 * The calculation of the date also depends on the paymentLabel selected.
 * @param invoice
 * @return The calculated due date
 */
export const calculateCustomerDueDate = (
  invoice: IInvoice | IJoinedInvoice
): Date => {
  const refDate: Date = invoice.invoiceDate || new Date();
  let dueDate = new Date(refDate);
  if (invoice?.paymentDeadline) {
    if (
      invoice?.invoiceIssue?.paymentLabel === EInvoicePaymentLabel.END_OF_MONTH
    ) {
      // If we are in "end of month" mode, we need to sent the date to the last day of the latest month
      dueDate.setDate(dueDate.getDate() + invoice?.paymentDeadline);
      dueDate = lastDayOfMonth(dueDate);
    } else {
      dueDate.setDate(dueDate.getDate() + invoice?.paymentDeadline);
    }
  }

  return dueDate;
};

export const calculateAdditionalActivityDeadlineDate = (
  invoice: IInvoice | IJoinedInvoice
): Date => {
  // PROVIDER AdditionalActivity INVOICE
  let paymentDeadlineDate = new Date(invoice?.createdAt as Date);
  paymentDeadlineDate.setDate(
    // @ts-ignore
    paymentDeadlineDate.getDate() + invoice?.paymentDeadline
  );
  const invoiceSubmittedDate = invoice?.submittedAt
    ? new Date(invoice.submittedAt)
    : new Date(invoice.createdAt as Date);
  // check day difference between le paimentDeadline witch invoice sumittedAt date
  const diffDays = daysDiffBetweenDates(
    paymentDeadlineDate,
    invoiceSubmittedDate
  );
  // case invoice submittedAt > paymentDeadlineDate
  // invoiceSubmittedAt -> 12 mars
  // paymentDeadlineDate (date soumission Cra + fastCash) -> 13 mars
  // diffDays = 1
  if (diffDays <= 7) {
    paymentDeadlineDate = new Date(
      invoiceSubmittedDate.setDate(invoiceSubmittedDate.getDate() + 10)
    );
  }

  return paymentDeadlineDate;
};
export const calculateCreditNoteDueDate = (invoice: IJoinedInvoice) => {
  /**
   * Avoir Client :
   * Si Date du jour <= Date d'échéance de la facture  initiale :
   * Date d'échéance de l’avoir = Date d'échéance de la facture initiale
   * Sinon Date d'échéance de la facture d’avoir client = Date du jour
   * Avoir fournisseur:
   * Si Date du jour <= Date d'échéance de la facture fournisseur initiale :
   * Date d'échéance de l’avoir = Date d'échéance de la facture fournisseur initiale
   * Sinon Date d'échéance de la facture d’avoir fournisseur = Date du jour
   */
  if (invoice?.dueDate) {
    return invoice?.dueDate;
  }
  const originalInvoice = invoice?.originalInvoice;
  const originalInvoiceDueDate = CUSTOMER_INVOICE_TYPES.includes(
    invoice.invoiceType
  )
    ? calculateCustomerDueDate(originalInvoice as IInvoice)
    : calculateDeadlineDate(invoice?.cra, originalInvoice as IInvoice);
  if (new Date() <= originalInvoiceDueDate) {
    return calculateCustomerDueDate(invoice);
  } else {
    return new Date();
  }
};

export const calculateInvoiceDeadlineDate = (
  invoice: IInvoice | IJoinedInvoice,
  cra: ICra | undefined
): Date => {
  if (isCreditNoteInvoice(invoice?.invoiceType)) {
    return calculateCreditNoteDueDate(invoice as IJoinedInvoice);
  }
  if (CUSTOMER_INVOICE_TYPES.includes(invoice.invoiceType)) {
    return calculateCustomerDueDate(invoice);
  }
  if (invoice.invoiceType === EInvoiceType.PROVIDER && cra) {
    return calculateDeadlineDate(cra, invoice);
  }
  // CASE : provider AdditionalActiviy
  return calculateAdditionalActivityDeadlineDate(invoice);
};

// retourner les invoices type correspondant à la prestation et au type de facture (facture/avoir) selectionné
export const getPrestationInvoiceType = (
  prestationType: string, // STANDARD, EXPENSE, STAND_BY_DUTY
  invoiceType: string, // FACTURE OU AVOIR
  type: 'CUSTOMER' | 'PROVIDER' = 'PROVIDER'
) => {
  let invoicesTypes =
    type === 'PROVIDER' ? PROVIDER_INVOICE_TYPES : CUSTOMER_INVOICE_TYPES;

  if (invoiceType !== 'ALL') {
    if (invoiceType === 'AVOIR')
      invoicesTypes = invoicesTypes.filter(e => isCreditNoteInvoice(e));
    if (invoiceType === 'FACTURE')
      invoicesTypes = invoicesTypes.filter(e => !isCreditNoteInvoice(e));
  }

  if (prestationType !== 'ALL') {
    if (prestationType === 'STANDARD')
      invoicesTypes = invoicesTypes.filter(e => isTimeSpentInvoice(e));
    if (prestationType === 'STAND_BY_DUTY')
      invoicesTypes = invoicesTypes.filter(e => isStandByDutyInvoice(e));
    if (prestationType === 'EXPENSE')
      invoicesTypes = invoicesTypes.filter(e => isExpenseInvoice(e));
    if (prestationType === 'MILESTONE')
      invoicesTypes = invoicesTypes.filter(e => isMileStoneInvoice(e));
  }

  return invoicesTypes;
};

export const getCustomerEstGescomCode = (invoice: IJoinedInvoice) => {
  const sageRefs = invoice?.estCustomer?.customer?.sage?.references || [];
  const missionStructure = invoice?.mission?.sage?.structure;
  const customerInvoiceStructure = sageRefs.find(
    (e: any) => e.structure === missionStructure
  );
  const code = customerInvoiceStructure?.customerId || 'N/A';

  return code;
};

export const getProviderEstGescomCode = (invoice: IJoinedInvoice) => {
  const sageRefs = invoice?.estProvider?.provider?.sage?.references || [];
  const missionStructure = invoice?.mission?.sage?.structure;
  const providerInvoiceStructure = sageRefs.find(
    (e: any) => e.structure === missionStructure
  );
  const code = providerInvoiceStructure?.customerId || 'N/A';

  return code;
};

/**
 * Resolve the contractorId that should be used based on the mission and the contractor specified.
 * The selected structure and missionType in the mission are going to be used to figure the contractorId to use
 * @param mission
 * @param contractor
 * @param invoiceType
 */
export const resolveContractorId = (
  invoice: IJoinedInvoice
): string | undefined => {
  let id: string | undefined;
  const mission: IMission | IJoinedMission = invoice.mission;
  const contractor: IContractor = invoice.contractor;
  const invoiceType: EInvoiceType = invoice.invoiceType;
  const struct = mission?.billingInformation?.structure;
  const contIds = contractor?.sage?.contractorIds;
  const isExp = isExpenseInvoice(invoiceType);
  switch (mission?.billingInformation?.missionType?.label) {
    case EMissionType.RESOURCE_MANAGEMENT:
      // eslint-disable-next-line no-case-declarations
      const targRMType: any = isExp ? 'FRAISRM' : 'RM';
      id = contIds?.find(
        cont => cont.structure === struct && cont.type === targRMType
      )?.contractorId;
      break;
    case EMissionType.CLASSIC:
      // eslint-disable-next-line no-case-declarations
      const targCLType: any = isExp ? 'FRAISCL' : 'CL';
      id = contIds?.find(
        cont => cont.structure === struct && cont.type === targCLType
      )?.contractorId;
      break;
    default:
      console.warn(
        `Unsupported mission type ${mission?.billingInformation?.missionType?.label} ${!isNotLocal(REACT_APP_SEED_STAGE_NAME) ? `mission : ${mission?.reference}` : ''}`
      );
      id = 'N/A';
  }

  return id;
};
