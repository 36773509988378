import {
  IMissionCustomer,
  IUpdateMissionData,
  IJoinedMission,
} from '@freelancelabs/teoreme-commons';
import { Text } from 'components/ui';
import { AddMissionCustomerOrProviderForm } from 'forms/AddMissionCustomerOrProviderForm';
import { useCreateOrUpdateMission, useGetMission } from 'hooks/missionHooks';
import { create } from 'react-modal-promise';
import { queryClient } from '@commons';
import { ModalFrame, ModalProps } from './ModalFrame';

type Props = ModalProps & {
  beforeValidation?: () => void;
  method: 'UPDATE' | 'CREATE';
  mission?: IJoinedMission;
  missionHasNoValidatedCraOrMilestone?: boolean;
};

export const AddMissionCustomerModal = ({
  onResolve,
  isOpen,
  method,
  mission,
  missionHasNoValidatedCraOrMilestone,
}: Props) => {
  const [createOrUpdate] = useCreateOrUpdateMission();

  const { reference } = useGetMission();

  const onAddMissionCustomer = async (
    customer: IMissionCustomer & { marketData?: any; marketId?: string }
  ) => {
    try {
      const mission: IUpdateMissionData = {
        customer: {
          ...(customer as IUpdateMissionData['customer']),
          //@ts-ignore
          marketData: undefined,
          marketId: undefined,
        },

        marketData: customer?.marketData,
        //marketId: customer?.marketId,
        incPurchaseOrderCounter: false,
        incQuoteCounter: false,
      };
      const newMission = await createOrUpdate(reference, mission);
      queryClient.refetchQueries({ queryKey: ['missions'], type: 'active' });
      if (newMission) onResolve(newMission);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <ModalFrame
      isOpen={isOpen}
      onClose={() => onResolve(false)}
      width={650}
      closeIcon
    >
      <Text variant="h1" mb={20}>
        {method === 'CREATE' ? 'Sélectionner un' : 'Modifier le'} client
      </Text>
      <AddMissionCustomerOrProviderForm
        onSubmit={onAddMissionCustomer}
        type="CUSTOMER"
        method={method}
        mission={mission}
        missionHasNoValidatedCraOrMilestone={
          missionHasNoValidatedCraOrMilestone
        }
      />
    </ModalFrame>
  );
};

export const showAddMissionCustomerModal = create<Props, boolean>(
  AddMissionCustomerModal
);
