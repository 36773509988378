import {
  registerInterceptor,
  isManagerMaintenance,
  REACT_APP_SEED_BUILD_ID,
  REACT_APP_SEED_STAGE_NAME,
  queryClient,
  REACT_APP_MAINTENANCE_MODE,
  initialize as initializeAuth,
} from '@commons';
import { AuthGuard } from 'components/AuthGuard';
import Translator from 'components/Translator';
import { CssReset } from 'components/ui';
import { StageRibbon } from 'components/StageRibbon';
import Notifications from 'components/ui/Notifications';
import { BreadCrumbProvider } from 'hooks/breadCrumb';
import { useShowMessage } from 'hooks/useShowMessage';
import { useStatics } from 'hooks/global';
import React, { useEffect } from 'react';
import ModalContainer from 'react-modal-promise';
import { QueryClientProvider } from '@tanstack/react-query';
import { BrowserRouter as ReactRouter } from 'react-router-dom';
import { Router } from 'routes';
import { ThemeProvider } from 'styled-components';
import { GlobalStyles, Theme } from 'styles';
import { CheckBuild } from 'components/CheckBuild';
import fr from '../locales/fr.json';

import { useLocale, addLocale } from 'ttag';

import Maintenance from 'Maintenance';

const RegisterAxiosInterceptor = () => {
  const showAlert = useShowMessage();
  useStatics();

  useEffect(() => {
    registerInterceptor(showAlert);
  }, [showAlert]);
  return null;
};
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const queryConfig = {
  queries: {
    retry: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    staleTime: 30000,
  },
  mutations: {
    throwOnError: true,
  },
};
console.log('VERSION : ', REACT_APP_SEED_BUILD_ID);
initializeAuth();
const App = () => {
  // @ts-ignore
  addLocale('fr', fr);
  useLocale('fr');
  const isMaintenance = isManagerMaintenance(REACT_APP_MAINTENANCE_MODE);
  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={Theme}>
        <CssReset />
        <GlobalStyles />
        <Translator>
          <RegisterAxiosInterceptor />
          <BreadCrumbProvider>
            {/* {process.env.NODE_ENV !== 'production' && (
              <ReactQueryDevtools initialIsOpen={false} />
            )} */}
            <ReactRouter>
              <Notifications />
              {REACT_APP_SEED_STAGE_NAME !== 'production' && <StageRibbon />}
              {isMaintenance ? (
                <Maintenance />
              ) : (
                <AuthGuard>
                  <CheckBuild>
                    <Router />
                  </CheckBuild>
                </AuthGuard>
              )}
              <ModalContainer />
            </ReactRouter>
          </BreadCrumbProvider>
        </Translator>
      </ThemeProvider>
    </QueryClientProvider>
  );
};
export default App;
