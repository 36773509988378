import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ContractListPage } from './List';

export const Contracts = () => {
  return (
    <Switch>
      <Route exact path="/providers/contracts">
        <Redirect to="/providers/contracts/active" />
      </Route>
      <Route path="/providers/contracts/:filter(active|archived|sent|countersign|refused|cancel)">
        <ContractListPage />
      </Route>
    </Switch>
  );
};
