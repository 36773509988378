import {
  ALink,
  Box,
  Text,
  Link,
  Menu,
  MenuItem,
  Itemize,
  TableColumn,
  Status,
  Row,
} from 'components/ui';
import {
  IAdditionalService,
  IEstablishmentMinifiedUsers,
  IEstablishmentFullUsers,
  ISigner,
} from '@freelancelabs/teoreme-commons';
import { getTradeNameSafe } from '@commons';
import { DeleteIcon, DotsIcon, EditIcon } from 'components/ui/icons';
import * as React from 'react';
import { showCancelRCPROModal } from 'components/modals/providers/CancelRCRPOModal';
import { showAdditionalServicesModal } from 'components/modals/AdditionalServicesModal';
import { showOtherSignersModal } from 'components/modals/providers/OtherSignersModal';

import { queryClient } from '@commons';
import { Theme } from 'styles';
export type Item = Itemize<any>;
export const columnsOtherSigners = (
  items: ISigner[],
  establishment: IEstablishmentMinifiedUsers | IEstablishmentFullUsers,
  removeOtherSigner: (signer: ISigner) => void
) => [
  {
    key: 'firstName',
    text: 'NOM',
    render: (item: Item) => {
      return item.firstName;
    },
  },
  {
    key: 'lastName',
    text: 'PRÉNOM',
    render: (item: Item) => {
      return item.lastName;
    },
  },
  {
    key: 'email',
    text: 'EMAIL',
    render: (item: Item) => {
      return item.email;
    },
  },
  {
    key: 'phone',
    text: 'N° TÉLÉPHONE',
    render: (item: Item) => {
      return item.phone ? `+${item.phone}` : 'N/A';
    },
  },
  {
    key: 'actions',
    text: 'ACTIONS',
    render: (item: Item) => {
      return (
        <Row spacing={10}>
          <Link
            onClick={() =>
              showOtherSignersModal({
                establishment: establishment,
                signer: item,
              })
            }
            iconLeft={<EditIcon />}
          >
            Modifier
          </Link>
          <Link
            iconLeft={<DeleteIcon />}
            onClick={() => removeOtherSigner(item)}
          >
            Supprimer
          </Link>
        </Row>
      );
    },
  },
];

// EXECUTIVE CONTRACT COLUMS
export const columns = (
  items: any,
  onUpdate: (service: any, refMission: string) => any,
  findEstablishmentRCPO: () => void
) =>
  [
    {
      key: 'displayRefMission',
      text: 'RÉF. MISSION',
      opacity: (item: Item) => (item.status === 'INACTIVE' ? 0.5 : 1),
      render: (item: Item) => {
        return (
          <ALink href={`/delivery/missions/${item?.refMission}`}>
            <Link fontSize={14}>{item.displayRefMission}</Link>
          </ALink>
        );
      },
    },
    {
      key: 'status',
      text: 'STATUT',
      opacity: (item: Item) => (item.status === 'INACTIVE' ? 0.5 : 1),
      render: item => {
        const obj: { color: 'grey' | 'success' | 'warning'; text: string } = {
          color: 'grey',
          text: '',
        };
        switch (item.status) {
          case 'INACTIVE':
            obj.color = 'grey';
            obj.text = 'Résilié';
            break;
          case 'ACTIVE':
            obj.color = 'success';
            obj.text = 'Actif';
            break;
          case 'PENDING':
            obj.color = 'warning';
            obj.text = 'En cours de souscription';
            break;
        }
        return (
          <Box>
            <Status variantColor={obj.color}>{obj.text}</Status>
          </Box>
        );
      },
    },
    {
      key: 'validity',
      text: 'PÉRIODE DE VALIDITÉ',
      opacity: (item: Item) => (item.status === 'INACTIVE' ? 0.5 : 1),
      render: item => {
        const validityStart = new Date(item?.validityStart);
        const validityEnd = item?.validityEnd
          ? new Date(item.validityEnd as Date)
          : false;
        return (
          <Text fontSize={12}>{`${
            validityStart.getUTCMonth() + 1
          }/${validityStart.getUTCFullYear()} >  ${
            validityEnd ? validityEnd.getUTCMonth() + 1 : ''
          }${validityEnd ? '/' : ''}${
            validityEnd ? validityEnd.getUTCFullYear() : 'N/A'
          }`}</Text>
        );
      },
    },
    {
      key: 'establishment',
      text: 'ÉTAB. FOURNISSEUR',
      opacity: (item: Item) => (item.status === 'INACTIVE' ? 0.5 : 1),
      render: item => {
        return <>{getTradeNameSafe(item?.establishment)}</>;
      },
    },
    {
      key: 'actions',
      text: 'ACTIONS',
      align: 'flex-end',
      opacity: (item: Item) => (item.status === 'INACTIVE' ? 0.5 : 1),
      render: (item: Item) => {
        const notEditable = item.status === 'INACTIVE';
        if (notEditable) {
          return (
            <Box>
              <DotsIcon fill="grey" fontSize={20} />
            </Box>
          );
        } else {
          return (
            <Box>
              <Menu
                align="right"
                menuItems={
                  <>
                    <MenuItem>
                      <Link
                        onClick={() =>
                          showAdditionalServicesModal({
                            service: item as IAdditionalService,
                            forRole: 'ESTABLISHMENT_PROVIDER',
                            additionalServices: [],
                            //@ts-ignore
                            mission: {
                              reference: item?.refMission,
                              additionalServices: [],
                            },
                          }).then(
                            service =>
                              service && onUpdate(service, item.refMission)
                          )
                        }
                        iconLeft={<EditIcon />}
                      >
                        Modifier la RC Pro
                      </Link>
                    </MenuItem>
                    <MenuItem>
                      <Link
                        onClick={async () => {
                          await showCancelRCPROModal({
                            service: item,
                            mission: { reference: item.refMission },
                          });
                          queryClient.refetchQueries(item?.establishment?.uuid);
                          findEstablishmentRCPO();
                        }}
                        iconLeft={<DeleteIcon />}
                      >
                        Supprimer la RC Pro
                      </Link>
                    </MenuItem>
                  </>
                }
              >
                <DotsIcon
                  fill={Theme?.colors?.primary?.default}
                  fontSize={20}
                />
              </Menu>
            </Box>
          );
        }
      },
      //   render: item => {
      //     return <></>;
      //   },
    },
  ] as TableColumn<Item>[];
