import {
  formatDate,
  FR_STATUS,
  getEstablishmentName,
  getFullName,
  STATUS_COLOR,
  ADDITIONAL_ACTIVITY_TYPE_SELECT,
  useAdditionalActivityFindMany,
  useMe,
  getAdditionalActivitiesTotalAmount,
  getStandByDutiesTotalAmount,
  formatInvoiceOrAdditionalActivitySort,
} from '@commons';
import {
  EadditionalActivityStatus,
  IJoinedAdditionalActivity,
  IJoinedAdditionalActivityWithInvoice,
  EadditionalActivityType,
  IStandByDutyLine,
  IJoinedMission,
  EBillingType,
  ETaskStatus,
} from '@freelancelabs/teoreme-commons';
import { ALink } from 'components/ALink';
import { showAdditionalActivityCreateGescomBDLModal } from 'components/modals/gescom/CreateGescomAdditionalActivityBDLModal';
import { showRejectAdditionalActivityModal } from 'components/modals/RejectAdditionalActivityModal';
import { StaticSelect } from 'components/selects/StaticSelect';
import { showGenerateInvoiceModal } from 'components/modals/invoices/GenerateInvoiceModal';
import { showPatchAdditionalActivityModal } from 'components/modals/activities/PatchAdditionalActivityModal';

import {
  Box,
  Container,
  CustomToolTip,
  DatePickerRange,
  Flex,
  FormLabel,
  Input,
  Itemize,
  Link,
  Menu,
  MenuItem,
  SpinnerBox,
  Status,
  Tab,
  Table,
  TableColumn,
  Text,
} from 'components/ui';
import {
  AddIcon,
  DotsIcon,
  EyeIcon,
  RefreshIcon,
  CancelIcon,
  EditIcon,
  SendIcon,
  InfoIcon,
} from 'components/ui/icons';
import { add, getTime } from 'date-fns';
import { useDebounce } from 'hooks/useDebounce';
import { useShowMessage } from 'hooks/useShowMessage';
import React from 'react';
import { useParams } from 'react-router-dom';
import { Theme } from 'styles';
import { useListAdditionalActivityStore } from 'store/mission';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { useTranslationFormat } from 'hooks/useTranslateFormat';
import {
  canDisplayCreateBdlButton,
  canDisplayGenerateCustomerActivityInvoiceButton,
  canDisplayShowCorrectRejectButton,
  checkDisplayAdditionalActivityMenuList,
} from 'helpers/business/additionalActivity';
import { taskTypeMapping } from 'helpers';

type Item = Itemize<IJoinedAdditionalActivity>;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Tabs = (
  <>
    <Tab
      variant="secondary"
      href={'/orders/additional-activities/to-be-validated'}
    >
      A valider
    </Tab>
    <Tab variant="secondary" href={'/orders/additional-activities/validated'}>
      Validés
    </Tab>
    <Tab variant="secondary" href={'/orders/additional-activities/rejected'}>
      Refusés
    </Tab>
  </>
);

const columns = (
  mission: IJoinedMission,
  RejectButton: any,
  showMessage: any,
  me: any,
  t: any
) =>
  [
    mission?.billing?.type === EBillingType?.DAY && {
      key: 'month',
      text: 'MOIS',
      sortKey: 'month',
      render: (item: IJoinedAdditionalActivity) => (
        <Box>
          <Text>
            {`${new Date(item?.month as Date)?.getUTCMonth() + 1}/${new Date(
              item?.month as Date
            )?.getUTCFullYear()}`}
          </Text>
        </Box>
      ),
    },
    {
      key: 'type',
      text: 'TYPE',
      render: (item: IJoinedAdditionalActivity) => {
        if (item?.type === EadditionalActivityType?.STAND_BY_DUTY) {
          return 'Prest. comp.';
        }
        if (item?.type === EadditionalActivityType?.EXPENSE) {
          return 'Frais';
        }
        if (item?.type === EadditionalActivityType?.MILESTONE) {
          return 'Jalon';
        }
      },
    },
    {
      key: 'submittedAt',
      text: 'RECU LE',
      sortKey: 'submittedAt',
      render: (item: IJoinedAdditionalActivity) => {
        const submitAt = item?.submittedAt || item?.statusChangedAt;
        const sumbitDate = submitAt ? new Date(submitAt) : undefined;
        return (
          <Box>
            <Text>{sumbitDate ? `${formatDate(sumbitDate)}` : 'N/A'}</Text>
          </Box>
        );
      },
    },
    {
      key: 'estCustomer',
      text: 'ÉTABLIS. CLIENT',
      sortKey: 'estCustomer.businessName',
      render: (item: IJoinedAdditionalActivity) =>
        getEstablishmentName(item.estCustomer),
    },
    {
      key: 'estProvider',
      text: 'ÉTABLIS. FOURN.',
      sortKey: 'estProvider.businessName',
      render: (item: IJoinedAdditionalActivity) =>
        getEstablishmentName(item.estProvider),
    },
    {
      key: 'contractor',
      text: 'INTERVENANT',
      sortKey: 'contractor.firstName',
      render: (item: IJoinedAdditionalActivity) => getFullName(item.contractor),
    },
    {
      key: 'status',
      text: 'STATUT',
      render: (item: IJoinedAdditionalActivity) => {
        return (
          <Status variantColor={STATUS_COLOR[item.status]}>
            {item.status !== EadditionalActivityStatus.TO_BE_FILLED
              ? FR_STATUS[item.status]
              : FR_STATUS[EadditionalActivityStatus.TO_BE_SUBMITTED]}{' '}
            {item.status === EadditionalActivityStatus.REJECTED &&
              ` le ${formatDate(item.rejectedAt || '')}`}
          </Status>
        );
      },
    },
    {
      key: 'totalAmount',
      text: 'MONTANT  HT',
      render: (item: IJoinedAdditionalActivity) => {
        if (item?.type === EadditionalActivityType?.STAND_BY_DUTY) {
          return `${getStandByDutiesTotalAmount(
            item?.standByDutyLines as IStandByDutyLine[],
            'PROVIDER'
          )} €`;
        }
        if (item?.type === EadditionalActivityType?.EXPENSE) {
          return `${getAdditionalActivitiesTotalAmount(
            [item],
            item?.type,
            'PROVIDER',
            false
          )} €`;
        }
      },
    },

    {
      key: 'invoice',
      text: 'BDL FOURNISSEUR',
      render: (item: IJoinedAdditionalActivityWithInvoice) => {
        let bdlCustomer = false;
        let bdlProvider = false;
        const customerReference =
          item?.mission?.customer?.purchaseOrder?.reference;

        if (item?.invoices?.customer?.sage?.documentType) {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          bdlCustomer = true;
        }
        if (item?.invoices?.provider?.sage?.documentType) {
          bdlProvider = true;
        }
        if (!customerReference) {
          return (
            <>
              <a data-for={item.uuid} data-tip="" data-iscapture="true">
                <ReactTooltip
                  id={item.uuid}
                  place={'top'}
                  variant={'dark'}
                  float={false}
                  style={{ backgroundColor: Theme.colors.error.default }}
                >
                  <Text color="white" fontSize={11}>
                    Le bon de commande client est requis pour créer les bons de
                    livraison dans gescom.
                  </Text>
                </ReactTooltip>
                <ALink
                  href={`/delivery/missions/${item?.mission?.reference}/client`}
                >
                  <Status variantColor="error">BDC Client manquant</Status>
                </ALink>
              </a>
            </>
          );
        }
        if (bdlProvider) {
          return <Status variantColor="success">Créé dans GESCOM</Status>;
        }
        if (!bdlProvider) {
          return <Status variantColor="error">BDL fournisseur manquant</Status>;
        }
      },
    },
    {
      key: 'task',
      text: 'TÂCHES',
      render: (item: IJoinedAdditionalActivityWithInvoice) => {
        const displayedStatus = [ETaskStatus.ERROR, ETaskStatus.ONGOING];
        const getDisplayTask = () =>
          item?.tasks?.filter(task =>
            displayedStatus?.includes(task?.lastTaskStatus)
          );
        const tasks = getDisplayTask();
        if (tasks && tasks?.length > 0) {
          return (
            <CustomToolTip
              color={Theme?.colors?.warning?.default}
              text={
                <Box>
                  <ul>
                    {tasks?.map(task => (
                      <li>
                        {taskTypeMapping?.[task.taskType]}:&nbsp;
                        {FR_STATUS?.[task?.lastTaskStatus]}
                      </li>
                    ))}
                  </ul>
                </Box>
              }
              id={item?.uuid + '_task'}
            >
              <InfoIcon
                //onClick={() => showCraTaskDetails({ cra: item })}
                fill={Theme?.colors?.warning?.default}
                style={{ cursor: 'pointer' }}
              />
            </CustomToolTip>
          );
        } else {
          return 'N/A';
        }
      },
    },
    {
      key: 'actions',
      text: 'ACTIONS',
      align: 'flex-end',
      // hidden: status === 'archived',
      render: (item: IJoinedAdditionalActivityWithInvoice) => {
        let bdlCustomer = false;
        let bdlProvider = false;

        const customerReference =
          item?.mission?.customer?.purchaseOrder?.reference;
        if (item?.invoices?.customer?.sage?.documentType) {
          bdlCustomer = true;
        }
        if (item?.invoices?.provider?.sage?.documentType) {
          bdlProvider = true;
        }
        /* CHECK PERMISSION FIX CRA */
        // const invoices = item?.invoices;
        // const isTeamLeader = checkUserHasRole(me, 'TEAM_LEADER');
        // const userHavePermissionToFix = true;
        const billingType = item?.mission?.billing?.type;
        return (
          <Flex alignItems="center">
            <Box width={1 / 2}>
              {billingType === EBillingType.DAY ? (
                <ALink href={`/orders/cra/${item?.cra?.refCra}/informations`}>
                  <Link iconLeft={<EyeIcon />}></Link>
                </ALink>
              ) : (
                <ALink
                  href={`/orders/activities/flat-rate/${item?.mission?.reference}`}
                >
                  <Link iconLeft={<EyeIcon />}></Link>
                </ALink>
              )}
            </Box>
            <Box
              width={1 / 2}
              marginLeft={
                !checkDisplayAdditionalActivityMenuList(item, bdlProvider)
                  ? 20
                  : ''
              }
            >
              {checkDisplayAdditionalActivityMenuList(item, bdlProvider) && (
                <Menu
                  align="right"
                  menuItems={
                    <>
                      {canDisplayCreateBdlButton(item, bdlProvider) && (
                        <MenuItem>
                          <Link
                            onClick={() =>
                              showAdditionalActivityCreateGescomBDLModal({
                                mission: item?.mission,
                                delivreyOrders: { bdlProvider, bdlCustomer },
                                additionalActivity: item,
                                customerReference: customerReference as string,
                                refetchQueryKey: 'Additionalactivity',
                              })
                            }
                            iconLeft={<AddIcon />}
                          >
                            Créer le BDL Fourn. dans GESCOM
                          </Link>
                        </MenuItem>
                      )}
                      {canDisplayShowCorrectRejectButton(item) && (
                        <MenuItem>
                          <Link
                            onClick={() =>
                              showPatchAdditionalActivityModal({
                                additionalActivity: item,
                              })
                            }
                            iconLeft={<EditIcon />}
                          >
                            Corriger / Refuser
                          </Link>
                        </MenuItem>
                      )}
                      {/*
                      - Check CRA status
                      - Only CRAs with existing BL can generate the customer invoice
                      - Check  customer invoice  status
                      - TODO CHECK IF VALIDATED BY SAGE !
                    */}
                      {canDisplayGenerateCustomerActivityInvoiceButton(
                        item
                      ) && (
                        <MenuItem>
                          <Link
                            iconLeft={<SendIcon />}
                            onClick={() =>
                              showGenerateInvoiceModal({
                                invoiceUuid: item?.invoices?.customer
                                  ?.uuid as string,
                              })
                            }
                          >
                            Générer et envoyer la facture client
                          </Link>
                        </MenuItem>
                      )}
                    </>
                  }
                >
                  <DotsIcon
                    fill={Theme?.colors?.primary?.default}
                    fontSize={20}
                  />
                </Menu>
              )}
            </Box>
          </Flex>
        );
      },
    },
  ].filter(Boolean) as TableColumn<Item>[];

const getSortBy = (
  filterParams: string,
  sortString: string,
  initialSate: boolean
) => {
  if (sortString?.includes('month')) {
    sortString?.replaceAll('month', 'normalizedDate');
  }
  let sortby = '';
  if (filterParams === 'to-be-validated') {
    sortby = `-hasFastCash fastCashDeadline ${
      sortString?.includes('-submittedAt') ? '-submittedAt' : 'submittedAt'
    }`;
  } else {
    sortby = sortString;
  }
  if (sortString?.includes('month') || sortString?.includes('submittedAt')) {
    sortby = `${sortby} uuid`;
  }
  return sortby;
};

export const AdditionalActivityList = ({
  mission,
  missionRef,
}: {
  mission: IJoinedMission;
  missionRef?: string;
}) => {
  const { filter: filterParams } = useParams<{
    filter:
      | 'awaiting'
      | 'validated'
      | 'to-be-validated'
      | 'archived'
      | 'rejected';
  }>();
  const {
    initialSate,
    order,
    sortedBy,
    searchQuery,
    startDate,
    endDate,
    statusSelect,
    selectedType,
    page,
    updateListAdditionalActivityStore,
    resetListAdditionalActivityStore,
  } = useListAdditionalActivityStore();
  const { me } = useMe();
  const t = useTranslationFormat();
  const sortString = (order === 'desc' ? '-' : '') + sortedBy;
  const debouncedFilterQuery = useDebounce(searchQuery, 500);
  const limit = 20;
  const {
    data: AdditionalActivityQuery,
    status,
    isFetching,
  } = useAdditionalActivityFindMany({
    joinInvoice: true,
    filterObject: {
      mission: missionRef,
      type:
        selectedType !== null &&
        selectedType !== 'N/A' &&
        selectedType !== undefined
          ? selectedType
          : {
              $in: [
                EadditionalActivityType.EXPENSE,
                EadditionalActivityType.STAND_BY_DUTY,
                //EadditionalActivityType.MILESTONE,
              ],
            },
      status: statusSelect !== 'N/A' ? statusSelect : undefined,
      $and:
        startDate && endDate
          ? [
              {
                $expr: {
                  $gte: [{ $toLong: '$month' }, getTime(startDate)],
                },
              },
              {
                $expr: {
                  $lte: [
                    { $toLong: '$month' },
                    getTime(add(endDate, { months: 1 })),
                  ],
                },
              },
            ]
          : undefined,
      $or: debouncedFilterQuery
        ? [
            {
              'mission.title': {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
            {
              'mission.displayReference': {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
            {
              'mission.cra.refCra': {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
            // customer
            {
              'estCustomer.businessName': {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
            {
              'estCustomer.tradeName': {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
            {
              'estCustomer.signBoard1': {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
            {
              'estCustomer.signBoard2': {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
            {
              'estCustomer.signBoard3': {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
            {
              'estCustomer.identifier': {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
            {
              'estCustomer.siret': {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
            {
              'estCustomer.siren': {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
            // provider
            {
              'estProvider.businessName': {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
            {
              'estProvider.tradeName': {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
            {
              'estProvider.signBoard1': {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
            {
              'estProvider.signBoard2': {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
            {
              'estProvider.signBoard3': {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
            {
              'estProvider.identifier': {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
            {
              'estProvider.siret': {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
            {
              'estProvider.siren': {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
          ]
        : undefined,
    },
    limit: limit,
    skip: limit * page,
    sort: formatInvoiceOrAdditionalActivitySort(
      getSortBy(filterParams, sortString, initialSate)
    ),
  });

  const loading = status === 'pending';
  const totalCount = AdditionalActivityQuery?.totalCount || 0;
  let items: Itemize<IJoinedAdditionalActivityWithInvoice>[] | [] | undefined =
    [];
  items = AdditionalActivityQuery?.additionalActivities?.map(
    (activity: any) => ({
      key: activity.uuid + '_' + activity.createdAt || '',
      ...activity,
    })
  );

  const onSortedChange = React.useCallback(
    (shortBy: string, order: 'asc' | 'desc'): void => {
      //@ts-ignore
      updateListAdditionalActivityStore({ order, sortedBy: shortBy });
    },
    []
  );

  const showMessage = useShowMessage();

  const RejectButton = ({
    additionalActivity,
  }: {
    additionalActivity: IJoinedAdditionalActivityWithInvoice;
  }) => {
    return (
      <Box>
        <Link
          iconLeft={<CancelIcon />}
          onClick={async () => {
            await showRejectAdditionalActivityModal({
              additionalActivity,
            });
          }}
        >
          Refuser
        </Link>
      </Box>
    );
  };
  React.useMemo(() => {
    if (filterParams === 'awaiting') {
      updateListAdditionalActivityStore({
        //@ts-ignore
        filter: filterParams,
        isOpen: true,
        initialSate,
        statusSelect: 'N/A',
      });
    } else {
      updateListAdditionalActivityStore({
        //@ts-ignore
        filter: filterParams,
        isOpen: false,
        initialSate,
        statusSelect: 'N/A',
      });
    }
  }, [filterParams]);

  return (
    <>
      <Container p="20px 0">
        <Flex justifyContent="space-between" ml={20} mr={20} mb={10}>
          <Box width={filterParams === 'awaiting' ? 3 / 12 : 4 / 12} mr={10}>
            <FormLabel>RECHERCHER</FormLabel>
            <Input
              isFullWidth
              type="search"
              value={searchQuery}
              onChange={e => {
                updateListAdditionalActivityStore({
                  //@ts-ignore
                  searchQuery: e?.target?.value,
                });
              }}
            />
          </Box>

          <Box width={4 / 12} mr={10}>
            <FormLabel pl={20}>PÉRIODE</FormLabel>
            <DatePickerRange
              startDate={startDate}
              endDate={endDate}
              isClearable={true}
              setStartDate={value => {
                updateListAdditionalActivityStore({
                  startDate: value as Date,
                  page: 0,
                });
              }}
              setEndDate={value => {
                updateListAdditionalActivityStore({
                  endDate: value as Date,
                  page: 0,
                });
              }}
            />
          </Box>
          <Box width={3 / 12} mr={10}>
            <FormLabel>Statuts</FormLabel>
            <StaticSelect
              options={[
                {
                  label: 'À soumettre',
                  value: EadditionalActivityStatus.TO_BE_SUBMITTED,
                  key: EadditionalActivityStatus.TO_BE_SUBMITTED,
                },
                {
                  label: 'À valider',
                  value: EadditionalActivityStatus.TO_BE_VALIDATED,
                  key: EadditionalActivityStatus.TO_BE_VALIDATED,
                },
                {
                  label: 'Validé',
                  value: EadditionalActivityStatus.VALIDATED,
                  key: EadditionalActivityStatus.VALIDATED,
                },
                {
                  label: 'Refusé',
                  value: EadditionalActivityStatus.REJECTED,
                  key: EadditionalActivityStatus.REJECTED,
                },
              ]}
              onChange={value =>
                updateListAdditionalActivityStore({
                  statusSelect: value as string,
                })
              }
              value={statusSelect}
            />
          </Box>
          <Box width={3 / 12} mr={10}>
            <FormLabel>Types</FormLabel>
            <StaticSelect
              placeholder="Tous les types"
              options={ADDITIONAL_ACTIVITY_TYPE_SELECT}
              onChange={value =>
                updateListAdditionalActivityStore({
                  selectedType: value as EadditionalActivityType,
                })
              }
              value={selectedType}
            />
          </Box>
          <Box>
            <Flex
              justifyContent={'center'}
              alignItems={'center'}
              flexWrap="wrap"
              alignContent="center"
              width={1 / 1}
              height={'100%'}
            >
              <Link
                isDisabled={initialSate}
                iconLeft={
                  <RefreshIcon
                    fill={
                      initialSate ? Theme?.colors?.grey?.default : undefined
                    }
                  />
                }
                mt={'5px'}
                onClick={() =>
                  !initialSate ? resetListAdditionalActivityStore({}) : false
                }
              >
                Réinitialiser
              </Link>
            </Flex>
          </Box>
        </Flex>
        {loading && <SpinnerBox />}
        {!loading && (
          <Table<Itemize<IJoinedAdditionalActivity>>
            isLoading={isFetching}
            columns={columns(mission, RejectButton, showMessage, me, t)}
            items={items}
            sortedBy={sortedBy}
            order={order}
            page={page}
            total={totalCount}
            perPage={totalCount < limit ? totalCount : limit}
            opacity={filterParams === 'archived' ? 0.5 : 1}
            onSortedChange={onSortedChange}
            onChangePage={page => updateListAdditionalActivityStore({ page })}
          />
        )}
      </Container>
    </>
  );
};
