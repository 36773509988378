import { useUserFindOne } from '@commons';
import { SpinnerBox } from 'components/ui';
import { OnBoardingEstablishmentForm } from 'forms/OnBoardingEstablishmentForm';
import { getFullName } from '@commons';
import { useSetCrumbs } from 'hooks/breadCrumb';
import * as React from 'react';
import { useParams } from 'react-router-dom';

export const ProviderContactsOnBoardingEstablishments: React.FunctionComponent<
  React.PropsWithChildren<unknown>
> = () => {
  const { id } = useParams<{ id: string }>();
  const { data: provider, isLoading } = useUserFindOne(id);
  useSetCrumbs(
    [provider],
    [
      {
        label: 'fournisseurs',
        path: '/providers',
      },
      {
        label: 'contacts',
        path: '/providers/contacts',
      },
      {
        label: provider ? getFullName(provider) : '-',
        path: `/providers/contacts/${id}`,
      },
      {
        label: 'informations',
        path: `/providers/contacts/on-boarding-card/${id}/establishments`,
      },
    ]
  );
  const tmpData = provider?.onboardingStep?.tmpData;
  return (
    <>
      {!isLoading && provider && provider.cognitoUserId === id && (
        <OnBoardingEstablishmentForm
          defaultValues={
            tmpData && Object.keys(tmpData).length > 0 ? tmpData : false
          }
          onboardingStep={provider?.onboardingStep}
        />
      )}
      {isLoading && <SpinnerBox />}
    </>
  );
};
