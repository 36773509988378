import { ReactNode } from 'react';
import styled from 'styled-components';
import { boxProperties, BoxProps } from './Box';

export type StatusVariantColor =
  | 'primary'
  | 'grey'
  | 'error'
  | 'success'
  | 'warning';

type StatusProps = {
  children?: ReactNode;
  variantColor?: StatusVariantColor;
  circleHidden?: boolean;
};
export const Status = styled.span<BoxProps & StatusProps>`
  display: flex;
  align-items: center;
  font-size: 11px;
  color: ${props =>
    props.theme.colors[props.variantColor || 'primary'].default};
  ${boxProperties}
  &:before {
    content: '';
    height: 7px;
    width: 7px;
    min-width: 7px;
    border-radius: 100%;
    margin-right: 10px;
    background-color: ${props =>
      props.theme.colors[props.variantColor || 'primary'].default};
    display: ${props => (props.circleHidden ? 'none' : 'initial')};
  }
`;
