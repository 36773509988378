import * as React from 'react';

function SvgEditIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
      <path
        fill={props?.fill}
        fillRule="evenodd"
        d="M5.172 15.673a7.254 7.254 0 013.155 3.11L4 20l1.172-4.327zm9.284-9.33l3.2 3.2-8.653 8.654c-.721-1.352-1.848-2.524-3.2-3.2l8.653-8.653zm3.516-2.073l1.803 1.803a1.103 1.103 0 01-.09 1.442l-1.398 1.398-3.2-3.2L16.53 4.27c.36-.36 1.036-.36 1.442 0z"
      />
    </svg>
  );
}

export default SvgEditIcon;
