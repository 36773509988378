import { Header } from 'components/Header';
import {
  Box,
  Container,
  Tab,
  Text,
  Itemize,
  Link,
  Spinner,
  Tag,
  Separator,
  CheckBox,
  Input,
  Flex,
  FormLabel,
  DatePickerRange,
} from 'components/ui';
import { add, getTime } from 'date-fns';
import { useDebounce } from 'hooks/useDebounce';
import * as React from 'react';
import { useSetCrumbs } from 'hooks/breadCrumb';
import { useParams } from 'react-router-dom';
import { useTranslationFormat } from 'hooks/useTranslateFormat';
import {
  IJoinedNotificationUser,
  INotificationState,
  ENotificationType,
} from '@freelancelabs/teoreme-commons';
import { notificationUpdateOne, getCivilityAllKeys } from '@commons';
import { useNotificationFindMany } from '@commons';
import { ALink } from 'components/ALink';
import {
  EyeIcon,
  StarIcon,
  TaskIcon,
  EyeSlashIcon,
  StarSlashIcon,
} from 'components/ui/icons';
import { Theme } from 'styles';
import { NOTIFICATION_TYPES } from '@commons';
import { StaticSelect } from 'components/selects/StaticSelect';
import TablePagination from 'components/ui/Table/components/TablePagination';
import { formatDateAndTime } from '@commons';

import { queryClient } from '@commons';

export type NotificationsListProps = {};
type Item = Itemize<IJoinedNotificationUser>;

const filterLabel: { [index: string]: string } = {
  all: 'Toutes les notifications',
  'non-read': 'Non lues',
  read: 'Lues',
  prioritized: 'Priorités',
};

const filterQuery: { [index: string]: any } = {
  all: {},
  'non-read': {
    'notificationUser.state.read': false,
  },
  read: {
    'notificationUser.state.read': true,
  },
  prioritized: {
    'notificationUser.state.priority': true,
  },
};

const tabItems = (
  <>
    <Tab variant="secondary" href={'/notifications/all'}>
      {filterLabel['all']}
    </Tab>
    <Tab variant="secondary" href={'/notifications/non-read'}>
      {filterLabel['non-read']}
    </Tab>
    <Tab variant="secondary" href={'/notifications/read'}>
      {filterLabel['read']}
    </Tab>
    <Tab variant="secondary" href={'/notifications/prioritized'}>
      {filterLabel['prioritized']}
    </Tab>
  </>
);

export const NotificationsList: React.FunctionComponent<
  React.PropsWithChildren<NotificationsListProps>
> = () => {
  const { filter } = useParams<{ filter: string }>();
  const t = useTranslationFormat();
  useSetCrumbs(
    [filter],
    [
      {
        label: 'notifications',
        path: '/notifications',
      },
      {
        //@ts-ignore
        label: filterLabel[filter]?.toLowerCase(),
        path: '/notifications/' + filter,
      },
    ]
  );

  const [checkAll, setCheckAll] = React.useState(false);
  const [checkedNotifications, setCheckedNotifications] = React.useState<
    Array<string>
  >([]);
  const [page, setPage] = React.useState(0);
  const [startDate, setStartDate] = React.useState<Date | null>(null);
  const [endDate, setEndDate] = React.useState<Date | null>(null);
  const [type, setType] = React.useState<string | undefined>();
  const [searchQuery, setSearchQuery] = React.useState<string | undefined>();

  const debouncedFilterQuery = useDebounce(searchQuery, 500);
  React.useEffect(() => {
    setPage(0);
    setCheckAll(false);
    setCheckedNotifications([]);
  }, [filter, debouncedFilterQuery, startDate, endDate, type]);
  const { data: notificationsQuery, status } = useNotificationFindMany({
    filterObject: {
      $or: debouncedFilterQuery
        ? [
            {
              title: {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
            {
              description: {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
          ]
        : undefined,
      $and:
        startDate && endDate
          ? [
              {
                $expr: {
                  $gte: [{ $toLong: '$updatedAt' }, getTime(startDate)],
                },
              },
              {
                $expr: {
                  $lte: [
                    { $toLong: '$updatedAt' },
                    getTime(add(endDate, { months: 1 })),
                  ],
                },
              },
            ]
          : undefined,
      //@ts-ignore
      ...filterQuery[filter],
      type: type === 'ALL' ? undefined : type,
    },
    sort: '-updatedAt',
    limit: 10,
    skip: 10 * page,
  });
  const loading = status === 'pending';
  const totalCount = notificationsQuery?.totalCount || 0;
  const totalPages = Math.ceil(totalCount / 10);
  const items: Item[] =
    //@ts-ignore
    notificationsQuery?.results.map(
      (notification: IJoinedNotificationUser) => ({
        ...notification,
        key: notification.uuid || '',
      })
    ) || [];

  const updateState = async (state: Partial<INotificationState>) => {
    const data = checkedNotifications.map(uuid => {
      return {
        uuid,
        state,
      };
    });
    if (data.length > 0) {
      await notificationUpdateOne({
        notificationStates: data,
      });
    }

    setCheckAll(false);
    setCheckedNotifications([]);
    queryClient.refetchQueries({ queryKey: ['notifications'], type: 'active' });
  };
  return (
    <Box>
      <Header tabs={tabItems}>
        <Text variant="h1">Notifications</Text>
      </Header>
      <Container paddingTop={4}>
        <Flex
          justifyContent="space-between"
          ml={20}
          mr={20}
          mb={20}
          padding={'10px'}
        >
          <Box width={3 / 12}>
            <FormLabel>RECHERCHER</FormLabel>
            <Input
              isFullWidth
              type="search"
              onChange={e => {
                //@ts-ignore
                setSearchQuery(e.target.value);
              }}
            />
          </Box>
          <Box width={4 / 12}>
            <FormLabel>Types</FormLabel>
            <StaticSelect
              width={1 / 1}
              options={[
                {
                  value: 'ALL',
                  label: 'Tout les types',
                },
                {
                  value: ENotificationType.ALERT,
                  label: 'Alertes',
                },
                {
                  value: ENotificationType.ACTION,
                  label: 'Actions',
                },
                {
                  value: ENotificationType.INFO,
                  label: 'Informations',
                },
              ]}
              value={type}
              onChange={value => setType(value as string)}
              placeholder="Types"
            />
          </Box>
          <Box width={4 / 12}>
            <FormLabel pl={20}>PÉRIODE</FormLabel>
            <DatePickerRange
              startDate={startDate}
              endDate={endDate}
              isClearable={true}
              setStartDate={value => {
                setStartDate(value as Date);
                setPage(0);
              }}
              setEndDate={value => {
                setEndDate(value as Date);
                setPage(0);
              }}
            />
          </Box>
        </Flex>
        {!loading && items.length === 0 && (
          <Box alignContent="center" alignItems="center">
            <TaskIcon style={{ margin: '0 auto' }} />
            <Text variant="h3" color={'primary.light'} textAlign="center">
              Aucune notification
            </Text>
            <Text color={'primary.light'} textAlign="center">
              Dès que vous recevrez une nouvelle notification, elle sera visible
              ici.
            </Text>
          </Box>
        )}
        {items && items.length > 0 && (
          <Box display="flex" mb={3}>
            <CheckBox
              checked={checkAll}
              mr={2}
              id={'check-all'}
              onChange={e => {
                if (e.currentTarget.checked) {
                  setCheckAll(true);
                  const saved: Array<string> = [];
                  items.forEach(item => {
                    saved.push(item.uuid);
                  });
                  setCheckedNotifications(saved);
                } else {
                  setCheckAll(false);
                  setCheckedNotifications([]);
                }
              }}
            >
              {' '}
            </CheckBox>
            {filter !== 'prioritized' && (
              <Link
                mt={-1}
                iconLeft={<StarIcon fill={Theme.colors.lightBlue} />}
                color={Theme.colors.lightBlue}
                fontSize={14}
                onClick={() => updateState({ priority: true })}
              >
                Prioriser
              </Link>
            )}
            <Link
              mt={-1}
              ml={filter !== 'prioritized' ? 4 : 0}
              iconLeft={<StarSlashIcon fill={Theme.colors.lightBlue} />}
              color={Theme.colors.lightBlue}
              fontSize={14}
              onClick={() => updateState({ priority: false })}
            >
              Déprioriser
            </Link>
            {filter !== 'read' && (
              <Link
                mt={-1}
                ml={4}
                iconLeft={<EyeIcon fill={Theme.colors.lightBlue} />}
                color={Theme.colors.lightBlue}
                fontSize={14}
                onClick={() => updateState({ read: true })}
              >
                Marquer comme lue
              </Link>
            )}
            {filter !== 'non-read' && (
              <Link
                mt={-1}
                ml={4}
                iconLeft={<EyeSlashIcon fill={Theme.colors.lightBlue} />}
                color={Theme.colors.lightBlue}
                fontSize={14}
                onClick={() => updateState({ read: false })}
              >
                Marquer comme non lue
              </Link>
            )}
          </Box>
        )}
        {loading && <Spinner />}
        {items.map(item => (
          <Box
            key={item.uuid}
            opacity={
              item.notificationUser?.state.read || item.defaultState?.read
                ? 0.5
                : 1
            }
          >
            <Box display="flex" mb={2}>
              <CheckBox
                checked={checkAll || checkedNotifications.includes(item.uuid)}
                mt={'5px'}
                mr={2}
                id={item.uuid}
                onChange={e => {
                  if (
                    e.currentTarget.checked &&
                    !checkedNotifications.includes(item.uuid)
                  ) {
                    setCheckedNotifications([
                      ...checkedNotifications,
                      item.uuid,
                    ]);
                  } else {
                    const saved = [...checkedNotifications];
                    const index = saved.indexOf(item.uuid);
                    if (index > -1) {
                      saved.splice(index, 1);
                    }
                    setCheckedNotifications(saved);
                    setCheckAll(false);
                  }
                }}
              >
                {' '}
              </CheckBox>
              <StarIcon
                fill={
                  item.notificationUser?.state.priority ||
                  item.defaultState?.priority
                    ? Theme.colors.primary.default
                    : Theme.colors.lightBlue
                }
              />
              <Tag
                marginLeft={2}
                variantColor={
                  item.type === 'ALERT'
                    ? 'warning'
                    : item.type === 'ACTION'
                      ? 'error'
                      : 'success'
                }
              >
                {NOTIFICATION_TYPES[item.type]}
              </Tag>
              <Text marginLeft={2} fontWeight="bold" maxWidth={'75%'}>
                {
                  t(`notifications:${item.reference}`, false, false, {
                    ...getCivilityAllKeys(item.parameters, item?.reference),
                  }).split('--')[0]
                }
              </Text>
              <ALink
                style={{ marginLeft: 'auto' }}
                href={t(
                  `links:${item.reference}`,
                  false,
                  false,
                  item.parameters
                )}
              >
                <Link iconLeft={<EyeIcon />}>Voir</Link>
              </ALink>
            </Box>
            <Box pl={4}>
              <Text>
                {t(`notifications:${item.reference}`, false, false, {
                  ...getCivilityAllKeys(item.parameters, item?.reference),
                })
                  .split('--')[1]
                  ?.replaceAll('&amp;', '&')}
              </Text>
            </Box>
            {item.createdAt && (
              <Box mt={3} pl={4}>
                <Text color={Theme.colors.lightBlue}>
                  {formatDateAndTime(item.createdAt || '')}
                </Text>
              </Box>
            )}
            <Separator />
          </Box>
        ))}
        {items.length > 0 && totalPages > 1 && (
          <TablePagination
            total={totalCount}
            page={page}
            perPage={10}
            onChangePage={page => {
              setCheckAll(false);
              setCheckedNotifications([]);
              setPage(page);
            }}
          />
        )}
      </Container>
    </Box>
  );
};
