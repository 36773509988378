import {
  EAdditionalServiceType,
  EPriceUnit,
  IFastCashService,
  IFastCashServiceCatalog,
  IJoinedMission,
} from '@freelancelabs/teoreme-commons';
import { StaticSelect } from 'components/selects/StaticSelect';
import {
  BlocInformation,
  Box,
  Button,
  CheckBox,
  CheckSwitch,
  Flex,
  FormControl,
  FormLabel,
  Link,
  Text,
  TextArea,
} from 'components/ui';
import { AddIcon } from 'components/ui/icons';
import { useState } from 'react';
import { getPriceByUnit } from '../../../../../../libs/commons/src/helpers';
import { FastCatalogForm } from './FastCatalogForm';

const defaultCatalog = [
  {
    uuid: 'TMP_1',
    deadline: 10,
    description: 'Fast Cash : paiement à 10 jours',
    price: 2,
    unit: EPriceUnit.PERCENT_INVOICE,
    upsert: true,
  },
  {
    uuid: 'TMP_2',
    deadline: 30,
    description: 'Fast Cash : paiement à 30 jours',
    price: 1,
    unit: EPriceUnit.PERCENT_INVOICE,
    upsert: true,
  },
];
type IFastCashServiceCatalogForm = IFastCashServiceCatalog & {
  upsert?: boolean;
  selected?: boolean;
};
type MissionFormValues = {
  formValues?: {
    endAt: Date;
    startAt: Date;
  };
};
type FastCashFormProps = {
  forRole: 'ALL' | 'CUSTOMER' | 'PROVIDER' | 'ESTABLISHMENT_PROVIDER';
  onSubmit: (service?: IFastCashService | undefined) => any | void;
  service?: IFastCashService;
  additionalServices: IFastCashService[];
  onClose: () => void;
  mission: IJoinedMission & MissionFormValues;
};

export const FastCashForm = ({
  additionalServices,
  forRole,
  onClose,
  onSubmit: onSubmitParent,
  service,
  mission,
}: FastCashFormProps) => {
  const paidByP =
    service?.paidByProvider !== undefined
      ? service?.paidByProvider
      : forRole === 'CUSTOMER'
        ? false
        : true;
  const formMethod = service ? 'update' : 'create';
  const [paidByProvider, setPaidByProvider] = useState(paidByP);
  const [recurrent, setRecurrent] = useState(service?.recurrent);
  const [comment, setComment] = useState(service?.comment);
  const [defaultCatalogItem, setDefaultCatalogItem] = useState(
    service?.defaultCatalogItem
  );
  const [onCreate, setOnCreate] = useState(false);
  const [catalogSelect, setCatalogSelect] = useState<string | undefined>();
  const [catalog, setCatalog] = useState<IFastCashServiceCatalogForm[]>(
    service?.catalog || defaultCatalog
  );
  const [loading, setLoading] = useState(false);
  const [defaultCatalogItemError, setDefaultCatalogItemError] = useState<
    string | undefined
  >();
  const onSubmit = async () => {
    if (recurrent === true && !defaultCatalogItem) {
      setDefaultCatalogItemError('Ce champs est requis');
      return;
    } else {
      setDefaultCatalogItemError(undefined);
    }
    setLoading(true);
    try {
      const upsert = catalog
        ?.filter((c: IFastCashServiceCatalogForm) => c?.upsert)
        ?.map((u: IFastCashServiceCatalogForm) => {
          return {
            uuid: u?.uuid?.includes('TMP_') ? undefined : u?.uuid,
            setAsDefaultItem: defaultCatalogItem === u?.uuid,
            deadline: u?.deadline,
            price: u?.price,
            unit: u?.unit,
            description: u?.description,
            comment: u?.comment,
          };
        });

      const remove = service?.catalog
        ?.filter(el => {
          const exist = catalog?.find(
            (c: IFastCashServiceCatalog) => c?.uuid === el?.uuid
          );
          if (!exist) {
            return true;
          }
        })
        ?.map(r => r?.uuid);
      await onSubmitParent({
        ...service,
        type: EAdditionalServiceType.FAST_CASH,
        recurrent,
        paidByProvider: paidByProvider,
        //@ts-ignore
        catalog:
          formMethod === 'update'
            ? {
                upsert,
                remove,
              }
            : undefined,
        comment,
        defaultCatalogItem: defaultCatalogItem?.includes('TMP_')
          ? undefined
          : defaultCatalogItem,
      });
    } catch (e) {
      //
    }
    setLoading(false);
  };
  const onUpdateForm = (values: any) => {
    const newCatalog = [...catalog];
    const index = catalog?.findIndex((c: any) => c?.uuid === values?.uuid);
    if (index !== -1) {
      newCatalog[index] = {
        ...values,
        deadline: Number(values?.deadline),
        price: Number(values?.price),
        upsert: true,
      };
    } else {
      newCatalog?.push({
        ...values,
        deadline: Number(values?.deadline),
        price: Number(values?.price),
        uuid: `TMP_${new Date()?.getTime()}`,
        upsert: true,
      });
    }
    setOnCreate(false);
    setCatalogSelect(undefined);
    setCatalog(newCatalog);
  };
  const onDelete = (uuid: string) => {
    if (uuid === defaultCatalogItem) {
      setRecurrent(false);
      setDefaultCatalogItem(undefined);
    }
    const newCatalog = [...catalog];
    const index = catalog?.findIndex((c: any) => c?.uuid === uuid);
    if (index !== -1) {
      newCatalog?.splice(index, 1);
    }
    setCatalog(newCatalog);
  };

  return (
    <Box width={1 / 1}>
      <>
        {forRole === 'PROVIDER' ||
          (forRole === 'ESTABLISHMENT_PROVIDER' && (
            <FormLabel mb={4}>Payé par le fournisseur</FormLabel>
          ))}
        {forRole === 'CUSTOMER' && (
          <FormLabel mb={4}>Payé par le client</FormLabel>
        )}
        <Flex mb={0}>
          {forRole === 'ALL' && (
            <>
              <FormControl label="">
                <CheckBox
                  checked={paidByProvider}
                  onChange={e => setPaidByProvider(e?.target?.checked)}
                  name="paidByProvider"
                  id={'paidByProvider'}
                  key="paidByProvider"
                >
                  <FormLabel>
                    {'Payé par le fournisseur ( sinon payé par le client )'}
                  </FormLabel>
                </CheckBox>
              </FormControl>
            </>
          )}
        </Flex>
      </>
      {formMethod === 'update' ? (
        <>
          <Flex>
            <FormControl label="Activer la récurrence">
              <CheckSwitch
                id="shouldValidateCra"
                defaultChecked={recurrent}
                onChange={e => setRecurrent(e?.target?.checked)}
                checked={recurrent}
              >
                {''}
              </CheckSwitch>
            </FormControl>
          </Flex>
          {recurrent && (
            <Box>
              <FormControl
                required
                label="Option récurrente"
                errorMessage={defaultCatalogItemError}
              >
                <StaticSelect
                  isClearable
                  options={catalog?.map((c: any) => {
                    return {
                      label: `Échéance: ${c?.deadline} jours , ${c?.price}${getPriceByUnit(
                        c?.unit
                      )}`,
                      value: c?.uuid,
                    };
                  })}
                  onChange={value => setDefaultCatalogItem(value as string)}
                  value={defaultCatalogItem}
                />
              </FormControl>
            </Box>
          )}

          <Text variant="h3">Options disponibles</Text>

          {catalog?.map((c: any, index: number) => (
            <Box width={1 / 1} key={c?.uuid}>
              <FastCatalogForm
                isEditable={catalogSelect === c?.uuid}
                onEdit={(uuid: string) => {
                  setCatalogSelect(uuid);
                  setOnCreate(false);
                }}
                onDelete={(uuid: string) => onDelete(uuid)}
                catalog={c}
                onSubmit={values => onUpdateForm(values)}
              />
            </Box>
          ))}
          {!onCreate && (
            <Link
              iconLeft={<AddIcon />}
              onClick={() => {
                setOnCreate(true);
                setCatalogSelect(undefined);
              }}
            >
              Ajouter une option
            </Link>
          )}
          {onCreate && (
            <Box width={1 / 1}>
              <FastCatalogForm
                isEditable={true}
                catalog={{}}
                onSubmit={values => onUpdateForm(values)}
              />
            </Box>
          )}
        </>
      ) : (
        <BlocInformation>
          <p>
            Le service fast-cash sera créé avec les options{' '}
            <b>10 jours et 30 jours par default et sans recurrence.</b>
          </p>
        </BlocInformation>
      )}
      <Box width={1 / 1} mt={10}>
        <FormControl label="Commentaire">
          <TextArea
            value={comment}
            minRows={3}
            isFullWidth
            name="comment"
            onChange={e => setComment(e?.target?.value)}
          />
        </FormControl>
      </Box>
      <Button
        mt={10}
        type="button"
        variant="filled"
        onClick={onSubmit}
        isLoading={loading}
      >
        {formMethod === 'create' ? 'Ajouter' : 'Modifier'}
      </Button>
    </Box>
  );
};
