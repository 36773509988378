import {
  EadditionalActivityType,
  EInvoiceStatus,
  EInvoiceType,
  EChangeAction,
} from '@freelancelabs/teoreme-commons';
import { add, getTime } from 'date-fns';
import { Theme } from 'styles';
import {
  AddIcon,
  ArrowUpIcon,
  CheckIcon,
  CloseIcon,
  DeleteIcon,
  DotsIcon,
  EditIcon,
  ExclamationCircleIcon,
} from 'components/ui/icons';
type InvoiceStatusEnum = Exclude<EInvoiceStatus, excludedOptions>;

type excludedOptions =
  | typeof EInvoiceStatus.REJECTED
  | typeof EInvoiceStatus.TO_BE_FILLED;

export const getInvoiceState = (
  filter: InvoiceStatusEnum | 'AWAITING',
  selectedState: EInvoiceStatus | 'ALL' | 'AWAITING'
): Array<EInvoiceStatus> => {
  if (filter === 'AWAITING') {
    if (
      !selectedState ||
      selectedState === 'ALL' ||
      selectedState === 'AWAITING'
    ) {
      return [
        EInvoiceStatus.TO_BE_FILLED,
        EInvoiceStatus.TO_BE_SUBMITTED,
        EInvoiceStatus.REJECTED,
        EInvoiceStatus.PROJECTED,
      ];
    }
    return [selectedState];
  }
  return [filter];
};
export const getObjectFilterInvoice = (
  debouncedFilterQuery = '',
  startDate: Date | null,
  endDate: Date | null,
  selectedStatus: EInvoiceStatus | 'ALL' | 'AWAITING',
  selectedType: EInvoiceType | 'ALL',
  accountManagerId: string | number | null | undefined
): any => {
  const filterObject = {
    invoiceType:
      selectedType !== 'ALL' && selectedType ? selectedType : undefined,
    status: selectedStatus !== 'ALL' ? selectedStatus : undefined,
    accountManager: accountManagerId || undefined,
  };
  const queryFilter = [
    {
      'estCustomer.businessName': {
        $regex: debouncedFilterQuery,
        $options: 'i',
      },
    },
    {
      'contractor.firstName': {
        $regex: debouncedFilterQuery,
        $options: 'i',
      },
    },
    {
      'contractor.lastName': {
        $regex: debouncedFilterQuery,
        $options: 'i',
      },
    },
    {
      'mission.displayReference': {
        $regex: debouncedFilterQuery,
        $options: 'i',
      },
    },
    {
      'mission.sage.structure': {
        $regex: debouncedFilterQuery,
        $options: 'i',
      },
    },
    {
      'estProvider.businessName': {
        $regex: debouncedFilterQuery,
        $options: 'i',
      },
    },
    {
      'estProvider.tradeName': {
        $regex: debouncedFilterQuery,
        $options: 'i',
      },
    },
    {
      'estProvider.signBoard1': {
        $regex: debouncedFilterQuery,
        $options: 'i',
      },
    },
    {
      'estProvider.signBoard2': {
        $regex: debouncedFilterQuery,
        $options: 'i',
      },
    },
    {
      'estProvider.signBoard3': {
        $regex: debouncedFilterQuery,
        $options: 'i',
      },
    },
    {
      'sage.invoiceId': {
        $regex: debouncedFilterQuery,
        $options: 'i',
      },
    },
    {
      providerInvoiceRef: {
        $regex: debouncedFilterQuery,
        $options: 'i',
      },
    },
  ];
  if (startDate !== null && endDate !== null) {
    //@ts-ignore
    filterObject.$and = [
      {
        $expr: {
          $gte: [{ $toLong: '$month' }, getTime(startDate)],
        },
      },
      {
        $expr: {
          $lte: [{ $toLong: '$month' }, getTime(add(endDate, { months: 1 }))],
        },
      },
    ];
    if (debouncedFilterQuery.length > 0) {
      //@ts-ignore
      filterObject.$and.push({ $or: queryFilter });
    }
  } else {
    if (debouncedFilterQuery.length > 0) {
      //@ts-ignore
      filterObject.$or = queryFilter;
    }
  }

  return filterObject;
};

export const getFilterObjectMilestones = (params: any): any => {
  const filterObject = {
    status:
      params?.selectedStatus !== 'ALL' ? params?.selectedStatus : undefined,
    type: EadditionalActivityType.MILESTONE,
    accountManager: params?.accountManagerId || undefined,
    'mission.billingInformation.structure':
      params?.structureSelected !== null && params?.structureSelected !== 'N/A'
        ? params?.structureSelected
        : undefined,
    estProvider:
      params?.multiProviderSelected && params?.multiProviderSelected?.length > 0
        ? { $in: params?.multiProviderSelected?.map((mps: any) => mps?.value) }
        : undefined,
    estCustomer:
      params?.multiClientSelected && params?.multiClientSelected?.length > 0
        ? { $in: params?.multiClientSelected?.map((mps: any) => mps?.value) }
        : undefined,
    contractor:
      params?.contractorSelected !== null
        ? params?.contractorSelected
        : undefined,
  };
  const queryFilter = [
    {
      'estCustomer.businessName': {
        $regex: params?.debouncedFilterQuery,
        $options: 'i',
      },
    },
    {
      'contractor.firstName': {
        $regex: params?.debouncedFilterQuery,
        $options: 'i',
      },
    },
    {
      'contractor.lastName': {
        $regex: params?.debouncedFilterQuery,
        $options: 'i',
      },
    },
    {
      'mission.displayReference': {
        $regex: params?.debouncedFilterQuery,
        $options: 'i',
      },
    },
    {
      'mission.sage.structure': {
        $regex: params?.debouncedFilterQuery,
        $options: 'i',
      },
    },
    {
      'estProvider.businessName': {
        $regex: params?.debouncedFilterQuery,
        $options: 'i',
      },
    },
    {
      'estProvider.tradeName': {
        $regex: params?.debouncedFilterQuery,
        $options: 'i',
      },
    },
    {
      'estProvider.signBoard1': {
        $regex: params?.debouncedFilterQuery,
        $options: 'i',
      },
    },
    {
      'estProvider.signBoard2': {
        $regex: params?.debouncedFilterQuery,
        $options: 'i',
      },
    },
    {
      'estProvider.signBoard3': {
        $regex: params?.debouncedFilterQuery,
        $options: 'i',
      },
    },
    // {
    //   'sage.invoiceId': {
    //     $regex: params?.debouncedFilterQuery,
    //     $options: 'i',
    //   },
    // },
    // {
    //   providerInvoiceRef: {
    //     $regex: params?.debouncedFilterQuery,
    //     $options: 'i',
    //   },
    // },
  ];
  if (params?.startDate !== null && params?.endDate !== null) {
    //@ts-ignore
    filterObject.$and = [
      {
        $expr: {
          $gte: [{ $toLong: '$month' }, getTime(params?.startDate)],
        },
      },
      {
        $expr: {
          $lte: [
            { $toLong: '$month' },
            getTime(add(params?.endDate, { months: 1 })),
          ],
        },
      },
    ];
    if (params?.debouncedFilterQuery.length > 0) {
      //@ts-ignore
      filterObject.$and.push({ $or: queryFilter });
    }
  } else {
    if (params?.debouncedFilterQuery.length > 0) {
      //@ts-ignore
      filterObject.$or = queryFilter;
    }
  }

  return filterObject;
};
export const getTimeLineIconByAction = (
  action: EChangeAction,
  state: string,
  color = 'white'
) => {
  if (!state) {
    switch (action) {
      case EChangeAction?.CREATE:
        return <AddIcon fill={color} />;
      case EChangeAction?.UPDATE:
        return <EditIcon fill={color} />;
      case EChangeAction?.DELETE:
        return <DeleteIcon fill={color} />;
      case EChangeAction?.ROLLBACK:
        return <ArrowUpIcon fill={color} />;
      case EChangeAction?.REMINDER:
        return <ExclamationCircleIcon fill={color} />;
      default:
        return <EditIcon fill={color} />;
    }
  } else {
    switch (state) {
      case 'DRAFT':
        return <DotsIcon fill={color} />;
      case 'TO_BE_VALIDATED':
        return <DotsIcon fill={color} />;
      case 'VALIDATED':
        return <CheckIcon fill={color} />;
      case 'REJECTED':
        return <CloseIcon fill={color} />;
      case 'CANCELLED':
        return <CloseIcon fill={color} />;
      case 'TERMINATED':
        return <CloseIcon fill={color} />;
      case 'ABORTED':
        return <CloseIcon fill={color} />;

      default:
        return <EditIcon fill={color} />;
    }
  }
};
export const getTimeLineColorsByState = (state: string) => {
  let backgroundColor = Theme?.colors?.grayBlue;
  switch (state) {
    // case 'ARCHIVED':
    //   backgroundColor = Theme?.colors?.grayBlue;
    //   break;
    // case 'PROJECTED':
    //   backgroundColor = Theme?.colors?.grayBlue;
    //   break;
    // case 'TO_BE_FILLED':
    //   backgroundColor = Theme?.colors?.grayBlue;
    //   break;
    // case 'TO_BE_SUBMITTED':
    //   backgroundColor = Theme?.colors?.grayBlue;
    //   break;
    // case 'DRAFT':
    //   backgroundColor = Theme?.colors?.warning?.default;
    //   break;
    case 'TO_BE_VALIDATED':
      backgroundColor = Theme?.colors?.warning?.default;
      break;
    case 'VALIDATED':
      backgroundColor = Theme?.colors?.success?.default;
      break;
    // case 'SENT':
    //   backgroundColor = Theme?.colors?.success?.default;
    //   break;
    // case 'PAID':
    //   backgroundColor = Theme?.colors?.success?.default;
    //   break;
    case 'REJECTED':
      backgroundColor = Theme?.colors?.error?.default;
      break;
    case 'CANCELLED':
      backgroundColor = Theme?.colors?.error?.default;
      break;
    case 'TERMINATED':
      backgroundColor = Theme?.colors?.error?.default;
      break;
    case 'ABORTED':
      backgroundColor = Theme?.colors?.error?.default;
      break;
  }
  return backgroundColor;
};
