import { ConfirmNavigationModal } from 'components/modals/ConfirmNavigationModal';
import { Flex, Text, Button, FormControl } from 'components/ui';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  MIXED_TAG_INPUT_SETTING,
  CUSTOMER_REFERENCE_PATTERN_TAG_WHITELIST,
  transformTagToFormula,
  transformFormulaToTag,
} from '@commons';
import { ECustomerReferencePatternVars } from '@freelancelabs/teoreme-commons';
import { MixedModeTagify } from 'components/InputTags';

type FormValues = {
  customerRefPattern: string;
};
type ContactFormProps = {
  customerRefPattern?: string;
  onSubmit: (values: any) => any | void;
  onClose: (data?: any) => void;
};

export const AddICustomerRefPatternForm = ({
  customerRefPattern,
  onSubmit: onSubmitParent,
  onClose,
}: ContactFormProps) => {
  const [loading, setLoading] = useState(false);
  const {
    handleSubmit,
    formState: { isDirty, isSubmitSuccessful },
    setValue,
  } = useForm<FormValues>({
    defaultValues: {
      customerRefPattern: customerRefPattern,
    },
  });
  const [formula, setFormula] = useState(
    customerRefPattern
      ? transformFormulaToTag(
          customerRefPattern,
          CUSTOMER_REFERENCE_PATTERN_TAG_WHITELIST
        ) + ' '
      : ''
    //   : transformFormulaToTag(
    //       `{{${ECustomerReferencePatternVars?.CUSTOMER_REF}}}`,
    //       CUSTOMER_REFERENCE_PATTERN_TAG_WHITELIST
    //     )
  );

  const onSubmit = async (formValues: FormValues) => {
    if (onSubmitParent) {
      try {
        setLoading(true);
        await onSubmitParent(formValues?.customerRefPattern);
      } catch (e) {
        setLoading(false);
      }
      setLoading(false);
    }
  };
  const onChangeProviderFormula = (value: string) => {
    setFormula(value);
    setValue(
      'customerRefPattern',
      transformTagToFormula(value)?.replaceAll('\n', '')
    );
  };
  const resultFormula = (): any => {
    if (!formula || formula?.length === 0) {
      return '';
    }
    const craFyear = '2023';
    const craYear = '23';
    const craMonth = '01';
    const missionRef = 'C202344444';
    const customerRef = 'REFERENCE_CLIENT';
    const formulaJS = transformTagToFormula(formula);
    let rp = formulaJS;
    rp = rp?.replaceAll(
      `{{${ECustomerReferencePatternVars?.CRA_FULL_YEAR}}}`,
      craFyear
    );
    rp = rp?.replaceAll(
      `{{${ECustomerReferencePatternVars?.CRA_MONTH}}}`,
      craMonth
    );
    rp = rp?.replaceAll(
      `{{${ECustomerReferencePatternVars?.CRA_YEAR}}}`,
      craYear
    );
    rp = rp?.replaceAll(
      `{{${ECustomerReferencePatternVars?.MISSION_REF}}}`,
      missionRef
    );
    rp = rp?.replaceAll(
      `{{${ECustomerReferencePatternVars?.CUSTOMER_REF}}}`,
      customerRef
    );
    return rp;
  };
  const result = resultFormula();
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <ConfirmNavigationModal active={isDirty && !isSubmitSuccessful} />
      <Text variant="h2" mb={10}>
        Référence de facturation
      </Text>
      <FormControl label="Formule Référence client">
        <>
          <MixedModeTagify
            placeholder="Ecrivez votre formule (@ pour utiliser les variables)"
            settings={{
              ...MIXED_TAG_INPUT_SETTING,
              whitelist: CUSTOMER_REFERENCE_PATTERN_TAG_WHITELIST,
            }}
            onChange={e => onChangeProviderFormula(e)}
            value={formula}
          />
          <Flex mb={20}>
            <Text variant="b">Résultat de la formule: </Text>
            <Text ml={'5px'}>
              {result && result?.length > 1 ? result : 'N/A'}
            </Text>
          </Flex>
        </>
      </FormControl>
      <Flex>
        <Button type="submit" isLoading={loading}>
          Enregistrer
        </Button>
        <Button
          ml={10}
          variant="ghost"
          type="button"
          isLoading={loading}
          onClick={() => onClose()}
        >
          Annuler
        </Button>
      </Flex>
    </form>
  );
};
