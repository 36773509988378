import { EconfirmUserAction } from '@freelancelabs/teoreme-commons';
import {
  verifyToken,
  checkValidPassword,
  confirmPassword,
  checkFormPassword,
} from '@commons';
import { useAmplifyLogout } from '@commons';
import { showDialogModal } from 'components/modals/DialogModal';
import { ReactComponent as Welcome } from 'assets/welcome.svg';
import { PageNotFound } from './NotFound';
import {
  Spinner,
  Box,
  Button,
  Flex,
  FormControl,
  Input,
  Text,
} from 'components/ui';
import { useBreadCrumb } from 'hooks/breadCrumb';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
type FormValues = {
  email: string;
  password: string;
  confirmPassword: string;
};

export const ResetPasswordPage = () => {
  const history = useHistory();
  const { mutateAsync: logout } = useAmplifyLogout();
  const { token } = useParams<{ token: string }>();
  const [status, setStatus] = React.useState('noFetch');
  const [email, setEmail] = React.useState('');
  const [isloading, setIsLoading] = React.useState(false);
  const { setCrumbs } = useBreadCrumb();
  setCrumbs([
    {
      label: `Réinitialiser votre mot de passe`,
      path: '',
    },
  ]);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm<FormValues>();
  const loading = status === 'pending';
  const checkToken = React.useCallback(async (): Promise<void> => {
    try {
      const response = await verifyToken({ token: token }, true);
      setEmail(response.email);
      setStatus('success');
    } catch {
      setStatus('error');
    }
  }, [token]);

  React.useEffect(() => {
    if (status === 'noFetch') {
      setStatus('loading');
      checkToken();
    }
  }, [checkToken, status]);

  const onSubmit = async (data: FormValues) => {
    let onError = false;
    if (data.password === data.confirmPassword) {
      if (checkValidPassword(data.password)) {
        setError('password', { message: undefined });
        setError('confirmPassword', { message: undefined });
      } else {
        onError = true;
        setError('password', {
          message: "Le format du mot de passe n'est pas valide",
        });
        setError('confirmPassword', {
          message: "Le format du mot de passe n'est pas valide",
        });
      }
    } else {
      onError = true;
      setError('password', {
        message: 'Les mots de passe ne sont pas identiques',
      });
      setError('confirmPassword', {
        message: 'Les mots de passe ne sont pas identiques',
      });
    }
    if (onError === false) {
      setIsLoading(true);
      try {
        const params = {
          email: email,
          newPassword: data.password,
          action: EconfirmUserAction.RESET,
        };

        await confirmPassword(params);
        setIsLoading(false);
        if (localStorage.token) {
          await logout();
          localStorage.clear();
        }
        await showDialogModal({
          title: 'Félicitations, votre mot de passe a bien été réinitialisé !',
          text: 'Vous allez recevoir un mail de confirmation.',
          confirmLabel: 'Retourner à la page de connexion',
        }).then(action => {
          history.push('/login');
        });
      } catch (e) {
        //console.log(e);
      }
    }
  };
  if (status === 'error') {
    return <PageNotFound />;
  }
  if (loading) {
    return (
      <Flex
        top={0}
        left={0}
        bottom={0}
        right={0}
        alignItems="center"
        justifyContent="center"
        position="fixed"
      >
        <Spinner />
      </Flex>
    );
  }
  return (
    <Flex height={'100vh'} alignItems="center" justifyContent="center">
      <Flex alignItems="center" width="100%" maxWidth={1000}>
        <Box flex={1}>
          <Text variant="h1" fontSize={32} mb={50}>
            Bienvenue sur Connecteed
          </Text>
          <Text variant="h3">
            <b>Réinitialiser votre mot de passe</b>
          </Text>
          <Text mb={10}>
            Doit contenir au moins 8 caractères, 1 majuscule, 1 minuscule et 1
            caractère spécial.
          </Text>
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormControl
              required
              label="Mot de passe"
              errorMessage={errors?.password?.message}
            >
              <Input
                width={'85%'}
                type="password"
                id="password"
                {...register('password', {
                  validate: (value: any) => checkFormPassword(value),
                })}
              />
            </FormControl>
            <FormControl
              required
              label="Mot de passe"
              errorMessage={errors?.password?.message}
            >
              <Input
                width={'85%'}
                type="password"
                id="confirmPassword"
                {...register('confirmPassword', {
                  validate: (value: any) => checkFormPassword(value),
                })}
              />
            </FormControl>
            <Button mb={10} type="submit" isLoading={isloading}>
              Réinitialiser
            </Button>
          </form>
        </Box>
        <Flex alignItems="center" flexDirection="column" flex={1}>
          <Welcome height="250px" width="500px" />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default ResetPasswordPage;
