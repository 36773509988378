import {
  IJoinedMission,
  IJoinedAdditionalActivity,
  EadditionalActivityStatus,
  EMarginType,
  ETaskStatus,
} from '@freelancelabs/teoreme-commons';
import {
  Flex,
  Box,
  Status,
  Text,
  Link,
  ALink,
  Container,
  Menu,
  MenuItem,
  CustomToolTip,
} from 'components/ui';
import { DotsIcon, EditIcon, EyeIcon, InfoIcon } from 'components/ui/icons';
import { MilestonList } from 'components/lists/MilestonList';
import {
  getEstablishmentName,
  getFullName,
  formatDate,
  getCustomerMileStoneAmount,
  FR_STATUS,
  round,
} from '@commons';
import { useState } from 'react';
import { getStatusData, taskTypeMapping } from 'helpers';
import { useMissionMilestonesListStore } from 'store';
import { showCreateOrUpdateMileStoneModal } from 'components/modals/activities/CreateOrUpdateMileStoneModal';
import { Theme } from 'styles';
import { showPatchAdditionalActivityModal } from 'components/modals/activities/PatchAdditionalActivityModal';
import { MissionMilestonesFilters } from './MissionMilestonesFilters';
type MilestonesMissionListProps = {
  mission: IJoinedMission;
};
export const MilestonesMissionList = ({
  mission,
}: MilestonesMissionListProps) => {
  const [colorStepper, setColorStepper] = useState(Theme?.colors?.blue);
  //   const onSortedChange = React.useCallback(
  //     (shortBy: string, order: 'asc' | 'desc'): void => {
  //       setSortedBy(shortBy);
  //       setOrder(order);
  //     },
  //     []
  //   );

  const columns = () => [
    {
      key: 'status',
      text: 'STATUT',
      sortKey: 'status',
      render: (item: IJoinedAdditionalActivity) => {
        const statusData = getStatusData(item);
        return (
          <Box>
            <Status variantColor={statusData.variantColor}>
              {statusData.statusText}
            </Status>
          </Box>
        );
      },
    },
    // {
    //   key: 'structure',
    //   sortKey: 'mission.sage.sucture',
    //   text: 'STRU',
    //   render: (item: IJoinedAdditionalActivity) =>
    //     item?.mission?.sage?.structure,
    // },
    {
      key: 'submittedAt',
      text: 'RECU LE',
      sortKey: 'submittedAt',
      render: (item: IJoinedAdditionalActivity) => {
        const submitAt = item?.submittedAt || item?.statusChangedAt;
        const sumbitDate = submitAt ? new Date(submitAt) : undefined;
        return (
          <Box>
            <Text>{sumbitDate ? `${formatDate(sumbitDate)}` : 'N/A'}</Text>
          </Box>
        );
      },
    },
    {
      key: 'estCustomer',
      text: 'ÉTABLIS. CLIENT',
      sortKey: 'estCustomer.businessName',
      render: (item: IJoinedAdditionalActivity) => (
        <ALink
          href={`/clients/establishments/${item?.estCustomer?.uuid}`}
          target="_blank"
        >
          {getEstablishmentName(item.estCustomer)}
        </ALink>
      ),
    },
    {
      key: 'estProvider',
      text: 'ÉTABLIS. FOURN.',
      sortKey: 'estProvider.businessName',
      render: (item: IJoinedAdditionalActivity) => (
        <ALink
          href={`/providers/establishments/${item?.estProvider?.uuid}`}
          target="_blank"
        >
          {getEstablishmentName(item.estProvider)}
        </ALink>
      ),
    },
    {
      key: 'contractor',
      text: 'INTERVENANT',
      sortKey: 'contractor.firstName',
      render: (item: IJoinedAdditionalActivity) => (
        <ALink
          href={`/providers/contractors/${item?.contractor?.uuid}`}
          target="_blank"
        >
          {getFullName(item.contractor)}
        </ALink>
      ),
    },
    {
      key: 'totalAmount',
      text: 'MONTANT HT FOURN.',
      render: (item: IJoinedAdditionalActivity) => {
        return `${item?.milestoneAmount} €`;
      },
    },
    {
      key: 'totalAmount',
      text: 'MONTANT HT CLIENT.',
      render: (item: IJoinedAdditionalActivity) => {
        return `${getCustomerMileStoneAmount(
          item?.milestoneAmount || 0,
          item?.customerMargin || 0,
          item?.customerMarginType as EMarginType,
          true
        )} €`;
      },
    },
    {
      key: 'task',
      text: 'TÂCHES',
      render: (item: IJoinedAdditionalActivity) => {
        const displayedStatus = [ETaskStatus.ERROR, ETaskStatus.ONGOING];
        const getDisplayTask = () =>
          item?.tasks?.filter(task =>
            displayedStatus?.includes(task?.lastTaskStatus)
          );
        const tasks = getDisplayTask();
        if (tasks && tasks?.length > 0) {
          return (
            <CustomToolTip
              color={Theme?.colors?.warning?.default}
              text={
                <Box>
                  <ul>
                    {tasks?.map(task => (
                      <li>
                        {taskTypeMapping?.[task.taskType]}:&nbsp;
                        {FR_STATUS?.[task?.lastTaskStatus]}
                      </li>
                    ))}
                  </ul>
                </Box>
              }
              id={item?.uuid + '_task'}
            >
              <InfoIcon
                //onClick={() => showCraTaskDetails({ cra: item })}
                fill={Theme?.colors?.warning?.default}
                style={{ cursor: 'pointer' }}
              />
            </CustomToolTip>
          );
        } else {
          return 'N/A';
        }
      },
    },
    {
      key: 'actions',
      text: 'ACTIONS',
      render: (item: IJoinedAdditionalActivity) => {
        return (
          <Flex alignItems="center">
            <Menu
              align="right"
              menuItems={
                <>
                  <MenuItem>
                    <ALink
                      href={`/orders/activities/flat-rate/${item?.mission?.reference}`}
                    >
                      <Link iconLeft={<EyeIcon />}>Voir la fiche</Link>
                    </ALink>
                  </MenuItem>
                  <MenuItem>
                    <Link
                      iconLeft={<EyeIcon />}
                      onClick={() =>
                        showCreateOrUpdateMileStoneModal({
                          method: 'UPDATE',
                          uuid: item?.uuid as string,
                          mission: mission as any,
                        })
                      }
                    >
                      Voir le jalon
                    </Link>
                  </MenuItem>
                  {item.status === EadditionalActivityStatus.VALIDATED && (
                    <MenuItem>
                      <Link
                        onClick={() =>
                          showPatchAdditionalActivityModal({
                            additionalActivity: item,
                          })
                        }
                        iconLeft={<EditIcon />}
                      >
                        Corriger / Refuser
                      </Link>
                    </MenuItem>
                  )}
                </>
              }
            >
              <DotsIcon fill={Theme?.colors?.primary?.default} fontSize={20} />
            </Menu>
          </Flex>
        );
      },
    },
  ];
  const currentConsumedBudget = mission?.billing?.consumedPurchaseBudget || 0;
  const contractorRate = mission?.billing?.contractorRate;
  const stepperPercent = () => {
    const percent = (currentConsumedBudget / (contractorRate || 0)) * 100;
    if (percent > 100) {
      if (colorStepper !== Theme?.colors?.error?.default) {
        setColorStepper(Theme?.colors?.error?.default);
      }

      return '100%';
    }
    if (percent >= 80) {
      if (colorStepper !== Theme?.colors?.warning?.default) {
        setColorStepper(Theme?.colors?.warning?.default);
      }
    } else {
      if (colorStepper !== Theme?.colors?.blue) {
        setColorStepper(Theme?.colors?.blue);
      }
    }

    return `${percent}%`;
  };

  const stepperWidth = stepperPercent();
  return (
    <Box>
      <Container p="20px 0">
        <Box>
          <Box mb={20}>
            <Flex
              width={1 / 1}
              zIndex={100}
              justifyContent="space-around"
              borderBottom={'12px solid #eaeefc'}
              borderRadius={'6px'}
            >
              <Box
                position="absolute"
                left={0}
                bottom={-12}
                //@ts-ignore
                width={stepperWidth}
                borderRadius={'6px'}
                borderBottom={`12px solid ${colorStepper}`}
              />
            </Flex>
            <Text mt={20} width={1 / 1}>
              {`Le fournisseur à consommé ${round(
                currentConsumedBudget
              )} € euros sur ${contractorRate} €
                euros prévus sur cette mission.`}
            </Text>
          </Box>
        </Box>
        <MilestonList
          filterComponents={
            <MissionMilestonesFilters
              hookStore={useMissionMilestonesListStore}
            />
          }
          hookStore={useMissionMilestonesListStore}
          filterObject={{ mission: mission.reference }}
          columns={columns}
        />
      </Container>
    </Box>
  );
};
