import { useCallback, useState } from 'react';
import { Box } from 'components/ui';
import './styles.css';
//@ts-ignore
import { MixedTags } from '@yaireo/tagify/dist/react.tagify';
// Tagify settings object
// eslint-disable-next-line @typescript-eslint/no-unused-vars
type MixedModeTagifyProps = {
  onChange?: (phone: string) => void;
  placeholder?: string;
  value: string;
  readOnly?: boolean;
  settings: any;
  autoFocus?: boolean;
  className?: string;
};
export const MixedModeTagify = ({
  onChange,
  placeholder,
  value,
  readOnly,
  settings,
  autoFocus,
  className,
}: MixedModeTagifyProps) => {
  const [textValue, setTextValue] = useState(value);
  const onTextChange = useCallback((e: any) => {
    setTextValue(e.detail.value);
    if (onChange) {
      onChange(e.detail.value);
    }
  }, []);
  return (
    <Box>
      <MixedTags
        autoFocus={autoFocus}
        settings={settings}
        className={className || (readOnly ? 'myTagsReadOnly' : 'myTags')}
        readOnly={readOnly}
        onChange={onTextChange}
        value={textValue}
        children={<></>}
        placeholder={
          placeholder || 'Écrivez votre formule ex: ( @TJM * @SAISIE * 1,5 ) '
        }
      />
    </Box>
  );
};

export default MixedModeTagify;
