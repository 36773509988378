import {
  FlatUser,
  IEstablishmentMinifiedUsers,
  ISageReference,
} from '@freelancelabs/teoreme-commons';
import { getFullName, getEstablishmentName } from '@commons';
import {
  useSageCreateContact,
  useUserFindOne,
  useEstablishmentUpdateOne,
  useEstablishmentFindMany,
  checkAllStructureCreatedInGescomForContactOnEstablishment,
} from '@commons';
import { EstablishmentStructuredCard } from 'components/cards/EstablishmentStructuredCard';
import { showDialogModal } from 'components/modals/DialogModal';
import {
  BlocInformation,
  Box,
  Button,
  Link,
  SpinnerBox,
  Flex,
  Status,
  Text,
} from 'components/ui';
import { AddIcon, CloseIcon, EditIcon } from 'components/ui/icons';
import { useSetCrumbs } from 'hooks/breadCrumb';
import * as React from 'react';
import { queryClient } from '@commons';
import { useParams } from 'react-router-dom';
import { Theme } from 'styles';
import { showLinkEstablishmentToContactProviderModal } from 'components/modals/providers/LinkEstablishmentProviderModal';
import { useShowMessage } from 'hooks/useShowMessage';

export const ProviderContactsEstablishments = () => {
  const showMessage = useShowMessage();
  const { id } = useParams<{ id: string }>();
  const { data: provider } = useUserFindOne(id);
  const { mutateAsync: updateEstablishment } = useEstablishmentUpdateOne();
  const { data: managerEstablishemnt } = useEstablishmentFindMany(
    !!provider && {
      filterObject: {
        'provider.manager': provider?.cognitoUserId,
      },
    }
  );
  const { data: contactEstablishment, isLoading: loadingce } =
    useEstablishmentFindMany(
      !!provider && {
        filterObject: {
          'provider.contacts': provider?.cognitoUserId,
        },
      }
    );
  const { data: tempContact, isLoading: loadingtmp } = useEstablishmentFindMany(
    !!provider && {
      filterObject: {
        'provider.tempContacts': provider?.cognitoUserId,
      },
    }
  );

  const { mutateAsync: sageCreateContact } = useSageCreateContact();

  useSetCrumbs(
    [provider],
    [
      {
        label: 'fournisseurs',
        path: '/providers',
      },
      {
        label: 'contacts',
        path: '/providers/contacts',
      },
      {
        label: provider ? getFullName(provider) : '-',
        path: `/providers/contacts/${id}`,
      },
      {
        label: 'établissements',
        path: `/providers/contacts/${id}/establishments`,
      },
    ]
  );

  const createInGescom = async (
    establishment: IEstablishmentMinifiedUsers,
    user: FlatUser | undefined
  ) => {
    if (user && establishment) {
      await sageCreateContact({
        establishment: establishment.uuid,
        type: 'provider',
        contact: user.cognitoUserId,
      });
      if (user.cognitoUserId) {
        queryClient.refetchQueries({ queryKey: [id] });
      }
      queryClient.refetchQueries({
        queryKey: ['establishments'],
        type: 'active',
      });
      queryClient.refetchQueries({ queryKey: [provider?.cognitoUserId || ''] });
      queryClient.refetchQueries({ queryKey: [user.cognitoUserId] });
      queryClient.refetchQueries({ queryKey: [establishment?.uuid] });
      queryClient.refetchQueries({ queryKey: [id] });
    }
  };

  const addTempContact = async (establishment: IEstablishmentMinifiedUsers) => {
    try {
      const establishmentData = {
        uuid: establishment?.uuid,
        removeTempContacts: { provider: [provider?.cognitoUserId] },
        addContacts: { provider: [provider?.cognitoUserId] },
      };
      showDialogModal({
        closeIcon: true,
        confirmLabel: 'Rattacher',
        cancelLabel: 'Annuler',
        title:
          'Etes-vous sûr de vouloir rattacher cet établissement à ce contact ?',
        text: '',
        beforeValidation: async () => {
          await updateEstablishment(establishmentData as any);
          queryClient.refetchQueries({
            queryKey: [provider?.cognitoUserId || ''],
          });
          queryClient.refetchQueries({ queryKey: [establishment?.uuid] });
          queryClient.refetchQueries({
            queryKey: ['establishments'],
            type: 'active',
          });
        },
      });
    } catch (e) {
      //
    }
  };
  const removeTempContact = async (
    establishment: IEstablishmentMinifiedUsers
  ) => {
    try {
      const establishmentData = {
        uuid: establishment?.uuid,
        removeTempContacts: { provider: [provider?.cognitoUserId] },
      };
      showDialogModal({
        closeIcon: true,
        confirmLabel: 'Refuser le rattachement',
        cancelLabel: 'Annuler',
        title:
          'Etes-vous sûr de vouloir refuser le rattachement de cet établissement à ce contact ?',
        text: '',
        beforeValidation: async () => {
          await updateEstablishment(establishmentData as any);
          queryClient.refetchQueries({
            queryKey: [provider?.cognitoUserId || ''],
          });
          queryClient.refetchQueries({ queryKey: [establishment?.uuid] });
          queryClient.refetchQueries({
            queryKey: ['establishments'],
            type: 'active',
          });
        },
      });
    } catch (e) {
      //
    }
  };
  const removeContact = async (establishment: IEstablishmentMinifiedUsers) => {
    try {
      const establishmentData = {
        uuid: establishment?.uuid,
        removeContacts: { provider: [provider?.cognitoUserId] },
      };
      showDialogModal({
        closeIcon: true,
        confirmLabel: 'Détacher',
        cancelLabel: 'Annuler',
        title:
          'Etes-vous sûr de vouloir détacher le contact de cet établissement ?',
        text: `Êtes-vous sûr de vouloir détacher l'établissement ${getEstablishmentName(
          establishment
        )} du contact fournisseur ${getFullName(provider)} ?`,
        beforeValidation: async () => {
          try {
            await updateEstablishment(establishmentData as any);
            queryClient.refetchQueries({
              queryKey: [provider?.cognitoUserId || ''],
            });
            queryClient.refetchQueries({ queryKey: [establishment?.uuid] });
            queryClient.refetchQueries({
              queryKey: ['establishments'],
              type: 'active',
            });
            showMessage(
              'success',
              `Le contact ${getFullName(
                provider
              )} a bien été détaché de l'établissement ${getEstablishmentName(
                establishment
              )}`
            );
          } catch (e) {
            //
          }
        },
      });
    } catch (e) {
      //
    }
  };
  const loading = loadingce || loadingtmp;
  if (loading) return <SpinnerBox />;
  if (
    managerEstablishemnt?.totalCount === 0 &&
    contactEstablishment?.totalCount === 0 &&
    tempContact?.totalCount === 0
  ) {
    return (
      <Flex flexDirection="column" alignItems="center" p="50px 0">
        <Text variant="h2" color="primary.light" mb="10">
          Aucun établissement rattaché
        </Text>
        <Text color="primary.light" mb={20}>
          Lors de son embarquement, le contact sera rattaché à l’établissement
          qu’il ajoutera. Cependant, vous pouvez le faire à sa place dès
          maintenant.
        </Text>
        <Button
          onClick={() =>
            showLinkEstablishmentToContactProviderModal({
              contact: provider as FlatUser,
            })
          }
        >
          Sélectionner un établissement
        </Button>
      </Flex>
    );
  }
  return (
    <>
      <Text variant="h2" mb={20}>
        Responsable de comptes
      </Text>
      <Text mb={20}>
        Retrouvez ici la liste des établissements fournisseurs pour lesquels le
        contact fournisseur est responsable de comptes.
      </Text>
      {!managerEstablishemnt?.establishments?.length && (
        <Text color="grey.default" mb={20}>
          Aucun responsable de compte
        </Text>
      )}
      {managerEstablishemnt?.establishments?.map(establishment => {
        const checkSageData =
          checkAllStructureCreatedInGescomForContactOnEstablishment(
            establishment?.provider?.sage,
            provider?.sage as ISageReference
          );
        return (
          <React.Fragment key={establishment.uuid}>
            <Box
              display={'inline-flex'}
              justifyContent={'space-between'}
              width={'100%'}
            >
              <Box display={'inline-flex'}>
                <Text mb={2}>{provider ? getFullName(provider) : ''}</Text>
                {checkSageData?.isReady && (
                  <Status
                    marginTop={-2}
                    marginLeft={2}
                    variantColor={'success'}
                  >
                    Créé dans GESCOM
                  </Status>
                )}
                {!checkSageData?.isReady && (
                  <Status
                    marginTop={-2}
                    marginLeft={2}
                    variantColor={'warning'}
                  >
                    {`À créer dans GESCOM ( ${checkSageData?.missingStructure?.map(
                      ms => ms
                    )} )`}
                  </Status>
                )}
              </Box>
              {!checkSageData?.isReady && (
                <Link
                  iconLeft={<EditIcon />}
                  onClick={() =>
                    showDialogModal({
                      confirmLabel: 'Créer',
                      cancelLabel: 'Annuler',
                      title: 'Créer dans GESCOM',
                      text: `Avant de valider la création de ce contact fournisseur pour cet établissement dans GESCOM, merci de vérifier que son Nom, Prénom et Email sont bien renseignés dans sa fiche contact.`,
                      beforeValidation: () =>
                        createInGescom(establishment, provider),
                    })
                  }
                >
                  {`Créer dans GESCOM ( ${checkSageData?.missingStructure?.map(
                    ms => ms
                  )} )`}
                </Link>
              )}
            </Box>
            {!establishment.provider.sage && (
              <Box mb={2}>
                <BlocInformation>
                  <p>
                    Avant de pouvoir créer ce contact dans GESCOM, veuillez
                    créer l'établissement fournisseur dans GESCOM depuis sa
                    fiche et vous assurer que l'établissement est bien rattaché
                    au contact.
                  </p>
                </BlocInformation>
              </Box>
            )}
            <EstablishmentStructuredCard
              mb={20}
              establishment={establishment}
              checkSage
              checkSaleForce
              forRole={'PROVIDER'}
              // disabled for manager
              // renderActions={
              //   <Flex
              //     justifyContent="center"
              //     alignContent="center"
              //     alignItems="center"
              //   >
              //     <Button
              //       onClick={() => removeContact(establishment)}
              //       mr={'10px'}
              //     >
              //       Détacher
              //     </Button>
              //   </Flex>
              // }
            />
          </React.Fragment>
        );
      })}

      <Text variant="h2" mb={20}>
        Contacts fournisseur
      </Text>
      <Text mb={20}>
        Retrouvez ici la liste des établissements fournisseurs pour lesquels le
        contact fournisseur est chargé de mission.
      </Text>
      {tempContact &&
        tempContact?.establishments.length > 0 &&
        tempContact?.establishments.map((esta, i) => (
          <EstablishmentStructuredCard
            key={i}
            mb={20}
            establishment={esta}
            checkSage
            forRole={'PROVIDER'}
            renderActions={
              <Flex
                justifyContent="center"
                alignContent="center"
                alignItems="center"
              >
                <Button onClick={() => addTempContact(esta)} mr={'10px'}>
                  Rattacher
                </Button>
                <Flex
                  justifyContent="center"
                  alignContent="center"
                  alignItems="center"
                >
                  <CloseIcon height={20} width={20} fill={Theme.colors.blue} />
                  <Link onClick={() => removeTempContact(esta)}>Refuser</Link>
                </Flex>
              </Flex>
            }
          />
        ))}
      {!contactEstablishment?.establishments?.length && (
        <Text color="grey.default" mb={20}>
          Aucun chargé de mission
        </Text>
      )}
      {contactEstablishment?.establishments?.map(establishment => {
        const checkSageData =
          checkAllStructureCreatedInGescomForContactOnEstablishment(
            establishment?.provider?.sage,
            provider?.sage as ISageReference
          );
        return (
          <React.Fragment key={establishment.uuid}>
            <Box
              display={'inline-flex'}
              justifyContent={'space-between'}
              width={'100%'}
            >
              <Box display={'inline-flex'}>
                <Text mb={2}>{provider ? getFullName(provider) : ''}</Text>
                {checkSageData?.isReady && (
                  <Status
                    marginTop={-2}
                    marginLeft={2}
                    variantColor={'success'}
                  >
                    Créé dans GESCOM
                  </Status>
                )}
                {!checkSageData?.isReady && (
                  <Status
                    marginTop={-2}
                    marginLeft={2}
                    variantColor={'warning'}
                  >
                    {`À créer dans GESCOM ( ${checkSageData?.missingStructure?.map(
                      ms => ms
                    )} )`}
                  </Status>
                )}
              </Box>
              {establishment.provider.sage && !checkSageData?.isReady && (
                <Link
                  iconLeft={<EditIcon />}
                  onClick={() =>
                    showDialogModal({
                      confirmLabel: 'Créer',
                      cancelLabel: 'Annuler',
                      title: 'Créer dans GESCOM',
                      text: `Avant de valider la création de ce contact fournisseur pour cet établissement dans GESCOM, merci de vérifier que son Nom, Prénom et Email sont bien renseignés dans sa fiche contact.`,
                      beforeValidation: () =>
                        createInGescom(establishment, provider),
                    })
                  }
                >
                  {`Créer dans GESCOM ( ${checkSageData?.missingStructure?.map(
                    ms => ms
                  )} )`}
                </Link>
              )}
            </Box>
            {!establishment.provider.sage && (
              <Box mb={2}>
                <BlocInformation>
                  <p>
                    Avant de pouvoir créer ce contact dans GESCOM, veuillez
                    créer l'établissement fournisseur dans GESCOM depuis sa
                    fiche et vous assurer que l'établissement est bien rattaché
                    au contact.
                  </p>
                </BlocInformation>
              </Box>
            )}
            <EstablishmentStructuredCard
              mb={20}
              establishment={establishment}
              checkSage
              forRole={'PROVIDER'}
              renderActions={
                <Flex
                  justifyContent="center"
                  alignContent="center"
                  alignItems="center"
                >
                  <Button
                    onClick={() => removeContact(establishment)}
                    mr={'10px'}
                  >
                    Détacher
                  </Button>
                </Flex>
              }
            />
          </React.Fragment>
        );
      })}
      <Link
        onClick={() =>
          showLinkEstablishmentToContactProviderModal({
            contact: provider as FlatUser,
          })
        }
        iconLeft={<AddIcon />}
      >
        Rattacher un établissement
      </Link>
    </>
  );
};
