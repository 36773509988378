import {
  IEstablishment,
  IEstablishmentBase,
} from '@freelancelabs/teoreme-commons';
import {
  buildOneLineAddress,
  getEstablishmentName,
  getFirstCaractsEstablisement,
  checkAllStrcutureCreatedInGescom,
} from '@commons';
import { Box, Text } from 'components/ui';
import React from 'react';
import { StructuredCard, StructuredCardProps } from './StructuredCard';
import { FileCard } from './FileCard';
export const EstablishmentStructuredCard = ({
  forRole,
  establishment,
  checkSage,
  checkSaleForce,
  disabledRedirect,
  ...props
}: {
  disabledRedirect?: boolean;
  forRole?: 'CUSTOMER' | 'PROVIDER';
  checkSage?: boolean;
  checkSaleForce?: boolean;
  establishment: Pick<
    IEstablishment,
    | 'address'
    | 'siret'
    | 'vatNumber'
    | 'businessName'
    | 'uuid'
    | 'headquarter'
    | 'identifier'
    | 'registrationFile'
  >;
} & Omit<
  StructuredCardProps,
  'avatarLetter' | 'primaryTopText' | 'secondaryTopText' | 'primaryBottomText'
>) => {
  const sage =
    forRole === 'CUSTOMER'
      ? (establishment as IEstablishmentBase)?.customer?.sage
      : (establishment as IEstablishmentBase)?.provider?.sage;
  const salesForces =
    forRole === 'CUSTOMER'
      ? (establishment as IEstablishmentBase)?.customer?.salesforce
      : (establishment as IEstablishmentBase)?.provider?.salesforce;
  const checkSageData = checkAllStrcutureCreatedInGescom(sage);
  type TypeLink = string | false;
  let link: TypeLink = false;

  const getIdentifierText = () => {
    if (establishment?.siret) return `N° SIRET : ${establishment?.siret}`;
    return `N° D’IMMATRICULATION : ${establishment?.identifier}`;
  };

  const getVatNumber = () => {
    if (establishment?.vatNumber)
      return `- N° TVA : ${establishment?.vatNumber}`;
    return '';
  };

  if (forRole === 'CUSTOMER') {
    link = `/clients/establishments/${establishment?.uuid}/information`;
  }
  if (forRole === 'PROVIDER') {
    link = `/providers/establishments/${establishment?.uuid}/information`;
  }
  const activeRedirect = !disabledRedirect && link;
  const isLock =
    forRole === 'CUSTOMER'
      ? (establishment as IEstablishmentBase)?.customer?.locked
      : (establishment as IEstablishmentBase)?.provider?.locked;
  return (
    <Box style={{ cursor: activeRedirect ? 'pointer' : 'initial' }}>
      <StructuredCard
        isLock={isLock}
        redirect={activeRedirect ? link : false}
        checkSage={checkSage}
        checkSaleForce={checkSaleForce}
        sage={checkSageData}
        salesforce={checkSaleForce ? salesForces : false}
        avatarLetter={getFirstCaractsEstablisement(establishment)}
        primaryTopText={`${getEstablishmentName(establishment)} ${
          establishment?.headquarter ? ' ( siège social ) ' : ''
        }`}
        secondaryTopText={
          establishment?.address && buildOneLineAddress(establishment?.address)
        }
        primaryBottomText={`${getIdentifierText()} ${getVatNumber()}`}
        {...props}
      />
      {establishment && establishment.registrationFile && (
        <>
          <Text variant="h3" my={10}>
            Document D'Immatriculation
          </Text>
          <FileCard
            mb={20}
            fileLocation={establishment.registrationFile.fileLocation || ''}
            fileName={
              establishment.registrationFile.fileName ||
              'justificatif_immatriculation.pdf'
            }
          />
        </>
      )}
    </Box>
  );
};
