import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ProviderContractorDetails } from './Details';
import { ProviderContractorList } from './List';
import { ValidateContractor } from './Validate';
export const Contractors = () => {
  return (
    <Switch>
      <Route exact path="/providers/contractors">
        <Redirect to="/providers/contractors/active" />
      </Route>
      <Route path="/providers/contractors/:filter(active|archived|pending|rejected)">
        <ProviderContractorList />
      </Route>
      <Route path="/providers/contractors/validate/:id">
        <ValidateContractor />
      </Route>
      <Route path="/providers/contractors/:id">
        <ProviderContractorDetails />
      </Route>
    </Switch>
  );
};
