import {
  IEstablishmentFullUsers,
  IEstablishmentMinifiedUsers,
} from '@freelancelabs/teoreme-commons';
import {
  Box,
  Button,
  Flex,
  Text,
  FormControl,
  UserSelectControlled,
} from 'components/ui';
import React from 'react';
import { create } from 'react-modal-promise';
import { ModalFrame, ModalProps } from '../ModalFrame';
import { useForm } from 'react-hook-form';
import { useEstablishmentUpdateOne } from '@commons';
import { queryClient } from '@commons';
type Props = ModalProps & {
  beforeValidation?: () => void;
  establishment: IEstablishmentMinifiedUsers | IEstablishmentFullUsers;
};
type FormValues = {
  contact: string;
};
export const LinkCustomerContactModal = ({
  onResolve,
  isOpen,
  establishment,
}: Props) => {
  const { handleSubmit, control, watch } = useForm<FormValues>({
    defaultValues: {},
  });

  const [loading, setLoading] = React.useState(false);
  const { mutateAsync: updateEstablishment } = useEstablishmentUpdateOne();
  const contact = watch('contact');
  const onSubmit = async (formValues: FormValues) => {
    setLoading(true);
    try {
      const establishmentData = {
        uuid: establishment?.uuid,
        addContacts: { customer: [formValues.contact] },
      };
      await updateEstablishment(establishmentData as any);
      queryClient.refetchQueries({ queryKey: [establishment?.uuid] });
      onResolve(true);
    } catch (err) {
      onResolve(false);
    }
    setLoading(false);
  };

  return (
    <ModalFrame
      isOpen={isOpen}
      onClose={() => onResolve(false)}
      width={550}
      closeIcon
    >
      <Box mt={20}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Text variant="h2" mb={20}>
            Rattacher un contact client
          </Text>

          <FormControl label="Contact client" required>
            <UserSelectControlled
              control={control}
              name="contact"
              role={'CUSTOMER'}
              placeholder="contacts"
            />
          </FormControl>

          <Flex>
            <Button type="submit" isLoading={loading} isDisabled={!contact}>
              Rattacher
            </Button>
          </Flex>
        </form>
      </Box>
    </ModalFrame>
  );
};

export const showLinkCustomerContactModal = create<Props, boolean>(
  LinkCustomerContactModal
);
