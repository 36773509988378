import {
  Link,
  FileInput,
  Box,
  Row,
  FormControl,
  Input,
  Text,
  Flex,
  Button,
  BlocInformation,
  NumberFormInputControlled,
  Radio,
} from 'components/ui';
import {
  cutLongText,
  missionUpdateOne,
  uploadFile,
  queryClient,
  getWorkingDays,
  useGetHolidays,
  getWorkingDaysWithoutHolidays,
  round,
  buildOneLineAddress,
  getDefaultInvoiceAdress,
  getMarginOrMarkup,
  calculateContractorRateFromMarginAndClientRate,
  calculateClientRateFromMarginAndContractorRate,
  isNotEmptyOrNaN,
} from '@commons';
import { isMissionFieldEditable } from 'helpers';
import { useDebounce } from 'hooks/useDebounce';
import { AddIcon, EuroIcon, PercentIcon } from 'components/ui/icons';
import { useForm } from 'react-hook-form';
import React, { useState } from 'react';
import { create } from 'react-modal-promise';
import { ModalFrame, ModalProps } from '../ModalFrame';
import {
  EBillingType,
  EFileType,
  EMarginType,
  EMissionType,
  IBilling,
  IJoinedMission,
} from '@freelancelabs/teoreme-commons';
import { showDialogModal } from 'components/modals/DialogModal';
type FormValues = {
  reference: string;
  estimatedDays: number;
  billing: IBilling;
};
type ImportBDCModalProps = ModalProps & {
  beforeValidation?: () => void;
  mission: IJoinedMission;
};

export const ImportBDCModal: React.FC<
  React.PropsWithChildren<ImportBDCModalProps>
> = ({ onResolve, isOpen, mission }) => {
  const purchaseOrder = mission?.customer?.purchaseOrder?.file;
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState<File | false>(false);
  const [fileError, setFileError] = useState<string | false>(false);
  const [fileUrl, setFileUrl] = useState<string>();

  const { data: holidays } = useGetHolidays();
  const { startAt = new Date(), endAt = new Date() } = mission;
  const missionWorkingDays = getWorkingDays(startAt, endAt);
  const missionWorkingDaysWithoutHolidays = getWorkingDaysWithoutHolidays(
    startAt,
    endAt,
    holidays
  );
  const billingType = mission.billing?.type;
  const {
    control,
    register,
    handleSubmit,
    getValues,
    setValue,
    setError,
    clearErrors,
    watch,
    formState: { errors },
  } = useForm<FormValues & any>({
    defaultValues: {
      reference: mission?.customer?.purchaseOrder?.reference,
      billing: mission.billing,
    },
  });
  const boundMSDelay = 0;
  const margin = useDebounce(watch('billing.margin'), boundMSDelay);
  const clientRate = useDebounce(watch('billing.clientRate'), boundMSDelay);
  const contractorRate = useDebounce(
    watch('billing.contractorRate'),
    boundMSDelay
  );
  const marginType = watch('billing.marginType');
  const marginTypeLabel =
    marginType === EMarginType.MARGIN ? 'Marque' : 'Marge';
  const [fieldBlur, setFieldBlur] = useState<string | false>(false);
  const [marginRounded, setMarginRounded] = React.useState<
    number | string | null | undefined
  >(!isNaN(margin) ? round(margin) : undefined);
  const isDisabled = false;
  const [fieldsForCalculation, setFieldsForCalculation] = React.useState<{
    margin: boolean;
    contractorRate: boolean;
    clientRate: boolean;
  }>({ margin: false, contractorRate: false, clientRate: false });

  React.useEffect(() => {
    if (isNotEmptyOrNaN(clientRate) && isNotEmptyOrNaN(contractorRate)) {
      const resultMargin = getMarginOrMarkup(
        Number(clientRate),
        Number(contractorRate),
        marginType
      );
      setMarginRounded(round(resultMargin));
      setValue('billing.margin', resultMargin);
    }
  }, [marginType]);

  const onImportPo = (fileSelected: File[]) => {
    const bdcFileUrl = fileSelected[0]
      ? URL?.createObjectURL(fileSelected[0])
      : fileUrl;
    setFile(fileSelected[0]);
    setFileUrl(bdcFileUrl);
    setFileError(false);
  };

  const getCalculationFields = () => {
    setFieldsForCalculation({
      margin: isNotEmptyOrNaN(margin),
      contractorRate: isNotEmptyOrNaN(contractorRate),
      clientRate: isNotEmptyOrNaN(clientRate),
    });
  };
  /* Si modification du “tarif jour/montant payé par le client” →
    recalcule automatique du “tarif jour/montant perçu par l’intervenant”
    (pas de modification du taux de marque)
   */
  const recalculateFieldsFromClientRate = (
    newClientRate: string | number | null | undefined
  ) => {
    setValue('billing.clientRate', newClientRate);
    if (
      isNotEmptyOrNaN(newClientRate) &&
      isNotEmptyOrNaN(margin) &&
      fieldsForCalculation.margin
    ) {
      const contractorResult = calculateContractorRateFromMarginAndClientRate(
        Number(newClientRate),
        Number(margin),
        marginType,
        true //round
      );
      const notEqual =
        // eslint-disable-next-line eqeqeq
        Number(contractorResult) != Number(contractorRate);
      //@ts-ignore
      if (notEqual) {
        setValue('billing.contractorRate', contractorResult);
      }
    } else if (
      isNotEmptyOrNaN(newClientRate) &&
      isNotEmptyOrNaN(contractorRate) &&
      fieldsForCalculation.contractorRate
    ) {
      const resultMargin = getMarginOrMarkup(
        Number(newClientRate),
        Number(contractorRate),
        marginType
      );
      setMarginRounded(round(resultMargin));
      setValue('billing.margin', resultMargin);
    }
  };
  const onChangeClientRate = (e: any) => {
    const clientRateValue = isNotEmptyOrNaN(e.target.value)
      ? Number(e.target.value)
      : undefined;
    setValue('billing.clientRate', clientRateValue);
    recalculateFieldsFromClientRate(clientRateValue);
  };
  const onChangeContractorRate = (e: any) => {
    const contractorRateValue = e.target.value
      ? Number(e.target.value)
      : undefined;
    setValue('billing.contractorRate', contractorRateValue);
    recalculateFieldsFromContractorRate(contractorRateValue);
  };

  /* Si modification du “taux de marque” →
   recalcule automatique du ““tarif jour/montant payé par le client”
   (pas de modification du tarif jour/montant perçu par l’intervenant)
   */
  const recalculateFieldsFromMargin = (
    newMargin: string | number | null | undefined
  ) => {
    const marginValue = isNotEmptyOrNaN(newMargin)
      ? Number(newMargin)
      : undefined;
    const marginValueRounded = isNotEmptyOrNaN(newMargin)
      ? round(Number(newMargin))
      : '';
    const nbValue = isNotEmptyOrNaN(marginValueRounded)
      ? Number(marginValueRounded)
      : 0;
    setMarginRounded(marginValueRounded);
    setValue('billing.margin', marginValue);

    if (marginType === EMarginType.MARGIN) {
      if (nbValue >= 100 || nbValue < 0) {
        setError('billing.margin', {
          message: 'La valeur doit être comprise entre 0% et 99%',
        });
        return;
      }
    }
    if (marginType === EMarginType.MARKUP) {
      if (nbValue < 0) {
        setError('billing.margin', {
          message: 'La valeur doit être supérieur à 0%',
        });
        return;
      }
    }
    clearErrors('billing.margin');

    if (
      isNotEmptyOrNaN(marginValue) &&
      isNotEmptyOrNaN(contractorRate) &&
      fieldsForCalculation.contractorRate
    ) {
      const clientResult = calculateClientRateFromMarginAndContractorRate(
        Number(contractorRate),
        Number(marginValue),
        marginType,
        true //round
      );
      const notEqual =
        // eslint-disable-next-line eqeqeq
        round(Number(clientResult)) != round(Number(clientRate));
      //@ts-ignore
      if (notEqual) {
        setValue('billing.clientRate', clientResult);
      }
    } else if (
      isNotEmptyOrNaN(clientRate) &&
      isNotEmptyOrNaN(marginValue) &&
      fieldsForCalculation.clientRate
    ) {
      const contractorResult = calculateContractorRateFromMarginAndClientRate(
        Number(clientRate),
        Number(marginValue),
        marginType,
        true //round
      );
      const notEqual =
        // eslint-disable-next-line eqeqeq
        Number(contractorResult) != Number(contractorRate);
      //@ts-ignore
      if (notEqual) {
        setValue('billing.contractorRate', contractorResult);
      }
    }
  };
  /*Si modification du “tarif jour/montant perçu par l’intervenant”  →
    recalcule automatique du “tarif jour/montant payé par le client”
    (pas de modification du taux de marque)
   */
  const recalculateFieldsFromContractorRate = (
    newContractorRate: string | number | null | undefined
  ) => {
    setValue('billing.contractorRate', newContractorRate);

    if (
      isNotEmptyOrNaN(newContractorRate) &&
      isNotEmptyOrNaN(margin) &&
      fieldsForCalculation.margin
    ) {
      const clientResult = calculateClientRateFromMarginAndContractorRate(
        Number(newContractorRate),
        Number(margin),
        marginType,
        true //round
      );
      const notEqual =
        // eslint-disable-next-line eqeqeq
        Number(clientResult) != Number(clientRate);
      //@ts-ignore
      if (notEqual) {
        setValue('billing.clientRate', clientResult);
      }
    } else if (
      isNotEmptyOrNaN(newContractorRate) &&
      isNotEmptyOrNaN(clientRate) &&
      fieldsForCalculation.clientRate
    ) {
      const resultMargin = getMarginOrMarkup(
        Number(clientRate),
        Number(newContractorRate),
        marginType
      );
      setMarginRounded(round(resultMargin));
      setValue('billing.margin', resultMargin);
    }
  };
  const onBlurContractorRate = (e: any) => {
    const contractorRateValue = e.target.value
      ? Number(e.target.value)
      : undefined;
    recalculateFieldsFromContractorRate(contractorRateValue);
  };
  const onChangeMargin = (e: any) => {
    const marginValue = e.target.value;

    recalculateFieldsFromMargin(marginValue);
  };

  const getMarginWarning = (margin: number) => {
    if (margin !== undefined) {
      if (
        mission?.billingInformation?.missionType?.label ===
          EMissionType.RESOURCE_MANAGEMENT &&
        margin > 7
      ) {
        return 'Le taux saisi semble élevé pour une mission de RM.';
      }
      if (
        mission?.billingInformation?.missionType?.label ===
          EMissionType.CLASSIC &&
        margin < 7
      ) {
        return 'Le taux saisi semble bas pour une mission de TA.';
      }
    }

    return undefined;
  };

  const onSubmit = async (formValues: FormValues) => {
    let onError = false;
    if (!file && !purchaseOrder) {
      onError = true;
      setFileError('Ce champs est requis');
    }

    if (!onError) {
      setLoading(true);
      if (file) {
        try {
          const s3Response = await uploadFile({
            file: file as File,
            fileType: EFileType.PURCHASE_ORDER_CUSTOMER,
            actionType: 'upload',
            missionRef: mission.reference,
          });
          if (!s3Response) throw new Error();
          await missionUpdateOne({
            reference: mission.reference,
            mission: {
              billing: {
                estimatedDays: formValues?.estimatedDays,
                clientRate: formValues?.billing?.clientRate,
                contractorRate: formValues?.billing?.contractorRate,
                margin: formValues?.billing?.margin,
                marginType: formValues?.billing?.marginType,
              },
              // @ts-ignore
              bcc: {
                file: {
                  eTag: s3Response?.eTag,
                  fileLocation: s3Response?.fileLocation,
                },
                reference: formValues?.reference,
              },
            },
          });
          await showDialogModal({
            title: 'Votre BDC client a bien été importé',
            text: "Attention : Les informations de la fiche Mission courante n'ont pas été mises à jour avec les informations contenues dans ce BDC client, merci de vérifier et de procéder si besoin à leur mise à jour manuelle.",
            confirmLabel: "J'ai bien compris",
          });
          // refresh customer contact to fetch GESCOM status
          queryClient.refetchQueries({
            queryKey: [mission.customer?.contact?.cognitoUserId || ''],
          });
          queryClient.refetchQueries({ queryKey: [mission.reference || ''] });
        } catch (e) {
          //
        }

        setLoading(false);
        onResolve(true);
      } else {
        try {
          await missionUpdateOne({
            reference: mission.reference,
            mission: {
              billing:
                billingType === EBillingType.DAY
                  ? { estimatedDays: formValues?.estimatedDays }
                  : undefined,
              // @ts-ignore
              bcc: {
                reference: formValues?.reference,
              },
            },
          });
          setLoading(false);
          // refresh customer contact to fetch GESCOM status
          queryClient.refetchQueries({
            queryKey: [mission.customer?.contact?.cognitoUserId || ''],
          });
          queryClient.refetchQueries({ queryKey: [mission.reference || ''] });
          await showDialogModal({
            title: 'Votre BDC client a bien été importé',
            text: "Attention : Les informations de la fiche Mission courante n'ont pas été mises à jour avec les informations contenues dans ce BDC client, merci de vérifier et de procéder si besoin à leur mise à jour manuelle.",
            confirmLabel: "J'ai bien compris",
          });
        } finally {
          setLoading(false);
          onResolve(true);
        }
      }
    }
  };
  const estimatedDays = watch('estimatedDays');
  return (
    <ModalFrame
      isOpen={isOpen}
      onClose={() => onResolve(false)}
      width={fileUrl ? 1500 : 520}
      closeIcon
    >
      <Flex width={1 / 1}>
        <Box width={fileUrl ? 2 / 5 : 1 / 1} paddingRight={fileUrl ? 40 : 1}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box>
              <Text variant="h2" mb={20}>
                Importer un nouveau BDC client
              </Text>
              {fileUrl && (
                <>
                  <Box mb={-15}>
                    <FormControl label="raison sociale établissement client">
                      <Text>
                        {mission?.customer?.establishment?.businessName ||
                          'N/A'}
                      </Text>
                    </FormControl>
                  </Box>
                  <Box mb={-10}>
                    <FormControl label="N°SIRET / N° de TVA">
                      <Text>
                        {`${
                          mission?.customer?.establishment?.siret ||
                          mission?.customer?.establishment?.identifier
                        } / ${mission?.customer?.establishment?.vatNumber}`}
                      </Text>
                    </FormControl>
                  </Box>
                  <Box mb={-10}>
                    <FormControl label="Adresse de facturation de la mission">
                      <Text>
                        {buildOneLineAddress(
                          getDefaultInvoiceAdress(
                            mission?.customer?.establishment
                          ) || mission?.customer?.establishment?.address,
                          true
                        ) || 'N/A'}
                      </Text>
                    </FormControl>
                  </Box>
                </>
              )}

              <FormControl label="" errorMessage={fileError}>
                <FileInput
                  onSelect={onImportPo}
                  accept=".pdf"
                  subLabel="Format accepté : PDF"
                >
                  <Link iconLeft={<AddIcon />}>
                    {/*@ts-ignore*/}
                    {!file
                      ? purchaseOrder?.fileLocation
                        ? cutLongText(
                            purchaseOrder.fileLocation?.split('/')[
                              purchaseOrder.fileLocation?.split.length + 1
                            ],
                            50
                          )
                        : 'Importer le bon de commande'
                      : file?.name}
                  </Link>
                </FileInput>
              </FormControl>

              {fileUrl && (
                <Box>
                  <FormControl
                    label="référence"
                    required
                    errorMessage={errors?.reference?.message}
                  >
                    <Input
                      isFullWidth
                      type="text"
                      {...register('reference', {
                        required: 'Ce champ est requis',
                      })}
                    />
                  </FormControl>
                </Box>
              )}

              {billingType === EBillingType.DAY && fileUrl && (
                <Box>
                  <BlocInformation>
                    Nombre de jours estimés sans tenir compte des jours fériés :{' '}
                    {missionWorkingDays}
                  </BlocInformation>
                  <BlocInformation>
                    Nombre de jours estimés en tenant compte des jours fériés :{' '}
                    {missionWorkingDaysWithoutHolidays}
                  </BlocInformation>
                  <Box mt={20}>
                    <FormControl
                      label="Nombre de jours commandés"
                      required
                      errorMessage={errors?.estimatedDays?.message}
                    >
                      <NumberFormInputControlled
                        control={control}
                        isFullWidth
                        name="estimatedDays"
                        type="number"
                        maxDecimal={2}
                        step={'0.01'}
                        rules={{ required: 'Ce champ est requis', min: 0.1 }}
                      />
                    </FormControl>
                  </Box>
                  {mission?.billing?.clientRate && (
                    <BlocInformation>
                      Montant HT de la commande :{' '}
                      {round(mission?.billing?.clientRate * estimatedDays)} €
                    </BlocInformation>
                  )}
                </Box>
              )}

              {billingType === EBillingType.FLAT_RATE && fileUrl && (
                <Box>
                  <>
                    <BlocInformation>
                      Montant HT de la commande :{' '}
                      {mission?.billing?.clientRate || 'N/A'} €
                    </BlocInformation>

                    <Row spacing={20}>
                      <FormControl
                        required
                        label="Montant payé par le client (HT)"
                        // @ts-ignore
                        errorMessage={
                          errors?.billing?.clientRate?.type === 'min'
                            ? 'Ne peut pas être inférieur à 1'
                            : // @ts-ignore
                              errors?.billing?.clientRate?.message
                        }
                      >
                        <NumberFormInputControlled
                          isDisabled={
                            isDisabled ||
                            !isMissionFieldEditable(
                              mission,
                              'billing.clientRate'
                            )
                          }
                          control={control}
                          isFullWidth
                          onSelect={() => getCalculationFields()}
                          onChange={(e: any) => onChangeClientRate(e)}
                          name="billing.clientRate"
                          data-cy="mission-clientRate-input"
                          rules={{
                            required: 'Ce champ est requis',
                            min: 1,
                            validate: (value: any) => {
                              if (
                                value &&
                                getValues()['billing.contractorRate']
                              ) {
                                if (
                                  getValues()['billing.contractorRate'] >
                                  Number(value)
                                ) {
                                  return "Le tarif jour payé par l'intervenant ne peut étre supérieur à celui du client.";
                                }
                              }
                              return undefined;
                            },
                          }}
                          icon={<EuroIcon />}
                        />
                      </FormControl>
                      <FormControl
                        required
                        label="Montant perçu par l'intervenant (HT)"
                        // @ts-ignore
                        errorMessage={
                          errors?.billing?.contractorRate?.type === 'min'
                            ? 'Ne peut pas être inférieur à 1'
                            : // @ts-ignore
                              errors?.billing?.contractorRate?.message
                        }
                      >
                        <NumberFormInputControlled
                          isDisabled={
                            isDisabled ||
                            !isMissionFieldEditable(
                              mission,
                              'billing.contractorRate'
                            )
                          }
                          control={control}
                          onSelect={() => getCalculationFields()}
                          onChange={(e: any) => onChangeContractorRate(e)}
                          onBlur={(e: any) => onBlurContractorRate(e)}
                          rules={{
                            validate: (value: any) => {
                              if (value && getValues()['billing.clientRate']) {
                                if (
                                  getValues()['billing.clientRate'] <
                                  Number(value)
                                ) {
                                  return "Le tarif jour payé par l'intervenant ne peut étre supérieur à celui du client.";
                                }
                              }
                              return undefined;
                            },
                            required: 'Ce champ est requis',
                            min: 1,
                          }}
                          isFullWidth
                          name="billing.contractorRate"
                          data-cy="mission-contractorRate-input"
                          icon={<EuroIcon />}
                        />
                      </FormControl>
                    </Row>
                    <FormControl
                      label={`Taux de ${marginTypeLabel}`}
                      errorMessage={errors?.billing?.margin?.message}
                      warningMessage={getMarginWarning(margin)}
                    >
                      <Box>
                        <Input
                          isDisabled={isDisabled}
                          icon={<PercentIcon />}
                          type="number"
                          maxDecimal={2}
                          step="0.01"
                          name="billing.margin.vue"
                          onSelect={() => getCalculationFields()}
                          onChange={(e: any) => onChangeMargin(e)}
                          //@ts-ignore
                          value={marginRounded}
                        />
                        {/* WARNING THIS FIELD USE REAL VALUE OF BILLING.MARGIN WITHOUT ROUNDING FOR HOOK-FORM DO NOT DELETE THIS*/}
                        <Box display={'none'}>
                          <NumberFormInputControlled
                            isDisabled={
                              !isMissionFieldEditable(mission, 'billing.margin')
                            }
                            defaultValue={round(margin)}
                            name="billing.margin"
                            onBlur={() => setFieldBlur('margin')}
                            control={control}
                            onChange={(e: any) =>
                              setValue('billing.margin', e.target.value)
                            }
                            step="any"
                            icon={<PercentIcon />}
                            rules={{
                              validate: (value: number) => {
                                if (marginType === EMarginType.MARGIN) {
                                  if (
                                    Number(value) >= 100 ||
                                    Number(value) < 0
                                  ) {
                                    return 'La valeur doit être comprise entre 0 et 99%';
                                  } else {
                                    return undefined;
                                  }
                                }
                                if (marginType === EMarginType.MARKUP) {
                                  if (Number(value) < 0) {
                                    return 'La valeur doit être supérieur à 0%';
                                  } else {
                                    return undefined;
                                  }
                                }
                              },
                            }}
                          />
                        </Box>
                      </Box>
                    </FormControl>
                  </>

                  <Box mb={0}>
                    <FormControl
                      label="Type de taux"
                      required
                      errorMessage={errors?.billing?.marginType?.message}
                    >
                      {}
                      <>
                        <Radio
                          disabled={
                            !isMissionFieldEditable(
                              mission,
                              'billing.marginType'
                            )
                          }
                          {...register('billing.marginType', {
                            required: 'Ce champ est requis',
                            disabled: !isMissionFieldEditable(
                              mission,
                              'billing.marginType'
                            ),
                          })}
                          // Taux de marque
                          value={EMarginType.MARGIN}
                        >
                          Taux de Marque
                        </Radio>
                        <Radio
                          disabled={
                            !isMissionFieldEditable(
                              mission,
                              'billing.marginType'
                            )
                          }
                          {...register('billing.marginType', {
                            required: 'Ce champ est requis',
                            disabled: !isMissionFieldEditable(
                              mission,
                              'billing.marginType'
                            ),
                          })}
                          // Taux de marge
                          value={EMarginType.MARKUP}
                        >
                          Taux de Marge
                        </Radio>
                      </>
                    </FormControl>
                  </Box>
                </Box>
              )}
            </Box>
            <Box mt={20}>
              <Button isLoading={loading} type="submit">
                Importer
              </Button>
            </Box>
          </form>
        </Box>
        {fileUrl && (
          <Box width={3 / 5} minHeight={900} p={10}>
            <iframe
              src={fileUrl + '#toolbar=0'}
              title="bdc"
              style={{ width: '100%', height: '100%' }}
            ></iframe>
          </Box>
        )}
      </Flex>
    </ModalFrame>
  );
};

export const showImportBDCModal = create<ImportBDCModalProps>(ImportBDCModal);
