import React from 'react';
import {
  Text,
  Form,
  Button,
  DatePickerControlled,
  FormControl,
  Row,
} from 'components/ui';
import { create } from 'react-modal-promise';
import { ModalFrame, ModalProps } from '../ModalFrame';
import { useShowMessage } from 'hooks/useShowMessage';
import { useForm } from 'react-hook-form';
import {
  removeOffsetDate,
  useCraFindMany,
  getHumanDate,
  missionEnd,
} from '@commons';
import {
  IJoinedMission,
  IUpdateMissionData,
  ECraStatus,
  IJoinedCra,
} from '@freelancelabs/teoreme-commons';
import { isAfter, isBefore, isSameDay } from 'date-fns';
import { queryClient } from '@commons';
type FormValues = {
  endDate: Date;
  state: 'DONE';
};
type EndMissionModalProps = ModalProps & {
  beforeValidation?: () => void;
  mission: IJoinedMission;
};

export const EndMissionModal: React.FC<
  React.PropsWithChildren<EndMissionModalProps>
> = ({ onResolve, isOpen, mission }) => {
  const form = useForm<FormValues>({
    defaultValues: {
      endDate: mission?.endAt,
    },
  });
  const {
    handleSubmit,
    formState: { errors },
    control,
    watch,
  } = form;
  const endDate = watch('endDate');
  const { data: craForMission } = useCraFindMany({
    //joinInvoice: true,
    filterObject: {
      mission: mission?.reference,
      $or: [
        //{ state: ECraStatus.REJECTED },
        { state: ECraStatus.VALIDATED },
        { state: ECraStatus.TO_BE_VALIDATED },
      ],
    },
    limit: 1,
    skip: 0,
    sort: '-month',
  });
  const [isLoading, setIsLoading] = React.useState(false);
  const showMessage = useShowMessage();
  const checkAvalaibleToEndMission = () => {
    const avalaibleStates: { avalaible: boolean; text: string | undefined } = {
      avalaible: true,
      text: undefined,
    };
    const smallDate =
      new Date() < new Date(mission?.endAt as Date)
        ? new Date()
        : new Date(mission?.endAt as Date);
    const haveInProgressCra = craForMission?.cras?.find(
      cra => cra?.state === ECraStatus.TO_BE_VALIDATED
    );
    const lastValidatedCra = craForMission?.cras?.sort(
      (a: IJoinedCra, b: IJoinedCra) =>
        new Date(a?.month as Date).getTime() -
        new Date(b?.month as Date).getTime()
    )?.[craForMission?.cras?.length - 1];

    if (haveInProgressCra) {
      avalaibleStates.avalaible = false;
      avalaibleStates.text =
        'Impossible de mettre fin à la mission tant qu’il reste des CRA au statut “à valider” associés à la mission';
    }
    if (
      !isSameDay(endDate, new Date(lastValidatedCra?.month as Date)) &&
      isBefore(endDate, new Date(lastValidatedCra?.month as Date))
    ) {
      avalaibleStates.avalaible = false;
      avalaibleStates.text = `Impossible de terminer la mission avant le dernier CRA validé ( ${getHumanDate(
        lastValidatedCra?.month as Date
      )} ) `;
    }
    if (!isSameDay(endDate, smallDate) && isAfter(endDate, smallDate)) {
      avalaibleStates.avalaible = false;
      avalaibleStates.text = `Impossible de terminer la mission après ${
        new Date() < new Date(mission?.endAt as Date)
          ? 'la date du jour'
          : `la date de fin de mission ${getHumanDate(mission?.endAt as Date)}`
      }  `;
    }

    return avalaibleStates;
  };
  const onSubmit = async (data: IUpdateMissionData) => {
    setIsLoading(true);
    try {
      await missionEnd({
        reference: mission?.reference,
        endDate: removeOffsetDate(endDate),
      });
      showMessage('success', 'La mission à bien été terminée');
      onResolve(true);
    } catch (e) {
      //
    }
    queryClient.refetchQueries({ queryKey: ['Cras'], type: 'active' });
    queryClient.refetchQueries({ queryKey: [mission?.reference] });
    setIsLoading(false);
  };

  return (
    <ModalFrame
      isOpen={isOpen}
      onClose={() => onResolve(false)}
      width={800}
      closeIcon
    >
      <Form
        onSubmit={handleSubmit(onSubmit)}
        mb={20}
        autoComplete="off"
        data-cy="mission-form"
      >
        {/* <ConfirmNavigationModal
          active={isDirty && ignoreNav === false && !isSubmitSuccessful}
        /> */}
        <Text variant="h1" mb={20}>
          Terminer la mission
        </Text>

        <Row spacing={20}>
          <FormControl
            required
            label="Date de fin de mission"
            errorMessage={errors?.endDate?.message}
            data-cy="mission-endAt"
          >
            <DatePickerControlled
              control={control}
              valueName="selected"
              //minDate={cra?.month}
              name="endDate"
              rules={{
                required: 'Ce champs est requis',
                validate: (value: any) => checkAvalaibleToEndMission()?.text,
              }}
            />
          </FormControl>
          <></>
        </Row>
        <Button
          mt={20}
          variant="filled"
          type="submit"
          //isDisabled={!avalaibleToEndMission.avalaible}
          isLoading={isLoading}
          data-cy="save-gescom"
        >
          Terminer la mission
        </Button>
      </Form>
    </ModalFrame>
  );
};

export const showEndMissionModal =
  create<EndMissionModalProps>(EndMissionModal);
