import React from 'react';
import { EServicesCommentList } from '@freelancelabs/teoreme-commons';
import { Box, Text } from 'components/ui';
import { useSetCrumbs } from 'hooks/breadCrumb';
import { useParams } from 'react-router-dom';
import CommentHandler from 'components/CommentsHandler';
import { useUserFindOne, getFullName } from '@commons';

export const Comments = () => {
  const { id } = useParams<{ id: string }>();

  const { data: client } = useUserFindOne(id);
  const comment = client?.customerComment;
  useSetCrumbs(
    [client],
    [
      {
        label: 'clients',
        path: '/clients',
      },
      {
        label: 'contacts',
        path: '/clients/contacts',
      },
      {
        label: client ? getFullName(client) : '-',
        path: `/clients/contacts/${id}`,
      },
      {
        label: 'commentaires',
        path: `/clients/contacts/${id}/comments`,
      },
    ]
  );

  return (
    <Box p={20}>
      <Text variant="h2" mb={20}>
        Commentaires
      </Text>
      {comment && (
        <CommentHandler
          parentServiceUuid={comment}
          serviceName={EServicesCommentList?.CUSTOMER}
        />
      )}
    </Box>
  );
};
