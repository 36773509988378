import { useUserFindOne } from '@commons';
import { Header } from 'components/Header';
import { SideBar } from 'components/SideBar';
import { SideLink } from 'components/SideLink';
import { Avatar, Box, Container, Flex, Separator, Text } from 'components/ui';
import { getFirstCaracts, getFullName } from '@commons';
import * as React from 'react';
import { useParams } from 'react-router-dom';
import { ProviderContactsOnBoardingEstablishments } from './Establishments';
import { ProviderContactsOnBoardingInformation } from './Information';
import { ProviderContactsOnBoardingContractors } from './Contractors';

export type InformationPage = {};

export const ContactProviderOnBoardingDetails: React.FunctionComponent<
  React.PropsWithChildren<InformationPage>
> = () => {
  const { id } = useParams<{ id: string }>();
  const { data: contact } = useUserFindOne(id);
  return (
    <Box>
      <Header>
        <Flex alignItems="center">
          <Avatar text={contact && getFirstCaracts(contact)} />
          <Text ml={10} variant="h1">
            {contact && getFullName(contact)}
          </Text>
        </Flex>
      </Header>
      <Container display="flex" pt={50}>
        <SideBar>
          {contact?.onboardingStep?.step === 'STEP_ONE' && (
            <SideLink
              exact
              href={`/providers/contacts/on-boarding-card/${id}/information`}
            >
              Étape 1 : Informations générales
            </SideLink>
          )}
          {contact?.onboardingStep?.step === 'STEP_TWO' && (
            <SideLink
              exact
              href={`/providers/contacts/on-boarding-card/${id}/establishments`}
            >
              Étape 2 : Etablissements
            </SideLink>
          )}
          {contact?.onboardingStep?.step === 'STEP_THREE' && (
            <SideLink
              exact
              href={`/providers/contacts/on-boarding-card/${id}/contractors`}
            >
              Étape 3 : Intervenants
            </SideLink>
          )}
          <Separator />
        </SideBar>
        <Box flex={1}>
          {contact?.onboardingStep?.step === 'STEP_ONE' && (
            <ProviderContactsOnBoardingInformation />
          )}
          {contact?.onboardingStep?.step === 'STEP_TWO' && (
            <ProviderContactsOnBoardingEstablishments />
          )}
          {contact?.onboardingStep?.step === 'STEP_THREE' && (
            <ProviderContactsOnBoardingContractors />
          )}
        </Box>
        {/* <Box flex={1}>
            {isLoading && <SpinnerBox />}
            {!isLoading && (
              <Switch>
                <Route
                  exact
                  path="/providers/contacts/on-boarding/:id/information"
                >
                  <ProviderContactsOnBoardingInformation />
                </Route>
                <Route
                  exact
                  path="/providers/contacts/on-boarding/:id/establishments"
                >
                  <ProviderContactsOnBoardingEstablishments />
                </Route>
                <Route
                  exact
                  path="/providers/contacts/on-boarding/:id/contractors"
                >
                  <ProviderContactsOnBoardingContractors />
                </Route>
              </Switch>
            )}
          </Box> */}
      </Container>
    </Box>
  );
};
