import { patchCra, uploadFile, queryClient } from '@commons';
import {
  ECraPatchActions,
  EFileType,
  ERejectCraReason,
  ICraPatchParams,
  ICraPatchResponse,
  IJoinedCraWithInvoice,
} from '@freelancelabs/teoreme-commons';
import { CardCra } from 'components/cards';
import { ConfirmNavigationModal } from 'components/modals/ConfirmNavigationModal';
import { Section } from 'components/Section';
import {
  Box,
  Button,
  Flex,
  FormControl,
  Link,
  Radio,
  Text,
} from 'components/ui';
import { EyeIcon, EyeSlashIcon } from 'components/ui/icons';
import { useShowMessage } from 'hooks/useShowMessage';
import React, { useState } from 'react';
type StepTreeFormProps = {
  dataStep: any;
  steps: any;
  cra: IJoinedCraWithInvoice;
  onSubmit?: (data: any) => any | void;
  onChangeStep: () => any | void;
  onClose: () => any | void;
};

export const StepTree = ({
  dataStep,
  steps,
  cra,
  onSubmit: onSubmitParent,
  onChangeStep,
  onClose,
}: StepTreeFormProps) => {
  const showMessage = useShowMessage();
  const [sendProviderNotifications, setSendProviderNotifications] =
    React.useState('false');
  const [showMore, setShowMore] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [responsePatchCra, setResponsePatchCra] = useState<
    false | ICraPatchResponse
  >(false);
  const [isLoading, setIsLoading] = useState(false);
  const [confimNavigation, setConfirmNavigation] = useState(true);
  const allDataSteps = {
    ...steps?.[0]?.data,
    ...steps?.[1]?.data,
  };
  const {
    clientRate,
    contractorRate,
    nbWorkingDays,
    nbFreeDays,
    billFreeDaysToCustomer,
    // proof,
    proofFile,
    action,
    rejectReason,
    rejectMetaData,
    //GESCOM PROBABLY UNDEFINED !
    invoiceFile,
    invoiceFileForm,
    customerInvoiceClientRate,
    customerCreditNoteReference,
  } = allDataSteps;
  // const invoiceCustomer = cra?.invoices?.customer;
  // const invoiceProvider = cra?.invoices?.provider;
  let attachment: any;
  let attachedInvoiceFile: any;
  const handleConfirmPatchCra = async () => {
    setIsLoading(true);
    try {
      if (proofFile) {
        const uploadResponse = await uploadFile({
          file: proofFile,
          fileType: EFileType?.CRA,
          craRef: cra?.refCra,
          missionRef: cra?.mission?.reference,
          actionType: 'upload',
        });
        if (!uploadResponse) return;
        attachment = {
          fileLocation: uploadResponse.fileLocation,
          fileName: uploadResponse.fileName,
          eTag: uploadResponse.eTag,
        };
      }
      if (invoiceFileForm) {
        const uploadInvoiceFileResponse = await uploadFile({
          file: invoiceFileForm,
          fileType: EFileType.TEMPORARY,
          missionRef: cra?.mission?.reference,
          actionType: 'upload',
        });
        if (!uploadInvoiceFileResponse) return;
        attachedInvoiceFile = {
          fileLocation: uploadInvoiceFileResponse.fileLocation,
          fileName: uploadInvoiceFileResponse.fileName,
          eTag: uploadInvoiceFileResponse.eTag,
        };
      }
      const valuesSubmitted: ICraPatchParams = {
        refCra: cra?.refCra,
        action: action,
        customerCreditNoteReference,
        cra: {
          proof: attachment,
          clientRate: clientRate ? Number(clientRate) : undefined,
          contractorRate: contractorRate ? Number(contractorRate) : undefined,
          nbWorkingDays: nbWorkingDays ? Number(nbWorkingDays) : undefined,
          nbFreeDays: nbFreeDays ? Number(nbFreeDays) : undefined,
          billFreeDaysToCustomer: billFreeDaysToCustomer,
        },
        rejectReason: rejectMetaData,
        providerInvoice: attachedInvoiceFile && {
          invoiceFile: attachedInvoiceFile,
        },
        sendProviderNotifications:
          action === ECraPatchActions?.REJECT_CRA
            ? sendProviderNotifications === 'true'
              ? true
              : false
            : undefined,
      };

      await patchCra(valuesSubmitted);
      //setResponsePatchCra(response);
      setConfirmNavigation(false);
      showMessage(
        'success',
        `Le cra est en cours de ${
          action === ECraPatchActions?.FIX_CRA ? 'correction' : 'refus'
        }`
      );
      await queryClient.refetchQueries({ queryKey: ['Cras'], type: 'active' });
      onClose();
      //history?.push(`/delivery/missions/${cra?.mission?.reference}/comments`);
    } catch (e) {
      //
    }
    setIsLoading(false);
  };

  return (
    <Box p={20}>
      <ConfirmNavigationModal active={confimNavigation} />
      <Box hidden={responsePatchCra !== false}>
        <Text mt={20} variant="h1">
          Résumé et Confirmation
        </Text>
        <Box>
          <Box mt={20}>
            <Section variant="h3" title={'Etape 1 :'}>
              <Text
                variant="li"
                style={{
                  listStyleType: 'none',
                  marginLeft: 20,
                  fontWeight: 'bold',
                }}
              >
                Vous avez séléctionné :{' '}
                {action === ECraPatchActions?.FIX_CRA
                  ? 'Corriger le CRA'
                  : 'Refuser le CRA'}
              </Text>
            </Section>
            {/* CAS 2 :  CRA validé (BL créés, facture fournisseur non validé) */}

            {/* CAS 3 : CRA et factures fournisseur validées */}

            {allDataSteps?.action === ECraPatchActions?.REJECT_CRA && (
              <Section variant="h3" title={'Etape 2 ( CRA ) :'}>
                <Text
                  variant="li"
                  style={{
                    listStyleType: 'none',
                    marginLeft: 20,
                    fontWeight: 'bold',
                  }}
                >
                  {`Vous avez renseigné le motif de refus suivant : ${
                    rejectReason === ERejectCraReason?.NON_COMPLIANT_PROOF
                      ? 'Justificatif non conforme'
                      : rejectMetaData
                  }`}
                </Text>
                {customerCreditNoteReference && (
                  <Text
                    variant="li"
                    style={{
                      listStyleType: 'none',
                      marginLeft: 20,
                      fontWeight: 'bold',
                    }}
                  >
                    {`Vous avez renseigné la référence de l'avoir suivante : ${customerCreditNoteReference}`}
                  </Text>
                )}
              </Section>
            )}
            {allDataSteps?.action === ECraPatchActions?.FIX_CRA && (
              <Section variant="h3" title={'Etape 2 ( CRA ) :'}>
                <Text
                  variant="li"
                  style={{
                    listStyleType: 'none',
                    marginLeft: 20,
                    fontWeight: 'bold',
                  }}
                >
                  Vous avez corrigé les valeurs suivantes :
                </Text>
                <Flex ml={20} mb={20} mt={20}>
                  <Box>
                    <Text variant="u">Nombre de jours : </Text>
                  </Box>
                  <Box
                    textAlign={'center'}
                    ml={10}
                    backgroundColor={'rgba(255,0,0,.2)'}
                    pl={10}
                    pr={10}
                  >
                    <Text variant="b">{cra?.nbWorkingDays}</Text>
                  </Box>
                  <Box ml={'5px'} mr={10}>
                    à
                  </Box>
                  <Box
                    textAlign={'center'}
                    backgroundColor={'rgba(76,175,81,.2)'}
                    pl={10}
                    pr={10}
                  >
                    <Text variant="b">{nbWorkingDays}</Text>
                  </Box>
                </Flex>
                {(cra?.nbFreeDays || nbFreeDays) && (
                  <>
                    <Flex ml={20} mb={20} mt={20}>
                      <Box>
                        <Text variant="u">Nombre de jours gratuits: </Text>
                      </Box>
                      <Box
                        textAlign={'center'}
                        ml={10}
                        backgroundColor={'rgba(255,0,0,.2)'}
                        pl={10}
                        pr={10}
                      >
                        <Text variant="b">{cra?.nbFreeDays ?? 0}</Text>
                      </Box>
                      <Box ml={'5px'} mr={10}>
                        à
                      </Box>
                      <Box
                        textAlign={'center'}
                        backgroundColor={'rgba(76,175,81,.2)'}
                        pl={10}
                        pr={10}
                      >
                        <Text variant="b">{nbFreeDays}</Text>
                      </Box>
                    </Flex>
                    <Flex ml={20} mb={20} mt={20}>
                      <Box>
                        <Text variant="u">Facturation jours gratuits: </Text>
                      </Box>
                      <Box
                        textAlign={'center'}
                        ml={10}
                        backgroundColor={'rgba(255,0,0,.2)'}
                        pl={10}
                        pr={10}
                      >
                        <Text variant="b">
                          {cra?.billFreeDaysToCustomer
                            ? 'Payé par le client'
                            : 'Payé par le Fournisseur'}
                        </Text>
                      </Box>
                      <Box ml={'5px'} mr={10}>
                        à
                      </Box>
                      <Box
                        textAlign={'center'}
                        backgroundColor={'rgba(76,175,81,.2)'}
                        pl={10}
                        pr={10}
                      >
                        <Text variant="b">
                          {billFreeDaysToCustomer
                            ? 'Payé par le client'
                            : 'Payé par le Fournisseur'}
                        </Text>
                      </Box>
                    </Flex>
                  </>
                )}
                <Flex ml={20} mb={20}>
                  <Box>
                    <Text variant="u">Tarif jour payé par le client: </Text>
                  </Box>
                  <Box
                    textAlign={'center'}
                    ml={10}
                    backgroundColor={'rgba(255,0,0,.2)'}
                    pl={10}
                    pr={10}
                  >
                    <Text variant="b">{customerInvoiceClientRate}</Text>
                  </Box>
                  <Box ml={'5px'} mr={10}>
                    à
                  </Box>
                  <Box
                    textAlign={'center'}
                    backgroundColor={'rgba(76,175,81,.2)'}
                    pl={10}
                    pr={10}
                  >
                    <Text variant="b">{clientRate}</Text>
                  </Box>
                </Flex>
                <Flex ml={20} mb={20}>
                  <Box>
                    <Text variant="u">Tarif jour payé à l'intervenant: </Text>
                  </Box>
                  <Box
                    textAlign={'center'}
                    ml={10}
                    backgroundColor={'rgba(255,0,0,.2)'}
                    pl={10}
                    pr={10}
                  >
                    <Text variant="b">{cra?.contractorRate}</Text>
                  </Box>
                  <Box ml={'5px'} mr={10}>
                    à
                  </Box>
                  <Box
                    textAlign={'center'}
                    backgroundColor={'rgba(76,175,81,.2)'}
                    pl={10}
                    pr={10}
                  >
                    <Text variant="b">{contractorRate}</Text>
                  </Box>
                </Flex>
                <Flex ml={20} mb={20}>
                  <Box>
                    <Text variant="u">Justificatif: </Text>
                  </Box>
                  <Box
                    textAlign={'center'}
                    ml={10}
                    backgroundColor={'rgba(255,0,0,.2)'}
                    pl={10}
                    pr={10}
                  >
                    <Text variant="b">{cra?.proof?.fileName || 'N/A'}</Text>
                  </Box>
                  <Box ml={'5px'} mr={10}>
                    à
                  </Box>
                  <Box
                    textAlign={'center'}
                    backgroundColor={'rgba(76,175,81,.2)'}
                    pl={10}
                    pr={10}
                  >
                    <Text variant="b">
                      {proofFile?.name || cra?.proof?.fileName || 'N/A'}
                    </Text>
                  </Box>
                </Flex>
                <Flex ml={20} mb={20}>
                  <Box>
                    <Text variant="u">Référence de l'avoir : </Text>
                  </Box>
                  {/* <Box
                  textAlign={'center'}
                  ml={10}
                  backgroundColor={'rgba(255,0,0,.2)'}
                  pl={10}
                  pr={10}
                >
                  <Text variant="b">{cra?.proof?.fileName || 'N/A'}</Text>
                </Box>
                <Box ml={'5px'} mr={10}>
                  à
                </Box> */}
                  <Box
                    textAlign={'center'}
                    backgroundColor={'rgba(76,175,81,.2)'}
                    pl={10}
                    pr={10}
                    ml={10}
                  >
                    <Text variant="b">
                      {customerCreditNoteReference || 'N/A'}
                    </Text>
                  </Box>
                </Flex>
                {invoiceFile && (
                  <Flex ml={20} mb={20}>
                    <Box>
                      <Text variant="u">
                        Facture fournisseur correspondante:{' '}
                      </Text>
                    </Box>
                    <Box
                      textAlign={'center'}
                      ml={10}
                      backgroundColor={'rgba(255,0,0,.2)'}
                      pl={10}
                      pr={10}
                    >
                      <Text variant="b">{invoiceFile?.fileName || 'N/A'}</Text>
                    </Box>
                    <Box ml={'5px'} mr={10}>
                      à
                    </Box>
                    <Box
                      textAlign={'center'}
                      backgroundColor={'rgba(76,175,81,.2)'}
                      pl={10}
                      pr={10}
                    >
                      <Text variant="b">
                        {invoiceFileForm?.name ||
                          invoiceFile?.fileName ||
                          'N/A'}
                      </Text>
                    </Box>
                  </Flex>
                )}
              </Section>
            )}
            {action === ECraPatchActions?.REJECT_CRA && (
              <Box mb={20} p={20}>
                <FormControl
                  label="Souhaitez vous envoyer les notifications au fournisseur ? "
                  required
                >
                  <Flex>
                    <Radio
                      checked={sendProviderNotifications === 'true'}
                      value={'true'}
                      onClick={() => setSendProviderNotifications('true')}
                    >
                      Oui
                    </Radio>
                    <Radio
                      checked={sendProviderNotifications === 'false'}
                      value={'false'}
                      onClick={() => setSendProviderNotifications('false')}
                    >
                      Non
                    </Radio>
                  </Flex>
                </FormControl>
              </Box>
            )}
          </Box>
        </Box>

        <Box mt={20} mb={20}>
          <Link
            onClick={() => setShowMore(!showMore)}
            iconLeft={showMore ? <EyeSlashIcon /> : <EyeIcon />}
          >
            {showMore
              ? 'Masquer toutes les informations initiales du cra'
              : 'Voir toutes les informations initiales du cra'}
          </Link>
        </Box>
        {showMore && (
          <CardCra cra={cra} displayGescom displayInvoice displayMission />
        )}
        <Flex justifyContent={'flex-end'} width={1 / 1} mb={20} mr={20}>
          <Button mr={20} type="button" onClick={() => onChangeStep()}>
            Précédent
          </Button>
          <Button
            isLoading={isLoading}
            onClick={() => handleConfirmPatchCra()}
            type="button"
          >
            Confirmer
          </Button>
        </Flex>
      </Box>
      <Box hidden={!responsePatchCra}>
        <Text mt={20} mb={20} variant="h1">
          Résumé
        </Text>
        {/* <Flex display={'inline-flex'} flexWrap={'wrap'}>
          {Object.keys(responsePatchCra)?.length === 1 && (
            <Flex display={'inline-flex'} flexWrap={'wrap'}>
              <Flex
                mt={20}
                width={1 / 1}
                display={'inline-flex'}
                flexWrap={'wrap'}
                alignContent={'center'}
                alignItems={'center'}
              >
                <Text variant="b">
                  - Le CRA à été{' '}
                  {action === ECraPatchActions?.FIX_CRA
                    ? 'mis à jour'
                    : 'refusé'}{' '}
                </Text>
                <Box ml={'5px'} backgroundColor="green" borderRadius={'50%'}>
                  <ValidateIcon height={20} fill="white" />
                </Box>
              </Flex>

              {cra?.invoices?.provider?.sage?.documentType ===
                EInvoiceDocumentType?.DELIVERY_ORDER && (
                <Flex
                  mt={20}
                  width={1 / 1}
                  display={'inline-flex'}
                  flexWrap={'wrap'}
                  alignContent={'center'}
                  alignItems={'center'}
                >
                  <Text variant="b">
                    - Le bon de livraison fournisseur référence :{' '}
                    {cra?.invoices?.provider?.sage?.invoiceId} à été{' '}
                    {action === ECraPatchActions?.FIX_CRA
                      ? 'mis à jour'
                      : 'supprimé'}{' '}
                    dans GESCOM.
                  </Text>
                  <Box ml={'5px'} backgroundColor="green" borderRadius={'50%'}>
                    <ValidateIcon height={20} fill="white" />
                  </Box>
                </Flex>
              )}
            </Flex>
          )}
          {responsePatchCra && responsePatchCra?.newCustomerInvoice && (
            <Flex
              display={'inline-flex'}
              flexWrap={'wrap'}
              width={1 / 1}
              alignContent={'center'}
              alignItems={'center'}
              mb={20}
            >
              <Text>- Une nouvelle facture client à été créée. &nbsp;</Text>
              <ALink
                ml={'5px'}
                href={`/orders/invoices/customer/time-spent/${responsePatchCra?.newCustomerInvoice?.uuid}`}
                target="_blank"
              >
                <Link>Voir la nouvelle facture client </Link>
              </ALink>
            </Flex>
          )}
          {responsePatchCra && responsePatchCra?.newProviderInvoice && (
            <Flex
              display={'inline-flex'}
              flexWrap={'wrap'}
              width={1 / 1}
              alignContent={'center'}
              alignItems={'center'}
              mb={20}
            >
              <Text>
                - Une nouvelle facture fournisseur à été créée. &nbsp;
              </Text>
              <ALink
                ml={'5px'}
                href={`/orders/invoices/provider/time-spent/${responsePatchCra?.newProviderInvoice?.uuid}`}
                target="_blank"
              >
                <Link>Voir la nouvelle facture fournisseur</Link>
              </ALink>
            </Flex>
          )}
          {responsePatchCra && responsePatchCra?.customerCreditNote && (
            <Flex
              display={'inline-flex'}
              flexWrap={'wrap'}
              width={1 / 1}
              alignContent={'center'}
              alignItems={'center'}
              mb={20}
            >
              <Text>
                - Un avoir client à été créé, la facture initiale est passée au
                statut 'Annulée'. &nbsp;
              </Text>
              <ALink
                ml={'5px'}
                href={`/orders/invoices/customer/time-spent/${responsePatchCra?.customerCreditNote?.uuid}`}
                target="_blank"
              >
                <Link>Voir l'avoir client</Link>
              </ALink>
            </Flex>
          )}
          {responsePatchCra && responsePatchCra?.providerCreditNote && (
            <Flex
              display={'inline-flex'}
              flexWrap={'wrap'}
              width={1 / 1}
              alignContent={'center'}
              alignItems={'center'}
              mb={20}
            >
              <Text>
                - Un avoir fournisseur à été créé, la facture initiale est
                passée au statut 'Annulé'. &nbsp;
              </Text>
              <ALink
                ml={'5px'}
                href={`/orders/invoices/provider/time-spent/${responsePatchCra?.providerCreditNote?.uuid}`}
                target="_blank"
              >
                <Link>Voir l'avoir fournisseur</Link>
              </ALink>
            </Flex>
          )}
        </Flex> */}
        <Flex justifyContent={'flex-end'} width={1 / 1} mt={20} mb={20} mr={20}>
          <Button onClick={() => onClose()} type="button">
            Fermer
          </Button>
        </Flex>
      </Box>
    </Box>
  );
};
