import {
  ECraStatus,
  IJoinedCraWithInvoice,
  IJoinedCra,
  ICra,
  EContractState,
} from '@freelancelabs/teoreme-commons';

// menuitems providers contact table
export const canDisplayCorrectRejectButtdon = (
  cra: ICra | IJoinedCra | IJoinedCraWithInvoice
) => {
  return cra.state === ECraStatus.VALIDATED;
};

export const canDisplayOnBoardingButton = (contact: any) => {
  return contact?.isConfirmed && !contact?.onboardingStep?.done;
};

export const canDisplayResendContactInvicationButton = (contact: any) => {
  return !contact.isConfirmed;
};

export const canDisplayValidateContactButton = (contact: any) => {
  return (
    contact.onboardingStep &&
    contact.onboardingStep.done &&
    !contact.onboardingStep?.ableToAccessSite
  );
};

export const checkDisplayProviderContactMenuList = (contact: any) => {
  return (
    canDisplayOnBoardingButton(contact) ||
    canDisplayValidateContactButton(contact) ||
    canDisplayResendContactInvicationButton(contact)
  );
};

// menuitems providers contract table

export const canDisplayResendContractButton = (contract: any) => {
  return (
    contract.state === EContractState.TO_SIGN_PROVIDER ||
    contract.state === EContractState.SIGNED_BY_PROVIDER
  );
};

export const canDisplayResiliationRequestButton = (contract: any) => {
  return (
    contract.state === EContractState.ARCHIVED &&
    contract?.cancelAttachment?.fileLocation
  );
};

export const canDisplayTerminateContractButton = (contract: any) => {
  return contract.state === EContractState.DONE;
};

export const canDisplayCancelContractButton = (contract: any) => {
  return contract.state === EContractState.SIGNED_BY_PROVIDER;
};

export const canDisplayDeleteContractButton = (contract: any) => {
  return contract.state === EContractState.TO_SIGN_PROVIDER;
};

// CONTRACT SHOW MENUITEM
export const checkDisplayProviderContractMenuList = (contract: any) => {
  return (
    canDisplayResendContractButton(contract) ||
    canDisplayResiliationRequestButton(contract) ||
    canDisplayTerminateContractButton(contract) ||
    canDisplayCancelContractButton(contract) ||
    canDisplayDeleteContractButton(contract)
  );
};
