import { EMissionStatus, IJoinedMission } from '@freelancelabs/teoreme-commons';
import { Box, Button, Flex, Text } from 'components/ui';
import { useSetCrumbs } from 'hooks/breadCrumb';
import { useGetMission } from 'hooks/missionHooks';
import React from 'react';
import { MilestonesMissionList } from './List';
import { showCreateOrUpdateMileStoneModal } from 'components/modals/activities/CreateOrUpdateMileStoneModal';

export const MissionMileStones = () => {
  const { mission, reference } = useGetMission();

  useSetCrumbs(
    [],
    [
      { label: 'prestations', path: '/delivery' },
      { label: 'missions', path: '/delivery/missions' },
      {
        label: mission?.displayReference || 'nouvelle mission',
        path: `/delivery/missions/${reference}`,
      },
      {
        label: 'Jalons',
        path: `/delivery/missions/${reference}/milestones`,
      },
    ]
  );
  return (
    <Box>
      <Flex justifyContent={'space-between'}>
        <Text variant="h2">Jalons</Text>
        <Button
          isDisabled={
            mission?.status !== EMissionStatus.VALIDATED &&
            mission?.status !== EMissionStatus?.TERMINATED
          }
          onClick={() =>
            showCreateOrUpdateMileStoneModal({
              method: 'CREATE',
              mission: mission as IJoinedMission,
            })
          }
        >
          Créer un jalon
        </Button>
      </Flex>

      {mission && <MilestonesMissionList mission={mission as IJoinedMission} />}
    </Box>
  );
};
