import {
  EFileType,
  IJoinedContractor,
  IJoinedContractorScopedData,
} from '@freelancelabs/teoreme-commons';
import { contractorUpdateOne, dpaeCreateOne, dpaeUpdateOne } from '@commons';
import { uploadFile, contractorNeedDpae } from '@commons';
import { FileInput, Link, SpinnerBox, Text } from 'components/ui';
import { AddIcon } from 'components/ui/icons';
import React, { useState } from 'react';
import { queryClient } from '@commons';
import { DpaeCard } from './DpaeCard';
import { Card } from 'components/ui/Card';

export const DpaeEditor = ({
  scopedData,
  contractor,
  isDisabled,
}: {
  contractor: IJoinedContractor;
  scopedData: IJoinedContractorScopedData;
  isDisabled?: boolean;
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [uploadLoading, setUploadLoading] = useState(false);
  const [createDpaeLoading, setCreateDpaeLoading] = useState(false);
  const [updateDpaeLoading, setUpdateDpaeLoading] = useState(false);
  const [contractorLoading, setContractorLoading] = useState(false);
  const isEmployee = contractorNeedDpae(scopedData);
  const dpae = scopedData?.dpae;
  const establishment = scopedData?.establishment;

  const addDpae = async () => {
    // type guard
    if (!contractor || !establishment) return;
    setCreateDpaeLoading(true);
    setContractorLoading(true);
    const dpae = await dpaeCreateOne({
      dpae: {
        contractor: contractor?.uuid,
        establishment: establishment.uuid,
      },
    });
    await contractorUpdateOne({
      uuid: contractor.uuid,
      scopedData: [
        {
          establishment: establishment?.uuid,
          dpae: dpae.uuid,
        },
      ],
    });
    queryClient.refetchQueries({ queryKey: [contractor.uuid] });
    setCreateDpaeLoading(false);
    setContractorLoading(false);
    return dpae;
  };

  const onChangeDpae = async (files: File[]) => {
    const dpae = await addDpae();
    await onDrop(files, dpae);
  };

  const onDrop = async (files: File[], localDpae?: any) => {
    setUploadLoading(true);
    setUpdateDpaeLoading(true);
    // type guard
    if (!contractor || !establishment || !dpae?.uuid) return;

    const file = files[0];
    if (!file) return;

    const uploadResponse = await uploadFile({
      file,
      fileType: EFileType.DPAE_FILE,
      establishment: establishment?.siren || '',
      contractor: contractor?.uuid,
      actionType: 'upload',
    });

    if (!uploadResponse) return;

    const attachment = {
      fileLocation: uploadResponse.fileLocation,
      fileName: uploadResponse.fileName,
      eTag: uploadResponse.eTag,
    };

    await dpaeUpdateOne({
      uuid: localDpae?.uuid || dpae.uuid,
      dpae: {
        attachment,
      },
    });
    queryClient.refetchQueries({ queryKey: [contractor.uuid] });
    setUpdateDpaeLoading(false);
    setUploadLoading(false);
  };

  if (!contractor || !establishment) return null;
  return (
    <>
      {(updateDpaeLoading || contractorLoading || createDpaeLoading) && (
        <SpinnerBox height={92} margin="auto" />
      )}

      {!isEmployee &&
        !updateDpaeLoading &&
        !contractorLoading &&
        !createDpaeLoading && (
          <Card p={40} display="flex" alignContent="center" alignItems="center">
            <Text variant="disabled" textAlign="center" margin="auto">
              DPAE non nécessaire
            </Text>
          </Card>
        )}

      {isEmployee &&
        dpae &&
        !updateDpaeLoading &&
        !contractorLoading &&
        !createDpaeLoading && (
          <>
            {!dpae?.attachment && !isDisabled && (
              <FileInput
                onSelect={onDrop}
                mb={20}
                accept=".pdf"
                subLabel="Format accepté : PDF"
              >
                <Link iconLeft={<AddIcon />}>importer la DPAE</Link>
              </FileInput>
            )}

            {!!dpae.attachment && (
              <DpaeCard
                contractor={contractor}
                dpae={dpae}
                establishment={establishment}
                onNewDpae={onChangeDpae}
                isDisabled={isDisabled}
              />
            )}
          </>
        )}
    </>
  );
};
