import { commentFindMany, commentManage } from '../routes';
import { useQuery, keepPreviousData } from '@tanstack/react-query';

export const useCommentFindMany = (variables?: any) => {
  return useQuery({
    queryKey: ['comments', variables],
    queryFn: async () => commentFindMany(variables),
    enabled: variables && variables.filterObject ? true : false,
    placeholderData: keepPreviousData,
  });
};

export const useCommentManage = (variables?: any) => {
  return useQuery({
    queryKey: ['comment-manage', variables],
    queryFn: async () => commentManage(variables),
    enabled: variables ? true : false,
  });
};
