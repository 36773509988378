import {
  getFullName,
  getTradeNameSafe,
  displayMonth,
  MAX_CUSTOMER_REFERENCE_FIELD_LENGTH,
  getCustomerRefPattern,
} from '@commons';
import {
  IJoinedCraWithInvoice,
  ICra,
  IMission,
  IMinifiedUser,
  IJoinedInvoice,
  EMissionType,
  IContractor,
  IEstablishment,
  ECustomerReferencePatternVars,
  IJoinedCra,
} from '@freelancelabs/teoreme-commons';
import {
  ALink,
  Link,
  Text,
  Flex,
  Box,
  Button,
  FormControl,
  Input,
  LabelField,
  Radio,
} from 'components/ui';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { create } from 'react-modal-promise';
import { ModalFrame, ModalProps } from '../ModalFrame';
import { queryClient } from '@commons';
type Props = ModalProps & {
  beforeValidation?: () => void;
  invoice?: IJoinedInvoice; // REQUIRED FROM INVOICE !!
  mission:
    | IMission
    | (Omit<IMission, 'accountManager'> & { accountManager: IMinifiedUser });
  cra: IJoinedCraWithInvoice | ICra;
  customerReference: string;
  delivreyOrders: { bdlProvider: boolean; bdlCustomer: boolean };
  refetchQueryKey: string;
};
type FormValues = any;
export const UpdateInvoiceReferenceModal = ({
  onResolve,
  invoice,
  mission,
  isOpen,
  cra,
  customerReference,
  delivreyOrders,
  refetchQueryKey,
}: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [billFreeDaysToCustomer, setBillFreeDaysToCustomer] = useState(false);
  const allowFreeDays = cra?.nbFreeDays && cra?.nbFreeDays > 0;
  const customerRefPattern =
    (cra as IJoinedCra)?.mission?.customer?.billingOptions
      ?.customerRefPattern ||
    (cra as IJoinedCra)?.estCustomer?.customer?.billingOptions
      ?.customerRefPattern;
  const craDateMonth = new Date(cra?.month);
  const customerRef = getCustomerRefPattern(customerRefPattern, {
    [ECustomerReferencePatternVars.CRA_FULL_YEAR]: craDateMonth
      ?.getUTCFullYear()
      ?.toString(),
    [ECustomerReferencePatternVars.CRA_YEAR]: craDateMonth
      ?.getUTCFullYear()
      ?.toString()
      ?.substring(2),

    [ECustomerReferencePatternVars.CRA_MONTH]:
      craDateMonth?.getUTCMonth() + 1 < 10
        ? `0${craDateMonth?.getUTCMonth() + 1}`
        : `${craDateMonth?.getUTCMonth() + 1}`,
    [ECustomerReferencePatternVars.MISSION_REF]:
      //@ts-ignore
      cra?.mission?.displayReference,
    [ECustomerReferencePatternVars.CUSTOMER_REF]: customerReference,
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<FormValues>({
    defaultValues: {
      customerReference: customerRef || customerReference,
    },
  });
  const customCustomerReference = watch('customerReference');
  const onSubmit = async () => {
    setIsLoading(true);
    const resolveObject = {
      customCustomerReference,
      billFreeDaysToCustomer: allowFreeDays
        ? billFreeDaysToCustomer
        : undefined,
    };
    await onResolve(resolveObject);
    setIsLoading(false);
  };
  const checkAvalaibleCreate = (): {
    estProvider: IEstablishment;
    estCustomer: IEstablishment;
    contractor: IContractor;
    contractorReady: boolean;
    estProviderReady: boolean;
    estCustomerReady: boolean;
    ready: boolean;
  } => {
    let contractor;
    const structure = mission?.billingInformation?.structure;
    const labelType =
      mission?.billingInformation?.missionType?.label ===
      EMissionType.RESOURCE_MANAGEMENT
        ? 'RM'
        : 'CL';
    let estProvider;
    let estCustomer;

    if (invoice) {
      estProvider = invoice?.estProvider;
      estCustomer = invoice?.estCustomer;
      contractor = invoice?.contractor;
    } else {
      estProvider = cra?.estProvider;
      estCustomer = cra?.estCustomer;
      contractor = cra?.contractor;
    }
    const contractorReady = (
      contractor as IContractor
    )?.sage?.contractorIds?.find(
      sage => sage?.structure === structure && sage?.type === labelType
    )
      ? true
      : false;
    //@ts-ignore
    const estProviderReady = estProvider?.provider?.sage?.references?.find(
      //@ts-ignore
      sage => sage?.structure === structure
    )
      ? true
      : false;
    //@ts-ignore
    const estCustomerReady = estCustomer?.customer?.sage?.references?.find(
      //@ts-ignore
      sage => sage?.structure === structure
    )
      ? true
      : false;
    return {
      estProvider: estProvider as IEstablishment,
      estCustomer: estCustomer as IEstablishment,
      contractor: contractor as IContractor,
      contractorReady,
      estProviderReady,
      estCustomerReady,
      ready: contractorReady && estProviderReady && estCustomerReady,
    };
  };
  const avalaibleStatusToCreate = checkAvalaibleCreate();
  if (cra?.nbWorkingDays === 0) {
    return (
      <ModalFrame
        isOpen={true}
        onClose={() => onResolve(false)}
        width={550}
        layer={150}
        closeIcon
      >
        <Box>
          <Text variant="h1" mb={20}>
            Bons de livraison et factures
          </Text>
          <Text variant="p">
            Les CRA soumis avec 0 jour ne généreront pas de factures Connecteed
            ni de bon de livraison dans GESCOM.
          </Text>
        </Box>
        <Flex mt={20} alignItems="center">
          <Button
            type="button"
            onClick={() => {
              queryClient.refetchQueries({
                queryKey: [refetchQueryKey],
                type: 'active',
              });
              onResolve(customerReference);
            }}
          >
            Valider le CRA
          </Button>
        </Flex>
      </ModalFrame>
    );
  }
  if (avalaibleStatusToCreate?.ready) {
    const month = displayMonth(cra?.month);
    return (
      <ModalFrame
        isOpen={true}
        onClose={() => onResolve(false)}
        width={550}
        closeIcon
      >
        <Box>
          <Text variant="h1" mb={20}>
            Référence client du mois ({month})
          </Text>
          <Text variant="p" mb={20}>
            La référence client ci-dessous sera indiqué sur la facture client
            générée.
          </Text>
          <LabelField mb={20} label="CRA concerné :" value={cra?.refCra} />
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormControl
              required
              label="Référence client"
              errorMessage={
                errors?.customerReference?.type === 'maxLength'
                  ? `Veuillez saisir moins de ${MAX_CUSTOMER_REFERENCE_FIELD_LENGTH} caractères`
                  : errors?.customerReference?.message
              }
            >
              <Input
                isFullWidth
                type="text"
                {...register('customerReference', {
                  required: 'Ce champs est requis',
                  maxLength: MAX_CUSTOMER_REFERENCE_FIELD_LENGTH,
                })}
              />
            </FormControl>
            {allowFreeDays && (
              <Box width={12 / 12}>
                <FormControl
                  required
                  label={'Facturation de la Gratuité sur ce CRA ?'}
                  errorMessage={errors?.billingInformation?.structure?.message}
                >
                  <Box mt={20}>
                    <Radio
                      {...register('billFreeDaysToCustomer')}
                      value={'true'}
                      onClick={e => setBillFreeDaysToCustomer(true)}
                    >
                      Facturer {cra?.nbWorkingDays} jours au client (pas de
                      remise)
                    </Radio>
                    <Radio
                      {...register('billFreeDaysToCustomer')}
                      value={'false'}
                      onClick={e => setBillFreeDaysToCustomer(false)}
                      checked={!billFreeDaysToCustomer}
                    >
                      Facturer{' '}
                      {(cra?.nbWorkingDays ?? 0) - (cra?.nbFreeDays ?? 0)} jours
                      au client (remise de {cra?.nbFreeDays ?? 0} jours)
                    </Radio>
                  </Box>
                </FormControl>
              </Box>
            )}
            <Flex alignItems="center">
              <Button type="submit" isLoading={isLoading}>
                Valider le cra
              </Button>
            </Flex>
          </form>
        </Box>
      </ModalFrame>
    );
  }
  return (
    <ModalFrame
      isOpen={true}
      onClose={() => onResolve(false)}
      width={670}
      closeIcon
    >
      <Box>
        <Text variant="h1" mb={20}>
          Impossible de créer le bon de livraison
        </Text>
        <Text>
          Ce bon de livraison ne peut être crée car certains liens avec la
          Gescom ne sont pas créés. Veuillez mettre à jour les liens Gescom
          suivants avant de crée ce bon de livraison.
          <Box mt={20} ml={20}>
            <ul>
              {!avalaibleStatusToCreate?.contractorReady && (
                <li>
                  <Flex>
                    L'intervenant &nbsp;
                    <ALink
                      ml={'5px'}
                      href={`/providers/contractors/${avalaibleStatusToCreate?.contractor?.uuid}`}
                      target="_blank"
                    >
                      <Link>
                        {getFullName(avalaibleStatusToCreate?.contractor)}
                      </Link>
                    </ALink>
                  </Flex>
                </li>
              )}
              {!avalaibleStatusToCreate?.estProviderReady && (
                <li>
                  <Flex>
                    L'établissement fournisseur &nbsp;
                    <ALink
                      ml={'5px'}
                      href={`/providers/establishments/${avalaibleStatusToCreate?.estProvider?.uuid}/information`}
                      target="_blank"
                    >
                      <Link>
                        {getTradeNameSafe(avalaibleStatusToCreate?.estProvider)}
                      </Link>
                    </ALink>
                  </Flex>
                </li>
              )}
              {!avalaibleStatusToCreate?.estCustomerReady && (
                <li>
                  <Flex>
                    L'établissement client &nbsp;
                    <ALink
                      ml={'5px'}
                      href={`/clients/establishments/${avalaibleStatusToCreate?.estCustomer?.uuid}/information`}
                      target="_blank"
                    >
                      <Link>
                        {getTradeNameSafe(avalaibleStatusToCreate?.estCustomer)}
                      </Link>
                    </ALink>
                  </Flex>
                </li>
              )}
            </ul>
          </Box>
        </Text>
        <Flex mt={20} alignItems="center">
          <Button
            type="button"
            isLoading={isLoading}
            onClick={() => onResolve(false)}
          >
            J'ai bien compris
          </Button>
        </Flex>
      </Box>
    </ModalFrame>
  );
};

export const showUpdateInvoiceReferenceModal = create<Props, boolean>(
  UpdateInvoiceReferenceModal
);
