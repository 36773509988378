import { CancelContractForm } from 'forms/CancelContractForm';
import { Box } from 'components/ui';
import React from 'react';
import { create } from 'react-modal-promise';
import { ModalFrame, ModalProps } from './ModalFrame';

type Props = ModalProps & {
  beforeValidation?: () => void;
  contractUuid: string;
};
type FormValues = {};

export const CancelContractModal = ({
  onResolve,
  isOpen,
  contractUuid,
}: Props) => {
  const handleSubmit = async (formData: FormValues) => {};
  return (
    <ModalFrame
      isOpen={isOpen}
      onClose={() => onResolve(false)}
      width={470}
      closeIcon
    >
      <Box>
        <CancelContractForm
          contractUuid={contractUuid}
          onClose={() => onResolve(false)}
          onSubmit={handleSubmit}
        />
      </Box>
    </ModalFrame>
  );
};

export const showCancelContractModal = create<Props>(CancelContractModal);
