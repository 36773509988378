import {
  ISalesForceGetMarketsParams,
  ISalesForceGetMarketsResponse,
  ISalesForceGetLinkParams,
  ISalesForceGetLinkResponse,
} from '@freelancelabs/teoreme-commons';
import { SALEFORCE_SERVICE_URL } from '../../constantz';
import { fetcher } from '../helpers/fetcher';

// TODO DELETE THIS AFTER  AND USE TC!!
export interface IGetContractDataFromSf {
  startDate: Date;
  endDate: Date;
  contractNumber?: string;
  totalAmountOrderedByCustomer: number;
  totalAmountOrderedByProvider: number;
  nbTotalCustomerOrder?: number;
  contractName?: string;
  marketId?: string;
}
export interface IGetOppDataFromSf {
  startDate: Date;
  endDate: Date;
  NbOrderedDays?: string;
  margin?: number;
  nbOrderedMonth?: number;
  customerRate?: number;
  providerRate?: number;
}

export interface IGetContractDataResponse {
  startDate: Date;
  endDate: Date;
  oppData?: IGetOppDataFromSf;
  contractData?: IGetContractDataFromSf;
}
export const saleforceGetContractData = (
  identifier: string,
  disableAlert?: boolean
) =>
  fetcher<any, IGetContractDataResponse>(
    SALEFORCE_SERVICE_URL + `/get-contract-data?identifier=${identifier}`,
    undefined,
    disableAlert
  );

export const saleforceGetmarket = (
  body: ISalesForceGetMarketsParams,
  disableAlert?: boolean
) =>
  fetcher<ISalesForceGetMarketsParams, ISalesForceGetMarketsResponse>(
    SALEFORCE_SERVICE_URL + `/get-markets`,
    {
      method: 'post',
      body,
    },
    disableAlert
  );
export const saleforceGetLink = (
  body: ISalesForceGetLinkParams,
  disableAlert?: boolean
) =>
  fetcher<ISalesForceGetLinkParams, ISalesForceGetLinkResponse>(
    SALEFORCE_SERVICE_URL + `/get-link`,
    {
      method: 'post',
      body,
    },
    disableAlert
  );
// TODO MAKE TC TYPES !!
export const saleforceGetOpp = (
  body: { externalId: string },
  disableAlert?: boolean
) =>
  fetcher<any, any>(
    SALEFORCE_SERVICE_URL + `/get-opp`,
    {
      method: 'post',
      body,
    },
    disableAlert
  );

export const saleforceRestartOppCreation = (
  body: { externalId: string },
  disableAlert?: boolean
) =>
  fetcher<any, any>(
    SALEFORCE_SERVICE_URL + `/restart-opp-creation`,
    {
      method: 'post',
      body,
    },
    disableAlert
  );
