//import { CloseIcon } from 'components/ui/icons';
import * as React from 'react';
import { useEffect, useState } from 'react';
//import { ContainerClose, PopupNotifications } from './styles';
import { useAlertStore } from 'store/alert';
import { ToastContainer, toast } from 'react-toastify';
import {
  INTERNAL_ERROR,
  INVALID_PARAMETER,
  MISSING_PARAMETERS,
  NOT_FOUND,
  INVALID_SEARCH_PARAMS,
  NOTHING_TO_UPDATE,
} from '@freelancelabs/teoreme-commons';
import { Text } from 'components/ui';

import 'react-toastify/dist/ReactToastify.css';
import ReactJson from '@microlink/react-json-view';

type Props = {};

const ERRORS_WITH_DETAILS = [
  INTERNAL_ERROR,
  INVALID_PARAMETER,
  MISSING_PARAMETERS,
  NOT_FOUND,
  INVALID_SEARCH_PARAMS,
  NOTHING_TO_UPDATE,
].map(error => error.code);

export const Notifications: React.FC<React.PropsWithChildren<Props>> = () => {
  const { message, open, variant, errorLogs, updateAlert } = useAlertStore();
  const closeNotification = React.useCallback(async (): Promise<void> => {
    updateAlert({
      message: '',
      open: false,
      variant: 'success',
    });
  }, [updateAlert]);

  const autoCloseNotification = React.useCallback(async (): Promise<void> => {
    await setTimeout(function () {
      closeNotification();
    }, 5000);
  }, [closeNotification]);

  const NotifError = ({ closeToast }: any) => {
    const [showDetails, setShowDetails] = React.useState<boolean>(false);
    const { message, ...objectJson } = errorLogs ?? {};

    return (
      <div>
        <p
          onClick={() => {
            setShowDetails(!showDetails);
          }}
          style={{ cursor: 'pointer' }}
        >
          {message}
          <br />
          <span style={{ fontWeight: 'bold' }}>
            {' '}
            {`${showDetails ? 'Masquer' : 'Afficher'} `} les details
          </span>
        </p>
        {showDetails && (
          <ReactJson
            src={objectJson}
            theme="harmonic"
            collapsed={false}
            displayDataTypes={false}
          />
        )}
      </div>
    );
  };

  useEffect(() => {
    if (open === true) {
      // if (variant === 'error') toast?.[variant]('message erreur');
      if (
        variant === 'error' &&
        ERRORS_WITH_DETAILS.includes(errorLogs?.errorCode)
      ) {
        toast.error(<NotifError style={{ marginTop: '10px' }} />, {});
      } else toast?.[variant](message);
      autoCloseNotification();
    }
  }, [autoCloseNotification, open]);
  // const getVariantColor = (): string => {
  //   if (variant === 'success') {
  //     return theme.colors.success.default;
  //   }
  //   if (variant === 'error') {
  //     return theme.colors.error.default;
  //   }
  //   if (variant === 'info') {
  //     return theme.colors.darkBlue;
  //   }
  //   return '';
  // };
  return (
    <div>
      <ToastContainer
        style={{ top: 80 }}
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        draggable
        pauseOnHover
        theme="colored"
        // closeButton={OnShowDetail}
      />
      {/* {open && (
        // <PopupNotifications variant={variant}>
        //   <p>
        //     {message}
        //     <ContainerClose onClick={() => closeNotification()}>
        //       <CloseIcon fill={getVariantColor()} />
        //     </ContainerClose>
        //   </p>
        // </PopupNotifications>
      )} */}
    </div>
  );
};

export default Notifications;
