import React, { ReactNode } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import styled, { css, useTheme } from 'styled-components';
import { Box, boxProperties, BoxProps } from './ui/Box';
import { ChevronRightIcon } from './ui/icons';

type SideLinkProps = {
  isSelected?: boolean;
  href?: string; // Maybe to deprecate
  exact?: boolean;
  children?: ReactNode;
  variant?: 'primary' | 'secondary';
} & BoxProps;

export const StyledTab = styled.div<SideLinkProps>`
  display: flex;
  align-items: center;
  padding: 0 10px;

  text-decoration: none;
  line-height: normal;

  letter-spacing: normal;
  height: 40px;
  white-space: nowrap;
  font-weight: bold;
  color: ${props => props.theme.colors.primary.light};

  ${props =>
    props.isSelected &&
    css`
      color: ${props => props.theme.colors.primary.dark};
    `}

  ${props =>
    props.variant === 'secondary' &&
    css`
      font-weight: normal;
      padding-left: 20px;
    `}

  cursor: pointer;

  svg {
    font-size: 1.5em;
  }

  ${boxProperties}
`;

export const SideLink = ({
  href,
  isSelected,
  children,
  exact,
  ...props
}: SideLinkProps) => {
  const theme = useTheme();
  const history = useHistory();
  const { pathname } = useLocation();
  const onNavigate = () => !!href && history.push(href);
  const routeIsSelected = exact
    ? pathname === href
    : pathname.includes(href || '');

  const selected = href ? routeIsSelected : isSelected;

  return (
    <StyledTab
      onClick={onNavigate}
      isSelected={href ? routeIsSelected : isSelected}
      {...props}
    >
      <Box flex={1} cursor="pointer" overflow="hidden" textOverflow="ellipsis">
        {children}
      </Box>
      {selected && (
        <ChevronRightIcon fill={theme.colors.blue} style={{ minWidth: 24 }} />
      )}
    </StyledTab>
  );
};
