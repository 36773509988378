import {
  craUpdateOne,
  cutLongText,
  dateAreSameMonth,
  dateIsWithinInterval,
  dateIsWithinIntervalByMonth,
  getEstablishmentName,
  getFirstDayOfMonth,
  getFullName,
  getHolidaysInWeek,
  getHolidaysWithinInterval,
  getHumanDate,
  getHumanDateMonthAndYear,
  getLastDayOfMonth,
  getWorkingDaysWithoutHolidays,
  isNotEmpty,
  queryClient,
  round,
  uploadFile,
  useGetHolidays,
} from '@commons';
import {
  EChangeOwnerType,
  ECraStatus,
  EFileType,
  EMissionCraValidatedBy,
  IFile,
  IHolidays,
  IJoinedCraWithInvoice,
  IMission,
} from '@freelancelabs/teoreme-commons';
import { HistoryList } from 'components/lists/HistoryList';
import { ConfirmNavigationModal } from 'components/modals/ConfirmNavigationModal';
import { showUpdateInvoiceReferenceModal } from 'components/modals/cra/UpdateInvoiceReferenceModal';
import { showDisplayPdfModal } from 'components/modals/DisplayPdfModal';
import { showValidateCraModal } from 'components/modals/DisplayValidateCraModal';
import { showRejectCraModal } from 'components/modals/RejectCraModal';
import TextInformation from 'components/TextInformation';
import {
  Box,
  Button,
  FileInput,
  Flex,
  FormControl,
  Input,
  LabelField,
  Link,
  Spinner,
  Status,
  Tab,
  Text,
} from 'components/ui';
import {
  AddIcon,
  CloseIcon,
  DownloadIcon,
  EuroIcon,
  EyeIcon,
} from 'components/ui/icons';
import { getStatusData } from 'helpers/string';
import { useShowMessage } from 'hooks/useShowMessage';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Theme } from 'styles';
import { columns } from './HistoryListData';
import bigDecimal from 'js-big-decimal';
type FormValues = any;
type CraDetailsFormProps = {
  cra: IJoinedCraWithInvoice;
  infoHidden?: boolean;
  isDisabled?: boolean;
  onResolve?: (data: any) => any | void;
};

const holidaysText = (holidays: IHolidays[]) => {
  const holidaysMonths = holidays?.map(day => getHumanDate(day?.date, 'dd/MM'));
  if (!holidaysMonths.length) return '';
  const resText = `${holidaysMonths?.length > 1 ? 'Les' : 'Le'}
        ${holidaysMonths?.join(', ').replace(/, ([^,]*)$/, ' et $1')}
        ${holidaysMonths?.length > 1 ? ' sont fériés.' : ' est férié.'}
  `;
  return resText;
};

export const CraDetailsForm = ({
  cra,
  infoHidden,
  isDisabled,
  onResolve,
}: CraDetailsFormProps) => {
  const showMessage = useShowMessage();
  const { data: holidays } = useGetHolidays();
  const holidaysWeekDays = holidays ? getHolidaysInWeek(holidays) : [];

  const [loading, setLoading] = useState(false);
  const [loadingFile, setLoadingFile] = useState(false);
  const [showFreeDaysInput, setShowFreeDaysInput] = useState(
    cra?.nbFreeDays && cra?.nbFreeDays > 0 ? true : false
  );
  const [showCancelFreeDaysInput, setShowCancelFreeDaysInput] = useState(false);

  const [proofFile, setProofFile] = useState<File | null>(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [proof, setProof] = useState<IFile | null>(
    cra?.proof ? cra?.proof : null
  );
  const [formSelected, setFormSelected] = useState<'INFORMATIONS' | 'HISTORY'>(
    'INFORMATIONS'
  );
  const {
    register,
    handleSubmit,
    formState: { errors, isDirty, isSubmitSuccessful },
    watch,
    setValue,
  } = useForm<FormValues>({
    defaultValues: {
      refCra: cra?.refCra,
      proof: cra?.proof,
      nbWorkingDays: cra?.nbWorkingDays,
      nbFreeDays: cra?.nbFreeDays,
      contractorRate: cra?.contractorRate,
      state: cra?.state,
    },
  });
  const nbWorkingDays = watch('nbWorkingDays');
  const nbFreeDays = watch('nbFreeDays');
  const missionEndAt = cra?.mission?.endAt as Date;
  const missionStartAt = cra?.mission?.startAt as Date;
  const currentCraMonth = new Date(cra.month);
  const declaredWorkingDaysInCras =
    cra?.mission?.billing?.declaredWorkingDaysInCras || 0;

  const firstDayOfCurrentCra = dateAreSameMonth(missionStartAt, currentCraMonth)
    ? missionStartAt
    : getFirstDayOfMonth(currentCraMonth);
  const lastDayOfMonth = getLastDayOfMonth(new Date(cra.month));
  // const workingDaysNoHolidays = getWorkingDaysWithoutHolidays(
  //   firstDayOfCurrentCra,
  //   missionEndAt,
  //   holidays
  // );
  const holidaysOfMonthWeekDays = getHolidaysWithinInterval(
    firstDayOfCurrentCra,
    lastDayOfMonth,
    holidaysWeekDays
  );

  const workingDaysToMissionEnd = getWorkingDaysWithoutHolidays(
    firstDayOfCurrentCra,
    missionEndAt,
    holidays
  );

  const workingDaysToMonthEnd = getWorkingDaysWithoutHolidays(
    firstDayOfCurrentCra,
    lastDayOfMonth,
    holidays
  );
  const estimatedDays = cra?.mission?.billing?.estimatedDays;
  // Si le status du cra est à soummettre deduire nbWorkingDays
  const nbRemainingDays = Number(
    bigDecimal.subtract(
      (estimatedDays || 0) +
        (cra?.state === ECraStatus?.TO_BE_SUBMITTED
          ? (cra?.nbWorkingDays as number)
          : 0),
      declaredWorkingDaysInCras
    )
  );

  const stepperPercent = () => {
    const percent = (declaredWorkingDaysInCras / (estimatedDays || 0)) * 100;
    if (percent > 100) {
      return '100%';
    }
    return `${percent}%`;
  };

  const stepperWidth = stepperPercent();
  const checkSubmitationAvalaible = (): {
    submitionAvalaible: boolean;
    avalaibleDateOfCRA: boolean;
    avalaibleRemaningDays: boolean;
    avalaibleEndMission: boolean;
    avalaibleEndMonth: boolean;
    avalaibleFreeDays: boolean;
  } => {
    /*
    CAS 1 : nombre de jours ouvrés restant
    CAS 2 : nombre de jours estimées
    CAS 3 : nombre de jours dépasse la fin de mission
    CAS 4 : CRA avant ou après les dates de mission
    */

    let submitionAvalaible = true;
    let avalaibleDateOfCRA = true;
    let avalaibleRemaningDays = true;
    let avalaibleEndMission = true;
    let avalaibleEndMonth = true;
    let avalaibleFreeDays = true;
    if (
      !dateIsWithinIntervalByMonth(
        currentCraMonth,
        missionStartAt as Date,
        missionEndAt as Date
      )
    ) {
      avalaibleDateOfCRA = false;
      submitionAvalaible = false;
    }
    if (nbWorkingDays > nbRemainingDays) {
      avalaibleRemaningDays = false;
      submitionAvalaible = false;
    }
    if (nbWorkingDays > workingDaysToMonthEnd) {
      avalaibleEndMonth = false;
      submitionAvalaible = false;
    }

    if (Number(nbFreeDays) > nbWorkingDays) {
      avalaibleFreeDays = false;
      submitionAvalaible = false;
    }

    if (nbWorkingDays > workingDaysToMissionEnd) {
      avalaibleEndMission = false;
      submitionAvalaible = false;
    }
    return {
      submitionAvalaible,
      avalaibleDateOfCRA,
      avalaibleRemaningDays,
      avalaibleEndMission,
      avalaibleEndMonth,
      avalaibleFreeDays,
    };
  };

  const submitationAvalaible = checkSubmitationAvalaible();

  const isMissionLastMonth = () => {
    return (
      dateIsWithinInterval(currentCraMonth, missionStartAt, missionEndAt) &&
      dateAreSameMonth(firstDayOfCurrentCra, missionEndAt)
    );
  };
  const checkDisplayMessage = () => {
    if (cra) {
      switch (cra.state) {
        case ECraStatus.TO_BE_FILLED:
          return true;
        case ECraStatus.REJECTED:
          return true;
        case ECraStatus.TO_BE_SUBMITTED:
          return true;
        case ECraStatus.TO_BE_VALIDATED:
          return false;
        case ECraStatus.VALIDATED:
          return false;
        case ECraStatus.ARCHIVED:
          return false;
      }
    }
  };
  const displayMessage = checkDisplayMessage();
  const checkIsEditableForm = () => {
    if (isDisabled) {
      return false;
    }
    switch (cra.state) {
      case ECraStatus.TO_BE_FILLED:
        return true;
      case ECraStatus.TO_BE_SUBMITTED:
        return true;
      case ECraStatus.TO_BE_VALIDATED:
        return false;
      case ECraStatus.VALIDATED:
        return false;
      case ECraStatus.ARCHIVED:
        return false;
      case ECraStatus.REJECTED:
        return true;
    }
  };

  const isEditable = checkIsEditableForm();
  // const amount = getDecimalInput((nbWorkingDays || 0) * contractorRate, 3);
  const handleChangeProofFile = async (files: File[]) => {
    setProofFile(files[0]);
    setLoadingFile(true);
    try {
      const s3Response = await uploadFile({
        file: files[0],
        fileType: EFileType.CRA,
        //@ts-ignore
        craRef: cra.refCra,
        missionRef: cra.mission.reference,
        actionType: 'upload',
      });
      const proof = {
        fileName: s3Response?.fileName,
        fileLocation: s3Response?.fileLocation as string,
        eTag: s3Response?.eTag,
      };
      setValue('proof', proof);
    } catch (e) {
      //
    }
    setLoadingFile(false);
  };
  const canShowAddFreeDaysButton = () => {
    if (!mission?.isFreeDaysEnabled) return false;
    if (!checkIsEditableForm()) return false;
    return !showFreeDaysInput;
  };

  const onSubmit = async (formValues: FormValues) => {
    setLoading(true);
    try {
      await showUpdateInvoiceReferenceModal({
        mission: cra?.mission as IMission,
        delivreyOrders: {
          bdlProvider: false,
          bdlCustomer: false,
        },
        cra: { ...cra, ...(formValues as IJoinedCraWithInvoice) },
        customerReference: cra?.mission?.customer?.purchaseOrder
          ?.reference as string,
        refetchQueryKey: 'Cras',
      })?.then(async (invoiceCustomerRefObj: any) => {
        if (
          invoiceCustomerRefObj &&
          invoiceCustomerRefObj?.customCustomerReference
        ) {
          const customCustomerReference =
            invoiceCustomerRefObj?.customCustomerReference;
          try {
            const updateCRAResponse = await craUpdateOne({
              refCras: [cra?.refCra as string],
              cra: {
                nbWorkingDays: formValues?.nbWorkingDays,
                proof: formValues?.proof,
                state: ECraStatus.VALIDATED,
                customerReference: customCustomerReference as string,
                nbFreeDays: formValues?.nbFreeDays,
                // @ts-ignore
                billFreeDaysToCustomer:
                  invoiceCustomerRefObj?.billFreeDaysToCustomer,
              },
            });
            if (
              //@ts-ignore
              updateCRAResponse?.errorUpdateArray &&
              //@ts-ignore
              updateCRAResponse?.errorUpdateArray?.length > 0
            ) {
              showMessage('error', 'Une erreur est survenue sur le  CRA');
            } else {
              showMessage('success', 'Le CRA à été validé');
              await queryClient.refetchQueries({ queryKey: [cra?.refCra] });
              await queryClient.refetchQueries({
                queryKey: ['additionalActivities'],
                type: 'active',
              });
              if (onResolve) {
                onResolve(true);
              }
            }
          } catch (e) {
            //
          }
        }
      });
    } catch (e) {
      setLoading(false);
    }
    setLoading(false);
  };
  const statusData = getStatusData(cra);
  const getIsDisabledForm = () => {
    if (isDisabled) {
      return true;
    }
    if (!isNotEmpty(nbWorkingDays) || loadingFile) {
      return true;
    }
    if (!submitationAvalaible?.submitionAvalaible) return true;
  };
  const mission = cra?.mission;
  const missionConsumedBudget =
    (mission?.billing?.declaredWorkingDaysInCras || 0) *
      (mission?.billing?.contractorRate || 0) || 0;
  //const contractorRate = mission?.billing?.contractorRate || 0;
  const missionBudget =
    (mission?.billing?.estimatedDays || 0) *
      (mission?.billing?.contractorRate || 0) || 0;
  const exepensesIsEnabled = mission?.expensesConfig?.isEnabled;
  const expenseBudget = mission?.expensesConfig?.purchaseBudget ?? 0;
  const haveExpenseBudget = exepensesIsEnabled && expenseBudget > 0;
  const expenseConsumedBudget = mission?.expensesConfig?.consumedPurchaseBudget;
  // const isOutOffBudget = haveExpenseBudget
  //   ? (expenseConsumedBudget || 0) >= (expenseBudget || 0)
  //   : false;
  const stepperPercentMission = (): { percent: string; color: string } => {
    let color = '';
    const percent = (missionConsumedBudget / (missionBudget || 0)) * 100;
    if (percent > 100) {
      color = Theme?.colors?.error?.default;
      return { percent: '100%', color };
    }
    if (percent >= 80) {
      color = Theme?.colors?.warning?.default;
    } else {
      color = Theme?.colors?.primary?.default;
    }
    return { percent: `${round(percent)}%`, color };
  };
  const stepperPercentExpenses = (): { percent: string; color: string } => {
    let color = '';
    const percent = ((expenseConsumedBudget || 0) / (expenseBudget || 0)) * 100;
    if (percent > 100) {
      color = Theme?.colors?.error?.default;
      return { percent: '100%', color };
    }
    if (percent >= 80) {
      color = Theme?.colors?.warning?.default;
    } else {
      color = Theme?.colors?.primary?.default;
    }
    return { percent: `${round(percent)}%`, color };
  };
  const stepperWidthMission = stepperPercentMission()?.percent;
  const colorStepperMission = stepperPercentMission()?.color;
  const stepperWidthExpense = stepperPercentExpenses()?.percent;
  const colorStepperExpense = stepperPercentExpenses()?.color;
  return (
    <Box>
      <Flex mb={10}>
        <Tab
          onClick={() => setFormSelected('INFORMATIONS')}
          isSelected={formSelected === 'INFORMATIONS'}
          variant="secondary"
          fontSize={12}
        >
          Informations
        </Tab>
        <Tab
          onClick={() => setFormSelected('HISTORY')}
          isSelected={formSelected === 'HISTORY'}
          variant="secondary"
          fontSize={12}
        >
          Historique
        </Tab>
      </Flex>
      <Flex justifyContent="space-between" alignItems="center" mb={20}>
        <Box>
          <Text variant="h1">{getHumanDateMonthAndYear(cra?.month)}</Text>
        </Box>
        <Box>
          <Status variantColor={statusData?.variantColor}>
            {statusData?.statusText}
          </Status>
        </Box>
      </Flex>
      {cra?.rejectReason && (
        <div style={{ padding: 5 }}>
          <TextInformation variant="error" width={1 / 1} mb={10}>
            {`Ce CRA a été refusé le ${new Date(
              cra?.stateChangedAt as Date
            ).toLocaleDateString()} pour cause de : ${
              cra?.rejectMetaData
                ? cra?.rejectMetaData
                : ' justificatif non conforme.'
            }`}
          </TextInformation>
        </div>
      )}
      <Flex>
        {formSelected === 'INFORMATIONS' && (
          <Box width={infoHidden ? 12 / 12 : 8 / 12} pr={10}>
            <Box mb={20}>
              <Flex
                width={1 / 1}
                zIndex={100}
                justifyContent="space-around"
                borderBottom={'12px solid #eaeefc'}
                borderRadius={'6px'}
              >
                <Box
                  position="absolute"
                  left={0}
                  bottom={-12}
                  //@ts-ignore
                  width={stepperWidth}
                  borderRadius={'6px'}
                  borderBottom={`12px solid ${Theme.colors.primary.default}`}
                />
              </Flex>
              <Text mt={20} width={1 / 1}>
                {round(declaredWorkingDaysInCras, 3)} jours sur {estimatedDays}{' '}
                jours prévus sur cette mission.
              </Text>
              {!submitationAvalaible?.avalaibleEndMonth && (
                <Text
                  color={Theme?.colors?.warning?.default}
                  width={1 / 1}
                  mb={20}
                  mt={20}
                >
                  Vous ne pouvez pas déclarer plus de {workingDaysToMonthEnd}{' '}
                  jours travaillés pour ce CRA.
                </Text>
              )}
              {!submitationAvalaible?.avalaibleFreeDays && (
                <Text
                  color={Theme?.colors?.warning?.default}
                  width={1 / 1}
                  mb={20}
                  mt={20}
                >
                  le nombre de jours gratuits ({nbFreeDays}) ne peut pas être
                  superieur au nombre jours travaillés ({nbWorkingDays}) pour ce
                  CRA.{' '}
                </Text>
              )}
              {displayMessage &&
                !submitationAvalaible?.avalaibleRemaningDays && (
                  <Text
                    color={Theme?.colors?.warning?.default}
                    width={1 / 1}
                    mb={20}
                    mt={20}
                  >
                    Le nombre de jours saisi ({nbWorkingDays}) est supérieur au
                    nombre de jour restant à facturer sur la mission (
                    {nbRemainingDays}).
                  </Text>
                )}

              {displayMessage && !submitationAvalaible?.avalaibleDateOfCRA && (
                <Text
                  color={Theme?.colors?.error?.default}
                  width={1 / 1}
                  mb={20}
                  mt={20}
                >
                  {`Ce CRA ne peut pas être saisi car il est postérieur à la date de fin de mission.`}
                </Text>
              )}
              {displayMessage &&
                !submitationAvalaible?.avalaibleEndMission &&
                isMissionLastMonth() && (
                  <TextInformation variant="error" width={1 / 1} mb={20}>
                    Vous ne pouvez pas déclarer plus de{' '}
                    {workingDaysToMissionEnd} jours travaillés pour ce CRA car
                    la mission se termine le{' '}
                    {getHumanDate(cra?.mission?.endAt as Date)}.
                  </TextInformation>
                )}
            </Box>
            <form onSubmit={handleSubmit(onSubmit)}>
              <ConfirmNavigationModal active={isDirty && !isSubmitSuccessful} />
              <Flex
                display="inline-flex"
                justifyContent="flex-start"
                flexWrap="wrap"
                width={12 / 12}
              >
                <Box width={showFreeDaysInput ? 5 / 12 : 12 / 12}>
                  <FormControl
                    required
                    label="Nb. de jours travaillés"
                    errorMessage={errors?.nbWorkingDays?.message}
                    warningMessage={
                      nbWorkingDays === '0'
                        ? 'Aucune facture ne sera crée chez connecteed pour tout CRA fournisseur validé à 0j travaillés'
                        : false
                    }
                  >
                    <>
                      <Input
                        data-cy="workingDaysInput"
                        isDisabled={
                          !isEditable ||
                          !submitationAvalaible?.avalaibleDateOfCRA
                        }
                        isFullWidth
                        type="number"
                        maxDecimal={3}
                        min="0"
                        step={'0.001'}
                        {...register('nbWorkingDays', {
                          required: 'Ce champ est obligatoire',
                        })}
                      />
                      {canShowAddFreeDaysButton() &&
                        !showCancelFreeDaysInput && (
                          <Box mt="10px">
                            <Link
                              iconLeft={<AddIcon />}
                              onClick={() => {
                                setShowFreeDaysInput(true);
                                setShowCancelFreeDaysInput(true);
                              }}
                            >
                              Ajouter des jours gratuits
                            </Link>
                          </Box>
                        )}
                    </>
                  </FormControl>
                </Box>

                {showFreeDaysInput && (
                  <>
                    <Box width={2 / 12} pt={30} textAlign="center">
                      <Text variant="span" ml={2}>
                        <b>dont</b>
                      </Text>
                    </Box>
                    <Box width={5 / 12}>
                      <FormControl required label="Nb. de jours Offerts">
                        <>
                          <Input
                            data-cy="billFreeDaysToCustomer"
                            pr={10}
                            isDisabled={
                              !isEditable ||
                              !submitationAvalaible?.avalaibleDateOfCRA
                            }
                            isFullWidth
                            type="number"
                            maxDecimal={3}
                            min="0"
                            step={'0.001'}
                            {...register('nbFreeDays', {
                              required: 'Ce champ est obligatoire',
                            })}
                          />
                          {isEditable && (
                            <Box mt="10px">
                              <Link
                                iconLeft={<CloseIcon />}
                                onClick={() => {
                                  setValue(
                                    'nbFreeDays',
                                    cra?.nbFreeDays ? 0 : undefined
                                  );
                                  setShowFreeDaysInput(false);
                                  setShowCancelFreeDaysInput(false);
                                }}
                              >
                                Supprimer les jours gratuits
                              </Link>
                            </Box>
                          )}
                        </>
                      </FormControl>
                    </Box>
                  </>
                )}
                <Box width={6 / 12}>
                  <FormControl
                    label="TARIF JOUR PERÇU PAR L'INTERVENANT"
                    errorMessage={errors?.contractorRate?.message}
                  >
                    <Input
                      icon={<EuroIcon style={{ marginRight: 10 }} />}
                      isDisabled
                      pr={10}
                      isFullWidth
                      {...register('contractorRate')}
                    />
                  </FormControl>
                </Box>
                <Box width={6 / 12}>
                  <FormControl
                    label="TARIF JOUR PAYÉ PAR LE CLIENT"
                    errorMessage={errors?.clientRate?.message}
                  >
                    <Input
                      icon={<EuroIcon style={{ marginRight: 10 }} />}
                      isDisabled
                      isFullWidth
                      value={cra?.mission?.billing?.clientRate}
                      name="clientRate"
                    />
                  </FormControl>
                </Box>
                <Box width={12 / 12}>
                  <FormControl
                    label={
                      <Flex>
                        <Text fontSize={11}>
                          {isEditable
                            ? 'Importer un justificatif'
                            : 'justificatif'}
                        </Text>
                      </Flex>
                    }
                    errorMessage={errors?.proof?.message}
                  >
                    <>
                      {proof?.fileName && (
                        <Link
                          mr={15}
                          iconLeft={<EyeIcon />}
                          onClick={() =>
                            showDisplayPdfModal({
                              fileLocation: proof?.fileLocation,
                              fileName: proof?.fileName || '',
                              layer: 102,
                            })
                          }
                        >
                          Voir le justificatif
                        </Link>
                      )}
                      {isEditable ? (
                        <FileInput
                          isDisabled={!isEditable}
                          label=""
                          accept=".pdf"
                          subLabel=""
                          onSelect={handleChangeProofFile}
                        >
                          {loadingFile && <Spinner />}
                          <Link iconLeft={<DownloadIcon />}>
                            {proofFile || proof?.fileName
                              ? `Modifier le fichier ${
                                  proofFile?.name
                                    ? cutLongText(proofFile.name, 20)
                                    : cutLongText(
                                        proof && proof.fileName
                                          ? proof?.fileName
                                          : '',
                                        20
                                      )
                                }`
                              : 'Importer un document'}
                          </Link>
                        </FileInput>
                      ) : (
                        <Box pr={20}>
                          <Flex
                            pl={'10px'}
                            justifyContent="flex-start"
                            alignContent="center"
                            alignItems="center"
                            border={`1px solid ${Theme.colors.primary?.dark}`}
                            height={45}
                            borderRadius={5}
                            backgroundColor="rgba(182,198,238,0.5)"
                          >
                            {proof ? (
                              <Link
                                onClick={() =>
                                  showDisplayPdfModal({
                                    fileLocation: proof?.fileLocation || '',
                                    fileName: proof?.fileName || '',
                                    layer: 102,
                                  })
                                }
                              >
                                <u>{proof?.fileName}</u>
                              </Link>
                            ) : (
                              <Text>Aucun justificatif</Text>
                            )}
                          </Flex>
                        </Box>
                      )}
                    </>
                  </FormControl>
                  <FormControl
                    label="Commentaire à l'intention du Chargé de compte"
                    errorMessage={errors?.clientRate?.message}
                  >
                    <Text>{cra?.accountManagerComment || 'N/A'}</Text>
                  </FormControl>
                </Box>
              </Flex>
              {!isDisabled && (
                <Flex width={12 / 12}>
                  {cra?.state === ECraStatus?.TO_BE_VALIDATED && (
                    <>
                      <Button
                        onClick={async () => {
                          await showValidateCraModal({
                            layer: 102,
                            fileLocation: cra?.proof?.fileLocation,
                            fileName: cra?.proof?.fileName,
                            confirmLabel: `Valider ( ${cra?.nbWorkingDays} jours )`,
                            refCra: cra.refCra,
                            onConfirm: async () => {
                              await queryClient.refetchQueries({
                                queryKey: ['Cras'],
                                type: 'active',
                              });
                              await queryClient.refetchQueries({
                                queryKey: ['additionalActivities'],
                                type: 'active',
                              });
                            },
                            rejectLabel: 'Refuser',
                            onRejectCra: async () => {
                              await showRejectCraModal({
                                cra: cra,
                                layer: 103,
                              });
                            },
                          });
                        }}
                        type="button"
                        isDisabled={getIsDisabledForm()}
                        isLoading={loading}
                        ml={'5px'}
                      >
                        Valider / Refuser
                      </Button>
                    </>
                  )}
                  {(cra?.state === ECraStatus?.TO_BE_FILLED ||
                    cra?.state === ECraStatus?.TO_BE_SUBMITTED ||
                    cra?.state === ECraStatus?.REJECTED) && (
                    <Button
                      type="submit"
                      isLoading={loading}
                      isDisabled={getIsDisabledForm()}
                      ml={'5px'}
                    >
                      Enregistrer et valider
                    </Button>
                  )}
                </Flex>
              )}
            </form>
          </Box>
        )}
        {formSelected === 'HISTORY' && (
          <Box width={infoHidden ? 12 / 12 : 8 / 12} pr={10}>
            <HistoryList
              columns={columns}
              filterObject={{
                ownerId: cra?.refCra,
                ownerType: EChangeOwnerType?.CRA,
              }}
            />
          </Box>
        )}
        {/* */}
        <Box hidden={infoHidden} width={4 / 12}>
          {holidaysOfMonthWeekDays.length > 0 && (
            <Box
              mb={20}
              width={1 / 1}
              borderRadius={'5px'}
              backgroundColor="#ffedcc"
            >
              <Box p={10}>
                {
                  <LabelField
                    label="Jours fériés"
                    value={holidaysText(holidaysOfMonthWeekDays)}
                    underline
                  />
                }
              </Box>
            </Box>
          )}
          <Box>
            <Box width={1 / 1} borderRadius={'5px'} backgroundColor="#e5f5ff">
              <Box p={10}>
                <LabelField
                  label="Référence CRA"
                  value={cra?.refCra}
                  underline
                  mb={10}
                />
                <LabelField
                  label="Date de mission"
                  value={`${getHumanDate(
                    cra?.mission?.startAt as Date
                  )} au ${getHumanDate(cra?.mission?.endAt as Date)}`}
                  underline
                  mb={10}
                />
                <LabelField
                  label="Référence de la mission"
                  value={cra?.mission?.displayReference}
                  linkValue={`/delivery/missions/${cra?.mission?.reference}/gescom-create`}
                  underline
                  mb={10}
                />
                <LabelField
                  label="Client"
                  value={getEstablishmentName(cra?.estCustomer)}
                  linkValue={`/clients/establishments/${cra?.estCustomer?.uuid}/information`}
                  underline
                  mb={10}
                />
                <LabelField
                  label="Fournisseur"
                  value={getEstablishmentName(cra?.estProvider)}
                  linkValue={`/providers/establishments/${cra?.estProvider?.uuid}/information`}
                  underline
                  mb={10}
                />
                <LabelField
                  label="Intervenant"
                  value={getFullName(cra?.contractor)}
                  linkValue={`/providers/contractors/${cra?.contractor?.uuid}`}
                  underline
                  mb={10}
                />
                <LabelField
                  label="Budget mission consommé"
                  value={
                    <Box mb={20}>
                      <Flex
                        width={1 / 1}
                        zIndex={100}
                        justifyContent="space-around"
                        borderBottom={'12px solid #eaeefc'}
                        borderRadius={'6px'}
                      >
                        <Box
                          position="absolute"
                          left={0}
                          bottom={-12}
                          //@ts-ignore
                          width={stepperWidthMission}
                          borderRadius={'6px'}
                          borderBottom={`12px solid ${colorStepperMission}`}
                        />
                        <Box
                          width={1 / 1}
                          position="absolute"
                          left={0}
                          bottom={-12}
                        >
                          <Text
                            top="2px"
                            textAlign={'center'}
                            color={
                              Number(stepperWidthMission?.replace('%', '')) > 47
                                ? 'white'
                                : 'black'
                            }
                            fontSize={10}
                          >
                            {stepperWidthMission}
                          </Text>
                        </Box>
                      </Flex>
                      <Text mt={20} width={1 / 1}>
                        {`Le fournisseur à consommé ${round(
                          missionConsumedBudget
                        )} € sur ${missionBudget} € prévus sur cette mission.`}
                      </Text>
                    </Box>
                  }
                  underline
                  mb={-10}
                />
                {haveExpenseBudget && (
                  <LabelField
                    label="Budget des frais consommé"
                    value={
                      <Box mb={20}>
                        <Flex
                          width={1 / 1}
                          zIndex={100}
                          justifyContent="space-around"
                          borderBottom={'12px solid #eaeefc'}
                          borderRadius={'6px'}
                        >
                          <Box
                            position="absolute"
                            left={0}
                            bottom={-12}
                            //@ts-ignore
                            width={stepperWidthExpense}
                            borderRadius={'6px'}
                            borderBottom={`12px solid ${colorStepperExpense}`}
                          />
                          <Box
                            width={1 / 1}
                            position="absolute"
                            left={0}
                            bottom={-12}
                          >
                            <Text
                              top="2px"
                              textAlign={'center'}
                              color={
                                Number(stepperWidthExpense?.replace('%', '')) >
                                47
                                  ? 'white'
                                  : 'black'
                              }
                              fontSize={10}
                            >
                              {stepperWidthExpense}
                            </Text>
                          </Box>
                        </Flex>
                        <Text mt={20} width={1 / 1}>
                          {`Le fournisseur à consommé ${round(
                            expenseConsumedBudget || 0
                          )} € de frais sur ${round(
                            expenseBudget || 0
                          )} € prévus sur cette mission.`}
                        </Text>
                      </Box>
                    }
                    underline
                    mb={10}
                  />
                )}
              </Box>
            </Box>
            <Box
              mt={20}
              width={1 / 1}
              borderRadius={'5px'}
              backgroundColor={'#ffedcc'}
            >
              <Box p={10}>
                <LabelField
                  label="Methode de validation du CRA"
                  value={
                    cra?.craValidatedBy ===
                    EMissionCraValidatedBy?.CUSTOMER_CONTACT
                      ? 'Validation par le client'
                      : 'Validation par le chargé de compte'
                  }
                  underline
                  mb={10}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Flex>
    </Box>
  );
};
